import styles from '../../../styles.module.scss'
import classNames from 'classnames'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, TableActionIcon } from '../../../../../../../assets/svg'
import { formatOnlyDate, formatToShamsiDate } from '../../../../../../../helpers/utils'
import { useContext } from 'react'
import { CenterModalContext } from 'src/features/Modals'
import { CENTER_MODALS } from 'src/helpers/contants'
import { Button } from 'src/shared/components/button/Button'
import { EEventHoliday, IEventHoliday } from '../../../../../models/IEventsHolidays'
import { Emitter, EmitterEvents } from 'src/helpers/eventEmitter'
import { useRemoveEventMutation } from 'src/features/Settings/core/http/EventsHolidaysApi'
import { notification, Table } from 'antd'
import { useEmptyTable } from 'src/shared/hooks/table/useEmptyTable'
import { useTablePagination } from 'src/shared/hooks/table/useTablePagination'
import { ITableConf } from 'src/shared/models/ITableConf'
import { ColumnsType } from 'antd/es/table'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from 'src/shared/components/popup/Popup'
import { ErrorNode, MESSAGE_KEY } from 'src/shared/api/errorHandler'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { useOnTableChange } from 'src/shared/hooks/table/useOnTableChange'
import moment from 'moment/moment'
import { CALENDAR_TYPE_ENUM } from '../../../../../models/IUser'
import dayjs from 'dayjs'

interface IEvent {
  totalCount: number | undefined
  items: IEventHoliday[] | undefined
  isFetching: boolean | undefined
  setTableConf: React.Dispatch<React.SetStateAction<ITableConf>>
}

export const EventsBlock = ({ totalCount = 0, items = [], isFetching, setTableConf }: IEvent) => {
  const { onOpen, onClose } = useContext(CenterModalContext)
  const { data: profile } = useGetProfileQuery()

  const [deleteEvent, dEvent] = useRemoveEventMutation()

  useNotification(NOTIFICATION_TYPES.success, dEvent.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, dEvent.isError, dEvent.error as ErrorNode)

  const openNotification = (id: string) => {
    const btn = (
      <Button
        type='primary'
        color='blue'
        onClick={() => {
          deleteEvent(id)
          notification.close(MESSAGE_KEY)
        }}
      >
        Confirm
      </Button>
    )
    notification.open({
      type: 'warning',
      message: 'This Event will be deleted',
      key: MESSAGE_KEY,
      btn,
    })
  }

  const tableActionsPopup = (id: string): IPopupListItems[] => {
    return [
      {
        text: 'Edit',
        shouldDisplay: true,
        onClick: () =>
          onOpen(CENTER_MODALS.EVENTS_MODAL, {
            title: 'Edit Event',
            type: EEventHoliday.events,
            onCloseAction: onResetForm,
            id,
            footer: (
              <Button
                color='orange'
                size='middle'
                type='primary'
                htmlType='submit'
                onClick={onSubmit}
              >
                Confirm
              </Button>
            ),
          }),
      },
      {
        text: 'Delete',
        withDivider: true,
        shouldDisplay: true,
        color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        onClick: () => openNotification(id),
      },
    ]
  }

  const onSubmit = () => {
    Emitter.emit(EmitterEvents.EVENT_CREATE)
  }

  const onResetForm = () => {
    onClose()
    Emitter.emit(EmitterEvents.EVENT_RESET)
  }

  const onEventClick = () => {
    onOpen(CENTER_MODALS.EVENTS_MODAL, {
      title: 'Create Event',
      type: EEventHoliday.events,
      onCloseAction: onResetForm,
      footer: (
        <Button color='orange' size='middle' type='primary' htmlType='submit' onClick={onSubmit}>
          Confirm
        </Button>
      ),
    })
  }

  const columns: ColumnsType<IEventHoliday> = [
    {
      title: 'Name',
      dataIndex: 'title',
      width: '33%',
      render: (title: IEventHoliday['title']) => <div>{title}</div>,
    },
    {
      title: 'Start Date',
      dataIndex: 'start',
      width: '33%',
      render: (start) => <span>{formatOnlyDate(start, profile?.calendar)}</span>,
    },
    {
      title: 'End Date',
      dataIndex: 'end',
      width: '33%',
      render: (date) => {
        if (profile?.calendar === CALENDAR_TYPE_ENUM.SHAMSI && date) {
          const end = dayjs(date).calendar('jalali').format('DD, YYYY')
          const { shamsiMonth } = formatToShamsiDate(end)
          return `${shamsiMonth} ${end}`
        }

        return moment(date).utc().format('MMMM DD, YYYY')
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const emptyTableConf = useEmptyTable()
  const paginationConfig = useTablePagination(totalCount, false, 5)
  const onTableParamsChange = useOnTableChange<IEventHoliday>(setTableConf)

  return (
    <div className={styles.eventsBlock}>
      <div className={styles.eventsBlockHeader}>
        <div className={styles.eventsBlockTitle}>
          <span className={classNames(styles.titleDot, styles.greenDot)} />
          <div>Events</div>
        </div>
        <div onClick={onEventClick}>
          <IconButton icon={<AddIcon />} color='orange' />
        </div>
      </div>
      <div className={classNames(styles.logsTable, styles.logsEvents)}>
        <Table
          columns={columns}
          locale={emptyTableConf}
          onChange={onTableParamsChange}
          loading={isFetching}
          dataSource={items}
          pagination={paginationConfig}
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  )
}
