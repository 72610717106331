import { IAgentAllGetResponse } from '../../../../../../../salesNetwork/models/IAgent'
import styles from './styles.module.scss'
import { formatNumberToLocale } from '../../../../../../../salesNetwork/helpers'

interface IProps {
  agent?: IAgentAllGetResponse | null
  customer?: string | null
  messengerId?: number
}

export const ChatroomUserInfo = ({ agent, customer, messengerId }: IProps) => {
  if (agent) {
    return (
      <div className={styles.internalChatUserInfo}>
        <div className={styles.userInfoItem}>
          <div className={styles.infoTitle}>ID</div>
          <div className={styles.infoData}>{agent?.id}</div>
        </div>
        <div className={styles.userInfoItem}>
          <div className={styles.infoTitle}>Account Type</div>
          <div className={styles.infoData}>{agent?.accountType?.accountType}</div>
        </div>
        {/*<div className={styles.userInfoItem}>*/}
        {/*  <div className={styles.infoTitle}>Parent Name</div>*/}
        {/*  <div className={styles.infoData}>{agent?.accountType?.accountType}</div>*/}
        {/*</div>*/}
        <div className={styles.userInfoItem}>
          <div className={styles.infoTitle}>Location</div>
          <div
            className={styles.infoData}
          >{`${agent?.province?.name}, ${agent?.district?.name}`}</div>
        </div>
        <div className={styles.userInfoItem}>
          <div className={styles.infoTitle}>Current Balance</div>
          <div className={styles.infoData}>{formatNumberToLocale(agent?.wallet?.amount ?? 0)}</div>
        </div>
      </div>
    )
  }

  if (customer) {
    return (
      <div className={styles.internalChatUserInfo}>
        <div className={styles.userInfoItem}>
          <div className={styles.infoTitle}>Phone Number</div>
          <div className={styles.infoData}>{customer}</div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.internalChatUserInfo}>
      {messengerId && (
        <div className={styles.userInfoItem}>
          <div className={styles.infoTitle}>Phone Number</div>
          <div className={styles.infoData}>{messengerId}</div>
        </div>
      )}
    </div>
  )
}
