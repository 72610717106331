import React, { memo } from 'react'
import moment from 'moment/moment'
import styles from '../../styles.module.scss'

interface IProps {
  createdAt: string
}

const DateDelimiter = ({ createdAt }: IProps) => {
  return (
    <div className={styles.daysMessageSeparator}>
      {moment().isSame(createdAt, 'days') ? 'Today' : moment(createdAt).format('MMMM DD')}
    </div>
  )
}

export default memo(DateDelimiter)
