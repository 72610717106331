import React, { memo } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

interface IProps {
  status: string
}

const TransactionStatus = ({ status }: IProps) => {
  return (
    <div className={styles.statusContainer}>
      <div
        className={classNames(styles[status.replace(' ', '_')?.toLowerCase()], styles.statusPoint)}
      />
      <div>{status}</div>
    </div>
  )
}

export default memo(TransactionStatus)
