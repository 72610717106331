import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { IAccountTypeOption, IAgentType } from '../../models/IAgentType'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../../shared/models/ITableConf'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { IAccountType } from '../../models/IAccountType'

export const systemConfigurationApi = createApi({
  reducerPath: 'systemConfiguration',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAgentTypeList: builder.query<ITableResponse<IAgentType>, ITableConf>({
      query: (params) => ({
        url: 'agent-types/configuration/agents',
        params,
      }),
    }),

    getAgentTypeFilterList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'agent-types/configuration/agents/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `agent-types/configuration/agents/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),

    getAccountTypeList: builder.query<ITableResponse<IAccountType>, ITableConf>({
      query: (params) => ({
        url: 'account-types/configuration/agents',
        params,
      }),
    }),

    getAccountTypeFilterList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'account-types/configuration/agents/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `account-types/configuration/agents/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),

    exportAccountTypeTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx'; id: string }
    >({
      query: ({ conf, formatFile }) => ({
        url: `account-types/configuration/agents/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getAccountTypeOptions: builder.query<ITableResponse<IAccountTypeOption>, void>({
      query: () => ({
        url: 'account-types',
      }),
    }),
  }),
})

export const {
  useGetAgentTypeListQuery,
  useGetAgentTypeFilterListQuery,
  useGetAccountTypeListQuery,
  useGetAccountTypeFilterListQuery,
  useLazyExportAccountTypeTableQuery,
  useGetAccountTypeOptionsQuery,
} = systemConfigurationApi
