import React, { useState } from 'react'
import { Table } from 'antd'
import ActiveHours from './Components/ActiveHours'
import { useEmptyTable } from 'src/shared/hooks/table/useEmptyTable'
import { ColumnsType } from 'antd/es/table'
import { IActivity } from '../../models/IProfile'
import useTable from '../../../../shared/hooks/table/useTable'
import { IBankAccountList } from '../../../Finance/models/IBankAccount'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { useGetQueryParams } from '../../../../shared/hooks/table/useGetQueryParams'
import styles from './styles.module.scss'

const ProfileActivityLog = () => {
  const emptyTableConf = useEmptyTable()

  const { queryParams } = useGetQueryParams()

  const [, setTableConf] = useState<ITableConf>(queryParams)

  const { pagination } = useTable<IBankAccountList>({
    total: 0,
    setTableConf,
  })

  const columns: ColumnsType<IActivity> = [
    {
      title: 'Activity',
      dataIndex: 'name',
      width: '60%',
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      width: '20%',
    },
    {
      title: 'Date/Time',
      dataIndex: 'createdAt',
      width: '20%',
    },
  ]
  return (
    <div className={styles.layout}>
      <ActiveHours />
      <div>
        <Table
          locale={emptyTableConf}
          className={styles.table}
          dataSource={[]}
          rowSelection={{ type: 'checkbox' }}
          columns={columns}
          rowKey={(record) => record.id}
          scroll={{ x: 1300 }}
          pagination={pagination}
        />
      </div>
    </div>
  )
}

export default ProfileActivityLog
