import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import SubmitInput from './Components/SubmitInput'
import ChatRoomAttachedFiles from './Components/AttachedFiles'
import { AttachFile, EmojiSelect } from 'src/shared/components/chat/Components'
import styles from './styles.module.scss'
import {
  externalChatApi,
  useCreateExternalChatMessageMutation,
  useUpdateChatMemberMutation,
} from '../../../../../../core/http/ExternalChatApi'
import { useAppDispatch } from '../../../../../../../../redux'
import { EmojiClickData } from 'emoji-picker-react'
import {
  EXTERNAL_CHAT_LIST_TABS,
  EXTERNAL_CHAT_WS_EVENTS_ENUM,
  ILastExternalChatMessage,
  MESSAGE_TYPE_ENUM,
} from '../../../../../../models/IExternalChat'
import { DefaultEventsMap } from '@socket.io/component-emitter'
import { Socket } from 'socket.io-client'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../shared/hooks/useNotification'
import ShowMessage, { ErrorNode } from '../../../../../../../../shared/api/errorHandler'
import { Button } from '../../../../../../../../shared/components/button/Button'
import { SendPlaneIcon } from '../../../../../../../../assets/svg'
import { IconButton } from '../../../../../../../../shared/components/iconButton/IconButton'
import { externalChatSocketConnection } from '../../../../../../../../shared/sockets'
import { addExternalMessagesAfter } from '../../../../../../core/store/ExternalChatMessagingSlice'
import {
  archiveExternalChat,
  clearExternalChatList,
  setActiveTab,
  updateExternalChatLastMessage,
  updateExternalChatUserInfo,
} from '../../../../../../core/store/ExternalChatChatsListSlice'
import { Tabs } from 'antd/es'
import classNames from 'classnames'
import MessageToReply from './Components/MessageToReply'

interface IProps {
  selectedChatId?: number
  currentUserId: number | null
  messageToReply: Partial<ILastExternalChatMessage> | null
  clearReply: () => void
  setToScrollId: Dispatch<SetStateAction<number | null>>
  isCurrentUserConnected?: boolean
  isChatArchived?: boolean
  activeTab: EXTERNAL_CHAT_LIST_TABS
}

// 100MB
const MAX_WHATSAPP_FILE_SIZE = 5 * 1024 * 1024

enum ACTIVE_MESSAGE_TAB_ENUM {
  'REPLY' = 'REPLY',
  'PRIVATE_NOTE' = 'PRIVATE NOTE',
}

const MessagingFooter = ({
  selectedChatId,
  currentUserId,
  messageToReply,
  clearReply,
  setToScrollId,
  isCurrentUserConnected,
  isChatArchived,
  activeTab,
}: IProps) => {
  const [activeMessageTab, setActiveMessageTab] = useState(ACTIVE_MESSAGE_TAB_ENUM.REPLY)
  const dispatch = useAppDispatch()
  const websocketRef = useRef<Socket<DefaultEventsMap, DefaultEventsMap> | null>(null)

  const [message, setMessage] = useState('')
  const [files, setFiles] = useState<FileList | Array<File> | null>(null)

  const [crMess, { isError, error, isLoading, data }] = useCreateExternalChatMessageMutation()
  const [updExtChatMemb, updExtChatMembResp] = useUpdateChatMemberMutation()

  useNotification(NOTIFICATION_TYPES.error, isError, error as ErrorNode)

  const onChangeMessage = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      websocketRef.current?.emit(EXTERNAL_CHAT_WS_EVENTS_ENUM.TYPING, {
        chatId: selectedChatId,
      })

      setMessage(e.target.value)
    },
    [selectedChatId]
  )

  const onAttachFiles = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFiles((prev) => {
      if (!prev?.length) {
        return e.target.files
      }
      return [...prev, ...(e.target.files as FileList)]
    })
  }, [])

  const onSelectEmoji = useCallback((em: EmojiClickData) => {
    setMessage((prev) => `${prev} ${em.emoji}`)
  }, [])

  const onRemoveAttachment = useCallback((name: string) => {
    setFiles((prev) => {
      if (prev?.length) {
        return [...prev].filter((s) => s.name !== name)
      }
      return null
    })
  }, [])

  const onSendMessage = useCallback(() => {
    if (isLoading) return
    let isSizeOutOfLimit = false
    const req = {
      chatId: selectedChatId,
      messageType:
        activeMessageTab === ACTIVE_MESSAGE_TAB_ENUM.REPLY
          ? MESSAGE_TYPE_ENUM.MESSAGE
          : MESSAGE_TYPE_ENUM.PRIVATE_NOTE,
      ...(messageToReply && { replyToMessageId: messageToReply?.id }),
      ...(message.trim().length && { message }),
    }

    const body = new FormData()
    body.append('externalChatMessage', JSON.stringify(req))

    if (files?.length) {
      if (files?.length > 1) {
        return ShowMessage('error', 'Only one file can be send in message!')
      }

      for (const file of files) {
        body.append(`files`, file)

        if (file.size > MAX_WHATSAPP_FILE_SIZE) {
          isSizeOutOfLimit = true
        }
      }
    }
    if (isSizeOutOfLimit) {
      return ShowMessage('error', "The file size can't be higher than 5MB!")
    }

    return (files?.length || req.message) && crMess(body)
  }, [activeMessageTab, crMess, files, isLoading, message, messageToReply, selectedChatId])

  const resetInputFields = useCallback(() => {
    setMessage('')
    setFiles(null)
    clearReply()
  }, [clearReply])

  const filesNames = useMemo(() => {
    const names = []
    if (files?.length) {
      for (const file of files) {
        names.push({ name: file.name })
      }
    }
    return names
  }, [files])

  useEffect(() => {
    if (data) {
      // UPDATE MESSAGES IN SELECTED CHAT WHEN IT WAS FROM CURRENT USER
      if (data?.chatId === selectedChatId && data?.createdByUserId === currentUserId) {
        dispatch(addExternalMessagesAfter([data]))
      }
      // UPDATE LAST MESSAGE IN CHATS
      if (data?.createdByUserId === currentUserId) {
        dispatch(updateExternalChatLastMessage({ selectedChatId: data?.chatId as number, data }))
      }

      resetInputFields()
      setToScrollId(data?.id)
    }
  }, [currentUserId, data, dispatch, resetInputFields, selectedChatId, setToScrollId])

  // RESET ALL THE INPUT FIELDS ON CHAT SELECTION
  useEffect(() => {
    if (selectedChatId) {
      resetInputFields()
      setActiveMessageTab(ACTIVE_MESSAGE_TAB_ENUM.REPLY)
    }
  }, [resetInputFields, selectedChatId])

  useEffect(() => {
    websocketRef.current = externalChatSocketConnection.getSocket()

    return () => {
      websocketRef.current = null
    }
  }, [])

  useEffect(() => {
    if (updExtChatMembResp?.data && updExtChatMembResp?.originalArgs?.isConnected) {
      dispatch(updateExternalChatUserInfo(updExtChatMembResp.data))
      dispatch(
        externalChatApi.util.invalidateTags([
          { type: 'IExternalChatById', id: updExtChatMembResp.data?.chatId },
        ])
      )
      if (activeTab !== EXTERNAL_CHAT_LIST_TABS.MY) {
        dispatch(archiveExternalChat(updExtChatMembResp.data?.chatId))
      }
      if (
        selectedChatId === updExtChatMembResp.data?.chatId &&
        activeTab !== EXTERNAL_CHAT_LIST_TABS.MY
      ) {
        dispatch(clearExternalChatList())
        dispatch(setActiveTab(EXTERNAL_CHAT_LIST_TABS.MY))
      }
    }
  }, [
    dispatch,
    selectedChatId,
    updExtChatMembResp.data,
    updExtChatMembResp?.originalArgs?.isConnected,
  ])

  const content = (
    <>
      {isChatArchived && <div className={styles.archivedChatMessage}>This Chat Is Archived</div>}
      {isCurrentUserConnected && !isChatArchived && (
        <>
          {!isLoading && filesNames.length > 0 ? (
            <ChatRoomAttachedFiles
              filesNames={filesNames}
              onRemoveAttachment={onRemoveAttachment}
            />
          ) : null}
          {messageToReply && <MessageToReply message={messageToReply} clearReply={clearReply} />}
          <div
            className={classNames(styles.addMessageToolsContainer, {
              [styles.privateContainer]: activeMessageTab === ACTIVE_MESSAGE_TAB_ENUM.PRIVATE_NOTE,
            })}
            id='ext-chat-add-message-input-container'
          >
            <SubmitInput
              message={message}
              onChangeMessage={onChangeMessage}
              onSendMessage={onSendMessage}
              setMessage={setMessage}
            />
            <div className={styles.actionsContainer}>
              <AttachFile onAttachFiles={onAttachFiles} key='external' />
              <EmojiSelect onSelectEmoji={onSelectEmoji} />
              <div className={styles.messageAction}>
                <IconButton
                  type='primary'
                  color='blue'
                  loading={isLoading}
                  icon={<SendPlaneIcon />}
                  onClick={onSendMessage}
                  disabled={!files?.length && !message?.length}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {!isCurrentUserConnected && !isChatArchived && (
        <Button
          color='orange'
          onClick={() => updExtChatMemb({ chatId: selectedChatId, isConnected: true })}
          block
        >
          Connect to the chat
        </Button>
      )}
    </>
  )

  const items = [
    {
      label: ACTIVE_MESSAGE_TAB_ENUM.REPLY,
      key: ACTIVE_MESSAGE_TAB_ENUM.REPLY,
    },
    {
      label: ACTIVE_MESSAGE_TAB_ENUM.PRIVATE_NOTE,
      key: ACTIVE_MESSAGE_TAB_ENUM.PRIVATE_NOTE,
    },
  ]

  const shouldDisplayTabs = useMemo(
    () => isCurrentUserConnected && !isChatArchived,
    [isChatArchived, isCurrentUserConnected]
  )

  return (
    <div className={styles.messagingFooterContainer}>
      {shouldDisplayTabs ? (
        <>
          <Tabs
            activeKey={activeMessageTab}
            onChange={(tab) => setActiveMessageTab(tab as ACTIVE_MESSAGE_TAB_ENUM)}
            items={items}
          />
          {content}
        </>
      ) : (
        content
      )}
    </div>
  )
}

export default memo(MessagingFooter)
