import { FormInstance } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { useContext } from 'react'
import { useParams } from 'react-router'
import { CenterModalContext } from 'src/features/Modals'
import { CENTER_MODALS } from 'src/helpers/contants'
import { onCopyText } from 'src/helpers/utils'
import { Button } from 'src/shared/components/button/Button'
import { TextField } from 'src/shared/components/textfield/TextField'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'

import styles from './styles.module.scss'

interface IApiKeyBlock {
  onGenerate: () => void
  view: boolean
  form: FormInstance
}

const ApiKeyBlock = ({ form, view, onGenerate }: IApiKeyBlock) => {
  const { onOpen, onClose } = useContext(CenterModalContext)
  const { mode } = useParams()

  const isEditMode = mode === 'edit'

  const handleGenerateClick = (title: string) => () => {
    onOpen(CENTER_MODALS.CONFIRM_MODAL, {
      title: 'Generate API Key',
      content: <div>Do you want to {title} API Key?</div>,
      onCloseAction: onClose,
      footer: (
        <>
          <Button color='blue' size='middle' onClick={onClose}>
            Cancel
          </Button>

          <Button
            color='orange'
            size='middle'
            type='primary'
            htmlType='submit'
            onClick={onGenerate}
          >
            Confirm
          </Button>
        </>
      ),
    })
  }
  return (
    <Wrapper title='api key' className={styles.wrapperContainer}>
      <FormItem name={['apiKey', 'secret']}>
        <TextField disabled placeholder='API Key' />
      </FormItem>
      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const apiKey = getFieldValue(['apiKey', 'secret'])

          if (apiKey) {
            return (
              <div>
                {!view && (
                  <Button
                    color='orange'
                    style={{ marginTop: '14px', marginRight: '8px' }}
                    onClick={handleGenerateClick('regenerate')}
                  >
                    Regenerate
                  </Button>
                )}
                <Button
                  color='orange'
                  style={{ marginTop: '14px' }}
                  onClick={() => onCopyText(form.getFieldValue(['apiKey', 'secret']))}
                >
                  Copy
                </Button>
              </div>
            )
          }

          if (isEditMode) {
            return (
              <Button
                color='orange'
                style={{ marginTop: '14px', marginRight: '8px' }}
                onClick={handleGenerateClick('generate')}
              >
                Generate
              </Button>
            )
          }
        }}
      </FormItem>
    </Wrapper>
  )
}

export default ApiKeyBlock
