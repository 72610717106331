import { Space, Tabs } from 'antd'
import { useAppDispatch } from '../../../../../../../../../redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import {
  useGetPositionByIdQuery,
  usePatchUserPositionMutation,
} from '../../../../../../../core/http/PositionsApi'
import { useContext, useEffect } from 'react'
import { setBreadcrumb } from '../../../../../../../../app/core/BreadcrumbsSlice'
import { POSITION_TABS_ENUM } from './index.d'
import { AddIcon, EditIcon } from '../../../../../../../../../assets/svg'
import { IconButton } from '../../../../../../../../../shared/components/iconButton/IconButton'
import { Toggle } from '../../../../../../../../../shared/components/toggle/Toggle'
import { InformationTab } from '../InformationTab'
import { UsersTab } from '../UsersTab'
import { RIGHT_MODALS } from '../../../../../../../../../helpers/contants'
import { RightModalContext } from '../../../../../../../../Modals'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../../../shared/api/errorHandler'
import styles from '../style.module.scss'
import { SETTINGS_ENUM } from '../../../../../../../../../routes/settings'
import { useSearchParams } from 'react-router-dom'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'
import { useGetDepartmentByIdQuery } from '../../../../../../../core/http/DepartmentsManagementApi'

interface IProps {
  id: string
  depId?: string
  isEditMode: boolean
  activeTab: string
}

const Operations = ({ depId, id, isEditMode, activeTab }: IProps) => {
  const dispatch = useAppDispatch()
  const { onOpen } = useContext(RightModalContext)
  const navigate = useNavigate()

  const { data, isFetching } = useGetPositionByIdQuery(id as string, { skip: !id })
  const { data: department } = useGetDepartmentByIdQuery(depId as string, {
    skip: !depId,
  })

  const [patchPos, patchPosResp] = usePatchUserPositionMutation()

  useNotification(NOTIFICATION_TYPES.success, patchPosResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, patchPosResp.isError, patchPosResp.error as ErrorNode)
  const onAddButtonClick = () => {
    onOpen(RIGHT_MODALS.SETTINGS.POSITIONS_USERS_LIST, {
      title: 'Add Users',
      id,
    })
  }

  const onEditButtonClick = () => {
    return navigate(
      depId
        ? `${SETTINGS_ENUM.USERS_MANAGEMENT}/departments/view/${depId}/positions/edit/${id}`
        : `${SETTINGS_ENUM.USERS_MANAGEMENT}/positions/edit/${id}`
    )
  }

  useEffect(() => {
    if (data) {
      dispatch(setBreadcrumb(data))
    }
  }, [data, dispatch])

  useEffect(() => {
    if (department) {
      dispatch(
        setBreadcrumb({ ...department, key: SETTINGS_ENUM.USERS_MANAGEMENT_DEPARTMENT_VIEW })
      )
    }
  }, [data, department, dispatch])

  const shouldDisplayEdit = !isEditMode && activeTab !== POSITION_TABS_ENUM.USERS

  return (
    <Space size={10}>
      {shouldDisplayEdit && (
        <IconButton color='orange' icon={<EditIcon />} onClick={onEditButtonClick} />
      )}
      <IconButton icon={<AddIcon />} color='orange' type='primary' onClick={onAddButtonClick} />
      <Toggle
        text='Status'
        inWrapper
        defaultChecked={data?.status}
        checked={data?.status}
        onChange={(status) => {
          patchPos({ id: id, body: { status } })
        }}
        loading={isFetching || patchPosResp.isLoading}
      />
    </Space>
  )
}

export const PositionDetailViewTabs = () => {
  const { posId, mode, depId } = useParams<{
    depId?: string
    posId: string
    mode: 'view' | 'edit'
  }>()

  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: POSITION_TABS_ENUM.INFORMATION,
  })

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  const items = [
    {
      label: POSITION_TABS_ENUM.INFORMATION,
      key: POSITION_TABS_ENUM.INFORMATION,
      children: <InformationTab />,
    },
    {
      label: POSITION_TABS_ENUM.USERS,
      key: POSITION_TABS_ENUM.USERS,
      children: <UsersTab />,
    },
  ]

  const isEditMode = mode === 'edit'

  return (
    <Tabs
      items={items}
      destroyInactiveTabPane
      activeKey={activeTab}
      tabBarExtraContent={
        <Operations
          id={posId as string}
          depId={depId}
          isEditMode={isEditMode}
          activeTab={activeTab}
        />
      }
      className={styles.tabs}
      onChange={onTabChange}
    />
  )
}
