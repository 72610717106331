import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { AddIcon, EmptyBox } from '../../assets/svg'
import styles from './styles.module.scss'
import { ReactElement } from 'react'

interface IProps {
  text?: string
  btnText?: string
  onAddButtonClick?: () => void
  icon?: ReactElement
}

export const NotFoundPage = ({
  text = 'No data available',
  onAddButtonClick,
  btnText = 'Create New',
  icon = <AddIcon />,
}: IProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <EmptyBox />
        <div className={styles.text}>{text}</div>
        {onAddButtonClick && (
          <IconButton color='orange' icon={icon} onClick={onAddButtonClick} type='primary'>
            {btnText}
          </IconButton>
        )}
      </div>
    </div>
  )
}
