import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from 'src/shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { ITableConf } from 'src/shared/models/ITableConf'
import {
  IInternalChat,
  IInternalChatAttachment,
  IInternalChatInfo,
  IInternalChatLink,
  IInternalChatMessageFile,
  ISearchTransaction,
  ITaggingUsers,
} from '../../models'

export const internalChatsApi = createApi({
  reducerPath: 'internalChatsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'IInternalChat',
    'IInternalChatInfo',
    'ITaggingUsers',
    'ISearchTransaction',
    'IInternalChatAttachment',
    'IInternalChatLink',
  ],
  endpoints: (builder) => ({
    getInternalChats: builder.query<ITableResponse<IInternalChat>, ITableConf>({
      query: (params) => ({
        url: 'internal-chats',
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: () => ['IInternalChat'],
    }),

    getInternalChatInfo: builder.query<IInternalChatInfo, number>({
      query: (id: number) => ({
        url: `internal-chats/${id}`,
      }),
      providesTags: () => ['IInternalChatInfo'],
    }),

    getInternalChatLinkToDownloadFile: builder.query<{ link: string }, number | string>({
      query: (id: number) => ({
        url: `/internal-chat-message-files/${id}`,
      }),
    }),

    getInternalChatTaggingUsers: builder.query<ITableResponse<ITaggingUsers>, ITableConf>({
      query: (params) => ({
        url: `internal-chats/users-for-tagging`,
        params,
      }),
      keepUnusedDataFor: 2,
      providesTags: () => ['ITaggingUsers'],
    }),

    getInternalChatTransactions: builder.query<ITableResponse<ISearchTransaction>, ITableConf>({
      query: (params) => ({
        url: `search`,
        params,
      }),
      providesTags: () => ['ISearchTransaction'],
    }),

    pinChat: builder.mutation<
      ITableResponse<IInternalChat>,
      { isPinned: boolean; toUserId: number; lastReadingAt?: Date }
    >({
      query: (body) => ({
        url: `internal-chat-message-readings`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['IInternalChat'],
    }),

    getInternalChatAttachments: builder.query<ITableResponse<IInternalChatMessageFile>, ITableConf>(
      {
        query: ({ id, ...rest }) => ({
          url: `internal-chat-message-files/get-files/${id}`,
          params: rest,
        }),
        providesTags: () => ['IInternalChatAttachment'],
      }
    ),

    getInternalChatLinks: builder.query<ITableResponse<IInternalChatLink>, ITableConf>({
      query: ({ id, ...rest }) => ({
        url: `internal-chat-message-links/${id}`,
        params: rest,
      }),
      providesTags: () => ['IInternalChatLink'],
    }),
  }),
})

export const {
  useGetInternalChatsQuery,
  useGetInternalChatInfoQuery,
  usePinChatMutation,
  useGetInternalChatLinkToDownloadFileQuery,
  useGetInternalChatAttachmentsQuery,
  useGetInternalChatLinksQuery,
} = internalChatsApi
