import { memo } from 'react'
import classNames from 'classnames'
import styles from '../../styles.module.scss'
import { CrossIcon } from 'src/assets/svg'
import { AgentDocumentStatus, AgentDocumentStatusEnum } from '../../../../AgentManagement.d'

interface IProps {
  title: string
  status: AgentDocumentStatusEnum
  view: boolean
  onRemove: () => void
}

const VerificationBlockHeader = ({ title, view, status, onRemove }: IProps) => {
  return (
    <div className={styles.verificationBlockHeader}>
      <div className={styles.verificationBlockTitle}>{title}</div>
      <div className={styles.verifiedStatusContainer}>
        <div
          className={classNames({
            [styles.isVerified]: status === AgentDocumentStatusEnum.VERIFIED,
            [styles.isNotVerifiedDocument]: status === AgentDocumentStatusEnum.UNVERIFIED,
            [styles.isInProgress]: status === AgentDocumentStatusEnum.IN_PROGRESS,
            [styles.isExpired]: status === AgentDocumentStatusEnum.EXPIRED,
          })}
        />
        <div
          className={classNames(styles.verifiedStatusTitle, {
            [styles.isVerifiedText]: status === AgentDocumentStatusEnum.VERIFIED,
            [styles.isNotVerifiedDocumentText]: status === AgentDocumentStatusEnum.UNVERIFIED,
            [styles.isInProgressText]: status === AgentDocumentStatusEnum.IN_PROGRESS,
            [styles.isExpiredText]: status === AgentDocumentStatusEnum.EXPIRED,
          })}
        >
          {AgentDocumentStatus[status]}
        </div>
        {!view && (
          <div className={styles.verificationBtnRemove} onClick={onRemove}>
            <CrossIcon />
          </div>
        )}
      </div>
    </div>
  )
}
export default memo(VerificationBlockHeader)
