import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { IWallet, IWalletForm, IWalletList } from '../../models/IWallet'

export const walletApi = createApi({
  reducerPath: 'wallet',
  baseQuery: baseQueryWithReauth,
  refetchOnFocus: true,
  tagTypes: ['IWalletList', 'IWallet'],
  endpoints: (builder) => ({
    getWallets: builder.query<ITableResponse<IWalletList>, ITableConf>({
      query: (params) => ({
        url: 'wallet',
        params,
      }),
      providesTags: () => ['IWalletList'],
    }),
    getWallet: builder.query<IWallet, string>({
      query: (id) => ({
        url: `wallet/${id}`,
      }),
      providesTags: () => ['IWallet'],
    }),

    createTransferFund: builder.mutation<null, IWalletForm>({
      query: (body) => ({
        url: 'wallet-transfer-fund',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IWalletList'],
    }),

    addAttachmentsTF: builder.mutation<null, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: `wallet-transfer-fund/${id}/attachments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IWallet'],
    }),

    getTransferFundById: builder.query<IWallet, string>({
      query: (id) => ({
        url: `wallet-transfer-fund/${id}`,
      }),
      providesTags: () => ['IWallet'],
    }),
  }),
})

export const {
  useGetWalletsQuery,
  useCreateTransferFundMutation,
  useGetTransferFundByIdQuery,
  useAddAttachmentsTFMutation,
} = walletApi
