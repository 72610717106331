import { Wrapper } from '../../../../../../../shared/components/wrapper/Wrapper'
import { Button } from '../../../../../../../shared/components/button/Button'
import styles from '../../styles.module.scss'
import { useContext } from 'react'
import { RightModalContext } from '../../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../../helpers/contants'
import { TargetPlanListItem } from './TargetPlanListItem'
import { useParams } from 'react-router-dom'
import { IPurchasePlanLog } from '../../../../../models/IInventory'

export const TargetPlanBlock = ({ logs }: { logs?: Array<IPurchasePlanLog> }) => {
  const { vendorId, inventoryId } = useParams()
  const { onOpen } = useContext(RightModalContext)

  return (
    <Wrapper title='Target Plan update'>
      <Button
        block
        color='blue'
        type='primary'
        onClick={() =>
          onOpen(RIGHT_MODALS.FINANCE.PURCHASE_PLAN, {
            title: 'Purchase Plan',
            vendorId,
            inventoryId,
          })
        }
      >
        Update
      </Button>
      <div className={styles.targetPlanList}>
        {logs?.map((log) => (
          <TargetPlanListItem key={log.id} log={log} />
        ))}
      </div>
    </Wrapper>
  )
}
