import styles from '../PositionsDetailView/style.module.scss'
import { useNavigate } from 'react-router'
import { Form, Row, Space, Spin } from 'antd'
import { useGetListOfCurrenciesQuery } from '../../../../../../../Finance/core/http/BankAccountApi'
import React, { useContext, useEffect, useMemo } from 'react'
import { formatDateWithTime } from '../../../../../../../../helpers/utils'
import { DetailInfo } from '../PositionsDetailView/DetailInfo'
import { Rates } from '../PositionsDetailView/Rates'
import { ISelectOption } from '../../../../../../../../shared/models/ISelectOption'
import { Button } from '../../../../../../../../shared/components/button/Button'
import {
  IPosition,
  IPositionCreate,
  POSITION_RATE_TYPE_ENUM,
} from '../../../../../../models/IPosition'
import { useCreatePositionMutation } from '../../../../../../core/http/PositionsApi'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../../shared/api/errorHandler'
import { AddIcon } from '../../../../../../../../assets/svg'
import { RIGHT_MODALS } from '../../../../../../../../helpers/contants'
import { IconButton } from '../../../../../../../../shared/components/iconButton/IconButton'
import { RightModalContext } from '../../../../../../../Modals'
import {
  addUser,
  clearUsersSliceState,
  deleteUserById,
  selectUsersManagementState,
} from '../../../../../../core/store/UsersManagerSlice'
import { IUser } from '../../../../../../models/IUser'
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux'
import { UserList } from '../PositionsDetailView/UsersListModal/UserList'
import { StepRegistration } from '../../../../../../../../shared/components/stepRegistration'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

export const PositionsNew = () => {
  const dispatch = useAppDispatch()
  const { onOpen } = useContext(RightModalContext)
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const { data: profile } = useGetProfileQuery()

  const { data: currencies, isFetching: isCurrencyFetching } = useGetListOfCurrenciesQuery()
  const [crPos, crPosResp] = useCreatePositionMutation()

  useNotification(NOTIFICATION_TYPES.success, crPosResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, crPosResp.isError, crPosResp.error as ErrorNode)

  const { usersList } = useAppSelector(selectUsersManagementState)
  const currencyList = useMemo(
    () =>
      currencies?.items.map((currency) => ({
        label: currency.name,
        value: currency.id,
      })),
    [currencies?.items]
  )

  const isViewMode = false

  const onFinish = (values: IPosition) => {
    const userIds = usersList.map((user) => user.id)

    const data = {
      name: values?.name,
      rateCurrencyId: values?.rateCurrency.id,
      status: true,
      supervisorId: values?.supervisor?.id,
      holidayRate: values?.holidayRate,
      overtimeRate: values?.overtimeRate,
      hourlyRate: values?.hourlyRate,
      userIds,
    } as IPositionCreate

    crPos(data)
  }

  const onCancelClick = () => {
    return navigate('/settings/users-management')
  }

  const onDeleteUser = (id: number) => {
    dispatch(deleteUserById(id))
  }

  const onAddUser = (user: IUser) => {
    dispatch(addUser(user))
  }

  useEffect(() => {
    if (crPosResp?.data) {
      return navigate(`/settings/users-management/positions/view/${crPosResp?.data?.id}`)
    }
    // eslint-disable-next-line
  }, [crPosResp?.data])

  useEffect(() => {
    return () => {
      dispatch(clearUsersSliceState())
    }
  }, [dispatch])

  const content = (
    <div className={styles.layout}>
      <Form
        form={form}
        layout='vertical'
        className={styles.form}
        initialValues={{
          createdAt: formatDateWithTime(new Date(), profile?.calendar),
          rates: [
            { amount: null, type: POSITION_RATE_TYPE_ENUM.HOURLY },
            { amount: null, type: POSITION_RATE_TYPE_ENUM.OVERTIME },
            { amount: null, type: POSITION_RATE_TYPE_ENUM.HOLIDAY },
          ],
        }}
        onFinish={onFinish}
      >
        <StepRegistration stepNumber={1} stepText='Detail Info' />
        <DetailInfo view={isViewMode} isCreateMode />
        <StepRegistration stepNumber={2} stepText='Rates' mT='32px' />
        <Rates view={isViewMode} currencyList={currencyList as Array<ISelectOption>} />
        <StepRegistration stepNumber={3} stepText='Users' mT='32px' mB='31px' />
        <UserList
          users={usersList}
          onPressSelect={(user) => onAddUser(user)}
          onDeleteUser={(id) => onDeleteUser(id)}
          onPositionCreate
        />
        <IconButton
          icon={<AddIcon />}
          color='orange'
          iconPos='right'
          size='large'
          block
          onClick={() => {
            onOpen(RIGHT_MODALS.SETTINGS.POSITIONS_USERS_LIST, {
              title: 'Add User',
              onDeleteUser,
              onAddUser,
            })
          }}
        >
          Add Users
        </IconButton>
      </Form>
    </div>
  )

  return (
    <div className='fullVH'>
      {isCurrencyFetching || crPosResp.isLoading ? <Spin>{content}</Spin> : content}
      <Row className={styles.footer} justify='end'>
        <Space size={10}>
          <Button color='blue' size='middle' onClick={onCancelClick} disabled={crPosResp.isLoading}>
            Cancel
          </Button>
          <Button
            htmlType='submit'
            size='middle'
            type='primary'
            onClick={form.submit}
            disabled={crPosResp.isLoading}
          >
            Create Position
          </Button>
        </Space>
      </Row>
    </div>
  )
}
