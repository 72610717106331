import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import { MessageIcon } from '../../../assets/svg'

import styles from '../header/Header.module.scss'
import { Badge } from '../badge/Badge'
import { useGetUnreadMessagesCountQuery } from '../../../features/Profile/core/http/Notification'
import { chatsSocketConnection } from '../../sockets'
import {
  IUnreadMessagesCount,
  UNREAD_POPOVER_TABS_ENUM,
} from '../../../features/Profile/core/models'
import { useGetProfileQuery } from '../../../features/Profile/core/http/Profile'
import { Divider, Segmented, Skeleton } from 'antd'
import classNames from 'classnames'
import { useOnClickOutside } from '../../hooks/useClickOutside'
import {
  externalChatApi,
  useGetUnreadExternalMessagesListQuery,
} from '../../../features/Support/core/http/ExternalChatApi'
import {
  EXTERNAL_CHAT_LIST_TABS,
  IExternalUnreadMessage,
} from '../../../features/Support/models/IExternalChat'
import { isEmpty, unionBy } from 'lodash'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import { MessageListItem } from './MessageListItem'
import {
  internalMessagingApi,
  useGetUnreadInternalMessagesListQuery,
} from '../../../features/InternalChat/core/http/InternalMessagingApi'
import { IUnreadInternalMessage } from '../../../features/InternalChat/models'
import { RightModalContext } from '../../../features/Modals'
import { RIGHT_MODALS } from '../../../helpers/contants'
import { useAppDispatch, useAppSelector } from '../../../redux'
import {
  setCurrentModalView,
  setOpenedChatId,
} from '../../../features/InternalChat/core/store/Chats'
import { modalViewTypeEnum } from '../../../features/InternalChat/core/models'
import { useNavigate } from 'react-router'
import { SUPPORT_ENUM } from '../../../routes/support'
import {
  clearExternalChatDates,
  clearExternalChatMessages,
  setCurrentExternalUserId,
} from '../../../features/Support/core/store/ExternalChatMessagingSlice'
import {
  clearExternalChatList,
  selectExternalChatChatsSlice,
  setActiveTab,
  setCurrentExternalChatId,
} from '../../../features/Support/core/store/ExternalChatChatsListSlice'
import { addMessagesUnreadCounter } from '../../../features/Profile/core/store/Notifications'

const initialTableConf = {
  page: 1,
  limit: 5,
}

const MessagesCounter = () => {
  const navigate = useNavigate()
  const { onOpen, onClose } = useContext(RightModalContext)
  const dispatch = useAppDispatch()
  const [tableConf, setTableConf] = useState(initialTableConf)
  const [mappedExternalMessages, setMappedExternalMessages] = useState<
    Array<IExternalUnreadMessage>
  >([])
  const [mappedInternalMessages, setMappedInternalMessages] = useState<
    Array<IUnreadInternalMessage>
  >([])
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isCounterUpdated, setIsCounterUpdated] = useState(false)
  const [messagesCount, setMessagesCount] = useState<IUnreadMessagesCount>(() => ({
    totalCount: 0,
    externalCount: 0,
    internalCount: 0,
  }))
  const [activeCat, setActiveCat] = useState<UNREAD_POPOVER_TABS_ENUM>(
    UNREAD_POPOVER_TABS_ENUM.EXTERNAL
  )
  const { selectedChatId } = useAppSelector(selectExternalChatChatsSlice)
  const popoverRef = useRef(null)

  const { data } = useGetUnreadMessagesCountQuery()
  const { data: profile } = useGetProfileQuery()
  const { data: externalMessages, isFetching: isFetchingExternal } =
    useGetUnreadExternalMessagesListQuery(tableConf, {
      skip: activeCat === UNREAD_POPOVER_TABS_ENUM.INTERNAL || !isPopoverOpen,
    })
  const { data: internalMessages, isFetching: isFetchingInternal } =
    useGetUnreadInternalMessagesListQuery(tableConf, {
      skip: activeCat === UNREAD_POPOVER_TABS_ENUM.EXTERNAL || !isPopoverOpen,
    })

  const onTabChange = (val: UNREAD_POPOVER_TABS_ENUM) => {
    setActiveCat(val)
    setMappedExternalMessages([])
    setMappedInternalMessages([])
    setTableConf(initialTableConf)
  }

  useOnClickOutside(popoverRef, () => {
    setIsPopoverOpen(false)
    setMappedExternalMessages([])
    setMappedInternalMessages([])
    setTableConf(initialTableConf)
  })

  useEffect(() => {
    if (!profile) return
    const socket = chatsSocketConnection.getSocket()

    socket?.on('update-unread-messages-counter', (data: IUnreadMessagesCount) => {
      setIsCounterUpdated(true)
      setMessagesCount(data)
      dispatch(addMessagesUnreadCounter(data.internalCount))

      if (!isPopoverOpen) return
      if (activeCat === UNREAD_POPOVER_TABS_ENUM.INTERNAL) {
        setTableConf(() => initialTableConf)
        dispatch(internalMessagingApi.util.invalidateTags(['UnreadMessages']))
      }
      if (activeCat === UNREAD_POPOVER_TABS_ENUM.EXTERNAL) {
        setTableConf(() => initialTableConf)
        dispatch(externalChatApi.util.invalidateTags(['UnreadMessages']))
      }
    })

    return () => {
      socket?.removeListener('update-unread-messages-counter')
    }
  }, [activeCat, dispatch, isPopoverOpen, profile])

  useEffect(() => {
    if (!data || isCounterUpdated) return
    setMessagesCount(data)
  }, [data, isCounterUpdated])

  useEffect(() => {
    if (activeCat === UNREAD_POPOVER_TABS_ENUM.INTERNAL || isEmpty(externalMessages)) return

    if (tableConf.page === 1) {
      setMappedExternalMessages(externalMessages.items)
    } else {
      setMappedExternalMessages((p) => unionBy([...p, ...externalMessages.items], 'id'))
    }
    // eslint-disable-next-line
  }, [activeCat, externalMessages])

  useEffect(() => {
    if (activeCat === UNREAD_POPOVER_TABS_ENUM.EXTERNAL || isEmpty(internalMessages)) return

    if (tableConf.page === 1) {
      setMappedInternalMessages(internalMessages?.items)
    } else {
      setMappedInternalMessages((p) => unionBy([...p, ...internalMessages?.items], 'id'))
    }
    // eslint-disable-next-line
  }, [activeCat, internalMessages])

  const loadMoreData = () => {
    setTableConf((pre) => ({ ...pre, page: pre.page! + 1 }))
  }

  const onChatItemClick = (type: UNREAD_POPOVER_TABS_ENUM) => (chatId: number) => {
    if (type === UNREAD_POPOVER_TABS_ENUM.INTERNAL) {
      onOpen(RIGHT_MODALS.SETTINGS.INTERNAL_CHAT, {
        title: 'internal chat',
        chatId,
        onCloseRedirect: () => {
          onClose()
          dispatch(setCurrentModalView(modalViewTypeEnum.chats))
          dispatch(setOpenedChatId(null))
        },
      })
    }
    if (type === UNREAD_POPOVER_TABS_ENUM.EXTERNAL && Number(selectedChatId) !== Number(chatId)) {
      dispatch(externalChatApi.util.resetApiState())
      dispatch(setActiveTab(EXTERNAL_CHAT_LIST_TABS.MY))
      dispatch(clearExternalChatList())
      dispatch(clearExternalChatDates())
      dispatch(clearExternalChatMessages())
      dispatch(setCurrentExternalUserId(profile?.id as number))

      navigate(SUPPORT_ENUM.SUPPORT_CHAT_ROOM)
      dispatch(setCurrentExternalChatId(chatId))
    }
  }

  return (
    <div
      className={classNames(styles['wrapper-message-icon'], styles.messagesCounter)}
      onClick={() => setIsPopoverOpen(true)}
      ref={popoverRef}
    >
      <MessageIcon />
      <div className={styles.counter}>
        <Badge color='orange' showZero count={messagesCount?.totalCount} />
      </div>

      {isPopoverOpen && (
        <div className={styles.messagesListWrapper}>
          <Segmented
            block
            value={activeCat}
            onChange={(val) => onTabChange(val as UNREAD_POPOVER_TABS_ENUM)}
            options={[
              {
                label: `External ${messagesCount?.externalCount}`,
                value: UNREAD_POPOVER_TABS_ENUM.EXTERNAL,
              },
              {
                label: `Internal ${messagesCount?.internalCount}`,
                value: UNREAD_POPOVER_TABS_ENUM.INTERNAL,
              },
            ]}
          />
          <div
            id='scrollable-unread-messages'
            style={{
              height: 260,
              overflow: 'auto',
            }}
          >
            {activeCat === UNREAD_POPOVER_TABS_ENUM.EXTERNAL && (
              <InfiniteScroll
                dataLength={mappedExternalMessages.length}
                next={loadMoreData}
                hasMore={mappedExternalMessages.length < messagesCount.externalCount}
                loader={isFetchingExternal && <Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                scrollableTarget='scrollable-unread-messages'
              >
                <div className={styles.messagesList}>
                  {mappedExternalMessages.map((message, i) => {
                    const isChatDayTheSame =
                      mappedExternalMessages[i + 1] || mappedExternalMessages.at(-1)
                        ? moment(message?.createdAt).isSame(
                            moment(mappedExternalMessages[i + 1]?.createdAt),
                            'day'
                          )
                        : true

                    return (
                      <MessageListItem
                        key={message?.createdAt}
                        isChatDayTheSame={isChatDayTheSame}
                        message={message}
                        onChatItemClick={onChatItemClick(UNREAD_POPOVER_TABS_ENUM.EXTERNAL)}
                      />
                    )
                  })}
                </div>
              </InfiniteScroll>
            )}

            {activeCat === UNREAD_POPOVER_TABS_ENUM.INTERNAL && (
              <InfiniteScroll
                dataLength={mappedInternalMessages.length}
                next={loadMoreData}
                hasMore={mappedInternalMessages.length < messagesCount.internalCount}
                loader={isFetchingInternal && <Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                scrollableTarget='scrollable-unread-messages'
              >
                <div className={styles.messagesList}>
                  {mappedInternalMessages.map((message) => (
                    <MessageListItem
                      key={message?.id}
                      internalMessage={message}
                      isChatDayTheSame={true}
                      onChatItemClick={onChatItemClick(UNREAD_POPOVER_TABS_ENUM.INTERNAL)}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(MessagesCounter)
