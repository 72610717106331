import React, { ChangeEvent, memo } from 'react'
import classNames from 'classnames'
import { AttachFileIcon } from 'src/assets/svg'
import styles from './styles.module.scss'

interface IProps {
  onAttachFiles: (e: ChangeEvent<HTMLInputElement>) => void
  key: string
}

const AttachFile = ({ onAttachFiles }: IProps) => {
  return (
    <div className={classNames(styles.messageAction, styles.messageActionAttach)}>
      <label className={styles.messageActionAttachLabel}>
        <AttachFileIcon />
        <input type='file' multiple onChange={onAttachFiles} />
      </label>
    </div>
  )
}
export default memo(AttachFile)
