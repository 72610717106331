import React from 'react'
import { Col, Spin } from 'antd'
import { InfiniteScrollComponent } from '../../../../../../../../../../shared/components/infiniteScoll/InfiniteScroll'
import useSearch from '../../../../../../../../helpers/useSearch'
import { TextFieldSearch } from '../../../../../../../../../../shared/components/textFieldSearch/TextFieldSearch'
import { IconButton } from '../../../../../../../../../../shared/components/iconButton/IconButton'
import { InfoMessage } from '../../../../../../../VendorManagement/VendorManagementContactListModal/Components/InfoMessage'
import { AddIcon } from '../../../../../../../../../../assets/svg'
import styles from '../styles.module.scss'
import { ContactListModalHeader } from '../../../../../../../VendorManagement/VendorManagementContactListModal/Components/ContactListModalHeader'
import { RolesAndPermissionsManagementUsersList } from '../RolesAndPermissionsManagementUsersList'
import useUsers from '../../../../../../hooks/useUsers'
import { isNumber } from 'lodash'

export const RolesAndPermissionsManagementUsersListModal = () => {
  const {
    usersList,
    filterValues,
    filterFields,
    totalCount,
    setTableConf,
    setIsChangeSearch,
    onPressCreateNewUser,
    loadMoreData,
    setActiveFilters,
    onAddUser,
    onDeleteUser,
  } = useUsers()

  const { handleSearchChange } = useSearch(setTableConf)

  const searchComponent = (
    <TextFieldSearch
      className={styles.search}
      onChange={(e) => {
        setIsChangeSearch(true)
        handleSearchChange(e)
      }}
    />
  )

  const addButtonComponent = (
    <Col>
      <IconButton
        className={styles.addBtn}
        icon={<AddIcon />}
        type='primary'
        color='orange'
        size='large'
        onClick={onPressCreateNewUser}
      />
    </Col>
  )

  const content = (
    <InfiniteScrollComponent
      loadMoreData={loadMoreData}
      dataLength={usersList.length}
      totalCount={totalCount as number}
      loaderStyles={{ width: 'calc(100% - 24px * 2)', marginLeft: '24px' }}
    >
      <div className={styles.layout}>
        <InfoMessage text='Users' />
        <ContactListModalHeader
          classNameFilterPopup={styles.filterPopup}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          data={filterFields}
          searchComponent={searchComponent}
          addButtonComponent={addButtonComponent}
          classNamesFilterWrapperIcon={styles.filterWrraperIcon}
        />
        <RolesAndPermissionsManagementUsersList
          onDeleteUser={onDeleteUser}
          onAddUser={onAddUser}
          users={usersList}
        />
      </div>
    </InfiniteScrollComponent>
  )

  return !isNumber(totalCount) ? <Spin>{content}</Spin> : content
}
