import { Link, useNavigate } from 'react-router-dom'
import { useGetQueryParams } from '../../../../../../shared/hooks/table/useGetQueryParams'
import React, { useCallback, useEffect, useState } from 'react'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { ITableConf } from '../../../../../../shared/models/ITableConf'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'
import { isEmpty } from 'lodash'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ErrorNode, showConfirmMessage } from '../../../../../../shared/api/errorHandler'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../shared/components/popup/Popup'
import { FINANCE_ENUM } from '../../../../../../routes/finance'
import { ColumnsType } from 'antd/es/table'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import emptyAvatarImage from '../../../../../../assets/img/BankLogoPlaceholder.png'
import {
  ClockIcon,
  EmailFilledIcon,
  PhoneDarkBlueIcon,
  TableActionIcon,
} from '../../../../../../assets/svg'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import useTable from '../../../../../../shared/hooks/table/useTable'
import { TableHeader } from '../TableHeader'
import { Space, Table } from 'antd'
import styles from '../BankAccountsTable/styles.module.scss'
import { IBankBranch, IBankTable } from '../../../../models/IBank'
import {
  useDeleteBankByIdMutation,
  useGetBankFiltersListQuery,
  useGetBankListQuery,
  useLazyGetBankByIdQuery,
} from '../../../../core/http/BankApi'
import { formatPhoneNumber } from '../../../../../salesNetwork/helpers'
import { extractLinkDomain, moment, tableExpandedIcon } from '../../../../../../helpers/utils'
import { ExpandableConfig } from 'rc-table/lib/interface'

export const BanksTable = () => {
  const navigate = useNavigate()
  const { queryParams, queryFields } = useGetQueryParams()

  const [expandedRowKeys, setExpandedRowKeys] = useState<Array<React.Key>>([])
  const [mappedBanks, setMappedBanks] = useState<Array<IBankTable>>([])
  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const [deleteBank, delBankResp] = useDeleteBankByIdMutation()
  const { data: filtersList } = useGetBankFiltersListQuery()
  const { data, isFetching } = useGetBankListQuery(
    {
      ...tableConf,
      ...filterValues,
    },
    { skip: isEmpty(tableConf) }
  )
  const [getBank, getBankResp] = useLazyGetBankByIdQuery()

  useNotification(NOTIFICATION_TYPES.success, delBankResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, delBankResp.isError, delBankResp.error as ErrorNode)

  const tableActionsPopup = useCallback(
    (id: IBankTable['id']): IPopupListItems[] => [
      {
        text: 'View',
        shouldDisplay: true,
        onClick: () => navigate(`${FINANCE_ENUM.BANK_SETTINGS}/banks/view/${id}`),
      },
      {
        text: 'Edit',
        shouldDisplay: true,
        onClick: () => navigate(`${FINANCE_ENUM.BANK_SETTINGS}/banks/edit/${id}`),
      },
      {
        text: 'Delete',
        shouldDisplay: true,
        onClick: () => showConfirmMessage('This bank will be deleted', () => deleteBank(id)),
        color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        withDivider: true,
      },
    ],
    [deleteBank, navigate]
  )

  const columns: ColumnsType<IBankTable> = [
    {
      title: '',
      dataIndex: '',
      key: 'expand',
      width: '1%',
    },
    {
      title: 'Bank Name',
      dataIndex: 'name',
      width: '20%',
      render: (companyName: IBankTable['name'], row) => (
        <RecursivelyReplaceCharacters>
          <Link to={`${FINANCE_ENUM.BANK_SETTINGS}/banks/view/${row.id}`} className='table-avatar'>
            <img alt='bank logo' src={!isPrivateMode && row.logo ? row.logo : emptyAvatarImage} />
            <div>{companyName}</div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
      sorter: true,
    },
    {
      title: 'Bank HQ Address',
      dataIndex: 'hqAddress',
      width: '30%',
      render: (address) => <RecursivelyReplaceCharacters>{address}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Bank Contact Info',
      dataIndex: 'phoneNumber',
      width: '20%',
      render: (phone, row) => (
        <RecursivelyReplaceCharacters>
          <div className='table-contact-info'>
            <div className='table-contact-info__row mobile'>
              {phone && (
                <>
                  <PhoneDarkBlueIcon />
                  <span>{formatPhoneNumber(phone)}</span>
                </>
              )}
            </div>
            <div className='table-contact-info__row'>
              {row.email && (
                <>
                  <EmailFilledIcon />
                  <span>{row.email}</span>
                </>
              )}
            </div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Bank Website',
      dataIndex: 'website',
      width: '15%',
      render: (link: string) =>
        link && (
          <a
            className='table-link'
            href={`https://${extractLinkDomain(link)}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <RecursivelyReplaceCharacters>{extractLinkDomain(link)}</RecursivelyReplaceCharacters>
          </a>
        ),
    },
    {
      title: 'Bank Email',
      dataIndex: 'email',
      width: '20%',
      render: (email) => <RecursivelyReplaceCharacters>{email}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Swift',
      dataIndex: 'swift',
      width: '10%',
      render: (swift) => <RecursivelyReplaceCharacters>{swift}</RecursivelyReplaceCharacters>,
    },
    {
      title: '',
      dataIndex: 'edit',
      width: '1%',
      key: 'edit',
      render: (_, row) =>
        !Object.hasOwn(row, 'closingHours') && (
          <Popup data={tableActionsPopup(row.id)}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        ),
    },
  ]

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IBankTable>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
    setExpandedRowKeys,
  })

  const onExpand: ExpandableConfig<IBankTable>['onExpand'] = async (expanded, record) => {
    if (!expanded) return

    const mappedRec = { ...record }
    const data = await getBank(record.id)

    setMappedBanks((prevState) =>
      prevState.map((bank) => {
        if (bank.id === mappedRec.id) {
          return { ...mappedRec, branches: data.data?.bankBranches }
        }
        return bank
      })
    )
  }

  const expandedRowRender = (record: IBankTable) => {
    const nestedColumns: ColumnsType<IBankBranch> = [
      {
        title: 'Bank Name',
        dataIndex: 'name',
        width: '20%',
        render: (name) => <RecursivelyReplaceCharacters>{name}</RecursivelyReplaceCharacters>,
      },
      {
        title: 'Bank-branch Address',
        dataIndex: 'address',
        width: '20%',
        render: (address) => <RecursivelyReplaceCharacters>{address}</RecursivelyReplaceCharacters>,
      },
      {
        title: 'Bank Contact Info',
        dataIndex: 'phoneNumber',
        width: '20%',
        render: (phone, row) => (
          <RecursivelyReplaceCharacters>
            <div className='table-contact-info'>
              <div className='table-contact-info__row mobile'>
                {phone && (
                  <>
                    <PhoneDarkBlueIcon />
                    <span>{formatPhoneNumber(phone)}</span>
                  </>
                )}
              </div>
              <div className='table-contact-info__row'>
                {row.email && (
                  <>
                    <EmailFilledIcon />
                    <span>{row.email}</span>
                  </>
                )}
              </div>
            </div>
          </RecursivelyReplaceCharacters>
        ),
      },
      {
        title: 'Working Hours',
        dataIndex: 'openingHours',
        width: '20%',
        render: (_, row) => {
          const [openH, openM] = row.openingHours.split(' ').pop()?.split(':') || []
          const [closeH, closeM] = row.closingHours.split(' ').pop()?.split(':') || []

          const opening = moment(
            moment().set('hour', +openH).set('minute', +openM).toISOString()
          ).format('HH:mm')
          const closing = moment(
            moment().set('hour', +closeH).set('minute', +closeM).toISOString()
          ).format('HH:mm')

          return (
            <RecursivelyReplaceCharacters>
              <Space size={5} align='center'>
                <div className={styles.clockIconWrapper}>
                  <ClockIcon />
                </div>
                <div>
                  <span>{opening}</span> - <span>{closing}</span>
                </div>
              </Space>
            </RecursivelyReplaceCharacters>
          )
        },
      },
    ]

    return (
      <Table
        columns={nestedColumns}
        dataSource={record.branches}
        loading={getBankResp.isFetching}
        rowKey={(rec) => `${record.id}-${rec.id}`}
        locale={emptyTableConf}
        pagination={false}
      />
    )
  }

  useEffect(() => {
    if (!data?.items) return
    setMappedBanks(data.items)
  }, [data])

  return (
    <>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filtersList}
      />
      <Table
        locale={emptyTableConf}
        className={styles.bankAccountsTable}
        dataSource={mappedBanks}
        loading={isFetching}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{ x: 1300 }}
        expandable={{
          expandedRowKeys,
          expandedRowRender,
          onExpand,
          expandIcon: (props) => tableExpandedIcon(props),
          onExpandedRowsChange: (expandedRows) => {
            setExpandedRowKeys([...expandedRows])
          },
        }}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </>
  )
}
