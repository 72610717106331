import Modal from 'antd/lib/modal'
import { Col, Row } from 'antd'
import { Button } from '../../../../../../shared/components/button/Button'
import {
  useLazyExportETReportQuery,
  useLazyExportETReportToVendorQuery,
} from '../../../../core/ExternalTransactionApi'
import styles from './styles.module.scss'
import { ITableConf } from '../../../../../../shared/models/ITableConf'
import { saveAs } from 'file-saver'

interface IProps {
  isOpen: boolean
  handleCancel: () => void
  exportTableParams: ITableConf
}

export const ExportReportModal = ({ handleCancel, isOpen, exportTableParams }: IProps) => {
  const [exportExternal, { isLoading: isExternalLoad }] = useLazyExportETReportQuery()
  const [exportExternalToVendor, { isLoading: isVendorLoad }] = useLazyExportETReportToVendorQuery()

  const onDownloadCsv = async (isRequestToVendor: boolean) => {
    const filtersString = `${Object.values(exportTableParams)}`
    if (isRequestToVendor) {
      const exportData = await exportExternalToVendor({
        conf: { format: 'csv', ...exportTableParams },
        formatFile: 'csv',
      })

      const name = 'req-to-ven' + filtersString
      const blob = new Blob([exportData.data as BlobPart], {
        type: `text/csv; charset=utf-8`,
      })

      return saveAs(blob, `${name}.csv`)
    }

    const name = 'ext-rep' + filtersString
    const exportData = await exportExternal({
      conf: { format: 'csv', ...exportTableParams },
      formatFile: 'csv',
    })

    const blob = new Blob([exportData.data as BlobPart], {
      type: `text/csv; charset=utf-8`,
    })

    return saveAs(blob, `${name}.csv`)
  }

  const onDownloadXlsx = async (isRequestToVendor: boolean) => {
    const filtersString = `${Object.values(exportTableParams)}`
    if (isRequestToVendor) {
      const exportData = await exportExternalToVendor({
        conf: { format: 'xlsx', ...exportTableParams },
        formatFile: 'xlsx',
      })
      const fileName = 'req-to-ven' + filtersString

      const blob = new Blob([exportData.data as BlobPart], {
        type: `text/xlsx; charset=utf-8`,
      })

      return saveAs(blob, `${fileName}.xlsx`)
    }

    const exportData = await exportExternal({
      conf: { format: 'xlsx', ...exportTableParams },
      formatFile: 'xlsx',
    })

    const name = 'ext-rep' + filtersString

    const blob = new Blob([exportData.data as BlobPart], {
      type: `text/xlsx; charset=utf-8`,
    })

    return saveAs(blob, `${name}.xlsx`)
  }

  return (
    <Modal
      destroyOnClose
      open={isOpen}
      onCancel={handleCancel}
      onOk={handleCancel}
      footer={null}
      title='Export Table'
      zIndex={1055}
      className={styles.exportModal}
    >
      <Row justify='center' className={styles.exportModalHeading}>
        External Transactions Report
      </Row>
      <Row justify='space-between' style={{ marginTop: 20 }}>
        <Col>
          <Button
            color='orange'
            htmlType='button'
            onClick={() => onDownloadCsv(false)}
            disabled={isExternalLoad || isVendorLoad}
          >
            Download csv
          </Button>
        </Col>
        <Col>
          <Button
            color='orange'
            htmlType='button'
            onClick={() => onDownloadXlsx(false)}
            disabled={isExternalLoad || isVendorLoad}
          >
            Download xlsx
          </Button>
        </Col>
      </Row>

      <Row justify='center' className={styles.exportModalHeading} style={{ marginTop: 20 }}>
        Requests to vendor data
      </Row>
      <Row justify='space-between' style={{ marginTop: 20 }}>
        <Col>
          <Button
            color='orange'
            htmlType='button'
            onClick={() => onDownloadCsv(true)}
            disabled={isExternalLoad || isVendorLoad}
          >
            Download csv
          </Button>
        </Col>
        <Col>
          <Button
            color='orange'
            htmlType='button'
            onClick={() => onDownloadXlsx(true)}
            disabled={isExternalLoad || isVendorLoad}
          >
            Download xlsx
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}
