import { useContext, useEffect } from 'react'
import { RightModalContext } from '../../../../../../Modals'
import { useGetPurchaseOrderLogsQuery } from '../../../../../../Finance/core/http/PurchaseOrderApi'
import { Form, Spin } from 'antd'
import styles from './styles.module.scss'
import { formatDateWithTime } from '../../../../../../../helpers/utils'
import { OldHistoryItem } from './OldHistoryItem'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { cloneDeep } from 'lodash'

export const PurchaseOrderChangesModal = () => {
  const [form] = Form.useForm()
  const {
    props: { id },
  } = useContext(RightModalContext)

  const { data: profile } = useGetProfileQuery()
  const { data = [], isFetching } = useGetPurchaseOrderLogsQuery(id, { skip: !id })

  useEffect(() => {
    if (data.length) {
      form.setFieldValue(
        'purchaseOrderChanges',
        cloneDeep(data)
          .sort((a, b) => b.id - a.id)
          .map((change) => ({
            ...change,
            createdAt: formatDateWithTime(change?.createdAt, profile?.calendar),
          }))
      )
    }
  }, [data, form, profile?.calendar])

  const content = (
    <Form layout='vertical' form={form} className={styles.layout}>
      <Form.List name='purchaseOrderChanges'>
        {(fields) => (
          <>
            {fields.map(({ key, name }) => (
              <OldHistoryItem key={key} name={name} />
            ))}
          </>
        )}
      </Form.List>
    </Form>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
