import { Slider } from 'antd'
import { SliderRangeProps } from 'antd/es/slider'
import { Fragment, ReactNode } from 'react'

interface IProps extends SliderRangeProps {
  color?: 'blue' | 'purple'
}

const RangeWrapper = ({ children }: { children: ReactNode }) => (
  <div className='vertical-wrapper'>{children}</div>
)

export const Range = ({ color = 'blue', vertical = false, ...rest }: IProps) => {
  const Wrapper = vertical ? RangeWrapper : Fragment

  return (
    <Wrapper>
      <Slider vertical={vertical} className={color} {...rest} />
    </Wrapper>
  )
}
