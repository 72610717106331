import { IconButton } from '../iconButton/IconButton'
import { FilterIcon } from '../../../assets/svg'
import { Col, FormInstance, Row } from 'antd'
import { SelectedFilters } from './SelectedFilters'
import styles from './styles.module.scss'
import { IFilterFields } from '../../models/IFilterFields'
import classNames from 'classnames'
import { Moment } from 'moment'
import { isEmpty } from 'lodash'

export type TypeOfFilter = string | number | Array<string> | boolean | undefined | Array<Moment>

export interface ISelectedFilters {
  [key: string]: TypeOfFilter
}

interface IProps {
  form: FormInstance
  data?: IFilterFields[]
  setIsOpen: () => void
  selectedFilters: ISelectedFilters
  setSelectedFilters: (obj: ISelectedFilters) => void
  classNamesFilterWrapperIcon?: string
  sizeFilter?: 'large' | 'middle' | 'small'
  disabled?: boolean
}

export const Filter = ({
  form,
  setIsOpen,
  selectedFilters,
  setSelectedFilters,
  classNamesFilterWrapperIcon,
  sizeFilter = 'middle',
  disabled,
}: IProps) => {
  const removeSelectedFilter = (key: keyof ISelectedFilters) => {
    const objectCopy = { ...selectedFilters }
    const splitKey = key.toString().split('_')

    if (splitKey[1] === 'less') {
      const objectKey = `${splitKey[0]}_more`
      delete objectCopy[objectKey]
      form.resetFields([splitKey[0]])
    }
    if (Object.hasOwn(objectCopy, key)) {
      delete objectCopy[key]
      form.setFieldValue(key, undefined)
    }

    setSelectedFilters(objectCopy)
  }

  const clearAllFilters = () => {
    setSelectedFilters({})
    form.resetFields()
  }

  return (
    <div>
      <Row className={styles.filter__top} gutter={24} align='middle'>
        <Col>
          <div className={classNames(styles.wrrapperFilterIcon, classNamesFilterWrapperIcon)}>
            <IconButton
              color='warning'
              icon={<FilterIcon />}
              iconPos='right'
              onClick={setIsOpen}
              type='default'
              size={sizeFilter}
              disabled={disabled}
              block
            >
              Filters
            </IconButton>
          </div>
        </Col>
        <Col>
          {!isEmpty(selectedFilters) && (
            <SelectedFilters
              selectedFilters={selectedFilters}
              removeSelectedFilter={removeSelectedFilter}
              clearAllFilters={clearAllFilters}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}
