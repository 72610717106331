/* eslint-disable */
import EventEmitter from 'eventemitter3'

export enum EmitterEvents {
  EXPORT_TABLE = 'EXPORT_TABLE',
  DISABLE_GOOGLE_AUTH = 'DISABLE_GOOGLE_AUTH',
  GENERATE_PDF = 'GENERATE_PDF',
  LOADING_PDF = 'LOADING_PDF',
  LOADED_PDF = 'LOADED_PDF',
  EVENT_CREATE = 'EVENT_CREATE',
  EVENT_EDIT = 'EVENT_EDIT',
  EVENT_RESET = 'EVENT_RESET',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
}

const eventEmitter = new EventEmitter()

export const Emitter = {
  on: (event: string, fn: (value: any) => void) => eventEmitter.on(event, fn),
  once: (event: string, fn: () => void) => eventEmitter.once(event, fn),
  off: (event: string, fn?: () => void) => eventEmitter.off(event, fn),
  emit: (event: string, props?: any) => eventEmitter.emit(event, props),
}

Object.freeze(Emitter)
