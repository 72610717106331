import React from 'react'
import { Route, createRoutesFromChildren } from 'use-react-router-breadcrumbs'

import { Login } from '../features/authorization/pages/login/Login'
import { SignUpGoogleAuth } from '../features/authorization/pages/SignUpGoogleAuth'
import { NotFoundPage } from '../features/NotFoundPage'
import { PrivateRoute } from './utils'

import renderDashboardRoutes from './dashboard'
import renderSalesRoutes from './sales'
import renderFinanceRoutes from './finance'
import renderSettingsRoutes from './settings'
import renderSupportRoutes from './support'
import renderProfileRoutes from './profile'
import renderReportsRoutes from './reports'

export const childrenRoutes = (
  <>
    <Route path='/' element={<PrivateRoute />}>
      {renderDashboardRoutes()}
      {renderSalesRoutes()}
      {renderFinanceRoutes()}
      {renderSupportRoutes()}
      {renderReportsRoutes()}
      {renderSettingsRoutes()}
      {renderProfileRoutes()}
      <Route path='*' element={<NotFoundPage text='Page not found' />} />
    </Route>
    <Route path='/sign-up/google-authenticator' element={<SignUpGoogleAuth />} />
    <Route path='/sign-in' element={<Login />} />
  </>
)

export const appRoutesList = createRoutesFromChildren(childrenRoutes)
