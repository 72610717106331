import { useState, useEffect } from 'react'

export function useLocalStorageGetByKey<T>(key: string): T | undefined {
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    if (typeof window === 'undefined') {
      return undefined
    }
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : undefined
    } catch (error) {
      return undefined
    }
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleStorageChange = () => {
        try {
          const item = window.localStorage.getItem(key)
          setStoredValue(item ? JSON.parse(item) : undefined)
        } catch (error) {
          // eslint-disable-next-line
          console.error(error)
        }
      }

      window.addEventListener('storage', handleStorageChange)

      return () => {
        window.removeEventListener('storage', handleStorageChange)
      }
    }
  }, [key])

  return storedValue
}
