import { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { Tabs } from 'antd'

// icons
import { AddIcon, CloudDownloadIcon, SwapCircleIcon } from 'src/assets/svg'

// components
import { PurchaseOrderTable } from '../PurchaseOrderTable'
import { IconButton } from 'src/shared/components/iconButton/IconButton'

// helpers
import { TrustWalletTabsEnum } from '../../index.d'
import { FINANCE_ENUM } from 'src/routes/finance'
import { CenterModalContext, RightModalContext } from 'src/features/Modals'
import { CENTER_MODALS, RIGHT_MODALS } from 'src/helpers/contants'
import { Emitter, EmitterEvents } from 'src/helpers/eventEmitter'
import { TableExportFormatEnum } from 'src/shared/hooks/table/useTableExport'

// styles
import styles from './styles.module.scss'
import { TransactionsTable } from '../TransactionsTable'
import { Button } from 'src/shared/components/button/Button'
import usePermissions from '../../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../../Settings/models/IPermissions'
import WithPermissionDeniedContainer from '../../../../../../shared/components/withPermissionDenied'
import { InventoryTable } from '../InventoryTable'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'
import { useDispatch } from 'react-redux'
import { purchaseOrderApi } from 'src/features/Finance/core/http/PurchaseOrderApi'
import { PinTable } from '../PinTable'
import { HydraTable } from '../HydraTable'

interface IPropsOperations {
  onAddButtonClick: () => void
  canAddNew: boolean
  canExport: boolean
}

const Operations = memo(({ onAddButtonClick, canAddNew, canExport }: IPropsOperations) => {
  const centerModalContext = useContext(CenterModalContext)
  const rightModalContext = useContext(RightModalContext)
  const dispatch = useDispatch()

  const handlerExportClick = () => {
    centerModalContext.onOpen(CENTER_MODALS.EXPORT_TABLE, {
      title: 'Export Table',
      onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
      onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      footer: <Button onClick={centerModalContext.onClose}>Cancel</Button>,
    })
  }

  const handlerTransferClick = () => {
    rightModalContext.onOpen(RIGHT_MODALS.FINANCE.TRANSFER_FUND, {
      title: 'Transfer Fund',
      afterCreate: () =>
        dispatch(purchaseOrderApi.util.invalidateTags(['ITrustWalletTransaction'])),
    })
  }

  return (
    <div className={styles.btnsContainer}>
      <IconButton
        onClick={handlerTransferClick}
        icon={<SwapCircleIcon />}
        type='default'
        color='blue'
        size='middle'
      >
        Transfer Fund
      </IconButton>
      {/* <IconButton icon={<TicksIcon />} type='default' color='blue' size='middle'>
        Reconcile
      </IconButton> */}
      {canExport && (
        <IconButton
          onClick={handlerExportClick}
          icon={<CloudDownloadIcon />}
          type='primary'
          color='blue'
          size='middle'
        />
      )}
      {canAddNew && (
        <IconButton
          icon={<AddIcon />}
          type='primary'
          color='orange'
          size='middle'
          onClick={onAddButtonClick}
        />
      )}
    </div>
  )
})

export const TrustWalletTabs = () => {
  const navigate = useNavigate()
  const { onOpen } = useContext(RightModalContext)
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: TrustWalletTabsEnum.OVERVIEW,
  })

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()

  const [transactionsId, purchasesId, financePurchaseId] = getModulesIdsByNames([
    IPermissionsNames['Transactions List'],
    IPermissionsNames['Purchase Orders'],
    IPermissionsNames['Finance Purchase Order'],
  ])

  const [canViewTransactions, canExportTransactions] = canPerformAction(transactionsId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.EXPORT,
  ])

  const [canViewPurchases, canExportPurchases] = canPerformAction(purchasesId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.EXPORT,
  ])

  const [canAddFinancePurchase, canEditFinancePurchase] = canPerformAction(financePurchaseId, [
    IPermissionsActions.ADD,
    IPermissionsActions.EDIT,
  ])

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  /** Table */
  const currentTabData = useMemo(() => {
    if (activeTab === TrustWalletTabsEnum.OVERVIEW) {
      return {
        navigateTo: FINANCE_ENUM.NEW_PURCHASE_ORDER,
        canAdd: canAddFinancePurchase,
        canExport: canExportTransactions,
      }
    }

    if (activeTab === TrustWalletTabsEnum.PURCHASE_ORDERS) {
      return {
        navigateTo: FINANCE_ENUM.NEW_PURCHASE_ORDER,
        canAdd: canAddFinancePurchase,
        canExport: canExportPurchases,
      }
    }

    if (activeTab === TrustWalletTabsEnum.INVENTORY) {
      return {
        openModal: {
          name: RIGHT_MODALS.FINANCE.PURCHASE_PLAN,
          props: { title: 'Create Purchase Plan' },
        },
        canAdd: true,
        canExport: false,
      }
    }

    if (activeTab === TrustWalletTabsEnum.E_PIN) {
      return {
        canAdd: false,
        canExport: false,
      }
    }

    if (activeTab === TrustWalletTabsEnum.HYDRA) {
      return {
        canAdd: false,
        canExport: false,
      }
    }

    return {
      canAdd: true,
      canExport: false,
    }
  }, [canAddFinancePurchase, canExportPurchases, canExportTransactions, activeTab])

  const items = useMemo(
    () => [
      {
        label: TrustWalletTabsEnum.OVERVIEW,
        key: TrustWalletTabsEnum.OVERVIEW,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canViewTransactions}>
            <TransactionsTable canAddNew={canAddFinancePurchase} />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: TrustWalletTabsEnum.PURCHASE_ORDERS,
        key: TrustWalletTabsEnum.PURCHASE_ORDERS,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canViewPurchases}>
            <PurchaseOrderTable
              canAddNew={canAddFinancePurchase}
              canEdit={canEditFinancePurchase}
            />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: TrustWalletTabsEnum.INVENTORY,
        key: TrustWalletTabsEnum.INVENTORY,
        children: <InventoryTable />,
      },
      {
        label: TrustWalletTabsEnum.E_PIN,
        key: TrustWalletTabsEnum.E_PIN,
        children: <PinTable />,
      },
      {
        label: TrustWalletTabsEnum.HYDRA,
        key: TrustWalletTabsEnum.HYDRA,
        children: <HydraTable />,
      },
    ],
    [canAddFinancePurchase, canEditFinancePurchase, canViewPurchases, canViewTransactions]
  )

  const onAddButtonClick = useCallback(() => {
    if (currentTabData?.navigateTo) {
      return navigate(currentTabData.navigateTo, { replace: true })
    }

    if (currentTabData?.openModal) {
      return onOpen(currentTabData.openModal?.name, currentTabData.openModal?.props)
    }
  }, [currentTabData.navigateTo, currentTabData?.openModal, navigate, onOpen])

  return (
    <Tabs
      destroyInactiveTabPane
      tabBarExtraContent={
        <Operations
          onAddButtonClick={onAddButtonClick}
          canAddNew={currentTabData.canAdd}
          canExport={currentTabData.canExport}
        />
      }
      items={items}
      activeKey={activeTab}
      onChange={onTabChange}
    />
  )
}
