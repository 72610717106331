import React, { useCallback, useState } from 'react'
import {
  useDeleteFileMutation,
  useGetFileListQuery,
  useLazyGetFileInfoQuery,
} from '../../../../../../core/http/FileManagementApi'
import { ITableConf } from '../../../../../../../../shared/models/ITableConf'
import { ISelectedFilters } from '../../../../../../../../shared/components/filter/Filter'
import styles from '../../../../styles.module.scss'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ICreatedBy, IFileTable } from '../../../../../../models/IFileManagementList'
import { Link, useNavigate } from 'react-router-dom'
import { PermissionEnum } from '../../../../../../../../shared/models/Permission'
import moment from 'moment/moment'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../../../shared/components/popup/Popup'
import { TableActionIcon } from '../../../../../../../../assets/svg'
import { ErrorNode, showConfirmMessage } from '../../../../../../../../shared/api/errorHandler'
import { saveAs } from 'file-saver'
import useTable from '../../../../../../../../shared/hooks/table/useTable'
import { SETTINGS_ENUM } from '../../../../../../../../routes/settings'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../shared/hooks/useNotification'
import useFileManagement from '../../../../useFileManagement'
import { RecursivelyReplaceCharacters } from '../../../../../../../../shared/components/privateMode'

interface IProps {
  folderId: string | number
}

const Folder = ({ folderId }: IProps) => {
  const navigate = useNavigate()

  const {
    permissions: { canExportFile, canDeleteFile },
    queryParams,
    queryFields,
  } = useFileManagement()

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  /** Request data */
  const [deleteFile, delFileResp] = useDeleteFileMutation()
  const [getFile, { data: fileInfo }] = useLazyGetFileInfoQuery()
  const { data, isFetching } = useGetFileListQuery({
    ...tableConf,
    ...filterValues,
    parentId: folderId,
  })

  const { pagination, handleTableChange } = useTable<IFileTable>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, delFileResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, delFileResp.isError, delFileResp.error as ErrorNode)

  const downloadFile = useCallback(
    async (row: IFileTable) => {
      if (row.preview) {
        return saveAs(row.preview, row.fileName)
      }
      await getFile(row.id)
      if (fileInfo) {
        saveAs(fileInfo.link, row.fileName)
      }
    },
    [fileInfo, getFile]
  )

  const tableActionsPopup = useCallback(
    (row: IFileTable): IPopupListItems[] => [
      {
        text: 'View',
        shouldDisplay: true,
        onClick: () => navigate(`${SETTINGS_ENUM.FILE_MANAGEMENT}/view/${row.id}`),
      },
      {
        text: 'Download',
        shouldDisplay: canExportFile,
        onClick: () => downloadFile(row),
      },
      {
        text: 'Delete',
        shouldDisplay: canDeleteFile,
        onClick: () => showConfirmMessage('This file will be deleted', () => deleteFile(row.id)),
        color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        withDivider: true,
      },
    ],
    [canDeleteFile, canExportFile, deleteFile, downloadFile]
  )

  const columns: ColumnsType<IFileTable> = [
    {
      title: 'File Name',
      dataIndex: 'name',
      sorter: true,
      width: '20%',
      render: (fileName: string, row) => (
        <RecursivelyReplaceCharacters>
          <Link
            to={`${SETTINGS_ENUM.FILE_MANAGEMENT}/view/${row.id}`}
            className={styles.tableNameLink}
          >
            {/* {row?.preview ? (
            <img alt='file-preview' src={row?.preview ?? emptyAvatarImage} />
          ) : (
            <AttachFileIcon />
          )} */}
            <div>{fileName}</div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'File Format',
      dataIndex: 'fileType',
      sorter: true,
      render: (type) => <RecursivelyReplaceCharacters>{type}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: true,
      render: (cat) => <RecursivelyReplaceCharacters>{cat}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Permission Type',
      dataIndex: 'isPrivate',
      sorter: true,
      render: (isPrivate: IFileTable['isPrivate']) => (
        <RecursivelyReplaceCharacters>
          {isPrivate ? PermissionEnum.PRIVATE : PermissionEnum.PUBLIC}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Added by',
      dataIndex: 'creatorName',
      sorter: true,
      render: (createdByUser: ICreatedBy, row) => (
        <RecursivelyReplaceCharacters>
          {row?.createdByUser ? row?.createdByUser.name : ''}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Date/Time Created',
      dataIndex: 'createdAt',
      sorter: true,
      render: (createdAt: IFileTable['createdAt']) => (
        <RecursivelyReplaceCharacters>
          <div className='table-date'>
            <span>{moment(createdAt).format('MMM D, YYYY HH:mm')}</span>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return (
    <div>
      <Table
        columns={columns}
        onChange={handleTableChange}
        loading={isFetching}
        className={styles.fileManagementList}
        scroll={{ x: 1150 }}
        dataSource={data?.items}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.id}
        pagination={pagination}
      />
    </div>
  )
}

export default Folder
