import React, { useEffect, useState } from 'react'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import { IBankWalletTransaction } from '../../../../models/IBankWalletTransactions'
import {
  useGetBankWalletTransactionsQuery,
  useGetBWTransTableFiltersListQuery,
  useLazyExportBankWalletQuery,
} from '../../../../core/http/BankWalletTransactionsApi'
import { TableHeader } from '../../Components/TableHeader'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import styles from '../PickupRequestTable/styles.module.scss'
import {
  DateTableIcon,
  MonetizationWidgetIcon,
  TableActionIcon,
} from '../../../../../../assets/svg'
import {
  formatDateWithTime,
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  formatMoneyByCurrencyType,
  formatShamsiMomentToISO,
  tableExpandedIcon,
} from '../../../../../../helpers/utils'
import classNames from 'classnames'
import { formatNumberToLocale } from '../../../../../salesNetwork/helpers'
import { IPopupListItems, Popup } from '../../../../../../shared/components/popup/Popup'

import {
  AVAILABLE_HISTORY_CATEGORIES,
  DEBIT_CREDIT_ENUM,
  TRANSACTION_STATUS_ENUM,
} from '../../../../models/ITransaction'
import { renderTableAgent } from './renderTableAgent'
import { TransactionTableFooter } from './TransactionTableFooter'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { useLazyGetSubTransactionsQuery } from '../../../../../Reports/core/Subtransactions'
import { useOnTransactionViewClick } from '../../../../../../shared/hooks/table/useOnTransactionViewClick'
import { Widget } from '../../../../../../shared/components/widget'
import { WIDGET_LIST_ENUM } from '../../../../../../shared/models/WidgetListEnum'
import { IWidget } from '../../../../../../shared/models/IWidget'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { widgetsSocketConnection } from '../../../../../../shared/sockets'
import { camelCase } from 'lodash'
import { PickupSummaryChart } from './PickupSummaryChart'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { useOnTransactionHistoryClick } from '../../../../../../shared/hooks/table/useOnTransactionHistoryClick'
import { useTableExport } from '../../../../../../shared/hooks/table/useTableExport'
import moment from 'moment/moment'
import { ITotalPickup } from '../../../../models/IPickupRequest'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

export const TransactionsTable = () => {
  const [widgetData, setWidgetData] = useState(() => ({
    [WIDGET_LIST_ENUM.BANK_WALLET_BALANCE]: {
      value: 0,
      statistic: 0,
    },
    [WIDGET_LIST_ENUM.TOTAL_PICKUP_TODAY]: {
      valueTotal: 0,
      value: 0,
    },
    [WIDGET_LIST_ENUM.PICKUP_SUMMARY]: {
      value: [] as ITotalPickup[],
      frequency: 'hour',
    },
  }))
  const onTransactionViewClick = useOnTransactionViewClick()
  const onTransactionHistoryClick = useOnTransactionHistoryClick()
  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [expandedRowKeys, setExpandedRowKeys] = useState<Array<React.Key>>([])
  const [mappedTrans, setMappedTrans] = useState<IBankWalletTransaction[]>([])
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const [getSubTrans, getSubTransResp] = useLazyGetSubTransactionsQuery()
  const [exportTable] = useLazyExportBankWalletQuery()
  const { data: profile } = useGetProfileQuery()
  const { data, isFetching } = useGetBankWalletTransactionsQuery(
    { ...filterValues, ...tableConf },
    { skip: Object.keys(tableConf).length < 4 }
  )
  const { data: filterFields } = useGetBWTransTableFiltersListQuery()

  useTableExport({ tableConf, filterValues, promise: exportTable })

  const tableActionsPopup = (row: IBankWalletTransaction): IPopupListItems[] => [
    {
      text: 'View',
      shouldDisplay: true,
      onClick: () => onTransactionViewClick(row.id, row.transactionCategory, row),
    },
    ...(AVAILABLE_HISTORY_CATEGORIES.includes(row.transactionCategory)
      ? [
          {
            text: 'History',
            shouldDisplay: true,
            onClick: () => onTransactionHistoryClick(row?.id, row?.transactionCategory),
          },
        ]
      : []),
  ]

  const columns: ColumnsType<IBankWalletTransaction> = [
    {
      title: '',
      dataIndex: '',
      key: 'expand',
      width: '1%',
      onCell: (record) => {
        return {
          onClick: async () => {
            const mappedRec = { ...record }
            const data = await getSubTrans({
              transactionCategory: record.transactionCategory,
              id: record.id,
            })
            mappedRec.children = data?.data?.items

            setMappedTrans((prevState) =>
              prevState.map((trans) => {
                if (trans.id === mappedRec.id) {
                  return mappedRec
                }
                return trans
              })
            )
          },
        }
      },
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      sorter: true,
      width: '15%',
      render: (name: IBankWalletTransaction['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(name, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Transaction ID',
      dataIndex: 'id',
      sorter: true,
      width: '15%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Agent',
      dataIndex: 'agent',
      sorter: true,
      width: '20%',
      render: (agent: IBankWalletTransaction['agent'], row) =>
        renderTableAgent(agent, row, isPrivateMode),
    },
    {
      title: 'Balance Before',
      dataIndex: 'balanceBefore',
      width: '10%',
      sorter: true,
      render: (name: IBankWalletTransaction['balanceBefore']) => (
        <RecursivelyReplaceCharacters>
          <div className={styles.priceBold}>{formatNumberToLocale(name)}</div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'currencyAmount',
      width: '10%',
      sorter: true,
      render: (amount: IBankWalletTransaction['currencyAmount'], row) => {
        const moneyCount = formatMoneyByCurrencyType(amount, row?.drCr)
        return (
          <>
            <RecursivelyReplaceCharacters>
              <span
                className={classNames(styles.amountContainer, {
                  [styles.dr]:
                    amount && row.drCr === DEBIT_CREDIT_ENUM.DEBIT && row.mainTransactionId,
                  [styles.cr]:
                    amount && row.drCr === DEBIT_CREDIT_ENUM.CREDIT && row.mainTransactionId,
                  [styles.zero]: !amount && row.mainTransactionId,
                })}
              >
                {(!Object.hasOwn(row, 'mainTransactionId')
                  ? moneyCount?.replace(/\+/, '')
                  : moneyCount) || 0}
              </span>
            </RecursivelyReplaceCharacters>
            <div className={styles.greyText}>
              <RecursivelyReplaceCharacters>{row?.bankAccount?.name}</RecursivelyReplaceCharacters>
            </div>
          </>
        )
      },
    },
    {
      title: 'Emoney',
      dataIndex: 'amount',
      width: '10%',
      sorter: true,
      render: (amount: IBankWalletTransaction['amount'], row) => {
        const moneyCount = formatMoneyByCurrencyType(amount, row?.drCr)
        return (
          <RecursivelyReplaceCharacters>
            <div
              className={classNames('amountContainer', {
                dr:
                  (amount < 0 && row?.drCr === DEBIT_CREDIT_ENUM.CREDIT && row.mainTransactionId) ||
                  (amount > 0 && row?.drCr === DEBIT_CREDIT_ENUM.DEBIT && row.mainTransactionId),
                cr:
                  (amount < 0 && row?.drCr === DEBIT_CREDIT_ENUM.DEBIT && row.mainTransactionId) ||
                  (amount > 0 && row?.drCr === DEBIT_CREDIT_ENUM.CREDIT && row.mainTransactionId),
                zero: !amount && row.mainTransactionId,
              })}
            >
              {!Object.hasOwn(row, 'mainTransactionId') ? moneyCount.replace(/\+/, '') : moneyCount}
            </div>
          </RecursivelyReplaceCharacters>
        )
      },
    },
    {
      title: 'Balance After',
      dataIndex: 'balanceAfter',
      width: '10%',
      sorter: true,
      render: (name: IBankWalletTransaction['balanceAfter']) => (
        <RecursivelyReplaceCharacters>
          <div className={styles.priceBold}>{formatNumberToLocale(name)}</div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: '10%',
      render: (status: IBankWalletTransaction['status']) => (
        <RecursivelyReplaceCharacters>
          <div className={classNames('statusContainer', `${status.toLowerCase()}-container`)}>
            <div className={classNames(camelCase(status.toLowerCase()), 'statusPoint')} />
            <div>{status}</div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => {
        if (Object.hasOwn(row, 'mainTransactionId')) return
        return (
          <Popup data={tableActionsPopup(row)}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        )
      },
    },
  ]

  useEffect(() => {
    if (data) {
      setMappedTrans(data.items)
    }
  }, [data])

  useEffect(() => {
    if (!profile?.timeZone) return
    moment.tz?.setDefault(profile?.timeZone as string)

    const from = formatMomentDateToDayStart(
      formatShamsiMomentToISO(profile?.calendar, moment())
    ).toISOString(true)
    const to = formatMomentDateToDayEnd(
      formatShamsiMomentToISO(profile?.calendar, moment())
    ).toISOString(true)

    const socket = widgetsSocketConnection.getSocket()
    const messagePayload = [
      {
        widget: WIDGET_LIST_ENUM.BANK_WALLET_BALANCE,
      },
      {
        widget: WIDGET_LIST_ENUM.TOTAL_PICKUP_TODAY,
        filters: {
          from,
          to,
        },
      },
      {
        widget: WIDGET_LIST_ENUM.PICKUP_SUMMARY,
        filters: {
          frequency: 'hour',
          from,
          to,
        },
      },
    ]

    socket?.emit('connect-widget', { data: messagePayload })

    return () => {
      socket?.emit('disconnect-widget', { data: messagePayload })
    }
  }, [profile])

  useEffect(() => {
    if (!profile) return

    const socket = widgetsSocketConnection.getSocket()
    socket?.on('widget-data', (data: IWidget) => {
      const balance = JSON.parse(data?.data?.jsonData)
      setWidgetData((prevState) => ({
        ...prevState,
        [data.widget]:
          Object.keys(balance).length === 1
            ? {
                value: balance?.value,
                ...(data['filters']?.frequency && { frequency: data['filters']?.frequency }),
              }
            : balance,
      }))
    })
  }, [profile])

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IBankWalletTransaction>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
    setExpandedRowKeys,
  })

  return (
    <>
      <div className={styles.tableWidgetsChart}>
        <div className={styles.widgetWrapper}>
          <Widget
            icon={<MonetizationWidgetIcon />}
            title='Operation Wallet Balance'
            value={widgetData[WIDGET_LIST_ENUM.BANK_WALLET_BALANCE]?.value}
            statistic={widgetData[WIDGET_LIST_ENUM.BANK_WALLET_BALANCE]?.statistic}
          />
          <Widget
            icon={<MonetizationWidgetIcon />}
            title='Total Pickup Today'
            value={widgetData[WIDGET_LIST_ENUM.TOTAL_PICKUP_TODAY]?.value}
            valueTotal={widgetData[WIDGET_LIST_ENUM.TOTAL_PICKUP_TODAY]?.valueTotal}
          />
        </div>
        <PickupSummaryChart
          widgetData={widgetData[WIDGET_LIST_ENUM.PICKUP_SUMMARY].value}
          frequency={widgetData[WIDGET_LIST_ENUM.PICKUP_SUMMARY].frequency}
        />
      </div>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filterFields}
      />
      <Table
        locale={emptyTableConf}
        className={styles.tableContainer}
        pagination={pagination}
        onChange={handleTableChange}
        loading={isFetching || getSubTransResp.isFetching}
        columns={columns}
        dataSource={mappedTrans}
        scroll={{ x: 1000 }}
        summary={(data) => {
          const dataObj = [...data]
          return <TransactionTableFooter data={dataObj} />
        }}
        expandable={{
          expandedRowKeys,
          expandIcon: (props) => {
            if (
              Object.hasOwn(props.record, 'mainTransactionId') ||
              !props.record?.countSubTransaction
            )
              return null
            if (props.record.status === TRANSACTION_STATUS_ENUM.APPROVED) {
              return tableExpandedIcon<IBankWalletTransaction>(props)
            }
            return null
          },
          onExpandedRowsChange: (expandedRows) => setExpandedRowKeys([...expandedRows]),
        }}
        rowKey={(record) => record.id}
      />
    </>
  )
}
