import { useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

import { DateTableIcon, TableActionIcon } from '../../../../../../assets/svg'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../shared/components/popup/Popup'
import { formatDateWithTime } from '../../../../../../helpers/utils'
import { useTableExport } from '../../../../../../shared/hooks/table/useTableExport'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'

import { ErrorNode, showConfirmMessage } from '../../../../../../shared/api/errorHandler'
import { ITableConf } from '../../../../../../shared/models/ITableConf'

import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import TableHeader from '../TableHeader'
import {
  useDeleteAgentRestrictionMutation,
  useGetAgentRestrictionFiltersListQuery,
  useGetAgentRestrictionsQuery,
  useLazyExportAgentRestrictionListTableQuery,
} from '../../../../core/http/AgentsRestriction'
import { IRestriction } from '../../../../models/Restrictions'
import styles from '../../styles.module.scss'
import { formatPhoneNumber } from '../../../../../salesNetwork/helpers'
import { Tags } from '../../../../../../shared/components/tags'
import emptyAvatar from '../../../../../../assets/img/Avatar.png'
import { SALES_ENUM } from '../../../../../../routes/sales'
import { SUPPORT_ENUM } from '../../../../../../routes/support'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { isEmpty } from 'lodash'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IProps {
  canEditRestriction: boolean
  canDeleteRestriction: boolean
}

export const AgentsRestrictionManagement = ({
  canEditRestriction,
  canDeleteRestriction,
}: IProps) => {
  const navigate = useNavigate()
  const { queryParams, queryFields } = useGetQueryParams()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const [deleteRestriction, delRestrictionResp] = useDeleteAgentRestrictionMutation()
  const [exportTable] = useLazyExportAgentRestrictionListTableQuery()
  const { data: filtersList } = useGetAgentRestrictionFiltersListQuery()
  const { data = { items: [], totalCount: 0 }, isFetching } = useGetAgentRestrictionsQuery(
    {
      ...filterValues,
      ...tableConf,
    },
    { skip: isEmpty(tableConf) }
  )
  const { data: profile } = useGetProfileQuery()

  /** Notifications*/
  useNotification(NOTIFICATION_TYPES.success, delRestrictionResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    delRestrictionResp.isError,
    delRestrictionResp.error as ErrorNode
  )

  /** Table export */
  useTableExport({ tableConf, filterValues, promise: exportTable })

  const emptyTableConf = useEmptyTable(() =>
    navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}/agent/new`)
  )
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IRestriction>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  const tableActionsPopup = useCallback(
    (id: string): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () =>
            navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}/agent/view/${id}`),
        },
        {
          text: 'Edit',
          shouldDisplay: canEditRestriction,
          onClick: () =>
            navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}/agent/edit/${id}`),
        },
        {
          text: 'Delete',
          withDivider: true,
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          shouldDisplay: canDeleteRestriction,
          onClick: () =>
            showConfirmMessage('This restriction will be deleted', () => deleteRestriction(id)),
        },
      ]
    },
    [canDeleteRestriction, canEditRestriction, deleteRestriction, navigate]
  )

  const columns: ColumnsType<IRestriction> = [
    {
      title: 'Agent',
      dataIndex: 'agent',
      width: '20%',
      sorter: true,
      render: (restrictionAgent: IRestriction['agent'], { agent }) => (
        <RecursivelyReplaceCharacters>
          <Link to={`${SALES_ENUM.SALES_NETWORK}/view/${agent?.id}`} className='table-avatar'>
            <div>
              <img
                src={!isPrivateMode && agent?.avatar ? agent.avatar : emptyAvatar}
                alt='icon'
                height={32}
                width={32}
              />
            </div>
            <div>
              <div>{agent?.companyTitle ?? `${agent?.firstName} ${agent?.lastName}`}</div>
              <div className={styles.agentIdText}>{`ID${agent?.id}`}</div>
            </div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Phone number',
      dataIndex: 'agentPhoneNumber',
      width: '15%',
      sorter: true,
      render: (name: IRestriction['phoneNumber'], { phoneNumber }) => (
        <RecursivelyReplaceCharacters>
          {formatPhoneNumber(phoneNumber)}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      width: '10%',
      sorter: true,
      render: (reason: IRestriction['reason']) => (
        <RecursivelyReplaceCharacters>{reason}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Type of restriction',
      dataIndex: 'restrictionTypes',
      width: '20%',
      render: (value: IRestriction['restrictionTypes']) => <Tags tags={value} isBlue />,
    },
    {
      title: 'Start date/time',
      dataIndex: 'startAt',
      width: '10%',
      sorter: true,
      render: (startTime: IRestriction['startAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(startTime as string, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'End date/time',
      dataIndex: 'endAt',
      width: '10%',
      sorter: true,
      render: (endTime: IRestriction['endAt']) =>
        endTime && (
          <div className='table-date'>
            <DateTableIcon />
            <RecursivelyReplaceCharacters>
              {formatDateWithTime(endTime as string, profile?.calendar)}
            </RecursivelyReplaceCharacters>
          </div>
        ),
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
      width: '15%',
      sorter: true,
      render: (_, { createdByUser }) => (
        <RecursivelyReplaceCharacters>{createdByUser?.name}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => {
        return (
          <Popup data={tableActionsPopup(row.id)}>
            <div className={`table-kebab-actions ${_ === 'Delete' && styles.deleteButton}`}>
              <TableActionIcon />
            </div>
          </Popup>
        )
      },
    },
  ]

  return (
    <>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filtersList}
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        onChange={handleTableChange}
        className={styles.table}
        loading={isFetching}
        dataSource={data.items}
        scroll={{ x: 1000 }}
        pagination={pagination}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.id}
      />
    </>
  )
}
