import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { Tabs } from 'antd'

// icons
import { AddIcon, CloudDownloadIcon, SwapCircleIcon } from 'src/assets/svg'

// components
import { PickupRequestTable } from '../../Tables/PickupRequestTable'
import { IconButton } from 'src/shared/components/iconButton/IconButton'

// helpers
import { BankWalletTabsEnum } from '../../index.d'
import { Emitter, EmitterEvents } from 'src/helpers/eventEmitter'
import { CenterModalContext } from 'src/features/Modals/centerModalProvider'
import { FINANCE_ENUM } from 'src/routes/finance'
import { CENTER_MODALS, RIGHT_MODALS } from 'src/helpers/contants'
import { TableExportFormatEnum } from 'src/shared/hooks/table/useTableExport'
import { RightModalContext } from 'src/features/Modals'

// styles
import styles from './styles.module.scss'
import { Button } from 'src/shared/components/button/Button'
import { RefundEmoneyTable } from '../../Tables/RefundEmoneyTable'
import { TransactionsTable } from '../../Tables/TransactionsTable'
import usePermissions from '../../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../../Settings/models/IPermissions'
import { ReverseTransactionsTable } from '../../Tables/ReverseTransactionsTable'
import WithPermissionDeniedContainer from '../../../../../../shared/components/withPermissionDenied'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'

interface IPropsOperations {
  activeTab: BankWalletTabsEnum
  onAddButtonClick: () => void
  canAddNew: boolean
  canExport: boolean
}

const Operations = ({ onAddButtonClick, canAddNew, canExport }: IPropsOperations) => {
  const centerModalContext = useContext(CenterModalContext)
  const rightModalContext = useContext(RightModalContext)

  const handlerExportClick = () => {
    centerModalContext.onOpen(CENTER_MODALS.EXPORT_TABLE, {
      title: 'Export Table',
      onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
      onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      footer: <Button onClick={centerModalContext.onClose}>Cancel</Button>,
    })
  }

  const handlerTransferClick = () => {
    rightModalContext.onOpen(RIGHT_MODALS.FINANCE.TRANSFER_FUND, {
      title: 'Transfer Fund',
    })
  }

  return (
    <div className={styles.btnsContainer}>
      <IconButton
        onClick={handlerTransferClick}
        icon={<SwapCircleIcon />}
        type='default'
        color='blue'
        size='middle'
      >
        Transfer Fund
      </IconButton>
      {/* <IconButton icon={<TicksIcon />} type='default' color='blue' size='middle'>
        Reconcile
      </IconButton> */}
      {canExport && (
        <IconButton
          onClick={handlerExportClick}
          icon={<CloudDownloadIcon />}
          type='primary'
          color='blue'
          size='middle'
        />
      )}
      {canAddNew && (
        <IconButton
          icon={<AddIcon />}
          type='primary'
          color='orange'
          size='middle'
          onClick={onAddButtonClick}
        />
      )}
    </div>
  )
}

export const BankWalletTabs = () => {
  const rightModalContext = useContext(RightModalContext)
  const navigate = useNavigate()

  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: BankWalletTabsEnum.TRANSACTIONS,
  })

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()

  const [
    financeTransactionsId,
    pickupRequestsId,
    refundEMoneyId,
    reverseTransactionsId,
    pickupRequestId,
    financeRefundEMoneyId,
    financeReverseId,
  ] = getModulesIdsByNames([
    IPermissionsNames['Finance Transactions List'],
    IPermissionsNames['Pickup Requests List'],
    IPermissionsNames['Refund eMoney List'],
    IPermissionsNames['Reverse Transaction List'],
    IPermissionsNames['Pickup'],
    IPermissionsNames['FinanceRefund eMoney'],
    IPermissionsNames['Reverse'],
  ])

  /* Finance Transactions */
  const [canViewFinanceTransactions, canExportFinanceTransactions] = canPerformAction(
    financeTransactionsId,
    [IPermissionsActions.VIEW, IPermissionsActions.EXPORT]
  )

  /* Pickup Requests */
  const [canViewPickupRequests, canExportPickupRequests] = canPerformAction(pickupRequestsId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.EXPORT,
  ])

  const [
    canViewPickupRequest,
    canAddPickupRequest,
    canEditPickupRequest,
    canApprovePickupRequest,
    canRejectPickupRequest,
  ] = canPerformAction(pickupRequestId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.ADD,
    IPermissionsActions.EDIT,
    IPermissionsActions.APPROVER,
    IPermissionsActions.REJECT,
  ])

  /* Refund eMoney */
  const [canViewRefundEMoney, canExportRefundEMoney] = canPerformAction(refundEMoneyId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.EXPORT,
  ])
  const [
    canViewFinanceRefundEMoney,
    canAddFinanceRefundEMoney,
    canEditFinanceRefundEMoney,
    canApproveFinanceRefundEMoney,
    canRejectFinanceRefundEMoney,
  ] = canPerformAction(financeRefundEMoneyId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.ADD,
    IPermissionsActions.EDIT,
    IPermissionsActions.APPROVER,
    IPermissionsActions.REJECT,
  ])

  /* Reverse Transaction */
  const [canViewReverseTransactions, canExportReverseTransactions] = canPerformAction(
    reverseTransactionsId,
    [IPermissionsActions.VIEW, IPermissionsActions.EXPORT]
  )
  const [canAddReverseTransaction] = canPerformAction(financeReverseId, [IPermissionsActions.ADD])

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  /** Table */
  const currentTabData = useMemo(() => {
    switch (activeTab) {
      case BankWalletTabsEnum.TRANSACTIONS:
        return { navigateTo: '', canAdd: false, canExport: canExportFinanceTransactions }
      case BankWalletTabsEnum.PICKUP_REQUEST:
        return {
          navigateTo: FINANCE_ENUM.NEW_PICKUP_REQUEST,
          canAdd: canAddPickupRequest,
          canExport: canExportPickupRequests,
        }
      case BankWalletTabsEnum.REFUND_EMONEY:
        return {
          navigateTo: FINANCE_ENUM.NEW_REFUND_REQUEST,
          canAdd: canAddFinanceRefundEMoney,
          canExport: canExportRefundEMoney,
        }
      case BankWalletTabsEnum.REVERSE_TRANSACTION:
        return {
          openModal: {
            name: RIGHT_MODALS.FINANCE.REVERSE_TRANSACTION_REQUEST,
            props: { title: 'Reverse Transaction Request' },
          },
          canAdd: canAddReverseTransaction,
          canExport: canExportReverseTransactions,
        }
      default:
        return { canAdd: false, canExport: false }
    }
  }, [
    canAddFinanceRefundEMoney,
    canAddPickupRequest,
    canAddReverseTransaction,
    canExportFinanceTransactions,
    canExportPickupRequests,
    canExportRefundEMoney,
    canExportReverseTransactions,
    activeTab,
  ])

  const items = [
    {
      label: BankWalletTabsEnum.TRANSACTIONS,
      key: BankWalletTabsEnum.TRANSACTIONS,
      children: (
        <WithPermissionDeniedContainer isAllowedView={canViewFinanceTransactions}>
          <TransactionsTable />
        </WithPermissionDeniedContainer>
      ),
    },
    {
      label: BankWalletTabsEnum.PICKUP_REQUEST,
      key: BankWalletTabsEnum.PICKUP_REQUEST,
      children: (
        <WithPermissionDeniedContainer isAllowedView={canViewPickupRequests}>
          <PickupRequestTable
            canView={canViewPickupRequest}
            canAdd={canAddPickupRequest}
            canEdit={canEditPickupRequest}
            canApprove={canApprovePickupRequest}
            canReject={canRejectPickupRequest}
          />
        </WithPermissionDeniedContainer>
      ),
    },
    {
      label: BankWalletTabsEnum.REFUND_EMONEY,
      key: BankWalletTabsEnum.REFUND_EMONEY,
      children: (
        <WithPermissionDeniedContainer isAllowedView={canViewRefundEMoney}>
          <RefundEmoneyTable
            canView={canViewFinanceRefundEMoney}
            canAdd={canAddFinanceRefundEMoney}
            canEdit={canEditFinanceRefundEMoney}
            canApprove={canApproveFinanceRefundEMoney}
            canReject={canRejectFinanceRefundEMoney}
          />
        </WithPermissionDeniedContainer>
      ),
    },
    {
      label: BankWalletTabsEnum.REVERSE_TRANSACTION,
      key: BankWalletTabsEnum.REVERSE_TRANSACTION,
      children: (
        <WithPermissionDeniedContainer isAllowedView={canViewReverseTransactions}>
          <ReverseTransactionsTable />
        </WithPermissionDeniedContainer>
      ),
    },
  ]

  const onAddButtonClick = useCallback(() => {
    if (currentTabData?.navigateTo) {
      return navigate(currentTabData.navigateTo)
    }
    if (currentTabData?.openModal) {
      rightModalContext.onOpen(currentTabData.openModal?.name, currentTabData.openModal?.props)
    }
  }, [currentTabData])

  return (
    <Tabs
      destroyInactiveTabPane
      tabBarExtraContent={
        <Operations
          activeTab={activeTab as BankWalletTabsEnum}
          onAddButtonClick={onAddButtonClick}
          canAddNew={currentTabData.canAdd}
          canExport={currentTabData.canExport}
        />
      }
      items={items}
      activeKey={activeTab}
      onChange={onTabChange}
    />
  )
}
