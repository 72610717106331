import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import {
  IRoles,
  IRoleCreate,
  IRoleUpdate,
  IPermissionModulesGroup,
  IPermissionAllowed,
  IPermissionCreate,
  IRoleUpdateUsers,
  IPermissionModules,
  IPermissionModule,
} from '../../models/IRoles'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../../shared/models/ITableConf'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import moment from 'moment'
import { IUser } from '../../models/IUser'

export const rolesManagementApi = createApi({
  reducerPath: 'rolesManagement',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'IRoles',
    'IRoleCreate',
    'IFilterFields',
    'IRolePermissions',
    'IPermissionModulesGroup',
    'IPermissionAllowed',
    'IPermissionModules',
    'IUser',
    'IPermissionCreate',
  ],
  endpoints: (builder) => ({
    getRolesList: builder.query<ITableResponse<IRoles>, ITableConf | void>({
      query: (params) => ({
        url: 'roles',
        params: params || {},
      }),
      providesTags: () => ['IRoles'],
    }),

    getRoleById: builder.query<IRoles, string>({
      query: (id) => ({
        url: `roles/${id}`,
      }),
      transformResponse: (res: IRoles) => ({
        ...res,
        createdBy: `${res.createdByUser?.firstName ?? ''} ${res.createdByUser?.lastName ?? ''}`,
        createdAt: moment(res.createdAt).format('MMM DD, YYYY hh:mm'),
      }),
      providesTags: (role) => [{ type: 'IRoles', id: role?.id }],
    }),

    createRole: builder.mutation<IRoles, IRoleCreate>({
      query: (body) => ({
        url: 'roles',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IRoles', 'IFilterFields'],
    }),

    updateRole: builder.mutation<
      IRoleCreate,
      { id: string | number | undefined; body: Partial<IRoleUpdate> }
    >({
      query: ({ id, body }) => ({
        url: `roles/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['IRoles', 'IFilterFields', 'IRoleCreate', 'IUser'],
    }),

    unassignedUsersFromRole: builder.mutation<IRoleCreate, IRoleUpdateUsers>({
      query: (body) => ({
        url: `roles`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['IRoles', 'IFilterFields', 'IRoleCreate', 'IUser'],
    }),

    deleteRoleById: builder.mutation<void, string | number | undefined>({
      query: (id) => ({
        url: `roles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['IRoles'],
    }),

    getRolesTableFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'roles/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `roles/filter-values?filterField=${filter.field}&id=${arg}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      keepUnusedDataFor: 0,
    }),

    getPermissionsByRoleId: builder.query<Array<IPermissionCreate>, string>({
      query: (id) => ({
        url: `roles/${id}/permissions`,
      }),
      providesTags: () => ['IPermissionCreate'],
    }),

    getPermissionModulesList: builder.query<ITableResponse<IPermissionModule>, void>({
      query: () => ({
        url: '/permission-modules',
      }),
      providesTags: () => ['IPermissionModules'],
    }),

    getPermissionModulesAllowedList: builder.query<ITableResponse<IPermissionAllowed>, void>({
      query: () => ({
        url: '/permission-modules/allowed-permissions',
      }),
      providesTags: () => ['IPermissionAllowed'],
    }),

    getPermissionModulesGroupList: builder.query<Array<IPermissionModulesGroup>, void>({
      query: () => ({
        url: '/permission-modules-group',
      }),
      providesTags: () => ['IPermissionModulesGroup'],
    }),

    getPermissionModulesGroupAllowedList: builder.query<
      ITableResponse<IPermissionModulesGroup>,
      void
    >({
      query: () => ({
        url: '/permission-modules-group/allowed',
      }),
      providesTags: () => ['IPermissionModulesGroup'],
    }),

    getUsersByRoleId: builder.query<ITableResponse<IUser>, ITableConf>({
      query: ({ id, ...params }) => ({
        url: `/roles/${id}/users`,
        params,
      }),
      providesTags: () => ['IUser'],
    }),

    exportRoleUsersTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx'; id: string }
    >({
      query: ({ conf, formatFile }) => ({
        url: `/roles/${conf.id}/users/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getRoleUsersTableFiltersList: builder.query<Array<IFilterFields>, { id: string | undefined }>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'roles/users/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `roles/${arg.id}/users/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      keepUnusedDataFor: 0,
    }),
  }),
})

export const {
  useGetRolesListQuery,
  useGetRoleByIdQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useUnassignedUsersFromRoleMutation,
  useDeleteRoleByIdMutation,
  useGetRolesTableFiltersListQuery,
  useGetPermissionsByRoleIdQuery,
  useGetPermissionModulesListQuery,
  useGetPermissionModulesGroupListQuery,
  useGetPermissionModulesAllowedListQuery,
  useGetPermissionModulesGroupAllowedListQuery,
  useGetUsersByRoleIdQuery,
  useLazyExportRoleUsersTableQuery,
  useGetRoleUsersTableFiltersListQuery,
} = rolesManagementApi
