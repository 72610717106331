import styles from './styles.module.scss'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'
import { useContext } from 'react'
import { RightModalContext } from '../../../../../Modals'
import { Row, Space } from 'antd'
import { Button } from '../../../../../../shared/components/button/Button'

const widgetTestArr = [
  'Total Sales',
  'Active Location',
  'Active Agents',
  'Vendor Current Balances',
  'Sales Summary',
  'Value Added Services',
  'Pickup',
  'Top Locations',
  'Top Agents',
  'Hawala',
  'Failed Transactions',
  'Sales Channels',
  'Vendor Balance',
]

export const DashboardCustomizationModal = () => {
  const { onClose } = useContext(RightModalContext)
  return (
    <div className={styles.layout}>
      <div className={styles.toggleList}>
        {widgetTestArr.map((widget) => (
          <div key={widget} className={styles.toggleListItem}>
            <Toggle isBlue />
            <span>{widget}</span>
          </div>
        ))}
      </div>
      <div className={styles.modalFooterWrapper}>
        <Row justify='end' className={styles.modalFooter}>
          <Space size={10}>
            <Button color='blue' onClick={onClose}>
              Cancel
            </Button>
            <Button type='primary' color='blue'>
              Save Changes
            </Button>
          </Space>
        </Row>
      </div>
    </div>
  )
}
