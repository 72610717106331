import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from 'src/config'
import { IAuthBody, IAuthResponse } from 'src/shared/models/IAuth'

export const authApi = createApi({
  reducerPath: 'auth',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    login: builder.mutation<IAuthResponse, IAuthBody>({
      query: (body: IAuthBody) => {
        return {
          url: 'auth/login',
          method: 'POST',
          body,
        }
      },
    }),
  }),
})

export const { useLoginMutation } = authApi
