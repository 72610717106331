import { Wrapper } from '../../../../../../../shared/components/wrapper/Wrapper'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { IViewMode } from '../../../../../models/IViewMode'
import { positiveNumber, requiredValidator } from '../../../../../../../helpers/validation'

interface IProps extends IViewMode {
  isPrivateMode?: boolean
}

export const VendorTresholdBlock = ({ view, isPrivateMode }: IProps) => {
  return (
    <Wrapper title='Vendor Treshold'>
      <FormItem
        name='thresholdAmount'
        label='Treshold Amount'
        rules={[
          ...(!view
            ? [
                { required: true, validator: requiredValidator('Treshold Amount') },
                { validator: positiveNumber('Treshold Amount') },
              ]
            : [{}]),
        ]}
      >
        <TextField
          disabled={view}
          type={isPrivateMode ? 'password' : 'number'}
          placeholder='Enter Treshold Amount'
        />
      </FormItem>

      <FormItem
        name='thresholdDenomination'
        label='Treshold Max Denomination'
        className='noBottomMargin'
        rules={[
          ...(!view
            ? [
                { required: true, validator: requiredValidator('Treshold Max Denomination') },
                { validator: positiveNumber('Treshold Max Denomination') },
              ]
            : [{}]),
        ]}
      >
        <TextField
          disabled={view}
          type={isPrivateMode ? 'password' : 'number'}
          placeholder='Enter Treshold Max Denomination'
        />
      </FormItem>
    </Wrapper>
  )
}
