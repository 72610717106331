import { Spin } from 'antd'
import React, { useContext } from 'react'

// icons
import { DownloadIcon, InfoOutlinedIcon, RefreshIcon } from 'src/assets/svg'
import { CenterModalContext } from 'src/features/Modals'
import { CENTER_MODALS } from 'src/helpers/contants'

// components
import { Button } from 'src/shared/components/button/Button'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { IBackupCode } from 'src/shared/models/IAuth'
import { TwoFAType } from '../../index'

// styles
import styles from '../styles.module.scss'

interface IBackupBlock {
  isFetching: boolean
  twoFAStatus: TwoFAType
  codes: IBackupCode[]
}

export const BackupBlock = ({ isFetching, twoFAStatus, codes }: IBackupBlock) => {
  const { onOpen } = useContext(CenterModalContext)

  const handleGoogleAuthClick = () => {
    onOpen(CENTER_MODALS.GOOGLE_AUTHENTICATOR, {
      title: 'Connect Google Authenticator',
      className: 'googleAuthContainer',
      footer: null,
    })
  }

  const actionsBtns = (
    <React.Fragment>
      <IconButton
        disabled
        icon={<RefreshIcon />}
        size='small'
        color='orange'
        type='default'
        style={{ minWidth: '34px', height: '26px' }}
      />
      <IconButton
        disabled
        icon={<DownloadIcon />}
        size='small'
        color='blue'
        type='default'
        style={{ minWidth: '34px', height: '26px' }}
      />
    </React.Fragment>
  )

  return (
    <Spin spinning={isFetching}>
      <Wrapper title='backup codes' titleIcon={<InfoOutlinedIcon />} actions={actionsBtns}>
        <div className={styles.row}>
          {twoFAStatus === 'disabled' && (
            <div className={styles.connectBtnContainer}>
              <Button size='middle' color='blue' type='default' onClick={handleGoogleAuthClick}>
                Connect your Account and Google Authenticator
              </Button>
            </div>
          )}
          {twoFAStatus === 'Google Authenticator' && (
            <div className={styles.backupCodesContainer}>
              {codes?.map((i) => (
                <div key={i.id}>{i.code}</div>
              ))}
            </div>
          )}
        </div>
      </Wrapper>
    </Spin>
  )
}
