import React, { useCallback } from 'react'
import { Form } from 'antd'
import classNames from 'classnames'
import FormItem from 'antd/es/form/FormItem'
import FormList from 'antd/es/form/FormList'
import {
  maxNumber,
  minNumber,
  positiveNumber,
  requiredValidator,
} from '../../../../../../helpers/validation'
import { TextField } from '../../../../../../shared/components/textfield/TextField'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from '../../../../../../assets/svg'
import { IPickupCondition, IPromotionPolicyProduct } from '../../../../models/IPromotionPolicy'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'
import styles from '../../styles.module.scss'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { debounce } from 'lodash'

interface IProps extends IViewMode {
  initialValues?: IPickupCondition[]
  onCreatePage?: boolean
}

export const PickupProducts = ({ initialValues, view, onCreatePage }: IProps) => {
  const form = useFormInstance()

  // eslint-disable-next-line
  const validatePickupProducts = useCallback(
    debounce((fieldName: 'from' | 'to') => {
      const pickUpConditions = (form.getFieldValue('pickUpConditions') ||
        []) as IPromotionPolicyProduct[]

      pickUpConditions?.forEach((prod, index) => {
        if (form.getFieldValue(['pickUpConditions', index, fieldName])) {
          form.validateFields([['pickUpConditions', index, fieldName]])
        }
      })
    }, 300),
    []
  )

  return (
    <FormList name='pickUpConditions' initialValue={initialValues}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ name, key }) => (
            <div key={key} className={styles.masterProductItemContainer}>
              <FormItem
                name={[name, 'emoneyProduct']}
                className={classNames(styles.fullWidth, 'noBottomMargin')}
              >
                <TextField disabled placeholder='eMoney Product' />
              </FormItem>

              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const previousItems: Array<IPromotionPolicyProduct> =
                    getFieldValue('pickUpConditions') || []
                  return (
                    <>
                      <FormItem
                        name={[name, 'from']}
                        className={classNames(styles.fullWidth, 'noBottomMargin')}
                        rules={[
                          {
                            required: true,
                            validator: requiredValidator('Pickup From'),
                          },
                          {
                            validator: positiveNumber('Pickup From'),
                          },
                          {
                            validator: (_, value) => {
                              const invalidIndex = previousItems?.findIndex(
                                (item) => Number(value) <= Number(item?.to)
                              )
                              const invalidItem =
                                invalidIndex !== -1 ? previousItems[invalidIndex] : null

                              if (Number(value) && invalidItem && name !== invalidIndex) {
                                return Promise.reject(
                                  new Error(
                                    `From value should be greater than the To value of the ${
                                      invalidIndex + 1
                                    } product`
                                  )
                                )
                              }
                              return Promise.resolve()
                            },
                          },
                          ...(getFieldValue(['pickUpConditions', name, 'to'])
                            ? [
                                {
                                  validator: maxNumber(
                                    +getFieldValue(['pickUpConditions', name, 'to']),
                                    'Pickup From'
                                  ),
                                },
                              ]
                            : [{}]),
                        ]}
                      >
                        <TextField
                          type='number'
                          disabled={view}
                          placeholder='Pickup From'
                          onChange={() => {
                            validatePickupProducts('to')
                          }}
                        />
                      </FormItem>

                      <FormItem
                        name={[name, 'to']}
                        className={classNames(styles.fullWidth, 'noBottomMargin')}
                        rules={[
                          {
                            required: true,
                            validator: requiredValidator('Pickup To'),
                          },
                          {
                            validator: positiveNumber('Pickup To'),
                          },
                          ...(getFieldValue(['pickUpConditions', name, 'from'])
                            ? [
                                {
                                  validator: minNumber(
                                    +getFieldValue(['pickUpConditions', name, 'from']),
                                    'To'
                                  ),
                                },
                              ]
                            : [{}]),
                        ]}
                      >
                        <TextField
                          type='number'
                          disabled={view}
                          onChange={() => {
                            validatePickupProducts('from')
                          }}
                          placeholder='Pickup To'
                        />
                      </FormItem>
                    </>
                  )
                }}
              </FormItem>

              <FormItem
                name={[name, 'rate']}
                className={classNames(styles.fullWidth, 'noBottomMargin')}
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('Rate'),
                  },
                  {
                    validator: positiveNumber('Rate'),
                  },
                  {
                    validator: maxNumber(100, 'Rate'),
                  },
                ]}
              >
                <TextField type='number' disabled={view} placeholder='Rate' />
              </FormItem>

              {!onCreatePage && (
                <Form.Item
                  name={[name, 'status']}
                  className={classNames(styles.toggleContainer, 'noBottomMargin')}
                  valuePropName='checked'
                >
                  <Toggle disabled={view} />
                </Form.Item>
              )}

              <div>
                <IconButton
                  block
                  size='large'
                  color='orange'
                  slim
                  icon={<BucketIcon />}
                  type='default'
                  onClick={() => remove(name)}
                  className='noBottomMargin'
                  disabled={view}
                />
              </div>
            </div>
          ))}
          <div className={styles.addButton}>
            <IconButton
              icon={<AddIcon />}
              color='orange'
              block
              onClick={() => add()}
              disabled={view}
            />
          </div>
        </>
      )}
    </FormList>
  )
}
