import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import {
  IRewardPolicy,
  IRewardPolicyMutation,
  IRewardPolicyTable,
} from '../../models/IRewardPolicy'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../../shared/models/ITableConf'
import { ITableResponse } from '../../../../shared/models/ITableResponse'

export const rewardPolicyApi = createApi({
  reducerPath: 'rewardPolicy',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['PolicyList', 'IPolicy'],
  endpoints: (builder) => ({
    getRewardPolicyList: builder.query<ITableResponse<IRewardPolicyTable>, ITableConf>({
      query: (params) => ({
        url: 'reward-policies',
        params,
      }),
      providesTags: () => ['PolicyList'],
    }),

    getRewardPolicyById: builder.query<IRewardPolicy, string>({
      query: (id) => ({
        url: `reward-policies/${id}`,
      }),
      providesTags: () => ['IPolicy'],
    }),

    deleteRewardPolicy: builder.mutation<void, string | number>({
      query: (id) => ({
        url: `reward-policies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['PolicyList'],
    }),

    createRewardPolicy: builder.mutation<IRewardPolicy, IRewardPolicyMutation>({
      query: (body) => ({
        url: `reward-policies`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['PolicyList'],
    }),

    updateRewardPolicy: builder.mutation<
      IRewardPolicy,
      { id: string; body: Partial<IRewardPolicyMutation> }
    >({
      query: ({ id, body }) => ({
        url: `reward-policies/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['PolicyList', 'IPolicy'],
    }),

    exportRewardPolicyTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx'; id: string }
    >({
      query: ({ conf, formatFile }) => ({
        url: `reward-policies/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
})

export const {
  useCreateRewardPolicyMutation,
  useGetRewardPolicyByIdQuery,
  useDeleteRewardPolicyMutation,
  useLazyExportRewardPolicyTableQuery,
  useGetRewardPolicyListQuery,
  useUpdateRewardPolicyMutation,
} = rewardPolicyApi
