import FormItem from 'antd/es/form/FormItem'
import { TextField } from 'src/shared/components/textfield/TextField'
import styles from '../../VendorManagementView/Components/styles.module.scss'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import { maxLengthValidator } from 'src/helpers/validation'
import { Wrapper } from 'src/features/salesNetwork/components/Wrapper'
import { InputPassword } from '../../../../../../shared/components/inputPassword'

export const ControlPanel = ({ view }: IViewMode) => {
  return (
    <div className={styles.wrapperBlock}>
      <Wrapper title='Control Panel'>
        <FormItem
          name='controlPanelURL'
          label='URL'
          rules={[
            {
              validator: maxLengthValidator(100),
            },
          ]}
        >
          <TextField disabled={view} placeholder='Enter URL' />
        </FormItem>

        <FormItem
          name='controlPanelUsername'
          label='Username'
          rules={[
            {
              validator: maxLengthValidator(100),
            },
          ]}
        >
          <TextField disabled={view} placeholder='Enter Username' />
        </FormItem>

        <FormItem
          name='controlPanelPassword'
          label='API Password'
          className={styles.noBottomMargin}
          rules={[
            {
              validator: maxLengthValidator(100),
            },
          ]}
        >
          <InputPassword disabled={view} placeholder='Enter API Password' />
        </FormItem>
      </Wrapper>
    </div>
  )
}
