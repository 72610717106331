import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import styles from '../styles.module.scss'
import React, { useMemo } from 'react'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import { Toggle } from '../../../../../../../shared/components/toggle/Toggle'
import { Wrapper } from 'src/features/salesNetwork/components/Wrapper'
import {
  ETHNIC_GROUP_ENUM,
  GENDER_TYPE_ENUM,
  NATIONAL_ID_ENUM,
  PROFESSION_ENUM,
  REGISTRATION_CHANNEL_ENUM,
} from '../../../../../models/ICustomer'
import { Select as AntSelect } from 'antd'
import classNames from 'classnames'
import { maxLengthValidator, requiredValidator } from '../../../../../../../helpers/validation'
import { startCase } from 'lodash'
import { DatePicker } from '../../../../../../../shared/components/datePicker'
import moment from 'moment'

interface IProps extends IViewMode {
  isDetailPage?: boolean
}

export const DetailInfoBlock = ({ view, isDetailPage }: IProps) => {
  const genderType = useMemo(
    () =>
      (Object.keys(GENDER_TYPE_ENUM) as Array<keyof typeof GENDER_TYPE_ENUM>).map((method) => ({
        label: startCase(GENDER_TYPE_ENUM[method].toLowerCase()),
        value: GENDER_TYPE_ENUM[method],
      })),
    []
  )

  const nationalIdOptions = useMemo(
    () =>
      (Object.keys(NATIONAL_ID_ENUM) as Array<keyof typeof NATIONAL_ID_ENUM>).map((method) => ({
        label: NATIONAL_ID_ENUM[method],
        value: NATIONAL_ID_ENUM[method],
      })),
    []
  )

  const professionOptions = useMemo(
    () =>
      (Object.keys(PROFESSION_ENUM) as Array<keyof typeof PROFESSION_ENUM>).map((method) => ({
        label: PROFESSION_ENUM[method],
        value: PROFESSION_ENUM[method],
      })),
    []
  )

  const ethnicGroup = useMemo(
    () =>
      (Object.keys(ETHNIC_GROUP_ENUM) as Array<keyof typeof ETHNIC_GROUP_ENUM>).map((method) => ({
        label: ETHNIC_GROUP_ENUM[method],
        value: ETHNIC_GROUP_ENUM[method],
      })),
    []
  )

  const registrationChannelOptions = useMemo(
    () =>
      (Object.keys(REGISTRATION_CHANNEL_ENUM) as Array<keyof typeof REGISTRATION_CHANNEL_ENUM>).map(
        (method) => ({
          label: REGISTRATION_CHANNEL_ENUM[method],
          value: REGISTRATION_CHANNEL_ENUM[method],
        })
      ),
    []
  )

  return (
    <Wrapper
      title='Detail Info'
      statusButton={
        isDetailPage && (
          <FormItem name='status' valuePropName='checked'>
            <Toggle text='Status' disabled={view} inWrapper />
          </FormItem>
        )
      }
    >
      <div>
        <FormItem name='createdAt' label='Created At'>
          <TextField disabled />
        </FormItem>
      </div>
      <div className={styles.dblRow}>
        <FormItem
          name='firstName'
          label='First Name'
          rules={[
            { required: true, validator: requiredValidator('First Name') },
            { validator: maxLengthValidator(90) },
          ]}
        >
          <TextField disabled={view} placeholder='Select Name' />
        </FormItem>

        <FormItem
          name='lastName'
          label='Last Name'
          rules={[
            { required: true, validator: requiredValidator('Last Name') },
            { validator: maxLengthValidator(90) },
          ]}
        >
          <TextField disabled={view} placeholder='Select Name' />
        </FormItem>
      </div>

      <div>
        <FormItem name='birthDate' label='Date Of Birth'>
          <DatePicker
            disabled={view}
            disabledDate={(current) => {
              const customDate = moment().format('YYYY-MM-DD')
              return current && current > moment(customDate, 'YYYY-MM-DD')
            }}
          />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem
          name='gender'
          label='Gender'
          rules={[{ required: true, validator: requiredValidator('Gender') }]}
        >
          <AntSelect allowClear placeholder='Select Gender' disabled={view} options={genderType} />
        </FormItem>

        <FormItem name='ethnicGroup' label='Ethnic Group'>
          <AntSelect
            allowClear
            placeholder='Select Ethnic Group'
            disabled={view}
            options={ethnicGroup}
          />
        </FormItem>
      </div>

      <div>
        <FormItem name='profession' label='Profession'>
          <AntSelect
            allowClear
            placeholder='Select Profession'
            disabled={view}
            options={professionOptions}
          />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem name='nationalId' label='National ID'>
          <AntSelect
            allowClear
            placeholder='Select National ID'
            disabled={view}
            options={nationalIdOptions}
          />
        </FormItem>

        <FormItem name='nationalIdNumber' label='National ID'>
          <TextField disabled={view} placeholder='Enter National ID Number' />
        </FormItem>
      </div>

      <div>
        <FormItem
          name='registrationChannel'
          label='Registration Channel'
          className={classNames({ noBottomMargin: !isDetailPage })}
        >
          <AntSelect
            allowClear
            placeholder='Select Registration Channel'
            disabled={view}
            options={registrationChannelOptions}
          />
        </FormItem>
      </div>

      {isDetailPage && (
        <>
          <div className={styles.dblRow}>
            <FormItem name='topupCount' label='Number Of Topup'>
              <TextField disabled />
            </FormItem>

            <FormItem name='topupSum' label='Total Amount'>
              <TextField disabled />
            </FormItem>
          </div>

          <FormItem name='topupAvg' label='Average Denomination'>
            <TextField disabled />
          </FormItem>
        </>
      )}
      {isDetailPage && (
        <div className={styles.dblRow}>
          <FormItem name='updatedAt' label='Last Update At'>
            <TextField disabled />
          </FormItem>

          <FormItem name={['updatedBy', 'name']} label='Last Update By'>
            <TextField disabled />
          </FormItem>
        </div>
      )}
    </Wrapper>
  )
}
