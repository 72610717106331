import { useContext } from 'react'
import { CloudDownloadIcon } from '../../../../../../assets/svg'
import { TableExportFormatEnum } from 'src/shared/hooks/table/useTableExport'
import { CenterModalContext } from 'src/features/Modals'
import { CENTER_MODALS } from 'src/helpers/contants'
import { Emitter, EmitterEvents } from 'src/helpers/eventEmitter'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { Button } from 'src/shared/components/button/Button'

export interface IOperations {
  activeTab?: string
}

export const Operations: React.FC<IOperations> = () => {
  const { onOpen, onClose } = useContext(CenterModalContext)

  const handlerExportClick = () => {
    onOpen(CENTER_MODALS.EXPORT_TABLE, {
      title: 'Export Table',
      onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
      onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      footer: <Button onClick={onClose}>Cancel</Button>,
    })
  }

  return (
    <div>
      <IconButton
        onClick={handlerExportClick}
        icon={<CloudDownloadIcon />}
        type='primary'
        color='blue'
      />
    </div>
  )
}
