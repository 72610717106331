export const compatibleTypeList = ['image/png', 'image/gif', 'image/jpeg']
export const MAX_IMAGE_SIZE = 4

export const videoTypes = ['video/mp4', 'video/quicktime', 'video/x-msvideo']
export const imageTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/svg+xml']
export const archiveTypes = [
  'application/zip',
  'application/vnd.rar',
  'application/x-7z-compressed',
  'application/x-tar',
]
export const documentTypes = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'application/pdf',
]

export const compatibleUplaodTypes = [
  ...videoTypes,
  ...imageTypes,
  ...archiveTypes,
  ...documentTypes,
]

export const PRUploadTypes = [...imageTypes, ...archiveTypes, ...documentTypes]

export const documentVerificationTypes = ['image/png', 'image/jpeg', 'application/pdf']
