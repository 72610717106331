import { ICurrency } from './ICurency'
import { IByUser } from '../../../shared/models/IByUser'

export enum ACCOUNT_TYPE_ENUM {
  CHECKING = 'Checking',
  SAVING = 'Saving',
  WALLET = 'Wallet',
}

export enum PAYMENT_METHOD_ENUM {
  CASH = 'Cash',
  BANK_TRANSFER = 'Bank Transfer',
  BANK_DEPOSIT = 'Bank deposit',
  CHEQUE = 'Cheque',
  EXCHANGE = 'Exchange',
  CARD = 'Card',
  ADVANCE = 'Advance',
  BONUS = 'Bonus',
  CRYPTO = 'Cryptocurrency',
  STRIPE = 'Stripe',
  MOBILE_PAYMENT = 'Mobile payment',
}

export interface IBankAccount {
  id: number
  name: string
  description: string
  beneficiaryName: string
  typeOfAccount: Array<ACCOUNT_TYPE_ENUM>
  swift: string
  routingNumber: string
  currency: ICurrency
  walletBalance: number
  paymentMethod: Array<PAYMENT_METHOD_ENUM>
  logo: string
}

export interface IBankAccountList {
  id: number
  name: string
  description: string
  typeOfAccount: Array<ACCOUNT_TYPE_ENUM>
  currency: ICurrency
  paymentMethod: Array<PAYMENT_METHOD_ENUM>
  logo: string
  walletBalance: number
}

export interface IBankAccountCreation extends Omit<IBankAccount, 'id' | 'currency' | 'logo'> {
  bank: IByUser
  currencyId: ICurrency['id']
  isDeleteLogo?: boolean
  isAccessible?: boolean
}

export interface IBankAccountUpdate extends IBankAccountCreation {
  id: number
}

export interface IBankAccountBalancesChart {
  currentBalance: number
  id: number
  logo: string
  name: string
}
