import styles from '../../../styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { DatePicker } from '../../../../../../../shared/components/datePicker'
import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import React, { useCallback, useMemo } from 'react'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import {
  maxLengthValidator,
  maxNumber,
  positiveNumber,
  requiredValidator,
} from '../../../../../../../helpers/validation'
import { Form, Select, Spin } from 'antd'
import {
  useGetAccountTypeListQuery,
  useGetAgentTypeListQuery,
} from '../../../../../core/http/SystemConfigurationApi'
import { debounce, startCase } from 'lodash'
import {
  DISCOUNT_POLICY_PRODUCT_TYPE_ENUM,
  ILevelRate,
} from '../../../../../models/IDiscountPolicy'
import { ACCOUNT_TYPE_TO_ID } from '../../../../../models/IAccountType'
import { useGetMasterProductsListQuery } from '../../../../../core/http/VendorManagementApi'
import { AGENT_TYPE_ID_ENUM } from '../../../../../models/IAgentType'
import moment from 'moment'
import { Toggle } from '../../../../../../../shared/components/toggle/Toggle'
import { IPromotionPolicyProduct } from '../../../../../models/IPromotionPolicy'

interface IProps extends IViewMode {
  isEditable?: boolean
  onViewPage?: boolean
}

export const DetailInfoBlock = ({ view, isEditable }: IProps) => {
  const form = Form.useFormInstance()
  const { data: masterProducts, isFetching: isMastersFetch } = useGetMasterProductsListQuery({
    hasEMoney: true,
  })
  const { data: agentTypes, isFetching: isAgentTypesFetch } = useGetAgentTypeListQuery({})
  const { data: accountTypes, isFetching: isAccountTypesFetch } = useGetAccountTypeListQuery({})

  const productTypes = useMemo(
    () =>
      (
        Object.keys(DISCOUNT_POLICY_PRODUCT_TYPE_ENUM) as Array<
          keyof typeof DISCOUNT_POLICY_PRODUCT_TYPE_ENUM
        >
      ).map((method) => ({
        label: startCase(DISCOUNT_POLICY_PRODUCT_TYPE_ENUM[method].toLowerCase()),
        value: DISCOUNT_POLICY_PRODUCT_TYPE_ENUM[method],
      })),
    []
  )

  const onProductTypeChange = (val: string, isProductTypeChange = false) => {
    form.resetFields(['discountPolicyProducts', 'productsAccess'])
    if (!val) return
    const isIndirect = form.getFieldValue(['agentType', 'id']) === AGENT_TYPE_ID_ENUM.INDIRECT
    const selectedType = isProductTypeChange ? val : form.getFieldValue('masterProductType')

    const products = masterProducts?.items
      .filter((master) => master?.type === selectedType)
      .map((filteredMaster) => ({
        masterProduct: { id: filteredMaster.id },
        levelRates: Array.from({ length: isIndirect ? 5 : 1 }, (_, index) => ({
          level: { id: index + 1 },
          rate: null,
        })),
      }))

    form.setFieldValue('discountPolicyProducts', products)
  }

  const onMinRateChange = useCallback(
    debounce(() => {
      const discountProducts = (form.getFieldValue('discountPolicyProducts') ||
        []) as IPromotionPolicyProduct[]

      discountProducts?.forEach((prod, index) => {
        const discountRow = form.getFieldValue([
          'discountPolicyProducts',
          index,
          'levelRates',
        ]) as ILevelRate[]

        discountRow?.forEach((level, levelInd) => {
          form.validateFields([['discountPolicyProducts', index, 'levelRates', levelInd, 'rate']])
        })
      })
    }, 300),
    []
  )

  const isLoading = isMastersFetch || isAccountTypesFetch || isAgentTypesFetch

  const statusButton = (
    <FormItem name='isOpenFloating' valuePropName='checked'>
      <Toggle disabled={view} text='Is Open Floating Balance' />
    </FormItem>
  )

  const content = (
    <Wrapper title='Detail Info' statusButton={statusButton}>
      <div className={styles.dblRow}>
        <FormItem name='createdAt' label='Creation Date'>
          <TextField disabled />
        </FormItem>

        <FormItem name={['createdByUser', 'name']} label='Created By'>
          <TextField disabled />
        </FormItem>
      </div>

      <div>
        <FormItem
          name='name'
          label='Policy Name'
          rules={[
            {
              required: true,
              validator: requiredValidator('Policy Name'),
            },
            {
              validator: maxLengthValidator(45),
            },
          ]}
        >
          <TextField disabled={view} placeholder='Enter Policy Name' />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <div className={styles.doubleRow}>
          <FormItem noStyle shouldUpdate>
            {({ setFieldValue }) => (
              <FormItem
                name='start'
                label='Starting Date'
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('Starting Date'),
                  },
                ]}
              >
                <DatePicker
                  showTime
                  disabled={view}
                  onChange={() => {
                    setFieldValue('end', null)
                  }}
                />
              </FormItem>
            )}
          </FormItem>

          <FormItem noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <FormItem
                name='end'
                label='Expiry Date'
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('Expiry Date'),
                  },
                ]}
              >
                <DatePicker
                  showTime
                  disabled={view || !getFieldValue('start')}
                  disabledDate={(current) =>
                    getFieldValue('start')
                      ? current.isBefore(moment(getFieldValue('start')), 'days') ||
                        current.isSame(moment(getFieldValue('start')), 'days')
                      : false
                  }
                />
              </FormItem>
            )}
          </FormItem>
        </div>
        <div className={styles.doubleRow}>
          <FormItem
            name={['agentType', 'id']}
            label='Agent Type'
            rules={[
              {
                required: true,
                validator: requiredValidator('Agent Type'),
              },
            ]}
          >
            <Select
              disabled={view || !isEditable}
              allowClear
              placeholder='Select Agent Type'
              onChange={(val) => {
                form.resetFields(['discountPolicyProducts'])
                onProductTypeChange(val)
              }}
            >
              {agentTypes?.items?.map((type) => (
                <Select.Option key={type.id} value={type.id}>
                  {type?.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>

          <FormItem
            name={['accountType', 'id']}
            label='Account Type'
            rules={[
              {
                required: true,
                validator: requiredValidator('Account Type'),
              },
            ]}
          >
            <Select
              disabled={view || !isEditable}
              allowClear
              placeholder='Select Account Type'
              onChange={(val) => {
                form.resetFields(['masterProductType', 'discountPolicyProducts', 'productsAccess'])
                onProductTypeChange(val)
              }}
            >
              {accountTypes?.items?.map((type) => (
                <Select.Option key={type.id} value={type.id}>
                  {type?.accountType}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </div>
      </div>

      <div className={styles.dblRow}>
        <div className={styles.doubleRow}>
          <FormItem
            name='isOpen'
            label='Commission Type'
            className='noBottomMargin'
            rules={[
              {
                required: true,
                validator: requiredValidator('Commission Type'),
              },
            ]}
          >
            <Select allowClear disabled={view} placeholder='Select Commission Type'>
              <Select.Option value={true}>Open Commission</Select.Option>
              <Select.Option value={false}>Close Commission</Select.Option>
            </Select>
          </FormItem>

          <FormItem
            name='minRate'
            label='Minimum Commission Rate'
            className='noBottomMargin'
            rules={[
              {
                required: true,
                validator: requiredValidator('Minimum Commission Rate'),
              },
              {
                validator: positiveNumber('Minimum Commission Rate'),
              },
              {
                validator: maxNumber(100, 'Minimum Commission Rate'),
              },
            ]}
          >
            <TextField
              type='number'
              disabled={view}
              placeholder='Enter Minimum Commission Rate'
              onChange={onMinRateChange}
            />
          </FormItem>
        </div>
        <div>
          <FormItem noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const accountType = Number(getFieldValue(['accountType', 'id']))
              const isPrepaid = accountType === ACCOUNT_TYPE_TO_ID.PREPAID

              return (
                <FormItem
                  name='masterProductType'
                  label='Product Type'
                  className='noBottomMargin'
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator('Product Type'),
                    },
                  ]}
                >
                  <Select
                    disabled={view || !accountType || !isEditable}
                    allowClear
                    placeholder='Select Product Type'
                    onChange={(val) => {
                      onProductTypeChange(val, true)
                    }}
                  >
                    {productTypes
                      .filter((masterProductType) => {
                        if (isPrepaid) {
                          return (
                            masterProductType.value === DISCOUNT_POLICY_PRODUCT_TYPE_ENUM.E_MONEY
                          )
                        }
                        return masterProductType.value !== DISCOUNT_POLICY_PRODUCT_TYPE_ENUM.E_MONEY
                      })
                      .map((type) => (
                        <Select.Option value={type.value} key={type.value}>
                          {type.label}
                        </Select.Option>
                      ))}
                  </Select>
                </FormItem>
              )
            }}
          </FormItem>
        </div>
      </div>
    </Wrapper>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
