import React, { useCallback, useEffect, useState } from 'react'
import { Form, Row, Spin, UploadFile } from 'antd'
import { useNavigate, useParams } from 'react-router'
import { debounce, isEmpty } from 'lodash'

// components
import { Button } from 'src/shared/components/button/Button'
import { DetailInfo } from './Components/DetailInfo'
import { StatusInfo } from './Components/StatusInfo'
import { LogOfOrder } from './Components/LogOfOrder'

// interfaces
import { IPickupRequestForm } from 'src/features/Finance/models/IPickupRequest'

// hooks
import {
  useAddAttachmentsPRMutation,
  useCreatePickupRequestMutation,
  useGetPickupRequestQuery,
  useUpdatePickupRequestMutation,
} from 'src/features/Finance/core/http/BankWalletTransactionsApi'
import {
  agentManagementApi,
  useLazyGetAgentByIdQuery,
  useLazyGetRootAgentByIdQuery,
} from 'src/features/salesNetwork/core/http/AgentManagement'
import { useGetBankAccountsQuery } from 'src/features/Finance/core/http/BankAccountApi'

// utils
import { ErrorNode } from 'src/shared/api/errorHandler'
import { formatOnlyDate } from 'src/helpers/utils'
import { PickupRequestModeEnum } from '../../index'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'

// styles
import styles from './styles.module.scss'
import { IPurchaseOrderStatus } from '../../../../models/IPurchaseOrder'
import usePermissions from '../../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../../Settings/models/IPermissions'
import PermissionDenied from '../../../../../../shared/components/permissionDenied'
import { FINANCE_ENUM } from '../../../../../../routes/finance'
import PickupSummary from './Components/PickupSummary'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from '../../../../../../redux'
import { TRANSACTION_STATUS_ENUM } from '../../../../models/ITransaction'
import { UploaderFiles } from '../../../../../../shared/components/uploaderPhotos/UploaderFiles'
import { PRUploadTypes } from '../../../../../../shared/components/uploaderPhotos/constants'
import classNames from 'classnames'

export const PickupRequest = () => {
  const dispatch = useAppDispatch()
  const { mode, prId } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { data: profile } = useGetProfileQuery()

  const [form] = Form.useForm()
  const [files, setFiles] = useState<UploadFile[]>([])
  const { data, isFetching } = useGetPickupRequestQuery(prId, { skip: !prId })
  const { data: bankAccountList } = useGetBankAccountsQuery({})

  const [createPR, dCreate] = useCreatePickupRequestMutation()
  const [updatePR, dUpdate] = useUpdatePickupRequestMutation()

  const [getAgent, dAgent] = useLazyGetAgentByIdQuery()
  const [getRootAgent, dRootAgent] = useLazyGetRootAgentByIdQuery()

  const [addAttachmentPO, dAttahcment] = useAddAttachmentsPRMutation()

  const isViewMode = mode === PickupRequestModeEnum.view
  const isEditMode = mode === PickupRequestModeEnum.edit
  const isNewMode = !isViewMode && !isEditMode
  const isLoading = dCreate.isLoading || dUpdate.isLoading // only for mutation
  const submitBtnText = isEditMode ? 'Edit Pickup Request' : 'Create Pickup Request'

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const [pickupsId] = getModulesIdsByNames([IPermissionsNames.Pickup])
  const demandPermissions = [
    IPermissionsActions.VIEW,
    IPermissionsActions.EDIT,
    IPermissionsActions.ADD,
  ]
  const [canViewPickup, canEditPickup, canAddPickup] = canPerformAction(
    pickupsId,
    demandPermissions
  )
  const notAllowedContent =
    (isViewMode && !canViewPickup) || (isEditMode && !canEditPickup) || (isNewMode && !canAddPickup)

  const afterCreate = () => {
    dispatch(agentManagementApi.util.invalidateTags(['TransactionHistory']))
    navigate(FINANCE_ENUM.BANK_WALLET)
  }

  const handleNotExistingAgent = () => {
    form.setFieldValue(['agent', 'name'], null)
    form.setFieldValue('parentAgentId', null)
    form.setFieldValue('parentAgentName', null)
    form.setFieldValue('accountType', null)
  }

  useNotification(NOTIFICATION_TYPES.success, dUpdate.isSuccess, null, afterCreate)
  useNotification(NOTIFICATION_TYPES.success, dCreate.isSuccess, null, afterCreate)
  useNotification(NOTIFICATION_TYPES.error, dUpdate.isError, dUpdate.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, dCreate.isError, dCreate.error as ErrorNode)
  useNotification(
    NOTIFICATION_TYPES.error,
    dAgent.isError,
    dAgent.error as ErrorNode,
    handleNotExistingAgent
  )

  // eslint-disable-next-line
  const debounceGetAgent = useCallback(
    debounce((value) => {
      Promise.all([getAgent(value), getRootAgent(value)])
    }, 300),
    []
  )

  const handlerAgentIdChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.value) {
      return handleNotExistingAgent()
    }
    debounceGetAgent(event.target.value)
  }

  const dispatchFiles = (arr: UploadFile[]) => setFiles(arr)

  const onSaveAttachments = () => {
    const fd = new FormData()

    files.forEach((value) => value.originFileObj && fd.append('files', value.originFileObj!))

    addAttachmentPO({
      id: prId as string,
      body: fd,
    })
  }

  const handleFinish = (values: IPickupRequestForm) => {
    const { agent, currencyAmount, note, bankAccount, paymentType } = values

    const body = {
      agentId: +agent.id,
      currencyAmount: +currencyAmount,
      bankAccountId: Number(bankAccount?.id),
      paymentType,
      note,
    }

    const fd = new FormData()

    Object.entries(body)?.forEach(([key, val]) => {
      if (val) {
        fd.append(key, val as string)
      }
    })

    if (files.length) {
      files.forEach((value) => value.originFileObj && fd.append('files', value.originFileObj!))
    }

    isEditMode ? updatePR({ id: prId!, body }) : createPR(fd)
  }

  useEffect(() => {
    if (isEmpty(data)) return
    const { agent } = data
    form.setFieldsValue({ ...data, createdAt: formatOnlyDate(data.createdAt, profile?.calendar) })
    if (agent?.id) {
      getRootAgent(agent?.id)
    }
  }, [data, form, getRootAgent])

  useEffect(() => {
    if (isEmpty(dAgent.data)) return
    const agent = dAgent.data

    form.setFieldValue(
      ['agent', 'name'],
      agent.companyTitle ? `${agent.companyTitle}` : `${agent.firstName} ${agent.lastName}`
    )
    form.setFieldsValue({
      promotionAmount: data?.promotionAmount,
    })
  }, [form, data, dAgent.data, dAgent.requestId])

  useEffect(() => {
    if (isEmpty(dRootAgent.data)) return
    const rootAgent = dRootAgent.data

    form.setFieldValue('accountType', rootAgent?.accountType?.accountType)
    form.setFieldValue('parentAgentId', `${rootAgent?.id}`)
    form.setFieldValue(
      'parentAgentName',
      `${rootAgent?.id} ${
        rootAgent?.companyTitle ?? `${rootAgent?.firstName} ${rootAgent?.lastName}`
      }`
    )
  }, [dRootAgent.data, dRootAgent.requestId, data, form])

  // SET DEFAULT AGENT ID FROM EXTERNAL CHAT
  useEffect(() => {
    if (state?.agentId) {
      form.setFieldValue(['agent', 'id'], state.agentId)
      debounceGetAgent(state.agentId)
    }
  }, [debounceGetAgent, form, state?.agentId])

  const setDiscountAmount = (value: number) => {
    if (!value) form.setFieldValue('amount', null)

    if (isEmpty(dAgent.data)) return

    if (!dAgent.data?.discountPolicies?.length) return

    form.setFieldValue(
      'discountAmount',
      ((value / 100) * dAgent.data?.discountPolicies[0].minRate).toFixed(2)
    )
  }

  const content = (
    <>
      {notAllowedContent && <PermissionDenied />}
      {((isViewMode && canViewPickup) ||
        (isEditMode && canEditPickup) ||
        (isNewMode && canAddPickup)) && (
        <main className={styles.layout}>
          <Form
            form={form}
            layout='vertical'
            initialValues={data}
            className={styles.pickupForm}
            onFinish={handleFinish}
          >
            <Row wrap={false} justify='start'>
              <div className={styles.leftColumn}>
                <DetailInfo
                  form={form}
                  viewMode={isViewMode}
                  editMode={isEditMode}
                  bankAccountList={bankAccountList?.items}
                  onChangeAgent={handlerAgentIdChange}
                  bankAccountId={form.getFieldValue(['bankAccount', 'id'])}
                  isPickupReq
                  setDiscountAmount={setDiscountAmount}
                />
              </div>
              <div
                className={classNames(styles.rightColumn, {
                  [styles.withPadding]: isViewMode || isEditMode,
                })}
              >
                <PickupSummary />
                {(data?.status === TRANSACTION_STATUS_ENUM.CREATED || !data) && (
                  <UploaderFiles
                    allowTypes={PRUploadTypes}
                    attachmentPropName='receipt'
                    btnTitle='Attach File'
                    viewFiles={data?.agentRequestReceipts}
                    dispatchData={dispatchFiles}
                    onSave={isViewMode || isEditMode ? onSaveAttachments : undefined}
                    isLoading={dAttahcment?.isLoading}
                    isStyledTitle
                  />
                )}
                {(isViewMode || isEditMode) && (
                  <>
                    <StatusInfo status={data?.status as string} />
                    <LogOfOrder logs={data?.AgentRequestSubTransaction as IPurchaseOrderStatus[]} />
                  </>
                )}
              </div>
            </Row>
          </Form>
        </main>
      )}
    </>
  )

  return (
    <React.Fragment>
      <Spin spinning={isLoading || isFetching || dAgent.isFetching}>{content}</Spin>

      {!isViewMode && (
        <Row className={styles.footer} justify='end'>
          <Button color='blue' size='middle' onClick={afterCreate}>
            Cancel
          </Button>
          {((isNewMode && canAddPickup) || (isEditMode && canEditPickup)) && (
            <Button
              style={{ marginLeft: '10px' }}
              htmlType='submit'
              size='middle'
              type='primary'
              color='orange'
              onClick={() => form.submit()}
              disabled={isLoading}
            >
              {submitBtnText}
            </Button>
          )}
        </Row>
      )}
    </React.Fragment>
  )
}
