import { Route } from 'use-react-router-breadcrumbs'
import { DashboardOverview } from '../../features/Dashboard/pages/Overview'

export enum DASHBOARD_ENUM {
  OVERVIEW = 'dashboard/overview',
}

export default () => {
  return (
    <>
      <Route path={DASHBOARD_ENUM.OVERVIEW} element={<DashboardOverview />} />
    </>
  )
}
