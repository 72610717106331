import { AgentManagementTabsEnum } from '../../AgentManagement.d'
import './style.scss'
import { Space, Tabs } from 'antd'
import { AgentAllTable } from '../AllTable'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { AddIcon, CloudDownloadIcon, RefreshCircleIcon } from 'src/assets/svg'
import { useNavigate } from 'react-router-dom'
import { SALES_ENUM } from 'src/routes/sales'
import { useTabsContext } from '../../AgentManagement'
import { Emitter, EmitterEvents } from 'src/helpers/eventEmitter'
import { useContext, useMemo } from 'react'
import { CenterModalContext, RightModalContext } from 'src/features/Modals'
import { CENTER_MODALS, RIGHT_MODALS } from 'src/helpers/contants'
import { TableExportFormatEnum } from 'src/shared/hooks/table/useTableExport'
import { Button } from 'src/shared/components/button/Button'
import usePermissions from '../../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../../Settings/models/IPermissions'
import WithPermissionDeniedContainer from '../../../../../../shared/components/withPermissionDenied'

interface IOperations {
  activeTab?: string
  canExport: boolean
  canAddNew: boolean
}

const Operations = ({ canExport, canAddNew }: IOperations) => {
  const navigate = useNavigate()
  const centerModalContext = useContext(CenterModalContext)
  const rightModalContext = useContext(RightModalContext)

  const handlerExportClick = () => {
    centerModalContext.onOpen(CENTER_MODALS.EXPORT_TABLE, {
      title: 'Export Table',
      onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
      onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      footer: <Button onClick={centerModalContext.onClose}>Cancel</Button>,
    })
  }

  const handlerTransferClick = () => {
    rightModalContext.onOpen(RIGHT_MODALS.SUPPORT.CHANGE_MPIN, {
      title: 'Transfer Sales Point',
    })
  }

  return (
    <Space size={10}>
      <IconButton
        icon={<RefreshCircleIcon />}
        type='default'
        color='blue'
        onClick={handlerTransferClick}
      >
        Transfer Sales Point
      </IconButton>
      {canExport && (
        <IconButton
          onClick={handlerExportClick}
          icon={<CloudDownloadIcon />}
          type='primary'
          color='blue'
        />
      )}
      {canAddNew && (
        <IconButton
          icon={<AddIcon />}
          type='primary'
          color='orange'
          onClick={() => navigate(SALES_ENUM.NEW_AGENT, { replace: true })}
        />
      )}
    </Space>
  )
}

export const AgentManagementTabs = () => {
  const { state, setState } = useTabsContext()

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()

  const [agentsId] = getModulesIdsByNames([IPermissionsNames['Agent List']])
  const [agentId] = getModulesIdsByNames([IPermissionsNames['Sales Agent Profile']])
  const [statusAgentId] = getModulesIdsByNames([IPermissionsNames['Status (Active/Inactive)']])

  const demandPermissions = [
    IPermissionsActions.VIEW,
    IPermissionsActions.EXPORT,
    IPermissionsActions.ADD,
  ]

  const [canViewAgents, canExportAgents, canAddAgent] = canPerformAction(
    agentsId,
    demandPermissions
  )
  const [canViewAgent, canEditAgent, canDeleteAgent] = canPerformAction(agentId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.EDIT,
    IPermissionsActions.DELETE,
  ])
  const [canEditAgentStatus] = canPerformAction(statusAgentId, [IPermissionsActions.EDIT])

  /** Table */
  const items = useMemo(
    () => [
      {
        label: AgentManagementTabsEnum.ALL,
        key: AgentManagementTabsEnum.ALL,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canViewAgents}>
            <AgentAllTable
              canView={canViewAgent}
              canEdit={canEditAgent}
              canDelete={canDeleteAgent}
              canEditStatus={canEditAgentStatus}
            />
          </WithPermissionDeniedContainer>
        ),
      },
    ],
    [canDeleteAgent, canEditAgent, canEditAgentStatus, canViewAgent, canViewAgents]
  )

  return (
    <Tabs
      destroyInactiveTabPane
      activeKey={state.activeTab}
      tabBarExtraContent={
        <Operations
          activeTab={state?.activeTab}
          canAddNew={canAddAgent}
          canExport={canExportAgents}
        />
      }
      items={items}
      onChange={(activeTab: string) => setState({ activeTab })}
    />
  )
}
