import React, { useContext } from 'react'
import { Tabs } from 'antd/es'
import { NotFoundPage } from '../../../../../NotFoundPage'
import { useGetDefaultTab } from '../../../../../salesNetwork/helpers/useGetDefaultTab'
import { RECONCILIATION_TABS_ENUM } from './index.d'
import { CENTER_MODALS, RIGHT_MODALS } from '../../../../../../helpers/contants'
import { CenterModalContext, RightModalContext } from '../../../../../Modals'
import { AddIcon, CloudDownloadIcon } from '../../../../../../assets/svg'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { Emitter, EmitterEvents } from '../../../../../../helpers/eventEmitter'
import { TableExportFormatEnum } from '../../../../../../shared/hooks/table/useTableExport'
import { Button } from '../../../../../../shared/components/button/Button'
import AllReconciliation from './AllReconciliation'
import styles from '../../styles.module.scss'

const Operations = () => {
  const centerModalContext = useContext(CenterModalContext)
  const rightModalContext = useContext(RightModalContext)

  const onAddReconciliation = () => {
    rightModalContext.onOpen(RIGHT_MODALS.FINANCE.RECONCILIATION, {
      title: 'Reconciliation',
    })
  }

  const handlerExportClick = () => {
    centerModalContext.onOpen(CENTER_MODALS.EXPORT_TABLE, {
      title: 'Export Table',
      onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
      onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      footer: <Button onClick={centerModalContext.onClose}>Cancel</Button>,
    })
  }

  return (
    <div className={styles.btnsContainer}>
      <IconButton
        onClick={handlerExportClick}
        icon={<CloudDownloadIcon />}
        type='primary'
        color='blue'
      />
      <IconButton
        color='orange'
        type='primary'
        icon={<AddIcon />}
        onClick={onAddReconciliation}
      ></IconButton>
    </div>
  )
}

const ReconciliationTabs = () => {
  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: RECONCILIATION_TABS_ENUM.ALL,
  })

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  const items = [
    {
      label: RECONCILIATION_TABS_ENUM.ALL,
      key: RECONCILIATION_TABS_ENUM.ALL,
      children: <AllReconciliation />,
    },
    {
      label: RECONCILIATION_TABS_ENUM.UNRECONCILED,
      key: RECONCILIATION_TABS_ENUM.UNRECONCILED,
      children: <NotFoundPage />,
    },
    {
      label: RECONCILIATION_TABS_ENUM.RECONCILED,
      key: RECONCILIATION_TABS_ENUM.RECONCILED,
      children: <NotFoundPage />,
    },
    {
      label: RECONCILIATION_TABS_ENUM.UPDATING,
      key: RECONCILIATION_TABS_ENUM.UPDATING,
      children: <NotFoundPage />,
    },
  ]
  return (
    <Tabs
      destroyInactiveTabPane
      activeKey={activeTab}
      items={items}
      onChange={onTabChange}
      tabBarExtraContent={<Operations />}
    />
  )
}

export default ReconciliationTabs
