import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import React, { useCallback } from 'react'
import { debounce, isNumber } from 'lodash'
import {
  IPromotionPolicyProduct,
  PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM,
} from '../../../../../models/IPromotionPolicy'
import styles from '../../../styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import classNames from 'classnames'
import {
  maxNumber,
  minNumber,
  positiveNumber,
  requiredValidator,
} from '../../../../../../../helpers/validation'
import { Form, Select } from 'antd'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { Toggle } from '../../../../../../../shared/components/toggle/Toggle'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { BucketIcon } from '../../../../../../../assets/svg'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import { ITableResponse } from '../../../../../../../shared/models/ITableResponse'
import { IMasterProduct } from '../../../../../models/IMasterProduct'

interface IProps extends IViewMode {
  data: ITableResponse<IMasterProduct>
  name: number
  remove: (value: number) => void
  measurementType?: PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM
  onViewPage?: boolean
}

export const MasterProductListItem = ({
  data,
  name,
  remove,
  view,
  measurementType,
  onViewPage,
}: IProps) => {
  const form = useFormInstance()

  // eslint-disable-next-line
  const validatePromotionProducts = useCallback(
    debounce((fieldName: 'from' | 'to') => {
      const promotionPolProducts = (form.getFieldValue('masterProducts') ||
        []) as IPromotionPolicyProduct[]

      promotionPolProducts?.forEach((prod, index) => {
        if (form.getFieldValue(['masterProducts', index, fieldName])) {
          form.validateFields([['masterProducts', index, fieldName]])
        }
      })
    }, 300),
    []
  )
  return (
    <div
      className={classNames(styles.masterProductItemContainer, {
        [styles.masterProductItemContainerView]: onViewPage,
      })}
    >
      <FormItem
        name={[name, 'masterProduct', 'id']}
        className={classNames(styles.fullWidth, 'noBottomMargin')}
        rules={[
          {
            required: true,
            validator: requiredValidator('Master Product'),
          },
        ]}
      >
        <Select
          placeholder='Select Master Product'
          disabled={view}
          allowClear
          onChange={() => validatePromotionProducts('from')}
        >
          {data?.items.map((product) => (
            <Select.Option key={product.id} value={product.id}>
              {product.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>

      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const previousItems: Array<IPromotionPolicyProduct> =
            getFieldValue('masterProducts') || []
          return (
            <>
              <FormItem
                name={[name, 'from']}
                className={classNames(styles.fullWidth, 'noBottomMargin')}
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('From'),
                  },
                  {
                    validator: positiveNumber('From'),
                  },
                  {
                    validator: (_, value) => {
                      const currentMasterProd = getFieldValue([
                        'masterProducts',
                        name,
                        'masterProduct',
                        'id',
                      ])
                      const invalidIndex = previousItems?.findIndex(
                        (item) =>
                          item?.masterProduct?.id === currentMasterProd &&
                          Number(value) <= Number(item?.to)
                      )
                      const invalidItem = invalidIndex !== -1 ? previousItems[invalidIndex] : null

                      if (Number(value) && invalidItem && name !== invalidIndex) {
                        return Promise.reject(
                          new Error(
                            `From value should be greater than the To value of the ${
                              invalidIndex + 1
                            } product`
                          )
                        )
                      }
                      return Promise.resolve()
                    },
                  },
                  ...(getFieldValue(['masterProducts', name, 'to'])
                    ? [
                        {
                          validator: maxNumber(
                            +getFieldValue(['masterProducts', name, 'to']),
                            'From'
                          ),
                        },
                      ]
                    : [{}]),
                ]}
              >
                <TextField
                  type='number'
                  disabled={view}
                  placeholder='From'
                  onChange={() => {
                    validatePromotionProducts('to')
                  }}
                />
              </FormItem>

              <FormItem
                name={[name, 'to']}
                className={classNames(styles.fullWidth, 'noBottomMargin')}
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('To'),
                  },
                  {
                    validator: positiveNumber('To'),
                  },
                  ...(isNumber(Number(getFieldValue(['masterProducts', name, 'from'])))
                    ? [
                        {
                          validator: minNumber(
                            +getFieldValue(['masterProducts', name, 'from']),
                            'To'
                          ),
                        },
                      ]
                    : [{}]),
                ]}
              >
                <TextField
                  type='number'
                  disabled={view}
                  placeholder='To'
                  onChange={() => {
                    validatePromotionProducts('from')
                  }}
                />
              </FormItem>
            </>
          )
        }}
      </FormItem>

      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const typeOfMeasurement = measurementType ?? getFieldValue('measurementType')
          return (
            <FormItem
              name={[name, 'rate']}
              className={classNames(styles.fullWidth, 'noBottomMargin')}
              rules={[
                {
                  required: true,
                  validator: requiredValidator('Rate'),
                },
                {
                  validator: positiveNumber('Rate'),
                },
                ...(typeOfMeasurement === PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM.PERCENTAGE
                  ? [
                      {
                        validator: maxNumber(100, 'Rate'),
                      },
                    ]
                  : []),
              ]}
            >
              <TextField type='number' disabled={view} placeholder='Rate' />
            </FormItem>
          )
        }}
      </FormItem>

      <Form.Item
        name={[name, 'status']}
        className={classNames(styles.toggleContainer, 'noBottomMargin')}
        valuePropName='checked'
      >
        <Toggle disabled={view} />
      </Form.Item>

      <div>
        <IconButton
          block
          size='large'
          color='orange'
          slim
          icon={<BucketIcon />}
          type='default'
          onClick={() => remove(name)}
          className='noBottomMargin'
          disabled={view}
        />
      </div>
    </div>
  )
}
