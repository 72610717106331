import { ColumnsType } from 'antd/es/table'
import { Table } from 'antd'
import styles from '../../../styles.module.scss'
import { useParams } from 'react-router'
import useTable from '../../../../../../../shared/hooks/table/useTable'
import { useEmptyTable } from '../../../../../../../shared/hooks/table/useEmptyTable'
import { ITableConf } from '../../../../../../../shared/models/ITableConf'
import { useEffect, useState } from 'react'
import { useGetDiscountPolicyByIdQuery } from '../../../../../core/http/DiscountPolicyApi'

interface ITargetGroup {
  agentType: string
  accountType: string
  groupSize: string
  id: string
}

export const TargetGroupTable = () => {
  const { id } = useParams()
  const [, setTableConf] = useState<ITableConf>({})
  const [data, setData] = useState<ITargetGroup[]>([])

  const { data: discountPolicy, isFetching } = useGetDiscountPolicyByIdQuery(id as string, {
    skip: !id,
  })

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange } = useTable<ITargetGroup>({
    total: 1,
    setTableConf,
  })

  useEffect(() => {
    if (discountPolicy) {
      const mappedData = [
        {
          id: id as string,
          agentType: discountPolicy?.agentType?.name,
          accountType: discountPolicy?.accountType?.accountType,
          groupSize: discountPolicy?.allowedAgents?.length,
        },
      ]
      setData(mappedData as unknown as ITargetGroup[])
    }
  }, [discountPolicy, id])

  const columns: ColumnsType<ITargetGroup> = [
    {
      title: 'Agent Type',
      dataIndex: 'agentType',
      width: '30%',
    },
    {
      title: 'Wallet Type',
      dataIndex: 'accountType',
      width: '30%',
    },
    {
      title: 'Agent Level',
      width: '20%',
      render: () => <span>1</span>,
    },
    {
      title: 'Group Size',
      dataIndex: 'groupSize',
      width: '15%',
    },
  ]

  return (
    <>
      <Table
        locale={emptyTableConf}
        onChange={handleTableChange}
        pagination={pagination}
        columns={columns}
        className={styles.table}
        scroll={{ x: 1000 }}
        rowKey={(record) => record.id}
        rowSelection={{ type: 'checkbox' }}
        dataSource={data}
        loading={isFetching}
      />
    </>
  )
}
