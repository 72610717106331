import styles from './styles.module.scss'
import { Col, Form, Row, Typography } from 'antd'
import { CheckMarkIcon, ChevronDoubleUpIcon, CloseIcon, FilterIcon } from '../../../assets/svg'
import { FilterFields } from './FilterFields'
import { Button } from '../button/Button'
import { IconButton } from '../iconButton/IconButton'
import { ISelectedFilters } from './Filter'
import { IFilterFields } from '../../models/IFilterFields'
import { IUseFilterReturn } from '../../hooks/filter/useFilter'
import classNames from 'classnames'
import ErrorBoundary from '../errorBoundary/ErrorBoundary'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { isIsoDate } from './SelectedFilters'
import { getFormCalendarTime } from 'src/helpers/utils'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

const listOfRangeFields = ['date', 'createdAt', 'updatedAt']

interface IProps extends Omit<IUseFilterReturn, 'isOpen'> {
  numberOfItems?: number
  setActiveFilters: (values: ISelectedFilters) => void
  filterValues: ISelectedFilters
  data?: IFilterFields[]
  className?: string
}

export const FilterPopup = ({
  onReset,
  onFinish,
  onCancel,
  form,
  data,
  filterValues,
  className,
}: IProps) => {
  const { data: profile } = useGetProfileQuery()
  const [width, setWidth] = useState<number>(1500)
  const ref = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    if (ref.current) {
      setWidth(ref.current?.clientWidth)
    }
  }, [])

  useEffect(() => {
    if (filterValues) {
      Object.entries(filterValues).forEach(([key, val]) => {
        listOfRangeFields.forEach((i) => {
          if (key.match(i)) {
            form.setFieldValue(i, [
              getFormCalendarTime(profile!.calendar, filterValues[`${i}_less`] as string),
              getFormCalendarTime(profile!.calendar, filterValues[`${i}_more`] as string),
            ])
          }
        })

        form.setFieldValue(
          key,
          isIsoDate(val as never) ? getFormCalendarTime(profile!.calendar, val as string) : val
        )
      })
    }
  }, [filterValues, form, profile])

  return (
    <ErrorBoundary>
      <div
        className={classNames(styles.filter__popup, className, { [styles.small]: width < 700 })}
        ref={ref}
      >
        <Row justify='space-between' align='middle' style={{ marginBottom: 20 }}>
          <Col flex={1}>
            <Row align='middle' className='filter__popup-header'>
              <FilterIcon />
              <Typography>Filters</Typography>
            </Row>
          </Col>
          <Col onClick={onReset}>
            <div className='filter-close__icon'>
              <CloseIcon />
            </div>
          </Col>
        </Row>
        <Form name='filter' onFinish={onFinish} form={form}>
          <FilterFields data={data} onChange={form.setFieldValue} />
          <div className='filter-bottom__actions'>
            <Button color='orange' onClick={onCancel}>
              Cancel
            </Button>
            <div style={{ width: 160 }}>
              <IconButton
                color='orange'
                icon={<CheckMarkIcon />}
                type='primary'
                htmlType='submit'
                block
              >
                Apply
              </IconButton>
            </div>
          </div>
        </Form>
        <Row justify='center' className='chevronUp' onClick={onCancel}>
          <ChevronDoubleUpIcon />
        </Row>
      </div>
    </ErrorBoundary>
  )
}
