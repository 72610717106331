import React, { useCallback } from 'react'
import VendorIntegrationForm from '../../IntegrationForm'
import { Form } from 'antd'

const VendorIntegrationAuthorization = () => {
  const [form] = Form.useForm()

  const onSubmitForm = useCallback(() => {}, [])

  return <VendorIntegrationForm form={form} onFinish={onSubmitForm} />
}

export default VendorIntegrationAuthorization
