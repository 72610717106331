import { useContext } from 'react'
import { RightModalContext } from '../../../features/Modals'
import { TRANSACTION_CATEGORY_ENUM } from '../../../features/Finance/models/ITransaction'
import { RIGHT_MODALS } from '../../../helpers/contants'

export const useOnTransactionHistoryClick = (onCloseRedirect?: () => void) => {
  const { onOpen } = useContext(RightModalContext)

  return (id: number, category: TRANSACTION_CATEGORY_ENUM) => {
    if (
      category === TRANSACTION_CATEGORY_ENUM.PICKUP ||
      category === TRANSACTION_CATEGORY_ENUM.PICKUP_REQUEST
    ) {
      return onOpen(RIGHT_MODALS.FINANCE.PICKUP_REQUEST_CHANGES, {
        title: 'Pickup History',
        onCloseRedirect,
        id,
      })
    }

    if (category === TRANSACTION_CATEGORY_ENUM.REFUND) {
      return onOpen(RIGHT_MODALS.FINANCE.REFUND_REQUEST_CHANGES, {
        title: 'Refund Request History',
        onCloseRedirect,
        id,
      })
    }

    if (category === TRANSACTION_CATEGORY_ENUM.PURCHASE_ORDER) {
      return onOpen(RIGHT_MODALS.FINANCE.PURCHASE_ORDER_CHANGES, {
        title: 'Purchase Order Changes',
        onCloseRedirect,
        id,
      })
    }
  }
}
