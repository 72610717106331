import styles from '../../VendorManagementView/Components/styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from 'src/shared/components/textfield/TextField'
import {
  useGetCountryListQuery,
  useGetVendorByIdQuery,
  useLazyGetDistrictListQuery,
  useLazyGetProvinceListQuery,
} from '../../../../core/http/VendorManagementApi'
import { IViewMode } from '../../../../../Finance/models/IViewMode'

import { Select as AntSelect } from 'antd'
import { maxLengthValidator, requiredValidator } from 'src/helpers/validation'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

export const Address = ({ view }: IViewMode) => {
  const { id } = useParams<{ id: string }>()
  const form = useFormInstance()

  const { data: dataVendor } = useGetVendorByIdQuery(id as string, { skip: !id })
  const { data: dataCountry = { items: [] } } = useGetCountryListQuery()
  const [getProvinceByCountryId, { data: dataProvince = { items: [] } }] =
    useLazyGetProvinceListQuery()
  const [getDistrictByProvinceId, { data: dataDistrict = { items: [] } }] =
    useLazyGetDistrictListQuery()

  const onProvinceChange = (value: number) => {
    getDistrictByProvinceId(value)
    form.setFieldValue('districtId', null)
  }

  const clearProvinceAndDistrict = () => {
    form.setFields([
      { name: 'provinceId', value: null, errors: [] },
      { name: 'districtId', value: null, errors: [] },
    ])
  }

  useEffect(() => {
    if (dataCountry && form.getFieldValue('countryId')) {
      getProvinceByCountryId(form.getFieldValue('countryId'))
    }
  }, [dataCountry, form, getProvinceByCountryId])

  useEffect(() => {
    if (dataProvince && form.getFieldValue('provinceId')) {
      getDistrictByProvinceId(form.getFieldValue('provinceId'))
    }
  }, [dataCountry, dataProvince, form, getDistrictByProvinceId, getProvinceByCountryId])

  useEffect(() => {
    if (dataVendor) {
      getProvinceByCountryId(dataVendor.country.value)
      getDistrictByProvinceId(dataVendor.province.value)
    }
  }, [dataVendor, form, getDistrictByProvinceId, getProvinceByCountryId])

  return (
    <div className={styles.wrapperBlock}>
      <Wrapper title='Address'>
        <div className={styles.dblRow}>
          <FormItem
            name='countryId'
            label='Country'
            rules={[{ required: true, validator: requiredValidator('Country') }]}
          >
            <AntSelect
              disabled={view || !dataCountry.items.length}
              onChange={(val) => {
                getProvinceByCountryId(val)
                clearProvinceAndDistrict()
              }}
              onClear={clearProvinceAndDistrict}
              options={dataCountry.items}
              placeholder='Select Country'
              allowClear
            />
          </FormItem>

          <FormItem noStyle shouldUpdate>
            {({ getFieldValue, setFields }) => {
              const isDisabled = view || !dataProvince.items.length || !getFieldValue('countryId')
              return (
                <FormItem
                  name='provinceId'
                  label='Province'
                  style={{ margin: '0 12px' }}
                  rules={[
                    !isDisabled ? { required: true, validator: requiredValidator('Province') } : {},
                  ]}
                >
                  <AntSelect
                    disabled={isDisabled}
                    onChange={(val) => {
                      setFields([{ name: 'districtId', value: null, errors: [] }])
                      onProvinceChange(val)
                    }}
                    onClear={() => {
                      setFields([{ name: 'districtId', value: null, errors: [] }])
                    }}
                    options={dataProvince.items}
                    placeholder='Select Province'
                    allowClear
                  />
                </FormItem>
              )
            }}
          </FormItem>

          <FormItem noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const isDisabled = view || !dataDistrict.items.length || !getFieldValue('provinceId')
              return (
                <FormItem
                  name='districtId'
                  label='City/District'
                  rules={[
                    !isDisabled
                      ? { required: true, validator: requiredValidator('City/District') }
                      : {},
                  ]}
                >
                  <AntSelect
                    disabled={isDisabled}
                    options={dataDistrict.items}
                    placeholder='Select City/District'
                    allowClear
                  />
                </FormItem>
              )
            }}
          </FormItem>
        </div>

        <div>
          <FormItem
            name='addressLine1'
            label='Address line 1'
            rules={[
              {
                required: true,
                validator: requiredValidator('Address line 1'),
              },
              { validator: maxLengthValidator(50) },
            ]}
          >
            <TextField placeholder='Address line 1' disabled={view} />
          </FormItem>
        </div>

        <div>
          <FormItem
            name='addressLine2'
            label='Address line 2'
            className='noBottomMargin'
            rules={[{ validator: maxLengthValidator(50) }]}
          >
            <TextField placeholder='Address line 2' disabled={view} />
          </FormItem>
        </div>
      </Wrapper>
    </div>
  )
}
