export interface IProfileNotification {
  id: number
  message: string
  isRead: boolean
  createdAt: string
  user: {
    firstName: string
    lastName: string
    avatar: string
  }
}

export interface IUnreadMessagesCount {
  totalCount: number
  externalCount: number
  internalCount: number
}

export enum UNREAD_POPOVER_TABS_ENUM {
  EXTERNAL = 'External',
  INTERNAL = 'Internal',
}
