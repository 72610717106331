import React, { memo } from 'react'
import FormItem from 'antd/es/form/FormItem'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { TextField } from 'src/shared/components/textfield/TextField'
import styles from '../styles.module.scss'

const TrustWalletSummary = () => {
  return (
    <Wrapper title='Treasury Wallet Summary' className={styles.container}>
      <div className={styles.dblRow}>
        <FormItem name='trustWalletBalanceBefore' label='Balance Before'>
          <TextField disabled />
        </FormItem>
        <FormItem name='trustWalletBalanceAfter' label='Balance After'>
          <TextField disabled />
        </FormItem>
      </div>
    </Wrapper>
  )
}

export default memo(TrustWalletSummary)
