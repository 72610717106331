import React, { useContext, useEffect } from 'react'
import { Form, Select, Spin } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import {
  VARIABLE_TYPE_ENUM,
  SYSTEM_VARIABLE_ENUM,
  IVendorVariable,
} from '../../../models/IVariables'
import { requiredValidator } from '../../../../../helpers/validation'
import { TextField } from '../../../../../shared/components/textfield/TextField'
import { Checkbox } from '../../../../../shared/components/checkbox/Checkbox'
import { Wrapper } from '../../../../../shared/components/wrapper/Wrapper'
import { IconButton } from '../../../../../shared/components/iconButton/IconButton'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import {
  useCreateVendorVariableMutation,
  useGetVariableByIdQuery,
  useUpdateVariableByIdMutation,
} from '../../../core/http/VendorManagementApi'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../shared/api/errorHandler'
import { RightModalContext } from '../../../../Modals'
import styles from './styles.module.scss'

const typesOptions = [
  { label: VARIABLE_TYPE_ENUM.COMPUTED, value: VARIABLE_TYPE_ENUM.COMPUTED },
  { label: VARIABLE_TYPE_ENUM.CONSTANT, value: VARIABLE_TYPE_ENUM.CONSTANT },
]

const systemVariableOptions = [
  { label: SYSTEM_VARIABLE_ENUM.AMOUNT, value: SYSTEM_VARIABLE_ENUM.AMOUNT },
  {
    label: SYSTEM_VARIABLE_ENUM.AMOUNT_MULTIPLYING_BY_100,
    value: SYSTEM_VARIABLE_ENUM.AMOUNT_MULTIPLYING_BY_100,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.AMOUNT_WITH_FORMAT,
    value: SYSTEM_VARIABLE_ENUM.AMOUNT_WITH_FORMAT,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.PRODUCT_CODE,
    value: SYSTEM_VARIABLE_ENUM.PRODUCT_CODE,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.TRANSACTION_ID,
    value: SYSTEM_VARIABLE_ENUM.TRANSACTION_ID,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.VENDOR_TRANSACTION_ID,
    value: SYSTEM_VARIABLE_ENUM.VENDOR_TRANSACTION_ID,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.CUSTOMER_PHONE_NUMBER,
    value: SYSTEM_VARIABLE_ENUM.CUSTOMER_PHONE_NUMBER,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.CUSTOMER_NATIONAL_PHONE_NUMBER,
    value: SYSTEM_VARIABLE_ENUM.CUSTOMER_NATIONAL_PHONE_NUMBER,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.CUSTOMER_INTERNATIONAL_PHONE_NUMBER,
    value: SYSTEM_VARIABLE_ENUM.CUSTOMER_INTERNATIONAL_PHONE_NUMBER,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.RESPONSE_CODE,
    value: SYSTEM_VARIABLE_ENUM.RESPONSE_CODE,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.RESPONSE_DESCRIPTION,
    value: SYSTEM_VARIABLE_ENUM.RESPONSE_DESCRIPTION,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.BALANCE,
    value: SYSTEM_VARIABLE_ENUM.BALANCE,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.CUSTOMER_BALANCE_BEFORE,
    value: SYSTEM_VARIABLE_ENUM.CUSTOMER_BALANCE_BEFORE,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.CUSTOMER_BALANCE_AFTER,
    value: SYSTEM_VARIABLE_ENUM.CUSTOMER_BALANCE_AFTER,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.REQUEST_DATE_TIME,
    value: SYSTEM_VARIABLE_ENUM.REQUEST_DATE_TIME,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.ENCRYPTION_KEY,
    value: SYSTEM_VARIABLE_ENUM.ENCRYPTION_KEY,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.SIGN_KEY,
    value: SYSTEM_VARIABLE_ENUM.SIGN_KEY,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.API_USER_NAME,
    value: SYSTEM_VARIABLE_ENUM.API_USER_NAME,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.API_PASSWORD,
    value: SYSTEM_VARIABLE_ENUM.API_PASSWORD,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.HOST,
    value: SYSTEM_VARIABLE_ENUM.HOST,
  },
  {
    label: SYSTEM_VARIABLE_ENUM.PORT,
    value: SYSTEM_VARIABLE_ENUM.PORT,
  },
]

const VendorManagementCreateNewVariable = () => {
  const [form] = Form.useForm()
  const {
    onClose,
    props: { vendorId, variableId, title },
  } = useContext(RightModalContext)

  const isNewMode = title?.toLowerCase() === 'add variable'
  const isEditMode = title?.toLowerCase() === 'update variable'
  const isViewMode = !isNewMode && !isEditMode

  const [createVariable, vCreate] = useCreateVendorVariableMutation()
  const [updateVariable, vUpdate] = useUpdateVariableByIdMutation()
  const { data, isFetching, isLoading } = useGetVariableByIdQuery(
    { vendorId, variableId },
    { skip: isNewMode || !variableId }
  )

  useNotification(NOTIFICATION_TYPES.success, vCreate.isSuccess, null)
  useNotification(NOTIFICATION_TYPES.error, vCreate.isError, vCreate.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.success, vUpdate.isSuccess, null)
  useNotification(NOTIFICATION_TYPES.error, vUpdate.isError, vUpdate.error as ErrorNode)

  const initialValues = {
    key: '',
    value: '',
    type: VARIABLE_TYPE_ENUM.CONSTANT,
    systemVariable: null,
    encrypted: false,
  }

  useEffect(() => {
    if (vCreate.isSuccess || vUpdate.isSuccess) {
      onClose()
    }
  }, [vCreate.isSuccess, vUpdate.isSuccess])

  const onChangeCheckbox = (event: CheckboxChangeEvent) => {
    form.setFieldValue('encrypted', event.target.checked)
  }

  const onFinish = (values: Partial<IVendorVariable>) => {
    if (isNewMode) {
      createVariable({ vendorId: vendorId as string, body: values })
    }

    if (isEditMode) {
      updateVariable({ vendorId: vendorId as string, variableId, body: values })
    }
  }

  const content = (
    <Form
      form={form}
      layout='vertical'
      initialValues={isNewMode ? initialValues : data}
      onFinish={onFinish}
      style={{ height: '100%' }}
    >
      <div className={styles.main}>
        <Wrapper title='Details'>
          <FormItem
            name='key'
            label='Key'
            rules={[{ required: true, validator: requiredValidator('key') }]}
          >
            <TextField placeholder='Enter key' disabled={isViewMode} />
          </FormItem>
          <FormItem
            name='value'
            label='Value'
            rules={[{ required: true, validator: requiredValidator('key') }]}
          >
            <TextField placeholder='Enter value' disabled={isViewMode} />
          </FormItem>
          <FormItem name='type' label='Type'>
            <Select options={typesOptions} placeholder='Type' disabled={isViewMode} />
          </FormItem>
          <FormItem name='systemVariable' label='System Variable'>
            <Select
              options={systemVariableOptions}
              placeholder='System Variable'
              disabled={isViewMode}
              allowClear
            />
          </FormItem>
          <FormItem name='encrypted'>
            <Checkbox
              onChange={onChangeCheckbox}
              text='Encrypted'
              defaultChecked={data?.encrypted}
              disabled={isViewMode}
            />
          </FormItem>
        </Wrapper>
        {(isNewMode || isEditMode) && (
          <div>
            <IconButton color='orange' size='large' type='primary' block htmlType='submit'>
              {isNewMode ? 'Create' : 'Update'}
            </IconButton>
          </div>
        )}
      </div>
    </Form>
  )

  return isFetching || isLoading ? <Spin>{content}</Spin> : content
}

export default VendorManagementCreateNewVariable
