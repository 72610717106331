import { Form } from 'antd'
import { Button } from '../../../../../../../../shared/components/button/Button'
import styles from '../../styles.module.scss'

interface IProps {
  onCancel: () => void
  onReject: () => void
  onApprove: () => void
}

export const Footer = ({ onCancel, onReject, onApprove }: IProps) => {
  return (
    <div className={styles.footerContainer}>
      <Button color='blue' size='middle' onClick={onCancel}>
        cancel
      </Button>
      <Button color='orange' size='middle' type='default' onClick={onReject}>
        reject
      </Button>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const vendorAmount = getFieldValue('vendorAmount')

          return (
            <Form.Item noStyle>
              <Button
                disabled={+vendorAmount <= 0}
                color='orange'
                size='middle'
                type='primary'
                onClick={onApprove}
              >
                approve
              </Button>
            </Form.Item>
          )
        }}
      </Form.Item>
    </div>
  )
}
