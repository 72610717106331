import { Breadcrumb } from '../breadcrumb/Breadcrumb'
import NotificationsCounter from '../notificationsCounter'
import MessagesCounter from '../messagesCounter'
import Timer from '../timer'

import styles from './Header.module.scss'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import React from 'react'
import { LockOutlined, UnlockOutlined } from '@ant-design/icons'

export const Header = () => {
  const [isPrivateMode, setIsPrivateMode] = useLocalStorage('isPrivateMode', false)

  return (
    <div className={styles.header}>
      <div className={styles['header-breadcrumbs-wrapper']}>
        <Breadcrumb />
      </div>
      <div className={styles['header-icons']}>
        <NotificationsCounter />
        <MessagesCounter />
        <div className={styles.lockIcon} onClick={() => setIsPrivateMode((prev) => !prev)}>
          {isPrivateMode ? <LockOutlined className={styles.lockEnabled} /> : <UnlockOutlined />}
        </div>
        <Timer />
      </div>
    </div>
  )
}
