import { ITableResponse } from 'src/shared/models/ITableResponse'
import { IProduct } from '../../../../../models/IProduct'
import styles from '../../../VendorManagementContactListModal/styles.module.scss'
import { nanoid } from 'nanoid'
import { useAppSelector } from '../../../../../../../redux'
import { selectVendorManagementState } from '../../../../../core/store/VendorManagementSlice'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { ArrowRightIcon, BucketIcon } from '../../../../../../../assets/svg'
import { RIGHT_MODALS } from '../../../../../../../helpers/contants'
import React, { useContext, useState } from 'react'
import { RightModalContext } from '../../../../../../Modals'
import { startCase } from 'lodash'
import { Button } from '../../../../../../../shared/components/button/Button'
import { Space } from 'antd'
import emptyProductImage from 'src/assets/img/ProductTablePlaceholder.png'

interface IProps {
  products?: ITableResponse<IProduct>['items']
  onDeleteProduct: (id: number) => void
  onAddProduct: (product: IProduct) => void
  defaultChecked?: boolean
  onVendorCreate?: boolean
}

interface IProductRow extends Omit<IProps, 'products'> {
  product: IProduct
}

const ProductRow = ({
  product,
  defaultChecked,
  onDeleteProduct,
  onAddProduct,
  onVendorCreate,
}: IProductRow) => {
  const {
    onOpen,
    props: { vendorId },
  } = useContext(RightModalContext)
  const [isSelected, setIsSelected] = useState(defaultChecked)

  const onSelectClick = () => {
    if (isSelected) {
      onDeleteProduct(product.id)
      return setIsSelected(false)
    }

    onAddProduct(product)
    if (!vendorId) {
      return setIsSelected(true)
    }
  }

  const onCloseRedirect = () => {
    onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_PRODUCT_LIST, {
      title: 'Add Products',
    })
  }

  return (
    <div className={styles.contact} key={nanoid()}>
      <div className={styles.contactContent}>
        <div className={styles.contactAvatar}>
          <img alt='product logo' src={product?.logo ?? emptyProductImage}></img>
        </div>
        <div className={styles.contactDescriptions}>
          <div className={styles.contactName}>{product.name}</div>
          <div className={styles.contactdepartmentAndPostion}>
            <div className={styles.contactDepartment}>{product.unitCost}</div>
            <div className={styles.circle}></div>
            <div className={styles.contactPosition}>{startCase(product.type.toLowerCase())}</div>
          </div>
        </div>
      </div>
      <Space size={10}>
        {onVendorCreate ? (
          <div style={{ width: 35 }}>
            <IconButton
              block
              size='large'
              color='orange'
              icon={<BucketIcon />}
              type='default'
              onClick={(e) => {
                e.stopPropagation()
                onSelectClick()
              }}
            />
          </div>
        ) : (
          <>
            {!product.vendor && (
              <div className={styles.selectButton}>
                <Button
                  color='orange'
                  type={defaultChecked ? 'primary' : 'default'}
                  onClick={(e) => {
                    e.stopPropagation()
                    onSelectClick()
                  }}
                >
                  Select
                </Button>
              </div>
            )}
            <div className={styles.redirectButton}>
              <IconButton
                color='blue'
                icon={<ArrowRightIcon />}
                onClick={() =>
                  onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_PRODUCT, {
                    title: 'View Products',
                    mode: 'view',
                    id: product.id,
                    onCloseRedirect,
                  })
                }
              />
            </div>
          </>
        )}
      </Space>
    </div>
  )
}

export const ProductsList = ({
  products,
  onDeleteProduct,
  onAddProduct,
  defaultChecked,
  onVendorCreate,
}: IProps) => {
  const { productsList } = useAppSelector(selectVendorManagementState)
  return (
    <>
      {products?.map((product) => {
        const shouldBeChecked = productsList.some((savedProduct) => savedProduct.id === product.id)

        return (
          <ProductRow
            key={product?.id}
            product={product}
            defaultChecked={defaultChecked || shouldBeChecked}
            onDeleteProduct={onDeleteProduct}
            onAddProduct={onAddProduct}
            onVendorCreate={onVendorCreate}
          />
        )
      })}
    </>
  )
}
