import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../redux'
import { IPermissionAllowed, IPermissionModule, IPermissionModulesGroup } from '../../models/IRoles'

interface UsersState {
  loading: boolean
  permissionModules: IPermissionModule[]
  permissionModulesAllowed: IPermissionAllowed[]
  permissionModulesGroup: IPermissionModulesGroup[]
}

const initialState: UsersState = {
  loading: true,
  permissionModules: [],
  permissionModulesAllowed: [],
  permissionModulesGroup: [],
}

export const rolesAndPermissionsManagementSlice = createSlice({
  name: 'rolesAndPermissionsManagementSlice',
  initialState,
  reducers: {
    setPermissionsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    addPermissionModules: (state, { payload }: PayloadAction<Array<IPermissionModule>>) => {
      state.permissionModules = payload
    },
    addPermissionModulesAllowed: (state, { payload }: PayloadAction<Array<IPermissionAllowed>>) => {
      state.permissionModulesAllowed = payload
    },
    addPermissionModulesGroup: (
      state,
      { payload }: PayloadAction<Array<IPermissionModulesGroup>>
    ) => {
      state.permissionModulesGroup = payload
    },
    clearRolesAndPermissionsSliceState(state) {
      state.permissionModules = []
      state.permissionModulesAllowed = []
      state.permissionModulesGroup = []
    },
  },
})

export const selectRolesAndPermissionsManagementState = (state: RootState) =>
  state.rolesAndPermissionsManagementSlice

export const {
  setPermissionsLoading,
  addPermissionModules,
  addPermissionModulesAllowed,
  addPermissionModulesGroup,
  clearRolesAndPermissionsSliceState,
} = rolesAndPermissionsManagementSlice.actions

export default rolesAndPermissionsManagementSlice.reducer
