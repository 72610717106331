import { io, Socket } from 'socket.io-client'
import { BASE_URL, NOTIFICATION_BASE_URL, WIDGET_ENGINE_URL } from 'src/config'

interface ISocketsConfig {
  reconnectionDelay?: number
  transports: Array<string>
  path: string
  upgrade?: boolean
  autoConnect?: boolean
  reconnection?: boolean
  query: { token: string }
  withCredentials?: boolean
  reconnectionDelayMax?: number
  reconnectionAttempts?: number
}

class SocketConnection {
  // @ts-ignore
  private socket: Socket

  constructor(private url: string, private namespace?: string) {}

  public connect(token: string): void {
    const config: ISocketsConfig = {
      reconnectionDelay: 10000,
      transports: ['websocket'],
      path: '/socket.io',
      upgrade: true,
      autoConnect: true,
      reconnection: true,
      query: { token },
    }

    if (this.socket) {
      this.disconnect()
    }

    const basePath = this.namespace ? `${this.url}${this.namespace}` : this.url

    this.socket = io(basePath, config)

    this.socket.connect()
  }

  public disconnect(): void {
    this.socket.disconnect()
  }

  public getSocket(): Socket {
    return this.socket
  }
}

export const rootSocketConnection = new SocketConnection(BASE_URL as string, '/internal-chat')

export const notificationSocketConnection = new SocketConnection(
  NOTIFICATION_BASE_URL as string,
  '/internal-notifications'
)

export const externalChatSocketConnection = new SocketConnection(
  BASE_URL as string,
  '/external-chat'
)

export const widgetsSocketConnection = new SocketConnection(WIDGET_ENGINE_URL as string, '/widgets')

export const systemMonitoringSocketConnection = new SocketConnection(
  WIDGET_ENGINE_URL as string,
  '/monitoring'
)

export const externalTransactionReportConnection = new SocketConnection(
  BASE_URL as string,
  '/external-transaction-report'
)

export const chatsSocketConnection = new SocketConnection(BASE_URL as string, '/chats')
