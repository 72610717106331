import styles from '../PurchaseOrder/styles.module.scss'
import { Form, Row, Spin, UploadFile } from 'antd'
import { formatDateWithTime } from '../../../../../helpers/utils'
import classNames from 'classnames'
import { DetailInfo } from '../PurchaseOrder/Components/DetailInfo'
import { PaymentInfo } from '../PurchaseOrder/Components/PaymentInfo'
import React, { useCallback, useEffect, useState } from 'react'
import { Button } from '../../../../../shared/components/button/Button'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import ShowMessage, { ErrorNode } from '../../../../../shared/api/errorHandler'
import { PAYMENT_METHOD_ENUM } from '../../../models/IBankAccount'
import { useNavigate } from 'react-router'
import {
  useCreatePurchaseOrderMutation,
  useGetDepartmentListQuery,
} from '../../../core/http/PurchaseOrderApi'
import { DefaultOptionType } from 'rc-select/lib/Select'
import { useLazyGetCurrencyRateToCurrencyQuery } from '../../../core/http/ExchangeRatesApi'
import {
  AFN_CURRENCY_CODE,
  IBankAccountForm,
  IPurchaseOrderBody,
  IPurchaseOrderCreate,
  IScratchCard,
  VENDOR_WALLET_TYPE_ENUM,
} from '../../../models/IPurchaseOrder'
import {
  bankAccountApi,
  useGetBankAccountsQuery,
  useGetListOfCurrenciesQuery,
} from '../../../core/http/BankAccountApi'
import { useGetVendorListQuery } from '../../../../Settings/core/http/VendorManagementApi'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import { useGetUsersListQuery } from '../../../../Settings/core/http/UsersManagementApi'
import { isEmpty, omit } from 'lodash'
import { ITableConf } from '../../../../../shared/models/ITableConf'
import usePermissions from '../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../Settings/models/IPermissions'
import PermissionDenied from '../../../../../shared/components/permissionDenied'
import { FINANCE_ENUM } from '../../../../../routes/finance'
import { PurchaseOrderSummary } from '../PurchaseOrder/Components/PurchaseOrderSummary'
import { useDispatch } from 'react-redux'
import { vendorInventoryApi } from '../../../core/http/VendorInventoryApi'
import { EPinBlock } from '../PurchaseOrder/Components/EPinBlock'
import FormItem from 'antd/es/form/FormItem'
import { EPinsCountBlock } from '../PurchaseOrder/Components/EPinsCountBlock'

export const NewPurchaseOrder = () => {
  const dispatch = useDispatch()
  const [currency, setCurrency] = useState<string>()
  const [files, setFiles] = useState<UploadFile[]>([])
  const [pinList, setPinList] = useState<Array<IScratchCard>>([])
  const [paymentType, setPaymentType] = useState<PAYMENT_METHOD_ENUM>()
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const { data: bankAccountList } = useGetBankAccountsQuery(
    {
      currency: currency as string,
    } as ITableConf,
    { skip: !currency }
  )
  const { data: vendorList } = useGetVendorListQuery({})
  const { data: currencyList } = useGetListOfCurrenciesQuery()
  const { data: departmentsList } = useGetDepartmentListQuery()
  const { data: userProfile } = useGetProfileQuery()
  const { data: usersList } = useGetUsersListQuery({})
  const [getRate, getRateResp] = useLazyGetCurrencyRateToCurrencyQuery()
  const [createPO, dCreate] = useCreatePurchaseOrderMutation()

  const afterCreate = () => {
    navigate(FINANCE_ENUM.TRUST_WALLET)
    dispatch(
      vendorInventoryApi.util.invalidateTags([
        'IInventory',
        'ICalendar',
        'IInventoryChart',
        'IInventoryTransactions',
      ])
    )
    dispatch(bankAccountApi.util.invalidateTags(['IBankAccountList', 'IBankAccount']))
  }

  const handleNotExistingCurrency = () => {
    form.setFieldValue('exchangeRate', null)
  }

  const handleSelectCurrency = (value: DefaultOptionType) => {
    if (!value) {
      form.setFieldValue('exchangeRate', null)
    }
    form.setFieldsValue({
      purchaseOrderBankWallets: [],
      amount: 0,
      currencyAmount: 0,
    })
    const currencyId = value?.value as number
    setCurrency(currencyList?.items.find((curr) => curr?.id === currencyId)?.name)

    if (currencyId === AFN_CURRENCY_CODE) {
      return form.setFieldValue('exchangeRate', 1)
    }

    currencyId &&
      getRate({
        currencyId,
      })
  }

  const recalculateMoneyAmount = useCallback(() => {
    const { purchaseOrderBankWallets } = form.getFieldsValue()

    const totalAmount = purchaseOrderBankWallets?.reduce(
      (acc: number, res: IBankAccountForm) => acc + +(res?.amount || 0),
      0
    )
    const totalCurrencyAmount = purchaseOrderBankWallets?.reduce(
      (acc: number, res: IBankAccountForm) => acc + +(res?.currencyAmount || 0),
      0
    )
    form.setFieldsValue({
      amount: totalAmount,
      currencyAmount: totalCurrencyAmount,
    })
  }, [form])

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const [financePurchasesId] = getModulesIdsByNames([IPermissionsNames['Finance Purchase Order']])
  const [canAddFinancePurchase] = canPerformAction(financePurchasesId, [IPermissionsActions.ADD])

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, dCreate.isSuccess, null, afterCreate)
  useNotification(NOTIFICATION_TYPES.error, dCreate.isError, dCreate.error as ErrorNode)
  useNotification(
    NOTIFICATION_TYPES.error,
    getRateResp.isError,
    getRateResp.error as ErrorNode,
    handleNotExistingCurrency
  )

  useEffect(() => {
    if (isEmpty(userProfile)) return
    form.setFieldValue(
      ['createdByUser', 'name'],
      `${userProfile.firstName} ${userProfile.lastName}`
    )
  }, [form, userProfile])

  useEffect(() => {
    if (getRateResp.isSuccess) {
      form.setFieldValue('exchangeRate', getRateResp.data?.sellingRate)
    }
    if (form.getFieldValue('currency')?.value === AFN_CURRENCY_CODE) {
      form.setFieldValue('exchangeRate', 1)
    }
  }, [form, getRateResp])

  const handleFinish = (values: IPurchaseOrderCreate) => {
    const {
      vendor,
      paymentType,
      assignOfficer,
      purchaseOrderBankWallets,
      currency,
      assignDepartment,
      exchangeRate,
      vendorWalletType,
      scratchCardInfo,
    } = values

    let body = {} as IPurchaseOrderBody
    const selectedPayment = typeof paymentType === 'string' ? paymentType : paymentType.label

    body.orderInfo = {
      vendorId: +vendor?.id,
      paymentType: selectedPayment,
      currencyId: +currency?.id,
      exchangeRate: +exchangeRate,
      vendorWalletType,
      ...(assignOfficer?.id && { assignOfficerId: +assignOfficer?.id }),
      ...(assignDepartment?.id && { assignDepartmentId: +assignDepartment?.id }),
    }
    body.paymentInfo = purchaseOrderBankWallets?.map(
      ({ bankAccount, currencyAmount, unitPrice, mainCurrencyAmount, amount }) => ({
        bankAccountId: +bankAccount?.id,
        currencyAmount: +currencyAmount!,
        amount: +amount!,
        mainCurrencyAmount: +mainCurrencyAmount!,
        unitPrice: +unitPrice!,
      })
    )

    if (body?.orderInfo?.vendorWalletType === VENDOR_WALLET_TYPE_ENUM.E_PIN) {
      const existingCardCodes = new Set()
      let hasRepeatingCode = false

      body.scratchCardInfo = pinList
        ?.concat(
          scratchCardInfo?.map((card) => ({
            ...card,
            denomination: +card?.denomination,
          }))
        )
        ?.filter((card) => {
          if (isEmpty(card)) return
          // Check if the card code is repeating
          if (existingCardCodes.has(card.code)) {
            ShowMessage('error', `Repeating card code detected: ${card.code}`)
            hasRepeatingCode = true
            return false // Discard the duplicate card
          } else {
            existingCardCodes.add(card.code)
            return true
          }
        })

      if (hasRepeatingCode) return

      if (!body?.scratchCardInfo.length) {
        body = omit(body, 'scratchCardInfo')
      }
    }

    if (selectedPayment === PAYMENT_METHOD_ENUM.BONUS) {
      body.orderInfo = {
        vendorId: +vendor.id,
        paymentType: selectedPayment,
        vendorWalletType,
        ...(assignOfficer?.id && { assignOfficerId: +assignOfficer?.id }),
        ...(assignDepartment?.id && { assignDepartmentId: +assignDepartment?.id }),
      }

      body.paymentInfo = purchaseOrderBankWallets?.map(({ unitPrice, amount, id }) => ({
        ...(id && { id: +id }),
        currencyAmount: 0,
        amount: +amount!,
        mainCurrencyAmount: 0,
        unitPrice: +unitPrice!,
      }))
    }

    createPO(body)
  }

  const isViewMode = false

  const content = canAddFinancePurchase ? (
    <React.Fragment>
      <main className={classNames(styles.layout, 'fullVH')}>
        <Form
          form={form}
          layout='vertical'
          initialValues={{
            createdAt: formatDateWithTime(new Date(), userProfile?.calendar),
          }}
          onFinish={handleFinish}
        >
          <Row wrap={false} justify='start'>
            <div className={classNames(styles.leftColumn)}>
              <DetailInfo
                viewMode={isViewMode}
                vendorList={vendorList?.items}
                usersList={usersList?.items}
                currencyList={currencyList?.items}
                departmentsList={departmentsList?.items}
                onSelect={handleSelectCurrency}
                handleSelectPayment={(payment) => setPaymentType(payment)}
                form={form}
              />
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const vendorWalletType = getFieldValue('vendorWalletType')
                  return (
                    vendorWalletType !== VENDOR_WALLET_TYPE_ENUM.E_PIN && (
                      <PaymentInfo
                        selectedPaymentType={paymentType}
                        viewMode={isViewMode}
                        form={form}
                        currency={form.getFieldValue('currency')}
                        bankAccountList={bankAccountList?.items}
                        recalculateMoneyAmount={recalculateMoneyAmount}
                      />
                    )
                  )
                }}
              </FormItem>
            </div>
            <div className={styles.rightColumn}>
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const isEPinType =
                    getFieldValue('vendorWalletType') === VENDOR_WALLET_TYPE_ENUM.E_PIN

                  return (
                    isEPinType && (
                      <EPinBlock
                        setPinList={setPinList}
                        files={files}
                        setFiles={setFiles}
                        pinList={pinList}
                      />
                    )
                  )
                }}
              </FormItem>
              <EPinsCountBlock pinList={pinList} />
              <PurchaseOrderSummary view={false} recalculateMoneyAmount={recalculateMoneyAmount} />
            </div>
          </Row>
        </Form>
        <div className={styles.footerWrapper}>
          <Row className={styles.footer} justify='end'>
            <Button style={{ width: '83px' }} color='blue' size='middle' onClick={afterCreate}>
              Cancel
            </Button>
            <Button
              className={styles.submitBtn}
              htmlType='submit'
              size='middle'
              type='primary'
              onClick={() => form.submit()}
              disabled={dCreate.isLoading}
            >
              Create PO
            </Button>
          </Row>
        </div>
      </main>
    </React.Fragment>
  ) : (
    <PermissionDenied />
  )

  return dCreate.isLoading ? <Spin>{content}</Spin> : content
}
