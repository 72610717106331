import { useContext, useEffect } from 'react'
import { Form, Spin } from 'antd'
import { isEmpty, startCase } from 'lodash'

import { RightModalContext } from '../../../../../../Modals'
import {
  useGetTopupTransactionByIdQuery,
  useGetTopupSubTransactionsQuery,
} from '../../../../../../Finance/core/http/ReverseTransactionApi'
import { formatDateWithSeconds } from '../../../../../../../helpers/utils'

import { TopupAgentAccountSummary } from './AgentAccountSummary'
import { Details } from '../Components/Details'

import styles from '../styles.module.scss'
import { SubTransactionDetail } from '../Components/SubTransactionDetail'
import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import VendorInformation from '../Components/VendorInformation'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { useGetVendorRequestListQuery } from '../../../../../core/ExternalTransactionApi'
import { RequestHistory } from '../Components/RequestHistory'
import { isJSON } from '../../../../../../Support/pages/TransactionLogs'

export const TopupTransactionDetailModal = () => {
  const [form] = Form.useForm()
  const {
    props: { id },
  } = useContext(RightModalContext)
  const { data: profile } = useGetProfileQuery()

  const { data, isFetching } = useGetTopupTransactionByIdQuery(id, { skip: !id })
  const { data: requestsHistory, isFetching: isFetchingHistory } = useGetVendorRequestListQuery(
    {
      topupId: id,
    },
    {
      skip: !id,
    }
  )
  const { data: subTransactions, isFetching: isFetchingSub } = useGetTopupSubTransactionsQuery(id, {
    skip: !id,
  })

  useEffect(() => {
    if (isEmpty(data)) return
    form.setFieldsValue({
      ...data,
      originalTopupAmount: data?.amount,
      commissionParents: data?.fullDiscountAmount - data?.discountAmount,
      topupSubTransactions: subTransactions?.items.map((sub) => ({
        ...sub,
        sender: startCase(sub[sub?.sender]?.name?.toLowerCase()),
        receiver: startCase(sub[sub?.receiver]?.name?.toLowerCase()),
        createdAt: formatDateWithSeconds(sub?.createdAt, profile?.calendar),
      })),
      requestHistory: requestsHistory?.items.map((request) => ({
        ...request,
        createdAt: formatDateWithSeconds(request?.createdAt, profile?.calendar),
        response: isJSON(request?.response)
          ? JSON.stringify(JSON.parse(request?.response), null, 2)
          : request?.response,
        processedResponse: isJSON(request?.processedResponse)
          ? JSON.stringify(JSON.parse(request?.processedResponse), null, 2)
          : request?.processedResponse,
        responseAt: request?.responseAt
          ? formatDateWithSeconds(request?.responseAt, profile?.calendar)
          : null,
      })),
      createdAt: formatDateWithSeconds(data?.createdAt, profile?.calendar),
      ...(data.agent && {
        location: `${data?.agent?.province?.name || ''} ${data?.agent?.district?.name || ''} ${
          data.agent.country.name || ''
        }`,
      }),
    })
  }, [data, form, subTransactions?.items, profile, requestsHistory])

  const content = (
    <Form form={form} className={styles.formContainer} layout='vertical'>
      <Details status={data?.status} />
      {data?.status && data?.status !== 'Fail' && <TopupAgentAccountSummary />}
      {requestsHistory && requestsHistory?.items?.length > 1 && (
        <Wrapper title='Request History'>
          <Form.List name='requestHistory'>
            {(fields) => (
              <>
                {fields?.length > 0
                  ? fields.map(({ key, name }) => {
                      return <RequestHistory key={key} name={name} />
                    })
                  : null}
              </>
            )}
          </Form.List>
        </Wrapper>
      )}
      <VendorInformation />
      {subTransactions && subTransactions?.items.length > 0 ? (
        <Wrapper title='Sub Transaction Detail'>
          <Form.List name='topupSubTransactions'>
            {(fields) => (
              <>
                {fields?.length > 0
                  ? fields.map(({ key, ...rest }) => {
                      return <SubTransactionDetail {...rest} key={key} />
                    })
                  : null}
              </>
            )}
          </Form.List>
        </Wrapper>
      ) : null}
    </Form>
  )

  return isFetching || isFetchingSub || isFetchingHistory ? <Spin>{content}</Spin> : content
}
