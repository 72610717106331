import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../redux'
import { IInternalChatMessage } from '../../models'
import { unionBy } from 'lodash'

interface MessagesState {
  limit: number
  createdAtBefore: string
  createdAtAfter: string
  lastReadingAt: string
  messages: Array<IInternalChatMessage>
}

const initialState: MessagesState = {
  limit: 25,
  createdAtBefore: '',
  createdAtAfter: '',
  lastReadingAt: '',
  messages: [],
}

export const internalChatMessagesSlice = createSlice({
  name: 'internalChatMessagesSlice',
  initialState,
  reducers: {
    setLimit: (state, { payload }: PayloadAction<number>) => {
      state.limit = payload
    },
    setCreatedAtBefore: (state, { payload }: PayloadAction<string>) => {
      state.createdAtBefore = payload
    },
    setCreatedAtAfter: (state, { payload }: PayloadAction<string>) => {
      state.createdAtAfter = payload
    },
    setLastReadingAt: (state, { payload }: PayloadAction<string>) => {
      state.lastReadingAt = payload
    },
    addBeforeMessages: (state, { payload }: PayloadAction<Array<IInternalChatMessage>>) => {
      state.messages = unionBy([...state.messages, ...payload], 'id')
    },
    addAfterMessages: (state, { payload }: PayloadAction<Array<IInternalChatMessage>>) => {
      state.messages = unionBy([...payload, ...state.messages], 'id')
    },
    resetRequestDates(state) {
      state.lastReadingAt = ''
      state.createdAtAfter = ''
      state.createdAtBefore = ''
    },
    clearMessages(state) {
      state.messages = []
    },
  },
})

export const selectInternalChatMessagesState = (state: RootState) => state.internalChatMessagesSlice

export const {
  addBeforeMessages,
  addAfterMessages,
  clearMessages,
  setCreatedAtBefore,
  setCreatedAtAfter,
  setLastReadingAt,
  resetRequestDates,
} = internalChatMessagesSlice.actions

export default internalChatMessagesSlice.reducer
