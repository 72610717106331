import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../../../shared/components/textfield/TextField'
import { Wrapper } from '../../../../../../../salesNetwork/components/Wrapper'
import styles from '../../styles.module.scss'
import { Form } from 'antd'
import React from 'react'

export const TopupAgentAccountSummary = () => {
  return (
    <Wrapper title='Agent Account Summary'>
      <div className={styles.dblRow}>
        <FormItem name='balanceBefore' label='Agent Balance Before'>
          <TextField disabled />
        </FormItem>

        <FormItem name='balanceAfter' label='Agent Balance After'>
          <TextField disabled />
        </FormItem>
      </div>

      {/*<div className={styles.dblRow}>*/}
      {/*  <FormItem name='discountAmount' label='Commission paid to Agent'>*/}
      {/*    <TextField disabled />*/}
      {/*  </FormItem>*/}

      {/*  <FormItem name='commissionParents' label='Commission paid to Parent Agent'>*/}
      {/*    <TextField disabled />*/}
      {/*  </FormItem>*/}
      {/*</div>*/}

      <div>
        <Form.Item name={['agent', 'name']} label='Agent Name'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item name={['agent', 'id']} label='Agent ID'>
          <TextField disabled />
        </Form.Item>

        <Form.Item name='location' label='Location'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div>
        <FormItem name='fullDiscountAmount' label='Commission paid'>
          <TextField disabled />
        </FormItem>
      </div>

      <div>
        <FormItem name='rewardAmount' label='Reward Paid'>
          <TextField disabled />
        </FormItem>
      </div>

      <div>
        <FormItem
          name='promotionAmount'
          label='Total Benefit Paid'
          className={styles.noBottomMargin}
        >
          <TextField disabled />
        </FormItem>
      </div>
    </Wrapper>
  )
}
