import React, { useMemo } from 'react'
import { Tabs } from 'antd'

import styles from './styles.module.scss'
import { UserList } from '../UserList'
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux'
import { EXTERNAL_CHAT_LIST_TABS } from '../../../../../../models/IExternalChat'
import {
  clearExternalChatList,
  selectExternalChatChatsSlice,
  setActiveTab,
} from '../../../../../../core/store/ExternalChatChatsListSlice'
import classNames from 'classnames'
import { externalChatApi } from '../../../../../../core/http/ExternalChatApi'

const ExternalChatListTabs = () => {
  const dispatch = useAppDispatch()
  const { activeTab, externalChat } = useAppSelector(selectExternalChatChatsSlice)

  const items = useMemo(
    () => [
      {
        label: EXTERNAL_CHAT_LIST_TABS.MY,
        key: EXTERNAL_CHAT_LIST_TABS.MY,
        children: (
          <UserList
            activeTab={activeTab}
            selectedFilters={{ isConnected: true, isArchived: false }}
          />
        ),
      },
      {
        label: EXTERNAL_CHAT_LIST_TABS.ALL,
        key: EXTERNAL_CHAT_LIST_TABS.ALL,
        children: (
          <UserList
            activeTab={activeTab}
            selectedFilters={{ isConnected: false, isArchived: false }}
          />
        ),
      },
      {
        label: EXTERNAL_CHAT_LIST_TABS.ARCHIVED,
        key: EXTERNAL_CHAT_LIST_TABS.ARCHIVED,
        children: <UserList activeTab={activeTab} selectedFilters={{ isArchived: true }} />,
      },
    ],
    [activeTab]
  )

  const onTabChange = (tab: EXTERNAL_CHAT_LIST_TABS) => {
    dispatch(setActiveTab(tab))
    dispatch(clearExternalChatList())
    if (externalChat?.isArchived || !externalChat?.id) return

    dispatch(
      externalChatApi.util.invalidateTags([{ type: 'IExternalChatById', id: externalChat?.id }])
    )
  }

  return (
    <div className={classNames('chatRoomLayout', styles.externalChatTabsContainer)}>
      <Tabs
        destroyInactiveTabPane
        centered
        items={items}
        activeKey={activeTab}
        onChange={(tab) => onTabChange(tab as EXTERNAL_CHAT_LIST_TABS)}
        className={styles.externalChatTabs}
      />
    </div>
  )
}

export default ExternalChatListTabs
