import { Link, useNavigate, useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ColumnsType } from 'antd/es/table'
import { IAgentSalesNetwork } from '../../../../models/IAgentSalesNetworkTable'
import emptyAvatarImage from '../../../../../../assets/img/Avatar.png'
import { formatNumberToLocale } from '../../../../helpers'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../shared/components/popup/Popup'
import { LocationIcon, TableActionIcon } from '../../../../../../assets/svg'
import {
  useGetAgentByIdQuery,
  useGetSalesNetworkFiltersListQuery,
  useGetSalesNetworkTableQuery,
  useGetSubAgentsQuery,
  useLazyGetAgentTableFileByFormatCsvOrXlsxQuery,
} from '../../../../core/http/AgentManagement'
import { useTablePagination } from '../../../../../../shared/hooks/table/useTablePagination'
import { Table } from 'antd'
import { ITableConf } from '../../../../../../shared/models/ITableConf'
import { AllTableHeader } from '../TableHeader/AllTableHeader'
import { skipToken } from '@reduxjs/toolkit/query'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import './style.scss'
import { ITableResponse } from 'src/shared/models/ITableResponse'
import { useTableExport } from 'src/shared/hooks/table/useTableExport'
import { useOnTableChange } from 'src/shared/hooks/table/useOnTableChange'
import { Operations } from './Operations'
import { tableExpandedIcon } from '../../../../../../helpers/utils'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { useDispatch } from 'react-redux'
import { setBreadcrumb } from 'src/features/app/core/BreadcrumbsSlice'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'

export const StructureTable = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { agentId } = useParams()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const { data: agentProfile } = useGetAgentByIdQuery(agentId, { skip: !agentId })

  const tableActionsPopup = useCallback(
    (id: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () => navigate(`/sales-network/agent-management/view/${id}`),
        },
        {
          text: 'Edit',
          shouldDisplay: true,
          onClick: () => navigate(`/sales-network/agent-management/edit/${id}`),
        },
        {
          text: 'Delete',
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          withDivider: true,
          shouldDisplay: true,
        },
      ]
    },
    [navigate]
  )

  const columns: ColumnsType<IAgentSalesNetwork> = useMemo(
    () => [
      {
        title: '',
        dataIndex: '',
        width: '1%',
      },
      {
        title: 'Account ID',
        dataIndex: 'id',
        width: '15%',
        sorter: true,
        render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
      },
      {
        title: 'Name',
        dataIndex: 'firstName',
        sorter: true,
        render: (name: IAgentSalesNetwork['firstName'], row) => (
          <RecursivelyReplaceCharacters>
            <Link to={`/sales-network/agent-management/view/${row.id}`} className='table-avatar'>
              <img
                alt='user-photo'
                src={!isPrivateMode && row?.avatar ? row?.avatar : emptyAvatarImage}
                width={40}
                height={40}
              />
              <div>
                {name} {row.lastName}
              </div>
            </Link>
          </RecursivelyReplaceCharacters>
        ),
        width: '20%',
      },
      {
        title: 'Agent Level',
        dataIndex: 'agentLevel',
        width: '10%',
        render: (agentLevel: IAgentSalesNetwork['agentLevel']) => (
          <RecursivelyReplaceCharacters>{agentLevel.level}</RecursivelyReplaceCharacters>
        ),
      },
      {
        title: 'Network Size',
        dataIndex: 'networkSize',
        align: 'center',
        width: '10%',
        sorter: true,
        render: (name, row) => (
          <RecursivelyReplaceCharacters>{row.networkSize}</RecursivelyReplaceCharacters>
        ),
      },
      {
        title: 'Location',
        dataIndex: 'location',
        sorter: true,
        width: '15%',
        render: (_, row) => (
          <RecursivelyReplaceCharacters>
            <div className='table-location'>
              <LocationIcon />
              <div>
                {row.country?.name}, {row.district?.name}
              </div>
            </div>
          </RecursivelyReplaceCharacters>
        ),
      },
      {
        title: 'Current Balance',
        dataIndex: 'balance',
        sorter: true,
        render: (_) => (
          <RecursivelyReplaceCharacters>
            <span className='table-currency'>
              {formatNumberToLocale(_, { isCurrency: true, locale: 'en-US' })}
            </span>
          </RecursivelyReplaceCharacters>
        ),
      },
      {
        title: '',
        dataIndex: 'edit',
        key: 'edit',
        width: 1,
        render: (_, row) => (
          <Popup data={tableActionsPopup(row.id)}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        ),
      },
    ],
    [tableActionsPopup]
  )

  const [expandedRows, setExpandedRows] = useState<number | undefined>()
  const [mappedAgents, setMappedAgents] = useState<ITableResponse<IAgentSalesNetwork>['items']>([])
  const [filterValues, setFilterValues] = useState<ISelectedFilters>({})
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 10,
  })

  const [getAgentTableFileByFormatCsvOrXlsxQuery] = useLazyGetAgentTableFileByFormatCsvOrXlsxQuery()

  useTableExport({
    tableConf: { ...tableConf, id: agentId },
    filterValues,
    promise: getAgentTableFileByFormatCsvOrXlsxQuery,
  })

  useEffect(() => {
    if (!agentProfile) return
    dispatch(setBreadcrumb(agentProfile))
  }, [agentProfile, dispatch])

  const { data = { totalCount: 0, current: 1, items: [] }, isFetching } =
    useGetSalesNetworkTableQuery(
      { ...tableConf, ...filterValues, id: agentId as string },
      { skip: !agentId }
    )

  const { data: childrenData, isFetching: isChildLoaded } = useGetSubAgentsQuery(
    {
      ...tableConf,
      ...filterValues,
      id: expandedRows,
    },
    { skip: !expandedRows }
  )

  const { data: filtersList } = useGetSalesNetworkFiltersListQuery(agentId ?? skipToken)

  const paginationConfig = useTablePagination(data?.totalCount)
  const onTableParamsChange = useOnTableChange<IAgentSalesNetwork>(setTableConf)
  const emptyTableConf = useEmptyTable()

  const mutateInputData = useCallback((data: IAgentSalesNetwork[]) => {
    return data?.map((agent) => {
      if (agent.networkSize > 0) {
        return { ...agent, children: [] }
      }
      return agent
    })
  }, [])

  useEffect(() => {
    setMappedAgents(mutateInputData(data.items))
  }, [data, mutateInputData])

  useEffect(() => {
    const mappedData = [...mappedAgents]

    function setChildrenForMapped(data: IAgentSalesNetwork[]) {
      for (let i = 0; i < data.length; i++) {
        const index = data.findIndex((el) => el.id === expandedRows)
        if (index !== -1) {
          data.splice(index, 1, {
            ...data[index],
            children: mutateInputData(childrenData?.items as IAgentSalesNetwork[]),
          })
        } else if (data[i].children) {
          setChildrenForMapped(data[i].children as IAgentSalesNetwork[])
        }
      }
    }

    setChildrenForMapped(mappedData)
    setMappedAgents(mappedData)
    // eslint-disable-next-line
  }, [childrenData, mutateInputData])

  return (
    <main className='agent-management-structure'>
      <AllTableHeader
        numberOfItems={data?.totalCount}
        setActiveFilters={setFilterValues}
        filterValues={filterValues}
        data={filtersList}
        rightComponent={<Operations />}
      />
      <Table
        className='structure expanded'
        columns={columns}
        locale={emptyTableConf}
        loading={isFetching || isChildLoaded}
        onChange={onTableParamsChange}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.id}
        rowClassName={(record) => (record.isExpanded ? 'expanded' : '')}
        dataSource={mappedAgents}
        scroll={{ x: 1100 }}
        expandable={{
          onExpand: (expanded, record) => {
            record.isExpanded = expanded
            setExpandedRows(record.id)
          },
          expandIcon: (props) => {
            if (props.record.networkSize === 0) return null
            return tableExpandedIcon(props)
          },
          indentSize: 0,
        }}
        pagination={paginationConfig}
      />
    </main>
  )
}
