import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'

import { IEventHoliday, IEventHolidayBody, ITableConfEvent } from '../../models/IEventsHolidays'
import moment from 'moment/moment'

export const eventsHolidaysApi = createApi({
  reducerPath: 'eventsHolidays',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['EventList', 'HolidayList', 'Holiday', 'Event'],
  endpoints: (builder) => ({
    getEventList: builder.query<ITableResponse<IEventHoliday>, ITableConfEvent>({
      query: (params) => ({
        url: 'calendar/events',
        params,
      }),
      transformResponse: (resp: ITableResponse<IEventHoliday>) => ({
        ...resp,
        items: [...resp?.items].sort((a, b) => moment(a?.start).diff(moment(b?.start))) || [],
      }),
      providesTags: ['EventList'],
    }),

    getHolidayList: builder.query<ITableResponse<IEventHoliday>, ITableConfEvent>({
      query: (params) => ({
        url: 'calendar/holidays',
        params,
      }),
      transformResponse: (resp: ITableResponse<IEventHoliday>) => ({
        ...resp,
        items: [...resp?.items].sort((a, b) => moment(a?.start).diff(moment(b?.start))) || [],
      }),
      providesTags: ['HolidayList'],
    }),

    getEventById: builder.query<IEventHoliday, string>({
      query: (id) => ({
        url: `calendar/events/${id}`,
      }),
      providesTags: (res) => [{ type: 'Event', id: res?.id }],
    }),

    getHolidayById: builder.query<IEventHoliday, string>({
      query: (id) => ({
        url: `calendar/holidays/${id}`,
      }),
      providesTags: (res) => [{ type: 'Holiday', id: res?.id }],
    }),

    createEvent: builder.mutation<IEventHoliday, IEventHolidayBody>({
      query: (body) => ({
        url: `calendar/events`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['EventList'],
    }),

    createHoliday: builder.mutation<IEventHoliday, IEventHolidayBody>({
      query: (body) => ({
        url: 'calendar/holidays',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['HolidayList'],
    }),

    removeEvent: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `calendar/events/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['EventList'],
    }),

    removeHoliday: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `calendar/holidays/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['HolidayList'],
    }),

    editEvent: builder.mutation<IEventHoliday, { body: Partial<IEventHolidayBody>; id: string }>({
      query: ({ body, id }) => ({
        url: `calendar/events/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['EventList', 'Event'],
    }),

    editHoliday: builder.mutation<IEventHoliday, { body: Partial<IEventHolidayBody>; id: string }>({
      query: ({ body, id }) => ({
        url: `calendar/holidays/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['HolidayList', 'Holiday'],
    }),
  }),
})

export const {
  useGetEventListQuery,
  useGetHolidayListQuery,
  useCreateEventMutation,
  useCreateHolidayMutation,
  useEditEventMutation,
  useEditHolidayMutation,
  useRemoveEventMutation,
  useRemoveHolidayMutation,
  useLazyGetHolidayByIdQuery,
  useLazyGetEventByIdQuery,
  useLazyGetEventListQuery,
  useLazyGetHolidayListQuery,
} = eventsHolidaysApi
