import React, { memo, useEffect } from 'react'
import { useGetPermissionModulesListQuery } from '../../../features/Settings/core/http/RolesAndPermissionsManagement'
import {
  addPermissionModules,
  setPermissionsLoading,
} from '../../../features/Settings/core/store/RoleAndPermissionsManagementSlice'
import { useAppDispatch } from '../../../redux'
import { useGetProfileQuery } from '../../../features/Profile/core/http/Profile'
import { addProfileDetails } from '../../../features/Profile/core/store/ProfileInfoSlice'
import { IUserDetail } from '../../../features/Settings/models/IUser'

const ProfilePermissions = () => {
  const dispatch = useAppDispatch()

  const { data: profile } = useGetProfileQuery()
  const { data: permissionModules, isLoading, isFetching } = useGetPermissionModulesListQuery()

  useEffect(() => {
    const load = isLoading || isFetching
    dispatch(setPermissionsLoading(load))
    // eslint-disable-next-line
  }, [isLoading, isFetching])

  useEffect(() => {
    if (profile) {
      dispatch(addProfileDetails(profile as IUserDetail))
    }
    // eslint-disable-next-line
  }, [profile])

  useEffect(() => {
    if (permissionModules) {
      dispatch(addPermissionModules(permissionModules?.items ?? []))
    }
    // eslint-disable-next-line
  }, [permissionModules])
  return <div />
}

export default memo(ProfilePermissions)
