import { memo, useEffect, useState } from 'react'
import { Select as AntSelect, SelectProps } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import classNames from 'classnames'

import './Select.scss'
import { IObjInfo } from '../../../features/salesNetwork/models/IAgent'
import { formatTimezones } from 'src/shared/data'

export interface ISelectItem extends IObjInfo {
  [key: string]: string | number | boolean
}

interface IProps extends SelectProps {
  defaultValue?: string | number | null
  className?: string
  label?: string
  disabled?: boolean
  view?: boolean
  id?: string
  value?: string
  placeholder?: string
  allowClear?: boolean
  open?: boolean
  items: Array<ISelectItem>
  onClearHandle?: () => void
  onChange: (val: DefaultOptionType) => void
  valueBy?: string
  labelBy?: string
}

export const SimpleSelect = memo(
  ({
    items,
    defaultValue = null,
    className = '',
    disabled = false,
    view = false,
    id,
    value,
    placeholder,
    allowClear = false,
    open,
    onChange,
    valueBy = 'id',
    labelBy = 'name',
  }: IProps) => {
    const [selectedItem, setSelectedItem] = useState<DefaultOptionType>({
      label: defaultValue,
    })

    const handleChange = (option: DefaultOptionType) => {
      option && setSelectedItem({ label: option.label, value: option.value })
      onChange && onChange(option)
    }

    useEffect(() => {
      if (!value) return
      const item = formatTimezones.find((i) => i.value === value)
      setSelectedItem({ ...item, label: item?.name })
    }, [value])

    return (
      <div
        className={classNames('select', {
          'select-view': view,
        })}
      >
        <AntSelect
          id={id}
          placeholder={placeholder}
          labelInValue={true}
          className={className}
          style={{ width: '100%' }}
          defaultValue={selectedItem}
          value={{ label: selectedItem.label || value }}
          onChange={handleChange}
          showArrow
          showSearch={false}
          disabled={disabled || view}
          allowClear={allowClear}
          open={open}
        >
          {items.map((item) => (
            <AntSelect.Option key={item.id} value={item[valueBy]}>
              {item[labelBy]}
            </AntSelect.Option>
          ))}
        </AntSelect>
      </div>
    )
  }
)
