import React, { memo, useContext } from 'react'
import { useAppSelector } from '../../../../redux'
import { selectInternalChatChatsState } from '../../core/store/Chats'
import { PurchaseOrderModal } from '../../../Reports/pages/Transactions/Components/Modals/PurchaseOrderModal'
import { PickupRequestModal } from '../../../Reports/pages/Transactions/Components/Modals/PickupRequestModal'
import { ReverseTransactionDetailModal } from '../../../Reports/pages/Transactions/Components/Modals/ReverseTransactionDetailModal'
import { TopupTransactionDetailModal } from '../../../Reports/pages/Transactions/Components/Modals/TopupTransactionDetailModal'
import { ArrowLeftIcon } from '../../../../assets/svg'
import { IconButton } from '../../../../shared/components/iconButton/IconButton'
import { InternalChatContext } from '../InternalChatModal'
import { TRANSACTION_CATEGORY_ENUM } from '../../../Finance/models/ITransaction'
import { TransferFundDetailModal } from '../../../Reports/pages/Transactions/Components/Modals/TransferFundDetailModal'
import { SubTransactionDetailModal } from '../../../Reports/pages/Transactions/Components/Modals/SubTransactionDetailModal'
import styles from './styles.module.scss'
import { modalViewTypeEnum } from '../../core/models'

const isEqualNormalizeText = (a = '', b = '') => a.toLowerCase() === b.toLowerCase()

const ChatTransactionView = () => {
  const { onSelectChat } = useContext(InternalChatContext)
  const { openedTransaction, openedChatId } = useAppSelector(selectInternalChatChatsState)

  return (
    <div className={styles.internalChatTransactionsContainer}>
      <div className={styles.internalChatTransactionsHeader}>
        <IconButton
          color='blue'
          type='default'
          icon={<ArrowLeftIcon />}
          className={styles.backButton}
          onClick={() => onSelectChat(modalViewTypeEnum.messaging, openedChatId as number)}
        />
        <div className={styles.internalChatTransactionsName}>{openedTransaction?.transaction}</div>
      </div>
      {isEqualNormalizeText(openedTransaction?.transaction, TRANSACTION_CATEGORY_ENUM.TOPUP) && (
        <TopupTransactionDetailModal />
      )}

      {isEqualNormalizeText(openedTransaction?.transaction, TRANSACTION_CATEGORY_ENUM.REVERSE) ||
        (isEqualNormalizeText(
          openedTransaction?.transaction,
          TRANSACTION_CATEGORY_ENUM.REVERSE_TOPUP
        ) && <ReverseTransactionDetailModal />)}

      {isEqualNormalizeText(openedTransaction?.transaction, TRANSACTION_CATEGORY_ENUM.PICKUP) ||
        (isEqualNormalizeText(
          openedTransaction?.transaction,
          TRANSACTION_CATEGORY_ENUM.PICKUP_REQUEST
        ) && <PickupRequestModal />)}

      {isEqualNormalizeText(
        openedTransaction?.transaction,
        TRANSACTION_CATEGORY_ENUM.PURCHASE_ORDER
      ) && <PurchaseOrderModal />}

      {isEqualNormalizeText(
        openedTransaction?.transaction,
        TRANSACTION_CATEGORY_ENUM.TRANSFER_FUND
      ) && <TransferFundDetailModal />}

      {[
        TRANSACTION_CATEGORY_ENUM.CREATE_EMONEY,
        TRANSACTION_CATEGORY_ENUM.DISCOUNT,
        TRANSACTION_CATEGORY_ENUM.KILL_EMONEY,
        TRANSACTION_CATEGORY_ENUM.PICKUP_REQUEST_SUBTRANSACTION,
        TRANSACTION_CATEGORY_ENUM.BANK_ACCOUNT_TRANSACTION,
        TRANSACTION_CATEGORY_ENUM.PURCHASE_ORDER_SUBTRANSACTION,
        TRANSACTION_CATEGORY_ENUM.TRUST_WALLET_TRANSACTION,
        TRANSACTION_CATEGORY_ENUM.DISCOUNT_WALLET_TRANSACTION,
      ].includes(openedTransaction?.transaction as TRANSACTION_CATEGORY_ENUM) && (
        <SubTransactionDetailModal />
      )}
    </div>
  )
}
export default memo(ChatTransactionView)
