import { identity, isEmpty, isNumber, pickBy } from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { RightModalContext } from 'src/features/Modals'
import {
  useGetAgentListQuery,
  useGetAllTableFiltersListQuery,
} from 'src/features/salesNetwork/core/http/AgentManagement'
import { useCreateAudienceMutation } from 'src/features/salesNetwork/core/http/SceduleNotifications'
import { IAgent } from 'src/features/salesNetwork/models/IAllTable'
import { AudienceType, IAudienceBody } from 'src/features/salesNetwork/models/SceduleNotification'
import {
  useGetUsersFiltersListQuery,
  useGetUsersListQuery,
} from 'src/features/Settings/core/http/UsersManagementApi'
import useSearch from 'src/features/Settings/helpers/useSearch'
import { IUser } from 'src/features/Settings/models/IUser'
import { InfoMessage } from 'src/features/Settings/pages/VendorManagement/VendorManagementContactListModal/Components/InfoMessage'
import ShowMessage, { ErrorNode } from 'src/shared/api/errorHandler'
import { Checkbox } from 'src/shared/components/checkbox/Checkbox'
import { ISelectedFilters } from 'src/shared/components/filter/Filter'
import { InfiniteScrollComponent } from 'src/shared/components/infiniteScoll/InfiniteScroll'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ITableConf } from 'src/shared/models/ITableConf'
import { ModalHeader } from '../../Components/ModalHeader'
import emptyAvatarImage from 'src/assets/img/Avatar.png'

import styles from '../../styles.module.scss'
import { Button } from 'src/shared/components/button/Button'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { ArrowRightIcon } from 'src/assets/svg'
import { Step } from 'src/features/Support/models/IMPin'
import { Space, Spin } from 'antd'
import { paginationSettings } from 'src/shared/hooks/table/useTable'

interface ISelectAudienceStep {
  step: Step
  onChangeStep: (step: Step, agentId: string | number) => void
  audienceType: AudienceType
  id: string
}

export const SelectAudienceStep = ({ id, audienceType, onChangeStep }: ISelectAudienceStep) => {
  const { onClose } = useContext(RightModalContext)

  const [isChangeSearch, setIsChangeSearch] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [itemList, setItemList] = useState<(IAgent | IUser)[]>([])
  const [selectedItemList, setSelectedItemList] = useState<number[]>([])
  const [filterValues, setFilterValues] = useState<ISelectedFilters>({})
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 10,
    search: '',
  })

  const { data: agentFilterFields } = useGetAllTableFiltersListQuery(null, {
    skip: audienceType === 'users',
  })
  const { data: usersFilterFields } = useGetUsersFiltersListQuery(null, {
    skip: audienceType === 'agents',
  })
  const agentList = useGetAgentListQuery(
    { ...tableConf, ...filterValues },
    { skip: audienceType === 'users' }
  )
  const userList = useGetUsersListQuery(
    { ...tableConf, ...filterValues },
    { skip: audienceType === 'agents' }
  )

  const filterFields = agentFilterFields || usersFilterFields
  const dataList = useMemo(
    () => (agentList.data ? agentList : userList),
    [agentList.data, userList.data]
  )

  const [createAudience, dCreate] = useCreateAudienceMutation()

  const { handleSearchChange } = useSearch(setTableConf)

  useNotification(NOTIFICATION_TYPES.success, dCreate.isSuccess, null, onClose)
  useNotification(NOTIFICATION_TYPES.error, dCreate.isError, dCreate.error as ErrorNode)

  useEffect(() => {
    if (isEmpty(dataList.data)) return
    if (isChangeSearch && (tableConf.search || Object.keys(filterValues).length !== 0)) {
      setItemList(dataList.data.items)
    } else {
      if (isChangeSearch && !tableConf.search) {
        setItemList(dataList.data.items)
        setIsChangeSearch(false)
      } else {
        setItemList((p) => [...p, ...dataList!.data!.items])
      }
    }
    // eslint-disable-next-line
  }, [dataList.data])

  useEffect(() => {
    if (!selectAll) return

    setSelectedItemList(itemList.map((i) => +i.id)!)
  }, [itemList, selectAll])

  useEffect(() => {
    setSelectedItemList([])
  }, [filterValues])

  useEffect(() => {
    if (selectedItemList.length === dataList?.data?.totalCount && !!selectedItemList.length)
      setSelectAll(true)
  }, [selectedItemList])

  const loadMoreData = () => {
    setTableConf((pre: ITableConf) => ({ ...pre, page: pre.page! + 1 }))
  }

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChangeSearch(true)
    handleSearchChange(e)
    setSelectedItemList([])
  }

  const handlerSetActiveFilters = (values: ISelectedFilters) => {
    setIsChangeSearch(true)
    setFilterValues(values)
    setTableConf((p) => ({ ...p, page: paginationSettings.current }))
  }

  const onAddButtonClick = (id: number) => () => {
    const isIncludes = selectedItemList.includes(+id)

    if (selectAll) setSelectAll(false)

    if (isIncludes) {
      setSelectedItemList((p) => p.filter((i) => i !== +id))
    } else {
      setSelectedItemList((p) => [...p, +id])
    }
  }

  const onCheckboxChange = () => {
    setSelectAll((p) => !p)
    if (!selectAll) {
      // eslint-disable-next-line
      setSelectedItemList(itemList.map((i) => +i.id)!)
    } else {
      setSelectedItemList([])
    }
  }

  const onFinish = () => {
    const filtersType = audienceType === 'agents' ? 'agentFilters' : 'userFilters'
    const itemType = audienceType === 'agents' ? 'agent' : 'user'

    if (isEmpty(selectedItemList)) {
      ShowMessage('error', `Need to add at least one ${itemType}`)
      return
    }

    const filters = pickBy(filterValues, identity)

    const req = {
      id: id as string,
      audienceType,
      body: {
        [filtersType]: filters,
        selectedIds: selectedItemList,
        selectAll,
      } as IAudienceBody,
    }
    createAudience(req)
  }

  const infoMessageTitle = audienceType === 'agents' ? 'Agent' : 'User'

  const content = (
    <React.Fragment>
      <InfiniteScrollComponent
        loadMoreData={loadMoreData}
        dataLength={itemList.length}
        containerStyles={{ width: '100%' }}
        loaderStyles={{ width: 'calc(100% - 32px - 24px)', marginLeft: '32px' }}
        // eslint-disable-next-line
        totalCount={dataList.data?.totalCount! as number}
      >
        <div className={styles.formContainer}>
          <InfoMessage text={infoMessageTitle} />
          <ModalHeader
            filterValues={filterValues}
            data={filterFields}
            setActiveFilters={handlerSetActiveFilters}
            onSearchChange={onSearchChange}
          />

          <div className={styles.amountContainer}>
            <div className={styles.amountContacts}>
              {`${dataList.data?.totalCount || 0} ${
                audienceType === 'agents' ? 'agents' : 'users'
              }`}
            </div>
            <Checkbox
              className={styles.amountContacts}
              color='blue'
              text='Select all'
              checked={selectAll}
              onChange={onCheckboxChange}
            />
          </div>
          {itemList.map((item) => {
            const isSelected = selectedItemList.includes(+item.id)
            return (
              <div className={styles.agentContainer} key={item.id}>
                <div className={styles.leftContainer}>
                  <img
                    src={(item as IAgent).avatar ?? (item as IUser).logo ?? emptyAvatarImage}
                    alt='image'
                  />
                  <div className={styles.agentInfo}>
                    <div className={styles.agentName}>
                      {(item as IAgent).companyTitle
                        ? `${(item as IAgent).companyTitle}`
                        : `${item.firstName} ${item.lastName}`}
                    </div>
                    <div className={styles.agentTypeContainer}>
                      {audienceType === 'agents' && (
                        <React.Fragment>
                          <div className={styles.agentType}>{(item as IAgent).agentType.name}</div>
                          <div>{`Level ${(item as IAgent).agentLevel.level}`}</div>
                        </React.Fragment>
                      )}
                      {audienceType === 'users' && <div>{(item as IUser).department?.name}</div>}
                    </div>
                    <div className={styles.idContainer}>
                      <div className={styles.agentId}>
                        {`ID `}
                        <div>{item.id}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.rightContainer}>
                  <Space size={10}>
                    <Button
                      size='small'
                      color={isSelected ? 'error' : 'orange'}
                      onClick={onAddButtonClick(item.id)}
                    >
                      {isSelected ? 'Remove' : 'Add'}
                    </Button>
                    <IconButton
                      color='blue'
                      size='small'
                      icon={<ArrowRightIcon />}
                      onClick={() =>
                        onChangeStep(
                          audienceType === 'agents' ? 'agentProfile' : 'userProfile',
                          item.id
                        )
                      }
                    />
                  </Space>
                </div>
              </div>
            )
          })}
        </div>
      </InfiniteScrollComponent>
      <div className={styles.footerContainer}>
        <Button size='large' color='orange' type='primary' onClick={onFinish}>
          Create Audience
        </Button>
      </div>
    </React.Fragment>
  )

  return !isNumber(dataList.data?.totalCount) ? <Spin>{content}</Spin> : content
}
