import { useCallback, useEffect } from 'react'
import { Form, Row, Space, Spin } from 'antd'
import { isEmpty, omit } from 'lodash'
import { useParams, useNavigate } from 'react-router-dom'

import { Button } from 'src/shared/components/button/Button'
import { DROP_SCHEDULE_FIELDS, Sheduler } from 'src/shared/components/sheduler/Sheduler'
import { formatOnlyDate, getFormCalendarTime } from 'src/helpers/utils'
import { DetailInfo } from '../Components/DetailInfo'

import {
  IScheduleNotificationBody,
  IScheduleNotificationForm,
} from 'src/features/salesNetwork/models/SceduleNotification'

import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import {
  useCreateScheduleNotificationMutation,
  useGetScheduleNotificationByIdQuery,
  useUpdateScheduleNotificationMutation,
} from 'src/features/salesNetwork/core/http/SceduleNotifications'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { OnTypes } from 'src/shared/components/sheduler/index.d'

// styles
import styles from './styles.module.scss'
import moment from 'moment'
import { CALENDAR_TYPE_ENUM } from 'src/features/Settings/models/IUser'
import dayjs from 'dayjs'
import { SALES_ENUM } from '../../../../../routes/sales'

export const ScheduleNotificationPage = ({}) => {
  const { mode, id } = useParams()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const profile = useGetProfileQuery()
  const { data, isFetching } = useGetScheduleNotificationByIdQuery(id as string, { skip: !id })

  const [updateNotification, dUpdate] = useUpdateScheduleNotificationMutation()
  const [createNotification, dCreate] = useCreateScheduleNotificationMutation()

  const afterCreate = () => {
    navigate(SALES_ENUM.SCEDULE_NOTIFICATIONS)
  }

  const setInitialForm = useCallback(() => {
    if (isEmpty(data)) return
    const { contents } = data

    if (data.scheduler.time) {
      const [hour, min] = data.scheduler.time.split(':')

      form.setFieldsValue({
        ...data,
        'sh-date':
          profile?.data?.calendar === CALENDAR_TYPE_ENUM.SHAMSI
            ? dayjs()
                .calendar('jalali')
                .set('hour', +hour)
                .set('minute', +min)
                .set(
                  'date',
                  !isEmpty(data.scheduler.monthlyDays) ? data.scheduler.monthlyDays![0] : 0
                )
            : moment().set({
                hour: +hour,
                minute: +min,
                ...(!isEmpty(data.scheduler.monthlyDays) && {
                  date: data.scheduler.monthlyDays![0],
                }),
              }),
      })
    }

    form.setFieldsValue({
      ...data,
      audienceType: data?.audience.audienceType,
      createdBy: data.createdBy.name,
      createdAt: formatOnlyDate(data.createdAt, profile?.data?.calendar),
      'content-1': contents.find(({ languageId }) => languageId === 1)?.body || '',
      'content-2': contents.find(({ languageId }) => languageId === 2)?.body || '',
      'content-3': contents.find(({ languageId }) => languageId === 3)?.body || '',
      ...(data.scheduler.expireOn && {
        'sh-expire-time': getFormCalendarTime(
          profile?.data?.calendar,
          data.scheduler.expireOn as string
        ),
      }),
      'sh-frequency': data.scheduler.frequency,
      'sh-expire': data.scheduler.expire,
      'sh-day': String(data.scheduler.weekDay),
      'sh-on': data.scheduler.monthlyType,
    })
  }, [data, form, profile?.data?.calendar])

  useNotification(
    NOTIFICATION_TYPES.success,
    dCreate.isSuccess || dUpdate.isSuccess,
    null,
    afterCreate
  )
  useNotification(NOTIFICATION_TYPES.error, dCreate.isError, dCreate.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, dUpdate.isError, dUpdate.error as ErrorNode)

  useEffect(() => {
    setInitialForm()
  }, [profile.data, setInitialForm])

  useEffect(() => {
    if (isEmpty(profile.data)) return

    form.setFieldsValue({
      createdBy: `${profile.data.firstName} ${profile.data.lastName}`,
      createdAt: formatOnlyDate(data?.createdAt, profile?.data?.calendar),
    })
  }, [profile.data])

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const onCancelButtonClick = () => {
    setInitialForm()
    if (isEditMode) navigate(`/sales-network/campaign/view/${id}`)
    if (isViewMode) navigate('/sales-network/campaign')
  }

  const onEditButtonClick = () => {
    navigate(`/sales-network/campaign/edit/${id}`)
  }

  const onFinish = (values: IScheduleNotificationForm) => {
    const req = {
      ...omit(values, [
        ...(id ? ['audienceType'] : []),
        'createdBy',
        'createdAt',
        'languageId',
        'content-1',
        'content-2',
        'content-3',
        ...DROP_SCHEDULE_FIELDS,
      ]),
      status: true,
      content: [
        ...(values['content-1'] ? [{ languageId: 1, body: values['content-1'] }] : []),
        ...(values['content-2'] ? [{ languageId: 2, body: values['content-2'] }] : []),
        ...(values['content-3'] ? [{ languageId: 3, body: values['content-3'] }] : []),
      ],
      scheduler: {
        frequency: values['sh-frequency'],
        time: values['sh-date']!.format('HH:mm'),
        expire: values['sh-expire'],
        ...(values['sh-day'] && { weekDay: +values['sh-day'] }),
        ...(values['sh-expire-time'] && {
          expireOn: values['sh-expire-time'].toISOString(),
        }),
        ...(values['sh-on'] === OnTypes.custom && { monthlyDays: [values['sh-date']!.date()] }),
        ...(values['sh-on'] && { monthlyType: values['sh-on'] }),
      },
    } as IScheduleNotificationBody

    id ? updateNotification({ id, body: req }) : createNotification(req)
  }

  const content = (
    <Form form={form} layout='vertical' onFinish={onFinish} className={styles.container}>
      <div className={styles.leftColumn}>
        <DetailInfo form={form} view={isViewMode} edit={isEditMode} />
      </div>
      <div className={styles.rightColumn}>
        <Sheduler form={form} view={isViewMode} />
      </div>
    </Form>
  )

  const loading = dCreate.isLoading || profile.isFetching || isFetching || dUpdate.isLoading
  const isEDitSbmt = isEditMode || (!isEditMode && !isViewMode)

  return (
    <main>
      {loading ? <Spin>{content}</Spin> : content}
      <div className={styles.wrapper}>
        <Row className={styles.footer} justify='end'>
          <Space size={10}>
            <Button color='blue' size='middle' disabled={false} onClick={onCancelButtonClick} block>
              Cancel
            </Button>
            {isEDitSbmt && (
              <Button
                htmlType='submit'
                size='middle'
                type='primary'
                block
                disabled={loading}
                onClick={() => form.submit()}
              >
                Save
              </Button>
            )}
            {isViewMode && (
              <Button
                htmlType='submit'
                size='middle'
                type='primary'
                block
                disabled={loading}
                onClick={onEditButtonClick}
              >
                Edit
              </Button>
            )}
          </Space>
        </Row>
      </div>
    </main>
  )
}
