import styles from '../styles.module.scss'
import { ReactNode } from 'react'

interface IProps {
  children: ReactNode
  title: string
}

export const TableWrapper = ({ children, title }: IProps) => {
  return (
    <div className={styles.productAccess}>
      <div className={styles.header}>{title}</div>
      <div className={styles.content}>
        <div className={styles.wrapperSearchAndFilerAndAddButton}>{children}</div>
      </div>
    </div>
  )
}
