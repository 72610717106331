import { ISelectedFilters } from '../../components/filter/Filter'
import { Form, FormInstance } from 'antd'
import { useCallback, useState } from 'react'
import moment from 'moment'
import { formatMomentDateToDayEnd, formatMomentDateToDayStart } from '../../../helpers/utils'

interface IProps {
  setActiveFilters: (values: ISelectedFilters) => void
}

export interface IUseFilterReturn {
  isOpen: boolean
  form: FormInstance
  onCancel: () => void
  onReset: () => void
  onFinish: (values: ISelectedFilters) => void
}

export const useFilter = ({ setActiveFilters }: IProps): IUseFilterReturn => {
  const [form] = Form.useForm()
  const [isOpen, setIsOpen] = useState(false)

  const onFinish = useCallback(
    (values: ISelectedFilters) => {
      const date = { ...values } as { [key: string]: string }

      Object.entries(values).forEach(([label, value]) => {
        if (moment.isMoment(value)) {
          date[label] = moment(value).toISOString(true)
        }
        if (Array.isArray(value)) {
          date[`${label}_more`] = moment.isMoment(value[0])
            ? formatMomentDateToDayStart(moment(value[0])).toISOString(true)
            : value[0]
          date[`${label}_less`] = moment.isMoment(value[1])
            ? formatMomentDateToDayEnd(moment(value[1])).toISOString(true)
            : value[1]
          delete date[label]
        }
      })

      setActiveFilters({ ...date })
      setIsOpen(false)
    },
    [setActiveFilters]
  )

  const onCancel = useCallback(() => {
    setIsOpen((prevState) => !prevState)
  }, [])

  const onReset = useCallback(() => {
    form.resetFields()
    setActiveFilters({})
    setIsOpen(false)
  }, [form, setActiveFilters])

  return { isOpen, onFinish, onReset, onCancel, form }
}
