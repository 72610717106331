import React, { useCallback, useState } from 'react'
import {
  BarChartOutlinedIcon,
  CalendarRounded,
  TableActionIcon,
  UserGroupRounded,
} from '../../../../../../../assets/svg'
import { Widget } from '../../../../../../../shared/components/widget'

import { IPopupListItems, Popup } from '../../../../../../../shared/components/popup/Popup'
import { FINANCE_ENUM } from '../../../../../../../routes/finance'
import { useNavigate } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import { ICreditScore } from '../../../../../models/ICreditScore'
import { Table } from 'antd'
import { ITableConf } from '../../../../../../../shared/models/ITableConf'
import { tableExpandedIcon } from '../../../../../../../helpers/utils'
import styles from './styles.module.scss'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'

const scores: Array<ICreditScore> = [
  {
    id: 1,
    criteriaName: 'Age in the System',
    score: 20,
    children: [{ id: 6, criteriaName: 'After 3 month', score: 5 }],
  },
  { id: 2, criteriaName: 'Amount of pickups', score: 20 },
  { id: 3, criteriaName: 'Topup Sales (Include Network)', score: 20 },
  { id: 4, criteriaName: 'Network Size', score: 20 },
  {
    id: 5,
    criteriaName: 'Loan Clearance',
    score: 20,
    children: [
      { id: 10, criteriaName: 'After 4 month', score: 5 },
      { id: 7, criteriaName: 'After 5 month', score: 5 },
      { id: 8, criteriaName: 'After 6 month', score: 5 },
      { id: 9, criteriaName: 'After 7 month', score: 5 },
    ],
  },
]

const CreditScoreOverview = () => {
  const navigate = useNavigate()
  const { queryParams } = useGetQueryParams()
  const [, setTableConf] = useState<ITableConf>(queryParams)
  const [mappedMasters] = useState<ICreditScore[]>(scores)
  const [, setExpandedRows] = useState<number | undefined>()

  const { pagination, handleTableChange } = useTable<ICreditScore>({
    total: scores.length,
    setTableConf,
  })

  const tableActionsPopup = useCallback(
    (id: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () => navigate(`${FINANCE_ENUM.CREDIT_SCORE_PARAMETER_DETAIL}/view/${id}`),
        },
      ]
    },
    [navigate]
  )

  const columns: ColumnsType<ICreditScore> = [
    {
      title: '',
      dataIndex: '',
      key: 'expand',
      width: '1%',
      onCell: () => {
        return {}
      },
    },
    {
      title: 'Criteria name',
      dataIndex: 'criteriaName',
      width: '78%',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      width: '20%',
      render: (_, row) => {
        return (
          <div className={row.score >= 20 ? styles.positiveValue : styles.negativeValue}>
            {row.score}
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => {
        if (!Object.hasOwn(row, 'children')) return
        return (
          <Popup data={tableActionsPopup(row.id)}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        )
      },
    },
  ]

  return (
    <div>
      <div className={styles.widgetsWrapper}>
        <Widget
          icon={<UserGroupRounded />}
          title='Eligable Agents'
          value={2320}
          valueTotal={6000}
          statistic={15}
        />
        <Widget
          icon={<CalendarRounded />}
          title='Total Eligible Loan Amount'
          value={1300000}
          statistic={35}
        />
        <Widget
          icon={<BarChartOutlinedIcon />}
          title='Credit Wallet Balance'
          value={1000000}
          statistic={35}
        />
      </div>
      <div>
        <Table
          columns={columns}
          pagination={pagination}
          className={styles.table}
          onChange={handleTableChange}
          loading={false}
          rowKey={(row) => row?.id}
          dataSource={mappedMasters}
          rowSelection={{ type: 'checkbox' }}
          scroll={{ x: 1100 }}
          expandable={{
            expandIcon: (props) => {
              if (!Object.hasOwn(props.record, 'children')) return null
              return tableExpandedIcon<ICreditScore>(props)
            },
            onExpand: (expanded, record) => {
              setExpandedRows(record.id)
            },
          }}
        />
      </div>
    </div>
  )
}

export default CreditScoreOverview
