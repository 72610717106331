import React, { useContext, useEffect } from 'react'
import { CenterModalContext, RightModalContext } from '../../../../../../Modals'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useAppDispatch } from '../../../../../../../redux'
import { SETTINGS_ENUM } from '../../../../../../../routes/settings'
import { CENTER_MODALS, RIGHT_MODALS } from '../../../../../../../helpers/contants'
import { setBreadcrumb } from '../../../../../../app/core/BreadcrumbsSlice'
import { Space, Tabs } from 'antd'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, DownloadIcon, EditIcon } from '../../../../../../../assets/svg'
import { Toggle } from '../../../../../../../shared/components/toggle/Toggle'
import { PROMOTION_POLICY_TABS_ENUM } from './index.d'
import { InformationTab } from '../InformationTab'
import {
  useGetPromotionPolicyByIdQuery,
  useUpdatePromotionPolicyMutation,
} from '../../../../../core/http/PromotionPolicyApi'
import { MasterProductsTab } from '../MasterProductsTab'
import { TargetGroupTable } from '../TargetGroupTable'
import { PROMOTION_POLICY_EVENT_ENUM } from '../../../../../models/IPromotionPolicy'
import { Emitter, EmitterEvents } from '../../../../../../../helpers/eventEmitter'
import { TableExportFormatEnum } from '../../../../../../../shared/hooks/table/useTableExport'
import { Button } from '../../../../../../../shared/components/button/Button'
import { useSearchParams } from 'react-router-dom'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'

const Operations = ({ activeTab }: { activeTab: string }) => {
  const { onOpen } = useContext(RightModalContext)
  const { onOpen: onOpenCenter, onClose } = useContext(CenterModalContext)
  const navigate = useNavigate()
  const { id, mode } = useParams()
  const dispatch = useAppDispatch()

  const { data } = useGetPromotionPolicyByIdQuery(id as string)
  const [updProm, updPromResp] = useUpdatePromotionPolicyMutation()

  const handlerExportClick = () => {
    onOpenCenter(CENTER_MODALS.EXPORT_TABLE, {
      title: 'Export Table',
      onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
      onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      footer: <Button onClick={onClose}>Cancel</Button>,
    })
  }

  const onEditButtonClick = () => {
    navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/promotion-policy/edit/${id}`)
  }

  const onAddButtonClick = () => {
    onOpen(RIGHT_MODALS.SETTINGS.NETWORK_CONFIGURATION_TARGET_GROUP_LIST, {
      title: 'Select Target Group',
      promotionId: id,
    })
  }

  useEffect(() => {
    if (data) {
      dispatch(setBreadcrumb(data))
    }
  }, [data, dispatch])

  const isTargetGroupTabActive = activeTab === PROMOTION_POLICY_TABS_ENUM.TARGET_GROUP

  return (
    <Space size={10}>
      {!isTargetGroupTabActive && mode === 'view' && (
        <IconButton color='orange' icon={<EditIcon />} onClick={onEditButtonClick} />
      )}
      {isTargetGroupTabActive && (
        <IconButton color='blue' icon={<DownloadIcon />} onClick={handlerExportClick} />
      )}
      <Toggle
        defaultChecked={data?.status}
        checked={data?.status}
        text='Status'
        inWrapper
        loading={updPromResp.isLoading}
        onChange={(status) => {
          updProm({ id: id as string, body: { status } })
        }}
      />
      {isTargetGroupTabActive && (
        <IconButton color='orange' icon={<AddIcon />} type='primary' onClick={onAddButtonClick} />
      )}
    </Space>
  )
}

export const PromotionPolicyDetailViewTabs = () => {
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: PROMOTION_POLICY_TABS_ENUM.INFORMATION,
  })

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  const { id } = useParams()

  const { data } = useGetPromotionPolicyByIdQuery(id as string)

  const items = [
    {
      label: PROMOTION_POLICY_TABS_ENUM.INFORMATION,
      key: PROMOTION_POLICY_TABS_ENUM.INFORMATION,
      children: <InformationTab />,
    },
    {
      label: PROMOTION_POLICY_TABS_ENUM.MASTER_PRODUCTS,
      key: PROMOTION_POLICY_TABS_ENUM.MASTER_PRODUCTS,
      children: <MasterProductsTab activeTab={activeTab} />,
      disabled:
        data?.event !== PROMOTION_POLICY_EVENT_ENUM.TOPUP &&
        data?.event !== PROMOTION_POLICY_EVENT_ENUM.PICKUP &&
        data?.event !== PROMOTION_POLICY_EVENT_ENUM.ACCESS_CHANNEL,
    },
    {
      label: PROMOTION_POLICY_TABS_ENUM.TARGET_GROUP,
      key: PROMOTION_POLICY_TABS_ENUM.TARGET_GROUP,
      children: <TargetGroupTable />,
    },
  ]

  return (
    <Tabs
      activeKey={activeTab}
      onChange={onTabChange}
      items={items}
      tabBarExtraContent={<Operations activeTab={activeTab} />}
    />
  )
}
