import styles from '../../styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import {
  maxLengthValidator,
  requiredMulSelectValidator,
  requiredValidator,
} from '../../../../../../../helpers/validation'
import { DatePicker } from '../../../../../../../shared/components/datePicker'
import { Select } from 'antd'
import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import React, { useMemo } from 'react'
import { PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM } from '../../../../../models/IPromotionPolicy'
import { startCase } from 'lodash'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import { Toggle } from '../../../../../../../shared/components/toggle/Toggle'
import { REWARD_POLICY_TYPE_ENUM } from '../../../../../models/IRewardPolicy'
import moment from 'moment/moment'
import { selectTagRender } from '../../../../../../../helpers/selectTagRender'

interface IProps extends IViewMode {
  isCreatePage?: boolean
  accessChannels?: { accessChannels: string[] }
}

export const DetailInfo = ({ view, accessChannels }: IProps) => {
  const measurementType = useMemo(
    () =>
      (
        Object.keys(PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM) as Array<
          keyof typeof PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM
        >
      ).map((method) => ({
        label: startCase(PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM[method].toLowerCase()),
        value: PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM[method],
      })),
    []
  )

  const isOnceButton = (
    <FormItem name='isOnce' valuePropName='checked'>
      <Toggle text='Use Reward Once' disabled={view} />
    </FormItem>
  )

  return (
    <Wrapper title='Detail Info' statusButton={isOnceButton}>
      <div className={styles.dblRow}>
        <FormItem name='createdAt' label='Creation Date'>
          <TextField disabled />
        </FormItem>

        <FormItem name={['createdByUser', 'name']} label='Created By'>
          <TextField disabled />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem
          name='title'
          label='Policy Name'
          rules={[
            {
              required: true,
              validator: requiredValidator('Policy Name'),
            },
            {
              validator: maxLengthValidator(45),
            },
          ]}
        >
          <TextField disabled={view} placeholder='Enter Policy Name' />
        </FormItem>

        <FormItem
          name='type'
          label='Type'
          rules={[
            {
              required: true,
              validator: requiredValidator('Type'),
            },
            {
              validator: maxLengthValidator(45),
            },
          ]}
        >
          <Select disabled={view} placeholder='Select Type' allowClear>
            <Select.Option value={REWARD_POLICY_TYPE_ENUM.TOPUP}>
              {REWARD_POLICY_TYPE_ENUM.TOPUP}
            </Select.Option>
          </Select>
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <div className={styles.doubleRow}>
          <FormItem noStyle shouldUpdate>
            {({ setFieldValue }) => (
              <FormItem
                name='start'
                label='Starting Date'
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('Starting Date'),
                  },
                ]}
              >
                <DatePicker
                  showTime
                  disabled={view}
                  onChange={() => {
                    setFieldValue('end', null)
                  }}
                />
              </FormItem>
            )}
          </FormItem>

          <FormItem noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <FormItem
                name='end'
                label='Expiry Date'
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('Expiry Date'),
                  },
                ]}
              >
                <DatePicker
                  showTime
                  disabled={view || !getFieldValue('start')}
                  disabledDate={(current) =>
                    getFieldValue('start')
                      ? current.isBefore(moment(getFieldValue('start')), 'days') ||
                        current.isSame(moment(getFieldValue('start')), 'days')
                      : false
                  }
                />
              </FormItem>
            )}
          </FormItem>
        </div>

        <div>
          <FormItem noStyle shouldUpdate>
            {({ setFields }) => (
              <FormItem
                label='Type Of Measurement'
                name='measurementType'
                className='noBottomMargin'
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('Type of measurement'),
                  },
                ]}
              >
                <Select
                  disabled={view}
                  options={measurementType}
                  onChange={() => {
                    setFields([{ name: 'event', value: undefined }])
                  }}
                  allowClear
                  placeholder='Select Measurement Type'
                />
              </FormItem>
            )}
          </FormItem>
        </div>
      </div>

      <div>
        <FormItem
          label='Access Channels'
          name='accessChannels'
          className='noBottomMargin'
          rules={[
            {
              required: true,
              validator: requiredMulSelectValidator('Access Channels'),
            },
          ]}
        >
          <Select
            disabled={view}
            mode='multiple'
            placeholder='Select Access Channels'
            tagRender={selectTagRender}
            maxTagCount='responsive'
            showArrow
            allowClear
          >
            {accessChannels?.accessChannels?.map((channel) => (
              <Select.Option key={channel} value={channel}>
                {channel}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </div>
    </Wrapper>
  )
}
