import { ITableResponse } from './ITableResponse'

export enum FieldsTypeEnum {
  NUMBER = 'number',
  STRING = 'string',
  DATE = 'Date',
  BOOLEAN = 'boolean',
}

export enum AllowedOperationsEnum {
  EQUALS = 'equals',
  MORE = 'more',
  LESS = 'less',
  IN = 'in',
  LIKE = 'like',
}

export interface IFilterFieldsSettings {
  type: FieldsTypeEnum
  allowedOperations: Array<AllowedOperationsEnum>
}

export interface IFilterFields {
  field: string
  settings: IFilterFieldsSettings
  data: ITableResponse<string>
}
