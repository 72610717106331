import { PaginationProps, TablePaginationConfig } from 'antd'
import { PaginationNextIcon, PaginationPrevIcon } from '../../../assets/svg'
import { useEffect, useState } from 'react'

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100]

export const useTablePagination = (
  total: number | undefined,
  showSizeChanger = true,
  sizeOption?: number
): TablePaginationConfig => {
  const [pageSizeOptions, setPageSizeOptions] = useState(PAGE_SIZE_OPTIONS)
  const itemRender: PaginationProps['itemRender'] = (_, type) => {
    if (type === 'prev') return <PaginationPrevIcon />
    if (type === 'next') return <PaginationNextIcon />

    return null
  }

  useEffect(() => {
    if (sizeOption) {
      setPageSizeOptions((prevState) => [sizeOption, ...prevState])
    }
  }, [sizeOption])

  return {
    position: ['bottomRight'],
    locale: { items_per_page: '' },
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
    defaultPageSize: sizeOption ? sizeOption : 10,
    pageSizeOptions,
    showSizeChanger,
    total,
    itemRender,
  }
}
