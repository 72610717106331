import styles from '../../../styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import classNames from 'classnames'
import { positiveNumber, requiredValidator } from '../../../../../../../helpers/validation'
import { Select } from 'antd'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { BucketIcon } from '../../../../../../../assets/svg'
import React, { useCallback } from 'react'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import { ITableResponse } from '../../../../../../../shared/models/ITableResponse'
import { IMasterProduct } from '../../../../../models/IMasterProduct'
import FormList from 'antd/es/form/FormList'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { RuleObject } from 'antd/lib/form'
import { AGENT_TYPE_ID_ENUM } from '../../../../../models/IAgentType'
import {
  IDiscountPolicy,
  IPolicyProduct,
  IProductAccess,
} from '../../../../../models/IDiscountPolicy'
import { ACCOUNT_TYPE_TO_ID } from '../../../../../models/IAccountType'

interface IProps extends IViewMode {
  data: ITableResponse<IMasterProduct>
  name: number
  remove: (value: number) => void
  onCreatePage?: boolean
  discountPolicy?: IDiscountPolicy
  view?: boolean
}

export const MasterProductListItem = ({ data, name, remove, view, onCreatePage }: IProps) => {
  const form = useFormInstance()

  // Validation rule for the rate field
  const rateValidationRule = {
    validator: (index: number) => (_: RuleObject, value: string) => {
      const levelRates = form.getFieldValue(['discountPolicyProducts', name, 'levelRates'])

      const maxLevel =
        form.getFieldValue(['agentType', 'id']) === AGENT_TYPE_ID_ENUM.INDIRECT ? 5 : 1
      const minRate = form.getFieldValue('minRate') * (maxLevel - (index + 1) + 1)

      const previousLevelRate = index ? levelRates[index - 1].rate : null
      if (+value > 100) {
        return Promise.reject(new Error('Max rate is 100.'))
      }

      const tolerance = 0.000001
      if (!(Math.abs(+value - +minRate) < tolerance) && +value < +minRate) {
        return Promise.reject(new Error(`Min rate is ${minRate?.toFixed(2)}.`))
      }

      if (previousLevelRate && +value > +previousLevelRate && index !== 0) {
        return Promise.reject(new Error(`Max rate is ${+previousLevelRate}`))
      }

      return Promise.resolve()
    },
  }

  const onProductDelete = useCallback(
    (arrId: number) => {
      const isPrepaid = form.getFieldValue(['accountType', 'id']) === ACCOUNT_TYPE_TO_ID.PREPAID
      if (isPrepaid) return

      const productsAccess = (form.getFieldValue('productsAccess') as IProductAccess[]) || []

      const elementMasterProduct = (
        form.getFieldValue('discountPolicyProducts') as IPolicyProduct[]
      )[arrId]?.masterProduct

      if (!elementMasterProduct?.id) {
        return
      }

      if (
        elementMasterProduct &&
        !productsAccess?.some((access) => access?.masterProduct?.id === elementMasterProduct.id)
      ) {
        form.setFieldValue('productsAccess', [
          ...productsAccess,
          { masterProduct: elementMasterProduct },
        ])
      }
    },
    [form]
  )

  const onProductChange = () => {
    const isPrepaid = form.getFieldValue(['accountType', 'id']) === ACCOUNT_TYPE_TO_ID.PREPAID
    if (isPrepaid) return

    const masterProductType = form.getFieldValue('masterProductType')
    const discountPolicyProducts =
      (form.getFieldValue('discountPolicyProducts') as IPolicyProduct[]) || []

    const productsAccess = data?.items
      .filter(
        (master) =>
          !discountPolicyProducts?.some((product) => product?.masterProduct?.id === master?.id) &&
          masterProductType === master.type
      )
      ?.map((filtered) => ({ masterProduct: filtered }))

    form.setFieldsValue({ productsAccess })
  }

  const onRateChange = (index: number) => {
    const nextLevel = ['discountPolicyProducts', name, 'levelRates', index + 1, 'rate']

    if (form.getFieldValue(nextLevel)) {
      form.validateFields([nextLevel])
    }
  }

  return (
    <div
      className={classNames(styles.masterProductItemContainer, {
        [styles.masterProductItemContainerView]: !onCreatePage,
      })}
    >
      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const selectedProducts = (
            getFieldValue('discountPolicyProducts') as Array<IPolicyProduct>
          )?.map((product) => product?.masterProduct?.id)

          const selectedProductType = getFieldValue('masterProductType')

          return (
            <FormItem
              name={[name, 'masterProduct', 'id']}
              className={classNames(styles.fullWidth, 'noBottomMargin')}
              style={{ minWidth: 300 }}
              label='Master Product'
              rules={[
                {
                  required: true,
                  validator: requiredValidator('Product'),
                },
              ]}
            >
              <Select
                disabled={view}
                allowClear
                placeholder='Select Product'
                onChange={onProductChange}
              >
                {data?.items
                  ?.filter((item) => item?.type === selectedProductType)
                  ?.map((product) => (
                    <Select.Option
                      key={product.id}
                      value={product.id}
                      disabled={selectedProducts?.includes(product?.id)}
                    >
                      {product.name}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
          )
        }}
      </FormItem>

      <FormList name={[name, 'levelRates']}>
        {(fields) => (
          <>
            {fields.map(({ name: levelName, key }) => (
              <div key={key}>
                <FormItem name={[levelName, 'level', 'id']} style={{ display: 'none' }}>
                  <TextField />
                </FormItem>

                <FormItem noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <FormItem
                      name={[levelName, 'rate']}
                      rules={[
                        { validator: rateValidationRule.validator(levelName) },
                        { validator: positiveNumber('Rate') },
                        { validator: requiredValidator(`Level ${levelName + 1}`) },
                      ]}
                      className='noBottomMargin'
                      label={`Level ${levelName + 1}`}
                    >
                      <TextField
                        disabled={view || !String(getFieldValue('minRate'))?.length}
                        type='number'
                        placeholder={`Level ${levelName + 1}`}
                        onChange={() => onRateChange(levelName)}
                      />
                    </FormItem>
                  )}
                </FormItem>
              </div>
            ))}
          </>
        )}
      </FormList>

      <FormItem className='noBottomMargin'>
        <div className={styles.deleteIcon}>
          <IconButton
            block
            size='large'
            color='orange'
            slim
            icon={<BucketIcon />}
            type='default'
            onClick={() => {
              onProductDelete(name)
              remove(name)
            }}
            className='noBottomMargin'
            disabled={view}
          />
        </div>
      </FormItem>
    </div>
  )
}
