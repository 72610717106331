import { RESTRICTION_MANAGEMENT_TABS_ENUM } from './index.d'
import { Tabs } from 'antd/es'
import { CustomersRestrictionManagement } from '../CustomersTable'
import { AgentsRestrictionManagement } from '../AgentsTable'
import { Space } from 'antd'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, CloudDownloadIcon } from '../../../../../../assets/svg'
import { useContext, useEffect } from 'react'
import { CenterModalContext } from '../../../../../Modals'
import { CENTER_MODALS } from '../../../../../../helpers/contants'
import { Emitter, EmitterEvents } from '../../../../../../helpers/eventEmitter'
import { TableExportFormatEnum } from '../../../../../../shared/hooks/table/useTableExport'
import { Button } from '../../../../../../shared/components/button/Button'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { SUPPORT_ENUM } from '../../../../../../routes/support'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'

interface IProps {
  activeTab: string
}

const Operations = ({ activeTab }: IProps) => {
  const navigate = useNavigate()
  const { onOpen, onClose } = useContext(CenterModalContext)

  const handlerExportClick = () => {
    onOpen(CENTER_MODALS.EXPORT_TABLE, {
      title: 'Export Table',
      onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
      onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      footer: <Button onClick={onClose}>Cancel</Button>,
    })
  }

  const handleAddClick = () => {
    if (activeTab === RESTRICTION_MANAGEMENT_TABS_ENUM.CUSTOMERS) {
      return navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT_CUSTOMER_NEW}`)
    }
    return navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT_AGENT_NEW}`)
  }

  return (
    <Space size={10}>
      <IconButton
        color='blue'
        icon={<CloudDownloadIcon />}
        type='primary'
        onClick={handlerExportClick}
      />
      <IconButton color='orange' icon={<AddIcon />} type='primary' onClick={handleAddClick} />
    </Space>
  )
}

export const RestrictionManagementTabs = () => {
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: RESTRICTION_MANAGEMENT_TABS_ENUM.CUSTOMERS,
  })

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  const items = [
    {
      label: RESTRICTION_MANAGEMENT_TABS_ENUM.CUSTOMERS,
      key: RESTRICTION_MANAGEMENT_TABS_ENUM.CUSTOMERS,
      children: (
        <CustomersRestrictionManagement canDeleteRestriction={true} canEditRestriction={true} />
      ),
    },
    {
      label: RESTRICTION_MANAGEMENT_TABS_ENUM.AGENTS,
      key: RESTRICTION_MANAGEMENT_TABS_ENUM.AGENTS,
      children: (
        <AgentsRestrictionManagement canEditRestriction={true} canDeleteRestriction={true} />
      ),
    },
  ]

  return (
    <Tabs
      items={items}
      activeKey={activeTab}
      onChange={onTabChange}
      tabBarExtraContent={<Operations activeTab={activeTab} />}
      destroyInactiveTabPane
    />
  )
}
