import { Row, Table } from 'antd'
import { useState } from 'react'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { ITableConf } from '../../../../../../shared/models/ITableConf'
import { IInventoryTable } from '../../../../models/IInventory'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { IPopupListItems, Popup } from '../../../../../../shared/components/popup/Popup'
import { ColumnsType } from 'antd/es/table'
import { DateTableIcon, TableActionIcon } from '../../../../../../assets/svg'
import styles from './styles.module.scss'
import {
  formatDateWithTime,
  formatMomentDateToDayStart,
  formatShamsiMomentToISO,
  getFormCalendarTime,
} from '../../../../../../helpers/utils'
import { Link, useNavigate } from 'react-router-dom'
import { FINANCE_ENUM } from '../../../../../../routes/finance'
import { DatePicker } from '../../../../../../shared/components/datePicker'
import moment, { Moment } from 'moment'
import { useGetInventoryListQuery } from '../../../../core/http/VendorInventoryApi'
import { formatNumberToLocale } from '../../../../../salesNetwork/helpers'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../shared/api/errorHandler'
import emptyUserImage from 'src/assets/img/Avatar.png'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { isEmpty } from 'lodash'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

export const InventoryTable = () => {
  const navigate = useNavigate()
  const { queryParams } = useGetQueryParams()
  const { data: profile } = useGetProfileQuery()

  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)
  const [dateFilter, setDateFilter] = useState<ISelectedFilters>({
    monthRange: moment().set({ date: 15 }).toISOString(),
  })
  const { data, isFetching, error, isError } = useGetInventoryListQuery(
    { ...tableConf, ...dateFilter },
    { skip: !dateFilter?.monthRange || isEmpty(tableConf) }
  )

  const { pagination, handleTableChange } = useTable<IInventoryTable>({
    total: data?.totalCount,
    setTableConf,
  })

  useNotification(NOTIFICATION_TYPES.error, isError, error as ErrorNode)

  const emptyTableConf = useEmptyTable()

  const onDateChange = (value: Moment | null) => {
    if (isEmpty(value)) {
      return setDateFilter({})
    }

    const monthRange = formatMomentDateToDayStart(formatShamsiMomentToISO(profile?.calendar, value))
      .set({ date: 15 })
      .toISOString()

    return monthRange && setDateFilter({ monthRange })
  }

  const tableActionsPopup = (row: IInventoryTable): IPopupListItems[] => [
    {
      text: 'View',
      shouldDisplay: true,
      onClick: () =>
        navigate(
          `${FINANCE_ENUM.TRUST_WALLET}/vendor/${row.vendorId}/inventory/view/${row.inventoryId}`
        ),
    },
  ]

  const columns: ColumnsType<IInventoryTable> = [
    {
      title: 'Account ID',
      dataIndex: 'inventoryId',
      sorter: true,
      width: '15%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '25%',
      sorter: true,
      render: (name: IInventoryTable['name'], row) => (
        <RecursivelyReplaceCharacters>
          <Link
            to={`${FINANCE_ENUM.TRUST_WALLET}/vendor/${row.vendorId}/inventory/view/${row.inventoryId}`}
            className='table-avatar'
          >
            <img src={!isPrivateMode && row?.logo ? row?.logo : emptyUserImage} alt='vendor-logo' />
            <div>{name}</div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Current Balance',
      dataIndex: 'balance',
      width: '20%',
      sorter: true,
      render: (balance) => (
        <RecursivelyReplaceCharacters>{formatNumberToLocale(balance)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Target',
      dataIndex: 'targetAmount',
      width: '15%',
      sorter: true,
      render: (targetAmount) => (
        <RecursivelyReplaceCharacters>
          {formatNumberToLocale(targetAmount)}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Purchased',
      dataIndex: 'purchasedAmount',
      width: '15%',
      sorter: true,
      render: (purchasedAmount) => (
        <RecursivelyReplaceCharacters>
          {formatNumberToLocale(purchasedAmount)}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Average Sales',
      dataIndex: 'averageSalesAmount',
      width: '15%',
      sorter: true,
      render: (averageSalesAmount) => (
        <RecursivelyReplaceCharacters>
          {formatNumberToLocale(averageSalesAmount)}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Last Purchased',
      dataIndex: 'lastPurchasedAt',
      width: '15%',
      sorter: true,
      render: (date: IInventoryTable['lastPurchasedAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(date, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '10%',
      render: (_, row) => {
        return (
          <Popup data={tableActionsPopup(row)}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        )
      },
    },
  ]

  return (
    <>
      <Row justify='end' className={styles.inventoryTableHeader}>
        {/* @ts-ignore */}
        <DatePicker
          picker='month'
          {...(dateFilter.monthRange && {
            value: getFormCalendarTime(profile?.calendar, dateFilter?.monthRange as string),
          })}
          onChange={onDateChange}
        />
      </Row>
      <Table
        rowSelection={{ type: 'checkbox' }}
        columns={columns}
        className={styles.table}
        locale={emptyTableConf}
        pagination={{
          ...pagination,
          total: dateFilter.monthRange ? data?.totalCount : undefined,
        }}
        dataSource={dateFilter.monthRange ? data?.items : undefined}
        loading={isFetching}
        onChange={handleTableChange}
        rowKey={(row) => row.vendorId}
        scroll={{ x: 1000 }}
      />
    </>
  )
}
