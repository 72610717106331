import { Route } from 'use-react-router-breadcrumbs'
import { UserManagement } from '../../features/Settings/pages/UserManagement'
import { UserDetailView } from '../../features/Settings/pages/UserManagement/Components/Tabs/Users/UsersManagementView'
import { UserNew } from '../../features/Settings/pages/UserManagement/Components/Tabs/Users/UsersManagementNew'
import { DepartmentsManagementView } from '../../features/Settings/pages/UserManagement/Components/Tabs/Departments/DepartmentsManagementView'
import { PositionsDetailView } from '../../features/Settings/pages/UserManagement/Components/Tabs/Positions/PositionsDetailView'
import { DepartmentsManagementNew } from '../../features/Settings/pages/UserManagement/Components/Tabs/Departments/DepartmentsManagementNew'
import { RolesManagementView } from '../../features/Settings/pages/UserManagement/Components/Tabs/RolesAndPermissions/RolesManagementView'
import { RolesManagementNew } from '../../features/Settings/pages/UserManagement/Components/Tabs/RolesAndPermissions/RolesManagementNew'
import { PositionsNew } from '../../features/Settings/pages/UserManagement/Components/Tabs/Positions/PositionsNew'
import { SalesNetworkConfiguration } from '../../features/Settings/pages/SalesNetworkConfiguration'
import { DiscountPolicyView } from '../../features/Settings/pages/SalesNetworkConfiguration/DiscountPolicyView'
import { DiscountPolicyNew } from '../../features/Settings/pages/SalesNetworkConfiguration/DiscountPolicyNew'
import { PromotionPolicyView } from '../../features/Settings/pages/SalesNetworkConfiguration/PromotionPolicyView'
import { PromotionPolicyNew } from '../../features/Settings/pages/SalesNetworkConfiguration/PromotionPolicyNew'
import { FileManagement } from '../../features/Settings/pages/FileManagement'
import { FileManagementView } from '../../features/Settings/pages/FileManagement/FileManagementView'
import { VendorManagement } from '../../features/Settings/pages/VendorManagement'
import { VendorManagementNew } from '../../features/Settings/pages/VendorManagement/VendorManagementNew'
import { VendorManagementView } from '../../features/Settings/pages/VendorManagement/VendorManagementView'
import VendorIntegrations from '../../features/Settings/pages/VendorManagement/VendorManagementView/Components/IntegrationView'
import { VendorManagementCreateNewContact } from '../../features/Settings/pages/VendorManagement/VendorManagementCreateNewContact'
import { VendorManagementViewContact } from '../../features/Settings/pages/VendorManagement/VendorManagementViewContact'
import { MasterProductNew } from '../../features/Settings/pages/VendorManagement/MasterProductNew'
import { MasterProductView } from '../../features/Settings/pages/VendorManagement/MasterProductView'
import { SystemConfiguration } from '../../features/Settings/pages/SystemConfiguration'
import { ValueAddServices } from '../../features/Settings/pages/ValueAddedServices'
import { ResponseCodeView } from '../../features/Settings/pages/VendorManagement/ResponseCodeView'
import { getBreadcrumb } from '../utils'
import { NotificationManagerView } from '../../features/Settings/pages/VendorManagement/NotificationManagerView'
import { RewardPolicyView } from '../../features/Settings/pages/SalesNetworkConfiguration/RewardPolicyView'
import { PinView } from '../../features/Finance/pages/TrustWallet/PinView'
import { SimCardView } from '../../features/Settings/pages/VendorManagement/VendorManagementView/Components/SimCardView'
import { SimCardNew } from '../../features/Settings/pages/VendorManagement/VendorManagementView/Components/SimCardNew'

export enum SETTINGS_ENUM {
  USERS_MANAGEMENT = '/settings/users-management',
  USERS_MANAGEMENT_USER_VIEW = '/settings/users-management/:mode/:id',
  USERS_MANAGEMENT_USER_NEW = '/settings/users-management/new',
  USERS_MANAGEMENT_DEPARTMENTS = '/settings/users-management/departments',
  USERS_MANAGEMENT_DEPARTMENT_VIEW = '/settings/users-management/departments/:mode/:id',
  USERS_MANAGEMENT_DEPARTMENT_NEW = '/settings/users-management/departments/new',
  USERS_MANAGEMENT_DEPARTMENT_DEPARTMENT_POSITION_VIEW = '/settings/users-management/departments/view/:depId/positions/:mode/:posId',
  USERS_MANAGEMENT_ROLES_AND_PERMISSIONS = '/settings/users-management/roles-and-permissions',
  USERS_MANAGEMENT_ROLES_AND_PERMISSIONS_VIEW = '/settings/users-management/roles-and-permissions/:mode/:id',
  USERS_MANAGEMENT_ROLES_AND_PERMISSIONS_NEW = '/settings/users-management/roles-and-permissions/new',
  USERS_MANAGEMENT_POSITION_VIEW = '/settings/users-management/positions/:mode/:posId',
  USERS_MANAGEMENT_POSITION_NEW = '/settings/users-management/positions/new',
  FILE_MANAGEMENT = '/settings/file-management',
  FILE_MANAGEMENT_VIEW = '/settings/file-management/:mode/:id',
  VENDOR_MANAGEMENT = '/settings/vendor-management',
  VENDOR_MANAGEMENT_NEW = '/settings/vendor-management/new',
  VENDOR_MANAGEMENT_VIEW = '/settings/vendor-management/:mode/:id',
  VENDOR_MANAGEMENT_SIM_CARD_VIEW = '/settings/vendor-management/view/:venId/sim-card/:mode/:id',
  VENDOR_MANAGEMENT_SIM_CARD_NEW = '/settings/vendor-management/view/:id/sim-card/new',
  VENDOR_MANAGEMENT_VIEW_INTEGRATIONS = '/settings/vendor-management/:mode/:id/integrations',
  VENDOR_MANAGEMENT_NEW_INTEGRATIONS = '/settings/vendor-management/:id/integrations/new',
  VENDOR_MANAGEMENT_CREATE_NEW_CONTACT = '/settings/vendor-management/new-contact',
  VENDOR_MANAGEMENT_VIEW_CONTACT = '/settings/vendor-management/contact/:mode/:contactId',
  VENDOR_MANAGEMENT_MASTER_PRODUCT_NEW = '/settings/vendor-management/master-product/new',
  VENDOR_MANAGEMENT_MASTER_PRODUCT_VIEW = '/settings/vendor-management/master-product/:mode/:id',
  VENDOR_MANAGEMENT_RESPONSE_CODE_VIEW = '/settings/vendor-management/response-code/:mode/:id',
  VENDOR_MANAGEMENT_RESPONSE_CODE_NEW = '/settings/vendor-management/response-code/new',
  SYSTEM_CONFIGURATION = '/settings/system-configuration',
  VALUE_ADD_SERVICES = '/settings/value-add-services',
  SALES_NETWORK_CONFIGURATION = '/settings/network-configuration',
  SALES_NETWORK_CONFIGURATION_DISCOUNT_POLICY_VIEW = '/settings/network-configuration/discount-policy/:mode/:id',
  SALES_NETWORK_CONFIGURATION_DISCOUNT_POLICY_NEW = '/settings/network-configuration/discount-policy/new',
  SALES_NETWORK_CONFIGURATION_PROMOTION_POLICY_VIEW = '/settings/network-configuration/promotion-policy/:mode/:id',
  SALES_NETWORK_CONFIGURATION_PROMOTION_POLICY_NEW = '/settings/network-configuration/promotion-policy/new',
  NOTIFICATION_MANAGER_VIEW = '/settings/vendor-management/notification-manager/:mode/:id',
  NOTIFICATION_MANAGER_NEW = '/settings/vendor-management/notification-manager/new',
  SALES_NETWORK_CONFIGURATION_EWARD_POLICY_NEW = '/settings/network-configuration/reward-policy/new',
  REWARD_POLICY_VIEW = '/settings/network-configuration/reward-policy/:mode/:id',
}

export default () => {
  return (
    <>
      <Route path={SETTINGS_ENUM.USERS_MANAGEMENT} element={<UserManagement />} />
      <Route
        path={SETTINGS_ENUM.USERS_MANAGEMENT_USER_VIEW}
        element={<UserDetailView />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={SETTINGS_ENUM.USERS_MANAGEMENT_USER_NEW}
        element={<UserNew />}
        breadcrumb='New User'
      />
      <Route
        path={SETTINGS_ENUM.USERS_MANAGEMENT_DEPARTMENT_VIEW}
        element={<DepartmentsManagementView />}
        breadcrumb={getBreadcrumb(false, SETTINGS_ENUM.USERS_MANAGEMENT_DEPARTMENT_VIEW)}
      />
      <Route
        path={SETTINGS_ENUM.USERS_MANAGEMENT_DEPARTMENT_DEPARTMENT_POSITION_VIEW}
        element={<PositionsDetailView />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={SETTINGS_ENUM.USERS_MANAGEMENT_DEPARTMENT_NEW}
        element={<DepartmentsManagementNew />}
        breadcrumb='New Department'
      />
      {/* SETTINGS */}
      <Route
        path={SETTINGS_ENUM.USERS_MANAGEMENT_ROLES_AND_PERMISSIONS_VIEW}
        element={<RolesManagementView />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={SETTINGS_ENUM.USERS_MANAGEMENT_ROLES_AND_PERMISSIONS_NEW}
        element={<RolesManagementNew />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={SETTINGS_ENUM.USERS_MANAGEMENT_POSITION_VIEW}
        element={<PositionsDetailView />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={SETTINGS_ENUM.USERS_MANAGEMENT_POSITION_NEW}
        element={<PositionsNew />}
        breadcrumb='New Position'
      />
      <Route
        path={SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}
        element={<SalesNetworkConfiguration />}
      />
      <Route
        path={SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION_DISCOUNT_POLICY_VIEW}
        element={<DiscountPolicyView />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION_DISCOUNT_POLICY_NEW}
        element={<DiscountPolicyNew />}
        breadcrumb='New Discount Policy'
      />
      <Route
        path={SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION_PROMOTION_POLICY_VIEW}
        element={<PromotionPolicyView />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION_PROMOTION_POLICY_NEW}
        element={<PromotionPolicyNew />}
        breadcrumb='New Promotion Policy'
      />
      {/* FILE MANAGEMENT */}
      <Route path={SETTINGS_ENUM.FILE_MANAGEMENT} element={<FileManagement />} />
      <Route
        path={SETTINGS_ENUM.FILE_MANAGEMENT_VIEW}
        element={<FileManagementView />}
        breadcrumb={getBreadcrumb()}
      />
      <Route path={SETTINGS_ENUM.VENDOR_MANAGEMENT} element={<VendorManagement />} />
      <Route
        path={SETTINGS_ENUM.VENDOR_MANAGEMENT_NEW}
        element={<VendorManagementNew />}
        breadcrumb='New Vendor'
      />
      <Route
        path={SETTINGS_ENUM.VENDOR_MANAGEMENT_VIEW}
        element={<VendorManagementView />}
        breadcrumb={getBreadcrumb(false, SETTINGS_ENUM.VENDOR_MANAGEMENT_VIEW)}
      />
      <Route
        path={SETTINGS_ENUM.VENDOR_MANAGEMENT_SIM_CARD_VIEW}
        element={<SimCardView />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={SETTINGS_ENUM.VENDOR_MANAGEMENT_SIM_CARD_NEW}
        element={<SimCardNew />}
        breadcrumb='New Sim Card'
      />
      <Route
        path={SETTINGS_ENUM.VENDOR_MANAGEMENT_NEW_INTEGRATIONS}
        element={<VendorIntegrations />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={SETTINGS_ENUM.VENDOR_MANAGEMENT_CREATE_NEW_CONTACT}
        element={<VendorManagementCreateNewContact />}
      />
      <Route
        path={SETTINGS_ENUM.VENDOR_MANAGEMENT_VIEW_CONTACT}
        element={<VendorManagementViewContact />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={SETTINGS_ENUM.VENDOR_MANAGEMENT_MASTER_PRODUCT_NEW}
        element={<MasterProductNew />}
        breadcrumb='New Master Product'
      />
      <Route
        path={SETTINGS_ENUM.VENDOR_MANAGEMENT_MASTER_PRODUCT_VIEW}
        element={<MasterProductView />}
        breadcrumb={getBreadcrumb()}
      />
      <Route path={SETTINGS_ENUM.SYSTEM_CONFIGURATION} element={<SystemConfiguration />} />
      <Route path={SETTINGS_ENUM.VALUE_ADD_SERVICES} element={<ValueAddServices />} />
      <Route
        path={SETTINGS_ENUM.VENDOR_MANAGEMENT_RESPONSE_CODE_NEW}
        element={<ResponseCodeView />}
        breadcrumb='New Response Code'
      />
      <Route
        path={SETTINGS_ENUM.VENDOR_MANAGEMENT_RESPONSE_CODE_VIEW}
        element={<ResponseCodeView />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={SETTINGS_ENUM.NOTIFICATION_MANAGER_NEW}
        element={<NotificationManagerView />}
        breadcrumb='New Notification Manager'
      />
      <Route
        path={SETTINGS_ENUM.NOTIFICATION_MANAGER_VIEW}
        element={<NotificationManagerView />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION_EWARD_POLICY_NEW}
        element={<RewardPolicyView />}
        breadcrumb='New Reward Policy'
      />
      <Route
        path={SETTINGS_ENUM.REWARD_POLICY_VIEW}
        element={<RewardPolicyView />}
        breadcrumb={getBreadcrumb()}
      />
    </>
  )
}
