import React, { useCallback, useEffect, useMemo } from 'react'
import { Form, Spin } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetRoleByIdQuery,
  useUpdateRoleMutation,
} from '../../../../../../../core/http/RolesAndPermissionsManagement'
import DetailInfo from '../../../../DetailInfo'
import { SETTINGS_ENUM } from '../../../../../../../../../routes/settings'
import FooterActions from '../../../../FooterActions'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../../../shared/api/errorHandler'

interface IProps {
  canEditRole: boolean
}

export const RolesAndPermissionsInfo = ({ canEditRole }: IProps) => {
  const navigate = useNavigate()

  const { mode, id } = useParams<{ mode: 'view' | 'edit'; id: string }>()

  const [form] = Form.useForm()

  const [updateRole, updateRoleResp] = useUpdateRoleMutation()
  const { data, isLoading } = useGetRoleByIdQuery(id as string, { skip: !id })

  const isEditMode = useMemo(() => mode === 'edit', [mode])
  const isViewMode = useMemo(() => mode === 'view', [mode])

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, updateRoleResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    updateRoleResp.isError,
    updateRoleResp.error as ErrorNode
  )

  const onPressEditName = useCallback(() => {
    navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT_ROLES_AND_PERMISSIONS}/edit/${id}`)
    // eslint-disable-next-line
  }, [])

  const onPressCancelChanges = useCallback(() => {
    form.resetFields()
    navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT_ROLES_AND_PERMISSIONS}/view/${id}`)
    // eslint-disable-next-line
  }, [])

  const onPressSaveChanges = useCallback(() => {
    if (isViewMode) {
      return onPressEditName()
    } else {
      form.submit()
    }
    // eslint-disable-next-line
  }, [])

  const onFinish = () => {
    const requestBody = {
      role: {
        name: form.getFieldValue('name'),
        status: form.getFieldValue('status'),
      },
    }
    updateRole({ id, body: requestBody })
  }

  useEffect(() => {
    if (updateRoleResp.isSuccess) {
      return navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT_ROLES_AND_PERMISSIONS}/view/${id}`)
    }
    // eslint-disable-next-line
  }, [updateRoleResp.isSuccess])

  const content = (
    <Form form={form} onFinish={onFinish} layout='vertical' initialValues={data}>
      <DetailInfo isEditMode={isEditMode} onPressEditName={onPressEditName} canEdit={canEditRole} />
      {canEditRole && (
        <FooterActions
          isViewMode={isViewMode}
          submitButtonText={isViewMode ? 'Edit Role' : 'Save Changes'}
          submitButtonDisabled={!canEditRole}
          onPressCancelChanges={onPressCancelChanges}
          onPressSaveChanges={onPressSaveChanges}
          isLoading={isLoading || updateRoleResp.isLoading}
        />
      )}
    </Form>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
