import React from 'react'
import { FormInstance, Form, Select } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import classNames from 'classnames'

// styles
import styles from './styles.module.scss'

// icons
import {
  AddIcon,
  BucketIcon,
  CopyIcon,
  EmailFilledIcon,
  EmailRedIcon,
  FacebookMessengerIcon2,
  PhoneLightBlueIcon,
  SkypeIcon,
  TelegramIcon,
  ViberIcon,
  WhatsappIcon,
} from 'src/assets/svg'

// components
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { Wrapper } from '../Wrapper'
import { TextField } from 'src/shared/components/textfield/TextField'
import { PhoneInputWithCountry } from 'src/shared/components/PhoneInput'

// helpers
import * as V from 'src/helpers/validation'
import * as I from 'src/features/salesNetwork/models/IAgent'
import { onCopyText } from 'src/helpers/utils'

const options = [
  {
    icon: <EmailRedIcon />,
    item: I.CONTACT_TYPE_NEW.EMAIL_IDENTITY,
  },
  {
    icon: <EmailFilledIcon />,
    item: I.CONTACT_TYPE_NEW.EMAIL,
  },
  {
    icon: <PhoneLightBlueIcon />,
    item: I.CONTACT_TYPE_NEW.MOBILE,
  },
  {
    icon: <WhatsappIcon />,
    item: I.CONTACT_TYPE_NEW.WHATSAPP,
  },
  {
    icon: <FacebookMessengerIcon2 />,
    item: I.CONTACT_TYPE_NEW.FACEBOOK,
  },
  {
    icon: <TelegramIcon />,
    item: I.CONTACT_TYPE_NEW.TELEGRAM,
  },
  {
    icon: <ViberIcon />,
    item: I.CONTACT_TYPE_NEW.VIBER,
  },
  {
    icon: <SkypeIcon />,
    item: I.CONTACT_TYPE_NEW.SKYPE,
  },
]

const REQUIRED_OBJ_FIELD = { required: true, message: 'Field required' }

const rulesObj = {
  [I.CONTACT_TYPE_NEW.EMAIL_IDENTITY.type]: [REQUIRED_OBJ_FIELD, { validator: V.emailValidator }],
  [I.CONTACT_TYPE_NEW.EMAIL.type]: [REQUIRED_OBJ_FIELD, { validator: V.emailValidator }],
  [I.CONTACT_TYPE_NEW.WHATSAPP.type]: [
    REQUIRED_OBJ_FIELD,
    { validator: V.commonValidator },
    { validator: V.maxLengthValidator25 },
  ],
  [I.CONTACT_TYPE_NEW.SKYPE.type]: [
    REQUIRED_OBJ_FIELD,
    { validator: V.commonValidator },
    { validator: V.maxLengthValidator25 },
  ],
  [I.CONTACT_TYPE_NEW.FACEBOOK.type]: [
    REQUIRED_OBJ_FIELD,
    { validator: V.minMaxLengthValidator(2, 50) },
  ],
  [I.CONTACT_TYPE_NEW.TELEGRAM.type]: [
    REQUIRED_OBJ_FIELD,
    { validator: V.mobileNumberLengthFrom8To14 },
  ],
  [I.CONTACT_TYPE_NEW.VIBER.type]: [
    REQUIRED_OBJ_FIELD,
    { validator: V.mobileNumberLengthFrom8To14 },
  ],
  [I.CONTACT_TYPE_NEW.MOBILE.type]: [
    REQUIRED_OBJ_FIELD,
    { validator: V.mobileNumberValidator },
    { validator: V.mobileNumberLengthFrom8To14 },
  ],
}

type rulesType = keyof typeof rulesObj
interface IContactInformation {
  view?: boolean
  form: FormInstance
  onContactDelete?: (index: number) => void
}

const ContactInformation = ({ view = false, form, onContactDelete }: IContactInformation) => {
  return (
    <Wrapper title='Contact information' className={classNames(styles['wrapper-user-identifiers'])}>
      <Form.List name='contactChannels'>
        {(fields, { add, remove }) => (
          <React.Fragment>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} className={styles.contactItemContainer}>
                <ContactItem
                  form={form}
                  name={name}
                  view={view as boolean}
                  remove={remove}
                  handleDeleteContact={onContactDelete}
                  {...restField}
                />
              </div>
            ))}
            {!view && (
              <div style={{ display: 'flex' }}>
                <Form.Item style={{ flexBasis: '29.5%' }}>
                  <IconButton icon={<AddIcon />} color='orange' block onClick={() => add()} />
                </Form.Item>
              </div>
            )}
          </React.Fragment>
        )}
      </Form.List>
    </Wrapper>
  )
}
export default ContactInformation

const ContactItem = ({
  name,
  view,
  remove,
  form,
  handleDeleteContact,
  ...restField
}: {
  name: number
  view: boolean
  remove: (index: number | number[]) => void
  handleDeleteContact?: (index: number) => void
  form: FormInstance
}) => {
  return (
    <>
      <FormItem
        {...restField}
        name={[name, 'type']}
        style={{ flexBasis: '30%', minWidth: 40, marginRight: '24px' }}
      >
        <Select
          disabled={view}
          onChange={() => {
            form.setFieldValue(['contactChannels', name, 'value'], undefined)
          }}
          allowClear
        >
          {options.map(({ item, icon }) => {
            return (
              <Select.Option key={item.type} value={item.type}>
                <div className={styles.wrapperContentOption}>
                  <div className={styles.wrapperIcon}>{icon}</div>
                  <div>
                    {I.CONTACT_TYPE_NEW[item.type as keyof typeof I.IDENTIFIERS_TYPE_NEW].name}
                  </div>
                </div>
              </Select.Option>
            )
          })}
        </Select>
      </FormItem>

      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const selectedType = getFieldValue(['contactChannels', name, 'type']) as rulesType

          const isMobile =
            selectedType === I.CONTACT_TYPE_NEW.MOBILE.type ||
            selectedType === I.CONTACT_TYPE_NEW.WHATSAPP.type ||
            selectedType === I.CONTACT_TYPE_NEW.TELEGRAM.type ||
            selectedType === I.CONTACT_TYPE_NEW.VIBER.type

          return isMobile ? (
            <Form.Item
              {...restField}
              name={[name, 'value']}
              style={{ flexBasis: '65%', marginRight: '24px' }}
              rules={rulesObj[selectedType]}
            >
              <PhoneInputWithCountry
                fieldName={['contactChannels', name, 'value']}
                form={form}
                view={view}
              />
            </Form.Item>
          ) : (
            <Form.Item
              {...restField}
              name={[name, 'value']}
              style={{ flexBasis: '65%', marginRight: '24px' }}
              rules={rulesObj[selectedType]}
            >
              <TextField disabled={view} />
            </Form.Item>
          )
        }}
      </FormItem>

      {view && (
        <div className={styles['wrapper-icons']}>
          <div className={styles['wrapper-icon']}>
            <IconButton
              onClick={() => onCopyText(form.getFieldValue(['contactChannels', name, 'value']))}
              slim
              size='large'
              color='orange'
              icon={<CopyIcon />}
              type='default'
            />
          </div>
        </div>
      )}

      {!view && (
        <Form.Item style={{ flexBasis: '3%' }}>
          <div className={styles.bucketIcon}>
            <IconButton
              block
              size='large'
              color='orange'
              icon={<BucketIcon />}
              type='default'
              disabled={view}
              onClick={() => {
                handleDeleteContact?.(name)
                remove(name)
              }}
            />
          </div>
        </Form.Item>
      )}
    </>
  )
}
