import React, { useEffect, useState } from 'react'
import { ITableConf } from '../../../../../shared/models/ITableConf'
import { useEmptyTable } from '../../../../../shared/hooks/table/useEmptyTable'
import {
  IWalletStatementTransactionDetail,
  WALLET_STATEMENT_CAT_ROUTE_ENUM,
} from '../../../models/IWalletStatement'
import { Table } from 'antd'
import styles from '../styles.module.scss'
import { ColumnsType } from 'antd/es/table'
import { DateTableIcon } from '../../../../../assets/svg'
import {
  downloadFileThroughATag,
  formatDateWithTime,
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  formatShamsiMomentToISO,
  getFormCalendarTime,
  moment,
} from '../../../../../helpers/utils'
import { formatNumberToLocale } from '../../../../salesNetwork/helpers'
import { useParams } from 'react-router-dom'
import {
  useGetStatementOfWalletsTransactionsQuery,
  useLazyGenerateStatementTransactionsPdfQuery,
} from '../../../core/http/StatementOfWalletsApi'
import classNames from 'classnames'
import { DEBIT_CREDIT_ENUM } from '../../../models/ITransaction'
import { TableHeader } from './TableHeader'
import { ISelectedFilters } from '../../../../../shared/components/filter/Filter'
import ShowMessage from '../../../../../shared/api/errorHandler'
import { isEmpty, startCase } from 'lodash'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { Moment } from 'moment'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { RecursivelyReplaceCharacters } from '../../../../../shared/components/privateMode'

export const StatementOfWalletsDetailView = () => {
  const { queryParams } = useGetQueryParams()
  const { data: profile, isFetching: isLoadingProfile } = useGetProfileQuery()

  const params = useParams<{ id?: string; cat: WALLET_STATEMENT_CAT_ROUTE_ENUM; subWall: string }>()

  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const columns: ColumnsType<IWalletStatementTransactionDetail> = [
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      render: (createdAt: IWalletStatementTransactionDetail['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(createdAt, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Main Transaction ID',
      dataIndex: 'mainTransactionId',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Transaction ID',
      dataIndex: 'id',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Sender',
      dataIndex: 'sender',
      render: (sender, row) => (
        <div>
          <RecursivelyReplaceCharacters>
            <div>{startCase(sender)}</div>
          </RecursivelyReplaceCharacters>
          <RecursivelyReplaceCharacters>
            {row?.escrowSender && <div className={styles.escrowMark}>Escrow</div>}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Receiver',
      dataIndex: 'receiver',
      render: (receiver, row) => (
        <div>
          <RecursivelyReplaceCharacters>
            <div>{startCase(receiver)}</div>
          </RecursivelyReplaceCharacters>
          <RecursivelyReplaceCharacters>
            {row?.escrowReceiver && <div className={styles.escrowMark}>Escrow</div>}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Debit',
      dataIndex: 'drCr',
      render: (drCr: IWalletStatementTransactionDetail['drCr'], row) => (
        <RecursivelyReplaceCharacters>
          {drCr === DEBIT_CREDIT_ENUM.CREDIT ? <span>-</span> : row?.amount}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Credit',
      dataIndex: 'drCr',
      render: (drCr: IWalletStatementTransactionDetail['drCr'], row) => (
        <RecursivelyReplaceCharacters>
          {drCr === DEBIT_CREDIT_ENUM.DEBIT ? <span>-</span> : row?.amount}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'balanceAfter',
      render: (amount: IWalletStatementTransactionDetail['amount']) => (
        <RecursivelyReplaceCharacters>{formatNumberToLocale(amount)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: IWalletStatementTransactionDetail['status']) => (
        <div className={styles.statusContainer}>
          <div className={classNames(styles[status.toLowerCase()], styles.statusPoint)} />
          <RecursivelyReplaceCharacters>{status}</RecursivelyReplaceCharacters>
        </div>
      ),
    },
  ]

  const [filterValues, setFilterValues] = useState<ISelectedFilters>({})

  const { data, isFetching } = useGetStatementOfWalletsTransactionsQuery(
    {
      route: params?.cat as WALLET_STATEMENT_CAT_ROUTE_ENUM,
      walletId: params?.id,
      subWallet: params?.subWall,
      ...tableConf,
      ...filterValues,
    },
    { skip: isEmpty(tableConf) }
  )
  const [exportTable, exportTableResp] = useLazyGenerateStatementTransactionsPdfQuery()

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange } = useTable<IWalletStatementTransactionDetail>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  const onExportClick = () => {
    if (!Object.values(filterValues)?.length) {
      return ShowMessage('error', 'Select date range to generate report!')
    }
    if (
      moment(filterValues.date_less as string).diff(
        moment(filterValues.date_more as string),
        'days'
      ) > 92
    ) {
      return ShowMessage('error', 'Date range is more than 3 months!')
    }

    exportTable({
      route: params?.cat as WALLET_STATEMENT_CAT_ROUTE_ENUM,
      params: { walletId: params?.id, ...filterValues },
    })
  }

  const onDateChange = (value: Moment[] | null) => {
    if (!Array.isArray(value)) {
      return setFilterValues({})
    }

    const date_more = !isEmpty(value[0])
      ? formatMomentDateToDayStart(
          formatShamsiMomentToISO(profile!.calendar, value[0])
        ).toISOString(true)
      : ''
    const date_less = !isEmpty(value[1])
      ? formatMomentDateToDayEnd(formatShamsiMomentToISO(profile!.calendar, value[1])).toISOString(
          true
        )
      : ''

    return setFilterValues({ date_less, date_more })
  }

  useEffect(() => {
    if (isEmpty(exportTableResp.data)) return

    downloadFileThroughATag(exportTableResp.data?.link, 'transaction-report.zip')
    ShowMessage('success', 'Success!')
  }, [exportTableResp?.data])

  useEffect(() => {
    if (profile?.timeZone) {
      moment.tz?.setDefault(profile?.timeZone as string)
    }
  }, [profile])

  return (
    <div className={styles.layout}>
      {/* @ts-ignore */}
      <TableHeader
        isLoading={exportTableResp.isFetching || isLoadingProfile}
        {...(filterValues.date_more &&
          filterValues.date_less && {
            value: [
              getFormCalendarTime(profile?.calendar, filterValues.date_more as string),
              getFormCalendarTime(profile?.calendar, filterValues.date_less as string),
            ],
          })}
        onDateChange={onDateChange}
        onExportClick={onExportClick}
      />
      <Table
        dataSource={data?.items}
        locale={emptyTableConf}
        onChange={handleTableChange}
        columns={columns}
        className={styles.table}
        pagination={pagination}
        loading={isFetching}
        rowKey={(record) => record.id}
        scroll={{ x: 1000 }}
      />
    </div>
  )
}
