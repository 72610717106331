import React, { useContext, useEffect, useState } from 'react'

import { Col, Spin } from 'antd'
import { ISelectedFilters } from 'src/shared/components/filter/Filter'
import { InfiniteScrollComponent } from '../../../../../../../../../../shared/components/infiniteScoll/InfiniteScroll'
import useSearch from '../../../../../../../../helpers/useSearch'
import { TextFieldSearch } from '../../../../../../../../../../shared/components/textFieldSearch/TextFieldSearch'
import { IconButton } from '../../../../../../../../../../shared/components/iconButton/IconButton'
import {
  useGetUsersFiltersListQuery,
  useGetUsersListQuery,
  usersApi,
} from '../../../../../../../../core/http/UsersManagementApi'
import { InfoMessage } from '../../../../../../../VendorManagement/VendorManagementContactListModal/Components/InfoMessage'
import { AddIcon } from '../../../../../../../../../../assets/svg'
import { IUser } from '../../../../../../../../models/IUser'
import { useAppDispatch } from '../../../../../../../../../../redux'

import styles from '../styles.module.scss'
import { ContactListModalHeader } from '../../../../../../../VendorManagement/VendorManagementContactListModal/Components/ContactListModalHeader'
import { DepartmentsManagementUsersList } from '../DepartmentsManagementUsersList'
import { addUser, deleteUserById } from '../../../../../../../../core/store/UsersManagerSlice'
import { useNavigate } from 'react-router-dom'
import { SETTINGS_ENUM } from '../../../../../../../../../../routes/settings'
import { RightModalContext } from '../../../../../../../../../Modals'
import {
  departmentsManagementApi,
  useAddUserToDepartmentMutation,
} from '../../../../../../../../core/http/DepartmentsManagementApi'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../../../../shared/api/errorHandler'
import { isEmpty, unionBy } from 'lodash'
import { ITableConf } from '../../../../../../../../../../shared/models/ITableConf'
import { externalChatSocketConnection } from '../../../../../../../../../../shared/sockets'
import { getSavedAuthData } from '../../../../../../../../../../shared/api'

export const DepartmentManagementUsersListModal = () => {
  const {
    onClose,
    props: { departmentId },
  } = useContext(RightModalContext)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const token = getSavedAuthData()?.tokens?.accessToken

  const [usersList, setUsersList] = useState<IUser[]>([])
  const [filterValues, setFilterValues] = useState<ISelectedFilters>({})
  const [isChangeSearch, setIsChangeSearch] = useState(false)
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 10,
    search: '',
  })

  const [addUserToDep, addUserToDepResp] = useAddUserToDepartmentMutation()
  const { data: filterFields } = useGetUsersFiltersListQuery()
  const { data } = useGetUsersListQuery({
    ...tableConf,
    ...filterValues,
  })

  const afterSuccess = () => {
    externalChatSocketConnection.connect(token)
  }

  useNotification(NOTIFICATION_TYPES.success, addUserToDepResp.isSuccess, null, afterSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    addUserToDepResp.isError,
    addUserToDepResp.error as ErrorNode
  )

  const { handleSearchChange } = useSearch(setTableConf)

  const loadMoreData = () => {
    setTableConf((pre: ITableConf) => ({ ...pre, page: pre.page! + 1 }))
  }

  useEffect(() => {
    if (isEmpty(data)) return

    if (isChangeSearch && (tableConf.search || Object.keys(filterValues).length !== 0)) {
      setUsersList(data!.items)
      setIsChangeSearch(false)
    } else {
      return setUsersList(unionBy([...usersList, ...data!.items], 'id'))
    }
  }, [data])

  const onDeleteUser = (id: number) => {
    if (departmentId) return
    dispatch(deleteUserById(id))
  }

  const onAddUser = (user: IUser) => {
    if (departmentId) {
      addUserToDep({ departmentId: Number(departmentId), userId: user.id })
    }
    dispatch(addUser(user))
  }

  const onPressCreateNewUser = () => {
    navigate(SETTINGS_ENUM.USERS_MANAGEMENT_USER_NEW)
    onClose()
  }

  useEffect(() => {
    if (addUserToDepResp.isSuccess) {
      dispatch(departmentsManagementApi.util.invalidateTags(['IUser']))
      dispatch(usersApi.util.invalidateTags(['IUser']))
    }
  }, [addUserToDepResp.isSuccess, dispatch])

  const searchComponent = (
    <TextFieldSearch
      className={styles.search}
      onChange={(e) => {
        setIsChangeSearch(true)
        handleSearchChange(e)
      }}
    />
  )

  const addButtonComponent = (
    <Col>
      <IconButton
        className={styles.addBtn}
        icon={<AddIcon />}
        type='primary'
        color='orange'
        size='large'
        onClick={onPressCreateNewUser}
      />
    </Col>
  )

  const content = (
    <InfiniteScrollComponent
      loadMoreData={loadMoreData}
      dataLength={usersList.length}
      totalCount={data?.totalCount as number}
      loaderStyles={{ width: 'calc(100% - 24px * 2)', marginLeft: '24px' }}
    >
      <div className={styles.layout}>
        <InfoMessage text='Users' />
        <ContactListModalHeader
          classNameFilterPopup={styles.filterPopup}
          setActiveFilters={(res) => {
            setIsChangeSearch(true)
            setFilterValues(res)
          }}
          filterValues={filterValues}
          data={filterFields}
          searchComponent={searchComponent}
          addButtonComponent={addButtonComponent}
          classNamesFilterWrapperIcon={styles.filterWrraperIcon}
        />
        <DepartmentsManagementUsersList
          onDeleteUser={onDeleteUser}
          onAddUser={onAddUser}
          departmentId={departmentId}
          users={usersList}
        />
      </div>
    </InfiniteScrollComponent>
  )

  return !data ? <Spin>{content}</Spin> : content
}
