import { Layout } from 'antd'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { DoubleArrowLeftIcon } from 'src/assets/svg'
import { RightModalContext } from 'src/features/Modals'
import { RIGHT_MODALS } from 'src/helpers/contants'
import { Header } from 'src/shared/components/header/Header'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { Sidebar } from 'src/shared/components/sidebar/Sidebar'
import { App } from 'src/features/app/App'
import ProfilePermissions from '../../components/permissions'
import { getSavedAuthData } from '../../api'
import classNames from 'classnames'
import {
  setCurrentModalView,
  setOpenedChatId,
} from '../../../features/InternalChat/core/store/Chats'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { Path } from '@remix-run/router/history'
import { modalViewTypeEnum } from '../../../features/InternalChat/core/models'
import styles from './SidebarLayout.module.scss'
import {
  chatsSocketConnection,
  externalChatSocketConnection,
  externalTransactionReportConnection,
  notificationSocketConnection,
  rootSocketConnection,
  systemMonitoringSocketConnection,
  widgetsSocketConnection,
} from '../../sockets'
import { useGetUnreadMessagesCountQuery } from '../../../features/Profile/core/http/Notification'
import {
  addMessagesUnreadCounter,
  selectNotificationsState,
} from '../../../features/Profile/core/store/Notifications'
import { useLocation } from 'react-router'

export const SidebarLayout = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const token = getSavedAuthData()?.tokens?.accessToken
  const [isSearchVis, setSearchVis] = useState(false)
  const [collapsed, setCollapsed] = useState(false)

  const { data: unreadMessagesCount } = useGetUnreadMessagesCountQuery()
  const { unreadMessagesCounter } = useAppSelector(selectNotificationsState)
  const { onOpen, onClose } = useContext(RightModalContext)

  const connectToWebsockets = useCallback(async () => {
    Promise.all([
      await rootSocketConnection.connect(token),
      await notificationSocketConnection.connect(token),
      await widgetsSocketConnection.connect(token),
      await externalChatSocketConnection.connect(token),
      await systemMonitoringSocketConnection.connect(token),
      await externalTransactionReportConnection.connect(token),
      await chatsSocketConnection.connect(token),
    ])
  }, [token])

  useEffect(() => {
    if (token) {
      connectToWebsockets()
    }
    return () => {
      rootSocketConnection.disconnect()
      notificationSocketConnection.disconnect()
      widgetsSocketConnection.disconnect()
      externalChatSocketConnection.disconnect()
      systemMonitoringSocketConnection.disconnect()
      externalTransactionReportConnection.disconnect()
      chatsSocketConnection.disconnect()
    }
  }, [connectToWebsockets, token])

  useEffect(() => {
    // FIX ISSUE WITH DOUBLE-CLICKING ON THE BACK BUTTON
    const handleBackButton = () => {
      navigate(-1 as Partial<Path>, { replace: true })
    }

    window.addEventListener('popstate', handleBackButton)

    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
  }, [navigate])

  useEffect(() => {
    if (!unreadMessagesCount) return
    dispatch(addMessagesUnreadCounter(unreadMessagesCount?.internalCount))
  }, [dispatch, unreadMessagesCount])

  return (
    <App>
      <div className={styles['sidebar-layout']}>
        <ProfilePermissions />
        <Sidebar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          isSearchVis={isSearchVis}
          setSearchVis={setSearchVis}
        />
        <Layout
          className={classNames(styles['sidebar-layout-content'], {
            [styles.collapsedWidth]: collapsed && !isSearchVis,
            [styles.collapsedWithSearch]: isSearchVis,
          })}
        >
          <Header />
          <div className={styles.outlet}>
            <Outlet />
          </div>
        </Layout>
        {/* TODO: NEED TO CHANGE!!!*/}
        <div className={styles.rightBtns}>
          <IconButton
            className={classNames(styles.chatButton, {
              [styles.hasUnreadMess]: unreadMessagesCounter,
            })}
            style={{ background: unreadMessagesCounter ? '#ff9800' : '#074480' }}
            type='primary'
            size='small'
            iconPos='right'
            icon={<DoubleArrowLeftIcon />}
            onClick={() =>
              onOpen(RIGHT_MODALS.SETTINGS.INTERNAL_CHAT, {
                title: 'internal chat',
                onCloseRedirect: () => {
                  onClose()
                  dispatch(setCurrentModalView(modalViewTypeEnum.chats))
                  dispatch(setOpenedChatId(null))
                },
              })
            }
          >
            chat
          </IconButton>

          <IconButton
            color='blue'
            type='primary'
            size='small'
            iconPos='right'
            icon={<DoubleArrowLeftIcon />}
            onClick={() =>
              onOpen(RIGHT_MODALS.FINANCE.REVERSE_TRANSACTION_REQUEST, {
                title: 'reverse transaction request',
              })
            }
          >
            reverse transaction request
          </IconButton>
          <IconButton
            color='blue'
            type='primary'
            size='small'
            iconPos='right'
            icon={<DoubleArrowLeftIcon />}
            onClick={() => onOpen(RIGHT_MODALS.SUPPORT.CHANGE_MPIN, { title: 'change mpin' })}
          >
            change mpin
          </IconButton>
          <IconButton
            color='blue'
            type='primary'
            size='small'
            iconPos='right'
            icon={<DoubleArrowLeftIcon />}
            onClick={() => onOpen(RIGHT_MODALS.SUPPORT.CHANGE_MPIN, { title: 'open profile' })}
          >
            open profile
          </IconButton>
        </div>
      </div>
    </App>
  )
}
