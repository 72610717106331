import React, { useContext, useEffect } from 'react'
import { Col, Form, Row, Spin } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import {
  maxLengthValidator,
  minLengthValidator,
  minMaxLengthValidator,
  requiredValidator,
} from '../../../../../helpers/validation'
import { TextField } from '../../../../../shared/components/textfield/TextField'
import { Wrapper } from '../../../../../shared/components/wrapper/Wrapper'
import { IconButton } from '../../../../../shared/components/iconButton/IconButton'
import {
  useCreateGatewayMutation,
  useGetGatewayByIdQuery,
  useUpdateGatewayByIdMutation,
} from '../../../core/http/VendorManagementApi'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../shared/api/errorHandler'
import { RightModalContext } from '../../../../Modals'
import styles from './styles.module.scss'
import { Toggle } from 'src/shared/components/toggle/Toggle'
import { IGateway } from 'src/features/Settings/models/IGateway'
import { formatDateWithTime } from 'src/helpers/utils'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

const VendorManagementGatewayModal = () => {
  const [form] = Form.useForm()
  const {
    onClose,
    props: { vendorId, gatewayId, title },
  } = useContext(RightModalContext)

  const { data: profile } = useGetProfileQuery()

  const isNewMode = title?.toLowerCase() === 'add gateway'
  const isEditMode = title?.toLowerCase() === 'update gateway'
  const isViewMode = !isNewMode && !isEditMode

  const [createGateway, gCreate] = useCreateGatewayMutation()
  const [updateGateway, gUpdate] = useUpdateGatewayByIdMutation()
  const { data, isFetching, isLoading } = useGetGatewayByIdQuery(
    { vendorId, gatewayId },
    { skip: isNewMode || !gatewayId }
  )

  useNotification(NOTIFICATION_TYPES.success, gCreate.isSuccess, null)
  useNotification(NOTIFICATION_TYPES.error, gCreate.isError, gCreate.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.success, gUpdate.isSuccess, null)
  useNotification(NOTIFICATION_TYPES.error, gUpdate.isError, gUpdate.error as ErrorNode)

  const initialValues = {
    simNumber: '',
    ownerFirstName: '',
    ownerLastName: '',
    status: false,
  }

  useEffect(() => {
    if (gCreate.isSuccess || gUpdate.isSuccess) {
      onClose()
    }
  }, [gCreate.isSuccess, gUpdate.isSuccess])

  const onFinish = (values: Partial<IGateway>) => {
    if (isNewMode) {
      createGateway({ vendorId: vendorId as string, body: values })
    }

    if (isEditMode) {
      updateGateway({ vendorId: vendorId as string, gatewayId, body: values })
    }
  }

  const content = (
    <Form
      form={form}
      layout='vertical'
      initialValues={
        isNewMode
          ? initialValues
          : { ...data, createdAt: formatDateWithTime(data?.createdAt, profile?.calendar) }
      }
      onFinish={onFinish}
      style={{ height: '100%' }}
    >
      <div className={styles.main}>
        <Wrapper title='Details'>
          <Row justify='end'>
            <Col span='auto'>
              <FormItem name='status' valuePropName='checked'>
                <Toggle disabled={isViewMode} text='Status' />
              </FormItem>
            </Col>
          </Row>
          <FormItem
            name='simNumber'
            label='Sim Number'
            rules={[
              { required: true, validator: requiredValidator('Sim Number') },
              { validator: minLengthValidator(11, 'Sim Number') },
              { validator: maxLengthValidator(11) },
            ]}
          >
            <TextField placeholder='Enter Sim Number' disabled={isViewMode} />
          </FormItem>
          <FormItem
            name='ownerFirstName'
            label='Owner First Name'
            rules={[{ validator: minMaxLengthValidator(3, 45) }]}
          >
            <TextField placeholder='Enter Owner First Name' disabled={isViewMode} />
          </FormItem>
          <FormItem
            name='ownerLastName'
            label='Owner Last Name'
            rules={[{ validator: minMaxLengthValidator(3, 45) }]}
          >
            <TextField placeholder='Enter Owner Last Name' disabled={isViewMode} />
          </FormItem>
          {isViewMode && (
            <>
              <FormItem name='createdAt' label='Created At'>
                <TextField placeholder='Created At' disabled />
              </FormItem>
              <FormItem name='createdByUser' label='Created By'>
                <TextField placeholder='Created By' disabled />
              </FormItem>
            </>
          )}
        </Wrapper>
        {(isNewMode || isEditMode) && (
          <div>
            <IconButton color='orange' size='large' type='primary' block htmlType='submit'>
              {isNewMode ? 'Create' : 'Update'}
            </IconButton>
          </div>
        )}
      </div>
    </Form>
  )

  return isFetching || isLoading ? <Spin>{content}</Spin> : content
}

export default VendorManagementGatewayModal
