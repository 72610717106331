import classNames from 'classnames'
import styles from '../header/Header.module.scss'
import emptyUserImage from '../../../assets/img/Avatar.png'
import {
  IExternalUnreadMessage,
  MESSENGER_TYPE_ENUM,
} from '../../../features/Support/models/IExternalChat'
import { ArrowRightIcon, WhatsappIcon } from '../../../assets/svg'
import { UserLastMessage } from '../../../features/Support/pages/Chatroom/Components/ChatroomListOfChats/Components/UserLastMessage'
import { isArabic } from '../../../helpers/utils'
import moment from 'moment/moment'
import React, { useMemo } from 'react'
import { IUnreadInternalMessage, MessageTypeEnum } from '../../../features/InternalChat/models'
import { LastMessage } from '../chat/Components'
import { Row } from 'antd'

interface IProps {
  message?: IExternalUnreadMessage
  internalMessage?: IUnreadInternalMessage
  isChatDayTheSame?: boolean
  onChatItemClick: (chatId: number) => void
}

export const MessageListItem = ({
  message,
  isChatDayTheSame,
  internalMessage,
  onChatItemClick,
}: IProps) => {
  const isExternal = useMemo(() => !!message, [message])

  const renderMessageDate = useMemo(() => {
    const messageDate = isExternal ? message?.createdAt : internalMessage?.createdAt

    if (moment(messageDate).isSame(moment(), 'day')) {
      return <span>{moment(messageDate).format('HH:mm')}</span>
    }

    const beforeTodayFormat = moment(messageDate).isSame(moment(), 'year') ? 'DD/MM' : 'DD/MM/YYYY'

    return (
      <div>
        <div>{moment(messageDate).format(beforeTodayFormat)}</div>
        <Row justify='end'>{moment(messageDate).format('HH:mm')}</Row>
      </div>
    )
    // eslint-disable-next-line
  }, [])

  return (
    <div
      className={classNames(styles.userListItem, {
        [styles.sameDay]: !isChatDayTheSame,
      })}
    >
      <div className={styles.userImage}>
        <img
          alt='user-logo'
          src={internalMessage?.fromUser?.logo ?? emptyUserImage}
          loading='lazy'
        />
        {isExternal && (
          <span className={styles.userMessengerType}>
            {message?.chat?.messengerProfileName === MESSENGER_TYPE_ENUM.WHATSAPP && (
              <WhatsappIcon />
            )}
          </span>
        )}
      </div>
      <div className={styles.userText}>
        <div className={styles.userName}>
          {isExternal ? message?.chat?.messengerProfileName : internalMessage?.fromUser?.name}
        </div>
        {isExternal ? (
          <UserLastMessage
            typing={false}
            lastMessage={{
              message: message?.message,
              externalChatMessageFiles: message?.externalChatMessageFiles,
            }}
            chatId={message?.chat?.id}
            userId={message?.createdByUser?.id}
            className={`${styles.userLastMessage} ${
              message?.message && isArabic(message?.message) ? styles.arabic : ''
            }`}
          />
        ) : (
          <LastMessage
            message={internalMessage?.message as string}
            type={MessageTypeEnum.TEXT_MESSAGE}
          />
        )}
      </div>
      <div>
        <div className={styles.lastMessageTime}>
          <span>{renderMessageDate}</span>
        </div>
        <div
          className={styles.redirectUserIcon}
          onClick={() => {
            if (isExternal) {
              return onChatItemClick(message?.chat?.id as number)
            }

            return onChatItemClick(internalMessage?.fromUser?.id as number)
          }}
        >
          <ArrowRightIcon />
        </div>
      </div>
    </div>
  )
}
