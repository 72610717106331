import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { DashboardTableWrapper } from '../../../../../Dashboard/pages/Overview/Components/DashboardTableWrapper'
import React, { useMemo } from 'react'
import { moment, replaceCharactersWithAsterisks } from '../../../../../../helpers/utils'
import { NotFoundPage } from '../../../../../NotFoundPage'
import { ISalesSummary } from '../../../../models/IExternalReport'
import styles from '../../../../../../shared/components/pieChart/PieChart.module.scss'
import { Moment } from 'moment'
import { RangePicker } from '../../../../../../shared/components/datePicker'
import { RecursivelyReplaceCharacters } from 'src/shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IProps {
  data: {
    pickupSummary: ISalesSummary[]
    salesSummary: ISalesSummary[]
  }
  frequency?: string
  onWidgetDateChange?: (value: Moment[] | null) => void
}

export const SalesSummaryBarChart = ({
  data = {
    pickupSummary: [],
    salesSummary: [],
  },
  frequency,
  onWidgetDateChange,
}: IProps) => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const mappedData = useMemo(() => {
    const result = [] as Array<{ name: string; sales: number; pickup: number }>
    const hours = Array(25)
      .fill(undefined)
      .map((_, index) => index)

    data?.pickupSummary?.forEach((pickup, index) => {
      const period = moment(pickup?.period)
      let name: string
      if (frequency === 'hour') {
        name = period?.hour(hours[index])?.format('HH')
      } else if (frequency === 'day') {
        name = period.format('DD MMM')
      } else {
        name = period.format('MMM')
      }

      if (!result[index]) {
        result[index] = { name, sales: 0, pickup: 0 }
      }

      result[index].pickup += pickup?.amount
    })

    data?.salesSummary.forEach((sale, index) => {
      const period = moment(sale?.period)
      let name: string
      if (frequency === 'hour') {
        name = period?.hour(hours[index]).format('HH')
      } else if (frequency === 'day') {
        name = period.format('DD MMM')
      } else {
        name = period.format('MMM')
      }

      if (!result[index]) {
        result[index] = { name, sales: 0, pickup: 0 }
      }

      result[index].sales += sale.amount
    })

    return result
  }, [data?.pickupSummary, data?.salesSummary, frequency])

  const maxY = useMemo(() => {
    let max = mappedData[0]?.sales

    mappedData?.forEach((item) => {
      if (item.sales > max) {
        max = item.sales
      }

      if (item.pickup > max) {
        max = item.pickup
      }
    })

    return max + max / 10
  }, [mappedData])

  const minY = useMemo(() => {
    let min = mappedData[0]?.sales

    mappedData?.forEach((item) => {
      if (item.sales > min) {
        min = item.sales
      }

      if (item.pickup > min) {
        min = item.pickup
      }
    })

    return min === 0 ? 1 : min + min / 2.5
  }, [mappedData])

  const title = useMemo(
    () => (onWidgetDateChange ? 'Overview' : 'Sales Summary'),
    [onWidgetDateChange]
  )

  const filters = onWidgetDateChange && (
    <RangePicker
      className={styles.delegationDatePicker}
      placeholder={['Date range', '']}
      onChange={(val) => onWidgetDateChange(val as Moment[])}
    />
  )

  return (
    <DashboardTableWrapper
      title={title}
      infoText={`${title} Bar Chart`}
      withPadding={false}
      filters={filters}
    >
      {!!mappedData.length ? (
        <div style={{ padding: '10px 0' }}>
          <ResponsiveContainer width='100%' height={260}>
            <ComposedChart
              width={500}
              height={400}
              data={mappedData}
              margin={{
                top: 30,
                right: 20,
                left: -10,
                bottom: 0,
              }}
            >
              <CartesianGrid stroke='#E6EFF8' strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis
                domain={[minY, maxY]}
                tickFormatter={(value) =>
                  isPrivateMode
                    ? replaceCharactersWithAsterisks(
                        new Intl.NumberFormat('en-US', {
                          notation: 'compact',
                          compactDisplay: 'short',
                        }).format(value)
                      )
                    : new Intl.NumberFormat('en-US', {
                        notation: 'compact',
                        compactDisplay: 'short',
                      }).format(value)
                }
              />
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const time = payload[0]?.payload?.name

                    return (
                      <RecursivelyReplaceCharacters>
                        <div className={styles.tooltip}>
                          <div className={styles.tooltipRow}>
                            <div className={styles.tooltipRowValue}>{time}</div>
                          </div>
                          {payload?.map((value, i) => (
                            <div
                              key={i}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gridGap: 10,
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{
                                  color: payload[i]?.color,
                                }}
                              >
                                {payload[i]?.name}:
                              </div>
                              <div>{payload[i]?.value}</div>
                            </div>
                          ))}
                        </div>
                      </RecursivelyReplaceCharacters>
                    )
                  }

                  return null
                }}
              />
              <Legend
                verticalAlign='top'
                align='left'
                wrapperStyle={{ left: 40, top: 10 }}
                iconType='circle'
              />
              <Bar dataKey='sales' barSize={40} fill='#80C9F5' name='Sales' />
              <ReferenceLine y={0} stroke='#000' />
              <Line
                type='monotone'
                dataKey='pickup'
                stroke='#81C784'
                strokeWidth={2}
                name='Pickup'
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <NotFoundPage />
      )}
    </DashboardTableWrapper>
  )
}
