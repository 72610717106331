import { Col, Form, Row, Spin, UploadFile } from 'antd'
import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import * as C from './Components'
import { UploaderPhotos2 } from 'src/shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { RcFile } from 'antd/lib/upload'
import { Button } from 'src/shared/components/button/Button'

import { useUpdateUserPasswordMutation } from 'src/features/Settings/core/http/UsersManagementApi'

import { isEmpty, omit } from 'lodash'
import { formatDateWithTime } from 'src/helpers/utils'
import { PasswordBlock } from './Components/PasswordBlock'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { useGetProfileQuery, useUpdateProfileMutation } from '../../core/http/Profile'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { EditIcon, SignOutIcon } from 'src/assets/svg'
import { clearAuthData } from 'src/shared/api'
import { useDispatch } from 'react-redux'
import { resetStateAction } from 'src/redux/actions/resetState'
import { clearProfileSliceState } from '../../core/store/ProfileInfoSlice'
import { Address } from 'src/features/salesNetwork/components'

export const ProfileInfo = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const [formPassword] = Form.useForm()

  const { mode } = useParams()
  const { key: keyLocation } = useLocation()

  const [avatarFile, serAvatarFile] = useState<RcFile | string | undefined>('')
  const [, setIsLogoDelete] = useState(false)

  const view = mode === 'view'
  const edit = mode === 'edit'

  const { data: profile, isFetching } = useGetProfileQuery()

  const [updatePass, dUpdatedPass] = useUpdateUserPasswordMutation()
  const [updateProfile, updateResp] = useUpdateProfileMutation()

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, dUpdatedPass.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, dUpdatedPass.isError, dUpdatedPass.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.success, updateResp.isSuccess, null, () =>
    navigate('/info/profile-info/view')
  )
  useNotification(NOTIFICATION_TYPES.error, updateResp.isError, updateResp.error as ErrorNode)

  useEffect(() => {
    if (isEmpty(profile)) return
    form.setFieldsValue({
      ...profile,
      countryId: profile.country?.id,
      provinceId: profile.province?.id,
      districtId: +profile.district?.id,
      createdAt: formatDateWithTime(profile.createdAt, profile?.calendar),
    })
  }, [profile, form])

  const handleSignOut = () => {
    clearAuthData()
    navigate('sign-in')
    dispatch(resetStateAction())
    dispatch(clearProfileSliceState())
  }

  const onFinishPassword = ({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string
    newPassword: string
  }) => {
    if (!newPassword || !oldPassword) return

    updatePass({
      id: String(profile?.id),
      body: {
        oldPassword,
        newPassword,
      },
    })
  }

  const onFinish = (values: {
    nativeLanguage: {
      name: string
      id: string
    }
    country: Record<string, unknown>
    province: Record<string, unknown>
    district: Record<string, unknown>
    userPosition: Record<string, unknown>
  }) => {
    const fData = new FormData()

    const req = {
      ...omit(values, ['district', 'province', 'country', 'nativeLanguage']),
      nativeLanguageId: values.nativeLanguage?.id,
      ...(values.country && { countryId: values.country?.value }),
      ...(values.district && { districtId: values.district?.value }),
      ...(values.province && { provinceId: values.province?.value }),
      userPosition: values.userPosition?.name,
    }

    fData.append('user', JSON.stringify(req))
    fData.append('logo', avatarFile!)

    updateProfile(fData)
  }

  const onPressCancel: VoidFunction = () => {
    const isInitialLocation: boolean = keyLocation === 'default' // <-- This value is always "default" on the initial location.
    const to = isInitialLocation ? '/' : -2
    navigate(to as string)
  }

  const isUpdating = updateResp.isLoading || dUpdatedPass.isLoading

  const content = (
    <main className={styles.main}>
      <div className={styles['agent-detail-view']}>
        <div className={styles.headerTopRow}>
          <IconButton
            color='orange'
            type='default'
            icon={<EditIcon />}
            disabled={!view}
            onClick={() => navigate(`/info/profile-info/edit`)}
          />
        </div>
        <Form form={form} layout='vertical' onFinish={onFinish} autoComplete='off'>
          <Row wrap={false} justify='start'>
            <Col className={styles['wrapper-column']}>
              <C.DetailInfo view={view} />
              <C.ContactInformation form={form} view={view} />
              <Address form={form} view={view} dataAgent={profile!} />
            </Col>

            <div>
              <UploaderPhotos2
                form={form}
                isPersonPreview
                title='PROFILE PICTURE'
                view={view}
                defaultPreviewImage={profile?.logo as string}
                setToDeleteLogo={setIsLogoDelete}
                onChange={(photo: UploadFile[]) => {
                  serAvatarFile(photo[0].originFileObj as RcFile)
                  setIsLogoDelete(false)
                }}
              />
            </div>
          </Row>
        </Form>
        {!view && (
          <Form
            form={formPassword}
            layout='vertical'
            onFinish={onFinishPassword}
            // initialValues={initialsPassword}
            autoComplete='off'
            className={styles['form-password']}
          >
            <PasswordBlock view={view} />
          </Form>
        )}
      </div>
      <div className={styles['footer']}>
        <Row className={styles['wrapper-footer-buttons']} justify='space-between'>
          <IconButton icon={<SignOutIcon />} color='error' size='middle' onClick={handleSignOut}>
            sign out
          </IconButton>
          <div>
            {edit && (
              <Button color='blue' onClick={onPressCancel} size='middle'>
                Cancel
              </Button>
            )}
            <Button
              color='blue'
              htmlType='submit'
              onClick={() => {
                if (!view) {
                  form.submit()
                  formPassword.submit()
                }
                // navigate(`/sales-network/agent-management/edit/`)
                navigate('/info/profile-info/edit')
              }}
              disabled={isUpdating}
              size='middle'
              type='primary'
            >
              {view ? 'Edit' : 'Save Changes'}
            </Button>
          </div>
        </Row>
      </div>
    </main>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
