import { ISalesByChannels } from '../../../../../Reports/models/IExternalReport'
import React, { useMemo } from 'react'
import { DashboardTableWrapper } from '../../../../../Dashboard/pages/Overview/Components/DashboardTableWrapper'
import styles from '../../../../../Finance/pages/Overview/styles.module.scss'
import {
  PIE_CHART_COLORS,
  PIE_CHART_COLORS_WITH_TWO_ITEMS,
} from '../../../../../../helpers/contants'
import { PieChart } from '../../../../../../shared/components/pieChart/pieChart'
import { NotFoundPage } from '../../../../../NotFoundPage'

interface IProps {
  widgetData: ISalesByChannels[]
}

export const WeekProductivityPieChart = ({ widgetData }: IProps) => {
  const mappedData = useMemo(
    () =>
      widgetData?.map((channel) => ({
        name: channel?.channel,
        value: Math.abs(channel?.salesAmount),
        label: channel?.salesAmount,
      })),
    [widgetData]
  )

  return (
    <DashboardTableWrapper title="Week's productivity">
      {widgetData?.length ? (
        <div className={styles.pieChartTypeDataContainer}>
          <div>
            {mappedData?.map((cData, i) => (
              <div key={cData.name} className={styles.chartDataTitle}>
                <div
                  className={styles.chartDataDot}
                  style={{
                    backgroundColor:
                      mappedData?.length <= 2
                        ? PIE_CHART_COLORS_WITH_TWO_ITEMS[i]
                        : PIE_CHART_COLORS[i],
                  }}
                />
                <div className={styles.chartDataName}>{cData.name}</div>
              </div>
            ))}
          </div>
          <div>
            <PieChart
              data={mappedData}
              colors={mappedData?.length <= 2 ? PIE_CHART_COLORS_WITH_TWO_ITEMS : PIE_CHART_COLORS}
              textCenterChart={mappedData.reduce((a, v) => a + v.value, 0)}
            />
          </div>
        </div>
      ) : (
        <NotFoundPage />
      )}
    </DashboardTableWrapper>
  )
}
