import { IByUser } from '../../../shared/models/IByUser'

export enum MessageTypeEnum {
  TEXT_MESSAGE = 'text',
  VOICE_MESSAGE = 'voice',
  MESSED_CALL = 'missed_call',
  DECLINE_CALL = 'decline_call',
  CALL = 'call',
}

export interface IInternalChatInfo {
  id: number
  firstName: string
  lastName: string
  logo: string
  department: {
    id: number
    name: string
  }
  userPosition: {
    id: number
    name: string
  }
  userContactChannels: [
    {
      id: number
      type: string
      value: string
    }
  ]
}

export interface IForwardedFrom {
  id: number
  logo: string
  name: string
}

export interface IRepliedTo {
  id: number
  message: string
  internalChatMessageFiles: IInternalChatMessageFile[]
  createdAt: string
}

export interface IAttachmentFile {
  category: string
  createdAt: string
  fileFormat: string
  id: string
  isPrivate: boolean
  link: string
  name: string
}

export interface IInternalChatMessageFile {
  createdAt: string
  fileId: string
  fileType: string
  id: number
  internalChatMessageId: number
  updatedAt: string
  file: IAttachmentFile
}

export interface IInternalChatMessage {
  id: number
  message: string
  fromUserId: number
  toUserId: number
  createdAt: string
  updatedAt: string
  internalChatMessageFiles?: Array<IInternalChatMessageFile>
  forwardedFromUser?: IForwardedFrom
  replyToMessage?: IRepliedTo
}

export interface IInternalForwarderChatMessage extends IInternalChatMessage {
  replyToMessage: IRepliedTo
  forwardedFromUser: IForwardedFrom
}

export interface IInternalChatMessageReadings {
  id: number
  isPinned: boolean
  lastReadingAt: string
  createdAt: string
  updatedAt: string
  toUserId: number
  createdByUserId?: number
}

export interface IInternalChat {
  id: number
  firstName: string
  lastName: string
  logo: null
  unreadMessagesCounter: number
  lastInternalChatMessage: IInternalChatMessage
  recievedInternalChatMessageReading?: IInternalChatMessageReadings
  sentInternalChatMessageReading?: IInternalChatMessageReadings
  typing?: boolean
  unreadedMessagesCount: number
  lastOnlineAt: string

  // TODO: (Test data). Update with response
  online?: boolean
  lastMessageType?: MessageTypeEnum
  unReadCount?: number
  isLastMessageRead?: boolean
}

export enum InternalChatAttachmentEnum {
  Pictures = 'Photo',
  Videos = 'Video',
  Links = 'Link',
}

export interface IInternalChatFile {
  id: number
  name: string
  link: string
  fileFormat: string
  category: string
  isPrivate: boolean
  createdByUser: {
    id: number
    firstName: string
    lastName: string
  }
  createdAt: string
}

export interface IInternalChatAttachment {
  id: number
  internalChatMessageId: number
  fileId: number
  fileType: InternalChatAttachmentEnum
  file: IInternalChatFile
  createdAt: string
  updatedAt: string
}

export interface IInternalChatLink {
  id: number
  messageId: number
  link: string
  createdAt: string
  updatedAt: string
  metadata: {
    ogTitle?: string
    ogType?: string
    ogUrl?: string
    ogDescription?: string
    ogImage?: {
      url: string
      width: string
      height: string
      type: string
    }
    requestUrl?: string
    success?: boolean
    favicon?: string
  }
}

export interface ITaggingUsers {
  id: number
  firstName: string
  lastName: string
}

export interface INewTextMessage {
  toUserId: number
  message: string
}

export interface ISearchTransaction {
  id: number
  type: string
  name: string
}

export interface IUnreadInternalMessage {
  id: number
  message: string
  fromUser: IByUser
  createdAt: string
  internalChatMessageFiles: IInternalChatMessageFile[]
}
