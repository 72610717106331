import React, { memo } from 'react'
import moment from 'moment'
import { Col, Row } from 'antd'

import { FilterPopup } from 'src/shared/components/filter/FilterPopup'
import { Filter, ISelectedFilters } from 'src/shared/components/filter/Filter'
import { IFilterFields } from 'src/shared/models/IFilterFields'
import { useFilter } from 'src/shared/hooks/filter/useFilter'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

import styles from './styles.module.scss'
import { CALENDAR_TYPE_ENUM } from 'src/features/Settings/models/IUser'
import dayjs from 'dayjs'

interface IProps {
  setActiveFilters: (values: ISelectedFilters) => void
  filterValues: ISelectedFilters
  data?: IFilterFields[]
}

const TableHeader = ({ setActiveFilters, data, filterValues }: IProps): JSX.Element => {
  const { isOpen, onCancel, form, onReset, onFinish } = useFilter({ setActiveFilters })
  const { data: profile } = useGetProfileQuery()

  const lastUpdate =
    profile?.calendar === CALENDAR_TYPE_ENUM.SHAMSI
      ? dayjs().calendar('jalali').format('DD-MM-YYYY HH:mm')
      : moment().format('DD-MM-YYYY HH:mm')

  return (
    <>
      <Row align='middle' justify='space-between' style={{ marginBottom: 20 }}>
        <Col style={{ display: 'flex', gridGap: 20 }}>
          <Filter
            data={data}
            form={form}
            setIsOpen={onCancel}
            selectedFilters={filterValues}
            setSelectedFilters={setActiveFilters}
          />
        </Col>
        <div className={styles.lastUpdate}>
          <div className={styles.lastUpdateTitle}>Last update</div>
          <div className={styles.lastUpdateDate}>{lastUpdate}</div>
        </div>
      </Row>
      {isOpen && (
        <FilterPopup
          data={data}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          form={form}
          onCancel={onCancel}
          onReset={onReset}
          onFinish={onFinish}
        />
      )}
    </>
  )
}

export default memo(TableHeader)
