import styles from '../../UsersManagementView/styles.module.scss'
import { Form, Select, Spin } from 'antd'
import { TextField } from '../../../../../../../../../shared/components/textfield/TextField'
import { Wrapper } from '../../../../../../../../salesNetwork/components/Wrapper'
import { useParams } from 'react-router-dom'
import { IViewMode } from '../../../../../../../../Finance/models/IViewMode'
import {
  useGetDepartmentsListQuery,
  useGetLanguagesListQuery,
} from '../../../../../../../core/http/DepartmentsManagementApi'

import { useGetRolesListQuery } from '../../../../../../../core/http/RolesAndPermissionsManagement'
import { AfghanistanFlag, AmericanFlag2 } from '../../../../../../../../../assets/svg'
import { useMemo } from 'react'
import {
  minMaxLengthValidator,
  requiredMulSelectValidator,
  requiredValidator,
} from '../../../../../../../../../helpers/validation'
import { timezones } from '../../../../../../../../../shared/data/timezones'
import { useGetPositionListQuery } from '../../../../../../../core/http/PositionsApi'
import { CALENDAR_TYPE_ENUM } from '../../../../../../../models/IUser'
import { startCase } from 'lodash'
import { selectTagRender } from '../../../../../../../../../helpers/selectTagRender'

export const DetailInfo = ({ view }: IViewMode) => {
  const { mode } = useParams<{ mode?: 'view' | 'edit' }>()
  const isCreateMode = !!mode

  const { data: rolesList, isLoading: loadingRoles } = useGetRolesListQuery()
  const { data: departmentList, isLoading: loadingDepart } = useGetDepartmentsListQuery({})
  const { data: languageList, isLoading: loadingLang } = useGetLanguagesListQuery()
  const { data: positionList, isLoading: loadingPos } = useGetPositionListQuery({})

  const languageIcons = useMemo(
    () => ({
      English: {
        icon: <AmericanFlag2 />,
      },
      Dari: {
        icon: <AfghanistanFlag />,
      },
      Pashto: {
        icon: <AfghanistanFlag />,
      },
    }),
    []
  )

  const isFetchingData = loadingDepart || loadingPos || loadingRoles || loadingLang

  const timezoneOptions = useMemo(
    () => Object.entries(timezones).map(([label, value]) => ({ label, value })),
    []
  )

  const calendarOptions = useMemo(
    () =>
      (Object.keys(CALENDAR_TYPE_ENUM) as Array<keyof typeof CALENDAR_TYPE_ENUM>).map((method) => ({
        label: startCase(CALENDAR_TYPE_ENUM[method].toLowerCase()),
        value: CALENDAR_TYPE_ENUM[method],
      })),
    []
  )

  const content = (
    <Wrapper title='Detail Info' className={styles.wrapperBlock}>
      {isCreateMode && (
        <div className={styles.dblRow}>
          <Form.Item
            name='id'
            label='Profile Id'
            rules={[{ required: true, validator: requiredValidator('Profile Id') }]}
          >
            <TextField disabled />
          </Form.Item>

          <Form.Item
            name='createdAt'
            label='Creation Date'
            rules={[{ required: true, validator: requiredValidator('Creation Date') }]}
          >
            <TextField disabled />
          </Form.Item>
        </div>
      )}

      <div className={styles.dblRow}>
        <Form.Item
          name='firstName'
          label='First Name'
          rules={[
            {
              required: true,
              validator: requiredValidator('First Name'),
            },
            { validator: minMaxLengthValidator(2, 50) },
          ]}
        >
          <TextField disabled={view} placeholder='Enter First Name' />
        </Form.Item>

        <Form.Item
          name='lastName'
          label='Last Name'
          rules={[
            {
              required: true,
              validator: requiredValidator('Last Name'),
            },
            { validator: minMaxLengthValidator(2, 50) },
          ]}
        >
          <TextField disabled={view} placeholder='Enter Last Name' />
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item
          name={['userPosition', 'id']}
          label='Position'
          rules={[{ required: true, validator: requiredValidator('Position') }]}
        >
          <Select disabled={view} allowClear placeholder='Select Position'>
            {positionList?.items.map((position) => (
              <Select.Option key={position?.id} value={position?.id}>
                {position?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={['userRole', 'id']}
          label='Role'
          rules={[{ required: true, validator: requiredValidator('Role') }]}
        >
          <Select disabled={view} allowClear placeholder='Select Role'>
            {rolesList?.items.map((role) => (
              <Select.Option key={role.id} value={role.id}>
                {role.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item
          name={['department', 'id']}
          label='Department'
          rules={[{ required: true, validator: requiredValidator('Department') }]}
        >
          <Select disabled={view} allowClear placeholder='Select Department'>
            {departmentList?.items.map((department) => (
              <Select.Option key={department.id} value={department.id}>
                {department.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={['nativeLanguage', 'id']}
          label='Native Language'
          rules={[{ required: true, validator: requiredValidator('Native Language') }]}
        >
          <Select disabled={view} allowClear placeholder='Select Native Language'>
            {languageList?.items.map((language) => (
              <Select.Option key={language.id} value={language.id}>
                <div className={styles.wrapperContentOption}>
                  <div className={styles.wrapperLanguageIcon}>
                    {languageIcons[language.name as keyof typeof languageIcons]?.icon}
                  </div>
                  <div>{language.name}</div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item
          name='timeZone'
          label='Time Zone'
          rules={[{ required: true, validator: requiredValidator('Time Zone') }]}
        >
          <Select
            disabled={view}
            options={timezoneOptions}
            placeholder='Select Time Zone'
            allowClear
          />
        </Form.Item>

        <Form.Item
          name='calendar'
          label='Calendar'
          rules={[{ required: true, validator: requiredValidator('Calendar') }]}
        >
          <Select
            disabled={view}
            options={calendarOptions}
            placeholder='Select Calendar'
            allowClear
          />
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item
          name='hasAccessToExternalChat'
          label='Has Access To External Chat'
          rules={[{ required: true, validator: requiredValidator('Has Access To External Chat') }]}
          className='noBottomMargin'
        >
          <Select disabled={view} allowClear placeholder='Has Access To External Chat'>
            <Select value={true}>Yes</Select>
            <Select value={false}>No</Select>
          </Select>
        </Form.Item>

        <Form.Item
          name='skillIds'
          label='Skills'
          rules={[
            {
              required: true,
              validator: requiredMulSelectValidator('Skills'),
            },
          ]}
          className='noBottomMargin'
        >
          <Select
            disabled={view}
            placeholder='Select Skills'
            mode='multiple'
            tagRender={selectTagRender}
            maxTagCount='responsive'
            showArrow
            allowClear
          >
            {languageList?.items.map((language) => (
              <Select.Option key={`skill-${language.id}`} value={language.id}>
                {language.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    </Wrapper>
  )

  return isFetchingData ? <Spin>{content}</Spin> : content
}
