import React, { memo } from 'react'
import { DoubleCheckMarkIcon } from '../../../../../../assets/svg'
import styles from '../../styles.module.scss'

interface IProps {
  status: boolean
}

const NotificationStatus = ({ status }: IProps) => {
  return (
    <div className={styles.notificationStatus}>
      {status ? <DoubleCheckMarkIcon /> : <div className={styles.orangeDot} />}
    </div>
  )
}

export default memo(NotificationStatus)
