// styles
import { FormInstance } from 'antd'
import { IWalletBody } from 'src/features/Finance/models/IWallet'
import { RightModalContext } from 'src/features/Modals'
import { useContext } from 'react'
import { Button } from 'src/shared/components/button/Button'
import styles from '../styles.module.scss'
import { showConfirmMessage } from '../../../../../../../../shared/api/errorHandler'

interface IFooter {
  form: FormInstance<IWalletBody>
}

export const Footer: React.FC<IFooter> = ({ form }) => {
  const rightModalContext = useContext(RightModalContext)

  const handlerConfirmClick = async () => {
    const { amount = 0, senderWalletId, receiverWalletId } = form.getFieldsValue()
    await form.validateFields()

    showConfirmMessage(
      `You send ${amount} eMoney from ${senderWalletId.label} to ${receiverWalletId.label}`,
      () => form.submit()
    )
  }

  return (
    <div className={styles.footerContainer}>
      <div className={styles.btnsInnerContainer}>
        <Button color='blue' size='middle' onClick={rightModalContext.onClose}>
          cancel
        </Button>
        <Button color='orange' size='middle' type='primary' onClick={handlerConfirmClick}>
          ok
        </Button>
      </div>
    </div>
  )
}
