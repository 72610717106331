import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Spin } from 'antd'

import DetailInfo from '../../../DetailInfo'
import FooterActions from '../../../FooterActions'
import { PermissionsTable } from '../../../Permissions'
import { AddIcon } from '../../../../../../../../assets/svg'
import { IconButton } from '../../../../../../../../shared/components/iconButton/IconButton'
import { StepRegistration } from '../../../../../../../../shared/components/stepRegistration'
import { RolesAndPermissionsManagementUsersList } from './Components/RolesAndPermissionsManagementUsersList'

import { RightModalContext } from '../../../../../../../Modals'
import { IUser } from '../../../../../../models/IUser'
import { IGroupCreate, IPermissionCreate, IRoles } from '../../../../../../models/IRoles'
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux'
import { formatOnlyDate } from '../../../../../../../../helpers/utils'
import { RIGHT_MODALS } from '../../../../../../../../helpers/contants'
import { SETTINGS_ENUM } from '../../../../../../../../routes/settings'
import {
  addUser,
  clearUsersSliceState,
  deleteUserById,
} from '../../../../../../core/store/UsersManagerSlice'
import { useCreateRoleMutation } from '../../../../../../core/http/RolesAndPermissionsManagement'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../../shared/api/errorHandler'

import usePermissions from '../../../../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../../../models/IPermissions'
import WithPermissionDeniedContainer from '../../../../../../../../shared/components/withPermissionDenied'
import styles from './styles.module.scss'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

interface IRequestBody {
  role: {
    name: string
    status: boolean
  }
  permissions: Array<IPermissionCreate>
  groups: Array<{ group: number; access: boolean }>
  users?: Array<number>
}

export const RolesManagementNew = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { onOpen } = useContext(RightModalContext)

  const [form] = Form.useForm()
  const { data: profile } = useGetProfileQuery()

  const usersList: IUser[] = useAppSelector((state) => state.usersManagementSlice.usersList)

  const [permissions, setPermissions] = useState<Array<IPermissionCreate>>([])
  const [groups, setGroups] = useState<Array<{ group: number; access: boolean }>>([])

  const [createRole, createRoleResp] = useCreateRoleMutation()

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const demandPermissions = [IPermissionsActions.ADD]
  const [rolesId] = getModulesIdsByNames([IPermissionsNames.Roles])
  const [usersId] = getModulesIdsByNames([IPermissionsNames.Users])
  const [canAddRole] = canPerformAction(rolesId, demandPermissions)
  const [canViewUser, canAddUser] = canPerformAction(usersId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.ADD,
  ])

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, createRoleResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    createRoleResp.isError,
    createRoleResp.error as ErrorNode
  )

  const userIds = useMemo(() => {
    return usersList.map((user) => user.id)
  }, [usersList])

  const onFinish = (values: IRoles) => {
    const reqBody: IRequestBody = {
      role: {
        name: form.getFieldValue('name'),
        status: values.status ?? false,
      },
      permissions,
      groups,
    }
    if (userIds.length) {
      reqBody.users = userIds
    }
    createRole(reqBody)
  }

  const _checkIfPermissionSelectedInState = (permission: IPermissionCreate) => {
    return !!permissions.find(
      (p) =>
        p.permissionModuleId === permission.permissionModuleId &&
        p.permission === permission.permission
    )
  }

  const _onSelectSinglePermission = (permission: IPermissionCreate) => {
    const selectedPermission = _checkIfPermissionSelectedInState(permission)

    if (selectedPermission) {
      return setPermissions((prev) => [
        ...prev.filter((p) => {
          return !(
            p.permissionModuleId === permission.permissionModuleId &&
            p.permission === permission.permission
          )
        }),
      ])
    }

    setPermissions((prev) => [...prev, permission])
  }

  const _onSelectMultiplePermissions = (
    selectedOnActions: Array<IPermissionCreate>,
    checked: boolean | undefined
  ) => {
    if (!checked) {
      const updated = permissions.filter(
        (sisPerm) =>
          !selectedOnActions.find(
            (eoAPerm) =>
              eoAPerm.permissionModuleId === sisPerm.permissionModuleId &&
              eoAPerm.permission === sisPerm.permission
          )
      )
      return setPermissions(updated)
    }

    const updated = [
      ...selectedOnActions.filter(
        (soaPerm) =>
          !permissions.find(
            (eoAPerm) =>
              eoAPerm.permissionModuleId === soaPerm.permissionModuleId &&
              eoAPerm.permission === soaPerm.permission
          )
      ),
      ...permissions,
    ]

    setPermissions(updated)
  }

  const onCheckPermission = (
    permission: IPermissionCreate | Array<IPermissionCreate>,
    checked: boolean | undefined
  ) => {
    if (Array.isArray(permission)) {
      _onSelectMultiplePermissions(permission, checked)
    } else {
      _onSelectSinglePermission(permission)
    }
  }

  const onChangeGroupStatus = (group: IGroupCreate) => {
    const index = groups.findIndex((el) => el.group === group.group)
    const updated = [...groups]
    if (index !== -1) {
      updated[index] = group
      setGroups(updated)
    } else {
      setGroups((prev) => [...prev, group])
    }
  }

  const onDeleteUser = (id: number) => {
    dispatch(deleteUserById(id))
  }

  const onAddUser = (user: IUser) => {
    dispatch(addUser(user))
  }

  const onPressAddUser = () => {
    onOpen(RIGHT_MODALS.SETTINGS.ROLES_AND_PERMISSIONS_MANAGEMENT_USERS_LIST, {
      title: 'Add users',
    })
  }

  const onPressCancelChanges = () => {
    navigate(SETTINGS_ENUM.USERS_MANAGEMENT)
  }

  const onPressSaveChanges = useCallback(() => {
    form.submit()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (createRoleResp.isSuccess) {
      dispatch(clearUsersSliceState())
      return navigate(
        `${SETTINGS_ENUM.USERS_MANAGEMENT_ROLES_AND_PERMISSIONS}/view/${createRoleResp.data.id}`
      )
    }
    // eslint-disable-next-line
  }, [createRoleResp.isSuccess])

  const content = (
    <WithPermissionDeniedContainer isAllowedView={canAddRole}>
      <div className={styles.layout}>
        <Form
          form={form}
          onFinish={onFinish}
          layout='vertical'
          initialValues={{
            createdBy: 'admin admin',
            createdAt: formatOnlyDate(new Date(), profile?.calendar),
            status: false,
          }}
        >
          <StepRegistration
            backgroundColor='#FF6D00'
            stepNumber={1}
            stepText='General Information'
            mB='33px'
            mT='12.5px'
          />
          <DetailInfo isNewMode />
          <StepRegistration stepNumber={2} stepText='Permissions' mB='33px' mT='33px' />
          <PermissionsTable
            selectedGroups={groups}
            selectedPermissions={permissions}
            onCheckPermission={onCheckPermission}
            onChangeGroupStatus={onChangeGroupStatus}
          />
          {canAddUser && canViewUser && (
            <>
              <StepRegistration stepNumber={3} stepText='Add users' mB='33px' mT='33px' />
              <RolesAndPermissionsManagementUsersList
                onDeleteUser={onDeleteUser}
                onAddUser={onAddUser}
                users={usersList}
              />
              <IconButton
                icon={<AddIcon />}
                color='orange'
                iconPos='right'
                size='large'
                block
                onClick={onPressAddUser}
              >
                Add users
              </IconButton>
            </>
          )}
        </Form>
        <FooterActions
          isLoading={createRoleResp.isLoading}
          onPressCancelChanges={onPressCancelChanges}
          onPressSaveChanges={onPressSaveChanges}
          submitButtonText='Create Role'
        />
      </div>
    </WithPermissionDeniedContainer>
  )

  return createRoleResp.isLoading ? <Spin>{content}</Spin> : content
}
