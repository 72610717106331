import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { Form, Row, Select as AntSelect, Space, Spin } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from 'src/shared/components/textfield/TextField'
import styles from './style.module.scss'
import footerStyle from '../../styles.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useDeleteFileMutation,
  useGetFileInfoQuery,
  useUpdateFileMutation,
} from '../../../../../core/http/FileManagementApi'
import React, { useCallback, useEffect } from 'react'
import { Button } from 'src/shared/components/button/Button'
import { ErrorNode, showConfirmMessage } from 'src/shared/api/errorHandler'
import { PermissionEnum } from 'src/shared/models/Permission'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { useAppDispatch } from '../../../../../../../redux'
import { setBreadcrumb } from '../../../../../../app/core/BreadcrumbsSlice'
import usePermissions from '../../../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../../models/IPermissions'
import PermissionDenied from '../../../../../../../shared/components/permissionDenied'
import { formatDateWithTime } from '../../../../../../../helpers/utils'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

const { Option } = AntSelect

export const DetailInfo = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { id, mode } = useParams<{ id: string; mode: 'edit' | 'view' }>()
  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const [form] = Form.useForm()

  const { data, isLoading } = useGetFileInfoQuery(id, { skip: !id })
  const { data: profile } = useGetProfileQuery()

  const [delFile, delFileResp] = useDeleteFileMutation()
  const [updateFile, { isLoading: isFileUpdating, isSuccess, isError, error }] =
    useUpdateFileMutation()

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const [contentsId] = getModulesIdsByNames([IPermissionsNames.Content])
  const demandPermissions = [IPermissionsActions.VIEW, IPermissionsActions.EDIT]
  const [canViewFile, canEditFile] = canPerformAction(contentsId, demandPermissions)
  const notAllowedContent = (isViewMode && !canViewFile) || (isEditMode && !canEditFile)

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, isSuccess)
  useNotification(NOTIFICATION_TYPES.success, delFileResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, delFileResp.isError, delFileResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, isError, error as ErrorNode)

  const openNotification = useCallback(() => {
    showConfirmMessage('This file will be deleted', () => delFile(id as string))
  }, [delFile, id])

  const onFinish = () => {
    if (id) {
      const { isPrivate, fileName } = form.getFieldsValue()
      const updateBody = {
        name: fileName + data?.fileFormat,
        isPrivate: isPrivate,
        id,
      }

      updateFile(updateBody)
    }
  }

  const setViewMode = () => navigate(`/settings/file-management/view/${id}`, { replace: true })

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...(data?.createdByUser?.name && { createdBy: `${data?.createdByUser?.name}` }),
        fileName: data?.name?.replace(data?.fileFormat, ''),
        createdAt: formatDateWithTime(data?.createdAt, profile?.calendar),
      })
    }
  }, [data, form, isLoading, profile])

  useEffect(() => {
    if (data) {
      dispatch(setBreadcrumb({ name: data?.name }))
    }
  }, [data, dispatch])

  useEffect(() => {
    if (isSuccess) {
      navigate(`/settings/file-management/view/${id}`)
    }
    // eslint-disable-next-line
  }, [id, isSuccess])

  useEffect(() => {
    if (delFileResp.isSuccess) {
      navigate('/settings/file-management')
    }
    // eslint-disable-next-line
  }, [delFileResp.isSuccess])

  const content = (
    <>
      {notAllowedContent && <PermissionDenied />}
      {((isViewMode && canViewFile) || (isEditMode && canEditFile)) && (
        <Wrapper title='Detail Info'>
          <Form form={form} onFinish={onFinish} layout='vertical' initialValues={data}>
            <div className={styles.dblRow}>
              <FormItem name='createdAt' label='Creation Date'>
                <TextField disabled />
              </FormItem>

              <FormItem name='createdBy' label='Created By'>
                <TextField disabled />
              </FormItem>
            </div>

            <div>
              <FormItem name='fileName' label='File Name'>
                <TextField disabled={isViewMode} />
              </FormItem>
            </div>

            <div className={styles.dblRow}>
              <FormItem name='fileFormat' label='File Format'>
                <TextField disabled />
              </FormItem>

              <FormItem name='category' label='Category'>
                <TextField disabled />
              </FormItem>
            </div>

            <div>
              <FormItem
                name='isPrivate'
                label='Permission'
                wrapperCol={{ span: 12 }}
                style={{ marginRight: 24 }}
              >
                <AntSelect disabled={isViewMode} allowClear>
                  <Option value={true}>{PermissionEnum.PRIVATE}</Option>
                  <Option value={false}>{PermissionEnum.PUBLIC}</Option>
                </AntSelect>
              </FormItem>
            </div>
          </Form>
        </Wrapper>
      )}
    </>
  )

  return (
    <div className='fullVH'>
      {isLoading || isFileUpdating ? <Spin>{content}</Spin> : content}
      {!isViewMode && (
        <div className={footerStyle.wrapper}>
          <Row className={footerStyle.footer} justify='space-between'>
            <Button color='error' size='middle' onClick={openNotification}>
              Delete
            </Button>
            <Space size={10}>
              <Button color='blue' size='middle' onClick={setViewMode}>
                Cancel
              </Button>
              {canEditFile && (
                <Button
                  htmlType='submit'
                  size='middle'
                  type='primary'
                  onClick={() => form.submit()}
                  disabled={isFileUpdating}
                >
                  Save Changes
                </Button>
              )}
            </Space>
          </Row>
        </div>
      )}
    </div>
  )
}
