import { IViewMode } from '../../../../../Finance/models/IViewMode'
import styles from '../../style.module.scss'
import { Form, Select } from 'antd'
import { TextField } from '../../../../../../shared/components/textfield/TextField'
import { TICKET_PRIORITY_ENUM, TICKET_TYPE_ENUM } from '../../../../models/ITicket'
import { useMemo } from 'react'
import classNames from 'classnames'
import { useGetUsersListQuery } from '../../../../../Settings/core/http/UsersManagementApi'
import { startCase } from 'lodash'
import { useGetDepartmentListQuery } from '../../../../../Finance/core/http/PurchaseOrderApi'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import userPlaceholder from '../../../../../../assets/img/UserLogoPlaceholder.png'
import { requiredValidator } from 'src/helpers/validation'

interface IProps extends IViewMode {
  edit?: boolean
  ticketType?: string
  logo: string | null | undefined
}

export const DetailInfo = ({ view, edit, form, logo, ticketType }: IProps) => {
  const { data: departmentList } = useGetDepartmentListQuery()
  const { data: userList = { items: [] } } = useGetUsersListQuery({})

  const priorityOptions = useMemo(
    () =>
      (Object.keys(TICKET_PRIORITY_ENUM) as Array<keyof typeof TICKET_PRIORITY_ENUM>).map(
        (priority) => ({
          label: startCase(TICKET_PRIORITY_ENUM[priority]),
          value: TICKET_PRIORITY_ENUM[priority],
        })
      ),
    []
  )

  const onValidateFields = () => {
    form!.setFields([
      {
        name: ['department', 'id'],
        errors: [],
      },
      {
        name: ['responsiblePersonUser', 'id'],
        errors: [],
        value: '',
      },
    ])
  }

  return (
    <Wrapper title='Detail Info' className={classNames(styles.detailInfo, styles.mb24)}>
      {(view || edit) && (
        <div className={styles.dblRow}>
          <Form.Item name='id' label='Ticket Id'>
            <TextField disabled prefix='ID' />
          </Form.Item>

          <Form.Item name='createdAt' label='Creation Date'>
            <TextField disabled />
          </Form.Item>
        </div>
      )}

      <div className={styles.dblRow}>
        <Form.Item
          shouldUpdate
          name='priority'
          label='Priority'
          rules={[{ required: true, validator: requiredValidator('Priority') }]}
        >
          <Select
            disabled={view || ticketType === TICKET_TYPE_ENUM['sub-ticket']}
            allowClear
            placeholder='Select Priority'
          >
            {priorityOptions.map(({ label, value }) => (
              <Select.Option key={value}>
                <div className={styles.priorityItemContainer}>
                  <div
                    className={classNames(styles.priorityStatus, [styles[value.toLowerCase()]])}
                  />
                  <div
                    className={classNames(styles.priorityItemLabel, [styles[value.toLowerCase()]])}
                  >
                    {label}
                  </div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name='creator' label='Creator'>
          <TextField disabled prefix={<img src={logo || userPlaceholder} />} />
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item
          name={['department', 'id']}
          label='Department'
          rules={[{ required: true, validator: requiredValidator('Department') }]}
        >
          <Select
            onChange={onValidateFields}
            allowClear
            disabled={view || edit}
            placeholder='Select Department'
          >
            {departmentList?.items.map((department) => (
              <Select.Option key={department.id} value={department.id}>
                {department.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const departmentId = getFieldValue('department')?.id

            const options = userList.items.filter(
              ({ department }) => department?.id === departmentId
            )

            return (
              <Form.Item name={['responsiblePersonUser', 'id']} label='Responsible Person'>
                <Select
                  allowClear
                  disabled={view || edit || !departmentId}
                  placeholder='Select Responsible Person'
                >
                  {options.map((user) => (
                    <Select.Option key={user.id} value={user.id}>
                      {`${user.firstName} ${user.lastName}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )
          }}
        </Form.Item>
      </div>
    </Wrapper>
  )
}
