import { CREDIT_SCORE_TABS_ENUM } from './index.d'
import { Tabs } from 'antd/es'
import CreditScoreOverview from './Overview'
import CreditScoreAgents from './Agents'
import CreditScoreParameters from './Parameters'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'
import { useEffect } from 'react'

export const CreditScoreTabs = () => {
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: CREDIT_SCORE_TABS_ENUM.OVERVIEW,
  })

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  const items = [
    {
      label: CREDIT_SCORE_TABS_ENUM.OVERVIEW,
      key: CREDIT_SCORE_TABS_ENUM.OVERVIEW,
      children: <CreditScoreOverview />,
    },
    {
      label: CREDIT_SCORE_TABS_ENUM.PROFILING,
      key: CREDIT_SCORE_TABS_ENUM.PROFILING,
      children: <CreditScoreOverview />,
    },
    {
      label: CREDIT_SCORE_TABS_ENUM.AGENTS,
      key: CREDIT_SCORE_TABS_ENUM.AGENTS,
      children: <CreditScoreAgents />,
    },
    {
      label: CREDIT_SCORE_TABS_ENUM.PARAMETERS,
      key: CREDIT_SCORE_TABS_ENUM.PARAMETERS,
      children: <CreditScoreParameters />,
    },
  ]

  return <Tabs destroyInactiveTabPane activeKey={activeTab} items={items} onChange={onTabChange} />
}
