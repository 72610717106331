import React, { memo, ReactElement, useContext } from 'react'

import styles from './styles.module.scss'
import { ITransaction } from '../../../../models/SystemMonitoring'
import classNames from 'classnames'
import { Popover } from 'antd'
import { RightModalContext } from '../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'

interface IProps {
  value?: string | number | null
  title: string
  icon: ReactElement
  transactions?: ITransaction[]
}

const PopoverContent = ({ trans }: { trans: ITransaction }) => (
  <div className={styles.popup}>
    <div>
      TopupId: <span>{trans?.topupId}</span>
    </div>
    {!!trans?.duration && (
      <div>
        Response Time: <span>{trans?.duration?.toFixed(2)} sec</span>
      </div>
    )}
  </div>
)

const VendorInfoItem = ({ value, title, icon, transactions }: IProps) => {
  const { onOpen } = useContext(RightModalContext)

  return (
    <div className={styles.infoItem}>
      <div className={styles.infoItemIcon}>{icon}</div>
      <div className={styles.infoItemData}>
        <div className={styles.infoItemTitle}>{title}</div>
        {!!value && <div className={styles.infoItemValue}>{value}</div>}
        <div className={styles.transactionsList}>
          {!!transactions?.length &&
            transactions?.map((trans) => (
              <Popover key={trans?.topupId} content={<PopoverContent trans={trans} />}>
                <div
                  className={classNames(styles.transactionSquare, {
                    [styles.green]: trans.success,
                    [styles.red]: !trans.success,
                  })}
                  onClick={() =>
                    onOpen(RIGHT_MODALS.FINANCE.TOPUP, {
                      title: 'Topup Transaction',
                      id: trans?.topupId,
                    })
                  }
                />
              </Popover>
            ))}
        </div>
      </div>
    </div>
  )
}

export default memo(VendorInfoItem)
