import { FormInstance, Row, Select as AntSelect, Switch } from 'antd'
// import FormItem from 'antd/lib/form/FormItem'
import FormItem from 'antd/es/form/FormItem'

import { Select } from 'src/shared/components/select/select'
import { TextField } from 'src/shared/components/textfield/TextField'
import styles from './styles.module.scss'
import { FormItemField } from '../FormItemField'
import { Wrapper } from '../Wrapper'
import { DefaultOptionType } from 'antd/lib/select'
import classNames from 'classnames'
import { useGetDepartmentListQuery } from 'src/features/Settings/core/http/VendorManagementApi'
import {
  commonValidator,
  maxLengthValidator25,
  minLengthValidator,
  requiredValidator,
} from '../../../../../../../helpers/validation'

const { Option } = AntSelect

interface IDetailInfo {
  form: FormInstance
  view?: boolean
  create?: boolean
  setAgentStatus?: React.Dispatch<React.SetStateAction<boolean>>
  agentStatus?: boolean
}

const DetailInfo = ({ form, view, create = false, setAgentStatus, agentStatus }: IDetailInfo) => {
  const { data: departmentList = { items: [] } } = useGetDepartmentListQuery('')

  const isEmptydepartmentList = departmentList.items.length === 0

  const statusButton = (
    <div className={classNames(styles['switch-wrapper'], styles['status-togle'])}>
      Status
      <Switch
        defaultChecked={agentStatus}
        checked={agentStatus}
        disabled={view}
        onChange={(isChecked) => setAgentStatus!(isChecked)}
      ></Switch>
    </div>
  )

  return (
    <Wrapper
      title='Detail Info'
      statusButton={create ? statusButton : null}
      className={styles['wrapper-detail-info']}
    >
      <Row justify='space-between'>
        <FormItem
          label='Added by'
          className={styles['form-item-inp']}
          name={['createdByUser', 'name']}
        >
          <TextField disabled defaultValue='Lorna Muller' />
        </FormItem>
        <FormItem label='Date/Time created' className={styles['form-item-inp']} name='createdAt'>
          <TextField disabled />
        </FormItem>
      </Row>

      <Row justify='space-between'>
        <FormItemField className={styles['form-item-inp']} name='name' label='Name' view={view} />
        <FormItemSelect
          placeholder='Select contact type'
          form={form}
          name='contactType'
          label='Contact Type'
          view={view}
          options={[
            { id: 'COMMERCIAL', name: 'Commercial' },
            { id: 'NON_COMMERCIAL', name: 'Non-commercial' },
          ]}
        />
      </Row>

      <Row justify='space-between'>
        <FormItemSelect
          placeholder='Select department'
          form={form}
          name='department'
          label='Department'
          view={view || isEmptydepartmentList}
          options={departmentList.items}
        />

        <FormItem
          rules={[
            { required: true, validator: requiredValidator('Position') },
            { validator: maxLengthValidator25 },
            { validator: minLengthValidator(3, 'Position') },
          ]}
          name='position'
          label='Position'
          className={classNames(styles['form-item-inp'])}
        >
          <TextField disabled={view} placeholder='Enter Position' />
        </FormItem>
      </Row>
    </Wrapper>
  )
}
export default DetailInfo

interface IFromItemSelect {
  name: string
  label: string
  form: FormInstance
  view?: boolean
  options: { id: number | string; name: string }[]
  disabled?: boolean
  className?: string
  onChange?: (value: string) => void
  placeholder?: string
}

export const FormItemSelect = ({
  name,
  label,
  form,
  options = [],
  disabled = false,
  view = false,
  className = '',
  onChange,
  placeholder,
}: IFromItemSelect) => {
  const handleChange = (obj: DefaultOptionType) => {
    form.setFieldValue(name, obj?.value)
    onChange && onChange(obj?.value as string)
  }

  return (
    <FormItem
      name={name}
      label={label}
      rules={[{ required: true, message: 'Field required' }]}
      className={classNames(styles['form-item-inp'], className)}
    >
      <Select
        placeholder={placeholder}
        disabled={disabled}
        view={view}
        onChange={handleChange}
        allowClear
      >
        {options.map(({ id, name }) => {
          return (
            <Option key={id} value={id}>
              {name}
            </Option>
          )
        })}
      </Select>
    </FormItem>
  )
}
