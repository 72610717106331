import { memo } from 'react'
import { Space, Tabs } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { FileManagementTabsEnum } from './index.d'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { DownloadIcon, EditIcon, ShareIcon } from 'src/assets/svg'
import { DetailInfo } from '../DetailInfo'
import { useGetFileInfoQuery } from '../../../../../core/http/FileManagementApi'
import { saveAs } from 'file-saver'
import { Content } from '../Content'
import usePermissions from '../../../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../../models/IPermissions'
import WithPermissionDeniedContainer from '../../../../../../../shared/components/withPermissionDenied'

interface IPropsOperations {
  canEdit: boolean
  canExport: boolean
}

const Operations = memo(({ canEdit, canExport }: IPropsOperations) => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()

  const { data } = useGetFileInfoQuery(id, { skip: !id })

  const saveFile = () => {
    if (data) {
      saveAs(data.link, data.name)
    }
  }

  return (
    <Space size={10}>
      <IconButton color='orange' type='default' icon={<ShareIcon />} />
      {canExport && (
        <IconButton color='blue' type='default' icon={<DownloadIcon />} onClick={saveFile} />
      )}
      {canEdit && (
        <IconButton
          color='orange'
          type='default'
          icon={<EditIcon />}
          onClick={() => navigate(`/settings/file-management/edit/${id}`)}
        />
      )}
    </Space>
  )
})

export const FileManagementTabs = () => {
  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const [filesId] = getModulesIdsByNames([IPermissionsNames['Files list']])
  const [contentsId] = getModulesIdsByNames([IPermissionsNames['Content']])

  const demandPermissions = [
    IPermissionsActions.VIEW,
    IPermissionsActions.EDIT,
    IPermissionsActions.EXPORT,
  ]

  const [canViewFiles, _, canExportFile] = canPerformAction(filesId, demandPermissions)
  const [canViewContent, canEditFile] = canPerformAction(contentsId, demandPermissions)

  const items = [
    {
      label: FileManagementTabsEnum.FILE_INFORMATION,
      key: FileManagementTabsEnum.FILE_INFORMATION,
      children: (
        <WithPermissionDeniedContainer isAllowedView={canViewFiles}>
          <DetailInfo />
        </WithPermissionDeniedContainer>
      ),
    },
    {
      label: FileManagementTabsEnum.CONTENT,
      key: FileManagementTabsEnum.CONTENT,
      children: (
        <WithPermissionDeniedContainer isAllowedView={canViewContent}>
          <Content />
        </WithPermissionDeniedContainer>
      ),
    },
  ]

  return (
    <Tabs
      destroyInactiveTabPane
      items={items}
      tabBarExtraContent={<Operations canEdit={canEditFile} canExport={canExportFile} />}
    />
  )
}
