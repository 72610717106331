import React, { useCallback, useContext, useState } from 'react'
import { RightModalContext } from '../../../../../Modals'
import { useLazyExportSegmentsTableQuery } from '../../../../core/http/SegmentsApi'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ErrorNode, showConfirmMessage } from '../../../../../../shared/api/errorHandler'
import { useGetQueryParams } from '../../../../../../shared/hooks/table/useGetQueryParams'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import { useTableExport } from '../../../../../../shared/hooks/table/useTableExport'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import useTable from '../../../../../../shared/hooks/table/useTable'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../shared/components/popup/Popup'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import { ColumnsType } from 'antd/es/table'
import { TableActionIcon } from '../../../../../../assets/svg'
import { Table } from 'antd'
import styles from '../../styles.module.scss'
import {
  useDeleteClosedGroupByIdMutation,
  useGetClosedGroupsListQuery,
} from '../../../../core/http/CustomersApi'
import { IClosedGroup, IClosedGroupCustomer } from '../../../../models/ICustomer'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'

export const CCGTable = () => {
  const { onOpen } = useContext(RightModalContext)

  const [delClosed, delClosedResp] = useDeleteClosedGroupByIdMutation()

  useNotification(NOTIFICATION_TYPES.success, delClosedResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, delClosedResp.isError, delClosedResp.error as ErrorNode)

  const { queryParams } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'id',
  })

  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data, isFetching } = useGetClosedGroupsListQuery(
    { ...tableConf },
    { skip: Object.keys(tableConf)?.length < 4 }
  )

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange } = useTable<IClosedGroup>({
    total: data?.totalCount,
    setTableConf,
  })

  const tableActionsPopup = useCallback(
    (id: string): IPopupListItems[] => [
      {
        text: 'View',
        shouldDisplay: true,
        onClick: () =>
          onOpen(RIGHT_MODALS.SALES.CUSTOMER_CLOSED_GROUP, {
            title: 'View Closed Group',
            mode: 'view',
            id,
          }),
      },
      {
        text: 'Edit',
        shouldDisplay: true,
        onClick: () =>
          onOpen(RIGHT_MODALS.SALES.CUSTOMER_CLOSED_GROUP, {
            title: 'Edit Closed Group',
            mode: 'edit',
            id,
          }),
      },
      {
        text: 'Delete',
        withDivider: true,
        color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        shouldDisplay: true,
        onClick: () =>
          showConfirmMessage('This closed customer group will be deleted', () => delClosed(id)),
      },
    ],
    [delClosed, onOpen]
  )

  const columns: ColumnsType<IClosedGroup> = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '25%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Customers',
      dataIndex: 'customers',
      width: '45%',
      render: (customers: Array<IClosedGroupCustomer>) => (
        <div className={styles.customersList}>
          {customers.map((cust, i) => (
            <RecursivelyReplaceCharacters key={cust?.id}>
              <div>
                <>{cust?.fullName}</>
                {i !== customers.length - 1 && ','}
              </div>
            </RecursivelyReplaceCharacters>
          ))}
        </div>
      ),
    },
    {
      title: 'Customers Count',
      dataIndex: 'count',
      width: '25%',
      align: 'center',
      render: (_, row) => (
        <RecursivelyReplaceCharacters>{row?.customers?.length}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      width: '1%',
      key: 'edit',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row?.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return (
    <Table
      locale={emptyTableConf}
      pagination={pagination}
      onChange={handleTableChange}
      className={styles.table}
      scroll={{ x: 1000 }}
      loading={isFetching}
      columns={columns}
      dataSource={data?.items}
      rowSelection={{ type: 'checkbox' }}
      rowKey={(record) => record?.id}
    />
  )
}
