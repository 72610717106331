import { FormInstance, Form, Select } from 'antd'
import FormItem from 'antd/lib/form/FormItem'

import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { PhoneInputWithCountry } from 'src/shared/components/PhoneInput'
import { Wrapper } from '../Wrapper'

// stylees
import styles from './styles.module.scss'

import {
  AddIcon,
  BucketIcon,
  CopyIcon,
  DoubleCheckMarkIcon,
  PhoneDarkBlueIcon,
  SendPlaneIcon,
  TelegramIcon,
  TickIcon,
  ViberIcon,
  WhatsappIcon,
} from 'src/assets/svg'

// helpers
import * as V from 'src/helpers/validation'
import * as I from 'src/features/salesNetwork/models/IAgent'
import { AGENT_TYPE_ID_ENUM } from 'src/features/Settings/models/IAgentType'
import { useContext, useEffect, useState } from 'react'
import { RightModalContext } from 'src/features/Modals'
import { useParams } from 'react-router'
import {
  useGetAgentByIdQuery,
  useLazyGetAgentByIdQuery,
  useLazyGetVerificationTimerQuery,
} from '../../core/http/AgentManagement'
import { getSecondsFromDateInFuture } from '../../helpers'
import { RIGHT_MODALS } from 'src/helpers/contants'
import { onCopyText } from 'src/helpers/utils'

export const USER_IDENTIFIERS_OPTIONS = [
  {
    identifier: null,
    agentIdentifierTypeId: I.IDENTIFIERS_TYPE_ENUM.MOBILE,
    agentIdentifierType: I.IDENTIFIERS_TYPE_NEW.MOBILE.type,
    icon: <PhoneDarkBlueIcon />,
    active: true,
  },
  {
    identifier: null,
    agentIdentifierTypeId: I.IDENTIFIERS_TYPE_ENUM.WHATSAPP,
    agentIdentifierType: I.IDENTIFIERS_TYPE_NEW.WHATSAPP.type,
    icon: <WhatsappIcon />,
    active: true,
  },
  {
    identifier: null,
    agentIdentifierTypeId: I.IDENTIFIERS_TYPE_ENUM.TELEGRAM,
    agentIdentifierType: I.IDENTIFIERS_TYPE_NEW.TELEGRAM.type,
    icon: <TelegramIcon />,
    active: true,
  },
  {
    identifier: null,
    agentIdentifierTypeId: I.IDENTIFIERS_TYPE_ENUM.VIBER,
    agentIdentifierType: I.IDENTIFIERS_TYPE_NEW.VIBER.type,
    icon: <ViberIcon />,
    active: true,
  },
]

const REQUIRED_OBJ_FIELD = { required: true, message: 'Field required' }

const rulesObj = {
  [I.IDENTIFIERS_TYPE_NEW.MOBILE.type]: [
    REQUIRED_OBJ_FIELD,
    { validator: V.mobileNumberLengthFrom8To14 },
    { validator: V.mobileNumberValidator },
  ],
  [I.IDENTIFIERS_TYPE_NEW.WHATSAPP.type]: [
    REQUIRED_OBJ_FIELD,
    { validator: V.mobileNumberLengthFrom8To14 },
  ],
  [I.IDENTIFIERS_TYPE_NEW.TELEGRAM.type]: [
    REQUIRED_OBJ_FIELD,
    { validator: V.mobileNumberLengthFrom8To14 },
  ],
  [I.IDENTIFIERS_TYPE_NEW.VIBER.type]: [
    REQUIRED_OBJ_FIELD,
    { validator: V.mobileNumberLengthFrom8To14 },
  ],
}

type rulesType = keyof typeof rulesObj

interface IContactInformation {
  view?: boolean
  form: FormInstance
  onIdentifierDelete?: (index: string) => void
}

const UserIdentifiers = ({ view = false, form, onIdentifierDelete }: IContactInformation) => {
  return (
    <Wrapper title='User Identifiers' className={styles['wrapper-user-identifiers']}>
      <Form.List name='agentIdentifiers'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} className={styles.identifierItemContainer}>
                <IdentifierItem
                  form={form}
                  name={name}
                  view={view as boolean}
                  remove={remove}
                  handleDeleteIdentifier={onIdentifierDelete}
                  {...restField}
                />
              </div>
            ))}
            {!view && (
              <div style={{ display: 'flex' }}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const agentTypeId = getFieldValue('agentTypeId')

                    return (
                      <Form.Item style={{ flexBasis: '29.5%' }}>
                        <IconButton
                          icon={<AddIcon />}
                          color='orange'
                          block
                          onClick={() => add()}
                          disabled={
                            agentTypeId === AGENT_TYPE_ID_ENUM.RESELLER && fields.length === 1
                          }
                        />
                      </Form.Item>
                    )
                  }}
                </Form.Item>
              </div>
            )}
          </>
        )}
      </Form.List>
    </Wrapper>
  )
}
export default UserIdentifiers

const IdentifierItem = ({
  name,
  view,
  remove,
  form,
  handleDeleteIdentifier,
  ...restField
}: {
  name: number
  view: boolean
  remove: (index: number | number[]) => void
  handleDeleteIdentifier?: (index: string) => void
  form: FormInstance
}) => {
  const { onOpen } = useContext(RightModalContext)
  const { agentId } = useParams()
  const { data: dataAgent } = useGetAgentByIdQuery(agentId, { skip: !agentId })

  const [getVerificationTimer, dVerification] = useLazyGetVerificationTimerQuery()
  const [getAgentById] = useLazyGetAgentByIdQuery()

  const [timeout, setTimeout] = useState(0)

  useEffect(() => {
    if (!view) return
    const { type, value, isOtpSent } = form.getFieldValue(['agentIdentifiers', name])

    isOtpSent &&
      getVerificationTimer({
        type,
        value,
      })
  }, [form, view])

  useEffect(() => {
    if (!dVerification.data || !view) return
    const { timeoutAt } = dVerification.data

    const timeoutLocal = getSecondsFromDateInFuture(timeoutAt)

    const countdown = setInterval(() => {
      setTimeout(Math.abs(timeoutLocal) - 1)
    }, 1000)

    if (timeoutLocal >= 0) {
      clearTimeout(countdown)
      getAgentById(agentId)
    }

    return () => clearInterval(countdown)
  }, [dVerification.data, timeout])

  const onVerifyIdentifiers = (selectedType: string, selectedValue: string) => () => {
    onOpen(RIGHT_MODALS.SALES.IDENTIFIER_VERIFICATION, {
      title: 'User Identifiers Verification',
      selectedType,
      selectedValue,
      agentId,
    })
  }

  return (
    <>
      <FormItem
        {...restField}
        name={[name, 'type']}
        style={{ flexBasis: '30%', minWidth: 256, marginRight: '24px' }}
        rules={[{ required: true, validator: V.requiredValidator('Type') }]}
      >
        <Select
          allowClear
          disabled={view}
          placeholder='Select User Identifier'
          onChange={() => {
            form.resetFields([['agentIdentifiers', name, 'value']])
          }}
        >
          {USER_IDENTIFIERS_OPTIONS.map(({ agentIdentifierType: type, icon }) => {
            return (
              <Select.Option key={type} value={type}>
                <div className={styles.wrapperContentOption}>
                  <div className={styles.wrapperIcon}>{icon}</div>
                  <div>
                    {I.IDENTIFIERS_TYPE_NEW[type as keyof typeof I.IDENTIFIERS_TYPE_NEW].name}
                  </div>
                </div>
              </Select.Option>
            )
          })}
        </Select>
      </FormItem>

      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const selectedType = getFieldValue(['agentIdentifiers', name, 'type']) as rulesType

          return (
            <Form.Item
              {...restField}
              name={[name, 'value']}
              style={{ flexBasis: '65%', marginRight: '24px' }}
              rules={rulesObj[selectedType]}
            >
              <PhoneInputWithCountry
                fieldName={['agentIdentifiers', name, 'value']}
                form={form}
                view={view}
              />
            </Form.Item>
          )
        }}
      </FormItem>

      {view && (
        <div className={styles['wrapper-icons']}>
          <div className={styles['wrapper-icon']}>
            <IconButton
              onClick={() => onCopyText(form.getFieldValue(['agentIdentifiers', name, 'value']))}
              slim
              size='large'
              color='orange'
              icon={<CopyIcon />}
              type='default'
            />
          </div>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const selectedType = getFieldValue(['agentIdentifiers', name, 'type'])
              const selectedValue = getFieldValue(['agentIdentifiers', name, 'value'])
              const isOtpSent = getFieldValue(['agentIdentifiers', name, 'isOtpSent'])
              const isVerified = getFieldValue(['agentIdentifiers', name, 'isVerified'])

              if (
                selectedType === I.CONTACT_TYPE_NEW.MOBILE.type ||
                selectedType === I.CONTACT_TYPE_NEW.WHATSAPP.type
              ) {
                return (
                  <div className={styles['wrapper-icon']} style={{ marginLeft: '16px' }}>
                    {!isOtpSent && !isVerified && (
                      <IconButton
                        slim
                        size='large'
                        color='blue'
                        icon={<TickIcon />}
                        type='default'
                        onClick={onVerifyIdentifiers(selectedType, selectedValue)}
                        disabled={
                          selectedType === I.CONTACT_TYPE_NEW.WHATSAPP.type || dataAgent?.isDeleted
                        }
                      />
                    )}

                    {isOtpSent && !isVerified && (
                      <IconButton
                        slim
                        size='large'
                        color='blue'
                        icon={<SendPlaneIcon />}
                        type='primary'
                        onClick={onVerifyIdentifiers(selectedType, selectedValue)}
                        disabled={dataAgent?.isDeleted}
                      />
                    )}

                    {isVerified && (
                      <IconButton
                        slim
                        size='large'
                        color='success'
                        icon={<DoubleCheckMarkIcon />}
                        type='default'
                      />
                    )}
                  </div>
                )
              }
            }}
          </Form.Item>
        </div>
      )}

      {!view && (
        <Form.Item style={{ flexBasis: '3%' }}>
          <div className={styles.bucketIcon}>
            <IconButton
              block
              size='large'
              color='orange'
              icon={<BucketIcon />}
              type='default'
              disabled={view}
              onClick={() => {
                handleDeleteIdentifier?.(form.getFieldValue(['agentIdentifiers', name, 'id']))
                remove(name)
              }}
            />
          </div>
        </Form.Item>
      )}
    </>
  )
}
