import { RcFile, UploadFile } from 'antd/es/upload'
import Modal from 'antd/lib/modal'
import Upload, { UploadProps } from 'antd/lib/upload'
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { IconButton } from '../../iconButton/IconButton'
import { BucketIcon, FullScreenIcon, UploadPhotoIcon } from '../../../../assets/svg'
import { Card, Typography } from 'antd'
import styles from './styles.module.scss'
import './styles2.scss'
import { getBase64, isCompatibleType, isLessThan } from '../helpers'
import userPlaceholder from '../../../../assets/img/UserLogoPlaceholder.png'
import productPlaceholder from '../../../../assets/img/ProductPlaceholderPicture.png'
import bankPlaceholder from '../../../../assets/img/BankLogoPlaceholder.png'
import FormItem from 'antd/es/form/FormItem'
import { FormInstance } from 'antd/es/form/Form'
import classNames from 'classnames'

import ImgCrop from 'antd-img-crop'

interface IUploaderPhotos {
  onChange?: (photo: UploadFile[]) => void
  setToDeleteLogo?: Dispatch<SetStateAction<boolean>>
  title?: string
  view?: boolean
  defaultPreviewImage?: string
  form: FormInstance
  isBig?: boolean
  isProductPreview?: boolean
  isPersonPreview?: boolean
  isBankPreview?: boolean
}

export const UploaderPhotos2 = ({
  form,
  onChange,
  setToDeleteLogo,
  title = 'Upload photo',
  view = true,
  defaultPreviewImage = '',
  isBig = false,
  isProductPreview = false,
  isPersonPreview = false,
  isBankPreview = false,
}: IUploaderPhotos) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState<null | string>(() => defaultPreviewImage)
  const [previewTitle, setPreviewTitle] = useState('')
  const [uploadedFile, setUploadedFile] = useState<UploadFile[]>([])

  useEffect(() => {
    setPreviewImage(defaultPreviewImage ? defaultPreviewImage : '')
  }, [defaultPreviewImage])

  const isEmptyUploadedFile = uploadedFile.length === 0
  const handleCancel = () => setPreviewOpen(false)

  const uploadProps: UploadProps = {
    customRequest: (info) => {
      if (typeof info.file !== 'string' && info.onError && info.onSuccess) {
        if (!isLessThan(info.file.size)) {
          const err = new Error("File size can't be grater than 4MB!")
          form.setFields([{ name: 'logo', errors: ["File size can't be grater than 4MB!"] }])

          return info.onError(err)
        }

        if (!isCompatibleType(info.file.type)) {
          const err = new Error('File type is not compatible!')
          form.setFields([{ name: 'logo', errors: ['File type is not compatible!'] }])

          return info.onError(err)
        }

        return info.onSuccess('Ok')
      }
    },
    onChange: (info) => {
      setUploadedFile(info.fileList)
      if (info.file.status !== 'uploading') {
        setUploadedFile(info.fileList)
        onChange && onChange(info.fileList)
      }
    },
    onPreview: async (file: UploadFile) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile)
      }

      if (isCompatibleType(file.type || '')) {
        setPreviewImage(file.url || (file.preview as string))
        setPreviewOpen(true)
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
      }
    },
    defaultFileList: uploadedFile,
    maxCount: 1,
  }

  const onDeletePhoto = () => {
    setUploadedFile([])
    setPreviewImage(null)
    if (setToDeleteLogo) {
      setToDeleteLogo(true)
    }
    form.setFields([{ name: 'logo', errors: [] }])
  }

  const renderUploadButton = (
    <IconButton className={styles['upload-btn']} color='orange' icon={<UploadPhotoIcon />} block>
      {`${uploadedFile.length <= 0 ? 'Upload' : 'Change'} ${title}`}
    </IconButton>
  )
  const renderText = (
    <Typography className={styles.uploaderHint}>
      You can upload a JPG, GIF or PNG file (max file size is 4 Mb)
    </Typography>
  )

  const previewPlaceholder = isPersonPreview
    ? userPlaceholder
    : isProductPreview
    ? productPlaceholder
    : isBankPreview
    ? bankPlaceholder
    : ''
  const heightUploader =
    previewImage || previewPlaceholder || uploadedFile.length !== 0
      ? 'max-content'
      : isBig
      ? '100%'
      : '100%'
  const shouldDisplayActions = isEmptyUploadedFile || !previewImage

  const ShowPreviewImageViewMode = () => {
    return (
      <UploadedImage
        setPreviewOpen={setPreviewOpen}
        url={previewImage}
        onDeletePhoto={onDeletePhoto}
        view={view}
        isBig={isBig}
        shouldDisplayActions={shouldDisplayActions}
        previewPlaceholder={previewPlaceholder}
      />
    )
  }

  const ShowPreviewImageEditMode = () => {
    return (
      <UploadedImage
        setPreviewOpen={setPreviewOpen}
        url={previewImage}
        onDeletePhoto={onDeletePhoto}
        view={view}
        isBig={isBig}
        previewPlaceholder={previewPlaceholder}
        shouldDisplayActions={shouldDisplayActions}
      />
    )
  }

  const ShowUploadImageEditMode = () => {
    return (
      <>
        <ImgCrop rotationSlider modalClassName='modal-crop'>
          <Upload
            itemRender={() => (
              <UploadedImage
                setPreviewOpen={setPreviewOpen}
                url={url}
                onDeletePhoto={onDeletePhoto}
                view={view}
                isBig={isBig}
                previewPlaceholder={previewPlaceholder}
                shouldDisplayActions={shouldDisplayActions}
              />
            )}
            {...uploadProps}
            listType='picture'
          >
            {!view && isEmptyUploadedFile && renderUploadButton}
          </Upload>
        </ImgCrop>
      </>
    )
  }

  const url = !isEmptyUploadedFile ? URL.createObjectURL(uploadedFile[0].originFileObj!) : ''

  return (
    <div className='wrapper-uploader'>
      <FormItem name='logo'>
        <Card
          className={styles['uploader']}
          style={{
            maxWidth: isBig ? '100%' : 345,
            width: '100%',
            height: heightUploader,
          }}
        >
          <div className={styles.uploaderTitle}>{title}</div>
          {view && previewPlaceholder && !previewImage && (
            <img
              className={classNames(styles['download-img'], { [styles.big]: isBig })}
              src={defaultPreviewImage || previewPlaceholder}
            />
          )}

          {!view && renderText}

          {view && previewImage && <ShowPreviewImageViewMode />}

          {!view && previewImage && <ShowPreviewImageEditMode />}

          {!view && !previewImage && <ShowUploadImageEditMode />}
        </Card>
      </FormItem>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        zIndex={1100}
      >
        <img alt='example' style={{ width: '100%' }} src={previewImage || url} />
      </Modal>
    </div>
  )
}

const UploadedImage = ({
  setPreviewOpen,
  onDeletePhoto,
  url,
  isBig = false,
  view = true,
  previewPlaceholder,
  shouldDisplayActions,
}: {
  file?: UploadFile
  setPreviewOpen: React.Dispatch<React.SetStateAction<boolean>>
  onDeletePhoto: () => void
  url: string | null
  view?: boolean
  isBig?: boolean
  previewPlaceholder?: string
  shouldDisplayActions: boolean
}) => {
  const onClickFullscreen = () => setPreviewOpen(true)

  if (!url && previewPlaceholder) {
    return null
  }

  return (
    <div className={styles['wrapper-download-img']}>
      {!view && shouldDisplayActions && (
        <div className={styles['wrapper-photo-icons']}>
          <Icon onClick={onClickFullscreen} icon={<FullScreenIcon />} />
          <Icon onClick={onDeletePhoto} icon={<BucketIcon />} />
        </div>
      )}
      <img
        className={classNames(styles['download-img'], { [styles.big]: isBig })}
        src={url as string}
      />
    </div>
  )
}

const Icon = ({ icon, onClick }: { icon: ReactNode; onClick?: () => void }) => (
  <div className={styles['wrapper-icon']}>
    <IconButton slim color='orange' icon={icon} onClick={onClick} type='default' size='large' />
  </div>
)

export const useUploaderPhoto = () => {
  const [isWillDeletePreviewImage, setIsWillDeletePreviewImage] = useState(false)
  const [willPreviewImage, setWillPreviewImage] = useState<null | string>(null)

  const onCancelUploaderPhoto = () => {
    setIsWillDeletePreviewImage(false)
    setWillPreviewImage(null)
  }

  return {
    isWillDeletePreviewImage,
    setIsWillDeletePreviewImage,
    willPreviewImage,
    setWillPreviewImage,
    onCancelUploaderPhoto,
  }
}
