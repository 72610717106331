import React, { memo } from 'react'
import { Row, Space } from 'antd'

import styles from './styles.module.scss'
import { Button } from 'src/shared/components/button/Button'

interface IProps {
  onPressCancelChanges: () => void
  onPressSaveChanges?: () => void
  onPressDelete?: () => void
  isLoading: boolean
  submitButtonText?: string | boolean
  cancelButtonText?: string
  deleteButtonText?: string
  isViewMode?: boolean
  submitButtonDisabled?: boolean
}

const FooterActions = ({
  onPressCancelChanges,
  onPressSaveChanges,
  isLoading,
  submitButtonText = 'Save Changes',
  cancelButtonText = 'Cancel',
  submitButtonDisabled,
}: IProps) => {
  return (
    <div className={styles.wrapper}>
      <Row className={styles.footer} justify='space-between'>
        <div></div>
        <Row justify='end'>
          <Space size={10}>
            <Button color='blue' size='middle' onClick={onPressCancelChanges}>
              {cancelButtonText}
            </Button>
            {onPressSaveChanges && (
              <Button
                htmlType='submit'
                size='middle'
                type='primary'
                onClick={onPressSaveChanges}
                disabled={isLoading || submitButtonDisabled}
              >
                {submitButtonText}
              </Button>
            )}
          </Space>
        </Row>
      </Row>
    </div>
  )
}

export default memo(FooterActions)
