import React, { useCallback } from 'react'
import moment from 'moment/moment'
import { IAttachmentFile, IInternalChatMessageFile } from '../../../../models'

import AttachmentFile from '../File'
import { InfiniteScrollComponent } from '../../../../../../shared/components/infiniteScoll/InfiniteScroll'
import { IPopupListItems } from '../../../../../../shared/components/popup/Popup'
import styles from './styles.module.scss'
import { NotFoundPage } from '../../../../../NotFoundPage'
import { DownloadIcon } from '../../../../../../assets/svg'
import { internalChatsApi } from '../../../../core/http/InternalChatsApi'
import { useAppDispatch } from '../../../../../../redux'
import { downloadFileThroughATag } from '../../../../../../helpers/utils'

interface IProps {
  files: Array<IInternalChatMessageFile>
  totalCount: number
  loadMoreData: () => void
}

const FilesList = ({ files, totalCount, loadMoreData }: IProps) => {
  const dispatch = useAppDispatch()

  // eslint-disable-next-line @typescript-eslint/ban-types
  const onPressDownloadFile = async (id: string, fileName: string, callback?: Function) => {
    dispatch(internalChatsApi.endpoints.getInternalChatLinkToDownloadFile.initiate(id))
      .then(({ data }) => {
        if (data?.link) {
          downloadFileThroughATag(data?.link, fileName)
        }
      })
      .then(() => {
        if (callback) {
          callback()
        }
      })
  }

  const tableActionsPopup = useCallback(
    (
      id: string,
      file: IAttachmentFile,
      // eslint-disable-next-line @typescript-eslint/ban-types
      callback?: Function
    ): IPopupListItems[] => {
      return [
        {
          text: 'Download',
          shouldDisplay: true,
          icon: <DownloadIcon />,
          onClick: () => onPressDownloadFile(id, file.name, callback),
        },
      ]
    },
    [onPressDownloadFile]
  )

  /** This condition is necessary for the design of notifications delimiter by date in the list */
  const isFirstNotificationInAMonth = useCallback(
    (createdAt: string, i: number) => {
      return (
        i === 0 ||
        (files[i + 1] && !moment(files[i + 1].createdAt).isSame(moment(createdAt), 'month'))
      )
    },
    [files]
  )

  return (
    <div className={styles.filesContainer}>
      {files.length ? (
        <InfiniteScrollComponent
          loadMoreData={loadMoreData}
          dataLength={files.length}
          totalCount={totalCount}
          endMessage=''
          showDivider={false}
        >
          {files.map(({ file, createdAt, fileId }, i) => (
            <AttachmentFile
              key={fileId}
              fileId={fileId}
              file={file}
              tableActionsPopup={tableActionsPopup}
              isFirstNotificationInAMonth={isFirstNotificationInAMonth(createdAt, i)}
              createdAt={createdAt}
            />
          ))}
        </InfiniteScrollComponent>
      ) : (
        <NotFoundPage text='Empty data' />
      )}
    </div>
  )
}

export default FilesList
