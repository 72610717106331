export enum CONTACT_TYPE_ENUM {
  EMAIL_IDENTITY = 'EMAIL_IDENTITY',
  MOBILE_IDENTITY = 'MOBILE_IDENTITY',
  EMAIL = 'EMAIL',
  MOBILE = 'MOBILE',
  SKYPE = 'SKYPE',
  VIBER = 'VIBER',
  WHATSAPP = 'WHATSAPP',
  FACEBOOK = 'FACEBOOK',
  TELEGRAM = 'TELEGRAM',
}
