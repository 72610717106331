export enum AGENT_TYPE_ID_ENUM {
  RESELLER = 1,
  DIRECT = 2,
  INDIRECT = 3,
}

export interface IAgentTypeAgent {
  id: string
  firstName: string
  lastName: string
  avatar: string
  status: boolean
}

export interface IAgentType {
  id: number
  name: string
  agents: IAgentTypeAgent[]
}

export interface IAccountTypeOption {
  id: number
  status: number
  accountType: string
}
