import styles from './styles.module.scss'
import { InfoMessage } from '../../../../../Settings/pages/VendorManagement/VendorManagementContactListModal/Components/InfoMessage'
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { TextFieldSearch } from '../../../../../../shared/components/textFieldSearch/TextFieldSearch'
import { ITableConf } from '../../../../../../shared/models/ITableConf'
import useSearch from '../../../../../Settings/helpers/useSearch'
import { InfiniteScrollComponent } from 'src/shared/components/infiniteScoll/InfiniteScroll'
import { useGetSegmentListQuery } from '../../../../core/http/SegmentsApi'
import { ISegmentTable } from '../../../../models/ISegment'
import { isEmpty, unionBy } from 'lodash'
import { Button } from '../../../../../../shared/components/button/Button'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { ArrowRightIcon } from '../../../../../../assets/svg'
import { Space } from 'antd'
import { RightModalContext } from '../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import {
  useGetRewardPolicyByIdQuery,
  useUpdateRewardPolicyMutation,
} from '../../../../../Settings/core/http/RewardPolicyApi'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../shared/api/errorHandler'

export const SegmentsTargetGroupListModal = () => {
  const {
    onOpen,
    props: { id },
  } = useContext(RightModalContext)
  const [mappedSegments, setMappedSegments] = useState<ISegmentTable[]>([])
  const [isChangeSearch, setIsChangeSearch] = useState(false)
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 10,
    search: '',
  })

  const [updRew, updRewResp] = useUpdateRewardPolicyMutation()
  const { data: rewardPolicy } = useGetRewardPolicyByIdQuery(id, { skip: !id })
  const { data } = useGetSegmentListQuery(tableConf, { skip: isEmpty(rewardPolicy) })

  useNotification(NOTIFICATION_TYPES.success, updRewResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, updRewResp.isError, updRewResp.error as ErrorNode)

  const selectedTargetGroups = useMemo(() => {
    return rewardPolicy?.targetGroups?.map((target) => target.id) || []
  }, [rewardPolicy?.targetGroups])

  const loadMoreData = () => {
    setTableConf((pre) => ({ ...pre, page: pre.page! + 1 }))
  }

  const { handleSearchChange } = useSearch(setTableConf)

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleSearchChange(e)
    setIsChangeSearch(true)
  }

  const onSelectSegment = (segmentId: string) => {
    const body = {
      targetGroups: selectedTargetGroups?.includes(segmentId)
        ? selectedTargetGroups.filter((group) => group !== segmentId)
        : [...selectedTargetGroups, segmentId],
    }
    updRew({ id: id as string, body })
  }

  const onCloseRedirect = () => {
    onOpen(RIGHT_MODALS.SALES.SEGMENT_TARGET_GROUP_LIST, {
      title: 'Select Target Group',
      id,
    })
  }

  useEffect(() => {
    if (isEmpty(data)) return

    if (tableConf.page === 1 || (isChangeSearch && tableConf.search)) {
      setMappedSegments(data?.items)
      setIsChangeSearch(false)
    } else {
      setMappedSegments((p) => unionBy([...p, ...data?.items], 'id'))
    }
    // eslint-disable-next-line
  }, [data])

  return (
    <InfiniteScrollComponent
      loadMoreData={loadMoreData}
      dataLength={mappedSegments.length}
      totalCount={data?.totalCount as number}
      containerStyles={{ width: '100%', padding: '24px 24px 8px 32px' }}
      loaderStyles={{ paddingTop: 24 }}
    >
      <InfoMessage text='Target Groups' isAccount={false} />
      <TextFieldSearch onChange={onSearchChange} className={classNames(styles.search)} />
      {mappedSegments?.map((segment) => {
        const isSelected = selectedTargetGroups.includes(segment.id)
        return (
          <div key={segment.id} className={styles.targetListItem}>
            <div className={styles.targetListItemInfo}>
              <div className={styles.groupName}>{segment?.title}</div>
              <div className={styles.groupPolicy}>
                <span>Group Name:</span>
                <span>{segment?.customersCount}</span>
              </div>
            </div>
            <Space size={13}>
              <div className={styles.selectButton}>
                <Button
                  color='orange'
                  type={isSelected ? 'primary' : 'default'}
                  onClick={(e) => {
                    e.stopPropagation()
                    onSelectSegment(segment.id)
                  }}
                >
                  Select
                </Button>
              </div>
              <div className={styles.redirectButton}>
                <IconButton
                  color='blue'
                  icon={<ArrowRightIcon />}
                  onClick={() =>
                    onOpen(RIGHT_MODALS.SALES.SEGMENT_TARGET_GROUP, {
                      title: 'View Target Group',
                      mode: 'view',
                      id: segment?.id,
                      onCloseRedirect,
                    })
                  }
                />
              </div>
            </Space>
          </div>
        )
      })}
    </InfiniteScrollComponent>
  )
}
