import { IUserDetail } from 'src/features/Settings/models/IUser'
import { PermissionEnum } from 'src/shared/models/Permission'
import { ITicket, ITicketExtended, TICKET_TYPE_ENUM } from '../../models/ITicket'

export enum TicketModeEnum {
  view = 'view',
  edit = 'edit',
}

export const getLocalTicketPermission = (
  ticket: ITicket | ITicketExtended | undefined,
  profile: IUserDetail | undefined
) => {
  const creator = ticket?.createdByUser?.id === profile?.id || ticket?.creator?.id === profile?.id
  const responsible =
    ticket?.responsiblePersonUser?.id === profile?.id || ticket?.assigned?.id === profile?.id

  const ticketType = ticket?.type === TICKET_TYPE_ENUM['ticket']
  const mainTicketType = ticket?.type === TICKET_TYPE_ENUM['main-ticket']
  const subTicketType = ticket?.type === TICKET_TYPE_ENUM['sub-ticket']

  const isReply = responsible
  const isDelete = creator
  const isEdit = responsible || (creator && (ticketType || mainTicketType))
  const isView = responsible || creator
  const isEditDescription = creator && (ticketType || mainTicketType)
  const isChangeStatus = creator
  const isChangeAttachments = creator || (responsible && subTicketType)

  return {
    isReply,
    isDelete,
    isEdit,
    isEditDescription,
    isChangeStatus,
    isChangeAttachments,
    isView,
    responsible,
    creator,
  }
}

export enum PermissionTypeEnum {
  reply = 'reply',
  view = 'view',
  edit = 'edit',
  delete = 'delete',
}

export const verifyUserPermissions = (
  user: IUserDetail | undefined,
  ticket: TicketEntity | undefined,
  permissionType?: boolean
) => {
  if (permissionType) {
    return true
  }

  if (user?.id === ticket?.creator?.id || user?.id === ticket?.createdByUser?.id) {
    return {
      reply: true,
      view: true,
      edit: true,
      delete: true,
    }
  }

  if (user?.id === ticket?.responsiblePersonUser?.id || user?.id === ticket?.assigned?.id) {
    return {
      reply: true,
      view: true,
      edit: false,
      delete: false,
    }
  }

  if (user?.department?.id === ticket?.department?.id) {
    return {
      reply: false,
      view: true,
      edit: false,
      delete: false,
    }
  }

  return false
}
