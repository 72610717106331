import { Table } from 'antd'
import { Wrapper } from '../../../../../../../shared/components/wrapper/Wrapper'
import React, { useState } from 'react'
import { IExternalReport } from '../../../../../../Reports/models/IExternalReport'
import { useGetExternalTransactionsQuery } from '../../../../../../Reports/core/ExternalTransactionApi'
import { useEmptyTable } from '../../../../../../../shared/hooks/table/useEmptyTable'
import { useOnTableChange } from '../../../../../../../shared/hooks/table/useOnTableChange'
import { useTablePagination } from '../../../../../../../shared/hooks/table/useTablePagination'
import { ITableConf, SortingOrderEnum } from '../../../../../../../shared/models/ITableConf'
import { ColumnsType } from 'antd/es/table'
import { formatDateWithSeconds } from '../../../../../../../helpers/utils'
import { formatNumberToLocale } from '../../../../../helpers'
import styles from '../styles.module.scss'
import { DateTableIcon } from '../../../../../../../assets/svg'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

interface IProps {
  phoneNumber: string
}

export const CustomerTransactionsBlock = ({ phoneNumber }: IProps) => {
  const { data: profile } = useGetProfileQuery()

  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 10,
  })

  const { data, isFetching } = useGetExternalTransactionsQuery(
    {
      orderType: SortingOrderEnum.DESC,
      orderField: 'createdAt',
      customerId: phoneNumber,
      ...tableConf,
    },
    { skip: !phoneNumber }
  )

  const emptyTableConf = useEmptyTable()
  const onTableChange = useOnTableChange<IExternalReport>(setTableConf)
  const paginationConf = useTablePagination(data?.totalCount)

  const columns: ColumnsType<IExternalReport> = [
    {
      title: 'Date/Time',
      dataIndex: 'createdAt',
      width: '30%',
      render: (createdAt: IExternalReport['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          {formatDateWithSeconds(createdAt, profile?.calendar)}
        </div>
      ),
    },
    {
      title: 'id',
      dataIndex: 'id',
      width: '30%',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '30%',
      align: 'center',
      render: (amount: IExternalReport['amount']) => <span>{formatNumberToLocale(amount)}</span>,
    },
  ]

  return (
    <Wrapper title='Customer Transactions' className={styles.transTableWrapper}>
      <Table
        columns={columns}
        locale={emptyTableConf}
        onChange={onTableChange}
        className={styles.table}
        pagination={paginationConf}
        dataSource={data?.items}
        loading={isFetching}
        rowKey={(record) => record.id}
        expandable={{
          expandIcon: () => null,
        }}
      />
    </Wrapper>
  )
}
