import { Table } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import emptyAvatarImage from 'src/assets/img/Avatar.png'
import { ColumnsType } from 'antd/es/table'
import { formatNumberToLocale } from '../../../helpers'
import { IAgentSalesNetwork } from '../../../models/IAgentSalesNetworkTable'
import {
  useGetSalesNetworkFiltersListQuery,
  useGetSalesNetworkTableQuery,
  useGetSubAgentsQuery,
} from '../../../core/http/AgentManagement'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ITableConf } from 'src/shared/models/ITableConf'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from 'src/shared/components/popup/Popup'
import { LocationIcon, TableActionIcon } from 'src/assets/svg'
import { ISelectedFilters } from '../../../../../shared/components/filter/Filter'
import { TableHeader } from './TableHeader'
import { AgentManagementDetailViewTabsList } from '../index.d'
import { useEmptyTable } from '../../../../../shared/hooks/table/useEmptyTable'
import { tableExpandedIcon } from '../../../../../helpers/utils'
import { SALES_ENUM } from '../../../../../routes/sales'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { isEmpty } from 'lodash'
import { RecursivelyReplaceCharacters } from '../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../shared/hooks/useLocalStorageGetByKey'

interface IAgentSalesNetworkTable {
  onChangeTab: React.Dispatch<React.SetStateAction<string>>
}

export const AgentSalesNetworkTable = ({ onChangeTab }: IAgentSalesNetworkTable) => {
  const { agentId } = useParams<{ agentId: string }>()
  const navigate = useNavigate()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const handleAgentClick = (agentId: number) => () => {
    navigate(`/sales-network/agent-management/view/${agentId}`)
    onChangeTab(AgentManagementDetailViewTabsList.GENERAL_INFORMATION)
  }

  const tableActionsPopup = useCallback(
    (id: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () =>
            navigate(`${SALES_ENUM.SALES_NETWORK}/view/${id}`, {
              state: {
                mode: 'view',
                activeTab: AgentManagementDetailViewTabsList.GENERAL_INFORMATION,
              },
            }),
        },
        {
          text: 'Edit',
          shouldDisplay: true,
          onClick: () =>
            navigate(`${SALES_ENUM.SALES_NETWORK}/edit/${id}`, {
              state: {
                mode: 'edit',
                activeTab: AgentManagementDetailViewTabsList.GENERAL_INFORMATION,
              },
            }),
        },
        {
          shouldDisplay: true,
          text: 'Delete',
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          withDivider: true,
        },
      ]
    },
    [navigate]
  )

  const columns: ColumnsType<IAgentSalesNetwork> = useMemo(
    () => [
      {
        title: '',
        dataIndex: '',
        width: '1%',
      },
      {
        title: 'Account ID',
        dataIndex: 'id',
        width: '15%',
        sorter: true,
        render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
      },
      {
        title: 'Name',
        dataIndex: 'firstName',
        sorter: true,
        render: (name: IAgentSalesNetwork['firstName'], row) => (
          <RecursivelyReplaceCharacters>
            <div className='table-avatar table-avatar-hover' onClick={handleAgentClick(row.id)}>
              <img
                alt='user-photo'
                src={!isPrivateMode && row?.avatar ? row.avatar : emptyAvatarImage}
                width={40}
                height={40}
              />
              <div>
                {name} {row.lastName}
              </div>
            </div>
          </RecursivelyReplaceCharacters>
        ),
        width: '20%',
      },
      {
        title: 'Agent Level',
        dataIndex: 'agentLevel',
        width: '10%',
        render: (agentLevel: IAgentSalesNetwork['agentLevel']) => (
          <RecursivelyReplaceCharacters>{agentLevel.level}</RecursivelyReplaceCharacters>
        ),
      },
      {
        title: 'Network Size',
        dataIndex: 'networkSize',
        align: 'center',
        width: '10%',
        sorter: true,
        render: (name, row) => (
          <RecursivelyReplaceCharacters>{row.networkSize}</RecursivelyReplaceCharacters>
        ),
      },
      {
        title: 'Location',
        dataIndex: 'location',
        width: '15%',
        render: (_: IAgentSalesNetwork['country'], row) => (
          <RecursivelyReplaceCharacters>
            <div className='table-location'>
              <LocationIcon />
              <div>
                {row.country?.name}, {row.district?.name}
              </div>
            </div>
          </RecursivelyReplaceCharacters>
        ),
        sorter: true,
      },
      {
        title: 'Current Balance',
        dataIndex: 'balance',
        sorter: true,
        render: (_) => (
          <RecursivelyReplaceCharacters>
            <span className='table-currency'>
              {formatNumberToLocale(_, { isCurrency: true, locale: 'en-US' })}
            </span>
          </RecursivelyReplaceCharacters>
        ),
      },
      {
        title: '',
        dataIndex: 'edit',
        key: 'edit',
        width: 1,
        render: (_, row) => (
          <Popup data={tableActionsPopup(row.id)}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        ),
      },
    ],
    [handleAgentClick, tableActionsPopup]
  )
  const { queryParams, queryFields } = useGetQueryParams()

  const [expandedRows, setExpandedRows] = useState<number | undefined>()
  const [mappedAgents, setMappedAgents] = useState<IAgentSalesNetwork[]>([])
  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data = { totalCount: 0, current: 1, items: [] }, isLoading } =
    useGetSalesNetworkTableQuery(
      { ...tableConf, ...filterValues, id: agentId },
      {
        skip: !agentId || isEmpty(tableConf),
      }
    )

  const { data: childrenData, isFetching: isChildLoaded } = useGetSubAgentsQuery(
    {
      ...tableConf,
      ...filterValues,
      id: expandedRows,
    },
    { skip: !expandedRows }
  )

  const { data: filtersList } = useGetSalesNetworkFiltersListQuery(agentId)

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IAgentSalesNetwork>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  const mutateInputData = useCallback((data: IAgentSalesNetwork[]) => {
    return data?.map((agent) => {
      if (agent.networkSize > 0) {
        return { ...agent, children: [] }
      }
      return agent
    })
  }, [])

  useEffect(() => {
    setMappedAgents(mutateInputData(data.items))
  }, [data, mutateInputData])

  useEffect(() => {
    if (mappedAgents.length) {
      const mappedData = [...mappedAgents]

      function setChildrenForMapped(data: IAgentSalesNetwork[]) {
        for (let i = 0; i < data.length; i++) {
          const index = data.findIndex((el) => el.id === expandedRows)
          if (index !== -1) {
            data.splice(index, 1, {
              ...data[index],
              children: mutateInputData(childrenData?.items as IAgentSalesNetwork[]),
            })
          } else if (data[i].children) {
            setChildrenForMapped(data[i].children as IAgentSalesNetwork[])
          }
        }
      }

      setChildrenForMapped(mappedData)
      setMappedAgents(mappedData)
    }
    // eslint-disable-next-line
  }, [childrenData, mutateInputData])

  return (
    <main className='agent-detail-view'>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filtersList}
        agentId={agentId as string}
      />
      <Table
        className='structure expanded'
        locale={emptyTableConf}
        columns={columns}
        loading={isLoading || isChildLoaded}
        onChange={handleTableChange}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.id}
        rowClassName={(record) => (record.isExpanded ? 'expanded' : '')}
        dataSource={mappedAgents}
        scroll={{ x: 1100 }}
        expandable={{
          onExpand: (expanded, record) => {
            record.isExpanded = expanded
            setExpandedRows(record.id)
          },
          expandIcon: (props) => {
            if (props.record.networkSize === 0) return null
            return tableExpandedIcon(props)
          },
          indentSize: 0,
        }}
        pagination={pagination}
      />
    </main>
  )
}
