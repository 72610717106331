import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import styles from '../../../styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { DatePicker } from '../../../../../../../shared/components/datePicker'
import { Select } from 'antd'
import React, { useMemo } from 'react'
import { PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM } from '../../../../../models/IPromotionPolicy'
import { startCase } from 'lodash'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import { maxLengthValidator, requiredValidator } from '../../../../../../../helpers/validation'
import { Toggle } from '../../../../../../../shared/components/toggle/Toggle'
import moment from 'moment/moment'

interface IProps extends IViewMode {
  isEditable?: boolean
}

export const DetailInfoBlock = ({ view, isEditable }: IProps) => {
  const measurementType = useMemo(
    () =>
      (
        Object.keys(PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM) as Array<
          keyof typeof PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM
        >
      ).map((method) => ({
        label: startCase(PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM[method].toLowerCase()),
        value: PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM[method],
      })),
    []
  )

  const isOnceButton = (
    <FormItem name='isOnce' valuePropName='checked'>
      <Toggle text='Use Promotion Once' disabled={view || !isEditable} />
    </FormItem>
  )

  return (
    <Wrapper title='Detail Info' statusButton={isOnceButton}>
      <div className={styles.dblRow}>
        <FormItem name='createdAt' label='Creation Date'>
          <TextField disabled />
        </FormItem>

        <FormItem name={['createdByUser', 'name']} label='Created By'>
          <TextField disabled />
        </FormItem>
      </div>

      <div>
        <FormItem
          name='name'
          label='Policy Name'
          rules={[
            {
              required: true,
              validator: requiredValidator('PolicyTab Name'),
            },
            {
              validator: maxLengthValidator(45),
            },
          ]}
        >
          <TextField disabled={view} placeholder='Enter Policy Name' />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <div className={styles.doubleRow}>
          <FormItem noStyle shouldUpdate>
            {({ setFieldValue }) => (
              <FormItem
                name='start'
                label='Starting Date'
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('Starting Date'),
                  },
                ]}
              >
                <DatePicker
                  showTime
                  disabled={view}
                  onChange={() => {
                    setFieldValue('end', null)
                  }}
                />
              </FormItem>
            )}
          </FormItem>

          <FormItem noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <FormItem
                name='end'
                label='Expiry Date'
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('Expiry Date'),
                  },
                ]}
              >
                <DatePicker
                  showTime
                  disabled={view || !getFieldValue('start')}
                  disabledDate={(current) =>
                    getFieldValue('start')
                      ? current.isBefore(moment(getFieldValue('start')), 'days') ||
                        current.isSame(moment(getFieldValue('start')), 'days')
                      : false
                  }
                />
              </FormItem>
            )}
          </FormItem>
        </div>

        <div>
          <FormItem noStyle shouldUpdate>
            {({ setFields, resetFields }) => (
              <FormItem
                label='Type Of Measurement'
                name='measurementType'
                className='noBottomMargin'
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('Type of measurement'),
                  },
                ]}
              >
                <Select
                  disabled={view || !isEditable}
                  options={measurementType}
                  onChange={() => {
                    setFields([{ name: 'event', value: null }])
                  }}
                  onClear={() => {
                    resetFields(['amount'])
                  }}
                  allowClear
                  placeholder='Select Measurement Type'
                />
              </FormItem>
            )}
          </FormItem>
        </div>
      </div>
    </Wrapper>
  )
}
