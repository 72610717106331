import { Form, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useContext, useEffect } from 'react'
import { RightModalContext } from '../../../../Modals'
import {
  useGetWhatsAppButtonsListQuery,
  useGetWhatsAppNotificationButtonByIdQuery,
  useUpdateWhatsAppNotificationButtonMutation,
} from '../../../core/http/WhatsAppManagerApi'
import styles from './styles.module.scss'
import { isEmpty } from 'lodash'
import { DetailInfo } from './Components/DetailInfo'
import { ButtonsBlock } from './Components/ButtonsBlock'
import { Button } from '../../../../../shared/components/button/Button'
import FormItem from 'antd/es/form/FormItem'
import { RIGHT_MODALS } from '../../../../../helpers/contants'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../shared/api/errorHandler'
import { IWhatsappManagerButton } from '../../../models/IWhatsappManager'

export const WhatsAppNotificationButtonDetailModal = () => {
  const [form] = useForm()

  const {
    onOpen,
    props: { mode, id },
  } = useContext(RightModalContext)

  const { data, isFetching } = useGetWhatsAppNotificationButtonByIdQuery(id)
  const { data: buttonsOptions, isFetching: isFetchingButtons } = useGetWhatsAppButtonsListQuery({})
  const [updBtn, updBtnResp] = useUpdateWhatsAppNotificationButtonMutation()

  const afterSuccess = () => {
    return onOpen(RIGHT_MODALS.SETTINGS.WHATSAPP_NOTIFICATION_BUTTON_DETAIL, {
      title: 'NOTIFICATION BUTTON DETAIL',
      mode: 'view',
      id,
    })
  }

  useNotification(NOTIFICATION_TYPES.success, updBtnResp.isSuccess, null, afterSuccess)
  useNotification(NOTIFICATION_TYPES.error, updBtnResp.isError, updBtnResp.error as ErrorNode)

  const isViewMode = mode === 'view'
  const isLoading = isFetchingButtons || isFetching || updBtnResp.isLoading

  const onFinish = (values: { whatsappButtons: Array<IWhatsappManagerButton> }) => {
    const body = { buttonIds: values.whatsappButtons?.map((btn) => Number(btn.id)) }
    updBtn({ id, body })
  }

  const onEdit = () => {
    if (isViewMode) {
      return onOpen(RIGHT_MODALS.SETTINGS.WHATSAPP_NOTIFICATION_BUTTON_DETAIL, {
        title: 'NOTIFICATION BUTTON DETAIL',
        mode: 'edit',
        id,
      })
    }

    form.submit()
  }

  useEffect(() => {
    if (isEmpty(data)) return

    form.setFieldsValue(data)
  }, [data, form])

  const content = (
    <div className={styles.layout}>
      <Form layout='vertical' form={form} className={styles.form} onFinish={onFinish}>
        <div>
          <DetailInfo />
          <ButtonsBlock view={isViewMode} buttons={buttonsOptions?.items} />
        </div>

        <FormItem className={styles.actionButton}>
          <Button color='orange' type='primary' block onClick={onEdit} disabled={isFetching}>
            {isViewMode ? 'Edit Notification Button' : 'Save Changes'}
          </Button>
        </FormItem>
      </Form>
    </div>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
