import { IByUser } from '../../../shared/models/IByUser'

export interface ITransaction {
  success: boolean
  duration?: number
  topupId: string
}

interface ITransactionData {
  avgTime: number
  transactions: ITransaction[]
}

export enum CONNECTION_DATA_TYPE_ENUM {
  VPN = 'vpn-connection',
  USSD = 'ussd-connection',
  SMSC = 'smsc-connection',
  RECHARGE = 'recharge-connection',
}

interface IConnectionData {
  type: CONNECTION_DATA_TYPE_ENUM
  status: string
  latency: number
}

interface ISystemMonitoringData {
  status: string
  connectionsData: IConnectionData[]
  transactionsData: ITransactionData
}

export interface ISystemMonitoring {
  vendor: IByUser
  data: ISystemMonitoringData
}
