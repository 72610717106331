import InfiniteScroll from 'react-infinite-scroll-component'
import { Divider, Skeleton, Space } from 'antd'
import { InfoMessage } from '../../../VendorManagementContactListModal/Components/InfoMessage'
import styles from '../../styles.module.scss'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { ArrowRightIcon, CrossIcon } from '../../../../../../../assets/svg'
import {
  IWhatsappManagerAction,
  WHATSAPP_BUTTON_MODAL_STEPS,
} from '../../../../../models/IWhatsappManager'
import { ITableResponse } from '../../../../../../../shared/models/ITableResponse'
import { Dispatch, SetStateAction } from 'react'

interface IProps {
  actions: Array<IWhatsappManagerAction>
  data?: ITableResponse<IWhatsappManagerAction>
  loadMoreData: () => void
  setViewStep: (step: WHATSAPP_BUTTON_MODAL_STEPS, id: string | null) => void
  setAction: Dispatch<SetStateAction<IWhatsappManagerAction | null>>
}

export const ActionsList = ({ data, loadMoreData, actions, setViewStep, setAction }: IProps) => {
  return (
    <div id='whatsapp-actions-list' style={{ height: 200 }}>
      <InfiniteScroll
        dataLength={actions.length}
        next={loadMoreData}
        hasMore={actions.length < (data?.totalCount as number)}
        loader={<Skeleton paragraph={{ rows: 1 }} active />}
        endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
        scrollableTarget='whatsapp-actions-list'
      >
        <InfoMessage text='Actions' isAccount={false} />
        <div className={styles.buttonsList}>
          {actions.map((act) => (
            <div className={styles.buttonListItem} key={act.id}>
              <div>
                <div className={styles.buttonName}>{act.description}</div>
                <div className={styles.buttonAction}>{act.id}</div>
              </div>

              <div className={styles.redirectButton}>
                <IconButton
                  color='blue'
                  icon={<ArrowRightIcon />}
                  onClick={() => {
                    setViewStep(WHATSAPP_BUTTON_MODAL_STEPS.VIEW_ACTION, null)
                    setAction(act)
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  )
}
