import { useNavigate } from 'react-router-dom'
import { useGetQueryParams } from '../../../../../../../../shared/hooks/table/useGetQueryParams'
import { ITableConf, SortingOrderEnum } from '../../../../../../../../shared/models/ITableConf'
import React, { useCallback, useState } from 'react'
import { useGetProfileQuery } from '../../../../../../../Profile/core/http/Profile'
import {
  useGetVendorSimCardsQuery,
  useLazyExportVendorSimCardsQuery,
  useUpdateVendorMutation,
} from '../../../../../../core/http/VendorManagementApi'
import { useEmptyTable } from '../../../../../../../../shared/hooks/table/useEmptyTable'
import useTable from '../../../../../../../../shared/hooks/table/useTable'
import { IVendorManagement, IVendorTable } from '../../../../../../models/IVendorManagement'
import { IPopupListItems, Popup } from '../../../../../../../../shared/components/popup/Popup'
import { ColumnsType } from 'antd/es/table'
import { Switch, Table } from 'antd'
import { RecursivelyReplaceCharacters } from '../../../../../../../../shared/components/privateMode'
import { formatDateWithTime } from '../../../../../../../../helpers/utils'
import {
  DateTableIcon,
  PhoneDarkBlueIcon,
  TableActionIcon,
} from '../../../../../../../../assets/svg'
import styles from '../../../../Components/VendorManagementTable/style.module.scss'
import { ISimCard } from '../../../../../../models/ISimCard'
import { IByUser } from '../../../../../../../../shared/models/IByUser'
import { formatPhoneNumber } from '../../../../../../../salesNetwork/helpers'
import { SETTINGS_ENUM } from '../../../../../../../../routes/settings'
import { useParams } from 'react-router'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../../shared/api/errorHandler'
import { useTableExport } from '../../../../../../../../shared/hooks/table/useTableExport'

export const SimCards = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { queryParams } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })

  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data: profile } = useGetProfileQuery()
  const { data, isFetching } = useGetVendorSimCardsQuery(
    { params: { ...tableConf, allVendorsType: false }, venId: id as string },
    { skip: Object.keys(tableConf).length < 4 }
  )
  const [updateVendor, upVenResp] = useUpdateVendorMutation()
  const [exportTable] = useLazyExportVendorSimCardsQuery()

  useTableExport({
    tableConf,
    promise: exportTable,
    promiseParams: { id: id as string },
  })
  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange } = useTable<ISimCard>({
    total: data?.totalCount,
    setTableConf,
  })

  useNotification(NOTIFICATION_TYPES.success, upVenResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, upVenResp.isError, upVenResp.error as ErrorNode)

  const tableActionsPopup = useCallback(
    (simId: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () =>
            navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${id}/sim-card/view/${simId}`),
        },
        {
          text: 'Edit',
          shouldDisplay: true,
          onClick: () =>
            navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${id}/sim-card/edit/${simId}`),
        },
      ]
    },
    [id, navigate]
  )

  const columns: ColumnsType<ISimCard> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Account Id',
      dataIndex: 'id',
      sorter: true,
      width: '25%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Number',
      dataIndex: 'simNumber',
      width: '20%',
      render: (phoneNumber) =>
        phoneNumber && (
          <RecursivelyReplaceCharacters>
            <div className='table-contact-info'>
              <div className='table-contact-info__row mobile'>
                <PhoneDarkBlueIcon />
                <span>{formatPhoneNumber(phoneNumber)}</span>
              </div>
            </div>
          </RecursivelyReplaceCharacters>
        ),
    },
    {
      title: 'Added by',
      dataIndex: 'createdByUser',
      width: '20%',
      sorter: true,
      render: (createdBy: IByUser) => (
        <RecursivelyReplaceCharacters>{createdBy.name}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      width: '20%',
      sorter: true,
      render: (createdAt: IVendorTable['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(createdAt, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: '10%',
      render: (status: IVendorManagement['status'], row) => {
        const fd = new FormData()
        return (
          <Switch
            checked={status}
            onChange={(status) => {
              fd.append('vendor', JSON.stringify({ status }))
              updateVendor({
                id: row.id,
                body: fd,
              })
            }}
            loading={upVenResp.isLoading}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      locale={emptyTableConf}
      onChange={handleTableChange}
      className={styles.table}
      loading={isFetching}
      dataSource={data?.items}
      scroll={{ x: 1000 }}
      pagination={pagination}
      rowSelection={{ type: 'checkbox' }}
      rowKey={(record) => record.id}
    />
  )
}
