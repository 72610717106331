import React, { useMemo } from 'react'
import ChatRoomDetailsMainInfo from './Components/MainInfo'
import ChatroomAttachmentsInfo from './Components/AttachmentsInfo'
import ChatroomReports from './Components/Reports'
import ChatroomSupport from './Components/Support'
import styles from './styles.module.scss'
import { useAppSelector } from '../../../../../../redux'
import { selectExternalChatMessagingSlice } from '../../../../core/store/ExternalChatMessagingSlice'
import { useGetExternalChatByIdQuery } from '../../../../core/http/ExternalChatApi'
import { AttachmentDetail } from './Components/AttachmentDetail'
import { Spin } from 'antd'
import { selectExternalChatChatsSlice } from '../../../../core/store/ExternalChatChatsListSlice'
import { useGetAgentByIdQuery } from '../../../../../salesNetwork/core/http/AgentManagement'
import { ChatroomUserInfo } from './Components/ChatroomUserInfo'

const ChatroomInfo = () => {
  const { userInfoModalType } = useAppSelector(selectExternalChatMessagingSlice)
  const { selectedChatId } = useAppSelector(selectExternalChatChatsSlice)

  const { data } = useGetExternalChatByIdQuery(selectedChatId as number, {
    skip: !selectedChatId,
  })
  const { data: agentData, isFetching: isFetchingAgent } = useGetAgentByIdQuery(
    data?.agent?.id as number,
    { skip: !data?.agent?.id }
  )

  const userName = useMemo(() => {
    if (data?.agent) {
      return data.agent?.companyTitle ?? `${data.agent?.firstName} ${data.agent?.lastName}`
    }

    if (data?.customer) {
      return data.customer?.name
    }

    return data?.messengerProfileName || ''
  }, [data?.agent, data?.customer, data?.messengerProfileName])

  return (
    <div className={styles.externalChatInfo}>
      <Spin spinning={isFetchingAgent}>
        {userInfoModalType === 'Info' ? (
          <>
            <ChatRoomDetailsMainInfo
              fullName={userName}
              logo={data?.agent ? agentData?.avatar : null}
            />
            <ChatroomUserInfo
              agent={data?.agent ? agentData : null}
              customer={data?.customer?.phoneNumber ?? null}
              messengerId={data?.messengerId}
            />
            {data?.agent && agentData && (
              <ChatroomReports
                agentId={agentData?.id}
                agentLevel={agentData?.agentLevel?.level}
                chatId={selectedChatId}
                isArchived={data?.isArchived}
              />
            )}
            <ChatroomSupport />
            <ChatroomAttachmentsInfo />
          </>
        ) : (
          <AttachmentDetail />
        )}
      </Spin>
    </div>
  )
}

export default ChatroomInfo
