import styles from '../styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../shared/components/textfield/TextField'
import { Wrapper } from '../../../../../../shared/components/wrapper/Wrapper'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import { Select, Spin } from 'antd'
import { useMemo } from 'react'
import {
  NOTIFICATION_MESSAGE_ACTION_ENUM,
  NOTIFICATION_MESSAGE_TRANSACTION_STATUS_ENUM,
  RECEIVER_ENUM,
} from '../../../../models/INotificationMessage'
import { maxLengthValidator, requiredValidator } from '../../../../../../helpers/validation'
import { useGetNotificationMessageActionListQuery } from '../../../../core/http/NotificationMessageApi'

interface IProps extends IViewMode {
  onChangeAction: (val: NOTIFICATION_MESSAGE_ACTION_ENUM | null) => void
}

export const DetailInfo = ({ view, onChangeAction }: IProps) => {
  const { data, isFetching } = useGetNotificationMessageActionListQuery()
  const transactionStatus = useMemo(
    () =>
      (
        Object.keys(NOTIFICATION_MESSAGE_TRANSACTION_STATUS_ENUM) as Array<
          keyof typeof NOTIFICATION_MESSAGE_TRANSACTION_STATUS_ENUM
        >
      ).map((method) => ({
        label: NOTIFICATION_MESSAGE_TRANSACTION_STATUS_ENUM[method],
        value: NOTIFICATION_MESSAGE_TRANSACTION_STATUS_ENUM[method],
      })),
    []
  )

  const receiverType = useMemo(
    () =>
      (Object.keys(RECEIVER_ENUM) as Array<keyof typeof RECEIVER_ENUM>).map((method) => ({
        label: RECEIVER_ENUM[method],
        value: RECEIVER_ENUM[method],
      })),
    []
  )

  const content = (
    <Wrapper title='Detail Info'>
      <div className={styles.dblRow}>
        <FormItem name={['createdByUser', 'name']} label='Created By'>
          <TextField disabled />
        </FormItem>

        <FormItem name='createdAt' label='Created At'>
          <TextField disabled />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem
          name='name'
          label='Name'
          rules={[
            { required: true, validator: requiredValidator('Name') },
            { validator: maxLengthValidator(45) },
          ]}
        >
          <TextField disabled={view} placeholder='Enter Name' />
        </FormItem>

        <FormItem
          name='transactionStatus'
          label='Transaction Status'
          rules={[{ required: true, validator: requiredValidator('Transaction Status') }]}
        >
          <Select
            disabled={view}
            allowClear
            placeholder='Select Transaction Status'
            options={transactionStatus}
          />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem
          name='receiver'
          label='Receiver Type'
          rules={[{ required: true, validator: requiredValidator('Receiver Type') }]}
        >
          <Select
            disabled={view}
            allowClear
            placeholder='Select Receiver Type'
            options={receiverType}
          />
        </FormItem>

        <FormItem
          name='action'
          label='Action'
          rules={[{ required: true, validator: requiredValidator('Action') }]}
        >
          <Select
            disabled={view}
            allowClear
            placeholder='Select Action'
            onChange={(val) => onChangeAction(val)}
          >
            {data?.map((action) => (
              <Select key={`not-act-${action}`} value={action}>
                {action}
              </Select>
            ))}
          </Select>
        </FormItem>
      </div>
    </Wrapper>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
