import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { ArrowLeftIcon } from '../../../../../../../assets/svg'
import {
  IWhatsappManagerAction,
  IWhatsappManagerButton,
  WHATSAPP_BUTTON_MODAL_STEPS,
} from '../../../../../models/IWhatsappManager'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'antd/es/form/Form'
import { useUpdateWhatsAppActionMutation } from '../../../../../core/http/WhatsAppManagerApi'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../shared/api/errorHandler'
import { Form, Select, Spin } from 'antd'
import styles from '../../styles.module.scss'
import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { requiredValidator } from '../../../../../../../helpers/validation'
import { Button } from '../../../../../../../shared/components/button/Button'
import { isEmpty } from 'lodash'
import classNames from 'classnames'

interface IProps {
  onChangeStep: (step: WHATSAPP_BUTTON_MODAL_STEPS, id: string | null) => void
  setAction: Dispatch<SetStateAction<IWhatsappManagerAction | null>>
  action: IWhatsappManagerAction | null
}

export const ViewAction = ({ action, onChangeStep, setAction }: IProps) => {
  const [mode, setMode] = useState('view')
  const [form] = useForm()

  const [updAct, updActResp] = useUpdateWhatsAppActionMutation()

  const afterUpdate = () => {
    setMode('view')
  }

  useNotification(NOTIFICATION_TYPES.success, updActResp.isSuccess, null, afterUpdate)
  useNotification(NOTIFICATION_TYPES.error, updActResp.isError, updActResp.error as ErrorNode)

  const isLoading = updActResp.isLoading
  const isViewMode = mode === 'view'

  const onEdit = () => {
    if (isViewMode) {
      return setMode('edit')
    }

    return form.submit()
  }

  const onFinish = (values: IWhatsappManagerAction) => {
    updAct({ id: action?.id as string, body: values })
  }

  useEffect(() => {
    if (isEmpty(action)) return

    form.setFieldsValue(action)
  }, [action, form])

  const content = (
    <div>
      <IconButton
        color='blue'
        icon={<ArrowLeftIcon />}
        onClick={() => {
          onChangeStep(WHATSAPP_BUTTON_MODAL_STEPS.LIST, null)
          setAction(null)
        }}
      />
      <Form layout='vertical' form={form} className={styles.viewButtonForm} onFinish={onFinish}>
        <Wrapper title='Detail Info'>
          <FormItem name='id' label='ID'>
            <TextField disabled />
          </FormItem>

          <FormItem
            name='description'
            label='Description'
            rules={[
              {
                validator: requiredValidator('Description'),
                required: true,
              },
            ]}
            className='noBottomMargin'
          >
            <TextField disabled={isViewMode} placeholder='Enter Description' />
          </FormItem>
        </Wrapper>

        <FormItem className={classNames(styles.actionButton, 'noBottomMargin')}>
          <Button color='orange' type='primary' block onClick={onEdit} disabled={isLoading}>
            {isViewMode ? 'Edit' : 'Save Changes'}
          </Button>
        </FormItem>
      </Form>
    </div>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
