import React from 'react'
import { Col, Row } from 'antd'
import SearchTransaction from '../SearchTransaction'
import { DownloadIcon } from '../../../../../../assets/svg'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { ITransactionLogAll } from '../../../../models/TransactionLog'
import { Moment } from 'moment'
import { RangePicker } from 'src/shared/components/datePicker'
import { Dayjs } from 'dayjs'

interface IProps {
  loading: boolean
  tableData: ITransactionLogAll[] | undefined
  onSearchSelect: React.ChangeEventHandler<HTMLSelectElement>
  onSearchChange: (value: string) => void
  isNeedClear: boolean
  setNeedClear: React.Dispatch<React.SetStateAction<boolean>>
  onDateChange: (value: Moment[] | null) => void | ''
  value?: [Moment, Moment] | [Dayjs, Dayjs]
  clearLogs: () => void
}

const TransactionLogHeader = ({
  tableData = [],
  isNeedClear,
  loading,
  value,
  onDateChange,
  onSearchSelect,
  onSearchChange,
  setNeedClear,
  clearLogs,
}: IProps) => {
  return (
    <>
      <Row align='middle' justify='space-between' style={{ marginBottom: 20 }}>
        <Row>
          <SearchTransaction
            onSearchSelect={onSearchSelect}
            onSearchChange={onSearchChange}
            tableData={tableData}
            isNeedClear={isNeedClear}
            setNeedClear={setNeedClear}
            loading={loading}
            clearLogs={clearLogs}
          />

          {onDateChange && (
            <Col style={{ display: 'flex', gridGap: 20, marginLeft: 16 }}>
              <RangePicker
                style={{ height: '32px' }}
                value={value}
                onChange={(value) => onDateChange(value as Moment[])}
              />
            </Col>
          )}
        </Row>
      </Row>
    </>
  )
}

export default TransactionLogHeader
