import { Moment } from 'moment'
import { IByUser, IByUserWithFullName } from '../../../shared/models/IByUser'

export enum RESTRICTION_TYPE_ENUM {
  TOPUP = 'Topup',
  HAWALA = 'Hawala',
  BILL_PAYMENT = 'Bill payment',
  TICKETING = 'Ticketing',
  CONTACT_US = 'Contact us',
}

export enum CUSTOMER_RESTRICTION_REASON_ENUM {
  NUMBER_NOT_EXIST = 'Number not exist',
  NUMBER_TERMINATED = 'Number terminated',
  SPAM = 'Spam',
  RUDE_BEHAVIOUR = 'Rude behaviour',
  FRAUD = 'Fraud',
  SPY = 'Spy',
  OTHERS = 'Others',
}

export enum AGENT_RESTRICTION_REASON_ENUM {
  BANKRUPT = 'Bankrupt',
  NONCOMPILIANCE = 'Noncompliance',
  RUDE_BEHAVIOUR = 'Rude behaviour',
  FRAUD = 'Fraud',
  OTHERS = 'Others',
}

interface ICustomer {
  id: string
  name: string
  phoneNumber: string
  status: boolean
  createdAt: Date
}

export interface IRestriction {
  id: string
  phoneNumber: string
  reason: string
  description: string
  restrictionTypes: Array<string>
  startAt: Date | string | Moment
  endAt: Date | string | Moment
  createdByUser?: IByUser
  agent?: IRestrictionAgent
  agentIdentifier?: {
    active: boolean
    agentId: string
    id: string
    isVerified: boolean
    type: string
    value: string
  }
  customer?: ICustomer
  createdAt: Date
  updatedAt: Date
  status?: boolean
}

export interface IRestrictionAgent extends Omit<IByUserWithFullName, 'logo'> {
  avatar: string
  companyTitle?: string
}

export interface IRestrictionMutate {
  customerPhoneNumber: string
  reason: CUSTOMER_RESTRICTION_REASON_ENUM
  type: Array<RESTRICTION_TYPE_ENUM>
  startAt?: string
  endAt?: string
  description?: string
  status?: boolean
}

export interface IAgentRestrictionMutate extends Omit<IRestrictionMutate, 'customerPhoneNumber'> {
  agentIdentifierId?: number
  agentId: number
}
