import styles from '../../styles.module.scss'
import { ColumnsType } from 'antd/es/table'
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import emptyAvatarImage from '../../../../../../assets/img/Avatar.png'
import { DatePickerIcon, LocationIcon } from '../../../../../../assets/svg'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { SALES_ENUM } from '../../../../../../routes/sales'
import { Select, Table } from 'antd'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { useOnTableChange } from '../../../../../../shared/hooks/table/useOnTableChange'
import { useGetAgentTypeListQuery } from '../../../../../salesNetwork/core/http/AgentManagement'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { ITableConf } from '../../../../../../shared/models/ITableConf'
import { formatNumberToLocale } from '../../../../../salesNetwork/helpers'
import { DashboardTableWrapper } from '../DashboardTableWrapper'
import { RangePicker } from '../../../../../../shared/components/datePicker'
import { ITableResponse } from '../../../../../../shared/models/ITableResponse'
import { ITopAgent } from '../../../../../salesNetwork/models/IAgent'
import { useTablePagination } from '../../../../../../shared/hooks/table/useTablePagination'
import { nanoid } from 'nanoid'
import { Moment } from 'moment'
import { TRANSACTION_CATEGORY_ENUM } from '../../../../../Finance/models/ITransaction'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IProps {
  data?: ITableResponse<ITopAgent>
  setFilterValues: Dispatch<SetStateAction<ISelectedFilters>>
}

interface IProps {
  onWidgetDateChange?: (value: Moment[] | null) => void
}

export const TopSalesPointTable = ({ data, setFilterValues, onWidgetDateChange }: IProps) => {
  const navigate = useNavigate()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const columns: ColumnsType<ITopAgent> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: ['agent', 'name'],
        render: (name: string, row) => (
          <RecursivelyReplaceCharacters>
            <Link
              className='table-avatar table-avatar-hover'
              to={`${SALES_ENUM.SALES_NETWORK}/view/${row?.agent?.id}`}
            >
              <img
                alt='user-photo'
                src={!isPrivateMode && row?.agent?.avatar ? row?.agent?.avatar : emptyAvatarImage}
                width={40}
                height={40}
              />
              <div>{name}</div>
            </Link>
          </RecursivelyReplaceCharacters>
        ),
        width: '20%',
      },
      {
        title: 'Type',
        dataIndex: 'agent',
        width: '10%',
        render: (agentType: ITopAgent['agent']) => (
          <RecursivelyReplaceCharacters>{agentType?.agentType?.name}</RecursivelyReplaceCharacters>
        ),
      },
      {
        title: 'Region',
        dataIndex: 'location',
        width: '15%',
        render: (_, row) =>
          row?.province?.name &&
          row.district.name && (
            <div className='table-location'>
              <LocationIcon />
              <RecursivelyReplaceCharacters>
                <>
                  {row?.province?.name}, {row?.district?.name}
                </>
              </RecursivelyReplaceCharacters>
            </div>
          ),
      },
      {
        title: 'Sales amount',
        dataIndex: 'amount',
        align: 'center',
        width: '15%',
        render: (salesAmount) => (
          <RecursivelyReplaceCharacters>
            <strong>{formatNumberToLocale(salesAmount)}</strong>
          </RecursivelyReplaceCharacters>
        ),
      },
    ],
    [isPrivateMode]
  )

  const { data: agentTypeList } = useGetAgentTypeListQuery()

  const [, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 5,
  })

  const emptyTableConf = useEmptyTable(() => navigate(SALES_ENUM.NEW_AGENT, { replace: true }))
  const onTableParamsChange = useOnTableChange<ITopAgent>(setTableConf)
  const pagination = useTablePagination(data?.totalCount, false, 5)

  const filters = (
    <>
      <div className={styles.dashboardVendorFilter}>
        <Select
          placeholder='Select Product Type'
          allowClear
          onChange={(val) =>
            setFilterValues((prevState) => ({ ...prevState, transactionCategory: val }))
          }
        >
          <Select.Option value={TRANSACTION_CATEGORY_ENUM.SEND_EMONEY}>
            {TRANSACTION_CATEGORY_ENUM.SEND_EMONEY}
          </Select.Option>
          <Select.Option value={TRANSACTION_CATEGORY_ENUM.PICKUP}>
            {TRANSACTION_CATEGORY_ENUM.PICKUP}
          </Select.Option>
          <Select.Option value={TRANSACTION_CATEGORY_ENUM.TOPUP}>
            {TRANSACTION_CATEGORY_ENUM.TOPUP}
          </Select.Option>
        </Select>
      </div>
      <div className={styles.dashboardVendorFilter}>
        <Select
          placeholder='Select Agent Type'
          allowClear
          onChange={(val) => setFilterValues((prevState) => ({ ...prevState, agentType: val }))}
        >
          {agentTypeList?.items?.map((type) => (
            <Select.Option key={type.id} value={type.name}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className={styles.dateFilter}>
        <RangePicker
          placeholder={['Date range', '']}
          onChange={(val) => onWidgetDateChange && onWidgetDateChange(val as Moment[])}
          suffixIcon={
            <div>
              <DatePickerIcon color='#0193EB' />
            </div>
          }
        />
      </div>
    </>
  )

  return (
    <div className={styles.fixedTableHeight}>
      <DashboardTableWrapper
        title='Top Sales Point'
        infoText='Top Sales Point Table'
        filters={filters}
      >
        <Table
          columns={columns}
          locale={emptyTableConf}
          onChange={onTableParamsChange}
          className={styles.topAgentsTable}
          dataSource={data?.items}
          rowKey={() => nanoid()}
          pagination={pagination}
        />
      </DashboardTableWrapper>
    </div>
  )
}
