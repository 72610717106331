import React, { memo, useContext } from 'react'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import emptyAvatar from 'src/assets/img/Avatar.png'

import { InfiniteScrollComponent } from '../../../../shared/components/infiniteScoll/InfiniteScroll'
import { checkIsOnlineStatus, InternalChatContext } from '../InternalChatModal'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { clearMessages, resetRequestDates } from '../../core/store/Messages'
import { IInternalChat } from '../../models'
import UnreadCountBadge from '../../../../shared/components/chat/Components/UnreadCountBadge'
import styles from './styles.module.scss'
import { selectInternalChatChatsState, setOpenedChatId } from '../../core/store/Chats'
import { internalMessagingApi } from '../../core/http/InternalMessagingApi'
import { modalViewTypeEnum } from '../../core/models'

interface IProps {
  totalCount: number
  loadMoreData: () => void
  chatsList: Array<IInternalChat>
}

export const MinimizedUsersList = memo(({ totalCount, loadMoreData, chatsList }: IProps) => {
  const dispatch = useAppDispatch()
  const { onSelectChat } = useContext(InternalChatContext)
  const { openedChatId } = useAppSelector(selectInternalChatChatsState)

  const onPressChat = (id: number) => {
    if (openedChatId === id) return
    dispatch(internalMessagingApi.util.resetApiState())
    onSelectChat(modalViewTypeEnum.messaging, id)
    dispatch(setOpenedChatId(id))
    dispatch(resetRequestDates())
    dispatch(clearMessages())
  }

  return (
    <div className={styles.userRightListContainer}>
      <InfiniteScrollComponent
        loadMoreData={loadMoreData}
        dataLength={chatsList.length}
        totalCount={totalCount}
        endMessage=''
      >
        {chatsList.map(({ id, logo, firstName, lastName, unreadedMessagesCount, lastOnlineAt }) => (
          <div
            key={id}
            className={classNames(styles.userRightItemContainer, {
              [styles.onlineContainer]: checkIsOnlineStatus(lastOnlineAt),
            })}
            onClick={() => onPressChat(id)}
          >
            <Tooltip title={`${firstName} ${lastName}`} placement='topRight'>
              <img src={logo ?? emptyAvatar} alt={`${firstName} ${lastName}`} />
            </Tooltip>
            {unreadedMessagesCount > 0 ? (
              <div className={styles.unreadCountContainer}>
                <UnreadCountBadge unreadMessagesCount={unreadedMessagesCount} />
              </div>
            ) : null}
          </div>
        ))}
      </InfiniteScrollComponent>
    </div>
  )
})
