import { FileManagementTabs } from './Components/Tabs'
import styles from './styles.module.scss'

export const FileManagementView = () => {
  return (
    <div className={styles.content}>
      <FileManagementTabs />
    </div>
  )
}
