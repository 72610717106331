import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, notification, Table } from 'antd'
import { useEmptyTable } from '../../../../../../../../shared/hooks/table/useEmptyTable'
import { useGetQueryParams } from '../../../../../../../../shared/hooks/table/useGetQueryParams'
import useTable from '../../../../../../../../shared/hooks/table/useTable'
import { ColumnsType } from 'antd/es/table'
import { IBankAccount, IBankAccountList } from '../../../../../../../Finance/models/IBankAccount'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../../../shared/components/popup/Popup'
import { TableActionIcon } from '../../../../../../../../assets/svg'
import { MESSAGE_KEY } from '../../../../../../../../shared/api/errorHandler'
import { ISelectedFilters } from '../../../../../../../../shared/components/filter/Filter'
import { ITableConf } from '../../../../../../../../shared/models/ITableConf'
import styles from '../../../../../../../Finance/pages/BankSettings/Components/BankAccountsTable/styles.module.scss'
import { useParams } from 'react-router'
import { SETTINGS_ENUM } from '../../../../../../../../routes/settings'

const IntegrationsTable = () => {
  const navigate = useNavigate()
  const { queryParams, queryFields } = useGetQueryParams()
  const { id } = useParams()

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const openNotification = useCallback((id: number) => {
    const btn = <Button type='primary'>Confirm</Button>
    const notificationRef = notification
    notificationRef.open({
      type: 'warning',
      message: 'This integration will be deleted',
      key: MESSAGE_KEY,
      btn,
    })
  }, [])

  const tableActionsPopup = useCallback(
    (id: IBankAccount['id']): IPopupListItems[] => [
      {
        text: 'View',
        shouldDisplay: true,
        onClick: () => navigate(`view/${id}`),
      },
      {
        text: 'Edit',
        shouldDisplay: true,
        onClick: () => navigate(`edit/${id}`),
      },
      {
        text: 'Delete',
        shouldDisplay: true,
        onClick: () => openNotification(id),
        color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        withDivider: true,
      },
    ],
    [navigate, openNotification]
  )

  const emptyTableConf = useEmptyTable(() =>
    navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/${id}/integrations/new`)
  )

  const { pagination, handleTableChange } = useTable<IBankAccountList>({
    total: 0,
    setTableConf,
    setFilterValues,
  })

  const columns: ColumnsType<IBankAccountList> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '20%',
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '80%',
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'edit',
      width: '1%',
      key: 'edit',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return (
    <Table
      locale={emptyTableConf}
      className={styles.bankAccountsTable}
      dataSource={[]}
      columns={columns}
      rowKey={(record) => record.id}
      scroll={{ x: 1300 }}
      pagination={pagination}
      onChange={handleTableChange}
    />
  )
}

export default IntegrationsTable
