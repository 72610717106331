import React from 'react'

import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { EditIcon } from 'src/assets/svg'
import { IconButton } from 'src/shared/components/iconButton/IconButton'

import FormItem from 'antd/es/form/FormItem'
import { TextField } from 'src/shared/components/textfield/TextField'
import {
  commonValidator,
  maxLengthValidator25,
  minLengthValidator,
  requiredValidator,
} from 'src/helpers/validation'
import styles from './styles.module.scss'

const ParameterDetailInfo = () => {
  return (
    <Wrapper title='Detail info'>
      <div>
        <div className={styles.dblRow}>
          <FormItem name='createdBy' label='Created By' style={{ marginBottom: 16 }}>
            <TextField disabled />
          </FormItem>
          <FormItem name='createdAt' label='Created date' style={{ marginBottom: 16 }}>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.dblRow}>
          <FormItem
            style={{ marginBottom: 0 }}
            name='parameterName'
            label='Parameter Name'
            rules={[
              { required: true, validator: requiredValidator('Parameter Name') },
              { validator: commonValidator },
              { validator: minLengthValidator(2, 'Parameter Name') },
              { validator: maxLengthValidator25 },
            ]}
          >
            <TextField />
          </FormItem>
          <div className={styles.editButtonContainer}>
            <IconButton
              style={{ height: 40 }}
              icon={<EditIcon />}
              type='default'
              color='orange'
              block
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default ParameterDetailInfo
