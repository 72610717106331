import { useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Table } from 'antd'
import { saveAs } from 'file-saver'

import { ColumnsType } from 'antd/es/table'
import { ISelectedFilters } from '../../../../shared/components/filter/Filter'
import { FileManagementTableHeader } from './Components/FileManagementTableHeader'
import PermissionDenied from '../../../../shared/components/permissionDenied'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../shared/components/popup/Popup'
import { TableActionIcon } from '../../../../assets/svg'

import { ICreatedBy, IFileTable } from 'src/features/Settings/models/IFileManagementList'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { PermissionEnum } from '../../../../shared/models/Permission'
import {
  useDeleteFileMutation,
  useGetFileListQuery,
  useGetFileManagementFiltersListQuery,
  useLazyGetFileInfoQuery,
} from '../../core/http/FileManagementApi'
import { ErrorNode, showConfirmMessage } from 'src/shared/api/errorHandler'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import useTable from 'src/shared/hooks/table/useTable'
import useFileManagement from './useFileManagement'
import { formatDateWithTime } from 'src/helpers/utils'

import styles from './styles.module.scss'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { RecursivelyReplaceCharacters } from '../../../../shared/components/privateMode'
import { useEmptyTable } from '../../../../shared/hooks/table/useEmptyTable'

export const FileManagement = () => {
  const navigate = useNavigate()

  const {
    permissions: { canAddFile, canViewFiles, canExportFile, canDeleteFile },
    queryParams,
    queryFields,
  } = useFileManagement()

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  /** Request data */
  const [deleteFile, delFileResp] = useDeleteFileMutation()
  const [getFile, { data: fileInfo }] = useLazyGetFileInfoQuery()
  const { data, isFetching } = useGetFileListQuery(
    { ...tableConf, ...filterValues },
    { skip: Object.keys(tableConf).length < 4 }
  )
  const { data: filterFields } = useGetFileManagementFiltersListQuery()
  const { data: profile } = useGetProfileQuery()

  const emptyTableConf = useEmptyTable()

  const { pagination, handleTableChange, handleFiltersChange } = useTable<IFileTable>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, delFileResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, delFileResp.isError, delFileResp.error as ErrorNode)

  const downloadFile = useCallback(
    async (row: IFileTable) => {
      if (row.preview) {
        return saveAs(row.preview, row.fileName)
      }
      await getFile(row.id)
      if (fileInfo) {
        saveAs(fileInfo.link, row.fileName)
      }
    },
    [fileInfo, getFile]
  )

  const tableActionsPopup = useCallback(
    (row: IFileTable): IPopupListItems[] => [
      {
        text: 'View',
        shouldDisplay: true,
        onClick: () => navigate(`view/${row.id}`),
      },
      {
        text: 'Download',
        shouldDisplay: canExportFile,
        onClick: () => downloadFile(row),
      },
      {
        text: 'Delete',
        shouldDisplay: canDeleteFile,
        onClick: () => showConfirmMessage('This file will be deleted', () => deleteFile(row.id)),
        color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        withDivider: true,
      },
    ],
    [canDeleteFile, canExportFile, deleteFile, downloadFile, navigate]
  )

  const columns: ColumnsType<IFileTable> = [
    {
      title: 'File Name',
      dataIndex: 'name',
      sorter: true,
      width: '20%',
      render: (fileName: string, row) => (
        <RecursivelyReplaceCharacters>
          <Link to={`view/${row.id}`} className={styles.tableNameLink}>
            <div>{fileName}</div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'File Format',
      dataIndex: 'fileType',
      sorter: true,
      render: (type: string) => <RecursivelyReplaceCharacters>{type}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: true,
      render: (cat: string) => <RecursivelyReplaceCharacters>{cat}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Permission Type',
      dataIndex: 'isPrivate',
      sorter: true,
      render: (isPrivate: IFileTable['isPrivate']) => (
        <RecursivelyReplaceCharacters>
          {isPrivate ? PermissionEnum.PRIVATE : PermissionEnum.PUBLIC}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Added by',
      dataIndex: 'creatorName',
      sorter: true,
      render: (createdByUser: ICreatedBy, row) => (
        <RecursivelyReplaceCharacters>
          {row?.createdByUser ? row?.createdByUser.name : ''}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      sorter: true,
      render: (createdAt: IFileTable['createdAt']) => (
        <RecursivelyReplaceCharacters>
          <div className='table-date'>
            <span>{formatDateWithTime(createdAt, profile?.calendar)}</span>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return canViewFiles ? (
    <div className={styles.fileManagementLayout}>
      <FileManagementTableHeader
        data={filterFields}
        canAddFile={canAddFile}
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
      />
      <Table
        columns={columns}
        onChange={handleTableChange}
        locale={emptyTableConf}
        loading={isFetching}
        className={styles.fileManagementList}
        scroll={{ x: 1150 }}
        dataSource={data?.items}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.id}
        pagination={pagination}
      />
    </div>
  ) : (
    <PermissionDenied />
  )
}
