import React, { useContext, useEffect, useState } from 'react'
import { Form, Spin, UploadFile } from 'antd'
import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import { RightModalContext } from '../../../../../../Modals'
import {
  useAddAttachmentsTFMutation,
  useGetTransferFundByIdQuery,
} from '../../../../../../Finance/core/http/WalletApi'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { formatNumberToLocale } from '../../../../../../salesNetwork/helpers'
import { formatDateWithTime } from '../../../../../../../helpers/utils'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { UploaderFiles } from 'src/shared/components/uploaderPhotos/UploaderFiles'
import { PRUploadTypes } from 'src/shared/components/uploaderPhotos/constants'
import { IAttachementRes } from 'src/features/Support/models/ITicket'
import TransactionStatus from '../../../../../../../shared/components/transactionStatus'
import styles from './styles.module.scss'
import { startCase } from 'lodash'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

export const TransferFundDetailModal = () => {
  const [form] = Form.useForm()
  const {
    props: { id, isReport },
  } = useContext(RightModalContext)

  const [files, setFiles] = useState<UploadFile[]>([])
  const [viewFiles, setViewFiles] = useState<IAttachementRes[]>([])

  const { data, isFetching } = useGetTransferFundByIdQuery(id)
  const { data: profile } = useGetProfileQuery()

  const [addAttachmentTF, dAttahcment] = useAddAttachmentsTFMutation()

  useNotification(NOTIFICATION_TYPES.success, dAttahcment.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, dAttahcment.isError, dAttahcment.error as ErrorNode)

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        amount: formatNumberToLocale(data?.amount),
        createdAt: formatDateWithTime(data.createdAt, profile?.calendar),
        receiverWallet: {
          ...data?.receiverWallet,
          name: startCase(data?.receiverWallet?.name.toLowerCase()),
        },
        senderWallet: {
          ...data?.senderWallet,
          name: startCase(data?.senderWallet?.name.toLowerCase()),
        },
      })

      setViewFiles(data.attachments)
    }
  }, [data, form, profile])

  const dispatchFiles = (arr: UploadFile[]) => setFiles(arr)

  const onSaveAttachments = () => {
    const fd = new FormData()

    files.forEach((value) => value.originFileObj && fd.append('files', value.originFileObj!))

    addAttachmentTF({
      id,
      body: fd,
    })
  }

  const content = (
    <Form form={form} layout='vertical' className={styles.layout} initialValues={data}>
      <Wrapper
        title='Transaction Details'
        className={styles.wrapper}
        statusButton={data?.status && <TransactionStatus status={data?.status} />}
      >
        <div>
          <FormItem name='id' label='Transaction Id'>
            <TextField disabled />
          </FormItem>
        </div>

        <div>
          <FormItem name='createdAt' label='Created At'>
            <TextField disabled />
          </FormItem>
        </div>

        <div>
          <FormItem name={['createdByUser', 'name']} label='Added By'>
            <TextField disabled />
          </FormItem>
        </div>

        <div className={styles.dblRow}>
          <FormItem name={['senderWallet', 'name']} label='Sender'>
            <TextField disabled />
          </FormItem>

          <FormItem name={['receiverWallet', 'name']} label='Receiver'>
            <TextField disabled />
          </FormItem>
        </div>

        <div>
          <FormItem name='amount' label='Amount'>
            <TextField disabled />
          </FormItem>
        </div>

        <div className={styles.dblRow}>
          <FormItem name='senderBalanceBefore' label='Sender Balance Before'>
            <TextField disabled />
          </FormItem>

          <FormItem name='senderBalanceAfter' label='Sender Balance After'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.dblRow}>
          <FormItem name='receiverBalanceBefore' label='Receiver Balance Before'>
            <TextField disabled />
          </FormItem>

          <FormItem name='receiverBalanceAfter' label='Receiver Balance After'>
            <TextField disabled />
          </FormItem>
        </div>
      </Wrapper>

      <div style={{ marginBottom: '24px' }}>
        <UploaderFiles
          allowTypes={PRUploadTypes}
          attachmentPropName='attachment'
          btnTitle='Attach File'
          viewFiles={viewFiles}
          dispatchData={dispatchFiles}
          onSave={onSaveAttachments}
          isLoading={dAttahcment.isLoading}
        />
      </div>
    </Form>
  )

  return isFetching || dAttahcment.isLoading ? <Spin>{content}</Spin> : content
}
