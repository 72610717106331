import FormItem from 'antd/es/form/FormItem'
import { requiredValidator } from 'src/helpers/validation'
import { Select as AntSelect } from 'antd'
import TextArea from 'antd/es/input/TextArea'

// components
import { TextField } from 'src/shared/components/textfield/TextField'
import { Select } from 'src/shared/components/select/select'

// styles
import styles from '../styles.module.scss'

// interfaces
import { IBankAccountList } from 'src/features/Finance/models/IBankAccount'
import TransactionStatus from 'src/shared/components/transactionStatus'

const { Option } = AntSelect

interface IDetailInfo {
  bankAccountList: Array<IBankAccountList> | undefined
  status?: string
}

export const DetailInfo: React.FC<IDetailInfo> = ({ bankAccountList = [], status }) => {
  return (
    <div className={styles.container}>
      <div className={styles.headerDetailInfo}>
        <div className={styles.header}>Transaction Details</div>
        {status && <TransactionStatus status={status} />}
      </div>
      <div>
        <FormItem name='paymentType' label='Payment Method' className={styles.paymentMethod}>
          <TextField disabled />
        </FormItem>
        <FormItem name='AccessChannel' label='Access channel' className={styles.paymentMethod}>
          <TextField disabled />
        </FormItem>
        <div className={styles.row}>
          <FormItem name='id' label='Transaction ID'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem
            name={['agent', 'id']}
            label='Request By'
            rules={[{ required: true, validator: requiredValidator('Request By') }]}
          >
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem name={['agent', 'name']} label='Requester Name'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem name='parentAgentId' label='eMoney will be send to'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.dblRow}>
          <FormItem
            name={['bankAccount', 'id']}
            label='Bank Account'
            rules={[{ required: true, validator: requiredValidator('Bank Account') }]}
          >
            <Select disabled>
              {bankAccountList.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem name={['currency', 'textCode']} label='Currency'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem name='exchangeRate' label='FX rate'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem
            name='currencyAmount'
            label='Amount'
            rules={[{ required: true, validator: requiredValidator('Amount') }]}
          >
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem name={['agent', 'accountType', 'accountType']} label='Account type'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem name='discountRate' label='Commission rate'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem name='promotionAmount' label='Bonus (Promotion)'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem name='amount' label='eMoney Amount'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem name='note' label='Note'>
            <TextArea disabled />
          </FormItem>
        </div>
      </div>
    </div>
  )
}
