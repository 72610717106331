export interface ICurrency {
  id: number
  name: string
  textCode: string
  symbol: string
}

export interface ICurrencyRate {
  sellingRate: number
  buyingRate: number
  date: Date
  currencyId: number
  toCurrencyId: number
}

export enum CURRENCIES_TO_COLOR_ENUM {
  AFN = '#F9C74F',
  USD = '#2D9CDB',
  IRR = '#4CAF50',
  TRY = '#F44336',
  PKR = '#9C27B0',
  AED = '#FF9800',
  EUR = '#90BE6D',
}
