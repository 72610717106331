import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { IBank, IBankTable, IUpdateBankBranch } from '../../models/IBank'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

export const bankApi = createApi({
  reducerPath: 'bank',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['BankList', 'FilterFields', 'Bank'],
  endpoints: (builder) => ({
    getBankList: builder.query<ITableResponse<IBankTable>, ITableConf>({
      query: (params) => ({
        url: 'bank',
        params,
      }),
      transformResponse: (resp: ITableResponse<IBankTable>) => ({
        ...resp,
        items: resp?.items.map((bank) => ({
          ...bank,
          branches: [],
        })),
      }),
      providesTags: ['BankList'],
    }),

    getBankById: builder.query<IBank, string | number>({
      query: (id) => ({
        url: `bank/${id}`,
      }),
      providesTags: ['Bank'],
    }),

    deleteBankById: builder.mutation<void, string | number>({
      query: (id) => ({
        url: `bank/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BankList', 'FilterFields'],
    }),

    createBank: builder.mutation<IBank, FormData>({
      query: (body) => ({
        url: `bank`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BankList', 'FilterFields'],
    }),

    updateBankById: builder.mutation<IBank, { id: string; body: FormData }>({
      query: ({ body, id }) => ({
        url: `bank/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Bank', 'FilterFields'],
    }),

    updateBankBranch: builder.mutation<IBank, { bankId: string; body: IUpdateBankBranch }>({
      query: ({ bankId, body }) => ({
        url: `bank/${bankId}/branch`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['FilterFields'],
    }),

    getBankFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'bank/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `bank/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: ['FilterFields'],
    }),
  }),
})

export const {
  useGetBankFiltersListQuery,
  useGetBankListQuery,
  useDeleteBankByIdMutation,
  useGetBankByIdQuery,
  useCreateBankMutation,
  useUpdateBankByIdMutation,
  useUpdateBankBranchMutation,
  useLazyGetBankByIdQuery,
} = bankApi
