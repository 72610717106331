import React, { useContext } from 'react'
import { Form } from 'antd'
import { RightModalContext } from '../../../../../Modals'
import styles from './styles.module.scss'

export const ReconciliationNewModal = () => {
  const [form] = Form.useForm()

  const content = <Form layout='vertical' form={form} className={styles.layout}></Form>

  return content
}
