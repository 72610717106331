//components
import { CenterModalContext } from 'src/features/Modals'
import { useContext } from 'react'
import { Button } from 'src/shared/components/button/Button'

// styles
import styles from './styles.module.scss'

export const ExportTableModal = () => {
  const {
    props: { onExportCSV, onExportXLSX },
  } = useContext(CenterModalContext)
  return (
    <div className={styles.container}>
      <Button onClick={onExportCSV} color='orange' htmlType='button'>
        Download csv
      </Button>
      <Button onClick={onExportXLSX} color='orange' htmlType='button'>
        Download xlsx
      </Button>
    </div>
  )
}
