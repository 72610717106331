import React, { memo, useContext, useEffect, useMemo, useState } from 'react'
import { Spin } from 'antd'

import { InternalChatContext } from '../InternalChatModal'
import {
  ArrowLeftIcon,
  ChatFilesIcon,
  ChatLinksIcon,
  ChatPicturesIcon,
  ChatVideosIcon,
} from '../../../../assets/svg'
import { IconButton } from '../../../../shared/components/iconButton/IconButton'
import { useAppSelector } from '../../../../redux'
import { selectInternalChatChatsState } from '../../core/store/Chats'
import { useGetInternalChatAttachmentsQuery } from '../../core/http/InternalChatsApi'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { IInternalChatMessageFile } from '../../models'
import FilesList from './Conponents/FilesList'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import ImagesList from './Conponents/ImagesList'
import VideoList from './Conponents/VideoList'
import { modalViewTypeEnum } from '../../core/models'

import styles from './styles.module.scss'
import chatStyles from '../ChatDetails/styles.module.scss'

const Attachments = () => {
  const { setProps, onSelectChat, props } = useContext(InternalChatContext)
  const { openedChatId } = useAppSelector(selectInternalChatChatsState)
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 100,
  })

  const [attachments, setAttachments] = useState<Array<IInternalChatMessageFile>>([])

  const type = useMemo(() => {
    if (props.attachmentType === 'Pictures') return 'Photo'
    if (props.attachmentType === 'Videos') return 'Video'
    if (props.attachmentType === 'Files') return 'File'
    return null
  }, [props.attachmentType])

  const {
    data = { items: [], totalCount: 0, currentPage: 1 } as ITableResponse<IInternalChatMessageFile>,
    isFetching,
  } = useGetInternalChatAttachmentsQuery(
    {
      id: openedChatId as number,
      fileType: type as string,
      ...tableConf,
    },
    { skip: !openedChatId || !type }
  )

  useEffect(() => {
    if (isFetching) return

    if (tableConf.page === 1) {
      setAttachments(data.items)
    } else {
      setAttachments((p) => [...p, ...data.items])
    }
    // eslint-disable-next-line
  }, [isFetching])

  const onPressBackButton = () => {
    onSelectChat(modalViewTypeEnum.details, openedChatId as number)
    setProps((prev) => ({ ...prev, attachmentType: null }))
  }

  const loadMoreData = () => {
    setTableConf((pre: ITableConf) => ({ ...pre, page: pre.page! + 1 }))
  }

  const content = (
    <div className={chatStyles.internalChatDetailsContainer}>
      <div className={styles.attachmentsHeader}>
        <IconButton
          color='blue'
          type='default'
          className={styles.backButton}
          icon={<ArrowLeftIcon />}
          onClick={onPressBackButton}
        />
        <div className={styles.attachmentsTitleContainer}>
          {props.attachmentType === 'Files' && <ChatFilesIcon />}
          {props.attachmentType === 'Pictures' && <ChatPicturesIcon />}
          {props.attachmentType === 'Videos' && <ChatVideosIcon />}
          {props.attachmentType === 'Links' && <ChatLinksIcon />}
          <div className={styles.attachmentsTitle}>{props.attachmentType}</div>
        </div>
      </div>
      <div style={{ flexGrow: 1, display: 'flex', width: '100%' }}>
        {props.attachmentType === 'Files' && (
          <FilesList files={attachments} totalCount={data.totalCount} loadMoreData={loadMoreData} />
        )}
        {props.attachmentType === 'Pictures' && (
          <ImagesList
            files={attachments}
            totalCount={data.totalCount}
            loadMoreData={loadMoreData}
          />
        )}
        {props.attachmentType === 'Videos' && (
          <VideoList files={attachments} totalCount={data.totalCount} loadMoreData={loadMoreData} />
        )}
      </div>
    </div>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}

export default memo(Attachments)
