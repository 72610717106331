import React, { memo } from 'react'
import { FormInstance } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { TextEditor } from 'src/shared/components/textEditor'
import styles from '../../styles.module.scss'

interface IProps {
  form: FormInstance
  isViewMode?: boolean
}

const ProfileDocumentationEditor = ({ form, isViewMode = false }: IProps) => {
  return (
    <Wrapper title='Editor' className={styles.wrapperDetailInfo}>
      <FormItem name='message'>
        <TextEditor
          form={form}
          name='message'
          viewMode={isViewMode}
          initialContent={form.getFieldValue('message')}
        />
      </FormItem>
    </Wrapper>
  )
}

export default memo(ProfileDocumentationEditor)
