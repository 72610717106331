export enum IPermissionsNames {
  'AF Authentication' = 'AF Authentication',
  'Account Type' = 'Account Type',
  'Active Agents' = 'Active Agents',
  'Active Locations' = 'Active Locations',
  'Agent Access Channel' = 'Agent Access Channel',
  'Agent Address' = 'Agent Address',
  'Agent Agent Network' = 'Agent Agent Network',
  'Agent Commission Management' = 'Agent Commission Management',
  'Agent Commission Policy Management' = 'Agent Commission Policy Management',
  'Agent Configuration' = 'Agent Configuration',
  'Agent Contact Information' = 'Agent Contact Information',
  'Agent Credit Score' = 'Agent Credit Score',
  'Agent Detail' = 'Agent Detail',
  'Agent List' = 'Agent List',
  'Agent Management' = 'Agent Management',
  'Agent Network' = 'Agent Network',
  'Agent Product Access' = 'Agent Product Access',
  'Agent Profile' = 'Agent Profile',
  'Agent Statement' = 'Agent Statement',
  'Agent Statment' = 'Agent Statment',
  'Agent Transaction History' = 'Agent Transaction History',
  'Agent Transfer Agent' = 'Agent Transfer Agent',
  'Agent Type' = 'Agent Type',
  'Agent Types' = 'Agent Types',
  'Agent User Identifier' = 'Agent User Identifier',
  'Agent mPIN Management' = 'Agent mPIN Management',
  'Assign Chat' = 'Assign Chat',
  'Attachment' = 'Attachment',
  'Audience' = 'Audience',
  'Bank Account Balnaces - Chart' = 'Bank Account Balnaces - Chart',
  'Bank Accounts' = 'Bank Accounts',
  'Bank Accounts Balance' = 'Bank Accounts Balance',
  'Change Password' = 'Change Password',
  'Change Status' = 'Change Status',
  'Change mPIN' = 'Change mPIN',
  'Chat History' = 'Chat History',
  'ChatRoom' = 'ChatRoom',
  'Collection Wallet' = 'Collection Wallet',
  'Commission Policy' = 'Commission Policy',
  'Commission Wallet' = 'Commission Wallet',
  'Contact' = 'Contact',
  'Content' = 'Content',
  'Create New Report' = 'Create New Report',
  'Creating Group' = 'Creating Group',
  'Credit Score' = 'Credit Score',
  'Credit Score Overview' = 'Credit Score Overview',
  'Credit Score Parameters' = 'Credit Score Parameters',
  'Credit Score Profiling' = 'Credit Score Profiling',
  'Credit Score Setting' = 'Credit Score Setting',
  'Dashboard Customization' = 'Dashboard Customization',
  'Delegation' = 'Delegation',
  'Departments' = 'Departments',
  'Detail view' = 'Detail view',
  'Download File' = 'Download File',
  'Download Profile' = 'Download Profile',
  'Edit Profile' = 'Edit Profile',
  'Exchange Rate' = 'Exchange Rate',
  'Exchange Rate Rate Management' = 'Exchange Rate Rate Management',
  'Failed Transactions' = 'Failed Transactions',
  'File Management' = 'File Management',
  'Files list' = 'Files list',
  'Finance Bank Wallet' = 'Finance Bank Wallet',
  'Finance Dashboard' = 'Finance Dashboard',
  'Finance Dashboard P&L' = 'Finance Dashboard P&L',
  'Finance Dashboard P&L Summary' = 'Finance Dashboard P&L Summary',
  'Finance Dashboard Sales Summary' = 'Finance Dashboard Sales Summary',
  'Finance Dashboard Transfer Fund' = 'Finance Dashboard Transfer Fund',
  'Finance Purchase Order' = 'Finance Purchase Order',
  'Finance Reconcile' = 'Finance Reconcile',
  'Finance Transactions List' = 'Finance Transactions List',
  'Finance Transfer Fund' = 'Finance Transfer Fund',
  'Finance Trust Wallet' = 'Finance Trust Wallet',
  'FinanceRefund eMoney' = 'FinanceRefund eMoney',
  'Group Call' = 'Group Call',
  'Hawala' = 'Hawala',
  'Hawala - Chart' = 'Hawala - Chart',
  'Hawala Wallet' = 'Hawala Wallet',
  'Information report' = 'Information report',
  'Integration' = 'Integration',
  'List of Notifications' = 'List of Notifications',
  'List of Tickets' = 'List of Tickets',
  'Media Library' = 'Media Library',
  'New Registrations' = 'New Registrations',
  'Notification Management' = 'Notification Management',
  'Notifications' = 'Notifications',
  'Open Cases' = 'Open Cases',
  'Overview' = 'Overview',
  'Permissions' = 'Permissions',
  'Pickup' = 'Pickup',
  'Pickup Request' = 'Pickup Request',
  'Pickup Requests List' = 'Pickup Requests List',
  'Pickups' = 'Pickups',
  'Pickups - Chart' = 'Pickups - Chart',
  'Product' = 'Product',
  'Promotions Policy' = 'Promotions Policy',
  'Purchase Orders' = 'Purchase Orders',
  'Purchase Orders List' = 'Purchase Orders List',
  'Refund eMoney List' = 'Refund eMoney List',
  'Report Builder' = 'Report Builder',
  'Reset Password' = 'Reset Password',
  'Reverse' = 'Reverse',
  'Reverse Transaction List' = 'Reverse Transaction List',
  'Reverse Transaction Request' = 'Reverse Transaction Request',
  'Reward Policy' = 'Reward Policy',
  'Reward Wallet' = 'Reward Wallet',
  'Roles' = 'Roles',
  'Sales Active Agents' = 'Sales Active Agents',
  'Sales Agent Management' = 'Sales Agent Management',
  'Sales Agent Profile' = 'Sales Agent Profile',
  'Agent Current Balance' = 'Agent Current Balance',
  'Agent Parent Account' = 'Agent Parent Account',
  'Sales Channels' = 'Sales Channels',
  'Sales Overview' = 'Sales Overview',
  'Sales Summary' = 'Sales Summary',
  'Sales Top Locations' = 'Sales Top Locations',
  'Sales Total Sales' = 'Sales Total Sales',
  'Schedule Notification' = 'Schedule Notification',
  'Schedule Report' = 'Schedule Report',
  'Send Voice Message' = 'Send Voice Message',
  'Statement of Wallets' = 'Statement of Wallets',
  'Status (Active/Inactive)' = 'Status (Active/Inactive)',
  'Summary' = 'Summary',
  'Support Overview' = 'Support Overview',
  'Templates' = 'Templates',
  'Text Chat' = 'Text Chat',
  'Ticket Detail View' = 'Ticket Detail View',
  'Tickets' = 'Tickets',
  'Time Tracker' = 'Time Tracker',
  'Top Agents' = 'Top Agents',
  'Top Locations' = 'Top Locations',
  'Total Floating Balance' = 'Total Floating Balance',
  'Total Pickups' = 'Total Pickups',
  'Total Pickups Today' = 'Total Pickups Today',
  'Total Sales' = 'Total Sales',
  'Transaction report' = 'Transaction report',
  'Transactions List' = 'Transactions List',
  'Transfer Agent' = 'Transfer Agent',
  'Trust Wallet' = 'Trust Wallet',
  'Upload File' = 'Upload File',
  'User Activity Logs' = 'User Activity Logs',
  'User Management' = 'User Management',
  'Users' = 'Users',
  'Vendor' = 'Vendor',
  'Vendor Balance' = 'Vendor Balance',
  'Vendor Balances - Chart' = 'Vendor Balances - Chart',
  'Vendor Current Balance' = 'Vendor Current Balance',
  'Vendor Management' = 'Vendor Management',
  'Vendor Wallets' = 'Vendor Wallets',
  'Vendors Balance' = 'Vendors Balance',
  'Video Chat' = 'Video Chat',
  'View Agent Transactions' = 'View Agent Transactions',
  'View Agent/Customer Information' = 'View Agent/Customer Information',
  'View Online Contact' = 'View Online Contact',
  'Voice Call' = 'Voice Call',
  'Voice Chat' = 'Voice Chat',
  'Voice Messages' = 'Voice Messages',
  'Wallet Types' = 'Wallet Types',
  '2AF Authentication' = '2AF Authentication',
}

export enum IPermissionsActions {
  'ADD' = 'ADD',
  'EDIT' = 'EDIT',
  'VIEW' = 'VIEW',
  'DELETE' = 'DELETE',
  'REJECT' = 'REJECT',
  'IMPORT' = 'IMPORT',
  'EXPORT' = 'EXPORT',
  'MAKER' = 'MAKER',
  'CHECKER' = 'CHECKER',
  'APPROVER' = 'APPROVER',
}
