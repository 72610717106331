import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import moment from 'moment'
import classNames from 'classnames'
import { startCase } from 'lodash'
import { useEmptyTable } from 'src/shared/hooks/table/useEmptyTable'
import useTable from 'src/shared/hooks/table/useTable'
import { ITableConf } from 'src/shared/models/ITableConf'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import { IReconciliation } from '../../../../../models/IReconciliation'
import { ColumnsType } from 'antd/es/table'
import emptyUserImage from '../../../../../../../assets/img/Avatar.png'
import { TableActionIcon } from '../../../../../../../assets/svg'
import { IPopupListItems, Popup } from 'src/shared/components/popup/Popup'
import styles from '../../../styles.module.scss'

const AllReconciliation = () => {
  const emptyTableConf = useEmptyTable()
  const { queryParams } = useGetQueryParams()

  const [, setTableConf] = useState<ITableConf>(queryParams)

  const { pagination, handleTableChange } = useTable<IReconciliation>({
    total: 0,
    setTableConf,
  })

  const tableActionsPopup = (): IPopupListItems[] => [
    {
      text: 'View',
      shouldDisplay: true,
    },
  ]

  const columns: ColumnsType<IReconciliation> = [
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: '15%',
      render: (status: IReconciliation['status']) => (
        <div className={styles.statusContainer}>
          <div className={classNames(styles.statusMark, styles[`status-mark-${status}`])}></div>
          <div className={classNames(styles.statusName, styles[`status-${status}`])}>
            {startCase(status)}
          </div>
        </div>
      ),
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      width: '25%',
      sorter: true,
      render: (name: IReconciliation['vendor'], row) => (
        <Link to='#' className='table-avatar'>
          <img src={row.vendor?.logo ?? emptyUserImage} alt='vendor-logo' />
          <div>{row.vendor.name}</div>
        </Link>
      ),
    },
    {
      title: 'File name',
      dataIndex: 'fileName',
      width: '20%',
      sorter: true,
    },
    {
      title: 'Differences',
      dataIndex: 'differences',
      width: '15%',
      sorter: true,
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      width: '15%',
      sorter: true,
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      width: '15%',
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '10%',
      render: () => {
        return (
          <Popup data={tableActionsPopup()}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        )
      },
    },
  ]

  const data: Array<IReconciliation> = [
    {
      id: 1,
      status: 'completed',
      vendor: { name: 'John Doe' },
      fileName: 'document.jpg',
      differences: 12,
      createdAt: moment().format('MMMM DD, YYYY h:mm'),
      duration: 11,
    },
    {
      id: 2,
      status: 'unreconciled',
      vendor: { name: 'Adam Smith' },
      fileName: 'document.png',
      differences: 123,
      createdAt: moment().format('MMMM DD, YYYY h:mm'),
      duration: 23,
    },
    {
      id: 3,
      status: 'pending',
      vendor: { name: 'Kate Long' },
      fileName: 'document.pdf',
      differences: 321,
      createdAt: moment().format('MMMM DD, YYYY h:mm'),
      duration: 34,
    },
  ]

  return (
    <div>
      <Table
        locale={emptyTableConf}
        className={styles.table}
        dataSource={data}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{ x: 1300 }}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </div>
  )
}

export default AllReconciliation
