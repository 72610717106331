import React, { useContext, useEffect, useState } from 'react'

import { Button } from '../../../../../../../../../../shared/components/button/Button'
import { CenterModalContext, RightModalContext } from '../../../../../../../../../Modals'

import { useAppSelector } from '../../../../../../../../../../redux'
import { IUser } from '../../../../../../../../models/IUser'
import { ITableResponse } from 'src/shared/models/ITableResponse'
import { CENTER_MODALS, RIGHT_MODALS } from '../../../../../../../../../../helpers/contants'
import { selectUsersManagementState } from '../../../../../../../../core/store/UsersManagerSlice'

import UserModalRow from '../../../../../UserModalRow'

interface IProps {
  users?: ITableResponse<IUser>['items']
  onDeleteUser: (id: number) => void
  onAddUser: (product: IUser) => void
  defaultChecked?: boolean
  departmentId?: string
  isCreating?: boolean
}

interface IUserRow extends Omit<IProps, 'users'> {
  user: IUser
}

const UserRow = ({
  user,
  defaultChecked,
  onDeleteUser,
  onAddUser,
  isCreating,
  departmentId,
}: IUserRow) => {
  const { onOpen } = useContext(RightModalContext)
  const centerModalContext = useContext(CenterModalContext)

  const [isSelected, setIsSelected] = useState(!!defaultChecked)

  const onCloseRedirect = () => {
    onOpen(RIGHT_MODALS.SETTINGS.DEPARTMENT_MANAGEMENT_USERS_LIST, {
      title: 'Add Users',
      departmentId,
    })
  }

  const onPressViewUser = () => {
    onOpen(RIGHT_MODALS.SETTINGS.USERS_MANAGEMENT_USER, {
      title: 'View Users',
      mode: 'view',
      id: user.id,
      onCloseRedirect,
    })
  }

  const onSelectConfirm = () => {
    if (isSelected) {
      onDeleteUser(user.id)
      setIsSelected(false)
      centerModalContext.onClose()
      return
    }

    onAddUser(user)
    setIsSelected(true)
    centerModalContext.onClose()
    return
  }

  const onPressSelectUser = () => {
    if (!user.department) {
      return onSelectConfirm()
    }

    centerModalContext.onOpen(CENTER_MODALS.CONFIRM_MODAL, {
      title: 'Add user to department',
      content: `Reassign user ${user.firstName} ${user.lastName} to new department.`,
      footer: (
        <>
          <Button color='blue' size='middle' onClick={centerModalContext.onClose}>
            Cancel
          </Button>

          <Button
            color='orange'
            size='middle'
            type='primary'
            htmlType='submit'
            onClick={onSelectConfirm}
          >
            Confirm
          </Button>
        </>
      ),
    })
  }

  useEffect(() => {
    centerModalContext.onClose()
    // eslint-disable-next-line
  }, [isSelected])

  return (
    <UserModalRow
      isCreating={isCreating}
      user={user}
      isSelected={isSelected}
      onPressSelect={onPressSelectUser}
      onPressViewUser={onPressViewUser}
    />
  )
}

export const DepartmentsManagementUsersList = ({
  users,
  onDeleteUser,
  onAddUser,
  departmentId,
  isCreating,
}: IProps) => {
  const { usersList } = useAppSelector(selectUsersManagementState)
  return (
    <>
      {users?.map((user) => {
        const shouldBeChecked = usersList.some((savedUser) => savedUser.id === user.id)
        const defaultChecked = user?.department?.id === Number(departmentId)

        return (
          <UserRow
            isCreating={isCreating}
            key={user.id}
            user={user}
            defaultChecked={defaultChecked || shouldBeChecked}
            onDeleteUser={onDeleteUser}
            onAddUser={onAddUser}
            departmentId={departmentId}
          />
        )
      })}
    </>
  )
}
