import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../redux'
import {
  EXTERNAL_CHAT_FILE_TYPE_ENUM,
  IAddReactionResp,
  ILastExternalChatMessage,
} from '../../models/IExternalChat'
import { omit, unionBy } from 'lodash'

interface IInitialState {
  currentUserId: number | null
  lastReadingAtByCurrentUser: string | null
  createdAtBefore: string | null
  createdAtAfter: string | null
  messages: Array<ILastExternalChatMessage>
  userInfoModalType: 'Info' | EXTERNAL_CHAT_FILE_TYPE_ENUM
}

const initialState: IInitialState = {
  currentUserId: null,
  lastReadingAtByCurrentUser: null,
  createdAtAfter: null,
  createdAtBefore: null,
  messages: [],
  userInfoModalType: 'Info',
}

const findExternalChatMessageIndex = (
  messages: Array<ILastExternalChatMessage>,
  messageId: number | string
) => {
  return messages?.findIndex((c) => {
    return +c?.id === +messageId
  })
}

export const externalChatMessagingSlice = createSlice({
  name: 'externalChatMessagingSlice',
  initialState,
  reducers: {
    setCurrentExternalUserId: (state, { payload }: PayloadAction<number>) => {
      state.currentUserId = payload
    },
    setLastReadingAtByCurrentUser: (state, { payload }: PayloadAction<string>) => {
      state.lastReadingAtByCurrentUser = payload
    },
    setCreatedAtBefore: (state, { payload }: PayloadAction<string>) => {
      state.createdAtBefore = payload
    },
    setCreatedAtAfter: (state, { payload }: PayloadAction<string>) => {
      state.createdAtAfter = payload
    },
    setExternalChatInfoType: (
      state,
      { payload }: PayloadAction<IInitialState['userInfoModalType']>
    ) => {
      state.userInfoModalType = payload
    },
    addExternalMessagesBefore: (
      state,
      { payload }: PayloadAction<Array<ILastExternalChatMessage>>
    ) => {
      if (!payload?.length) return
      state.messages = unionBy([...state.messages, ...payload], 'id')
    },
    addExternalMessagesAfter: (
      state,
      { payload }: PayloadAction<Array<ILastExternalChatMessage>>
    ) => {
      if (!payload?.length) return
      state.messages = unionBy([...payload, ...state.messages], 'id')
    },
    addReactionToExternalMessage: (state, { payload }: PayloadAction<IAddReactionResp>) => {
      const index = findExternalChatMessageIndex(state.messages, payload.messageId)
      if (index === -1) return

      if (!state.messages[index].reactions) {
        state.messages[index].reactions = []
      }

      const existIndex = state.messages[index].reactions.findIndex(
        (reaction) => reaction.isFromMessenger === payload.isFromMessenger
      )
      if (existIndex !== -1) {
        state.messages[index].reactions.splice(existIndex, 1)
      }
      state.messages[index].reactions.push(omit(payload, 'chatId' || 'messageId'))
    },
    deleteReactionToExternalMessage: (
      state,
      { payload }: PayloadAction<Omit<IAddReactionResp, 'emoji'>>
    ) => {
      const index = findExternalChatMessageIndex(state.messages, payload.messageId)
      if (index === -1) return

      const removeIndex = state.messages[index].reactions.findIndex(
        (reaction) => reaction.isFromMessenger === payload.isFromMessenger
      )
      if (removeIndex === -1) return
      state.messages[index].reactions.splice(removeIndex, 1)
    },
    clearExternalChatMessages: (state) => {
      state.messages = []
    },
    clearExternalChatDates: (state) => {
      state.lastReadingAtByCurrentUser = null
      state.createdAtAfter = null
      state.createdAtBefore = null
    },
    clearExternalChatState: (state) => {
      state.currentUserId = null
      state.lastReadingAtByCurrentUser = null
      state.createdAtAfter = null
      state.createdAtBefore = null
      state.userInfoModalType = 'Info'
      state.messages = []
    },
  },
})

export const selectExternalChatMessagingSlice = (state: RootState) => state.externalChatSlice

export const {
  setCurrentExternalUserId,
  addExternalMessagesBefore,
  addExternalMessagesAfter,
  clearExternalChatMessages,
  clearExternalChatState,
  setLastReadingAtByCurrentUser,
  setCreatedAtBefore,
  setCreatedAtAfter,
  clearExternalChatDates,
  setExternalChatInfoType,
  addReactionToExternalMessage,
  deleteReactionToExternalMessage,
} = externalChatMessagingSlice.actions
