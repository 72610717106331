import { Form, Spin } from 'antd'
import { useContext, useEffect } from 'react'
import { RightModalContext } from '../../../../../../Modals'
import { useGetProfileQuery } from '../../../../../../Profile/core/http/Profile'
import { useGetPickupRequestLogsByIdQuery } from '../../../../../../Finance/core/http/BankWalletTransactionsApi'
import styles from '../PurchaseOrderChangesModal/styles.module.scss'
import { cloneDeep, isEmpty } from 'lodash'
import { formatDateWithTime } from '../../../../../../../helpers/utils'
import { OldItem } from './OldItem'

export const PickupRequestChangesModal = () => {
  const [form] = Form.useForm()
  const {
    props: { id },
  } = useContext(RightModalContext)

  const { data: profile } = useGetProfileQuery()
  const { data = [], isFetching } = useGetPickupRequestLogsByIdQuery(id, { skip: !id })

  useEffect(() => {
    if (!isEmpty(data)) {
      form.setFieldValue(
        'historyChanges',
        cloneDeep(data)
          .sort((a, b) => b.id - a.id)
          .map((change) => ({
            ...change,
            createdAt: formatDateWithTime(change?.createdAt, profile?.calendar),
          }))
      )
    }
  }, [data, form, profile?.calendar])

  const content = (
    <Form layout='vertical' form={form} className={styles.layout}>
      <Form.List name='historyChanges'>
        {(fields) => (
          <>
            {fields.map(({ key, name }) => (
              <OldItem key={key} name={name} />
            ))}
          </>
        )}
      </Form.List>
    </Form>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
