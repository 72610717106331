import { Route } from 'use-react-router-breadcrumbs'
import { FinanceOverview } from '../../features/Finance/pages/Overview'
import { TrustWallet } from '../../features/Finance/pages/TrustWallet/index'
import { NewPurchaseOrder } from '../../features/Finance/pages/TrustWallet/NewPurchaseOrder'
import { PurchaseOrder } from '../../features/Finance/pages/TrustWallet/PurchaseOrder'
import { BankWallet } from '../../features/Finance/pages/BankWallet/index'
import { PickupRequest } from '../../features/Finance/pages/BankWallet/Requests/PickupRequest'
import { RefundRequest } from '../../features/Finance/pages/BankWallet/Requests/RefundRequest'
import { StatementOfWallets } from '../../features/Finance/pages/StatementOfWallets'
import { StatementOfWalletsDetailView } from '../../features/Finance/pages/StatementOfWallets/StatementOfWalletsDetailView'
import { BankAccount } from '../../features/Finance/pages/BankSettings/BankAccountView'
import { EnchangeRates } from '../../features/Finance/pages/ExchangeRates'
import { CreditScore } from '../../features/Finance/pages/CreditScore'
import { CreditScoreParameterDetails } from '../../features/Finance/pages/CreditScore/Components/ParameterDetailView'
import { InventoryView } from '../../features/Finance/pages/TrustWallet/InventoryView'
import { getBreadcrumb } from '../utils'
import Reconciliation from '../../features/Finance/pages/Reconciliation'
import { PinView } from '../../features/Finance/pages/TrustWallet/PinView'
import { BankSettings } from '../../features/Finance/pages/BankSettings/index'
import { BankView } from '../../features/Finance/pages/BankSettings/BankView'
import { HydraInventoryView } from '../../features/Finance/pages/TrustWallet/HydraInventoryView'

export enum FINANCE_ENUM {
  OVERVIEW = '/finance/overview',
  TRUST_WALLET = '/finance/treasury-wallet',
  NEW_PURCHASE_ORDER = '/finance/treasury-wallet/purchase-order/new',
  VIEW_PURCHASE_ORDER = '/finance/treasury-wallet/purchase-order/:mode/:poId',
  BANK_SETTINGS = '/finance/bank-settings',
  NEW_BANK_ACCOUNT = '/finance/bank-settings/bank-accounts/new',
  VIEW_BANK_ACCOUNT = '/finance/bank-settings/bank-accounts/:mode/:id',
  NEW_BANK = '/finance/bank-settings/banks/new',
  VIEW_BANK = '/finance/bank-settings/banks/:mode/:id',
  BANK_WALLET = '/finance/operation-wallet',
  NEW_PICKUP_REQUEST = '/finance/operation-wallet/pickup-request/new',
  VIEW_PICKUP_REQUEST = '/finance/operation-wallet/pickup-request/:mode/:prId',
  NEW_REFUND_REQUEST = '/finance/operation-wallet/refund-request/new',
  VIEW_REFUND_REQUEST = '/finance/operation-wallet/refund-request/:mode/:id',
  VIEW_ENCHANGE_RATE = '/finance/exchange-rates',
  STATEMENT_OF_WALLETS = '/finance/statement-of-wallets',
  STATEMENT_OF_WALLETS_VIEW_ALL = '/finance/statement-of-wallets/:cat',
  STATEMENT_OF_WALLETS_VIEW_SUB = '/finance/statement-of-wallets/:cat/:id/:subWall',
  STATEMENT_OF_WALLETS_VIEW = '/finance/statement-of-wallets/:cat/:id',
  CREDIT_SCORE = '/finance/credit-score',
  CREDIT_SCORE_PARAMETER_DETAIL = '/finance/credit-score/parameter-detail',
  CREDIT_SCORE_PARAMETER_DETAIL_VIEW = '/finance/credit-score/parameter-detail/:mode/:id',
  CREDIT_SCORE_AGENTS = '/finance/credit-score/agents',
  INVENTORY_VIEW = '/finance/treasury-wallet/vendor/:vendorId/inventory/:mode/:inventoryId',
  EPIN_INVENTORY_VIEW = '/finance/treasury-wallet/e-pin/inventory/:mode/:id',
  HYDRA_INVENTORY_VIEW = '/finance/treasury-wallet/hydra/inventory/:mode/:id',
  RECONCILIATION = '/finance/reconciliation',
}

export default () => {
  return (
    <>
      <Route path={FINANCE_ENUM.OVERVIEW} element={<FinanceOverview />} />
      <Route path={FINANCE_ENUM.TRUST_WALLET} element={<TrustWallet />} />
      <Route
        path={FINANCE_ENUM.NEW_PURCHASE_ORDER}
        element={<NewPurchaseOrder />}
        breadcrumb='New Purchase Order'
      />
      <Route
        path={FINANCE_ENUM.VIEW_PURCHASE_ORDER}
        element={<PurchaseOrder />}
        breadcrumb='Purchase Order'
      />
      <Route path={FINANCE_ENUM.BANK_WALLET} element={<BankWallet />} />
      <Route
        path={FINANCE_ENUM.NEW_PICKUP_REQUEST}
        element={<PickupRequest />}
        breadcrumb='New Pickup Request'
      />
      <Route
        path={FINANCE_ENUM.VIEW_PICKUP_REQUEST}
        element={<PickupRequest />}
        breadcrumb='Pickup Request detail view'
      />
      <Route
        path={FINANCE_ENUM.VIEW_REFUND_REQUEST}
        element={<RefundRequest />}
        breadcrumb='Refund Request detail view'
      />
      <Route
        path={FINANCE_ENUM.NEW_REFUND_REQUEST}
        element={<RefundRequest />}
        breadcrumb='New Refund Request'
      />
      <Route path={FINANCE_ENUM.STATEMENT_OF_WALLETS} element={<StatementOfWallets />} />
      <Route
        path={FINANCE_ENUM.STATEMENT_OF_WALLETS_VIEW}
        element={<StatementOfWalletsDetailView />}
        breadcrumb={null}
      />
      <Route
        path={FINANCE_ENUM.STATEMENT_OF_WALLETS_VIEW_SUB}
        element={<StatementOfWalletsDetailView />}
        breadcrumb={null}
      />
      <Route
        path={FINANCE_ENUM.STATEMENT_OF_WALLETS_VIEW_ALL}
        element={<StatementOfWalletsDetailView />}
      />
      <Route path={FINANCE_ENUM.BANK_SETTINGS} element={<BankSettings />} />
      <Route
        path={FINANCE_ENUM.NEW_BANK_ACCOUNT}
        element={<BankAccount />}
        breadcrumb='New Bank Account'
      />
      <Route
        path={FINANCE_ENUM.VIEW_BANK_ACCOUNT}
        element={<BankAccount />}
        breadcrumb={getBreadcrumb()}
      />
      <Route path={FINANCE_ENUM.NEW_BANK} element={<BankView />} breadcrumb='New Bank' />
      <Route path={FINANCE_ENUM.VIEW_BANK} element={<BankView />} breadcrumb={getBreadcrumb()} />
      <Route path={FINANCE_ENUM.VIEW_ENCHANGE_RATE} element={<EnchangeRates />} />
      <Route path={FINANCE_ENUM.CREDIT_SCORE} element={<CreditScore />} />
      <Route
        path={FINANCE_ENUM.CREDIT_SCORE_PARAMETER_DETAIL_VIEW}
        element={<CreditScoreParameterDetails />}
      />
      <Route
        path={FINANCE_ENUM.INVENTORY_VIEW}
        element={<InventoryView />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={FINANCE_ENUM.RECONCILIATION}
        element={<Reconciliation />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={FINANCE_ENUM.EPIN_INVENTORY_VIEW}
        element={<PinView />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={FINANCE_ENUM.HYDRA_INVENTORY_VIEW}
        element={<HydraInventoryView />}
        breadcrumb={getBreadcrumb()}
      />
      {/*/!*{TODO: FOR BREADCRUMB ON INVENTORY PAGE, REMOVE IN FUTURE}*!/*/}
      <Route path='/finance/treasury-wallet/vendor/:id' breadcrumb={null} />
    </>
  )
}
