import FormList from 'antd/es/form/FormList'
import { Form, Select } from 'antd'
import styles from '../../styles.module.scss'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from '../../../../../../../assets/svg'
import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import classNames from 'classnames'
import { IWhatsappManagerButton } from '../../../../../models/IWhatsappManager'
import { requiredValidator } from '../../../../../../../helpers/validation'

interface IProps extends IViewMode {
  buttons?: Array<IWhatsappManagerButton>
}

export const ButtonsBlock = ({ view, buttons }: IProps) => {
  return (
    <Wrapper title='Buttons'>
      <FormList name='whatsappButtons'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <div key={key} className={styles.buttonListItem}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const selectedButtons = (
                      (getFieldValue('whatsappButtons') as Array<IWhatsappManagerButton>) || []
                    )
                      .filter((btn) => !!btn?.id)
                      .map((btn) => btn.id)

                    return (
                      <Form.Item
                        name={[name, 'id']}
                        label='Button'
                        className={styles.buttonSelect}
                        rules={[
                          {
                            required: true,
                            validator: requiredValidator('Button'),
                          },
                        ]}
                      >
                        <Select disabled={view} allowClear placeholder='Select Button'>
                          {buttons?.map((btn) => (
                            <Select.Option
                              value={btn.id}
                              key={btn.id}
                              disabled={selectedButtons.includes(btn.id)}
                            >
                              {btn.title}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )
                  }}
                </Form.Item>

                <Form.Item>
                  <div className={styles.bucketIcon}>
                    <IconButton
                      block
                      size='large'
                      color='orange'
                      icon={<BucketIcon />}
                      type='default'
                      disabled={view}
                      onClick={() => {
                        remove(name)
                      }}
                    />
                  </div>
                </Form.Item>
              </div>
            ))}

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const isMoreThanThree = (getFieldValue('whatsappButtons') || []).length > 2

                return (
                  !isMoreThanThree && (
                    <Form.Item className={classNames('noBottomMargin', styles.addButton)}>
                      <IconButton
                        icon={<AddIcon />}
                        color='orange'
                        block
                        onClick={() => add()}
                        disabled={view}
                      />
                    </Form.Item>
                  )
                )
              }}
            </Form.Item>
          </>
        )}
      </FormList>
    </Wrapper>
  )
}
