import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import useTable from '../../../../../../shared/hooks/table/useTable'
import React, { useCallback, useState } from 'react'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import { useGetQueryParams } from '../../../../../../shared/hooks/table/useGetQueryParams'
import styles from '../../../../../Settings/pages/VendorManagement/Components/VendorManagementTable/style.module.scss'
import { Table } from 'antd'
import { IPopupListItems, Popup } from '../../../../../../shared/components/popup/Popup'
import { ColumnsType } from 'antd/es/table'
import { DateTableIcon, TableActionIcon } from '../../../../../../assets/svg'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { formatDateWithTime, tableExpandedIcon } from '../../../../../../helpers/utils'
import { useGetProfileQuery } from '../../../../../Profile/core/http/Profile'
import { IEpinTable } from '../../../../models/IEPin'
import { useGetEPinListQuery } from '../../../../core/http/EPinApi'
import emptyUserImage from '../../../../../../assets/img/Avatar.png'
import { Link } from 'react-router-dom'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'
import { formatNumberToLocale } from '../../../../../salesNetwork/helpers'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router'
import { FINANCE_ENUM } from '../../../../../../routes/finance'

export const PinTable = () => {
  const navigate = useNavigate()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const [expandedRowKeys, setExpandedRowKeys] = useState<Array<React.Key>>([])

  const { queryParams } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'id',
  })

  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data: profile } = useGetProfileQuery()
  const { data, isFetching } = useGetEPinListQuery(tableConf, { skip: isEmpty(tableConf) })

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange } = useTable<IEpinTable>({
    total: data?.totalCount,
    setTableConf,
    setExpandedRowKeys,
  })

  const tableActionsPopup = useCallback(
    (id: number): IPopupListItems[] => [
      {
        text: 'View',
        shouldDisplay: true,
        onClick: () => navigate(`${FINANCE_ENUM.TRUST_WALLET}/e-pin/inventory/view/${id}`),
      },
    ],
    [navigate]
  )

  const columns: ColumnsType<IEpinTable> = [
    {
      title: '',
      dataIndex: '',
      key: 'expand',
      align: 'center',
      width: '1%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      width: '15%',
      render: (name, row) => (
        <RecursivelyReplaceCharacters>
          {!row?.mainTransactionId ? (
            <Link
              to={`${FINANCE_ENUM.TRUST_WALLET}/e-pin/inventory/view/${row.id}`}
              className='table-avatar'
            >
              <img src={!isPrivateMode && row.logo ? row.logo : emptyUserImage} alt='vendor-logo' />
              <div>{name}</div>
            </Link>
          ) : (
            formatNumberToLocale(row?.denomination)
          )}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Current Balance',
      dataIndex: 'balance',
      sorter: true,
      width: '15%',
      render: (balance) => (
        <RecursivelyReplaceCharacters>
          {balance && formatNumberToLocale(balance)}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Available PINS',
      dataIndex: 'ePinAvailableAmount',
      sorter: true,
      width: '15%',
      render: (availableAmount, row) => (
        <RecursivelyReplaceCharacters>
          {formatNumberToLocale(availableAmount ?? row?.available)}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Used PINS',
      dataIndex: 'ePinUsedAmount',
      sorter: true,
      width: '15%',
      render: (usedAmount, row) => (
        <RecursivelyReplaceCharacters>
          {formatNumberToLocale(usedAmount ?? row?.used)}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Invalid PINS',
      dataIndex: 'ePinInvalidAmount',
      sorter: true,
      width: '15%',
      render: (invalidAmount, row) => (
        <RecursivelyReplaceCharacters>
          {formatNumberToLocale(invalidAmount ?? row?.invalid)}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Last Update',
      dataIndex: 'lastPurchasedAt',
      width: '20%',
      sorter: true,
      render: (date, { lastPO }) =>
        lastPO && (
          <div className='table-date'>
            <DateTableIcon />
            <RecursivelyReplaceCharacters>
              {formatDateWithTime(lastPO, profile?.calendar)}
            </RecursivelyReplaceCharacters>
          </div>
        ),
    },
    {
      title: '',
      dataIndex: 'edit',
      width: '1%',
      key: 'edit',
      render: (_, row) =>
        !row?.mainTransactionId && (
          <Popup data={tableActionsPopup(row?.id)}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        ),
    },
  ]

  return (
    <div>
      {/*<VendorManagementTableHeader*/}
      {/*  setActiveFilters={handleFiltersChange}*/}
      {/*  filterValues={filterValues}*/}
      {/*  numberText='Pins'*/}
      {/*/>*/}
      <Table
        columns={columns}
        locale={emptyTableConf}
        onChange={handleTableChange}
        className={styles.table}
        loading={isFetching}
        dataSource={data?.items}
        scroll={{ x: 1000 }}
        pagination={pagination}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.id}
        expandable={{
          expandedRowKeys,
          expandIcon: (props) => {
            if (!Object.hasOwn(props.record, 'children')) return null
            return tableExpandedIcon<IEpinTable>(props)
          },
          onExpandedRowsChange: (expandedKeys) => setExpandedRowKeys([...expandedKeys]),
        }}
      />
    </div>
  )
}
