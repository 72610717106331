import { Switch, Table } from 'antd'
import { VendorManagementTableHeader } from '../TableHeader'
import { ITableConf, SortingOrderEnum } from 'src/shared/models/ITableConf'
import { useCallback, useState } from 'react'
import { ISelectedFilters } from 'src/shared/components/filter/Filter'
import { ColumnsType } from 'antd/es/table'
import { IVendorManagement, IVendorTable } from '../../../../models/IVendorManagement'
import { DateTableIcon, TableActionIcon } from 'src/assets/svg'
import { IPopupListItems, Popup } from 'src/shared/components/popup/Popup'
import styles from './style.module.scss'
import emptyAvatarImage from 'src/assets/img/ProductTablePlaceholder.png'
import { Link, useNavigate } from 'react-router-dom'
import {
  useGetVendorListQuery,
  useGetVendorManagementFiltersListQuery,
  useLazyExportVendorsListTableQuery,
  useUpdateVendorMutation,
} from '../../../../core/http/VendorManagementApi'
import { extractLinkDomain, formatDateWithTime, toSpaceCase } from 'src/helpers/utils'
import { useTableExport } from '../../../../../../shared/hooks/table/useTableExport'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../shared/api/errorHandler'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IProps {
  canAdd: boolean
  canEdit: boolean
  canDelete?: boolean
}

export const VendorManagementTable = ({ canAdd, canEdit }: IProps) => {
  const navigate = useNavigate()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data: profile } = useGetProfileQuery()

  const { data: filtersList } = useGetVendorManagementFiltersListQuery()
  const { data = { items: [], totalCount: 0 }, isFetching } = useGetVendorListQuery(
    {
      ...filterValues,
      ...tableConf,
      allVendorsType: false,
    },
    { skip: Object.keys(tableConf).length < 4 }
  )

  const emptyTableConf = useEmptyTable(canAdd ? () => navigate('new') : undefined)
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IVendorTable>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  const [updateVendor, upVenResp] = useUpdateVendorMutation()

  const [exportTable] = useLazyExportVendorsListTableQuery()
  useTableExport({ tableConf, filterValues, promise: exportTable })

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, upVenResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, upVenResp.isError, upVenResp.error as ErrorNode)

  const tableActionsPopup = useCallback(
    (id: string): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () => navigate(`view/${id}`),
        },
        {
          text: 'Edit',
          shouldDisplay: canEdit,
          onClick: () => navigate(`edit/${id}`),
        },
      ]
    },
    [navigate]
  )

  const columns: ColumnsType<IVendorTable> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Account Id',
      dataIndex: 'id',
      sorter: true,
      width: '10%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      width: '30%',
      render: (companyName: IVendorTable['name'], row) => (
        <RecursivelyReplaceCharacters>
          <Link to={`view/${row.id}`} className='table-avatar'>
            <img
              alt='vendor logo'
              src={!isPrivateMode && row.logo ? row.logo : emptyAvatarImage}
              loading='lazy'
            />
            <div>{companyName}</div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Vendor',
      dataIndex: 'vendorType',
      sorter: true,
      width: '10%',
      render: (vendorType) => (
        <RecursivelyReplaceCharacters>{toSpaceCase(vendorType)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Website',
      dataIndex: 'website',
      sorter: true,
      width: '10%',
      render: (link: IVendorTable['website']) =>
        link && (
          <a
            className='table-link'
            href={`https://${extractLinkDomain(link)}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <RecursivelyReplaceCharacters>{extractLinkDomain(link)}</RecursivelyReplaceCharacters>
          </a>
        ),
    },
    {
      title: 'Added by',
      dataIndex: 'createdByUser',
      width: '20%',
      sorter: true,
      render: (createdBy: IVendorTable['createdByUser']) => (
        <RecursivelyReplaceCharacters>{createdBy.name}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      width: '20%',
      sorter: true,
      render: (createdAt: IVendorTable['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(createdAt, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: {},
      width: '10%',
      render: (status: IVendorManagement['status'], row) => {
        const fd = new FormData()
        return (
          <Switch
            disabled={!canEdit}
            checked={status}
            onChange={(status) => {
              fd.append('vendor', JSON.stringify({ status }))
              updateVendor({
                id: row.id,
                body: fd,
              })
            }}
            loading={upVenResp.isLoading}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return (
    <div>
      <VendorManagementTableHeader
        data={filtersList}
        setActiveFilters={handleFiltersChange}
        numberOfItems={data?.totalCount}
        filterValues={filterValues}
      />
      <Table
        columns={columns}
        locale={emptyTableConf}
        onChange={handleTableChange}
        className={styles.table}
        loading={isFetching}
        dataSource={data.items}
        scroll={{ x: 1000 }}
        pagination={pagination}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.id}
      />
    </div>
  )
}
