import { IByUser } from '../../../shared/models/IByUser'
import { CUSTOMER_RESTRICTION_REASON_ENUM } from '../../Support/models/Restrictions'

export enum RESPONSE_CODE_CHANNEL {
  API = 'API',
  USSD = 'USSD',
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
}

interface IResponseCodeMessage {
  id: string
  channel: RESPONSE_CODE_CHANNEL
  en: string
  prs: string
  ps: string
}

interface IVendorCode {
  id: string
  vendorCode: string
  vendor: IByUser
}

export interface IResponseCode {
  code: string
  responseType: RESPONSE_CODE_TYPE_ENUM
  vendorCodes: Array<IVendorCode>
  messages: Array<IResponseCodeMessage>
  description: string
  addToRestriction: boolean
  restrictionReason: CUSTOMER_RESTRICTION_REASON_ENUM
  operators: Array<string>
  operatorsCode: Array<string>
  createdAt: Date
  createdByUser: IByUser
}

export interface IResponseCodeMutation extends Omit<IResponseCode, 'vendorCodes'> {
  vendorCodes: Array<{ vendorCode: string; vendorId: number }>
}

export interface IResponseCodeTable {
  code: string
  responseType: RESPONSE_CODE_TYPE_ENUM
  channels: Array<string>
  vendorCodes: Array<string>
}

export enum RESPONSE_CODE_TYPE_ENUM {
  TRANSITION = 'TRANSACTION',
  INTERNAL = 'INTERNAL',
  HAWALA = 'HAWALA',
}
