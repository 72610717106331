/* eslint-disable */
import { memo, ReactNode, useState } from 'react'
import { Select as AntSelect, SelectProps } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import classNames from 'classnames'
import { noop } from 'lodash'

import './Select.scss'

interface IProps extends SelectProps {
  children: ReactNode
  onChange?: (val: DefaultOptionType) => void
  defaultValue?: string | number | null
  className?: string
  label?: string
  disabled?: boolean
  view?: boolean
  id?: string
  value?: string
  placeholder?: string
  allowClear?: boolean
  onClearHandle?: () => void
  open?: boolean
}

export const Select = memo(
  ({
    children,
    defaultValue = null,
    className = '',
    disabled = false,
    view = false,
    id,
    value,
    placeholder,
    allowClear = false,
    open,
    onChange,
    onClearHandle = noop,
  }: IProps) => {
    const [selectedItem, setSelectedItem] = useState<string | number | null>(defaultValue)
    const handleChange = (option: DefaultOptionType) => {
      option && setSelectedItem(option.value as string)
      onChange && onChange(option)
    }

    const handleClear = () => {
      onClearHandle?.()
      setSelectedItem(null)
    }

    return (
      <div
        className={classNames('select', {
          'select-view': view,
        })}
      >
        <AntSelect
          placeholder={placeholder}
          id={id}
          labelInValue={true}
          className={className}
          style={{ width: '100%' }}
          /* @ts-ignore */
          defaultValue={selectedItem}
          /* @ts-ignore */
          value={value}
          onChange={handleChange}
          showArrow
          showSearch={false}
          disabled={disabled || view}
          allowClear={allowClear}
          open={open}
          onClear={handleClear}
          // suffixIcon={
          //   !view && (
          //     <div className='wrapper-arrow'>
          //       <ArrowDropDownRounded />
          //     </div>
          //   )
          // }
        >
          {children}
        </AntSelect>
      </div>
    )
  }
)
