import React from 'react'
import PermissionDenied from '../permissionDenied'
import { useAppSelector } from '../../../redux'
import { selectRolesAndPermissionsManagementState } from '../../../features/Settings/core/store/RoleAndPermissionsManagementSlice'
import { Spin } from 'antd'

interface IProps {
  isAllowedView: boolean
  children: React.ReactNode
}

const WithPermissionDeniedContainer = ({ isAllowedView, children }: IProps) => {
  const { loading } = useAppSelector(selectRolesAndPermissionsManagementState)

  return !loading ? (
    <>{isAllowedView ? <>{children}</> : <PermissionDenied />}</>
  ) : (
    <Spin>
      <div
        style={{
          width: '100%',
          height: '100&',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    </Spin>
  )
}

export default WithPermissionDeniedContainer
