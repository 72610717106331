/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import { Gallery, Image } from 'react-grid-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
import heic2any from 'heic2any'

import styles from './styles.module.scss'
import { IInternalChatMessageFile } from '../../../../models'
import { CloseIcon, DownloadIcon } from '../../../../../../assets/svg'
import { IExternalChatMessageFile } from '../../../../../Support/models/IExternalChat'
import { convertHeicToJpeg, downloadFileThroughATag } from '../../../../../../helpers/utils'
import { Spin } from 'antd'

interface IProps {
  images: Array<IInternalChatMessageFile | IExternalChatMessageFile>
}

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const ImagesImMessage = ({ images }: IProps) => {
  const [isFetching, setIsFetching] = useState(false)
  const [files, setFiles] = useState<Array<Image>>([])
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const convertImages = useCallback(async () => {
    setIsFetching(true)
    const imagesData = await Promise.all(
      images.map(async (el) => ({
        src: await convertHeicToJpeg(el.file?.link, el.file?.fileFormat),
        width: randomIntFromInterval(150, 200),
        height: randomIntFromInterval(100, 120),
        key: el.file?.link || '',
        alt: el.file?.name || '',
      }))
    )
    setIsFetching(false)

    setFiles(imagesData)
  }, [images])

  const openLightbox = useCallback((index: number) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const onPressDownloadImage = (link: string, fileName: string) => {
    downloadFileThroughATag(link, fileName)
  }

  const onViewChange = (id: number) => setCurrentImage(id)

  useEffect(() => {
    convertImages()
  }, [convertImages])

  return (
    <div className={styles.filesImagesContainer}>
      <div style={{ overflow: 'hidden', width: '100%' }}>
        {isFetching ? (
          <Spin size='small' />
        ) : (
          <Gallery images={files} enableImageSelection={false} onClick={openLightbox} margin={1} />
        )}
      </div>
      {/* @ts-ignore */}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox} allowFullscreen={false}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div className={styles.topLineContainer}>
                <div
                  className={styles.topLineIcon}
                  onClick={() =>
                    onPressDownloadImage(files[currentImage].src, files[currentImage].alt as string)
                  }
                >
                  <DownloadIcon color='#FFFFFF' />
                </div>
                <div className={styles.topLineIcon} onClick={closeLightbox}>
                  <CloseIcon color='#FFFFFF' />
                </div>
              </div>
              <Carousel
                currentIndex={currentImage}
                views={files.map((x) => ({
                  ...x,
                  source: x.src,
                }))}
                trackProps={{ onViewChange }}
              />
            </div>
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}
