import { useContext, useEffect } from 'react'
import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import FormItem from 'antd/es/form/FormItem'

//components
import { CenterModalContext } from 'src/features/Modals'
import { TextField } from 'src/shared/components/textfield/TextField'

// styles
import styles from './styles.module.scss'

import { requiredValidator } from 'src/helpers/validation'
import { IStep2FormInstance } from '../GoogleAuthenticatorModal'
import { useGoogleAuthDisableMutation } from 'src/features/authorization/core/http/TwoFactorAuthApi'
import { Emitter, EmitterEvents } from 'src/helpers/eventEmitter'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { profileApi } from 'src/features/Profile/core/http/Profile'
import { useDispatch } from 'react-redux'
import { saveAuthData } from 'src/shared/api'

export const DisableGoogleAuthModal = () => {
  const {
    onClose,
    props: { content },
  } = useContext(CenterModalContext)
  const dispatch = useDispatch()
  const [form] = useForm()
  const [disableGoogleAuth, { data, isSuccess, isError, error }] = useGoogleAuthDisableMutation()

  useNotification(NOTIFICATION_TYPES.error, isError, error as ErrorNode, () => form.resetFields())
  useNotification(NOTIFICATION_TYPES.success, isSuccess, null, () => {
    dispatch(profileApi.util.invalidateTags(['IProfile']))
    saveAuthData(data!)
    onClose()
  })

  useEffect(() => {
    Emitter.on(EmitterEvents.DISABLE_GOOGLE_AUTH, form.submit)

    return () => {
      Emitter.off(EmitterEvents.DISABLE_GOOGLE_AUTH)
    }
    // eslint-disable-next-line
  }, [])

  const onFinish = (values: IStep2FormInstance) => {
    disableGoogleAuth(values)
  }

  return (
    <div className={styles.container}>
      {content}
      <Form form={form} layout='vertical' onFinish={onFinish} className={styles.formContainer}>
        <FormItem
          name='code'
          className={styles.codeInput}
          rules={[{ validator: requiredValidator('Verification code') }]}
        >
          <TextField placeholder='Verification code' type='number' />
        </FormItem>
      </Form>
    </div>
  )
}
