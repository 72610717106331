import { Tag } from 'antd'
import { CustomTagProps } from 'rc-select/es/BaseSelect'
import React from 'react'

export const selectTagRender = (props: CustomTagProps) => {
  const { label, closable, onClose } = props
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <Tag
      color='#0185d4'
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  )
}
