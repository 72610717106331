import React from 'react'
import { Form, Spin } from 'antd'
import { useContext } from 'react'
import { startCase } from 'lodash'
import {
  useApproveReverseTransMutation,
  useCancelReverseTransMutation,
  useGetReverseTransactionByIdQuery,
  useRejectReverseTransMutation,
  useGetReverseTopupSubTransactionsQuery,
} from '../../../../../../Finance/core/http/ReverseTransactionApi'
import { RightModalContext } from '../../../../../../Modals'
import VendorInformation from '../Components/VendorInformation'
import { Details } from '../Components/Details'
import { Footer } from '../Components/Footer'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { ReverseAgentAccountSummary } from './AgentAccountSummary'
import { SubTransactionDetail } from '../Components/SubTransactionDetail'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { formatDateWithTime } from '../../../../../../../helpers/utils'
import {
  IReverseTransactionDetail,
  IReverseTransApproveBody,
} from '../../../../../../Finance/models/IReverseTransaction'
import { TRANSACTION_STATUS_ENUM } from '../../../../../../Finance/models/ITransaction'

import styles from '../styles.module.scss'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { RequestHistory } from '../Components/RequestHistory'
import { useGetVendorRequestListQuery } from '../../../../../core/ExternalTransactionApi'

export const ReverseTransactionDetailModal = () => {
  const [form] = Form.useForm()
  const {
    onClose,
    props: { id },
  } = useContext(RightModalContext)

  const { data, isFetching } = useGetReverseTransactionByIdQuery(id, { skip: !id })
  const [apprRT, apprRTResp] = useApproveReverseTransMutation()
  const [cancRT, cancRTResp] = useCancelReverseTransMutation()
  const [rejectRT, rejectRTResp] = useRejectReverseTransMutation()

  const { data: profile } = useGetProfileQuery()
  const { data: requestsHistory, isFetching: isFetchingHistory } = useGetVendorRequestListQuery(
    {
      reverseTopupId: id as number,
    },
    {
      skip: !id,
    }
  )
  const { data: subtransactions, isFetching: isss } = useGetReverseTopupSubTransactionsQuery(id, {
    skip: !id,
  })

  const onApproveButtonClick = () => {
    const formValues = form.getFieldsValue() as IReverseTransactionDetail
    const body = {
      vendorAmount: formValues?.vendorAmount,
      ...(formValues?.vendorResponseMessage
        ? { vendorResponseMessage: formValues?.vendorResponseMessage }
        : {}),
      ...(formValues?.vendorTransactionId
        ? { vendorTransactionId: formValues?.vendorTransactionId }
        : {}),
    } as IReverseTransApproveBody

    apprRT({ id, body })
  }

  const isCreatedStatus = data?.status === TRANSACTION_STATUS_ENUM.CREATED
  const shouldDisplaySubTrans = !!subtransactions?.items?.length
  const isLoading =
    isss ||
    isFetching ||
    apprRTResp.isLoading ||
    cancRTResp.isLoading ||
    rejectRTResp.isLoading ||
    isFetchingHistory

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, apprRTResp.isSuccess, null, onClose)
  useNotification(NOTIFICATION_TYPES.success, cancRTResp.isSuccess, null, onClose)
  useNotification(NOTIFICATION_TYPES.success, rejectRTResp.isSuccess, null, onClose)

  useNotification(NOTIFICATION_TYPES.error, cancRTResp.isError, cancRTResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, rejectRTResp.isError, rejectRTResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, apprRTResp.isError, apprRTResp.error as ErrorNode)

  const content = (
    <Form
      form={form}
      className={styles.formContainer}
      initialValues={{
        ...data,
        createdAt: formatDateWithTime(data?.createdAt, profile?.calendar),
        ...(data?.agent && {
          location: `${data?.agent?.province?.name} ${data?.agent?.district?.name}`,
        }),
      }}
      layout='vertical'
    >
      <Details isReverse isCreatedStatus={isCreatedStatus} status={data?.status} />
      <ReverseAgentAccountSummary isCreatedStatus={isCreatedStatus} />
      {requestsHistory && requestsHistory?.items?.length > 1 && (
        <Wrapper title='Request History'>
          <Form.List name='requestHistory'>
            {(fields) => (
              <>
                {fields?.length > 0
                  ? fields.map(({ key, name }) => {
                      return <RequestHistory key={key} name={name} />
                    })
                  : null}
              </>
            )}
          </Form.List>
        </Wrapper>
      )}
      <VendorInformation />
      {shouldDisplaySubTrans && (
        <Wrapper title='Sub Transaction Detail'>
          <Form.List
            name='topupSubTransactions'
            initialValue={subtransactions?.items.map((trans) => ({
              ...trans,
              sender: startCase(trans[trans?.sender]?.name.toLowerCase()),
              receiver: startCase(trans[trans?.receiver]?.name.toLowerCase()),
              createdAt: formatDateWithTime(trans?.createdAt, profile?.calendar),
            }))}
          >
            {(fields) => (
              <>
                {fields.map(({ key, ...rest }) => (
                  <SubTransactionDetail key={key} {...rest} />
                ))}
              </>
            )}
          </Form.List>
        </Wrapper>
      )}
      {isCreatedStatus && (
        <Footer
          onApprove={onApproveButtonClick}
          onReject={() => rejectRT(id)}
          onCancel={() => cancRT(id)}
        />
      )}
    </Form>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
