import React, { memo } from 'react'
import styles from '../../styles.module.scss'

interface IProps {
  name: string
  onPressDetails: () => void
}

const ForwardMessageTitle = ({ name, onPressDetails }: IProps) => {
  return (
    <div className={styles.forwardedFrom} onClick={onPressDetails}>
      <span className={styles.forwardedFromTitle}>Forwarded from:</span> {name}
    </div>
  )
}
export default memo(ForwardMessageTitle)
