import FormItem from 'antd/es/form/FormItem'
import styles from '../../../../../Settings/pages/UserManagement/Components/Tabs/Departments/Components/DepartmentInfo/styles.module.scss'
import { TextField } from '../../../../../../shared/components/textfield/TextField'
import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import React, { useMemo } from 'react'
import { Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import {
  CUSTOMER_RESTRICTION_REASON_ENUM,
  AGENT_RESTRICTION_REASON_ENUM,
  RESTRICTION_TYPE_ENUM,
} from '../../../../models/Restrictions'
import { requiredMulSelectValidator, requiredValidator } from '../../../../../../helpers/validation'
import { selectTagRender } from '../../../../../../helpers/selectTagRender'

interface IIProps {
  isViewMode?: boolean
  isAgentPage?: boolean
  contactListOptions?: Array<{ label: string; value: number | string }>
}

export const RestrictionDetailInfo = ({ isViewMode, isAgentPage, contactListOptions }: IIProps) => {
  const reasonOptions = useMemo(() => {
    const reasonEnum = isAgentPage
      ? AGENT_RESTRICTION_REASON_ENUM
      : CUSTOMER_RESTRICTION_REASON_ENUM

    return (Object.keys(reasonEnum) as Array<keyof typeof reasonEnum>).map((method) => ({
      label: reasonEnum[method],
      value: reasonEnum[method],
    }))
  }, [isAgentPage])

  const typeOfRestrictions = useMemo(
    () =>
      (Object.keys(RESTRICTION_TYPE_ENUM) as Array<keyof typeof RESTRICTION_TYPE_ENUM>).map(
        (method) => ({
          label: RESTRICTION_TYPE_ENUM[method],
          value: RESTRICTION_TYPE_ENUM[method],
        })
      ),
    []
  )

  return (
    <Wrapper title='Detail info'>
      <div className={styles.dblRow}>
        <FormItem name='createdAt' label='Creation Date'>
          <TextField disabled />
        </FormItem>

        <FormItem name='createdBy' label='Created By'>
          <TextField disabled />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem noStyle shouldUpdate>
          {() => {
            return isAgentPage ? (
              <FormItem name={['agentIdentifier', 'id']} label='Phone number'>
                <Select
                  allowClear
                  disabled={isViewMode || !contactListOptions?.length}
                  options={contactListOptions || []}
                  placeholder='Select Phone Number'
                />
              </FormItem>
            ) : (
              <FormItem
                name={['customer', 'phoneNumber']}
                rules={[{ required: true, validator: requiredValidator('Phone number') }]}
                label='Phone number'
              >
                <TextField disabled={isViewMode} placeholder='Enter Phone Number' />
              </FormItem>
            )
          }}
        </FormItem>

        <FormItem
          name='reason'
          label='Reason'
          rules={[{ required: true, validator: requiredValidator('Reason') }]}
        >
          <Select
            disabled={isViewMode}
            options={reasonOptions}
            placeholder='Select Reason'
            allowClear
          />
        </FormItem>
      </div>

      <div>
        <FormItem
          name='restrictionTypes'
          label='Type of Restriction'
          rules={[{ required: true, validator: requiredMulSelectValidator('Restriction Types') }]}
        >
          <Select
            mode='multiple'
            disabled={isViewMode}
            placeholder='Select Restriction Type'
            options={typeOfRestrictions}
            tagRender={selectTagRender}
            maxTagCount='responsive'
            showArrow
          />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem name='description' label='Description'>
          <TextArea placeholder='Enter Description' rows={5} disabled={isViewMode} />
        </FormItem>
      </div>
    </Wrapper>
  )
}
