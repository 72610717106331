import classNames from 'classnames'
import { ReactNode } from 'react'
import styles from './styles.module.scss'

interface IWrapper {
  children?: ReactNode
  title: string
  className?: string
  statusButton?: ReactNode
}
export const Wrapper = ({ children, title, className, statusButton }: IWrapper) => {
  const titleWithStatusButton = (
    <div className={styles['wrapper-title-and-status']}>
      <div className={styles['wrapper-title']}>{title} </div>
      {statusButton && statusButton}
    </div>
  )

  return (
    <div className={classNames(styles['wrapper'], className)}>
      {statusButton ? (
        titleWithStatusButton
      ) : (
        <div className={styles['wrapper-title']}>{title} </div>
      )}
      {children}
    </div>
  )
}
