import { Col, Divider, Skeleton, Spin } from 'antd'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

// helpers
import { RightModalContext } from 'src/features/Modals'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
// api
import styles from './styles.module.scss'

import { ErrorNode } from 'src/shared/api/errorHandler'
import {
  useAssignContactsToVendorMutation,
  useGetVendorContactFiltersListQuery,
  useLazyGetVendorContactListQuery,
} from 'src/features/Settings/core/http/VendorManagementApi'
import { AddIcon, ArrowRightIcon, BucketIcon } from 'src/assets/svg'
import { TextFieldSearch } from 'src/shared/components/textFieldSearch/TextFieldSearch'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { ContactListModalHeader } from './Components/ContactListModalHeader'
import { ISelectedFilters } from 'src/shared/components/filter/Filter'
import { useNavigate, useLocation } from 'react-router-dom'
import { IVendorContact } from 'src/features/Settings/models/IVendorManagement'
import useSearch from 'src/features/Settings/helpers/useSearch'
import { nanoid } from 'nanoid'
import { InfoMessage } from './Components/InfoMessage'
import { IProduct } from '../../../models/IProduct'
import { useDispatch, useSelector } from 'react-redux'
import {
  addContact,
  deleteContactById,
  getContactListSelector,
} from 'src/features/Settings/core/store/VendorManagementSlice'
import { Button } from '../../../../../shared/components/button/Button'
import emptyAvatar from '../../../../../assets/img/Avatar.png'
import { ITableConf } from '../../../../../shared/models/ITableConf'
import { isEmpty, unionBy } from 'lodash'

export const VendorManagementContactListModal = () => {
  const {
    onClose,
    props: { vendorId },
  } = useContext(RightModalContext)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const contactList: IVendorContact[] = useSelector(getContactListSelector)

  const [filterValues, setFilterValues] = useState<ISelectedFilters>({})
  const [pageConf, setPageConf] = useState<ITableConf>({
    page: 1,
    limit: 10,
    search: '',
  })

  const { handleSearchChange } = useSearch(setPageConf)

  const [dataVendorContactList, setDataVendorContactList] = useState<IVendorContact[]>([])

  const [
    getVendorContactlist,
    { data: vendorContactList, isError: isErrorVendorContactList, error: errorVendorContactList },
  ] = useLazyGetVendorContactListQuery()
  const [assignContacts, assignContactsResp] = useAssignContactsToVendorMutation()

  const [isChangeSearch, setIsChangeSearch] = useState(false)

  useEffect(() => {
    getVendorContactlist({ ...pageConf, ...filterValues })
  }, [pageConf, filterValues])

  useEffect(() => {
    if (isEmpty(vendorContactList)) return

    if (isChangeSearch && (pageConf.search || Object.keys(filterValues).length !== 0)) {
      setDataVendorContactList(vendorContactList!.items)
      setIsChangeSearch(false)
    } else {
      setDataVendorContactList((pre) => unionBy([...pre, ...vendorContactList!.items], 'id'))
    }
  }, [vendorContactList])

  const loadMoreData = () => {
    setPageConf((pre) => ({ ...pre, page: pre.page! + 1 }))
  }

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, assignContactsResp.isSuccess, null, onClose)
  useNotification(
    NOTIFICATION_TYPES.error,
    isErrorVendorContactList,
    errorVendorContactList as ErrorNode
  )
  useNotification(
    NOTIFICATION_TYPES.error,
    assignContactsResp.isError,
    assignContactsResp.error as ErrorNode
  )

  const { data: filtersList } = useGetVendorContactFiltersListQuery()

  const searchComponent = (
    <TextFieldSearch
      className={styles.search}
      onChange={(e) => {
        setIsChangeSearch(true)
        handleSearchChange(e)
      }}
    />
  )

  const onClickContact = (contactId: number, vId: number) => () => {
    onClose()
    navigate(`settings/vendor-management/contact/view/${contactId}`)
  }

  const onAddContact = (contact: IVendorContact) => () => {
    if (vendorId) {
      return assignContacts({ id: vendorId, body: { items: [contact.id] } })
    }
    dispatch(addContact(contact))
  }

  const onDeleteContact = (contact: IVendorContact) => () => {
    dispatch(deleteContactById(contact.id))
  }

  const addButtonComponent = (
    <Col>
      <IconButton
        className={styles.addBtn}
        icon={<AddIcon />}
        type='primary'
        color='orange'
        size='large'
        onClick={() => {
          onClose()
          navigate(`/settings/vendor-management/new-contact`, {
            state: { prevPath: pathname },
          })
        }}
      />
    </Col>
  )
  const content = (
    <InfiniteScrollComponent
      data={dataVendorContactList}
      loadMoreData={loadMoreData}
      totalCount={vendorContactList?.totalCount as number}
    >
      <div className={styles.wrapperInfoMessage}>
        <InfoMessage />
      </div>
      <div className={styles.wrapperSearchAndFilerAndAddButton}>
        <ContactListModalHeader
          classNameFilterPopup={styles.filterPopup}
          setActiveFilters={(res) => {
            setIsChangeSearch(true)
            setFilterValues(res)
          }}
          filterValues={filterValues}
          data={filtersList}
          searchComponent={searchComponent}
          addButtonComponent={addButtonComponent}
          classNamesFilterWrapperIcon={styles.filterWrraperIcon}
        />
      </div>

      <div className={styles.amountContacts}>{vendorContactList?.totalCount || 0} contacts</div>
      {dataVendorContactList.map((contact) => {
        const isAddedContact = contactList.some((storeContact) => storeContact.id === contact.id)

        return (
          <Contact
            key={nanoid()}
            contact={contact}
            onClick={onClickContact(contact.id, contact.vendor?.id as number)}
            onClickConfirm={isAddedContact ? onDeleteContact : onAddContact}
            isConfirm={isAddedContact}
          />
        )
      })}
    </InfiniteScrollComponent>
  )

  return !vendorContactList ? <Spin>{content}</Spin> : content
}

interface IContact {
  contact: IVendorContact
  onClick?: () => void
  onClickConfirm: (contact: IVendorContact) => () => void
  isConfirm?: boolean
  onVendorCreate?: boolean
}

export const Contact = ({
  contact,
  onClick,
  onClickConfirm,
  isConfirm,
  onVendorCreate,
}: IContact) => {
  const { name, avatar, department, position, vendor } = contact
  return (
    <div className={styles.contact}>
      <div className={styles.contactContent}>
        <div className={styles.contactAvatar}>
          <img src={avatar || emptyAvatar}></img>
        </div>
        <div className={styles.contactDescriptions}>
          <div className={styles.contactName}>{name}</div>
          <div className={styles.contactdepartmentAndPostion}>
            <div className={styles.contactDepartment}>{department}</div>
            <div className={styles.circle}></div>
            <div className={styles.contactPosition}>{position}</div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', gridGap: 10 }}>
        {onVendorCreate ? (
          <div style={{ width: 35 }}>
            <IconButton
              block
              size='large'
              color='orange'
              icon={<BucketIcon />}
              type='default'
              onClick={(e) => {
                e.stopPropagation()
                onClickConfirm(contact)()
              }}
            />
          </div>
        ) : (
          <>
            {!vendor && (
              <div className={styles.selectButton}>
                <Button
                  color='orange'
                  type={isConfirm ? 'primary' : 'default'}
                  onClick={(e) => {
                    e.stopPropagation()
                    onClickConfirm(contact)()
                  }}
                >
                  Select
                </Button>
              </div>
            )}

            <div className={styles.redirectButton}>
              <IconButton color='blue' icon={<ArrowRightIcon />} onClick={onClick} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

interface IInfiniteScrollComponent {
  loadMoreData: () => void
  data: IVendorContact[] | IProduct[]
  totalCount: number
  children: ReactNode
}

export const InfiniteScrollComponent = ({
  loadMoreData,
  data,
  totalCount,
  children,
}: IInfiniteScrollComponent) => {
  return (
    <div
      id='scrollableDiv'
      style={{
        height: '100%',
        overflow: 'auto',
        padding: 24,
        paddingBottom: 0,
      }}
    >
      <InfiniteScroll
        className={styles.infiniteScroll}
        dataLength={data.length}
        next={loadMoreData}
        hasMore={data.length < totalCount}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        endMessage={<Divider plain>It is all, nothing more</Divider>}
        scrollableTarget='scrollableDiv'
      >
        {children}
      </InfiniteScroll>
    </div>
  )
}
