import styles from '../styles.module.scss'
import { PromotionPolicyDetailViewTabs } from './Components/Tabs/index'

export const PromotionPolicyView = () => {
  return (
    <div className={styles.layout}>
      <PromotionPolicyDetailViewTabs />
    </div>
  )
}
