import { useContext, useEffect, useMemo } from 'react'
import { Tabs } from 'antd'
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { VENDOR_MANAGEMENT_VIEW_TABS_ENUM } from './index.d'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { AddIcon, DownloadIcon, EditIcon } from 'src/assets/svg'
import { Toggle } from 'src/shared/components/toggle/Toggle'
import { Button } from 'src/shared/components/button/Button'
import VendorInfoTab from './VendorInfoTab'
import ProductsTable from './ProductsTable'
import ContactsTable from './ContactsTable'
import IntegrationsTable from './Integrations'
import VariablesTable from './Variables'
import {
  useGetVendorByIdQuery,
  useUpdateVendorMutation,
} from '../../../../../core/http/VendorManagementApi'
import { CENTER_MODALS, RIGHT_MODALS } from 'src/helpers/contants'

import { SETTINGS_ENUM } from '../../../../../../../routes/settings'
import { CenterModalContext, RightModalContext } from '../../../../../../Modals'
import { Emitter, EmitterEvents } from '../../../../../../../helpers/eventEmitter'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'
import { setBreadcrumb } from 'src/features/app/core/BreadcrumbsSlice'
import { TableExportFormatEnum } from 'src/shared/hooks/table/useTableExport'
import { SimCards } from './SimCards'
import { isEmpty } from 'lodash'
import { useAppDispatch } from '../../../../../../../redux'
import { VENDOR_TYPE_ENUM } from '../../../../../models/IVendorManagement'
import Gateway from './Gateway'

const Operations = ({ activeTab }: { activeTab: string }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { onOpen } = useContext(RightModalContext)
  const { onOpen: onCenterModalOpen, onClose: onCenterModalClose } = useContext(CenterModalContext)

  const { mode, id } = useParams<{ mode: 'view' | 'edit'; id: string }>()

  const [updateVendor, upVenResp] = useUpdateVendorMutation()
  const { data, isFetching } = useGetVendorByIdQuery(id as string, { skip: !id })

  useEffect(() => {
    if (isEmpty(data)) return

    dispatch(setBreadcrumb({ name: data.name }))
  }, [data, dispatch])

  const onStatusChange = (checked: boolean) => {
    const fd = new FormData()
    fd.append('vendor', JSON.stringify({ status: checked }))
    updateVendor({ id: id, body: fd })
  }

  const onAddButtonClick = () => {
    switch (activeTab) {
      case VENDOR_MANAGEMENT_VIEW_TABS_ENUM.INTEGRATIONS: {
        return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/${id}/integrations/new`)
      }
      case VENDOR_MANAGEMENT_VIEW_TABS_ENUM.PRODUCTS: {
        return onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_PRODUCT_LIST, {
          title: 'Add Products',
          vendorId: id,
          assignToVendor: true,
        })
      }
      case VENDOR_MANAGEMENT_VIEW_TABS_ENUM.CONTACTS: {
        return onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_CONTACT_LIST, {
          title: 'Add Contacts',
          vendorId: id,
          assignToVendor: true,
        })
      }

      case VENDOR_MANAGEMENT_VIEW_TABS_ENUM.VARIABLES: {
        return onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_VARIABLES, {
          title: 'Add Variable',
          vendorId: id,
        })
      }

      case VENDOR_MANAGEMENT_VIEW_TABS_ENUM.SIM_CARDS: {
        return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${id}/sim-card/new`)
      }

      case VENDOR_MANAGEMENT_VIEW_TABS_ENUM.GATEWAY: {
        return onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_GATEWAY, {
          title: 'Add Gateway',
          vendorId: id,
        })
      }

      default:
        return
    }
  }

  const handlerExportClick = () => {
    onCenterModalOpen(CENTER_MODALS.EXPORT_TABLE, {
      title: 'Export Table',
      onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
      onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      footer: <Button onClick={onCenterModalClose}>Cancel</Button>,
    })
  }

  const isVendorInfoActive = activeTab === VENDOR_MANAGEMENT_VIEW_TABS_ENUM.VENDOR_INFORMATION
  const shouldDisplayEdit = mode === 'view' && isVendorInfoActive

  return (
    <div style={{ display: 'flex', gridGap: 10 }}>
      {shouldDisplayEdit && (
        <IconButton
          icon={<EditIcon />}
          color='orange'
          onClick={() => navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/edit/${id}`)}
        />
      )}
      {!isVendorInfoActive && (
        <IconButton icon={<DownloadIcon />} color='blue' onClick={handlerExportClick} />
      )}
      <Toggle
        defaultChecked={data?.status}
        checked={data?.status}
        text='Status'
        inWrapper
        loading={upVenResp.isLoading || isFetching}
        onChange={onStatusChange}
      />
      {!isVendorInfoActive && (
        <IconButton icon={<AddIcon />} color='orange' type='primary' onClick={onAddButtonClick} />
      )}
    </div>
  )
}

export const VendorManagementViewTabs = () => {
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: VENDOR_MANAGEMENT_VIEW_TABS_ENUM.VENDOR_INFORMATION,
  })
  const { id } = useParams<{ mode: 'view' | 'edit'; id: string }>()

  const { data } = useGetVendorByIdQuery(id as string, { skip: !id })

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  const items = useMemo(
    () => [
      {
        key: VENDOR_MANAGEMENT_VIEW_TABS_ENUM.VENDOR_INFORMATION,
        label: VENDOR_MANAGEMENT_VIEW_TABS_ENUM.VENDOR_INFORMATION,
        children: <VendorInfoTab />,
      },
      ...(data?.vendorType !== VENDOR_TYPE_ENUM.PROCESSOR
        ? [
            {
              key: VENDOR_MANAGEMENT_VIEW_TABS_ENUM.CONTACTS,
              label: VENDOR_MANAGEMENT_VIEW_TABS_ENUM.CONTACTS,
              children: <ContactsTable />,
            },
          ]
        : []),
      {
        key: VENDOR_MANAGEMENT_VIEW_TABS_ENUM.PRODUCTS,
        label: VENDOR_MANAGEMENT_VIEW_TABS_ENUM.PRODUCTS,
        children: <ProductsTable />,
      },
      {
        key: VENDOR_MANAGEMENT_VIEW_TABS_ENUM.INTEGRATIONS,
        label: VENDOR_MANAGEMENT_VIEW_TABS_ENUM.INTEGRATIONS,
        children: <IntegrationsTable />,
      },
      {
        key: VENDOR_MANAGEMENT_VIEW_TABS_ENUM.VARIABLES,
        label: VENDOR_MANAGEMENT_VIEW_TABS_ENUM.VARIABLES,
        children: <VariablesTable />,
      },
      ...(data?.vendorType === VENDOR_TYPE_ENUM.PROCESSOR
        ? [
            {
              key: VENDOR_MANAGEMENT_VIEW_TABS_ENUM.SIM_CARDS,
              label: VENDOR_MANAGEMENT_VIEW_TABS_ENUM.SIM_CARDS,
              children: <SimCards />,
            },
          ]
        : []),
      {
        key: VENDOR_MANAGEMENT_VIEW_TABS_ENUM.GATEWAY,
        label: VENDOR_MANAGEMENT_VIEW_TABS_ENUM.GATEWAY,
        children: <Gateway />,
      },
    ],
    [data?.vendorType]
  )

  return (
    <Tabs
      destroyInactiveTabPane
      activeKey={activeTab}
      items={items}
      tabBarExtraContent={<Operations activeTab={activeTab} />}
      onChange={onTabChange}
    />
  )
}
