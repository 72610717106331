/* eslint-disable @typescript-eslint/no-explicit-any */
import { URLSearchParamsInit } from 'react-router-dom'
import queryString, { ParsedQuery, ParseOptions } from 'query-string'
import { isISO8601 } from 'class-validator'
import { isIsoDate } from '../shared/components/filter/SelectedFilters'

// ================ getQueryParams ================
type TGetQueryStr = (query: string, options?: ParseOptions) => ParsedQuery<string>
const getQueryParams: TGetQueryStr = (locationSearch, options = { arrayFormat: 'bracket' }) =>
  queryString.parse(locationSearch, options)

// ================ getParsedQuery ================
type TGetParsedQuery = (queryParams: { [key: string]: any }) => string
const getParsedQuery: TGetParsedQuery = (queryParams) =>
  Object.keys(queryParams)
    .filter(
      (key) =>
        (queryParams[key] &&
          (queryParams[key].length === undefined || queryParams[key].length > 0)) ||
        queryParams[key] === 0
    )
    .map(
      (key) =>
        `${key}=${
          queryParams[key] instanceof Array ? queryParams[key].join(`&${key}=`) : queryParams[key]
        }`
    )
    .join('&')

// ================ updatePageUrl ================
type TSearchParams = (
  nextInit: URLSearchParamsInit,
  navigateOptions?: { replace?: boolean | undefined; state?: any } | undefined
) => void
type TUpdateUrl = <QP>(
  prevQParams: ParsedQuery<string>,
  newQParams: QP,
  setSearchParams: TSearchParams
) => void
const updatePageUrl: TUpdateUrl = (prevQueryParams, newQueryParams, setSearchParams) => {
  const queryParams = {
    ...prevQueryParams,
    ...newQueryParams,
  }

  // TODO: CHECK IN FUTURE WHY PLUS SIGN IS REMOVED FROM REQUEST AND CODE FILTER TOO
  for (const i in queryParams) {
    if (isIsoDate(queryParams[i] as never)) {
      delete queryParams[i]
    }
  }

  const query = getParsedQuery(queryParams)

  setSearchParams(query)
}

export { getQueryParams, updatePageUrl }
