import { Select as AntSelect } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { DefaultOptionType } from 'rc-select/lib/Select'

// components
import { TextField } from 'src/shared/components/textfield/TextField'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'

// styles
import styles from '../styles.module.scss'

// helpers
import { requiredValidator } from 'src/helpers/validation'
import { PAYMENT_METHOD } from 'src/helpers/contants'

// interfaces
import { ICurrency } from 'src/features/Finance/models/ICurency'
import { IVendorTable } from 'src/features/Settings/models/IVendorManagement'
import { IDepartment } from '../../../../../models/IDepartment'
import { FormInstance } from 'antd/es/form/Form'
import { PAYMENT_METHOD_ENUM } from '../../../../../models/IBankAccount'
import { IUser } from '../../../../../../Settings/models/IUser'
import { VENDOR_WALLET_TYPE_ENUM } from '../../../../../models/IPurchaseOrder'
import { useGetVendorWalletTypesQuery } from '../../../../../../Settings/core/http/VendorManagementApi'

const { Option } = AntSelect

interface IDetailInfo {
  viewMode: boolean
  vendorList?: Array<IVendorTable>
  currencyList?: Array<ICurrency>
  usersList?: Array<IUser>
  departmentsList?: Array<IDepartment>
  onSelect: (value: DefaultOptionType) => void
  handleSelectPayment: (paymentType: PAYMENT_METHOD_ENUM) => void
  form: FormInstance
}

export const DetailInfo: React.FC<IDetailInfo> = ({
  viewMode,
  vendorList,
  currencyList,
  departmentsList,
  onSelect,
  usersList,
  handleSelectPayment,
  form,
}) => {
  const { data: vendorWalletTypes } = useGetVendorWalletTypesQuery()
  const handleSelect = (value: DefaultOptionType) => {
    onSelect(value)
  }

  const handlePaymentTypeChange = (val: string) => {
    handleSelectPayment(val as PAYMENT_METHOD_ENUM)
    if (val === PAYMENT_METHOD_ENUM.BONUS) {
      form.setFieldValue(['currency', 'id'], null)
      form.setFieldValue('exchangeRate', null)
      form.setFieldValue('currencyAmount', null)
      form.setFieldValue('purchaseOrderBankWallets', [])
      form.setFieldValue('amount', null)
    }
  }

  return (
    <Wrapper title='Detail info' className={styles.layout}>
      <div className={styles.dblRow}>
        <FormItem name={['createdByUser', 'name']} label='Created by'>
          <TextField disabled />
        </FormItem>

        <FormItem name='createdAt' label='Date'>
          <TextField disabled />
        </FormItem>
      </div>

      <FormItem noStyle shouldUpdate>
        {({ setFieldValue }) => (
          <FormItem
            name='vendorWalletType'
            label='Vendor Wallet Type'
            rules={[
              {
                required: true,
                validator: requiredValidator('Vendor Wallet Type'),
              },
            ]}
          >
            <AntSelect
              disabled={viewMode}
              allowClear
              placeholder='Select Vendor Wallet Type'
              onChange={() => {
                setFieldValue(['vendor', 'id'], null)
              }}
            >
              {vendorWalletTypes?.map((type) => (
                <AntSelect.Option value={type} key={type}>
                  {type}
                </AntSelect.Option>
              ))}
            </AntSelect>
          </FormItem>
        )}
      </FormItem>

      <div className={styles.dblRow}>
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const isEPinWallet = getFieldValue('vendorWalletType') === VENDOR_WALLET_TYPE_ENUM.E_PIN
            return (
              <FormItem
                name={['vendor', 'id']}
                label='Vendor Name'
                rules={[{ required: true, validator: requiredValidator('Vendor Name') }]}
              >
                <AntSelect disabled={viewMode} allowClear placeholder='Select Vendor'>
                  {vendorList
                    ?.filter((vendor) =>
                      isEPinWallet ? ['Vendor', 'Processor'].includes(vendor?.vendorType) : vendor
                    )
                    .map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                </AntSelect>
              </FormItem>
            )
          }}
        </FormItem>

        <FormItem
          name='paymentType'
          label='Payment Method'
          rules={[{ required: true, validator: requiredValidator('Payment Method') }]}
        >
          <AntSelect
            disabled={viewMode}
            onChange={handlePaymentTypeChange}
            allowClear
            placeholder='Select Payment Method'
          >
            {Object.entries(PAYMENT_METHOD).map(([key, value]) => (
              <Option key={key} value={value}>
                {value}
              </Option>
            ))}
          </AntSelect>
        </FormItem>
      </div>

      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const paymentType = getFieldValue('paymentType') as PAYMENT_METHOD_ENUM

          return (
            paymentType !== PAYMENT_METHOD_ENUM.BONUS && (
              <>
                <FormItem
                  name={['currency', 'id']}
                  label='Currency'
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator('Currency'),
                    },
                  ]}
                >
                  <AntSelect
                    disabled={viewMode}
                    onChange={(_, option) => handleSelect(option as DefaultOptionType)}
                    allowClear
                    placeholder='Select Currency'
                  >
                    {currencyList?.map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </AntSelect>
                </FormItem>
              </>
            )
          )
        }}
      </FormItem>

      <div className={styles.dblRow}>
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue, setFields }) => {
            const isDepartment = getFieldValue(['assignDepartment', 'id'])
            return (
              <FormItem
                name={['assignOfficer', 'id']}
                label='Assign Officer'
                rules={[
                  ...(!isDepartment
                    ? [{ required: true, validator: requiredValidator('Officer') }]
                    : [{}]),
                ]}
              >
                <AntSelect
                  disabled={viewMode || isDepartment}
                  onChange={() => {
                    setFields([{ name: ['assignDepartment', 'id'], errors: [] }])
                  }}
                  allowClear
                  placeholder='Select Assign Officer'
                >
                  {usersList?.map(({ id, firstName, lastName }) => (
                    <Option key={id} value={id}>
                      {`${firstName} ${lastName}`}
                    </Option>
                  ))}
                </AntSelect>
              </FormItem>
            )
          }}
        </FormItem>

        <FormItem noStyle shouldUpdate>
          {({ getFieldValue, setFields }) => {
            const isOfficer = getFieldValue(['assignOfficer', 'id'])
            return (
              <FormItem
                name={['assignDepartment', 'id']}
                label='Assign Department'
                rules={[
                  ...(!isOfficer
                    ? [{ required: true, validator: requiredValidator('Department') }]
                    : [{}]),
                ]}
              >
                <AntSelect
                  disabled={viewMode || isOfficer}
                  onChange={() => {
                    setFields([{ name: ['assignOfficer', 'id'], errors: [] }])
                  }}
                  allowClear
                  placeholder='Select Assign Department'
                >
                  {departmentsList?.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </AntSelect>
              </FormItem>
            )
          }}
        </FormItem>
      </div>
    </Wrapper>
  )
}
