import { useNavigate } from 'react-router'
import { useGetQueryParams } from '../../../../../../shared/hooks/table/useGetQueryParams'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import { useCallback, useState } from 'react'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import {
  useDeleteNotificationMessageByIdMutation,
  useGetNotificationMessagesFiltersListQuery,
  useGetNotificationMessagesListQuery,
} from '../../../../core/http/NotificationMessageApi'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import useTable from '../../../../../../shared/hooks/table/useTable'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../shared/components/popup/Popup'
import { ErrorNode, showConfirmMessage } from '../../../../../../shared/api/errorHandler'
import { ColumnsType } from 'antd/es/table'
import { Tags } from '../../../../../../shared/components/tags'
import { DateTableIcon, TableActionIcon } from '../../../../../../assets/svg'
import { INotificationMessageTable } from '../../../../models/INotificationMessage'
import { TableHeader } from '../../VendorManagementView/Components/TableHeader'
import { Table } from 'antd'
import styles from '../VendorManagementTable/style.module.scss'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { formatDateWithTime } from '../../../../../../helpers/utils'
import { useGetProfileQuery } from '../../../../../Profile/core/http/Profile'
import { startCase } from 'lodash'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { whatsAppManagerApi } from '../../../../core/http/WhatsAppManagerApi'
import { useAppDispatch } from '../../../../../../redux'

export const NotificationManagerTable = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'id',
  })

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data, isFetching } = useGetNotificationMessagesListQuery(
    {
      ...tableConf,
      ...filterValues,
    },
    { skip: Object.keys(tableConf).length < 4 }
  )
  const { data: filterFields } = useGetNotificationMessagesFiltersListQuery()
  const { data: profile } = useGetProfileQuery()
  const [delNotMan, delNotManResp] = useDeleteNotificationMessageByIdMutation()

  const afterDelete = () => {
    dispatch(whatsAppManagerApi.util.invalidateTags(['NotButtons']))
  }

  useNotification(NOTIFICATION_TYPES.success, delNotManResp.isSuccess, null, afterDelete)
  useNotification(
    NOTIFICATION_TYPES.success,
    delNotManResp.isError,
    delNotManResp.error as ErrorNode
  )

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } =
    useTable<INotificationMessageTable>({
      total: data?.totalCount,
      setTableConf,
      setFilterValues,
    })

  const tableActionsPopup = useCallback(
    (id: number): IPopupListItems[] => [
      {
        text: 'View',
        shouldDisplay: true,
        onClick: () => navigate(`notification-manager/view/${id}`),
      },
      {
        text: 'Edit',
        shouldDisplay: true,
        onClick: () => navigate(`notification-manager/edit/${id}`),
      },
      {
        text: 'Delete',
        shouldDisplay: true,
        withDivider: true,
        color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        onClick: () => showConfirmMessage('This notification will be deleted', () => delNotMan(id)),
      },
    ],
    [delNotMan, navigate]
  )

  const columns: ColumnsType<INotificationMessageTable> = [
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      width: '15%',
      render: (createdAt: string) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(createdAt, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      width: '10%',
      sorter: true,
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '25%',
      render: (name) => <RecursivelyReplaceCharacters>{name}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '20%',
      sorter: true,
      render: (action) => <RecursivelyReplaceCharacters>{action}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Receiver Type',
      dataIndex: 'receiver',
      width: '10%',
      sorter: true,
      render: (receiver: string) => (
        <RecursivelyReplaceCharacters>{startCase(receiver)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Channels',
      dataIndex: 'channels',
      width: '20%',
      render: (operators: INotificationMessageTable['channels']) => (
        <Tags tags={operators?.map((op) => op?.channel)} isBlue />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'transactionStatus',
      width: '15%',
      sorter: true,
      render: (status) => <RecursivelyReplaceCharacters>{status}</RecursivelyReplaceCharacters>,
    },
    {
      title: '',
      dataIndex: 'edit',
      width: '1%',
      key: 'edit',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return (
    <>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filterFields}
      />
      <Table
        columns={columns}
        locale={emptyTableConf}
        onChange={handleTableChange}
        className={styles.table}
        loading={isFetching || delNotManResp.isLoading}
        dataSource={data?.items}
        scroll={{ x: 1000 }}
        pagination={pagination}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.id}
      />
    </>
  )
}
