import { useFilter } from '../../../../../shared/hooks/filter/useFilter'
import { Col, Row } from 'antd'
import { Filter, ISelectedFilters } from '../../../../../shared/components/filter/Filter'
import { FilterPopup } from '../../../../../shared/components/filter/FilterPopup'
import React, { useEffect, useMemo, useState } from 'react'
import { IFilterFields } from '../../../../../shared/models/IFilterFields'
import { TotalSalesPieChart } from '../../../../Reports/pages/Transactions/Components/TotalSalesPieChart'
import { WIDGET_LIST_ENUM } from '../../../../../shared/models/WidgetListEnum'
import { SalesSummaryBarChart } from '../../../../Reports/pages/Transactions/Components/SalesSummaryBarChart'
import styles from '../../../../Reports/pages/Transactions/style.module.scss'
import { widgetsSocketConnection } from '../../../../../shared/sockets'
import { IWidget } from '../../../../../shared/models/IWidget'
import { useParams } from 'react-router-dom'
import { getFrequency, moment } from '../../../../../helpers/utils'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import { ISalesByMasterProducts, ISalesSummary } from '../../../../Reports/models/IExternalReport'
import { isEmpty } from 'lodash'
import { Moment } from 'moment'
import { RangePicker } from '../../../../../shared/components/datePicker'
import { Dayjs } from 'dayjs'

interface IProps {
  setActiveFilters: (values: ISelectedFilters) => void
  filterValues: ISelectedFilters
  data?: IFilterFields[]
  onDateChange: (value: Moment[] | null) => void
  dateFilter?: [Moment, Moment] | [Dayjs, Dayjs]
}

const initialState = {
  [WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS]: {
    value: [] as ISalesByMasterProducts[],
  },
  [WIDGET_LIST_ENUM.SALES_SUMMARY]: {
    value: {
      pickupSummary: [] as ISalesSummary[],
      salesSummary: [] as ISalesSummary[],
    },
    frequency: '',
  },
}

export const TableHeader = ({
  setActiveFilters,
  data,
  filterValues,
  dateFilter,
  onDateChange,
}: IProps): JSX.Element => {
  const { data: profile } = useGetProfileQuery()
  const { agentId } = useParams()
  const [widgetData, setWidgetData] = useState(() => initialState)
  const { isOpen, onCancel, form, onReset, onFinish } = useFilter({ setActiveFilters })

  const skipCondition = useMemo(
    () => !agentId || !profile || !dateFilter?.length,
    [agentId, dateFilter, profile]
  )

  useEffect(() => {
    if (skipCondition) return
    const socket = widgetsSocketConnection.getSocket()
    const messagePayload = [
      {
        widget: WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS,
        filters: {
          agentId,
          ...(dateFilter?.length === 2 && {
            from: moment.isMoment(dateFilter[0])
              ? dateFilter[0]?.toISOString(true)
              : dateFilter[0]?.toISOString(),
            to: moment.isMoment(dateFilter[1])
              ? dateFilter[1]?.toISOString(true)
              : dateFilter[1]?.toISOString(),
          }),
        },
      },
      {
        widget: WIDGET_LIST_ENUM.SALES_SUMMARY,
        filters: {
          agentId,
          ...(dateFilter?.length === 2 && {
            from: moment.isMoment(dateFilter[0])
              ? dateFilter[0]?.toISOString(true)
              : dateFilter[0]?.toISOString(),
            to: moment.isMoment(dateFilter[1])
              ? dateFilter[1]?.toISOString(true)
              : dateFilter[1]?.toISOString(),
            frequency: getFrequency(
              String(dateFilter[0]?.toISOString(true)),
              String(dateFilter[1]?.toISOString(true))
            ),
          }),
        },
      },
    ]

    socket?.emit('connect-widget', { data: messagePayload })

    return () => {
      socket?.emit('disconnect-widget', {
        data: messagePayload,
      })
    }
  }, [agentId, dateFilter, skipCondition])

  useEffect(() => {
    if (skipCondition) return
    const socket = widgetsSocketConnection.getSocket()
    socket?.on('widget-data', (data: IWidget) => {
      if (isEmpty(data?.data)) {
        return setWidgetData(initialState)
      }

      const balance = JSON.parse(data?.data?.jsonData)

      setWidgetData((prevState) => ({
        ...prevState,
        [data.widget]: Object.keys(balance).length
          ? {
              value: balance?.value,
              ...(data['filters']['frequency'] && { frequency: data['filters']['frequency'] }),
            }
          : {},
      }))
    })
  }, [agentId, profile, skipCondition])

  useEffect(() => {
    if (dateFilter?.length !== 2) {
      setWidgetData(initialState)
    }
  }, [dateFilter?.length])

  return (
    <>
      <Row align='middle' justify='space-between' style={{ marginBottom: '20px' }}>
        <Col>
          <Filter
            data={data}
            form={form}
            setIsOpen={onCancel}
            selectedFilters={filterValues}
            setSelectedFilters={setActiveFilters}
          />
        </Col>
        <Col>
          <RangePicker value={dateFilter} onChange={(value) => onDateChange(value as Moment[])} />
        </Col>
      </Row>
      {isOpen && (
        <FilterPopup
          data={data}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          form={form}
          onCancel={onCancel}
          onReset={onReset}
          onFinish={onFinish}
        />
      )}
      <div className={styles.tableCharts}>
        <TotalSalesPieChart data={widgetData[WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS].value} />
        <SalesSummaryBarChart
          data={widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY].value}
          frequency={widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY].frequency}
        />
      </div>
    </>
  )
}
