import React, { memo, useContext, useEffect, useState } from 'react'
import { Spin } from 'antd'

import Link from './Components/Link'
import { IconButton } from '../../../../shared/components/iconButton/IconButton'
import { InfiniteScrollComponent } from '../../../../shared/components/infiniteScoll/InfiniteScroll'

import { ArrowLeftIcon, ChatLinksIcon } from '../../../../assets/svg'
import { InternalChatContext } from '../InternalChatModal'
import { useAppSelector } from '../../../../redux'
import { selectInternalChatChatsState } from '../../core/store/Chats'
import { useGetInternalChatLinksQuery } from '../../core/http/InternalChatsApi'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { IInternalChatLink } from '../../models'

import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { NotFoundPage } from '../../../NotFoundPage'
import { modalViewTypeEnum } from '../../core/models'
import chatStyles from '../ChatDetails/styles.module.scss'
import styles from './styles.module.scss'

const Links = () => {
  const { setProps, onSelectChat, props } = useContext(InternalChatContext)
  const { openedChatId } = useAppSelector(selectInternalChatChatsState)
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 25,
  })

  const [links, setLinks] = useState<Array<IInternalChatLink>>([])

  const {
    data = { items: [], totalCount: 0, currentPage: 1 } as ITableResponse<IInternalChatLink>,
    isFetching,
  } = useGetInternalChatLinksQuery(
    {
      id: openedChatId as number,
      ...tableConf,
    },
    { skip: !openedChatId }
  )

  useEffect(() => {
    if (isFetching) return

    if (tableConf.page === 1) {
      setLinks(data.items)
    } else {
      setLinks((p) => [...p, ...data.items])
    }
    // eslint-disable-next-line
  }, [isFetching])

  const onPressBackButton = () => {
    onSelectChat(modalViewTypeEnum.details, openedChatId as number)
    setProps((prev) => ({ ...prev, attachmentType: null }))
  }

  const loadMoreData = () => {
    setTableConf((pre: ITableConf) => ({ ...pre, page: pre.page! + 1 }))
  }

  const content = (
    <div className={chatStyles.internalChatDetailsContainer}>
      <div className={styles.attachmentsHeader}>
        <IconButton
          color='blue'
          type='default'
          className={styles.backButton}
          icon={<ArrowLeftIcon />}
          onClick={onPressBackButton}
        />
        <div className={styles.attachmentsTitleContainer}>
          {props.attachmentType === 'Links' && <ChatLinksIcon />}
          <div className={styles.attachmentsTitle}>{props.attachmentType}</div>
        </div>
      </div>
      <div className={styles.linksContainer}>
        {links.length ? (
          <InfiniteScrollComponent
            loadMoreData={loadMoreData}
            dataLength={links.length}
            totalCount={data.totalCount}
            endMessage=''
            showDivider={false}
          >
            {links.map((item) => (
              <Link key={item.id} link={item} />
            ))}
          </InfiniteScrollComponent>
        ) : (
          <NotFoundPage text='Empty data' />
        )}
      </div>
    </div>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}

export default memo(Links)
