import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../../shared/models/ITableConf'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { IDiscountPolicy, IDiscountPolicyCreate } from '../../models/IDiscountPolicy'
import { ITargetGroup } from '../../models/ITargetGroup'

export const discountPolicyApi = createApi({
  reducerPath: 'discountPolicy',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['DiscountList', 'Discount', 'DiscountTargetGroups', 'FilterFields'],
  endpoints: (builder) => ({
    getDiscountPolicyList: builder.query<ITableResponse<IDiscountPolicy>, ITableConf>({
      query: (params) => ({
        url: 'discount-policy',
        params,
      }),
      providesTags: ['DiscountList'],
    }),

    getDiscountPolicyById: builder.query<IDiscountPolicy, string>({
      query: (id) => ({
        url: `discount-policy/${id}`,
      }),
      providesTags: (res) => [{ type: 'Discount', id: res?.id }],
      transformResponse: (res: IDiscountPolicy) => ({
        ...res,
        discountPolicyProducts: res?.discountPolicyProducts?.map((product) => ({
          ...product,
          levelRates: [...product?.levelRates].sort((a, b) => a?.level?.level - b?.level?.level),
        })),
      }),
    }),

    getDiscountPolicyFilterList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'discount-policy/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `discount-policy/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: ['FilterFields'],
    }),

    getDPTargetGroupsList: builder.query<ITableResponse<ITargetGroup>, ITableConf>({
      query: ({ id, ...params }) => ({
        url: `discount-policy/${id}/target-groups?withGroupSize=true`,
        params,
      }),
      providesTags: () => ['DiscountTargetGroups'],
    }),

    getDPTargetGroupsFilterList: builder.query<Array<IFilterFields>, string>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: `discount-policy/${arg}/target-groups/filter-fields`,
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `discount-policy/${arg}/target-groups/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),

    createDiscountPolicy: builder.mutation<IDiscountPolicy, IDiscountPolicyCreate>({
      query: (body) => ({
        url: `discount-policy`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['DiscountList', 'FilterFields'],
    }),

    assignTargetToDiscountPolicy: builder.mutation<void, { discountId: string; groupId: number }>({
      query: ({ discountId, groupId }) => ({
        url: `discount-policy/${discountId}/target-groups/${groupId}`,
        method: 'POST',
      }),
      invalidatesTags: () => ['DiscountTargetGroups'],
    }),

    unassignTargetFromDiscountPolicy: builder.mutation<
      void,
      { discountId: string | number; groupId: number | string }
    >({
      query: ({ discountId, groupId }) => ({
        url: `discount-policy/${discountId}/target-groups/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['DiscountTargetGroups'],
    }),

    updateDiscountPolicy: builder.mutation<
      IDiscountPolicy,
      { id: string | number; body: Partial<IDiscountPolicyCreate> }
    >({
      query: ({ id, body }) => ({
        url: `discount-policy/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (res) => ['DiscountList', 'FilterFields', { type: 'Discount', id: res?.id }],
    }),

    exportDiscountPolicyTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx'; id: string }
    >({
      query: ({ conf, formatFile }) => ({
        url: `discount-policy/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getDiscountPolicyProductsList: builder.query<Array<ITargetGroup>, string>({
      query: (id) => ({
        url: `discount-policy/${id}/products`,
      }),
    }),

    getAccessChannelsList: builder.query<{ accessChannels: Array<string> }, void>({
      query: () => ({
        url: `access-channels`,
      }),
    }),

    deleteDiscountPolicyById: builder.mutation<void, string | number>({
      query: (id) => ({
        url: `discount-policy/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['DiscountList', 'FilterFields'],
    }),

    exportDPTargetGroupsTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx'; id: string }
    >({
      query: ({ conf, formatFile }) => ({
        url: `discount-policy/${conf.id}/target-groups/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
})

export const {
  useGetDiscountPolicyFilterListQuery,
  useGetDiscountPolicyListQuery,
  useGetDiscountPolicyByIdQuery,
  useDeleteDiscountPolicyByIdMutation,
  useCreateDiscountPolicyMutation,
  useUpdateDiscountPolicyMutation,
  useLazyExportDiscountPolicyTableQuery,
  useGetAccessChannelsListQuery,
} = discountPolicyApi
