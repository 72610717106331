import React, { useCallback, useEffect, useMemo } from 'react'
import styles from './styles.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Row, Space, Spin } from 'antd'
import { RestrictionDetailInfo } from '../Components/DetailInfoBlock'
import { SUPPORT_ENUM } from '../../../../../routes/support'
import { RestrictionDuration } from '../Components/DurationBlock'
import {
  useCreateCustomerRestrictionMutation,
  useDeleteCustomerRestrictionMutation,
  useGetCustomerRestrictionByIdQuery,
  useUpdateCustomerRestrictionMutation,
} from '../../../core/http/CutomerRestrictionApi'
import { useDispatch } from 'react-redux'
import { setBreadcrumb } from '../../../../app/core/BreadcrumbsSlice'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import {
  formatDateMomentToISO,
  formatDateWithTime,
  getFormCalendarTime,
} from '../../../../../helpers/utils'
import { IRestriction, IRestrictionMutate } from '../../../models/Restrictions'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import { ErrorNode, showConfirmMessage } from '../../../../../shared/api/errorHandler'
import { Button } from '../../../../../shared/components/button/Button'
import { Moment } from 'moment'

export const CustomerRestrictionView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { mode, id } = useParams()

  const [updateRestriction, upRestrictionResp] = useUpdateCustomerRestrictionMutation()
  const [crRestriction, crRestrictionResp] = useCreateCustomerRestrictionMutation()
  const [delRestriction, delRestrictionResp] = useDeleteCustomerRestrictionMutation()
  const { data: userProfile, isFetching: isFetchingUser } = useGetProfileQuery()
  const { data, isFetching } = useGetCustomerRestrictionByIdQuery(id as string, { skip: !id })

  const handleUpdateSuccess = () => {
    return navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}/customer/view/${id}`)
  }

  const handleDeleteSuccess = () => {
    return navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}`)
  }

  const handleCreateSuccess = () => {
    if (crRestrictionResp.isSuccess && crRestrictionResp.data?.id) {
      return navigate(
        `/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}/customer/view/${crRestrictionResp.data?.id}`
      )
    }
  }

  useNotification(
    NOTIFICATION_TYPES.success,
    upRestrictionResp.isSuccess,
    null,
    handleUpdateSuccess
  )
  useNotification(
    NOTIFICATION_TYPES.success,
    crRestrictionResp.isSuccess,
    null,
    handleCreateSuccess
  )
  useNotification(
    NOTIFICATION_TYPES.success,
    delRestrictionResp.isSuccess,
    null,
    handleDeleteSuccess
  )
  useNotification(
    NOTIFICATION_TYPES.error,
    upRestrictionResp.isError,
    upRestrictionResp.error as ErrorNode
  )
  useNotification(
    NOTIFICATION_TYPES.error,
    crRestrictionResp.isError,
    crRestrictionResp.error as ErrorNode
  )
  useNotification(
    NOTIFICATION_TYPES.error,
    delRestrictionResp.isError,
    delRestrictionResp.error as ErrorNode
  )

  const isEditMode = useMemo(() => mode === 'edit', [mode])
  const isViewMode = useMemo(() => mode === 'view', [mode])
  const isLoading =
    isFetching ||
    isFetchingUser ||
    crRestrictionResp.isLoading ||
    upRestrictionResp.isLoading ||
    delRestrictionResp.isLoading

  const setInitialFields = useCallback(() => {
    form.setFieldsValue({
      ...data,
      ...(data?.endAt
        ? { endAt: getFormCalendarTime(userProfile?.calendar, data?.endAt as string) }
        : {}),
      startAt: getFormCalendarTime(userProfile?.calendar, data?.startAt as string),
      createdAt: formatDateWithTime(data?.createdAt, userProfile?.calendar),
      createdBy: `${userProfile?.firstName} ${userProfile?.lastName}`,
      customer: {
        phoneNumber: data?.phoneNumber,
      },
    })
  }, [data, form, userProfile?.firstName, userProfile?.lastName])

  const onFinish = (values: IRestriction) => {
    const body = {
      ...(values?.description ? { description: values.description } : {}),
      ...(values?.endAt
        ? { endAt: formatDateMomentToISO(userProfile?.calendar, values.endAt as Moment) }
        : {}),
      startAt: formatDateMomentToISO(userProfile?.calendar, values?.startAt as Moment),
      reason: values?.reason,
      customerPhoneNumber: values?.customer?.phoneNumber,
      type: values?.restrictionTypes,
    } as IRestrictionMutate

    isEditMode ? updateRestriction({ id: id as string, body }) : crRestriction(body)
  }

  const onPressCancelChanges = useCallback(() => {
    if (isEditMode) {
      form.resetFields()
      setInitialFields()
      return navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}/customer/view/${id}`)
    }

    if (isViewMode) {
      return navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}`)
    }

    return navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}`)
  }, [form, id, isEditMode, isViewMode, navigate, setInitialFields])

  const onPressSaveChanges = useCallback(() => {
    if (isViewMode) {
      return navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}/customer/edit/${id}`)
    }

    return form.submit()
  }, [form, id, isViewMode, navigate])

  const onPressDelete = () => {
    return showConfirmMessage('This restriction will be deleted', () =>
      delRestriction(id as string)
    )
  }

  useEffect(() => {
    if (data) {
      dispatch(setBreadcrumb(data?.customer || {}))
      setInitialFields()
    }
  }, [data, dispatch, form, setInitialFields, userProfile])

  useEffect(() => {
    if (userProfile) {
      form.setFieldValue('createdBy', `${userProfile?.firstName} ${userProfile?.lastName}`)
    }
  }, [form, userProfile])

  const content = (
    <div className={styles.layout}>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ createdAt: formatDateWithTime(new Date(), userProfile?.calendar) }}
        layout='vertical'
        className={styles.columnWrapper}
      >
        <div className={styles.leftColumn}>
          <RestrictionDetailInfo isViewMode={isViewMode} />
        </div>
        <div className={styles.rightColumn}>
          <RestrictionDuration view={isViewMode} />
        </div>
      </Form>
      <div className={styles.wrapper}>
        <Row className={styles.footer} justify='space-between'>
          <div>
            {(isViewMode || isEditMode) && (
              <Button color='error' size='middle' onClick={onPressDelete}>
                Delete restriction
              </Button>
            )}
          </div>
          <Row justify='end'>
            <Space size={10}>
              <Button color='blue' size='middle' onClick={onPressCancelChanges}>
                Cancel
              </Button>
              <Button htmlType='submit' size='middle' type='primary' onClick={onPressSaveChanges}>
                {isViewMode && 'Edit'}
                {isEditMode && 'Save Changes'}
                {!isViewMode && !isEditMode && 'Create Restriction'}
              </Button>
            </Space>
          </Row>
        </Row>
      </div>
    </div>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
