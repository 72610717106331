import { useEffect, useState } from 'react'
import { Space } from 'antd'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { EventsBlock } from './EventsBlock/EventsBlock'
import { PublicHolidaysBlock } from './PublicHolidayBlock/PublicHolidaysBlock'
import styles from '../../styles.module.scss'
import {
  useLazyGetEventListQuery,
  useLazyGetHolidayListQuery,
} from 'src/features/Settings/core/http/EventsHolidaysApi'
import { ITableConf } from 'src/shared/models/ITableConf'
import { IEventOrHoliday } from 'src/features/Settings/models/IEventsHolidays'
import { CalendarShamsi } from 'src/shared/components/calendarShamsi'
import dayjs, { Dayjs } from 'dayjs'

const tableConf = {
  page: 1,
  limit: 5,
}

export const CalendarTab = () => {
  const [, setTableConfEvent] = useState<ITableConf>(tableConf)
  const [, setTableConfHoliday] = useState<ITableConf>(tableConf)

  const [getEvents, { data: dEvent, isFetching: isFE }] = useLazyGetEventListQuery()
  const [getHolidays, { data: dHoliday, isFetching: isFH }] = useLazyGetHolidayListQuery()

  const [dateValue, setDateValue] = useState(new Date())

  useEffect(() => {
    getEvents({
      monthRange: dayjs(dateValue).set('date', 15).toISOString(),
    })
  }, [dateValue])

  useEffect(() => {
    getHolidays({
      monthRange: dayjs(dateValue).set('date', 15).toISOString(),
    })
  }, [dateValue])

  const events = [
    ...(dEvent?.items ? dEvent?.items?.map((i) => ({ ...i, type: 'events' })) : []),
    ...(dHoliday?.items ? dHoliday?.items?.map((i) => ({ ...i, type: 'holidays' })) : []),
  ] as unknown as IEventOrHoliday[]

  const handlePanelChange = (date: Dayjs) => {
    setDateValue(new Date(date.toISOString()))
  }

  return (
    <div className={styles.container}>
      <div className={styles.calendarList}>
        <Wrapper title='Gregorian Calendar'>
          <CalendarShamsi events={events} onPanelChange={handlePanelChange} value={dateValue} />
        </Wrapper>
        <Wrapper title='Hijri Shamsi Calendar'>
          <CalendarShamsi
            events={events}
            jalali
            onPanelChange={handlePanelChange}
            value={dateValue}
          />
        </Wrapper>
      </div>
      <div className={styles.events}>
        <Space size={32} style={{ width: '100%' }} direction='vertical'>
          <EventsBlock
            totalCount={dEvent?.totalCount}
            items={dEvent?.items}
            isFetching={isFE}
            setTableConf={setTableConfEvent}
          />
          <PublicHolidaysBlock
            totalCount={dHoliday?.totalCount}
            items={dHoliday?.items}
            isFetching={isFH}
            setTableConf={setTableConfHoliday}
          />
        </Space>
      </div>
    </div>
  )
}
