import FormItem from 'antd/es/form/FormItem'
import { Toggle } from 'src/shared/components/toggle/Toggle'
import { Wrapper } from '../../../../salesNetwork/components/Wrapper'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Form, Spin, UploadFile } from 'antd'
import styles from './style.module.scss'
import { UploaderPhotos2 } from 'src/shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import { IProduct, ITopupBundle } from '../../../models/IProduct'
import { Details } from './Details'
import { Pricing } from './Pricing'
import { ProductInfo } from './ProductInfo'
import { Button } from 'src/shared/components/button/Button'
import { formatOnlyDate } from 'src/helpers/utils'
import { RcFile } from 'antd/lib/upload'
import {
  useCreateProductMutation,
  useGetProductByIdQuery,
  useGetVendorByIdQuery,
  useUpdateProductMutation,
  vendorManagementApi,
} from '../../../core/http/VendorManagementApi'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { RightModalContext } from '../../../../Modals'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import { ProductConfiguration } from './ProductConfiguration'
import { PAYMENT_POLICY_TYPE_ENUM } from '../../../models/ITargetGroup'
import { ReverseBlock } from './Reverse'
import { isEmpty } from 'lodash'
import { useAppDispatch } from '../../../../../redux'
import { Emitter, EmitterEvents } from '../../../../../helpers/eventEmitter'

export const VendorManagementProductModal = () => {
  const dispatch = useAppDispatch()
  const [picture, setPicture] = useState<RcFile>()
  const [isImageError, setIsImageError] = useState(false)
  const [isDeleteLogo, setIsDeleteLogo] = useState(false)

  const {
    onClose,
    props: { id, mode, vendorId },
  } = useContext(RightModalContext)

  const [form] = Form.useForm()
  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const { data: userProfile, isFetching: isLoadingProfile } = useGetProfileQuery()
  const { data: vendorProfile, isFetching: isFetchingVendor } = useGetVendorByIdQuery(vendorId, {
    skip: !vendorId,
  })
  const { data, isLoading } = useGetProductByIdQuery(id, { skip: !isViewMode && !isEditMode })
  const [updProd, updProdResp] = useUpdateProductMutation()
  const [crProd, crProdResp] = useCreateProductMutation()

  const afterSuccess = () => {
    dispatch(vendorManagementApi.util.invalidateTags(['IMasterProduct']))
    Emitter.emit(EmitterEvents.UPDATE_PRODUCT)
  }

  useNotification(NOTIFICATION_TYPES.success, crProdResp.isSuccess, null, afterSuccess)
  useNotification(NOTIFICATION_TYPES.success, updProdResp.isSuccess, null, afterSuccess)
  useNotification(NOTIFICATION_TYPES.error, crProdResp.isError, crProdResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, updProdResp.isError, updProdResp.error as ErrorNode)

  function getBundle(data: IProduct, type: string, index: number): ITopupBundle {
    const size = data?.bundleType === type ? data?.bundleSize : data?.bundleSize?.split('+')[index]
    const unit =
      data?.bundleType === type
        ? (data?.bundleSizeUnit as ITopupBundle['unit'])
        : (data?.bundleSizeUnit?.split('+')[index] as ITopupBundle['unit'])
    return { size, unit }
  }

  const onFinish = (values: IProduct) => {
    const data = {
      ...values,
      masterProductId: values?.masterProduct.id,
      vendorId: Number(vendorId),
      ...(values.productIntegration?.id
        ? { productIntegrationId: values.productIntegration?.id }
        : { productIntegrationId: null }),
      ...(!!values?.discountRate && { discountRate: +values.discountRate }),
      ...(values?.reverseTimeEligibility && {
        reverseTimeEligibility: +values.reverseTimeEligibility * 60,
      }),
      ...(values?.maximumReverseProcessingTime && {
        maximumReverseProcessingTime: +values.maximumReverseProcessingTime,
      }),
      ...(values?.taxRecoveryRate && {
        taxRecoveryRate: +values.taxRecoveryRate,
      }),
      ...(values?.denomination && {
        denominationMin: +values.denomination,
        denominationMax: +values.denomination,
      }),
      ...(values?.reverseDenominationMin &&
        values?.reverseDenominationMax && {
          reverseDenominationMin: +values.reverseDenominationMin,
          reverseDenominationMax: +values.reverseDenominationMax,
        }),
      ...(values?.bundleDescription && { bundleDescription: values.bundleDescription }),
      ...(values?.bundlePrice && { bundlePrice: +values.bundlePrice }),
      ...(values?.bundleTax && { bundleTax: +values.bundleTax }),
      ...(values?.agentBundlePrice && { agentBundlePrice: +values.agentBundlePrice }),
      ...(values?.bundleValidity && { bundleValidity: +values.bundleValidity }),
      ...(values?.apiBundleServiceFee && { apiBundleServiceFee: +values.apiBundleServiceFee }),
      ...(values?.agentBundleServiceFee && {
        agentBundleServiceFee: +values.agentBundleServiceFee,
      }),
      ...(values?.apiBundlePrice && { apiBundlePrice: +values.apiBundlePrice }),
      ...(values?.bundleType === 'Data' && {
        bundleSize: values.dataBundle?.size,
      }),
      ...(values?.bundleType === 'Data' && {
        bundleSizeUnit: values.dataBundle?.unit,
      }),
      ...(values?.bundleType === 'Voice' && {
        bundleSize: values.voiceBundle?.size,
      }),
      ...(values?.bundleType === 'Voice' && {
        bundleSizeUnit: values.voiceBundle?.unit,
      }),
      ...(values?.bundleType === 'SMS' && {
        bundleSize: values.SMSBundle?.size,
      }),
      ...(values?.bundleType === 'SMS' && {
        bundleSizeUnit: values.SMSBundle?.unit,
      }),
      ...(values?.bundleType === 'Mix' && {
        bundleSize: `${values.dataBundle?.size}+${values.voiceBundle?.size}+${values.SMSBundle?.size}`,
      }),
      ...(values?.bundleType === 'Mix' && {
        bundleSizeUnit: `${values.dataBundle?.unit}+${values.voiceBundle?.unit}+${values.SMSBundle?.unit}`,
      }),
      ...(isDeleteLogo && { isDeleteLogo: true }),
    }

    const fd = new FormData()

    if (picture) {
      fd.append('logo', picture!)
    }
    fd.append('product', JSON.stringify(data))

    isEditMode ? updProd({ id, body: fd }) : crProd(fd)
  }

  useEffect(() => {
    if (isEmpty(data) || isEmpty(userProfile)) return
    form.setFieldsValue({
      ...data,
      createdAt: formatOnlyDate(data?.createdAt, userProfile?.calendar),
      updatedAt: formatOnlyDate(data?.updatedAt, userProfile?.calendar),
      status: data.status,
      denomination: data?.denominationMin,
      reverseTimeEligibility: data?.reverseTimeEligibility
        ? data?.reverseTimeEligibility / 60
        : null,
      dataBundle: getBundle(data, 'Data', 0),
      voiceBundle: getBundle(data, 'Voice', 1),
      SMSBundle: getBundle(data, 'SSM', 2),
    })
  }, [data, form, userProfile])

  useEffect(() => {
    if (crProdResp.isSuccess || updProdResp.isSuccess) {
      onClose()
    }
  }, [crProdResp.isSuccess, onClose, updProdResp.isSuccess])

  const isDiscountRateDisabled = useMemo(
    () => vendorProfile?.paymentPolicyType === PAYMENT_POLICY_TYPE_ENUM.PREPAID,
    [vendorProfile?.paymentPolicyType]
  )

  const isFetching =
    updProdResp.isLoading || crProdResp.isLoading || isLoadingProfile || isFetchingVendor

  const onCreatePage = !isViewMode && !isEditMode

  const content = (
    <Form
      layout='vertical'
      form={form}
      className={styles.addProductModal}
      onFinish={onFinish}
      initialValues={{
        status: false,
        createdAt: formatOnlyDate(new Date(), userProfile?.calendar),
        createdBy: `${userProfile?.firstName} ${userProfile?.lastName}`,
        bundleType: 'Data',
        bundles: [{ bundleName: '', topupAmount: '' }],
      }}
    >
      <UploaderPhotos2
        form={form}
        title='Product Picture'
        key='logo'
        isProductPreview
        view={isViewMode}
        defaultPreviewImage={data?.logo}
        setToDeleteLogo={setIsDeleteLogo}
        isBig
        onChange={(photo: UploadFile[]) => {
          setPicture(photo[0].originFileObj)
          setIsImageError(form.getFieldsError().filter(({ errors }) => errors.length).length > 0)
          setIsDeleteLogo(false)
        }}
      />
      <Wrapper
        title='Status'
        className={styles.statusWrapper}
        statusButton={
          <FormItem name='status' valuePropName='checked'>
            <Toggle disabled={isViewMode} defaultChecked={false} />
          </FormItem>
        }
      />
      <Details onCreatePage={onCreatePage} />
      <ProductInfo view={isViewMode} form={form} />
      <Pricing view={isViewMode} isDiscountRateDisabled={isDiscountRateDisabled} />
      <ReverseBlock view={isViewMode} />
      <ProductConfiguration view={isViewMode} />

      {!isViewMode && (
        <FormItem>
          <Button
            color='orange'
            type='primary'
            htmlType='submit'
            block
            disabled={isFetching || isImageError}
          >
            {isEditMode ? 'Edit Product' : 'Add Product'}
          </Button>
        </FormItem>
      )}
    </Form>
  )

  return isLoading || isFetching ? <Spin>{content}</Spin> : content
}
