import { useEffect, useState } from 'react'
import { Form, Spin, FormListFieldData } from 'antd'
import VerificationBlockHeader from './Components/VerificationBlockHeader'
import IdVerificationForm from './Components/IdVerificationForm'
import styles from './styles.module.scss'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { AddIcon } from 'src/assets/svg'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import {
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useGetDocumentListQuery,
  useUpdateDocumentMutation,
  useVerifyDocumentMutation,
} from 'src/features/salesNetwork/core/http/DocumentVerificationApi'
import {
  IDocumentForm,
  IDocumentListResponse,
  IGeneralDocumentForm,
  IGeneralDocument,
  IUploadFileBody,
} from 'src/features/salesNetwork/models/IDocumentVerification'
import moment, { Moment } from 'moment'
import ShowMessage, { ErrorNode } from 'src/shared/api/errorHandler'
import FooterActions from './Components/FooterActions'
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition,
} from '@reduxjs/toolkit/dist/query'
import { isEmpty, omit } from 'lodash'
import classNames from 'classnames'
import {
  AgentDocumentsEnum,
  AgentDocumentStatusEnum,
  AgentGeneralStatus,
  AgentGeneralStatusEnum,
} from '../../AgentManagement.d'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { formatDateMomentToISO, getFormCalendarTime } from 'src/helpers/utils'
import { useGetAgentByIdQuery } from 'src/features/salesNetwork/core/http/AgentManagement'

export const VerificationAgent = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { mode, agentId } = useParams()
  const { data: profile } = useGetProfileQuery()

  const { data: dataAgent } = useGetAgentByIdQuery(agentId, { skip: !agentId })

  const [itemsToDelete, setItemsToDelete] = useState<string[]>([])
  const [isLoading, setLoading] = useState(false)
  const [files, setFiles] = useState<IUploadFileBody>({})
  const [attchmntsToRemove, setAttchmntsToRemove] = useState<Record<string, string[]>>({})

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const {
    data: documents,
    isFetching,
    refetch,
  } = useGetDocumentListQuery(agentId, {
    skip: !isViewMode && !isEditMode,
  })

  const [createDocument] = useCreateDocumentMutation()
  const [updateDocument] = useUpdateDocumentMutation()
  const [deleteDocument] = useDeleteDocumentMutation()
  const [verifyDocument, dVerify] = useVerifyDocumentMutation()

  useNotification(NOTIFICATION_TYPES.success, dVerify.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, dVerify.isError, dVerify.error as ErrorNode)

  useEffect(() => {
    if (isEmpty(documents?.documents)) return

    handleSetInitialForm(documents!)
  }, [documents?.documents, profile])

  const handleSetInitialForm = (data: IDocumentListResponse) => {
    form.resetFields()

    const documentsForm = data?.documents?.map((i) => ({
      ...i,
      ...(i.expireAt && { expireAt: getFormCalendarTime(profile?.calendar, i.expireAt) }),
      ...(i.issuedAt && { issuedAt: getFormCalendarTime(profile?.calendar, i.issuedAt) }),
    }))

    form.setFieldsValue({
      documents: !isEmpty(documentsForm) ? documentsForm : [{}],
    })

    setItemsToDelete([])
    setFiles({})
  }

  const generatePromise = (
    item: IDocumentForm | string,
    promise: MutationTrigger<
      MutationDefinition<
        {
          id: string
          type?: string
          body?: FormData
        },
        BaseQueryFn<
          string | FetchArgs,
          unknown,
          FetchBaseQueryError,
          Record<string, unknown>,
          Record<string, unknown>
        >,
        'IDocumentList',
        IGeneralDocument,
        'documentVerificationApi'
      >
    >,
    mutationType: 'create' | 'update' | 'delete'
  ) => {
    let type: string = (item as IDocumentForm).type

    if (mutationType === 'create' || mutationType === 'update') {
      if (
        type === AgentDocumentsEnum.DRIVING_LICENCE ||
        type === AgentDocumentsEnum.COMPANY_LICENCE
      ) {
        type = type.replace('LICENCE', 'LICENSE')
      }
      type = type.toLowerCase().split('_').join('-')
    }

    const fd = new FormData()

    let req = item

    if (typeof item === 'object') {
      const filteredAttachments = attchmntsToRemove[(item as IDocumentForm).type] || []

      req = {
        ...omit(item as IDocumentForm, 'attachments'),
        deleteAttachments: filteredAttachments,
      }
    }
    const filteredFiles = files[(item as IDocumentForm).type] || []

    filteredFiles.forEach(
      (value) => value.originFileObj && fd.append('files', value.originFileObj!)
    )
    fd.append('document', JSON.stringify(req))

    return promise({
      id: agentId as string,
      ...(type && { type }),
      ...(mutationType === 'delete' && { documentId: item }),
      ...(mutationType === 'create' && { body: fd }),
      ...(mutationType === 'update' && {
        documentId: (item as IDocumentForm).id,
        body: fd,
      }),
    })
  }

  const onFinish = async (values: IGeneralDocumentForm) => {
    const { documents } = values

    const itemsToCreate: Array<Omit<IDocumentForm, 'id'>> = []
    const itemsToUpdate: Array<IDocumentForm> = []

    documents.forEach((item: IDocumentForm) => {
      const updatedItem = {
        ...item,
        ...(item.expireAt
          ? {
              expireAt: formatDateMomentToISO(
                profile?.calendar,
                item.expireAt as unknown as Moment
              ),
            }
          : {}),
        ...(item.issuedAt
          ? {
              issuedAt: formatDateMomentToISO(
                profile?.calendar,
                item.issuedAt as unknown as Moment
              ),
            }
          : {}),
      }
      if (Object.hasOwn(updatedItem, 'id')) {
        itemsToUpdate.push(updatedItem)
        return
      }
      itemsToCreate.push(updatedItem)
    })

    setLoading(true)
    const res = await Promise.all([
      // @ts-ignore
      ...itemsToCreate.map((i) => generatePromise(i, createDocument, 'create')),
      // @ts-ignore
      ...itemsToUpdate.map((i) => generatePromise(i, updateDocument, 'update')),
      // @ts-ignore
      ...itemsToDelete.map((i) => generatePromise(i, deleteDocument, 'delete')),
    ])
    setLoading(false)

    if (res.every((i) => 'data' in i)) {
      ShowMessage('success', 'Success!')
      refetch()
      navigate(`/sales-network/agent-management/view/${agentId}`)
    } else {
      res
        .filter((i) => 'error' in i)
        // eslint-disable-next-line
        // @ts-ignore
        .forEach((i) => ShowMessage('error', i.error.data.message))
    }
  }

  const onPressCancelChanges = () => {
    if (isViewMode) navigate('/sales-network/agent-management')
    if (isEditMode) {
      handleSetInitialForm(documents!)
      navigate(`/sales-network/agent-management/view/${agentId}`)
    }
  }

  const onPressSaveChanges = () => {
    if (isViewMode) navigate(`/sales-network/agent-management/edit/${agentId}`)
    if (isEditMode) form.submit()
  }
  const onPressVerify = (documentId: string) => {
    verifyDocument({
      id: agentId!,
      documentId,
      body: {
        verificationStatus: AgentDocumentStatusEnum.VERIFIED,
      },
    })
  }

  const dispatchFiles = (obj: IUploadFileBody) => setFiles((p) => ({ ...p, ...obj }))

  const handleRemoveAttachements = (id: string, type: string) => {
    setAttchmntsToRemove((p) => ({ ...p, [type]: p[type] ? [...p[type], id] : [id] }))
  }

  const handlerRemove =
    (
      fields: FormListFieldData[],
      id: string | null,
      name: number,
      remove: (index: number | number[]) => void
    ) =>
    () => {
      return (() => {
        remove(name)
        setItemsToDelete((p) => [...p, ...(id ? [id] : [])])

        if (fields.length === 1) {
          form.setFieldsValue({ documents: [{}] })
        }
      })()
    }

  const content = (
    <div
      className={classNames(styles.verifyContainer, {
        [styles.pt32]: !documents?.generalVerificationStatus,
      })}
    >
      {documents?.generalVerificationStatus && (
        <div className={styles.verifyAgentTopLine}>
          <div className={styles.verifyAgentTopLineStatus}>
            {/* eslint-disable-next-line */}
            <div className={styles[documents?.generalVerificationStatus!]} />
            <div className={styles[`${documents?.generalVerificationStatus}TEXT`]}>
              {/* eslint-disable-next-line */}
              {AgentGeneralStatus[documents?.generalVerificationStatus!]}
            </div>
          </div>
        </div>
      )}

      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        className={styles.formContainer}
        initialValues={{ documents: [{}] }}
      >
        <Form.List name='documents'>
          {(fields, { add, remove }) => (
            <>
              <div className={styles.vertifyListContainer}>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} className={styles.verifyAgentContent}>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        const type = getFieldValue(['documents', name, 'type'])
                        const id = getFieldValue(['documents', name, 'id'])
                        const verificationStatus = getFieldValue([
                          'documents',
                          name,
                          'verificationStatus',
                        ])

                        const title = type
                          ? `${type.split('_').join(' ')} verification`
                          : 'Select Document Type'

                        return (
                          <VerificationBlockHeader
                            status={verificationStatus}
                            title={title}
                            view={isViewMode}
                            onRemove={handlerRemove(fields, id, name, remove)}
                          />
                        )
                      }}
                    </Form.Item>

                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const documentId = getFieldValue(['documents', name, 'id'])
                        const documentItem = documents?.documents.find((i) => i.id === documentId)

                        return (
                          <IdVerificationForm
                            form={form}
                            name={name}
                            view={isViewMode}
                            edit={isEditMode}
                            viewFiles={documentItem?.attachments}
                            dispatchData={dispatchFiles}
                            onPressVerify={onPressVerify}
                            onRemoveAttachments={handleRemoveAttachements}
                            {...restField}
                          />
                        )
                      }}
                    </Form.Item>
                  </div>
                ))}
              </div>

              {!isViewMode && fields.length < Object.keys(AgentDocumentsEnum).length && (
                <div style={{ display: 'flex' }}>
                  <Form.Item style={{ flexBasis: '29.5%' }}>
                    <IconButton icon={<AddIcon />} color='orange' block onClick={() => add()}>
                      Add Document
                    </IconButton>
                  </Form.Item>
                </div>
              )}
            </>
          )}
        </Form.List>
      </Form>

      <FooterActions
        onPressCancelChanges={onPressCancelChanges}
        onPressSaveChanges={onPressSaveChanges}
        isLoading={false}
        isViewMode={isViewMode}
        submitButtonText={isViewMode ? 'Edit Documents' : 'Save Documents'}
        submitButtonDisabled={dataAgent?.isDeleted}
      />
    </div>
  )

  return isFetching || isLoading || dVerify.isLoading ? <Spin>{content}</Spin> : content
}
