import { RcFile } from 'antd/es/upload'
import { compatibleTypeList, MAX_IMAGE_SIZE } from './constants'

export const isCompatibleType = (type: string): boolean => {
  return compatibleTypeList.includes(type)
}

export const isLessThan = (size: number): boolean => {
  return size / 1024 / 1024 < MAX_IMAGE_SIZE
}

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
