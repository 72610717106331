import React, { Dispatch, memo, SetStateAction, useRef, useState } from 'react'
import classNames from 'classnames'
import EmojiPicker, { EmojiClickData, EmojiStyle } from 'emoji-picker-react'
import { SmileIcon } from 'src/assets/svg'
import { useOnClickOutside } from 'src/shared/hooks/useClickOutside'
import styles from './styles.module.scss'

interface IProps {
  onSelectEmoji: (em: EmojiClickData) => void
  emojiOpenText?: string
  handleClick?: Dispatch<SetStateAction<boolean>>
}

const EmojiSelect = ({ onSelectEmoji, emojiOpenText, handleClick }: IProps) => {
  const emojiRef = useRef(null)
  const [isShowEmoji, setIsShowEmoji] = useState(false)

  useOnClickOutside(emojiRef, () => {
    setIsShowEmoji(false)
    handleClick?.(false)
  })

  return (
    <div
      ref={emojiRef}
      className={classNames(styles.messageAction, { [styles.withoutMargin]: !!emojiOpenText })}
      onClick={() => {
        setIsShowEmoji(true)
        handleClick?.(true)
      }}
    >
      <div className={classNames(styles.emojiContainer, { [styles.isShownEmoji]: isShowEmoji })}>
        <EmojiPicker
          onEmojiClick={(em, e) => {
            onSelectEmoji(em)
            handleClick?.(false)
            e.stopPropagation()
          }}
          emojiStyle={EmojiStyle.GOOGLE}
        />
      </div>
      {emojiOpenText ? (
        <div className={styles.messageActionItem}>{emojiOpenText}</div>
      ) : (
        <SmileIcon />
      )}
    </div>
  )
}

export default memo(EmojiSelect)
