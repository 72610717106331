import { useFilter } from '../../../../../../shared/hooks/filter/useFilter'
import { Col, Row } from 'antd'
import { Filter, ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { FilterPopup } from '../../../../../../shared/components/filter/FilterPopup'
import { IFilterFields } from '../../../../../../shared/models/IFilterFields'
import React from 'react'

interface IProps {
  setActiveFilters: (values: ISelectedFilters) => void
  filterValues: ISelectedFilters
  data?: IFilterFields[]
}

export const TableHeader = ({ setActiveFilters, data, filterValues }: IProps): JSX.Element => {
  const { isOpen, onCancel, form, onReset, onFinish } = useFilter({ setActiveFilters })

  return (
    <>
      <Row align='middle' justify='space-between'>
        <Col style={{ display: 'flex', gridGap: 20 }}>
          <Filter
            data={data}
            form={form}
            setIsOpen={onCancel}
            selectedFilters={filterValues}
            setSelectedFilters={setActiveFilters}
          />
        </Col>
      </Row>
      {isOpen && (
        <FilterPopup
          data={data}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          form={form}
          onCancel={onCancel}
          onReset={onReset}
          onFinish={onFinish}
        />
      )}
    </>
  )
}
