import { Filter, ISelectedFilters } from '../../../../../shared/components/filter/Filter'
import { IFilterFields } from '../../../../../shared/models/IFilterFields'
import { Col, Row, Spin } from 'antd'
import HeaderCard from '../../../components/HeaderCard'
import headerCardStyle from '../AgentManagementDetailView.module.scss'
import { useFilter } from 'src/shared/hooks/filter/useFilter'
import { FilterPopup } from 'src/shared/components/filter/FilterPopup'
import { useGetAgentCountersQuery } from 'src/features/salesNetwork/core/http/AgentManagement'

interface IProps {
  setActiveFilters: (values: ISelectedFilters) => void
  filterValues: ISelectedFilters
  data?: IFilterFields[]
  agentId: string
}

export const TableHeader = ({
  setActiveFilters,
  data,
  filterValues,
  agentId,
}: IProps): JSX.Element => {
  const { isOpen, onCancel, form, onReset, onFinish } = useFilter({ setActiveFilters })

  const { data: counters, isFetching } = useGetAgentCountersQuery(agentId)

  return (
    <>
      <Row align='middle' justify='space-between' style={{ marginBottom: 20 }}>
        <Col style={{ display: 'flex', gridGap: 20 }}>
          <Filter
            data={data}
            form={form}
            setIsOpen={onCancel}
            selectedFilters={filterValues}
            setSelectedFilters={setActiveFilters}
          />
        </Col>
      </Row>
      {isOpen && (
        <FilterPopup
          data={data}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          form={form}
          onCancel={onCancel}
          onReset={onReset}
          onFinish={onFinish}
        />
      )}
      <Spin spinning={isFetching}>
        <Row className={headerCardStyle['wrapper-header-cards']} wrap={false}>
          <HeaderCard
            cardName='Network Size'
            cardValue={String(counters?.networkSize || 0)}
            fontSize={24}
          />
          <HeaderCard
            cardName='Active Agents'
            cardValue={String(counters?.activeAgentsCount || 0)}
          />
          <HeaderCard
            cardName='Network Floating Balance'
            cardValue={String(counters?.walletsBalance || 0)}
            right='AFN'
            fontSize={24}
          />
          <HeaderCard
            color='orange'
            cardName='Network Sales'
            cardValue={String(counters?.salesAmount || 0)}
            right='AFN'
          />
        </Row>
      </Spin>
    </>
  )
}
