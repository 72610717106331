import { Button, notification, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { IUser } from '../../../../../../../models/IUser'
import { Link, useNavigate, useParams } from 'react-router-dom'
import emptyAvatarImage from '../../../../../../../../../assets/img/Avatar.png'
import {
  DateTableIcon,
  PhoneLightBlueIcon,
  TableActionIcon,
} from '../../../../../../../../../assets/svg'
import { formatDateWithTime } from '../../../../../../../../../helpers/utils'
import { Toggle } from '../../../../../../../../../shared/components/toggle/Toggle'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../../../../shared/components/popup/Popup'
import { useCallback, useState } from 'react'
import { useTablePagination } from '../../../../../../../../../shared/hooks/table/useTablePagination'
import { ISelectedFilters } from '../../../../../../../../../shared/components/filter/Filter'
import { ITableConf, SortingOrderEnum } from '../../../../../../../../../shared/models/ITableConf'
import { useOnTableChange } from '../../../../../../../../../shared/hooks/table/useOnTableChange'
import styles from './styles.module.scss'
import { useUpdateUserByIdMutation } from '../../../../../../../core/http/UsersManagementApi'
import { formatPhoneNumber } from '../../../../../../../../salesNetwork/helpers'
import { useTableExport } from '../../../../../../../../../shared/hooks/table/useTableExport'
import { SETTINGS_ENUM } from '../../../../../../../../../routes/settings'
import TableHeader from '../../../../TableHeader'
import {
  useUnassignedUsersFromRoleMutation,
  useGetUsersByRoleIdQuery,
  useLazyExportRoleUsersTableQuery,
  useGetRoleUsersTableFiltersListQuery,
} from '../../../../../../../core/http/RolesAndPermissionsManagement'

import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../../shared/hooks/useNotification'
import { ErrorNode, MESSAGE_KEY } from '../../../../../../../../../shared/api/errorHandler'
import { useDeleteUserByIdMutation } from '../../../../../../../core/http/UsersManagementApi'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

interface IProps {
  canEditUser: boolean
}

export const RoleUsers = ({ canEditUser }: IProps) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [filterValues, setFilterValues] = useState<ISelectedFilters>({})
  const [tableConf, setTableConf] = useState<ITableConf>({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
    page: 1,
    limit: 10,
  })

  const { data: users, isFetching } = useGetUsersByRoleIdQuery({
    ...{ ...tableConf, id: id as string },
    ...filterValues,
  })
  const [updateUser, updateUserResp] = useUpdateUserByIdMutation()
  const [deleteUser, deleteUserResp] = useDeleteUserByIdMutation()
  const [updateRole, updateRoleResp] = useUnassignedUsersFromRoleMutation()
  const [exportTable] = useLazyExportRoleUsersTableQuery()
  const { data: filtersList } = useGetRoleUsersTableFiltersListQuery({ id }, { skip: !id })
  const { data: profile } = useGetProfileQuery()

  useTableExport({
    tableConf,
    filterValues,
    promise: exportTable,
    promiseParams: { id: id as string },
  })
  const paginationConfig = useTablePagination(users?.totalCount)
  const onTableParamsChange = useOnTableChange<IUser>(setTableConf)

  const isSuccess = updateUserResp.isSuccess || deleteUserResp.isSuccess || updateRoleResp.isSuccess
  const isError = updateUserResp.isError || deleteUserResp.isError || updateRoleResp.isError
  const error = updateRoleResp.error || updateRoleResp.error || updateRoleResp.error

  useNotification(NOTIFICATION_TYPES.success, isSuccess)
  useNotification(NOTIFICATION_TYPES.error, isError, error as ErrorNode)

  const openNotificationOnUnassigned = useCallback(
    (roleId: number | undefined, id: number) => {
      const btn = (
        <Button type='primary' onClick={() => updateRole({ roleId, userIds: [id] })}>
          Confirm
        </Button>
      )
      const notificationRef = notification
      notificationRef.open({
        type: 'warning',
        message: 'This user will be unassigned',
        key: MESSAGE_KEY,
        btn,
      })
    },
    [deleteUser]
  )

  const tableActionsPopup = useCallback(
    (userId: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () => navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT}/view/${userId}`),
        },
        {
          text: 'Edit',
          shouldDisplay: canEditUser,
          onClick: () => navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT}/edit/${userId}`),
        },
        {
          text: 'Unassign',
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          shouldDisplay: canEditUser,
          withDivider: true,
          onClick: () => openNotificationOnUnassigned(Number(id), userId),
        },
      ]
    },
    // eslint-disable-next-line
    [navigate]
  )

  const columns: ColumnsType<IUser> = [
    {
      title: 'Account Id',
      dataIndex: 'id',
      sorter: true,
      width: '10%',
    },
    {
      title: 'Position',
      dataIndex: 'position',
      sorter: true,
      width: '10%',
      render: (position: IUser['userPosition'], record) =>
        record.userPosition && <span>{record.userPosition?.name}</span>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      width: '17.5%',
      render: (value, record) => (
        <Link to={`${SETTINGS_ENUM.USERS_MANAGEMENT}/view/${record.id}`} className='table-avatar'>
          <img alt='user logo' src={record.logo ?? emptyAvatarImage} style={{ minWidth: 32 }} />
          <div>{`${record.firstName} ${record.lastName}`}</div>
        </Link>
      ),
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      sorter: true,
      width: '20%',
      render: (mobile: IUser['mobileNumber'], record) =>
        record.phoneNumber && (
          <div className='table-contact-info__row mobile'>
            <PhoneLightBlueIcon />
            <span>{formatPhoneNumber(record.phoneNumber)}</span>
          </div>
        ),
    },
    {
      title: 'Date/Time Created',
      dataIndex: 'createdAt',
      width: '17.5%',
      sorter: true,
      render: (createdAt: IUser['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <span>{formatDateWithTime(createdAt, profile?.calendar)}</span>
        </div>
      ),
    },
    {
      title: 'Added by',
      dataIndex: 'createdBy',
      width: '15%',
      sorter: true,
      render: (createdBy: IUser['addedBy'], row) =>
        row?.addedBy && <span>{`${row?.addedBy?.firstName} ${row?.addedBy?.lastName}`}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '9%',
      sorter: true,
      render: (status: IUser['status'], row) => {
        const fd = new FormData()
        return (
          <Toggle
            disabled={!canEditUser}
            defaultChecked={status}
            onChange={(status) => {
              fd.append('user', JSON.stringify({ status }))
              updateUser({ id: row.id, body: fd })
            }}
            loading={updateUserResp.isLoading}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return (
    <>
      <TableHeader
        filterValues={filterValues}
        setActiveFilters={setFilterValues}
        data={filtersList}
      />
      <Table
        columns={columns}
        pagination={paginationConfig}
        className={styles.table}
        onChange={onTableParamsChange}
        loading={isFetching}
        rowKey={(row) => row?.id}
        dataSource={users?.items}
        rowSelection={{ type: 'checkbox' }}
        scroll={{ x: 1100 }}
      />
    </>
  )
}
