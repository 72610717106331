// icons
import { DateTableIcon, PersonIcon, TicksIcon } from 'src/assets/svg'

// components
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'

// helpers
import { formatDateWithTime } from 'src/helpers/utils'
import { IPickupRequestStatus } from 'src/features/Finance/models/IPickupRequest'

// styles
import styles from '../styles.module.scss'
import { nanoid } from 'nanoid'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

export const LogOfOrder = ({ logs }: { logs: IPickupRequestStatus[] }) => {
  const { data: profile } = useGetProfileQuery()

  return (
    <Wrapper title='Log of Order' className={styles.logOfOrderWrapper}>
      <>
        {logs?.map(({ status, user, createdAt }) => (
          <div className={styles.logOfOrderContainer} key={nanoid()}>
            <div className={styles.logStatusContainer}>
              <TicksIcon />
              {status}
            </div>
            <div className={styles.logNameContainer}>
              <PersonIcon />
              {user?.name}
            </div>
            <div className={styles.logDateContainer}>
              <DateTableIcon />
              {formatDateWithTime(createdAt, profile?.calendar)}
            </div>
          </div>
        ))}
      </>
    </Wrapper>
  )
}
