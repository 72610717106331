import { Switch, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { IAgent } from '../../../../models/IAllTable'
import { EmailFilledIcon, PhoneDarkBlueIcon, TableActionIcon } from 'src/assets/svg'
import './styles.scss'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from 'src/shared/components/popup/Popup'
import { AllTableHeader } from '../TableHeader/AllTableHeader'
import { Link, useNavigate } from 'react-router-dom'
import emptyAvatarImage from 'src/assets/img/Avatar.png'
import React, { useCallback, useState } from 'react'
import {
  useDeleteAgentMutation,
  useGetAllTableFiltersListQuery,
  useGetAgentListQuery,
  useLazyGetAgentTableFileByFormatCsvOrXlsxQuery,
  usePatchAgentMutation,
} from '../../../../core/http/AgentManagement'
import { ITableConf } from 'src/shared/models/ITableConf'
import { formatNumberToLocale, formatPhoneNumber } from '../../../../helpers'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { useTableExport } from 'src/shared/hooks/table/useTableExport'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode, showConfirmMessage } from 'src/shared/api/errorHandler'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { SALES_ENUM } from '../../../../../../routes/sales'
import classNames from 'classnames'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import { useAppDispatch } from '../../../../../../redux'
import { agentPolicyApi } from '../../../../core/http/AgentPolicyApi'
import { bankWalletTransactionsApi } from '../../../../../Finance/core/http/BankWalletTransactionsApi'
import { isEmpty } from 'lodash'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IProps {
  canView: boolean
  canEdit: boolean
  canDelete: boolean
  canEditStatus: boolean
}

export const AgentAllTable = ({ canView, canEdit, canDelete, canEditStatus }: IProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const [patch, { data: agentData, isLoading: isStatusUpdating, isSuccess, isError }] =
    usePatchAgentMutation()
  const [deleteAgent, delAgentResp] = useDeleteAgentMutation()

  const invalidateAgentPolicy = () => {
    dispatch(
      agentPolicyApi.util.invalidateTags([
        'IAgentDiscountPolicy',
        'IProductAccessList',
        'IDiscountTemplate',
      ])
    )
    dispatch(bankWalletTransactionsApi.util.invalidateTags(['RefReqList']))
  }

  /** Notifications  */
  useNotification(NOTIFICATION_TYPES.success, isSuccess)
  useNotification(NOTIFICATION_TYPES.error, isError)
  useNotification(NOTIFICATION_TYPES.success, delAgentResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    delAgentResp.isError,
    delAgentResp.error as ErrorNode,
    invalidateAgentPolicy
  )

  const tableActionsPopup = useCallback(
    (id: number, networkSize: number, isDeleted: boolean): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: canView,
          onClick: () => navigate(`view/${id}`),
        },
        {
          text: 'Structure',
          onClick: () => navigate(`${SALES_ENUM.SALES_NETWORK}/structure/${id}`),
          shouldDisplay: networkSize !== 0,
        },
        {
          text: 'Edit',
          shouldDisplay: canEdit,
          onClick: () => navigate(`edit/${id}`),
        },
        {
          text: 'Delete',
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          withDivider: true,
          shouldDisplay: !isDeleted && canDelete,
          onClick: () => showConfirmMessage('This agent will be deleted', () => deleteAgent(id)),
        },
      ]
    },
    [canDelete, canEdit, canView, deleteAgent, navigate]
  )

  const columns: ColumnsType<IAgent> = [
    {
      title: 'Account ID',
      dataIndex: 'id',
      sorter: true,
      width: '10%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      sorter: true,
      width: '15%',
      render: (name: IAgent['firstName'], row) => (
        <RecursivelyReplaceCharacters>
          <Link to={canView ? `view/${row.id}` : '#'} className='table-avatar'>
            <img
              alt='user-photo'
              src={!isPrivateMode && row?.avatar ? row.avatar : emptyAvatarImage}
              loading='lazy'
            />
            <div>{`${name ? `${name} ${row.lastName}` : `${row.companyTitle}`}`}</div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'agentType',
      sorter: true,
      width: '10%',
      render: (agentType: IAgent['agentType']) => (
        <RecursivelyReplaceCharacters>{agentType?.name}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Level',
      dataIndex: 'agentLevel',
      align: 'center',
      width: '7%',
      sorter: true,
      render: (agentLevel: IAgent['agentLevel']) => (
        <RecursivelyReplaceCharacters>{agentLevel?.level}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Network Size',
      dataIndex: 'networkSize',
      align: 'center',
      width: '5%',
      sorter: true,
      render: (networkSize) => (
        <RecursivelyReplaceCharacters>{networkSize}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Contact info',
      dataIndex: 'contactInfo',
      sorter: false,
      width: '15%',
      render: (_, row) => (
        <RecursivelyReplaceCharacters>
          <div className='table-contact-info'>
            <div className='table-contact-info__row mobile'>
              {row?.mobileNumber && (
                <>
                  <PhoneDarkBlueIcon />
                  <span>{formatPhoneNumber(row?.mobileNumber)}</span>
                </>
              )}
            </div>
            <div className='table-contact-info__row'>
              {row?.email && (
                <>
                  <EmailFilledIcon />
                  <span>{row.email}</span>
                </>
              )}
            </div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Region',
      dataIndex: 'region',
      sorter: true,
      width: '10%',
      render: (_, row) => (
        <RecursivelyReplaceCharacters>
          <div>
            <div>{row?.country?.name}</div>
            <div>{row?.province?.name}</div>
            <div>{row?.district?.name}</div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Current Balance',
      dataIndex: 'balance',
      align: 'left',
      width: '15%',
      sorter: true,
      render: (_) => (
        <RecursivelyReplaceCharacters>
          <span className='table-currency'>
            {formatNumberToLocale(_, { isCurrency: true, locale: 'en-US' })}
          </span>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Credit Score',
      dataIndex: 'creditScore',
      align: 'center',
      width: '7%',
      sorter: true,
      render: () => <RecursivelyReplaceCharacters>0</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      render: (status: IAgent['status'], row) => {
        if (row?.isDeleted)
          return (
            <div className='statusContainer'>
              <div className={classNames('deleted', 'statusPoint')} />
              <div>Deleted</div>
            </div>
          )
        return (
          <Switch
            disabled={!canEditStatus || row?.isDeleted}
            defaultChecked={status}
            checked={row.id === agentData?.id ? agentData?.status : status}
            onChange={(status) => {
              const fd = new FormData()
              fd.append('agent', JSON.stringify({ status }))
              patch({
                id: row.id,
                body: fd,
              })
            }}
            loading={isStatusUpdating}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id, row.networkSize, row?.isDeleted)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const { queryParams, queryFields } = useGetQueryParams()

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data, isFetching } = useGetAgentListQuery(
    { ...tableConf, ...filterValues },
    { skip: isEmpty(tableConf) }
  )
  const { data: filtersList } = useGetAllTableFiltersListQuery()
  const [getAgentTableFileByFormatCsvOrXlsxQuery] = useLazyGetAgentTableFileByFormatCsvOrXlsxQuery()

  useTableExport({ tableConf, filterValues, promise: getAgentTableFileByFormatCsvOrXlsxQuery })

  const emptyTableConf = useEmptyTable(() => navigate(SALES_ENUM.NEW_AGENT, { replace: true }))
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IAgent>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  return (
    <>
      <AllTableHeader
        numberOfItems={data?.totalCount}
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filtersList}
      />
      <Table
        columns={columns}
        locale={emptyTableConf}
        loading={isFetching}
        onChange={handleTableChange}
        className='sales-all-table'
        dataSource={data?.items}
        scroll={{ x: 1300 }}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.id}
        pagination={pagination}
      />
    </>
  )
}
