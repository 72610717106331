import { useFilter } from 'src/shared/hooks/filter/useFilter'
import { Filter, ISelectedFilters } from 'src/shared/components/filter/Filter'
import { IFilterFields } from 'src/shared/models/IFilterFields'
import { FilterPopup } from 'src/shared/components/filter/FilterPopup'

import styles from './style.module.scss'
import { AddIcon } from 'src/assets/svg'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { Col, Row } from 'antd'
import { useState } from 'react'
import { AddFileModal } from '../AddFileModal'
import { nanoid } from 'nanoid'

interface IProps {
  setActiveFilters: (values: ISelectedFilters) => void
  filterValues: ISelectedFilters
  data?: IFilterFields[]
  canAddFile: boolean
}

export const FileManagementTableHeader = ({
  setActiveFilters,
  filterValues,
  data,
  canAddFile,
}: IProps) => {
  const [modalKey, setModalKey] = useState(() => nanoid())
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { isOpen, onCancel, form, onReset, onFinish } = useFilter({ setActiveFilters })

  const handleCancel = () => {
    setIsModalOpen(false)
    setTimeout(() => {
      setModalKey(nanoid())
    }, 300)
  }

  return (
    <>
      <Row className={styles.tableHeader} justify='space-between' align='middle'>
        <Col>
          <Filter
            data={data}
            form={form}
            setIsOpen={onCancel}
            selectedFilters={filterValues}
            setSelectedFilters={setActiveFilters}
          />
        </Col>
        {canAddFile && (
          <Col>
            <IconButton
              icon={<AddIcon />}
              type='primary'
              color='orange'
              onClick={() => setIsModalOpen(true)}
            />
            <AddFileModal key={modalKey} isOpen={isModalOpen} handleCancel={handleCancel} />
          </Col>
        )}
      </Row>
      {isOpen && (
        <FilterPopup
          data={data}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          form={form}
          onCancel={onCancel}
          onReset={onReset}
          onFinish={onFinish}
        />
      )}
    </>
  )
}
