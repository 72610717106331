import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../../shared/models/ITableConf'
import { IRestriction, IRestrictionMutate } from '../../models/Restrictions'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

export const customerRestrictionManagementApi = createApi({
  reducerPath: 'customerRestrictionManagement',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['IRestrictionList', 'IRestriction', 'FilterFields'],
  endpoints: (builder) => ({
    getCustomerRestrictions: builder.query<ITableResponse<IRestriction>, ITableConf>({
      query: (params) => ({
        url: 'customer-restrictions',
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: () => ['IRestrictionList'],
    }),

    getCustomerRestrictionById: builder.query<IRestriction, string>({
      query: (id) => ({
        url: `customer-restrictions/${id}`,
      }),
      providesTags: (data) => [{ type: 'IRestriction', id: data?.id }],
    }),

    createCustomerRestriction: builder.mutation<IRestriction, IRestrictionMutate>({
      query: (body) => ({
        url: 'customer-restrictions',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IRestrictionList', 'FilterFields'],
    }),

    updateCustomerRestriction: builder.mutation<
      IRestriction,
      { id: string; body: Partial<IRestrictionMutate> }
    >({
      query: ({ id, body }) => ({
        url: `customer-restrictions/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (restriction) => [
        { type: 'IRestriction', id: restriction?.id },
        'IRestrictionList',
        'FilterFields',
      ],
    }),

    deleteCustomerRestriction: builder.mutation<void, string>({
      query: (id) => ({
        url: `customer-restrictions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['IRestrictionList', 'FilterFields'],
    }),

    exportCustomerRestrictionListTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx' }
    >({
      query: ({ conf, formatFile }) => ({
        url: `customer-restrictions/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getCustomerRestrictionFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'customer-restrictions/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `customer-restrictions/filter-values?filterField=${filter.field}&id=${arg}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      keepUnusedDataFor: 0,
      providesTags: () => ['FilterFields'],
    }),
  }),
})

export const {
  useGetCustomerRestrictionsQuery,
  useGetCustomerRestrictionByIdQuery,
  useCreateCustomerRestrictionMutation,
  useUpdateCustomerRestrictionMutation,
  useDeleteCustomerRestrictionMutation,
  useLazyExportCustomerRestrictionListTableQuery,
  useGetCustomerRestrictionFiltersListQuery,
} = customerRestrictionManagementApi
