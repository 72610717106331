import { useState } from 'react'
import { ITableConf } from '../../../../../../shared/models/ITableConf'
import {
  useGetHawalaWalletsListQuery,
  useLazyGenerateStatementTransactionsPdfQuery,
} from '../../../../core/http/StatementOfWalletsApi'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import {
  IWalletStatementTrans,
  WALLET_STATEMENT_CAT_ROUTE_ENUM,
} from '../../../../models/IWalletStatement'
import { useTableColumns } from '../columns'
import { Table } from 'antd'
import styles from '../../styles.module.scss'
import { usePdfExport } from '../../../../../../shared/hooks/table/usePdfExport'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../shared/api/errorHandler'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { isEmpty } from 'lodash'

export const HawalaWalletsTable = () => {
  const { queryParams, queryFields } = useGetQueryParams()
  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)
  const { data, isFetching } = useGetHawalaWalletsListQuery(tableConf, { skip: isEmpty(tableConf) })
  const [exportTable, exportTableResp] = useLazyGenerateStatementTransactionsPdfQuery()

  useNotification(
    NOTIFICATION_TYPES.error,
    exportTableResp.isError,
    exportTableResp.error as ErrorNode
  )
  usePdfExport({
    route: WALLET_STATEMENT_CAT_ROUTE_ENUM.HAWALA,
    promise: exportTable,
    filterValues,
  })

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange } = useTable<IWalletStatementTrans>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })
  const columns = useTableColumns(WALLET_STATEMENT_CAT_ROUTE_ENUM.HAWALA)

  return (
    <>
      {/* <TableHeader.tsx setFilterValues={setFilterValues} /> */}
      <Table
        className={styles.table}
        pagination={pagination}
        locale={emptyTableConf}
        columns={columns}
        onChange={handleTableChange}
        rowKey={(record) => record.walletId}
        loading={isFetching}
        dataSource={data?.items}
      />
    </>
  )
}
