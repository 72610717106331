import React, { useCallback, useEffect } from 'react'
import { FormInstance, Form } from 'antd'
import VendorInfo from './Components/VendorInfo'
import GeneralInfo from './Components/GeneralInfo'
import Headers from './Components/Headers'
import RequestVariables from './Components/RequestVariables'
import ResponseVariables from './Components/ResponseVariables'
import ResponseCode from './Components/ResponseCode'
import styles from './styles.module.scss'
import { useParams } from 'react-router'

interface IProps {
  form: FormInstance
  onFinish: () => void
}

type AddCallback = <T>(value: T, key: string) => void

const VendorIntegrationForm = ({ form, onFinish }: IProps) => {
  const { id } = useParams()

  useEffect(() => {
    form.setFieldValue('headers', [
      {
        key: '',
        type: 'constant',
        value: '',
        base_authorisation: false,
      },
    ])

    form.setFieldValue('requestVariables', [
      {
        key: '',
        type: 'constant',
        value: '',
        encrypted: false,
        system_variables: 'AMOUNT_WITH_FORMAT',
      },
    ])

    form.setFieldValue('responseVariables', [
      {
        key: '',
        type: 'constant',
        value: '',
        encrypted: false,
        system_variables: 'AMOUNT_WITH_FORMAT',
      },
    ])

    form.setFieldValue('responseCode', [
      {
        code: '',
        description: '',
        status: 'SUCCESS',
        type: 'VENDOR_CODE',
      },
    ])
  }, [form])

  const onAddNew = useCallback<AddCallback>(
    (value, key) => {
      const prev = form.getFieldValue(key)
      form.setFieldValue(key, [...prev, value])
      form.setFieldValue('vendorId', id)
    },
    [form]
  )

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className={styles.columnWrapper}
      layout='vertical'
      initialValues={{ vendorId: id }}
    >
      <VendorInfo />
      <GeneralInfo />
      <Headers onAddNew={onAddNew} />
      <RequestVariables onAddNew={onAddNew} />
      <ResponseVariables onAddNew={onAddNew} />
      <ResponseCode onAddNew={onAddNew} />
    </Form>
  )
}

export default VendorIntegrationForm
