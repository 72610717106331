import FormItem from 'antd/es/form/FormItem'

// components
import { TextField } from 'src/shared/components/textfield/TextField'

// styles
import styles from '../styles.module.scss'
import TransactionStatus from 'src/shared/components/transactionStatus'

interface IDetailInfo {
  status: string
}

export const DetailInfo = ({ status }: IDetailInfo) => {
  return (
    <div className={styles.container}>
      <div className={styles.headerDetailInfo}>
        <div className={styles.header}>Transaction Details</div>
        <TransactionStatus status={status} />
      </div>
      <div>
        <div className={styles.row}>
          <FormItem name='id' label='Trasaction ID'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem name={['vendor', 'name']} label='Vendor'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.dblRow}>
          <FormItem name='paymentType' label='Payment Method'>
            <TextField disabled />
          </FormItem>
          <FormItem name='currency' label='Currency'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.dblRow}>
          <FormItem name='vendorBalanceBefore' label='Vendor Balance Before'>
            <TextField disabled />
          </FormItem>
          <FormItem name='vendorBalanceAfter' label='Vendor Balance After'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem name='amount' label='eMoney Amount'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem name='currencyAmount' label='Cash Amount'>
            <TextField disabled />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem name='exchangeRate' label='FX rate'>
            <TextField disabled />
          </FormItem>
        </div>
      </div>
    </div>
  )
}
