import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IVendor, IVendorContact } from '../../models/IVendorManagement'
import { RcFile } from 'antd/es/upload'
import { RootState } from '../../../../redux'
import { IProduct } from '../../models/IProduct'

interface VendorState {
  formState: Partial<IVendor>
  picture: Partial<RcFile>
  productsList: IProduct[]
  contactList: IVendorContact[]
}

const initialState: VendorState = {
  formState: {},
  picture: {},
  productsList: [],
  contactList: [],
}

export const vendorManagementSlice = createSlice({
  name: 'vendorSlice',
  initialState,
  reducers: {
    setInitialState: (state, { payload }: PayloadAction<{ form: IVendor }>) => {
      const { form } = payload
      state.formState = form
    },
    addProduct: (state, { payload }: PayloadAction<IProduct>) => {
      state.productsList.push(payload)
    },
    deleteProductById: (state, { payload }: PayloadAction<number>) => {
      if (state.productsList) {
        state.productsList = state.productsList.filter((product) => product.id !== payload)
      }
    },
    addContact(state, action: PayloadAction<IVendorContact>) {
      state.contactList.push(action.payload)
    },
    deleteContactById(state, action: PayloadAction<string | number>) {
      if (state.contactList) {
        state.contactList = state.contactList.filter((contact) => contact.id !== action.payload)
      }
    },
    clearVendorSliceState(state) {
      state.picture = {}
      state.formState = {}
      state.contactList = []
      state.productsList = []
    },
  },
})

export const selectVendorManagementState = (state: RootState) => state.vendorManagementSlice
export const getContactListSelector = (state: RootState) => state.vendorManagementSlice.contactList

export const {
  addProduct,
  deleteProductById,
  addContact,
  deleteContactById,
  clearVendorSliceState,
} = vendorManagementSlice.actions

export default vendorManagementSlice.reducer
