import React, { memo, useEffect, useMemo, useState } from 'react'
import { Checkbox } from '../../../../../../../../shared/components/checkbox/Checkbox'

import styles from '../../styles.module.scss'
import { IPermissionCreate, IRolePermissionModule } from '../../../../../../models/IRoles'

interface IProps {
  disabled?: boolean
  selectedPermissions: Array<IPermissionCreate>
  module: IRolePermissionModule
  onSelectAllSubmodulePermissions: (module: IRolePermissionModule, status: boolean) => void
}

const PermissionsAllInSubModuleCheckbox = ({
  disabled,
  selectedPermissions,
  module,
  onSelectAllSubmodulePermissions,
}: IProps) => {
  const [isChecked, setIsChecked] = useState(false)
  const [isIndeterminate, setIsIndeterminate] = useState(false)

  const permissionsCountInModule = useMemo(
    () =>
      module.subModules.map((module) => module.permissionModuleAllowedPermissions).flat().length,
    [module.subModules]
  )

  const selectedPermissionsCountInModule = useMemo(
    () =>
      module.subModules
        .map((module) => selectedPermissions.filter((per) => per.permissionModuleId === module.id))
        .flat().length,
    [module.subModules, selectedPermissions]
  )

  useEffect(() => {
    const checked =
      selectedPermissionsCountInModule > 0 &&
      permissionsCountInModule === selectedPermissionsCountInModule
    setIsChecked(checked)
  }, [permissionsCountInModule, selectedPermissionsCountInModule])

  useEffect(() => {
    const selectedDiff = permissionsCountInModule - selectedPermissionsCountInModule
    const indeterminate = selectedDiff > 0 && selectedDiff !== permissionsCountInModule
    setIsIndeterminate(indeterminate)
  }, [permissionsCountInModule, selectedPermissionsCountInModule])

  return (
    <div className={styles.actionContainer}>
      <Checkbox
        disabled={disabled}
        checked={isChecked}
        indeterminate={isIndeterminate}
        onChange={(status) => onSelectAllSubmodulePermissions(module, status.target.checked)}
      />
    </div>
  )
}

export default memo(PermissionsAllInSubModuleCheckbox)
