import React, { useCallback, useContext, useState } from 'react'
import { RightModalContext } from '../../../../../Modals'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ErrorNode, showConfirmMessage } from '../../../../../../shared/api/errorHandler'
import {
  useDeleteSegmentByIdMutation,
  useGetSegmentListQuery,
  useLazyExportSegmentsTableQuery,
  useUpdateSegmentByIdMutation,
} from '../../../../core/http/SegmentsApi'
import { useGetQueryParams } from '../../../../../../shared/hooks/table/useGetQueryParams'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import { isEmpty } from 'lodash'
import { useTableExport } from '../../../../../../shared/hooks/table/useTableExport'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import useTable from '../../../../../../shared/hooks/table/useTable'
import styles from '../../styles.module.scss'
import { Table } from 'antd'
import { ISegmentTable } from '../../../../models/ISegment'
import { ColumnsType } from 'antd/es/table'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../shared/components/popup/Popup'
import { TableActionIcon } from '../../../../../../assets/svg'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'

export const SegmentsTable = () => {
  const { onOpen } = useContext(RightModalContext)

  const [delSegm, delSegmResp] = useDeleteSegmentByIdMutation()
  const [updSegm, updSegmResp] = useUpdateSegmentByIdMutation()

  useNotification(NOTIFICATION_TYPES.success, delSegmResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, updSegmResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, delSegmResp.isError, delSegmResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, updSegmResp.isError, updSegmResp.error as ErrorNode)

  const { queryParams } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'id',
  })

  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data, isFetching } = useGetSegmentListQuery(
    { ...tableConf },
    { skip: isEmpty(tableConf) }
  )
  const [exportTable] = useLazyExportSegmentsTableQuery()

  useTableExport({ tableConf, promise: exportTable })

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange } = useTable<ISegmentTable>({
    total: data?.totalCount,
    setTableConf,
  })

  const tableActionsPopup = useCallback(
    (id: string): IPopupListItems[] => [
      {
        text: 'View',
        shouldDisplay: true,
        onClick: () =>
          onOpen(RIGHT_MODALS.SALES.SEGMENT_TARGET_GROUP, {
            title: 'View Target Group',
            mode: 'view',
            id,
          }),
      },
      {
        text: 'Edit',
        shouldDisplay: true,
        onClick: () =>
          onOpen(RIGHT_MODALS.SALES.SEGMENT_TARGET_GROUP, {
            title: 'Edit Target Group',
            mode: 'edit',
            id,
          }),
      },
      {
        text: 'Delete',
        withDivider: true,
        color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        shouldDisplay: true,
        onClick: () => showConfirmMessage('This segment will be deleted', () => delSegm(id)),
      },
    ],
    [delSegm, onOpen]
  )

  const columns: ColumnsType<ISegmentTable> = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '25%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: true,
      width: '35%',
      render: (title) => <RecursivelyReplaceCharacters>{title}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Customers Count',
      dataIndex: 'count',
      width: '25%',
      align: 'center',
      render: (_, row) => (
        <RecursivelyReplaceCharacters>{row?.customersCount}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      render: (status: boolean, { id }) => {
        return (
          <Toggle
            defaultChecked={status}
            checked={status}
            loading={updSegmResp.isLoading}
            onChange={(status) => {
              updSegm({ id, body: { status } })
            }}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      width: '1%',
      key: 'edit',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row?.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return (
    <Table
      locale={emptyTableConf}
      pagination={pagination}
      onChange={handleTableChange}
      className={styles.table}
      scroll={{ x: 1000 }}
      loading={isFetching}
      columns={columns}
      dataSource={data?.items}
      rowSelection={{ type: 'checkbox' }}
      rowKey={(record) => record?.id}
    />
  )
}
