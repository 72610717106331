import classNames from 'classnames'
import { ReactNode } from 'react'

// styles
import styles from './styles.module.scss'

interface IWrapper {
  children: ReactNode
  title: string
  className?: string
  titleIcon?: ReactNode | undefined
  actions?: ReactNode | undefined
}
export const Wrapper = ({ children, title, className, titleIcon, actions }: IWrapper) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.wrapperTitleContainer}>
        <div
          className={classNames({
            [styles.wrapperTitleIcon]: titleIcon,
          })}
        >
          {title}
          {titleIcon && titleIcon}
        </div>
        {actions && <div className={styles.actionsBtns}>{actions}</div>}
      </div>
      {children}
    </div>
  )
}
