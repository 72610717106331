import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { ITargetGroup, ITargetGroupCreate, ITargetGroupSize } from '../../models/ITargetGroup'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

export const targetGroupsApi = createApi({
  reducerPath: 'targetGroups',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['TargetGroups', 'TargetGroup'],
  endpoints: (builder) => ({
    getTargetGroupSize: builder.query<number, ITargetGroupSize>({
      query: (params) => ({
        url: `target-groups/size`,
        params,
      }),
      keepUnusedDataFor: 0,
    }),

    getTargetGroupList: builder.query<ITableResponse<ITargetGroup>, ITableConf>({
      query: (params) => ({
        url: `target-groups`,
        params,
      }),
      providesTags: () => ['TargetGroups'],
    }),

    createTargetGroup: builder.mutation<ITargetGroup, ITargetGroupCreate>({
      query: (body) => ({
        url: `target-groups`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['TargetGroups'],
    }),

    getTargetGroupById: builder.query<ITargetGroup, string>({
      query: (id) => ({
        url: `target-groups/${id}`,
      }),
      providesTags: (result) => [{ type: 'TargetGroup', id: result?.id }],
    }),

    updateTargetGroup: builder.mutation<
      ITargetGroup,
      { id: string | number; body: Partial<ITargetGroupCreate> }
    >({
      query: ({ id, body }) => ({
        url: `target-groups/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result) => ['TargetGroups', { type: 'TargetGroup', id: result?.id }],
    }),

    getTargetGroupsFilterList: builder.query<Array<IFilterFields>, null>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: `target-groups/filter-fields`,
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `target-groups/filter-values/filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),
  }),
})

export const {
  useCreateTargetGroupMutation,
  useGetTargetGroupsFilterListQuery,
  useGetTargetGroupListQuery,
  useGetTargetGroupByIdQuery,
  useUpdateTargetGroupMutation,
  useLazyGetTargetGroupSizeQuery,
} = targetGroupsApi
