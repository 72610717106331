import { PasswordIcon } from '../../../assets/svg'
import { Input, InputProps } from 'antd'
import * as React from 'react'

export const InputPassword = (props: InputProps) => {
  return (
    <Input.Password
      {...props}
      autoComplete='new-password'
      iconRender={(visible) => {
        return visible ? (
          <span>
            <PasswordIcon active />
          </span>
        ) : (
          <span>
            <PasswordIcon />
          </span>
        )
      }}
    />
  )
}
