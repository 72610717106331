import React, { lazy, Suspense } from 'react'
import { Spin } from 'antd/es'
import { FileCategoryEnum, IFile } from '../../../../../../models/IFileManagementList'
import { VideoViewer } from '../VideoViewer'
import { ImageViewer } from '../ImageViewer'
import { TextViewer } from '../TextViewer'
import { PDFViewer } from '../PDFViewer'
import { NotFoundPage } from '../../../../../../../NotFoundPage'
import { DownloadIcon } from '../../../../../../../../assets/svg'
import styles from '../styles.module.scss'
import { AudioViewer } from '../AudioViewer'

const DocViewer = lazy(() => import('../DocViewer'))

interface IProps {
  data: IFile
}

const SingleFile = ({ data }: IProps) => {
  const onDownloadFile = (link: string, fileName: string) => {
    if (link) {
      const element = document.createElement('a')
      element.setAttribute('href', link)
      element.setAttribute('download', fileName)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
  }

  if (data?.category === FileCategoryEnum.VIDEO) {
    return <VideoViewer src={data.link} />
  }

  if (data?.category === FileCategoryEnum.PHOTO) {
    return <ImageViewer src={data.link} extension={data.fileFormat} />
  }

  if (data.category === FileCategoryEnum.AUDIO) {
    return <AudioViewer src={data.link} />
  }

  if (['.csv', '.txt', '.xls', '.xlsx'].includes(data.fileFormat)) {
    return <TextViewer uri={data.link} fileName={data.name} fileFormat={data.fileFormat} />
  }

  if (data.fileFormat === '.docx') {
    return (
      <Suspense fallback={<Spin />}>
        <DocViewer src={data.link} type='docx' />
      </Suspense>
    )
  }

  if (data.fileFormat === '.pdf') {
    return <PDFViewer src={data.link} />
  }

  return (
    <div className={styles.unknownPreview}>
      <NotFoundPage
        text={`Unfortunately, there is no way to open ${data.name}.`}
        btnText='Download'
        onAddButtonClick={() => onDownloadFile(data.link, data.name)}
        icon={<DownloadIcon />}
      />
    </div>
  )
}

export default SingleFile
