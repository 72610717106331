import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import FormList from 'antd/es/form/FormList'
import { Form, Row, Select } from 'antd'
import classNames from 'classnames'
import styles from '../../../../../../Settings/pages/UserManagement/Components/Tabs/Users/UsersManagementView/styles.module.scss'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import {
  AddIcon,
  BucketIcon,
  CopyIcon,
  PhoneLightBlueIcon,
  TelegramIcon,
  ViberIcon,
  WhatsappIcon,
} from '../../../../../../../assets/svg'
import React, { useMemo } from 'react'
import { startCase } from 'lodash'
import { CONTACT_TYPE_ENUM } from '../../../../../../../shared/models/Contact'
import FormItem from 'antd/es/form/FormItem'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { PhoneInputWithCountry } from '../../../../../../../shared/components/PhoneInput'
import {
  mobileNumberLengthFrom8To14,
  requiredValidator,
} from '../../../../../../../helpers/validation'
import { ErrorNode, handleError, handleInfo } from '../../../../../../../shared/api/errorHandler'

export const CustomerIdentifiersBlock = ({ view }: IViewMode) => {
  const form = useFormInstance()
  const contactTypes = useMemo(
    () => [
      { type: CONTACT_TYPE_ENUM.MOBILE, icon: <PhoneLightBlueIcon /> },
      { type: CONTACT_TYPE_ENUM.WHATSAPP, icon: <WhatsappIcon /> },
      { type: CONTACT_TYPE_ENUM.TELEGRAM, icon: <TelegramIcon /> },
      { type: CONTACT_TYPE_ENUM.VIBER, icon: <ViberIcon /> },
    ],
    []
  )

  const onCopyText = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        handleInfo('Text copied')
      })
      .catch((error) => handleError(error as ErrorNode))
  }

  return (
    <Wrapper title='Customer Identifiers'>
      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <FormList
            name='identifiers'
            rules={[
              {
                validator: () => {
                  if (getFieldValue('identifiers') && getFieldValue('identifiers').length) {
                    return Promise.resolve()
                  }
                  return Promise.reject('At least one customer identifier is required')
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ name, key }) => (
                  <div key={key} style={{ display: 'flex', gridGap: 12 }}>
                    <FormItem
                      name={[name, 'type']}
                      style={{ flexBasis: '30%', minWidth: 40 }}
                      rules={[{ required: true, validator: requiredValidator('Type') }]}
                    >
                      <Select
                        disabled={view}
                        onChange={() => {
                          form.resetFields([['identifiers', name, 'value']])
                        }}
                        placeholder='Select Identifier'
                        allowClear
                      >
                        {contactTypes.map(({ type, icon }) => {
                          return (
                            <Select.Option key={type} value={type}>
                              <div className={styles.wrapperContentOption}>
                                <div className={classNames(styles.wrapperSelectIcon)}>{icon}</div>
                                <div>{startCase(CONTACT_TYPE_ENUM[type].toLowerCase())}</div>
                              </div>
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </FormItem>

                    <Form.Item
                      name={[name, 'value']}
                      style={{ flexBasis: '65%' }}
                      rules={[
                        { required: true, message: 'Field required' },
                        { validator: mobileNumberLengthFrom8To14 },
                      ]}
                    >
                      <PhoneInputWithCountry
                        fieldName={['identifiers', name, 'value']}
                        form={form}
                        view={view}
                      />
                    </Form.Item>

                    {view && (
                      <div className={styles['wrapper-icon']}>
                        <IconButton
                          onClick={() =>
                            onCopyText(form.getFieldValue(['identifiers', name, 'value']))
                          }
                          slim
                          size='large'
                          color='orange'
                          icon={<CopyIcon />}
                          type='default'
                        />
                      </div>
                    )}

                    {!view && (
                      <div className={styles['wrapper-icon']}>
                        <IconButton
                          slim
                          size='large'
                          color='orange'
                          icon={<BucketIcon />}
                          type='default'
                          disabled={view}
                          onClick={() => {
                            remove(name)
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
                {!view && (
                  <Row>
                    <Form.Item className={classNames(styles.noBottomMargin, styles.addButton)}>
                      <IconButton icon={<AddIcon />} color='orange' block onClick={() => add()} />
                    </Form.Item>
                  </Row>
                )}
                <div className={styles.errorsList}>
                  {errors?.map((error, i) => (
                    <div key={i}>{error}</div>
                  ))}
                </div>
              </>
            )}
          </FormList>
        )}
      </FormItem>
    </Wrapper>
  )
}
