import { RenderExpandIconProps } from 'rc-table/lib/interface'
import classNames from 'classnames'
import momentFromLibrary, { Moment } from 'moment-timezone'
import dayjs from 'dayjs'

import { ExpandTableDown, ExpandTableUp } from 'src/assets/svg'
import { ErrorNode, handleError, handleInfo } from 'src/shared/api/errorHandler'

import styles from './utils.module.scss'
import { DEBIT_CREDIT_ENUM } from '../features/Finance/models/ITransaction'
import { formatNumberToLocale } from '../features/salesNetwork/helpers'
import { SHAMSI_MONTHS } from 'src/shared/components/calendar/index.d'
import { CALENDAR_TYPE_ENUM } from 'src/features/Settings/models/IUser'
import React, { cloneElement, isValidElement, ReactNode } from 'react'
import { RecursivelyReplaceCharacters } from '../shared/components/privateMode'
import heic2any from 'heic2any'

momentFromLibrary.tz.setDefault(
  (momentFromLibrary()?.tz() as string) || (momentFromLibrary?.tz?.guess() as string)
)
export const moment = momentFromLibrary

export const tableExpandedIcon = <T,>({ expanded, onExpand, record }: RenderExpandIconProps<T>) => {
  if (expanded) {
    return (
      <a
        className={classNames(styles.expandedIconContainer, styles.active, 'expandIcon')}
        onClick={(e) => {
          onExpand(record, e)
        }}
      >
        <ExpandTableUp />
      </a>
    )
  } else {
    return (
      <a
        className={classNames(styles.expandedIconContainer, 'expandIcon')}
        onClick={(e) => {
          onExpand(record, e)
        }}
      >
        <ExpandTableDown />
      </a>
    )
  }
}

export const renderDebitCreditAmount = (amount: number, drCr?: DEBIT_CREDIT_ENUM) => (
  <RecursivelyReplaceCharacters>
    <span
      className={classNames('amountContainer', {
        dr:
          (amount < 0 && drCr === DEBIT_CREDIT_ENUM.CREDIT) ||
          (amount > 0 && drCr === DEBIT_CREDIT_ENUM.DEBIT),
        cr:
          (amount < 0 && drCr === DEBIT_CREDIT_ENUM.DEBIT) ||
          (amount > 0 && drCr === DEBIT_CREDIT_ENUM.CREDIT),
        zero: !amount,
      })}
    >
      {formatMoneyByCurrencyType(amount, drCr)}
    </span>
  </RecursivelyReplaceCharacters>
)

export const formatMoneyByCurrencyType = (value: number, drCr?: DEBIT_CREDIT_ENUM): string => {
  if (!value) return value?.toString()

  if (drCr === DEBIT_CREDIT_ENUM.CREDIT) {
    return value > 0
      ? `+${formatNumberToLocale(Math.abs(value))}`
      : `-${formatNumberToLocale(Math.abs(value))}`
  }
  if (drCr === DEBIT_CREDIT_ENUM.DEBIT) {
    return value < 0
      ? `+${formatNumberToLocale(Math.abs(value))}`
      : `-${formatNumberToLocale(Math.abs(value))}`
  }

  return formatNumberToLocale(value)
}

export const extractLinkDomain = (link: string) => {
  const res = new RegExp(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/gim)

  return res?.exec(link)?.[1]
}

export const formatOnlyDate: (
  date?: string | Date,
  calendarType?: CALENDAR_TYPE_ENUM | undefined
) => string = (date, calendarType) => {
  const d = date || new Date()

  if (calendarType === CALENDAR_TYPE_ENUM.SHAMSI && d) {
    const end = dayjs(d).calendar('jalali').format('DD, YYYY')
    const { shamsiMonth } = formatToShamsiDate(d)
    return `${shamsiMonth} ${end}`
  }

  return moment(d).format('MMMM DD, YYYY')
}

export const formatDateWithTime: (
  date?: string | Date,
  calendarType?: CALENDAR_TYPE_ENUM | undefined
) => string = (date, calendarType) => {
  const d = date || new Date()

  if (calendarType === CALENDAR_TYPE_ENUM.SHAMSI && d) {
    const end = dayjs(d).calendar('jalali').format('DD, YYYY HH:mm')
    const { shamsiMonthShort } = formatToShamsiDate(d)
    return `${shamsiMonthShort} ${end}`
  }
  return moment(d).format('MMM DD, YYYY HH:mm')
}

export const formatDateWithSeconds: (
  date?: string | Date,
  calendarType?: CALENDAR_TYPE_ENUM | undefined
) => string = (date, calendarType) => {
  const d = date || new Date()

  if (calendarType === CALENDAR_TYPE_ENUM.SHAMSI && d) {
    const end = dayjs(d).calendar('jalali').format('DD, YYYY HH:mm:ss')
    const { shamsiMonthShort } = formatToShamsiDate(d)
    return `${shamsiMonthShort} ${end}`
  }

  return moment(d).format('MMM DD, YYYY HH:mm:ss')
}

export const onCopyText = (value: string) => {
  navigator.clipboard
    .writeText(value)
    .then(() => {
      handleInfo('Text copied')
    })
    .catch((error) => handleError(error as ErrorNode))
}

export const isPositiveNumber = (value: number) => value > 0

export const formatMomentDateToDayStart = (date: Moment | string) => {
  return moment(date).startOf('day')
}

export const formatMomentDateToDayEnd = (date: Moment | string) => {
  return moment(date).endOf('day')
}

export const generatePassword = (length = 8) => {
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz'
  const digitChars = '0123456789'
  const specialChars = '!@#$%^&*'

  let password = ''
  password += getRandomChar(uppercaseChars)
  password += getRandomChar(lowercaseChars)
  password += getRandomChar(digitChars)
  password += getRandomChar(specialChars)

  const remainingChars = length - 4
  for (let i = 0; i < remainingChars; i++) {
    const allChars = uppercaseChars + lowercaseChars + digitChars + specialChars
    password += getRandomChar(allChars)
  }

  return password
}

const getRandomChar = (characters: string) => {
  const index = Math.floor(Math.random() * characters.length)
  return characters.charAt(index)
}

export const deleteHtmlTagsFromString = (value: string) => {
  return value?.replace(/(<([^>]+)>)/gi, '') || ''
}

export const generateId = (): string => {
  let id = ''
  const symbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < 10; i++) {
    id += symbols.charAt(Math.floor(Math.random() * symbols.length))
  }

  return id
}

export const formatToShamsiDate = (
  date: Date | string = new Date()
): { shamsiMonth: string; shamsiMonthShort: string } => {
  const d =
    typeof date === 'string'
      ? dayjs(date).calendar('jalali')
      : dayjs(date.toISOString()).calendar('jalali')

  const month = (d.get('month') + 1).toString()

  const shamsiData = SHAMSI_MONTHS[month as keyof typeof SHAMSI_MONTHS]

  return {
    shamsiMonth: shamsiData.name,
    shamsiMonthShort: shamsiData.short,
  }
}

export const getFormCalendarTime = (
  calendarType: CALENDAR_TYPE_ENUM | undefined,
  data: string[] | string
) => {
  if (calendarType === CALENDAR_TYPE_ENUM.SHAMSI) {
    return Array.isArray(data)
      ? data.map((i) => dayjs(i).calendar('jalali'))
      : dayjs(data).calendar('jalali')
  }
  if (calendarType === CALENDAR_TYPE_ENUM.GREGORIAN) {
    return Array.isArray(data) ? data.map((i) => moment(i)) : moment(data)
  }
}

export const formatShamsiMomentToISO = (
  calendarType: CALENDAR_TYPE_ENUM | undefined,
  date: Moment
) => {
  return calendarType === CALENDAR_TYPE_ENUM.SHAMSI ? date.toISOString() : date
}

export const formatDateMomentToISO = (
  calendarType: CALENDAR_TYPE_ENUM | undefined,
  date: Moment
) => {
  return calendarType === CALENDAR_TYPE_ENUM.SHAMSI
    ? date.toISOString()
    : moment(date).toISOString()
}

export const formatMomentISOToShamsiISO = (date: string) => {
  return dayjs(date).calendar('jalali').hour(0).minute(0).second(0).millisecond(0)
}

export const getHourNames = (startDate: string, endDate: string) => {
  const diffInHours = moment.duration(moment(endDate).diff(startDate)).asHours()
  const hourNames = []

  for (let i = 0; i <= diffInHours; i++) {
    const hour = moment(startDate).add(i, 'hours').format('h A')
    hourNames.push(hour)
  }

  return hourNames
}

export const downloadFileThroughATag = (link: string, fileName: string) => {
  fetch(link, { mode: 'cors', cache: 'no-cache' })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const element = document.createElement('a')
      element.setAttribute('href', url)
      element.setAttribute('download', fileName)
      element.style.display = 'none'

      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
      window.URL.revokeObjectURL(url)
    })
}

export const getFrequency = (isoDate1: string, isoDate2: string) => {
  const date1 = moment(isoDate1)
  const date2 = moment(isoDate2)

  // Calculate the difference in days
  const hours = date2.diff(date1, 'hours')

  if (hours > 2190) {
    return 'month'
  } else if (hours > 24) {
    return 'day'
  } else {
    return 'hour'
  }
}

export const isArabic = (val: string) => /[\u0600-\u06FF]/.test(val)

export const truncateFileName = (name: string) => {
  if (name.length > 25) {
    const basename = name.split('.')[0]
    const extension = name.split('.').at(-1)
    const truncatedBasename = basename.substring(0, 13) + '...' + basename.slice(-12)
    return truncatedBasename + '.' + extension
  }

  return name
}

export const replaceCharactersWithAsterisks = (text: string | number): string => {
  return String(text)?.replace(/./g, '*') || '******'
}

export const recursivelyReplaceTextWithAsterisks = (
  element: ReactNode,
  isPrivate = false
): ReactNode => {
  if (!isPrivate) return element

  if (typeof element === 'string' || typeof element === 'number') {
    return replaceCharactersWithAsterisks(element)
  }

  if (isValidElement(element)) {
    const { children } = element.props
    if (typeof children === 'string') {
      // If it's a single text node, replace its content with asterisks
      return cloneElement(element, {}, replaceCharactersWithAsterisks(children))
    } else if (React.Children.count(children) > 0) {
      // If it has children, recursively apply the replacement logic to each child
      const newChildren = React.Children.map(children, (child) =>
        recursivelyReplaceTextWithAsterisks(child, isPrivate)
      )
      return cloneElement(element, {}, newChildren)
    }
  }

  return element
}

export const convertHeicToJpeg = async (link: string, fileFormat: string) => {
  if (fileFormat === '.heic') {
    const res = await fetch(link)
    const blob = await res.blob()
    const converted = await heic2any({
      blob,
      toType: 'image/jpeg',
      quality: 0.5,
    })

    return URL.createObjectURL(converted as Blob)
  }

  return link
}

export const toSpaceCase = (input = '') => {
  return input?.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export const maskStringByAsterisk = (value: string | number, visibleCount: number) => {
  const inputString = String(value)
  if (inputString.length <= visibleCount) {
    return inputString
  }

  const maskedLength = inputString.length - visibleCount
  const maskedPart = '*'.repeat(maskedLength)
  const visiblePart = inputString.slice(maskedLength)

  return maskedPart + visiblePart
}

export const maskPhoneNumberByAsterisk = (value = '') => {
  if (value.length <= 6) return value

  const firstThreeDigits = value.substring(0, 3)
  const lastThreeDigits = value.substring(value.length - 3)
  const insertCount = value.length - 6

  return firstThreeDigits + '*'.repeat(insertCount) + lastThreeDigits
}
