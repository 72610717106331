import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { IBankAccount, IBankAccountList, IBankAccountUpdate } from '../../models/IBankAccount'
import { ICurrency } from '../../models/ICurency'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { ITableConf } from '../../../../shared/models/ITableConf'

export const bankAccountApi = createApi({
  reducerPath: 'bankAccount',
  baseQuery: baseQueryWithReauth,
  refetchOnFocus: true,
  tagTypes: ['IBankAccountList', 'IFilterFields', 'IBankAccount'],
  endpoints: (builder) => ({
    getBankAccounts: builder.query<ITableResponse<IBankAccountList>, ITableConf>({
      query: (params) => ({
        url: 'bank-account',
        params,
      }),
      providesTags: () => ['IBankAccountList'],
    }),
    getListOfCurrencies: builder.query<ITableResponse<ICurrency>, void>({
      query: () => 'currency',
    }),
    getBankAccountsById: builder.query<IBankAccount, string>({
      query: (id) => ({
        url: `bank-account/${id}`,
      }),
      providesTags: (bank) => [{ type: 'IBankAccount', id: bank?.id }],
    }),

    createBankAccount: builder.mutation<IBankAccount, FormData>({
      query: (body) => ({
        url: 'bank-account',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IBankAccountList', 'IFilterFields'],
    }),
    updateBankAccount: builder.mutation<IBankAccount, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: `bank-account/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['IBankAccountList', 'IFilterFields', 'IBankAccount'],
    }),
    patchBankAccount: builder.mutation<IBankAccount, IBankAccountUpdate>({
      query: (body) => ({
        url: 'bank-account',
        method: 'PATCH',
        body: [body],
      }),
      invalidatesTags: () => ['IBankAccountList', 'IFilterFields', 'IBankAccount'],
    }),
    deleteBankAccount: builder.mutation<void, number>({
      query: (id) => ({
        url: `bank-account/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['IBankAccountList', 'IFilterFields'],
    }),

    getBAFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'bank-account/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `bank-account/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: () => ['IFilterFields'],
    }),
  }),
})

export const {
  useGetBankAccountsQuery,
  useGetListOfCurrenciesQuery,
  useGetBAFiltersListQuery,
  useGetBankAccountsByIdQuery,
  useCreateBankAccountMutation,
  useUpdateBankAccountMutation,
  useDeleteBankAccountMutation,
} = bankAccountApi
