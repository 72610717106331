import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Row, Spin, UploadFile } from 'antd'
import { useNavigate, useParams } from 'react-router'
import { isEmpty, omit } from 'lodash'
import { DefaultOptionType } from 'rc-select/lib/Select'

// components
import { Button } from 'src/shared/components/button/Button'
import { DetailInfo } from './Components/DetailInfo'
import { PaymentInfo } from './Components/PaymentInfo'
import { StatusInfo } from './Components/StatusInfo'
import { LogOfOrder } from './Components/LogOfOrder'

// interfaces
import {
  AFN_CURRENCY_CODE,
  IBankAccountForm,
  IPurchaseOrderBody,
  IPurchaseOrderCreate,
  IPurchaseOrderStatus,
  IScratchCard,
  VENDOR_WALLET_TYPE_ENUM,
} from 'src/features/Finance/models/IPurchaseOrder'

// hooks
import {
  useGetDepartmentListQuery,
  useGetPurchaseOrderQuery,
  useUpdatePurchaseOrderMutation,
} from 'src/features/Finance/core/http/PurchaseOrderApi'
import { useGetVendorListQuery } from 'src/features/Settings/core/http/VendorManagementApi'
import {
  useGetBankAccountsQuery,
  useGetListOfCurrenciesQuery,
} from 'src/features/Finance/core/http/BankAccountApi'
import { useLazyGetCurrencyRateToCurrencyQuery } from '../../../core/http/ExchangeRatesApi'

// utils
import { formatDateWithTime } from 'src/helpers/utils'
import { PurchaseOrderMode } from '../index.d'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import ShowMessage, { ErrorNode } from 'src/shared/api/errorHandler'

// styles
import styles from './styles.module.scss'
import classNames from 'classnames'
import { PAYMENT_METHOD_ENUM } from '../../../models/IBankAccount'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import { useGetUsersListQuery } from '../../../../Settings/core/http/UsersManagementApi'
import { ITableConf } from '../../../../../shared/models/ITableConf'
import usePermissions from '../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../Settings/models/IPermissions'
import PermissionDenied from '../../../../../shared/components/permissionDenied'
import { FINANCE_ENUM } from '../../../../../routes/finance'
import { PurchaseOrderSummary } from './Components/PurchaseOrderSummary'
import { vendorInventoryApi } from '../../../core/http/VendorInventoryApi'
import { useDispatch } from 'react-redux'
import { EPinBlock } from './Components/EPinBlock'
import FormItem from 'antd/es/form/FormItem'
import { EPinsCountBlock } from './Components/EPinsCountBlock'
import { useGetPOScratchCardListQuery } from '../../../core/http/EPinApi'

export const PurchaseOrder = () => {
  const dispatch = useDispatch()
  const [currency, setCurrency] = useState<string>()
  const [paymentType, setPaymentType] = useState<PAYMENT_METHOD_ENUM>()
  const { mode, poId } = useParams()
  const [files, setFiles] = useState<UploadFile[]>([])
  const [pinList, setPinList] = useState<Array<IScratchCard>>([])
  const navigate = useNavigate()

  const [form] = Form.useForm()
  const { data: bankAccountList, isFetching: isBanksFetch } = useGetBankAccountsQuery(
    {
      currency: currency as string,
    } as ITableConf,
    { skip: !currency }
  )
  const { data, isFetching } = useGetPurchaseOrderQuery(poId, { skip: !poId })
  const { data: vendorList } = useGetVendorListQuery({})
  const { data: currencyList } = useGetListOfCurrenciesQuery()
  const { data: departmentsList } = useGetDepartmentListQuery()
  const { data: usersList } = useGetUsersListQuery({})
  const { data: poScratchCards } = useGetPOScratchCardListQuery(poId as string, {
    skip: data?.vendorWalletType !== VENDOR_WALLET_TYPE_ENUM.E_PIN || !poId,
  })
  const { data: userProfile, isLoading: isLoadingProfile } = useGetProfileQuery()
  const [getRate, getRateResp] = useLazyGetCurrencyRateToCurrencyQuery()
  const [updatePO, dUpdate] = useUpdatePurchaseOrderMutation()

  const isViewMode = mode === PurchaseOrderMode.view
  const isEditMode = mode === PurchaseOrderMode.edit
  const isLoading = dUpdate.isLoading || isLoadingProfile || isBanksFetch
  const submitBtnText = isEditMode ? 'Edit PO' : 'Create PO'

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const [financePurchasesId] = getModulesIdsByNames([IPermissionsNames['Finance Purchase Order']])
  const [canViewFinancePurchase, canEditFinancePurchase] = canPerformAction(financePurchasesId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.EDIT,
  ])

  const notAllowedContent =
    (isViewMode && !canViewFinancePurchase) || (isEditMode && !canEditFinancePurchase)

  const afterUpdate = () => {
    navigate(FINANCE_ENUM.TRUST_WALLET)
    dispatch(
      vendorInventoryApi.util.invalidateTags([
        'IInventory',
        'ICalendar',
        'IInventoryChart',
        'IInventoryTransactions',
      ])
    )
  }

  const handleNotExistingCurrency = () => {
    form.setFieldValue('exchangeRate', null)
  }

  useNotification(NOTIFICATION_TYPES.success, dUpdate.isSuccess, null, afterUpdate)
  useNotification(NOTIFICATION_TYPES.error, dUpdate.isError, dUpdate.error as ErrorNode)
  useNotification(
    NOTIFICATION_TYPES.error,
    getRateResp.isError,
    getRateResp.error as ErrorNode,
    handleNotExistingCurrency
  )

  const handleSelectCurrency = (value: DefaultOptionType) => {
    if (!value) {
      form.setFieldValue('exchangeRate', null)
    }
    form.setFieldsValue({ purchaseOrderBankWallets: [], amount: 0, currencyAmount: 0 })
    const currencyId = value?.value as number

    setCurrency(currencyList?.items.find((curr) => curr?.id === currencyId)?.name)
    if (currencyId === AFN_CURRENCY_CODE) {
      return form.setFieldValue('exchangeRate', 1)
    }
    currencyId &&
      getRate({
        currencyId,
      })
  }

  const recalculateMoneyAmount = useCallback(() => {
    const { purchaseOrderBankWallets } = form.getFieldsValue()

    const totalAmount = purchaseOrderBankWallets?.reduce(
      (acc: number, res: IBankAccountForm) => acc + +(res?.amount || 0),
      0
    )
    const totalCurrencyAmount = purchaseOrderBankWallets?.reduce(
      (acc: number, res: IBankAccountForm) => acc + +(res?.currencyAmount || 0),
      0
    )
    form.setFieldsValue({
      amount: totalAmount,
      currencyAmount: totalCurrencyAmount,
    })
  }, [form])

  const handleFinish = (values: IPurchaseOrderCreate) => {
    const {
      vendor,
      paymentType,
      assignOfficer,
      purchaseOrderBankWallets,
      currency,
      assignDepartment,
      exchangeRate,
      vendorWalletType,
      scratchCardInfo,
    } = values

    const vendorId = vendor.id.value ?? vendor.id
    const selectedPayment = typeof paymentType === 'string' ? paymentType : paymentType.label

    let body = {} as IPurchaseOrderBody
    body.orderInfo = {
      vendorId: +vendorId,
      paymentType: selectedPayment,
      currencyId: +currency?.id,
      exchangeRate: +exchangeRate,
      vendorWalletType,
      ...(assignOfficer?.id
        ? { assignOfficerId: +assignOfficer.id.value || +assignOfficer.id }
        : { assignOfficerId: null }),
      ...(assignDepartment?.id
        ? { assignDepartmentId: +assignDepartment.id.value || +assignDepartment.id }
        : { assignDepartmentId: null }),
    }
    body.paymentInfo = purchaseOrderBankWallets?.map(
      ({ bankAccount, currencyAmount, unitPrice, mainCurrencyAmount, amount, id }) => ({
        ...(id && { id: +id }),
        bankAccountId: +bankAccount?.id?.value || +bankAccount?.id,
        currencyAmount: +currencyAmount!,
        amount: +amount!,
        mainCurrencyAmount: +mainCurrencyAmount!,
        unitPrice: +unitPrice!,
      })
    )

    if (body?.orderInfo?.vendorWalletType === VENDOR_WALLET_TYPE_ENUM.E_PIN) {
      const existingCardCodes = new Set()
      let hasRepeatingCode = false

      body.scratchCardInfo = pinList
        ?.concat(
          scratchCardInfo?.map((card) => ({
            ...card,
            denomination: +card?.denomination,
          }))
        )
        ?.filter((card) => {
          if (isEmpty(card)) return
          // Check if the card code is repeating
          if (existingCardCodes.has(card.code)) {
            ShowMessage('error', `Repeating card code detected: ${card.code}`)
            hasRepeatingCode = true
            return false // Discard the duplicate card
          } else {
            existingCardCodes.add(card.code)
            return true
          }
        })

      if (hasRepeatingCode) return

      if (!body?.scratchCardInfo.length) {
        body = omit(body, 'scratchCardInfo')
      }
    }

    if (selectedPayment === PAYMENT_METHOD_ENUM.BONUS) {
      body.orderInfo = {
        vendorId: +vendorId,
        paymentType: selectedPayment,
        vendorWalletType,
        ...(assignOfficer?.id
          ? {
              assignOfficerId: +assignOfficer?.id?.value || +assignOfficer?.id,
            }
          : { assignOfficerId: null }),
        ...(assignDepartment?.id
          ? {
              assignDepartmentId: +assignDepartment?.id?.value || +assignDepartment?.id,
            }
          : { assignDepartmentId: null }),
      }

      body.paymentInfo = purchaseOrderBankWallets?.map(({ unitPrice, amount, id }) => ({
        ...(id && { id: +id }),
        currencyAmount: 0,
        amount: +amount!,
        mainCurrencyAmount: 0,
        unitPrice: +unitPrice!,
      }))
    }

    updatePO({ id: poId!, body })
  }

  useEffect(() => {
    if (isEmpty(data)) return
    const currency = typeof data?.currency === 'string' ? data?.currency : data?.currency?.name

    form.setFieldValue('createdAt', formatDateWithTime(data?.createdAt, userProfile?.calendar))
    setPaymentType(data?.paymentType)
    setCurrency(currency)
  }, [data, form, userProfile])

  useEffect(() => {
    if (isEmpty(userProfile)) return
    form.setFieldValue(
      ['createdByUser', 'name'],
      `${userProfile.firstName} ${userProfile.lastName}`
    )
  }, [form, userProfile])

  useEffect(() => {
    if (getRateResp.isSuccess) {
      form.setFieldValue('exchangeRate', getRateResp.data?.sellingRate)
    }
    if (form.getFieldValue('currency')?.value === 3) {
      form.setFieldValue('exchangeRate', 1)
    }
  }, [form, getRateResp])

  const cardsToCount = useMemo(() => {
    return pinList.length ? pinList : poScratchCards?.map((card) => omit(card.scratchCard, 'id'))
  }, [pinList, poScratchCards])

  const content = (
    <>
      {notAllowedContent && <PermissionDenied />}
      {((isViewMode && canViewFinancePurchase) || (isEditMode && canEditFinancePurchase)) && (
        <main className={styles.layout}>
          <Form form={form} layout='vertical' initialValues={data} onFinish={handleFinish}>
            <Row wrap={false} justify='start'>
              <div
                className={classNames(styles.leftColumn, styles.view, {
                  [styles.full]: !isViewMode || !isEditMode,
                })}
              >
                <DetailInfo
                  viewMode={isViewMode}
                  usersList={usersList?.items}
                  vendorList={vendorList?.items}
                  currencyList={currencyList?.items}
                  departmentsList={departmentsList?.items}
                  onSelect={handleSelectCurrency}
                  handleSelectPayment={(payment) => setPaymentType(payment)}
                  form={form}
                />
                <FormItem noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const vendorWalletType = getFieldValue('vendorWalletType')
                    return (
                      vendorWalletType !== VENDOR_WALLET_TYPE_ENUM.E_PIN && (
                        <PaymentInfo
                          form={form}
                          selectedPaymentType={paymentType}
                          currency={form.getFieldValue('currency')}
                          viewMode={isViewMode}
                          bankAccountList={bankAccountList?.items}
                          recalculateMoneyAmount={recalculateMoneyAmount}
                        />
                      )
                    )
                  }}
                </FormItem>
              </div>
              {(isViewMode || isEditMode) && (
                <div className={classNames(styles.rightColumn, styles.view)}>
                  {isEditMode && (
                    <FormItem noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        const isEPinType =
                          getFieldValue('vendorWalletType') === VENDOR_WALLET_TYPE_ENUM.E_PIN

                        return (
                          isEPinType && (
                            <EPinBlock
                              setPinList={setPinList}
                              files={files}
                              setFiles={setFiles}
                              pinList={pinList}
                            />
                          )
                        )
                      }}
                    </FormItem>
                  )}
                  <EPinsCountBlock pinList={cardsToCount} />

                  <PurchaseOrderSummary
                    view={isViewMode}
                    recalculateMoneyAmount={recalculateMoneyAmount}
                  />
                  <StatusInfo status={data?.status as string} />
                  <LogOfOrder
                    logs={data?.purchaseOrderSubTransactions as Array<IPurchaseOrderStatus>}
                  />
                </div>
              )}
            </Row>
          </Form>
        </main>
      )}
    </>
  )

  return (
    <div className='fullVH'>
      {isLoading || isFetching ? <Spin>{content}</Spin> : content}
      {!isViewMode && (
        <div className={styles.footerWrapper}>
          <Row className={styles.footer} justify='end'>
            <Button style={{ width: '83px' }} color='blue' size='middle' onClick={afterUpdate}>
              Cancel
            </Button>
            {canEditFinancePurchase && (
              <Button
                className={styles.submitBtn}
                htmlType='submit'
                size='middle'
                type='primary'
                onClick={() => form.submit()}
                disabled={isLoading}
              >
                {submitBtnText}
              </Button>
            )}
          </Row>
        </div>
      )}
    </div>
  )
}
