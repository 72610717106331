import React, { MouseEvent, useCallback, useEffect, useMemo } from 'react'
import moment from 'moment'
import { Row } from 'antd'
import {
  LastMessage,
  ChatTooltipAvatar,
  IsReadMessageMark,
  PinChat,
  UnreadCountBadge,
} from 'src/shared/components/chat/Components'
import { IInternalChat, MessageTypeEnum } from '../../../../models'
import { usePinChatMutation } from '../../../../core/http/InternalChatsApi'
import { updateChatTypingStatus } from '../../../../core/store/Chats'
import { useAppDispatch } from '../../../../../../redux'
import styles from './styles.module.scss'
import classNames from 'classnames'

interface IProps {
  user: IInternalChat
  isOnline: boolean
  onPressChat: (id: number) => void
}

export const InternalChatUser = ({
  user: {
    id,
    lastName,
    firstName,
    logo,
    sentInternalChatMessageReading,
    recievedInternalChatMessageReading,
    lastInternalChatMessage,
    lastMessageType = MessageTypeEnum.TEXT_MESSAGE,
    unreadedMessagesCount,
    typing,
  },
  onPressChat,
  isOnline,
}: IProps) => {
  const dispatch = useAppDispatch()
  const [pinChat] = usePinChatMutation()

  const isPinned = useMemo(
    () => sentInternalChatMessageReading?.isPinned,
    [sentInternalChatMessageReading]
  )

  const isLastMessageRead = useMemo(() => {
    if (lastInternalChatMessage?.createdAt && recievedInternalChatMessageReading?.lastReadingAt) {
      return recievedInternalChatMessageReading?.lastReadingAt
        ? moment(recievedInternalChatMessageReading?.lastReadingAt).isAfter(
            lastInternalChatMessage?.createdAt
          ) ||
            moment(recievedInternalChatMessageReading?.lastReadingAt).isSame(
              moment(lastInternalChatMessage?.createdAt)
            )
        : false
    }
    return false
  }, [lastInternalChatMessage?.createdAt, recievedInternalChatMessageReading?.lastReadingAt])

  const isLastMessageMy = useMemo(() => {
    return lastInternalChatMessage?.toUserId === id
  }, [id, lastInternalChatMessage?.toUserId])

  useEffect(() => {
    if (typing) {
      const timeout = setTimeout(() => {
        dispatch(updateChatTypingStatus({ status: false, fromUserId: id }))
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [dispatch, id, typing])

  const onPressPin = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      pinChat({
        isPinned: !isPinned,
        toUserId: id,
      })
    },
    [id, isPinned, pinChat]
  )

  const currentMessageInfo = useMemo(() => {
    if (typing) return 'Typing...'
    if (lastInternalChatMessage) {
      return lastInternalChatMessage
    } else {
      return 'Start a conversation.'
    }
    // eslint-disable-next-line
  }, [typing])

  return (
    <Row className={styles.internalUserContainer} onClick={() => onPressChat(id)}>
      <ChatTooltipAvatar logo={logo} userName={`${lastName} ${firstName}`} isOnline={isOnline} />
      <div className={styles.internalUserInfo}>
        <div className={styles.internalUserName}>{`${firstName} ${lastName}`}</div>
        <LastMessage message={currentMessageInfo} type={lastMessageType} />
      </div>
      <div className={styles.internalUserMessageInfo}>
        {lastInternalChatMessage && (
          <Row>
            {unreadedMessagesCount > 0 ? (
              <UnreadCountBadge unreadMessagesCount={unreadedMessagesCount} />
            ) : isLastMessageMy ? (
              <IsReadMessageMark isRead={isLastMessageRead} />
            ) : null}
            <div className={styles.lastMessageTime}>
              {moment(lastInternalChatMessage.createdAt).format('hh:mm A')}
            </div>
          </Row>
        )}
        <div className={classNames(styles.pinnedIcon, { [styles.isPinned]: isPinned })}>
          <PinChat isPinned={isPinned as boolean} onPressPin={onPressPin} />
        </div>
      </div>
    </Row>
  )
}
