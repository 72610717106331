import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { Dropdown, MenuProps } from 'antd'
import Linkify from 'linkify-react'

import { ImagesImMessage } from '../ImagesInMessage'
import MessageInfo from './Components/MessageInfo/MessageInfo'
import RepliedMessageTitle from './Components/RepliedMessageTitle'
import ForwardMessageTitle from './Components/ForwardMessageTitle'
import DateDelimiter from './Components/DateDelimiter'
import { IForwardedFrom, IInternalChatMessage, IRepliedTo } from '../../../../models'
import {
  useForwardTextMessageMutation,
  useSendLastReadMessageMutation,
} from '../../../../core/http/InternalMessagingApi'
import { useAppDispatch, useAppSelector } from '../../../../../../redux'
import {
  selectInternalChatChatsState,
  setCurrentModalView,
  setOpenedTransaction,
} from '../../../../core/store/Chats'

import { InternalChatContext } from '../../../InternalChatModal'
import { RightModalContext } from '../../../../../Modals'
import { TRANSACTION_CATEGORY_ENUM } from '../../../../../Finance/models/ITransaction'
import styles from './styles.module.scss'
import { VideosInMessage } from '../VideosInMessage'
import FilesInMessage from '../FilesInMessage'
import { modalViewTypeEnum } from '../../../../core/models'
import { downloadFileThroughATag, isArabic } from '../../../../../../helpers/utils'
import { internalChatsApi } from '../../../../core/http/InternalChatsApi'

interface IProps {
  openedChatId: number
  toScrollId: number | null
  lastReadingAt: string
  isFirstInGroup?: boolean
  isLastInGroup?: boolean
  isFirstMessageInADay?: boolean
  chatClient: HTMLDivElement | null
  scrollPosition: number
  forwardedFromUser?: IForwardedFrom
  replyToMessage?: IRepliedTo
  scrollToMessage: (message: IRepliedTo) => void
  setToScrollId: (id: number | null) => void
  resetCreateDates: (date: string, id: number) => void
  selectToReply: (message: Partial<IInternalChatMessage>) => void
  message: IInternalChatMessage
  isNextMessageByTheSameUser: boolean
  setIsActionMenuOpen: Dispatch<SetStateAction<boolean>>
}

export const TextMessage = ({
  isNextMessageByTheSameUser,
  isLastInGroup,
  isFirstMessageInADay,
  chatClient,
  scrollPosition,
  lastReadingAt,
  selectToReply,
  openedChatId,
  toScrollId,
  scrollToMessage,
  message: fullMessage,
  setIsActionMenuOpen,
}: IProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dispatch = useAppDispatch()

  const { setProps } = useContext(RightModalContext)
  const { onSelectChat } = useContext(InternalChatContext)

  const messageRef = useRef<HTMLDivElement | null>(null)
  const {
    fromUserId,
    internalChatMessageFiles,
    createdAt,
    id,
    message,
    replyToMessage,
    forwardedFromUser,
  } = fullMessage

  const { chats } = useAppSelector(selectInternalChatChatsState)
  const [sendLastRead] = useSendLastReadMessageMutation()
  const [forwardMessage] = useForwardTextMessageMutation()

  useEffect(() => {
    if (toScrollId === id) {
      setTimeout(() => {
        if (messageRef.current) {
          messageRef.current.scrollIntoView({ behavior: 'smooth' })
        }
      }, 500)
    }
  }, [id, toScrollId])

  const isRead = useMemo(() => {
    return lastReadingAt
      ? moment(lastReadingAt).isAfter(moment(createdAt)) ||
          moment(lastReadingAt).isSame(moment(createdAt))
      : false
  }, [createdAt, lastReadingAt])

  const messageTime = useMemo(() => {
    return moment(createdAt).format('hh:mm A')
  }, [createdAt])

  const imagesFiles = useMemo(
    () =>
      internalChatMessageFiles
        ?.filter((item) => item.fileType.toLowerCase() === 'photo')
        .map((el) => ({ ...el, source: el.file.link })),
    [internalChatMessageFiles]
  )

  const videoFiles = useMemo(
    () => internalChatMessageFiles?.filter((item) => item.fileType.toLowerCase() === 'video'),
    [internalChatMessageFiles]
  )
  const otherFiles = useMemo(
    () =>
      internalChatMessageFiles?.filter(
        (item) => !['photo', 'video'].includes(item.fileType.toLowerCase())
      ),
    [internalChatMessageFiles]
  )

  // const formatMessage = useMemo(() => {
  //   if (/[^\x00-\x7F]+/.test(message)) {
  //     return message.split('').map((el, i) => {
  //       if (/[^\x00-\x7F]+/.test(el)) {
  //         return (
  //           <Emoji key={`${el}-${i}`} unified={el} />
  //           // <span key={`${el}-${i}`} className={classNames(styles.isEmoji)}>
  //           //   {el}
  //           // </span>
  //         )
  //       } else {
  //         return el
  //       }
  //     })
  //   } else {
  //     return message
  //   }
  // }, [message])

  const isMyMessage = useMemo(() => {
    return openedChatId !== fromUserId
  }, [fromUserId, openedChatId])

  const onPressReplayTo = useCallback(
    ({ toUserId, id }: Partial<IInternalChatMessage>) => {
      if (toUserId && id) {
        forwardMessage({ toUserId, messageId: id })
      }
    },
    [forwardMessage]
  )

  const downloadMessageFiles = useCallback(() => {
    if (!internalChatMessageFiles?.length) return
    const promiseList = internalChatMessageFiles?.map((file) => {
      return dispatch(
        internalChatsApi.endpoints.getInternalChatLinkToDownloadFile.initiate(file?.fileId)
      )
    })

    Promise.all(promiseList).then((resp) => {
      resp?.forEach((data, i) => {
        const link = data?.data?.link
        const name = internalChatMessageFiles[i]?.file?.name

        if (link && name) {
          downloadFileThroughATag(link, name)
        }
      })
    })
  }, [dispatch, internalChatMessageFiles])

  const messageActions: MenuProps['items'] = useMemo(
    () => [
      {
        key: `internal-${id}-reply`,
        label: (
          <div onClick={() => selectToReply({ id, message, internalChatMessageFiles })}>Reply</div>
        ),
      },
      {
        key: `internal-${id}-forward`,
        label: 'Forward',
        children: chats.map((el) => ({
          key: el?.id,
          label: (
            <div
              onClick={() =>
                onPressReplayTo({
                  id,
                  message,
                  toUserId: el?.id,
                })
              }
            >{`${el?.firstName} ${el?.lastName}`}</div>
          ),
        })),
      },
      ...(internalChatMessageFiles?.length
        ? [
            {
              key: `download-${id}-file`,
              label: <div onClick={downloadMessageFiles}>Download</div>,
            },
          ]
        : []),
    ],
    [
      chats,
      downloadMessageFiles,
      id,
      internalChatMessageFiles,
      message,
      onPressReplayTo,
      selectToReply,
    ]
  )

  const normalizedMessage = useMemo(() => {
    const regexTag = /\{"id": "([0-9]+)", "name": "([a-zA-Z0-9\s-]+)"}/gi
    const regexTransaction = /\{"id": "([0-9]+)", "transaction": "([a-zA-Z\s-]+)"}/gi
    if (regexTag.test(message) || regexTransaction.test(message)) {
      return message?.split('::').map((el) => {
        if (regexTag.test(el)) {
          const data = JSON.parse(el)
          return (
            <span
              key={data?.id}
              className={styles.isUserTag}
              onClick={() => onClickToTag(data?.id)}
            >
              {data.name}
            </span>
          )
        }
        if (regexTransaction.test(el)) {
          const data = JSON.parse(el)
          return (
            <span
              key={data?.id}
              className={styles.isUserTag}
              onClick={() => onClickToTransaction(data)}
            >
              {data?.id}
            </span>
          )
        }
        return el
      })
    }
    return message
    // eslint-disable-next-line
  }, [message])

  const onClickToTag = (chatDetailId: number) => {
    onSelectChat(modalViewTypeEnum.details, chatDetailId)
    setProps((prev) => ({ ...prev, id: openedChatId }))
  }

  const onClickToTransaction = ({
    transaction,
    id,
  }: {
    id: number
    transaction: TRANSACTION_CATEGORY_ENUM
  }) => {
    setProps({ id, transaction, title: 'Internal Chat' })
    dispatch(setCurrentModalView(modalViewTypeEnum.transaction))
    dispatch(setOpenedTransaction({ id, transaction }))
  }

  const onPressDetails = (userId: number) => () => {
    onSelectChat(modalViewTypeEnum.details, userId)
    setProps((prev) => ({ ...prev, id: openedChatId }))
  }

  const isVisible = function (ele: HTMLDivElement | null, container: HTMLDivElement | null) {
    if (ele && container) {
      const eleTop = ele.offsetTop
      const eleBottom = eleTop + ele.clientHeight

      const containerTop = container.scrollTop + 180
      const containerBottom = containerTop + container.clientHeight

      // The element is fully visible in the container
      return (
        (eleTop >= containerTop && eleBottom <= containerBottom) ||
        // Some part of the element is visible in the container
        (eleTop < containerTop && containerTop < eleBottom) ||
        (eleTop < containerBottom && containerBottom < eleBottom)
      )
    }
    return false
  }

  useEffect(() => {
    const isVisibleCurrent = isVisible(messageRef.current, chatClient)
    const isVisiblePreviousSibling = isVisible(
      (messageRef.current?.previousSibling as HTMLDivElement) || null,
      chatClient
    )
    if (!isMyMessage && !isRead && isVisibleCurrent && !isVisiblePreviousSibling) {
      sendLastRead({ toUserId: openedChatId, lastReadingAt: createdAt })
    }
    // eslint-disable-next-line
  }, [isRead, scrollPosition, chatClient, isMyMessage, message])

  const openLinkInNewTab = (url: string) => {
    window.open(url, '_blank')?.focus()
  }

  const linkProps = {
    onClick: (e: MouseEvent<HTMLLinkElement>) => {
      e.preventDefault()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      openLinkInNewTab(e.target.href)
    },
  }

  return (
    <>
      <div
        id={`message${id}`}
        ref={messageRef}
        className={classNames(styles.messageItem, {
          [styles.isLastMessageInGroup]: isLastInGroup,
          [styles.isMyMessageContainer]: isMyMessage,
          [styles.isNextMessageByTheSameUser]: isNextMessageByTheSameUser,
        })}
      >
        <Dropdown
          menu={{ items: messageActions }}
          trigger={['contextMenu']}
          overlayClassName={styles.contextMenuContainer}
          open={isDropdownOpen}
          onOpenChange={(vis) => {
            setIsDropdownOpen(vis)
            setIsActionMenuOpen(vis)
          }}
        >
          <div
            onClick={(e) => {
              e.preventDefault()
            }}
            className={classNames(
              styles.messageContainer,
              { [styles.isMyMessage]: isMyMessage },
              { [styles.isNotMyMessage]: !isMyMessage },
              { [styles.isReplied]: !!replyToMessage }
            )}
          >
            <div className={styles.messageContent}>
              {forwardedFromUser ? (
                <ForwardMessageTitle
                  name={forwardedFromUser.name}
                  onPressDetails={onPressDetails(forwardedFromUser.id)}
                />
              ) : null}
              {replyToMessage ? (
                <RepliedMessageTitle
                  replyToMessage={replyToMessage}
                  scrollToMessage={scrollToMessage}
                />
              ) : null}
              {imagesFiles?.length ? <ImagesImMessage images={imagesFiles} /> : null}
              {videoFiles?.length ? <VideosInMessage videos={videoFiles} /> : null}
              {otherFiles?.length ? <FilesInMessage files={otherFiles} /> : null}
              <Linkify options={{ attributes: linkProps }}>
                <div
                  className={classNames(styles.messageText, {
                    [styles.arabic]: isArabic(message),
                  })}
                >
                  {normalizedMessage}
                </div>
              </Linkify>
            </div>
            <MessageInfo isMy={isMyMessage} isRead={isRead} messageTime={messageTime} />
          </div>
        </Dropdown>
      </div>
      {isFirstMessageInADay && <DateDelimiter createdAt={createdAt} />}
    </>
  )
}
