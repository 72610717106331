import React, { memo, useEffect, useMemo, useState } from 'react'
import { Checkbox } from '../../../../../../../../shared/components/checkbox/Checkbox'
import styles from '../../styles.module.scss'
import { IPermissionCreate, IRolePermissionModule } from '../../../../../../models/IRoles'

interface IProps {
  disabled?: boolean
  selectedPermissions: Array<IPermissionCreate>
  module: IRolePermissionModule
  onSelectAllModulePermissions: (module: IRolePermissionModule, status: boolean) => void
}

const PermissionsAllInModuleCheckbox = ({
  disabled,
  selectedPermissions,
  module,
  onSelectAllModulePermissions,
}: IProps) => {
  const [isChecked, setIsChecked] = useState(false)
  const [isIndeterminate, setIsIndeterminate] = useState(false)

  const selectedPermissionsCountInModule = useMemo(
    () => selectedPermissions.filter((p) => p.permissionModuleId === module.id).length,
    [module.id, selectedPermissions]
  )

  useEffect(() => {
    setIsChecked(
      selectedPermissionsCountInModule === module.permissionModuleAllowedPermissions.length
    )
  }, [module.permissionModuleAllowedPermissions.length, selectedPermissionsCountInModule])

  useEffect(() => {
    const selectedDiff =
      module.permissionModuleAllowedPermissions.length - selectedPermissionsCountInModule
    const indeterminate =
      selectedDiff > 0 && selectedDiff !== module.permissionModuleAllowedPermissions.length
    setIsIndeterminate(indeterminate)
  }, [module.permissionModuleAllowedPermissions.length, selectedPermissionsCountInModule])

  return (
    <div
      className={styles.actionContainer}
      style={{ paddingLeft: 16, height: 56, alignItems: 'center' }}
    >
      <Checkbox
        disabled={disabled}
        checked={isChecked}
        indeterminate={isIndeterminate}
        onChange={(status) => onSelectAllModulePermissions(module, status.target.checked)}
      />
    </div>
  )
}

export default memo(PermissionsAllInModuleCheckbox)
