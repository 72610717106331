import { Wrapper } from 'src/features/salesNetwork/components/Wrapper'
import FormList from 'antd/lib/form/FormList'
import styles from '../../../../AgentManagementDetailView/FinancialInformationTab/styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { requiredValidator } from '../../../../../../../helpers/validation'
import { Form, Row, Select } from 'antd'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from '../../../../../../../assets/svg'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { MOBILE_WALLET_PROVIDER_ENUM } from '../../../../../models/ICustomer'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import { Toggle } from '../../../../../../../shared/components/toggle/Toggle'

export const WalletAccountsBlock = ({ view }: IViewMode) => {
  const mobileWalletOptions = useMemo(
    () =>
      (
        Object.keys(MOBILE_WALLET_PROVIDER_ENUM) as Array<keyof typeof MOBILE_WALLET_PROVIDER_ENUM>
      ).map((method) => ({
        label: MOBILE_WALLET_PROVIDER_ENUM[method],
        value: MOBILE_WALLET_PROVIDER_ENUM[method],
      })),
    []
  )

  return (
    <Wrapper title='Wallet Accounts'>
      <FormList name='walletAccounts'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name, key }) => (
              <div className={styles.walletRow} key={key}>
                <FormItem
                  label='Wallet Provider'
                  name={[name, 'mobileWalletProvider']}
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator('Wallet Provider'),
                    },
                  ]}
                  className={styles.walletRowItem}
                >
                  <Select
                    allowClear
                    placeholder='Select Wallet Provider'
                    disabled={view}
                    options={mobileWalletOptions}
                  />
                </FormItem>

                <FormItem
                  label='Wallet Number'
                  name={[name, 'mobileWalletNumber']}
                  rules={[{ required: true, validator: requiredValidator('Wallet Number') }]}
                  className={styles.walletRowItem}
                >
                  <TextField disabled={view} placeholder='Enter Wallet Number' />
                </FormItem>

                <FormItem className={styles.bucketIcon}>
                  <IconButton
                    block
                    size='large'
                    color='orange'
                    icon={<BucketIcon />}
                    type='default'
                    disabled={view}
                    onClick={() => remove(name)}
                  />
                </FormItem>

                <div className={styles.statusButton}>
                  <Form.Item
                    name={[name, 'active']}
                    style={{ flexBasis: '10%' }}
                    valuePropName='checked'
                  >
                    <Toggle disabled={view} />
                  </Form.Item>
                </div>
              </div>
            ))}

            {!view && (
              <Row>
                <Form.Item className={classNames('noBottomMargin', styles.addButton)}>
                  <IconButton icon={<AddIcon />} color='orange' block onClick={() => add()} />
                </Form.Item>
              </Row>
            )}
          </>
        )}
      </FormList>
    </Wrapper>
  )
}
