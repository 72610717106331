import styles from '../styles.module.scss'
import emptyAvatarImage from '../../../../../assets/img/Avatar.png'
import emptyProductImage from '../../../../../assets/img/ProductTablePlaceholder.png'
import { IByUser } from '../../../../../shared/models/IByUser'

interface IConstraint {
  masterProduct: Pick<IByUser, 'logo'>
  id: number
}

type ProductsType<T extends IConstraint> = T

interface IProps {
  count: number
  products: ProductsType<IConstraint>[]
  isProduct?: boolean
  isPrivateMode?: boolean
}

export const renderTableTargetGroups = ({ count, products, isProduct, isPrivateMode }: IProps) => {
  const imagePlaceholder = isProduct ? emptyProductImage : emptyAvatarImage

  return (
    <>
      {(products.length || count > 0) && (
        <div className={styles.agentsTableList}>
          {products.slice(0, 2).map((product) => (
            <img
              key={product.id}
              alt='product logo'
              src={
                !isPrivateMode && product?.masterProduct?.logo
                  ? product?.masterProduct?.logo
                  : imagePlaceholder
              }
              loading='lazy'
              width={38}
              height={38}
            />
          ))}
          {count > 2 && <div className={styles.agentCount}>+{count - 2}</div>}
        </div>
      )}
    </>
  )
}
