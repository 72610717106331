import PhoneInput from 'react-phone-input-2'
import { FormInstance } from 'antd/es/form/Form'
import 'react-phone-input-2/lib/plain.css'
import { IViewMode } from '../../../features/Finance/models/IViewMode'
import classNames from 'classnames'
import styles from './style.module.scss'

interface IProps extends IViewMode {
  fieldName: string | [string, number, string] | [string, string]
  form: FormInstance
  onChange?: (val: string) => void
}

export const PhoneInputWithCountry = ({ fieldName, form, view, onChange }: IProps) => {
  return (
    <PhoneInput
      disabled={view}
      preferredCountries={['Afganistan']}
      disableDropdown={view}
      inputClass={classNames(styles.input, { [styles.disabledInput]: view })}
      dropdownClass={classNames(styles.dropdown, { [styles.disabledDropdown]: view })}
      countryCodeEditable={false}
      enableSearch={true}
      value={form.getFieldValue(fieldName)}
      onChange={(phone: string, country: { dialCode: string }) => {
        const includesCountryCode = `+${form.getFieldValue(fieldName)}`.includes(country?.dialCode)
        if (!includesCountryCode) {
          form.setFieldValue(fieldName, '')
          return onChange && onChange(`+${country?.dialCode}`)
        }
        onChange && onChange(`+${phone}`)
      }}
      containerClass={styles.inputContainer}
    />
  )
}
