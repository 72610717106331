import { AdjustmentIcon, DownloadIcon } from '../../../../../../assets/svg'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import React, { useContext } from 'react'
import { RightModalContext } from '../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import styles from '../../styles.module.scss'
import classNames from 'classnames'
import { Space } from 'antd'
import { Moment } from 'moment'
import { RangePicker } from '../../../../../../shared/components/datePicker'
import { Dayjs } from 'dayjs'

interface IProps {
  onDateChange: (value: Moment[] | null) => void
  onExportClick: () => void
  isLoading: boolean
  value?: [Moment, Moment] | [Dayjs, Dayjs]
}

export const TableHeader = ({ onDateChange, onExportClick, isLoading, value }: IProps) => {
  const rightModalContext = useContext(RightModalContext)

  const handlerAdjustmentClick = () => {
    rightModalContext.onOpen(RIGHT_MODALS.FINANCE.ADJUSTMENT, {
      title: 'Adjustment',
    })
  }

  return (
    <div className={styles.tableHeader}>
      <div className={classNames(styles.date, 'statementDate')}>
        <RangePicker value={value} onChange={(value) => onDateChange(value as Moment[])} />
      </div>
      <Space size={10}>
        <IconButton
          color='blue'
          type='primary'
          icon={<AdjustmentIcon />}
          onClick={handlerAdjustmentClick}
        >
          Adjustment
        </IconButton>
        <IconButton
          color='blue'
          icon={<DownloadIcon />}
          onClick={onExportClick}
          loading={isLoading}
        />
      </Space>
    </div>
  )
}
