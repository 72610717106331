import styles from './styles.module.scss'
import { ValueAddServicesTabs } from './Components/Tabs/index'

export const ValueAddServices = () => {
  return (
    <div className={styles.layout}>
      <ValueAddServicesTabs />
    </div>
  )
}
