import styles from '../CustomerRestrictionView/styles.module.scss'
import { Form, Row, Space, Spin } from 'antd'
import { RestrictionDetailInfo } from '../Components/DetailInfoBlock'
import { RestrictionDuration } from '../Components/DurationBlock'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import ShowMessage, { ErrorNode, showConfirmMessage } from '../../../../../shared/api/errorHandler'
import {
  useCreateAgentRestrictionMutation,
  useDeleteAgentRestrictionMutation,
  useGetAgentRestrictionByIdQuery,
  useUpdateAgentRestrictionMutation,
} from '../../../core/http/AgentsRestriction'
import { SUPPORT_ENUM } from '../../../../../routes/support'
import { Moment } from 'moment'
import {
  formatDateMomentToISO,
  formatDateWithTime,
  getFormCalendarTime,
} from '../../../../../helpers/utils'
import { Button } from '../../../../../shared/components/button/Button'
import { setBreadcrumb } from '../../../../app/core/BreadcrumbsSlice'
import { AgentBlock } from '../Components/AgentBlock'
import { useLazyGetAgentByIdQuery } from '../../../../salesNetwork/core/http/AgentManagement'
import { IAgentAllGetResponse, IDENTIFIERS_TYPE_NEW } from '../../../../salesNetwork/models/IAgent'
import { IAgentRestrictionMutate, IRestriction } from '../../../models/Restrictions'

export const AgentRestrictionView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { mode, id } = useParams()
  const [selectedAgent, setSelectedAgent] = useState<IAgentAllGetResponse>(
    {} as IAgentAllGetResponse
  )
  const [updateRestriction, upRestrictionResp] = useUpdateAgentRestrictionMutation()
  const [crRestriction, crRestrictionResp] = useCreateAgentRestrictionMutation()
  const [delRestriction, delRestrictionResp] = useDeleteAgentRestrictionMutation()
  const [getAgent, getAgentResp] = useLazyGetAgentByIdQuery()
  const { data: userProfile, isFetching: isFetchingUser } = useGetProfileQuery()
  const { data, isFetching } = useGetAgentRestrictionByIdQuery(id as string, { skip: !id })

  const handleUpdateSuccess = () => {
    return navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}/agent/view/${id}`)
  }

  const handleDeleteSuccess = () => {
    return navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}`)
  }

  const handleCreateSuccess = () => {
    if (crRestrictionResp.isSuccess && crRestrictionResp.data?.id) {
      return navigate(
        `/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}/agent/view/${crRestrictionResp.data?.id}`
      )
    }
  }

  useNotification(
    NOTIFICATION_TYPES.success,
    upRestrictionResp.isSuccess,
    null,
    handleUpdateSuccess
  )
  useNotification(
    NOTIFICATION_TYPES.success,
    crRestrictionResp.isSuccess,
    null,
    handleCreateSuccess
  )
  useNotification(
    NOTIFICATION_TYPES.success,
    delRestrictionResp.isSuccess,
    null,
    handleDeleteSuccess
  )
  useNotification(
    NOTIFICATION_TYPES.error,
    upRestrictionResp.isError,
    upRestrictionResp.error as ErrorNode
  )
  useNotification(
    NOTIFICATION_TYPES.error,
    crRestrictionResp.isError,
    crRestrictionResp.error as ErrorNode
  )
  useNotification(
    NOTIFICATION_TYPES.error,
    delRestrictionResp.isError,
    delRestrictionResp.error as ErrorNode
  )

  const isEditMode = useMemo(() => mode === 'edit', [mode])
  const isViewMode = useMemo(() => mode === 'view', [mode])
  const agentName = useMemo(() => {
    if (getAgentResp?.data) {
      return (
        getAgentResp?.data?.companyTitle ??
        `${getAgentResp?.data?.firstName} ${getAgentResp?.data?.lastName}`
      )
    }
  }, [getAgentResp.data])
  const isLoading =
    isFetching ||
    isFetchingUser ||
    crRestrictionResp.isLoading ||
    upRestrictionResp.isLoading ||
    delRestrictionResp.isLoading ||
    getAgentResp.isLoading

  const contactListOptions = useMemo(() => {
    return selectedAgent?.agentIdentifiers
      ?.filter((contactOption) => contactOption.type === IDENTIFIERS_TYPE_NEW.MOBILE.type)
      ?.map((mobile) => ({
        value: mobile?.id,
        label: mobile?.value,
      }))
  }, [selectedAgent?.agentIdentifiers])

  const onFinish = (values: IRestriction) => {
    if (!selectedAgent?.id) {
      return ShowMessage('error', 'Agent Is Required!')
    }
    const body = {
      ...(values?.description && { description: values.description }),
      ...(values?.endAt && {
        endAt: formatDateMomentToISO(userProfile?.calendar, values.endAt as Moment),
      }),
      ...(values?.agentIdentifier?.id && { agentIdentifierId: +values.agentIdentifier?.id }),
      startAt: formatDateMomentToISO(userProfile?.calendar, values?.startAt as Moment),
      reason: values?.reason,
      agentId: +selectedAgent?.id,
      type: values?.restrictionTypes,
    } as IAgentRestrictionMutate

    isEditMode ? updateRestriction({ id: id as string, body }) : crRestriction(body)
  }

  const setInitialFields = useCallback(() => {
    form.setFieldsValue({
      ...data,
      ...(data?.endAt
        ? { endAt: getFormCalendarTime(userProfile?.calendar, data?.endAt as string) }
        : {}),
      startAt: getFormCalendarTime(userProfile?.calendar, data?.startAt as string),
      createdAt: formatDateWithTime(data?.createdAt, userProfile?.calendar),
      createdBy: `${userProfile?.firstName} ${userProfile?.lastName}`,
    })
  }, [data, form, userProfile])

  const onPressCancelChanges = useCallback(() => {
    if (isEditMode) {
      form.resetFields()
      setInitialFields()
      return navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}/agent/view/${id}`)
    }

    if (isViewMode) {
      return navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}`)
    }

    return navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}`)
  }, [form, id, isEditMode, isViewMode, navigate, setInitialFields])

  const onPressSaveChanges = useCallback(() => {
    if (isViewMode) {
      return navigate(`/${SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}/agent/edit/${id}`)
    }

    return form.submit()
  }, [form, id, isViewMode, navigate])

  const onPressDelete = () => {
    return showConfirmMessage('This restriction will be deleted', () =>
      delRestriction(id as string)
    )
  }

  const onAgentSelect = (id: string) => {
    form.setFieldValue(['agentIdentifier', 'id'], null)
    form.setFieldValue('agentId', id)
    getAgent(id)
  }

  useEffect(() => {
    if (data) {
      dispatch(setBreadcrumb({ name: agentName }))
      getAgent(data?.agent?.id)
      setInitialFields()
    }
  }, [agentName, data, dispatch, form, getAgent, setInitialFields, userProfile])

  useEffect(() => {
    if (userProfile) {
      form.setFieldValue('createdBy', `${userProfile?.firstName} ${userProfile?.lastName}`)
    }
  }, [form, userProfile])

  useEffect(() => {
    if (getAgentResp?.data) {
      setSelectedAgent(getAgentResp.data)
    }
  }, [getAgentResp.data])

  const content = (
    <div className={styles.layout}>
      <Form
        form={form}
        layout='vertical'
        initialValues={{ createdAt: formatDateWithTime(new Date(), userProfile?.calendar) }}
        onFinish={onFinish}
        className={styles.columnWrapper}
      >
        <div className={styles.leftColumn}>
          <RestrictionDetailInfo
            isViewMode={isViewMode}
            contactListOptions={contactListOptions}
            isAgentPage
          />
        </div>
        <div className={styles.rightColumn}>
          <AgentBlock
            view={isViewMode || isEditMode}
            agent={selectedAgent}
            onAgentSelect={onAgentSelect}
            agentName={agentName}
          />
          <RestrictionDuration view={isViewMode} />
        </div>
      </Form>
      <div className={styles.wrapper}>
        <Row className={styles.footer} justify='space-between'>
          <div>
            {(isViewMode || isEditMode) && (
              <Button color='error' size='middle' onClick={onPressDelete}>
                Delete restriction
              </Button>
            )}
          </div>
          <Row justify='end'>
            <Space size={10}>
              <Button color='blue' size='middle' onClick={onPressCancelChanges}>
                Cancel
              </Button>
              <Button htmlType='submit' size='middle' type='primary' onClick={onPressSaveChanges}>
                {isViewMode && 'Edit'}
                {isEditMode && 'Save Changes'}
                {!isViewMode && !isEditMode && 'Create Restriction'}
              </Button>
            </Space>
          </Row>
        </Row>
      </div>
    </div>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
