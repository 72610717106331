import React, { memo } from 'react'
import { ClockIcon } from '../../../../../../assets/svg'
import styles from '../../styles.module.scss'

interface IProps {
  text: string
  createdAt: string
}

const NotificationData = ({ text, createdAt }: IProps) => {
  return (
    <div className={styles.notificationDataContainer}>
      <div className={styles.notificationText}>{text}</div>
      <div className={styles.notificationDate}>
        <ClockIcon size={10} color='#ff9800' />
        <div>{createdAt}</div>
      </div>
    </div>
  )
}

export default memo(NotificationData)
