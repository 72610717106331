import usePermissions from '../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../models/IPermissions'
import { SortingOrderEnum } from '../../../../shared/models/ITableConf'
import { useGetQueryParams } from '../../../../shared/hooks/table/useGetQueryParams'

export default () => {
  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })
  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const [filesId] = getModulesIdsByNames([IPermissionsNames['Files list']])
  const [contentsId] = getModulesIdsByNames([IPermissionsNames.Content])

  const demandPermissions = [
    IPermissionsActions.VIEW,
    IPermissionsActions.ADD,
    IPermissionsActions.EXPORT,
    IPermissionsActions.DELETE,
  ]

  const [canViewFiles] = canPerformAction(filesId, demandPermissions)
  const [canViewFile, canAddFile, canExportFile, canDeleteFile] = canPerformAction(
    contentsId,
    demandPermissions
  )

  return {
    permissions: { canViewFile, canAddFile, canViewFiles, canExportFile, canDeleteFile },
    queryParams,
    queryFields,
  }
}
