import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { ITransactionLogAll } from '../../models/TransactionLog'

export const requestToVendorApi = createApi({
  reducerPath: 'requestToVendor',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['TransactionLogList', 'TransactionLog', 'FilterFields'],
  endpoints: (builder) => ({
    getTransactionLogList: builder.query<ITableResponse<ITransactionLogAll>, ITableConf>({
      query: (params) => ({
        url: 'request-to-vendor',
        params,
      }),
      keepUnusedDataFor: 0,
    }),

    getTransactionLogById: builder.query<ITransactionLogAll, string>({
      query: (id) => ({
        url: `request-to-vendor/${id}`,
      }),
      providesTags: (data) => [{ type: 'TransactionLog', id: data?.id }],
    }),

    getTransactinoLogFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'request-to-vendor/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `request-to-vendor/filter-values?filterField=${filter.field}&id=${arg}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      keepUnusedDataFor: 0,
      providesTags: () => ['FilterFields'],
    }),
  }),
})

export const {
  useGetTransactinoLogFiltersListQuery,
  useGetTransactionLogByIdQuery,
  useGetTransactionLogListQuery,
} = requestToVendorApi
