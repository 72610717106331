import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../shared/api'
import { ITableResponse } from '../../../shared/models/ITableResponse'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../shared/models/ITableConf'
import { IFilterFields } from '../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { TableExportFormatEnum } from '../../../shared/hooks/table/useTableExport'
import { IInternalReport } from '../models/IInternalReport'

export const internalTransactionApi = createApi({
  reducerPath: 'internalTransaction',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getInternalTransactions: builder.query<ITableResponse<IInternalReport>, ITableConf>({
      query: (params) => ({
        url: 'internal-transaction-report',
        params,
      }),
      transformResponse: (res: ITableResponse<IInternalReport>) => ({
        ...res,
        items: res.items.map((report) => ({ ...report, children: [] })),
      }),
    }),

    getITFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'internal-transaction-report/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `internal-transaction-report/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),

    exportITReport: builder.query<
      BlobPart,
      {
        conf: IFileFormatCsvOrXlsx
        formatFile: keyof typeof TableExportFormatEnum
      }
    >({
      query: ({ conf, formatFile }) => ({
        url: 'internal-transaction-report/export',
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
})

export const {
  useLazyExportITReportQuery,
  useGetInternalTransactionsQuery,
  useGetITFiltersListQuery,
} = internalTransactionApi
