import React, { useState } from 'react'
import MessagingHeader from './Components/Header'
import MessagingList from './Components/Messaging'
import styles from '../../styles.module.scss'

const ChatroomMessaging = () => {
  const [search, setSearch] = useState<null | string>('')
  const [toScrollId, setToScrollId] = useState<number | null>(null)

  return (
    <div className={styles.externalChatMessaging}>
      <MessagingHeader setToScrollId={setToScrollId} search={search} setSearch={setSearch} />
      <MessagingList scrollToSearchMessageId={toScrollId} searchMessageDate={search} />
    </div>
  )
}

export default ChatroomMessaging
