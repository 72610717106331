import React from 'react'
import { Form } from 'antd'
import FormItem from 'antd/es/form/FormItem'

import ParameterDetailInfo from './Components/DetailInfo'
import TotalScore from './Components/TotalScore'
import CreditScoreDetailParameters from './Components/Parameters'
import styles from './styles.module.scss'

export const CreditScoreParameterDetails = () => {
  const [form] = Form.useForm()

  const onFinish = () => {}

  return (
    <div className={styles.layout}>
      <Form form={form} layout='vertical' onFinish={onFinish}>
        <div className={styles.columnWrapper}>
          <div className={styles.leftColumn}>
            <FormItem shouldUpdate>
              <ParameterDetailInfo />
            </FormItem>
            <FormItem shouldUpdate noStyle>
              <CreditScoreDetailParameters />
            </FormItem>
          </div>
          <div className={styles.rightColumn}>
            <TotalScore />
          </div>
        </div>
      </Form>
    </div>
  )
}
