import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../redux'
import { IUserDetail } from '../../../Settings/models/IUser'

interface ProfileState {
  user: IUserDetail | null
}

const initialState: ProfileState = {
  user: null,
}

export const profileSlice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {
    addProfileDetails: (state, { payload }: PayloadAction<IUserDetail>) => {
      state.user = payload
    },
    clearProfileSliceState(state) {
      state.user = null
    },
  },
})

export const selectProfileState = (state: RootState) => state.profileSlice
export const selectProfileRole = (state: RootState) => state.profileSlice?.user?.userRole
export const selectProfileType = (state: RootState) => !!state.profileSlice?.user?.isSuperUser

export const { addProfileDetails, clearProfileSliceState } = profileSlice.actions

export default profileSlice.reducer
