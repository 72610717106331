import React, { useContext, useMemo } from 'react'
import {
  IDiscountPolicyTargetGroups,
  IPromotionPolicyTargetGroups,
  ITargetGroup,
} from '../../../../models/ITargetGroup'
import styles from './style.module.scss'
import { Button } from '../../../../../../shared/components/button/Button'
import { Space } from 'antd'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { ArrowRightIcon } from '../../../../../../assets/svg'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import { RightModalContext } from '../../../../../Modals'

interface IProps {
  onCloseRedirect: () => void
  onSelectClick: (groupId: number) => void
  targetGroups: Array<ITargetGroup>
  promotionId?: string
}

export const TargetGroupList = ({
  targetGroups,
  onCloseRedirect,
  onSelectClick,
  promotionId,
}: IProps) => {
  const { onOpen } = useContext(RightModalContext)
  const assignedGroups: Array<IDiscountPolicyTargetGroups | IPromotionPolicyTargetGroups> =
    useMemo(() => {
      if (promotionId) {
        return targetGroups
          ?.flatMap((group) => group.promotionPolicyTargetGroups)
          .filter((mappedGroup) => mappedGroup.promotionPolicyId === String(promotionId))
      }

      return []
    }, [promotionId, targetGroups])

  return (
    <>
      {targetGroups?.map((group) => {
        const isAssignedToGroup = assignedGroups?.some(
          (assignedGroup) => String(assignedGroup.targetGroupId) === String(group.id)
        )
        return (
          <div key={group.id} className={styles.targetListItem}>
            <div className={styles.targetListItemInfo}>
              <div className={styles.groupName}>{group?.name}</div>
              <div className={styles.groupType}>
                <div>{group?.agentType}</div>
                <span></span>
                <div>{group?.agentLevel.name}</div>
              </div>
              <div className={styles.groupPolicy}>{group?.paymentPolicyType}</div>
            </div>
            <Space size={13}>
              <div className={styles.selectButton}>
                {!isAssignedToGroup && (
                  <Button
                    color='orange'
                    type={isAssignedToGroup ? 'primary' : 'default'}
                    onClick={(e) => {
                      e.stopPropagation()
                      onSelectClick(group.id)
                    }}
                    disabled={isAssignedToGroup}
                  >
                    Select
                  </Button>
                )}
              </div>
              <div className={styles.redirectButton}>
                <IconButton
                  color='blue'
                  icon={<ArrowRightIcon />}
                  onClick={() =>
                    onOpen(RIGHT_MODALS.SETTINGS.NETWORK_CONFIGURATION_TARGET_GROUP, {
                      title: 'View Target Group',
                      mode: 'view',
                      id: group?.id,
                      onCloseRedirect,
                    })
                  }
                />
              </div>
            </Space>
          </div>
        )
      })}
    </>
  )
}
