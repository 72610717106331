import { IAgentTypeAgent } from './IAgentType'

export enum ACCOUNT_TYPE_TO_ID {
  PREPAID = 2,
  POSTPAID = 1,
}

export enum ACCOUNT_TYPE_ENUM {
  PREPAID = 'PREPAID',
  POSTPAID = 'POSTPAID',
}

export interface IAccountType {
  id: number
  accountType: ACCOUNT_TYPE_ENUM
  agents: Array<IAgentTypeAgent>
}
