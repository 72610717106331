import { MASTER_PRODUCT_TYPE_ENUM } from './IProduct'
import { IByUser } from '../../../shared/models/IByUser'

export enum PRODUCTS_ACTIONS_ENUM {
  DELETE = 'delete',
  UPDATE = 'update',
  CREATE = 'create',
}

interface IMasterProdPrefix {
  id?: string
  receiverPhoneNumberPrefixId: string
}

export interface IMasterProductDetailMasterProdResp
  extends Omit<IMasterProductMaster, 'masterProductId'> {
  masterProduct: IByUser
}

export interface IMasterProduct {
  id: number
  name: string
  logo: string
  type: MASTER_PRODUCT_TYPE_ENUM
  price: number
  createdByUser: IByUser
  createdAt: string
  updatedAt: string
  internalCode: string
  externalCode: string
  products: Array<IMasterProductProduct>
  status: boolean
  receiverPhoneNumberPrefixes: Array<IMasterProdPrefix | string>
  masterBundleItems: Array<IMasterProductDetailMasterProdResp>
  color: string
}

export interface IMasterProductProduct {
  productId: number
  status: true
  priority: PRIORITY_ENUM
  action: PRODUCTS_ACTIONS_ENUM
}

export interface IMasterProductMaster {
  masterProductId: number
  status: true
  priority: PRIORITY_ENUM
  action: PRODUCTS_ACTIONS_ENUM
}

export interface ICreateMasterProduct {
  name: string
  status: boolean
  internalCode: string
  externalCode: string
  type: MASTER_PRODUCT_TYPE_ENUM
  products: Array<IMasterProductProduct>
  receiverPhoneNumberPrefixes: Array<IMasterProdPrefix>
  masterBundleItems: Array<IMasterProductMaster>
  price?: number
}

export interface ICreateMasterProductResponse
  extends Omit<
    ICreateMasterProduct,
    'internalCode' | 'externalCode' | 'ussdCode' | 'messengerCode'
  > {
  id: number
  logo: string
  createdAt: Date
  updatedAt: Date
}

export enum PRIORITY_ENUM {
  LOW = 3,
  MIDDLE = 2,
  HIGH = 1,
}

export interface IWidgetMasterProduct extends Omit<IByUser, 'logo'> {
  color: string
}

export interface IFailedTransactionsByMasterProduct {
  masterProduct: IWidgetMasterProduct
  count: number
  amount: number
}

export interface ISalesSummaryByMasterProdData {
  amount: number
  masterProduct: IWidgetMasterProduct
}

export interface ISalesSummaryByMasterProd {
  period: string
  data: ISalesSummaryByMasterProdData[]
}
