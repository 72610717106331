import { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom'
import { Tabs } from 'antd'

import { AddIcon, EditIcon } from 'src/assets/svg'
import { IconButton } from 'src/shared/components/iconButton/IconButton'

import { IPermissionsActions, IPermissionsNames } from 'src/features/Settings/models/IPermissions'
import usePermissions from 'src/shared/hooks/usePermissions'
import { ScheduleNotificationPage } from '../../NotificationPage/index'
import { Toggle } from 'src/shared/components/toggle/Toggle'
import { CAMPAIGNS_TABS_ENUM } from '../Tabs/index.d'
import {
  useGetScheduleNotificationByIdQuery,
  useUpdateScheduleNotificationMutation,
} from 'src/features/salesNetwork/core/http/SceduleNotifications'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode } from 'src/shared/api/errorHandler'
import WithPermissionDeniedContainer from 'src/shared/components/withPermissionDenied'
import { AudienceList } from '../../AudienceList'
import { RightModalContext } from 'src/features/Modals'
import { RIGHT_MODALS } from 'src/helpers/contants'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'
import { useDispatch } from 'react-redux'
import { setBreadcrumb } from 'src/features/app/core/BreadcrumbsSlice'

interface IPropsOperations {
  canAddNew: boolean
  onAddButtonClick: () => void
  onEditButtonClick: () => void
  onStatusChangeClick: (status: boolean) => void
  canViewStatus: boolean
  canEdit: boolean
  status: boolean | undefined
  isFetching: boolean
}

const Operations = memo(
  ({
    onAddButtonClick,
    onEditButtonClick,
    onStatusChangeClick,
    canAddNew,
    canViewStatus,
    canEdit,
    status,
    isFetching,
  }: IPropsOperations) => {
    return (
      <div style={{ display: 'flex', gridGap: 10 }}>
        {canEdit && (
          <IconButton
            disabled={!canEdit}
            icon={<EditIcon />}
            color='orange'
            onClick={onEditButtonClick}
          />
        )}
        {canAddNew && (
          <IconButton
            disabled={!canAddNew}
            icon={<AddIcon />}
            type='primary'
            color='orange'
            onClick={onAddButtonClick}
          />
        )}
        {canViewStatus && (
          <Toggle
            disabled={!canViewStatus}
            defaultChecked={true}
            checked={status}
            text='Status'
            loading={isFetching}
            onChange={onStatusChangeClick}
            inWrapper
          />
        )}
      </div>
    )
  }
)

export const ScheduleNotificationTabs = () => {
  const { id, mode } = useParams()
  const dispatch = useDispatch()
  const { onOpen } = useContext(RightModalContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: CAMPAIGNS_TABS_ENUM.GENERAL_INFO,
  })

  const { data, isFetching } = useGetScheduleNotificationByIdQuery(id as string, { skip: !id })
  const [updateNotification, dUpdate] = useUpdateScheduleNotificationMutation()

  useNotification(NOTIFICATION_TYPES.success, dUpdate.isSuccess, null)
  useNotification(NOTIFICATION_TYPES.error, dUpdate.isError, dUpdate.error as ErrorNode)

  useEffect(() => {
    if (data) {
      dispatch(setBreadcrumb(data))
    }
  }, [data, dispatch])

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()

  // CHECK!!!
  const [notificationId, audienceId] = getModulesIdsByNames([
    IPermissionsNames['Schedule Notification'],
    IPermissionsNames['Audience'],
  ])

  const demandPermissionsNotification = [
    IPermissionsActions.VIEW,
    IPermissionsActions.ADD,
    IPermissionsActions.EDIT,
  ]
  const demandPermissionsAudience = [
    IPermissionsActions.VIEW,
    IPermissionsActions.ADD,
    IPermissionsActions.DELETE,
  ]

  const [canShowNotification, canAddNotification, canEditNotification] = canPerformAction(
    notificationId,
    demandPermissionsNotification
  )
  const [canShowAudience, canAddAudience, canDeleteAudience] = canPerformAction(
    audienceId,
    demandPermissionsAudience
  )

  /** Table */
  const currentTabData = useMemo(() => {
    if (activeTab === CAMPAIGNS_TABS_ENUM.GENERAL_INFO) {
      return {
        navigateTo: `/sales-network/campaign/edit/${id}`,
        canAdd: false,
        canEdit: canEditNotification && !!mode,
        canViewStatus: canShowNotification && !!mode,
      }
    }
    if (activeTab === CAMPAIGNS_TABS_ENUM.AUDIENCE) {
      return {
        navigateTo: 'system-notifications/new',
        canAdd: canAddAudience && !!mode,
        canEdit: false,
        canViewStatus: canShowNotification && !!mode,
      }
    }

    return {
      canAdd: false,
      canEdit: false,
      canViewStatus: false,
    }
  }, [
    activeTab,
    id,
    canAddNotification,
    canShowNotification,
    canAddAudience,
    canEditNotification,
    mode,
  ])

  const items = useMemo(
    () => [
      {
        label: CAMPAIGNS_TABS_ENUM.GENERAL_INFO,
        key: CAMPAIGNS_TABS_ENUM.GENERAL_INFO,

        children: (
          <WithPermissionDeniedContainer isAllowedView={canShowNotification}>
            <ScheduleNotificationPage />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: CAMPAIGNS_TABS_ENUM.AUDIENCE,
        key: CAMPAIGNS_TABS_ENUM.AUDIENCE,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canShowAudience && !!mode}>
            <AudienceList canDelete={canDeleteAudience} />
          </WithPermissionDeniedContainer>
        ),
      },
    ],
    [canShowNotification, canShowAudience, canDeleteAudience]
  )

  const onAddButtonClick = useCallback(() => {
    if (!currentTabData?.navigateTo) return

    if (activeTab === CAMPAIGNS_TABS_ENUM.AUDIENCE) {
      onOpen(RIGHT_MODALS.SUPPORT.CHANGE_MPIN, {
        audienceType: data?.audience.audienceType,
        title: 'Add Audience Base',
        id,
        initialStep: 'searchAudience',
      })
      return
    }

    navigate(currentTabData.navigateTo)
  }, [currentTabData])

  const onEditButtonClick = useCallback(() => {
    if (!currentTabData?.navigateTo) return

    navigate(currentTabData.navigateTo)
  }, [currentTabData])

  const onStatusChangeClick = (status: boolean) => {
    updateNotification({
      id: String(id),
      body: {
        status,
      },
    })
  }

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  return (
    <Tabs
      destroyInactiveTabPane
      activeKey={activeTab}
      tabBarExtraContent={
        <Operations
          onEditButtonClick={onEditButtonClick}
          onAddButtonClick={onAddButtonClick}
          onStatusChangeClick={onStatusChangeClick}
          canAddNew={currentTabData.canAdd}
          canEdit={currentTabData.canEdit}
          canViewStatus={currentTabData.canViewStatus}
          status={data?.status}
          isFetching={isFetching || dUpdate.isLoading}
        />
      }
      onChange={onTabChange}
      items={items}
    />
  )
}
