import React, { memo } from 'react'
import { Button, IButtonProps } from '../button/Button'
import classNames from 'classnames'
import './style.scss'

interface IProps extends IButtonProps {
  iconPos?: 'left' | 'right'
  borderColor?: string
  slim?: boolean
  textColor?: string
}

export const IconButton = memo(
  ({
    color,
    borderColor,
    children,
    slim = false,
    iconPos = 'left',
    className,
    textColor,
    ...rest
  }: IProps): JSX.Element => {
    return (
      <Button
        style={{ borderColor, alignItems: 'center' }}
        className={classNames('icon-button', iconPos, color, { slim }, className)}
        {...rest}
      >
        {children && (
          <span className='title-button' style={{ color: textColor }}>
            {children}
          </span>
        )}
      </Button>
    )
  }
)
