import React, { SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { keyBy, noop } from 'lodash'
import moment from 'moment/moment'
import { Messaging } from '../Messaging'
import { InternalChatUsersList } from '../UsersList'
import { MinimizedUsersList } from '../MinimizedUsersList'
import { ChatDetails } from '../ChatDetails'
import ChatTransactionView from '../Transaction'
import Attachments from '../Attachments'
import Links from '../Links'

import { useAppDispatch, useAppSelector } from '../../../../redux'
import { KeyboardDoubleArrowRightRounded } from '../../../../assets/svg'
import { RightModalContext } from '../../../Modals'
import { useGetInternalChatsQuery } from '../../core/http/InternalChatsApi'
import { IPageConf } from '../../../../shared/components/infiniteScoll/InfiniteScroll'
import { ISelectedFilters } from '../../../../shared/components/filter/Filter'
import { IInternalChat, IInternalChatMessage, IInternalChatMessageReadings } from '../../models'
import {
  addChatOnOpen,
  addChats,
  selectInternalChatChatsState,
  setCurrentModalView,
  setOpenedChatId,
  updateChatLastMessage,
  updateChatMessageLastReadingDate,
  updateChatTypingStatus,
  updateChatUnreadCounter,
  updateOnlineStatus,
} from '../../core/store/Chats'

import { addAfterMessages, clearMessages } from '../../core/store/Messages'
import { rootSocketConnection } from '../../../../shared/sockets'
import styles from './styles.module.scss'
import { generateId } from '../../../../helpers/utils'

export enum modalViewTypeEnum {
  chats = 'chats',
  messaging = 'messaging',
  details = 'details',
  transaction = 'transaction',
  attachments = 'attachments',
  links = 'links',
}

interface IInternalChatContext {
  onSelectChat: (viewType: modalViewTypeEnum, chatId: number) => void
  props: {
    // eslint-disable-next-line
    [key: string]: any
  }
  setProps: (props: SetStateAction<object>) => void
}

const context = {
  onSelectChat: noop,
  props: {},
  setProps: noop,
}

export const InternalChatContext = React.createContext<IInternalChatContext>(context)

export const checkIsOnlineStatus = (date: string): boolean => {
  return moment().diff(moment(date), 'seconds') < 315
}

export const InternalChatModal = () => {
  const dispatch = useAppDispatch()
  const [modalProps, setModalProps] = useState({})
  const {
    onClose,
    props: { id, chatId },
  } = useContext(RightModalContext)

  const { currentModalView: currentView, openedChatId: currentChat } = useAppSelector(
    selectInternalChatChatsState
  )

  const [filterValues] = useState<ISelectedFilters>({})
  const [tableConf, setTableConf] = useState<IPageConf>({
    page: 1,
    limit: 25,
    search: '',
  })
  const [updateId] = useState(() => generateId())

  const { data: chats = { items: [], totalCount: 0 }, isFetching } = useGetInternalChatsQuery({
    ...tableConf,
    ...filterValues,
  })

  const { chats: chatsList, openedChat: currentChatMessaging } = useAppSelector(
    selectInternalChatChatsState
  )

  const sortedChats = useMemo(() => {
    const pinned: Array<IInternalChat> = []
    const notPinned: Array<IInternalChat> = []
    chatsList.forEach((el) => {
      if (el.sentInternalChatMessageReading?.isPinned) {
        pinned.push(el)
      } else {
        notPinned.push(el)
      }
    })
    const withMessage = notPinned
      .filter((el) => el.lastInternalChatMessage)
      .sort((a, b) =>
        moment(b.lastInternalChatMessage?.createdAt).diff(
          moment(a.lastInternalChatMessage?.createdAt)
        )
      )
    const withoutMessage = notPinned.filter((el) => !el.lastInternalChatMessage)
    return [...pinned, ...withMessage, ...withoutMessage]
  }, [chatsList])

  const chatsMap = useMemo(() => {
    return keyBy(chatsList, 'id')
  }, [chatsList])

  const onSelectChat = useCallback(
    (viewType: modalViewTypeEnum, chatId: number) => {
      dispatch(clearMessages())
      dispatch(setCurrentModalView(viewType))
      dispatch(setOpenedChatId(chatId))
      dispatch(addChatOnOpen(chatsMap[chatId]))
    },
    [chatsMap, dispatch]
  )

  const onSwitchView = useCallback(
    (viewType: modalViewTypeEnum) => {
      dispatch(setCurrentModalView(viewType))
    },
    [dispatch]
  )

  const loadMoreData = useCallback(() => {
    setTableConf((pre: IPageConf) => ({ ...pre, page: pre.page! + 1 }))
  }, [])

  const onPressClose = () => {
    onClose()
    dispatch(setCurrentModalView(modalViewTypeEnum.chats))
    dispatch(setOpenedChatId(null))
  }

  useEffect(() => {
    if (chatId) {
      onSelectChat(modalViewTypeEnum.messaging, Number(chatId))
    }
    // eslint-disable-next-line
  }, [chatId])

  useEffect(() => {
    dispatch(addChatOnOpen(currentChat ? chatsMap[currentChat] : null))
    // eslint-disable-next-line
  }, [chatsMap, currentChat])

  useEffect(() => {
    if (isFetching) return

    if (tableConf.page === 1 || Object.keys(filterValues).length !== 0) {
      dispatch(addChats(chats.items))
    } else {
      dispatch(addChats([...chatsList, ...chats.items]))
    }
    // eslint-disable-next-line
  }, [filterValues, tableConf.page, isFetching])

  /** Sockets effects */
  /** Allows to notify the user that another user is writing a message.
   * Changes the information about the typing status in the chat list.
   * */
  useEffect(() => {
    const socket = rootSocketConnection.getSocket()
    const onTypingEvent = ({ fromUserId }: { fromUserId: number }) => {
      dispatch(updateChatTypingStatus({ status: true, fromUserId }))
    }
    socket?.on('typing', onTypingEvent)
    return () => {
      socket?.removeListener('typing', onTypingEvent)
    }
    // eslint-disable-next-line
  }, [])

  /** Allows to notify the user that a new message has been received.
   * Changes the information about the received message in the chat list
   * and unread messages counter
   * */
  useEffect(() => {
    const socket = rootSocketConnection.getSocket()
    const onMessageHandler = (message: IInternalChatMessage) => {
      dispatch(updateChatLastMessage({ message }))
      if (message.fromUserId && (!currentChat || currentChat !== message.fromUserId)) {
        dispatch(updateChatUnreadCounter({ fromUserId: message.fromUserId }))
      }
      if (message.fromUserId === currentChat) {
        dispatch(addAfterMessages([message]))
      }
    }
    socket?.on('message', onMessageHandler)
    return () => {
      socket?.removeListener('message', onMessageHandler)
    }
    // eslint-disable-next-line
  }, [currentChat])

  /** Allows to notify the user that another user has read a previously sent message.
   * Changes the read status of chat messages.
   * */
  useEffect(() => {
    const socket = rootSocketConnection.getSocket()
    const onMessageReadingHandler = (data: IInternalChatMessageReadings) => {
      dispatch(updateChatMessageLastReadingDate({ data }))
    }
    socket?.on('reading', onMessageReadingHandler)
    return () => {
      socket?.removeListener('reading', onMessageReadingHandler)
    }
    // eslint-disable-next-line
  }, [])

  /** Allows to notify the user that another user is online.
   * Changes the online status of user.
   * */
  useEffect(() => {
    const socket = rootSocketConnection.getSocket()
    const onOnlineStatusChangeHandler = (data: { userId: number; lastOnlineAt: string }) => {
      if (data && id !== data.userId) {
        dispatch(updateOnlineStatus({ id: data.userId, lastOnlineAt: data.lastOnlineAt }))
      }
    }
    socket?.on('online', onOnlineStatusChangeHandler)
    return () => {
      socket?.removeListener('online', onOnlineStatusChangeHandler)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <InternalChatContext.Provider
      value={{ onSelectChat, props: modalProps, setProps: setModalProps }}
    >
      <div className={styles.internalChatModalContainer}>
        <div className={styles.sideLeftButton} onClick={onPressClose}>
          <KeyboardDoubleArrowRightRounded />
        </div>
        <div className={styles.internalContainer}>
          {currentView === modalViewTypeEnum.chats && (
            <InternalChatUsersList
              key={updateId}
              totalCount={chats?.totalCount as number}
              loadMoreData={loadMoreData}
              chatsList={sortedChats}
            />
          )}
          {currentView === modalViewTypeEnum.messaging && currentChatMessaging && (
            <Messaging onSwitchView={onSwitchView} />
          )}
          {currentView === modalViewTypeEnum.details && <ChatDetails />}
          {currentView === modalViewTypeEnum.transaction && <ChatTransactionView />}
          {currentView === modalViewTypeEnum.attachments && <Attachments />}
          {currentView === modalViewTypeEnum.links && <Links />}
          {currentView !== modalViewTypeEnum.chats && (
            <MinimizedUsersList
              totalCount={chats?.totalCount as number}
              loadMoreData={loadMoreData}
              chatsList={sortedChats}
              key={updateId}
            />
          )}
        </div>
      </div>
    </InternalChatContext.Provider>
  )
}
