import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Spin } from 'antd'

import { PermissionsTableHeader, PermissionsTableBody } from './Components'

import { useGetPermissionModulesGroupListQuery } from '../../../../core/http/RolesAndPermissionsManagement'
import { IGroupCreate, IPermissionCreate } from '../../../../models/IRoles'

import styles from './styles.module.scss'

const PERMISSIONS_ACTIONS = [
  'ADD',
  'EDIT',
  'VIEW',
  'DELETE',
  'REJECT',
  'IMPORT',
  'EXPORT',
  'MAKER',
  'CHECKER',
  'APPROVER',
]

interface IProps {
  selectedPermissions: Array<IPermissionCreate>
  selectedGroups: Array<IGroupCreate>
  onCheckPermission: (
    permissions: IPermissionCreate | Array<IPermissionCreate>,
    checked?: boolean
  ) => void
  onChangeGroupStatus: (group: IGroupCreate) => void
}

export const PermissionsTable = ({
  selectedGroups,
  onCheckPermission,
  onChangeGroupStatus,
  selectedPermissions,
}: IProps) => {
  const { mode } = useParams<{ mode: 'view' | 'edit'; id: string }>()

  const { data, ...permissionModuleGroupsDataResp } = useGetPermissionModulesGroupListQuery()

  const isViewMode = useMemo(() => mode === 'view', [mode])

  const content = (
    <div className={`roleAndPermissionLayout ${styles.tableContainer}`}>
      <div style={{ minWidth: '1200px' }}>
        <PermissionsTableHeader actions={PERMISSIONS_ACTIONS} />
        <PermissionsTableBody
          isViewMode={isViewMode}
          actions={PERMISSIONS_ACTIONS}
          onCheckPermission={onCheckPermission}
          onChangeGroupStatus={onChangeGroupStatus}
          selectedPermissions={selectedPermissions}
          selectedGroups={selectedGroups}
        />
      </div>
    </div>
  )

  return permissionModuleGroupsDataResp.isLoading ? <Spin>{content}</Spin> : content
}
