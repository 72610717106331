import TransactionStatus from '../../../../../../../../shared/components/transactionStatus'
import { Form } from 'antd'
import { TextField } from '../../../../../../../../shared/components/textfield/TextField'
import styles from '../../styles.module.scss'
import { Wrapper } from '../../../../../../../salesNetwork/components/Wrapper'
import React from 'react'

export const DetailInfo = ({ status = '' }) => {
  return (
    <Wrapper title='Transaction Details' statusButton={<TransactionStatus status={status} />}>
      <div>
        <Form.Item name='id' label='Transaction ID'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item name='transactionCategory' label='Transaction Category'>
          <TextField disabled />
        </Form.Item>

        <Form.Item name='transactionCategory' label='Access Channel'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div>
        <Form.Item name='requestIp' label='Request IP'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item name={['agentIdentifier', 'type']} label='Identifier Type'>
          <TextField disabled />
        </Form.Item>

        <Form.Item name={['agentIdentifier', 'value']} label='Identifier Value'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div>
        <Form.Item name={['receiverAgent', 'name']} label='Receiver'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item name='receiverBalanceBefore' label='Receiver Balance Before'>
          <TextField disabled />
        </Form.Item>

        <Form.Item name='receiverBalanceAfter' label='Receiver Balance After'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div>
        <Form.Item name={['senderAgent', 'name']} label='Sender'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item name='senderBalanceBefore' label='Sender Balance Before'>
          <TextField disabled />
        </Form.Item>

        <Form.Item name='senderBalanceAfter' label='Sender Balance After'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item name='amount' label='Amount'>
          <TextField disabled />
        </Form.Item>

        <Form.Item name='commissionAmount' label='Commission Amount'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div>
        <Form.Item name='aminPayResponseCode' label='AminPay Response Code'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div>
        <Form.Item
          name='aminPayResponseMessage'
          label='AminPay Response Message'
          className='noBottomMargin'
        >
          <TextField disabled />
        </Form.Item>
      </div>
    </Wrapper>
  )
}
