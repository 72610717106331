import { ColumnsType } from 'antd/es/table'
import { useCallback, useState } from 'react'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import {
  useDeleteDiscountPolicyByIdMutation,
  useGetDiscountPolicyFilterListQuery,
  useGetDiscountPolicyListQuery,
  useLazyExportDiscountPolicyTableQuery,
  useUpdateDiscountPolicyMutation,
} from '../../../../core/http/DiscountPolicyApi'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { TableHeader } from '../TableHeader'
import { Table } from 'antd'
import styles from '../../styles.module.scss'
import { IDiscountPolicy } from '../../../../models/IDiscountPolicy'
import { formatDateWithTime } from '../../../../../../helpers/utils'
import { DateTableIcon, TableActionIcon } from '../../../../../../assets/svg'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../shared/components/popup/Popup'
import { renderTableTargetGroups } from '../../helpers/renderTableTargetGroups'
import { Link, useNavigate } from 'react-router-dom'
import { SETTINGS_ENUM } from '../../../../../../routes/settings'
import { ErrorNode, showConfirmMessage } from '../../../../../../shared/api/errorHandler'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { useTableExport } from '../../../../../../shared/hooks/table/useTableExport'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { useAppDispatch } from '../../../../../../redux'
import { agentPolicyApi } from '../../../../../salesNetwork/core/http/AgentPolicyApi'
import { renderAgentGroups } from '../../helpers/renderAgentGroups'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

export const DiscountPolicyTable = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [delDisc, delDiscResp] = useDeleteDiscountPolicyByIdMutation()
  const [updDisc, updDiscResp] = useUpdateDiscountPolicyMutation()

  const { data: profile } = useGetProfileQuery()

  const invalidateAgentDiscount = () => {
    dispatch(agentPolicyApi.util.invalidateTags(['IDiscountTemplate', 'IAgentDiscountPolicy']))
  }

  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  useNotification(NOTIFICATION_TYPES.success, delDiscResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, updDiscResp.isSuccess, null, invalidateAgentDiscount)
  useNotification(NOTIFICATION_TYPES.error, delDiscResp.isError, delDiscResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, updDiscResp.isError, updDiscResp.error as ErrorNode)

  const openNotification = useCallback(
    (id: number) => {
      showConfirmMessage('This discount policy will be deleted', () => delDisc(id))
    },
    [delDisc]
  )

  const tableActionsPopup = useCallback(
    (id: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          onClick: () =>
            navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/discount-policy/view/${id}`),
          shouldDisplay: true,
        },
        {
          text: 'Edit',
          onClick: () =>
            navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/discount-policy/edit/${id}`),
          shouldDisplay: true,
        },
        {
          text: 'Delete',
          withDivider: true,
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          shouldDisplay: true,
          onClick: () => openNotification(id),
        },
      ]
    },
    [navigate, openNotification]
  )

  const columns: ColumnsType<IDiscountPolicy> = [
    {
      title: 'Policy Id',
      dataIndex: 'id',
      width: '10%',
      sorter: true,
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Policy Name',
      dataIndex: 'name',
      width: '20%',
      sorter: true,
      render: (name, row) => (
        <RecursivelyReplaceCharacters>
          <Link
            to={`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/discount-policy/view/${row.id}`}
            className='table-avatar'
          >
            {name}
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Master Products',
      dataIndex: 'discountPolicyProducts',
      width: '20%',
      render: (_, row) =>
        renderTableTargetGroups({
          count: row?.discountPolicyProductsCount,
          products: row?.discountPolicyProducts,
          isProduct: true,
          isPrivateMode,
        }),
    },
    {
      title: 'Target Group',
      width: '15%',
      render: (_, row) =>
        renderAgentGroups({
          agents: row?.assignedAgents,
          count: row?.assignedAgentsCount,
          isPrivateMode,
        }),
    },
    {
      title: 'Starting Date',
      dataIndex: 'start',
      width: '20%',
      sorter: true,
      render: (date: IDiscountPolicy['start']) => (
        <RecursivelyReplaceCharacters>
          <div className='table-date'>
            <DateTableIcon />
            <span>{formatDateWithTime(date, profile?.calendar)}</span>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Expiry Date',
      dataIndex: 'end',
      width: '20%',
      sorter: true,
      render: (date: IDiscountPolicy['end']) => (
        <RecursivelyReplaceCharacters>
          <div className='table-date'>
            <DateTableIcon />
            <span>{formatDateWithTime(date, profile?.calendar)}</span>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      sorter: true,
      render: (status: IDiscountPolicy['status'], { id }) => {
        return (
          <Toggle
            onChange={(status) => updDisc({ id, body: { status } })}
            checked={status}
            defaultChecked={status}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'id',
  })
  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data, isFetching } = useGetDiscountPolicyListQuery(
    { ...filterValues, ...tableConf },
    { skip: Object.keys(tableConf).length < 4 }
  )
  const { data: filterList } = useGetDiscountPolicyFilterListQuery()
  const [exportTable] = useLazyExportDiscountPolicyTableQuery()

  useTableExport({
    tableConf,
    filterValues,
    promise: exportTable,
  })

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IDiscountPolicy>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  const isLoading = updDiscResp.isLoading || isFetching

  return (
    <>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        data={filterList}
        filterValues={filterValues}
      />
      <Table
        locale={emptyTableConf}
        onChange={handleTableChange}
        pagination={pagination}
        columns={columns}
        className={styles.table}
        scroll={{ x: 1000 }}
        rowKey={(record) => record.id}
        rowSelection={{ type: 'checkbox' }}
        dataSource={data?.items}
        loading={isLoading}
      />
    </>
  )
}
