import React, { memo } from 'react'
import { CheckMarkIcon, DoubleCheckMarkIcon } from '../../../../../assets/svg'
import styles from './styles.module.scss'

interface IProps {
  isRead: boolean
}

const IsReadMessageMark = ({ isRead }: IProps) => {
  return (
    <div className={styles.readIcon}>
      {isRead ? <DoubleCheckMarkIcon color='#0193EB' /> : <CheckMarkIcon color='#0193EB' />}
    </div>
  )
}

export default memo(IsReadMessageMark)
