import {
  EXTERNAL_CHAT_LIST_TABS,
  IExtChatWSReadingEvent,
  IExternalChat,
  IExternalChatTable,
  ILastExternalChatMessage,
  IUpdateExternalChatMemberResp,
} from '../../models/IExternalChat'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { unionBy } from 'lodash'
import { RootState } from '../../../../redux'

interface IInitialState {
  selectedChatId: number | null
  externalChat: IExternalChat | null
  activeTab: EXTERNAL_CHAT_LIST_TABS
  chatList: Array<IExternalChatTable>
}

const initialState: IInitialState = {
  selectedChatId: null,
  externalChat: null,
  chatList: [],
  activeTab: EXTERNAL_CHAT_LIST_TABS.MY,
}

const findExternalChatListIndex = (chatList: Array<IExternalChat>, chatId: number | string) => {
  return chatList?.findIndex((c) => {
    return +c?.id === +chatId
  })
}

export const externalChatChatsListSlice = createSlice({
  name: 'externalChatChatsListSlice',
  initialState,
  reducers: {
    setCurrentExternalChatId: (state, { payload }: PayloadAction<number | null>) => {
      state.selectedChatId = payload
    },
    setActiveTab: (state, { payload }: PayloadAction<EXTERNAL_CHAT_LIST_TABS>) => {
      state.activeTab = payload
    },
    setExternalChatList: (state, { payload }: PayloadAction<Array<IExternalChatTable>>) => {
      state.chatList = unionBy([...state.chatList, ...payload], 'id')
    },
    updateExternalChatLastMessage: (
      state,
      {
        payload: { selectedChatId, data },
      }: PayloadAction<{ selectedChatId: number; data: ILastExternalChatMessage }>
    ) => {
      const currentChatIndex = findExternalChatListIndex(state.chatList, selectedChatId)

      if (currentChatIndex === -1) return

      state.chatList[currentChatIndex].lastExternalChatMessage = data
    },
    updateExternalChatCounter: (
      state,
      { payload: { chatId, count } }: PayloadAction<{ chatId: number; count: number }>
    ) => {
      const currentChatIndex = findExternalChatListIndex(state.chatList, chatId)

      if (currentChatIndex === -1) return
      state.chatList[currentChatIndex].unreadedMessagesCount = count
    },
    updateExternalChatLastReadingAt: (
      state,
      { payload }: PayloadAction<IExtChatWSReadingEvent>
    ) => {
      const chatIndex = findExternalChatListIndex(state.chatList, payload.chatId)

      if (chatIndex > -1) {
        state.chatList[chatIndex].lastReadingAt = payload.lastReadingAt
      }

      if (state?.externalChat?.id !== payload?.chatId) return

      state.externalChat.lastReadingAt = payload.lastReadingAt
    },
    updateExternalChatTypingStatus: (
      state,
      { payload }: PayloadAction<{ chatId: number; userId: number | null; status: boolean }>
    ) => {
      const currentChatIndex = findExternalChatListIndex(state.chatList, payload.chatId)
      if (currentChatIndex > -1) {
        state.chatList[currentChatIndex].typing = payload.status
      }

      if (state?.externalChat?.id === payload?.chatId) {
        state.externalChat.typingUser = {
          typing: payload.status,
          userId: payload.userId,
        }
      }
    },
    updateExternalChatUserInfo: (
      state,
      { payload }: PayloadAction<IUpdateExternalChatMemberResp>
    ) => {
      const currentChatIndex = findExternalChatListIndex(state.chatList, payload.chatId)

      if (currentChatIndex === -1) return

      state.chatList[currentChatIndex].member = payload
    },
    setExternalChat: (state, { payload }: PayloadAction<IExternalChat | null>) => {
      state.externalChat = payload
    },
    clearExternalChatList: (state) => {
      state.chatList = []
    },
    archiveExternalChat: (state, { payload }: PayloadAction<number | string>) => {
      state.chatList = state.chatList.filter((chat) => chat.id !== payload)
    },
    clearExternalChatChatsState: (state) => {
      state.selectedChatId = null
      state.externalChat = null
      state.chatList = []
      state.activeTab = EXTERNAL_CHAT_LIST_TABS.MY
    },
  },
})

export const selectExternalChatChatsSlice = (state: RootState) => state.externalChatChatsListSlice
export const selectExternalChat = (state: RootState, chatId: number | null) =>
  state.externalChatChatsListSlice.chatList.find((chat) => chat.id === chatId)

export const {
  archiveExternalChat,
  clearExternalChatList,
  setExternalChatList,
  setExternalChat,
  updateExternalChatCounter,
  setActiveTab,
  updateExternalChatLastMessage,
  updateExternalChatUserInfo,
  setCurrentExternalChatId,
  clearExternalChatChatsState,
  updateExternalChatLastReadingAt,
  updateExternalChatTypingStatus,
} = externalChatChatsListSlice.actions
