import styles from '../styles.module.scss'
import emptyAvatarImage from '../../../../../assets/img/Avatar.png'

interface IConstraint {
  avatar: string
  id: string
}

type AssignedAgent<T> = T

interface IProps {
  agents: Array<AssignedAgent<IConstraint>>
  count?: number
  isPrivateMode?: boolean
}

export const renderAgentGroups = ({ agents, count, isPrivateMode }: IProps) => {
  return (
    <>
      {agents?.length > 0 && (
        <div className={styles.agentsTableList}>
          {agents.slice(0, 2).map((agent) => (
            <img
              key={agent.id}
              alt='agent logo'
              src={!isPrivateMode && agent?.avatar ? agent?.avatar : emptyAvatarImage}
              loading='lazy'
              width={38}
              height={38}
            />
          ))}
          {count
            ? count > 2 && <div className={styles.agentCount}>+{count - 2}</div>
            : agents.length > 2 && <div className={styles.agentCount}>+{agents.length - 2}</div>}
        </div>
      )}
    </>
  )
}
