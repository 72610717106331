import React, { memo, useContext, useEffect } from 'react'
import { Col, Spin } from 'antd'
import { Button } from 'src/shared/components/button/Button'
import { InfiniteScrollComponent } from '../../../../../../../../../../shared/components/infiniteScoll/InfiniteScroll'
import useSearch from '../../../../../../../../helpers/useSearch'
import { TextFieldSearch } from '../../../../../../../../../../shared/components/textFieldSearch/TextFieldSearch'
import { IconButton } from '../../../../../../../../../../shared/components/iconButton/IconButton'
import { InfoMessage } from '../../../../../../../VendorManagement/VendorManagementContactListModal/Components/InfoMessage'
import { AddIcon } from '../../../../../../../../../../assets/svg'
import styles from './styles.module.scss'
import { ContactListModalHeader } from '../../../../../../../VendorManagement/VendorManagementContactListModal/Components/ContactListModalHeader'
import { RolesAndPermissionsManagementUsersList } from '../../../RolesManagementNew/Components/RolesAndPermissionsManagementUsersList'
import useUsers from '../../../../../../hooks/useUsers'
import { useUpdateRoleMutation } from '../../../../../../../../core/http/RolesAndPermissionsManagement'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../../../../shared/api/errorHandler'
import { RightModalContext } from '../../../../../../../../../Modals'
import { useAppDispatch } from '../../../../../../../../../../redux'
import { clearUsersSliceState } from '../../../../../../../../core/store/UsersManagerSlice'
import { isNumber } from 'lodash'

export const RoleManagementAddUsersToRoleListModal = memo(() => {
  const {
    props: { roleId },
    onClose,
  } = useContext(RightModalContext)
  const dispatch = useAppDispatch()

  const [updateRole, updateRoleResp] = useUpdateRoleMutation()

  const {
    usersList,
    selectedUsersIds,
    filterValues,
    filterFields,
    totalCount,
    setTableConf,
    setIsChangeSearch,
    onPressCreateNewUser,
    loadMoreData,
    setActiveFilters,
    onAddUser,
    onDeleteUser,
  } = useUsers()

  const { handleSearchChange } = useSearch(setTableConf)

  useNotification(NOTIFICATION_TYPES.success, updateRoleResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    updateRoleResp.isError,
    updateRoleResp.error as ErrorNode
  )

  const searchComponent = (
    <TextFieldSearch
      className={styles.search}
      onChange={(e) => {
        setIsChangeSearch(true)
        handleSearchChange(e)
      }}
    />
  )

  const addButtonComponent = (
    <Col>
      <IconButton
        className={styles.addBtn}
        icon={<AddIcon />}
        type='primary'
        color='orange'
        size='large'
        onClick={onPressCreateNewUser}
      />
    </Col>
  )

  const onPressAddUsersButton = () => {
    updateRole({ id: roleId, body: { users: selectedUsersIds } })
  }

  useEffect(() => {
    if (updateRoleResp.isSuccess) {
      onClose()
      dispatch(clearUsersSliceState())
    }
  }, [updateRoleResp.isSuccess])

  const content = (
    <React.Fragment>
      <InfiniteScrollComponent
        loadMoreData={loadMoreData}
        dataLength={usersList.length}
        totalCount={totalCount as number}
        containerStyles={{ padding: 24, paddingBottom: 0 }}
      >
        <InfoMessage text='Users' />
        <ContactListModalHeader
          classNameFilterPopup={styles.filterPopup}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          data={filterFields}
          searchComponent={searchComponent}
          addButtonComponent={addButtonComponent}
          classNamesFilterWrapperIcon={styles.filterWrraperIcon}
        />
        <RolesAndPermissionsManagementUsersList
          onDeleteUser={onDeleteUser}
          onAddUser={onAddUser}
          users={usersList}
        />
      </InfiniteScrollComponent>
      <div className={styles.footerContainer}>
        <Button
          disabled={!selectedUsersIds.length}
          color='orange'
          block
          type='primary'
          onClick={onPressAddUsersButton}
        >
          Add users to role
        </Button>
      </div>
    </React.Fragment>
  )

  return (
    <div className={styles.rolesModalContainer}>
      {!isNumber(totalCount) ? <Spin>{content}</Spin> : content}
    </div>
  )
})
