import { PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM } from './IPromotionPolicy'
import { IByUser } from '../../../shared/models/IByUser'

export enum REWARD_POLICY_TYPE_ENUM {
  TOPUP = 'TOPUP',
}

export enum REWARD_POLICY_ACCESS_CHANNEL {
  ADMIN_PORTAL = ' Admin Portal',
  AGENT_MOBILE_APP_AND = 'Admin Mobile App - Android',
  AGENT_PORTAL = 'Agent Portal',
  AGENT_MOBILE_APP_IOS = 'Agent Mobile App - iOS',
  API = 'API',
  CORPORATE_PORTAL = 'Corporate Portal',
  POS = 'POS',
  SYSTEM = 'System',
  CUSTOMER_PORTAL = 'Customer Portal',
  CUSTOMER_MOBILE_APP_IOS = 'Customer Mobile App - iOS',
  CUSTOMER_MOBILE_APP_AND = 'Customer Mobile App - Android',
  USSD = 'USSD',
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
}

export interface IRewardMasterProd {
  rate: number
  from: number
  to: number
  id: string
  masterProduct: IByUser
  status: boolean
}

export interface IRewardTargetGroup {
  id: string
  title: string
  status: boolean
  customersCount: number
}

export interface IRewardPolicy {
  id: string
  title: string
  type: REWARD_POLICY_TYPE_ENUM
  measurementType: PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM
  start: string
  end: string
  isOnce: boolean
  status: boolean
  masterProducts: Array<IRewardMasterProd>
  targetGroups: Array<IRewardTargetGroup>
  createdAt: string
  accessChannels: REWARD_POLICY_ACCESS_CHANNEL
}

export interface IRewardPolicyMutation extends Omit<IRewardPolicy, 'targetGroups'> {
  targetGroups: Array<string>
}

export interface ITargetGroupCustomer {
  avatar: string
  fullName: string
  id: number
}

export interface IRewardPolicyTable {
  id: string
  title: string
  start: string
  end: string
  status: boolean
  masterProducts: Array<IByUser>
  targetGroupCustomers: Array<ITargetGroupCustomer>
  masterProductsCount: string
  targetGroupCount: number
}
