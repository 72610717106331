import { ICustomer } from '../../../../models/ICustomer'
import React, { useCallback, useContext, useState } from 'react'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../shared/components/popup/Popup'
import { ColumnsType } from 'antd/es/table'
import { TableActionIcon } from '../../../../../../assets/svg'
import { formatNumberToLocale } from '../../../../helpers'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import styles from '../../styles.module.scss'
import { Table } from 'antd'
import { TableHeader } from '../TableHeader'
import { RightModalContext } from '../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import {
  useDeleteCustomerByIdMutation,
  useGetCustomersListQuery,
  useGetCustomersTableFiltersListQuery,
  useLazyExportCustomersTableQuery,
} from '../../../../core/http/CustomersApi'
import { useTableExport } from '../../../../../../shared/hooks/table/useTableExport'
import { ErrorNode, showConfirmMessage } from '../../../../../../shared/api/errorHandler'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { isEmpty } from 'lodash'
import { useAppDispatch } from '../../../../../../redux'
import { segmentsApi } from '../../../../core/http/SegmentsApi'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'

export const CustomersTable = () => {
  const dispatch = useAppDispatch()
  const { onOpen } = useContext(RightModalContext)
  const [delCust, delCustResp] = useDeleteCustomerByIdMutation()

  const afterDelete = () => {
    dispatch(segmentsApi.util.resetApiState())
  }

  useNotification(NOTIFICATION_TYPES.success, delCustResp.isSuccess, null, afterDelete)
  useNotification(NOTIFICATION_TYPES.error, delCustResp.isError, delCustResp.error as ErrorNode)

  const tableActionsPopup = useCallback(
    (row: ICustomer): IPopupListItems[] => [
      {
        text: 'View',
        shouldDisplay: true,
        onClick: () =>
          onOpen(RIGHT_MODALS.SALES.CUSTOMER, {
            title: 'Customer',
            mode: 'view',
            id: row?.id,
          }),
      },
      ...(row?.closedGroupId
        ? [
            {
              text: 'View CCG',
              shouldDisplay: true,
              onClick: () =>
                onOpen(RIGHT_MODALS.SALES.CUSTOMER_CLOSED_GROUP, {
                  title: 'View Closed Group',
                  id: row?.closedGroupId,
                  mode: 'view',
                }),
            },
          ]
        : [
            {
              text: 'Add CCG',
              shouldDisplay: true,
              onClick: () =>
                onOpen(RIGHT_MODALS.SALES.CUSTOMER_CLOSED_GROUP, {
                  title: 'Add Closed Group',
                  ownerId: row?.id,
                }),
            },
          ]),
      {
        text: 'Edit',
        shouldDisplay: true,
        onClick: () =>
          onOpen(RIGHT_MODALS.SALES.CUSTOMER, {
            title: 'Customer',
            mode: 'edit',
            id: row?.id,
          }),
      },
      {
        text: 'Delete',
        withDivider: true,
        color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        shouldDisplay: true,
        onClick: () => showConfirmMessage('This customer will be deleted', () => delCust(row?.id)),
      },
    ],
    [delCust, onOpen]
  )

  const columns: ColumnsType<ICustomer> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '15%',
      sorter: true,
      render: (id, row) => (
        <>
          <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>
          {row?.closedGroupId && (
            <RecursivelyReplaceCharacters>
              <div className={styles.escrowMark}>{row?.closedGroupId}</div>
            </RecursivelyReplaceCharacters>
          )}
        </>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '20%',
      sorter: true,
      render: (name) => <RecursivelyReplaceCharacters>{name}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Number Of Topup',
      dataIndex: 'topupCount',
      width: '15%',
      sorter: true,
      render: (num) => (
        <RecursivelyReplaceCharacters>{formatNumberToLocale(num)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Total Amount',
      dataIndex: 'topupSum',
      sorter: true,
      width: '15%',
      render: (num) => (
        <RecursivelyReplaceCharacters>{formatNumberToLocale(num)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Average Denomination',
      dataIndex: 'topupAvg',
      width: '15%',
      sorter: true,
      render: (num) => (
        <RecursivelyReplaceCharacters>{formatNumberToLocale(num)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'region',
      width: '15%',
      sorter: true,
      render: (_, row) => (
        <RecursivelyReplaceCharacters>
          <div>
            <div>{row?.country && row.country?.name}</div>
            <div>{row?.province && row.province?.name}</div>
            <div>{row?.district && row.district?.name}</div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      width: '1%',
      key: 'edit',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'id',
  })

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data, isFetching } = useGetCustomersListQuery(
    { ...tableConf, ...filterValues },
    { skip: isEmpty(tableConf) }
  )
  const { data: filterFields } = useGetCustomersTableFiltersListQuery()
  const [exportTable] = useLazyExportCustomersTableQuery()

  useTableExport({ tableConf, filterValues, promise: exportTable })

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<ICustomer>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  return (
    <>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filterFields}
      />
      <Table
        locale={emptyTableConf}
        pagination={pagination}
        onChange={handleTableChange}
        className={styles.table}
        scroll={{ x: 1000 }}
        loading={isFetching}
        columns={columns}
        dataSource={data?.items}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record?.id}
      />
    </>
  )
}
