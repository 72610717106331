import React from 'react'
import { DepartmentsManagementViewTabs } from './Components/Tabs/index'

import styles from './styles.module.scss'

export const DepartmentsManagementView = () => {
  return (
    <div className={styles.layout}>
      <DepartmentsManagementViewTabs />
    </div>
  )
}
