import React from 'react'
import { startCase } from 'lodash'
import { Link } from 'react-router-dom'
import { IBankWalletTransaction } from '../../../../models/IBankWalletTransactions'
import emptyAvatar from '../../../../../../assets/img/Avatar.png'
import styles from '../PickupRequestTable/styles.module.scss'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'

export const renderTableAgent = (
  agent: IBankWalletTransaction['agent'],
  row: IBankWalletTransaction,
  isPrivateMode = false
) =>
  agent && !Object.hasOwn(row, 'mainTransactionId') ? (
    <RecursivelyReplaceCharacters>
      <Link to={`/sales-network/agent-management/view/${agent?.id}`} className='table-avatar'>
        <div>
          <img
            src={!isPrivateMode && agent?.avatar ? agent.avatar : emptyAvatar}
            alt='icon'
            height={32}
            width={32}
          />
        </div>
        <div>
          <div>{agent?.name}</div>
          <div className={styles.agentIdText}>{`${agent?.id}`}</div>
          {row?.escrow && <div className={styles.bankAccountId}>Escrow</div>}
        </div>
      </Link>
    </RecursivelyReplaceCharacters>
  ) : row?.wallet ? (
    <div>
      <RecursivelyReplaceCharacters>
        <div>{row?.wallet?.name}</div>
      </RecursivelyReplaceCharacters>
      <div>
        <RecursivelyReplaceCharacters>
          {row?.escrow && <div className={styles.bankAccountId}>Escrow</div>}
        </RecursivelyReplaceCharacters>
      </div>
    </div>
  ) : row?.walletType ? (
    <div>
      {row.walletType !== 'Agent Wallet' && (
        <RecursivelyReplaceCharacters>
          <div className={styles.bankAccountId}>{startCase(row.walletType)}</div>
        </RecursivelyReplaceCharacters>
      )}
      <div>
        <RecursivelyReplaceCharacters>
          {row?.bankAccount?.name || row?.agent?.name}
        </RecursivelyReplaceCharacters>
      </div>
      {row?.bankAccount?.id && (
        <RecursivelyReplaceCharacters>
          <div className={styles.bankAccountId}>Account ID: {row?.bankAccount?.id}</div>
        </RecursivelyReplaceCharacters>
      )}
      <RecursivelyReplaceCharacters>
        {row?.escrow && <div className={styles.bankAccountId}>Escrow</div>}
      </RecursivelyReplaceCharacters>
    </div>
  ) : null
