import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import {
  IAdjustmentCreate,
  IAdjustmentDetail,
  IWalletStatementPDFReport,
  IWalletStatementTrans,
  IWalletStatementTransactionDetail,
  WALLET_STATEMENT_CAT_ROUTE_ENUM,
} from '../../models/IWalletStatement'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { ISelectedFilters } from '../../../../shared/components/filter/Filter'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

export const statementOfWalletsApi = createApi({
  reducerPath: 'statementOfWallets',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 0,
  tagTypes: ['Adjustment', 'WalletsList', 'StatementList'],
  endpoints: (builder) => ({
    getStatementOfWalletsList: builder.query<Array<IWalletStatementTrans>, void>({
      query: () => ({
        url: 'wallets-statement',
      }),
      providesTags: () => ['WalletsList'],
    }),

    getAgentWalletsList: builder.query<ITableResponse<IWalletStatementTrans>, ITableConf>({
      query: (params) => ({
        url: 'wallets-statement/agent-wallet',
        params,
      }),
      providesTags: () => ['StatementList'],
    }),

    getStatementBankAccountsList: builder.query<ITableResponse<IWalletStatementTrans>, ITableConf>({
      query: (params) => ({
        url: 'wallets-statement/bank-account',
        params,
      }),
      providesTags: () => ['StatementList'],
    }),

    getVendorWalletsList: builder.query<ITableResponse<IWalletStatementTrans>, ITableConf>({
      query: (params) => ({
        url: 'wallets-statement/vendor-wallet',
        params,
      }),
      transformResponse: (res: ITableResponse<IWalletStatementTrans>) => ({
        ...res,
        items: res.items.map((trans) =>
          trans?.subWallets?.totalCount
            ? {
                ...trans,
                children: trans?.subWallets?.items.map((sub) => ({
                  ...sub,
                  walletId: sub?.walletId ?? trans?.walletId,
                  children: sub?.subWallets?.items.map((subNest) => ({
                    ...subNest,
                    shouldDisplayExpand: false,
                  })),
                })),
              }
            : trans
        ),
      }),
      providesTags: () => ['StatementList'],
    }),

    getRewardWalletsList: builder.query<ITableResponse<IWalletStatementTrans>, ITableConf>({
      query: (params) => ({
        url: 'wallets-statement/reward-wallet',
        params,
      }),
      providesTags: () => ['StatementList'],
    }),

    getTrustWalletsList: builder.query<ITableResponse<IWalletStatementTrans>, ITableConf>({
      query: (params) => ({
        url: 'wallets-statement/treasury-wallet',
        params,
      }),
      providesTags: () => ['StatementList'],
    }),

    getBankWalletsList: builder.query<ITableResponse<IWalletStatementTrans>, ITableConf>({
      query: (params) => ({
        url: 'wallets-statement/operation-wallet',
        params,
      }),
      providesTags: () => ['StatementList'],
    }),

    getDiscountWalletsList: builder.query<ITableResponse<IWalletStatementTrans>, ITableConf>({
      query: (params) => ({
        url: 'wallets-statement/discount-wallet',
        params,
      }),
      providesTags: () => ['StatementList'],
    }),

    getHawalaWalletsList: builder.query<ITableResponse<IWalletStatementTrans>, ITableConf>({
      query: (params) => ({
        url: 'wallets-statement/hawala-wallet',
        params,
      }),
      providesTags: () => ['StatementList'],
    }),

    getCreditWalletsList: builder.query<ITableResponse<IWalletStatementTrans>, ITableConf>({
      query: (params) => ({
        url: 'wallets-statement/credit-wallet',
        params,
      }),
      providesTags: () => ['StatementList'],
    }),

    getPromotionWalletsList: builder.query<ITableResponse<IWalletStatementTrans>, ITableConf>({
      query: (params) => ({
        url: 'wallets-statement/promotion-wallet',
        params,
      }),
      providesTags: () => ['StatementList'],
    }),

    getCollectionWalletsList: builder.query<ITableResponse<IWalletStatementTrans>, ITableConf>({
      query: (params) => ({
        url: 'wallets-statement/collection-wallet',
        params,
      }),
      providesTags: () => ['StatementList'],
    }),

    getStatementOfWalletsTransactions: builder.query<
      ITableResponse<IWalletStatementTransactionDetail>,
      { route: WALLET_STATEMENT_CAT_ROUTE_ENUM; walletId?: string; subWallet?: string }
    >({
      query: ({ route, ...params }) => ({
        url: `wallets-statement/${route}/transactions`,
        params,
      }),
    }),

    generateStatementTransactionsPdf: builder.query<
      IWalletStatementPDFReport,
      { route: WALLET_STATEMENT_CAT_ROUTE_ENUM; params: ISelectedFilters }
    >({
      query: ({ route, params }) => ({
        url: `wallets-statement/${route}/transactions/report`,
        params,
      }),
    }),

    createAdjustment: builder.mutation<IAdjustmentDetail, IAdjustmentCreate>({
      query: (body) => ({
        url: `adjustment`,
        method: 'POST',
        body,
      }),
    }),

    getAdjustmentById: builder.query<IAdjustmentDetail, string>({
      query: (id) => ({
        url: `adjustment/${id}`,
      }),
      providesTags: (result) => [{ type: 'Adjustment', id: result?.id }],
    }),

    addAttachmentsToAdjustment: builder.mutation<IAdjustmentDetail, { id: string; body: FormData }>(
      {
        query: ({ id, body }) => ({
          url: `adjustment/${id}/attachments`,
          method: 'POST',
          body,
        }),
        invalidatesTags: (result) => [{ type: 'Adjustment', id: result?.id }],
      }
    ),

    getAgentWalletFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: '/wallets-statement/finance/wallets-statement/agent-wallet/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }
        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `wallets-statement/finance/wallets-statement/agent-wallet/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),
  }),
})

export const {
  useGetStatementOfWalletsListQuery,
  useGetAgentWalletsListQuery,
  useGetStatementBankAccountsListQuery,
  useGetVendorWalletsListQuery,
  useGetRewardWalletsListQuery,
  useGetTrustWalletsListQuery,
  useGetDiscountWalletsListQuery,
  useGetHawalaWalletsListQuery,
  useGetCreditWalletsListQuery,
  useGetPromotionWalletsListQuery,
  useGetCollectionWalletsListQuery,
  useGetStatementOfWalletsTransactionsQuery,
  useGetBankWalletsListQuery,
  useLazyGenerateStatementTransactionsPdfQuery,
  useCreateAdjustmentMutation,
  useGetAdjustmentByIdQuery,
  useAddAttachmentsToAdjustmentMutation,
  useGetAgentWalletFiltersListQuery,
} = statementOfWalletsApi
