import classNames from 'classnames'
import { Button as AntButton, ButtonProps } from 'antd'

export interface IButtonProps extends ButtonProps {
  color?: 'blue' | 'orange' | 'error' | 'warning' | 'success' | 'black'
}

export const Button = ({
  children,
  color = 'blue',
  size = 'middle',
  ...rest
}: IButtonProps): JSX.Element => {
  return (
    <AntButton
      className={classNames('button', color, rest.className)}
      htmlType='button'
      size={size}
      {...rest}
    >
      {children}
    </AntButton>
  )
}
