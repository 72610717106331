import React, { memo } from 'react'
import { ChatCreateTicketIcon, ChatRegisterAgentIcon } from '../../../../../../../../assets/svg'
import { ChatInfoAction } from '../../../../../../../../shared/components/chat/Components'
import styles from './styles.module.scss'
import { useNavigate } from 'react-router-dom'
import { SALES_ENUM } from '../../../../../../../../routes/sales'
import { SUPPORT_ENUM } from '../../../../../../../../routes/support'

const ChatroomSupport = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.chatRoomSupportActionsContainer}>
      <ChatInfoAction
        icon={<ChatRegisterAgentIcon />}
        onClickByType={() => navigate(SALES_ENUM.NEW_AGENT)}
        actionType='Register Agent'
      />
      <ChatInfoAction
        icon={<ChatCreateTicketIcon />}
        onClickByType={() => navigate(`/${SUPPORT_ENUM.SUPPORT_TICKET_NEW}`)}
        actionType='Create a Ticket'
      />
    </div>
  )
}
export default memo(ChatroomSupport)
