import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { first } from 'lodash'
import { Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'

import { AddMessageTools } from '../AddMessageTools'
import { TextMessage } from '../Message'
import { internalChatsApi } from '../../../../core/http/InternalChatsApi'
import {
  useLazyGetBeforeLastReadMessagesByInternalChatIdQuery,
  useSendLastReadMessageMutation,
} from '../../../../core/http/InternalMessagingApi'
import { useAppDispatch } from '../../../../../../redux'
import { IInternalChat, IInternalChatMessage, IRepliedTo } from '../../../../models'
import {
  addAfterMessages,
  addBeforeMessages,
  clearMessages,
  setCreatedAtAfter,
  setCreatedAtBefore,
  setLastReadingAt,
} from '../../../../core/store/Messages'

import styles from './styles.module.scss'
import { updateChatUnreadCounter } from '../../../../core/store/Chats'
import classNames from 'classnames'

interface IProps {
  limit: number
  openedChatId: number
  lastReadingAt: string
  createdAtBefore: string
  createdAtAfter: string
  openedChat: IInternalChat
  messages: Array<IInternalChatMessage>
}

export interface IChatMessagesConf {
  openedChatId: number
  limit: number
  createdAtBefore?: string
  createdAtAfter?: string
}

export const MessagesList = ({
  openedChatId,
  createdAtBefore,
  createdAtAfter,
  lastReadingAt,
  limit,
  messages,
  openedChat,
}: IProps) => {
  const dispatch = useAppDispatch()

  const chatContainer = useRef<HTMLDivElement | null>(null)

  const [toScrollId, setToScrollId] = useState<number | null>(null)
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false)
  const [scrollPosition] = useState(0)
  const [messageToReply, setMessageToReply] = useState<Partial<IInternalChatMessage> | null>(null)

  const [sendLastRead] = useSendLastReadMessageMutation()
  const [
    getBeforeMessages,
    {
      data: messagesBefore = { items: [], totalCount: 0 },
      isFetching: isFetchingBefore,
      isLoading: isLoadingBefore,
    },
  ] = useLazyGetBeforeLastReadMessagesByInternalChatIdQuery()
  const [
    getAfterMessages,
    {
      data: messagesAfter = { items: [], totalCount: 0 },
      isFetching: isFetchingAfter,
      isLoading: isLoadingAfter,
    },
  ] = useLazyGetBeforeLastReadMessagesByInternalChatIdQuery()

  useEffect(() => {
    if (!openedChatId || (!createdAtBefore && !lastReadingAt)) return
    getBeforeMessages({ openedChatId, limit, createdAtBefore: createdAtBefore || lastReadingAt })
    // eslint-disable-next-line
  }, [createdAtBefore, lastReadingAt, limit, openedChatId])

  useEffect(() => {
    if (!openedChatId || (!createdAtBefore && !lastReadingAt)) return
    getAfterMessages({ openedChatId, limit, createdAtAfter: createdAtAfter || lastReadingAt })
    // eslint-disable-next-line
  }, [createdAtAfter, lastReadingAt, limit, openedChatId])

  const endConversationMessage = useMemo(() => {
    if (isFetchingBefore || isLoadingBefore) return 'Loading...'
    return messagesBefore?.totalCount === 0 && !messages.length
      ? 'Start a conversation by typing your message below.'
      : null
    // eslint-disable-next-line
  }, [isFetchingBefore, isLoadingBefore, messagesBefore?.totalCount])

  useEffect(() => {
    return () => {
      dispatch(clearMessages())
      dispatch(internalChatsApi.util.resetApiState())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isFetchingBefore || isLoadingBefore) return
    if (messagesBefore.items?.length) {
      dispatch(addBeforeMessages(messagesBefore.items))
    }
    // eslint-disable-next-line
  }, [isFetchingBefore, isLoadingBefore, messagesBefore.items])

  useEffect(() => {
    if (isFetchingAfter || isLoadingAfter) return
    if (messagesAfter.items?.length) {
      const sorted = [...messagesAfter.items].sort((a, b) =>
        moment(b?.createdAt).diff(moment(a?.createdAt))
      )
      dispatch(addAfterMessages(sorted))
    }
    // eslint-disable-next-line
  }, [isFetchingAfter, isLoadingAfter, messagesAfter.items])

  useEffect(() => {
    if (openedChatId) {
      let lastRead: string
      if (openedChat?.lastInternalChatMessage) {
        const { toUserId, createdAt } = openedChat.lastInternalChatMessage
        if (
          openedChatId === toUserId ||
          !openedChat?.sentInternalChatMessageReading?.lastReadingAt
        ) {
          lastRead = createdAt
        } else {
          lastRead = openedChat?.sentInternalChatMessageReading?.lastReadingAt
        }
      } else {
        lastRead = ''
      }

      dispatch(setLastReadingAt(lastRead))
      dispatch(setCreatedAtBefore(lastRead))
      dispatch(setCreatedAtAfter(lastRead))
    }
    // eslint-disable-next-line
  }, [openedChat, openedChatId])

  useEffect(() => {
    if (
      openedChat &&
      chatContainer.current &&
      (chatContainer.current?.clientHeight >= chatContainer.current?.scrollHeight ||
        chatContainer.current?.scrollTop < 2) &&
      openedChat.unreadedMessagesCount > 0
    ) {
      const lastMessage = first(messages.filter((el) => el.fromUserId === openedChatId))
      if (lastMessage) {
        sendLastRead({ toUserId: lastMessage.fromUserId, lastReadingAt: lastMessage.createdAt })
      }
    }
    // eslint-disable-next-line
  }, [messages, openedChat])

  /** If a user has an unread message in the chat, we should scroll the chat
   * automatically to that message. The message can either be the first
   * unread message since the last read date, or the first in the list of
   * non-personal messages if the user opened the chat for the first time.
   * */
  const firstUnreadMessage = useMemo(() => {
    let message = null

    if (lastReadingAt) {
      message = messagesAfter.items.find(
        (m) => m.toUserId !== openedChatId && moment(m.createdAt).isAfter(moment(lastReadingAt))
      )
    } else {
      message = first(messagesAfter.items.filter((el) => el.toUserId !== openedChatId))
    }

    return message
  }, [openedChatId, messagesAfter.items, lastReadingAt])

  useEffect(() => {
    if (firstUnreadMessage) {
      const currentMessageElement = document.getElementById(`message${firstUnreadMessage?.id}`)
      let topToScroll = 0
      if (chatContainer.current && currentMessageElement) {
        const screenPositionOfCurrentMessageElement = currentMessageElement?.getBoundingClientRect()
        topToScroll =
          screenPositionOfCurrentMessageElement?.top -
          chatContainer.current?.offsetTop -
          chatContainer.current?.clientHeight +
          currentMessageElement.clientHeight
        const timeout = setTimeout(() => {
          chatContainer.current?.scrollTo({
            top: topToScroll,
            left: 0,
          })
        }, 500)
        return () => clearTimeout(timeout)
      }
    } else {
      dispatch(updateChatUnreadCounter({ fromUserId: openedChatId, counter: 0 }))
    }
    // eslint-disable-next-line
  }, [firstUnreadMessage])

  /** A group of messages is conventionally messages sent by one user in a row.
   This condition is necessary for the design of messages in the chat */
  const isFirstMessageOfGroup = useCallback(
    (fromUserId: number, i: number) => {
      return (
        i === messages.length - 1 || (messages[i + 1] && messages[i + 1].fromUserId !== fromUserId)
      )
    },
    [messages]
  )

  /** A group of messages is conventionally messages sent by one user in a row.
  This condition is necessary for the design of messages in the chat */
  const isLastMessageOfGroup = useCallback(
    (fromUserId: number, i: number) => {
      return i === 0 || (messages[i - 1] && messages[i - 1].fromUserId !== fromUserId)
    },
    [messages]
  )

  /** This condition is necessary for the design of messages delimiter by date in the chat */
  const isFirstMessageInADay = useCallback(
    (createdAt: string, i: number) => {
      return (
        i === messages.length - 1 ||
        (messages[i + 1] && !moment(messages[i + 1].createdAt).isSame(moment(createdAt), 'days'))
      )
    },
    [messages]
  )

  const loadMoreBefore = () => {
    const lastMessageDate = messagesBefore.items.at(-1)
    if (!lastMessageDate) return
    if (lastMessageDate?.createdAt) {
      return dispatch(setCreatedAtBefore(lastMessageDate.createdAt))
    }
  }

  const loadMoreAfter = () => {
    // setScrollPosition(chatContainer.current?.scrollTop || 0)
    const newCreatedAfter = first(messages)?.createdAt
    if (newCreatedAfter) {
      dispatch(setCreatedAtAfter(newCreatedAfter))
    }
  }

  const selectToReply = useCallback((message: Partial<IInternalChatMessage> | null) => {
    setMessageToReply(message)
  }, [])

  const scrollToMyRef = useCallback(() => {
    if (chatContainer.current) {
      const scroll = chatContainer.current.scrollHeight - chatContainer.current.clientHeight
      chatContainer.current.scrollTo({
        top: scroll,
        left: 0,
      })
    }
  }, [])

  const onMessageCreated = useCallback((message: IInternalChatMessage) => {
    dispatch(addAfterMessages([message]))
    // eslint-disable-next-line
  }, [])

  const resetCreateDates = useCallback((date: string) => {
    dispatch(setLastReadingAt(date))
    dispatch(setCreatedAtBefore(date))
    dispatch(setCreatedAtAfter(date))
    // eslint-disable-next-line
  }, [])

  const resetToRepliedMessage = (date: string, id: number) => {
    setToScrollId(id)
    dispatch(setCreatedAtBefore(date))
    dispatch(setCreatedAtAfter(date))
  }

  const scrollToRepliedMessage = useCallback((message: IRepliedTo) => {
    const currentMessageElement = document.getElementById(`message${message.id}`)
    if (chatContainer?.current && currentMessageElement) {
      currentMessageElement.scrollIntoView({ behavior: 'smooth' })
      currentMessageElement.classList.add('highlight-internal')
      setTimeout(() => {
        currentMessageElement?.classList.remove('highlight-internal')
      }, 1000)
    } else {
      dispatch(clearMessages())
      if (message?.createdAt) {
        resetToRepliedMessage(message.createdAt, message.id)
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.messagesContainer}>
      <div className={styles.messagesList}>
        <div
          id='scrollableDiv'
          className={classNames(styles.messagesListScrollContainer, {
            [styles.isOpen]: isActionMenuOpen,
          })}
          ref={chatContainer}
        >
          <InfiniteScroll
            inverse={true}
            onScroll={loadMoreAfter}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            dataLength={messages?.length}
            next={loadMoreBefore}
            hasMore={!!messagesBefore?.totalCount && messagesBefore?.totalCount > limit}
            loader={isFetchingBefore && <Skeleton avatar paragraph={{ rows: 5 }} active />}
            endMessage={
              <div className={styles.scrollMessage}>
                {typeof endConversationMessage === 'string' ? endConversationMessage : ''}
              </div>
            }
            scrollableTarget='scrollableDiv'
          >
            {messages.map((mes, i) => {
              const isNextMessageByTheSameUser = messages[i - 1]?.fromUserId === mes?.fromUserId
              return (
                <TextMessage
                  key={mes?.id}
                  toScrollId={toScrollId}
                  setToScrollId={setToScrollId}
                  openedChatId={openedChatId as number}
                  isFirstInGroup={isFirstMessageOfGroup(mes?.fromUserId, i)}
                  isLastInGroup={isLastMessageOfGroup(mes?.fromUserId, i)}
                  isFirstMessageInADay={isFirstMessageInADay(mes?.createdAt, i)}
                  chatClient={chatContainer.current}
                  scrollPosition={scrollPosition}
                  selectToReply={selectToReply}
                  resetCreateDates={resetCreateDates}
                  lastReadingAt={
                    openedChat?.recievedInternalChatMessageReading?.lastReadingAt as string
                  }
                  scrollToMessage={scrollToRepliedMessage}
                  message={mes}
                  isNextMessageByTheSameUser={isNextMessageByTheSameUser}
                  setIsActionMenuOpen={setIsActionMenuOpen}
                />
              )
            })}
          </InfiniteScroll>
        </div>
      </div>
      <AddMessageTools
        messageToReply={messageToReply}
        clearReply={selectToReply}
        currentChatId={openedChatId as number}
        scrollToMyRef={scrollToMyRef}
        onMessageCreated={onMessageCreated}
      />
    </div>
  )
}
