import { useAppSelector } from '../../redux'
import {
  selectProfileRole,
  selectProfileType,
} from '../../features/Profile/core/store/ProfileInfoSlice'
import { useCallback, useMemo } from 'react'
import { IProfileRolePermission } from '../../features/Settings/models/IUser'
import { selectRolesAndPermissionsManagementState } from '../../features/Settings/core/store/RoleAndPermissionsManagementSlice'
import { IPermissionModule } from '../../features/Settings/models/IRoles'

export default () => {
  const role = useAppSelector(selectProfileRole)
  const isSuperUser = useAppSelector(selectProfileType)
  const { permissionModules } = useAppSelector(selectRolesAndPermissionsManagementState)

  /** Returns an object of available permissions by module id. Ex: {123: ['VIEW', 'EDIT', ...]} */
  const permissionsByModuleIds = useMemo(() => {
    const p: { [key: number]: Array<string> } = {}
    role?.rolePermissions &&
      role?.rolePermissions.forEach((el: IProfileRolePermission) => {
        p[el.permissionModule.id] = [...(p[el.permissionModule.id] ?? []), el.permission]
      })

    return p
  }, [role?.rolePermissions])

  /** Returns an object of permissions by name: id. Ex: { Users: 123, ... } */
  const permissionsByModuleNames = useMemo(() => {
    const p: { [key: string]: number } = {}
    permissionModules.forEach((el: IPermissionModule) => (p[`${el.module}`] = el.id))
    return p
  }, [permissionModules])

  const getAllowedPermissionsById = useCallback(
    (id: number) => {
      return permissionsByModuleIds[id]
    },
    [permissionsByModuleIds]
  )

  /** Can perform if user has isSuperUser: true */
  const canPerformAction = useCallback(
    (id: number, actions: Array<string>) => {
      return actions.map((action) => permissionsByModuleIds[id]?.includes(action) || isSuperUser)
    },
    [isSuperUser, permissionsByModuleIds]
  )

  const getModulesIdsByNames = useCallback(
    (names: Array<string>) => {
      return names.map((name) => permissionsByModuleNames[name])
    },
    [permissionsByModuleNames]
  )

  return {
    roleId: role?.id,
    roleName: role?.name,
    isActiveRole: role?.status,
    getAllowedPermissionsById,
    getModulesIdsByNames,
    canPerformAction,
  }
}
