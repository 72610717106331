import { Form, Row, Select, Select as AntSelect, Space, Spin, UploadFile } from 'antd'
import styles from './styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../shared/components/textfield/TextField'
import { useEffect, useMemo, useState } from 'react'
import footerStyle from '../../../../Settings/pages/FileManagement/FileManagementView/styles.module.scss'
import { Button } from '../../../../../shared/components/button/Button'
import {
  useCreateBankAccountMutation,
  useGetBankAccountsByIdQuery,
  useGetListOfCurrenciesQuery,
  useUpdateBankAccountMutation,
} from '../../../core/http/BankAccountApi'
import {
  ACCOUNT_TYPE_ENUM,
  IBankAccountCreation,
  PAYMENT_METHOD_ENUM,
} from '../../../models/IBankAccount'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { useNavigate, useParams } from 'react-router-dom'
import TextArea from 'antd/es/input/TextArea'
import {
  commonValidator,
  maxLengthValidator,
  requiredMulSelectValidator,
  requiredValidator,
} from 'src/helpers/validation'
import { UploaderPhotos2 } from '../../../../../shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import { RcFile } from 'antd/lib/upload'
import { useAppDispatch } from '../../../../../redux'
import { setBreadcrumb } from '../../../../app/core/BreadcrumbsSlice'
import { CurrentBalance } from './CurrentBalance/CurrentBalance'
import { ICurrency } from '../../../models/ICurency'
import { selectTagRender } from '../../../../../helpers/selectTagRender'
import classNames from 'classnames'
import { FINANCE_ENUM } from '../../../../../routes/finance'
import { Toggle } from '../../../../../shared/components/toggle/Toggle'
import { Wrapper } from '../../../../salesNetwork/components/Wrapper'
import { useGetBankListQuery } from '../../../core/http/BankApi'

export const BankAccount = () => {
  const [isImageError, setIsImageError] = useState(false)
  const [picture, setPicture] = useState<RcFile>()
  const [uploaderKey, setUploaderKey] = useState(() => new Date().toString())
  const [isLogoDelete, setIsLogoDelete] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const { id, mode } = useParams<{ id: string; mode: 'view' | 'edit' }>()

  const { data, isFetching, requestId } = useGetBankAccountsByIdQuery(id as string, { skip: !id })
  const { data: currencies, isFetching: isCurrencyFetching } = useGetListOfCurrenciesQuery()
  const { data: banksList, isFetching: isFetchingBanks } = useGetBankListQuery({})
  const [createBank, creBAResp] = useCreateBankAccountMutation()
  const [updateBank, upBAResp] = useUpdateBankAccountMutation()

  useNotification(NOTIFICATION_TYPES.success, creBAResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, upBAResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, upBAResp.isError, upBAResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, upBAResp.isError, upBAResp.error as ErrorNode)

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const onFinish = (values: IBankAccountCreation) => {
    const fData = new FormData()
    fData.append('logo', picture!)
    const req = {
      ...values,
      swift: values.swift.toUpperCase(),
      bankId: values.bank.id,
      isAccessible: !!values.isAccessible,
    }

    if (isLogoDelete) {
      req.isDeleteLogo = isLogoDelete
    }

    fData.append('bankAccount', JSON.stringify(req))

    isEditMode ? updateBank({ id: id!, body: fData }) : createBank(fData)
  }

  const paymentMethods = useMemo(
    () =>
      (Object.keys(PAYMENT_METHOD_ENUM) as Array<keyof typeof PAYMENT_METHOD_ENUM>).map(
        (method) => ({
          label: PAYMENT_METHOD_ENUM[method],
          value: PAYMENT_METHOD_ENUM[method],
        })
      ),
    []
  )

  const accountType = useMemo(
    () =>
      (Object.keys(ACCOUNT_TYPE_ENUM) as Array<keyof typeof ACCOUNT_TYPE_ENUM>).map((method) => ({
        label: ACCOUNT_TYPE_ENUM[method],
        value: ACCOUNT_TYPE_ENUM[method],
      })),
    []
  )

  const currenciesMap = useMemo(
    () =>
      currencies?.items.map((currency) => ({
        label: currency?.name,
        value: currency?.id,
      })),
    [currencies?.items]
  )

  const isLoading = creBAResp.isLoading || upBAResp.isLoading
  const isRetrievingInfo = isLoading || isFetching || isCurrencyFetching || isFetchingBanks
  const submitBtnText = isEditMode
    ? 'Save Changes'
    : isViewMode
    ? 'Edit Bank Account'
    : 'Add Bank Account'

  const onCancelButtonClick = () => {
    if (isViewMode || (!isViewMode && !isEditMode)) {
      return navigate(FINANCE_ENUM.BANK_SETTINGS)
    }
    if (isEditMode) {
      form.resetFields()
      setUploaderKey(new Date().toString())
      form.setFieldsValue({ ...data, currencyId: data?.currency?.id })
      return navigate(`${FINANCE_ENUM.BANK_SETTINGS}/bank-accounts/view/${id}`)
    }
  }

  const onSubmitButtonClick = () => {
    if (isViewMode) {
      return navigate(`${FINANCE_ENUM.BANK_SETTINGS}/bank-accounts/edit/${id}`)
    }
    form.submit()
  }

  useEffect(() => {
    if (data) {
      dispatch(setBreadcrumb(data))
      form.setFieldsValue({ ...data, currencyId: data?.currency?.id })
    }
  }, [data, dispatch, form, requestId])

  useEffect(() => {
    if (!isRetrievingInfo) {
      form.setFieldValue('currencyId', data?.currency?.id)
    }
  }, [data?.currency?.id, form, isRetrievingInfo])

  useEffect(() => {
    if (creBAResp.data) {
      const bankId = creBAResp.data?.id
      return navigate(`${FINANCE_ENUM.BANK_SETTINGS}/bank-accounts/view/${bankId}`)
    }
    // eslint-disable-next-line
  }, [creBAResp.data])

  useEffect(() => {
    if (upBAResp.data) {
      const bankId = upBAResp.data?.id
      return navigate(`${FINANCE_ENUM.BANK_SETTINGS}/bank-accounts/view/${bankId}`)
    }
    // eslint-disable-next-line
  }, [upBAResp.data])

  const shouldDisplayBalance = isViewMode || isEditMode

  const isAccessible = (
    <FormItem name='isAccessible' valuePropName='checked'>
      <Toggle disabled={isViewMode} text='Is Accessible' />
    </FormItem>
  )

  const content = (
    <div className={styles.bankAccountWrapper}>
      <Form form={form} onFinish={onFinish} className={styles.columnWrapper} layout='vertical'>
        <Wrapper title='Detail Info' className={styles.leftColumn} statusButton={isAccessible}>
          <div className={styles.dblRow}>
            <FormItem
              name={['bank', 'id']}
              label='Bank Name'
              rules={[{ required: true, validator: requiredValidator('Bank') }]}
            >
              <Select disabled={isViewMode} placeholder='Select Bank'>
                {banksList?.items.map((bank) => (
                  <Select.Option value={bank.id} key={bank.id}>
                    {bank.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>

            <FormItem
              name='beneficiaryName'
              label='Beneficiary Name'
              rules={[
                { required: true, validator: requiredValidator('Beneficiary Name') },
                { validator: maxLengthValidator(45) },
              ]}
            >
              <TextField disabled={isViewMode} placeholder='Enter Beneficiary name' />
            </FormItem>
          </div>

          <div className={styles.dblRow}>
            <FormItem noStyle shouldUpdate>
              {({ setFieldValue }) => (
                <FormItem
                  name='description'
                  label='Account Number'
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator('Account Number'),
                    },
                    { validator: maxLengthValidator(20) },
                    { validator: commonValidator },
                  ]}
                >
                  <TextField
                    disabled={isViewMode}
                    placeholder='Enter account number'
                    onChange={(e) => {
                      setFieldValue('description', e.target.value?.toUpperCase())
                    }}
                  />
                </FormItem>
              )}
            </FormItem>

            <FormItem
              name='typeOfAccount'
              label='Type Of Account'
              rules={[{ required: true, validator: requiredValidator('Account Type') }]}
            >
              <AntSelect
                allowClear
                placeholder='Select Account Type'
                disabled={isViewMode}
                options={accountType}
              />
            </FormItem>
          </div>

          <div className={styles.dblRow}>
            <FormItem noStyle shouldUpdate>
              {({ setFieldValue }) => (
                <FormItem
                  name='swift'
                  label='SWIFT'
                  rules={[
                    { required: true, validator: requiredValidator('Swift') },
                    { validator: maxLengthValidator(9) },
                  ]}
                >
                  <TextField
                    disabled={isViewMode}
                    placeholder='Enter SWIFT'
                    onChange={(e) => {
                      setFieldValue('swift', e.target.value?.toUpperCase())
                    }}
                  />
                </FormItem>
              )}
            </FormItem>

            <FormItem
              name='routingNumber'
              label='IBAN/Routing Number'
              rules={[
                { required: true, validator: requiredValidator('Routing Number') },
                { validator: maxLengthValidator(45) },
              ]}
            >
              <TextField disabled={isViewMode} placeholder='Enter IBAN/Routing Number' />
            </FormItem>
          </div>

          <div>
            <Form.Item
              name='currencyId'
              label='Account Currency'
              rules={[{ required: true, validator: requiredValidator('Currency') }]}
              wrapperCol={{ span: 12 }}
              style={{ marginRight: 24 }}
            >
              <AntSelect
                allowClear
                placeholder='Select Currency'
                disabled={isViewMode}
                options={currenciesMap}
              />
            </Form.Item>
          </div>

          <div>
            <FormItem
              name='paymentMethod'
              label='Payment Options'
              rules={[{ required: true, validator: requiredMulSelectValidator('Payment Options') }]}
            >
              <AntSelect
                disabled={isViewMode}
                mode='multiple'
                placeholder='Select Payment Options'
                options={paymentMethods}
                tagRender={selectTagRender}
                maxTagCount='responsive'
                showArrow
                allowClear
              />
            </FormItem>
          </div>

          <div>
            <FormItem
              name='wireTransferInstruction'
              label='Wire Transfer Instruction'
              className='noBottomMargin'
              rules={[{ required: true, validator: requiredValidator('Wire Transfer') }]}
            >
              <TextArea
                disabled={isViewMode}
                placeholder='Add instruction'
                style={{ height: 150 }}
              />
            </FormItem>
          </div>
        </Wrapper>

        <div>
          {shouldDisplayBalance && (
            <CurrentBalance balance={data?.walletBalance} currency={data?.currency as ICurrency} />
          )}
          <UploaderPhotos2
            form={form}
            title='BANK LOGO'
            view={isViewMode}
            key={uploaderKey}
            isBankPreview
            setToDeleteLogo={setIsLogoDelete}
            defaultPreviewImage={data?.logo}
            onChange={(photo: UploadFile[]) => {
              setPicture(photo[0].originFileObj as RcFile)
              setIsImageError(
                form.getFieldsError().filter(({ errors }) => errors.length).length > 0
              )
              setIsLogoDelete(false)
            }}
          />
        </div>
      </Form>
    </div>
  )

  return (
    <div className={classNames(styles.content, 'fullVH')}>
      {isRetrievingInfo ? <Spin>{content}</Spin> : content}

      <div className={footerStyle.wrapper}>
        <Row className={footerStyle.footer} justify='end'>
          <Space size={10}>
            <div className={styles.cancelButtonWrapper}>
              <Button
                color='blue'
                size='middle'
                disabled={isLoading}
                onClick={onCancelButtonClick}
                block
              >
                Cancel
              </Button>
            </div>
            <div className={styles.addBankAccountWrapper}>
              <Button
                htmlType='submit'
                size='middle'
                type='primary'
                block
                disabled={isImageError || isLoading}
                onClick={onSubmitButtonClick}
              >
                {submitBtnText}
              </Button>
            </div>
          </Space>
        </Row>
      </div>
    </div>
  )
}
