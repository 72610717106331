import { FormInstance } from 'antd/es/form/Form'
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

// styles
import styles from './styles.module.scss'
import classNames from 'classnames'
import { isArabic } from '../../../helpers/utils'

interface ITextEditor {
  form: FormInstance
  name: string | [string, number, string]
  viewMode: boolean
  initialContent?: string
  fieldToValidate?: string
}

export const TextEditor = ({
  form,
  name,
  viewMode,
  initialContent,
  fieldToValidate,
}: ITextEditor) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (!initialContent) return
    setValue(initialContent)
  }, [initialContent])

  const onChange = (value: string) => {
    form.validateFields([name, fieldToValidate ?? ''])
    setValue(value)

    form.setFields([
      {
        name,
        value,
      },
    ])
  }

  const description = form.getFieldValue(name)

  return (
    <React.Fragment>
      {viewMode ? (
        <div
          className={classNames(styles.content, { [styles.arabic]: isArabic(description) })}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      ) : (
        <ReactQuill
          className={styles.reactQuillContainer}
          theme='snow'
          value={value}
          onChange={onChange}
          modules={{
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ color: [] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ align: [] }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ indent: '-1' }, { indent: '+1' }],
                ['link', 'image', 'video'],
                [{ direction: 'rtl' }],
                ['clean'],
              ],
            },
          }}
        />
      )}
    </React.Fragment>
  )
}
