import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { DashboardTableWrapper } from '../../../../../../Dashboard/pages/Overview/Components/DashboardTableWrapper'
import styles from '../../styles.module.scss'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { INVENTORY_CHART_TYPE_ENUM } from '../../../../../models/IInventory'
import { startCase } from 'lodash'
import { Radio } from 'antd'
import { IEpinSalesChartWidget } from '../../../../../models/IEPin'
import moment from 'moment/moment'
import { RecursivelyReplaceCharacters } from '../../../../../../../shared/components/privateMode'
import { replaceCharactersWithAsterisks } from '../../../../../../../helpers/utils'
import { useLocalStorageGetByKey } from '../../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IProps {
  chartType: INVENTORY_CHART_TYPE_ENUM
  setChartType: Dispatch<SetStateAction<INVENTORY_CHART_TYPE_ENUM>>
  data: IEpinSalesChartWidget
}

export const SalesBarChart = ({ chartType, setChartType, data }: IProps) => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const mappedData = useMemo(() => {
    if (!data) return

    if (chartType === INVENTORY_CHART_TYPE_ENUM.YEAR) {
      return [...data.value]
        .reverse()
        .map((chartData) => ({
          ...chartData,
          date: moment(chartData?.period).format('MMM'),
        }))
        .reverse()
    }

    if (chartType === INVENTORY_CHART_TYPE_ENUM.WEEK) {
      return [...data.value].map((chartData) => ({
        ...chartData,
        date: moment(chartData?.period).format('DD MMM'),
      }))
    }

    if (chartType === INVENTORY_CHART_TYPE_ENUM.MONTH) {
      return [...data.value].map((chartData) => ({
        ...chartData,
        date: moment(chartData?.period).format('DD MMM'),
      }))
    }
  }, [chartType, data])

  const dateOptions = useMemo(
    () =>
      (Object.keys(INVENTORY_CHART_TYPE_ENUM) as Array<keyof typeof INVENTORY_CHART_TYPE_ENUM>).map(
        (method) => ({
          label: startCase(INVENTORY_CHART_TYPE_ENUM[method]),
          value: INVENTORY_CHART_TYPE_ENUM[method],
        })
      ),
    []
  )

  const filters = (
    <Radio.Group value={chartType} onChange={(e) => setChartType(e.target.value)}>
      {dateOptions.map((option) => (
        <Radio.Button key={option.value} value={option.value}>
          {option.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  )

  return (
    <div className={styles.salesChart}>
      <DashboardTableWrapper
        title='Sales'
        infoText='Sales Chart'
        filters={filters}
        withPadding={false}
      >
        <ResponsiveContainer width='100%' height={300}>
          <BarChart
            height={300}
            data={mappedData}
            margin={{
              top: 30,
              right: 30,
              left: 10,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='date' />
            <YAxis
              tickFormatter={(value) =>
                isPrivateMode ? replaceCharactersWithAsterisks(value) : value
              }
            />
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const time = payload[0]?.payload?.name

                  return (
                    <RecursivelyReplaceCharacters>
                      <div className={styles.tooltip}>
                        <div className={styles.tooltipRow}>
                          <div className={styles.tooltipRowValue}>{time}</div>
                        </div>
                        {payload?.map((value, i) => (
                          <div
                            key={i}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gridGap: 10,
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                color: payload[i]?.color,
                              }}
                            >
                              {payload[i]?.name}:
                            </div>
                            <div>{payload[i]?.value}</div>
                          </div>
                        ))}
                      </div>
                    </RecursivelyReplaceCharacters>
                  )
                }

                return null
              }}
            />
            <Legend
              verticalAlign='top'
              align='left'
              wrapperStyle={{ left: 75, top: 10 }}
              iconType='circle'
            />
            <Bar dataKey='poAmount' fill='#0176BC' name='Purchase' />
            <Bar dataKey='sAmount' fill='#F3722C' name='Sales' />
          </BarChart>
        </ResponsiveContainer>
      </DashboardTableWrapper>
    </div>
  )
}
