import { Wrapper } from '../../../../../../../shared/components/wrapper/Wrapper'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import FormItem from 'antd/es/form/FormItem'
import { IViewMode } from '../../../../../models/IViewMode'
import {
  AFN_CURRENCY_CODE,
  IPurchaseOrderBankWallet,
  VENDOR_WALLET_TYPE_ENUM,
} from '../../../../../models/IPurchaseOrder'
import { positiveNumber, requiredValidator } from '../../../../../../../helpers/validation'
import { Form } from 'antd'
import { SyntheticEvent } from 'react'

interface IProps extends IViewMode {
  recalculateMoneyAmount: () => void
}

export const PurchaseOrderSummary = ({ view, recalculateMoneyAmount }: IProps) => {
  const form = Form.useFormInstance()
  const handleExchangeRateChange = (event: SyntheticEvent) => {
    const { purchaseOrderBankWallets = [] } = form.getFieldsValue()
    const value = (event.target as HTMLInputElement).value as string | 0

    const updatedBankWallets = (purchaseOrderBankWallets as Array<IPurchaseOrderBankWallet>).map(
      (bank) => {
        const updatedBank = {
          ...bank,
          mainCurrencyAmount: +(+bank?.currencyAmount * Number(value)).toFixed(2),
        }
        return {
          ...updatedBank,
          amount: (updatedBank.mainCurrencyAmount * +updatedBank?.unitPrice).toFixed(2),
        }
      }
    )

    form.setFieldsValue({
      purchaseOrderBankWallets: updatedBankWallets,
    })
    recalculateMoneyAmount()
  }
  return (
    <Wrapper title='Purchase Order Summary'>
      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const isEditable =
            !getFieldValue(['currency', 'id']) ||
            getFieldValue(['currency', 'id']) === AFN_CURRENCY_CODE

          const isEPinType = getFieldValue('vendorWalletType') === VENDOR_WALLET_TYPE_ENUM.E_PIN

          return (
            <FormItem
              name='exchangeRate'
              label='Exchange Rate'
              rules={
                !isEditable && !isEPinType
                  ? [
                      { required: true, validator: requiredValidator('Exchange Rate') },
                      { validator: positiveNumber('Exchange Rate') },
                    ]
                  : []
              }
            >
              <TextField
                disabled={view || isEditable || isEPinType}
                type='number'
                onChange={handleExchangeRateChange}
              />
            </FormItem>
          )
        }}
      </FormItem>

      <FormItem name='amount' label='eMoney Amount'>
        <TextField disabled />
      </FormItem>

      <FormItem name='currencyAmount' label='Cash Amount' className='noBottomMargin'>
        <TextField disabled />
      </FormItem>
    </Wrapper>
  )
}
