import React, { memo, useMemo } from 'react'
import { Wrapper } from '../../../../../../shared/components/wrapper/Wrapper'
import { DatePicker } from 'antd'
import { DatePickerIcon } from '../../../../../../assets/svg'
import { PieChart } from '../../../../../../shared/components/pieChart/pieChart'
import styles from '../../styles.module.scss'
import {
  IDashboardAgentType,
  IDashboardWalletType,
  SalesDashboardWidgetStateType,
} from '../../index'
import { NotFoundPage } from '../../../../../NotFoundPage'
import {
  PIE_CHART_COLORS,
  PIE_CHART_COLORS_WITH_TWO_ITEMS,
} from '../../../../../../helpers/contants'
import { DashboardTableWrapper } from '../../../../../Dashboard/pages/Overview/Components/DashboardTableWrapper'
import { RangePicker } from '../../../../../../shared/components/datePicker'
import { Moment } from 'moment/moment'
import { omit } from 'lodash'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'

interface IProps {
  widgetData: IDashboardAgentType[]
  onWidgetDateChange?: (value: Moment[] | null) => void
}

export const AgentTypeChart = ({ widgetData, onWidgetDateChange }: IProps) => {
  const mappedData = useMemo(
    () =>
      widgetData?.map((agentType) => ({
        name: agentType?.agentType,
        value: Math.abs(agentType?.salesAmount),
        label: agentType?.salesAmount,
        info: omit(agentType, 'agentType'),
      })),
    [widgetData]
  )

  const colors = useMemo(
    () => (mappedData?.length <= 2 ? PIE_CHART_COLORS_WITH_TWO_ITEMS : PIE_CHART_COLORS),
    [mappedData?.length]
  )

  return (
    <DashboardTableWrapper
      title='Agent Type'
      filters={
        <RangePicker
          className={styles.delegationDatePicker}
          placeholder={['Date range', '']}
          onChange={(val) => onWidgetDateChange && onWidgetDateChange(val as Moment[])}
          suffixIcon={
            <div>
              <DatePickerIcon color='#0193EB' />
            </div>
          }
        />
      }
    >
      {mappedData?.length ? (
        <div className={styles.pieChartTypeDataContainer}>
          <div>
            {mappedData?.map((cData, i) => (
              <RecursivelyReplaceCharacters key={cData?.name}>
                <div className={styles.chartDataTitle}>
                  <div className={styles.chartDataDot} style={{ backgroundColor: colors[i] }} />
                  <div className={styles.chartDataName}>{cData?.name}</div>
                </div>
              </RecursivelyReplaceCharacters>
            ))}
          </div>
          <div>
            <PieChart
              data={mappedData}
              colors={colors}
              textCenterChart={mappedData?.reduce((a, v) => a + v.value, 0)}
            />
          </div>
        </div>
      ) : (
        <NotFoundPage />
      )}
    </DashboardTableWrapper>
  )
}
