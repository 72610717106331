import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Form, Select, Select as AntSelect, Spin } from 'antd'
import FormItem from 'antd/es/form/FormItem'

import { selectTagRender } from '../../../../../../helpers/selectTagRender'

import styles from './styles.module.scss'
import React, { memo, useMemo, useState } from 'react'
import { useGetCurrencyRateHistoryQuery } from '../../../../core/http/ExchangeRatesApi'
import { RangePicker } from '../../../../../../shared/components/datePicker'
import { useGetListOfCurrenciesQuery } from '../../../../core/http/BankAccountApi'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { Moment } from 'moment'
import {
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  formatShamsiMomentToISO,
  moment,
  replaceCharactersWithAsterisks,
} from '../../../../../../helpers/utils'
import { isEmpty, omit } from 'lodash'
import { ICurrencyRate } from '../../../../models/ICurencyRate'
import { CURRENCIES_TO_COLOR_ENUM } from '../../../../models/ICurency'
import { NotFoundPage } from '../../../../../NotFoundPage'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import { SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IDataItem {
  date: number
  rate: number
  toCurrency: string
}

interface IMappedData {
  name: string
  color: string
  data: Array<IDataItem>
}

const ExchangeChart = () => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const [rateType] = useState<'sellingRate' | 'buyingRate'>('sellingRate')
  const [filterValues, setFilterValues] = useState<ISelectedFilters>({})
  const [form] = Form.useForm()

  const { data: currencyList, isFetching: isFetchingCurrencyList } = useGetListOfCurrenciesQuery()
  const { data, isFetching } = useGetCurrencyRateHistoryQuery({
    orderField: 'date',
    orderType: SortingOrderEnum.ASC,
    ...filterValues,
  })
  const { data: profile } = useGetProfileQuery()

  const onCurrencySelect = (currencyIn: Array<string>) => {
    if (currencyIn?.length === 1) {
      const prev = omit(filterValues, ['currencyIn'])
      return setFilterValues({ ...prev, currency: currencyIn })
    }

    const prev = omit(filterValues, ['currency'])
    return setFilterValues({ ...prev, currencyIn })
  }

  const onCurrencyToChange = (toCurrency: string) => {
    setFilterValues((prevState) => ({ ...prevState, toCurrency }))
  }

  const onDateChange = (value: Moment[] | null) => {
    if (!Array.isArray(value)) {
      const prevState = omit(filterValues, ['date_more', 'date_less'])
      return setFilterValues(prevState)
    }

    const date_more = !isEmpty(value[0])
      ? formatMomentDateToDayStart(
          formatShamsiMomentToISO(profile?.calendar, value[0])
        ).toISOString()
      : ''
    const date_less = !isEmpty(value[1])
      ? formatMomentDateToDayEnd(formatShamsiMomentToISO(profile?.calendar, value[1])).toISOString()
      : ''

    return date_less && date_more && setFilterValues({ date_less, date_more })
  }

  const mappedData = useMemo(() => {
    if (!data?.items) return []

    return data.items?.reduce((acc: IMappedData[], curr: ICurrencyRate) => {
      const { name, textCode } = curr?.currency
      const { date, toCurrency, coefficient } = curr
      const formattedDate = new Date(date).getTime()

      const existingCurrency = acc?.find((c) => c?.name === name)

      if (existingCurrency) {
        existingCurrency.data.push({
          toCurrency: toCurrency?.name,
          rate: Number((curr[rateType] / coefficient).toFixed(5)),
          date: formattedDate,
        })
      }
      if (!existingCurrency) {
        acc.push({
          name,
          color: CURRENCIES_TO_COLOR_ENUM[textCode as keyof typeof CURRENCIES_TO_COLOR_ENUM],
          data: [
            {
              date: formattedDate,
              rate: Number((curr[rateType] / coefficient).toFixed(5)),
              toCurrency: toCurrency?.name,
            },
          ],
        })
      }

      return acc
    }, [])
  }, [data?.items, rateType])

  const maxSellRate = useMemo(
    () =>
      Math.max(...(mappedData?.flatMap((item) => item?.data?.flatMap((i) => i?.rate)) || [10000])),
    [mappedData]
  )

  const isLoading = isFetching || isFetchingCurrencyList

  const formatXAxis = (tickItem: number) => {
    return moment(tickItem).format('DD MMM HH:mm')
  }

  const minDate = useMemo(
    () =>
      mappedData?.reduce((min, curr) => {
        const currMin = Math.min(...curr.data.map((d) => new Date(d.date).getTime()))
        return Math.min(min, currMin)
      }, Infinity),
    [mappedData]
  )

  const maxDate = useMemo(
    () =>
      mappedData?.reduce((max, curr) => {
        const currMax = Math.max(...curr.data.map((d) => new Date(d.date).getTime()))
        return Math.max(max, currMax)
      }, -Infinity),
    [mappedData]
  )

  // Calculate the number of days between the minimum and maximum dates
  const daysDiff = Math.floor((maxDate - minDate) / (1000 * 60 * 60 * 24))

  // Determine the tick count based on the number of days
  const tickCount = Math.min(daysDiff + 1, 10)

  const content = (
    <Wrapper title='Exchange Rate Summary'>
      <Form form={form} layout='vertical'>
        <div className={styles.dblRow}>
          <div className={styles.dblRow}>
            <FormItem name='currency' label='Currencies'>
              <AntSelect
                className={styles.fmItem}
                mode='multiple'
                placeholder='Select currencies to display'
                tagRender={selectTagRender}
                onChange={onCurrencySelect}
                maxTagCount='responsive'
                showArrow
                allowClear
              >
                {currencyList?.items?.map((currency) => (
                  <Select.Option key={currency?.id} value={currency?.name}>
                    {currency?.name}
                  </Select.Option>
                ))}
              </AntSelect>
            </FormItem>

            <span className={styles.toDivider}>to</span>

            <FormItem name='toCurrency' label='Currency'>
              <Select placeholder='To Currency' allowClear onChange={onCurrencyToChange}>
                {currencyList?.items?.map((currency) => (
                  <Select.Option key={currency?.id} value={currency?.name}>
                    {currency?.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </div>
          <div className={styles.dblRow}>
            <FormItem name='dateRange' label='Date Range'>
              <RangePicker
                placeholder={['Starting Date', 'End Date']}
                className={styles.delegationDatePicker}
                onChange={(value) => onDateChange(value as Moment[])}
              />
            </FormItem>
          </div>
        </div>
      </Form>
      <ResponsiveContainer width='100%' height={283}>
        {mappedData?.length ? (
          <LineChart height={283} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            {mappedData?.map((rate) => (
              <Line
                type='monotone'
                dataKey='rate'
                stroke={rate?.color}
                strokeWidth={2}
                data={rate?.data}
                name={rate?.name}
                key={rate?.name}
              />
            ))}
            <CartesianGrid stroke='#E6EFF8' strokeDasharray='3 3' />
            <XAxis
              dataKey='date'
              type='number'
              domain={[minDate || 0, maxDate || 0]}
              allowDuplicatedCategory={false}
              tickFormatter={formatXAxis}
              tickCount={tickCount}
            />
            <YAxis
              dataKey='rate'
              scale='log'
              domain={['auto', maxSellRate]}
              tickFormatter={(value) =>
                isPrivateMode
                  ? replaceCharactersWithAsterisks(
                      new Intl.NumberFormat('en-US', {
                        notation: 'compact',
                        compactDisplay: 'short',
                      }).format(value)
                    )
                  : new Intl.NumberFormat('en-US', {
                      notation: 'compact',
                      compactDisplay: 'short',
                    }).format(value)
              }
            />
            <Tooltip
              content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  return (
                    <RecursivelyReplaceCharacters>
                      <div className={styles.tooltip}>
                        <div>{formatXAxis(label)}</div>
                        {payload?.map((data, i) => (
                          <div key={i} className={styles.tooltipRow}>
                            <div
                              className={styles.tooltipDot}
                              style={{ backgroundColor: data.stroke }}
                            />
                            <div>
                              <span>
                                {`${data.name} to ${(data.payload as IDataItem)?.toCurrency}`}:
                              </span>
                              <span className={styles.tooltipRowValue}>{data.value}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </RecursivelyReplaceCharacters>
                  )
                }

                return null
              }}
            />
            {!isPrivateMode && <Legend />}
          </LineChart>
        ) : (
          <NotFoundPage />
        )}
      </ResponsiveContainer>
    </Wrapper>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}

export default memo(ExchangeChart)
