import { DetailInfo } from './DetailInfo'
import { useParams } from 'react-router-dom'
import { Col, Form, Row, Space, Spin, UploadFile } from 'antd'
import { useGetProfileQuery } from '../../../../../../../../Profile/core/http/Profile'
import { formatDateWithTime } from '../../../../../../../../../helpers/utils'
import moment from 'moment/moment'
import classNames from 'classnames'
import styles from '../../../../../styles.module.scss'
import { UploaderPhotos2 } from '../../../../../../../../../shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import { VendorMasterColorBlock } from '../../../../../Components/VendorMasterColorBlock'
import React, { useCallback, useEffect, useState } from 'react'
import { Button } from '../../../../../../../../../shared/components/button/Button'
import { useNavigate } from 'react-router'
import { SETTINGS_ENUM } from '../../../../../../../../../routes/settings'
import {
  useGetVendorByIdQuery,
  useUpdateVendorMutation,
} from '../../../../../../../core/http/VendorManagementApi'
import { isEmpty, omit } from 'lodash'
import { IVendor } from '../../../../../../../models/IVendorManagement'
import { RcFile } from 'antd/lib/upload'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../../../shared/api/errorHandler'

export const InformationTab = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const { id, mode, venId } = useParams<{ id: string; venId: string; mode: 'view' | 'edit' }>()

  const { data, isFetching, error, isError } = useGetVendorByIdQuery(id as string, { skip: !id })
  const [keyUploader, setKeyUploader] = useState(() => new Date().toString())

  const [updateVendor, upVenResp] = useUpdateVendorMutation()
  const { data: profile, isFetching: isFetchingProfile } = useGetProfileQuery()

  const afterSuccess = () => {
    return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${venId}/sim-card/view/${id}`)
  }

  useNotification(NOTIFICATION_TYPES.success, upVenResp.isSuccess, null, afterSuccess)
  useNotification(NOTIFICATION_TYPES.error, upVenResp.isError, upVenResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, isError, error as ErrorNode)

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const setDefaultValues = useCallback(() => {
    form.setFieldsValue({
      ...data,
      createdBy: `${profile?.firstName} ${profile?.lastName}`,
      createdAt: formatDateWithTime(data?.createdAt, profile?.calendar),
    })
  }, [data, form, profile])

  const onFinish = (formValues: IVendor) => {
    const fData = new FormData()

    const body = omit(formValues, 'operator' || 'logo')
    body.operatorId = formValues.operator?.id

    if (form.getFieldValue('logo')) {
      fData.append('logo', form.getFieldValue('logo') as RcFile)
    }

    fData.append('vendor', JSON.stringify(body))
    updateVendor({ id, body: fData })
  }

  const onCancelButtonClick = () => {
    if (isViewMode) {
      return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${venId}`)
    }
    if (isEditMode) {
      form.resetFields()
      setDefaultValues()
      setKeyUploader(new Date().toString())

      return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${venId}/sim-card/view/${id}`)
    }
  }

  const onSubmitButtonClick = () => {
    if (isViewMode) {
      return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${venId}/sim-card/edit/${id}`)
    }
    form.submit()
  }

  useEffect(() => {
    if (isEmpty(data) || isEmpty(profile)) return
    setDefaultValues()
  }, [data, profile, setDefaultValues])

  const isLoading = isFetching || isFetchingProfile

  const content = (
    <>
      <Form layout='vertical' form={form} className={styles.columnWrapper} onFinish={onFinish}>
        <div className={styles.leftColumn}>
          <DetailInfo view={isViewMode} />
        </div>
        <div className={classNames(styles.rightBlock, styles.withoutMargin)}>
          <UploaderPhotos2
            form={form}
            title='Vendor Logo'
            key={keyUploader}
            view={isViewMode}
            isProductPreview
            defaultPreviewImage={data?.logo}
            onChange={(photo: UploadFile[]) => {
              form.setFieldValue('logo', photo[0].originFileObj)
            }}
          />
          <VendorMasterColorBlock title='Vendor Color' view={isViewMode} />
        </div>
      </Form>
      <Row className={styles.simCardFooter} justify='end'>
        <Col>
          <Space size={10}>
            <div className='cancelButtonWrapper'>
              <Button color='blue' size='middle' onClick={onCancelButtonClick} block>
                Cancel
              </Button>
            </div>
            <Button
              htmlType='submit'
              size='middle'
              type='primary'
              onClick={onSubmitButtonClick}
              // disabled={isImageError || upVenResp.isLoading}
              block
            >
              {isViewMode ? 'Edit Vendor' : 'Save Changes'}
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
