import usePermissions from '../../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../../Settings/models/IPermissions'

const useLocalPermissions = () => {
  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()

  const [
    summaryId,
    agentStatementId,
    trustWalletId,
    bankAccountsId,
    vendorWalletsId,
    rewardWalletId,
    commissionWalletId,
    collectionWalletId,
    hawalaWalletId,
  ] = getModulesIdsByNames([
    IPermissionsNames['Summary'],
    IPermissionsNames['Agent Statement'],
    IPermissionsNames['Trust Wallet'],
    IPermissionsNames['Bank Accounts'],
    IPermissionsNames['Vendor Wallets'],
    IPermissionsNames['Reward Wallet'],
    IPermissionsNames['Commission Wallet'],
    IPermissionsNames['Collection Wallet'],
    IPermissionsNames['Hawala Wallet'],
  ])

  const demandPermissions = [IPermissionsActions.VIEW, IPermissionsActions.EXPORT]

  /* Summary */
  const [canViewSummary, canExportSummary] = canPerformAction(summaryId, demandPermissions)
  const [canViewAgentStatement, canExportAgentStatementId] = canPerformAction(
    agentStatementId,
    demandPermissions
  )
  const [canViewTrustWallet] = canPerformAction(trustWalletId, demandPermissions)
  const [canViewBankAccounts] = canPerformAction(bankAccountsId, demandPermissions)
  const [canViewVendorWallets] = canPerformAction(vendorWalletsId, demandPermissions)
  const [canViewRewardWallet] = canPerformAction(rewardWalletId, demandPermissions)
  const [canViewCollectionWallet] = canPerformAction(collectionWalletId, demandPermissions)
  const [canViewHawalaWallet] = canPerformAction(hawalaWalletId, demandPermissions)

  return {
    canViewSummary,
    canViewAgentStatement,
    canViewTrustWallet,
    canViewBankAccounts,
    canViewVendorWallets,
    canViewRewardWallet,
    canViewCollectionWallet,
    canViewHawalaWallet,
  }
}

export default useLocalPermissions
