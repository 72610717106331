import { IUser } from '../../../../../../../models/IUser'
import styles from '../../../RolesAndPermissions/RolesManagementNew/Components/RolesAndPermissionsManagementUsersList/styles.module.scss'
import emptyAvatarImage from '../../../../../../../../../assets/img/Avatar.png'
import { Button } from '../../../../../../../../../shared/components/button/Button'
import { IconButton } from '../../../../../../../../../shared/components/iconButton/IconButton'
import { ArrowRightIcon, BucketIcon } from '../../../../../../../../../assets/svg'
import React, { useContext } from 'react'
import { RIGHT_MODALS } from '../../../../../../../../../helpers/contants'
import { RightModalContext } from '../../../../../../../../Modals'
import { Space } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../../../../../../redux'
import {
  deleteUserById,
  selectUsersManagementState,
} from '../../../../../../../core/store/UsersManagerSlice'

interface IProps {
  users: Array<IUser>
  onPressSelect: (user: IUser) => void
  onDeleteUser: (id: number) => void
  positionId?: string
  onPositionCreate?: boolean
}

export const UserList = ({
  users,
  onPressSelect,
  positionId,
  onDeleteUser,
  onPositionCreate,
}: IProps) => {
  const dispatch = useAppDispatch()
  const { onOpen } = useContext(RightModalContext)
  const { usersList } = useAppSelector(selectUsersManagementState)

  const onCloseRedirect = () => {
    onOpen(RIGHT_MODALS.SETTINGS.POSITIONS_USERS_LIST, {
      title: 'Add Users',
    })
  }

  const onPressViewUser = (id: number) => {
    onOpen(RIGHT_MODALS.SETTINGS.USERS_MANAGEMENT_USER, {
      title: 'View Users',
      mode: 'view',
      id,
      onCloseRedirect,
    })
  }

  const handleAddUser = (user: IUser) => {
    if (usersList.includes(user)) {
      return dispatch(deleteUserById(user.id))
    }
    return onPressSelect(user)
  }

  return (
    <>
      {users.map((user) => {
        const isSelected = String(user?.userPosition?.id) === positionId
        const shouldBeChecked = usersList.some((savedUser) => savedUser.id === user.id)

        return (
          <div className={styles.user} key={user.id}>
            <div className={styles.userContent}>
              <div className={styles.userAvatar}>
                <img alt='product logo' src={user.logo ?? emptyAvatarImage}></img>
              </div>
              <div className={styles.userDescriptions}>
                <div className={styles.userName}>{`${user.firstName ?? ''} ${
                  user.lastName ?? ''
                }`}</div>
                <div className={`${styles.userDepartmentAndPosition} ${styles.userPositionName}`}>
                  {user.userPosition?.name}
                </div>
              </div>
            </div>
            <Space size={10}>
              {onPositionCreate ? (
                <div style={{ width: 35 }}>
                  <IconButton
                    block
                    size='large'
                    color='orange'
                    icon={<BucketIcon />}
                    type='default'
                    onClick={(e) => {
                      e.stopPropagation()
                      onDeleteUser(user.id)
                    }}
                  />
                </div>
              ) : (
                <>
                  {!isSelected && (
                    <div>
                      <Button
                        color='orange'
                        type={shouldBeChecked ? 'primary' : 'default'}
                        onClick={() => handleAddUser(user)}
                      >
                        Select
                      </Button>
                    </div>
                  )}
                  <div>
                    <IconButton
                      color='blue'
                      icon={<ArrowRightIcon />}
                      onClick={() => onPressViewUser(user.id)}
                    />
                  </div>
                </>
              )}
            </Space>
          </div>
        )
      })}
    </>
  )
}
