import { DISCOUNT_POLICY_PRODUCT_TYPE_ENUM } from '../../Settings/models/IDiscountPolicy'
import { IByUser, IUserAvatar } from '../../../shared/models/IByUser'
import { ILocation } from '../../../shared/models/ILocation'
import { TRANSACTION_CATEGORY_ENUM } from '../../Finance/models/ITransaction'

interface IDiscountPolicy {
  masterProductType: DISCOUNT_POLICY_PRODUCT_TYPE_ENUM
  name: string
  minRate: number
}

export interface IAgentAll {
  id: number
  firstName: string
  lastName: string
  selfRegistration: boolean
  fatherName: string
  avatar: string
  parentId: number
  commissionPolicyId: number
  status: boolean
  gender: string
  addressLine1: string
  addressLine2: string
  isDeleted: boolean
  childCommissionId: number
  discountPolicyId: string
  discountPolicies: Array<IDiscountPolicy>
  createdAt: string
  calendar: string
  country: IObjInfo
  district: IObjInfo
  province: IObjInfo
  language: IObjInfo
  agentType: IObjInfo
  agentLevel: IAgentLevel
  accountType: IAccountType
  agentAccessOption: IAgentAccessOption
  contactInfo: IContactInfoResponse[]
  agentIdentifiers: IAgentIdentifiersGetRequestView[]
  resellerCredentials: {
    ips: string[]
    email: string
    value?: string
    isVerified: boolean
    isOtpSent: boolean
    isWhitelist: boolean
    username?: string
    password?: string
  }
  password?: string
  balance: number
  companyTitle?: string
  wallet: {
    amount: number
    currencyAmount: number
    escrowAmount: number
    escrowCurrencyAmount: number
  } | null
  apiKey: {
    id: string
    secret: string
  }
  mPinActivation: boolean
  createdByUser: IByUser
  updatedAt: string
}

export interface IAgentAllGetResponse extends IAgentAll {
  agentIdentifiers: IAgentIdentifiersGetRequestView[]
}

export interface ISendVerification {
  code: string
  resettableAt: string
  timeoutAt: string
}

export interface ISendVerificationBody {
  type: string
  value: string
}

export interface IVerifyPhoneBody {
  code: string
  type: string
  value: string
}

export interface IContactInfoResponse {
  active: boolean
  value: string | null
  type: string | null
  isOtpSent?: boolean
  isVerified?: boolean
}

export type SetStateType<T> = React.Dispatch<React.SetStateAction<T>>

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>> Start Contact info

export enum CONTACT_TYPE_ENUM {
  MOBILE = 'MOBILE',
  EMAIL = 'EMAIL',
  WHATSAPP = 'WHATSAPP',
  SKYPE = 'SKYPE',
  FACEBOOK = 'FACEBOOK',
  TELEGRAM = 'TELEGRAM',
  VIBER = 'VIBER',
}

export enum CONTACT_VALUE_FOR_DISPLAYED_BY_TYPE_ENUM {
  MOBILE = 'Phone number',
  EMAIL_IDENTITY = 'Email Identity',
  EMAIL = 'Email',
  WHATSAPP = 'Whatsapp',
  SKYPE = 'Skype',
  FACEBOOK = 'Facebook',
  TELEGRAM = 'Telegram',
  VIBER = 'Viber',
}

export const CONTACT_TYPE_NEW = {
  MOBILE: {
    type: 'MOBILE',
    name: 'Phone number',
  },
  EMAIL_IDENTITY: {
    type: 'EMAIL_IDENTITY',
    name: 'Email Identity',
  },
  EMAIL: {
    type: 'EMAIL',
    name: 'Email',
  },
  WHATSAPP: {
    type: 'WHATSAPP',
    name: 'Whatsapp',
  },
  SKYPE: {
    type: 'SKYPE',
    name: 'Skype',
  },
  FACEBOOK: {
    type: 'FACEBOOK',
    name: 'Facebook ID',
  },
  TELEGRAM: {
    type: 'TELEGRAM',
    name: 'Telegram',
  },
  VIBER: {
    type: 'VIBER',
    name: 'Viber',
  },
}

export interface IContactInfo extends IContactInfoResponse {
  createId: number
}

export interface IContactInfoUpdate extends IContactInfoResponse {
  id?: number
}

export interface IContactInfoUpdateResponse {
  items: IContactInfoUpdate[]
}

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>> End Contact info

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>> Start Identifiers

export interface IAgentIdentifiersPostRequest {
  agentIdentifierTypeId: number | null
  agentIdentifierType?: string
  identifier: string | null
  active: boolean
}

export interface IAgentIdentifiers2 extends IAgentIdentifiersPostRequest {
  createId: number
}

export interface IAgentCreate extends IAgentAll {
  isDeleteLogo?: boolean
}

export enum IDENTIFIERS_TYPE_ENUM {
  MOBILE = 1,
  WHATSAPP = 2,
  FACEBOOK = 3,
  TELEGRAM = 4,
  VIBER = 5,
  EMAIL = 6,
}

export const IDENTIFIERS_VALUE_FOR_DISPLAYED_BY_TYPE = {
  1: 'Phone number',
  2: 'Whatsapp',
  3: 'Facebook',
  4: 'Telegram',
  5: 'Viber',
  6: 'Email',
}

export const IDENTIFIERS_TYPE_NEW = {
  MOBILE: {
    type: 'MOBILE',
    name: 'Phone number',
  },
  WHATSAPP: {
    type: 'WHATSAPP',
    name: 'Whatsapp',
  },
  FACEBOOK: {
    type: 'FACEBOOK',
    name: 'Facebook ID',
  },
  TELEGRAM: {
    type: 'TELEGRAM',
    name: 'Telegram',
  },
  VIBER: {
    type: 'VIBER',
    name: 'Viber',
  },
  EMAIL: {
    type: 'EMAIL',
    name: 'Email',
  },
}
export interface IAgentIdentifiersPutRequest {
  id: string | number
  agentIdentifierTypeId: number | null
  agentIdentifierType?: string
  identifier: string | null
  active: boolean
}

export interface IAgentIdentifiersPutRequestObj {
  toCreate?: IAgentIdentifiersPostRequest[]
  toUpdate?: IAgentIdentifiersPutRequest[]
  toDelete?: number[]
}

export interface IAgentIdentifiersPut extends IAgentIdentifiersPutRequest {
  createId: number
  status?: string
}

export interface IAgentIdentifiersGetRequestView {
  id: string | number
  active: boolean
  type: string
  value: string
  isOtpSent: boolean
  isVerified: boolean
}

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>> End Identifiers

interface IAgentAccessOption {
  id: number
  mPin: string
  hasPassword?: boolean
}

interface IAccountType {
  id: number
  status: number
  accountType: string
}

export interface IObjInfo {
  id: number
  name: string
  [key: string]: string | number | boolean
}

interface IAgentLevel extends IObjInfo {
  level: number
}

export interface IAgentTypeListResponse {
  items: IObjInfo[]
}

export interface IAgentLevelListResponse {
  items: IAgentLevel[]
}

interface IAgentLevel extends IObjInfo {
  level: number
  parentId: number
}

export interface IAgentLanguagesListResponse {
  items: IObjInfo[]
}

export interface ICountryListResponse {
  items: IObjInfo[]
}

export interface IProvinceListResponse {
  items: IObjInfo[]
}

export interface IDistrictListResponse {
  items: IObjInfo[]
}

export interface IIdentifiersListResponse {
  items: IIdentifier[]
}

interface IIdentifier extends IObjInfo {
  active: boolean
  identifierType: string
}

export interface IUpdateAgentMpin {
  id: number
  newmPin: string
  oldmPin: string
}

interface ITopAgentAgent extends IUserAvatar {
  agentType: Omit<IByUser, 'logo'>
}

export interface ITopAgent {
  agent: ITopAgentAgent
  amount: number
  country: ILocation
  province: ILocation
  district: ILocation
  plAmount: number
}

export interface IAgentActivityData {
  count: number
  transactionCategory: TRANSACTION_CATEGORY_ENUM
}

export interface IAgentActivity {
  count: number
  data: Array<IAgentActivityData>
  period: string
}

export interface IAgentBankAccountInfo {
  id: number
  bankId: number
  bankBranchId: number
  agentId: number
  name: string
  number: string
  currency: string
  mobileWalletProvider: string
  mobileWalletNumber: string
}
