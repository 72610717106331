import React, { memo, useMemo } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import styles from '../../../styles.module.scss'
import { RangePicker } from 'src/shared/components/datePicker'
import { DashboardTableWrapper } from '../../../../../../Dashboard/pages/Overview/Components/DashboardTableWrapper'
import { Moment } from 'moment'
import { IPNLSummary } from '../../../../../models/IBankWalletTransactions'
import {
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  formatShamsiMomentToISO,
  getFormCalendarTime,
  moment,
  replaceCharactersWithAsterisks,
} from '../../../../../../../helpers/utils'
import { useGetProfileQuery } from '../../../../../../Profile/core/http/Profile'
import { RecursivelyReplaceCharacters } from '../../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IProps {
  widgetData: IPNLSummary[]
  frequency: string
  onWidgetDateChange?: (value: Moment[] | null) => void
}

const PLSummaryChart = ({ widgetData, frequency, onWidgetDateChange }: IProps) => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const { data: profile } = useGetProfileQuery()
  const mappedData = useMemo(() => {
    const result: Array<{ name: string; pickup: number; purchase: number; profit: number }> = []
    const hours = Array(25)
      .fill(undefined)
      .map((_, index) => index)

    widgetData?.forEach((entry, index) => {
      const period = moment(entry?.period)
      let name: string

      if (frequency === 'hour') {
        name = period.hour(hours[index]).format('HH')
      } else if (frequency === 'day') {
        name = period.format('DD MMM')
      } else {
        name = period.format('MMM')
      }

      const transformedEntry = {
        name,
        pickup: entry.prAmount,
        purchase: entry.poAmount,
        profit: entry.plAmount,
      }

      result.push(transformedEntry)
    })

    return result
  }, [frequency, widgetData])

  // TODO: TEMPORARY FIX TS ERROR WITH TS-IGNORE
  return (
    <DashboardTableWrapper
      title='P&L Summary'
      filters={
        <RangePicker
          className={styles.delegationDatePicker}
          placeholder={['Date range', '']}
          onChange={(val) => onWidgetDateChange && onWidgetDateChange(val as Moment[])}
          // @ts-ignore
          defaultValue={
            getFormCalendarTime(profile?.calendar, [
              formatMomentDateToDayStart(
                formatShamsiMomentToISO(profile?.calendar, moment().startOf('month'))
              ).toISOString(true) as string,
              formatMomentDateToDayEnd(
                formatShamsiMomentToISO(profile?.calendar, moment())
              ).toISOString(true) as string,
            ]) ?? undefined
          }
        />
      }
    >
      <ResponsiveContainer width='100%' height={280}>
        <BarChart
          width={500}
          height={350}
          data={mappedData}
          margin={{
            top: 30,
            right: 10,
            left: 10,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis
            tickFormatter={(value) =>
              isPrivateMode ? replaceCharactersWithAsterisks(value) : value
            }
          />
          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const time = payload[0]?.payload?.name

                return (
                  <RecursivelyReplaceCharacters>
                    <div className={styles.tooltipSummary}>
                      <div className={styles.tooltipRow}>
                        <div className={styles.tooltipRowValue}>{time}</div>
                      </div>
                      {payload?.map((value, i) => (
                        <div key={i} style={{ display: 'flex', alignItems: 'center', gridGap: 10 }}>
                          <div
                            style={{
                              color: payload[i]?.color,
                            }}
                          >
                            {payload[i]?.name}:
                          </div>
                          <div className={styles.tooltipValue}>{payload[i]?.value}</div>
                        </div>
                      ))}
                    </div>
                  </RecursivelyReplaceCharacters>
                )
              }
              return null
            }}
          />
          <Legend
            verticalAlign='top'
            align='left'
            wrapperStyle={{ left: 40, top: 10 }}
            iconType='circle'
          />
          <Bar dataKey='pickup' fill='#4FC3F7' name='Pickup' />
          <Bar dataKey='purchase' fill='#F3722C' name='Purchase' />
          <Bar dataKey='profit' fill='#81C784' name='Profit' />
        </BarChart>
      </ResponsiveContainer>
    </DashboardTableWrapper>
  )
}

export default memo(PLSummaryChart)
