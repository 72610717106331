import { FormInstance, Select as AntSelect } from 'antd'
import { useState } from 'react'
import FormItem from 'antd/es/form/FormItem'
import { DefaultOptionType } from 'antd/lib/select'
import { Select } from 'src/shared/components/select/select'
import TextArea from 'antd/es/input/TextArea'

// components
import { TextField } from 'src/shared/components/textfield/TextField'

// interfaces
import { IWalletBody, IWalletList } from 'src/features/Finance/models/IWallet'

// helpers
import { requiredValidator } from 'src/helpers/validation'

// styles
import styles from '../styles.module.scss'

const { Option } = AntSelect

interface IWalletForm {
  form: FormInstance<IWalletBody>
  walletList: IWalletList[] | undefined
}

export const WalletForm: React.FC<IWalletForm> = ({ form, walletList }) => {
  const [senderWallet, setSenderWallet] = useState<DefaultOptionType>()

  const handlerChangeWallet = (option: DefaultOptionType) => {
    setSenderWallet(option)
    form.setFieldsValue({ receiverWalletId: undefined })
  }

  const filteredReceiverList = walletList?.filter((i) =>
    senderWallet ? i.id !== senderWallet?.value : i
  )

  return (
    <div className={styles.container}>
      <div className={styles.headerDetailInfo}>
        <div className={styles.header}>Wallets info</div>
      </div>
      <div>
        <div className={styles.dblRow}>
          <FormItem
            name='senderWalletId'
            label='From Wallet'
            rules={[{ required: true, validator: requiredValidator('From Wallet') }]}
          >
            <Select onChange={handlerChangeWallet} allowClear placeholder='From Wallet'>
              {walletList?.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            name='receiverWalletId'
            label='To Wallet'
            rules={[{ required: true, validator: requiredValidator('To Wallet') }]}
          >
            <Select allowClear placeholder='To Wallet'>
              {filteredReceiverList?.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </FormItem>
        </div>

        <div className={styles.row}>
          <FormItem name='balance' label='Amount of money'>
            <TextField disabled placeholder='Amount of money' />
          </FormItem>
        </div>

        <div className={styles.row}>
          <FormItem
            name='amount'
            label='Amount to send'
            rules={[{ required: true, validator: requiredValidator('Amount to send') }]}
          >
            <TextField type='number' placeholder='Enter Amount to send' />
          </FormItem>
        </div>

        <div className={styles.row}>
          <FormItem name='description' label='Note'>
            <TextArea placeholder='Enter Note' />
          </FormItem>
        </div>
      </div>
    </div>
  )
}
