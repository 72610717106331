import { useAppDispatch } from '../../../../../../../../../redux'
import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { IExternalChatMessageFile } from '../../../../../../../models/IExternalChat'
import { IPopupListItems } from '../../../../../../../../../shared/components/popup/Popup'
import { DownloadIcon } from '../../../../../../../../../assets/svg'
import moment from 'moment'
import styles from '../../../../../../../../InternalChat/Components/Attachments/Conponents/FilesList/styles.module.scss'
import { InfiniteScrollComponent } from '../../../../../../../../../shared/components/infiniteScoll/InfiniteScroll'
import { NotFoundPage } from '../../../../../../../../NotFoundPage'
import { externalChatApi } from '../../../../../../../core/http/ExternalChatApi'
import { AttachmentFile } from './AttachmentFile'
import { downloadFileThroughATag } from '../../../../../../../../../helpers/utils'
import classNames from 'classnames'

interface IProps {
  files: Array<IExternalChatMessageFile>
  totalCount: number
  loadMoreData: () => void
}

export const FilesList = ({ files, totalCount, loadMoreData }: IProps) => {
  const dispatch = useAppDispatch()

  const onPressDownloadFile = useCallback(
    (id: number, fileName: string, callback?: Dispatch<SetStateAction<boolean | void>>) => {
      dispatch(externalChatApi.endpoints.getExternalChatLinkToDownloadFile.initiate(id))
        .then(({ data }) => {
          if (data?.link) {
            downloadFileThroughATag(data.link, fileName)
          }
        })
        .then(() => {
          if (callback) {
            callback()
          }
        })
    },
    [dispatch]
  )

  const tableActionsPopup = useCallback(
    (
      id: number,
      file: IExternalChatMessageFile['file'],
      callback?: Dispatch<SetStateAction<boolean | void>>
    ): IPopupListItems[] => {
      return [
        {
          text: 'Download',
          shouldDisplay: true,
          icon: <DownloadIcon />,
          onClick: () => onPressDownloadFile(id, file.name, callback),
        },
      ]
    },
    [onPressDownloadFile]
  )

  /** This condition is necessary for the design of notifications delimiter by date in the list */
  const isFirstNotificationInAMonth = useCallback(
    (createdAt: string, i: number) => {
      return (
        i === 0 ||
        (files[i + 1] && !moment(files[i + 1].createdAt).isSame(moment(createdAt), 'month'))
      )
    },
    [files]
  )

  return (
    <div
      className={classNames(styles.filesContainer, { [styles.emptyFileContainer]: !files.length })}
    >
      {files.length ? (
        <InfiniteScrollComponent
          loadMoreData={loadMoreData}
          dataLength={files.length}
          totalCount={totalCount}
          endMessage=''
          showDivider={false}
        >
          {files.map(({ file, createdAt, id }, i) => (
            <AttachmentFile
              key={file.id}
              fileId={id}
              file={file}
              tableActionsPopup={tableActionsPopup}
              isFirstNotificationInAMonth={isFirstNotificationInAMonth(createdAt, i)}
              createdAt={createdAt}
            />
          ))}
        </InfiniteScrollComponent>
      ) : (
        <NotFoundPage text='Empty data' />
      )}
    </div>
  )
}
