import React from 'react'
import { Select } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { TextField } from 'src/shared/components/textfield/TextField'
import { Checkbox } from 'src/shared/components/checkbox/Checkbox'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from 'src/assets/svg'
import styles from './styles.module.scss'
import FormList from 'antd/es/form/FormList'
import classNames from 'classnames'

const headerTypes = [
  { label: 'CONSTANT', value: 'constant' },
  { label: 'COMPUTED', value: 'computed' },
]

interface IProps {
  onAddNew: (header: IIntegrationFormHeader, key: string) => void
}

export interface IIntegrationFormHeader {
  key: string
  type: string
  value: string
  base_authorisation: boolean
}

const Headers = ({ onAddNew }: IProps) => {
  const addNewHeader = () => {
    onAddNew(
      {
        key: '',
        type: 'constant',
        value: '',
        base_authorisation: false,
      },
      'headers'
    )
  }

  const onChangeCheckbox = () => {}
  return (
    <Wrapper
      title='Headers'
      className={styles.generalInfoWrapper}
      actions={
        <IconButton type='default' color='orange' block icon={<AddIcon />} onClick={addNewHeader} />
      }
    >
      <FormList name='headers'>
        {(fields, { remove }) => (
          <>
            {fields.map(({ name, key }) => (
              <div className={classNames(styles.dblRow, styles.formBlockItem)} key={key}>
                <FormItem name={[name, 'key']} label='Key' rules={[{ required: true }]}>
                  <TextField placeholder='Enter header key' />
                </FormItem>
                <FormItem name={[name, 'type']} label='Type'>
                  <Select allowClear options={headerTypes} defaultValue='constant' />
                </FormItem>
                <FormItem name={[name, 'value']} label='Value' rules={[{ required: true }]}>
                  <TextField placeholder='Enter header value' />
                </FormItem>
                <FormItem name={[name, 'base_authorisation']}>
                  <Checkbox onChange={onChangeCheckbox} text='Base authorisation' />
                </FormItem>
                <IconButton
                  color='orange'
                  size='middle'
                  icon={<BucketIcon />}
                  onClick={() => remove(name)}
                />
              </div>
            ))}
          </>
        )}
      </FormList>
    </Wrapper>
  )
}

export default Headers
