import styles from '../Messaging/styles.module.scss'
import React, { useMemo } from 'react'
import {
  EXTERNAL_CHAT_FILE_TYPE_ENUM,
  IReplyToMessage,
} from '../../../../../../models/IExternalChat'
import FilesInMessage from '../../../../../../../InternalChat/Components/Messaging/Components/FilesInMessage'

interface IProps {
  replyToMessage: IReplyToMessage
  scrollToMessage: (m: IReplyToMessage) => void
}

export const ReplyTo = ({ replyToMessage, scrollToMessage }: IProps) => {
  const messageWithFile = useMemo(() => {
    if (replyToMessage?.externalChatMessageFiles?.length) {
      const file = replyToMessage.externalChatMessageFiles[0]?.file
      const fileType = replyToMessage.externalChatMessageFiles[0]?.fileType

      if (fileType === EXTERNAL_CHAT_FILE_TYPE_ENUM.PHOTO) {
        return (
          <>
            <img width={252} height={180} src={file?.link} style={{ objectFit: 'cover' }} />
          </>
        )
      }

      if (fileType === EXTERNAL_CHAT_FILE_TYPE_ENUM.VIDEO) {
        return (
          <>
            <video width={240} height={160} src={file?.link} />
          </>
        )
      }

      if (file?.fileFormat === '.ogg') {
        return 'Voice Message'
      }

      if (fileType === EXTERNAL_CHAT_FILE_TYPE_ENUM.FILE) {
        const otherFiles = replyToMessage?.externalChatMessageFiles?.filter(
          (item) =>
            !['photo', 'video'].includes(item.fileType.toLowerCase()) &&
            item?.file?.fileFormat !== '.ogg'
        )
        return !!otherFiles?.length && <FilesInMessage files={otherFiles} />
      }
    }
  }, [replyToMessage.externalChatMessageFiles])

  return (
    <div className={styles.repliedTo} onClick={() => scrollToMessage(replyToMessage)}>
      <span className={styles.repliedToTitle}>Replied to:</span> {replyToMessage?.message}
      {messageWithFile && <div>{messageWithFile}</div>}
    </div>
  )
}
