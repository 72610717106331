import { createApi } from '@reduxjs/toolkit/query/react'
import {
  ICountryListResponse,
  IDistrictListResponse,
  IProvinceListResponse,
} from 'src/features/salesNetwork/models/IAgent'
import { baseQueryWithReauth } from '../../../../shared/api'
import {
  IProfileContactPutRequestObj,
  IProfilePasswordRequest,
  IProfilePutRequest,
} from '../../models/IProfile'
import { IUserDelegation, IUserDetail } from '../../../Settings/models/IUser'

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'IProfile',
    'IAgentSalesNetworkResponse',
    'IAllTableResponse',
    'IFilterFields',
    'IAgentAllGetResponse',
  ],
  endpoints: (builder) => ({
    getCountryList: builder.query<ICountryListResponse, string>({
      query: () => `locations/countries`,
    }),

    getProvinceList: builder.query<IProvinceListResponse, string | number>({
      query: (id) => `locations/${id}/provinces`,
    }),

    getDistrictList: builder.query<IDistrictListResponse, string | number>({
      query: (id) => `locations/${id}/districts`,
    }),

    getProfile: builder.query<IUserDetail, void>({
      query: () => ({
        url: 'profile',
      }),
      providesTags: () => ['IProfile'],
    }),

    updateProfile: builder.mutation<IProfilePutRequest, FormData>({
      query: (body) => ({
        url: `profile`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['IProfile'],
    }),

    updateUserDelegation: builder.mutation<
      Array<IUserDelegation>,
      { delegations: Array<IUserDelegation> }
    >({
      query: (body) => ({
        url: `profile/delegations`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['IProfile'],
    }),

    updatePassword: builder.mutation<
      IProfilePutRequest,
      { id: string; body: IProfilePasswordRequest }
    >({
      query: ({ id, body }) => ({
        url: `users/${id}/reset-password`,
        method: 'PATCH',
        body,
      }),
    }),

    updateProfileContactInfo: builder.mutation<
      IProfilePutRequest,
      { id: string; body: IProfileContactPutRequestObj }
    >({
      query: ({ id, body }) => ({
        url: `users/${id}/contacts`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useUpdatePasswordMutation,
  useUpdateProfileContactInfoMutation,
  useUpdateUserDelegationMutation,
} = profileApi
