import { IByUser, IUserAvatar } from '../../../shared/models/IByUser'
import { TRANSACTION_STATUS_ENUM } from './ITransaction'
import { ILocation } from '../../../shared/models/ILocation'
import { ICurrency } from './ICurency'

export interface IReverseTransaction {
  id: number
  createdAt: Date
  agent: IUserAvatar
  createdByAgent: IUserAvatar
  createdByUser: IUserAvatar
  amount: number
  discountAmount: number
  penaltyRate: number
  penaltyAmount: number
  balanceBefore: number
  balanceAfter: number
  status: TRANSACTION_STATUS_ENUM
  receiver: string
  sender: string
  product: IByUser
  vendor: IByUser
}

export interface IRTAgent {
  id: string
  name: string
  avatar: string
  district: ILocation
  province: ILocation
  country: ILocation
}

export interface ITRWallet {
  id: number
  name: string
  walletType: string
}

export interface IReverseTransactionDetail {
  id: string
  topupId: string
  createdAt: Date
  agent: IRTAgent
  product: IByUser
  originalTopupAmount: number
  receiver: string
  vendorTransactionId: string
  vendorResponseMessage: string
  vendorAmount: number
  agentDiscount: number
  totalDiscount: number
  promotionAmount: number
  rewardAmount: number
  penaltyAmount: number
  amount: number
  status: string
  reason: string
  createdByAgent: IUserAvatar
  createdByUser: IByUser
  SubTransaction: string[]
  balanceBefore: number
  balanceAfter: number
}

export interface ITopup {
  id: number
  createdAt: Date
  amount: number
  status: TRANSACTION_STATUS_ENUM
  receiver: string
  vendor: IByUser
}

export interface ITopupDetail {
  id: number
  status: string
  note: string
  createdByAgent: IUserAvatar
  createdByUser: IByUser
  receiver: string
  createdAt: Date
  agent: IRTAgent
  product: IByUser
  masterProduct: IByUser
  vendor: IByUser
  currency: ICurrency
  exchangeRate: number
  bankAccount: IByUser
  paymentType: string
  currencyAmount: number
  amount: number
  discountRate: number
  fullDiscountAmount: number
  discountAmount: number
  mainCurrencyAmount: number
  discountPolicy: string
  balanceBefore: number
  balanceAfter: number
}

type KeyOfTransaction = 'agent' | 'agentWallet' | 'secondAgentWallet' | 'secondWallet' | 'wallet'

export interface ITopupSubTransaction {
  agent: IRTAgent
  agentWallet: ITRWallet
  amount: number
  balanceAfter: number
  balanceBefore: number
  bankAccount: string
  createdAt: string
  createdByUser: string
  currency: string
  currencyAmount: number
  discountAmount: number
  discountRate: number
  sender: KeyOfTransaction
  receiver: KeyOfTransaction
  drCr: string
  duration: number
  escrow: boolean
  exchangeRate: number
  id: number
  mainCurrency: string
  mainTransactionId: number
  product: string
  secondAgentWallet: ITRWallet
  secondWallet: ITRWallet
  serviceTypes: string
  status: string
  transactionCategory: string
  transactionRequestAccessChannel: string
  vendor: object
  wallet: ITRWallet
  walletType: string
}

export enum CREATE_REVERSE_TRANS_REASON {
  WRONG_RECEIVER = 'Wrong Receiver Number',
  WRONG_DENOMINATION = 'Wrong Denomination',
  WRONG_OPERATOR = 'Wrong Operator',
  AMOUNT = 'Amount not Received and Other',
}

export interface ICreateReverseTransBody {
  topupId: number
  reason: CREATE_REVERSE_TRANS_REASON
}

export interface IReverseTransApproveBody {
  vendorAmount: number
  vendorTransactionId?: string
  vendorResponseCode?: string
  vendorResponseMessage?: string
  receiverBalanceBefore?: number
  receiverBalanceAfter?: number
}

export type CreateReverseTransStepType = 'searchTopup' | 'createTopup' | 'afterCreateTopup'
