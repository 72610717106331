import { Wrapper } from '../../../../../../../../shared/components/wrapper/Wrapper'
import FormItem from 'antd/es/form/FormItem'
import { useGetVendorListQuery } from '../../../../../../../Settings/core/http/VendorManagementApi'
import { Select, Spin } from 'antd'
import { requiredValidator } from '../../../../../../../../helpers/validation'

export const VendorBlock = () => {
  const { data, isFetching } = useGetVendorListQuery({})

  const content = (
    <Wrapper title='Vendor'>
      <FormItem
        name='vendorId'
        label='Vendor'
        className='noBottomMargin'
        rules={[{ required: true, validator: requiredValidator('Vendor') }]}
      >
        <Select placeholder='Select Vendor' allowClear>
          {data?.items.map((vendor) => (
            <Select.Option key={vendor.id} value={vendor.id}>
              {vendor?.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    </Wrapper>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
