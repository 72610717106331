import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import FormItem from 'antd/es/form/FormItem'
import { maxNumber, positiveNumber, requiredValidator } from '../../../../../../helpers/validation'
import styles from '../style.module.scss'
import React from 'react'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import { TextField } from '../../../../../../shared/components/textfield/TextField'

interface IProps extends IViewMode {
  isDiscountRateDisabled: boolean
}

export const Pricing = ({ view: isDisabled, isDiscountRateDisabled }: IProps) => {
  return (
    <Wrapper title='Pricing'>
      <div className={styles.dblRow}>
        <FormItem
          name='unitCost'
          label='Unit Cost'
          className='noBottomMargin'
          rules={[
            {
              required: true,
              validator: requiredValidator('Unit Cost'),
            },
            {
              validator: positiveNumber('Unit Cost'),
            },
          ]}
        >
          <TextField type='number' disabled={isDisabled} placeholder='$' />
        </FormItem>

        <FormItem
          name='discountRate'
          label='Discount percentage'
          className='noBottomMargin'
          rules={
            !isDiscountRateDisabled
              ? [
                  {
                    required: true,
                    validator: requiredValidator('Discount percentage'),
                  },
                  {
                    validator: positiveNumber('Discount percentage'),
                  },
                  {
                    validator: maxNumber(100, 'Discount percentage'),
                  },
                ]
              : []
          }
        >
          <TextField
            type='number'
            disabled={isDiscountRateDisabled || isDisabled}
            placeholder='%'
          />
        </FormItem>
      </div>
    </Wrapper>
  )
}
