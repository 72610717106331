import React, { useEffect, useState } from 'react'

import TransactionLogHeader from './Components/TransactionLogHeader'
import { ISelectedFilters } from '../../../../shared/components/filter/Filter'
import { CrossIconBlue, TransactionLogIcon } from '../../../../assets/svg'
import { ITransactionLogAll } from '../../models/TransactionLog'
import styles from './styles.module.scss'
import { ITableConf } from 'src/shared/models/ITableConf'
import { useGetTransactionLogListQuery } from '../../core/http/RequestToVendor'
import classNames from 'classnames'
import {
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  formatShamsiMomentToISO,
  getFormCalendarTime,
} from 'src/helpers/utils'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import useSearch from 'src/features/Settings/helpers/useSearch'
import { Moment } from 'moment'
import { isEmpty, omit } from 'lodash'

export const isJSON = (str: string) => {
  try {
    if (JSON.parse(str) && str) {
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

const SupportTransactionLogs = () => {
  const [logs, setLogs] = useState<ITransactionLogAll[]>([])
  const [dateFilter, setDateFilter] = useState<ISelectedFilters>()
  const [tableConf, setTableConf] = useState<ITableConf>({})
  const [transaction, setTransaction] = useState<ITransactionLogAll | null>(null)
  const [isNeedClear, setNeedClear] = useState(false)

  const { handleSearchChange } = useSearch(setTableConf)
  const { data: profile } = useGetProfileQuery()

  const { data, isFetching } = useGetTransactionLogListQuery(
    {
      ...tableConf,
      ...dateFilter,
    },
    {
      skip: !tableConf.search,
    }
  )

  const onSearchChange = (value: string) => {
    handleSearchChange(value)
    if (!value) {
      setLogs([])
    }
  }

  const onSearchSelect: React.ChangeEventHandler<HTMLSelectElement> = (value) => {
    if (typeof value === 'string' || typeof value === 'undefined') {
      const transaction = data!.items.find((i) => i.id === value)
      setTransaction(transaction!)
    }
  }

  const onSearchClear = () => {
    setNeedClear(true)
    setTransaction(null)
  }

  const clearLogs = () => {
    setLogs([])
  }

  const getVenAgrResponse = (
    transaction: ITransactionLogAll = {} as ITransactionLogAll,
    isAminPay = false
  ) => {
    const { processedResponse } = transaction

    if (isJSON(processedResponse) && isAminPay) {
      return JSON.stringify(
        (JSON.parse(processedResponse) as { Data: Record<string, string> })?.Data,
        null,
        2
      )
    }

    if (isJSON(processedResponse) && !isAminPay) {
      return JSON.stringify(omit(JSON.parse(processedResponse), 'Data'), null, 2)
    }

    return 'null'
  }

  const onDateChange = (value: Moment[] | null) => {
    if (!Array.isArray(value)) {
      return setDateFilter({})
    }

    const createdAt_more = !isEmpty(value[0])
      ? formatMomentDateToDayStart(
          formatShamsiMomentToISO(profile?.calendar, value[0])
        ).toISOString()
      : ''
    const createdAt_less = !isEmpty(value[1])
      ? formatMomentDateToDayEnd(formatShamsiMomentToISO(profile?.calendar, value[1])).toISOString()
      : ''

    return createdAt_less && createdAt_more && setDateFilter({ createdAt_less, createdAt_more })
  }

  useEffect(() => {
    if (data?.items) {
      setLogs(data.items)
    }
  }, [data])

  return (
    <div className={styles.layout}>
      {/* @ts-ignore */}
      <TransactionLogHeader
        tableData={logs}
        isNeedClear={isNeedClear}
        onSearchChange={onSearchChange}
        onSearchSelect={onSearchSelect}
        onDateChange={onDateChange}
        setNeedClear={setNeedClear}
        clearLogs={clearLogs}
        loading={isFetching}
        {...(dateFilter?.createdAt_more &&
          dateFilter?.createdAt_less && {
            value: [
              getFormCalendarTime(profile?.calendar, dateFilter.createdAt_more as string),
              getFormCalendarTime(profile?.calendar, dateFilter.createdAt_less as string),
            ],
          })}
      />
      {transaction ? (
        <div className={styles.codeContainer}>
          <div className={styles.codeHeader}>
            <div>
              <div className={styles.codeTitle}>ID{transaction.topupId}</div>
              <div className={styles.codeTitle}>{transaction.vendorRequestType}</div>
              <div className={styles.codeTitle}>{transaction.vendor?.name}</div>
            </div>
            <div className={styles.crossContainer} onClick={onSearchClear}>
              <CrossIconBlue />
            </div>
          </div>
          <pre className={classNames(styles.mainCodeContainer, styles.codeTitle)}>
            <h3 className={styles.logHeading}>Request To Vendor/Aggregator</h3>
            <div className={classNames(styles.logBody, styles.withWrap)}>{transaction?.body}</div>

            <h3 className={styles.logHeading}>Vendor/Aggregator Response</h3>
            <div className={styles.logBody}>{getVenAgrResponse(transaction)}</div>

            <h3 className={styles.logHeading}>AminPay Response</h3>
            <div className={styles.logBody}>{getVenAgrResponse(transaction, true)}</div>
          </pre>
        </div>
      ) : (
        <div className={styles.logDataContainer}>
          <TransactionLogIcon />
          <div className={styles.transactionInstr}>
            To see the log, you need to select the required transaction.
          </div>
          <div className={styles.transactionInstrUse}>Use the search field.</div>
        </div>
      )}
    </div>
  )
}

export default SupportTransactionLogs
