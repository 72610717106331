import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { ICustomer } from '../../models/ICustomer'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../../shared/models/ITableConf'
import { ISegment, ISegmentTable } from '../../models/ISegment'

export const segmentsApi = createApi({
  reducerPath: 'segments',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['SegmentsList', 'ISegment'],
  endpoints: (builder) => ({
    getSegmentList: builder.query<ITableResponse<ISegmentTable>, ITableConf>({
      query: (params) => ({
        url: 'customer-target-groups',
        params,
      }),
      providesTags: () => ['SegmentsList'],
    }),

    getSegmentById: builder.query<ISegment, string | number>({
      query: (id) => ({
        url: `customer-target-groups/${id}`,
      }),
      providesTags: (result) => [{ type: 'ISegment', id: result?.id }],
    }),

    createSegment: builder.mutation<ICustomer, Partial<ISegment>>({
      query: (body) => ({
        url: `customer-target-groups`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['SegmentsList'],
    }),

    updateSegmentById: builder.mutation<ICustomer, { id: string; body: Partial<ISegment> }>({
      query: ({ id, body }) => ({
        url: `customer-target-groups/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result) => ['SegmentsList', { type: 'ISegment', id: result?.id }],
    }),

    deleteSegmentById: builder.mutation<void, number | string>({
      query: (id) => ({
        url: `customer-target-groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'ISegment', id: arg }, 'SegmentsList'],
    }),

    exportSegmentsTable: builder.query<
      BlobPart,
      {
        conf: IFileFormatCsvOrXlsx
        formatFile: 'csv' | 'xlsx'
      }
    >({
      query: ({ conf, formatFile }) => ({
        url: 'customer-target-groups/export',
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
})

export const {
  useGetSegmentByIdQuery,
  useCreateSegmentMutation,
  useDeleteSegmentByIdMutation,
  useUpdateSegmentByIdMutation,
  useLazyExportSegmentsTableQuery,
  useGetSegmentListQuery,
} = segmentsApi
