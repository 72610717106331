import { useCallback, useContext, useEffect, useState } from 'react'
import { RightModalContext } from '../../../../../../Modals'
import { useParams } from 'react-router'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../../shared/components/popup/Popup'
import { RIGHT_MODALS } from '../../../../../../../helpers/contants'
import { ColumnsType } from 'antd/es/table'
import { ITargetGroup } from '../../../../../models/ITargetGroup'
import { Toggle } from '../../../../../../../shared/components/toggle/Toggle'
import { TableActionIcon } from '../../../../../../../assets/svg'
import { ISelectedFilters } from '../../../../../../../shared/components/filter/Filter'
import { ITableConf } from '../../../../../../../shared/models/ITableConf'
import { useEmptyTable } from '../../../../../../../shared/hooks/table/useEmptyTable'
import { TableHeader } from '../../../DiscountPolicyView/Components/TableHeader'
import { Table } from 'antd'
import styles from '../../../styles.module.scss'
import {
  useGetPPTargetGroupsFilterListQuery,
  useGetPPTargetGroupsListQuery,
  useLazyExportPPTargetGroupsTableQuery,
  useUnassignTargetFromPromotionPolicyMutation,
} from '../../../../../core/http/PromotionPolicyApi'
import {
  targetGroupsApi,
  useUpdateTargetGroupMutation,
} from '../../../../../core/http/TargetGroupsApi'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { ErrorNode, showConfirmMessage } from '../../../../../../../shared/api/errorHandler'
import { IPromotionPolicy } from '../../../../../models/IPromotionPolicy'
import { useAppDispatch } from '../../../../../../../redux'
import { useTableExport } from '../../../../../../../shared/hooks/table/useTableExport'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'

export const TargetGroupTable = () => {
  const dispatch = useAppDispatch()
  const { onOpen } = useContext(RightModalContext)
  const { id } = useParams()
  const [upTG, upTGResp] = useUpdateTargetGroupMutation()
  const [unassignGroup, unassignGroupResp] = useUnassignTargetFromPromotionPolicyMutation()

  useNotification(NOTIFICATION_TYPES.success, upTGResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, unassignGroupResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, upTGResp.isError, upTGResp.error as ErrorNode)
  useNotification(
    NOTIFICATION_TYPES.error,
    unassignGroupResp.isError,
    unassignGroupResp.error as ErrorNode
  )

  const openNotification = useCallback(
    (groupId: number) => {
      const onConfirmClick = () => unassignGroup({ promotionId: id as string, groupId })
      showConfirmMessage(
        'This target group will be removed from the promotion policy',
        onConfirmClick
      )
    },
    [id, unassignGroup]
  )

  const tableActionsPopup = useCallback(
    (id: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () =>
            onOpen(RIGHT_MODALS.SETTINGS.NETWORK_CONFIGURATION_TARGET_GROUP, {
              title: 'View Target Group',
              mode: 'view',
              id,
            }),
        },
        {
          text: 'Edit',
          shouldDisplay: true,
          onClick: () =>
            onOpen(RIGHT_MODALS.SETTINGS.NETWORK_CONFIGURATION_TARGET_GROUP, {
              title: 'Edit Target Group',
              mode: 'edit',
              id,
            }),
        },
        {
          text: 'Delete',
          withDivider: true,
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          onClick: () => openNotification(id),
          shouldDisplay: true,
        },
      ]
    },
    [onOpen, openNotification]
  )

  const columns: ColumnsType<ITargetGroup> = [
    {
      title: 'Target Group Name',
      dataIndex: 'name',
      width: '20%',
      sorter: true,
    },
    {
      title: 'Agent Type',
      dataIndex: 'agentType',
      width: '20%',
    },
    {
      title: 'Agent Level',
      dataIndex: 'agentLevel',
      width: '20%',
      render: (level: ITargetGroup['agentLevel']) => level?.level,
    },
    {
      title: 'Wallet Type',
      dataIndex: 'paymentPolicyType',
      width: '15%',
    },
    {
      title: 'Group Size',
      dataIndex: 'groupSize',
      width: '15%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      sorter: true,
      render: (status: IPromotionPolicy['status'], { id }) => {
        return (
          <Toggle
            defaultChecked={status}
            loading={upTGResp.isLoading}
            onChange={(status) => {
              upTG({ id, body: { status } })
            }}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const { queryParams, queryFields } = useGetQueryParams()
  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data, isFetching } = useGetPPTargetGroupsListQuery({
    ...filterValues,
    ...tableConf,
    id: id as string,
  })
  const { data: filterList } = useGetPPTargetGroupsFilterListQuery(id as string)
  const [exportTable] = useLazyExportPPTargetGroupsTableQuery()

  useTableExport({
    tableConf,
    filterValues,
    promise: exportTable,
    promiseParams: { id: id as string },
  })
  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<ITargetGroup>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  useEffect(() => {
    if (upTGResp.isSuccess) {
      dispatch(targetGroupsApi.util.invalidateTags(['TargetGroups']))
    }
  }, [dispatch, upTGResp.isSuccess])

  useEffect(() => {
    if (unassignGroupResp.isSuccess) {
      dispatch(targetGroupsApi.util.invalidateTags(['TargetGroups']))
    }
  }, [dispatch, unassignGroupResp.isSuccess])

  return (
    <>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        data={filterList}
        filterValues={filterValues}
        numberOfItems={data?.totalCount}
      />
      <Table
        locale={emptyTableConf}
        onChange={handleTableChange}
        pagination={pagination}
        columns={columns}
        className={styles.table}
        scroll={{ x: 1000 }}
        rowKey={(record) => record.id}
        rowSelection={{ type: 'checkbox' }}
        dataSource={data?.items}
        loading={isFetching}
      />
    </>
  )
}
