import React, {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  memo,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import styles from './styles.module.scss'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { MentionsInput, Mention } from 'react-mentions'
import { internalChatsApi } from '../../../../../../core/http/InternalChatsApi'
import { useAppDispatch } from '../../../../../../../../redux'
import { ISearchTransaction, ITaggingUsers } from '../../../../../../models'
import { isArabic } from '../../../../../../../../helpers/utils'
import classNames from 'classnames'

const suggestionsStyle = {
  highlighter: {
    display: 'none',
  },
  suggestions: {
    bottom: 0,
    top: 'unset',
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(213, 214, 223, 1)',
      fontSize: 16,
      maxHeight: 400,
      overflow: 'scroll',
    },
    item: {
      padding: '4px 16px',
      width: 200,
      fontSize: 14,
      color: '#707784',
      '&focused': {
        backgroundColor: '#cce9fb',
      },
    },
  },
}

interface IProps {
  message: string
  onSendMessage: () => void
  onChangeMessage: (e: ChangeEvent<HTMLInputElement>) => void
  isLoading: boolean
  setMessage: Dispatch<SetStateAction<string>>
}

interface ISuggestionTag extends ITaggingUsers {
  display: string
}

interface ISuggestionTransaction extends ISearchTransaction {
  display: string
}

const SubmitInput = ({
  message,
  onSendMessage,
  onChangeMessage,
  isLoading,
  setMessage,
}: IProps) => {
  const [arabic, setArabic] = useState(false)
  const dispatch = useAppDispatch()
  const inputRef = useRef<HTMLDivElement | null>(null)

  const fetchUsersToTags = (query: string, callback: () => void) => {
    dispatch(
      internalChatsApi.endpoints.getInternalChatTaggingUsers.initiate({
        page: 1,
        limit: 25,
        search: query,
      })
    )
      .then(({ data }) => {
        return data?.items.map((el) => ({ ...el, display: `${el?.firstName} ${el?.lastName}` }))
      })
      .then(callback)
  }

  const fetchTransactions = (query: string, callback: () => void) => {
    dispatch(
      internalChatsApi.endpoints.getInternalChatTransactions.initiate({
        page: 1,
        limit: 25,
        search: query,
        entityType: 'transactions',
      })
    )
      .then(({ data }) => {
        return data?.items.map((el) => ({ ...el, display: `${el?.name}` }))
      })
      .then(callback)
  }

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.metaKey && !e.shiftKey) {
      e.preventDefault()
      onSendMessage()
      if (inputRef.current) {
        setTimeout(() => {
          inputRef.current?.focus()
        }, 500)
      }
    }

    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault()
      setMessage(message + '\n')
    }
  }

  const renderSuggestionTag = (suggestion: ISuggestionTag) => {
    return <div>{suggestion.display}</div>
  }

  const renderSuggestionTransaction = (suggestion: ISuggestionTransaction) => {
    return <div>{suggestion.id}</div>
  }

  const handleMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isArabic(e.target.value)) {
      setArabic(true)
      return onChangeMessage(e)
    }
    setArabic(false)
    onChangeMessage(e)
  }

  /** Input autofocus */
  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    const textarea = document.querySelector('#internal-chat-input-field') as HTMLTextAreaElement
    const wrapper = document.querySelector(
      '#int-chat-add-message-input-container'
    ) as HTMLDivElement

    if (!message.length) {
      textarea.style.height = '24px'
      wrapper.style.height = `48px`
    }

    const adjustTextareaHeight = () => {
      textarea.style.height = '24px'
      textarea.style.height = `${textarea.scrollHeight}px`
      wrapper.style.height = `48px`
      wrapper.style.height = `${textarea.scrollHeight + 14}px`
    }

    const onInput = () => {
      adjustTextareaHeight()
    }

    // Fix for the paste event to work from the first time
    const onPaste = () => {
      setTimeout(adjustTextareaHeight, 0)
    }

    textarea.addEventListener('input', onInput)
    textarea.addEventListener('paste', onPaste)

    return () => {
      textarea.removeEventListener('input', onInput)
      textarea.removeEventListener('paste', onInput)
    }
  }, [message.length])

  return (
    <>
      <MentionsInput
        inputRef={inputRef}
        disabled={isLoading}
        onKeyDown={onKeyDown}
        className={classNames(styles.addMessageToolsInput, { [styles.arabic]: arabic })}
        id='internal-chat-input-field'
        style={suggestionsStyle}
        value={message}
        onChange={handleMessageChange}
        placeholder='Write a message...'
        allowSuggestionsAboveCursor
      >
        <Mention
          trigger='@'
          markup='::{"id": "__id__", "name": "__display__"}::'
          displayTransform={(id: number, display: string) => `@${display}`}
          data={fetchUsersToTags}
          renderSuggestion={renderSuggestionTag}
        />
        <Mention
          trigger='#'
          markup='::{"id": "__id__", "transaction": "__display__"}::'
          displayTransform={(id: number) => `#${id}`}
          data={fetchTransactions}
          renderSuggestion={renderSuggestionTransaction}
        />
      </MentionsInput>
    </>
  )
}

export default memo(SubmitInput)
