import { AminPayLogoIcon, AminPayText } from '../../../assets/svg'
import styles from './logo.module.scss'
import { Space } from 'antd'

interface IProps {
  withText?: boolean
}

export const Logo = ({ withText = false }: IProps): JSX.Element => {
  return (
    <div className={styles.logo}>
      <div className={styles.logoAnimatedIcon}>
        <AminPayLogoIcon />
      </div>
      <Space size={16} align='end' className={styles.logoWithText}>
        <AminPayLogoIcon />
        {withText && <AminPayText />}
      </Space>
    </div>
  )
}
