import { useGetMasterProductsListQuery } from '../../../../../core/http/VendorManagementApi'
import FormList from 'antd/es/form/FormList'
import { ITableResponse } from '../../../../../../../shared/models/ITableResponse'
import { IMasterProduct } from '../../../../../models/IMasterProduct'
import styles from '../../../styles.module.scss'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon } from '../../../../../../../assets/svg'
import React from 'react'
import { MasterProductListItem } from './MasterProductListItem'
import FormItem from 'antd/es/form/FormItem'
import { AGENT_TYPE_ID_ENUM } from '../../../../../models/IAgentType'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'

interface IProps extends IViewMode {
  onCreatePage?: boolean
}

export const MasterProducts = ({ onCreatePage, view }: IProps) => {
  const { data } = useGetMasterProductsListQuery({ hasEMoney: true })

  return (
    <FormList name='discountPolicyProducts'>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ name, key }) => (
            <MasterProductListItem
              key={key}
              name={name}
              data={data as ITableResponse<IMasterProduct>}
              remove={remove}
              onCreatePage={onCreatePage}
              view={view}
            />
          ))}
          <FormItem noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const isIndirect = getFieldValue(['agentType', 'id']) === AGENT_TYPE_ID_ENUM.INDIRECT

              return (
                <div className={styles.addButton}>
                  <IconButton
                    icon={<AddIcon />}
                    disabled={
                      view ||
                      !getFieldValue(['agentType', 'id']) ||
                      !getFieldValue(['masterProductType'])
                    }
                    color='orange'
                    block
                    onClick={() => {
                      const newItem = {
                        masterProduct: { id: null },
                        levelRates: Array.from({ length: isIndirect ? 5 : 1 }, (_, index) => ({
                          level: { id: index + 1 },
                          rate: null,
                        })),
                      }
                      add(newItem)
                    }}
                  />
                </div>
              )
            }}
          </FormItem>
        </>
      )}
    </FormList>
  )
}
