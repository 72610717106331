import { DashboardTableWrapper } from 'src/features/Dashboard/pages/Overview/Components/DashboardTableWrapper'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { ITotalPickup } from '../../../../models/IPickupRequest'
import styles from '../../../../../Dashboard/pages/Overview/styles.module.scss'
import { NotFoundPage } from '../../../../../NotFoundPage'
import React, { useMemo } from 'react'
import { moment, replaceCharactersWithAsterisks } from '../../../../../../helpers/utils'
import { RangePicker } from '../../../../../../shared/components/datePicker'
import { Moment } from 'moment/moment'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IProps {
  widgetData: ITotalPickup[]
  frequency: string
  onWidgetDateChange?: (value: Moment[] | null) => void
}

export const PickupSummaryChart = ({ widgetData, frequency, onWidgetDateChange }: IProps) => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const mappedData = useMemo(() => {
    const result: Array<{ name: string; amount: number }> = []
    const hours = Array(25)
      .fill(undefined)
      .map((_, index) => index)

    widgetData?.forEach((entry, index) => {
      const period = moment(entry?.period)
      let name: string

      if (frequency === 'hour') {
        name = period.hour(hours[index]).format('HH')
      } else if (frequency === 'day') {
        name = period.format('DD MMM')
      } else {
        name = period.format('MMM')
      }

      const transformedEntry = {
        name,
        amount: entry?.amount,
      }

      result.push(transformedEntry)
    })

    return result
  }, [frequency, widgetData])

  const filters = onWidgetDateChange && (
    <RangePicker
      className={styles.delegationDatePicker}
      placeholder={['Date range', '']}
      onChange={(val) => onWidgetDateChange(val as Moment[])}
    />
  )

  return (
    <DashboardTableWrapper
      title='Pickup Summary'
      infoText='Pickup Summary Bar Chart'
      withPadding={false}
      filters={filters}
    >
      {mappedData?.length ? (
        <ResponsiveContainer width='100%' height={260}>
          <BarChart
            width={900}
            height={300}
            data={mappedData}
            margin={{
              top: 30,
              right: 10,
              left: 10,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis
              tickFormatter={(value) =>
                isPrivateMode ? replaceCharactersWithAsterisks(value) : value
              }
            />
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const time = payload[0]?.payload?.name

                  return (
                    <RecursivelyReplaceCharacters>
                      <div className={styles.tooltipSummary}>
                        <div className={styles.tooltipRow}>
                          <div className={styles.tooltipRowValue}>{time}</div>
                        </div>
                        {payload?.map((value, i) => (
                          <div
                            key={i}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gridGap: 10,
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                color: payload[i]?.color,
                              }}
                            >
                              {payload[i]?.name}:
                            </div>
                            <div className={styles.value}>{payload[i]?.value}</div>
                          </div>
                        ))}
                      </div>
                    </RecursivelyReplaceCharacters>
                  )
                }

                return null
              }}
            />
            {!onWidgetDateChange && !isPrivateMode && (
              <Legend
                verticalAlign='top'
                align='left'
                wrapperStyle={{ left: 40, top: 10 }}
                iconType='circle'
              />
            )}
            <Bar dataKey='amount' fill='#0176BC' name='Purchased' />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <NotFoundPage />
      )}
    </DashboardTableWrapper>
  )
}
