import { Step } from 'src/features/Support/models/IMPin'
import { Form, Skeleton, Spin } from 'antd'
import { useContext, useEffect } from 'react'
import { useGetUserByIdQuery } from 'src/features/Settings/core/http/UsersManagementApi'
import emptyAvatarImage from 'src/assets/img/Avatar.png'

import styles from '../../styles.module.scss'
import { UploaderPhotos2 } from 'src/shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import { DetailInfo } from 'src/features/Settings/pages/UserManagement/Components/Tabs/Users/Components/DetailInfo/index'
import { Address } from 'src/features/Settings/pages/UserManagement/Components/Tabs/Users/Components/Address'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { ArrowLeftIcon, OpenProfileIcon } from 'src/assets/svg'
import { RightModalContext } from 'src/features/Modals'
import { useNavigate } from 'react-router'

interface IUserProfileStep {
  step: Step
  id: string
  onChangeStep: (step: Step, agentId: string | number | null) => void
}

export const UserProfileStep = ({ step, id, onChangeStep }: IUserProfileStep) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const { onClose } = useContext(RightModalContext)

  const { data, isLoading, isFetching } = useGetUserByIdQuery(id)

  useEffect(() => {
    if (data) {
      form.setFieldValue('countryId', data.country?.id)
      form.setFieldValue('provinceId', data.province?.name)
      form.setFieldValue('districtId', data.district?.name)
    }
  }, [data, form])

  const handleOpenProfile = () => {
    navigate(`/settings/users-management/view/${id}`)
    onClose()
  }

  const content = (
    <div className={styles.userProfileContainer}>
      <div className={styles.headerContainer}>
        <IconButton
          color='blue'
          icon={<ArrowLeftIcon />}
          onClick={() => onChangeStep('searchAudience', null)}
        />
        <IconButton
          color='blue'
          type='primary'
          iconPos='right'
          icon={<OpenProfileIcon />}
          onClick={handleOpenProfile}
        >
          Open full profile page
        </IconButton>
      </div>

      <Form
        layout='vertical'
        form={form}
        className={styles.addProductModal}
        initialValues={{
          ...data,
        }}
      >
        <Skeleton loading={isFetching} avatar>
          <UploaderPhotos2
            form={form}
            defaultPreviewImage={data?.logo ?? emptyAvatarImage}
            view
            title=''
            isBig
          />
        </Skeleton>
        <Skeleton loading={isFetching} paragraph={{ rows: 2 }}>
          <div className={styles.wrapperBlockExternal}>
            <DetailInfo view />
          </div>
        </Skeleton>
        <Skeleton loading={isFetching} paragraph={{ rows: 2 }}>
          <Address form={form} view />
        </Skeleton>
      </Form>
    </div>
  )

  return isFetching || isLoading ? <Spin>{content}</Spin> : content
}
