import React, { memo, useMemo } from 'react'
import styles from '../../styles.module.scss'
import { ChatFilesIcon, CrossIcon, FileIcon } from '../../../../../../../../../../assets/svg'
import {
  EXTERNAL_CHAT_FILE_TYPE_ENUM,
  ILastExternalChatMessage,
} from '../../../../../../../../models/IExternalChat'
import { Row } from 'antd'

interface IProps {
  message: Partial<ILastExternalChatMessage>
  clearReply: (message: Partial<ILastExternalChatMessage> | null) => void
}

const MessageToReply = ({ message, clearReply }: IProps) => {
  const normalizedMessage = useMemo(() => {
    const regexTag = /\{"id": "([0-9]+)", "name": "([a-zA-Z0-9\s-]+)"}/gi
    const regexTransaction = /\{"id": "([0-9]+)", "transaction": "([a-zA-Z\s-]+)"}/gi
    if (
      regexTag.test(message?.message as string) ||
      regexTransaction.test(message?.message as string)
    ) {
      return message?.message?.split('::').map((el) => {
        if (regexTag.test(el)) {
          const data = JSON.parse(el)
          return <span key={data?.id}>{data.name}</span>
        }
        if (regexTransaction.test(el)) {
          const data = JSON.parse(el)
          return <span key={data?.id}>{data?.id}</span>
        }
        return el
      })
    }
    if (message?.externalChatMessageFiles?.length) {
      const file = message.externalChatMessageFiles[0]?.file
      const fileType = message.externalChatMessageFiles[0]?.fileType

      if (fileType === EXTERNAL_CHAT_FILE_TYPE_ENUM.PHOTO) {
        return (
          <>
            <img width={20} height={20} src={file?.link} style={{ objectFit: 'cover' }} />
          </>
        )
      }

      if (fileType === EXTERNAL_CHAT_FILE_TYPE_ENUM.VIDEO) {
        return (
          <>
            <video width={24} height={24} src={file?.link} />
          </>
        )
      }

      if (fileType === EXTERNAL_CHAT_FILE_TYPE_ENUM.FILE && file?.fileFormat !== '.ogg') {
        return (
          <div className={styles.replyFileContainer}>
            <FileIcon />
            <div className={styles.messageText} title={file?.name}>
              {file?.name}
            </div>
          </div>
        )
      }

      if (file?.fileFormat === '.ogg') {
        return 'Voice Message'
      }
    }
  }, [message])

  return (
    <div className={styles.messageToReply}>
      <div className={styles.messageToReplyMessage}>
        <span className={styles.replyToText}>Reply to:</span>
        <div className={styles.messageToReplyMessageText}>
          {!!message?.externalChatMessageFiles?.length && normalizedMessage}
          <div className={styles.messageText}>{message?.message}</div>
        </div>
      </div>
      <div className={styles.removeReplyMessageIcon} onClick={() => clearReply(null)}>
        <CrossIcon color='#67bef3' size={8} />
      </div>
    </div>
  )
}

export default memo(MessageToReply)
