import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../../shared/models/ITableConf'
import { IAgentRestrictionMutate, IRestriction } from '../../models/Restrictions'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

export const agentsRestrictionManagementApi = createApi({
  reducerPath: 'agentsRestrictionManagement',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['IRestriction', 'RestrictionList', 'FilterFields'],
  endpoints: (builder) => ({
    getAgentRestrictions: builder.query<ITableResponse<IRestriction>, ITableConf>({
      query: (params) => ({
        url: 'agent-restrictions',
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: () => ['RestrictionList'],
    }),

    getAgentRestrictionById: builder.query<IRestriction, string>({
      query: (id) => ({
        url: `agent-restrictions/${id}`,
      }),
      providesTags: (data) => [{ type: 'IRestriction', id: data?.id }],
    }),

    createAgentRestriction: builder.mutation<IRestriction, IAgentRestrictionMutate>({
      query: (body) => ({
        url: 'agent-restrictions',
        method: 'POST',
        body,
      }),
      invalidatesTags: (data) => [
        { type: 'IRestriction', id: data?.id },
        'FilterFields',
        'RestrictionList',
      ],
    }),

    updateAgentRestriction: builder.mutation<
      IRestriction,
      { id: string; body: IAgentRestrictionMutate }
    >({
      query: ({ id, body }) => ({
        url: `agent-restrictions/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (restriction) => [
        { type: 'IRestriction', id: restriction?.id },
        'RestrictionList',
        'FilterFields',
      ],
    }),

    deleteAgentRestriction: builder.mutation<void, string>({
      query: (id) => ({
        url: `agent-restrictions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['RestrictionList', 'FilterFields'],
    }),

    exportAgentRestrictionListTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx' }
    >({
      query: ({ conf, formatFile }) => ({
        url: `agent-restrictions/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getAgentRestrictionFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'agent-restrictions/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `agent-restrictions/filter-values?filterField=${filter.field}&id=${arg}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      keepUnusedDataFor: 0,
      providesTags: () => ['FilterFields'],
    }),
  }),
})

export const {
  useGetAgentRestrictionsQuery,
  useGetAgentRestrictionByIdQuery,
  useCreateAgentRestrictionMutation,
  useUpdateAgentRestrictionMutation,
  useDeleteAgentRestrictionMutation,
  useLazyExportAgentRestrictionListTableQuery,
  useGetAgentRestrictionFiltersListQuery,
} = agentsRestrictionManagementApi
