import React, { useContext, useEffect, useState } from 'react'
import {
  useGetTopupFiltersListQuery,
  useGetTopupTransactionsByAgentIdQuery,
} from '../../../../../../Finance/core/http/ReverseTransactionApi'
import { ISelectedFilters } from '../../../../../../../shared/components/filter/Filter'
import useSearch from '../../../../../../Settings/helpers/useSearch'
import {
  CreateReverseTransStepType,
  ITopup,
} from '../../../../../../Finance/models/IReverseTransaction'
import { SearchStep } from './SearchStep'
import { ITableResponse } from '../../../../../../../shared/models/ITableResponse'
import { CreateStep } from './CreateStep'
import styles from './styles.module.scss'
import { DoubleArrowRightIcon } from '../../../../../../../assets/svg'
import { RightModalContext } from '../../../../../../Modals'
import { ITableConf, SortingOrderEnum } from '../../../../../../../shared/models/ITableConf'
import { paginationSettings } from 'src/shared/hooks/table/useTable'

export const ReverseTransactionRequestModal = () => {
  const {
    onClose,
    props: { agentId },
  } = useContext(RightModalContext)

  const [topupId, setTopupId] = useState<number | null>(null)
  const [step, setStep] = useState<CreateReverseTransStepType>('searchTopup')
  const [isChangePagination, setIsChangePagination] = useState(false)
  const [dataTopupTrans, setDataTopupTrans] = useState<ITopup[]>([])
  const [filterValues, setFilterValues] = useState<ISelectedFilters>({})
  const [searchValue, setSearchValue] = useState('')
  const [tableConf, setTableConf] = useState<ITableConf>(() => ({
    page: 1,
    limit: 10,
    search: searchValue,
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  }))

  const { handleSearchChange } = useSearch(setTableConf)

  const { data = { items: [], totalCount: 0 }, isFetching } = useGetTopupTransactionsByAgentIdQuery(
    {
      ...filterValues,
      ...tableConf,
    },
    { skip: !tableConf.search }
  )
  const { data: filtersList } = useGetTopupFiltersListQuery(tableConf.search!, {
    skip: !tableConf.search || !data?.totalCount,
  })

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    handleSearchChange(e)
    setIsChangePagination(true)
  }

  const loadMoreData = () => {
    setTableConf((pre: ITableConf) => ({ ...pre, page: pre.page! + 1 }))
  }

  const handleChangeStep = (step: CreateReverseTransStepType, topupId: number | null) => {
    setStep(step)
    setTopupId(topupId)
  }

  useEffect(() => {
    if (isFetching) return

    if (
      tableConf.page === 1 ||
      (isChangePagination && (Object.keys(filterValues).length !== 0 || tableConf.search))
    ) {
      setIsChangePagination(false)
      setDataTopupTrans(data.items)
    } else {
      setDataTopupTrans((prev) => [...prev, ...data.items])
    }
    /*// eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filterValues, tableConf.search, isChangePagination, isFetching])

  useEffect(() => {
    if (!tableConf?.search) {
      setDataTopupTrans([])
    }
  }, [tableConf?.search])

  useEffect(() => {
    if (agentId) {
      setSearchValue(agentId)
      setTableConf((prevState) => ({ ...prevState, search: agentId }))
    }
  }, [agentId])

  const shouldDisplayCreate = step === 'createTopup' || step === 'afterCreateTopup'

  const setActiveFilters = (values: ISelectedFilters) => {
    setFilterValues(values)
    setTableConf((p) => ({ ...p, page: paginationSettings.current }))
  }

  return (
    <div>
      <div className={styles.btnClose} onClick={onClose}>
        <DoubleArrowRightIcon />
      </div>
      {step === 'searchTopup' && (
        <SearchStep
          loadMoreData={loadMoreData}
          onSearchChange={onSearchChange}
          data={data as ITableResponse<ITopup>}
          searchQuery={searchValue}
          filterValues={filterValues}
          filterFields={filtersList}
          dataTopupTrans={dataTopupTrans}
          setActiveFilters={setActiveFilters}
          handleChangeStep={handleChangeStep}
        />
      )}
      {shouldDisplayCreate && (
        <CreateStep handleChangeStep={handleChangeStep} topupId={topupId as number} step={step} />
      )}
    </div>
  )
}
