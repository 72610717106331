import Table, { ColumnsType } from 'antd/es/table'
import { DateTableIcon, TableActionIcon } from '../../../../../../assets/svg'
import { formatDateWithTime } from '../../../../../../helpers/utils'
import styles from '../PickupRequestTable/styles.module.scss'
import classNames from 'classnames'
import { IPopupListItems, Popup } from '../../../../../../shared/components/popup/Popup'
import React, { useContext, useState } from 'react'
import { IReverseTransaction } from '../../../../models/IReverseTransaction'
import emptyAvatar from '../../../../../../assets/img/Avatar.png'
import { Link } from 'react-router-dom'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import {
  useGetReverseTransactionsQuery,
  useGetRTFiltersListQuery,
  useLazyExportReverseTransactionsTableQuery,
} from '../../../../core/http/ReverseTransactionApi'
import { TableHeader } from '../../Components/TableHeader'
import { formatNumberToLocale } from '../../../../../salesNetwork/helpers'
import { ReverseTransactionsTableFooter } from './ReverseTransactionsTableFooter'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import { useTableExport } from '../../../../../../shared/hooks/table/useTableExport'
import { SALES_ENUM } from '../../../../../../routes/sales'
import { useOnTransactionViewClick } from '../../../../../../shared/hooks/table/useOnTransactionViewClick'
import { TRANSACTION_CATEGORY_ENUM } from '../../../../models/ITransaction'
import { RightModalContext } from '../../../../../Modals'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'
import camelcase from 'camelcase'

export const ReverseTransactionsTable = () => {
  const { data: profile } = useGetProfileQuery()

  const rightModalContext = useContext(RightModalContext)
  const onTransactionViewClick = useOnTransactionViewClick()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const tableActionsPopup = (row: IReverseTransaction): IPopupListItems[] => [
    {
      text: 'View',
      shouldDisplay: true,
      onClick: () => onTransactionViewClick(row.id, TRANSACTION_CATEGORY_ENUM.REVERSE, row),
    },
  ]

  const columns: ColumnsType<IReverseTransaction> = [
    {
      title: 'Transaction ID',
      dataIndex: 'id',
      sorter: true,
      width: '20%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      sorter: true,
      width: '15%',
      render: (name: IReverseTransaction['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(name, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Agent Name',
      dataIndex: 'agent',
      sorter: true,
      width: '15%',
      render: (agent: IReverseTransaction['agent']) => (
        <RecursivelyReplaceCharacters>
          <Link to={`${SALES_ENUM.SALES_NETWORK}/view/${agent?.id}`} className='table-avatar'>
            <div>
              <img
                src={!isPrivateMode && agent?.avatar ? agent?.avatar : emptyAvatar}
                alt='icon'
                height={32}
                width={32}
                loading='lazy'
              />
            </div>
            <div>
              <div>{agent?.name}</div>
              <div className={styles.agentIdText}>{`ID${agent?.id}`}</div>
            </div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Receiver',
      dataIndex: 'receiver',
      width: '10%',
      sorter: true,
      render: (receiver) => <RecursivelyReplaceCharacters>{receiver}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      width: '10%',
      sorter: true,
      render: (vendor: IReverseTransaction['vendor']) =>
        vendor && <RecursivelyReplaceCharacters>{vendor.name}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Product',
      dataIndex: 'product',
      width: '10%',
      sorter: true,
      render: (product: IReverseTransaction['product']) => (
        <RecursivelyReplaceCharacters>{product?.name}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '10%',
      sorter: true,
      render: (amount: IReverseTransaction['amount']) => (
        <RecursivelyReplaceCharacters>{formatNumberToLocale(amount)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: '10%',
      render: (name: IReverseTransaction['status']) => (
        <RecursivelyReplaceCharacters>
          <div className={styles.statusContainer}>
            <div
              className={classNames(styles[camelcase(name.toLowerCase())], styles.statusPoint)}
            />
            <div>{name}</div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '10%',
      render: (_, row) => {
        if (Object.hasOwn(row, 'mainTransactionId')) return
        return (
          <Popup data={tableActionsPopup(row)}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        )
      },
    },
  ]

  const { queryParams, queryFields } = useGetQueryParams({
    orderField: 'createdAt',
    orderType: SortingOrderEnum.DESC,
  })

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data, isFetching } = useGetReverseTransactionsQuery(
    { ...filterValues, ...tableConf },
    { skip: Object.keys(tableConf).length < 4 }
  )
  const { data: filterFields } = useGetRTFiltersListQuery()
  const [exportTable] = useLazyExportReverseTransactionsTableQuery()

  useTableExport({ tableConf, filterValues, promise: exportTable })

  const emptyTableConf = useEmptyTable(() =>
    rightModalContext.onOpen(RIGHT_MODALS.FINANCE.REVERSE_TRANSACTION_REQUEST, {
      title: 'REVERSE TRANSACTION REQUEST',
    })
  )

  const { pagination, handleTableChange, handleFiltersChange } = useTable<IReverseTransaction>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  return (
    <>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filterFields}
      />
      <Table
        locale={emptyTableConf}
        loading={isFetching}
        pagination={pagination}
        columns={columns}
        summary={(data) => {
          const dataObj = [...data]
          return <ReverseTransactionsTableFooter data={dataObj} />
        }}
        className={styles.tableContainer}
        rowKey={(record) => record.id}
        rowSelection={{ type: 'checkbox' }}
        scroll={{ x: 1000 }}
        dataSource={data?.items}
        onChange={handleTableChange}
      />
    </>
  )
}
