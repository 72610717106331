import { DatePicker } from '../../../../../../shared/components/datePicker'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { EditIcon, SettingsIcon } from '../../../../../../assets/svg'
import { Space, Radio } from 'antd'
import styles from '../../styles.module.scss'
import { Dispatch, SetStateAction, useContext, useMemo } from 'react'
import { startCase } from 'lodash'
import { RightModalContext } from '../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { CALENDAR_TYPE_ENUM } from '../../../../../Settings/models/IUser'
import { getFormCalendarTime } from '../../../../../../helpers/utils'
import { Moment } from 'moment/moment'
import { Dayjs } from 'dayjs'
import moment from 'moment'
import { DASHBOARD_DATE_OPTIONS } from '../../../../../../shared/models/WidgetListEnum'

interface IProps {
  option: DASHBOARD_DATE_OPTIONS
  setOption: Dispatch<SetStateAction<DASHBOARD_DATE_OPTIONS>>
  dateFilter?: string | null
  onDateChange: (date: Moment | null) => void
}

export const DashboardHeader = ({ option, setOption, dateFilter, onDateChange }: IProps) => {
  const { onOpen } = useContext(RightModalContext)
  const { data: profile } = useGetProfileQuery()

  const onSettingsClick = () => {
    onOpen(RIGHT_MODALS.DASHBOARD.DASHBOARD_OVERVIEW_CUSTOMIZATION, {
      title: 'Dashboard Customization',
    })
  }

  const dateOptions = useMemo(
    () =>
      (Object.keys(DASHBOARD_DATE_OPTIONS) as Array<keyof typeof DASHBOARD_DATE_OPTIONS>).map(
        (method) => ({
          label:
            DASHBOARD_DATE_OPTIONS[method] === DASHBOARD_DATE_OPTIONS.TODAY
              ? 'TODAY'
              : startCase(DASHBOARD_DATE_OPTIONS[method]),
          value: DASHBOARD_DATE_OPTIONS[method],
        })
      ),
    []
  )

  return (
    <div className={styles.headerWrapper}>
      <Space size={10}>
        <DatePicker
          picker={option}
          value={
            ((getFormCalendarTime(profile?.calendar, dateFilter as string) as Moment) || Dayjs) ??
            undefined
          }
          {...(option === DASHBOARD_DATE_OPTIONS.WEEK &&
            profile?.calendar === CALENDAR_TYPE_ENUM.SHAMSI && { format: 'YYYY-DD' })}
          onChange={(value) => onDateChange(value as Moment)}
        />
        <Radio.Group
          value={option}
          onChange={(e) => {
            setOption(e.target.value)
            onDateChange(moment())
          }}
        >
          {dateOptions.map((option) => (
            <Radio.Button key={option.value} value={option.value}>
              {option.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Space>
      <Space size={10}>
        <IconButton
          color='orange'
          icon={<SettingsIcon />}
          type='primary'
          onClick={onSettingsClick}
        />
        <IconButton color='blue' icon={<EditIcon />} type='primary' />
      </Space>
    </div>
  )
}
