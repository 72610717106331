import { Route } from 'use-react-router-breadcrumbs'
import { SalesNetworkOverview } from '../../features/salesNetwork/pages/Overview'
import { AgentManagement } from '../../features/salesNetwork/pages/AgentManagment/AgentManagement'
import { AgentManagementDetailView } from '../../features/salesNetwork/pages/AgentManagementDetailView/index'
import { AgentManagementNewAgent } from '../../features/salesNetwork/pages/AgentManagementNewAgent/AgentManagementNewAgent'
import { StructureTable } from '../../features/salesNetwork/pages/AgentManagment/componentns/StructureTable'
import { Campaigns } from '../../features/salesNetwork/pages/Campaigns'
import { ScheduleNotification } from '../../features/salesNetwork/pages/Campaigns/ScheduleNotification'
import { Customers } from '../../features/salesNetwork/pages/Customers'
import { getBreadcrumb } from '../utils'

export enum SALES_ENUM {
  SALES_NETWORK_OVERVIEW = '/sales-network/overview',
  SALES_NETWORK = '/sales-network/agent-management',
  NEW_AGENT = '/sales-network/agent-management/new-agent',
  VIEW_AGENT = '/sales-network/agent-management/:mode/:agentId',
  STRUCTURE = '/sales-network/agent-management/structure/:agentId',
  CUSTOMERS = '/sales-network/customer',
  SCEDULE_NOTIFICATIONS = '/sales-network/campaign',
  SCEDULE_NOTIFICATIONS_NEW = '/sales-network/campaign/new',
  SCEDULE_NOTIFICATIONS_VIEW = '/sales-network/campaign/:mode/:id',
}

export default () => {
  return (
    <>
      <Route path={SALES_ENUM.SALES_NETWORK_OVERVIEW} element={<SalesNetworkOverview />} />
      <Route path={SALES_ENUM.SALES_NETWORK} element={<AgentManagement />} />
      <Route
        path={SALES_ENUM.VIEW_AGENT}
        element={<AgentManagementDetailView />}
        breadcrumb={getBreadcrumb(true)}
      />
      <Route path={SALES_ENUM.NEW_AGENT} element={<AgentManagementNewAgent />} />
      <Route
        path={SALES_ENUM.STRUCTURE}
        element={<StructureTable />}
        breadcrumb={getBreadcrumb(true)}
      />
      <Route path={SALES_ENUM.SCEDULE_NOTIFICATIONS} element={<Campaigns />} />
      <Route
        path={SALES_ENUM.SCEDULE_NOTIFICATIONS_NEW}
        element={<ScheduleNotification />}
        breadcrumb='New Notification'
      />
      <Route
        path={SALES_ENUM.SCEDULE_NOTIFICATIONS_VIEW}
        element={<ScheduleNotification />}
        breadcrumb={getBreadcrumb()}
      />
      <Route path={SALES_ENUM.CUSTOMERS} element={<Customers />} />
    </>
  )
}
