import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form } from 'antd'

import { AddIcon } from '../../../../../../../../assets/svg'
import { RightModalContext } from '../../../../../../../Modals'
import FooterActions from '../../../FooterActions'
import DetailInfo from '../../../DetailInfo'
import { IconButton } from '../../../../../../../../shared/components/iconButton/IconButton'
import { StepRegistration } from '../../../../../../../../shared/components/stepRegistration'

import { useAppDispatch, useAppSelector } from '../../../../../../../../redux'
import { SETTINGS_ENUM } from '../../../../../../../../routes/settings'
import { formatOnlyDate } from '../../../../../../../../helpers/utils'
import { ErrorNode } from '../../../../../../../../shared/api/errorHandler'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../shared/hooks/useNotification'

import { IUser } from '../../../../../../models/IUser'
import {
  IDepartmentManagement,
  IDepartmentManagementCreate,
} from '../../../../../../models/IDepartmentsManagement'

import {
  addUser,
  clearUsersSliceState,
  deleteUserById,
} from '../../../../../../core/store/UsersManagerSlice'
import { useCreateDepartmentMutation } from '../../../../../../core/http/DepartmentsManagementApi'

import { RIGHT_MODALS } from '../../../../../../../../helpers/contants'

import styles from './styles.module.scss'
import { DepartmentsManagementUsersList } from './Components/DepartmentsManagementUsersList'
import usePermissions from '../../../../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../../../models/IPermissions'
import PermissionDenied from '../../../../../../../../shared/components/permissionDenied'
import { useGetProfileQuery } from '../../../../../../../Profile/core/http/Profile'
import { externalChatSocketConnection } from '../../../../../../../../shared/sockets'
import { getSavedAuthData } from '../../../../../../../../shared/api'

export const DepartmentsManagementNew = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const token = getSavedAuthData()?.tokens?.accessToken

  const [form] = Form.useForm()

  const { data: profile } = useGetProfileQuery()
  const [createDepartment, crDepartmentResp] = useCreateDepartmentMutation()
  const usersList: IUser[] = useAppSelector((state) => state.usersManagementSlice.usersList)

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const demandPermissions = [IPermissionsActions.ADD]
  const [departmentsId] = getModulesIdsByNames([IPermissionsNames.Departments])
  const [canAddDepartment] = canPerformAction(departmentsId, demandPermissions)

  const userIds = useMemo(() => {
    return usersList.map((user) => user.id)
  }, [usersList])

  const { onOpen } = useContext(RightModalContext)

  const showModalDepartmentUsersList = () => {
    onOpen(RIGHT_MODALS.SETTINGS.DEPARTMENT_MANAGEMENT_USERS_LIST, {
      title: 'Add users',
    })
    location.state.isOpenModal = false
  }

  location?.state?.isOpenModal && showModalDepartmentUsersList()

  const afterSuccess = () => {
    externalChatSocketConnection.connect(token)
  }

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, crDepartmentResp.isSuccess, null, afterSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    crDepartmentResp.isError,
    crDepartmentResp.error as ErrorNode
  )

  const onFinish = (values: IDepartmentManagement) => {
    const reqBody: IDepartmentManagementCreate = {
      ...values,
      status: !!values?.status,
      hasAccessToExternalChat: !!values?.hasAccessToExternalChat,
      isGeneralExternalChatSupport: !!values?.isGeneralExternalChatSupport,
      name: form.getFieldValue('name'),
      userIds,
    }
    if (userIds.length) {
      reqBody.userIds = userIds
    }
    createDepartment(reqBody)
  }

  const onPressCancelChanges = useCallback(() => {
    navigate(SETTINGS_ENUM.USERS_MANAGEMENT_DEPARTMENTS)
    // eslint-disable-next-line
  }, [])

  const onPressSaveChanges = useCallback(() => {
    form.submit()
    // eslint-disable-next-line
  }, [])

  const onPressAddUser = useCallback(() => {
    onOpen(RIGHT_MODALS.SETTINGS.DEPARTMENT_MANAGEMENT_USERS_LIST, {
      title: 'Add users',
    })
  }, [onOpen])

  const onDeleteUser = (id: number) => {
    dispatch(deleteUserById(id))
  }

  const onAddUser = (user: IUser) => {
    dispatch(addUser(user))
  }

  useEffect(() => {
    if (crDepartmentResp.isSuccess) {
      return navigate(
        `${SETTINGS_ENUM.USERS_MANAGEMENT_DEPARTMENTS}/view/${crDepartmentResp.data.id}`
      )
    }
    // eslint-disable-next-line
  }, [crDepartmentResp.isSuccess])

  useEffect(() => {
    return () => {
      dispatch(clearUsersSliceState())
    }
  }, [dispatch])

  return (
    <div className={styles.layout}>
      {canAddDepartment ? (
        <>
          <Form
            form={form}
            onFinish={onFinish}
            layout='vertical'
            initialValues={{
              createdBy: `${profile?.firstName || ''} ${profile?.lastName || ''}`,
              createdAt: formatOnlyDate(new Date(), profile?.calendar),
              status: false,
            }}
          >
            <StepRegistration
              backgroundColor='#FF6D00'
              stepNumber={1}
              stepText='General Information'
              mB='33px'
              mT='12.5px'
            />
            <DetailInfo isNewMode />
            <StepRegistration stepNumber={2} stepText='Add users' mB='33px' mT='33px' />
            <DepartmentsManagementUsersList
              onDeleteUser={onDeleteUser}
              onAddUser={onAddUser}
              users={usersList}
              isCreating
            />
            <IconButton
              icon={<AddIcon />}
              color='orange'
              iconPos='right'
              size='large'
              block
              onClick={onPressAddUser}
            >
              Add users
            </IconButton>
          </Form>
          <FooterActions
            isLoading={false}
            onPressCancelChanges={onPressCancelChanges}
            onPressSaveChanges={onPressSaveChanges}
            submitButtonText='Create Department'
          />
        </>
      ) : (
        <PermissionDenied />
      )}
    </div>
  )
}
