import React, { memo, useContext, useEffect } from 'react'

import styles from './styles.module.scss'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import {
  ArrowLeftIcon,
  ChatDotsIcon,
  // AddIcon,
  // ChatAddIcon,
  // ChatCallIcon,
  // ChatSearchIcon,
  // SearchIcon,
} from '../../../../../../assets/svg'
import emptyAvatar from '../../../../../../assets/img/Avatar.png'
import { Tooltip } from 'antd'
import {
  addChatOnOpen,
  setOpenedChatId,
  updateChatTypingStatus,
} from '../../../../core/store/Chats'
import { useAppDispatch } from '../../../../../../redux'
import { RightModalContext } from '../../../../../Modals'
import { modalViewTypeEnum } from '../../../../core/models'

interface IProps {
  id: number
  name: string
  typing: boolean
  icon: string | null
  onSwitchView: (viewType: modalViewTypeEnum) => void
  isOnline: boolean
}

export const MessagingHeader = memo(
  ({ onSwitchView, name, icon, typing, id, isOnline }: IProps) => {
    const dispatch = useAppDispatch()

    const { setProps } = useContext(RightModalContext)

    const onPressBack = () => {
      dispatch(addChatOnOpen(null))
      dispatch(setOpenedChatId(null))
      onSwitchView(modalViewTypeEnum.chats)
    }

    const onPressDetails = () => {
      /** Set the id so that when you click back in the details window, the navigation will work on the chat you need. */
      setProps((prev) => ({ ...prev, id }))
      onSwitchView(modalViewTypeEnum.details)
    }

    useEffect(() => {
      if (typing) {
        const timeout = setTimeout(() => {
          dispatch(updateChatTypingStatus({ status: false, fromUserId: id }))
        }, 3000)
        return () => clearTimeout(timeout)
      }
      // eslint-disable-next-line
    }, [id, typing])

    return (
      <div className={styles.messagingHeader}>
        <IconButton
          color='blue'
          type='default'
          icon={<ArrowLeftIcon />}
          className={styles.backButton}
          onClick={onPressBack}
        />
        <div onClick={onPressDetails}>
          <Tooltip title={name} placement='topRight' className={styles.chatAvatar}>
            <img src={icon ?? emptyAvatar} alt={name} />
          </Tooltip>
        </div>
        <div className={styles.chatInfo}>
          <div className={styles.chatName}>{name}</div>
          <div className={styles.chatStatus}>{typing && 'Typing...'}</div>
          {!typing && isOnline && <div className={styles.chatStatusOnline}>Online</div>}
        </div>
        <div className={styles.chatActions}>
          {/*<div className={styles.chatActionBtn}>*/}
          {/*  <ChatAddIcon />*/}
          {/*</div>*/}
          {/*<div className={styles.chatActionBtn}>*/}
          {/*  <ChatSearchIcon />*/}
          {/*</div>*/}
          {/*<div className={styles.chatActionBtn}>*/}
          {/*  <ChatCallIcon />*/}
          {/*</div>*/}
          <div className={styles.chatActionBtn} onClick={onPressDetails}>
            <ChatDotsIcon />
          </div>
        </div>
      </div>
    )
  }
)
