import styles from './style.module.scss'
import { OverviewTabs } from './Components/Tabs/index'

export const SupportOverview = () => {
  return (
    <div className={styles.layout}>
      <OverviewTabs />
    </div>
  )
}
