import { noop } from 'lodash'
import { useEffect } from 'react'
import ShowMessage, { ErrorNode, handleError } from 'src/shared/api/errorHandler'

export enum NOTIFICATION_TYPES {
  success,
  error,
}

interface IUseNotification {
  (
    type: NOTIFICATION_TYPES.success | NOTIFICATION_TYPES.error,
    status: boolean,
    error?: ErrorNode | null,
    // eslint-disable-next-line
    afterCreate?: (...args: any[]) => void
  ): void
}

export const useNotification: IUseNotification = (type, status, error, afterCreate = noop) => {
  useEffect(() => {
    if (!status) return
    if (type === NOTIFICATION_TYPES.success) {
      ShowMessage('success', 'Success!')
      afterCreate?.()
    } else {
      handleError(error as ErrorNode)
      afterCreate?.()
    }
  }, [type, status, error])
}
