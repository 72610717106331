import { Collapse, Skeleton, Form, Spin } from 'antd'
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { noop } from 'lodash'

import {
  CopyIcon,
  DoubleCheckMarkIcon,
  KeyboardArrowDown,
  ReplayCircleRounded,
  SendPlaneIcon,
  SwapArrowsIcon,
  TickIcon,
} from 'src/assets/svg'
import {
  useGetAgentByIdQuery,
  usePatchAgentMutation,
} from 'src/features/salesNetwork/core/http/AgentManagement'
import { NotificationAlert } from 'src/shared/components/notificationAlert/NotificationAlert'
import { AgentMainInfo } from '../../Components/AgentMainInfo'
import { IconButton } from 'src/shared/components/iconButton/IconButton'

// helpers
import { onCopyText } from 'src/helpers/utils'
import { Step } from 'src/features/Support/models/IMPin'
import { CONTACT_TYPE_OPTIONS } from 'src/features/salesNetwork/components/ContactInformation'

// styles
import styles from './styles.module.scss'
import '../../../../../../../../../node_modules/animate.css/animate.css'
import { formatNumberToLocale } from 'src/features/salesNetwork/helpers'
import { USER_IDENTIFIERS_OPTIONS } from 'src/features/salesNetwork/components/UserIdentifiers'
import { IDENTIFIERS_VALUE_FOR_DISPLAYED_BY_TYPE } from 'src/features/salesNetwork/models/IAgent'
import * as I from '../../../../../../../salesNetwork/models/IAgent'

const { Panel } = Collapse

interface ITextFieldRow {
  options: Record<string | number, string>
  identifierType: {
    icon: ReactNode
    id?: number
    active: boolean
    agentIdentifierTypeId: number
    agentIdentifier: {
      type: string
      name: string
    }
  }
  identifier: {
    value?: string
    active: boolean
    id: string
    isOtpSent: boolean
    isVerified: boolean
    type: string
  }
  onCopy: (val: string) => () => void
}

const TextFieldRow: React.FC<ITextFieldRow> = ({ identifierType, identifier, onCopy }) => {
  // const identifierIcon = (
  // if (
  //     identifier?.type === I.CONTACT_TYPE_NEW.MOBILE.type ||
  //     identifier?.type === I.CONTACT_TYPE_NEW.WHATSAPP.type
  // ) {
  //   return
  // }
  // )
  return (
    <div className={styles.rowIdentifiersContainer}>
      <div className={classNames(styles.identifierType, styles.textStyle)}>
        <div className={styles.iconContainer}>{identifierType?.icon}</div>
        {IDENTIFIERS_VALUE_FOR_DISPLAYED_BY_TYPE[
          identifierType.agentIdentifierTypeId as keyof typeof IDENTIFIERS_VALUE_FOR_DISPLAYED_BY_TYPE
        ] || identifierType.agentIdentifier.name}
      </div>
      <div className={classNames(styles.indentifierValue, styles.textStyle)}>
        {identifier.value}
      </div>
      <IconButton
        onClick={onCopy(identifier.value!)}
        size='large'
        color='orange'
        icon={<CopyIcon />}
        type='default'
      />
      {(identifier?.type === I.CONTACT_TYPE_NEW.MOBILE.type ||
        identifier?.type === I.CONTACT_TYPE_NEW.WHATSAPP.type) && (
        <div style={{ marginLeft: '16px' }}>
          {!identifier?.isOtpSent && !identifier?.isVerified && (
            <IconButton
              slim
              size='large'
              color='blue'
              icon={<TickIcon />}
              type='default'
              // onClick={onVerifyIdentifiers(selectedType, selectedValue)}
              // disabled={
              //     identifier?.type === I.CONTACT_TYPE_NEW.WHATSAPP.type || dataAgent?.isDeleted
              // }
            />
          )}

          {identifier?.isOtpSent && !identifier?.isVerified && (
            <IconButton
              slim
              size='large'
              color='blue'
              icon={<SendPlaneIcon />}
              type='primary'
              // onClick={onVerifyIdentifiers(identifier?.selectedType, selectedValue)}
              // disabled={dataAgent?.isDeleted}
            />
          )}

          {identifier?.isVerified && (
            <IconButton
              slim
              size='large'
              color='success'
              icon={<DoubleCheckMarkIcon />}
              type='default'
            />
          )}
        </div>
      )}
    </div>
  )
}
interface IAgentProfileStep {
  agentId: string | number | undefined
  parentId?: string | number | null
  isChangedMPin: boolean
  isChangedParentId: boolean
  isAudience?: boolean
  onChangeStep: (step: Step, agentId: string | number | null) => void
  onChangedMPin: (value: boolean) => void
  onChangeParentId: (value: {
    status: boolean
    parentId: string | number | null
    agentLevel: number | null
  }) => void
}

export const AgentProfileStep: React.FC<IAgentProfileStep> = ({
  agentId,
  parentId,
  isChangedMPin,
  isChangedParentId,
  isAudience,
  onChangeStep,
  onChangedMPin,
  onChangeParentId,
}) => {
  const { data: agent, isFetching } = useGetAgentByIdQuery(agentId as string, {
    skip: !agentId,
  })
  const [patch, { isLoading }] = usePatchAgentMutation()

  const onStatusChange = (checked: boolean) => {
    const fd = new FormData()
    fd.append('agent', JSON.stringify({ status: checked }))
    patch({ id: agentId, body: fd })
  }

  const onStatusMpinChange = (checked: boolean) => {
    const fd = new FormData()
    fd.append('agent', JSON.stringify({ mPinActivation: checked }))
    patch({ id: agentId, body: fd })
  }

  const onCancel = () => {
    onChangedMPin(false)
    onChangeParentId({ status: false, parentId: null, agentLevel: null })
  }

  const handlerBackClick = () => {
    onChangeStep(isAudience ? 'searchAudience' : 'searchAgent', null)
    onCancel()
  }

  const handleMPinClick = () => {
    onChangeStep('changeMPin', agentId!)
    onCancel()
  }

  const handleTransferClick = () => {
    onChangeStep('searchParent', agentId!)
    onCancel()
  }

  const onCopyTextContactField = (identifier: string) => () => {
    identifier ? onCopyText(identifier) : noop
  }

  const mPinView = agent?.agentAccessOption.mPin.split('').join(' ')
  const notificationTitle = parentId
    ? `You have successfully transferred to new Parent ID${parentId}`
    : 'You have successfully changed mPIN!'

  return (
    <div className={styles.agentProfileContainer}>
      <Spin spinning={isFetching || isLoading}>
        {(isChangedMPin || isChangedParentId) && (
          <div className='animate__animated animate__bounceInDown'>
            <NotificationAlert type='success' variant='contained' message={notificationTitle} />
          </div>
        )}

        <Form>
          <Skeleton loading={isFetching} avatar>
            <AgentMainInfo
              agent={agent!}
              onChangeStep={handlerBackClick}
              onStatusChange={onStatusChange}
              onStatusMpinChange={onStatusMpinChange}
            />
          </Skeleton>
          <Skeleton loading={isFetching} paragraph={{ rows: 2 }}>
            <div className={classNames(styles.currentBalanceContainer, styles.blockContainer)}>
              <div className={styles.blockTitle}>Current Balance</div>
              <div className={classNames(styles.blueColor, styles.blockNumber)}>
                {formatNumberToLocale(agent?.wallet?.amount ?? 0)}
              </div>
              <div className={classNames(styles.blueColor, styles.currencyType)}>AFN</div>
            </div>
          </Skeleton>
          <Skeleton loading={isFetching} paragraph={{ rows: 2 }}>
            <div className={classNames(styles.blockContainer)}>
              <div className={styles.blockTitle}>Credit Score</div>
              <div className={classNames(styles.orangeColor, styles.blockNumber)}>95</div>
              <div className={styles.currencyType} />
            </div>
          </Skeleton>
          <Skeleton loading={isFetching} paragraph={{ rows: 2 }}>
            <div className={styles.blockContainer}>
              <div className={styles.blockTitle}>mpin</div>
              <div className={classNames(styles.orangeColor, styles.blockNumber)}>{mPinView}</div>
              <div className={styles.currencyType}>
                <div className={styles.mpinContainer} onClick={handleMPinClick}>
                  <ReplayCircleRounded />
                </div>
              </div>
            </div>
          </Skeleton>
          {agent?.agentLevel.level && agent?.agentLevel.level !== 1 && (
            <Skeleton loading={isFetching} paragraph={{ rows: 2 }}>
              <div className={classNames(styles.blockContainer)}>
                <div className={styles.blockTitle}>Parent Account</div>
                <div className={classNames(styles.orangeColor, styles.blockNumber)}>
                  {`ID${agent?.parentId || ''}`}
                </div>
                <div className={styles.currencyType}>
                  <div className={styles.mpinContainer} onClick={handleTransferClick}>
                    <SwapArrowsIcon />
                  </div>
                </div>
              </div>
            </Skeleton>
          )}

          <Skeleton loading={isFetching} paragraph={{ rows: 2 }}>
            <div className={styles.collapseContainer}>
              <Collapse bordered={false} expandIcon={() => <KeyboardArrowDown />}>
                <Panel header='User Identifiers' key='1'>
                  {agent?.agentIdentifiers.map((identifier) => {
                    const identifierType = USER_IDENTIFIERS_OPTIONS.find(
                      (i) => i.agentIdentifierType === identifier.type
                    )

                    return (
                      <TextFieldRow
                        key={identifier.id}
                        // eslint-disable-next-line
                        // @ts-ignore
                        identifierType={identifierType}
                        identifier={identifier as ITextFieldRow['identifier']}
                        onCopy={onCopyTextContactField}
                      />
                    )
                  })}
                </Panel>
              </Collapse>
            </div>
          </Skeleton>

          <Skeleton loading={isFetching} paragraph={{ rows: 2 }}>
            <div className={styles.collapseContainer}>
              <Collapse bordered={false} expandIcon={() => <KeyboardArrowDown />}>
                <Panel header='Contact Information' key='1'>
                  {agent?.contactInfo.map((contact) => {
                    const identifierType = CONTACT_TYPE_OPTIONS.find(
                      (i) => i.agentIdentifier.type === contact.type
                    )
                    return (
                      <TextFieldRow
                        key={contact.value}
                        // eslint-disable-next-line
                        // @ts-ignore
                        identifierType={identifierType}
                        identifier={contact as ITextFieldRow['identifier']}
                        onCopy={onCopyTextContactField}
                      />
                    )
                  })}
                </Panel>
              </Collapse>
            </div>
          </Skeleton>
        </Form>
      </Spin>
    </div>
  )
}
