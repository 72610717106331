import { IAgentLevel } from '../../salesNetwork/models/IAllTable'

export enum PAYMENT_POLICY_TYPE_ENUM {
  PREPAID = 'PREPAID',
  POSTPAID = 'POSTPAID',
}

export interface IDiscountPolicyTargetGroups {
  targetGroupId: number
  discountPolicyId: string
}

export interface IPromotionPolicyTargetGroups {
  targetGroupId: number
  promotionPolicyId: string
}

export interface ITargetGroup {
  id: number
  name: string
  paymentPolicyType: PAYMENT_POLICY_TYPE_ENUM
  discountPolicyTargetGroups: Array<IDiscountPolicyTargetGroups>
  promotionPolicyTargetGroups: Array<IPromotionPolicyTargetGroups>
  agentType: string
  agentTypeId: string
  agentLevel: IAgentLevel
  groupSize: number
  status: boolean
  createdAt: Date
  updatedAt: Date
}

export interface ITargetGroupCreate {
  agentTypeId: number
  agentLevelId: number
  paymentPolicyType: PAYMENT_POLICY_TYPE_ENUM
  name: string
  status: boolean
}

export interface ITargetGroupSize {
  agentTypeId: number | string
  agentLevelId: number | string
  paymentPolicyType: PAYMENT_POLICY_TYPE_ENUM
}
