import React from 'react'
import styles from '../styles.module.scss'

export const AudioViewer = ({ src }: { src: string }) => {
  return (
    <div className={styles.audioPreview}>
      <audio controls>
        <source src={src} />
      </audio>
    </div>
  )
}
