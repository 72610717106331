import { Checkbox as AntCheckbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import classNames from 'classnames'
import './style.scss'
import { CheckboxProps } from 'antd/lib/checkbox/Checkbox'

interface IProps extends CheckboxProps {
  onChange: (checked: CheckboxChangeEvent) => void
  color?: 'blue' | 'orange' | 'error' | 'warning' | 'success' | 'black'
  text?: string
  disabled?: boolean
}

export const Checkbox = ({ onChange, text, color, disabled, ...rest }: IProps): JSX.Element => {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <AntCheckbox onChange={onChange} className={classNames(color)} disabled={disabled} {...rest}>
        {text}
      </AntCheckbox>
    </div>
  )
}
