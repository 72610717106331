import React, { memo, useEffect, useMemo, useState } from 'react'
import { Checkbox } from '../../../../../../../../shared/components/checkbox/Checkbox'
import { IPermissionCreate, IRolePermissionModule } from '../../../../../../models/IRoles'

interface IProps {
  disabled?: boolean
  selectedPermissions: Array<IPermissionCreate>
  action: string
  module: IRolePermissionModule
  onSelectAllSubmoduleByTypePermissions: (
    module: IRolePermissionModule,
    action: string,
    status: boolean
  ) => void
}

const PermissionsAllByTypeSubmoduleCheckbox = ({
  disabled,
  action,
  module,
  onSelectAllSubmoduleByTypePermissions,
  selectedPermissions,
}: IProps) => {
  const [isChecked, setIsChecked] = useState(false)
  const [isDisabled, setIsDisabled] = useState(disabled)
  const [isIndeterminate, setIsIndeterminate] = useState(false)

  const permissionsCountInModule = useMemo(
    () =>
      module.subModules
        .map((subModules) => subModules.permissionModuleAllowedPermissions)
        .flat()
        .filter((el) => el.permission === action).length,
    [action, module.subModules]
  )

  const selectedPermissionsCountInModule = useMemo(
    () =>
      module.subModules
        .map((module) =>
          selectedPermissions.filter(
            (per) => per.permission === action && per.permissionModuleId === module.id
          )
        )
        .flat().length,
    [action, module.subModules, selectedPermissions]
  )

  useEffect(() => {
    const checked =
      selectedPermissionsCountInModule > 0 &&
      permissionsCountInModule === selectedPermissionsCountInModule
    setIsChecked(checked)
  }, [permissionsCountInModule, selectedPermissionsCountInModule])

  useEffect(() => {
    setIsDisabled(!permissionsCountInModule)
  }, [permissionsCountInModule])

  useEffect(() => {
    const selectedDiff = permissionsCountInModule - selectedPermissionsCountInModule
    const indeterminate = selectedDiff > 0 && selectedDiff !== permissionsCountInModule
    setIsIndeterminate(indeterminate)
  }, [permissionsCountInModule, selectedPermissionsCountInModule])

  return (
    <Checkbox
      checked={isChecked}
      disabled={isDisabled || disabled}
      indeterminate={isIndeterminate}
      onChange={(status) =>
        onSelectAllSubmoduleByTypePermissions(module, action, status.target.checked)
      }
    />
  )
}

export default memo(PermissionsAllByTypeSubmoduleCheckbox)
