import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Col, notification, Row, Tabs } from 'antd'

import { AddIcon, CloudDownloadIcon } from '../../../../../../../../../../assets/svg'
import { Toggle } from '../../../../../../../../../../shared/components/toggle/Toggle'
import { IconButton } from '../../../../../../../../../../shared/components/iconButton/IconButton'
import { RolesAndPermissionsInfo } from '../../../Components/RoleInfo'

import { ROLES_MANAGEMENT_VIEW_TABS_ENUM } from './index.d'
import { CenterModalContext, RightModalContext } from '../../../../../../../../../Modals'
import { CENTER_MODALS, RIGHT_MODALS } from '../../../../../../../../../../helpers/contants'
import { Emitter, EmitterEvents } from '../../../../../../../../../../helpers/eventEmitter'
import { TableExportFormatEnum } from '../../../../../../../../../../shared/hooks/table/useTableExport'
import {
  useGetRoleByIdQuery,
  useUpdateRoleMutation,
} from '../../../../../../../../core/http/RolesAndPermissionsManagement'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../../../shared/hooks/useNotification'
import { ErrorNode, MESSAGE_KEY } from '../../../../../../../../../../shared/api/errorHandler'
import RolePermissionsView from '../../../Components/RolePermissions'
import { RoleUsers } from '../../../Components/RoleUsers'
import usePermissions from '../../../../../../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../../../../../models/IPermissions'
import WithPermissionDeniedContainer from '../../../../../../../../../../shared/components/withPermissionDenied'
import { useAppDispatch } from '../../../../../../../../../../redux'
import { setBreadcrumb } from '../../../../../../../../../app/core/BreadcrumbsSlice'

interface IPropsOperations {
  activeTab: string
  canEditRole: boolean
  canAddUser: boolean
  canExportUser: boolean
}

const Operations = memo(
  ({ activeTab, canEditRole, canAddUser, canExportUser }: IPropsOperations) => {
    const dispatch = useAppDispatch()
    const { onOpen } = useContext(CenterModalContext)
    const { onOpen: openRightModal } = useContext(RightModalContext)
    const { id } = useParams<{ mode: 'view' | 'edit'; id: string }>()

    const { data, isFetching } = useGetRoleByIdQuery(id as string, { skip: !id })
    const [updateRole, updateRoleResp] = useUpdateRoleMutation()

    const isRoleInfoActive = activeTab === ROLES_MANAGEMENT_VIEW_TABS_ENUM.ROLE_DETAIL_INFO
    const isRolePermissionsActive =
      activeTab === ROLES_MANAGEMENT_VIEW_TABS_ENUM.LIST_OF_PERMISSIONS
    const isUsersTabActive = activeTab === ROLES_MANAGEMENT_VIEW_TABS_ENUM.USERS

    /** Notifications */
    useNotification(NOTIFICATION_TYPES.success, updateRoleResp.isSuccess)
    useNotification(
      NOTIFICATION_TYPES.error,
      updateRoleResp.isError,
      updateRoleResp.error as ErrorNode
    )

    const openNotificationOnBlockUsers = useCallback(
      (checked: boolean) => {
        const btn = (
          <Button
            type='primary'
            onClick={() => updateRole({ id: id as string, body: { role: { status: checked } } })}
          >
            Confirm
          </Button>
        )
        const notificationRef = notification
        notificationRef.open({
          type: 'warning',
          message: !checked
            ? 'This action will block permissions for all users using this role!'
            : 'This action unlocks permissions for all users using this role',
          key: MESSAGE_KEY,
          btn,
        })
      },
      // eslint-disable-next-line
      [updateRole]
    )

    const handlerExportClick = () => {
      onOpen(CENTER_MODALS.EXPORT_TABLE, {
        title: 'Export Table',
        onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
        onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      })
    }

    const onAddButtonClick = () => {
      if (isUsersTabActive) {
        openRightModal(RIGHT_MODALS.SETTINGS.ROLES_AND_PERMISSIONS_MANAGEMENT_USERS_TO_ROLE_LIST, {
          title: 'Add users',
          roleId: id,
        })
      }
    }

    useEffect(() => {
      if (data) {
        dispatch(setBreadcrumb(data))
      }
    }, [data, dispatch])

    return (
      <div style={{ display: 'flex', gridGap: 10 }}>
        {!isRoleInfoActive && !isRolePermissionsActive && (
          <Row>
            <Col style={{ marginRight: 10 }}>
              {canAddUser && (
                <IconButton
                  disabled={!canAddUser}
                  icon={<CloudDownloadIcon />}
                  color='blue'
                  type='primary'
                  onClick={handlerExportClick}
                />
              )}
            </Col>
            <Col>
              {canExportUser && (
                <IconButton
                  disabled={!canExportUser}
                  icon={<AddIcon />}
                  color='orange'
                  type='primary'
                  onClick={onAddButtonClick}
                />
              )}
            </Col>
          </Row>
        )}
        {canEditRole && (
          <Toggle
            disabled={!canEditRole}
            defaultChecked={data?.status}
            checked={data?.status}
            text='Status'
            loading={updateRoleResp.isLoading || isFetching}
            onChange={openNotificationOnBlockUsers}
            inWrapper
          />
        )}
      </div>
    )
  }
)

export const RolesManagementViewTabs = () => {
  const [activeTab, setActiveTab] = useState<string>(
    ROLES_MANAGEMENT_VIEW_TABS_ENUM.ROLE_DETAIL_INFO
  )

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const [rolesId, permissionsId, usersId] = getModulesIdsByNames([
    IPermissionsNames.Roles,
    IPermissionsNames.Permissions,
    IPermissionsNames.Users,
  ])

  const demandPermissions = [
    IPermissionsActions.VIEW,
    IPermissionsActions.EDIT,
    IPermissionsActions.ADD,
  ]

  const [canShowRole, canEditRole] = canPerformAction(rolesId, demandPermissions)
  const [canShowPermissions, canEditPermissions] = canPerformAction(
    permissionsId,
    demandPermissions
  )
  const [canShowUsers, canEditUser, canAddUser, canExportUser] = canPerformAction(usersId, [
    ...demandPermissions,
    IPermissionsActions.EXPORT,
  ])

  const items = useMemo(
    () => [
      {
        key: ROLES_MANAGEMENT_VIEW_TABS_ENUM.ROLE_DETAIL_INFO,
        label: ROLES_MANAGEMENT_VIEW_TABS_ENUM.ROLE_DETAIL_INFO,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canShowRole}>
            <RolesAndPermissionsInfo canEditRole={canEditRole} />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        key: ROLES_MANAGEMENT_VIEW_TABS_ENUM.LIST_OF_PERMISSIONS,
        label: ROLES_MANAGEMENT_VIEW_TABS_ENUM.LIST_OF_PERMISSIONS,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canShowPermissions}>
            <RolePermissionsView canEditPermissions={canEditPermissions} />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        key: ROLES_MANAGEMENT_VIEW_TABS_ENUM.USERS,
        label: ROLES_MANAGEMENT_VIEW_TABS_ENUM.USERS,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canShowUsers}>
            <RoleUsers canEditUser={canEditUser} />
          </WithPermissionDeniedContainer>
        ),
      },
    ],
    [canEditPermissions, canEditRole, canEditUser, canShowPermissions, canShowRole, canShowUsers]
  )

  return (
    <Tabs
      destroyInactiveTabPane
      items={items}
      tabBarExtraContent={
        <Operations
          activeTab={activeTab}
          canEditRole={canEditRole}
          canAddUser={canAddUser}
          canExportUser={canExportUser}
        />
      }
      onChange={(activeKey) => setActiveTab(activeKey)}
    />
  )
}
