import React, { memo, MouseEvent } from 'react'
import classNames from 'classnames'
import { Pin } from '../../../../../assets/svg'
import { Tooltip } from 'antd'
import styles from './styles.module.scss'

interface IProps {
  isPinned: boolean
  onPressPin?: (e: MouseEvent<HTMLDivElement>) => void
}

const PinChat = ({ isPinned, onPressPin }: IProps) => {
  const onHandlePress = (e: MouseEvent<HTMLDivElement>) => {
    if (onPressPin) {
      onPressPin(e)
    }
  }

  return (
    <Tooltip title={isPinned ? 'UnPin' : 'Pin'}>
      <div
        className={classNames(styles.pinnedIcon, { [styles.isPinned]: isPinned })}
        onClick={onHandlePress}
      >
        <Pin color={!isPinned ? '#67bef3' : 'none'} />
      </div>
    </Tooltip>
  )
}

export default memo(PinChat)
