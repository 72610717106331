import { Select } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { useContext } from 'react'
import { CenterModalContext } from 'src/features/Modals'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { IUserDetail } from 'src/features/Settings/models/IUser'
import { CENTER_MODALS } from 'src/helpers/contants'
import { Emitter, EmitterEvents } from 'src/helpers/eventEmitter'
import { Button } from 'src/shared/components/button/Button'

import { Wrapper } from 'src/shared/components/wrapper/Wrapper'

// styles
import styles from '../../styles.module.scss'

const twoFactorOptions = [
  {
    id: 1,
    value: 'Google Authenticator',
    name: 'Google Authenticator',
  },
]

interface ITwoFactorBlock {
  onResetForm: (profile: IUserDetail) => void
}

export const TwoFactorBlock = ({ onResetForm }: ITwoFactorBlock) => {
  const { data: profile } = useGetProfileQuery()
  const { onOpen, onClose } = useContext(CenterModalContext)

  const handleDisableGoogleAuth = () => {
    Emitter.emit(EmitterEvents.DISABLE_GOOGLE_AUTH)
  }

  const handlerReset = () => {
    onResetForm(profile!)
    onClose()
  }

  const handleCancel2FA = () => {
    if (profile?.twoFactorAuthenticator === 'disabled') return

    onOpen(CENTER_MODALS.DISABLE_GOOGLE_AUTH, {
      title: 'Disable Two-Factor Authentication',
      onCloseAction: handlerReset,
      content: (
        <div style={{ marginBottom: '8px' }}>Do you want to disable Google Authenticator?</div>
      ),
      footer: (
        <>
          <Button color='blue' size='middle' onClick={handlerReset}>
            Cancel
          </Button>

          <Button
            color='orange'
            size='middle'
            type='primary'
            htmlType='submit'
            onClick={handleDisableGoogleAuth}
          >
            Confirm
          </Button>
        </>
      ),
    })
  }

  return (
    <Wrapper title='Two-Factor Authentication' className={styles.twoFABlock}>
      <div className={styles.row}>
        <FormItem name='twoFactorAuthenticator' label='Method'>
          <Select allowClear placeholder='Select Method of 2FA' onClear={handleCancel2FA}>
            {twoFactorOptions.map(({ id, name, value }) => (
              <Select.Option key={id} value={value}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </div>
      {profile?.twoFactorAuthenticator && profile?.twoFactorAuthenticator !== 'disabled' && (
        <div className={styles.btnsContainer}>
          <Button color='blue' size='large' onClick={handleCancel2FA}>
            Cancel
          </Button>
        </div>
      )}
    </Wrapper>
  )
}
