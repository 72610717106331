import React from 'react'
import { Select } from 'antd'
import classNames from 'classnames'
import FormItem from 'antd/es/form/FormItem'
import FormList from 'antd/es/form/FormList'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { TextField } from 'src/shared/components/textfield/TextField'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from 'src/assets/svg'
import styles from './styles.module.scss'
import TextArea from 'antd/es/input/TextArea'

const codeStatuses = [
  { label: 'SUCCESS', value: 'SUCCESS' },
  { label: 'PENDING', value: 'PENDING' },
  { label: 'FAIL REPEAT CHECK REQUEST', value: 'FAIL_REPEAT_CHECK_REQUEST' },
  { label: 'FAIL REPEAT REQUEST', value: 'FAIL_REPEAT_REQUEST' },
  { label: 'FAIL', value: 'FAIL' },
  { label: 'ABORT REQUESTS', value: 'ABORT_REQUESTS' },
]

const codeTypes = [
  { label: 'VENDOR CODE', value: 'VENDOR_CODE' },
  { label: 'TRANSPORT CODE', value: 'TRANSPORT_CODE' },
]

interface IProps {
  onAddNew: (header: IIntegrationFormResponseCode, key: string) => void
}

export interface IIntegrationFormResponseCode {
  code: string
  description: string
  status: string
  type: string
}

const ResponseCode = ({ onAddNew }: IProps) => {
  const addNewResponseCode = () => {
    onAddNew(
      {
        code: '',
        description: '',
        status: 'SUCCESS',
        type: 'VENDOR_CODE',
      },
      'responseCode'
    )
  }

  return (
    <Wrapper
      title='Response Code'
      className={styles.generalInfoWrapper}
      actions={
        <IconButton
          type='default'
          color='orange'
          block
          icon={<AddIcon />}
          onClick={addNewResponseCode}
        />
      }
    >
      <FormList name='responseCode'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name, key }) => (
              <div className={classNames(styles.dblRow, styles.formBlockItem)} key={key}>
                <div>
                  <div className={styles.dblRow}>
                    <FormItem name={[name, 'code']} label='Code' rules={[{ required: true }]}>
                      <TextField placeholder='Enter code' />
                    </FormItem>
                    <FormItem name={[name, 'status']} label='Code status'>
                      <Select allowClear options={codeStatuses} defaultValue='SUCCESS' />
                    </FormItem>
                    <FormItem name={[name, 'type']} label='Code type'>
                      <Select allowClear options={codeTypes} defaultValue='VENDOR_CODE' />
                    </FormItem>
                  </div>
                  <FormItem
                    name={[name, 'description']}
                    label='Description'
                    rules={[{ required: true }]}
                  >
                    <TextArea placeholder='Enter description' />
                  </FormItem>
                </div>
                <IconButton
                  color='orange'
                  size='middle'
                  icon={<BucketIcon />}
                  onClick={() => remove(name)}
                  className={styles.removeButton}
                />
              </div>
            ))}
          </>
        )}
      </FormList>
    </Wrapper>
  )
}

export default ResponseCode
