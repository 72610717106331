import React, { useCallback, useContext, useState } from 'react'
import { Button, notification, Table } from 'antd'
import { useNavigate, useParams } from 'react-router'
import useTable from '../../../../../../../../shared/hooks/table/useTable'
import {
  useDeleteVariableMutation,
  useGetVariablesFiltersListQuery,
  useGetVendorVariablesListQuery,
} from '../../../../../../core/http/VendorManagementApi'
import { useGetQueryParams } from '../../../../../../../../shared/hooks/table/useGetQueryParams'
import { ISelectedFilters } from '../../../../../../../../shared/components/filter/Filter'
import { ITableConf } from '../../../../../../../../shared/models/ITableConf'
import { useEmptyTable } from '../../../../../../../../shared/hooks/table/useEmptyTable'
import { ColumnsType } from 'antd/es/table'
import { IVendorVariable } from '../../../../../../models/IVariables'
import styles from '../ContactsTable/styles.module.scss'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../../../shared/components/popup/Popup'
import { TableActionIcon } from '../../../../../../../../assets/svg'
import { ErrorNode, MESSAGE_KEY } from '../../../../../../../../shared/api/errorHandler'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../shared/hooks/useNotification'
import { TableHeader } from '../../TableHeader'
import { RIGHT_MODALS } from '../../../../../../../../helpers/contants'
import { RightModalContext } from '../../../../../../../Modals'

const VariablesTable = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { queryParams, queryFields } = useGetQueryParams()

  const {
    onOpen,
    props: { vendorId },
  } = useContext(RightModalContext)

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data: filtersList } = useGetVariablesFiltersListQuery({ vendorId: id as string })
  const { data, isFetching } = useGetVendorVariablesListQuery({
    params: {
      ...tableConf,
      ...filterValues,
    },
    vendorId: id as string,
  })

  const [deleteVariable, dvResp] = useDeleteVariableMutation()

  useNotification(NOTIFICATION_TYPES.success, dvResp.isSuccess, null)
  useNotification(NOTIFICATION_TYPES.error, dvResp.isError, dvResp.error as ErrorNode)

  const { pagination, handleTableChange, handleFiltersChange } = useTable<IVendorVariable>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  const emptyTableConf = useEmptyTable()

  const openNotification = useCallback(
    (variableId: number) => {
      const btn = (
        <Button
          type='primary'
          onClick={() => deleteVariable({ vendorId: id as string, variableId })}
        >
          Confirm
        </Button>
      )
      const notificationRef = notification
      notificationRef.open({
        type: 'warning',
        message: 'This variable will be deleted',
        key: MESSAGE_KEY,
        btn,
      })
    },
    [deleteVariable]
  )

  const tableActionsPopup = useCallback(
    (variableId: IVendorVariable['id']): IPopupListItems[] => [
      {
        text: 'View',
        shouldDisplay: true,
        onClick: () => {
          onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_VARIABLES, {
            title: 'Variable',
            vendorId: id,
            variableId,
          })
        },
      },
      {
        text: 'Edit',
        shouldDisplay: true,
        onClick: () => {
          onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_VARIABLES, {
            title: 'Update Variable',
            vendorId: id,
            variableId,
          })
        },
      },
      {
        text: 'Delete',
        shouldDisplay: true,
        onClick: () => openNotification(variableId),
        color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        withDivider: true,
      },
    ],
    [navigate, openNotification]
  )

  const columns: ColumnsType<IVendorVariable> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Key',
      dataIndex: 'key',
      sorter: true,
      width: '25%',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      sorter: true,
      width: '25%',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: true,
      width: '20%',
    },
    {
      title: 'Encrypted',
      dataIndex: 'encrypted',
      sorter: true,
      width: '20%',
      render: (encrypted) => <div>{encrypted.toString()}</div>,
    },
    {
      title: 'System Variable',
      dataIndex: 'systemVariable',
      sorter: true,
      width: '10%',
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '10%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return (
    <>
      <TableHeader
        data={filtersList}
        setActiveFilters={handleFiltersChange}
        numberOfItems={data?.totalCount}
        filterValues={filterValues}
        nameNumberOfItems='Variables'
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        onChange={handleTableChange}
        dataSource={data?.items}
        loading={isFetching}
        scroll={{ x: 1100 }}
        pagination={pagination}
        className={styles.table}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.id}
      />
    </>
  )
}

export default VariablesTable
