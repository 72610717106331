import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import {
  ICreateReverseTransBody,
  IReverseTransaction,
  IReverseTransactionDetail,
  IReverseTransApproveBody,
  ITopup,
  ITopupDetail,
  ITopupSubTransaction,
} from '../../models/IReverseTransaction'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../../shared/models/ITableConf'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { IPickupRequestSub } from '../../models/IPickupRequest'

export const reverseTransactionApi = createApi({
  reducerPath: 'reverseTransaction',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['TopupList', 'ReverseList', 'ITopup', 'IReverseTransDetail'],
  endpoints: (builder) => ({
    getReverseTransactions: builder.query<ITableResponse<IReverseTransaction>, ITableConf>({
      query: (params) => ({
        url: 'reverse-topup',
        params,
      }),
      providesTags: ['ReverseList'],
    }),

    getReverseTransactionById: builder.query<IReverseTransactionDetail, string>({
      query: (id) => ({
        url: `reverse-topup/${id}`,
      }),
      providesTags: ['IReverseTransDetail'],
    }),

    approveReverseTrans: builder.mutation<void, { id: string; body: IReverseTransApproveBody }>({
      query: ({ id, body }) => ({
        url: `reverse-topup/${id}/approve`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ReverseList'],
    }),

    rejectReverseTrans: builder.mutation<void, string>({
      query: (id) => ({
        url: `reverse-topup/${id}/reject`,
        method: 'PUT',
      }),
      invalidatesTags: ['ReverseList'],
    }),

    cancelReverseTrans: builder.mutation<void, string>({
      query: (id) => ({
        url: `reverse-topup/${id}/cancel`,
        method: 'PUT',
      }),
      invalidatesTags: ['ReverseList'],
    }),

    getTopupTransactionsByAgentId: builder.query<ITableResponse<ITopup>, ITableConf>({
      query: ({ search, ...params }) => ({
        url: `topup/for-reverse/${search}`,
        params,
      }),
      providesTags: ['TopupList'],
      keepUnusedDataFor: 0,
    }),

    getTopupTransactionById: builder.query<ITopupDetail, number | null>({
      query: (id) => ({
        url: `topup/${id}`,
      }),
      providesTags: (result) => [{ type: 'ITopup', id: result?.id }],
    }),

    createReverseTransaction: builder.mutation<IReverseTransactionDetail, ICreateReverseTransBody>({
      query: (body) => ({
        url: `reverse-topup`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => [
        'TopupList',
        'ReverseList',
        { type: 'ITopup', id: result?.topupId },
      ],
    }),

    exportReverseTransactionsTable: builder.query<
      BlobPart,
      {
        conf: IFileFormatCsvOrXlsx
        formatFile: 'csv' | 'xlsx'
      }
    >({
      query: ({ conf, formatFile }) => ({
        url: 'reverse-topup/export',
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getTopupFiltersList: builder.query<Array<IFilterFields>, string>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'topup/for-reverse/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `topup/for-reverse/${arg}/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      keepUnusedDataFor: 0,
    }),

    getRTFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'reverse-topup/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `reverse-topup/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      keepUnusedDataFor: 0,
    }),

    getTopupSubTransactions: builder.query<ITableResponse<ITopupSubTransaction>, number>({
      query: (id) => ({
        url: `/topup/${id}/sub-transaction`,
      }),
    }),

    getReverseTopupSubTransactions: builder.query<ITableResponse<IPickupRequestSub>, number>({
      query: (id) => ({
        url: `/reverse-topup/${id}/sub-transaction`,
      }),
    }),
  }),
})

export const {
  useGetReverseTransactionsQuery,
  useGetRTFiltersListQuery,
  useGetReverseTransactionByIdQuery,
  useGetTopupTransactionsByAgentIdQuery,
  useGetTopupFiltersListQuery,
  useGetTopupTransactionByIdQuery,
  useCreateReverseTransactionMutation,
  useApproveReverseTransMutation,
  useRejectReverseTransMutation,
  useCancelReverseTransMutation,
  useLazyExportReverseTransactionsTableQuery,
  useGetTopupSubTransactionsQuery,
  useGetReverseTopupSubTransactionsQuery,
} = reverseTransactionApi
