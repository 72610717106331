import { SALES_NETWORK_CONFIGURATION_TABS_ENUM } from './index.d'
import { NotFoundPage } from '../../../../../NotFoundPage'
import { Tabs } from 'antd/es'
import { AgentTypeTable } from '../AgentTypeTable'
import { Space } from 'antd'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, CloudDownloadIcon } from '../../../../../../assets/svg'
import { AccountTypeTable } from '../AccountTypeTable'
import { useContext, useEffect } from 'react'
import { CenterModalContext } from '../../../../../Modals'
import { CENTER_MODALS } from '../../../../../../helpers/contants'
import { Emitter, EmitterEvents } from '../../../../../../helpers/eventEmitter'
import { TableExportFormatEnum } from '../../../../../../shared/hooks/table/useTableExport'
import { Button } from '../../../../../../shared/components/button/Button'
import { DiscountPolicyTable } from '../DiscountPolicyTable'
import { PromotionPolicyTable } from '../PromotionPolicyTable'
import { useLocation, useNavigate } from 'react-router'
import { SETTINGS_ENUM } from '../../../../../../routes/settings'
import { useSearchParams } from 'react-router-dom'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'
import styles from './styles.module.scss'
import { RewardPolicyTable } from '../RewardPolicyTable'

const Operations = ({ activeTab }: { activeTab: string }) => {
  const navigate = useNavigate()
  const { onOpen, onClose } = useContext(CenterModalContext)

  const handlerExportClick = () => {
    onOpen(CENTER_MODALS.EXPORT_TABLE, {
      title: 'Export Table',
      onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
      onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      footer: <Button onClick={onClose}>Cancel</Button>,
    })
  }

  const onAddButtonClick = () => {
    if (activeTab === SALES_NETWORK_CONFIGURATION_TABS_ENUM.DISCOUNT_POLICY) {
      return navigate(SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION_DISCOUNT_POLICY_NEW)
    }
    if (activeTab === SALES_NETWORK_CONFIGURATION_TABS_ENUM.PROMOTION_POLICY) {
      return navigate(SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION_PROMOTION_POLICY_NEW)
    }
    if (activeTab === SALES_NETWORK_CONFIGURATION_TABS_ENUM.REWARD_POLICY) {
      return navigate(SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION_EWARD_POLICY_NEW)
    }
  }

  return (
    <Space size={10}>
      {activeTab !== SALES_NETWORK_CONFIGURATION_TABS_ENUM.AGENT_TYPE &&
        activeTab !== SALES_NETWORK_CONFIGURATION_TABS_ENUM.ACCOUNT_TYPE && (
          <>
            <IconButton
              color='blue'
              icon={<CloudDownloadIcon />}
              type='primary'
              onClick={handlerExportClick}
            />
            <IconButton
              color='orange'
              icon={<AddIcon />}
              type='primary'
              onClick={onAddButtonClick}
            />
          </>
        )}
    </Space>
  )
}

export const SalesNetworkConfigurationTabs = () => {
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: SALES_NETWORK_CONFIGURATION_TABS_ENUM.AGENT_TYPE,
  })

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  const items = [
    {
      label: SALES_NETWORK_CONFIGURATION_TABS_ENUM.AGENT_TYPE,
      key: SALES_NETWORK_CONFIGURATION_TABS_ENUM.AGENT_TYPE,
      children: <AgentTypeTable />,
    },
    {
      label: SALES_NETWORK_CONFIGURATION_TABS_ENUM.ACCOUNT_TYPE,
      key: SALES_NETWORK_CONFIGURATION_TABS_ENUM.ACCOUNT_TYPE,
      children: <AccountTypeTable />,
    },
    {
      label: SALES_NETWORK_CONFIGURATION_TABS_ENUM.DISCOUNT_POLICY,
      key: SALES_NETWORK_CONFIGURATION_TABS_ENUM.DISCOUNT_POLICY,
      children: <DiscountPolicyTable />,
    },
    {
      label: SALES_NETWORK_CONFIGURATION_TABS_ENUM.PROMOTION_POLICY,
      key: SALES_NETWORK_CONFIGURATION_TABS_ENUM.PROMOTION_POLICY,
      children: <PromotionPolicyTable />,
    },
    {
      label: SALES_NETWORK_CONFIGURATION_TABS_ENUM.REWARD_POLICY,
      key: SALES_NETWORK_CONFIGURATION_TABS_ENUM.REWARD_POLICY,
      children: <RewardPolicyTable />,
    },
  ]

  return (
    <Tabs
      destroyInactiveTabPane
      items={items}
      activeKey={activeTab}
      tabBarExtraContent={<Operations activeTab={activeTab} />}
      onChange={onTabChange}
      className={styles.tabsList}
    />
  )
}
