import styles from './styles.module.scss'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { RightModalContext } from '../../../../../Modals'
import { Form, Spin, UploadFile } from 'antd'
import { Button } from '../../../../../../shared/components/button/Button'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import {
  useCreateCustomerMutation,
  useGetCustomerBankAccountsByIdQuery,
  useGetCustomerByIdQuery,
  useGetCustomerWalletAccountByIdQuery,
  useUpdateCustomerBankAccountMutation,
  useUpdateCustomerByIdMutation,
  useUpdateCustomerWalletAccountMutation,
} from '../../../../core/http/CustomersApi'
import { ICustomer } from '../../../../models/ICustomer'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import ShowMessage, { ErrorNode } from '../../../../../../shared/api/errorHandler'
import { DetailInfoBlock } from './DetailInfoBlock'
import {
  formatDateWithTime,
  formatMomentDateToDayStart,
  getFormCalendarTime,
} from '../../../../../../helpers/utils'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { AddressBlock } from './AddressBlock'
import { CustomerIdentifiersBlock } from './CustomerIdentifiersBlock'
import FormItem from 'antd/es/form/FormItem'
import { CustomerTransactionsBlock } from './CustomerTransactionsBlock'
import { UploaderPhotos2 } from '../../../../../../shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import { RcFile } from 'antd/lib/upload'
import { segmentsApi } from '../../../../core/http/SegmentsApi'
import { useAppDispatch } from '../../../../../../redux'
import { BankAccountDetailBlock } from './BankAccountDetailBlock'
import { WalletAccountsBlock } from './WalletAccountsBlock'

export const CustomerModal = () => {
  const dispatch = useAppDispatch()
  const [picture, setPicture] = useState<RcFile>()
  const [form] = Form.useForm()
  const {
    onOpen,
    props: { id, mode },
  } = useContext(RightModalContext)

  const [updCust, updCustResp] = useUpdateCustomerByIdMutation()
  const [crCust, crCustResp] = useCreateCustomerMutation()
  const [updBA, updBAResp] = useUpdateCustomerBankAccountMutation()
  const [updWA, updWAResp] = useUpdateCustomerWalletAccountMutation()

  const { data, isFetching } = useGetCustomerByIdQuery(id as string, {
    skip: !id,
  })
  const { data: bankAccounts, isFetching: isFetchingAccounts } =
    useGetCustomerBankAccountsByIdQuery(id as string, { skip: !id })
  const { data: customerWallets, isFetching: isFetchingWalletAccounts } =
    useGetCustomerWalletAccountByIdQuery(id as string, { skip: !id })
  const { data: profile } = useGetProfileQuery()

  const afterMutation = () => {
    dispatch(segmentsApi.util.resetApiState())
    return onOpen(RIGHT_MODALS.SALES.CUSTOMER, {
      mode: 'view',
      title: 'Customer',
      id: id ?? crCustResp?.data?.id,
    })
  }

  useNotification(NOTIFICATION_TYPES.success, crCustResp.isSuccess, null, afterMutation)
  useNotification(NOTIFICATION_TYPES.success, updWAResp.isSuccess, null, afterMutation)
  useNotification(NOTIFICATION_TYPES.success, updBAResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, updWAResp.isError, updWAResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, updBAResp.isError, updBAResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, updCustResp.isError, updCustResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, crCustResp.isError, crCustResp.error as ErrorNode)

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'
  const isLoading =
    isFetchingWalletAccounts ||
    isFetching ||
    updCustResp.isLoading ||
    crCustResp.isLoading ||
    isFetchingAccounts ||
    updWAResp.isLoading ||
    updBAResp.isLoading

  const onFinish = (values: ICustomer) => {
    const body = {
      ...values,
      birthDate: formatMomentDateToDayStart(values?.birthDate).toISOString(true),
      status: isEditMode ? !!values?.status : true,
      ...(values?.identifiers && {
        identifiers: values?.identifiers?.map((ident) => ({
          type: ident?.type,
          value: ident?.value?.includes('+') ? ident?.value : `+${ident?.value}`,
        })),
      }),
    } as Partial<ICustomer>

    const identifiersList: Record<string, number> = {}

    body.identifiers?.forEach((ident) => {
      const key = `${ident?.value}-${ident?.type}`

      if (identifiersList[key]) {
        identifiersList[key] += 1
      } else {
        identifiersList[key] = 1
      }
    })

    let hasRepeatingIdentifiers = false
    Object.values(identifiersList).forEach((count) => {
      if (count > 1) {
        hasRepeatingIdentifiers = true
      }
    })

    if (hasRepeatingIdentifiers) {
      ShowMessage('error', 'Identifiers should be unique!')
      return
    }

    const fd = new FormData()

    if (picture) {
      fd.append('avatar', picture!)
    }
    fd.append('customer', JSON.stringify(body))

    if (isEditMode) {
      // BANK ACCOUNTS
      const toDeleteBanks = (bankAccounts || [])
        .filter(
          (dataBranches) =>
            !values.bankAccounts?.some((valueBranch) => valueBranch.id === dataBranches.id)
        )
        .map(({ id }) => ({ bankAccountId: id }))
      const toCreateBanks =
        values.bankAccounts
          ?.filter((bank) => !bank.customerId)
          .map((bank) => ({
            ...bank,
            active: !!bank.active,
            bankBranchId: bank.bankBranch.id,
          })) || []
      const toUpdateBanks =
        values.bankAccounts
          ?.filter((bank) => bank.customerId)
          .map((bank) => ({
            ...bank,
            bankAccountId: bank.id,
            bankBranchId: bank.bankBranch.id,
            active: !!bank.active,
          })) || []

      // WALLET ACCOUNTS
      const toDeleteWallets = (customerWallets || [])
        .filter(
          (dataWallets) => !values.walletAccounts?.some((wallet) => wallet.id === dataWallets.id)
        )
        .map(({ id }) => ({ walletAccountId: id }))
      const toCreateWallets =
        values.walletAccounts
          ?.filter((wallet) => !wallet.customerId)
          .map((wallet) => ({ ...wallet, active: !!wallet.active })) || []
      const toUpdateWallets =
        values.walletAccounts
          ?.filter((wallet) => wallet.customerId)
          .map((wallet) => ({
            walletAccountId: wallet.id,
            mobileWalletProvider: wallet.mobileWalletProvider,
            mobileWalletNumber: wallet.mobileWalletNumber,
            active: !!wallet.active,
          })) || []

      updCust({ id, body: fd })
      updBA({
        customerId: id,
        body: { toCreate: toCreateBanks, toUpdate: toUpdateBanks, toDelete: toDeleteBanks },
      })
      updWA({
        customerId: id,
        body: { toCreate: toCreateWallets, toUpdate: toUpdateWallets, toDelete: toDeleteWallets },
      })

      return
    }

    crCust(fd)
  }

  const onEdit = useCallback(() => {
    if (isViewMode) {
      return onOpen(RIGHT_MODALS.SALES.CUSTOMER, {
        mode: 'edit',
        title: 'Customer',
        id,
      })
    }

    form.submit()
  }, [form, id, isViewMode, onOpen])

  useEffect(() => {
    if (!data) return
    form.setFieldsValue({
      ...data,
      createdAt: formatDateWithTime(data?.createdAt, profile?.calendar),
      updatedAt: formatDateWithTime(data?.updatedAt, profile?.calendar),
      ...(data?.birthDate && {
        birthDate: getFormCalendarTime(profile?.calendar, data?.birthDate),
      }),
      countryId: data.country?.id,
      provinceId: data.province?.id,
      districtId: data.district?.id,
    })
  }, [data, form, profile])

  useEffect(() => {
    if (!bankAccounts) return
    form.setFieldsValue({ bankAccounts })
  }, [bankAccounts, form])

  useEffect(() => {
    if (!customerWallets) return
    form.setFieldsValue({ walletAccounts: customerWallets })
  }, [customerWallets, form])

  const content = (
    <div className={styles.layout}>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        className={styles.form}
        initialValues={{
          createdAt: formatDateWithTime(new Date(), profile?.calendar),
        }}
      >
        <UploaderPhotos2
          form={form}
          title='Customer Picture'
          key='logo'
          isProductPreview
          view={isViewMode}
          defaultPreviewImage={data?.avatar}
          isBig
          onChange={(photo: UploadFile[]) => {
            setPicture(photo[0].originFileObj)
          }}
        />
        <DetailInfoBlock view={isViewMode} isDetailPage={!!id} />
        <AddressBlock view={isViewMode} id={id} />
        {mode && (
          <>
            <BankAccountDetailBlock view={isViewMode} />
            <WalletAccountsBlock view={isViewMode} />
          </>
        )}
        <CustomerIdentifiersBlock view={isViewMode} />
        {id && <CustomerTransactionsBlock phoneNumber={id} />}
        <FormItem>
          <Button color='orange' type='primary' block onClick={onEdit} disabled={isFetching}>
            {!id && 'Create Customer'}
            {isViewMode && 'Edit Customer'}
            {isEditMode && 'Save Changes'}
          </Button>
        </FormItem>
      </Form>
    </div>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
