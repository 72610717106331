import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import {
  useGetCountryListQuery,
  useLazyGetDistrictListQuery,
  useLazyGetProvinceListQuery,
} from '../../../../../../Settings/core/http/VendorManagementApi'
import { useEffect } from 'react'
import { Wrapper } from '../../../../../components/Wrapper'
import styles from '../../../../../../Settings/pages/VendorManagement/VendorManagementView/Components/styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { requiredValidator } from '../../../../../../../helpers/validation'
import { Select as AntSelect } from 'antd'
import { useGetSegmentByIdQuery } from '../../../../../core/http/SegmentsApi'

interface IProps extends IViewMode {
  id?: number
}

export const AddressBlock = ({ view, id }: IProps) => {
  const form = useFormInstance()

  const { data: dataCustomer } = useGetSegmentByIdQuery(id as number, { skip: !id })
  const { data: dataCountry = { items: [] } } = useGetCountryListQuery()
  const [getProvinceByCountryId, { data: dataProvince = { items: [] } }] =
    useLazyGetProvinceListQuery()
  const [getDistrictByProvinceId, { data: dataDistrict = { items: [] } }] =
    useLazyGetDistrictListQuery()

  const onProvinceChange = (value: number) => {
    getDistrictByProvinceId(value)
    form.setFieldValue('districtId', null)
  }

  const clearProvinceAndDistrict = () => {
    form.setFields([
      { name: 'provinceId', value: null, errors: [] },
      { name: 'districtId', value: null, errors: [] },
    ])
  }

  useEffect(() => {
    if (dataCountry && form.getFieldValue('countryId')) {
      getProvinceByCountryId(form.getFieldValue('countryId'))
    }
  }, [dataCountry, form, getProvinceByCountryId])

  useEffect(() => {
    if (dataProvince && form.getFieldValue('provinceId')) {
      getDistrictByProvinceId(form.getFieldValue('provinceId'))
    }
  }, [dataCountry, dataProvince, form, getDistrictByProvinceId, getProvinceByCountryId])

  useEffect(() => {
    if (dataCustomer) {
      getProvinceByCountryId(dataCustomer.country?.id)
      getDistrictByProvinceId(dataCustomer.province?.id)
    }
  }, [dataCustomer, form, getDistrictByProvinceId, getProvinceByCountryId])

  return (
    <Wrapper title='Address'>
      <div className={styles.dblRow}>
        <FormItem
          name='countryId'
          label='Country'
          rules={[{ required: true, validator: requiredValidator('Country') }]}
          className='noBottomMargin'
        >
          <AntSelect
            disabled={view || !dataCountry.items.length}
            onChange={(val) => {
              getProvinceByCountryId(val)
              clearProvinceAndDistrict()
            }}
            onClear={clearProvinceAndDistrict}
            options={dataCountry.items}
            placeholder='Select Country'
            allowClear
          />
        </FormItem>

        <FormItem noStyle shouldUpdate>
          {({ getFieldValue, setFields }) => {
            const isDisabled = view || !dataProvince.items.length || !getFieldValue('countryId')
            return (
              <FormItem
                name='provinceId'
                label='Province'
                style={{ margin: '0 12px' }}
                rules={[
                  !isDisabled ? { required: true, validator: requiredValidator('Province') } : {},
                ]}
                className='noBottomMargin'
              >
                <AntSelect
                  disabled={isDisabled}
                  onChange={(val) => {
                    setFields([{ name: 'districtId', value: null, errors: [] }])
                    onProvinceChange(val)
                  }}
                  onClear={() => {
                    setFields([{ name: 'districtId', value: null, errors: [] }])
                  }}
                  options={dataProvince.items}
                  placeholder='Select Province'
                  allowClear
                />
              </FormItem>
            )
          }}
        </FormItem>

        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const isDisabled = view || !dataDistrict.items.length || !getFieldValue('provinceId')
            return (
              <FormItem
                name='districtId'
                label='City/District'
                rules={[
                  !isDisabled
                    ? { required: true, validator: requiredValidator('City/District') }
                    : {},
                ]}
                className='noBottomMargin'
              >
                <AntSelect
                  disabled={isDisabled}
                  options={dataDistrict.items}
                  placeholder='Select City/District'
                  allowClear
                />
              </FormItem>
            )
          }}
        </FormItem>
      </div>
    </Wrapper>
  )
}
