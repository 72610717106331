import UserListItem from '../UserListItem'
import {
  externalChatApi,
  useArchiveExternalChatMutation,
  useGetExternalChatByIdQuery,
  useGetExternalChatsListQuery,
  useRemoveChatMemberByIdMutation,
  useUpdateChatMemberMutation,
} from '../../../../../../core/http/ExternalChatApi'
import { ISelectedFilters } from '../../../../../../../../shared/components/filter/Filter'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  EXTERNAL_CHAT_LIST_TABS,
  EXTERNAL_CHAT_WS_EVENTS_ENUM,
  IExternalChatTable,
  IUpdateExternalChatMember,
} from '../../../../../../models/IExternalChat'
import { Row, Spin } from 'antd'
import styles from '../UserListItem/styles.module.scss'
import useSearch from '../../../../../../../Settings/helpers/useSearch'
import { ITableConf } from '../../../../../../../../shared/models/ITableConf'
import { TextFieldSearch } from '../../../../../../../../shared/components/textFieldSearch/TextFieldSearch'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../../shared/api/errorHandler'
import { useInfiniteScroll } from '../../../../../../../../shared/hooks/useInfiniteScroll'
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux'
import {
  clearExternalChatDates,
  clearExternalChatMessages,
  selectExternalChatMessagingSlice,
  setExternalChatInfoType,
  setLastReadingAtByCurrentUser,
} from '../../../../../../core/store/ExternalChatMessagingSlice'
import {
  clearExternalChatList,
  selectExternalChatChatsSlice,
  setActiveTab,
  setCurrentExternalChatId,
  setExternalChat,
  setExternalChatList,
  updateExternalChatUserInfo,
} from '../../../../../../core/store/ExternalChatChatsListSlice'
import moment from 'moment'
import { NotFoundPage } from '../../../../../../../NotFoundPage'
import classNames from 'classnames'
import { externalChatSocketConnection } from '../../../../../../../../shared/sockets'

interface IProps {
  selectedFilters?: ISelectedFilters
  activeTab: EXTERNAL_CHAT_LIST_TABS
}

export const UserList = ({ selectedFilters = {}, activeTab }: IProps) => {
  const dispatch = useAppDispatch()
  const { currentUserId } = useAppSelector(selectExternalChatMessagingSlice)
  const {
    chatList,
    externalChat,
    selectedChatId,
    activeTab: activeChatTab,
  } = useAppSelector(selectExternalChatChatsSlice)
  const [isChangeSearch, setIsChangeSearch] = useState(false)
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 15,
    search: '',
  })

  const [updExtChatMemb, updExtChatMembResp] = useUpdateChatMemberMutation()
  const [remExtChatMemb, remExtChatMembResp] = useRemoveChatMemberByIdMutation()
  const [archiveExtChat, archExtChatResp] = useArchiveExternalChatMutation()
  const { data = { items: [], totalCount: 0 }, isFetching } = useGetExternalChatsListQuery(
    {
      type: activeTab,
      params: { ...tableConf, ...selectedFilters },
    },
    { skip: !isChangeSearch && !!tableConf.search }
  )
  const { data: extChat, isFetching: isFetchingExternal } = useGetExternalChatByIdQuery(
    selectedChatId as number,
    {
      skip: !selectedChatId,
    }
  )

  const afterLeaveConversation = () => {
    dispatch(
      externalChatApi.util.invalidateTags([
        { type: 'IExternalChatById', id: remExtChatMembResp?.originalArgs?.chatId },
      ])
    )
    if (
      selectedChatId === Number(remExtChatMembResp?.originalArgs?.chatId) &&
      activeTab === EXTERNAL_CHAT_LIST_TABS.MY
    ) {
      dispatch(clearExternalChatList())
      dispatch(setActiveTab(EXTERNAL_CHAT_LIST_TABS.ALL))
    }
  }

  useNotification(NOTIFICATION_TYPES.success, archExtChatResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.success,
    remExtChatMembResp.isSuccess,
    null,
    afterLeaveConversation
  )
  useNotification(
    NOTIFICATION_TYPES.error,
    updExtChatMembResp.isError,
    updExtChatMembResp.error as ErrorNode
  )
  useNotification(
    NOTIFICATION_TYPES.error,
    archExtChatResp.isError,
    archExtChatResp.error as ErrorNode
  )
  useNotification(
    NOTIFICATION_TYPES.error,
    remExtChatMembResp.isError,
    remExtChatMembResp.error as ErrorNode
  )

  const { handleSearchChange } = useSearch(setTableConf)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(clearExternalChatList())
    handleSearchChange(event)
    setIsChangeSearch(true)
  }

  const updateChatMember = useCallback(
    (body: Partial<IUpdateExternalChatMember>) => {
      updExtChatMemb(body)
    },
    [updExtChatMemb]
  )

  const handleArchiveExtChat = useCallback(
    (id: number, isArchived: boolean) => {
      archiveExtChat({ id, body: { isArchived } })
    },
    [archiveExtChat]
  )

  const removeExtChatMemb = useCallback(() => {
    remExtChatMemb({ chatId: externalChat?.id as number, userId: currentUserId as number })
  }, [currentUserId, externalChat?.id, remExtChatMemb])

  const onChatItemClick = (id: number) => {
    if (externalChat?.id !== id) {
      dispatch(externalChatApi.util.resetApiState())
      dispatch(clearExternalChatDates())
      dispatch(clearExternalChatMessages())
      dispatch(setCurrentExternalChatId(id))
      dispatch(setExternalChatInfoType('Info'))
    }
  }

  const loadMoreData = useCallback(() => {
    if (!isChangeSearch && chatList.length < data.totalCount) {
      setTableConf((pre: ITableConf) => ({ ...pre, page: pre.page! + 1 }))
    }
  }, [chatList.length, data.totalCount, isChangeSearch])

  const loaderRef = useRef<HTMLDivElement | null>(null)

  const isUserInChat = useMemo(
    () => !!externalChat?.members?.find((memb) => memb?.userId === currentUserId),
    [currentUserId, externalChat?.members]
  )

  useEffect(() => {
    if (!data.items.length) return

    dispatch(setExternalChatList(data.items))
    setIsChangeSearch(false)
  }, [data.items, dispatch])

  useEffect(() => {
    if (!archExtChatResp?.data) return
    const connection = externalChatSocketConnection.getSocket()
    setTableConf((prevState) => ({
      ...prevState,
      page: 1,
    }))

    if (archExtChatResp.data.isArchived) {
      dispatch(clearExternalChatList())
      dispatch(setActiveTab(EXTERNAL_CHAT_LIST_TABS.ARCHIVED))

      connection?.emit(EXTERNAL_CHAT_WS_EVENTS_ENUM.ARCHIVE_CHAT, {
        chatId: archExtChatResp?.data?.id,
      })
    } else {
      dispatch(clearExternalChatList())
      dispatch(setActiveTab(EXTERNAL_CHAT_LIST_TABS.MY))

      connection?.emit(EXTERNAL_CHAT_WS_EVENTS_ENUM.UN_ARCHIVE_CHAT, {
        chatId: archExtChatResp?.data?.id,
      })
    }
  }, [archExtChatResp?.data, dispatch])

  useEffect(() => {
    if (updExtChatMembResp?.data) {
      dispatch(updateExternalChatUserInfo(updExtChatMembResp?.data))
    }
  }, [dispatch, updExtChatMembResp?.data])

  useEffect(() => {
    if (!extChat || isFetchingExternal) return

    if (extChat.id !== selectedChatId) return

    dispatch(setExternalChat(extChat))

    const userChat = chatList?.find((chatItem) => chatItem?.id === extChat?.id)

    /** IF THE LAST MESSAGE IN CHAT WAS CREATED BY THE CURRENT USER
     * WE SHOULD LOAD MESSAGES BEFORE AND AFTER THIS MESSAGE
     * DATE CREATION */
    if (
      userChat?.lastExternalChatMessage?.createdByUserId === currentUserId &&
      userChat?.lastExternalChatMessage?.createdAt
    ) {
      dispatch(setLastReadingAtByCurrentUser(userChat?.lastExternalChatMessage?.createdAt))
      return
    }

    /** IF THE LAST MESSAGE IN CHAT WAS NOT CREATED BY THE CURRENT USER
     * WE SHOULD LOAD MESSAGES BEFORE AND AFTER USER LAST READING AT DATE
     * */
    if (extChat?.lastReadingByUserAt) {
      dispatch(setLastReadingAtByCurrentUser(extChat?.lastReadingByUserAt))
      return
    }

    if (extChat?.firstMessageDate) {
      dispatch(setLastReadingAtByCurrentUser(extChat?.firstMessageDate))
    }
  }, [extChat, isFetchingExternal])

  useInfiniteScroll({
    loaderRef,
    mappedArrLength: chatList?.length,
    totalCount: data?.totalCount,
    callback: loadMoreData,
  })

  const sortedChats = useMemo(() => {
    const pinned: Array<IExternalChatTable> = []
    const notPinned: Array<IExternalChatTable> = []
    chatList?.forEach((el) => {
      if (
        activeTab === EXTERNAL_CHAT_LIST_TABS.MY &&
        el?.member?.userId === currentUserId &&
        el?.member?.isPinned
      ) {
        pinned.push(el)
      } else {
        notPinned.push(el)
      }
    })

    const withMessage = notPinned
      .filter((el) => el?.lastExternalChatMessage)
      .sort((a, b) =>
        moment(b?.lastExternalChatMessage?.createdAt).diff(
          moment(a?.lastExternalChatMessage?.createdAt)
        )
      )
    const withoutMessage = notPinned.filter((el) => !el.lastExternalChatMessage)

    return [...pinned, ...withMessage, ...withoutMessage]
  }, [activeTab, chatList, currentUserId])

  return (
    <div>
      <TextFieldSearch
        className={styles.search}
        onChange={(e) => {
          handleSearch(e)
        }}
      />
      {!sortedChats.length && !isFetching ? (
        <div
          className={classNames(styles.userListBlock, { [styles.emptyList]: !sortedChats.length })}
        >
          <NotFoundPage text='There is no agent' />
        </div>
      ) : (
        <div style={{ overflowY: 'auto', height: 'calc(100vh - 216px)' }}>
          {sortedChats?.map((user, i) => {
            const isChatDayTheSame =
              sortedChats[i + 1] || sortedChats.at(-1)
                ? moment(user?.lastExternalChatMessage?.createdAt).isSame(
                    moment(sortedChats[i + 1]?.lastExternalChatMessage?.createdAt),
                    'day'
                  )
                : true

            return (
              <UserListItem
                key={user?.id}
                user={user}
                updateChatMember={updateChatMember}
                onChatItemClick={onChatItemClick}
                archiveExtChat={handleArchiveExtChat}
                isSelected={user?.id === externalChat?.id}
                hasSession={externalChat?.id === user?.id && externalChat?.hasSession}
                currentUserId={currentUserId}
                activeTab={activeTab}
                isChatDayTheSame={isChatDayTheSame}
                removeExtChatMemb={removeExtChatMemb}
                isUserInChat={isUserInChat}
              />
            )
          })}
          <div ref={loaderRef} />
          <Row justify='center'>
            <Spin spinning={isFetching} />
          </Row>
        </div>
      )}
    </div>
  )
}
