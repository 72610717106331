import FormItem from 'antd/es/form/FormItem'
import { Form } from 'antd'
import {
  negativeNumberInArrValidator,
  totalNumberValidator,
} from '../../../../../../../../helpers/validation'
import styles from '../../../styles.module.scss'
import {
  IPurchasePlanDistributionRange,
  PURCHASE_PLAN_TIME_OPTIONS,
} from '../../../../../../models/IInventory'
import { RangePicker } from '../../../../../../../../shared/components/datePicker'
import { TextField } from '../../../../../../../../shared/components/textfield/TextField'
import { AddIcon, CloseIcon } from '../../../../../../../../assets/svg'
import { IconButton } from '../../../../../../../../shared/components/iconButton/IconButton'
import { DATE_RANGE_LABEL_NAME } from '../index.d'
import moment, { Moment } from 'moment'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useCallback } from 'react'
import classNames from 'classnames'
import { formatMomentDateToDayStart } from '../../../../../../../../helpers/utils'

export const DateRangeFormList = () => {
  const form = useFormInstance()

  const disabledDate = useCallback(
    (current: Moment) => {
      const distributionRanges = form.getFieldValue(
        'distributionRanges'
      ) as Array<IPurchasePlanDistributionRange>
      const selectedMonth = moment(form.getFieldValue('monthRange')).month()
      const selectedYear = moment(form.getFieldValue('monthRange')).year()

      // Disable dates outside of the selected month
      if (current.month() !== selectedMonth || current.year() !== selectedYear) {
        return true
      }

      // Disable dates that fall within the selected date ranges
      for (const range of distributionRanges) {
        if (range?.date && range?.date[0] && range?.date[1]) {
          if (
            current.isBetween(
              formatMomentDateToDayStart(range?.date[0]),
              formatMomentDateToDayStart(range?.date[1]),
              'day',
              '[]'
            )
          ) {
            return true
          }
          if (current.isSame(range?.date[0], 'day') || current.isSame(range?.date[1], 'day')) {
            return false
          }
        }
      }

      // Enable all other dates
      return false
    },
    [form]
  )

  const getFieldLabel = useCallback((pickerType: PURCHASE_PLAN_TIME_OPTIONS, name: number) => {
    if (pickerType === PURCHASE_PLAN_TIME_OPTIONS.MANUALLY_PER_WEEK) {
      return `${DATE_RANGE_LABEL_NAME.WEEK} ${name + 1}`
    }
    if (pickerType === PURCHASE_PLAN_TIME_OPTIONS.MANUALLY_PER_DAY) {
      return `${DATE_RANGE_LABEL_NAME.DAY} ${name + 1}`
    }
    if (pickerType === PURCHASE_PLAN_TIME_OPTIONS.MANUALLY_CUSTOM) {
      return `${DATE_RANGE_LABEL_NAME.CUSTOM} ${name + 1}`
    }
  }, [])

  const handleChangeAmount = (index: number, value?: string) => {
    const amount = Number(value)
    const totalAmount = Number(form.getFieldValue('cashAmount'))

    if (!isNaN(amount) && !isNaN(totalAmount) && totalAmount > 0 && amount >= 0) {
      const percent = (amount / totalAmount) * 100
      return form.setFieldValue(['distributionRanges', index, 'percent'], percent.toFixed(2))
    }

    return form.setFieldValue(['distributionRanges', index, 'percent'], null)
  }

  const handleChangePercent = (index: number, value?: string) => {
    const percent = Number(value)
    const totalAmount = Number(form.getFieldValue('cashAmount'))

    if (!isNaN(percent) && !isNaN(totalAmount) && totalAmount > 0 && percent >= 0) {
      const amount = (percent / 100) * totalAmount
      return form.setFieldValue(['distributionRanges', index, 'amount'], amount.toFixed(2))
    }

    return form.setFieldValue(['distributionRanges', index, 'amount'], null)
  }

  return (
    <FormItem noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const isNumberFieldDisabled = +getFieldValue('cashAmount') < 0
        return (
          <Form.List
            name='distributionRanges'
            rules={[
              {
                validator: totalNumberValidator(
                  100,
                  getFieldValue('distributionRanges') || [],
                  'percent'
                ),
              },
              {
                validator: totalNumberValidator(
                  getFieldValue('cashAmount'),
                  getFieldValue('distributionRanges') || [],
                  'amount'
                ),
              },
              {
                validator: negativeNumberInArrValidator(
                  getFieldValue('distributionRanges') || [],
                  'percent'
                ),
              },
              {
                validator: negativeNumberInArrValidator(
                  getFieldValue('distributionRanges') || [],
                  'amount'
                ),
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <div className={styles.modalDateRange}>
                {fields.map(({ name, key }) => (
                  <div key={key} className={styles.modalDateRangeItem}>
                    <div className={styles.dateRangeTitle}>
                      {getFieldLabel(getFieldValue('timeOption'), name)}
                    </div>
                    <div className={styles.dateRangeBody}>
                      <FormItem
                        name={[name, 'date']}
                        className={classNames('noBottomMargin', styles.dateRangePicker)}
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <RangePicker
                          disabled={
                            !getFieldValue('monthRange') ||
                            getFieldValue('timeOption') !==
                              PURCHASE_PLAN_TIME_OPTIONS.MANUALLY_CUSTOM
                          }
                          disabledDate={(current) => disabledDate(current as Moment)}
                        />
                      </FormItem>

                      <FormItem
                        name={[name, 'percent']}
                        className='noBottomMargin'
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <TextField
                          onChange={(event) => {
                            form.validateFields(['distributionRanges'])
                            handleChangePercent(name, event?.target.value)
                          }}
                          type='number'
                          disabled={isNumberFieldDisabled}
                          placeholder='Percent'
                        />
                      </FormItem>
                      <FormItem
                        name={[name, 'amount']}
                        className='noBottomMargin'
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <TextField
                          onChange={(event) => {
                            form.validateFields(['distributionRanges'])
                            handleChangeAmount(name, event.target.value)
                          }}
                          type='number'
                          disabled={isNumberFieldDisabled}
                          placeholder='Amount'
                        />
                      </FormItem>
                      <div className={styles.removeButton} onClick={() => remove(name)}>
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                ))}
                {getFieldValue('timeOption') === PURCHASE_PLAN_TIME_OPTIONS.MANUALLY_CUSTOM && (
                  <IconButton icon={<AddIcon />} onClick={() => add()} color='orange' block>
                    Add date range
                  </IconButton>
                )}
                <div style={{ color: 'red' }}>
                  {errors.map((error, i) => (
                    <div key={i}>{error}</div>
                  ))}
                </div>
              </div>
            )}
          </Form.List>
        )
      }}
    </FormItem>
  )
}
