import { TRANSACTION_STATUS_ENUM } from '../../Finance/models/ITransaction'

export interface IWhatsappManagerAction {
  id: string
  description: string
}

export interface IWhatsappManagerButton {
  id: string
  title: string
  actionId: string
}

export interface IWhatsappManagerNotificationBtn {
  id: string
  name: string
  action: string
  transactionStatus: TRANSACTION_STATUS_ENUM
  whatsappButtons: Array<Omit<IWhatsappManagerButton, 'actionId'>>
}

export enum WHATSAPP_BUTTON_MODAL_TABS {
  BUTTONS = 'Buttons',
  ACTIONS = 'Actions',
}

export enum WHATSAPP_BUTTON_MODAL_STEPS {
  LIST = 'LIST',
  VIEW_BUTTON = 'VIEW_BUTTON',
  VIEW_ACTION = 'VIEW_ACTION',
}
