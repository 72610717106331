import { useNavigate, useParams } from 'react-router'
import { Form, Spin } from 'antd'
import React, { useCallback, useEffect } from 'react'
import {
  formatDateMomentToISO,
  formatDateWithTime,
  getFormCalendarTime,
} from '../../../../../../../helpers/utils'
import styles from '../../../styles.module.scss'
import { Footer } from '../Footer'
import {
  useDeletePromotionPolicyMutation,
  useGetPromotionPolicyByIdQuery,
  useUpdatePromotionPolicyMutation,
} from '../../../../../core/http/PromotionPolicyApi'
import { DetailInfoBlock } from './DetailInfoBlock'
import { ConditionsBlock } from './ConditionsBlock'
import { SETTINGS_ENUM } from '../../../../../../../routes/settings'
import { ErrorNode, showConfirmMessage } from '../../../../../../../shared/api/errorHandler'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { IPromotionPolicy, PromotionPolicyCreateType } from '../../../../../models/IPromotionPolicy'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { Moment } from 'moment'

export const InformationTab = () => {
  const navigate = useNavigate()
  const { id, mode } = useParams<{ id: string; mode: 'view' | 'edit' }>()
  const [form] = Form.useForm()

  const { data: profile, isFetching: isFetchingProfile } = useGetProfileQuery()

  const { data, isFetching } = useGetPromotionPolicyByIdQuery(id as string)
  const [delProm, delPromResp] = useDeletePromotionPolicyMutation()
  const [upProm, upPromResp] = useUpdatePromotionPolicyMutation()

  useNotification(NOTIFICATION_TYPES.success, delPromResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, upPromResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, delPromResp.isError, delPromResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, upPromResp.isError, upPromResp.error as ErrorNode)

  const isViewMode = mode === 'view'

  const setInitialValues = useCallback(() => {
    if (data && profile) {
      const { start, end, createdAt, ...rest } = data
      form.setFieldsValue({
        start: getFormCalendarTime(profile?.calendar, start as string),
        end: getFormCalendarTime(profile?.calendar, end as string),
        createdAt: formatDateWithTime(createdAt, profile?.calendar),
        ...rest,
      })
    }
  }, [data, form, profile])

  useEffect(() => {
    setInitialValues()
  }, [setInitialValues])

  const onCancelClick = () => {
    if (isViewMode) {
      return navigate(SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION)
    }

    form.resetFields()
    setInitialValues()
    return navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/promotion-policy/view/${id}`)
  }

  const onSubmitClick = () => {
    if (isViewMode) {
      return navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/promotion-policy/edit/${id}`)
    }

    return form.submit()
  }

  const openNotification = useCallback(() => {
    showConfirmMessage('This promotion policy will be deleted', () => delProm(id as string))
  }, [delProm, id])

  const onFinish = (values: IPromotionPolicy) => {
    const editableReq = {
      name: values.name,
      amount: +values.amount,
      measurementType: values.measurementType,
      event: values.event,
      start: formatDateMomentToISO(profile?.calendar, values.start as Moment),
      end: formatDateMomentToISO(profile?.calendar, values.end as Moment),
      ...(values?.pickupFrom &&
        values.pickupTo && { pickupFrom: +values.pickupFrom, pickupTo: +values.pickupTo }),
      isOnce: !!values.isOnce,
      ...(values?.reactivationInterval && { reactivationInterval: +values.reactivationInterval }),
      ...(values?.walletAboveAmount && { walletAboveAmount: +values?.walletAboveAmount }),
      ...(values?.accessChannels && { accessChannels: values?.accessChannels }),
    } as PromotionPolicyCreateType

    const disabledEditReq = {
      name: values.name,
      start: formatDateMomentToISO(profile?.calendar, values.start as Moment),
      end: formatDateMomentToISO(profile?.calendar, values.end as Moment),
      ...(values?.pickupFrom &&
        values.pickupTo && { pickupFrom: +values.pickupFrom, pickupTo: +values.pickupTo }),
    } as PromotionPolicyCreateType

    upProm({ id: id as string, body: data?.isEditable ? editableReq : disabledEditReq })
  }

  useEffect(() => {
    if (delPromResp.isSuccess) {
      navigate(SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION)
    }
    // eslint-disable-next-line
  }, [delPromResp.isSuccess])

  useEffect(() => {
    if (upPromResp.isSuccess) {
      navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/promotion-policy/view/${id}`)
    }
    // eslint-disable-next-line
  }, [upPromResp.isSuccess])

  const content = (
    <Form form={form} layout='vertical' className={styles.promotionInfoForm} onFinish={onFinish}>
      <DetailInfoBlock view={isViewMode} isEditable={data?.isEditable} />
      <ConditionsBlock view={isViewMode} isEditable={data?.isEditable} />
    </Form>
  )

  const isLoading = isFetching || upPromResp.isLoading || delPromResp.isLoading || isFetchingProfile

  return (
    <div className='fullVH'>
      {isLoading ? <Spin>{content}</Spin> : content}
      <Footer
        onCancelClick={onCancelClick}
        onSubmitClick={onSubmitClick}
        onDeleteClick={openNotification}
        mode={mode}
      />
    </div>
  )
}
