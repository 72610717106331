import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { IIntegrationTable } from '../../models/IIntegration'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

export const integrationsApi = createApi({
  reducerPath: 'integrations',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getIntegrationList: builder.query<ITableResponse<IIntegrationTable>, ITableConf>({
      query: (params) => ({
        url: 'product-integration',
        params,
      }),
    }),

    getIntegrationFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'product-integration/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `product-integration/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),
  }),
})

export const { useGetIntegrationListQuery } = integrationsApi
