import { useMemo } from 'react'
import styles from '../../styles.module.scss'
import { Form, Select } from 'antd'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { Wrapper } from '../../../../../../../shared/components/wrapper/Wrapper'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import { RESPONSE_CODE_TYPE_ENUM } from '../../../../../models/IResponseCode'
import { startCase } from 'lodash'
import { minMaxLengthValidator, requiredValidator } from '../../../../../../../helpers/validation'

export const SystemResponse = ({ view }: IViewMode) => {
  const responseTypes = useMemo(
    () =>
      (Object.keys(RESPONSE_CODE_TYPE_ENUM) as Array<keyof typeof RESPONSE_CODE_TYPE_ENUM>).map(
        (method) => ({
          label: `${startCase(RESPONSE_CODE_TYPE_ENUM[method].toLowerCase())} Response`,
          value: RESPONSE_CODE_TYPE_ENUM[method],
        })
      ),
    []
  )

  return (
    <Wrapper title='System Response'>
      <div className={styles.dblRow}>
        <Form.Item
          name='code'
          label='Response Code'
          className='noBottomMargin'
          rules={[
            { required: true, validator: requiredValidator('Response Code') },
            { validator: minMaxLengthValidator(4, 4) },
          ]}
        >
          <TextField disabled={view} placeholder='Enter Response Code' />
        </Form.Item>

        <Form.Item
          name='responseType'
          label='Response Type'
          className='noBottomMargin'
          rules={[{ required: true, validator: requiredValidator('Response Type') }]}
        >
          <Select disabled={view} placeholder='Select Response Code' options={responseTypes} />
        </Form.Item>
      </div>
    </Wrapper>
  )
}
