import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { EmojiClickData } from 'emoji-picker-react'

import { useCreateNewTextMessageMutation } from '../../../../core/http/InternalMessagingApi'
import { IInternalChatMessage } from '../../../../models'
// import { chatSockets } from '../../../../../../shared/sockets'
import {
  EmojiSelect,
  AttachFile,
  AttachedFiles,
  MessageToReply,
  // VoiceMessage
} from 'src/shared/components/chat/Components'

import SubmitInput from './Components/SubmitInput'
import { updateChatLastMessage } from '../../../../core/store/Chats'
import { useAppDispatch } from '../../../../../../redux'
import { Spin } from 'antd'
import { rootSocketConnection } from '../../../../../../shared/sockets'
import styles from './styles.module.scss'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../shared/api/errorHandler'
import { SendPlaneIcon } from '../../../../../../assets/svg'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'

interface IProps {
  currentChatId: number
  messageToReply: Partial<IInternalChatMessage> | null
  scrollToMyRef: () => void
  clearReply: (message: Partial<IInternalChatMessage> | null) => void
  onMessageCreated: (message: IInternalChatMessage) => void
}

const urlRegex = new RegExp(
  '(^|[ \t\r\n])((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|aim|webcal):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))',
  'g'
)

export const AddMessageTools = ({
  currentChatId,
  messageToReply,
  scrollToMyRef,
  onMessageCreated,
  clearReply,
}: IProps) => {
  const dispatch = useAppDispatch()

  const [message, setMessage] = useState('')
  const [files, setFiles] = useState<FileList | Array<File> | null>(null)

  const [createMessage, { data, isSuccess, isLoading, error, isError }] =
    useCreateNewTextMessageMutation()

  useNotification(NOTIFICATION_TYPES.error, isError, error as ErrorNode)

  useEffect(() => {
    if (isSuccess) {
      setMessage('')
      setFiles(null)
      clearReply(null)
      onMessageCreated(data as IInternalChatMessage)
      if (data) {
        dispatch(updateChatLastMessage({ message: data }))
      }
      const timeout = setTimeout(() => {
        scrollToMyRef()
      }, 500)
      return () => clearTimeout(timeout)
    }
    // eslint-disable-next-line
  }, [data, dispatch])

  const onChangeMessage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const socket = rootSocketConnection.getSocket()
      // chatSockets.manager?.emit('typing', {
      //   toUserId: currentChatId,
      // })
      socket?.emit('typing', {
        toUserId: currentChatId,
      })
      setMessage(e.target.value)
    },
    [currentChatId]
  )

  const onSendMessage = useCallback(() => {
    if ((!message && !files?.length) || !currentChatId) return
    const req: {
      toUserId: number
      message?: string
      replyToMessageId?: number
      links?: Array<string>
    } = {
      toUserId: currentChatId,
    }
    if (message) {
      req.message = message
    }
    if (messageToReply) {
      req.replyToMessageId = messageToReply.id
    }

    const linksInMessage = message.match(urlRegex)?.map((link) => link.trim())

    if (linksInMessage) {
      req.links = linksInMessage
    }

    const formData = new FormData()
    formData.append('internalChatMessage', JSON.stringify(req))
    if (files?.length) {
      for (const file of files) {
        formData.append('files', file)
      }
    }

    createMessage(formData)
  }, [createMessage, currentChatId, files, message, messageToReply])

  const onAttachFiles = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFiles((prev) => {
      if (!prev?.length) {
        return e.target.files
      }
      return [...prev, ...(e.target.files as FileList)]
    })
  }, [])

  const onRemoveAttachment = useCallback((name: string) => {
    setFiles((prev) => {
      if (prev?.length) {
        return [...prev].filter((s) => s.name !== name)
      }
      return null
    })
  }, [])

  const onSelectEmoji = useCallback((em: EmojiClickData) => {
    setMessage((prev) => `${prev} ${em.emoji}`)
  }, [])

  const filesNames = useMemo(() => {
    const names = []
    if (files?.length) {
      for (const file of files) {
        names.push({ name: file.name })
      }
    }
    return names
  }, [files])

  return (
    <div style={{ position: 'relative', paddingTop: '16px' }}>
      <div className={styles.inputToolInfo}>
        {isLoading && (
          <div className={styles.messageLoading}>
            <Spin size='small' />
            <div className={styles.loadingTitle}>Loading</div>
          </div>
        )}
        {!isLoading && filesNames.length > 0 ? (
          <AttachedFiles filesNames={filesNames} onRemoveAttachment={onRemoveAttachment} />
        ) : null}
        {messageToReply && <MessageToReply message={messageToReply} clearReply={clearReply} />}
      </div>
      <div className={styles.addMessageToolsContainer} id='int-chat-add-message-input-container'>
        <SubmitInput
          isLoading={isLoading}
          message={message}
          onSendMessage={onSendMessage}
          onChangeMessage={onChangeMessage}
          setMessage={setMessage}
        />
        <div className={styles.actionsContainer}>
          <AttachFile onAttachFiles={onAttachFiles} key='internal' />
          <EmojiSelect onSelectEmoji={onSelectEmoji} />
          <div className={styles.messageAction}>
            <IconButton
              type='primary'
              color='blue'
              loading={isLoading}
              icon={<SendPlaneIcon />}
              onClick={onSendMessage}
              disabled={(!message && !files?.length) || !currentChatId}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
