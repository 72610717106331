import styles from '../style.module.scss'
import { Form, Select, Spin } from 'antd'
import { TextField } from '../../../../../../../../../shared/components/textfield/TextField'
import { Wrapper } from '../../../../../../../../salesNetwork/components/Wrapper'
import { IViewMode } from '../../../../../../../../Finance/models/IViewMode'
import { useGetUsersListQuery } from '../../../../../../../core/http/UsersManagementApi'
import { useMemo } from 'react'
import {
  maxLengthValidator,
  requiredValidator,
} from '../../../../../../../../../helpers/validation'

interface IProps extends IViewMode {
  isCreateMode?: boolean
}

export const DetailInfo = ({ view, isCreateMode }: IProps) => {
  const { data, isFetching } = useGetUsersListQuery({})

  const userOptions = useMemo(
    () =>
      data?.items.map((user) => ({
        label: `${user?.firstName} ${user?.lastName}`,
        value: user?.id,
      })),
    [data?.items]
  )

  const content = (
    <Wrapper title='Detail Info'>
      {!isCreateMode && (
        <div className={styles.dblRow}>
          <div className={styles.doubleRow}>
            <Form.Item name='id' label='Position ID'>
              <TextField disabled />
            </Form.Item>

            <Form.Item name='createdAt' label='Creation Date'>
              <TextField disabled />
            </Form.Item>
          </div>

          <div className={styles.fullWidth}>
            <Form.Item name={['createdBy', 'name']} label='Created By'>
              <TextField disabled />
            </Form.Item>
          </div>
        </div>
      )}

      <div className={styles.doubleRow}>
        <Form.Item
          name='name'
          label='Position Name'
          className={styles.noBottomMargin}
          rules={[
            {
              required: true,
              validator: requiredValidator('Position Name'),
            },
            {
              validator: maxLengthValidator(50),
            },
          ]}
        >
          <TextField disabled={view} placeholder='Enter Position Name' />
        </Form.Item>

        <Form.Item
          name={['supervisor', 'id']}
          label='Supervisor'
          className={styles.noBottomMargin}
          rules={[
            {
              required: true,
              validator: requiredValidator('Supervisor'),
            },
          ]}
        >
          <Select
            disabled={view}
            options={userOptions}
            allowClear
            placeholder='Select Supervisor'
          />
        </Form.Item>
      </div>
    </Wrapper>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
