import { ISelectedFilters } from '../components/filter/Filter'

export enum SortingOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ITableConf extends ISelectedFilters {
  page?: number
  limit?: number
  orderField?: string
  orderType?: SortingOrderEnum
  id?: number | string
  search?: string
}

export interface IFileFormatCsvOrXlsx extends ITableConf {
  format: 'csv' | 'xlsx'
}

export type SetState<T> = React.Dispatch<React.SetStateAction<T>>
