import styles from '../styles.module.scss'
import { InfoMessage } from '../../../../../../../Settings/pages/VendorManagement/VendorManagementContactListModal/Components/InfoMessage'
import { ModalHeader } from '../ModalHeader'
import { InfiniteScrollComponent } from '../../../../../../../../shared/components/infiniteScoll/InfiniteScroll'
import React from 'react'
import { ISelectedFilters } from '../../../../../../../../shared/components/filter/Filter'
import { IFilterFields } from '../../../../../../../../shared/models/IFilterFields'
import {
  CreateReverseTransStepType,
  ITopup,
} from '../../../../../../../Finance/models/IReverseTransaction'
import { ITableResponse } from '../../../../../../../../shared/models/ITableResponse'
import { TopupListItem } from './TopupListItem'
import { TRANSACTION_STATUS_ENUM } from '../../../../../../../Finance/models/ITransaction'
import { Spin } from 'antd'
import { isNumber } from 'lodash'

interface IProps {
  loadMoreData: () => void
  searchQuery?: string
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeStep: (step: CreateReverseTransStepType, topupId: number | null) => void
  filterValues: ISelectedFilters
  filterFields?: IFilterFields[]
  setActiveFilters: (values: ISelectedFilters) => void
  dataTopupTrans: ITopup[]
  data: ITableResponse<ITopup>
  isFetching?: boolean
}

export const SearchStep = ({
  loadMoreData,
  onSearchChange,
  filterValues,
  filterFields,
  setActiveFilters,
  dataTopupTrans,
  handleChangeStep,
  searchQuery,
  data,
}: IProps) => {
  const content = (
    <InfiniteScrollComponent
      loadMoreData={loadMoreData}
      dataLength={dataTopupTrans.length}
      totalCount={data?.totalCount}
      containerStyles={{ padding: '24px 24px 24px 32px' }}
    >
      <InfoMessage text='Transaction' isAccount={false} />
      <ModalHeader
        filterValues={filterValues}
        data={filterFields}
        setActiveFilters={setActiveFilters}
        onSearchChange={onSearchChange}
        searchQuery={searchQuery}
      />
      <div className={styles.amountTransactions}>
        {searchQuery ? data?.totalCount : 0} Transactions
      </div>
      {dataTopupTrans?.map((topup) => {
        const shouldDisplayCreate = topup.status === TRANSACTION_STATUS_ENUM.SUCCESS
        return (
          <TopupListItem
            key={topup.id}
            topup={topup}
            handleChangeStep={handleChangeStep}
            shouldDisplayCreate={shouldDisplayCreate}
          />
        )
      })}
    </InfiniteScrollComponent>
  )
  return !isNumber(data?.totalCount) ? <Spin>{content}</Spin> : content
}
