import React, { ChangeEvent } from 'react'
import { Col, Row } from 'antd'
import styles from './style.module.scss'
import { TextFieldSearch } from '../../../../../../shared/components/textFieldSearch/TextFieldSearch'
import { Filter, ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { IFilterFields } from '../../../../../../shared/models/IFilterFields'
import { useFilter } from '../../../../../../shared/hooks/filter/useFilter'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { AddIcon } from '../../../../../../assets/svg'
import { FilterPopup } from '../../../../../../shared/components/filter/FilterPopup'
import classNames from 'classnames'

interface IProps {
  setActiveFilters: (values: ISelectedFilters) => void
  filterValues: ISelectedFilters
  data?: IFilterFields[]
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void
  onAddButtonClick: () => void
  classNameFilterPopup?: string
  isDiscountPolicy: boolean
}

export const TargetListModalHeader = ({
  setActiveFilters,
  data,
  filterValues,
  onSearchChange,
  onAddButtonClick,
  classNameFilterPopup,
  isDiscountPolicy,
}: IProps): JSX.Element => {
  const { isOpen, onCancel, form, onReset, onFinish } = useFilter({ setActiveFilters })

  return (
    <>
      <Row align='middle' justify='space-between' style={{ marginBottom: 20, width: '100%' }}>
        <TextFieldSearch
          onChange={onSearchChange}
          className={classNames(styles.search, { [styles.promotionSearch]: !isDiscountPolicy })}
        />
        {!isDiscountPolicy && (
          <IconButton
            icon={<AddIcon />}
            type='primary'
            color='orange'
            size='large'
            onClick={onAddButtonClick}
            className={styles.addButton}
          />
        )}
      </Row>
      {!isDiscountPolicy && (
        <>
          <Row align='middle' justify='space-between' style={{ marginBottom: 20, width: '100%' }}>
            <Col style={{ display: 'flex', gridGap: 20 }}>
              <Filter
                data={data}
                form={form}
                setIsOpen={onCancel}
                selectedFilters={filterValues}
                setSelectedFilters={setActiveFilters}
                classNamesFilterWrapperIcon={styles.filterIcon}
                sizeFilter='large'
              />
            </Col>
          </Row>
          {isOpen && (
            <FilterPopup
              className={classNameFilterPopup}
              data={data}
              setActiveFilters={setActiveFilters}
              filterValues={filterValues}
              form={form}
              onCancel={onCancel}
              onReset={onReset}
              onFinish={onFinish}
            />
          )}
        </>
      )}
    </>
  )
}
