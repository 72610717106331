import { useCallback, useState } from 'react'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import { useGetQueryParams } from '../../../../../../shared/hooks/table/useGetQueryParams'
import {
  useDeleteRewardPolicyMutation,
  useGetRewardPolicyListQuery,
  useLazyExportRewardPolicyTableQuery,
  useUpdateRewardPolicyMutation,
} from '../../../../core/http/RewardPolicyApi'
import { useTableExport } from '../../../../../../shared/hooks/table/useTableExport'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import useTable from '../../../../../../shared/hooks/table/useTable'
import { IPromotionPolicy } from '../../../../models/IPromotionPolicy'
import { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import { SETTINGS_ENUM } from '../../../../../../routes/settings'
import { DateTableIcon, TableActionIcon } from '../../../../../../assets/svg'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../shared/components/popup/Popup'
import { IRewardPolicyTable } from '../../../../models/IRewardPolicy'
import { useNavigate } from 'react-router'
import { useGetProfileQuery } from '../../../../../Profile/core/http/Profile'
import { ErrorNode, showConfirmMessage } from '../../../../../../shared/api/errorHandler'
import { Table } from 'antd'
import styles from '../../styles.module.scss'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { formatDateWithTime } from '../../../../../../helpers/utils'
import emptyProductImage from 'src/assets/img/ProductTablePlaceholder.png'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

export const RewardPolicyTable = () => {
  const navigate = useNavigate()
  const { queryParams } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'id',
  })
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data: profile } = useGetProfileQuery()
  const { data, isFetching } = useGetRewardPolicyListQuery(
    { ...tableConf },
    { skip: Object.keys(tableConf).length < 4 }
  )
  const [exportTable] = useLazyExportRewardPolicyTableQuery()
  const [delRew, delRewResp] = useDeleteRewardPolicyMutation()
  const [updRew, updRewResp] = useUpdateRewardPolicyMutation()

  useNotification(NOTIFICATION_TYPES.success, updRewResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, delRewResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, updRewResp.isError, updRewResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, delRewResp.isError, delRewResp.error as ErrorNode)

  useTableExport({
    tableConf,
    promise: exportTable,
  })

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange } = useTable<IRewardPolicyTable>({
    total: data?.totalCount,
    setTableConf,
  })

  const tableActionsPopup = useCallback(
    (id: string): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () =>
            navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/reward-policy/view/${id}`),
        },
        {
          text: 'Edit',
          shouldDisplay: true,
          onClick: () =>
            navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/reward-policy/edit/${id}`),
        },
        {
          text: 'Delete',
          shouldDisplay: true,
          withDivider: true,
          onClick: () => showConfirmMessage('This reward policy will be deleted', () => delRew(id)),
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        },
      ]
    },
    [delRew, navigate]
  )

  const columns: ColumnsType<IRewardPolicyTable> = [
    {
      title: 'Policy Id',
      dataIndex: 'id',
      width: '10%',
      sorter: true,
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Policy Name',
      dataIndex: 'title',
      width: '15%',
      sorter: true,
      render: (name, row) => (
        <RecursivelyReplaceCharacters>
          <Link
            to={`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/reward-policy/view/${row.id}`}
            className='table-avatar'
          >
            {name}
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Master Product',
      dataIndex: 'masterProducts',
      width: '15%',
      render: (_, row) => (
        <>
          {(row?.masterProducts?.length || Number(row?.masterProductsCount) > 0) && (
            <div className={styles.agentsTableList}>
              {row?.masterProducts?.slice(0, 2).map((product) => (
                <RecursivelyReplaceCharacters key={product.id}>
                  <img
                    alt='product logo'
                    src={!isPrivateMode && product?.logo ? product.logo : emptyProductImage}
                    loading='lazy'
                    width={38}
                    height={38}
                  />
                </RecursivelyReplaceCharacters>
              ))}
              {Number(row?.masterProductsCount) > 2 && (
                <div className={styles.agentCount}>+{Number(row?.masterProductsCount) - 2}</div>
              )}
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Target Group',
      width: '15%',
      dataIndex: 'targetGroupCustomers',
      render: (_, row) => (
        <>
          {(row?.targetGroupCustomers?.length || Number(row?.targetGroupCount) > 0) && (
            <div className={styles.agentsTableList}>
              {row?.targetGroupCustomers?.slice(0, 2).map((group) => (
                <RecursivelyReplaceCharacters key={group?.id}>
                  <img
                    alt='product logo'
                    src={!isPrivateMode && group?.avatar ? group.avatar : emptyProductImage}
                    loading='lazy'
                    width={38}
                    height={38}
                  />
                </RecursivelyReplaceCharacters>
              ))}
              {Number(row?.targetGroupCount) > 2 && (
                <div className={styles.agentCount}>+{Number(row?.targetGroupCount) - 2}</div>
              )}
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Starting Date',
      dataIndex: 'start',
      width: '20%',
      sorter: true,
      render: (date: IPromotionPolicy['start']) => (
        <RecursivelyReplaceCharacters>
          <div className='table-date'>
            <DateTableIcon />
            <span>{formatDateWithTime(date as string, profile?.calendar)}</span>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Expiry Date',
      dataIndex: 'end',
      width: '20%',
      sorter: true,
      render: (date: IPromotionPolicy['end']) => (
        <RecursivelyReplaceCharacters>
          <div className='table-date'>
            <DateTableIcon />
            <span>{formatDateWithTime(date as string, profile?.calendar)}</span>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      sorter: true,
      render: (status: IPromotionPolicy['status'], { id }) => {
        return (
          <Toggle
            defaultChecked={status}
            checked={status}
            loading={updRewResp.isLoading}
            onChange={(status) => {
              updRew({ id, body: { status } })
            }}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return (
    <Table
      locale={emptyTableConf}
      onChange={handleTableChange}
      pagination={pagination}
      columns={columns}
      className={styles.table}
      scroll={{ x: 1000 }}
      rowKey={(record) => record.id}
      rowSelection={{ type: 'checkbox' }}
      dataSource={data?.items}
      loading={isFetching}
    />
  )
}
