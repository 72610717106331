import { Route } from 'use-react-router-breadcrumbs'
import { ExternalTransactionReport } from '../../features/Reports/pages/Transactions/ExternalTransactionReport'
import { InternalTransactionReport } from '../../features/Reports/pages/Transactions/InternalTransactionReport'

export enum REPORT_ENUM {
  EXTERNAL_TRANSACTION_REPORT = 'reports/external-transaction-report',
  INTERNAL_TRANSACTION_REPORT = 'reports/internal-transaction-report',
  // SCHEDULED_REPORT = 'reports/scheduled-reports',
}

export default () => {
  return (
    <>
      <Route
        path={REPORT_ENUM.EXTERNAL_TRANSACTION_REPORT}
        element={<ExternalTransactionReport />}
      />
      <Route
        path={REPORT_ENUM.INTERNAL_TRANSACTION_REPORT}
        element={<InternalTransactionReport />}
      />
      {/*<Route path={REPORT_ENUM.SCHEDULED_REPORT} element={<ScheduledReport />} />*/}
    </>
  )
}
