import { Form, FormInstance, FormListFieldData, Select, Select as AntSelect } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { AddIcon, BucketIcon } from 'src/assets/svg'
import { useState } from 'react'
import { DefaultOptionType } from 'rc-select/lib/Select'

// helpers
import { maxNumber, positiveNumber, requiredValidator } from 'src/helpers/validation'

// components
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { TextField } from 'src/shared/components/textfield/TextField'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'

// interfaces
import { IBankAccountList, PAYMENT_METHOD_ENUM } from 'src/features/Finance/models/IBankAccount'
import { IPurchaseOrderBankWallet } from 'src/features/Finance/models/IPurchaseOrder'

// styles
import styles from '../styles.module.scss'
import classNames from 'classnames'

const { Option } = AntSelect

export interface IPaymentRow {
  viewMode: boolean
  field: FormListFieldData
  bankAccountList: IBankAccountList[] | undefined
  remove: () => void
  onSelectBankAccount: ((val: number, fieldName: [string, number]) => void) | undefined
  onChangeCash: (id: number) => ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
  form: FormInstance
  isBonusPayment: boolean
}

interface IPaymentInfo {
  currency: DefaultOptionType | undefined
  form: FormInstance
  viewMode: boolean
  bankAccountList: IBankAccountList[] | undefined
  selectedPaymentType?: PAYMENT_METHOD_ENUM
  recalculateMoneyAmount: () => void
}

const PaymentRow: React.FC<IPaymentRow> = ({
  field,
  viewMode,
  bankAccountList,
  remove,
  onSelectBankAccount,
  onChangeCash,
  isBonusPayment,
}) => {
  const [bankAccount, setBankAccount] = useState<IBankAccountList | undefined>()

  const handlerSelectBankAccount = (option: number, fieldName: [string, number]) => {
    const dataBA = bankAccountList?.find((i) => i.id === option)
    setBankAccount(dataBA)
    onSelectBankAccount?.(option, fieldName)
  }

  return (
    <div className={styles.paymentRow}>
      <div className={styles.paymentLeftRow}>
        <FormItem noStyle shouldUpdate>
          {({ setFieldValue, getFieldValue }) => {
            const selectedBankAccounts = (
              getFieldValue('purchaseOrderBankWallets') as Array<IPurchaseOrderBankWallet>
            )?.map((bank) => bank?.bankAccount?.id)
            return (
              <FormItem
                label='Bank Account'
                name={[field.name, 'bankAccount', 'id']}
                className={classNames(styles.paymentItem, styles.bankAccountSelect)}
                rules={[
                  !isBonusPayment
                    ? { required: true, validator: requiredValidator('Bank Account') }
                    : {},
                ]}
              >
                <Select
                  disabled={viewMode || isBonusPayment}
                  onChange={(val) =>
                    handlerSelectBankAccount(val, ['purchaseOrderBankWallets', field.name])
                  }
                  onClear={() => {
                    setFieldValue(
                      ['purchaseOrderBankWallets', field.name, 'bankAccount', 'walletBalance'],
                      null
                    )
                  }}
                  allowClear
                >
                  {bankAccountList?.map(({ id, name }) => (
                    <Option key={id} value={id} disabled={selectedBankAccounts?.includes(id)}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            )
          }}
        </FormItem>

        <FormItem noStyle shouldUpdate>
          {() => {
            return (
              <FormItem {...field} style={{ display: 'none' }} name={[field.name, 'id']}>
                <TextField disabled />
              </FormItem>
            )
          }}
        </FormItem>

        <FormItem noStyle shouldUpdate>
          {() => {
            return (
              <FormItem
                label='Available Balance'
                name={[field.name, 'bankAccount', 'walletBalance']}
                className={styles.paymentItem}
              >
                <TextField disabled />
              </FormItem>
            )
          }}
        </FormItem>

        <FormItem
          label='Cash Amount'
          name={[field.name, 'currencyAmount']}
          className={styles.paymentItem}
          rules={[
            ...(!isBonusPayment
              ? [{ required: true, validator: requiredValidator('Cash Amount') }]
              : []),
            {
              validator: positiveNumber('Cash Amount'),
            },
            ...(bankAccount
              ? [
                  {
                    validator: maxNumber(bankAccount.walletBalance, 'Cash Amount'),
                  },
                ]
              : []),
          ]}
        >
          <TextField
            onChange={onChangeCash(field.name)}
            type='number'
            min={0}
            disabled={viewMode || isBonusPayment}
          />
        </FormItem>

        <FormItem noStyle shouldUpdate>
          {() => {
            return (
              <FormItem
                {...field}
                className={styles.paymentItem}
                label='Currency Amount'
                name={[field.name, 'mainCurrencyAmount']}
              >
                <TextField disabled min={0} />
              </FormItem>
            )
          }}
        </FormItem>

        <FormItem
          label='Unit Rate'
          className={styles.paymentItem}
          name={[field.name, 'unitPrice']}
          rules={[
            { required: true, validator: requiredValidator('Unit Rate') },
            {
              validator: positiveNumber('Unit Rate'),
            },
          ]}
        >
          <TextField
            onChange={onChangeCash(field.name)}
            type='number'
            min={0}
            disabled={viewMode}
          />
        </FormItem>
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue, setFieldValue }) => {
            const isBonus = getFieldValue('paymentType') === PAYMENT_METHOD_ENUM.BONUS
            return (
              <FormItem
                label='eMoney Amount'
                name={[field.name, 'amount']}
                className={styles.paymentItem}
                rules={[
                  ...(isBonusPayment
                    ? [{ required: true, validator: requiredValidator('Cash Amount') }]
                    : []),
                ]}
              >
                <TextField
                  disabled={viewMode || !isBonus}
                  onChange={(e) => {
                    setFieldValue('amount', e.target.value)
                  }}
                />
              </FormItem>
            )
          }}
        </FormItem>
      </div>

      {!viewMode && (
        <div className={styles.bucketIcon}>
          <IconButton
            block
            size='large'
            color='orange'
            icon={<BucketIcon />}
            type='default'
            onClick={remove}
          />
        </div>
      )}
    </div>
  )
}

export const PaymentInfo: React.FC<IPaymentInfo> = ({
  form,
  viewMode,
  bankAccountList,
  selectedPaymentType,
  recalculateMoneyAmount,
}) => {
  const isBonusPayment = selectedPaymentType === PAYMENT_METHOD_ENUM.BONUS

  const handlerSelectBankAccount = (value: number, fieldName: [string, number]) => {
    const bankAccount = form.getFieldValue(fieldName)

    const dataBA = bankAccountList?.find((i) => i?.id === bankAccount?.bankAccount?.id)
    Object.assign(bankAccount, {
      bankAccount: { id: value, walletBalance: dataBA?.walletBalance },
      unitPrice: 0,
      currencyAmount: 0,
      mainCurrencyAmount: 0,
      amount: 0,
    })
    form.setFieldValue(fieldName, bankAccount)
    recalculateMoneyAmount()
  }

  const handlerChangeCash = (id: number) => () => {
    const isBonus = form.getFieldValue('paymentType') === PAYMENT_METHOD_ENUM.BONUS
    if (isBonus) return

    const { purchaseOrderBankWallets, exchangeRate } = form.getFieldsValue()

    const currentBA = purchaseOrderBankWallets[id]

    const mainCurrencyAmount = +(+currentBA?.currencyAmount * exchangeRate).toFixed(2)

    Object.assign(currentBA, {
      mainCurrencyAmount,
      amount: (mainCurrencyAmount * +currentBA?.unitPrice).toFixed(2),
    })

    form.setFieldsValue({
      purchaseOrderBankWallets,
    })
    recalculateMoneyAmount()
  }

  return (
    <Wrapper title='Payment Information' className={styles.layout}>
      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.List
            name='purchaseOrderBankWallets'
            rules={[
              {
                validator: () => {
                  if (
                    getFieldValue('purchaseOrderBankWallets') &&
                    getFieldValue('purchaseOrderBankWallets').length
                  ) {
                    return Promise.resolve()
                  }
                  return Promise.reject('Payment Information is required')
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field) => (
                  <PaymentRow
                    form={form}
                    field={field}
                    key={field.key}
                    viewMode={viewMode!}
                    bankAccountList={bankAccountList}
                    remove={() => {
                      remove(field.name)
                      recalculateMoneyAmount()
                    }}
                    onSelectBankAccount={handlerSelectBankAccount}
                    onChangeCash={handlerChangeCash}
                    isBonusPayment={isBonusPayment}
                  />
                ))}
                {/* eslint-disable-next-line */}
                {(fields.length < bankAccountList?.length! ||
                  !fields.length ||
                  !(isBonusPayment && fields.length === 1)) &&
                  !viewMode && (
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => (
                        <>
                          <div className={styles.addBtn}>
                            <IconButton
                              size='large'
                              block
                              color='orange'
                              icon={<AddIcon />}
                              type='default'
                              disabled={
                                viewMode ||
                                (!getFieldValue(['currency', 'id']) &&
                                  getFieldValue('purchaseOrderBankWallets')?.length === 1 &&
                                  getFieldValue('paymentType') === PAYMENT_METHOD_ENUM.BONUS)
                              }
                              onClick={() => add()}
                            >
                              add more
                            </IconButton>
                          </div>
                          <div style={{ color: 'red' }}>
                            {errors?.map((error, i) => (
                              <div key={i}>{error}</div>
                            ))}
                          </div>
                        </>
                      )}
                    </Form.Item>
                  )}
              </>
            )}
          </Form.List>
        )}
      </FormItem>
    </Wrapper>
  )
}
