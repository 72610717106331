import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { ILog } from 'src/features/Support/models/ITicket'
import { formatDateWithTime } from 'src/helpers/utils'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'

// styles
import styles from '../../style.module.scss'

interface ILogsOfTicket {
  logs: ILog[]
}

export const LogsOfTicket = ({ logs = [] }: ILogsOfTicket) => {
  const { data: profile } = useGetProfileQuery()

  return (
    <Wrapper title='logs of ticket' className={styles.mb24}>
      <div className={styles.logsTable}>
        <div className={styles.logsHeader}>
          <div className={styles.logsHeaderItem}>Name</div>
          <div className={styles.logsHeaderItem}>Date/Time opened</div>
        </div>
        <div className={styles.logsBody}>
          {logs.map(({ id, createdByUser, createdAt }) => (
            <div key={id} className={styles.logItem}>
              <div className={styles.logsValue}>{createdByUser?.name}</div>
              <div className={styles.logsValue}>
                {formatDateWithTime(createdAt, profile?.calendar)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  )
}
