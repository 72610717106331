import { Divider, Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ReactNode } from 'react'

interface IInfiniteScrollComponent {
  inverse?: boolean
  loadMoreData: () => void
  totalCount: number
  dataLength: number
  children: ReactNode
  containerStyles?: object
  loaderStyles?: object
  endMessage?: string | null
  showDivider?: boolean
  onScroll?: () => void
}

export interface IPageConf {
  page: number
  limit: number
  search: string
}

export const InfiniteScrollComponent = ({
  loadMoreData,
  dataLength,
  totalCount,
  children,
  containerStyles,
  loaderStyles = {},
  endMessage,
  showDivider = true,
  onScroll,
}: IInfiniteScrollComponent) => {
  return (
    <div
      id='scrollableDiv'
      style={{
        height: '100%',
        overflow: 'auto',
        ...(containerStyles && containerStyles),
      }}
    >
      <InfiniteScroll
        dataLength={dataLength}
        next={loadMoreData}
        hasMore={dataLength < totalCount}
        loader={
          <div style={loaderStyles}>
            <Skeleton avatar paragraph={{ rows: 1 }} active />
          </div>
        }
        endMessage={
          showDivider ? (
            <Divider plain>
              {typeof endMessage === 'string' ? endMessage : 'It is all, nothing more'}
            </Divider>
          ) : null
        }
        scrollableTarget='scrollableDiv'
        onScroll={onScroll}
      >
        {children}
      </InfiniteScroll>
    </div>
  )
}
