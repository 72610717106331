import { IconButton } from 'src/shared/components/iconButton/IconButton'
import styles from '../styles.module.scss'
import { ArrowLeftIcon, CreateReverseTransIcon } from '../../../../../../../../assets/svg'
import {
  CREATE_REVERSE_TRANS_REASON,
  CreateReverseTransStepType,
  ICreateReverseTransBody,
  ITopupDetail,
} from '../../../../../../../Finance/models/IReverseTransaction'
import {
  useCreateReverseTransactionMutation,
  useGetTopupTransactionByIdQuery,
} from '../../../../../../../Finance/core/http/ReverseTransactionApi'
import { TopupListItem } from '../SearchStep/TopupListItem'
import { Form, Select, Spin } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import React, { useEffect, useMemo } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { requiredValidator } from '../../../../../../../../helpers/validation'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../../shared/api/errorHandler'
import { NotificationAlert } from '../../../../../../../../shared/components/notificationAlert/NotificationAlert'
import classNames from 'classnames'

interface IProps {
  handleChangeStep: (step: CreateReverseTransStepType, topupId: number | null) => void
  topupId: number
  step: CreateReverseTransStepType
}

export const CreateStep = ({ handleChangeStep, topupId, step }: IProps) => {
  const [form] = Form.useForm()
  const { data, isFetching } = useGetTopupTransactionByIdQuery(topupId, {
    skip: !topupId,
  })
  const [crRT, crRTResp] = useCreateReverseTransactionMutation()

  useNotification(NOTIFICATION_TYPES.error, crRTResp.isError, crRTResp.error as ErrorNode)

  const reasonOptions = useMemo(
    () =>
      (
        Object.keys(CREATE_REVERSE_TRANS_REASON) as Array<keyof typeof CREATE_REVERSE_TRANS_REASON>
      ).map((option) => ({
        label: CREATE_REVERSE_TRANS_REASON[option],
        value: CREATE_REVERSE_TRANS_REASON[option],
      })),
    []
  )

  const onFinish = (values: ICreateReverseTransBody) => {
    const body: ICreateReverseTransBody = {
      ...values,
      topupId,
    }
    crRT(body)
  }

  useEffect(() => {
    if (crRTResp.isSuccess) {
      handleChangeStep('afterCreateTopup', null)
    }
  }, [crRTResp.isSuccess, handleChangeStep])

  const isAfterCreateStep = step === 'afterCreateTopup'

  return (
    <Spin spinning={isFetching || crRTResp.isLoading}>
      <div className={styles.formContainer}>
        {isAfterCreateStep && (
          <div className='animate__animated animate__bounceInDown'>
            <NotificationAlert
              type='success'
              variant='contained'
              message='You have successfully create Reverse Transaction Request!'
            />
          </div>
        )}
        <div
          className={classNames(styles.backButtonContainer, {
            [styles.withTopMargin]: isAfterCreateStep,
          })}
        >
          <IconButton
            color='blue'
            icon={<ArrowLeftIcon />}
            onClick={() => handleChangeStep('searchTopup', null)}
          />
        </div>
        <TopupListItem
          topup={data as ITopupDetail}
          handleChangeStep={handleChangeStep}
          shouldDisplayCreate={false}
        />

        {!isAfterCreateStep && (
          <>
            <div className={styles.whiteContainer}>
              <Form form={form} layout='vertical' onFinish={onFinish}>
                <FormItem
                  name='reason'
                  label='Reason for Reverse Transaction Request'
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator('Reason for Reverse Transaction Request'),
                    },
                  ]}
                >
                  <Select placeholder='Select' options={reasonOptions} allowClear />
                </FormItem>

                <FormItem name='description' label='Description' className={styles.noBottomMargin}>
                  <TextArea className={styles.textarea} />
                </FormItem>
              </Form>
            </div>

            <div>
              <IconButton
                color='orange'
                block
                size='large'
                type='primary'
                icon={<CreateReverseTransIcon />}
                onClick={form.submit}
              >
                Create Reverse Transaction Request
              </IconButton>
            </div>
          </>
        )}
      </div>
    </Spin>
  )
}
