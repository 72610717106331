import React, { memo } from 'react'
import { ClockIcon, PauseIcon, PlayArrowIcon, StopIcon } from '../../../assets/svg'
import styles from '../header/Header.module.scss'

const Timer = () => {
  return (
    <div className={styles['wrapper-timer']}>
      <div className={styles['wrapper-clock-icon']}>
        <ClockIcon />
      </div>

      <div className={styles.time}>4:55</div>

      <div className={styles['wrapper-play-icon']}>
        <PlayArrowIcon />
      </div>

      <div className={styles['wrapper-pause-icon']}>
        <PauseIcon />
      </div>

      <div className={styles['wrapper-stop-icon']}>
        <StopIcon />
      </div>
    </div>
  )
}

export default memo(Timer)
