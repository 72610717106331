export enum AgentManagementTabsEnum {
  ALL = 'ALL',
}

export enum AgentDocumentsEnum {
  NIC = 'NIC',
  ID = 'ID',
  PASSPORT = 'PASSPORT',
  DRIVING_LICENCE = 'DRIVING_LICENCE',
  COMPANY_LICENCE = 'COMPANY_LICENCE',
  CONTRACT = 'CONTRACT',
}

export enum AgentDocumentStatusEnum {
  UNVERIFIED = 'UNVERIFIED',
  IN_PROGRESS = 'IN_PROGRESS',
  VERIFIED = 'VERIFIED',
  EXPIRED = 'EXPIRED',
}

export enum AgentGeneralStatusEnum {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
  UNVERIFIED = 'UNVERIFIED',
}

export const AgentGeneralStatus = {
  FULL: 'Fully Verified',
  PARTIAL: 'Partially verified',
  UNVERIFIED: 'Unverified',
}

export const AgentDocumentStatus = {
  UNVERIFIED: 'Unverified',
  IN_PROGRESS: 'Verification In Progress',
  VERIFIED: 'Verified',
  EXPIRED: 'Verification Expired',
}
