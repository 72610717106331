import { useNavigate } from 'react-router'
import { Form, Spin } from 'antd'
import { IPurchaseOrderStatus } from '../../../../../../Finance/models/IPurchaseOrder'
import { useContext, useEffect } from 'react'
import { RightModalContext } from '../../../../../../Modals'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../shared/api/errorHandler'
import { isEmpty } from 'lodash'
import { formatOnlyDate } from '../../../../../../../helpers/utils'
import {
  useApproveRefundRequestMutation,
  useCancelRefundRequestMutation,
  useGetRufundReqByIdQuery,
  useRejectRefundRequestMutation,
} from '../../../../../../Finance/core/http/BankWalletTransactionsApi'
import styles from '../PickupRequestModal/styles.module.scss'
import { DetailInfo } from '../PickupRequestModal/DetailInfo'
import { Logs } from '../PickupRequestModal/Logs'
import { Footer } from '../PickupRequestModal/Footer'
import { useGetBankAccountsQuery } from '../../../../../../Finance/core/http/BankAccountApi'
import {
  agentManagementApi,
  useLazyGetRootAgentByIdQuery,
} from '../../../../../../salesNetwork/core/http/AgentManagement'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { useDispatch } from 'react-redux'

export const RefundRequestModal = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const {
    onClose,
    props: { id },
  } = useContext(RightModalContext)
  const { data: profile } = useGetProfileQuery()

  const { data, isFetching } = useGetRufundReqByIdQuery(id, { skip: !id })

  const [getRootAgent, dRootAgent] = useLazyGetRootAgentByIdQuery()
  const [onCancelRef, dCancel] = useCancelRefundRequestMutation()
  const [onRejectRef, dReject] = useRejectRefundRequestMutation()
  const [onApproveRef, dApprove] = useApproveRefundRequestMutation()
  const { data: bankAccountList } = useGetBankAccountsQuery({})

  const afterCreate = () => {
    navigate('/finance/operation-wallet')
    onClose()
  }

  useNotification(NOTIFICATION_TYPES.success, dCancel.isSuccess, null)
  useNotification(NOTIFICATION_TYPES.error, dCancel.isError, dCancel.error as ErrorNode)

  useNotification(NOTIFICATION_TYPES.success, dReject.isSuccess, null, afterCreate)
  useNotification(NOTIFICATION_TYPES.error, dReject.isError, dReject.error as ErrorNode)

  useNotification(NOTIFICATION_TYPES.success, dApprove.isSuccess, null, () => {
    dispatch(agentManagementApi.util.invalidateTags(['IAllTableResponse']))
    afterCreate()
  })
  useNotification(NOTIFICATION_TYPES.error, dApprove.isError, dApprove.error as ErrorNode)

  useEffect(() => {
    if (isEmpty(data)) return
    getRootAgent(data?.agent?.id)
  }, [data, form, getRootAgent])

  useEffect(() => {
    if (isEmpty(dRootAgent)) return
    const rootAgent = dRootAgent?.data
    form.setFieldsValue({
      parentAgentId: rootAgent?.id,
    })
  }, [dRootAgent, form])

  useEffect(() => {
    if (isEmpty(data) || isEmpty(profile)) return
    form.setFieldsValue({
      ...data,
      createdAt: formatOnlyDate(data?.createdAt, profile?.calendar),
    })
  }, [data, form, profile])

  const handlerEdit = () => {
    navigate(`/finance/operation-wallet/refund-request/edit/${id}`)
    onClose()
  }

  const isMutating = dApprove.isLoading || dCancel.isLoading || dReject.isLoading

  const content = (
    <Form form={form} className={styles.formContainer} layout='vertical' initialValues={data}>
      <DetailInfo bankAccountList={bankAccountList?.items} status={data?.status} />
      <Logs logs={data?.AgentRequestSubTransaction as IPurchaseOrderStatus[]} />
      <Footer
        status={data?.status}
        onEdit={handlerEdit}
        canCancel={data?.createdByUser?.id === profile?.id}
        onCancel={() => onCancelRef(id)}
        onReject={() => onRejectRef(id)}
        onApprove={() => onApproveRef(id)}
      />
    </Form>
  )

  return isFetching || isMutating ? <Spin>{content}</Spin> : content
}
