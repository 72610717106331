import { Col, List, Row } from 'antd'
import classNames from 'classnames'
import { IPopupListItems } from '../../../../../../shared/components/popup/Popup'
import styles from '../../../../../../shared/components/popup/popup.module.scss'

interface IProps {
  data: Array<IPopupListItems>
}

const InternalChatPopup = ({ data }: IProps) => {
  return (
    <List
      dataSource={data}
      renderItem={({ onClick, withDivider, shouldDisplay, icon, text, color, suffix }) => (
        <List.Item
          onClick={onClick}
          className={classNames({
            [styles.topBorder]: withDivider,
            [styles.shouldHide]: !shouldDisplay,
            [styles[color!]]: color,
          })}
        >
          <Row align='middle'>
            {icon && <Col style={{ width: 40, height: 15 }}>{icon}</Col>}
            <Col>{text}</Col>
            {suffix && <Col className={styles.suffixContainer}>{suffix}</Col>}
          </Row>
        </List.Item>
      )}
    />
  )
}

export default InternalChatPopup
