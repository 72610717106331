import styles from './styles.module.scss'
import { Divider, Select, Spin } from 'antd'
import classNames from 'classnames'
import moment from 'moment/moment'
import {
  ChatSearchIcon,
  PaginationNextIcon,
  PaginationPrevIcon,
} from '../../../../../../../../assets/svg'
import React, { useCallback, useEffect, useState } from 'react'
import { DefaultOptionType } from 'rc-select/lib/Select'
import { debounce } from 'lodash'
import { useLazyGetExternalChatMessagesByIdQuery } from '../../../../../../core/http/ExternalChatApi'
import { ILastExternalChatMessage } from '../../../../../../models/IExternalChat'

interface IProps {
  setToScrollId: React.Dispatch<React.SetStateAction<number | null>>
  search: string | null
  setSearch: React.Dispatch<React.SetStateAction<string | null>>
  selectedChatId: number | null
}

export const HeaderSearch = ({ setSearch, search, setToScrollId, selectedChatId }: IProps) => {
  const [messagesList, setMessagesList] = useState<ILastExternalChatMessage[]>([])
  const [isSearchVisible, setIsSearchVisible] = useState(false)

  const [searchMessages, searchMessagesResp] = useLazyGetExternalChatMessagesByIdQuery()
  const handleChange = (val: string) => {
    setSearch(val)
  }

  const handleSelect = (val: string, option: DefaultOptionType | DefaultOptionType[]) => {
    const optionKey = option as unknown as { key: number }
    setToScrollId(optionKey.key as number)
  }

  const handleSearch = useCallback(
    debounce((val: string) => {
      if (selectedChatId && val) {
        searchMessages({ id: selectedChatId as number, search: val })
      }
    }, 300),
    [selectedChatId, searchMessages]
  )

  const onArrowClick = useCallback(
    (direction: 'up' | 'down') => {
      const index = searchMessagesResp?.data?.items.findIndex((el) => el.createdAt === search) || 0
      if (index === -1) {
        const message = searchMessagesResp?.data?.items[0]
        setSearch(message?.createdAt || null)
        setToScrollId(message?.id || null)
      } else {
        const message =
          direction === 'up'
            ? searchMessagesResp?.data?.items[index + 1]
            : searchMessagesResp?.data?.items[index - 1]
        setSearch(message?.createdAt || null)
        setToScrollId(message?.id || null)
      }
    },
    [search, searchMessagesResp?.data?.items, setSearch, setToScrollId]
  )

  useEffect(() => {
    if (searchMessagesResp?.data?.items) {
      setMessagesList(searchMessagesResp?.data?.items)
    }
  }, [searchMessagesResp?.data, searchMessagesResp.requestId])

  useEffect(() => {
    return () => {
      setMessagesList([])
      setIsSearchVisible(false)
    }
  }, [selectedChatId])

  return (
    <div className={styles.chatActionBtn}>
      <Select
        showSearch
        value={search}
        placeholder='Search by message'
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        notFoundContent={searchMessagesResp.isLoading ? <Spin size='small' /> : null}
        className={classNames(styles.searchInput, {
          [styles.visibleSearch]: isSearchVisible,
        })}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            {messagesList.length && (
              <div style={{ padding: '0 8px 4px' }}>
                Total Count: <span>{messagesList.length}</span>
              </div>
            )}
          </>
        )}
        onChange={handleChange}
        onSearch={(val: string) => {
          setSearch(null)
          handleSearch(val)
          if (!val) {
            setMessagesList([])
            setSearch(null)
          }
        }}
        onSelect={handleSelect}
        onClear={() => {
          setIsSearchVisible(false)
          setToScrollId(null)
          setMessagesList([])
        }}
        suffixIcon={<ChatSearchIcon />}
        allowClear
      >
        {messagesList?.map((message) => {
          return message.chatId === selectedChatId ? (
            <Select.Option
              key={message?.id}
              value={message?.createdAt}
              className={styles.searchSelectOption}
            >
              <div className={styles.searchOptionText} title={message?.message}>
                {message?.message}
              </div>
              <div className={styles.searchOptionTime}>
                {moment(message?.createdAt).format('HH:mm')}
              </div>
            </Select.Option>
          ) : null
        })}
      </Select>
      {isSearchVisible && (
        <div className={styles.searchNavigation}>
          <div
            className={classNames(styles.prevSearchIcon, {
              [styles.disabledArrow]:
                !search ||
                search === messagesList.at(-1)?.createdAt ||
                !messagesList.length ||
                messagesList.some((m) => m.chatId !== selectedChatId),
            })}
            onClick={() => onArrowClick('up')}
          >
            <PaginationPrevIcon />
          </div>
          <div
            className={classNames(styles.nextSearchIcon, {
              [styles.disabledArrow]:
                search === messagesList[0]?.createdAt ||
                !messagesList.length ||
                messagesList.some((m) => m.chatId !== selectedChatId),
            })}
            onClick={() => onArrowClick('down')}
          >
            <PaginationNextIcon />
          </div>
        </div>
      )}
      {!isSearchVisible && (
        <div
          onClick={() => setIsSearchVisible((prevState) => !prevState)}
          className={styles.searchIcon}
        >
          <ChatSearchIcon />
        </div>
      )}
    </div>
  )
}
