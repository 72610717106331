import styles from '../styles.module.scss'
import { Form, Spin } from 'antd'
import React, { useEffect } from 'react'
import { DetailInfoBlock } from '../DiscountPolicyView/Components/DetailInfoBlock'
import { Footer } from '../DiscountPolicyView/Components/Footer'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import { formatDateMomentToISO, formatDateWithTime } from '../../../../../helpers/utils'
import { useNavigate } from 'react-router'
import { SETTINGS_ENUM } from '../../../../../routes/settings'
import { StepRegistration } from '../../../../../shared/components/stepRegistration'
import { IDiscountPolicy, IDiscountPolicyCreate } from '../../../models/IDiscountPolicy'
import { useCreateDiscountPolicyMutation } from '../../../core/http/DiscountPolicyApi'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../shared/api/errorHandler'
import { MasterProducts } from '../DiscountPolicyView/Components/MasterProducts/MasterProducts'
import { ProductAccessBlock } from '../DiscountPolicyView/Components/ProductAccessBlock'
import { ACCOUNT_TYPE_TO_ID } from '../../../models/IAccountType'
import { Moment } from 'moment'
import { AccessChannelsBlock } from '../DiscountPolicyView/Components/AccessChannelsBlock'

export const DiscountPolicyNew = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const isViewMode = false

  const [crDisc, crDiscResp] = useCreateDiscountPolicyMutation()
  const { data: profileInfo, isFetching } = useGetProfileQuery()

  useNotification(NOTIFICATION_TYPES.success, crDiscResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, crDiscResp.isError, crDiscResp.error as ErrorNode)

  const onCancelButtonClick = () => {
    return navigate(SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION)
  }

  const onFinish = (values: IDiscountPolicy) => {
    const dataObj = {
      name: values?.name,
      start: formatDateMomentToISO(profileInfo?.calendar, values.start as unknown as Moment),
      end: formatDateMomentToISO(profileInfo?.calendar, values.end as unknown as Moment),
      accountTypeId: +values?.accountType?.id,
      agentTypeId: +values?.agentType?.id,
      minRate: +values?.minRate,
      isOpen: values.isOpen,
      masterProductType: values.masterProductType,
      isOpenFloating: !!values.isOpenFloating,
      discountPolicyProducts: values?.discountPolicyProducts?.map((product) => ({
        masterProductId: product.masterProduct.id,
        levelRates: product?.levelRates?.map((level) => {
          return {
            levelId: level.level.id,
            rate: +level?.rate,
          }
        }),
      })),
      status: true,
      ...(values.accountType?.id === ACCOUNT_TYPE_TO_ID.PREPAID && {
        productsAccess: values?.productsAccess?.map((prod) => prod?.masterProduct.id),
      }),
      forbiddenAccessChannels: values?.forbiddenAccessChannels,
    } as IDiscountPolicyCreate

    crDisc(dataObj)
  }

  useEffect(() => {
    if (profileInfo) {
      form.setFieldsValue({
        createdAt: formatDateWithTime(new Date(), profileInfo?.calendar),
        createdByUser: { name: `${profileInfo?.firstName} ${profileInfo?.lastName}` },
      })
    }
  }, [form, profileInfo])

  useEffect(() => {
    if (crDiscResp?.data) {
      return navigate(
        `${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/discount-policy/view/${crDiscResp.data.id}`
      )
    }
    // eslint-disable-next-line
  }, [crDiscResp.data])

  const content = (
    <Form form={form} className={styles.discountForm} layout='vertical' onFinish={onFinish}>
      <StepRegistration stepNumber={1} stepText='Detail Info' mT='11.5px' mB='32px' />
      <DetailInfoBlock view={isViewMode} isEditable={true} />
      <StepRegistration stepNumber={2} stepText='Products' mT='32px' mB='32px' />
      <MasterProducts onCreatePage />
      <StepRegistration stepNumber={3} stepText='Forbidden Products' mT='32px' mB='32px' />
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const isPostpaid = getFieldValue(['accountType', 'id']) === ACCOUNT_TYPE_TO_ID.POSTPAID
          return <ProductAccessBlock view={isViewMode} isPostpaid={isPostpaid} />
        }}
      </Form.Item>
      <StepRegistration stepNumber={4} stepText='Forbidden Access Channels' mT='32px' mB='32px' />
      <AccessChannelsBlock />
    </Form>
  )

  return (
    <div className={styles.layout}>
      {isFetching || crDiscResp.isLoading ? <Spin>{content}</Spin> : content}
      <Footer onCancelClick={onCancelButtonClick} onSubmitClick={form.submit} />
    </div>
  )
}
