import React from 'react'
import { useAppSelector } from '../redux'
import { selectLastBreadCrumb } from '../features/app/core/BreadcrumbsSlice'
import { Navigate, useLocation } from 'react-router-dom'
import { isAuthorized, isNeedTwoFA } from '../shared/api'
import { SidebarLayout } from '../shared/layout/sidebarLayout/SidebarLayout'
import styles from '../shared/components/breadcrumb/Breadcrumb.module.scss'

export const getBreadcrumb = (idView = false, key = '') => {
  return () => {
    const { entityName, entityId, ...rest } = useAppSelector(selectLastBreadCrumb)
    const title = idView ? entityId : 'Detail view'
    const content = (
      <span>
        {rest[key] ?? entityName} - <span className={styles['title-page__slim']}>{title}</span>
      </span>
    )

    return entityName ? content : <span className={styles['title-page__slim']}>{title}</span>
  }
}

export const PrivateRoute = () => {
  const { pathname } = useLocation()

  if (!isAuthorized() && !isNeedTwoFA()) {
    return <Navigate to='/sign-in' state={{ from: pathname }} />
  }

  return <SidebarLayout />
}
