import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { identity, pick, pickBy } from 'lodash'
import { TablePaginationConfig, TableProps } from 'antd'
import { SorterResult } from 'antd/lib/table/interface'

import { ITableConf, SortingOrderEnum } from 'src/shared/models/ITableConf'
import { getQueryParams, updatePageUrl } from 'src/helpers/queryString'
import { PaginationNextIcon, PaginationPrevIcon } from 'src/assets/svg'
import { ISelectedFilters } from 'src/shared/components/filter/Filter'
import { Moment } from 'moment'

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100]
export const paginationParams = ['limit', 'page', 'orderField', 'orderType']

export const paginationSettings: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ['bottomRight'],
  locale: { items_per_page: '' },
  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
  defaultPageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: PAGE_SIZE_OPTIONS,
  itemRender: (_, type) => {
    if (type === 'prev') return <PaginationPrevIcon />
    if (type === 'next') return <PaginationNextIcon />

    return null
  },
}

interface IUseTable {
  total: number | undefined
  extraFilters?: string[]
  setTableConf: React.Dispatch<React.SetStateAction<ITableConf>>
  setFilterValues?: React.Dispatch<React.SetStateAction<ISelectedFilters>>
  setExpandedRowKeys?: Dispatch<SetStateAction<Array<React.Key>>>
  onChange?: () => void
}

/**
 *
 * @param total
 * @param {Array<String>} extraFilters - when you need to use extra filters in url, but without dispay in filters block
 * @param setTableConf
 * @param setFilterValues
 * @param setExpandedRowKeys
 * @param onChange - used for external trans report update
 * @returns
 */
const useTable = <T,>({
  total,
  extraFilters = [],
  setTableConf,
  setFilterValues,
  setExpandedRowKeys,
  onChange,
}: IUseTable) => {
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const [pagination, setPagination] = useState(paginationSettings)

  useEffect(() => {
    if (!total) return

    setPagination((p) => ({ ...p, total }))
  }, [total])

  useEffect(() => {
    const queryParams = getQueryParams(location.search)

    if (!queryParams.page || !queryParams.limit) {
      setFilterValues?.({})
      return
    }
    const { page, limit } = queryParams

    setTableConf({
      ...queryParams,
      page: Number(page),
      limit: Number(limit),
    })

    setPagination((prev) => ({ ...prev, current: Number(page), pageSize: Number(limit) }))
    onChange?.()
    // eslint-disable-next-line
  }, [location.search])

  const handleTableChange: TableProps<T>['onChange'] = (pagination, _, sorter, extra) => {
    const { current: page, pageSize: limit } = pagination
    const { order, field } = sorter as SorterResult<T>
    const { action } = extra

    let orderType!: SortingOrderEnum
    if (order) {
      orderType = order === 'ascend' ? SortingOrderEnum.ASC : SortingOrderEnum.DESC
    }

    let orderField = field
    if (field === 'firstName' || field === 'lastName') {
      orderField = 'name'
    }

    const prevQueryParams = getQueryParams(location.search)
    const newQueryParams = {
      ...(limit && action === 'paginate' && { limit }),
      ...(page && action === 'paginate' && { page }),
      ...(orderField && action === 'sort' && { orderField }),
      ...(orderType && action === 'sort' && { orderType }),
    }

    updatePageUrl(prevQueryParams, newQueryParams, setSearchParams)

    setPagination((p) => ({ ...p, ...pagination }))
    setTableConf({
      ...(action === 'paginate' && { page: pagination.current }),
      ...(action === 'paginate' && { limit: pagination.pageSize }),
      ...(orderType && action === 'sort' && { orderType }),
      ...(orderField && action === 'sort' && { orderField: orderField as string }),
    })
    setExpandedRowKeys?.([])
    onChange?.()
  }

  const handleFiltersChange = (values: ISelectedFilters) => {
    const prevQueryParams = pick(getQueryParams(location.search), [
      ...paginationParams,
      ...extraFilters,
    ])

    for (const i in values) {
      if (typeof values[i] === 'object') {
        values[i] = (values[i] as unknown as Moment).toISOString(true)
      }
    }

    const newQueryParams = {
      ...pickBy(values, identity),
      page: paginationSettings.current,
    }

    updatePageUrl(prevQueryParams, newQueryParams, setSearchParams)
    setFilterValues?.(values)
    setExpandedRowKeys?.([])
    onChange?.()
  }

  return { pagination, handleTableChange, handleFiltersChange }
}

export default useTable
