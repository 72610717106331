import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import {
  ArchiveChatIcon,
  ArrowLeftIcon,
  ChatAssignToAnotherIcon,
  ChatDotsIcon,
  DeleteContainedIcon,
  MuteMessageIcon,
  Pin,
  UnArchiveChatIcon,
  UnMuteMessageIcon,
  UnpinMessageIcon,
} from '../../../../../../../../assets/svg'
import { Dropdown, MenuProps, Spin } from 'antd'
import styles from './styles.module.scss'
import {
  EXTERNAL_CHAT_LIST_TABS,
  EXTERNAL_CHAT_WS_EVENTS_ENUM,
  IExternalChatMember,
  IUpdateExternalChatMember,
} from '../../../../../../models/IExternalChat'
import {
  externalChatApi,
  useArchiveExternalChatMutation,
  useRemoveChatMemberByIdMutation,
  useUpdateChatMemberMutation,
} from '../../../../../../core/http/ExternalChatApi'
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux'
import {
  archiveExternalChat,
  clearExternalChatList,
  selectExternalChat,
  selectExternalChatChatsSlice,
  setActiveTab,
  updateExternalChatUserInfo,
} from '../../../../../../core/store/ExternalChatChatsListSlice'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../shared/hooks/useNotification'
import { ErrorNode, showConfirmMessage } from '../../../../../../../../shared/api/errorHandler'
import { IPopupListItems, Popup } from '../../../../../../../../shared/components/popup/Popup'
import { HeaderWithInfo } from './HeaderWithInfo'
import { HeaderSearch } from './HeaderSearch'
import { useGetUsersListQuery } from '../../../../../../../Settings/core/http/UsersManagementApi'
import { selectExternalChatMessagingSlice } from '../../../../../../core/store/ExternalChatMessagingSlice'
import { externalChatSocketConnection } from '../../../../../../../../shared/sockets'

interface IProps {
  setToScrollId: React.Dispatch<React.SetStateAction<number | null>>
  search: string | null
  setSearch: React.Dispatch<React.SetStateAction<string | null>>
}

const MessagingHeader = ({ setToScrollId, setSearch, search }: IProps) => {
  const dispatch = useAppDispatch()
  const { externalChat, selectedChatId, activeTab } = useAppSelector(selectExternalChatChatsSlice)
  const { currentUserId } = useAppSelector(selectExternalChatMessagingSlice)
  const chat = useAppSelector((state) => selectExternalChat(state, selectedChatId as number))

  const { data: userList = { items: [] }, isFetching: isFetchingUsers } = useGetUsersListQuery({})
  const [updExtChatMemb, updExtChatMembResp] = useUpdateChatMemberMutation()
  const [archiveExtChat, archExtChatResp] = useArchiveExternalChatMutation()
  const [remChatMemb, remChatMembResp] = useRemoveChatMemberByIdMutation()

  const afterLeaveConversation = () => {
    dispatch(
      externalChatApi.util.invalidateTags([{ type: 'IExternalChatById', id: externalChat?.id }])
    )
    if (
      selectedChatId === Number(remChatMembResp?.originalArgs?.chatId) &&
      activeTab === EXTERNAL_CHAT_LIST_TABS.MY &&
      remChatMembResp?.originalArgs?.userId === currentUserId
    ) {
      dispatch(clearExternalChatList())
      dispatch(setActiveTab(EXTERNAL_CHAT_LIST_TABS.ALL))
    }
  }

  useNotification(NOTIFICATION_TYPES.success, archExtChatResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.success,
    remChatMembResp.isSuccess,
    null,
    afterLeaveConversation
  )
  useNotification(NOTIFICATION_TYPES.success, updExtChatMembResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    updExtChatMembResp.isError,
    updExtChatMembResp.error as ErrorNode
  )
  useNotification(
    NOTIFICATION_TYPES.error,
    archExtChatResp.isError,
    archExtChatResp.error as ErrorNode
  )
  useNotification(
    NOTIFICATION_TYPES.error,
    remChatMembResp.isError,
    remChatMembResp.error as ErrorNode
  )

  const updateChatMember = useCallback(
    (body: Partial<IUpdateExternalChatMember>) => {
      updExtChatMemb(body)
    },
    [updExtChatMemb]
  )

  const handleArchiveExtChat = useCallback(
    (id: number, isArchived: boolean) => {
      archiveExtChat({ id, body: { isArchived } })
    },
    [archiveExtChat]
  )

  const handleRemoveUserFromChat = useCallback(
    (chatId: number, userId: number) => {
      remChatMemb({ chatId, userId })
    },
    [remChatMemb]
  )

  const tableActionsPopup = useCallback((): IPopupListItems[] => {
    // NEW VARIABLE TO CHECK WEATHER OR NOT THE USER IS CONNECTED TO THE CHAT
    const chatMember = externalChat?.members?.find((memb) => memb?.userId === currentUserId)
    const member = chat?.member
    const obj = {
      chatId: chat?.id,
    }
    return [
      ...(activeTab === EXTERNAL_CHAT_LIST_TABS.MY
        ? [
            {
              text: member?.isPinned ? 'Unpin' : 'Pin',
              shouldDisplay: true,
              icon: member?.isPinned ? <UnpinMessageIcon /> : <Pin color='#0193EB' />,
              onClick: () => {
                const body = { ...obj, isPinned: !member?.isPinned }
                updateChatMember(body)
              },
            },
          ]
        : []),
      ...(chatMember
        ? [
            {
              text: 'Leave Conversation',
              shouldDisplay: true,
              icon: <ArrowLeftIcon />,
              onClick: () => {
                const confirmMessage = 'You will leave this conversation'
                showConfirmMessage(confirmMessage, () =>
                  handleRemoveUserFromChat(externalChat?.id as number, chatMember?.userId)
                )
              },
            },
          ]
        : []),
      ...(externalChat?.hasSession
        ? [
            {
              text: chat?.isArchived ? 'Unarchive Chat' : 'Archive Chat',
              shouldDisplay: true,
              icon: <ArchiveChatIcon />,
              onClick: () => {
                const confirmMessage = chat?.isArchived
                  ? 'This chat will be unarchived'
                  : 'This chat will be archived'
                showConfirmMessage(confirmMessage, () =>
                  handleArchiveExtChat(chat?.id as number, !chat?.isArchived)
                )
              },
            },
          ]
        : []),
    ]
  }, [
    activeTab,
    chat?.id,
    chat?.isArchived,
    chat?.member,
    currentUserId,
    externalChat?.hasSession,
    externalChat?.id,
    externalChat?.members,
    handleArchiveExtChat,
    handleRemoveUserFromChat,
    updateChatMember,
  ])

  const messageActions: MenuProps['items'] = useMemo(
    () =>
      userList.items
        .filter(
          (user) =>
            !externalChat?.members?.some(
              (member: IExternalChatMember) => member?.userId === user?.id
            )
        )
        .map((el) => ({
          key: `connect-user-to-chat-${el?.id}`,
          label: (
            <div
              onClick={() => {
                showConfirmMessage(
                  `${el?.firstName} ${el?.lastName} will be connected to this chat!`,
                  () =>
                    updateChatMember({
                      chatId: selectedChatId as number,
                      userId: el.id,
                      isConnected: false,
                      lastReadingAt: chat?.lastExternalChatMessage?.createdAt,
                    })
                )
              }}
            >{`${el?.firstName} ${el?.lastName}`}</div>
          ),
        })),
    [
      chat?.lastExternalChatMessage?.createdAt,
      externalChat?.members,
      selectedChatId,
      updateChatMember,
      userList.items,
    ]
  )

  useEffect(() => {
    if (!archExtChatResp?.data) return
    const connection = externalChatSocketConnection.getSocket()
    dispatch(archiveExternalChat(archExtChatResp?.data?.id))

    if (archExtChatResp.data.isArchived) {
      dispatch(clearExternalChatList())
      dispatch(setActiveTab(EXTERNAL_CHAT_LIST_TABS.ARCHIVED))

      connection?.emit(EXTERNAL_CHAT_WS_EVENTS_ENUM.ARCHIVE_CHAT, {
        chatId: archExtChatResp?.data?.id,
      })
    } else {
      dispatch(clearExternalChatList())
      dispatch(setActiveTab(EXTERNAL_CHAT_LIST_TABS.MY))

      connection?.emit(EXTERNAL_CHAT_WS_EVENTS_ENUM.UN_ARCHIVE_CHAT, {
        chatId: archExtChatResp?.data?.id,
      })
    }
  }, [archExtChatResp?.data, dispatch])

  useEffect(() => {
    if (updExtChatMembResp?.data) {
      dispatch(updateExternalChatUserInfo(updExtChatMembResp?.data))
    }
  }, [dispatch, updExtChatMembResp?.data])

  useEffect(() => {
    setSearch(null)
  }, [selectedChatId, setSearch])

  return (
    <div>
      <Spin spinning={isFetchingUsers}>
        <div className={styles.messagingHeader}>
          <HeaderWithInfo
            externalChat={externalChat}
            selectedChatId={selectedChatId}
            currentUserId={currentUserId}
            handleRemoveUserFromChat={handleRemoveUserFromChat}
          />
          <div className={styles.chatActions}>
            {externalChat && !externalChat?.isArchived && (
              <Dropdown
                menu={{ items: messageActions }}
                trigger={['click']}
                overlayClassName={styles.contextMenuContainer}
              >
                <div className={styles.chatActionAssign}>
                  <ChatAssignToAnotherIcon />
                </div>
              </Dropdown>
            )}
            <HeaderSearch
              setToScrollId={setToScrollId}
              search={search}
              setSearch={setSearch}
              selectedChatId={selectedChatId}
            />
            <div className={styles.chatActionBtn}>
              <Popup data={tableActionsPopup()}>
                <div className='table-kebab-actions'>
                  <ChatDotsIcon />
                </div>
              </Popup>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default memo(MessagingHeader)
