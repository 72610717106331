export const provinceWithDistricts = [
  {
    province: 'Kabul',
    districts: [
      { district: 'Kabul', code: 'AFG-1001' },
      { district: 'Istalif', code: 'AFG-1002' },
      { district: 'Bagrami', code: 'AFG-1003' },
      { district: 'Farza', code: 'AFG-1004' },
      { district: 'Paghman', code: 'AFG-1005' },
      { district: 'Qarabagh', code: 'AFG-1006' },
      { district: 'Chahar Aseyab', code: 'AFG-1007' },
      { district: 'Kalakan', code: 'AFG-1008' },
      { district: 'Khake Jabar', code: 'AFG-1009' },
      { district: 'Guldara', code: 'AFG-1010' },
      { district: 'Mosahi', code: 'AFG-1011' },
      { district: 'Dehsabz', code: 'AFG-1012' },
      { district: 'Surobi', code: 'AFG-1013' },
      { district: 'Mirbachakot', code: 'AFG-1014' },
      { district: 'Shakar Darah', code: 'AFG-1015' },
    ],
  },
  {
    province: 'Herat',
    districts: [
      { district: 'Adraskan', code: 'AFG-1016' },
      { district: 'Enjil', code: 'AFG-1017' },
      { district: 'Obah', code: 'AFG-1018' },
      { district: 'Pashton Zarghon', code: 'AFG-1019' },
      { district: 'Cheshte Sharif', code: 'AFG-1020' },
      { district: 'Herat', code: 'AFG-1021' },
      { district: 'Torghondi', code: 'AFG-1022' },
      { district: 'Islam Qala', code: 'AFG-1023' },
      { district: 'Zenda Jan', code: 'AFG-1024' },
      { district: 'Shendand', code: 'AFG-1025' },
      { district: 'Ghoreyan', code: 'AFG-1026' },
      { district: 'Farsi', code: 'AFG-1027' },
      { district: 'Karokh', code: 'AFG-1028' },
      { district: 'Keshk', code: 'AFG-1029' },
      { district: 'Kohsan', code: 'AFG-1031' },
      { district: 'Guzara', code: 'AFG-1032' },
      { district: 'Gulran', code: 'AFG-1033' },
    ],
  },
  {
    province: 'Nangarhar',
    districts: [
      { district: 'Achen', code: 'AFG-1034' },
      { district: 'Rodath', code: 'AFG-1035' },
      { district: 'Bathekod', code: 'AFG-1036' },
      { district: 'Surkhrod', code: 'AFG-1037' },
      { district: 'Bihsud', code: 'AFG-1038' },
      { district: 'Shirzad', code: 'AFG-1039' },
      { district: 'PacheroAgan', code: 'AFG-1040' },
      { district: 'Ghani khil', code: 'AFG-1041' },
      { district: 'Kama', code: 'AFG-1042' },
      { district: 'Hesarak', code: 'AFG-1043' },
      { district: 'Haska Minah', code: 'AFG-1044' },
      { district: 'Chaparhar', code: 'AFG-1045' },
      { district: 'Goshta', code: 'AFG-1046' },
      { district: 'Khogyani', code: 'AFG-1047' },
      { district: 'Jalalabad', code: 'AFG-1048' },
      { district: 'Shinwar', code: 'AFG-1049' },
      { district: 'Kuz Kunar', code: 'AFG-1050' },
      { district: 'Dehbala', code: 'AFG-1051' },
      { district: 'Dorbaba', code: 'AFG-1052' },
      { district: 'Lalpour', code: 'AFG-1053' },
      { district: 'Dare Nor', code: 'AFG-1054' },
      { district: 'Mohmand Dara', code: 'AFG-1055' },
      { district: 'Khewah', code: 'AFG-1056' },
      { district: 'Naziyan', code: 'AFG-1057' },
    ],
  },
  {
    province: 'Balkh',
    districts: [
      { district: 'Charbolak', code: 'AFG-1059' },
      { district: 'Charkent', code: 'AFG-1060' },
      { district: 'Chemtal', code: 'AFG-1061' },
      { district: 'Khulm', code: 'AFG-1062' },
      { district: 'Dawlatabad', code: 'AFG-1063' },
      { district: 'Dehdadi', code: 'AFG-1064' },
      { district: 'Zari', code: 'AFG-1065' },
      { district: 'Shortapah', code: 'AFG-1066' },
      { district: 'Sholgarah', code: 'AFG-1067' },
      { district: 'Keshendah', code: 'AFG-1068' },
      { district: 'Kaldar', code: 'AFG-1069' },
      { district: 'Marmal', code: 'AFG-1070' },
    ],
  },
  {
    province: 'Kandahar',
    districts: [
      { district: 'Arghestan', code: 'AFG-1073' },
      { district: 'Shahwali kot', code: 'AFG-1074' },
      { district: 'Arghandab', code: 'AFG-1075' },
      { district: 'Shorabak', code: 'AFG-1076' },
      { district: 'Paknjwaee', code: 'AFG-1077' },
      { district: 'Ghorak', code: 'AFG-1078' },
      { district: 'Khakriz', code: 'AFG-1079' },
      { district: 'Daman', code: 'AFG-1080' },
      { district: 'Marof', code: 'AFG-1081' },
      { district: 'Rigestan', code: 'AFG-1082' },
      { district: 'Mianshin', code: 'AFG-1083' },
      { district: 'Zharee', code: 'AFG-1084' },
      { district: 'Miwand', code: 'AFG-1085' },
      { district: 'Nish', code: 'AFG-1086' },
      { district: 'Kandahar', code: 'AFG-1087' },
      { district: 'Dand', code: 'AFG-1088' },
      { district: 'Spin Boldak', code: 'AFG-1089' },
    ],
  },
  {
    province: 'Ghor',
    districts: [
      { district: 'Firoz Koh', code: 'AFG-1090' },
      { district: 'Tiurah', code: 'AFG-1091' },
      { district: 'Lal w Sarjangal', code: 'AFG-1092' },
      { district: 'Dolina', code: 'AFG-1093' },
      { district: 'Chahar Sadah', code: 'AFG-1094' },
      { district: 'Marghab', code: 'AFG-1095' },
      { district: 'Tolak', code: 'AFG-1096' },
      { district: 'Shahrak', code: 'AFG-1097' },
      { district: 'Saghar', code: 'AFG-1098' },
      { district: 'DawlatYar', code: 'AFG-1099' },
      { district: 'Pasaband', code: 'AFG-1100' },
    ],
  },
  {
    province: 'Laghman',
    districts: [
      { district: 'Dawlat Shah', code: 'AFG-1101' },
      { district: 'Qarghaee', code: 'AFG-1102' },
      { district: 'Ali Sheng', code: 'AFG-1103' },
      { district: 'Ali Negar', code: 'AFG-1104' },
      { district: 'Mehtar Lam', code: 'AFG-1105' },
    ],
  },
  {
    province: 'Kundoz',
    districts: [
      { district: 'Imam Saheb', code: 'AFG-1106' },
      { district: 'Chahar Dara', code: 'AFG-1107' },
      { district: 'Gul Tepa', code: 'AFG-1108' },
      { district: 'Khan Abad', code: 'AFG-1109' },
      { district: 'Aaqtash', code: 'AFG-1110' },
      { district: 'Dasht Archi', code: 'AFG-1111' },
      { district: 'Ali Abad', code: 'AFG-1112' },
      { district: 'Qalah Zal', code: 'AFG-1113' },
      { district: 'Kundoz', code: 'AFG-1114' },
    ],
  },
  {
    province: 'Paktia',
    districts: [
      { district: 'Gardiz', code: 'AFG-1115' },
      { district: 'Ahmad Abad', code: 'AFG-1116' },
      { district: 'Jani Khail', code: 'AFG-1117' },
      { district: 'Chamkani', code: 'AFG-1118' },
      { district: 'Dand Patan', code: 'AFG-1119' },
      { district: 'Wazi Zadran', code: 'AFG-1120' },
      { district: 'Zarmat', code: 'AFG-1121' },
      { district: 'Said Karam', code: 'AFG-1122' },
      { district: 'Showak', code: 'AFG-1123' },
      { district: 'Laja Ahmad Khail', code: 'AFG-1124' },
      { district: 'Mirzaka', code: 'AFG-1125' },
      { district: 'Zazi Aryub', code: 'AFG-1126' },
    ],
  },
  {
    province: 'Zabul',
    districts: [
      { district: 'Atghar', code: 'AFG-1127' },
      { district: 'Arghandab', code: 'AFG-1128' },
      { district: 'Trang w Jaldak', code: 'AFG-1129' },
      { district: 'Dai Chopan', code: 'AFG-1130' },
      { district: 'Shah Joy', code: 'AFG-1131' },
      { district: 'Shamalzai', code: 'AFG-1132' },
      { district: 'Shenkai', code: 'AFG-1133' },
      { district: 'Qalat', code: 'AFG-1134' },
      { district: 'Kakar', code: 'AFG-1135' },
      { district: 'Mizan', code: 'AFG-1136' },
      { district: 'Newbahar', code: 'AFG-1137' },
    ],
  },
  {
    province: 'Baghlan',
    districts: [
      { district: 'Nahrain', code: 'AFG-1138' },
      { district: 'Andarab', code: 'AFG-1139' },
      { district: 'Baghlan Jadid', code: 'AFG-1140' },
      { district: 'Pol Hesar', code: 'AFG-1141' },
      { district: 'Pol Khomri', code: 'AFG-1142' },
      { district: 'Tala w Barfak', code: 'AFG-1143' },
      { district: 'Jelga', code: 'AFG-1144' },
      { district: 'Khenjan', code: 'AFG-1145' },
      { district: 'Khost w Freng', code: 'AFG-1146' },
      { district: 'Doshi', code: 'AFG-1147' },
      { district: 'Dahana Ghori', code: 'AFG-1148' },
      { district: 'Deh Salah', code: 'AFG-1149' },
      { district: 'Freng w Gharo', code: 'AFG-1150' },
      { district: 'Gozarga Noor', code: 'AFG-1151' },
      { district: 'Berka', code: 'AFG-1152' },
    ],
  },
  {
    province: 'Badakhshan',
    districts: [
      { district: 'Arghang Khah', code: 'AFG-1153' },
      { district: 'Sheghnan', code: 'AFG-1154' },
      { district: 'Argo', code: 'AFG-1155' },
      { district: 'Shaki', code: 'AFG-1156' },
      { district: 'Ishkashem', code: 'AFG-1157' },
      { district: 'Shohada', code: 'AFG-1158' },
      { district: 'Baharak', code: 'AFG-1159' },
      { district: 'Shahr Bozorg', code: 'AFG-1160' },
      { district: 'Tagab/Keshm Bala', code: 'AFG-1161' },
      { district: 'Shehr Faiz Abad', code: 'AFG-1162' },
      { district: 'Keran w Manjan', code: 'AFG-1163' },
      { district: 'Teshkan', code: 'AFG-1164' },
      { district: 'Jerm', code: 'AFG-1165' },
      { district: 'Keshm', code: 'AFG-1166' },
      { district: 'Kof Ab', code: 'AFG-1167' },
      { district: 'Khahan', code: 'AFG-1168' },
      { district: 'Kohestan', code: 'AFG-1169' },
      { district: 'Draim', code: 'AFG-1170' },
      { district: 'Wakhan', code: 'AFG-1171' },
      { district: 'Dawaz Paeen', code: 'AFG-1172' },
      { district: 'Wardoj', code: 'AFG-1173' },
      { district: 'Yawan', code: 'AFG-1174' },
      { district: 'Darwaz Bala', code: 'AFG-1175' },
      { district: 'Yaftal Pain', code: 'AFG-1176' },
      { district: 'Raghestan', code: 'AFG-1177' },
      { district: 'Yamgan', code: 'AFG-1178' },
      { district: 'Zibak', code: 'AFG-1179' },
    ],
  },
  {
    province: 'Bamyan',
    districts: [
      { district: 'Bamyan', code: 'AFG-1180' },
      { district: 'Kahmard', code: 'AFG-1181' },
      { district: 'Panjab', code: 'AFG-1182' },
      { district: 'Saighan', code: 'AFG-1183' },
      { district: 'Shibar', code: 'AFG-1184' },
      { district: 'Waras', code: 'AFG-1185' },
      { district: 'Yakawlang 1', code: 'AFG-1186' },
      { district: 'Yakawlang 2', code: 'AFG-1187' },
    ],
  },
  {
    province: 'Badghis',
    districts: [
      { district: 'Ab Kamari', code: 'AFG-1188' },
      { district: 'Jond', code: 'AFG-1189' },
      { district: 'Qadis', code: 'AFG-1190' },
      { district: 'Qalah New', code: 'AFG-1191' },
      { district: 'Bala Morghab', code: 'AFG-1192' },
      { district: 'Moqor', code: 'AFG-1193' },
    ],
  },
  {
    province: 'Maidan Wardak',
    districts: [
      { district: 'Maidan Wardak', code: 'AFG-1194' },
      { district: 'Jalriz', code: 'AFG-1195' },
      { district: 'Jaghto', code: 'AFG-1196' },
      { district: 'Chak Wardak', code: 'AFG-1197' },
      { district: 'Hesa Awal Behsod', code: 'AFG-1198' },
      { district: 'Daimerdad/Jelga', code: 'AFG-1199' },
      { district: 'Said Abad', code: 'AFG-1200' },
      { district: 'Markaz Behsod', code: 'AFG-1201' },
      { district: 'Nerkh', code: 'AFG-1202' },
    ],
  },
  {
    province: 'Loger',
    districts: [
      { district: 'Azrah', code: 'AFG-1203' },
      { district: 'Baraki Barak', code: 'AFG-1204' },
      { district: 'Pol e ALam', code: 'AFG-1205' },
      { district: 'Charkh', code: 'AFG-1206' },
      { district: 'Kherwar', code: 'AFG-1207' },
      { district: 'Khoshi', code: 'AFG-1208' },
      { district: 'Mohammad Agha', code: 'AFG-1209' },
    ],
  },
  {
    province: 'Samangan',
    districts: [
      { district: 'Aybak', code: 'AFG-1210' },
      { district: 'Hazrat Sultan', code: 'AFG-1211' },
      { district: 'Khuram w Sarbagh', code: 'AFG-1212' },
      { district: 'Darahsof Bala', code: 'AFG-1213' },
      { district: 'Darahsof Pain', code: 'AFG-1214' },
      { district: 'Roy Do Ab', code: 'AFG-1215' },
      { district: 'Feroz Nakhcher', code: 'AFG-1216' },
    ],
  },
  {
    province: 'Takhar',
    districts: [
      { district: 'Dasht Qalah', code: 'AFG-1217' },
      { district: 'Ishkamash', code: 'AFG-1218' },
      { district: 'Bangi', code: 'AFG-1219' },
      { district: 'Rostaq', code: 'AFG-1220' },
      { district: 'Baharak', code: 'AFG-1221' },
      { district: 'Farkhar', code: 'AFG-1222' },
      { district: 'Talqan', code: 'AFG-1223' },
      { district: 'Chal', code: 'AFG-1224' },
      { district: 'Kalafkan', code: 'AFG-1225' },
      { district: 'Chah Ab', code: 'AFG-1226' },
      { district: 'Namak Ab', code: 'AFG-1227' },
      { district: 'Kahwja Bahawoddin', code: 'AFG-1228' },
      { district: 'Warsaj', code: 'AFG-1229' },
      { district: 'Khawja Ghar', code: 'AFG-1230' },
      { district: 'Hazar somoch', code: 'AFG-1231' },
      { district: 'Darqad', code: 'AFG-1232' },
      { district: 'Yengi Qalah', code: 'AFG-1233' },
    ],
  },
  {
    province: 'Nuristan',
    districts: [
      { district: 'Barg Matal ', code: 'AFG-1234' },
      { district: 'Paron', code: 'AFG-1235' },
      { district: 'Do Ab', code: 'AFG-1236' },
      { district: 'Kamdish', code: 'AFG-1237' },
      { district: 'Mandol', code: 'AFG-1238' },
      { district: 'Noor Geram', code: 'AFG-1239' },
      { district: 'Wama', code: 'AFG-1240' },
      { district: 'Waigal', code: 'AFG-1241' },
    ],
  },
  {
    province: 'Faryab',
    districts: [
      { district: 'Almar', code: 'AFG-1242' },
      { district: 'Andkhoy', code: 'AFG-1243' },
      { district: 'Shirin Tagab', code: 'AFG-1244' },
      { district: 'Qorghan', code: 'AFG-1245' },
      { district: 'Balcheragh', code: 'AFG-1246' },
      { district: 'Qaram Qol', code: 'AFG-1247' },
      { district: 'Pashton Kot', code: 'AFG-1248' },
      { district: 'Qaisar', code: 'AFG-1249' },
      { district: 'Khan Charbagh', code: 'AFG-1250' },
      { district: 'Dawlat Abad', code: 'AFG-1251' },
      { district: 'Kohestan', code: 'AFG-1252' },
      { district: 'Khawja Sabzposh Wali', code: 'AFG-1253' },
      { district: 'Garziwan', code: 'AFG-1254' },
      { district: 'Maimana', code: 'AFG-1255' },
      { district: 'Ghormach', code: 'AFG-1256' },
    ],
  },
  {
    province: 'Sar-e Pul',
    districts: [
      { district: 'Balkhab', code: 'AFG-1257' },
      { district: 'Sar-e Pul', code: 'AFG-1258' },
      { district: 'Sancharak', code: 'AFG-1259' },
      { district: 'Sozma Qala', code: 'AFG-1260' },
      { district: 'Sayad', code: 'AFG-1261' },
      { district: 'Kohestanat', code: 'AFG-1262' },
      { district: 'Gosfandi', code: 'AFG-1263' },
    ],
  },
  {
    province: 'Paktika',
    districts: [
      { district: 'Argon', code: 'AFG-1264' },
      { district: 'Sharan', code: 'AFG-1265' },
      { district: 'Omna', code: 'AFG-1266' },
      { district: 'Gomal', code: 'AFG-1267' },
      { district: 'Barmal', code: 'AFG-1268' },
      { district: 'Gian', code: 'AFG-1269' },
      { district: 'Taro', code: 'AFG-1270' },
      { district: 'Mata Khan', code: 'AFG-1271' },
      { district: 'Jani khail', code: 'AFG-1272' },
      { district: 'Nakah', code: 'AFG-1273' },
      { district: 'Wazah Khah', code: 'AFG-1274' },
      { district: 'Daila w Khoshamand', code: 'AFG-1275' },
      { district: 'warmami', code: 'AFG-1276' },
      { district: 'Zarghon Shahr', code: 'AFG-1277' },
      { district: 'Zirok', code: 'AFG-1278' },
      { district: 'Yahya Khail', code: 'AFG-1279' },
      { district: 'Sarhawza', code: 'AFG-1280' },
      { district: 'Yosuf Khail', code: 'AFG-1281' },
      { district: 'Sorobi', code: 'AFG-1282' },
    ],
  },
  {
    province: 'Farah',
    districts: [
      { district: 'Anar Dara', code: 'AFG-1283' },
      { district: 'Bala Block', code: 'AFG-1284' },
      { district: 'Bakwah', code: 'AFG-1285' },
      { district: 'Parchaman', code: 'AFG-1286' },
      { district: 'Posht Road', code: 'AFG-1287' },
      { district: 'Khak Safid', code: 'AFG-1288' },
      { district: 'Shib Koh', code: 'AFG-1289' },
      { district: 'Farah', code: 'AFG-1290' },
      { district: 'Qala Gah', code: 'AFG-1291' },
      { district: 'Gulistan', code: 'AFG-1292' },
      { district: 'Lash w Jowin', code: 'AFG-1293' },
    ],
  },
  {
    province: 'Helmand',
    districts: [
      { district: 'Boghran', code: 'AFG-1294' },
      { district: 'Disho', code: 'AFG-1295' },
      { district: 'Rigistan Khanshin', code: 'AFG-1296' },
      { district: 'Sangin', code: 'AFG-1297' },
      { district: 'Kajaki', code: 'AFG-1298' },
      { district: 'Garmsir', code: 'AFG-1299' },
      { district: 'Lashkargah', code: 'AFG-1300' },
      { district: 'Mosa Qala', code: 'AFG-1301' },
      { district: 'Nad Ali', code: 'AFG-1302' },
      { district: 'Nawah Barakzaie', code: 'AFG-1303' },
      { district: 'Nawzad', code: 'AFG-1304' },
      { district: 'Nahr Seraj', code: 'AFG-1305' },
      { district: 'Washir', code: 'AFG-1306' },
      { district: 'Greshk', code: 'AFG-1307' },
      { district: 'Marjah', code: 'AFG-1308' },
    ],
  },
  {
    province: 'Nemruz',
    districts: [
      { district: 'Chakhansor', code: 'AFG-1309' },
      { district: 'Chahar Borjak', code: 'AFG-1310' },
      { district: 'Khash Road', code: 'AFG-1311' },
      { district: 'Zarang', code: 'AFG-1312' },
      { district: 'Kang', code: 'AFG-1313' },
    ],
  },
  {
    province: 'Ghazni',
    districts: [
      { district: 'Ab Band', code: 'AFG-1314' },
      { district: 'Jaghato', code: 'AFG-1315' },
      { district: 'Ajrestan', code: 'AFG-1316' },
      { district: 'Qara Bagh', code: 'AFG-1317' },
      { district: 'Giro', code: 'AFG-1318' },
      { district: 'Andar', code: 'AFG-1319' },
      { district: 'Gilan', code: 'AFG-1320' },
      { district: 'Malestan', code: 'AFG-1321' },
      { district: 'Jaghori', code: 'AFG-1322' },
      { district: 'Moqor', code: 'AFG-1193' },
      { district: 'Khawja Omari', code: 'AFG-1324' },
      { district: 'Nawor', code: 'AFG-1325' },
      { district: 'Dah Yak', code: 'AFG-1326' },
      { district: 'Nawah', code: 'AFG-1327' },
      { district: 'Rashidan', code: 'AFG-1328' },
      { district: 'Waghaz', code: 'AFG-1329' },
      { district: 'Zanakhan', code: 'AFG-1330' },
      { district: 'Khogyani', code: 'AFG-1331' },
      { district: 'Ghazni', code: 'AFG-1332' },
    ],
  },
  {
    province: 'Oruzgan',
    districts: [
      { district: 'Terinkot', code: 'AFG-1333' },
      { district: 'Chorah', code: 'AFG-1334' },
      { district: 'Khasorozgan', code: 'AFG-1335' },
      { district: 'Dehrowod', code: 'AFG-1336' },
      { district: 'Shahid Hasas', code: 'AFG-1337' },
      { district: 'Dai Chopan', code: 'AFG-1138' },
      { district: 'Gezab', code: 'AFG-1339' },
    ],
  },
  {
    province: 'Kapisa',
    districts: [
      { district: 'Alasaye', code: 'AFG-1340' },
      { district: 'Tagab', code: 'AFG-1341' },
      { district: 'Hesa Awal Kohistan', code: 'AFG-1342' },
      { district: 'Hesa Dowom Kohistan', code: 'AFG-1343' },
      { district: 'Kohband', code: 'AFG-1344' },
      { district: 'Mahmod Raqi', code: 'AFG-1345' },
      { district: 'Nigrab', code: 'AFG-1346' },
    ],
  },
  {
    province: 'Parwan',
    districts: [
      { district: 'Bagram', code: 'AFG-1347' },
      { district: 'Jabal Seraj', code: 'AFG-1348' },
      { district: 'Charikar', code: 'AFG-1349' },
      { district: 'Salang', code: 'AFG-1350' },
      { district: 'Surkhparsa', code: 'AFG-1351' },
      { district: 'Sayed khiel', code: 'AFG-1352' },
      { district: 'Shaikh Ali', code: 'AFG-1353' },
      { district: 'Shenwari', code: 'AFG-1354' },
      { district: 'Ghorband', code: 'AFG-1355' },
      { district: 'Kohesafi', code: 'AFG-1356' },
    ],
  },
  {
    province: 'Panjshir',
    districts: [
      { district: 'Anabah', code: 'AFG-1357' },
      { district: 'Bazarak', code: 'AFG-1358' },
      { district: 'Paryan', code: 'AFG-1359' },
      { district: 'Khenj', code: 'AFG-1360' },
      { district: 'Darah', code: 'AFG-1361' },
      { district: 'Rokhah', code: 'AFG-1362' },
      { district: 'Shatal', code: 'AFG-1363' },
      { district: 'Abshar', code: 'AFG-1364' },
    ],
  },
  {
    province: 'Jowzjan',
    districts: [
      { district: 'Aqchah', code: 'AFG-1365' },
      { district: 'Khaneqah', code: 'AFG-1366' },
      { district: 'Khamab', code: 'AFG-1367' },
      { district: 'Khaja Dokoh', code: 'AFG-1368' },
      { district: 'Darzab', code: 'AFG-1369' },
      { district: 'Sheberghan', code: 'AFG-1370' },
      { district: 'Faiz Abad', code: 'AFG-1371' },
      { district: 'Qarqin', code: 'AFG-1372' },
      { district: 'Qoshtapah', code: 'AFG-1373' },
      { district: 'Mardian', code: 'AFG-1374' },
      { district: 'Mangjak', code: 'AFG-1375' },
    ],
  },
  {
    province: 'Khost',
    districts: [
      { district: 'Bak', code: 'AFG-1376' },
      { district: 'Tanai', code: 'AFG-1377' },
      { district: 'Terezai', code: 'AFG-1378' },
      { district: 'Jajimaidan', code: 'AFG-1379' },
      { district: 'Khostmaton', code: 'AFG-1380' },
      { district: 'Sperah', code: 'AFG-1381' },
      { district: 'Shamal', code: 'AFG-1382' },
      { district: 'Sabri/Yaqubi', code: 'AFG-1383' },
      { district: 'Qalandar', code: 'AFG-1384' },
      { district: 'Guriz', code: 'AFG-1385' },
      { district: 'Mandozai', code: 'AFG-1386' },
      { district: 'Musakheil', code: 'AFG-1387' },
      { district: 'Nadir shah kot', code: 'AFG-1388' },
    ],
  },
  {
    province: 'Kunar',
    districts: [
      { district: 'Asad abad', code: 'AFG-1389' },
      { district: 'Sarkani', code: 'AFG-1390' },
      { district: 'Barknar', code: 'AFG-1391' },
      { district: 'Shigal & Shatal', code: 'AFG-1392' },
      { district: 'Khas konar', code: 'AFG-1393' },
      { district: 'Ghaziabad', code: 'AFG-1394' },
      { district: 'Marorah', code: 'AFG-1395' },
      { district: 'Dangam', code: 'AFG-1396' },
      { district: 'Nari', code: 'AFG-1397' },
      { district: 'Narang', code: 'AFG-1398' },
      { district: 'Darahpech', code: 'AFG-1399' },
      { district: 'Norgul', code: 'AFG-1400' },
      { district: 'Chapadarah', code: 'AFG-1401' },
      { district: 'Wotapor', code: 'AFG-1402' },
      { district: 'Chawki', code: 'AFG-1403' },
    ],
  },
  {
    province: 'Daikundi',
    districts: [
      { district: 'Ashtarlai', code: 'AFG-1404' },
      { district: 'Kheder', code: 'AFG-1405' },
      { district: 'Sangtakht', code: 'AFG-1406' },
      { district: 'Shahristan', code: 'AFG-1407' },
      { district: 'Kejran', code: 'AFG-1408' },
      { district: 'Keti', code: 'AFG-1409' },
      { district: 'Meramor', code: 'AFG-1410' },
      { district: 'Neli', code: 'AFG-1411' },
    ],
  },
]
