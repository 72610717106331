import styles from '../style.module.scss'
import { PositionDetailViewTabs } from './Tabs'

export const PositionsDetailView = () => {
  return (
    <div className={styles.layout}>
      <PositionDetailViewTabs />
    </div>
  )
}
