import React, { memo } from 'react'
import { FileInMessage } from '../Message/Components/FileInMessage'
import { IInternalChatMessageFile } from '../../../../models'
import { IExternalChatMessageFile } from '../../../../../Support/models/IExternalChat'

interface IProps {
  files: Array<IInternalChatMessageFile | IExternalChatMessageFile>
}

const FilesInMessage = ({ files }: IProps) => {
  return (
    <>
      {files.map((file) => (
        <FileInMessage file={file} key={file?.id} />
      ))}
    </>
  )
}

export default memo(FilesInMessage)
