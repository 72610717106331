import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import FormItem from 'antd/es/form/FormItem'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'
import styles from '../../VendorManagementView/Components/styles.module.scss'
import wrapperStyles from '../../styles.module.scss'
import { TextField } from '../../../../../../shared/components/textfield/TextField'
import React, { useMemo } from 'react'
import { Col, Row, Select, Spin } from 'antd'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import { MASTER_PRODUCT_TYPE_ENUM } from '../../../../models/IProduct'
import { startCase } from 'lodash'
import { useGetReceiverPhoneNumberPrefixesQuery } from '../../../../core/http/VendorManagementApi'
import { selectTagRender } from '../../../../../../helpers/selectTagRender'
import {
  maxLengthValidator,
  positiveNumber,
  requiredValidator,
} from '../../../../../../helpers/validation'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

interface IProps extends IViewMode {
  isTypeDisabled?: boolean
}

export const DetailInfo = ({ view, isTypeDisabled = false }: IProps) => {
  const form = useFormInstance()
  const { data: prefixes, isFetching } = useGetReceiverPhoneNumberPrefixesQuery()

  const productTypes = useMemo(
    () =>
      (Object.keys(MASTER_PRODUCT_TYPE_ENUM) as Array<keyof typeof MASTER_PRODUCT_TYPE_ENUM>)
        .map((method) => ({
          label: startCase(MASTER_PRODUCT_TYPE_ENUM[method].toLowerCase()),
          value: MASTER_PRODUCT_TYPE_ENUM[method],
        }))
        .filter((type) => type.value !== MASTER_PRODUCT_TYPE_ENUM.E_PIN),
    []
  )

  const onChangeType = () => {
    form.setFields([
      {
        name: 'price',
        value: null,
        errors: [],
      },
      {
        name: 'externalCode',
        value: null,
        errors: [],
      },
      {
        name: 'products',
        value: null,
        errors: [],
      },
    ])
  }

  const content = (
    <Wrapper
      title='Detail Info'
      statusButton={
        <FormItem name='status' valuePropName='checked'>
          <Toggle text='Status' disabled={view} inWrapper />
        </FormItem>
      }
      className={wrapperStyles.masterProductDetailInfo}
    >
      <div className={styles.dblRow}>
        <FormItem name='createdByUser' label='Added By'>
          <TextField disabled />
        </FormItem>

        <FormItem name='createdAt' label='Date/Time created'>
          <TextField disabled />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem
          name='name'
          label='Master Product Name'
          rules={[
            { required: true, validator: requiredValidator('Master Product Name') },
            { validator: maxLengthValidator(45) },
          ]}
        >
          <TextField disabled={view} placeholder='Enter Master Product Name' />
        </FormItem>

        <FormItem
          name='type'
          label='Product Type'
          rules={[{ required: true, validator: requiredValidator('Product Type') }]}
        >
          <Select
            disabled={isTypeDisabled}
            options={productTypes}
            allowClear
            onClear={onChangeType}
            onChange={onChangeType}
            placeholder='Select Product Type'
          />
        </FormItem>
      </div>

      <Row gutter={24}>
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const type = getFieldValue('type') as MASTER_PRODUCT_TYPE_ENUM
            if (
              ![MASTER_PRODUCT_TYPE_ENUM.BUNDLE_TOPUP, MASTER_PRODUCT_TYPE_ENUM.TOPUP].includes(
                type
              )
            ) {
              return (
                <Col span={12}>
                  <FormItem noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const isMasterBundle =
                        getFieldValue('type') === MASTER_PRODUCT_TYPE_ENUM.MASTER_BUNDLE_TOPUP

                      return (
                        <FormItem
                          name='price'
                          label='Price'
                          rules={
                            isMasterBundle
                              ? [
                                  { required: true, validator: requiredValidator('Price') },
                                  { validator: positiveNumber('Price') },
                                ]
                              : [{}]
                          }
                        >
                          <TextField
                            disabled={view || !isMasterBundle}
                            type='number'
                            placeholder='Enter Product Type'
                          />
                        </FormItem>
                      )
                    }}
                  </FormItem>
                </Col>
              )
            }
          }}
        </FormItem>
        <Col span={12}>
          <FormItem name='receiverPhoneNumberPrefixes' label='Receiver Phone Number'>
            <Select
              mode='multiple'
              disabled={view}
              tagRender={selectTagRender}
              maxTagCount='responsive'
              showArrow
              allowClear
              placeholder='Select Receiver Phone Number'
            >
              {prefixes?.items?.map((prefix) => (
                <Select.Option key={prefix.prefix} value={prefix.prefix}>
                  {`${prefix.prefix} ${prefix.description}`}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            name='internalCode'
            label='Master Product Internal Code'
            rules={[
              { required: true, validator: requiredValidator('Master Product Internal Code') },
            ]}
            required
          >
            <TextField disabled={view} placeholder='Enter Master Product Internal Code' />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const isMasterBundle =
                getFieldValue('type') === MASTER_PRODUCT_TYPE_ENUM.MASTER_BUNDLE_TOPUP
              return (
                <FormItem
                  name='externalCode'
                  label='Master Product External Code'
                  rules={[
                    ...(isMasterBundle
                      ? [
                          {
                            required: true,
                            validator: requiredValidator('Master Product External Code'),
                          },
                          {
                            validator: maxLengthValidator(3),
                          },
                        ]
                      : [{}]),
                  ]}
                >
                  <TextField
                    disabled={view || !isMasterBundle}
                    placeholder='Enter Master Product External Code'
                  />
                </FormItem>
              )
            }}
          </FormItem>
        </Col>
      </Row>
    </Wrapper>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
