import { Col, Row } from 'antd'
import { CloseIcon } from '../../../assets/svg'
import { useCallback, useEffect, useState } from 'react'
import { ISelectedFilters, TypeOfFilter } from './Filter'
import styles from './styles.module.scss'
import moment from 'moment'
import lodash, { isBoolean, isNil } from 'lodash'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { CALENDAR_TYPE_ENUM } from 'src/features/Settings/models/IUser'
import dayjs from 'dayjs'

interface IProps {
  selectedFilters: ISelectedFilters
  clearAllFilters: () => void
  removeSelectedFilter: (key: keyof ISelectedFilters) => void
}

export function isIsoDate(str: never) {
  const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:\d{2})?$/

  return isoDatePattern.test(str)
}

interface IDataFilter {
  label: string
  value: string | number | undefined
  fieldName: string
}

export const SelectedFilters = ({
  selectedFilters,
  removeSelectedFilter,
  clearAllFilters,
}: IProps) => {
  const { data: profile = { calendar: false } } = useGetProfileQuery()
  const [data, setData] = useState<Array<IDataFilter>>([])

  const handleDate = useCallback(
    (value: TypeOfFilter) => {
      if (isIsoDate(value as never)) {
        return profile.calendar === CALENDAR_TYPE_ENUM.GREGORIAN
          ? moment(value as string)
              .format('YYYY-MM-DD')
              .toString()
          : dayjs(value as string)
              .calendar('jalali')
              .format('YYYY-MM-DD')
      }

      if (Array.isArray(value)) {
        return value.join(', ')
      }

      if (isBoolean(value)) {
        return value.toString()
      }

      if (value === 'true') {
        return 'Active'
      }

      if (value === 'false') {
        return 'Inactive'
      }

      return value
    },
    [profile]
  )

  const getFieldLabel = useCallback(
    (fieldName: string) => {
      const dateFilterFields =
        Object.entries(selectedFilters)
          ?.filter(([key, val]) => key.split('_').length === 2 && isIsoDate(val as never))
          ?.flat() || []

      if (dateFilterFields?.includes(fieldName)) {
        return fieldName?.split('_')[1] === 'less' ? 'to' : 'from'
      }

      return lodash.startCase(fieldName)
    },
    [selectedFilters]
  )

  const shouldDisplayCloseBtn = (label: string, fieldName: string) => {
    // IF WE FILTER BY DATE OR NUMBER RANGE, WE SET TWO FIELDS WITH POSTFIX _LESS OR _MORE
    const isSeparateField =
      Object.keys(selectedFilters)
        .map((filter) => filter.split('_')[0])
        .filter((splitted) => splitted === fieldName.split('_')[0]).length > 1

    if (isSeparateField) {
      return fieldName.toLowerCase().includes('less') || label === 'to'
    }

    return true
  }

  useEffect(() => {
    const mappedFilters = Object.entries(selectedFilters)
      .filter((item) => !isNil(item[1]))
      .map((filtered) => ({
        label: getFieldLabel(filtered[0]),
        value: handleDate(filtered[1]),
        fieldName: filtered[0],
      }))

    setData(mappedFilters)
  }, [getFieldLabel, handleDate, selectedFilters])

  return (
    <>
      {data.length !== 0 && (
        <Row className={styles['filter__top-selected']}>
          {data.map((filter) => (
            <Row key={filter.fieldName} align='middle' gutter={7}>
              <Col className={styles['filter__top-item-label']}>{filter.label}:</Col>
              <Col className={styles['filter__top-item-text']}>{filter.value}</Col>
              {shouldDisplayCloseBtn(filter.label, filter.fieldName) && (
                <Col
                  onClick={() => removeSelectedFilter(filter.fieldName as keyof ISelectedFilters)}
                  className={styles['filter__top-item-icon']}
                >
                  <CloseIcon />
                </Col>
              )}
            </Row>
          ))}
          <span onClick={clearAllFilters} className={styles['filter__top-clear']}>
            Clear all
          </span>
        </Row>
      )}
    </>
  )
}
