import { Link, useNavigate } from 'react-router-dom'
import { useCallback, useContext, useState } from 'react'
import { ISelectedFilters } from '../../../../../../../../../shared/components/filter/Filter'
import { ITableConf, SortingOrderEnum } from '../../../../../../../../../shared/models/ITableConf'
import {
  useGetUsersFiltersListQuery,
  useUpdateUserByIdMutation,
} from '../../../../../../../core/http/UsersManagementApi'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../../../shared/api/errorHandler'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../../../../shared/components/popup/Popup'
import { SETTINGS_ENUM } from '../../../../../../../../../routes/settings'
import { ColumnsType } from 'antd/es/table'
import { IUser } from '../../../../../../../models/IUser'
import styles from '../../../Users/styles.module.scss'
import emptyAvatarImage from '../../../../../../../../../assets/img/Avatar.png'
import {
  DateTableIcon,
  PhoneLightBlueIcon,
  TableActionIcon,
} from '../../../../../../../../../assets/svg'
import { formatPhoneNumber } from '../../../../../../../../salesNetwork/helpers'
import { formatDateWithTime } from '../../../../../../../../../helpers/utils'
import { Toggle } from '../../../../../../../../../shared/components/toggle/Toggle'
import { useEmptyTable } from '../../../../../../../../../shared/hooks/table/useEmptyTable'
import { UsersTableHeader } from '../../../Users/Components/UsersTableHeader'
import { Table } from 'antd'
import { useParams } from 'react-router'
import { RightModalContext } from '../../../../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../../../../helpers/contants'
import { useGetPositionUsersListQuery } from '../../../../../../../core/http/PositionsApi'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

export const UsersTab = () => {
  const rightModal = useContext(RightModalContext)
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data, isFetching } = useGetPositionUsersListQuery({
    ...tableConf,
    ...filterValues,
    positionId: id,
  })
  const [patchUser, patchUserResp] = useUpdateUserByIdMutation()
  const { data: filtersList } = useGetUsersFiltersListQuery()
  const { data: profile } = useGetProfileQuery()

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, patchUserResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, patchUserResp.isError, patchUserResp.error as ErrorNode)

  const tableActionsPopup = useCallback(
    (id: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () =>
            rightModal.onOpen(RIGHT_MODALS.SETTINGS.USERS_MANAGEMENT_USER, {
              title: 'View Users',
              mode: 'view',
              id,
            }),
        },
        {
          text: 'Edit',
          shouldDisplay: true,
          onClick: () => navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT}/edit/${id}`),
        },
        {
          text: 'Unassign',
          shouldDisplay: true,
          withDivider: true,
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        },
      ]
    },
    [navigate, rightModal]
  )

  const columns: ColumnsType<IUser> = [
    {
      title: 'Position',
      dataIndex: 'position',
      sorter: true,
      width: '10%',
      render: (position: IUser['userPosition'], row) =>
        row.userPosition && <span>{row.userPosition?.name}</span>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      width: '15%',
      render: (value, record) => (
        <Link to={`${SETTINGS_ENUM.USERS_MANAGEMENT}/view/${record.id}`} className='table-avatar'>
          <img className={styles.avatar} alt='user logo' src={record.logo ?? emptyAvatarImage} />
          <div>{`${record.firstName} ${record.lastName}`}</div>
        </Link>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: true,
      width: '10%',
      render: (role: IUser['userRole'], row) => row.userRole && <span>{row.userRole?.name}</span>,
    },
    {
      title: 'Mobile NO',
      dataIndex: 'mobile',
      sorter: true,
      width: '20%',
      render: (mobile: IUser['phoneNumber'], row) => {
        return (
          row.phoneNumber && (
            <div className='table-contact-info__row mobile'>
              <PhoneLightBlueIcon />
              <span>{formatPhoneNumber(row.phoneNumber)}</span>
            </div>
          )
        )
      },
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      width: '20%',
      sorter: true,
      render: (createdAt: IUser['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <span>{formatDateWithTime(createdAt, profile?.calendar)}</span>
        </div>
      ),
    },
    {
      title: 'Added by',
      dataIndex: 'createdBy',
      width: '20%',
      sorter: true,
      render: (createdBy: IUser['createdBy']) =>
        createdBy && <span>{`${createdBy.firstName} ${createdBy.lastName}`}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      sorter: true,
      render: (status: IUser['status'], row) => {
        const fd = new FormData()
        return (
          <Toggle
            defaultChecked={status}
            onChange={(status) => {
              fd.append('user', JSON.stringify({ status }))
              patchUser({ id: row.id, body: fd })
            }}
            loading={patchUserResp.isLoading}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IUser>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  return (
    <>
      <UsersTableHeader
        filterValues={filterValues}
        setActiveFilters={handleFiltersChange}
        data={filtersList}
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        pagination={pagination}
        className={styles.table}
        onChange={handleTableChange}
        loading={isFetching}
        rowKey={(row) => row?.id}
        dataSource={data?.items}
        rowSelection={{ type: 'checkbox' }}
        scroll={{ x: 1100 }}
      />
    </>
  )
}
