import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { ColumnsType } from 'antd/es/table'
import { Table } from 'antd'
import classNames from 'classnames'

// components
import { Button } from 'src/shared/components/button/Button'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from 'src/shared/components/popup/Popup'
import { TableHeader } from '../../Components/TableHeader'

// icons
import { DateTableIcon, TableActionIcon } from 'src/assets/svg'

// helpers
import { formatDateWithTime, tableExpandedIcon } from 'src/helpers/utils'
import { RIGHT_MODALS } from 'src/helpers/contants'
import { RightModalContext } from 'src/features/Modals'

// interfaces
import { ITableConf, SortingOrderEnum } from 'src/shared/models/ITableConf'
import { IPickupRequest } from 'src/features/Finance/models/IPickupRequest'
import { ISelectedFilters } from 'src/shared/components/filter/Filter'

// api
import {
  useGetPickupRequestListQuery,
  useGetPickupRequestSubQuery,
  useGetPRTableFiltersListQuery,
  useLazyExportPickupRequestQuery,
} from 'src/features/Finance/core/http/BankWalletTransactionsApi'

// styles
import styles from './styles.module.scss'
import { useTableExport } from 'src/shared/hooks/table/useTableExport'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { TRANSACTION_CATEGORY_ENUM, TRANSACTION_STATUS_ENUM } from '../../../../models/ITransaction'
import emptyAvatar from 'src/assets/img/Avatar.png'
import { PickupRefundTableFooter } from '../../Components/PickupRefundTableFooter'
import { Link } from 'react-router-dom'
import { SALES_ENUM } from '../../../../../../routes/sales'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { useOnTransactionHistoryClick } from '../../../../../../shared/hooks/table/useOnTransactionHistoryClick'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'
import { PAYMENT_METHOD_ENUM } from '../../../../models/IBankAccount'

interface IProps {
  canView: boolean
  canAdd: boolean
  canEdit: boolean
  canApprove: boolean
  canReject: boolean
}

export const PickupRequestTable = ({ canView, canAdd, canEdit, canApprove, canReject }: IProps) => {
  const navigate = useNavigate()
  const { onOpen } = useContext(RightModalContext)
  const onTransactionHistoryClick = useOnTransactionHistoryClick()
  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const { data: profile } = useGetProfileQuery()

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data, isFetching } = useGetPickupRequestListQuery(
    { ...tableConf, ...filterValues },
    { skip: Object.keys(tableConf).length < 4 }
  )
  const { data: filtersList } = useGetPRTableFiltersListQuery()

  const [exportPickupRequest] = useLazyExportPickupRequestQuery()

  useTableExport({ tableConf, filterValues, promise: exportPickupRequest })

  const tableActionsPopup = (id: number, row: IPickupRequest): IPopupListItems[] => [
    {
      text: 'View',
      shouldDisplay: canView,
      onClick: () => navigate(`pickup-request/view/${id}`),
    },
    {
      text: 'Edit',
      shouldDisplay: canEdit,
      onClick: () => navigate(`pickup-request/edit/${id}`),
    },
    {
      text: 'History',
      shouldDisplay: true,
      onClick: () => onTransactionHistoryClick(id, TRANSACTION_CATEGORY_ENUM.PICKUP_REQUEST),
    },
    ...(row.status === TRANSACTION_STATUS_ENUM.CREATED
      ? [
          ...(row.paymentType !== PAYMENT_METHOD_ENUM.STRIPE
            ? [
                {
                  text: 'Approve',
                  withDivider: true,
                  color: POPOVER_LIST_ITEM_COLORS_ENUM.GREEN,
                  shouldDisplay: canApprove,
                  onClick: () =>
                    onOpen(RIGHT_MODALS.FINANCE.PICKUP_REQUEST, { id, title: 'Pickup Request' }),
                },
                {
                  text: 'Cancel',
                  color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
                  shouldDisplay: true,
                  onClick: () =>
                    onOpen(RIGHT_MODALS.FINANCE.PICKUP_REQUEST, { id, title: 'Pickup Request' }),
                },
              ]
            : []),
          {
            text: 'Reject',
            color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
            shouldDisplay: canReject,
            withDivider: row.paymentType === PAYMENT_METHOD_ENUM.STRIPE,
            onClick: () =>
              onOpen(RIGHT_MODALS.FINANCE.PICKUP_REQUEST, { id, title: 'Pickup Request' }),
          },
        ]
      : []),
  ]

  const columns: ColumnsType<IPickupRequest> = [
    {
      title: '',
      dataIndex: '',
      width: '1%',
    },
    {
      title: 'Transaction ID',
      dataIndex: 'id',
      sorter: true,
      width: '10%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      sorter: true,
      width: '15%',
      render: (name: IPickupRequest['createdAt']) => (
        <div className={styles.poCreatedContainer}>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(name, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Agent Name',
      dataIndex: 'agent',
      sorter: true,
      width: '15%',
      render: (agent: IPickupRequest['agent']) => (
        <RecursivelyReplaceCharacters>
          <Link to={`${SALES_ENUM.SALES_NETWORK}/view/${agent?.id}`} className='table-avatar'>
            <div>
              <img
                src={!isPrivateMode && agent?.avatar ? agent.avatar : emptyAvatar}
                alt='icon'
                height={32}
                width={32}
              />
            </div>
            <div>
              <div>{agent?.name}</div>
              <div className={styles.agentIdText}>{`ID${agent?.id}`}</div>
            </div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Cash Amount',
      dataIndex: 'currencyAmount',
      width: '15%',
      sorter: true,
      render: (name: IPickupRequest['currencyAmount']) => (
        <RecursivelyReplaceCharacters>
          <div className={styles.priceBold}>{name}</div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Bank Account',
      dataIndex: 'bankAccount',
      sorter: true,
      width: '10%',
      render: (bank: IPickupRequest['bankAccount']) => (
        <RecursivelyReplaceCharacters>{bank?.name}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Emoney Amount',
      dataIndex: 'amount',
      width: '15%',
      sorter: true,
      render: (name: IPickupRequest['amount']) => (
        <RecursivelyReplaceCharacters>
          <div className={styles.priceBold}>{name}</div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: '10%',
      render: (name: IPickupRequest['status']) => (
        <RecursivelyReplaceCharacters>
          <div className={styles.statusContainer}>
            <div className={classNames(styles[name.toLowerCase()], styles.statusPoint)} />
            <div>{name}</div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: '25%',
      render: (_, { id, status, paymentType }) => {
        const isStripePayment = paymentType === PAYMENT_METHOD_ENUM.STRIPE
        return (
          <div className={styles.actionsContainer}>
            {status === TRANSACTION_STATUS_ENUM.CREATED && (
              <>
                {!isStripePayment && (
                  <Button
                    style={{ marginRight: '8px', width: '65px' }}
                    color='blue'
                    size='middle'
                    onClick={() =>
                      onOpen(RIGHT_MODALS.FINANCE.PICKUP_REQUEST, { id, title: 'Pickup Request' })
                    }
                  >
                    Cancel
                  </Button>
                )}
                {canReject && (
                  <Button
                    style={{
                      marginRight: isStripePayment ? '0px' : '8px',
                      width: isStripePayment ? '100%' : '65px',
                    }}
                    size='middle'
                    onClick={() =>
                      onOpen(RIGHT_MODALS.FINANCE.PICKUP_REQUEST, { id, title: 'Pickup Request' })
                    }
                  >
                    Reject
                  </Button>
                )}
                {canApprove && !isStripePayment && (
                  <Button
                    style={{ width: '65px' }}
                    color='blue'
                    size='middle'
                    type='primary'
                    onClick={() =>
                      onOpen(RIGHT_MODALS.FINANCE.PICKUP_REQUEST, { id, title: 'Pickup Request' })
                    }
                  >
                    Approve
                  </Button>
                )}
              </>
            )}
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '10%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id, row)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const [mappedPickups, setMappedPickups] = useState<Array<IPickupRequest>>([])

  const emptyTableConf = useEmptyTable(canAdd ? () => navigate('pickup-request/new') : undefined)
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IPickupRequest>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  const [expandedRows, setExpandedRows] = useState<number>()

  const { data: pickupsSub, isFetching: isSubFetching } = useGetPickupRequestSubQuery(
    expandedRows as number,
    {
      skip: !expandedRows,
    }
  )

  useEffect(() => {
    if (pickupsSub) {
      const mappedData = mappedPickups.map((elem) => {
        if (elem.id === expandedRows) {
          return {
            ...elem,
            children: pickupsSub?.items,
          }
        }
        return { ...elem }
      })

      setMappedPickups(mappedData)
    }
    // eslint-disable-next-line
  }, [setMappedPickups, pickupsSub])

  useEffect(() => {
    if (data) {
      setMappedPickups(data?.items)
    }
  }, [data])

  return (
    <React.Fragment>
      <TableHeader
        numberOfItems={data?.totalCount}
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filtersList}
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        loading={isFetching || isSubFetching}
        onChange={handleTableChange}
        className={styles.tableContainer}
        dataSource={mappedPickups}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.id}
        pagination={pagination}
        summary={(data) => {
          const dataObj = [...data]
          return <PickupRefundTableFooter data={dataObj} />
        }}
        expandable={{
          expandIcon: (props) => {
            if (
              Object.hasOwn(props.record, 'mainTransactionId') ||
              !props.record?.countSubTransaction
            )
              return null
            return tableExpandedIcon<IPickupRequest>(props)
          },
          onExpand: (expanded, record) => {
            setExpandedRows(record.id)
          },
        }}
        scroll={{ x: 940 }}
      />
    </React.Fragment>
  )
}
