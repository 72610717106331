import { TableWrapper } from '../TableWrapper'
import styles from '../styles.module.scss'
import { Form, Spin } from 'antd'
import classNames from 'classnames'
import { requiredValidator } from '../../../../../../helpers/validation'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'
import React from 'react'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import { TextField } from '../../../../../../shared/components/textfield/TextField'

export const AccessChannelBlock = ({ view }: IViewMode) => {
  return (
    <TableWrapper title='Access Channel'>
      <div className={styles.accessChannelInner}>
        <Form.List name='forbiddenAccessChannels'>
          {(fields) => (
            <>
              {fields.map(({ key, name }) => (
                <div key={key} className={styles.discountPolicyRow}>
                  <Form.Item
                    name={[name, 'name']}
                    className={classNames(styles.accessChannelTextField, 'noBottomMargin')}
                    rules={[{ required: true, validator: requiredValidator('Access Channel') }]}
                  >
                    <TextField disabled placeholder='Access Channel' />
                  </Form.Item>

                  <Form.Item
                    name={[name, 'isLocked']}
                    className={classNames(
                      styles.productAccessMasterProductStatus,
                      'noBottomMargin'
                    )}
                    valuePropName='checked'
                  >
                    <Toggle disabled={view} text='Is Locked' />
                  </Form.Item>

                  <Form.Item
                    name={[name, 'status']}
                    className={classNames(
                      styles.productAccessMasterProductStatus,
                      'noBottomMargin'
                    )}
                    valuePropName='checked'
                  >
                    <Toggle disabled={view} />
                  </Form.Item>
                </div>
              ))}
            </>
          )}
        </Form.List>
      </div>
    </TableWrapper>
  )
}
