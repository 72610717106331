import styles from '../../styles.module.scss'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { AddIcon } from '../../../../../../assets/svg'
import FormList from 'antd/es/form/FormList'
import React from 'react'
import { useGetMasterProductsListQuery } from '../../../../core/http/VendorManagementApi'
import { MasterProductListItem } from '../../PromotionPolicyView/Components/MasterProductsTab/MasterProductListItem'
import { ITableResponse } from '../../../../../../shared/models/ITableResponse'
import { IMasterProduct } from '../../../../models/IMasterProduct'

export const MasterProducts = () => {
  const { data } = useGetMasterProductsListQuery({})

  return (
    <FormList name='promotionPolicyProducts'>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ name, key }) => (
            <MasterProductListItem
              key={key}
              name={name}
              data={data as ITableResponse<IMasterProduct>}
              remove={remove}
            />
          ))}
          <div className={styles.addButton}>
            <IconButton icon={<AddIcon />} color='orange' block onClick={() => add()} />
          </div>
        </>
      )}
    </FormList>
  )
}
