import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../../../shared/api'
import { ITableResponse } from '../../../shared/models/ITableResponse'
import { IExternalReport } from '../models/IExternalReport'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../shared/models/ITableConf'
import { IFilterFields } from '../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { TableExportFormatEnum } from '../../../shared/hooks/table/useTableExport'
import { IRequestToVendor } from '../models/IRequestToVendor'

export const externalTransactionApi = createApi({
  reducerPath: 'externalTransaction',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getExternalTransactions: builder.query<ITableResponse<IExternalReport>, ITableConf>({
      query: (params) => ({
        url: 'external-transaction-report',
        params,
      }),
      transformResponse: (res: ITableResponse<IExternalReport>) => ({
        ...res,
        items: res.items.map((report) => ({ ...report, children: [] })),
      }),
    }),

    getETFiltersList: builder.query<Array<IFilterFields>, ITableConf>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'external-transaction-report/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `external-transaction-report/filter-values?filterField=${filter.field}`,
            params: arg,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),

    exportETReport: builder.query<
      BlobPart,
      {
        conf: IFileFormatCsvOrXlsx
        formatFile: keyof typeof TableExportFormatEnum
      }
    >({
      query: ({ conf, formatFile }) => ({
        url: 'external-transaction-report/export',
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    exportETReportToVendor: builder.query<
      BlobPart,
      {
        conf: IFileFormatCsvOrXlsx
        formatFile: keyof typeof TableExportFormatEnum
      }
    >({
      query: ({ conf, formatFile }) => ({
        url: 'external-transaction-report/exportRequestToVendor',
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getVendorRequestList: builder.query<
      ITableResponse<IRequestToVendor>,
      { topupId?: number; reverseTopupId?: number }
    >({
      query: (params) => ({
        url: 'request-to-vendor',
        params,
      }),
    }),
  }),
})

export const {
  useGetExternalTransactionsQuery,
  useGetETFiltersListQuery,
  useLazyExportETReportQuery,
  useGetVendorRequestListQuery,
  useLazyExportETReportToVendorQuery,
} = externalTransactionApi
