export enum LANGUAGE_ENUM {
  ENGLISH = 'English',
  DARI = 'Dari',
  PASHTU = 'Pashtu',
}

export enum CHANNEL_ENUM {
  API = 'API',
  USSD = 'USSD',
  SMS = 'SMS',
}
