export enum VARIABLE_TYPE_ENUM {
  CONSTANT = 'CONSTANT',
  COMPUTED = 'COMPUTED',
}

export enum SYSTEM_VARIABLE_ENUM {
  AMOUNT = 'AMOUNT',
  AMOUNT_WITH_FORMAT = 'AMOUNT_WITH_FORMAT',
  AMOUNT_MULTIPLYING_BY_100 = 'AMOUNT_MULTIPLYING_BY_100',
  PRODUCT_CODE = 'PRODUCT_CODE',
  TRANSACTION_ID = 'TRANSACTION_ID',
  VENDOR_TRANSACTION_ID = 'VENDOR_TRANSACTION_ID',
  CUSTOMER_PHONE_NUMBER = 'CUSTOMER_PHONE_NUMBER',
  CUSTOMER_NATIONAL_PHONE_NUMBER = 'CUSTOMER_NATIONAL_PHONE_NUMBER',
  CUSTOMER_INTERNATIONAL_PHONE_NUMBER = 'CUSTOMER_INTERNATIONAL_PHONE_NUMBER',
  RESPONSE_CODE = 'RESPONSE_CODE',
  RESPONSE_DESCRIPTION = 'RESPONSE_DESCRIPTION',
  BALANCE = 'BALANCE',
  CUSTOMER_BALANCE_BEFORE = 'CUSTOMER_BALANCE_BEFORE',
  CUSTOMER_BALANCE_AFTER = 'CUSTOMER_BALANCE_AFTER',
  REQUEST_DATE_TIME = 'REQUEST_DATE_TIME',
  ENCRYPTION_KEY = 'ENCRYPTION_KEY',
  SIGN_KEY = 'SIGN_KEY',
  API_USER_NAME = 'API_USER_NAME',
  API_PASSWORD = 'API_PASSWORD',
  HOST = 'HOST',
  PORT = 'PORT',
}

export interface IVendorVariable {
  id: number
  key: string
  value: string
  type: VARIABLE_TYPE_ENUM
  encrypted: boolean
  systemVariable: SYSTEM_VARIABLE_ENUM
}
