import React, { memo, useMemo } from 'react'
import { IRepliedTo } from '../../../../../../models'

import styles from '../../styles.module.scss'
import { EXTERNAL_CHAT_FILE_TYPE_ENUM } from '../../../../../../../Support/models/IExternalChat'
import FilesInMessage from '../../../FilesInMessage'

interface IProps {
  replyToMessage: IRepliedTo
  scrollToMessage: (message: IRepliedTo) => void
}

const RepliedMessageTitle = ({ replyToMessage, scrollToMessage }: IProps) => {
  const messageWithFile = useMemo(() => {
    if (replyToMessage?.internalChatMessageFiles?.length) {
      const file = replyToMessage.internalChatMessageFiles[0]?.file
      const fileType = replyToMessage.internalChatMessageFiles[0]?.fileType

      if (fileType === EXTERNAL_CHAT_FILE_TYPE_ENUM.PHOTO) {
        return (
          <>
            <img width={252} height={180} src={file?.link} style={{ objectFit: 'cover' }} />
          </>
        )
      }

      if (fileType === EXTERNAL_CHAT_FILE_TYPE_ENUM.VIDEO) {
        return (
          <>
            <video width={240} height={160} src={file?.link} />
          </>
        )
      }

      if (file?.fileFormat === '.ogg') {
        return 'Voice Message'
      }

      if (fileType === EXTERNAL_CHAT_FILE_TYPE_ENUM.FILE) {
        const otherFiles = replyToMessage?.internalChatMessageFiles?.filter(
          (item) => !['photo', 'video'].includes(item.fileType.toLowerCase())
        )
        return !!otherFiles?.length && <FilesInMessage files={otherFiles} />
      }
    }
  }, [replyToMessage.internalChatMessageFiles])

  const normalizedMessage = useMemo(() => {
    const { message } = replyToMessage
    const regexTag = /\{"id": "([0-9]+)", "name": "([a-zA-Z0-9\s-]+)"}/gi
    const regexTransaction = /\{"id": "([0-9]+)", "transaction": "([a-zA-Z\s-]+)"}/gi
    if (regexTag.test(message) || regexTransaction.test(message)) {
      return message?.split('::').map((el) => {
        if (regexTag.test(el)) {
          const data = JSON.parse(el)
          return <span key={data?.id}>{data.name}</span>
        }
        if (regexTransaction.test(el)) {
          const data = JSON.parse(el)
          return <span key={data?.id}>{data?.id}</span>
        }
        return el
      })
    }
    return message
  }, [replyToMessage])

  return (
    <div className={styles.repliedTo} onClick={() => scrollToMessage(replyToMessage)}>
      <span className={styles.repliedToTitle}>Replied to:</span> {normalizedMessage}
      {messageWithFile && <div>{messageWithFile}</div>}
    </div>
  )
}

export default memo(RepliedMessageTitle)
