import React, { useCallback, useContext, useEffect, useState } from 'react'
import { RightModalContext } from '../../../../../../Modals'
import { Form, Spin, UploadFile } from 'antd'
import { DetailInfo } from './DetailInfo'
import { useGetBankAccountsQuery } from '../../../../../../Finance/core/http/BankAccountApi'
import styles from './styles.module.scss'
import { useCreatePickupRequestMutation } from '../../../../../../Finance/core/http/BankWalletTransactionsApi'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../shared/api/errorHandler'
import { IPickupRequestForm } from '../../../../../../Finance/models/IPickupRequest'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { debounce, isEmpty } from 'lodash'
import {
  useLazyGetAgentByIdQuery,
  useLazyGetRootAgentByIdQuery,
} from '../../../../../../salesNetwork/core/http/AgentManagement'
import { Wrapper } from '../../../../../../../shared/components/wrapper/Wrapper'
import { DoubleArrowRightIcon, MoneyIcon } from '../../../../../../../assets/svg'
import { RIGHT_MODALS } from '../../../../../../../helpers/contants'
import { UploaderFiles } from '../../../../../../../shared/components/uploaderPhotos/UploaderFiles'
import { PRUploadTypes } from '../../../../../../../shared/components/uploaderPhotos/constants'

export const CreatePickupRequestModal = () => {
  const {
    onOpen,
    onClose,
    props: { agentId },
  } = useContext(RightModalContext)

  const [form] = Form.useForm()
  const [files, setFiles] = useState<UploadFile[]>([])

  const { data: bankAccountList } = useGetBankAccountsQuery({})
  const [createPR, dCreate] = useCreatePickupRequestMutation()
  const [getAgent, dAgent] = useLazyGetAgentByIdQuery()
  const [getRootAgent, dRootAgent] = useLazyGetRootAgentByIdQuery()

  const handleNotExistingAgent = () => {
    form.setFieldValue(['agent', 'name'], null)
    form.setFieldValue('parentAgentId', null)
    form.setFieldValue('parentAgentName', null)
    form.setFieldValue('accountType', null)
  }

  // eslint-disable-next-line
  const debounceGetAgent = useCallback(
    debounce((value) => {
      Promise.all([getAgent(value), getRootAgent(value)])
    }, 300),
    []
  )

  const handlerAgentIdChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.value) {
      return handleNotExistingAgent()
    }
    debounceGetAgent(event.target.value)
  }

  const dispatchFiles = (arr: UploadFile[]) => setFiles(arr)

  useNotification(NOTIFICATION_TYPES.success, dCreate.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, dCreate.isError, dCreate.error as ErrorNode)

  const onFinish = (values: IPickupRequestForm) => {
    const { agent, currencyAmount, note, bankAccount, paymentType } = values

    const body = {
      agentId: +agent.id,
      currencyAmount: +currencyAmount,
      bankAccountId: Number(bankAccount?.id),
      paymentType,
      note,
    }

    const fd = new FormData()

    Object.entries(body)?.forEach(([key, val]) => {
      if (val) {
        fd.append(key, val as string)
      }
    })

    if (files.length) {
      files.forEach((value) => value.originFileObj && fd.append('files', value.originFileObj!))
    }

    createPR(fd)
  }

  useEffect(() => {
    if (isEmpty(dAgent.data)) return
    const agent = dAgent.data

    form.setFieldValue(
      ['agent', 'name'],
      agent.companyTitle ? `${agent.companyTitle}` : `${agent.firstName} ${agent.lastName}`
    )
  }, [form, dAgent.data, dAgent.requestId])

  useEffect(() => {
    if (isEmpty(dRootAgent.data)) return
    const rootAgent = dRootAgent.data

    form.setFieldValue('accountType', rootAgent?.accountType?.accountType)
    form.setFieldValue('parentAgentId', `${rootAgent?.id}`)
    form.setFieldValue(
      'parentAgentName',
      `${rootAgent?.id} ${
        rootAgent?.companyTitle ?? `${rootAgent?.firstName} ${rootAgent?.lastName}`
      }`
    )
  }, [dRootAgent.data, dRootAgent.requestId, form])

  useEffect(() => {
    if (agentId) {
      form.setFieldValue(['agent', 'id'], agentId)
      debounceGetAgent(agentId)
    }
  }, [agentId, debounceGetAgent, form])

  useEffect(() => {
    if (dCreate.data?.id) {
      onOpen(RIGHT_MODALS.FINANCE.PICKUP_REQUEST, {
        title: 'Pickup Request',
        id: dCreate.data?.id,
      })
    }
  }, [dCreate.data?.id, onOpen])

  const content = (
    <Form layout='vertical' className={styles.layout} onFinish={onFinish} form={form}>
      <div className={styles.btnClose} onClick={onClose}>
        <DoubleArrowRightIcon />
      </div>
      <Wrapper title='Detail Info' className={styles.detailInfoBlock}>
        <DetailInfo bankAccountList={bankAccountList?.items} onChangeAgent={handlerAgentIdChange} />
      </Wrapper>
      <div className={styles.detailInfoBlock}>
        <UploaderFiles
          allowTypes={PRUploadTypes}
          attachmentPropName='receipt'
          btnTitle='Attach File'
          dispatchData={dispatchFiles}
          isStyledTitle
        />
        <div className={styles.addReqButton}>
          <IconButton
            block
            color='orange'
            type='primary'
            onClick={form.submit}
            icon={<MoneyIcon />}
          >
            Create Pickup Request
          </IconButton>
        </div>
      </div>
    </Form>
  )

  return dCreate.isLoading || dAgent.isLoading || dRootAgent.isLoading ? (
    <Spin>{content}</Spin>
  ) : (
    content
  )
}
