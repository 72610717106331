import { useContext } from 'react'
import { Drawer } from 'antd'

// modals
import { CustomerModal } from '../salesNetwork/pages/Customers/Components/CustomerModal'
import { IdentifierVerificationModal } from '../salesNetwork/components/IdentifierVerificationModal'
import { ChangeMPinModal } from '../Support/pages/Chatroom/Components/ChangeMPinModal'
import { InternalChatModal } from '../InternalChat/Components/InternalChatModal'
import { DashboardCustomizationModal } from '../Dashboard/pages/Overview/Components/DashboardCustomizationModal'
import { ReconciliationNewModal } from '../Finance/pages/Reconciliation/Components/ReconciliationNewModal'

// Settings modals
import { VendorManagementProductModal } from '../Settings/pages/VendorManagement/VendorManagementProductModal'
import { VendorManagementProductsList } from '../Settings/pages/VendorManagement/VendorManagementNew/Components/VendorManagementProductsList'
import { DepartmentManagementUsersListModal } from '../Settings/pages/UserManagement/Components/Tabs/Departments/DepartmentsManagementNew/Components/DepartmentsManagementUsersListModal'
import { RolesAndPermissionsManagementUsersListModal } from '../Settings/pages/UserManagement/Components/Tabs/RolesAndPermissions/RolesManagementNew/Components/RolesAndPermissionsManagementUsersListModal'
import { RoleManagementAddUsersToRoleListModal } from '../Settings/pages/UserManagement/Components/Tabs/RolesAndPermissions/RolesManagementView/Components/RoleManagementAddUsersToRoleListModal'
import { VendorManagementContactListModal } from '../Settings/pages/VendorManagement/VendorManagementContactListModal'
import { PositionUserListModal } from '../Settings/pages/UserManagement/Components/Tabs/Positions/PositionsDetailView/UsersListModal'
import { TargetGroupViewModal } from '../Settings/pages/SalesNetworkConfiguration/Components/TargetGroupViewModal'
import { TargetGroupListModal } from '../Settings/pages/SalesNetworkConfiguration/Components/TargetGroupListModal'
import { PromotionModal } from '../Settings/pages/SalesNetworkConfiguration/PromotionModal'
import UserViewModal from '../Settings/pages/UserManagement/Components/UserViewModal'

// Transactions modals
import { TrunsferFundModal } from '../Reports/pages/Transactions/Components/Modals/TrunsferFundModal'
import { PurchaseOrderModal } from '../Reports/pages/Transactions/Components/Modals/PurchaseOrderModal'
import { PickupRequestModal } from '../Reports/pages/Transactions/Components/Modals/PickupRequestModal'
import { EnChangeRatesModal } from '../Reports/pages/Transactions/Components/Modals/ExchangeRatesModal'
import { RefundRequestModal } from '../Reports/pages/Transactions/Components/Modals/RefundRequestModal'
import { AdjustmentModal } from '../Reports/pages/Transactions/Components/Modals/AdjustmentModal'
import { ReverseTransactionDetailModal } from '../Reports/pages/Transactions/Components/Modals/ReverseTransactionDetailModal'
import { SubTransactionDetailModal } from '../Reports/pages/Transactions/Components/Modals/SubTransactionDetailModal'
import { ReverseTransactionRequestModal } from '../Reports/pages/Transactions/Components/Modals/ReverseTransactionRequestModal'
import { TransferFundDetailModal } from '../Reports/pages/Transactions/Components/Modals/TransferFundDetailModal'
import { PurchasePlanModal } from '../Finance/pages/TrustWallet/InventoryView/Components/PurchasePlanModal'
import { TopupTransactionDetailModal } from '../Reports/pages/Transactions/Components/Modals/TopupTransactionDetailModal'
import { PurchaseOrderChangesModal } from '../Reports/pages/Transactions/Components/Modals/PurchaseOrderChangesModal'

// helpers
import { RIGHT_MODALS } from 'src/helpers/contants'
import { RightModalContext } from './index'

// icons
import { CrossIcon } from 'src/assets/svg'

// styles
import styles from './styles.module.scss'
import VendorManagementCreateNewVariable from '../Settings/pages/VendorManagement/VendorManagementCreateNewVariable'
import { AgentTransactionsModal } from '../Reports/pages/Transactions/Components/Modals/AgentTransactionsModal'
import { CreatePickupRequestModal } from '../Reports/pages/Transactions/Components/Modals/CreatePickupRequestModal'
import { SendEmoneyModal } from '../Reports/pages/Transactions/Components/Modals/SendEmoneyModal'
import { RefundRequestChangesModal } from '../Reports/pages/Transactions/Components/Modals/RefundRequestChangesModal'
import { PickupRequestChangesModal } from '../Reports/pages/Transactions/Components/Modals/PickupRequestChangesModal'
import { SegmentTargetGroupModal } from '../salesNetwork/pages/Customers/Components/TargetGroupModal'
import { SegmentsTargetGroupListModal } from '../salesNetwork/pages/Customers/Components/TargetGroupListModal'
import { CustomerClosedGroupModal } from '../salesNetwork/pages/Customers/Components/CustomerClosedGroupModal'
import { WhatsAppManagerButtonsModal } from '../Settings/pages/VendorManagement/WhatsAppButtonsModal'
import { WhatsAppNotificationButtonDetailModal } from '../Settings/pages/VendorManagement/WhatsAppNotificationButtonDetailModal'
import VendorManagementGatewayModal from '../Settings/pages/VendorManagement/VendorManagementGatewayModal'

export const RightModals = () => {
  const { selectedModal, props, onClose } = useContext(RightModalContext)

  const isVisible = selectedModal !== null

  return (
    <Drawer
      closeIcon={<CrossIcon />}
      className={styles.modalRight}
      width={598} // default width of all right modals
      title={props.title}
      placement='right'
      onClose={props?.onCloseRedirect || onClose}
      open={isVisible}
      zIndex={1050}
    >
      {selectedModal === RIGHT_MODALS.FINANCE.PURCHASE_ORDER && <PurchaseOrderModal />}
      {selectedModal === RIGHT_MODALS.FINANCE.PICKUP_REQUEST && <PickupRequestModal />}
      {selectedModal === RIGHT_MODALS.FINANCE.ENCHANGE_RATES && <EnChangeRatesModal />}
      {selectedModal === RIGHT_MODALS.FINANCE.TRANSFER_FUND && <TrunsferFundModal />}
      {selectedModal === RIGHT_MODALS.FINANCE.REFUND_REQUEST && <RefundRequestModal />}
      {selectedModal === RIGHT_MODALS.FINANCE.ADJUSTMENT && <AdjustmentModal />}
      {selectedModal === RIGHT_MODALS.FINANCE.REVERSE_TRANSACTION && (
        <ReverseTransactionDetailModal />
      )}
      {selectedModal === RIGHT_MODALS.FINANCE.TOPUP && <TopupTransactionDetailModal />}
      {selectedModal === RIGHT_MODALS.FINANCE.TOPUP_OR_REVERSE_SUB_TRANSACTION && (
        <SubTransactionDetailModal />
      )}
      {selectedModal === RIGHT_MODALS.FINANCE.REVERSE_TRANSACTION_REQUEST && (
        <ReverseTransactionRequestModal />
      )}
      {selectedModal === RIGHT_MODALS.FINANCE.TRANSFER_FUND_DETAIL && <TransferFundDetailModal />}
      {selectedModal === RIGHT_MODALS.FINANCE.PURCHASE_PLAN && <PurchasePlanModal />}
      {selectedModal === RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_PRODUCT && (
        <VendorManagementProductModal />
      )}
      {selectedModal === RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_CONTACT_LIST && (
        <VendorManagementContactListModal />
      )}
      {selectedModal === RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_VARIABLES && (
        <VendorManagementCreateNewVariable />
      )}
      {selectedModal === RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_GATEWAY && (
        <VendorManagementGatewayModal />
      )}
      {selectedModal === RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_PRODUCT_LIST && (
        <VendorManagementProductsList />
      )}
      {selectedModal === RIGHT_MODALS.SETTINGS.DEPARTMENT_MANAGEMENT_USERS_LIST && (
        <DepartmentManagementUsersListModal />
      )}
      {selectedModal === RIGHT_MODALS.SETTINGS.ROLES_AND_PERMISSIONS_MANAGEMENT_USERS_LIST && (
        <RolesAndPermissionsManagementUsersListModal />
      )}
      {selectedModal === RIGHT_MODALS.SETTINGS.POSITIONS_USERS_LIST && <PositionUserListModal />}
      {selectedModal ===
        RIGHT_MODALS.SETTINGS.ROLES_AND_PERMISSIONS_MANAGEMENT_USERS_TO_ROLE_LIST && (
        <RoleManagementAddUsersToRoleListModal />
      )}
      {selectedModal === RIGHT_MODALS.SETTINGS.USERS_MANAGEMENT_USER && <UserViewModal />}
      {selectedModal === RIGHT_MODALS.SUPPORT.CHANGE_MPIN && <ChangeMPinModal />}
      {selectedModal === RIGHT_MODALS.SETTINGS.NETWORK_CONFIGURATION_TARGET_GROUP && (
        <TargetGroupViewModal />
      )}
      {selectedModal === RIGHT_MODALS.SETTINGS.NETWORK_CONFIGURATION_TARGET_GROUP_LIST && (
        <TargetGroupListModal />
      )}
      {selectedModal === RIGHT_MODALS.SETTINGS.INTERNAL_CHAT && <InternalChatModal />}
      {selectedModal === RIGHT_MODALS.SETTINGS.PROMOTION && <PromotionModal />}
      {selectedModal === RIGHT_MODALS.SETTINGS.WHATSAPP_MANAGER_BUTTONS && (
        <WhatsAppManagerButtonsModal />
      )}
      {selectedModal === RIGHT_MODALS.SETTINGS.WHATSAPP_NOTIFICATION_BUTTON_DETAIL && (
        <WhatsAppNotificationButtonDetailModal />
      )}
      {selectedModal === RIGHT_MODALS.SALES.CUSTOMER && <CustomerModal />}
      {selectedModal === RIGHT_MODALS.SALES.IDENTIFIER_VERIFICATION && (
        <IdentifierVerificationModal />
      )}
      {selectedModal === RIGHT_MODALS.DASHBOARD.DASHBOARD_OVERVIEW_CUSTOMIZATION && (
        <DashboardCustomizationModal />
      )}
      {selectedModal === RIGHT_MODALS.FINANCE.PURCHASE_ORDER_CHANGES && (
        <PurchaseOrderChangesModal />
      )}
      {selectedModal === RIGHT_MODALS.FINANCE.RECONCILIATION && <ReconciliationNewModal />}
      {selectedModal === RIGHT_MODALS.SALES.AGENT_TRANSACTIONS && <AgentTransactionsModal />}
      {selectedModal === RIGHT_MODALS.FINANCE.CREATE_PICKUP_REQUEST && <CreatePickupRequestModal />}
      {selectedModal === RIGHT_MODALS.FINANCE.SEND_EMONEY && <SendEmoneyModal />}
      {selectedModal === RIGHT_MODALS.FINANCE.REFUND_REQUEST_CHANGES && (
        <RefundRequestChangesModal />
      )}
      {selectedModal === RIGHT_MODALS.FINANCE.PICKUP_REQUEST_CHANGES && (
        <PickupRequestChangesModal />
      )}
      {selectedModal === RIGHT_MODALS.SALES.SEGMENT_TARGET_GROUP && <SegmentTargetGroupModal />}
      {selectedModal === RIGHT_MODALS.SALES.SEGMENT_TARGET_GROUP_LIST && (
        <SegmentsTargetGroupListModal />
      )}
      {selectedModal === RIGHT_MODALS.SALES.CUSTOMER_CLOSED_GROUP && <CustomerClosedGroupModal />}
    </Drawer>
  )
}
