import { IPopupListItems } from '../../../../../../../../../../shared/components/popup/Popup'
import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import { useOnClickOutside } from '../../../../../../../../../../shared/hooks/useClickOutside'
import styles from '../../styles.module.scss'
import moment from 'moment/moment'
import { FileIcon, TableActionIcon } from '../../../../../../../../../../assets/svg'
import InternalChatPopup from '../../../../../../../../../InternalChat/Components/Attachments/Conponents/InternalChatPopup'
import { IExternalChatMessageFile } from '../../../../../../../../models/IExternalChat'

interface IProps {
  tableActionsPopup: (
    id: number,
    file: IExternalChatMessageFile['file'],
    callback?: Dispatch<SetStateAction<boolean | void>>
  ) => IPopupListItems[]
  file: IExternalChatMessageFile['file']
  isFirstNotificationInAMonth: boolean
  createdAt: string
  fileId: number
}

export const AttachmentFile = ({
  tableActionsPopup,
  file,
  isFirstNotificationInAMonth,
  createdAt,
  fileId,
}: IProps) => {
  const actionsRef = useRef(null)
  const [isShownActions, setIsShownActions] = useState(false)

  useOnClickOutside(actionsRef, () => {
    setIsShownActions(false)
  })

  const content = tableActionsPopup(
    file.id,
    file,
    setIsShownActions as Dispatch<SetStateAction<boolean | void>>
  )

  return (
    <div>
      {isFirstNotificationInAMonth && (
        <div className={styles.dateDivider}>{moment(createdAt).format('MMMM')}</div>
      )}
      <div key={file.id} className={styles.fileContainer}>
        <div className={styles.fileInfo}>
          <FileIcon />
          <div className={styles.fileName} title={file.name}>
            {file.name}
          </div>
        </div>
        <div className='table-kebab-actions' onClick={() => setIsShownActions(true)}>
          <TableActionIcon />
        </div>
        {isShownActions && (
          <div className={styles.isShawnActions} ref={actionsRef}>
            <InternalChatPopup data={content} />
          </div>
        )}
      </div>
    </div>
  )
}
