import styles from '../../styles.module.scss'
import { Row, Space } from 'antd'
import {
  LocationIcon,
  MonetizationRoundedIcon,
  UserGroupCircleIcon,
} from '../../../../../../assets/svg'
import { formatNumberToLocale } from '../../../../../salesNetwork/helpers'
import React from 'react'
import { ITopLocation } from '../../../../models/ITopLocation'
import classNames from 'classnames'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'

interface IProps {
  tooltipPosition: {
    x: number
    y: number
  }
  tooltipContent: Partial<ITopLocation>
}

export const Tooltip = ({ tooltipPosition, tooltipContent }: IProps) => {
  return (
    <RecursivelyReplaceCharacters>
      <div
        style={{
          left: tooltipPosition.x,
          top: tooltipPosition.y,
        }}
        className={classNames(styles.tooltip, 'dashboard-tooltip')}
      >
        <Space size={7} align='center'>
          <Row className={styles.tooltipIcon}>
            <LocationIcon />
          </Row>
          <Space size={3}>
            <div>
              {tooltipContent?.province?.name}
              {tooltipContent?.district && ','}
            </div>
            {tooltipContent?.district && <div>{tooltipContent?.district?.name}</div>}
          </Space>
        </Space>

        <Space size={6} align='center'>
          <Row className={styles.tooltipIcon}>
            <MonetizationRoundedIcon />
          </Row>
          <span>{formatNumberToLocale(tooltipContent?.salesAmount as number)}</span>
        </Space>

        <Space size={6} align='center'>
          <Row className={styles.tooltipIcon}>
            <UserGroupCircleIcon />
          </Row>
          <span>{formatNumberToLocale(tooltipContent?.activeAgentsCount as number)}</span>
        </Space>
      </div>
    </RecursivelyReplaceCharacters>
  )
}
