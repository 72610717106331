import { ChangeEvent, ReactNode, KeyboardEventHandler } from 'react'
import classNames from 'classnames'
import { Input } from 'antd'
import styles from './TextField.module.scss'

interface IProps {
  disabled?: boolean
  type?: string
  placeholder?: string
  className?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  id?: string
  value?: string | number
  view?: boolean
  defaultValue?: string
  prefix?: string | ReactNode
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  min?: number
  max?: number
  onFocus?: () => void
}

export const TextField = ({
  view = false,
  onChange,
  disabled = false,
  type = 'text',
  placeholder,
  className = '',
  id,
  value,
  defaultValue,
  prefix,
  onKeyDown,
  min,
  max,
  ...rest
}: IProps): JSX.Element => {
  return (
    <Input
      autoComplete='off'
      onKeyDown={onKeyDown}
      className={classNames(styles.input, className, {
        [styles['input-disabled']]: disabled,
        [styles['input-view']]: view,
      })}
      placeholder={placeholder}
      disabled={disabled || view}
      type={type}
      onChange={onChange}
      id={id}
      value={value}
      defaultValue={defaultValue}
      {...(prefix && { prefix })}
      min={min}
      max={max}
      {...rest}
    />
  )
}
