import React, { memo } from 'react'
import { Tooltip } from 'antd'
import emptyAvatar from '../../../../../assets/img/Avatar.png'
import styles from './styles.module.scss'

interface IProps {
  logo?: string
}

const ChatInfoAvatar = ({ logo }: IProps) => {
  return (
    <div>
      <Tooltip title='' placement='topRight' className={styles.chatAvatar}>
        <img src={logo ?? emptyAvatar} alt='' />
      </Tooltip>
    </div>
  )
}

export default memo(ChatInfoAvatar)
