import React, { memo } from 'react'
import { IInternalChatLink } from '../../../../models'
import productPlaceholder from 'src/assets/img/ProductPlaceholderPicture.png'
import styles from '../../styles.module.scss'

interface IProps {
  link: IInternalChatLink
}

const Link = ({ link }: IProps) => {
  const hasIco = /https:/.test(link?.metadata?.favicon as string)
  const parsedLink = new URL(link?.link)
  return (
    <div className={styles.linkContainer}>
      <div className={styles.linkImage}>
        <img
          src={
            link?.metadata?.ogImage?.url || (!hasIco ? productPlaceholder : link?.metadata?.favicon)
          }
          alt='link'
        />
      </div>
      <div className={styles.linkDataContainer}>
        <div className={styles.linkTitle}>
          <span className={styles.siteTitle}>{link?.metadata?.ogTitle || parsedLink?.host}</span>
          <span>{link?.metadata?.ogDescription}</span>
        </div>
        <div className={styles.linkData}>
          <a href={link.link} target='_blank' rel='noreferrer'>
            {link.link}
          </a>
        </div>
      </div>
    </div>
  )
}

export default memo(Link)
