import { TRANSACTION_CATEGORY_ENUM } from '../../../features/Finance/models/ITransaction'
import { useContext } from 'react'
import { RightModalContext } from '../../../features/Modals'
import { RIGHT_MODALS } from '../../../helpers/contants'

interface IConstraint {
  sender: string
  receiver: string
}

type SupTransactionType<T extends IConstraint> = T

export const useOnTransactionViewClick = (onCloseRedirect?: () => void) => {
  const { onOpen } = useContext(RightModalContext)

  return (
    id: number,
    category: TRANSACTION_CATEGORY_ENUM,
    subTransaction: SupTransactionType<IConstraint> | null,
    isReport?: boolean
  ) => {
    if (subTransaction && Object.hasOwn(subTransaction, 'mainTransactionId')) {
      return onOpen(RIGHT_MODALS.FINANCE.TOPUP_OR_REVERSE_SUB_TRANSACTION, {
        title: `${category} transaction`,
        onCloseRedirect,
        subTransaction,
        id,
      })
    }
    if (
      category === TRANSACTION_CATEGORY_ENUM.PICKUP ||
      category === TRANSACTION_CATEGORY_ENUM.PICKUP_REQUEST
    ) {
      return onOpen(RIGHT_MODALS.FINANCE.PICKUP_REQUEST, {
        title: 'Pickup Transaction',
        onCloseRedirect,
        id,
      })
    }
    if (category === TRANSACTION_CATEGORY_ENUM.PURCHASE_ORDER) {
      return onOpen(RIGHT_MODALS.FINANCE.PURCHASE_ORDER, {
        title: 'Purchase Order',
        onCloseRedirect,
        isReport,
        id,
      })
    }
    if (category === TRANSACTION_CATEGORY_ENUM.REFUND) {
      return onOpen(RIGHT_MODALS.FINANCE.REFUND_REQUEST, {
        title: 'Refund Request Transaction',
        onCloseRedirect,
        id,
      })
    }
    if (category === TRANSACTION_CATEGORY_ENUM.TRANSFER_FUND) {
      return onOpen(RIGHT_MODALS.FINANCE.TRANSFER_FUND_DETAIL, {
        title: 'Transfer Fund Transaction',
        onCloseRedirect,
        isReport,
        id,
      })
    }
    if (
      category === TRANSACTION_CATEGORY_ENUM.REVERSE ||
      category === TRANSACTION_CATEGORY_ENUM.REVERSE_TOPUP
    ) {
      return onOpen(RIGHT_MODALS.FINANCE.REVERSE_TRANSACTION, {
        title: 'Reverse Transaction',
        onCloseRedirect,
        isReport,
        id,
      })
    }
    if (category === TRANSACTION_CATEGORY_ENUM.TOPUP) {
      return onOpen(RIGHT_MODALS.FINANCE.TOPUP, {
        title: 'Topup Transaction',
        onCloseRedirect,
        id,
      })
    }
    if (category === TRANSACTION_CATEGORY_ENUM.ADJUSTMENT) {
      return onOpen(RIGHT_MODALS.FINANCE.ADJUSTMENT, {
        title: 'Adjustment',
        mode: 'view',
        onCloseRedirect,
        id,
      })
    }
    if (category === TRANSACTION_CATEGORY_ENUM.PROMOTION) {
      return onOpen(RIGHT_MODALS.SETTINGS.PROMOTION, {
        title: 'Promotion',
        mode: 'view',
        onCloseRedirect,
        id,
      })
    }
    if (category === TRANSACTION_CATEGORY_ENUM.SEND_EMONEY) {
      return onOpen(RIGHT_MODALS.FINANCE.SEND_EMONEY, {
        title: 'Send Emoney',
        mode: 'view',
        onCloseRedirect,
        id,
      })
    }
  }
}
