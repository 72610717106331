import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

// styles
import './assets/styles/index.scss'
import { childrenRoutes } from './routes/routes'
import { store } from './redux'
import {
  CenterModalContextProvider,
  CenterModals,
  RightModalContextProvider,
  RightModals,
} from './features/Modals'
import { BrowserRouter, Routes } from 'react-router-dom'

export const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <CenterModalContextProvider>
        <RightModalContextProvider>
          <Routes>{childrenRoutes}</Routes>
          <RightModals />
          <CenterModals />
        </RightModalContextProvider>
      </CenterModalContextProvider>
    </Provider>
  </BrowserRouter>
)
