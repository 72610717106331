import { Col, Form, Row, Space, Spin, UploadFile } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import * as C from './components'

import { Button } from 'src/shared/components/button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { UploaderPhotos2 } from 'src/shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import { RcFile } from 'antd/lib/upload'
import { customAlphabet } from 'nanoid'
import { IContactInfo } from 'src/features/salesNetwork/models/IAgent'
import { isEmpty, omit } from 'lodash'
import { ErrorNode } from 'src/shared/api/errorHandler'

import {
  useGetVendorContactByIdQuery,
  useUpdateVendorContactByIdMutation,
  useUpdateVendorContactContactInfoByIdMutation,
} from 'src/features/Settings/core/http/VendorManagementApi'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import {
  IVendorContactContactInfoPutRequest,
  IVendorContactResponseView,
} from 'src/features/Settings/models/IVendorManagement'
import { setBreadcrumb } from '../../../../app/core/BreadcrumbsSlice'
import { useAppDispatch } from '../../../../../redux'
import { ContactInformation } from 'src/features/salesNetwork/components'
import { CONTACTS_OPTIONS } from '../VendorManagementCreateNewContact'
import { formatDateWithTime } from 'src/helpers/utils'
import { SETTINGS_ENUM } from '../../../../../routes/settings'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'

const nanoid = customAlphabet('1234567890', 18)

export interface IVendorContactContactInfoPut extends IContactInfo {
  id?: number
  status?: string
}

export const VendorManagementViewContact = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [form] = Form.useForm()

  const { contactId, mode } = useParams()
  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const [agentStatus, setAgentStatus] = useState(true)
  const [keyUploader, setKeyUploader] = useState(nanoid())
  const [deleteContacts, setDeleteContacts] = useState<number[]>([])
  const [isDeleteLogo, setIsDeleteLogo] = useState(false)
  const [keyMain, setKeyMain] = useState(nanoid())

  const [updateVendorContact, dUpdContact] = useUpdateVendorContactByIdMutation()
  const [updateVCContactInfo, dUpdVCContactInfo] = useUpdateVendorContactContactInfoByIdMutation()
  const { data, isFetching } = useGetVendorContactByIdQuery(contactId, {
    skip: !contactId,
  })
  const { data: profile } = useGetProfileQuery()

  const [avatarFile, serAvatarFile] = useState<RcFile | string | undefined>(data?.avatar)

  const afterContactsUpdate = () => {
    setDeleteContacts([])
  }

  const afterCreate = () => {
    afterContactsUpdate()
    navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/contact/view/${contactId}`)
  }

  useNotification(
    NOTIFICATION_TYPES.success,
    dUpdContact.isSuccess && dUpdVCContactInfo.isSuccess,
    null,
    afterCreate
  )
  useNotification(NOTIFICATION_TYPES.success, dUpdContact.isSuccess && dUpdVCContactInfo.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    dUpdVCContactInfo.isError,
    dUpdVCContactInfo.error as ErrorNode,
    afterContactsUpdate
  )
  useNotification(
    NOTIFICATION_TYPES.error,
    dUpdContact.isError,
    dUpdContact.error as ErrorNode,
    afterContactsUpdate
  )

  const handleSetInitialForm = useCallback(
    (d: IVendorContactResponseView) => {
      const mobileNumbers = [...d.mobileNumber]?.map((mobile) => ({
        ...mobile,
        value: mobile?.value?.includes('+') ? mobile?.value : `+${mobile?.value}`,
      }))
      const contactInfo = [...d.email, ...d.whatsapp, ...d.skype, ...mobileNumbers]
      setAgentStatus(d?.status)
      serAvatarFile(d?.avatar)

      form.setFieldsValue({
        ...d,
        createdAt: formatDateWithTime(d.createdAt, profile?.calendar),
        contactInfo,
      })
    },
    [form, profile]
  )

  useEffect(() => {
    if (isEmpty(data)) return

    handleSetInitialForm(data as IVendorContactResponseView)

    dispatch(setBreadcrumb(data))
  }, [data, dispatch, handleSetInitialForm])

  const initialValues = {
    name: null,
    department: null,
    position: null,
    contactType: null,
    contactInfo: [],
  }

  const onContactDelete = (id: number) => {
    if (!id) return
    setDeleteContacts((prev) => [...prev, id])
  }

  const onCancel = () => {
    handleSetInitialForm(data as IVendorContactResponseView)
    setKeyUploader(nanoid())
    setKeyMain(nanoid())

    if (isEditMode) {
      navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/contact/view/${contactId}`)
      return
    }
    if (data?.vendor?.id && isViewMode) {
      navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${data?.vendor?.id}`)
      return
    }
    navigate(SETTINGS_ENUM.VENDOR_MANAGEMENT)
  }

  const onFinish = (values: typeof initialValues) => {
    const createNewContacts = (values.contactInfo as IVendorContactContactInfoPut[])
      .filter((contact) => !contact?.id)
      .filter((contact) => contact.value !== null)
      .filter((contact) => contact.type !== null)

    const updateContacts = (values.contactInfo as IVendorContactContactInfoPut[])
      .filter((contact) => contact.id)
      .map((contact) => {
        return {
          id: contact.id!,
          type: contact.type,
          value: contact.value,
        }
      })

    const vendorContactContactInfo: IVendorContactContactInfoPutRequest = {}
    if (createNewContacts.length !== 0) {
      vendorContactContactInfo['toCreate'] = createNewContacts
    }
    if (updateContacts.length !== 0) {
      vendorContactContactInfo['toUpdate'] = updateContacts
    }
    if (deleteContacts.length !== 0) {
      vendorContactContactInfo['toDelete'] = deleteContacts
    }

    const requestObj = {
      ...omit(values, 'avatarF', 'contactInfo'),
      status: agentStatus,
    }
    const fData = new FormData()

    if (isDeleteLogo) {
      fData.append('isDeleteLogo', JSON.stringify(isDeleteLogo))
    }
    fData.append('contact', JSON.stringify(requestObj))
    fData.append('avatar', avatarFile!)

    updateVendorContact({
      id: contactId,
      body: fData,
    })

    updateVCContactInfo({ id: contactId, body: vendorContactContactInfo })
  }

  const content = (
    <main className={styles.main}>
      <div key={keyMain} className={styles['agent-management-new-agent']}>
        <Form form={form} layout='vertical' initialValues={initialValues} onFinish={onFinish}>
          <Row wrap={false} justify='start'>
            <Col className={styles['wrapper-column']}>
              <C.DetailInfo
                setAgentStatus={setAgentStatus}
                agentStatus={agentStatus}
                create
                form={form}
                view={isViewMode}
              />

              <ContactInformation
                form={form}
                view={isViewMode}
                options={CONTACTS_OPTIONS}
                onContactDelete={onContactDelete}
              />
            </Col>
            <div>
              <UploaderPhotos2
                key={keyUploader}
                form={form}
                title='Profile Picture'
                isPersonPreview
                defaultPreviewImage={data?.avatar}
                setToDeleteLogo={setIsDeleteLogo}
                view={isViewMode}
                onChange={(photo: UploadFile[]) => {
                  serAvatarFile(photo[0].originFileObj)
                  setIsDeleteLogo(false)
                }}
              />
            </div>
          </Row>
        </Form>
      </div>
      <div className={styles.wrapper}>
        <Row className={styles.footer} justify='end'>
          <Space size={10}>
            <Button color='blue' onClick={onCancel} size='middle'>
              Cancel
            </Button>

            <Button
              color='blue'
              htmlType='submit'
              onClick={() => {
                if (!isViewMode) {
                  form.submit()
                }
                navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/contact/edit/${contactId}`)
              }}
              size='middle'
              type='primary'
            >
              {isViewMode ? 'Edit Contact' : 'Save Changes'}
            </Button>
          </Space>
        </Row>
      </div>
    </main>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
