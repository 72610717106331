import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import styles from '../style.module.scss'
import FormItem from 'antd/es/form/FormItem'
import {
  maxNumber,
  minNumber,
  positiveNumber,
  requiredValidator,
} from '../../../../../../helpers/validation'
import { TextField } from '../../../../../../shared/components/textfield/TextField'
import { Select } from 'antd'
import React from 'react'
import { PRODUCT_BUNDLE_REVERSE_TYPE_ENUM, PRODUCT_TYPE_ENUM } from '../../../../models/IProduct'
import { isNumber, startCase } from 'lodash'
import { IViewMode } from '../../../../../Finance/models/IViewMode'

const MAX_REVERSE_TIME_IN_MINUTES = 153722867280912928

export const ReverseBlock = ({ view }: IViewMode) => {
  return (
    <FormItem noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const isEPinOrBundleTopupType = [
          PRODUCT_TYPE_ENUM.E_PIN,
          PRODUCT_TYPE_ENUM.BUNDLE_TOPUP,
        ].includes(getFieldValue('type') as PRODUCT_TYPE_ENUM)

        return (
          <Wrapper title='Reverse'>
            <div className={styles.dblRow}>
              <FormItem
                name='refundPenaltyRate'
                label='Reverse Penalty Rate'
                rules={[
                  ...(!isEPinOrBundleTopupType
                    ? [
                        {
                          required: true,
                          validator: requiredValidator('Reverse Penalty Rate'),
                        },
                      ]
                    : []),
                  {
                    validator: positiveNumber('Reverse Penalty Rate'),
                  },
                  {
                    validator: maxNumber(100, 'Reverse Penalty Rate'),
                  },
                ]}
                className='noBottomMargin'
              >
                <TextField
                  type='number'
                  disabled={view || isEPinOrBundleTopupType}
                  placeholder='%'
                />
              </FormItem>

              <FormItem
                name='reverseType'
                label='Reverse Type'
                rules={[
                  ...(!isEPinOrBundleTopupType
                    ? [
                        {
                          required: true,
                          validator: requiredValidator('Reverse Type'),
                        },
                      ]
                    : []),
                ]}
              >
                <Select
                  disabled={view || isEPinOrBundleTopupType}
                  placeholder='Select Reverse Type'
                  allowClear
                >
                  <Select.Option value={PRODUCT_BUNDLE_REVERSE_TYPE_ENUM.MANUAL}>
                    {startCase(PRODUCT_BUNDLE_REVERSE_TYPE_ENUM.MANUAL.toLowerCase())}
                  </Select.Option>
                  <Select.Option value={PRODUCT_BUNDLE_REVERSE_TYPE_ENUM.API}>
                    {PRODUCT_BUNDLE_REVERSE_TYPE_ENUM.API}
                  </Select.Option>
                  <Select.Option value={PRODUCT_BUNDLE_REVERSE_TYPE_ENUM.NA}>
                    {PRODUCT_BUNDLE_REVERSE_TYPE_ENUM.NA}
                  </Select.Option>
                </Select>
              </FormItem>
            </div>

            <div>
              <FormItem
                name='reverseTimeEligibility'
                label='Reverse Time Eligibility (in minutes)'
                rules={[
                  ...(!isEPinOrBundleTopupType
                    ? [
                        {
                          validator: maxNumber(
                            MAX_REVERSE_TIME_IN_MINUTES,
                            'Reverse Time Eligibility'
                          ),
                        },
                        {
                          validator: positiveNumber('Reverse Time Eligibility'),
                        },
                        {
                          required: true,
                          validator: requiredValidator('Reverse Time Eligibility'),
                        },
                      ]
                    : []),
                ]}
              >
                <TextField
                  disabled={view || isEPinOrBundleTopupType}
                  type='number'
                  placeholder='Enter Reverse Time Eligibility in minutes'
                />
              </FormItem>
            </div>

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue, validateFields }) => {
                return (
                  <div className={styles.dblRow}>
                    <FormItem
                      name='reverseDenominationMin'
                      label='Reverse Denomination Min'
                      rules={[
                        ...(!isEPinOrBundleTopupType
                          ? [
                              {
                                required: true,
                                validator: requiredValidator('Reverse Denomination Min'),
                              },
                            ]
                          : []),
                        {
                          validator: positiveNumber('Reverse Denomination Min'),
                        },
                        ...(isNumber(+getFieldValue('reverseDenominationMax'))
                          ? [
                              {
                                validator: maxNumber(
                                  +getFieldValue('reverseDenominationMax'),
                                  'Reverse Denomination Min'
                                ),
                              },
                            ]
                          : [{}]),
                      ]}
                    >
                      <TextField
                        type='number'
                        disabled={view || isEPinOrBundleTopupType}
                        placeholder='Min'
                        onChange={() => {
                          if (getFieldValue('reverseDenominationMax')) {
                            validateFields(['reverseDenominationMax'])
                          }
                        }}
                      />
                    </FormItem>

                    <FormItem
                      name='reverseDenominationMax'
                      label='Reverse Denomination Max'
                      rules={[
                        ...(!isEPinOrBundleTopupType
                          ? [
                              {
                                required: true,
                                validator: requiredValidator('Reverse Denomination Max'),
                              },
                            ]
                          : []),
                        ...(isNumber(+getFieldValue('reverseDenominationMin'))
                          ? [
                              {
                                validator: minNumber(
                                  +getFieldValue('reverseDenominationMin'),
                                  'Reverse Denomination Max'
                                ),
                              },
                            ]
                          : [{}]),
                        {
                          validator: positiveNumber('Reverse Denomination Max'),
                        },
                      ]}
                    >
                      <TextField
                        type='number'
                        disabled={view || isEPinOrBundleTopupType}
                        placeholder='Max'
                        onChange={() => {
                          validateFields(['reverseDenominationMin'])
                        }}
                      />
                    </FormItem>
                  </div>
                )
              }}
            </FormItem>

            <div>
              <FormItem
                name='maximumReverseProcessingTime'
                label='Maximum Processing Time (in hours)'
                rules={[
                  ...(!isEPinOrBundleTopupType
                    ? [
                        {
                          validator: maxNumber(
                            MAX_REVERSE_TIME_IN_MINUTES,
                            'Maximum Processing Time'
                          ),
                        },
                        {
                          validator: positiveNumber('Maximum Processing Time'),
                        },
                        {
                          required: true,
                          validator: requiredValidator('Maximum Processing Time'),
                        },
                      ]
                    : []),
                ]}
              >
                <TextField
                  disabled={view || isEPinOrBundleTopupType}
                  type='number'
                  placeholder='Enter Maximum Processing Time in hours'
                />
              </FormItem>
            </div>

            <div>
              <FormItem
                name='taxRecoveryRate'
                label='Tax Refund'
                className='noBottomMargin'
                rules={[
                  { validator: maxNumber(MAX_REVERSE_TIME_IN_MINUTES, 'Tax Refund') },
                  {
                    validator: positiveNumber('Tax Refund'),
                  },
                ]}
              >
                <TextField
                  disabled={view || isEPinOrBundleTopupType}
                  type='number'
                  placeholder='Enter Tax Refund'
                />
              </FormItem>
            </div>
          </Wrapper>
        )
      }}
    </FormItem>
  )
}
