import React, { memo, useContext } from 'react'
import {
  ChatAddPickupRequestIcon,
  ChatChangeMPinIcon,
  ChatOpenProfileIcon,
  ChatTransferAgentIcon,
  TransactionIcon,
} from '../../../../../../../../assets/svg'
import { ChatInfoAction } from '../../../../../../../../shared/components/chat/Components'
import styles from './styles.module.scss'
import { RightModalContext } from '../../../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../../../helpers/contants'

interface IProps {
  agentId: string | number
  agentLevel: number
  chatId: number | null
  isArchived: boolean
}

const ChatroomReports = ({ agentId, agentLevel, chatId, isArchived }: IProps) => {
  const { onOpen } = useContext(RightModalContext)

  return (
    <div className={styles.chatRoomReportsActionsContainer}>
      <ChatInfoAction
        icon={<ChatOpenProfileIcon />}
        actionType='Open Profile'
        onClickByType={() =>
          onOpen(RIGHT_MODALS.SUPPORT.CHANGE_MPIN, {
            title: 'Agent Profile',
            initialStep: 'agentProfile',
            agentId,
          })
        }
      />
      <ChatInfoAction
        icon={<ChatAddPickupRequestIcon />}
        actionType='Pickup Request'
        onClickByType={() => {
          onOpen(RIGHT_MODALS.FINANCE.CREATE_PICKUP_REQUEST, {
            title: 'Pickup Request',
            agentId,
          })
        }}
      />
      <ChatInfoAction
        icon={<ChatChangeMPinIcon />}
        actionType='Change mPIN'
        onClickByType={() =>
          onOpen(RIGHT_MODALS.SUPPORT.CHANGE_MPIN, {
            title: 'Agent Profile',
            initialStep: 'changeMPin',
            agentId,
          })
        }
      />
      {agentLevel !== 1 && (
        <ChatInfoAction
          icon={<ChatTransferAgentIcon />}
          actionType='Transfer Agent'
          onClickByType={() =>
            onOpen(RIGHT_MODALS.SUPPORT.CHANGE_MPIN, {
              title: 'Transfer Sales Point',
              agentId,
            })
          }
        />
      )}
      <ChatInfoAction
        icon={<TransactionIcon />}
        actionType='Transactions History'
        onClickByType={() =>
          onOpen(RIGHT_MODALS.SALES.AGENT_TRANSACTIONS, {
            title: 'Transactions History',
            agentId,
            chatId,
            isArchived,
          })
        }
      />
      <ChatInfoAction
        icon={<TransactionIcon />}
        actionType='Reverse Request'
        onClickByType={() =>
          onOpen(RIGHT_MODALS.FINANCE.REVERSE_TRANSACTION_REQUEST, {
            title: 'Reverse Transaction Request',
            agentId,
          })
        }
      />
      {/*<ChatInfoAction*/}
      {/*  icon={<ChatSendTransactionIcon />}*/}
      {/*  actionType='Send transaction report'*/}
      {/*  onClickByType={onSelectByAttachmentType}*/}
      {/*/>*/}
    </div>
  )
}
export default memo(ChatroomReports)
