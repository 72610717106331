import { Row, Select, Select as AntSelect } from 'antd'
import FormItem from 'antd/es/form/FormItem'

// component
import { TextField } from 'src/shared/components/textfield/TextField'
import { Wrapper } from '../Wrapper'
import { useGetAgentLanguageslListQuery } from 'src/features/salesNetwork/core/http/AgentManagement'

//helpers
import { requiredValidator } from 'src/helpers/validation'

// icons
import { AfghanistanFlag, AmericanFlag2 } from 'src/assets/svg'

// styles
import styles from './styles.module.scss'

const { Option } = AntSelect

interface IDetailInfo {
  view?: boolean
}

const DetailInfo = ({ view }: IDetailInfo) => {
  const { data: languagesList } = useGetAgentLanguageslListQuery()

  return (
    <Wrapper title='Detail Info' className={styles['wrapper-detail-info']}>
      <Row justify='space-between'>
        <FormItem name='id' label='Profile ID' className={styles['form-item-inp']}>
          <TextField disabled />
        </FormItem>
        <FormItem name='createdAt' label='Creation Date' className={styles['form-item-inp']}>
          <TextField disabled />
        </FormItem>
      </Row>

      <Row justify='space-between'>
        <FormItem
          name='firstName'
          label='First Name'
          className={styles['form-item-inp']}
          rules={[{ required: true, validator: requiredValidator('First Name') }]}
        >
          <TextField disabled={view} />
        </FormItem>

        <FormItem
          name='lastName'
          label='Last Name'
          className={styles['form-item-inp']}
          rules={[{ required: true, validator: requiredValidator('Last Name') }]}
        >
          <TextField disabled={view} />
        </FormItem>
      </Row>

      <Row justify='space-between'>
        <FormItem name='role' label='Role' className={styles['form-item-inp']}>
          <Select placeholder='Role' disabled={view} allowClear>
            {[].map((i) => {
              return (
                <Option key={i} value={i}>
                  {i}
                </Option>
              )
            })}
          </Select>
        </FormItem>

        <FormItem name='company' label='Company' className={styles['form-item-inp']}>
          <TextField disabled={view} />
        </FormItem>
      </Row>

      <Row justify='space-between'>
        <FormItem
          name={['userPosition', 'name']}
          label='Position'
          className={styles['form-item-inp']}
        >
          <TextField disabled={view} />
        </FormItem>

        <FormItem
          name={['nativeLanguage', 'id']}
          label='Native Language'
          className={styles['form-item-inp']}
        >
          <Select placeholder='Select language' disabled={view} allowClear>
            {languagesList?.items.map(({ id, name }) => (
              <Option key={id} value={id}>
                <div className={styles['wrapper-content-option']}>
                  <div className={styles['wrapper-icon']}>
                    {id === 1 ? <AmericanFlag2 /> : <AfghanistanFlag />}
                  </div>
                  <div>{name}</div>
                </div>
              </Option>
            ))}
          </Select>
        </FormItem>
      </Row>
    </Wrapper>
  )
}
export default DetailInfo
