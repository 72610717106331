import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { IPosition, IPositionCreate } from '../../models/IPosition'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { IUser } from '../../models/IUser'

export const positionsApi = createApi({
  reducerPath: 'positions',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['PosList', 'PosDetail', 'UserList'],
  endpoints: (builder) => ({
    getPositionUsersList: builder.query<ITableResponse<IUser>, ITableConf>({
      query: (params) => ({
        url: 'users',
        params,
      }),
      providesTags: () => ['UserList'],
    }),

    getPositionList: builder.query<ITableResponse<IPosition>, ITableConf>({
      query: (params) => ({
        url: 'user-positions',
        params,
      }),
      providesTags: ['PosList'],
    }),

    getPositionById: builder.query<IPosition, string>({
      query: (id) => ({
        url: `user-positions/${id}`,
      }),
      providesTags: (result) => [{ type: 'PosDetail', id: result?.id }],
    }),

    createPosition: builder.mutation<IPosition, IPositionCreate>({
      query: (body) => ({
        url: 'user-positions',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PosList'],
    }),

    patchUserPosition: builder.mutation<
      IPosition,
      { id: number | string; body: Partial<IPositionCreate> }
    >({
      query: ({ id, body }) => ({
        url: `user-positions/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result) => ['PosList', { type: 'PosDetail', id: result?.id }, 'UserList'],
    }),

    deletePosition: builder.mutation<void, number | string>({
      query: (id) => ({
        url: `user-positions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PosList', 'UserList'],
    }),
  }),
})

export const {
  useGetPositionListQuery,
  useGetPositionByIdQuery,
  useCreatePositionMutation,
  usePatchUserPositionMutation,
  useDeletePositionMutation,
  useGetPositionUsersListQuery,
} = positionsApi
