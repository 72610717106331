import classNames from 'classnames'
import { Badge as AntBadge } from 'antd'
import { BadgeProps } from 'antd/lib/badge'
import './styles.scss'

type PropsType = {
  color: 'blue' | 'orange' | 'error' | 'warning' | 'success' | 'black'
  outlined?: boolean
} & BadgeProps

export const Badge = ({ color, outlined = false, ...rest }: PropsType) => {
  return <AntBadge className={classNames(color, { outlined })} {...rest} />
}
