import { useLocation, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useGetDefaultTab } from '../../../../../../salesNetwork/helpers/useGetDefaultTab'
import React, { useContext, useEffect } from 'react'
import { REWARD_POLICY_TABS_ENUM } from './index.d'
import { Space, Tabs } from 'antd'
import { Toggle } from '../../../../../../../shared/components/toggle/Toggle'
import {
  useGetRewardPolicyByIdQuery,
  useUpdateRewardPolicyMutation,
} from '../../../../../core/http/RewardPolicyApi'
import { InformationTab } from '../InformationTab'
import { TargetGroupTab } from '../TargetGroupTab'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon } from '../../../../../../../assets/svg'
import { RightModalContext } from '../../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../../helpers/contants'

const Operations = ({ activeTab }: { activeTab: string }) => {
  const { onOpen } = useContext(RightModalContext)
  const { id } = useParams()

  const { data } = useGetRewardPolicyByIdQuery(id as string)
  const [updRew, updRewResp] = useUpdateRewardPolicyMutation()

  const isTargetGroupTabActive = activeTab === REWARD_POLICY_TABS_ENUM.TARGET_GROUP

  const onAddButtonClick = () => {
    onOpen(RIGHT_MODALS.SALES.SEGMENT_TARGET_GROUP_LIST, {
      title: 'Select Target Group',
      id,
    })
  }

  return (
    <Space>
      <Toggle
        defaultChecked={data?.status}
        checked={data?.status}
        text='Status'
        inWrapper
        loading={updRewResp.isLoading}
        onChange={(status) => {
          updRew({ id: id as string, body: { status } })
        }}
      />
      {isTargetGroupTabActive && (
        <IconButton color='orange' icon={<AddIcon />} type='primary' onClick={onAddButtonClick} />
      )}
    </Space>
  )
}

export const RewardPolicyViewTabs = () => {
  const { mode } = useParams()
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: REWARD_POLICY_TABS_ENUM.INFORMATION,
  })

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  const items = [
    {
      label: REWARD_POLICY_TABS_ENUM.INFORMATION,
      key: REWARD_POLICY_TABS_ENUM.INFORMATION,
      children: <InformationTab />,
    },
    {
      label: REWARD_POLICY_TABS_ENUM.TARGET_GROUP,
      key: REWARD_POLICY_TABS_ENUM.TARGET_GROUP,
      children: <TargetGroupTab />,
    },
  ]

  return mode ? (
    <Tabs
      activeKey={activeTab}
      onChange={onTabChange}
      items={items}
      tabBarExtraContent={<Operations activeTab={activeTab} />}
    />
  ) : (
    <InformationTab />
  )
}
