import { RewardPolicyViewTabs } from './Components/Tabs/index'
import styles from './styles.module.scss'

export const RewardPolicyView = () => {
  return (
    <div className={styles.layout}>
      <RewardPolicyViewTabs />
    </div>
  )
}
