import React, { memo } from 'react'
import styles from '../../styles.module.scss'

interface IProps {
  createdAt: string
}

const NotificationDelimiter = ({ createdAt }: IProps) => {
  return (
    <div className={styles.notificationDateDelimiterContainer}>
      <div className={styles.notificationDateDelimiter}>{createdAt}</div>
    </div>
  )
}

export default memo(NotificationDelimiter)
