import { Col, Form, Row, Select, Space, Spin } from 'antd'
import FormList from 'antd/lib/form/FormList'
import { Wrapper } from '../../../../../../../../salesNetwork/components/Wrapper'
import FormItem from 'antd/es/form/FormItem'
import { useNavigate, useParams } from 'react-router'
import { requiredValidator } from '../../../../../../../../../helpers/validation'
import { IconButton } from '../../../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from '../../../../../../../../../assets/svg'
import styles from './styles.module.scss'
import { Button } from '../../../../../../../../../shared/components/button/Button'
import React, { useCallback, useEffect, useMemo } from 'react'
import { SETTINGS_ENUM } from '../../../../../../../../../routes/settings'
import { PAYMENT_POLICY_TYPE_ENUM } from '../../../../../../../models/ITargetGroup'
import { TextField } from '../../../../../../../../../shared/components/textfield/TextField'
import { TypeOfContractsBlock } from './TypeOfContractsBlock'
import { ProductsBlock } from './ProductsBlock'
import { useGetProfileQuery } from '../../../../../../../../Profile/core/http/Profile'
import { formatDateWithTime } from '../../../../../../../../../helpers/utils'
import moment from 'moment/moment'
import {
  useGetVendorPaymentPolicyTypesQuery,
  useGetVendorPolicyProductsQuery,
  useUpdateVendorPaymentPolicyTypesMutation,
  useUpdateVendorPolicyProductsMutation,
} from '../../../../../../../core/http/VendorManagementApi'
import { isEmpty } from 'lodash'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../../../shared/api/errorHandler'
import {
  IVendorPaymentPolicyProduct,
  IVendorPaymentPolicyType,
} from '../../../../../../../models/IVendorManagement'

export const WalletTypeTab = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { id, mode, venId } = useParams<{ id: string; venId: string; mode: 'view' | 'edit' }>()

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const { data: contractTypes, isFetching: isFetchingContractTypes } =
    useGetVendorPaymentPolicyTypesQuery(id as string, { skip: !id })
  const { data: policyProducts, isFetching: isFetchingProducts } = useGetVendorPolicyProductsQuery(
    id as string,
    { skip: !id }
  )

  const [updProd, updProdResp] = useUpdateVendorPolicyProductsMutation()
  const [updCont, updContResp] = useUpdateVendorPaymentPolicyTypesMutation()

  const afterSuccess = () => {
    return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${venId}/sim-card/view/${id}`)
  }

  useNotification(NOTIFICATION_TYPES.success, updProdResp.isSuccess, null, afterSuccess)
  useNotification(NOTIFICATION_TYPES.error, updContResp.isError, updContResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, updProdResp.isError, updProdResp.error as ErrorNode)

  const setDefaultValues = useCallback(() => {
    form.setFieldsValue({
      contractTypes,
      policyProducts,
    })
  }, [contractTypes, form, policyProducts])

  const onSubmit = (values: {
    contractTypes: Array<IVendorPaymentPolicyType>
    policyProducts: Array<IVendorPaymentPolicyProduct>
  }) => {
    const contactTypes =
      values.contractTypes?.map((contract) => ({
        ...contract,
        discountRate: contract?.discountRate ? Number(contract.discountRate) : 0,
      })) || []

    const policyProducts =
      values.policyProducts?.map((product) => ({
        vendorWalletType: product.vendorWalletType,
        productId: product.product?.id,
        discountRate: product?.discountRate ? Number(product.discountRate) : 0,
      })) || []

    updCont({ vendorId: id as string, body: contactTypes })
    updProd({ vendorId: id as string, body: policyProducts })
  }

  const onCancelButtonClick = () => {
    if (isViewMode) {
      return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${venId}`)
    }
    if (isEditMode) {
      form.resetFields()
      setDefaultValues()

      return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${venId}/sim-card/view/${id}`)
    }
  }

  const onSubmitButtonClick = () => {
    if (isViewMode) {
      return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${venId}/sim-card/edit/${id}`)
    }
    form.submit()
  }

  useEffect(() => {
    if (!policyProducts || !contractTypes) return
    setDefaultValues()
  }, [contractTypes, policyProducts, setDefaultValues])

  const isLoading = updProdResp.isLoading || updContResp.isLoading

  const content = (
    <>
      <Form layout='vertical' form={form} className={styles.walletTypeForm} onFinish={onSubmit}>
        <TypeOfContractsBlock view={isViewMode} />
        <ProductsBlock view={isViewMode} />
      </Form>
      <Row className={styles.simCardFooter} justify='end'>
        <Col>
          <Space size={10}>
            <div className='cancelButtonWrapper'>
              <Button color='blue' size='middle' onClick={onCancelButtonClick} block>
                Cancel
              </Button>
            </div>
            <Button
              htmlType='submit'
              size='middle'
              type='primary'
              onClick={onSubmitButtonClick}
              block
            >
              {isViewMode ? 'Edit Sim Card' : 'Save Changes'}
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
