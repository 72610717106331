import { AgentManagementTabs } from './componentns/Tabs'
import styles from './Agent-Management.module.scss'
import { createContext, Dispatch, ReactNode, useContext, useMemo, useState } from 'react'
import { AgentManagementTabsEnum } from './AgentManagement.d'

export interface IInitialValues {
  agentId?: number | null
  activeTab: string
}

const initialValues: IInitialValues = {
  agentId: null,
  activeTab: AgentManagementTabsEnum.ALL,
}

const TabsContext = createContext<{
  state: IInitialValues
  setState: Dispatch<IInitialValues>
}>({
  state: initialValues,
  setState: (): void => {},
})

export const TabsContextProvider = ({ children }: { children: ReactNode }) => {
  const [value, setValue] = useState<IInitialValues>(initialValues)

  const stateObj = useMemo(
    () => ({
      state: value,
      setState: (val: IInitialValues) => setValue((prevState) => ({ ...prevState, ...val })),
    }),
    [value]
  )

  return <TabsContext.Provider value={stateObj}>{children}</TabsContext.Provider>
}

export const useTabsContext = () => useContext(TabsContext)

export const AgentManagement = () => {
  return (
    <main className={styles['agent-management']}>
      <TabsContextProvider>
        <AgentManagementTabs />
      </TabsContextProvider>
    </main>
  )
}
