import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import { Table } from 'antd'
import classNames from 'classnames'

// components
import { Button } from 'src/shared/components/button/Button'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from 'src/shared/components/popup/Popup'
import { TableHeader } from './TableHeader'

// icons
import { DateTableIcon, TableActionIcon } from 'src/assets/svg'

// helpers
import { formatDateWithTime, formatMoneyByCurrencyType, tableExpandedIcon } from 'src/helpers/utils'
import { RIGHT_MODALS } from 'src/helpers/contants'
import { RightModalContext } from 'src/features/Modals'

// interfaces
import { ITableConf, SortingOrderEnum } from 'src/shared/models/ITableConf'
import { IPurchaseOrder } from 'src/features/Finance/models/IPurchaseOrder'
import { ISelectedFilters } from 'src/shared/components/filter/Filter'

// api
import {
  useGetPOTableFiltersListQuery,
  useGetPurchaseOrderListQuery,
  useLazyExportPurchaseOrderQuery,
} from 'src/features/Finance/core/http/PurchaseOrderApi'

// styles
import styles from './styles.module.scss'
import { useTableExport } from 'src/shared/hooks/table/useTableExport'
import {
  DEBIT_CREDIT_ENUM,
  TRANSACTION_CATEGORY_ENUM,
  TRANSACTION_STATUS_ENUM,
} from '../../../../models/ITransaction'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { useLazyGetSubTransactionsQuery } from '../../../../../Reports/core/Subtransactions'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { renderTableVendor } from '../TransactionsTable/renderTableVendor'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'
import { ICurrency } from '../../../../models/ICurency'

interface IProps {
  canAddNew: boolean
  canEdit: boolean
}

export const PurchaseOrderTable = ({ canAddNew, canEdit }: IProps) => {
  const navigate = useNavigate()
  const { onOpen } = useContext(RightModalContext)
  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)
  const [mappedPO, setMappedPO] = useState<Array<IPurchaseOrder>>([])
  const [expandedRowKeys, setExpandedRowKeys] = useState<Array<React.Key>>([])

  const { data: profile } = useGetProfileQuery()
  const { data, isFetching } = useGetPurchaseOrderListQuery(
    { ...tableConf, ...filterValues },
    { skip: Object.keys(tableConf).length < 4 }
  )
  const { data: filtersList } = useGetPOTableFiltersListQuery()
  const [getSubTrans, getSubTransResp] = useLazyGetSubTransactionsQuery()

  const [exportPurchaseOrder] = useLazyExportPurchaseOrderQuery()

  useTableExport({ tableConf, filterValues, promise: exportPurchaseOrder })

  const emptyTableConf = useEmptyTable(canAddNew ? () => navigate('purchase-order/new') : undefined)
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IPurchaseOrder>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
    setExpandedRowKeys,
  })

  const tableActionsPopup = (id: string, status: string): IPopupListItems[] => [
    {
      text: 'View',
      shouldDisplay: true,
      onClick: () => navigate(`purchase-order/view/${id}`),
    },
    {
      text: 'History',
      shouldDisplay: true,
      onClick: () =>
        onOpen(RIGHT_MODALS.FINANCE.PURCHASE_ORDER_CHANGES, {
          title: 'Purchase Order Changes',
          id,
        }),
    },
    {
      text: 'Edit',
      shouldDisplay: canEdit,
      onClick: () => navigate(`purchase-order/edit/${id}`),
    },
    ...(status === TRANSACTION_STATUS_ENUM.CHECKED
      ? [
          {
            text: 'Approve',
            withDivider: true,
            shouldDisplay: true,
            color: POPOVER_LIST_ITEM_COLORS_ENUM.GREEN,
            onClick: () => onOpen(RIGHT_MODALS.FINANCE.PURCHASE_ORDER, { id }),
          },
          {
            text: 'Reject',
            shouldDisplay: true,
            color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
            onClick: () => onOpen(RIGHT_MODALS.FINANCE.PURCHASE_ORDER, { id }),
          },
        ]
      : []),
    ...(status === TRANSACTION_STATUS_ENUM.CREATED
      ? [
          {
            text: 'Check',
            withDivider: true,
            shouldDisplay: true,
            color: POPOVER_LIST_ITEM_COLORS_ENUM.GREEN,
            onClick: () => onOpen(RIGHT_MODALS.FINANCE.PURCHASE_ORDER, { id }),
          },
        ]
      : []),
  ]

  const columns: ColumnsType<IPurchaseOrder> = [
    {
      title: '',
      dataIndex: '',
      key: 'expand',
      width: '1%',
      onCell: (record) => {
        return {
          onClick: async () => {
            const mappedRec = { ...record }
            const data = await getSubTrans({
              transactionCategory: TRANSACTION_CATEGORY_ENUM.PURCHASE_ORDER,
              id: record.id,
            })
            mappedRec.children = data?.data?.items

            setMappedPO((prevState) =>
              prevState.map((trans) => {
                if (trans.id === mappedRec.id) {
                  return mappedRec
                }
                return trans
              })
            )
          },
        }
      },
    },
    {
      title: 'Transaction ID',
      dataIndex: 'id',
      sorter: true,
      width: '10%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      sorter: true,
      width: '15%',
      render: (name: IPurchaseOrder['createdAt']) => (
        <div className={styles.poCreatedContainer}>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(name, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Vendor Name/Description',
      dataIndex: 'name',
      sorter: true,
      width: '15%',
      render: (_, row) => renderTableVendor(row?.vendor, row, isPrivateMode),
    },
    {
      title: 'Amount',
      dataIndex: 'currencyAmount',
      width: '15%',
      sorter: true,
      render: (amount: IPurchaseOrder['currencyAmount'], row) => (
        <RecursivelyReplaceCharacters>
          <span
            className={classNames('amountContainer', {
              dr:
                (amount < 0 && row.drCr === DEBIT_CREDIT_ENUM.CREDIT) ||
                (amount > 0 && row.drCr === DEBIT_CREDIT_ENUM.DEBIT),
              cr:
                (amount < 0 && row.drCr === DEBIT_CREDIT_ENUM.DEBIT) ||
                (amount > 0 && row.drCr === DEBIT_CREDIT_ENUM.CREDIT),
              zero: !amount,
            })}
          >
            {formatMoneyByCurrencyType(amount, row?.drCr)}
          </span>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'EMoney',
      dataIndex: 'amount',
      width: '15%',
      sorter: true,
      render: (amount: IPurchaseOrder['amount'], row) => (
        <RecursivelyReplaceCharacters>
          <span
            className={classNames('amountContainer', {
              dr:
                (amount < 0 && row.drCr === DEBIT_CREDIT_ENUM.CREDIT) ||
                (amount > 0 && row.drCr === DEBIT_CREDIT_ENUM.DEBIT),
              cr:
                (amount < 0 && row.drCr === DEBIT_CREDIT_ENUM.DEBIT) ||
                (amount > 0 && row.drCr === DEBIT_CREDIT_ENUM.CREDIT),
              zero: !amount,
            })}
          >
            {formatMoneyByCurrencyType(amount, row?.drCr)}
          </span>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      sorter: true,
      width: '10%',
      render: (curr: ICurrency | string) => (
        <RecursivelyReplaceCharacters>
          {typeof curr === 'string' ? curr : curr?.textCode}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: '10%',
      render: (name: IPurchaseOrder['status']) => (
        <RecursivelyReplaceCharacters>
          <div className={styles.statusContainer}>
            <div className={classNames(styles[name.toLowerCase()], styles.statusPoint)} />
            <div>{name}</div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: '25%',
      render: (_, row) => {
        if (Object.hasOwn(row, 'mainTransactionId')) return
        return (
          <div className={styles.actionsContainer}>
            {row.status === TRANSACTION_STATUS_ENUM.CREATED && (
              <Button
                style={{ width: '182px' }}
                color='blue'
                size='middle'
                type='primary'
                onClick={() => onOpen(RIGHT_MODALS.FINANCE.PURCHASE_ORDER, { id: row?.id })}
              >
                Check
              </Button>
            )}
            {row.status === TRANSACTION_STATUS_ENUM.CHECKED && (
              <React.Fragment>
                <Button
                  style={{ marginRight: '10px', width: '80px' }}
                  color='blue'
                  size='middle'
                  onClick={() => onOpen(RIGHT_MODALS.FINANCE.PURCHASE_ORDER, { id: row?.id })}
                >
                  Reject
                </Button>
                <Button
                  style={{ width: '92px' }}
                  size='middle'
                  type='primary'
                  onClick={() => onOpen(RIGHT_MODALS.FINANCE.PURCHASE_ORDER, { id: row?.id })}
                >
                  Approve
                </Button>
              </React.Fragment>
            )}
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '10%',
      render: (_, row) => {
        if (Object.hasOwn(row, 'mainTransactionId')) return
        return (
          <Popup data={tableActionsPopup(row.id, row.status)}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        )
      },
    },
  ]

  useEffect(() => {
    if (data) {
      setMappedPO(data?.items)
    }
  }, [data])

  return (
    <React.Fragment>
      <TableHeader
        numberOfItems={data?.totalCount}
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filtersList}
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        loading={isFetching || getSubTransResp.isFetching}
        onChange={handleTableChange}
        className={styles.tableContainer}
        dataSource={mappedPO}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.id}
        pagination={pagination}
        scroll={{ x: 940 }}
        expandable={{
          expandedRowKeys,
          expandIcon: (props) => {
            if (
              Object.hasOwn(props.record, 'mainTransactionId') ||
              !props.record?.countSubTransaction
            )
              return null
            return tableExpandedIcon<IPurchaseOrder>(props)
          },
          onExpandedRowsChange: (expandedKeys) => setExpandedRowKeys([...expandedKeys]),
        }}
      />
    </React.Fragment>
  )
}
