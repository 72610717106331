import { Table } from 'antd'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { ColumnsType } from 'antd/es/table'
import {
  ITicket,
  TICKET_PRIORITY_ENUM,
  TICKET_STATUS_ENUM,
  TICKET_TYPE_ENUM,
} from '../../../../models/ITicket'
import React, { useCallback, useEffect, useState } from 'react'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../shared/components/popup/Popup'
import {
  DateTableIcon,
  TableActionIcon,
  TicketOpenStatusIcon,
  TicketResolvedStatusIcon,
} from '../../../../../../assets/svg'
import { Link, useNavigate } from 'react-router-dom'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import styles from './style.module.scss'
import { formatDateWithTime, tableExpandedIcon } from '../../../../../../helpers/utils'
import classNames from 'classnames'
import emptyAvatarImage from '../../../../../../assets/img/Avatar.png'
import { Tags } from '../../../../../../shared/components/tags'
import {
  useDeleteTicketMutation,
  useGetAllTicketsQuery,
  useGetAllTicketsTableFiltersListQuery,
  useGetAssignedTicketsQuery,
} from '../../../../core/http/TicketSystemApi'
import { AllCasesTableHeader } from '../TableHeader'
import { useTableExport } from 'src/shared/hooks/table/useTableExport'
import { useLazyExportUsersTableQuery } from 'src/features/Settings/core/http/UsersManagementApi'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { isEmpty, omit } from 'lodash'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { verifyUserPermissions } from '../../index.d'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

const getStatusIcon = (status: ITicket['status']) => {
  if (status === TICKET_STATUS_ENUM.OPEN) return <TicketOpenStatusIcon />
  if (status === TICKET_STATUS_ENUM.RESOLVED) return <TicketResolvedStatusIcon />
  if (status === TICKET_STATUS_ENUM.REPLIED) return <div className={styles.repliedStatus} />
}

interface IProps {
  assigned?: boolean
  canEdit: boolean
  canView: boolean
  canDelete: boolean
  status?: TICKET_STATUS_ENUM | undefined
}

export const AllCasesTable = ({ canEdit, canView, canDelete, status, assigned }: IProps) => {
  const { queryParams, queryFields } = useGetQueryParams(
    {
      orderType: SortingOrderEnum.DESC,
      orderField: 'createdAt',
      ...(status && { status }),
    },
    false,
    ['status']
  )

  const [exportTable] = useLazyExportUsersTableQuery()
  const [expandedRowKeys, setExpandedRowKeys] = useState<Array<React.Key>>([])
  const [mappedTrans, setMappedTrans] = useState<ITicket[]>([])

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)
  const navigate = useNavigate()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const [deleteTicket, dDelete] = useDeleteTicketMutation()

  const allFilters = useGetAllTicketsTableFiltersListQuery(null, {
    skip: assigned,
  })
  const tickets = useGetAllTicketsQuery(
    { ...tableConf, ...filterValues },
    { skip: isEmpty(tableConf) || assigned }
  )
  const { data: userProfile } = useGetProfileQuery()

  const assignedFilters = useGetAllTicketsTableFiltersListQuery(null, {
    skip: !assigned,
  })

  const assignedTickets = useGetAssignedTicketsQuery(
    { ...tableConf, ...filterValues },
    { skip: isEmpty(tableConf) || !assigned }
  )

  const data = assignedTickets.data ?? tickets.data
  const filterFields = assignedFilters.data ?? allFilters.data
  const isFetching = assignedTickets.isFetching || tickets.isFetching

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<ITicket>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
    setExpandedRowKeys,
    extraFilters: ['status'],
  })

  useNotification(NOTIFICATION_TYPES.success, dDelete.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, dDelete.isError, dDelete.error as ErrorNode)

  useTableExport({
    tableConf,
    filterValues,
    promise: exportTable,
  })

  useEffect(() => {
    if (!status) return
    setTableConf((p) => ({ ...p, status }))
  }, [status])

  const handleTagClick = (tag: string) => () => {
    setFilterValues({ ...filterValues, tags: tag })
  }

  useEffect(() => {
    if (isEmpty(data)) return

    setMappedTrans(data.items)
  }, [data])

  const tableActionsPopup = useCallback(
    (row: ITicket): IPopupListItems[] => {
      // Local permission on ticket actions

      const permissionView = verifyUserPermissions(userProfile, row, canView)
      const permissionEdit = verifyUserPermissions(userProfile, row, canEdit)
      const permissionDelete = verifyUserPermissions(userProfile, row, canDelete)

      const restrictView =
        !permissionView || (typeof permissionView === 'object' && !permissionView.view)

      const restrictEdit =
        !permissionEdit ||
        (typeof permissionEdit === 'object' && !permissionEdit.edit) ||
        row.type === TICKET_TYPE_ENUM['sub-ticket']

      const restrictDelete =
        !permissionDelete || (typeof permissionDelete === 'object' && !permissionDelete.delete)

      return [
        {
          text: 'View',
          shouldDisplay: !restrictView,
          onClick: () => navigate(`/support/tickets/view/${row.id}`),
        },
        {
          text: 'Edit',
          shouldDisplay: !restrictEdit && row.status !== TICKET_STATUS_ENUM.RESOLVED,
          onClick: () => navigate(`/support/tickets/edit/${row.id}`),
        },
        {
          text: 'Delete',
          shouldDisplay: !restrictDelete,
          withDivider: true,
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          onClick: () => deleteTicket(String(row.id)),
        },
      ]
    },
    [navigate, canView, canEdit, canDelete, userProfile]
  )

  const columns: ColumnsType<ITicket> = [
    {
      title: '',
      dataIndex: '',
      key: 'expand',
      width: '1%',
      onCell: (record) => ({
        onClick: () => {
          const mappedRec = { ...record }

          mappedRec.children = record.tickets.map((i) => ({
            ...i,
            creator: record.creator,
            subject: record.subject,
            tags: record.tags,
            priority: record.priority,
            mainTicketId: record.id,
          }))

          setMappedTrans((prevState) =>
            prevState.map((trans) => {
              if (trans.id === mappedRec.id) {
                return mappedRec
              }
              return trans
            })
          )
        },
      }),
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      sorter: true,
      render: (name: ITicket['subject'], row) => (
        <RecursivelyReplaceCharacters>
          <Link to={`${canView ? `view/${row.id}` : '#'}`} className='table-avatar'>
            <div>{name}</div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Ticket Id',
      dataIndex: 'id',
      sorter: true,
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
      render: (createdBy: ITicket['creator']) => (
        <RecursivelyReplaceCharacters>
          <Link to={`/settings/users-management/view/${createdBy?.id}`} className='table-avatar'>
            <img
              alt='creator logo'
              src={!isPrivateMode && createdBy?.logo ? createdBy?.logo : emptyAvatarImage}
            />{' '}
            <div>{createdBy?.name || ''}</div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      sorter: true,
      render: (createdAt: ITicket['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(createdAt, userProfile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Assigned To',
      dataIndex: 'assigned',
      sorter: true,
      render: (assigned: ITicket['assigned'], row: ITicket) => (
        <RecursivelyReplaceCharacters>
          {assigned?.name || row.department?.name}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      render: (tags: ITicket['tags']) => <Tags tags={tags ?? []} onTagClick={handleTagClick} />,
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      sorter: true,
      render: (priority: TICKET_PRIORITY_ENUM) => {
        if (priority === TICKET_PRIORITY_ENUM.LOW) {
          return (
            <RecursivelyReplaceCharacters>
              <div className={styles.priority}>
                <div className={classNames(styles.low, styles.point)} />
                <div>Low</div>
              </div>
            </RecursivelyReplaceCharacters>
          )
        }
        if (priority === TICKET_PRIORITY_ENUM.MEDIUM) {
          return (
            <RecursivelyReplaceCharacters>
              <div className={styles.priority}>
                <div className={classNames(styles.middle, styles.point)} />
                <div>Medium</div>
              </div>
            </RecursivelyReplaceCharacters>
          )
        }
        if (priority === TICKET_PRIORITY_ENUM.HIGH) {
          return (
            <RecursivelyReplaceCharacters>
              <div className={styles.priority}>
                <div className={classNames(styles.high, styles.point)} />
                <div>High</div>
              </div>
            </RecursivelyReplaceCharacters>
          )
        }
      },
    },
    {
      title: 'Ticket Status',
      dataIndex: 'status',
      sorter: true,
      render: (status: ITicket['status']) => (
        <RecursivelyReplaceCharacters>
          <div className={classNames(styles[status.toLowerCase()], styles.status)}>
            {getStatusIcon(status)}
            {status}
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      render: (_: ITicket['id'], row: ITicket) => (
        <Popup data={tableActionsPopup(row)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return (
    <>
      <AllCasesTableHeader
        data={filterFields}
        numberOfItems={data?.totalCount}
        setActiveFilters={handleFiltersChange}
        filterValues={omit(filterValues, 'status')}
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        className={styles.table}
        onChange={handleTableChange}
        pagination={pagination}
        loading={isFetching || dDelete.isLoading}
        dataSource={mappedTrans}
        rowKey={(row) => row.id}
        scroll={{ x: 1000 }}
        expandable={{
          expandedRowKeys,
          expandIcon: (props) => {
            if (isEmpty(props.record.tickets)) return null
            return tableExpandedIcon<ITicket>(props)
          },
          onExpandedRowsChange: (expandedKeys) => setExpandedRowKeys([...expandedKeys]),
        }}
      />
    </>
  )
}
