import { Moment } from 'moment'
import { IByUser } from '../../../shared/models/IByUser'
import { IAgentType } from './IAgentType'

export enum DISCOUNT_POLICY_PRODUCT_TYPE_ENUM {
  TOPUP = 'TOPUP',
  BILL_PAYMENT = 'BILL_PAYMENT',
  BUNDLE_TOPUP = 'BUNDLE_TOPUP',
  MASTER_BUNDLE_TOPUP = 'MASTER_BUNDLE_TOPUP',
  E_MONEY = 'E_MONEY',
}

export interface IPolicyMasterProduct {
  id: number
  name: string
  status: boolean
  type: string
  logo: string
  createdByUser: IByUser
  createdAt: Date
  updatedAt: Date
}

interface ILevel {
  id: number
  name: string
  level: number
}

export interface ILevelRate {
  level: ILevel
  rate: number
}

export interface IPolicyProduct {
  id: number
  rate: number
  masterProduct: IByUser
  levelRates: Array<ILevelRate>
}

export interface IProductAccess {
  masterProduct: IByUser
  isLocked: boolean
  isTree: boolean
  status?: boolean
}

export interface IAssignedAgent {
  name: string
  avatar: string
  id: string
  isDeleted: boolean
}

export interface IDiscountPolicy {
  id: number
  name: string
  discountPolicyType: string
  start: string
  end: string
  status: boolean
  minRate: number
  isOpen: boolean
  isEditable: boolean
  isOpenFloating?: boolean
  masterProductType: DISCOUNT_POLICY_PRODUCT_TYPE_ENUM
  agentType: Omit<IAgentType, 'agents'>
  accountType: {
    id: number
    status: boolean
    accountType: string
  }
  createdByUser: IByUser
  createdAt: Date
  updatedAt: Date
  isAllowAllAgents: boolean
  allowedAgents: Array<string>
  discountPolicyProducts: Array<IPolicyProduct>
  discountPolicyProductsCount: number
  assignedAgentsCount: number
  assignedAgents: Array<IAssignedAgent>
  productsAccess: IProductAccess[]
  forbiddenAccessChannels: Array<string>
}

export interface IDiscountPolicyDetail {
  id: number
  name: string
  discountPolicyType: string
  start: string
  end: string
  status: boolean
  createdByUser: IByUser
  createdAt: Date
  updatedAt: Date
}

interface IDiscountPolicyProductCreate {
  masterProductId: number
  levelRates: Array<{
    levelId: number
    rate: number
  }>
}

export interface IDiscountPolicyCreate {
  name: string
  start: string | Moment
  end: string | Moment
  agentTypeId: number
  accountTypeId: number
  minRate: number
  isOpen: boolean
  isOpenFloating: boolean
  isAllowAllAgents: boolean
  allowedAgents: Array<number>
  masterProductType: DISCOUNT_POLICY_PRODUCT_TYPE_ENUM
  status: boolean
  discountPolicyProducts: Array<IDiscountPolicyProductCreate>
  productAccess: Array<number>
  forbiddenAccessChannels: Array<string>
}

export interface IDiscountPolicyShort {
  id: number
  name: string
  discountPolicyType: string
  minRate: number
}
