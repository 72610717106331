import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

interface IActiveTab {
  pathName: string
  activeTab: string
}

interface IUseGetDefaultTab {
  activeTab: string
}

export const useGetDefaultTab = ({ activeTab: currentTab }: IUseGetDefaultTab) => {
  const { pathname } = useLocation()

  const normalizePathname = useCallback((path: string) => {
    return path.replace(/view|edit/gi, 'mode')
  }, [])

  const findActiveTabByPathname = useCallback(
    (pathname?: string) => {
      if (!pathname) return currentTab
      const storedTabs = localStorage.getItem('activeTabs')
      const tabs = storedTabs ? (JSON.parse(storedTabs) as Array<IActiveTab>) : []

      const tab = tabs?.find((tab) => tab?.pathName === normalizePathname(pathname))
      return tab ? tab?.activeTab : currentTab
    },
    [currentTab, normalizePathname]
  )

  const [activeTab, setActiveTab] = useState(() => findActiveTabByPathname(pathname))

  const setActiveTabs = useCallback(
    (activeTab: string) => {
      if (!pathname) return
      const storedTabs = localStorage.getItem('activeTabs')

      const tabs = storedTabs ? (JSON.parse(storedTabs) as Array<IActiveTab>) : []

      const tabIndex = tabs?.findIndex(
        (tab) => tab?.pathName === pathname.replace(/view|edit/gi, 'mode')
      )
      if (tabIndex !== -1) {
        tabs[tabIndex].activeTab = activeTab
      } else {
        tabs.push({ pathName: normalizePathname(pathname), activeTab })
      }

      localStorage.setItem('activeTabs', JSON.stringify(tabs))
    },
    [normalizePathname, pathname]
  )

  const getActiveTabFromLocalStorage = useCallback(() => {
    const currentTab = findActiveTabByPathname(pathname)
    if (!currentTab) return

    setActiveTab(currentTab as string)
  }, [findActiveTabByPathname, pathname])

  useEffect(() => {
    getActiveTabFromLocalStorage()
  }, [getActiveTabFromLocalStorage])

  useEffect(() => {
    setActiveTabs(activeTab)
  }, [activeTab, pathname, setActiveTabs])

  return { activeTab, setActiveTab }
}
