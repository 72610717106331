import { Button, notification } from 'antd'

export type ErrorNode = {
  data: {
    message: string
    statusCode: number
  }
  status: number
}

type NotificationType = 'success' | 'info' | 'warning' | 'error'
export const MESSAGE_KEY = 'notification-message-key'

const ShowMessage = (type: NotificationType = 'success', message = '', description = '') => {
  notification?.open({
    message: message || 'undef message',
    key: MESSAGE_KEY,
    type,
    description,
  })
}

export const showConfirmMessage = (message: string, onConfirmClick: () => void) => {
  const btn = (
    <Button
      type='primary'
      onClick={() => {
        onConfirmClick()
        notification.destroy()
      }}
    >
      Confirm
    </Button>
  )

  notification?.open({
    type: 'warning',
    key: MESSAGE_KEY,
    duration: 3,
    message,
    btn,
  })
}

export const handleError = (err: ErrorNode) => ShowMessage('error', err?.data?.message)
export const handleErrorText = (err: string) => ShowMessage('error', err)
export const handleInfo = (info: string) => ShowMessage('info', info)
export const handleSuccess = (success: string) => ShowMessage('success', success)

export default ShowMessage
