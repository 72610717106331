import React from 'react'
import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import FormItem from 'antd/es/form/FormItem'
import { DatePicker } from '../../../../../../shared/components/datePicker'
import { requiredValidator } from '../../../../../../helpers/validation'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import moment from 'moment'

export const RestrictionDuration = ({ view }: IViewMode) => {
  return (
    <Wrapper title='Restriction duration'>
      <FormItem noStyle shouldUpdate>
        {({ resetFields }) => (
          <FormItem
            name='startAt'
            label='Start Date/Time'
            rules={[{ required: true, validator: requiredValidator('Start Date/Time') }]}
          >
            <DatePicker
              disabled={view}
              onChange={() => {
                resetFields(['endAt'])
              }}
              showTime
            />
          </FormItem>
        )}
      </FormItem>

      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <FormItem name='endAt' label='End Date/Time' className='noBottomMargin'>
            <DatePicker
              disabled={view || !getFieldValue('startAt')}
              disabledDate={(current) =>
                getFieldValue('startAt')
                  ? current.isBefore(moment(getFieldValue('startAt')), 'days') ||
                    current.isSame(moment(getFieldValue('startAt')), 'days')
                  : false
              }
              showTime
            />
          </FormItem>
        )}
      </FormItem>
    </Wrapper>
  )
}
