import { FormInstance, Select, Form, Spin } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import TextArea from 'antd/es/input/TextArea'

import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { ArrowRightIcon, EmailFilledIcon, PhoneDarkBlueIcon } from 'src/assets/svg'
import { requiredValidator } from 'src/helpers/validation'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { useGetAgentByIdQuery, useSendMessageMutation } from '../../core/http/AgentManagement'
import { CONTACT_TYPE_NEW, IAgentAll } from '../../models/IAgent'

// styles
import styles from './styles.module.scss'

const options = [
  { id: 0, name: 'Email', value: 'EMAIL', icon: <EmailFilledIcon /> },
  { id: 0, name: 'SMS', value: 'MOBILE', icon: <PhoneDarkBlueIcon /> },
]

interface IAvailableOption {
  type: string
  value: string
}

export const generateAvailableOptions = (
  data:
    | IAgentAll['resellerCredentials']
    | IAgentAll['agentIdentifiers']
    | IAgentAll['contactInfo'] = []
) => {
  let arr: IAvailableOption[] = []

  if (!Array.isArray(data)) {
    const item = data as IAgentAll['resellerCredentials']

    if (item?.isVerified) {
      arr = [
        {
          type: CONTACT_TYPE_NEW.EMAIL.type,
          value: item.email,
        },
      ]
    }
    return arr
  }

  const items = data as IAgentAll['agentIdentifiers'] | IAgentAll['contactInfo']
  arr = items
    .filter((i) => i?.isVerified)
    .map(({ type, value }) => ({ type: type as string, value: value as string }))

  return arr
}

export const SendMessageBlock = () => {
  const { agentId } = useParams()
  const [form] = Form.useForm()

  const { data: dataAgent, isFetching } = useGetAgentByIdQuery(agentId)
  const [sendMessage, dSend] = useSendMessageMutation()

  const [availableOptions, setAvailableOptions] = useState<IAvailableOption[]>([])

  useNotification(NOTIFICATION_TYPES.error, dSend.isError, dSend.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.success, dSend.isSuccess, null, () => {
    form.resetFields()
  })

  useEffect(() => {
    if (!dataAgent) return

    const { resellerCredentials, contactInfo, agentIdentifiers } = dataAgent

    const resArr = generateAvailableOptions(resellerCredentials)
    const contArr = generateAvailableOptions(contactInfo)
    const identArr = generateAvailableOptions(agentIdentifiers)

    const availableOptions = [...resArr, ...contArr, ...identArr]

    setAvailableOptions(availableOptions)
  }, [dataAgent])

  const resetMessageType = () => {
    form.setFields([
      {
        name: 'value',
        value: null,
        errors: [],
      },
      {
        name: 'message',
        value: null,
        errors: [],
      },
    ])
  }

  const onChangeMessageType = () => {
    resetMessageType()
  }

  const onFinish = (values: { type: string; value: string; message: string }) => {
    const { type, value, message } = values
    sendMessage({ id: agentId as string, type, value, body: { message } })
  }

  const availableTypesArr = availableOptions.map(({ type }) => type)
  const valueOpts = options.filter(({ value }) => availableTypesArr.includes(value))

  return (
    <Wrapper title='Send Message' className={styles.wrapperContainer}>
      <Spin spinning={isFetching || dSend.isLoading}>
        <Form form={form} layout='vertical' onFinish={onFinish}>
          <FormItem
            name='type'
            className={styles['form-item-inp']}
            rules={[{ required: true, validator: requiredValidator('Message Type') }]}
          >
            <Select
              allowClear
              placeholder='Select Message Type'
              onChange={onChangeMessageType}
              onClear={resetMessageType}
              disabled={dataAgent?.isDeleted}
            >
              {valueOpts.map((i) => (
                <Select.Option key={i.id} value={i.value}>
                  <div className={styles.optionContainer}>
                    {i.icon}
                    <div>{i.name}</div>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </FormItem>

          <FormItem noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const type = getFieldValue('type')
              const options = availableOptions.filter((i) => i.type === type)

              return (
                <FormItem
                  name='value'
                  className={styles['form-item-inp']}
                  rules={[{ required: true, validator: requiredValidator('Message Type Value') }]}
                >
                  <Select disabled={!type} allowClear placeholder='Select Message Type Value'>
                    {options.map((i) => (
                      <Select.Option key={i.value} value={i.value}>
                        <div className={styles.optionContainer}>
                          <div>{i.value}</div>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              )
            }}
          </FormItem>

          <FormItem noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const type = getFieldValue('type')

              return (
                <FormItem
                  name='message'
                  className={styles.textAreaContainer}
                  rules={[{ required: true, validator: requiredValidator('Message') }]}
                >
                  <TextArea disabled={!type} placeholder='Type Message' style={{ height: 100 }} />
                </FormItem>
              )
            }}
          </FormItem>

          <FormItem className={styles.btnContainer}>
            <IconButton
              icon={<ArrowRightIcon />}
              iconPos='right'
              size='large'
              color='orange'
              type='primary'
              onClick={() => form.submit()}
              disabled={dataAgent?.isDeleted}
            >
              Send
            </IconButton>
          </FormItem>
        </Form>
      </Spin>
    </Wrapper>
  )
}
