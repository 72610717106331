import styles from '../../../InventoryView/styles.module.scss'
import { Widget } from '../../../../../../../shared/components/widget'
import {
  BalanceIcon,
  MonetizationWidgetIcon,
  WidgetClockIcon,
} from '../../../../../../../assets/svg'
import { WIDGET_LIST_ENUM } from '../../../../../../../shared/models/WidgetListEnum'
import { HYDRA_INVENTORY_WIDGETS_TYPE } from '../../index'

interface IProps {
  data: HYDRA_INVENTORY_WIDGETS_TYPE
}

export const Widgets = ({ data }: IProps) => {
  const findActiveInactiveWidget = (active = true) => {
    return data[WIDGET_LIST_ENUM.SIM_CARD_COUNT_BY_STATUS].value.find((widget) =>
      active ? widget.status : !widget.status
    )
  }

  return (
    <div className={styles.widgetsWrapper}>
      <Widget
        icon={<BalanceIcon />}
        title='Total Balance'
        value={data[WIDGET_LIST_ENUM.SIM_CARD_TOTAL_BALANCE].value}
        color='orange'
      />
      <Widget
        icon={<MonetizationWidgetIcon />}
        title='Active/Inactive'
        value={(findActiveInactiveWidget()?.count as number) ?? 0}
        valueTotal={findActiveInactiveWidget(false)?.count ?? 0}
      />
      <Widget
        icon={<WidgetClockIcon />}
        title='Purchase/Sales'
        value={data[WIDGET_LIST_ENUM.SIM_CARD_SALES_AND_PO].value[0].po}
        valueTotal={data[WIDGET_LIST_ENUM.SIM_CARD_SALES_AND_PO].value[0].sales}
      />
    </div>
  )
}
