import styles from '../../styles.module.scss'
import { DateTableIcon, EditIcon, UserCircleIcon } from '../../../../../../../assets/svg'
import { formatDateWithTime } from '../../../../../../../helpers/utils'
import { IPurchasePlanLog } from '../../../../../models/IInventory'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

export const TargetPlanListItem = ({ log }: { log: IPurchasePlanLog }) => {
  const { data: profile } = useGetProfileQuery()

  return (
    <div className={styles.targetListItem}>
      <div className={styles.targetPlanItemLeft}>
        <div className={styles.targetPlanItemRow}>
          <span className={styles.targetPlanItemIcon}>
            {log?.user?.logo ? <img src={log?.user?.logo} alt='user-logo' /> : <UserCircleIcon />}
          </span>
          <div>{log?.user?.name}</div>
        </div>
        <div className={styles.targetPlanItemRow}>
          <span className={styles.targetPlanItemIcon}>
            <DateTableIcon />
          </span>
          <div>{formatDateWithTime(log?.createdAt, profile?.calendar)}</div>
        </div>
      </div>

      <div className={styles.targetListItemEdit}>
        <EditIcon />
      </div>
    </div>
  )
}
