import { DEBIT_CREDIT_ENUM, TRANSACTION_STATUS_ENUM } from '../../../../models/ITransaction'
import { Table } from 'antd'
import { formatNumberToLocale } from '../../../../../salesNetwork/helpers'
import classNames from 'classnames'
import styles from '../PickupRequestTable/styles.module.scss'
import { isPositiveNumber } from '../../../../../../helpers/utils'
import React from 'react'
import { IBankWalletTransaction } from '../../../../models/IBankWalletTransactions'

export const TransactionTableFooter = ({ data }: { data: IBankWalletTransaction[] }) => {
  const balanceBefore = data?.reduce((prev, curr) => prev + curr.balanceBefore, 0)
  const currencyAmount = data?.reduce((prev, curr) => {
    if (
      curr.status === TRANSACTION_STATUS_ENUM.REJECTED ||
      curr.status === TRANSACTION_STATUS_ENUM.CANCELED ||
      curr.status === TRANSACTION_STATUS_ENUM.FAILED
    ) {
      return prev + 0
    }

    if (curr.drCr === DEBIT_CREDIT_ENUM.DEBIT) {
      return prev - curr.currencyAmount
    }
    return prev + curr.currencyAmount
  }, 0)
  const amount = data?.reduce((prev, curr) => {
    if (
      curr.status === TRANSACTION_STATUS_ENUM.REJECTED ||
      curr.status === TRANSACTION_STATUS_ENUM.CANCELED ||
      curr.status === TRANSACTION_STATUS_ENUM.FAILED
    ) {
      return prev + 0
    }

    if (curr.drCr === DEBIT_CREDIT_ENUM.DEBIT) {
      return prev - curr.amount
    }
    return prev + curr.amount
  }, 0)
  const balanceAfter = data?.reduce((prev, curr) => prev + curr.balanceAfter, 0)

  return (
    <Table.Summary.Row className='table-footer-total'>
      <Table.Summary.Cell index={0} colSpan={4}>
        Total
      </Table.Summary.Cell>

      <Table.Summary.Cell index={1} colSpan={1}>
        <div>{formatNumberToLocale(balanceBefore)}</div>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={2} colSpan={1}>
        <div
          className={classNames(styles.amountContainer, {
            [styles.dr]: !isPositiveNumber(currencyAmount),
            [styles.cr]: isPositiveNumber(currencyAmount),
          })}
        >
          {formatNumberToLocale(currencyAmount)}
        </div>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={3} colSpan={1}>
        <div>{formatNumberToLocale(amount)}</div>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={3} colSpan={3}>
        <div>{formatNumberToLocale(balanceAfter)}</div>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  )
}
