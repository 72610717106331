import React, { useEffect, useMemo, useState } from 'react'
import { AgentTypeChart } from './Components/AgentTypeChart'
import WalletTypeChart from './Components/WalletTypeChart'
import SalesNetworkWidgets from './Components/Widgets'
import { DashboardHeader } from '../../../Dashboard/pages/Overview/Components/DashboardHeader/index'
import styles from './styles.module.scss'
import {
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  formatShamsiMomentToISO,
  getFrequency,
  moment,
} from '../../../../helpers/utils'
import { useGetProfileQuery } from '../../../Profile/core/http/Profile'
import { Moment } from 'moment/moment'
import { WIDGET_LIST_ENUM, DASHBOARD_DATE_OPTIONS } from '../../../../shared/models/WidgetListEnum'
import { widgetsSocketConnection } from '../../../../shared/sockets'
import { IWidget } from '../../../../shared/models/IWidget'
import { ISelectedFilters } from '../../../../shared/components/filter/Filter'
import { isEmpty } from 'lodash'
import { SalesSummaryBarChart } from '../../../Reports/pages/Transactions/Components/SalesSummaryBarChart'
import { ISalesSummary } from '../../../Reports/models/IExternalReport'
import { TopLocationsBlock } from '../../../Dashboard/pages/Overview/Components/TopLocationsBlock/index'
import { ITopLocation } from '../../../Dashboard/models/ITopLocation'

export interface IDashboardWalletType {
  walletType: string
  salesAmount: number
}

export interface IDashboardAgentType extends Omit<IDashboardWalletType, 'walletType'> {
  agentType: string
}

export type SalesDashboardWidgetStateType = Record<
  Exclude<
    WIDGET_LIST_ENUM,
    | WIDGET_LIST_ENUM.WALLET_TYPE
    | WIDGET_LIST_ENUM.AGENT_TYPE
    | WIDGET_LIST_ENUM.SALES_SUMMARY
    | WIDGET_LIST_ENUM.TOP_LOCATIONS
  >,
  {
    value: number
    statistic: number
    valueTotal?: number
    totalValue?: number
  }
> &
  Record<
    WIDGET_LIST_ENUM.WALLET_TYPE,
    {
      value: Array<IDashboardWalletType>
    }
  > &
  Record<
    WIDGET_LIST_ENUM.AGENT_TYPE,
    {
      value: Array<IDashboardAgentType>
    }
  > &
  Record<
    WIDGET_LIST_ENUM.SALES_SUMMARY,
    {
      value: {
        pickupSummary: ISalesSummary[]
        salesSummary: ISalesSummary[]
      }
      frequency: string
    }
  > &
  Record<
    WIDGET_LIST_ENUM.TOP_LOCATIONS,
    {
      value: ITopLocation[]
    }
  >

const initialData = {
  [WIDGET_LIST_ENUM.TOTAL_PICKUP]: {
    value: 0,
    valueTotal: 0,
    statistic: 0,
  },
  [WIDGET_LIST_ENUM.NEW_REGISTRATION]: {
    value: 0,
    statistic: 0,
  },
  [WIDGET_LIST_ENUM.TOTAL_SALES]: {
    value: 0,
    statistic: 0,
  },
  [WIDGET_LIST_ENUM.ACTIVE_AGENTS]: {
    value: 0,
    totalValue: 0,
    statistic: 0,
  },
  [WIDGET_LIST_ENUM.WALLET_TYPE]: {
    value: [] as IDashboardWalletType[],
  },
  [WIDGET_LIST_ENUM.AGENT_TYPE]: {
    value: [] as IDashboardAgentType[],
  },
  [WIDGET_LIST_ENUM.SALES_SUMMARY]: {
    value: {
      pickupSummary: [] as ISalesSummary[],
      salesSummary: [] as ISalesSummary[],
    },
    frequency: '',
  },
  [WIDGET_LIST_ENUM.TOP_LOCATIONS]: {
    value: [] as ITopLocation[],
  },
} as SalesDashboardWidgetStateType

export const SalesNetworkOverview = () => {
  const [widgetData, setWidgetData] = useState<SalesDashboardWidgetStateType>(initialData)
  const [widgetDate, setWidgetDate] = useState<Record<WIDGET_LIST_ENUM, ISelectedFilters>>(
    {} as Record<WIDGET_LIST_ENUM, ISelectedFilters>
  )
  const [topLocationsVendor, setTopLocationsVendor] = useState<number | null>(null)
  const [isLoadingTopLocations, setIsLoadingTopLocations] = useState(false)
  const [option, setOption] = useState(DASHBOARD_DATE_OPTIONS.TODAY)
  const [dateFilter, setDateFilter] = useState<string | null>(null)
  const { data: profile } = useGetProfileQuery()

  const onDateChange = (date: Moment | null) => {
    if (date) {
      setDateFilter(typeof date === 'string' ? date : date.toISOString(true))
    }
  }

  const onWidgetDateChange = (widgetName: WIDGET_LIST_ENUM) => (value: Moment[] | null) => {
    if (!Array.isArray(value)) {
      return setWidgetDate((prevState) => ({
        ...prevState,
        [widgetName]: null,
      }))
    }

    const from = !isEmpty(value[0])
      ? formatMomentDateToDayStart(
          formatShamsiMomentToISO(profile?.calendar, value[0])
        ).toISOString(true)
      : ''
    const to = !isEmpty(value[1])
      ? formatMomentDateToDayEnd(formatShamsiMomentToISO(profile?.calendar, value[1])).toISOString(
          true
        )
      : ''

    return setWidgetDate((prevState) => ({
      ...prevState,
      [widgetName]: { from, to },
    }))
  }

  const filters = useMemo(() => {
    const selectedDate = dateFilter
    if (option === DASHBOARD_DATE_OPTIONS.WEEK) {
      return {
        from: formatMomentDateToDayStart(moment(selectedDate).startOf('week'))?.toISOString(true),
        to: formatMomentDateToDayEnd(moment(selectedDate).endOf('week'))?.toISOString(true),
      }
    }
    if (option === DASHBOARD_DATE_OPTIONS.MONTH) {
      return {
        from: formatMomentDateToDayStart(moment(selectedDate).startOf('month'))?.toISOString(true),
        to: formatMomentDateToDayEnd(moment(selectedDate).endOf('month'))?.toISOString(true),
      }
    }
    if (option === DASHBOARD_DATE_OPTIONS.TODAY) {
      return {
        from: formatMomentDateToDayStart(moment(selectedDate))?.toISOString(true),
        to: formatMomentDateToDayEnd(moment(selectedDate))?.toISOString(true),
      }
    }
  }, [dateFilter, option])

  const getFrequencyName = (widgetDate: ISelectedFilters) => {
    return getFrequency(widgetDate.from as string, widgetDate.to as string)
  }

  useEffect(() => {
    if (!profile) return
    const socket = widgetsSocketConnection.getSocket()
    setIsLoadingTopLocations(true)

    const messagePayload = [
      {
        widget: WIDGET_LIST_ENUM.TOTAL_PICKUP,
        filters,
      },
      {
        widget: WIDGET_LIST_ENUM.NEW_REGISTRATION,
        filters,
      },
      {
        widget: WIDGET_LIST_ENUM.TOTAL_SALES,
        filters,
      },
      {
        widget: WIDGET_LIST_ENUM.ACTIVE_AGENTS,
        filters,
      },
      {
        widget: WIDGET_LIST_ENUM.WALLET_TYPE,
        filters: widgetDate[WIDGET_LIST_ENUM.WALLET_TYPE] ?? filters,
      },
      {
        widget: WIDGET_LIST_ENUM.AGENT_TYPE,
        filters: widgetDate[WIDGET_LIST_ENUM.AGENT_TYPE] ?? filters,
      },
      {
        widget: WIDGET_LIST_ENUM.SALES_CHANNELS,
        filters: widgetDate[WIDGET_LIST_ENUM.SALES_CHANNELS] ?? filters,
      },
      {
        widget: WIDGET_LIST_ENUM.SALES_SUMMARY,
        filters: {
          ...(widgetDate[WIDGET_LIST_ENUM.SALES_SUMMARY] ?? filters),
          frequency: getFrequencyName(widgetDate[WIDGET_LIST_ENUM.SALES_SUMMARY] ?? filters),
        },
      },
      {
        widget: WIDGET_LIST_ENUM.TOP_LOCATIONS,
        filters: {
          ...(widgetDate[WIDGET_LIST_ENUM.TOP_LOCATIONS] ?? filters),
          ...(topLocationsVendor && { vendorId: topLocationsVendor }),
        },
      },
    ]

    socket?.emit('connect-widget', { data: messagePayload })

    return () => {
      socket?.emit('disconnect-widget', { data: messagePayload })
    }
  }, [filters, profile, topLocationsVendor, widgetDate])

  useEffect(() => {
    if (!profile) return

    const socket = widgetsSocketConnection.getSocket()
    socket?.on('widget-data', (data: IWidget) => {
      const balance = JSON.parse(data?.data?.jsonData)

      setWidgetData((prevState) => ({
        ...prevState,
        [data.widget]:
          Object.keys(balance).length === 1
            ? {
                value: balance.value,
                ...(data['filters']?.frequency && { frequency: data['filters']?.frequency }),
              }
            : balance,
      }))
      setIsLoadingTopLocations(false)
    })
  }, [profile])

  useEffect(() => {
    if (profile?.timeZone) {
      moment.tz?.setDefault(profile?.timeZone as string)

      const date = formatShamsiMomentToISO(profile?.calendar, moment())
      setDateFilter(typeof date === 'string' ? date : date.toISOString(true))
    }
  }, [profile])

  return (
    <div className={styles.layout}>
      <DashboardHeader
        option={option}
        setOption={setOption}
        dateFilter={dateFilter}
        onDateChange={onDateChange}
      />
      <SalesNetworkWidgets widgetData={widgetData} />
      <div className={styles.overViewChartsWrapper}>
        <SalesSummaryBarChart
          frequency={widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY].frequency}
          data={widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY].value}
          onWidgetDateChange={onWidgetDateChange(WIDGET_LIST_ENUM.SALES_SUMMARY)}
        />
      </div>
      <TopLocationsBlock
        widgetData={widgetData[WIDGET_LIST_ENUM.TOP_LOCATIONS].value}
        onWidgetDateChange={onWidgetDateChange(WIDGET_LIST_ENUM.TOP_LOCATIONS)}
        setTopLocationsVendor={setTopLocationsVendor}
        isLoadingTopLocations={isLoadingTopLocations}
      />
      <div className={styles.chartsWrapper}>
        <AgentTypeChart
          widgetData={widgetData[WIDGET_LIST_ENUM.AGENT_TYPE].value}
          onWidgetDateChange={onWidgetDateChange(WIDGET_LIST_ENUM.AGENT_TYPE)}
        />
        <WalletTypeChart
          widgetData={widgetData[WIDGET_LIST_ENUM.WALLET_TYPE].value}
          onWidgetDateChange={onWidgetDateChange(WIDGET_LIST_ENUM.WALLET_TYPE)}
        />
      </div>
    </div>
  )
}
