import { createApi } from '@reduxjs/toolkit/query/react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

import { baseQueryWithReauth } from 'src/shared/api'
import { IFilterFields } from 'src/shared/models/IFilterFields'
import { IFileFormatCsvOrXlsx, ITableConf } from 'src/shared/models/ITableConf'
import { ITableResponse } from 'src/shared/models/ITableResponse'
import { ICurrencyRate, ICurrencyRatePost, ICurrencyRatePut } from '../../models/ICurencyRate'

export const exchangeRatesApi = createApi({
  reducerPath: 'exchangeRates',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['CurrencyRateList', 'CR', 'RateHistory', 'FilterFields'],
  endpoints: (builder) => ({
    // TODO: change next time
    createCurrencyRate: builder.mutation<ICurrencyRatePost, ICurrencyRatePost>({
      query: (body) => ({
        url: 'currency-rate',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['CurrencyRateList', 'RateHistory', 'FilterFields'],
    }),

    updateCurrencyRateById: builder.mutation<ICurrencyRatePut, { body: ICurrencyRatePut }>({
      query: ({ body }) => ({
        url: `currency-rate`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['CurrencyRateList', 'CR', 'RateHistory', 'FilterFields'],
    }),

    deleteCurrencyRateById: builder.mutation<ICurrencyRate, { id: number }>({
      query: ({ id }) => ({
        url: `currency-rate/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['CurrencyRateList', 'RateHistory', 'FilterFields'],
    }),

    getCurrencyRateList: builder.query<ITableResponse<ICurrencyRate>, ITableConf>({
      query: (params) => ({
        url: 'currency-rate',
        params,
      }),
      transformResponse: (res: ITableResponse<ICurrencyRate>) => ({
        ...res,
        items: res?.items?.map((curr) => ({
          ...curr,
          buyingRate: +(curr?.buyingRate / curr?.coefficient)?.toFixed(5),
          sellingRate: +(curr?.sellingRate / curr?.coefficient)?.toFixed(5),
        })),
      }),
      providesTags: () => ['CurrencyRateList'],
    }),

    getCurrencyRateById: builder.query<ICurrencyRate, string | undefined>({
      query: (id) => ({
        url: `currency-rate/${id}`,
      }),
      providesTags: () => ['CR'],
    }),

    getCurrencyRateToCurrency: builder.query<
      ICurrencyRate,
      { currencyId: number; toCurrencyId?: number; date?: string }
    >({
      query: (params) => ({
        url: `currency-rate/current-currency-rate`,
        params,
      }),
    }),

    getCurrencyRateHistory: builder.query<ITableResponse<ICurrencyRate>, ITableConf>({
      query: ({ currencyIn, ...params }: { currencyIn: Array<string> }) => {
        let url = 'currency-rate/history?'
        if (currencyIn) {
          currencyIn?.forEach((curr, i) => {
            if (!i) {
              url += `currency_in=${curr}`
            } else {
              url += `&currency_in=${curr}`
            }
          })
        }
        return {
          url,
          params,
        }
      },
      providesTags: () => ['RateHistory'],
    }),

    // TODO: need to check in future
    getExchangeRatesTableFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'currency-rate/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }
        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `currency-rate/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: ['FilterFields'],
    }),

    getCurrencyRateTableFileByFormatCsvOrXlsx: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx' }
    >({
      query: ({ conf, formatFile }) => ({
        url: `currency-rate/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
})

export const {
  useUpdateCurrencyRateByIdMutation,
  useCreateCurrencyRateMutation,
  useGetCurrencyRateListQuery,
  useGetExchangeRatesTableFiltersListQuery,
  useLazyGetCurrencyRateTableFileByFormatCsvOrXlsxQuery,
  useGetCurrencyRateByIdQuery,
  useLazyGetCurrencyRateToCurrencyQuery,
  useGetCurrencyRateHistoryQuery,
} = exchangeRatesApi
