import { ReactNode } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { formatNumberToLocale } from '../../../features/salesNetwork/helpers'
import {
  LineChartDownIcon,
  LineChartUpIcon,
  TrendingArrowDownIcon,
  TrendingArrowUpIcon,
} from '../../../assets/svg'
import { useSpring, animated } from 'react-spring'
import { RecursivelyReplaceCharacters } from '../privateMode'
import { useLocalStorageGetByKey } from '../../hooks/useLocalStorageGetByKey'

interface IProps {
  icon: ReactNode
  title: string
  value: string | number
  statistic?: number
  withHeaderLine?: boolean
  valueTotal?: string | number
  width?: number
  color?: 'blue' | 'darkBlue' | 'orange'
  valueColor?: 'green' | 'red' | 'white'
}

export const Widget = ({
  icon,
  title,
  value,
  valueTotal,
  statistic,
  width,
  withHeaderLine,
  color = 'blue',
  valueColor = 'white',
}: IProps) => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const isPositiveStatistic = statistic && statistic > 0
  const numberProps = useSpring({ val: Number(value), from: { val: 0 } })

  return (
    <div
      className={classNames(styles.widgetWrapper, { [styles[color]]: color })}
      style={{ flexBasis: `${width}%` }}
    >
      <div className={styles.iconWrapper}>{icon}</div>
      <div className={styles.widgetTitle}>
        <span>{title}</span>
        <>
          {withHeaderLine ? (
            isPositiveStatistic ? (
              <LineChartUpIcon />
            ) : (
              <LineChartDownIcon />
            )
          ) : null}
        </>
      </div>
      <div className={classNames(styles.value, { [styles[valueColor]]: valueColor })}>
        {isPrivateMode ? (
          <RecursivelyReplaceCharacters>
            {formatNumberToLocale(Math.floor(Number(value)))}
          </RecursivelyReplaceCharacters>
        ) : (
          <animated.span>
            {numberProps.val.to((val) => formatNumberToLocale(Math.floor(val)))}
          </animated.span>
        )}
        {valueTotal ? (
          <RecursivelyReplaceCharacters>
            <span className={styles.valueTotal}>/{formatNumberToLocale(valueTotal)}</span>
          </RecursivelyReplaceCharacters>
        ) : null}
      </div>
      {statistic ? (
        <RecursivelyReplaceCharacters>
          <div className={styles.footerStatistic}>
            <span>{isPositiveStatistic ? <TrendingArrowUpIcon /> : <TrendingArrowDownIcon />}</span>
            <span className={styles.statisticValue}>
              {isPositiveStatistic ? `+${statistic}` : statistic}%
            </span>
          </div>
        </RecursivelyReplaceCharacters>
      ) : null}
    </div>
  )
}
