import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { omit } from 'lodash'
import { Col, Form, Row, Space, Spin } from 'antd'
import { RcFile } from 'antd/es/upload'

import { DetailInfo } from '../Components/DetailInfo'
import { ContactInfo } from '../Components/ContactInfo'
import { Address } from '../Components/Address'
import { PasswordBlock } from '../Components/Password'
import { Button } from '../../../../../../../../shared/components/button/Button'
import WithPermissionDeniedContainer from '../../../../../../../../shared/components/withPermissionDenied'
import { UploaderPhotos2 } from '../../../../../../../../shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'

import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../shared/hooks/useNotification'
import usePermissions from '../../../../../../../../shared/hooks/usePermissions'
import { useCreateUserMutation } from '../../../../../../core/http/UsersManagementApi'
import { ErrorNode } from '../../../../../../../../shared/api/errorHandler'
import { IUserCreate, IUserDetail } from '../../../../../../models/IUser'
import { IPermissionsActions, IPermissionsNames } from '../../../../../../models/IPermissions'

import styles from '../UsersManagementView/styles.module.scss'
import { SETTINGS_ENUM } from '../../../../../../../../routes/settings'
import { externalChatSocketConnection } from '../../../../../../../../shared/sockets'
import { getSavedAuthData } from '../../../../../../../../shared/api'

export const UserNew = () => {
  const navigate = useNavigate()
  const token = getSavedAuthData()?.tokens?.accessToken

  const [form] = Form.useForm()

  const [picture, setPicture] = useState<RcFile>()

  const [crUser, crUserResp] = useCreateUserMutation()

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const [usersId] = getModulesIdsByNames([IPermissionsNames.Users])
  const [canAddUser] = canPerformAction(usersId, [IPermissionsActions.ADD])

  /** Notifications */
  const afterCreate = () => {
    if (crUserResp.data) {
      externalChatSocketConnection.connect(token)
      navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT}/view/${crUserResp.data.id}`)
    }
  }

  useNotification(NOTIFICATION_TYPES.success, crUserResp.isSuccess, null, afterCreate)
  useNotification(NOTIFICATION_TYPES.error, crUserResp.isError, crUserResp.error as ErrorNode)

  const onFinish = (values: IUserDetail) => {
    const obj = omit(values, [
      'department',
      'userPosition',
      'userRole',
      'nativeLanguage',
      'confirmPassword',
      'logo',
    ]) as IUserCreate

    if (!values.addressLine2) {
      delete obj.addressLine2
    }

    obj.departmentId = values?.department?.id
    obj.nativeLanguageId = values?.nativeLanguage?.id
    obj.userRoleId = values?.userRole?.id
    obj.userPositionId = +values?.userPosition?.id

    const userFd = new FormData()
    userFd.append('user', JSON.stringify(obj))

    if (picture) {
      userFd.append('logo', picture!)
    }

    crUser(userFd)
  }
  const onCancelButtonClick = () => {
    navigate(SETTINGS_ENUM.USERS_MANAGEMENT)
  }

  const content = (
    <WithPermissionDeniedContainer isAllowedView={canAddUser}>
      <div className={styles.layout}>
        <Form
          form={form}
          layout='vertical'
          className={styles.columnWrapper}
          onFinish={onFinish}
          autoComplete='off'
        >
          <div className={styles.leftColumn}>
            <DetailInfo />
            <ContactInfo form={form} />
            <Address form={form} />
            <PasswordBlock isEditMode={false} />
          </div>

          <div>
            <UploaderPhotos2
              form={form}
              view={false}
              title='Profile Picture'
              onChange={(photo) => setPicture(photo[0].originFileObj as RcFile)}
            />
          </div>
        </Form>
      </div>
      <div className={styles.footerWrapper}>
        <Row className={styles.footerStyle} justify='end'>
          <Col>
            <Space size={10}>
              <div className='cancelButtonWrapper'>
                <Button
                  color='blue'
                  size='middle'
                  onClick={onCancelButtonClick}
                  disabled={crUserResp.isLoading}
                  block
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  htmlType='submit'
                  size='middle'
                  type='primary'
                  onClick={form.submit}
                  disabled={crUserResp.isLoading}
                  block
                >
                  Create User
                </Button>
              </div>
            </Space>
          </Col>
        </Row>
      </div>
    </WithPermissionDeniedContainer>
  )

  return crUserResp.isLoading ? <Spin>{content}</Spin> : content
}
