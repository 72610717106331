import React, { memo } from 'react'
import styles from './styles.module.scss'

interface IProps {
  unreadMessagesCount: number
}

const UnreadCountBadge = ({ unreadMessagesCount }: IProps) => {
  return <div className={styles.unreadCount}>{unreadMessagesCount}</div>
}
export default memo(UnreadCountBadge)
