import { Wrapper } from 'src/features/salesNetwork/components/Wrapper'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import styles from '../styles.module.scss'
import React, { useMemo } from 'react'
import { useGetListOfCurrenciesQuery } from '../../../../../../Finance/core/http/BankAccountApi'
import { Form, Row, Spin } from 'antd'
import FormList from 'antd/es/form/FormList'
import classNames from 'classnames'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon } from '../../../../../../../assets/svg'
import { useGetBankListQuery } from '../../../../../../Finance/core/http/BankApi'
import { BankAccountListItem } from '../../../../AgentManagementDetailView/FinancialInformationTab/BankAccountsBlock/BankAccountListItem'

export const BankAccountDetailBlock = ({ view }: IViewMode) => {
  const { data: currencies, isFetching: isCurrencyFetching } = useGetListOfCurrenciesQuery()
  const { data: banks, isFetching: isFetchingBanks } = useGetBankListQuery({})

  const currenciesMap = useMemo(
    () =>
      currencies?.items.map((currency) => ({
        label: currency.name,
        value: currency.id,
      })),
    [currencies?.items]
  )

  const content = (
    <Wrapper title='Bank Accounts'>
      <FormList name='bankAccounts'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name, key }) => (
              <BankAccountListItem
                key={key}
                name={name}
                remove={remove}
                banks={banks}
                view={view}
                currenciesMap={currenciesMap}
              />
            ))}
            {!view && (
              <Row>
                <Form.Item className={classNames('noBottomMargin', styles.addButton)}>
                  <IconButton icon={<AddIcon />} color='orange' block onClick={() => add()} />
                </Form.Item>
              </Row>
            )}
          </>
        )}
      </FormList>
    </Wrapper>
  )

  return isCurrencyFetching || isFetchingBanks ? <Spin>{content}</Spin> : content
}
