import { Dropdown, MenuProps, Tooltip } from 'antd'
import styles from './styles.module.scss'
import emptyAvatar from '../../../../../../../../assets/img/Avatar.png'
import {
  IExternalChat,
  IExternalChatMember,
  MESSENGER_TYPE_ENUM,
} from '../../../../../../models/IExternalChat'
import { CloseIcon, WhatsappIcon } from '../../../../../../../../assets/svg'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { updateExternalChatTypingStatus } from '../../../../../../core/store/ExternalChatChatsListSlice'
import { useAppDispatch } from '../../../../../../../../redux'
import classNames from 'classnames'
import { showConfirmMessage } from '../../../../../../../../shared/api/errorHandler'

interface IProps {
  externalChat: IExternalChat | null
  selectedChatId: number | null
  currentUserId: null | number
  handleRemoveUserFromChat: (chatId: number, userId: number) => void
}

export const HeaderWithInfo = ({
  externalChat,
  selectedChatId,
  currentUserId,
  handleRemoveUserFromChat,
}: IProps) => {
  const dispatch = useAppDispatch()
  const [numDots, setNumDots] = useState(1)

  const membersCount = useMemo(() => {
    const membersLength = externalChat?.members?.length
    if (!membersLength) return
    if (membersLength === 1) return '1 Member'
    if (membersLength > 1) return `${membersLength} Members`
  }, [externalChat?.members?.length])

  const findTypingMember = useCallback(
    (userId: number | null | undefined) => {
      if (!userId) return

      const member = externalChat?.members?.find(
        (member) => member.userId !== currentUserId && member.userId === userId
      )?.user

      if (!member) return

      return `${member?.firstName} ${member?.lastName}`
    },
    [currentUserId, externalChat?.members]
  )

  useEffect(() => {
    if (externalChat?.typingUser) {
      const interval = setInterval(() => {
        setNumDots((prev) => (prev < 3 ? prev + 1 : 1))
      }, 500)

      return () => clearInterval(interval)
    }
  }, [externalChat?.typingUser])

  useEffect(() => {
    if (externalChat?.typingUser) {
      const timeout = setTimeout(() => {
        dispatch(
          updateExternalChatTypingStatus({
            status: false,
            chatId: selectedChatId as number,
            userId: null,
          })
        )
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [dispatch, externalChat?.typingUser, selectedChatId])

  const chatUserList: MenuProps['items'] = useMemo(
    () =>
      externalChat?.members?.map(({ user }) => ({
        key: `connect-user-to-chat-${user?.id}`,
        label: (
          <div className={styles.dropdownItem} onClick={(e) => e.stopPropagation()}>
            <div>{`${user?.firstName} ${user?.lastName}`}</div>
            {currentUserId !== user?.id && (
              <div
                className={styles.dropdownItemCloseIcon}
                onClick={() => {
                  const confirmMessage = `${user?.firstName} ${user?.lastName} will be removed from the conversation`
                  showConfirmMessage(confirmMessage, () =>
                    handleRemoveUserFromChat(externalChat?.id as number, user?.id as number)
                  )
                }}
              >
                <CloseIcon />
              </div>
            )}
          </div>
        ),
      })),
    [currentUserId, externalChat?.id, externalChat?.members, handleRemoveUserFromChat]
  )

  return (
    <>
      <Tooltip
        title={externalChat?.messengerProfileName}
        placement='topRight'
        className={styles.chatAvatar}
      >
        <img src={emptyAvatar} alt={externalChat?.messengerProfileName || 'external-chat-image'} />
        <span className={styles.userMessengerType}>
          {externalChat?.messengerType === MESSENGER_TYPE_ENUM.WHATSAPP && <WhatsappIcon />}
        </span>
      </Tooltip>
      <div className={styles.chatInfo}>
        <div className={styles.chatName}>{externalChat?.messengerProfileName}</div>
        <div className={styles.chatStatus}>
          <Dropdown
            trigger={['hover']}
            menu={{ items: chatUserList }}
            className={classNames(styles.contextMenuContainer, styles.withoutBackground)}
            overlayClassName={styles.overlay}
          >
            <span>{membersCount}</span>
          </Dropdown>
          {findTypingMember(externalChat?.typingUser?.userId) &&
            `, ${findTypingMember(externalChat?.typingUser?.userId)} is typing${'.'.repeat(
              numDots
            )}`}
        </div>
      </div>
    </>
  )
}
