import React, { memo } from 'react'
import { PermissionsAllInModuleCheckbox, PermissionsModuleCheckbox } from '../index'
import { IPermissionCreate, IRolePermissionModule } from '../../../../../../models/IRoles'

import styles from '../../styles.module.scss'

interface IProps {
  isViewMode?: boolean
  isSubModule?: boolean
  actions: Array<string>
  module: IRolePermissionModule
  selectedPermissions: Array<IPermissionCreate>
  onSelectAllModulePermissions: (module: IRolePermissionModule, status: boolean) => void
  onCheckPermission: (permission: IPermissionCreate) => void
}

const PermissionModule = ({
  isViewMode,
  isSubModule,
  actions,
  module,
  selectedPermissions,
  onSelectAllModulePermissions,
  onCheckPermission,
}: IProps) => {
  return (
    <div className={styles.actionsPanelHeader} style={{ marginLeft: 40 }}>
      <div className={`${!isSubModule && styles.simpleModule} ${styles.sumModuleName}`}>
        {module.module}
      </div>
      <div className={styles.actionsContainer} style={{ paddingLeft: 16 }}>
        <PermissionsAllInModuleCheckbox
          disabled={isViewMode}
          selectedPermissions={selectedPermissions}
          module={module}
          onSelectAllModulePermissions={onSelectAllModulePermissions}
        />
        {actions.map((action) => (
          <PermissionsModuleCheckbox
            key={`simple-module-${module.id}-${action}`}
            disabled={isViewMode}
            action={action}
            moduleId={module.id}
            permissionModuleAllowedPermissions={module.permissionModuleAllowedPermissions}
            selectedPermissions={selectedPermissions}
            onCheckPermission={onCheckPermission}
          />
        ))}
        <div className={styles.actionContainer}></div>
      </div>
    </div>
  )
}

export default memo(PermissionModule)
