export const calendars: Record<string, string> = {
  gregorian: 'GREGORIAN',
  shamsi: 'SHAMSI',
}

export const formatCalendars = Object.values(calendars).map((value, index) => ({
  id: index,
  name: value,
}))

export enum ECalendarType {
  GREGORIAN = 'GREGORIAN',
  SHAMSI = 'SHAMSI',
}
