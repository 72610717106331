import React from 'react'
import { Form } from 'antd'
import classNames from 'classnames'
import { TextField } from 'src/shared/components/textfield/TextField'
import TransactionStatus from 'src/shared/components/transactionStatus'
import { Wrapper } from '../../../../../../../salesNetwork/components/Wrapper'
import styles from '../../styles.module.scss'

interface IProps {
  isReverse?: boolean
  isCreatedStatus?: boolean
  status?: string
}

export const Details = ({ isReverse, isCreatedStatus, status }: IProps) => {
  return (
    <Wrapper
      title='Transaction Details'
      statusButton={status && <TransactionStatus status={status} />}
    >
      <div>
        <Form.Item name={isReverse ? 'topupId' : 'id'} label='Transaction ID'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div>
        <Form.Item name='createdAt' label='Date & Time'>
          <TextField disabled />
        </Form.Item>
      </div>

      {/*<div>*/}
      {/*  <Form.Item name={['agent', 'name']} label='Agent Name'>*/}
      {/*    <TextField disabled />*/}
      {/*  </Form.Item>*/}
      {/*</div>*/}

      {/*<div className={styles.dblRow}>*/}
      {/*  <Form.Item name={['agent', 'id']} label='Agent ID'>*/}
      {/*    <TextField disabled prefix='ID' />*/}
      {/*  </Form.Item>*/}

      {/*  <Form.Item name='location' label='Location'>*/}
      {/*    <TextField disabled />*/}
      {/*  </Form.Item>*/}
      {/*</div>*/}

      <div>
        <Form.Item name={['product', 'name']} label='Product Name'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div>
        <Form.Item name='originalTopupAmount' label='Topup Amount'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div>
        <Form.Item name='receiver' label='Customer Number'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div>
        <Form.Item name='aminPayResponseCode' label='AminPay Response Code'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div>
        <Form.Item
          name='aminPayResponseMessage'
          label='AminPay Response Message'
          className={classNames({ [styles.noBottomMargin]: !isReverse })}
        >
          <TextField disabled />
        </Form.Item>
      </div>

      {isReverse && (
        <React.Fragment>
          <div>
            <Form.Item name='vendorTransactionId' label='Vendor Transaction ID'>
              <TextField disabled={!isCreatedStatus} />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              name='vendorResponseMessage'
              label='Vendor Response'
              className={styles.noBottomMargin}
            >
              <TextField disabled={!isCreatedStatus} />
            </Form.Item>
          </div>
        </React.Fragment>
      )}
    </Wrapper>
  )
}
