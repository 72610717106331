import emptyUserImage from 'src/assets/img/Avatar.png'
import styles from './styles.module.scss'
import {
  EXTERNAL_CHAT_LIST_TABS,
  IExternalChatTable,
  IUpdateExternalChatMember,
  MESSAGE_TYPE_ENUM,
  MESSENGER_TYPE_ENUM,
} from '../../../../../../models/IExternalChat'
import {
  ArchiveChatIcon,
  ArrowLeftIcon,
  ChatDotsIcon,
  Pin,
  UnArchiveChatIcon,
  UnpinMessageIcon,
  WhatsappIcon,
} from '../../../../../../../../assets/svg'
import { IsReadMessageMark } from '../../../../../../../../shared/components/chat/Components'
import moment from 'moment/moment'
import React, { memo, useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { UserLastMessage } from '../UserLastMessage'
import { Badge } from '../../../../../../../../shared/components/badge/Badge'
import { IPopupListItems, Popup } from '../../../../../../../../shared/components/popup/Popup'
import { showConfirmMessage } from '../../../../../../../../shared/api/errorHandler'

interface IProps {
  user: IExternalChatTable
  updateChatMember: (body: Partial<IUpdateExternalChatMember>) => void
  archiveExtChat: (chatId: number, isArchived: boolean) => void
  onChatItemClick: (id: number) => void
  currentUserId: number | null
  isSelected: boolean
  activeTab: EXTERNAL_CHAT_LIST_TABS
  hasSession: boolean
  isChatDayTheSame?: boolean
  removeExtChatMemb: () => void
  isUserInChat: boolean
}

const UserListItem = ({
  user,
  updateChatMember,
  onChatItemClick,
  isSelected,
  archiveExtChat,
  currentUserId,
  activeTab,
  hasSession,
  isChatDayTheSame,
  removeExtChatMemb,
  isUserInChat,
}: IProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const tableActionsPopup = useCallback((): IPopupListItems[] => {
    const obj = {
      chatId: user?.id,
    }
    return [
      ...(activeTab === EXTERNAL_CHAT_LIST_TABS.MY
        ? [
            {
              text: user?.member?.isPinned ? 'Unpin' : 'Pin',
              shouldDisplay: true,
              icon: user?.member?.isPinned ? <UnpinMessageIcon /> : <Pin color='#0193EB' />,
              onClick: () => {
                const body = { ...obj, isPinned: !user?.member?.isPinned }
                updateChatMember(body)
              },
            },
          ]
        : []),
      ...(hasSession
        ? [
            {
              text: user?.isArchived ? 'Unarchive Chat' : 'Archive Chat',
              shouldDisplay: true,
              icon: <ArchiveChatIcon />,
              onClick: () => {
                const confirmMessage = user?.isArchived
                  ? 'This chat will be unarchived'
                  : 'This chat will be archived'
                showConfirmMessage(confirmMessage, () =>
                  archiveExtChat(user?.id, !user?.isArchived)
                )
              },
            },
          ]
        : []),
      ...(isUserInChat
        ? [
            {
              text: 'Leave Conversation',
              shouldDisplay: true,
              icon: <ArrowLeftIcon />,
              onClick: () => {
                const confirmMessage = 'You will leave this conversation'
                showConfirmMessage(confirmMessage, removeExtChatMemb)
              },
            },
          ]
        : []),
    ]
  }, [
    activeTab,
    archiveExtChat,
    hasSession,
    isUserInChat,
    removeExtChatMemb,
    updateChatMember,
    user?.id,
    user?.isArchived,
    user?.member?.isPinned,
  ])

  const isLastMessageMy = useMemo(() => {
    return user?.lastExternalChatMessage?.createdByUserId === currentUserId
  }, [currentUserId, user?.lastExternalChatMessage?.createdByUserId])

  const isLastMessageRead = useMemo(() => {
    if (user?.lastExternalChatMessage && user?.lastReadingAt) {
      return user?.lastReadingAt
        ? moment(user?.lastReadingAt).isAfter(user.lastExternalChatMessage?.createdAt) ||
            moment(user?.lastReadingAt).isSame(moment(user.lastExternalChatMessage?.createdAt))
        : false
    }
    return false
  }, [user])

  const handleMouseOver = (e: React.MouseEvent<HTMLDivElement>, state: boolean) => {
    e.stopPropagation()
    setIsHovered(state)
  }

  const handleChatClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onChatItemClick(user.id)
  }

  const renderMessageDate = useMemo(() => {
    const messageDate = user?.lastExternalChatMessage?.createdAt ?? user?.createdAt

    return <span>{moment(messageDate).format('HH:mm')}</span>
  }, [user?.createdAt, user?.lastExternalChatMessage?.createdAt])

  return (
    <div
      className={classNames(styles.userListItem, {
        [styles.isSelected]: isSelected,
        [styles.sameDay]: !isChatDayTheSame,
      })}
      onMouseEnter={(e) => handleMouseOver(e, true)}
      onMouseLeave={(e) => handleMouseOver(e, false)}
      onClick={handleChatClick}
    >
      <div className={styles.userImage}>
        <img alt='user-logo' src={emptyUserImage} loading='lazy' />
        <span className={styles.userMessengerType}>
          {user?.messengerType === MESSENGER_TYPE_ENUM.WHATSAPP && <WhatsappIcon />}
        </span>
      </div>
      <div className={styles.userText}>
        <div className={styles.userName}>{user?.messengerProfileName}</div>
        <UserLastMessage
          typing={user?.typing}
          lastMessage={user?.lastExternalChatMessage}
          chatId={user?.id}
          userId={user?.member?.userId}
        />
      </div>
      <div
        className={classNames(styles.externalMessageInfo, {
          [styles.actionVisible]: isHovered && isSelected,
          [styles.actionsAtTheTop]:
            activeTab === EXTERNAL_CHAT_LIST_TABS.MY &&
            user?.member?.isPinned &&
            !moment(user?.lastExternalChatMessage?.createdAt).isSame(moment(), 'day'),
        })}
      >
        {isHovered && isSelected ? (
          <Popup data={tableActionsPopup()}>
            <div className={classNames(styles.messageAction, 'table-kebab-actions')}>
              <ChatDotsIcon />
            </div>
          </Popup>
        ) : (
          <>
            <div className={styles.messageStatus}>
              {user?.unreadedMessagesCount > 0 ? (
                <Badge color='orange' count={user.unreadedMessagesCount} />
              ) : (
                isLastMessageMy &&
                user?.lastExternalChatMessage.messageType !== MESSAGE_TYPE_ENUM.PRIVATE_NOTE && (
                  <IsReadMessageMark isRead={isLastMessageRead} />
                )
              )}
              <div className={styles.lastMessageTime}>{renderMessageDate}</div>
            </div>
            {activeTab === EXTERNAL_CHAT_LIST_TABS.MY && user?.member?.isPinned && (
              <div className={styles.pinIcon}>
                <Pin />
              </div>
            )}
          </>
        )}
      </div>
      <div className={styles.messageBottomDate}>
        {moment(user?.lastExternalChatMessage?.createdAt).format('DD/MM/YYYY')}
      </div>
    </div>
  )
}

export default memo(UserListItem)
