import { IByUser, IUserAvatar } from '../../../shared/models/IByUser'
import { IPolicyMasterProduct } from './IDiscountPolicy'
import { IAgentTypeAgent } from './IAgentType'
import { TRANSACTION_STATUS_ENUM } from '../../Finance/models/ITransaction'
import { Moment } from 'moment'

export enum PROMOTION_POLICY_EVENT_ENUM {
  TOPUP = 'TOPUP',
  PICKUP = 'PICKUP',
  REACTIVATION = 'REACTIVATION',
  REGISTRATION = 'REGISTRATION',
  WALLET_BALANCE = 'WALLET_BALANCE',
  IDENTIFIER_VERIFICATION = 'IDENTIFIER_VERIFICATION',
  ACCESS_CHANNEL = 'ACCESS_CHANNEL',
}

export enum PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM {
  FLAT_RATE = 'FLAT_RATE',
  PERCENTAGE = 'PERCENTAGE',
}

export type PromotionPolicyCreateType = Omit<IPromotionPolicy, 'id' | 'createdAt'>

export interface IPromotionPolicyProduct {
  id: number
  rate: number
  from: number
  min: number
  to: number
  max: number
  status?: boolean
  masterProduct: IPolicyMasterProduct
}

export interface IPickupCondition {
  rate: number
  from: number
  to: number
  status?: boolean
}

export interface IPromotionPolicy {
  id: number
  name: string
  isEditable: boolean
  pickUpConditions: Array<IPickupCondition>
  event: PROMOTION_POLICY_EVENT_ENUM
  measurementType: PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM
  promotionPolicyProducts: Array<IPromotionPolicyProduct>
  targetGroupAgents: Array<IAgentTypeAgent>
  targetGroupAgentsCount: string
  promotionPolicyProductsTotalCount: number
  amount: number
  start: Date | string | Moment
  end: Date | string | Moment
  isOnce: boolean
  pickupFrom: number
  pickupTo: number
  reactivationInterval: number
  walletAboveAmount: number
  status: boolean
  createdByUser: IByUser
  createdAt: Date
  accessChannels: Array<string>
}

export interface IAgentPromotion {
  id: number
  status: TRANSACTION_STATUS_ENUM
  description: string
  createdByUser: IByUser
  agent: IUserAvatar
  promotionPolicy: Omit<IByUser, 'logo'>
  amount: number
  balanceBefore: number
  balanceAfter: number
}
