import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { saveAs } from 'file-saver'
import { Emitter, EmitterEvents } from 'src/helpers/eventEmitter'
import { useEffect } from 'react'
import { ISelectedFilters } from 'src/shared/components/filter/Filter'

import { IFileFormatCsvOrXlsx, ITableConf } from 'src/shared/models/ITableConf'

interface IUseTableExport {
  tableConf: ITableConf
  filterValues?: ISelectedFilters
  // eslint-disable-next-line
  promise: LazyQueryTrigger<any>
  promiseParams?: Record<string, string | number>
}

export enum TableExportFormatEnum {
  csv = 'csv',
  xlsx = 'xlsx',
}

export const useTableExport = ({
  tableConf,
  filterValues,
  promise,
  promiseParams = {},
}: IUseTableExport) => {
  // eslint-disable-next-line
  const handler = async (conf: IFileFormatCsvOrXlsx, promise: LazyQueryTrigger<any>) => {
    const exportData = await promise({ conf, formatFile: conf.format, ...promiseParams })

    const blob = new Blob([exportData.data as BlobPart], {
      type: `text/${conf.format}; charset=utf-8`,
    })

    const fileName =
      `${exportData.endpointName}-` + Object.values({ ...tableConf, ...filterValues })

    saveAs(blob, `${fileName}.${conf.format}`)
  }

  useEffect(() => {
    Emitter.on(EmitterEvents.EXPORT_TABLE, async (format: keyof typeof TableExportFormatEnum) => {
      const conf = {
        ...tableConf,
        ...filterValues,
        ...promiseParams,
        format,
      }
      handler(conf, promise)
    })

    return () => {
      Emitter.off(EmitterEvents.EXPORT_TABLE)
    }
  }, [tableConf, filterValues, promise, promiseParams, handler])
}
