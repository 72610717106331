import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import FormList from 'antd/es/form/FormList'
import { Form, Spin } from 'antd'
import React, { useEffect } from 'react'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { AddIcon } from '../../../../../../assets/svg'
import styles from '../../styles.module.scss'
import {
  useGetMasterProductsListQuery,
  useGetVendorListQuery,
  useLazyGetProductsListQuery,
} from '../../../../core/http/VendorManagementApi'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../shared/api/errorHandler'
import { FormInstance } from 'antd/es/form/Form'
import { MASTER_PRODUCT_TYPE_ENUM } from '../../../../models/IProduct'
import { MasterProductListItem } from './MasterProductListItem'

interface IProps extends IViewMode {
  form: FormInstance
  masterProductId?: string
}

export const Products = ({ view, form, masterProductId }: IProps) => {
  const { data, isFetching } = useGetVendorListQuery({})
  const { data: masterProductsList, isFetching: isFetchingMasterProd } =
    useGetMasterProductsListQuery({})
  const [getProducts, getProductsResp] = useLazyGetProductsListQuery()

  useNotification(
    NOTIFICATION_TYPES.error,
    getProductsResp.isError,
    getProductsResp.error as ErrorNode
  )

  useEffect(() => {
    if (data) {
      getProducts({})
    }
  }, [data, getProducts])

  const content = (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const type = getFieldValue('type')
        const isMasterBundle = type === MASTER_PRODUCT_TYPE_ENUM.MASTER_BUNDLE_TOPUP
        return (
          <Wrapper
            title={isMasterBundle ? 'Bundle Topups' : 'Products'}
            className={styles.masterProductProducts}
          >
            <FormList name={isMasterBundle ? 'masterBundleItems' : 'products'}>
              {(fields, { add, remove }) => (
                <div>
                  {fields.map(({ key, name }) => {
                    const vendorId = getFieldValue([
                      isMasterBundle ? 'masterBundleItems' : 'products',
                      name,
                      'vendor',
                      'id',
                    ])
                    return (
                      <MasterProductListItem
                        key={key}
                        masterProductsList={masterProductsList}
                        vendorsList={data}
                        isMasterBundle={isMasterBundle}
                        name={name}
                        masterProductId={masterProductId}
                        remove={remove}
                        type={type}
                        view={view}
                        vendorId={vendorId}
                      />
                    )
                  })}
                  {!view && (
                    <div style={{ display: 'flex' }}>
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                          const type = getFieldValue('type')
                          return (
                            <Form.Item
                              style={{ flexBasis: '35%' }}
                              className={styles.noBottomMargin}
                            >
                              <IconButton
                                icon={<AddIcon />}
                                color='orange'
                                block
                                onClick={() => add()}
                                disabled={!type}
                              />
                            </Form.Item>
                          )
                        }}
                      </Form.Item>
                    </div>
                  )}
                </div>
              )}
            </FormList>
          </Wrapper>
        )
      }}
    </Form.Item>
  )

  return isFetching || isFetchingMasterProd ? <Spin>{content}</Spin> : content
}
