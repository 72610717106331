import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import {
  IInternalChat,
  IInternalChatMessage,
  IInternalForwarderChatMessage,
  IUnreadInternalMessage,
} from '../../models'
import { IChatMessagesConf } from '../../Components/Messaging/Components/Messages'
import { ITableConf } from '../../../../shared/models/ITableConf'

export const internalMessagingApi = createApi({
  reducerPath: 'internalMessagingApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['IInternalChat', 'IInternalChatMessage', 'UnreadMessages'],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    sendLastReadMessage: builder.mutation<
      ITableResponse<IInternalChat>,
      { toUserId: number; lastReadingAt?: Date | string }
    >({
      query: (body) => ({
        url: `internal-chat-message-readings`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['IInternalChat'],
    }),

    createNewTextMessage: builder.mutation<IInternalChatMessage, FormData>({
      query: (body) => ({
        url: `internal-chat-messages`,
        method: 'POST',
        body,
      }),
    }),

    forwardTextMessage: builder.mutation<
      IInternalForwarderChatMessage,
      { toUserId: number; messageId: number }
    >({
      query: (body) => ({
        url: `internal-chat-messages/forward`,
        method: 'POST',
        body,
      }),
    }),

    getBeforeLastReadMessagesByInternalChatId: builder.query<
      ITableResponse<IInternalChatMessage>,
      IChatMessagesConf
    >({
      query: ({ openedChatId, ...rest }: IChatMessagesConf) => ({
        url: `internal-chat-messages/${openedChatId}`,
        params: rest,
      }),
      providesTags: (result, error, { openedChatId }) => {
        return [{ type: 'IInternalChatMessage', openedChatId }]
      },
      keepUnusedDataFor: 0,
    }),

    getAfterLastReadMessagesByInternalChatId: builder.query<
      ITableResponse<IInternalChatMessage>,
      IChatMessagesConf
    >({
      query: ({ openedChatId, ...rest }: IChatMessagesConf) => ({
        url: `internal-chat-messages/${openedChatId}`,
        params: rest,
      }),
      providesTags: (result, error, { openedChatId }) => {
        return [{ type: 'IInternalChatMessage', openedChatId }]
      },
      keepUnusedDataFor: 0,
    }),

    getUnreadInternalMessagesList: builder.query<
      ITableResponse<IUnreadInternalMessage>,
      ITableConf
    >({
      query: (params) => ({
        url: 'internal-chat-messages/unread',
        params,
      }),
      providesTags: ['UnreadMessages'],
      keepUnusedDataFor: 0,
    }),
  }),
})

export const {
  useSendLastReadMessageMutation,
  useForwardTextMessageMutation,
  useCreateNewTextMessageMutation,
  useGetAfterLastReadMessagesByInternalChatIdQuery,
  useGetBeforeLastReadMessagesByInternalChatIdQuery,
  useLazyGetAfterLastReadMessagesByInternalChatIdQuery,
  useLazyGetBeforeLastReadMessagesByInternalChatIdQuery,
  useGetUnreadInternalMessagesListQuery,
} = internalMessagingApi
