import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import {
  IWhatsappManagerAction,
  IWhatsappManagerButton,
  IWhatsappManagerNotificationBtn,
} from '../../models/IWhatsappManager'
import { ITableConf } from '../../../../shared/models/ITableConf'

export const whatsAppManagerApi = createApi({
  reducerPath: 'whatsAppManager',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['NotButtons', 'NotBtn', 'WSButton', 'WSButtonList', 'ACTList'],
  endpoints: (builder) => ({
    getWhatsAppActionsList: builder.query<ITableResponse<IWhatsappManagerAction>, ITableConf>({
      query: (params) => ({
        url: 'whatsapp-manager/actions',
        params,
      }),
      providesTags: ['ACTList'],
    }),

    updateWhatsAppAction: builder.mutation<
      IWhatsappManagerAction,
      { id: string; body: Pick<IWhatsappManagerAction, 'description'> }
    >({
      query: ({ id, body }) => ({
        url: `whatsapp-manager/actions/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['ACTList'],
    }),

    getWhatsAppButtonsList: builder.query<ITableResponse<IWhatsappManagerButton>, ITableConf>({
      query: (params) => ({
        url: 'whatsapp-manager/buttons',
        params,
      }),
      providesTags: ['WSButtonList'],
    }),

    createWhatsAppButton: builder.mutation<
      IWhatsappManagerButton,
      Omit<IWhatsappManagerButton, 'id'>
    >({
      query: (body) => ({
        url: 'whatsapp-manager/buttons',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['WSButtonList'],
    }),

    getWhatsAppButtonById: builder.query<IWhatsappManagerButton, string>({
      query: (id) => ({
        url: `whatsapp-manager/buttons/${id}`,
      }),
      providesTags: (res) => [{ type: 'WSButton', id: res?.id }],
    }),

    updateWhatsAppButton: builder.mutation<
      IWhatsappManagerButton,
      { id: string; body: IWhatsappManagerButton }
    >({
      query: ({ id, body }) => ({
        url: `whatsapp-manager/buttons/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (res) => ['NotButtons', { type: 'WSButton', id: res?.id }, 'WSButtonList'],
    }),

    deleteWhatsAppButton: builder.mutation<void, string>({
      query: (id) => ({
        url: `whatsapp-manager/buttons/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['NotButtons', 'WSButtonList'],
    }),

    getWhatsAppNotificationButtonsList: builder.query<
      ITableResponse<IWhatsappManagerNotificationBtn>,
      ITableConf
    >({
      query: (params) => ({
        url: `whatsapp-manager/notification-buttons`,
        params,
      }),
      providesTags: ['NotButtons'],
    }),

    getWhatsAppNotificationButtonById: builder.query<IWhatsappManagerNotificationBtn, string>({
      query: (id) => ({
        url: `whatsapp-manager/notification-buttons/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'NotBtn', id: arg }],
    }),

    updateWhatsAppNotificationButton: builder.mutation<
      IWhatsappManagerNotificationBtn,
      { id: string; body: { buttonIds: Array<number> } }
    >({
      query: ({ id, body }) => ({
        url: `whatsapp-manager/notification-buttons/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result) => ['NotButtons', { type: 'NotBtn', id: result?.id }],
    }),
  }),
})

export const {
  useGetWhatsAppActionsListQuery,
  useUpdateWhatsAppActionMutation,
  useGetWhatsAppNotificationButtonsListQuery,
  useGetWhatsAppButtonsListQuery,
  useCreateWhatsAppButtonMutation,
  useUpdateWhatsAppButtonMutation,
  useGetWhatsAppButtonByIdQuery,
  useDeleteWhatsAppButtonMutation,
  useGetWhatsAppNotificationButtonByIdQuery,
  useUpdateWhatsAppNotificationButtonMutation,
} = whatsAppManagerApi
