import styles from '../styles.module.scss'
import { Form, Spin } from 'antd'
import { StepRegistration } from '../../../../../shared/components/stepRegistration'
import React, { useEffect } from 'react'
import { DetailInfoBlock } from '../PromotionPolicyView/Components/InformationTab/DetailInfoBlock'
import { ConditionsBlock } from '../PromotionPolicyView/Components/InformationTab/ConditionsBlock'
import { Footer } from '../PromotionPolicyView/Components/Footer'
import { SETTINGS_ENUM } from '../../../../../routes/settings'
import { useNavigate } from 'react-router'
import {
  IPromotionPolicy,
  PROMOTION_POLICY_EVENT_ENUM,
  PromotionPolicyCreateType,
} from '../../../models/IPromotionPolicy'
import classNames from 'classnames'
import { formatDateMomentToISO, formatDateWithTime } from '../../../../../helpers/utils'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import { useCreatePromotionPolicyMutation } from '../../../core/http/PromotionPolicyApi'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../shared/api/errorHandler'
import { Moment } from 'moment'
import FormItem from 'antd/es/form/FormItem'
import { MasterProducts } from './Components/MasterProducts'
import { PickupProducts } from './Components/PickupProducts'

export const PromotionPolicyNew = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const { data, isLoading } = useGetProfileQuery()
  const [crProm, crPromResp] = useCreatePromotionPolicyMutation()

  useNotification(NOTIFICATION_TYPES.success, crPromResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, crPromResp.isError, crPromResp.error as ErrorNode)

  const onCancelButtonClick = () => {
    return navigate(SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION)
  }

  const onFinish = (values: IPromotionPolicy) => {
    const dataObj = {
      name: values.name,
      measurementType: values.measurementType,
      event: values.event,
      status: true,
      start: formatDateMomentToISO(data?.calendar, values.start as Moment),
      ...(values?.pickUpConditions && {
        pickUpConditions: values?.pickUpConditions.map((condition) => ({
          ...condition,
          status: !!condition.status,
          from: +condition.from,
          to: +condition.to,
        })),
      }),
      ...(values?.promotionPolicyProducts && {
        promotionPolicyProducts: values.promotionPolicyProducts?.map((product) => ({
          masterProductId: product.masterProduct.id,
          status: !!product.status,
          from: +product.from,
          to: +product.to,
          rate: +product.rate,
        })),
      }),
      ...(values?.accessChannels && { accessChannels: values?.accessChannels }),
      ...(values?.walletAboveAmount && { walletAboveAmount: +values?.walletAboveAmount }),
      ...(values?.amount && { amount: +values.amount }),
      isOnce: !!values.isOnce,
      ...(values?.reactivationInterval && { reactivationInterval: +values.reactivationInterval }),
      end: formatDateMomentToISO(data?.calendar, values.end as Moment),
    } as unknown as PromotionPolicyCreateType

    crProm(dataObj)
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        createdAt: formatDateWithTime(new Date(), data?.calendar),
        createdByUser: { name: `${data?.firstName} ${data?.lastName}` },
      })
    }
  }, [data, form])

  useEffect(() => {
    if (crPromResp?.data) {
      return navigate(
        `${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/promotion-policy/view/${crPromResp.data?.id}`
      )
    }
    // eslint-disable-next-line
  }, [crPromResp.data])

  const content = (
    <div className={classNames(styles.layout, 'fullVH')}>
      <Form form={form} layout='vertical' onFinish={onFinish} className={styles.createForm}>
        <StepRegistration stepNumber={1} stepText='Detail Info' mT='11.5px' mB='32px' />
        <DetailInfoBlock isEditable={true} />
        <StepRegistration stepNumber={2} stepText='Conditions' mT='32px' mB='32px' />
        <ConditionsBlock isEditable={true} />
        <FormItem shouldUpdate noStyle>
          {({ getFieldValue }) => {
            if (getFieldValue('event') === PROMOTION_POLICY_EVENT_ENUM.PICKUP) {
              return (
                <>
                  <StepRegistration stepNumber={3} stepText='Products' mT='32px' mB='32px' />
                  <PickupProducts onCreatePage />
                </>
              )
            }
            if (
              getFieldValue('event') === PROMOTION_POLICY_EVENT_ENUM.TOPUP ||
              getFieldValue('event') === PROMOTION_POLICY_EVENT_ENUM.ACCESS_CHANNEL
            ) {
              return (
                <>
                  <StepRegistration stepNumber={3} stepText='Products' mT='32px' mB='32px' />
                  <MasterProducts />
                </>
              )
            }
          }}
        </FormItem>
      </Form>
      <Footer onCancelClick={onCancelButtonClick} onSubmitClick={form.submit} />
    </div>
  )

  return isLoading || crPromResp.isLoading ? <Spin>{content}</Spin> : content
}
