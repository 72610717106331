import { useGetMasterProductsListQuery } from '../../../../../core/http/VendorManagementApi'
import FormList from 'antd/es/form/FormList'
import { MasterProductListItem } from './MasterProductListItem'
import { ITableResponse } from '../../../../../../../shared/models/ITableResponse'
import { IMasterProduct } from '../../../../../models/IMasterProduct'
import styles from '../../../styles.module.scss'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon } from '../../../../../../../assets/svg'
import React from 'react'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import { Spin } from 'antd'

interface IProps extends IViewMode {
  onViewPage?: boolean
}

export const MasterProducts = ({ view, onViewPage }: IProps) => {
  const { data, isFetching } = useGetMasterProductsListQuery({})

  const content = (
    <FormList name='masterProducts'>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ name, key }) => (
            <MasterProductListItem
              key={key}
              name={name}
              data={data as ITableResponse<IMasterProduct>}
              remove={remove}
              view={view}
              onViewPage={onViewPage}
            />
          ))}
          <div className={styles.addButton}>
            <IconButton
              icon={<AddIcon />}
              color='orange'
              block
              onClick={() => add()}
              disabled={view}
            />
          </div>
        </>
      )}
    </FormList>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
