import { IByUser, IByUserWithFullName } from '../../../shared/models/IByUser'
import { ILocation } from '../../../shared/models/ILocation'
import { IDepartment } from './IDepartmentsManagement'
import { CONTACT_TYPE_ENUM } from '../../../shared/models/Contact'
import { TwoFAType } from 'src/features/Profile/pages/ProfileSettings'
import { Moment } from 'moment'

export enum CALENDAR_TYPE_ENUM {
  GREGORIAN = 'GREGORIAN',
  SHAMSI = 'SHAMSI',
}

export interface IProfileRolePermission {
  permission: string
  permissionModule: {
    id: number
    module: string
  }
}

interface IUserRole {
  id: number
  name: string
  status?: boolean
  rolePermissions?: Array<IProfileRolePermission>
}

export interface IContactChanel {
  id?: number
  type: CONTACT_TYPE_ENUM
  value: string
  active: boolean
}

export interface IUserIdentifiers {
  id?: number
  type: CONTACT_TYPE_ENUM
  value: string
}

export interface IUserDelegation {
  toUserId?: number
  toUser?: {
    id: number
    logo: string
    name: string
  }
  startAt: string
  endAt: string
  isActive: boolean
}

export interface IUserDelegationForm {
  id: number
  toUserId: number
  dateRange: [Moment, Moment]
  isActive: boolean
}

export interface IUserDetail {
  id: number
  firstName: string
  lastName: string
  status: boolean
  isSuperUser: boolean
  mobileNumber: string
  email: string
  addressLine1: string
  addressLine2?: string | null
  logo: string | null
  activityStatus: string
  createdAt: Date
  updatedAt: Date
  calendar: CALENDAR_TYPE_ENUM
  department: IUserRole
  userPosition: IUserRole
  userRole: IUserRole
  country: IUserRole
  province: IUserRole
  district: IUserRole
  nativeLanguage: IUserRole
  systemLanguage?: string
  timeZone: number | string | null
  hasAccessToExternalChat: boolean
  skills: Omit<IByUser, 'logo'>
  userDelegations: Array<IUserDelegation>
  contactChannels: Array<IContactChanel>
  twoFactorAuthenticator?: TwoFAType
}

export interface IUserCreate
  extends Omit<
    IUserDetail,
    'department' | 'userPosition' | 'userRole' | 'nativeLanguage' | 'province' | 'district'
  > {
  departmentId: number
  userPositionId: number
  userRoleId: number
  nativeLanguageId: number
  isDeleteLogo?: boolean
  provinceId?: number
  districtId?: number
  skillIds: Array<number>
}

export interface IUser {
  id: number
  firstName: string
  lastName: string
  logo: string | null
  userRole: IUserRole
  userPosition: IUserRole
  mobileNumber: string
  phoneNumber: string
  createdAt: Date
  createdBy?: IByUserWithFullName
  addedBy?: IByUserWithFullName
  status: boolean
  country: ILocation
  province: ILocation
  district: ILocation
  department?: IDepartment
  contactChannels: Array<IContactChanel>
  skills: Array<Omit<IByUser, 'logo'>>
}

export interface ILocationTransformed {
  id: number
  name: string
}

export interface IUserTransformed extends Omit<IUser, 'country' | 'province' | 'district'> {
  country: ILocationTransformed
  province: ILocationTransformed
  district: ILocationTransformed
  userContactChannels: Array<IContactChanel>
}

export interface IMutateUserContacts {
  toUpdate: Array<IContactChanel>
  toCreate: Array<Omit<IContactChanel, 'id'>>
  toDelete: Array<number>
}

export interface IUserResetPassword {
  oldPassword: string
  newPassword: string
}
