import { Form, Spin } from 'antd'
import { useNavigate, useParams } from 'react-router'
import { useGetMasterProductsListQuery } from '../../../../../core/http/VendorManagementApi'
import FormList from 'antd/es/form/FormList'
import styles from '../../../styles.module.scss'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon } from '../../../../../../../assets/svg'
import { Footer } from '../../../DiscountPolicyView/Components/Footer'
import React, { useCallback, useEffect } from 'react'
import {
  useDeletePromotionPolicyMutation,
  useGetPromotionPolicyByIdQuery,
  useUpdatePromotionPolicyMutation,
} from '../../../../../core/http/PromotionPolicyApi'
import { SETTINGS_ENUM } from '../../../../../../../routes/settings'
import { ErrorNode, showConfirmMessage } from '../../../../../../../shared/api/errorHandler'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import {
  IPromotionPolicy,
  PROMOTION_POLICY_EVENT_ENUM,
} from '../../../../../models/IPromotionPolicy'
import { MasterProductListItem } from './MasterProductListItem'
import { ITableResponse } from '../../../../../../../shared/models/ITableResponse'
import { IMasterProduct } from '../../../../../models/IMasterProduct'
import { PickupProducts } from '../../../PromotionPolicyNew/Components/PickupProducts'
import { PROMOTION_POLICY_TABS_ENUM } from '../Tabs/index.d'

interface IProps {
  activeTab: string
}

export const MasterProductsTab = ({ activeTab }: IProps) => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { id, mode } = useParams<{ id: string; mode: 'view' | 'edit' }>()

  const { data: promotionPolicy, isFetching: isDiscountFetching } = useGetPromotionPolicyByIdQuery(
    id as string
  )
  const { data, isFetching } = useGetMasterProductsListQuery({})
  const [delProm, delPromResp] = useDeletePromotionPolicyMutation()
  const [addProd, addProdResp] = useUpdatePromotionPolicyMutation()

  useNotification(NOTIFICATION_TYPES.success, delPromResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, addProdResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, delPromResp.isError, delPromResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, addProdResp.isError, addProdResp.error as ErrorNode)

  const isViewMode = mode === 'view'

  const onCancelClick = () => {
    if (isViewMode) {
      return navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}`)
    }

    form.resetFields()
    return navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/promotion-policy/view/${id}`, {
      state: { activeTab: 'MASTER PRODUCTS' },
    })
  }

  const onSubmitClick = () => {
    if (isViewMode) {
      return navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/promotion-policy/edit/${id}`)
    }

    return form.submit()
  }

  const openNotification = useCallback(() => {
    showConfirmMessage('This promotion policy will be deleted', () => delProm(id as string))
  }, [delProm, id])

  const onFinish = (values: IPromotionPolicy) => {
    const mappedProducts = {
      event: promotionPolicy?.event,
      measurementType: promotionPolicy?.measurementType,
      ...(values?.pickUpConditions
        ? {
            pickUpConditions: values?.pickUpConditions.map((condition) => ({
              rate: +condition.rate,
              status: !!condition.status,
              from: +condition.from,
              to: +condition.to,
            })),
          }
        : {}),
      ...(values?.promotionPolicyProducts
        ? {
            promotionPolicyProducts: values.promotionPolicyProducts.map((product) => ({
              ...product,
              masterProductId: product.masterProduct.id,
              status: !!product.status,
              from: +product.from,
              to: +product.to,
              rate: +product.rate,
            })),
          }
        : {}),
    }

    addProd({ id: id as string, body: promotionPolicy?.isEditable ? mappedProducts : {} })
  }

  useEffect(() => {
    if (delPromResp.isSuccess) {
      navigate(SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION)
    }
    // eslint-disable-next-line
  }, [delPromResp.isSuccess])

  useEffect(() => {
    if (addProdResp.isSuccess) {
      navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/promotion-policy/view/${id}`)
    }
    // eslint-disable-next-line
  }, [addProdResp.isSuccess])

  useEffect(() => {
    if (activeTab !== PROMOTION_POLICY_TABS_ENUM.MASTER_PRODUCTS) {
      form.resetFields()
    }
  }, [activeTab, form])

  const isLoading = isDiscountFetching || isFetching || addProdResp.isLoading

  const content = (
    <Form form={form} layout='vertical' onFinish={onFinish}>
      {(promotionPolicy?.event === PROMOTION_POLICY_EVENT_ENUM.TOPUP ||
        promotionPolicy?.event === PROMOTION_POLICY_EVENT_ENUM.ACCESS_CHANNEL) && (
        <FormList
          name='promotionPolicyProducts'
          initialValue={promotionPolicy?.promotionPolicyProducts
            ?.map(({ masterProduct, ...rest }) => ({
              ...rest,
              masterProduct,
            }))
            ?.sort((a, b) => Number(a?.id) - Number(b?.id))}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name, key }) => (
                <MasterProductListItem
                  key={key}
                  name={name}
                  data={data as ITableResponse<IMasterProduct>}
                  remove={remove}
                  view={isViewMode || !promotionPolicy?.isEditable}
                  measurementType={promotionPolicy?.measurementType}
                />
              ))}
              <div className={styles.addButton}>
                <IconButton
                  icon={<AddIcon />}
                  color='orange'
                  block
                  onClick={() => add()}
                  disabled={!promotionPolicy?.isEditable || isViewMode}
                />
              </div>
            </>
          )}
        </FormList>
      )}
      {promotionPolicy?.event === PROMOTION_POLICY_EVENT_ENUM.PICKUP && (
        <PickupProducts initialValues={promotionPolicy?.pickUpConditions} view={isViewMode} />
      )}
    </Form>
  )

  return (
    <div className='fullVH'>
      {isLoading ? <Spin>{content}</Spin> : content}
      <Footer
        onCancelClick={onCancelClick}
        onSubmitClick={onSubmitClick}
        onDeleteClick={openNotification}
        mode={mode}
      />
    </div>
  )
}
