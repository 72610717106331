import React, { memo } from 'react'

import FormItem from 'antd/es/form/FormItem'
import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'
import { TextField } from '../../../../../../shared/components/textfield/TextField'

import {
  commonValidator,
  maxLengthValidator25,
  minLengthValidator,
  requiredValidator,
} from '../../../../../../helpers/validation'

import styles from '../Tabs/Departments/Components/DepartmentInfo/styles.module.scss'
import { Form, Select } from 'antd'

interface IIProps {
  isEditMode?: boolean
  isNewMode?: boolean
  onPressEditName?: () => void
  canEdit?: boolean
}

const DetailInfo = ({ isEditMode, isNewMode }: IIProps) => {
  return (
    <Wrapper
      title='Detail info'
      statusButton={
        isNewMode && (
          <FormItem name='status' valuePropName='checked'>
            <Toggle text='Status' inWrapper />
          </FormItem>
        )
      }
    >
      <div className={styles.dblRow}>
        <FormItem name='createdAt' label='Creation Date'>
          <TextField disabled />
        </FormItem>

        <FormItem name='createdBy' label='Created By'>
          <TextField disabled />
        </FormItem>
      </div>
      <div className={styles.dblRow}>
        <FormItem
          className='noBottomMargin'
          name='name'
          label='Name'
          rules={[
            { required: true, validator: requiredValidator('Name') },
            { validator: commonValidator },
            { validator: minLengthValidator(2, 'Name') },
            { validator: maxLengthValidator25 },
          ]}
        >
          <TextField disabled={!isEditMode && !isNewMode} placeholder='Enter User Name' />
        </FormItem>

        <div className={styles.doubleRow}>
          <Form.Item
            name='hasAccessToExternalChat'
            label='Has Access To Ext. Chat'
            rules={[{ required: true, validator: requiredValidator('Has Access To Ext. Chat') }]}
            className='noBottomMargin'
          >
            <Select
              disabled={!isEditMode && !isNewMode}
              allowClear
              placeholder='Has Access To Ext. Chat'
            >
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name='isGeneralExternalChatSupport'
            label='General Ext. Chat Support'
            rules={[{ required: true, validator: requiredValidator('General Ext. Chat Support') }]}
            className='noBottomMargin'
          >
            <Select
              disabled={!isEditMode && !isNewMode}
              allowClear
              placeholder='General Ext. Chat Support'
            >
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
        </div>
      </div>
    </Wrapper>
  )
}

export default memo(DetailInfo)
