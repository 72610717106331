import React from 'react'

import styles from './styles.module.scss'
import VendorIntegrationsTabs from './Components/Tabs/index'

const VendorIntegrations = () => {
  return (
    <div className={styles.layout}>
      <VendorIntegrationsTabs />
    </div>
  )
}

export default VendorIntegrations
