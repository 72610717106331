/* eslint-disable */
import React, { useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import ReactPlayer from 'react-player/lazy'
import { IInternalChatMessageFile } from '../../../../models'
import { InfiniteScrollComponent } from '../../../../../../shared/components/infiniteScoll/InfiniteScroll'
import styles from '../FilesList/styles.module.scss'
import { Modal, ModalGateway } from 'react-images'
import { PlayVideoIcon } from '../../../../../../assets/svg'
import { NotFoundPage } from '../../../../../NotFoundPage'
import { IExternalChatMessageFile } from '../../../../../Support/models/IExternalChat'

interface IProps {
  files: Array<IInternalChatMessageFile | IExternalChatMessageFile>
  totalCount: number
  loadMoreData: () => void
}

const VideoList = ({ files, totalCount, loadMoreData }: IProps) => {
  const [currentVideoLink, setCurrentVideoLink] = useState('')
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((link: string) => {
    setCurrentVideoLink(link)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentVideoLink('')
    setViewerIsOpen(false)
  }

  const itemsMap = useMemo(() => {
    const a: { [key: string]: Array<IInternalChatMessageFile | IExternalChatMessageFile> } = {}
    files.forEach((el) => {
      const key = moment(el.createdAt).format('YYYY-MM-DD')
      if (a[key] && Array.isArray(a[key])) {
        a[key].push(el)
      } else {
        a[key] = [el]
      }
    })
    return a
  }, [files])

  return (
    <div className={styles.filesContainer}>
      {files.length ? (
        <InfiniteScrollComponent
          loadMoreData={loadMoreData}
          dataLength={files.length}
          totalCount={totalCount}
          endMessage=''
          showDivider={false}
        >
          {Object.keys(itemsMap).map((key: string) => (
            <div key={key} className={styles.imagesMonthContainer}>
              <div className={styles.imageDateDivider}>{moment(key).format('MMMM')}</div>
              <div className={styles.monthImages}>
                {itemsMap[key].map(({ file }, i) => (
                  <div key={file.id}>
                    <div className={styles.imageContainer} onClick={() => openLightbox(file.link)}>
                      <div className={styles.playVideoIcon}>
                        <PlayVideoIcon />
                      </div>
                      <ReactPlayer url={file.link} width={64} height={64} />
                    </div>
                  </div>
                ))}
              </div>
              {/* @ts-ignore */}
              <ModalGateway>
                {viewerIsOpen ? (
                  <Modal onClose={closeLightbox}>
                    <ReactPlayer url={currentVideoLink} controls playing />
                  </Modal>
                ) : null}
              </ModalGateway>
            </div>
          ))}
        </InfiniteScrollComponent>
      ) : (
        <NotFoundPage text='Empty data' />
      )}
    </div>
  )
}

export default VideoList
