import React, { useCallback, useContext, useState } from 'react'
import { useGetWhatsAppNotificationButtonsListQuery } from '../../../../core/http/WhatsAppManagerApi'
import { ITableConf } from '../../../../../../shared/models/ITableConf'
import { useGetQueryParams } from '../../../../../../shared/hooks/table/useGetQueryParams'
import { isEmpty } from 'lodash'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import useTable from '../../../../../../shared/hooks/table/useTable'
import { IWhatsappManagerNotificationBtn } from '../../../../models/IWhatsappManager'
import { IPopupListItems, Popup } from '../../../../../../shared/components/popup/Popup'
import { ColumnsType } from 'antd/es/table'
import { TableActionIcon } from '../../../../../../assets/svg'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { Tags } from '../../../../../../shared/components/tags'
import { Table } from 'antd'
import styles from '../VendorManagementTable/style.module.scss'
import { RightModalContext } from '../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'

export const WhatsAppManagerTab = () => {
  const { onOpen } = useContext(RightModalContext)
  const { queryParams } = useGetQueryParams()

  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data, isFetching } = useGetWhatsAppNotificationButtonsListQuery(tableConf, {
    skip: isEmpty(tableConf),
  })

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange } = useTable<IWhatsappManagerNotificationBtn>({
    total: data?.totalCount,
    setTableConf,
  })

  const tableActionsPopup = useCallback(
    (id: string): IPopupListItems[] => [
      {
        text: 'View',
        shouldDisplay: true,
        onClick: () =>
          onOpen(RIGHT_MODALS.SETTINGS.WHATSAPP_NOTIFICATION_BUTTON_DETAIL, {
            title: 'Notification Button Detail',
            mode: 'view',
            id,
          }),
      },
      {
        text: 'Edit',
        shouldDisplay: true,
        onClick: () =>
          onOpen(RIGHT_MODALS.SETTINGS.WHATSAPP_NOTIFICATION_BUTTON_DETAIL, {
            title: 'Notification Button Detail',
            mode: 'edit',
            id,
          }),
      },
    ],
    [onOpen]
  )

  const columns: ColumnsType<IWhatsappManagerNotificationBtn> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '15%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '25%',
      render: (name) => <RecursivelyReplaceCharacters>{name}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '20%',
      render: (action) => <RecursivelyReplaceCharacters>{action}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Buttons',
      dataIndex: 'whatsappButtons',
      width: '20%',
      render: (buttons: IWhatsappManagerNotificationBtn['whatsappButtons']) => (
        <Tags tags={buttons?.map((op) => op?.title)} isBlue />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'transactionStatus',
      width: '15%',
      render: (status) => <RecursivelyReplaceCharacters>{status}</RecursivelyReplaceCharacters>,
    },
    {
      title: '',
      dataIndex: 'edit',
      width: '1%',
      key: 'edit',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      locale={emptyTableConf}
      onChange={handleTableChange}
      className={styles.table}
      loading={isFetching}
      dataSource={data?.items}
      scroll={{ x: 1000 }}
      pagination={pagination}
      rowSelection={{ type: 'checkbox' }}
      rowKey={(record) => record.id}
    />
  )
}
