import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/shared/api'
import {
  ILocationTransformed,
  IVendor,
  IVendorContact,
  IVendorContactContactInfoPutRequest,
  IVendorContactResponseView,
  IVendorPaymentPolicyProduct,
  IVendorPaymentPolicyProductUpdate,
  IVendorPaymentPolicyType,
  IVendorTable,
  IVendorTransformed,
} from '../../models/IVendorManagement'
import {
  ICountryListResponse,
  IDistrictListResponse,
  IProvinceListResponse,
} from '../../../salesNetwork/models/IAgent'
import { ILocation } from 'src/shared/models/ILocation'
import { ITableResponse } from 'src/shared/models/ITableResponse'
import { IFileFormatCsvOrXlsx, ITableConf } from 'src/shared/models/ITableConf'
import { IFilterFields } from 'src/shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { IGetProducts, IProduct, IProductCreate, IUpdateVendorBundle } from '../../models/IProduct'
import {
  ICreateMasterProduct,
  ICreateMasterProductResponse,
  IMasterProduct,
} from '../../models/IMasterProduct'
import { IReceiverPhoneNumber } from '../../models/IReceiverPhoneNumber'
import { IVendorVariable } from '../../models/IVariables'
import { ISimCard } from '../../models/ISimCard'
import { IGateway } from '../../models/IGateway'

interface ICountry {
  items: ILocationTransformed[]
}

const transformLocationResponse = (resp: ILocation[]) => {
  return resp.map((country) => ({
    label: country?.name,
    value: country?.id,
  }))
}

const transformDepartmentResponse = (resp: { name: string }[]): { name: string; id: string }[] => {
  return resp.map((department) => ({
    name: department.name,
    id: department.name,
  }))
}

export const vendorManagementApi = createApi({
  reducerPath: 'vendorManagement',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'IContact',
    'IVendor',
    'IVendorTable',
    'IFilterFields',
    'IVendorFilterFields',
    'IVendorTransformed',
    'IVendorContacts',
    'IContactListByVendorId',
    'IProduct',
    'IMasterProduct',
    'IMasterProductItem',
    'IMasterProductsFilterFields',
    'IProductList',
    'IVendorVariable',
    'SimCardsList',
    'PolicyTypes',
    'PolicyProducts',
    'IGateway',
  ],
  endpoints: (builder) => ({
    /** Vendors */
    getVendorList: builder.query<ITableResponse<IVendorTable>, ITableConf>({
      query: (params) => ({
        url: 'vendor',
        params,
      }),
      providesTags: () => ['IVendorTable'],
    }),

    getVendorById: builder.query<IVendorTransformed, string>({
      query: (id) => ({
        url: `vendor/${id}`,
      }),
      transformResponse: (res: IVendor) => ({
        ...res,
        country: {
          label: res.country?.name,
          value: res.country?.id,
        },
        district: {
          label: res.district?.name,
          value: res.district?.id,
        },
        province: {
          label: res.province?.name,
          value: res.province?.id,
        },
      }),
      providesTags: (vendor) => [{ type: 'IVendorTransformed', id: vendor?.id }],
    }),

    createVendor: builder.mutation<IVendor, FormData>({
      query: (body) => ({
        url: 'vendor',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IVendorTable', 'IFilterFields', 'IVendorFilterFields'],
    }),

    updateVendor: builder.mutation<IVendor, { id: string | number | undefined; body: FormData }>({
      query: ({ id, body }) => ({
        url: `vendor/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => [
        'IVendorTable',
        'IFilterFields',
        'IVendorTransformed',
        'IVendor',
        'SimCardsList',
        'IVendorFilterFields',
      ],
    }),

    exportVendorsListTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx' }
    >({
      query: ({ conf, formatFile }) => ({
        url: `vendor/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getVendorManagementFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'vendor/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `vendor/filter-values?filterField=${filter.field}&id=${arg}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      keepUnusedDataFor: 0,
      providesTags: ['IVendorFilterFields'],
    }),

    getCountryList: builder.query<ICountry, void>({
      query: () => `locations/countries`,
      transformResponse: (resp: ICountryListResponse) => ({
        ...resp,
        items: transformLocationResponse(resp.items),
      }),
    }),

    getProvinceList: builder.query<ICountry, number>({
      query: (id) => `locations/${id}/provinces`,
      transformResponse: (resp: IProvinceListResponse) => ({
        ...resp,
        items: transformLocationResponse(resp.items),
      }),
    }),

    getDistrictList: builder.query<ICountry, number>({
      query: (id) => `locations/${id}/districts`,
      transformResponse: (resp: IDistrictListResponse) => ({
        ...resp,
        items: transformLocationResponse(resp.items),
      }),
    }),

    getDepartmentList: builder.query<ITableResponse<{ name: string; id: string }>, string | number>(
      {
        query: () => `departments`,
        transformResponse: (resp: ITableResponse<{ name: string; id: string }>) => ({
          ...resp,
          items: transformDepartmentResponse(resp.items),
        }),
      }
    ),

    /** Products */
    getProductsList: builder.query<ITableResponse<IProduct>, IGetProducts>({
      query: (params) => ({
        url: `products`,
        params,
      }),
      providesTags: ['IProductList'],
    }),

    getProductById: builder.query<IProduct, number>({
      query: (id) => ({
        url: `products/${id}`,
      }),
      providesTags: (product) => [{ type: 'IProduct', id: product?.id }],
    }),

    createProduct: builder.mutation<IProductCreate, FormData>({
      query: (body) => ({
        url: 'products',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['IProduct', 'IProductList'],
    }),

    deleteProductById: builder.mutation<void, number>({
      query: (productId) => ({
        url: `products/${productId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['IProduct', 'IProductList'],
    }),

    patchProduct: builder.mutation<IProduct, { vendorId: number; status: boolean }>({
      query: ({ vendorId, ...rest }) => ({
        url: `products/${vendorId}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: (p) => [{ type: 'IProduct', id: p?.id }, 'IProductList'],
    }),

    updateProduct: builder.mutation<IProduct, { id: number; body: FormData }>({
      query: ({ id, body }) => ({
        url: `products/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (p) => [{ type: 'IProduct', id: p?.id }, 'IProductList'],
    }),

    assignProductsToVendor: builder.mutation<
      IVendor,
      { id: number; body: { items: Array<number> } }
    >({
      query: ({ id, body }) => ({
        url: `vendor/${id}/products`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['IProduct', 'IProductList'],
    }),

    updateProductBundles: builder.mutation<void, { id: string; body: IUpdateVendorBundle }>({
      query: ({ id, body }) => ({
        url: `products/${id}/bundles`,
        method: 'POST',
        body,
      }),
    }),

    exportProductsTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx' }
    >({
      query: ({ conf, formatFile }) => ({
        url: `products/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getProductsFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'products/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }
        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `products/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),

    /** Master Products */
    getMasterProductsList: builder.query<ITableResponse<IMasterProduct>, ITableConf>({
      query: (params) => ({
        url: 'settings/master-product',
        params,
      }),
      providesTags: () => ['IMasterProduct'],
    }),

    getMasterProductById: builder.query<IMasterProduct, string>({
      query: (id) => ({
        url: `settings/master-product/${id}`,
      }),
      providesTags: (masterProduct) => [{ type: 'IMasterProductItem', id: masterProduct?.id }],
    }),

    createMasterProduct: builder.mutation<ICreateMasterProductResponse, ICreateMasterProduct>({
      query: (body) => ({
        url: '/settings/master-product',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IMasterProduct', 'IMasterProductsFilterFields'],
    }),

    patchMasterProductLogo: builder.mutation<void, { id: string | number; body: FormData }>({
      query: ({ id, body }) => ({
        url: `/settings/master-product/${id}/logo`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        'IMasterProduct',
        { type: 'IMasterProductItem', id: arg?.id },
        'IMasterProductsFilterFields',
      ],
    }),

    updateMasterProduct: builder.mutation<
      IMasterProduct,
      { id: string | number; body: Partial<ICreateMasterProduct> }
    >({
      query: ({ id, body }) => ({
        url: `settings/master-product/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (masterProduct) => [
        'IMasterProduct',
        { type: 'IMasterProductItem', id: masterProduct?.id },
        'IMasterProductsFilterFields',
      ],
    }),

    exportMasterProductsTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx' }
    >({
      query: ({ conf, formatFile }) => ({
        url: `settings/master-product/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getMasterProductsFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'settings/master-product/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }
        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `settings/master-product/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: ['IMasterProductsFilterFields'],
    }),

    /** Contacts */
    getVendorContactList: builder.query<ITableResponse<IVendorContact>, ITableConf>({
      query: (params) => {
        return { url: 'vendors/contacts', params }
      },
      providesTags: () => ['IVendorContacts'],
      keepUnusedDataFor: 0,
    }),

    getVendorContactFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'vendors/contacts/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }
        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `vendors/contacts/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),

    getContactListByVendorId: builder.query<
      ITableResponse<IVendorContactResponseView>,
      { vendorId: string }
    >({
      query: (params) => ({
        url: `vendors/contacts`,
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: ['IContactListByVendorId', 'IFilterFields'],
    }),

    getVendorContactById: builder.query<IVendorContact, string | undefined>({
      query: (id) => ({
        url: `vendors/contacts/${id}`,
      }),
      providesTags: (contact) => [{ type: 'IContact', id: contact?.id }],
    }),

    createVendorContact: builder.mutation<IVendorContact, FormData>({
      query: (body) => ({
        url: 'vendors/contacts',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IVendorContacts'],
    }),

    deleteContact: builder.mutation<void, number>({
      query: (id) => ({
        url: `vendors/contacts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['IContactListByVendorId', 'IFilterFields'],
    }),

    patchContactById: builder.mutation<
      IVendorContact,
      { id: number; body: Partial<IVendorContact> }
    >({
      query: ({ id, body }) => ({
        url: `vendors/contacts/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['IContactListByVendorId', 'IFilterFields'],
    }),

    updateVendorContactById: builder.mutation<
      IVendorContact,
      { id: string | number | undefined; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `vendors/contacts/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (contact) => ['IVendorContacts', { type: 'IContact', id: contact?.id }],
    }),

    updateVendorContactContactInfoById: builder.mutation<
      IVendorContact,
      { id: string | number | undefined; body: IVendorContactContactInfoPutRequest }
    >({
      query: ({ id, body }) => ({
        url: `vendors/contacts/${id}/channels`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IVendorContacts'],
    }),

    assignContactsToVendor: builder.mutation<
      IVendor,
      { id: number; body: { items: Array<number> } }
    >({
      query: ({ id, body }) => ({
        url: `vendor/${id}/contacts`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['IContactListByVendorId'],
    }),

    exportContactListByVendorIdListTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx' }
    >({
      query: ({ conf, formatFile }) => ({
        url: `vendors/contacts/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    /** Receiver Phone Number */
    getReceiverPhoneNumberPrefixes: builder.query<ITableResponse<IReceiverPhoneNumber>, void>({
      query: () => ({
        url: `receiver-phone-number-prefix`,
      }),
    }),

    /** Variables */
    getVendorVariablesList: builder.query<
      ITableResponse<IVendorVariable>,
      { vendorId: string; params: ITableConf }
    >({
      query: ({ vendorId, params }) => {
        return { url: `/vendors/${vendorId}/variables`, params }
      },
      providesTags: () => ['IVendorVariable'],
    }),

    createVendorVariable: builder.mutation<
      IVendorVariable,
      { vendorId: string; body: Partial<IVendorVariable> }
    >({
      query: ({ vendorId, body }) => ({
        url: `/vendors/${vendorId}/variables`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IVendorVariable'],
    }),

    deleteVariable: builder.mutation<void, { vendorId: string; variableId: number }>({
      query: ({ vendorId, variableId }) => ({
        url: `/vendors/${vendorId}/variables/${variableId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['IVendorVariable'],
    }),

    getVariableById: builder.query<IVendorVariable, { vendorId: string; variableId: number }>({
      query: ({ vendorId, variableId }) => ({
        url: `/vendors/${vendorId}/variables/${variableId}`,
      }),
      providesTags: (variable) => [{ type: 'IVendorVariable', id: variable?.id }],
    }),

    updateVariableById: builder.mutation<
      IVendorVariable,
      { vendorId: string; variableId: number; body: Partial<IVendorVariable> }
    >({
      query: ({ vendorId, variableId, body }) => ({
        url: `/vendors/${vendorId}/variables/${variableId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['IVendorVariable'],
    }),

    getVariablesFiltersList: builder.query<Array<IFilterFields>, { vendorId: string }>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: `/vendors/variables/filter-fields`,
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `/vendors/${arg.vendorId}/variables/filter-values?filterField=${filter.field}&id=${arg.vendorId}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      keepUnusedDataFor: 0,
    }),

    getVendorSimCards: builder.query<
      ITableResponse<ISimCard>,
      { venId: string | number; params: ITableConf }
    >({
      query: ({ venId, params }) => ({
        url: `vendor/${venId}/sub-vendors`,
        params,
      }),
      providesTags: ['SimCardsList'],
    }),

    exportVendorSimCards: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx'; id: string }
    >({
      query: ({ conf, formatFile }) => ({
        url: `vendor/${conf.id}/sub-vendors/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getVendorWalletTypes: builder.query<Array<string>, void>({
      query: () => ({
        url: `vendor/wallet-types`,
      }),
    }),

    getVendorPaymentPolicyTypes: builder.query<Array<IVendorPaymentPolicyType>, string>({
      query: (vendorId) => ({
        url: `vendor/${vendorId}/payment-policy-type`,
      }),
      providesTags: ['PolicyTypes'],
    }),

    updateVendorPaymentPolicyTypes: builder.mutation<
      Array<IVendorPaymentPolicyType>,
      { vendorId: string; body: Array<IVendorPaymentPolicyType> }
    >({
      query: ({ vendorId, body }) => ({
        url: `vendor/${vendorId}/payment-policy-type`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['PolicyTypes'],
    }),

    getVendorPolicyProducts: builder.query<Array<IVendorPaymentPolicyProduct>, string>({
      query: (vendorId) => ({
        url: `vendor/${vendorId}/policy-product`,
      }),
      providesTags: ['PolicyProducts'],
    }),

    updateVendorPolicyProducts: builder.mutation<
      Array<IVendorPaymentPolicyProduct>,
      { vendorId: string; body: Array<IVendorPaymentPolicyProductUpdate> }
    >({
      query: ({ vendorId, body }) => ({
        url: `vendor/${vendorId}/policy-product`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['PolicyProducts'],
    }),
    getVendorBundles: builder.query<Array<void>, string>({
      query: (vendorId) => ({
        url: `vendor/${vendorId}/bundles`,
      }),
    }),

    // Gateway Start
    getGatewayList: builder.query<
      ITableResponse<IGateway>,
      { vendorId: string; params: ITableConf }
    >({
      query: ({ vendorId, params }) => {
        return { url: `/vendors/${vendorId}/bundle-processor-sims`, params }
      },
      providesTags: () => ['IGateway'],
    }),

    createGateway: builder.mutation<IGateway, { vendorId: string; body: Partial<IGateway> }>({
      query: ({ vendorId, body }) => ({
        url: `/vendors/${vendorId}/bundle-processor-sims`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IGateway'],
    }),

    deleteGateway: builder.mutation<void, { vendorId: string; gatewayId: number }>({
      query: ({ vendorId, gatewayId }) => ({
        url: `/vendors/${vendorId}/bundle-processor-sims/${gatewayId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['IGateway'],
    }),

    getGatewayById: builder.query<IGateway, { vendorId: string; gatewayId: number }>({
      query: ({ vendorId, gatewayId }) => ({
        url: `/vendors/${vendorId}/bundle-processor-sims/${gatewayId}`,
      }),
      providesTags: (variable) => [{ type: 'IGateway', id: variable?.id }],
    }),

    updateGatewayById: builder.mutation<
      IGateway,
      { vendorId: string; gatewayId: number; body: Partial<IGateway> }
    >({
      query: ({ vendorId, gatewayId, body }) => ({
        url: `/vendors/${vendorId}/bundle-processor-sims/${gatewayId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['IGateway'],
    }),

    getGatewayFiltersList: builder.query<Array<IFilterFields>, { vendorId: string }>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: `/vendors/bundle-processor-sims/filter-fields`,
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `/vendors/${arg.vendorId}/bundle-processor-sims/filter-values?filterField=${filter.field}&id=${arg.vendorId}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      keepUnusedDataFor: 0,
    }),
    // Gateway End
  }),
})

export const {
  useCreateVendorMutation,
  useUpdateVendorMutation,
  useGetCountryListQuery,
  useGetVendorByIdQuery,
  useGetVendorListQuery,
  useGetVendorManagementFiltersListQuery,
  useLazyGetProvinceListQuery,
  useLazyGetDistrictListQuery,
  useLazyGetVendorContactListQuery,
  useGetVendorContactFiltersListQuery,
  useCreateVendorContactMutation,
  useGetDepartmentListQuery,
  useLazyGetVendorContactByIdQuery,
  useUpdateVendorContactByIdMutation,
  useUpdateVendorContactContactInfoByIdMutation,
  useCreateProductMutation,
  useGetProductsListQuery,
  useLazyGetProductsListQuery,
  useGetProductsFiltersListQuery,
  usePatchProductMutation,
  useGetProductByIdQuery,
  useUpdateProductMutation,
  useGetMasterProductByIdQuery,
  useDeleteProductByIdMutation,
  useLazyExportProductsTableQuery,
  useLazyExportVendorsListTableQuery,
  useGetMasterProductsListQuery,
  useGetMasterProductsFiltersListQuery,
  useUpdateMasterProductMutation,
  usePatchMasterProductLogoMutation,
  useCreateMasterProductMutation,
  useGetContactListByVendorIdQuery,
  useDeleteContactMutation,
  useLazyExportContactListByVendorIdListTableQuery,
  useLazyExportMasterProductsTableQuery,
  useAssignContactsToVendorMutation,
  useAssignProductsToVendorMutation,
  useGetVendorContactByIdQuery,
  useGetReceiverPhoneNumberPrefixesQuery,
  usePatchContactByIdMutation,
  useGetVendorVariablesListQuery,
  useCreateVendorVariableMutation,
  useDeleteVariableMutation,
  useGetVariableByIdQuery,
  useUpdateVariableByIdMutation,
  useGetVariablesFiltersListQuery,
  useGetVendorSimCardsQuery,
  useGetVendorWalletTypesQuery,
  useGetVendorPolicyProductsQuery,
  useGetVendorPaymentPolicyTypesQuery,
  useUpdateVendorPolicyProductsMutation,
  useUpdateVendorPaymentPolicyTypesMutation,
  useLazyExportVendorSimCardsQuery,
  useLazyGetVendorBundlesQuery,
  // Gateway Start
  useGetGatewayListQuery,
  useCreateGatewayMutation,
  useDeleteGatewayMutation,
  useGetGatewayByIdQuery,
  useUpdateGatewayByIdMutation,
  useGetGatewayFiltersListQuery,
  // Gateway End
} = vendorManagementApi
