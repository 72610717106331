import { Alert } from 'antd'
import { AlertProps } from 'antd/lib/alert'
import './style.scss'

interface IProps extends AlertProps {
  variant: 'contained' | 'outlined' | 'standard'
}

export const NotificationAlert = ({ variant, showIcon = true, ...rest }: IProps): JSX.Element => {
  return <Alert className={variant} showIcon={showIcon} {...rest} />
}
