import FormItem from 'antd/es/form/FormItem'
import { TextField } from 'src/shared/components/textfield/TextField'
import styles from '../../VendorManagementView/Components/styles.module.scss'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import { Toggle } from 'src/shared/components/toggle/Toggle'
import { Select, Select as AntSelect } from 'antd'
import React, { useMemo } from 'react'
import { VENDOR_TYPE_ENUM } from '../../../../models/IVendorManagement'
import { useParams } from 'react-router-dom'
import { maxLengthValidator, requiredValidator } from '../../../../../../helpers/validation'
import { PAYMENT_POLICY_TYPE_ENUM } from '../../../../models/ITargetGroup'
import classNames from 'classnames'

export const DetailInfo = ({ view }: IViewMode) => {
  const { mode: viewMode } = useParams<{ mode: 'view' | 'edit' }>()

  const vendorType = useMemo(
    () =>
      (Object.keys(VENDOR_TYPE_ENUM) as Array<keyof typeof VENDOR_TYPE_ENUM>)
        .filter((vendor) => VENDOR_TYPE_ENUM[vendor] !== VENDOR_TYPE_ENUM.SIM_CARD)
        .map((vendor) => ({
          label: VENDOR_TYPE_ENUM[vendor],
          value: VENDOR_TYPE_ENUM[vendor],
        })),
    []
  )

  const paymentPolicyType = useMemo(
    () =>
      (Object.keys(PAYMENT_POLICY_TYPE_ENUM) as Array<keyof typeof PAYMENT_POLICY_TYPE_ENUM>).map(
        (vendor) => ({
          label: PAYMENT_POLICY_TYPE_ENUM[vendor],
          value: PAYMENT_POLICY_TYPE_ENUM[vendor],
        })
      ),
    []
  )

  const isNewMode = viewMode !== 'view' && viewMode !== 'edit'

  return (
    <div className={styles.wrapperBlock}>
      <Wrapper
        title='Detail Info'
        statusButton={
          isNewMode && (
            <FormItem name='status' valuePropName='checked'>
              <Toggle text='Status' inWrapper />
            </FormItem>
          )
        }
      >
        <div className={styles.dblRow}>
          <FormItem name='createdAt' label='Creation Date'>
            <TextField disabled />
          </FormItem>

          <FormItem name='createdBy' label='Created By'>
            <TextField disabled />
          </FormItem>
        </div>

        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const isSimGroup = getFieldValue('vendorType') === VENDOR_TYPE_ENUM.PROCESSOR
            return (
              <div className={classNames({ [styles.dblRow]: !isSimGroup })}>
                <FormItem
                  name='name'
                  label='Vendor Name'
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator('Vendor Company Name'),
                    },
                    { validator: maxLengthValidator(25) },
                  ]}
                >
                  <TextField disabled={view} placeholder='Enter Vendor Name' />
                </FormItem>

                {!isSimGroup && (
                  <FormItem
                    name='paymentPolicyType'
                    label='Type Of Contract'
                    rules={[
                      {
                        required: true,
                        validator: requiredValidator('Type Of Contract'),
                      },
                    ]}
                  >
                    <Select
                      disabled={view}
                      options={paymentPolicyType}
                      allowClear
                      placeholder='Enter Contract Type'
                    />
                  </FormItem>
                )}
              </div>
            )
          }}
        </FormItem>

        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const isSimGroup = getFieldValue('vendorType') === VENDOR_TYPE_ENUM.PROCESSOR
            return (
              <div className={classNames({ [styles.dblRow]: !isSimGroup })}>
                <FormItem
                  name='vendorType'
                  label='Vendor Type'
                  className={styles.noBottomMargin}
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator('Vendor Type'),
                    },
                  ]}
                >
                  <AntSelect
                    allowClear
                    disabled={view}
                    placeholder='Select Vendor Type'
                    options={vendorType}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </FormItem>

                {!isSimGroup && (
                  <FormItem
                    name='website'
                    label='Website'
                    className={styles.noBottomMargin}
                    rules={[
                      {
                        required: true,
                        validator: requiredValidator('Website'),
                      },
                      {
                        validator: maxLengthValidator(100),
                      },
                    ]}
                  >
                    <TextField disabled={view} placeholder='Enter Website' />
                  </FormItem>
                )}
              </div>
            )
          }}
        </FormItem>
      </Wrapper>
    </div>
  )
}
