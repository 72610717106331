import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/shared/api'
import { IFile, IFileTable } from '../../models/IFileManagementList'
import { ITableConf } from 'src/shared/models/ITableConf'
import { IFilterFields } from 'src/shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { ITableResponse } from 'src/shared/models/ITableResponse'

export const fileManagementApi = createApi({
  reducerPath: 'fileManagement',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['IFileManagementList', 'IFilterFields', 'IFile'],
  endpoints: (builder) => ({
    getFileInfo: builder.query<IFile, string | undefined>({
      query: (id) => ({
        url: `file-management/${id}`,
      }),
      providesTags: (file) => [{ type: 'IFile', id: file?.id }],
    }),
    getFileList: builder.query<ITableResponse<IFileTable>, ITableConf>({
      query: (params) => ({
        url: 'file-management',
        params,
      }),
      providesTags: () => ['IFileManagementList'],
    }),
    getFileManagementFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'file-management/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `file-management/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: () => ['IFilterFields'],
    }),

    downloadFile: builder.query<BlobPart, string>({
      query: (id) => ({
        url: `file-management/download/${id}`,
      }),
    }),
    updateFile: builder.mutation<
      IFile,
      { name: IFile['name']; isPrivate: IFile['isPrivate']; id: string }
    >({
      query: ({ id, ...body }) => ({
        url: `file-management/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (p) => [
        'IFileManagementList',
        { type: 'IFile', id: p?.id },
        'IFilterFields',
      ],
    }),
    createFile: builder.mutation<IFileTable, FormData>({
      query: (body) => ({
        url: `file-management`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['IFileManagementList', 'IFilterFields'],
    }),
    deleteFile: builder.mutation<undefined, string>({
      query: (id) => ({
        url: `file-management/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['IFileManagementList', 'IFilterFields'],
    }),
  }),
})

export const {
  useGetFileInfoQuery,
  useLazyGetFileInfoQuery,
  useGetFileListQuery,
  useLazyDownloadFileQuery,
  useUpdateFileMutation,
  useCreateFileMutation,
  useGetFileManagementFiltersListQuery,
  useDeleteFileMutation,
} = fileManagementApi
