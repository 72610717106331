import { SYSTEM_CONFIGURATION_TABS_ENUM } from './index.d'
import { NotFoundPage } from '../../../../../NotFoundPage'
import { Tabs } from 'antd/es'
import { CalendarTab } from '../CalendarTab'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useGetDefaultTab } from '../../../../../salesNetwork/helpers/useGetDefaultTab'
import { useEffect } from 'react'

export const SystemConfigurationTabs = () => {
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: SYSTEM_CONFIGURATION_TABS_ENUM.GENERAL,
  })

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  const items = [
    {
      label: SYSTEM_CONFIGURATION_TABS_ENUM.GENERAL,
      key: SYSTEM_CONFIGURATION_TABS_ENUM.GENERAL,
      children: <NotFoundPage />,
    },
    {
      label: SYSTEM_CONFIGURATION_TABS_ENUM.CALENDAR,
      key: SYSTEM_CONFIGURATION_TABS_ENUM.CALENDAR,
      children: <CalendarTab />,
    },
    {
      label: SYSTEM_CONFIGURATION_TABS_ENUM.SYSTEM_EVENTS,
      key: SYSTEM_CONFIGURATION_TABS_ENUM.SYSTEM_EVENTS,
      children: <NotFoundPage />,
    },
    {
      label: SYSTEM_CONFIGURATION_TABS_ENUM.TRANSACTION_MANAGEMENT,
      key: SYSTEM_CONFIGURATION_TABS_ENUM.TRANSACTION_MANAGEMENT,
      children: <NotFoundPage />,
    },
  ]

  return <Tabs items={items} activeKey={activeTab} onChange={onTabChange} />
}
