import React, { useEffect, useMemo, useState } from 'react'
import { FinanceOverviewWidgets } from './Components/Widgets'
import BankAccountBalance from './Components/Charts/BankAccountBalance'
import VendorBalance from './Components/Charts/VendorBalance'
import HawalaChart from './Components/Charts/Hawala'
import ServicesChart from './Components/Charts/Services'
import styles from './styles.module.scss'
import PLSummaryChart from './Components/Charts/PLSummary'
import { useGetProfileQuery } from '../../../Profile/core/http/Profile'
import { DASHBOARD_DATE_OPTIONS, WIDGET_LIST_ENUM } from '../../../../shared/models/WidgetListEnum'
import { widgetsSocketConnection } from '../../../../shared/sockets'
import { IWidget } from '../../../../shared/models/IWidget'
import { DashboardHeader } from '../../../Dashboard/pages/Overview/Components/DashboardHeader/index'
import { Moment } from 'moment/moment'
import {
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  formatShamsiMomentToISO,
  getFrequency,
  moment,
} from '../../../../helpers/utils'
import { PickupSummaryChart } from '../BankWallet/Tables/TransactionsTable/PickupSummaryChart'
import { ITotalPickup } from '../../models/IPickupRequest'
import { ISelectedFilters } from '../../../../shared/components/filter/Filter'
import { isEmpty } from 'lodash'
import ErrorBoundary from '../../../../shared/components/errorBoundary/ErrorBoundary'
import { IPNLSummary } from '../../models/IBankWalletTransactions'
import { PIE_CHART_COLORS } from '../../../../helpers/contants'
import { ISalesSummaryByVendors } from '../../../Reports/models/IExternalReport'
import { ISalesSummaryByMasterProd } from '../../../Settings/models/IMasterProduct'
import { SalesSummaryBarChart } from '../../../Dashboard/pages/Overview/Components/SalesSummaryBarChart'
import { IVendorBalanceChart } from '../../../Settings/models/IVendorManagement'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { IBankAccountBalancesChart } from '../../models/IBankAccount'

export type FinanceDashboardWidgetStateType = Record<
  Exclude<
    WIDGET_LIST_ENUM,
    | WIDGET_LIST_ENUM.PICKUP_SUMMARY
    | WIDGET_LIST_ENUM.PNL_SUMMARY
    | WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT
    | WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS
    | WIDGET_LIST_ENUM.VENDOR_BALANCES
    | WIDGET_LIST_ENUM.BANK_ACCOUNTS_BALANCE_CHART
  >,
  {
    value: number
    statistic: number
    valueTotal?: number
  }
> &
  Record<
    WIDGET_LIST_ENUM.PICKUP_SUMMARY,
    {
      value: ITotalPickup[]
      frequency: string
    }
  > &
  Record<
    WIDGET_LIST_ENUM.PNL_SUMMARY,
    {
      value: IPNLSummary[]
      frequency: string
    }
  > &
  Record<
    WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT,
    {
      value: ISalesSummaryByMasterProd[]
      frequency: 'day' | 'hour'
    }
  > &
  Record<
    WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS,
    {
      value: ISalesSummaryByVendors[]
      frequency: 'day' | 'hour'
    }
  > &
  Record<
    WIDGET_LIST_ENUM.VENDOR_BALANCES,
    {
      value: ITableResponse<IVendorBalanceChart>
    }
  > &
  Record<
    WIDGET_LIST_ENUM.BANK_ACCOUNTS_BALANCE_CHART,
    {
      value: ITableResponse<IBankAccountBalancesChart>
    }
  >

export const FinanceOverview = () => {
  const { data: profile } = useGetProfileQuery()
  const [vbTableConf, setVbTableConf] = useState<ITableConf>({
    page: 1,
    limit: 5,
  })
  const [baTableConf, setBaTableConf] = useState<ITableConf>({
    page: 1,
    limit: 5,
  })
  const [option, setOption] = useState(DASHBOARD_DATE_OPTIONS.TODAY)
  const [dateFilter, setDateFilter] = useState<string | null>(null)
  const [salesSummaryType, setSalesSummaryType] = useState<
    WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS | WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT
  >(WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT)
  const [widgetDate, setWidgetDate] = useState<Record<WIDGET_LIST_ENUM, ISelectedFilters>>(
    {} as Record<WIDGET_LIST_ENUM, ISelectedFilters>
  )

  const [widgetData, setWidgetData] = useState<FinanceDashboardWidgetStateType>(
    () =>
      ({
        [WIDGET_LIST_ENUM.INVENTORY]: {
          value: 0,
          statistic: 0,
        },
        [WIDGET_LIST_ENUM.TOTAL_PICKUP_TODAY]: {
          value: 0,
          statistic: 0,
          valueTotal: 0,
        },
        [WIDGET_LIST_ENUM.BANK_ACCOUNT_BALANCE]: {
          value: 0,
          statistic: 0,
        },
        [WIDGET_LIST_ENUM.PURCHASE_ORDERS]: {
          value: 0,
          valueTotal: 0,
        },
        [WIDGET_LIST_ENUM.TOTAL_FLOATING_BALANCE]: {
          value: 0,
          valueTotal: 0,
          statistic: 0,
        },
        [WIDGET_LIST_ENUM.GENERATED_PROFIT]: {
          value: 0,
          statistic: 0,
        },
        [WIDGET_LIST_ENUM.PICKUP_SUMMARY]: {
          value: [] as ITotalPickup[],
          frequency: 'hour',
        },
        [WIDGET_LIST_ENUM.PNL_SUMMARY]: {
          value: [] as IPNLSummary[],
          frequency: 'hour',
        },
        [WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS]: {
          value: [] as ISalesSummaryByVendors[],
          frequency: 'hour',
        },
        [WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT]: {
          value: [] as ISalesSummaryByMasterProd[],
          frequency: 'hour',
        },
        [WIDGET_LIST_ENUM.VENDOR_BALANCES]: {
          value: {} as ITableResponse<IVendorBalanceChart>,
        },
        [WIDGET_LIST_ENUM.BANK_ACCOUNTS_BALANCE_CHART]: {
          value: {} as ITableResponse<IBankAccountBalancesChart>,
        },
      } as FinanceDashboardWidgetStateType)
  )

  const filters = useMemo(() => {
    const selectedDate = dateFilter
    if (option === DASHBOARD_DATE_OPTIONS.WEEK) {
      return {
        from: formatMomentDateToDayStart(moment(selectedDate).startOf('week'))?.toISOString(true),
        to: formatMomentDateToDayEnd(moment(selectedDate).endOf('week'))?.toISOString(true),
      }
    }
    if (option === DASHBOARD_DATE_OPTIONS.MONTH) {
      return {
        from: formatMomentDateToDayStart(moment(selectedDate).startOf('month'))?.toISOString(true),
        to: formatMomentDateToDayEnd(moment(selectedDate).endOf('month'))?.toISOString(true),
      }
    }
    if (option === DASHBOARD_DATE_OPTIONS.TODAY) {
      return {
        from: formatMomentDateToDayStart(moment(selectedDate))?.toISOString(true),
        to: formatMomentDateToDayEnd(moment(selectedDate))?.toISOString(true),
      }
    }
  }, [dateFilter, option])

  const onWidgetDateChange = (widgetName: WIDGET_LIST_ENUM) => (value: Moment[] | null) => {
    if (!Array.isArray(value)) {
      return setWidgetDate((prevState) => ({
        ...prevState,
        [widgetName]: null,
      }))
    }

    const from = !isEmpty(value[0])
      ? formatMomentDateToDayStart(
          formatShamsiMomentToISO(profile?.calendar, value[0])
        ).toISOString(true)
      : ''
    const to = !isEmpty(value[1])
      ? formatMomentDateToDayEnd(formatShamsiMomentToISO(profile?.calendar, value[1])).toISOString(
          true
        )
      : ''

    return setWidgetDate((prevState) => ({
      ...prevState,
      [widgetName]: { from, to },
    }))
  }

  const getFrequencyName = (widgetDate: ISelectedFilters) => {
    return getFrequency(widgetDate.from as string, widgetDate.to as string)
  }

  useEffect(() => {
    if (!profile) return
    const socket = widgetsSocketConnection.getSocket()
    const messagePayload = [
      {
        widget: WIDGET_LIST_ENUM.INVENTORY,
        filters,
      },
      {
        widget: WIDGET_LIST_ENUM.TOTAL_PICKUP_TODAY,
        filters,
      },
      {
        widget: WIDGET_LIST_ENUM.BANK_ACCOUNT_BALANCE,
        filters,
      },
      {
        widget: WIDGET_LIST_ENUM.PURCHASE_ORDERS,
        filters,
      },
      {
        widget: WIDGET_LIST_ENUM.TOTAL_FLOATING_BALANCE,
        filters,
      },
      {
        widget: WIDGET_LIST_ENUM.GENERATED_PROFIT,
        filters,
      },
      {
        widget: WIDGET_LIST_ENUM.PICKUP_SUMMARY,
        filters: {
          ...(widgetDate[WIDGET_LIST_ENUM.PICKUP_SUMMARY] ?? filters),
          frequency: getFrequencyName(widgetDate[WIDGET_LIST_ENUM.PICKUP_SUMMARY] ?? filters),
        },
      },
      {
        widget: WIDGET_LIST_ENUM.PNL_SUMMARY,
        filters: {
          ...(widgetDate[WIDGET_LIST_ENUM.PNL_SUMMARY] ?? filters),
          frequency: getFrequencyName(widgetDate[WIDGET_LIST_ENUM.PNL_SUMMARY] ?? filters),
        },
      },
      {
        widget: salesSummaryType,
        filters: {
          ...filters,
          ...(filters?.from &&
            filters?.to && { frequency: getFrequency(filters.from, filters?.to) }),
        },
      },
      {
        widget: WIDGET_LIST_ENUM.VENDOR_BALANCES,
        filters: vbTableConf,
      },
      {
        widget: WIDGET_LIST_ENUM.BANK_ACCOUNTS_BALANCE_CHART,
        filters: baTableConf,
      },
    ]

    socket?.emit('connect-widget', { data: messagePayload })

    return () => {
      socket?.emit('disconnect-widget', { data: messagePayload })
    }
  }, [baTableConf, filters, profile, salesSummaryType, vbTableConf, widgetDate])

  const onDateChange = (date: Moment | null) => {
    if (date) {
      setDateFilter(typeof date === 'string' ? date : date.toISOString(true))
    }
  }

  useEffect(() => {
    if (profile?.timeZone) {
      moment.tz?.setDefault(profile?.timeZone as string)

      const date = formatShamsiMomentToISO(profile?.calendar, moment())
      setDateFilter(typeof date === 'string' ? date : date.toISOString(true))
      setWidgetDate((prevState) => ({
        ...prevState,
        [WIDGET_LIST_ENUM.PNL_SUMMARY]: {
          from: formatMomentDateToDayStart(
            formatShamsiMomentToISO(profile?.calendar, moment().startOf('month'))
          ).toISOString(true),
          to: formatMomentDateToDayEnd(
            formatShamsiMomentToISO(profile?.calendar, moment())
          ).toISOString(true),
        },
      }))
    }
  }, [profile])

  useEffect(() => {
    if (!profile) return
    const socket = widgetsSocketConnection.getSocket()
    socket?.on('widget-data', (data: IWidget) => {
      const balance = JSON.parse(data?.data?.jsonData)

      setWidgetData((prevState) => ({
        ...prevState,
        [data.widget]:
          Object.keys(balance).length === 1
            ? {
                value: balance.value,
                ...(data['filters']?.frequency && { frequency: data['filters']?.frequency }),
              }
            : balance,
      }))
    })
  }, [profile])

  const frequency = useMemo(
    () =>
      salesSummaryType === WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT
        ? widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT]?.frequency
        : widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS]?.frequency,
    [salesSummaryType, widgetData]
  )

  return (
    <div className={styles.layout}>
      <DashboardHeader
        option={option}
        setOption={setOption}
        onDateChange={onDateChange}
        dateFilter={dateFilter}
      />
      <FinanceOverviewWidgets widgetData={widgetData} />
      <div className={styles.chartsWrapper}>
        <BankAccountBalance
          widgetData={widgetData[WIDGET_LIST_ENUM.BANK_ACCOUNTS_BALANCE_CHART].value}
          page={baTableConf.page!}
          setPage={setBaTableConf}
        />
        <VendorBalance
          widgetData={widgetData[WIDGET_LIST_ENUM.VENDOR_BALANCES].value}
          page={vbTableConf.page!}
          setPage={setVbTableConf}
        />
      </div>
      <div className={styles.chartsWrapper}>
        <ErrorBoundary>
          <PickupSummaryChart
            widgetData={widgetData[WIDGET_LIST_ENUM.PICKUP_SUMMARY].value}
            frequency={widgetData[WIDGET_LIST_ENUM.PICKUP_SUMMARY].frequency}
            onWidgetDateChange={onWidgetDateChange(WIDGET_LIST_ENUM.PICKUP_SUMMARY)}
          />
        </ErrorBoundary>
      </div>
      <div className={styles.chartsWrapper}>
        <HawalaChart colors={PIE_CHART_COLORS} />
        <ServicesChart colors={PIE_CHART_COLORS} />
      </div>
      <ErrorBoundary>
        <SalesSummaryBarChart
          salesSummaryType={salesSummaryType}
          setSalesSummaryType={setSalesSummaryType}
          frequency={frequency}
          byVendors={widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS]?.value}
          byMasters={widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT]?.value}
        />
      </ErrorBoundary>
      <div className={styles.chartsWrapper}>
        <PLSummaryChart
          widgetData={widgetData[WIDGET_LIST_ENUM.PNL_SUMMARY].value}
          frequency={widgetData[WIDGET_LIST_ENUM.PNL_SUMMARY].frequency}
          onWidgetDateChange={onWidgetDateChange(WIDGET_LIST_ENUM.PNL_SUMMARY)}
        />
      </div>
    </div>
  )
}
