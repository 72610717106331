import React from 'react'
import { Select } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import TextArea from 'antd/es/input/TextArea'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { TextField } from 'src/shared/components/textfield/TextField'
import { urlValidator } from '../../../../../../../../../../../helpers/validation'
import styles from './styles.module.scss'

const protocols = [
  { label: 'HTTP', value: 'http' },
  { label: 'SMTP', value: 'smtp' },
  { label: 'TCP', value: 'tcp' },
]

const methods = [
  { label: 'GET', value: 'get' },
  { label: 'PATCH', value: 'patch' },
  { label: 'POST', value: 'post' },
  { label: 'DELETE', value: 'delete' },
  { label: 'MML', value: 'mml' },
]

const responseTypes = [
  { label: 'XML', value: 'xml' },
  { label: 'JSON', value: 'json' },
]

const GeneralInfo = () => {
  return (
    <Wrapper title='General Info' className={styles.generalInfoWrapper}>
      <FormItem name='name' label='Name' rules={[{ required: true }]}>
        <TextField placeholder='Enter name' />
      </FormItem>
      <div className={styles.dblRow}>
        <FormItem name='type' label='Type'>
          <Select allowClear options={protocols} defaultValue='http' />
        </FormItem>
        <FormItem name='method' label='Method'>
          <Select allowClear options={methods} defaultValue='get' />
        </FormItem>
      </div>
      <FormItem name='link' label='Link' rules={[{ required: true, validator: urlValidator }]}>
        <TextArea placeholder='Enter link' />
      </FormItem>
      <FormItem name='body' label='Body' rules={[{ required: true }]}>
        <TextArea placeholder='Enter body' />
      </FormItem>
      <div className={styles.dblRow}>
        <FormItem name='responseType' label='Response type'>
          <Select allowClear options={responseTypes} defaultValue='json' />
        </FormItem>
        <FormItem name='requestsNumber' label='Number of requests' rules={[{ required: true }]}>
          <TextField type='number' placeholder='Enter number of requests' />
        </FormItem>
      </div>
      <div className={styles.dblRow}>
        <FormItem name='requestTimeout' label='request timeout' rules={[{ required: true }]}>
          <TextField type='number' placeholder='Enter request timeout' />
        </FormItem>
        <FormItem
          name='timeoutBetweenRequests'
          label='Timeout between requests'
          rules={[{ required: true }]}
        >
          <TextField type='number' placeholder='Enter timeout between requests' />
        </FormItem>
      </div>
    </Wrapper>
  )
}

export default GeneralInfo
