import { Switch } from 'antd'
import styles from './Toggle.module.scss'
import { SwitchProps } from 'antd/lib/switch'
import classNames from 'classnames'
import { ReactNode } from 'react'

interface IProps extends SwitchProps {
  text?: string
  classNameWrapper?: string
  children?: ReactNode
  inWrapper?: boolean
  isBlue?: boolean
}

export const Toggle = ({
  text,
  classNameWrapper,
  inWrapper,
  isBlue,
  ...rest
}: IProps): JSX.Element => {
  return text ? (
    <div
      className={classNames(styles['switch-wrapper'], classNameWrapper, {
        inWrapper,
      })}
    >
      {text}
      <Switch {...rest} />
    </div>
  ) : (
    <Switch {...rest} className={classNames({ 'ant-switch-blue': isBlue })} />
  )
}

export const ToggleWithWrapper = ({ text, classNameWrapper, children }: IProps): JSX.Element => {
  return (
    <div className={classNames(styles['switch-wrapper'], classNameWrapper)}>
      {text}
      {children}
    </div>
  )
}
