import { Table } from 'antd'
import { useGetStatementOfWalletsListQuery } from '../../../../core/http/StatementOfWalletsApi'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import styles from '../../styles.module.scss'
import { useTablePagination } from '../../../../../../shared/hooks/table/useTablePagination'
import { useTableColumns } from '../columns'

export const SummaryStatementTable = () => {
  const { data, isFetching } = useGetStatementOfWalletsListQuery()

  const emptyTableConf = useEmptyTable()
  const paginationConf = useTablePagination(data?.length)
  const columns = useTableColumns()

  return (
    <Table
      pagination={paginationConf}
      className={styles.table}
      locale={emptyTableConf}
      rowSelection={{ type: 'checkbox' }}
      rowKey={(row) => row.wallet}
      columns={columns}
      dataSource={data}
      loading={isFetching}
      scroll={{ x: 900 }}
    />
  )
}
