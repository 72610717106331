import { useAppDispatch } from '../../../../../../../redux'
import { useNavigate, useParams } from 'react-router'
import { Form, Spin } from 'antd'
import { useGetProfileQuery } from '../../../../../../Profile/core/http/Profile'
import {
  useCreateRewardPolicyMutation,
  useDeleteRewardPolicyMutation,
  useGetRewardPolicyByIdQuery,
  useUpdateRewardPolicyMutation,
} from '../../../../../core/http/RewardPolicyApi'
import React, { useCallback, useEffect } from 'react'
import {
  formatDateMomentToISO,
  formatDateWithTime,
  getFormCalendarTime,
} from '../../../../../../../helpers/utils'
import { setBreadcrumb } from '../../../../../../app/core/BreadcrumbsSlice'
import { DetailInfo } from '../DetailInfo'
import { Footer } from '../../../PromotionPolicyView/Components/Footer'
import { SETTINGS_ENUM } from '../../../../../../../routes/settings'
import { StepRegistration } from '../../../../../../../shared/components/stepRegistration'
import { MasterProducts } from '../MasterProducts'
import styles from '../../styles.module.scss'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { ErrorNode, showConfirmMessage } from '../../../../../../../shared/api/errorHandler'
import { Moment } from 'moment/moment'
import { IRewardPolicy, IRewardPolicyMutation } from '../../../../../models/IRewardPolicy'
import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import classNames from 'classnames'
import { useGetAccessChannelsListQuery } from '../../../../../core/http/DiscountPolicyApi'

export const InformationTab = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { id, mode } = useParams<{ id: string; mode?: 'view' | 'edit' }>()
  const [form] = Form.useForm()

  const { data: profile } = useGetProfileQuery()
  const { data, isFetching } = useGetRewardPolicyByIdQuery(id as string, { skip: !id })
  const { data: accessChannels } = useGetAccessChannelsListQuery()
  const [crRewPol, crRewPolResp] = useCreateRewardPolicyMutation()
  const [delRew, delRewResp] = useDeleteRewardPolicyMutation()
  const [updRewPol, updRewPolResp] = useUpdateRewardPolicyMutation()

  const afterMutation =
    (isCreate = false) =>
    () => {
      if (isCreate) {
        return navigate(
          `${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/reward-policy/view/${crRewPolResp?.data?.id}`
        )
      }

      return navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/reward-policy/view/${id}`)
    }

  const afterDelete = () => {
    navigate(SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION)
  }

  useNotification(NOTIFICATION_TYPES.success, crRewPolResp.isSuccess, null, afterMutation(true))
  useNotification(NOTIFICATION_TYPES.success, delRewResp.isSuccess, null, afterDelete)
  useNotification(NOTIFICATION_TYPES.success, updRewPolResp.isSuccess, null, afterMutation(false))
  useNotification(NOTIFICATION_TYPES.error, crRewPolResp.isError, crRewPolResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, delRewResp.isError, delRewResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, updRewPolResp.isError, updRewPolResp.error as ErrorNode)

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const handleDeleteClick = () => {
    showConfirmMessage('This reward policy will be deleted', () => delRew(id as string))
  }

  const setInitialValues = useCallback(() => {
    if (data && profile) {
      const { start, end, createdAt, masterProducts, ...rest } = data
      form.setFieldsValue({
        ...rest,
        start: getFormCalendarTime(profile?.calendar, start as string),
        end: getFormCalendarTime(profile?.calendar, end as string),
        createdAt: formatDateWithTime(createdAt, profile?.calendar),
        masterProducts: masterProducts
          ?.map(({ masterProduct, ...rest }) => ({
            ...rest,
            masterProduct,
          }))
          ?.sort((a, b) => Number(a?.id) - Number(b?.id)),
      })
    }
  }, [data, form, profile])

  const onFinish = (values: IRewardPolicy) => {
    const dataObj = {
      title: values?.title,
      measurementType: values?.measurementType,
      type: values?.type,
      ...(!mode && { status: true }),
      start: formatDateMomentToISO(profile?.calendar, values.start as unknown as Moment),
      end: formatDateMomentToISO(profile?.calendar, values.end as unknown as Moment),
      ...(values?.masterProducts && {
        masterProducts: values.masterProducts?.map((product) => ({
          masterProductId: product?.masterProduct?.id,
          status: !!product.status,
          from: +product.from,
          to: +product.to,
          rate: +product.rate,
        })),
      }),
      accessChannels: values.accessChannels,
      isOnce: !!values.isOnce,
    } as unknown as IRewardPolicyMutation

    isEditMode ? updRewPol({ id: id as string, body: dataObj }) : crRewPol(dataObj)
  }

  const onCancelClick = () => {
    if (!mode || isViewMode) {
      return navigate(SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION)
    }

    if (isEditMode) {
      form.resetFields()
      setInitialValues()
      return navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/reward-policy/view/${id}`)
    }
  }

  const onSubmitClick = () => {
    if (isViewMode) {
      return navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/reward-policy/edit/${id}`)
    }

    return form.submit()
  }

  useEffect(() => {
    if (!id && profile) {
      form.setFieldsValue({
        createdAt: formatDateWithTime(new Date(), profile?.calendar),
        createdByUser: {
          name: `${profile?.firstName} ${profile?.lastName}`,
        },
      })
    }
  }, [form, id, profile])

  useEffect(() => {
    if (data) {
      setInitialValues()
      dispatch(setBreadcrumb({ name: data?.title }))
    }
  }, [data, dispatch, setInitialValues])

  const isLoading = isFetching || crRewPolResp.isLoading || updRewPolResp.isLoading

  const content = (
    <>
      <Form
        form={form}
        layout='vertical'
        className={classNames(styles.form, { [styles.rewardInfoForm]: !!id })}
        onFinish={onFinish}
      >
        {!mode && <StepRegistration stepNumber={1} stepText='Detail Info' mT='11.5px' mB='32px' />}
        <DetailInfo view={isViewMode} isCreatePage={!!mode} accessChannels={accessChannels} />
        {!mode && <StepRegistration stepNumber={2} stepText='Products' mT='32px' mB='32px' />}
        {mode ? (
          <Wrapper title='Products'>
            <MasterProducts view={isViewMode} onViewPage={true} />
          </Wrapper>
        ) : (
          <MasterProducts view={false} onViewPage={false} />
        )}
      </Form>
    </>
  )

  return (
    <>
      {isLoading ? <Spin>{content}</Spin> : content}
      <Footer
        onCancelClick={onCancelClick}
        onSubmitClick={onSubmitClick}
        onDeleteClick={mode ? handleDeleteClick : undefined}
        mode={mode}
      />
    </>
  )
}
