import { FormInstance, Row, Select as AntSelect } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import classNames from 'classnames'
import { Select } from 'src/shared/components/select/select'
import { TextField } from 'src/shared/components/textfield/TextField'
import styles from './styles.module.scss'
import {
  AddIcon,
  BucketIcon,
  CopyIcon,
  FacebookMessengerIcon,
  IOSShareIcon,
  PhoneLightBlueIcon,
  TelegramIcon,
  ViberIcon,
  WhatsappIcon,
} from 'src/assets/svg'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { Wrapper } from '../Wrapper'
import * as V from 'src/helpers/validation'
import * as I from 'src/features/salesNetwork/models/IAgent'
import { DefaultOptionType } from 'antd/lib/select'
import { ErrorNode, handleError, handleInfo } from 'src/shared/api/errorHandler'

import { COUNTRIES_DATA } from '../WrapperReactFlagsSelectWithPhoneNumber/countryData'
import WrapperReactFlagsSelectWithPhoneNumber from '../WrapperReactFlagsSelectWithPhoneNumber/CountrySelect'
import { customAlphabet } from 'nanoid'

const { Option } = AntSelect

const nanoid = customAlphabet('1234567890', 18)

interface IContactInformation {
  view?: boolean
  form: FormInstance
  userIdentifiers: I.IAgentIdentifiers2[]
  setUserIdentifiers: I.SetStateType<I.IAgentIdentifiers2[] | I.IAgentIdentifiersPut[]>
  setUserDeleteIdentifiers?: I.SetStateType<number[]>
}

const UserIdentifiers = ({
  view = false,
  form,
  userIdentifiers,
  setUserIdentifiers,
  setUserDeleteIdentifiers,
}: IContactInformation) => {
  const options = [
    {
      identifier: null,
      agentIdentifierTypeId: I.IDENTIFIERS_TYPE_ENUM.MOBILE,
      icon: <PhoneLightBlueIcon />,
      active: true,
    },
    {
      identifier: null,
      agentIdentifierTypeId: I.IDENTIFIERS_TYPE_ENUM.WHATSAPP,
      icon: <WhatsappIcon />,
      active: true,
    },
    {
      identifier: null,
      agentIdentifierTypeId: I.IDENTIFIERS_TYPE_ENUM.FACEBOOK,
      icon: <FacebookMessengerIcon />,
      active: true,
    },
    {
      identifier: null,
      agentIdentifierTypeId: I.IDENTIFIERS_TYPE_ENUM.TELEGRAM,
      icon: <TelegramIcon />,
      active: true,
    },
    {
      identifier: null,
      agentIdentifierTypeId: I.IDENTIFIERS_TYPE_ENUM.VIBER,
      icon: <ViberIcon />,
      active: true,
    },
  ]

  const REQUIRED_OBJ_FIELD = { required: true, message: 'Field required' }

  const rulesObj = {
    [I.IDENTIFIERS_TYPE_ENUM.WHATSAPP]: [
      REQUIRED_OBJ_FIELD,
      { validator: V.commonValidator },
      { validator: V.maxLengthValidator25 },
    ],
    [I.IDENTIFIERS_TYPE_ENUM.FACEBOOK]: [
      REQUIRED_OBJ_FIELD,
      { validator: V.commonValidator },
      { validator: V.maxLengthValidator25 },
    ],
    // [I.IDENTIFIERS_TYPE_ENUM.MOBILE]: [
    //   REQUIRED_OBJ_FIELD,
    //   { validator: V.mobileNumberValidator },
    //   { validator: V.mobileNumberLength11 },
    // ],
  }

  type rulesType = keyof typeof rulesObj

  const onChange = (contact: I.IAgentIdentifiers2) => (obj: DefaultOptionType) => {
    const objSelectedOption = options.find((it) => it.agentIdentifierTypeId === obj?.value)
    setUserIdentifiers((pre) =>
      pre.map((currIt) =>
        currIt.createId === contact.createId
          ? { ...objSelectedOption!, createId: contact.createId }
          : { ...currIt }
      )
    )
  }

  const onCopyText = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        handleInfo('Text copied')
      })
      .catch((error) => handleError(error as ErrorNode))
  }
  const getDefaultSelectedCountryCodeByPhoneNumber = (phoneNumber: string) => {
    const defaultCountry = COUNTRIES_DATA.find((country) => phoneNumber.includes(country.dial_code))
    return defaultCountry?.code
  }

  const onChangeReactFlagSelectWithNumber =
    (identifier: I.IAgentIdentifiers2) => (identifierValue: string) => {
      setUserIdentifiers((pre) =>
        pre.map((currContact) =>
          currContact.createId === identifier.createId
            ? { ...currContact, identifier: identifierValue, status: 'edit' }
            : currContact
        )
      )
    }

  const onChangeTextFieldContactInfo =
    (identifier: I.IAgentIdentifiers2) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const identifierValue = e.target.value
      setUserIdentifiers((pre) =>
        pre.map((currContact) =>
          currContact.createId === identifier.createId
            ? { ...currContact, identifier: identifierValue, status: 'edit' }
            : currContact
        )
      )
    }

  const onDeleteContact = (identifier: I.IAgentIdentifiers2 | I.IAgentIdentifiersPut) => () => {
    identifier.agentIdentifierTypeId &&
      // form.setFieldValue([identifier.agentIdentifierTypeId, String(identifier.createId)], null)
      form.setFieldValue(['identifier', String(identifier.createId)], null)

    setUserIdentifiers((pre) => pre.filter((currIt) => currIt.createId != identifier.createId))

    const deleteIdentifierId = Number((identifier as I.IAgentIdentifiersPut)?.id)
    ;(identifier as I.IAgentIdentifiersPut)?.id &&
      setUserDeleteIdentifiers &&
      setUserDeleteIdentifiers((pre) => [...pre, deleteIdentifierId])
  }

  const onCopyTextContactField = (identifier: I.IAgentIdentifiers2) => () => {
    identifier?.identifier ? onCopyText(identifier.identifier as string) : () => {}
  }

  const onSelectCountry = (identifier: I.IAgentIdentifiers2) => (prefixNumber: string) => {
    identifier?.agentIdentifierTypeId &&
      // form.setFieldValue([identifier?.agentIdentifierTypeId, String(identifier.createId)], '')
      form.setFieldValue(['identifier', String(identifier.createId)], '')
  }

  const onAddNewEmptyIdentifier = () =>
    setUserIdentifiers((pre) => [
      ...pre,
      {
        createId: Number(nanoid()),
        identifier: null,
        agentIdentifierTypeId: null,
        active: true,
      },
    ])

  return (
    <Wrapper title='User Identifiers' className={styles['wrapper-user-identifiers']}>
      {userIdentifiers.map((identifier) => {
        const isTelegram = identifier?.agentIdentifierTypeId === I.IDENTIFIERS_TYPE_ENUM.TELEGRAM
        const isViber = identifier?.agentIdentifierTypeId === I.IDENTIFIERS_TYPE_ENUM.VIBER
        const isMobileNumber =
          identifier?.agentIdentifierTypeId === I.IDENTIFIERS_TYPE_ENUM.MOBILE ||
          isTelegram ||
          isViber
        const mobileNumberValue = identifier?.identifier
        const mobileNumberHaveValue = mobileNumberValue ? mobileNumberValue : false

        return (
          <Row align='top' wrap={false} key={identifier.createId} justify='space-between'>
            <FormItem className={classNames(styles['form-item-inp'], styles['select'])}>
              <Select
                placeholder='Select contact info'
                view={view}
                onChange={onChange(identifier)}
                defaultValue={identifier.agentIdentifierTypeId}
                allowClear
              >
                {options.map(({ agentIdentifierTypeId, icon }) => {
                  const isTelegram = agentIdentifierTypeId === I.IDENTIFIERS_TYPE_ENUM.TELEGRAM
                  const isViber = agentIdentifierTypeId === I.IDENTIFIERS_TYPE_ENUM.VIBER
                  const isViberOrTelegramm = isViber || isTelegram

                  return (
                    <Option key={agentIdentifierTypeId} value={agentIdentifierTypeId}>
                      <div className={styles['wrapper-content-option']}>
                        <div
                          className={classNames(styles['wrapper-icon'], {
                            [styles['viberOrTelegram']]: isViberOrTelegramm,
                          })}
                        >
                          {icon}
                        </div>
                        {/* ATENTION */}
                        <div>
                          {I.IDENTIFIERS_VALUE_FOR_DISPLAYED_BY_TYPE[agentIdentifierTypeId]}
                        </div>
                      </div>
                    </Option>
                  )
                })}
              </Select>
            </FormItem>
            {isMobileNumber && (
              <WrapperReactFlagsSelectWithPhoneNumber
                view={view}
                selectedCountry={
                  isMobileNumber && mobileNumberHaveValue
                    ? getDefaultSelectedCountryCodeByPhoneNumber(mobileNumberValue!)
                    : ''
                }
                form={form}
                name={
                  identifier?.agentIdentifierTypeId
                    ? ['identifier', String(identifier.createId)]
                    : undefined
                }
                // rules={rulesObj[identifier?.agentIdentifierTypeId as rulesType]}
                onSelect={onSelectCountry(identifier)}
                onChange={onChangeReactFlagSelectWithNumber(identifier)}
                // defaultCountryCode = {}
                defaultValue={identifier?.identifier ? identifier.identifier : undefined}
              />
            )}
            {!isMobileNumber && (
              <FormItem
                rules={rulesObj[identifier?.agentIdentifierTypeId as rulesType]}
                name={
                  identifier?.agentIdentifierTypeId
                    ? ['identifier', String(identifier.createId)]
                    : undefined
                }
                className={classNames(styles['form-item-inp'], styles['big-inp'])}
                initialValue={identifier.identifier}
              >
                <TextField disabled={view} onChange={onChangeTextFieldContactInfo(identifier)} />
              </FormItem>
            )}

            {view && (
              <div className={styles['wrapper-icons']}>
                <div className={styles['wrapper-icon']}>
                  <IconButton
                    onClick={onCopyTextContactField(identifier)}
                    slim
                    size='large'
                    color='orange'
                    icon={<CopyIcon />}
                    type='default'
                  />
                </div>

                <div className={styles['wrapper-icon']}>
                  <IconButton
                    slim
                    size='large'
                    color='blue'
                    icon={<IOSShareIcon />}
                    type='default'
                  />
                </div>
              </div>
            )}

            {!view && (
              <div className={classNames(styles['wrapper-icon'], styles['wrapper-bucket-icon'])}>
                <IconButton
                  slim
                  size='large'
                  color='orange'
                  icon={<BucketIcon />}
                  onClick={onDeleteContact(identifier)}
                  type='default'
                />
              </div>
            )}
          </Row>
        )
      })}
      {!view && (
        <div className={styles['wrapper-add-btn']}>
          <IconButton
            size='middle'
            block
            color='orange'
            icon={
              <div className={styles['wrapper-add-icon']}>
                <AddIcon />
              </div>
            }
            onClick={onAddNewEmptyIdentifier}
            type='default'
          ></IconButton>
        </div>
      )}
    </Wrapper>
  )
}
export default UserIdentifiers
