import { DetailInfo } from '../SimCardView/Components/InformationTab/DetailInfo'
import {
  useCreateVendorMutation,
  useGetVendorByIdQuery,
} from '../../../../../core/http/VendorManagementApi'
import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { isEmpty, omit } from 'lodash'
import { setBreadcrumb } from '../../../../../../app/core/BreadcrumbsSlice'
import { SETTINGS_ENUM } from '../../../../../../../routes/settings'
import { useAppDispatch } from '../../../../../../../redux'
import { Col, Form, Row, Space, Spin, UploadFile } from 'antd'
import classNames from 'classnames'
import { UploaderPhotos2 } from '../../../../../../../shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import { VendorMasterColorBlock } from '../../../Components/VendorMasterColorBlock'
import styles from '../../../styles.module.scss'
import { Button } from '../../../../../../../shared/components/button/Button'
import { formatDateWithTime } from '../../../../../../../helpers/utils'
import moment from 'moment'
import { useGetProfileQuery } from '../../../../../../Profile/core/http/Profile'
import { useNavigate } from 'react-router'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../shared/api/errorHandler'
import { RcFile } from 'antd/lib/upload'
import { IVendor } from '../../../../../models/IVendorManagement'

export const SimCardNew = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [picture, setPicture] = useState<RcFile>()
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()

  const { data: vendorData, isFetching: isFetchingVendor } = useGetVendorByIdQuery(id as string, {
    skip: !id,
  })
  const { data: profile, isFetching: isFetchingProfile } = useGetProfileQuery()
  const [createVendor, crVenResp] = useCreateVendorMutation()

  const afterSuccess = () => {
    if (!crVenResp.data?.id) return
    return navigate(
      `${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${id}/sim-card/view/${crVenResp.data.id}`
    )
  }

  useNotification(NOTIFICATION_TYPES.success, crVenResp.isSuccess, null, afterSuccess)
  useNotification(NOTIFICATION_TYPES.error, crVenResp.isError, crVenResp.error as ErrorNode)

  useEffect(() => {
    if (!vendorData?.name) return
    dispatch(setBreadcrumb({ name: vendorData.name, key: SETTINGS_ENUM.VENDOR_MANAGEMENT_VIEW }))
  }, [dispatch, vendorData])

  const onFinish = (values: IVendor) => {
    const body = new FormData()
    Object.entries(omit(values, 'operator')).forEach((item) =>
      body.append(item[0], item[1] as string)
    )

    body.append('operatorId', String(values.operator?.id))
    body.append('groupVendorId', id as string)

    body.append('logo', picture!)
    createVendor(body)
  }

  const onCancelButtonClick = () => {
    return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${id}`)
  }

  const onSubmitButtonClick = () => {
    form.submit()
  }

  useEffect(() => {
    if (isEmpty(profile)) return

    form.setFieldsValue({
      createdBy: `${profile?.firstName} ${profile?.lastName}`,
      createdAt: formatDateWithTime(moment().toISOString(), profile?.calendar),
    })
  }, [form, profile])

  const isLoading = isFetchingProfile || isFetchingVendor || crVenResp.isLoading

  const content = (
    <>
      <Form layout='vertical' form={form} className={styles.layout} onFinish={onFinish}>
        <div className={styles.columnWrapper}>
          <div className={styles.leftColumn}>
            <DetailInfo />
          </div>
          <div className={classNames(styles.rightBlock, styles.withoutMargin)}>
            <UploaderPhotos2
              form={form}
              title='Sim Card Logo'
              isProductPreview
              view={false}
              onChange={(photo: UploadFile[]) => {
                setPicture(photo[0].originFileObj as RcFile)
              }}
            />
            <VendorMasterColorBlock title='Sim Card Color' />
          </div>
        </div>
      </Form>
      <Row className={styles.simCardFooter} justify='end'>
        <Col>
          <Space size={10}>
            <div className='cancelButtonWrapper'>
              <Button color='blue' size='middle' onClick={onCancelButtonClick} block>
                Cancel
              </Button>
            </div>
            <Button
              htmlType='submit'
              size='middle'
              type='primary'
              onClick={onSubmitButtonClick}
              block
            >
              Create Sim Card
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
