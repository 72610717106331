import Table from 'antd/es/table'
import { formatNumberToLocale } from '../../../../../salesNetwork/helpers'
import React from 'react'
import { IReverseTransaction } from '../../../../models/IReverseTransaction'

export const ReverseTransactionsTableFooter = ({ data }: { data: IReverseTransaction[] }) => {
  const emoneyAmount = data?.reduce((prev, curr) => prev + curr.amount, 0)

  return (
    <Table.Summary.Row className='table-footer-total'>
      <Table.Summary.Cell index={0} colSpan={8}>
        Total
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1} colSpan={3}>
        <div>{formatNumberToLocale(emoneyAmount)}</div>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  )
}
