import styles from '../../DiscountPolicyView/Components/styles.module.scss'
import { Form, Select, Spin } from 'antd'
import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../shared/components/textfield/TextField'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { RightModalContext } from '../../../../../Modals'
import { Button } from '../../../../../../shared/components/button/Button'
import { useGetAgentTypeListQuery } from '../../../../core/http/SystemConfigurationApi'
import { useGetAgentLevelListQuery } from '../../../../../salesNetwork/core/http/AgentManagement'
import {
  ITargetGroup,
  ITargetGroupCreate,
  ITargetGroupSize,
  PAYMENT_POLICY_TYPE_ENUM,
} from '../../../../models/ITargetGroup'
import {
  useCreateTargetGroupMutation,
  useGetTargetGroupByIdQuery,
  useLazyGetTargetGroupSizeQuery,
  useUpdateTargetGroupMutation,
} from '../../../../core/http/TargetGroupsApi'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../shared/api/errorHandler'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import { maxLengthValidator, requiredValidator } from '../../../../../../helpers/validation'
import { useAppDispatch } from '../../../../../../redux'
import { discountPolicyApi } from '../../../../core/http/DiscountPolicyApi'
import { AGENT_TYPE_ID_ENUM } from '../../../../models/IAgentType'
import { promotionPolicyApi } from '../../../../core/http/PromotionPolicyApi'
import { isNumber, omit } from 'lodash'

export const TargetGroupViewModal = () => {
  const [groupSizeParams, setGroupSizeParams] = useState<ITargetGroupSize>({} as ITargetGroupSize)
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const {
    onOpen,
    props: { id, mode },
  } = useContext(RightModalContext)

  const [crTG, crTGResp] = useCreateTargetGroupMutation()
  const [upTG, upTGResp] = useUpdateTargetGroupMutation()
  const [grSize, grSizeResp] = useLazyGetTargetGroupSizeQuery()
  const { data, isFetching } = useGetTargetGroupByIdQuery(id, { skip: !id })
  const { data: agentTypes, isFetching: isFetchingAgentTypes } = useGetAgentTypeListQuery({})
  const { data: agentLevels, isFetching: isFetchingAgentLevels } = useGetAgentLevelListQuery()

  useNotification(NOTIFICATION_TYPES.success, crTGResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, upTGResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, crTGResp.isError, crTGResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, upTGResp.isError, upTGResp.error as ErrorNode)

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const walletTypes = useMemo(
    () =>
      (Object.keys(PAYMENT_POLICY_TYPE_ENUM) as Array<keyof typeof PAYMENT_POLICY_TYPE_ENUM>).map(
        (method) => ({
          label: PAYMENT_POLICY_TYPE_ENUM[method],
          value: PAYMENT_POLICY_TYPE_ENUM[method],
        })
      ),
    []
  )

  const onFinish = (values: ITargetGroup) => {
    const dataObj = {
      ...values,
      status: !!values.status,
    } as unknown as ITargetGroupCreate

    isEditMode ? upTG({ id, body: dataObj }) : crTG(dataObj)
  }

  const onSelectChange = (key: keyof ITargetGroupSize, value: number) => {
    if (key === 'agentTypeId') {
      form.setFieldValue('agentLevelId', null)
      onClear('agentLevelId')
    }

    setGroupSizeParams((prevState) => ({ ...prevState, [key]: value }))
  }

  const onClear = (fieldName: keyof ITargetGroupSize) => {
    if (fieldName === 'agentTypeId') {
      form.setFieldValue('agentLevelId', null)
      onClear('agentLevelId')
    }

    let prevState = { ...groupSizeParams }
    form.setFieldValue('groupSize', null)
    prevState = omit(prevState, [fieldName]) as ITargetGroupSize
    setGroupSizeParams(prevState)
  }

  const isLoading =
    isFetchingAgentTypes || isFetchingAgentLevels || isFetching || grSizeResp.isLoading
  const isMutating = crTGResp.isLoading || upTGResp.isLoading

  useEffect(() => {
    if (data) {
      setGroupSizeParams({
        agentLevelId: data?.agentLevel?.id,
        agentTypeId: data?.agentTypeId,
        paymentPolicyType: data?.paymentPolicyType,
      })
    }
  }, [data])

  useEffect(() => {
    if (Object.values(groupSizeParams).length === 3) {
      grSize(groupSizeParams)
    }
  }, [grSize, groupSizeParams])

  useEffect(() => {
    if (isNumber(grSizeResp.data) && grSizeResp.status) {
      form.setFieldValue('groupSize', grSizeResp.data)
    }
  }, [form, grSizeResp.data, grSizeResp.status])

  useEffect(() => {
    if (crTGResp?.data) {
      onOpen(RIGHT_MODALS.SETTINGS.NETWORK_CONFIGURATION_TARGET_GROUP, {
        title: 'View Target Group',
        id: crTGResp.data.id,
        mode: 'view',
      })
    }
    // eslint-disable-next-line
  }, [crTGResp.data])

  useEffect(() => {
    if (upTGResp?.data) {
      onOpen(RIGHT_MODALS.SETTINGS.NETWORK_CONFIGURATION_TARGET_GROUP, {
        title: 'View Target Group',
        id: upTGResp.data.id,
        mode: 'view',
      })
      dispatch(discountPolicyApi.util.invalidateTags(['DiscountTargetGroups']))
      dispatch(promotionPolicyApi.util.invalidateTags(['TargetGroups']))
    }
    // eslint-disable-next-line
  }, [dispatch, upTGResp.data])

  const statusButton = (
    <FormItem name='status' className='noBottomMargin' valuePropName='checked'>
      <Toggle disabled={isViewMode} />
    </FormItem>
  )

  const content = (
    <div className={styles.layout}>
      <Form
        form={form}
        layout='vertical'
        className={styles.targetGroupForm}
        initialValues={data}
        onFinish={onFinish}
      >
        <Wrapper title='Status' statusButton={statusButton} />
        <Wrapper title='Target Group Name'>
          <FormItem
            name='name'
            label='Target Group Name'
            rules={[
              {
                required: true,
                validator: requiredValidator('Target Group Name'),
              },
              {
                validator: maxLengthValidator(45),
              },
            ]}
          >
            <TextField disabled={isViewMode} placeholder='Enter Target Group Name' />
          </FormItem>

          <FormItem
            name='agentTypeId'
            label='Agent Type'
            rules={[
              {
                required: true,
                validator: requiredValidator('Agent Type'),
              },
            ]}
          >
            <Select
              disabled={isViewMode}
              allowClear
              onClear={() => onClear('agentTypeId')}
              onSelect={(value) => onSelectChange('agentTypeId', value)}
              placeholder='Select Agent Type'
            >
              {agentTypes?.items.map((type) => (
                <Select.Option key={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>

          <FormItem shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <FormItem
                name='agentLevelId'
                label='Agent Level'
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('Agent Level'),
                  },
                ]}
              >
                <Select
                  disabled={isViewMode || !getFieldValue('agentTypeId')}
                  allowClear
                  onClear={() => onClear('agentLevelId')}
                  onSelect={(value) => onSelectChange('agentLevelId', value)}
                  placeholder='Select Agent Level'
                >
                  {agentLevels?.items
                    .filter((lev) => {
                      if (getFieldValue('agentTypeId') === AGENT_TYPE_ID_ENUM.INDIRECT) {
                        return lev
                      }
                      return lev.level === 1
                    })
                    .map((level) => (
                      <Select.Option key={level.id} value={level.id}>
                        {level.level}
                      </Select.Option>
                    ))}
                </Select>
              </FormItem>
            )}
          </FormItem>

          <FormItem
            name='paymentPolicyType'
            label='Wallet Type'
            rules={[
              {
                required: true,
                validator: requiredValidator('Wallet Type'),
              },
            ]}
          >
            <Select
              options={walletTypes}
              disabled={isViewMode}
              onClear={() => onClear('paymentPolicyType')}
              allowClear
              onSelect={(value) => onSelectChange('paymentPolicyType', value)}
              placeholder='Select Wallet Type'
            />
          </FormItem>

          <FormItem name='groupSize' label='Group Size' className='noBottomMargin'>
            <TextField disabled placeholder='Group Size' />
          </FormItem>
        </Wrapper>
        {!isViewMode && (
          <FormItem>
            <Button color='orange' type='primary' htmlType='submit' block>
              {isEditMode ? 'Edit Target Group' : 'Add Target Group'}
            </Button>
          </FormItem>
        )}
      </Form>
    </div>
  )

  return isLoading || isMutating ? <Spin>{content}</Spin> : content
}
