import { TableProps } from 'antd'
import { SorterResult } from 'antd/lib/table/interface'
import { ITableConf, SortingOrderEnum } from '../../models/ITableConf'
import { Dispatch, SetStateAction } from 'react'

export const useOnTableChange =
  <T,>(
    setTableConf: React.Dispatch<React.SetStateAction<ITableConf>>,
    setExpandedRowKeys?: Dispatch<SetStateAction<Array<React.Key>>>
  ): TableProps<T>['onChange'] =>
  (pagination, filters, sorter) => {
    const { order, field } = sorter as SorterResult<T>

    let orderType: SortingOrderEnum
    if (order) {
      orderType = order === 'ascend' ? SortingOrderEnum.ASC : SortingOrderEnum.DESC
    }

    let orderField = field
    if (field === 'firstName' || field === 'lastName') {
      orderField = 'name'
    }

    setTableConf((p) => ({
      ...p,
      ...(orderType && { orderType }),
      ...(orderField && { orderField: orderField as string }),
      page: pagination.current,
      limit: pagination.pageSize,
    }))
    setExpandedRowKeys?.([])
  }
