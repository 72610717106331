import { memo, useCallback, useEffect, useMemo } from 'react'
import { Space, Tabs } from 'antd'
import { OVERVIEW_TABS_ENUM } from './index.d'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { AddIcon } from '../../../../../../assets/svg'
import { AllCasesTable } from '../AllCasesTable'
import { useLocation, useNavigate } from 'react-router'
import usePermissions from '../../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../../Settings/models/IPermissions'
import { TICKET_STATUS_ENUM } from 'src/features/Support/models/ITicket'
import WithPermissionDeniedContainer from '../../../../../../shared/components/withPermissionDenied'
import { useSearchParams } from 'react-router-dom'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'

interface IPropsOperations {
  onAddButtonClick: () => void
  canAddNew: boolean
}

const Operations = memo(({ onAddButtonClick, canAddNew }: IPropsOperations) => (
  <Space size={10}>
    {canAddNew && (
      <IconButton icon={<AddIcon />} type='primary' color='orange' onClick={onAddButtonClick} />
    )}
  </Space>
))

export const OverviewTabs = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: OVERVIEW_TABS_ENUM.MY_TICKETS,
  })

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()

  const [overviewsId, ticketDetailId] = getModulesIdsByNames([
    IPermissionsNames['List of Tickets'],
    IPermissionsNames['Ticket Detail View'],
  ])

  const demandPermissions = [
    IPermissionsActions.VIEW,
    IPermissionsActions.ADD,
    IPermissionsActions.EXPORT,
    IPermissionsActions.EDIT,
    IPermissionsActions.DELETE,
  ]

  const [canViewAllTickets, canAddTicket, canExportAllTicket] = canPerformAction(
    overviewsId,
    demandPermissions
  )

  const [canViewDetails, _, __, canAddEditTicket, canDeleteTicket] = canPerformAction(
    ticketDetailId,
    demandPermissions
  )

  /** Table */
  const currentTabData = useMemo(() => {
    if (activeTab) {
      return {
        navigateTo: 'ticket/new',
        canAdd: canAddTicket,
      }
    }
    return {
      canAdd: false,
    }
  }, [activeTab, canAddTicket, canExportAllTicket])

  const items = useMemo(
    () => [
      {
        label: OVERVIEW_TABS_ENUM.MY_TICKETS,
        key: OVERVIEW_TABS_ENUM.MY_TICKETS,
        children: (
          <>
            <WithPermissionDeniedContainer isAllowedView={canViewAllTickets}>
              <AllCasesTable
                canEdit={canAddEditTicket}
                canView={canViewDetails}
                canDelete={canDeleteTicket}
                assigned
              />
            </WithPermissionDeniedContainer>
          </>
        ),
      },
      {
        label: OVERVIEW_TABS_ENUM.OPEN_TICKETS,
        key: OVERVIEW_TABS_ENUM.OPEN_TICKETS,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canViewAllTickets}>
            <AllCasesTable
              canEdit={canAddEditTicket}
              canView={canViewDetails}
              canDelete={canDeleteTicket}
              status={TICKET_STATUS_ENUM.OPEN}
            />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: OVERVIEW_TABS_ENUM.RESOLVED_TICKETS,
        key: OVERVIEW_TABS_ENUM.RESOLVED_TICKETS,
        children: (
          <>
            <WithPermissionDeniedContainer isAllowedView={canViewAllTickets}>
              <AllCasesTable
                canEdit={canAddEditTicket}
                canView={canViewDetails}
                canDelete={canDeleteTicket}
                status={TICKET_STATUS_ENUM.RESOLVED}
              />
            </WithPermissionDeniedContainer>
          </>
        ),
      },
    ],
    [canAddEditTicket, canViewDetails, canViewAllTickets, canDeleteTicket]
  )

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  const onAddButtonClick = useCallback(() => {
    if (currentTabData?.navigateTo) {
      return navigate(currentTabData.navigateTo)
    }
  }, [currentTabData])

  return (
    <Tabs
      destroyInactiveTabPane
      activeKey={activeTab}
      items={items}
      tabBarExtraContent={
        <Operations onAddButtonClick={onAddButtonClick} canAddNew={currentTabData.canAdd} />
      }
      onChange={onTabChange}
    />
  )
}
