import FormItem from 'antd/es/form/FormItem'
import MaskedInput from 'react-text-mask'
import { Form, FormInstance } from 'antd'

import { IPValidator } from 'src/helpers/validation'

import styles from './styles.module.scss'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from 'src/assets/svg'
import { Wrapper } from '../Wrapper'
import { Toggle } from 'src/shared/components/toggle/Toggle'

interface IIPBlock {
  view?: boolean
  isWhitelist: boolean
  form: FormInstance
}

const config = {
  guide: false,
  placeholderChar: '\u2000',
  mask: (value: string) => Array(value.length).fill(/[\d.]/),
  pipe: (value: string) => {
    if (value === '.' || value.endsWith('..')) return false

    const parts = value.split('.')

    if (parts.length > 4 || parts.some((part) => part === '00' || +part < 0 || +part > 255)) {
      return false
    }

    return value
  },
}

const IPBlock = ({ view = false, form, isWhitelist }: IIPBlock) => {
  const handleChangeStatus = () => {
    form.setFieldValue(['resellerCredentials', 'isWhitelist'], !isWhitelist)
  }

  const statusButton = (
    <FormItem name={['resellerCredentials', 'isWhitelist']} style={{ marginBottom: 0 }}>
      <Toggle
        disabled={view}
        defaultChecked={isWhitelist}
        onChange={handleChangeStatus}
        inWrapper
        text={isWhitelist ? 'Active' : 'Inactive'}
      />
    </FormItem>
  )

  return (
    <Wrapper title='ip address' className={styles.wrapperContainer} statusButton={statusButton}>
      <Form.List name={['resellerCredentials', 'ips']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} className={styles.contactItemContainer}>
                <FormItem
                  {...restField}
                  style={{ width: '100%' }}
                  name={[name]}
                  label='IP Address'
                  rules={[
                    ...(view
                      ? []
                      : [
                          { required: true, message: 'Field required!' },
                          {
                            validator: IPValidator,
                          },
                        ]),
                  ]}
                >
                  <MaskedInput
                    {...config}
                    className={styles.maskedInput}
                    placeholder='***.***.***.***'
                    disabled={view}
                  />
                </FormItem>
                {!view && (
                  <Form.Item>
                    <div className={styles.bucketIcon}>
                      <IconButton
                        block
                        size='large'
                        color='orange'
                        icon={<BucketIcon />}
                        type='default'
                        disabled={view || fields.length === 1}
                        onClick={() => {
                          remove(name)
                        }}
                      />
                    </div>
                  </Form.Item>
                )}
              </div>
            ))}
            {!view && (
              <div style={{ display: 'flex', width: '92px' }}>
                <IconButton icon={<AddIcon />} color='orange' block onClick={() => add()} />
              </div>
            )}
          </>
        )}
      </Form.List>
    </Wrapper>
  )
}

export default IPBlock
