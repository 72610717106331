import { PermissionsTable } from '../../../../Permissions'
import React, { useCallback, useEffect, useState } from 'react'
import { IGroupCreate, IPermissionCreate } from '../../../../../../../models/IRoles'
import {
  useGetPermissionsByRoleIdQuery,
  useUpdateRoleMutation,
  useGetRoleByIdQuery,
} from '../../../../../../../core/http/RolesAndPermissionsManagement'
import { Spin } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import FooterActions from '../../../../FooterActions'
import { SETTINGS_ENUM } from '../../../../../../../../../routes/settings'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../../../shared/api/errorHandler'
import styles from '../../styles.module.scss'

interface IRequestBody {
  permissions?: Array<IPermissionCreate>
  groups?: Array<IGroupCreate>
}

interface IProps {
  canEditPermissions: boolean
}

const RolePermissionsView = ({ canEditPermissions }: IProps) => {
  const navigate = useNavigate()

  const { mode, id } = useParams<{ mode: 'view' | 'edit'; id: string }>()

  const {
    data: role,
    isLoading: roleLoading,
    isSuccess: roleSuccess,
  } = useGetRoleByIdQuery(id as string, { skip: !id })
  const { data, isLoading, isSuccess } = useGetPermissionsByRoleIdQuery(id as string, { skip: !id })
  const [updateRole, updateRoleResp] = useUpdateRoleMutation()

  const [permissions, setPermissions] = useState<Array<IPermissionCreate>>([])
  const [groups, setGroups] = useState<Array<IGroupCreate>>([])

  const isViewMode = mode === 'view'

  useNotification(NOTIFICATION_TYPES.success, updateRoleResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    updateRoleResp.isError,
    updateRoleResp.error as ErrorNode
  )

  useEffect(() => {
    if (isSuccess) {
      setPermissions(data)
    }
    // eslint-disable-next-line
  }, [isSuccess])

  useEffect(() => {
    if (roleSuccess) {
      const initialGroups = Array.isArray(role?.permissionModuleGroups)
        ? role?.permissionModuleGroups.map(
            (group) =>
              ({
                group: group.id,
                access: group.access,
              } as IGroupCreate)
          )
        : []
      setGroups(initialGroups)
    }
  }, [role?.permissionModuleGroups, roleSuccess])

  useEffect(() => {
    if (updateRoleResp.isSuccess) {
      navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT_ROLES_AND_PERMISSIONS}/view/${id}`)
    }
    // eslint-disable-next-line
  }, [updateRoleResp.isSuccess])

  const onPressEditPermissions = useCallback(() => {
    navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT_ROLES_AND_PERMISSIONS}/edit/${id}`)
    // eslint-disable-next-line
  }, [])

  const _checkIfPermissionSelectedInState = (permission: IPermissionCreate) => {
    return !!permissions.find(
      (p) =>
        p.permissionModuleId === permission.permissionModuleId &&
        p.permission === permission.permission
    )
  }

  const _onSelectSinglePermission = (permission: IPermissionCreate) => {
    const selectedPermission = _checkIfPermissionSelectedInState(permission)

    if (selectedPermission) {
      return setPermissions((prev) => [
        ...prev.filter((p) => {
          return !(
            p.permissionModuleId === permission.permissionModuleId &&
            p.permission === permission.permission
          )
        }),
      ])
    }

    setPermissions((prev) => [...prev, permission])
  }

  const _onSelectMultiplePermissions = (
    selectedOnActions: Array<IPermissionCreate>,
    checked: boolean | undefined
  ) => {
    if (!checked) {
      const updated = permissions.filter(
        (sisPerm) =>
          !selectedOnActions.find(
            (eoAPerm) =>
              eoAPerm.permissionModuleId === sisPerm.permissionModuleId &&
              eoAPerm.permission === sisPerm.permission
          )
      )
      return setPermissions(updated)
    }

    const updated = [
      ...selectedOnActions.filter(
        (soaPerm) =>
          !permissions.find(
            (eoAPerm) =>
              eoAPerm.permissionModuleId === soaPerm.permissionModuleId &&
              eoAPerm.permission === soaPerm.permission
          )
      ),
      ...permissions,
    ]

    setPermissions(updated)
  }

  const onCheckPermission = (
    permission: IPermissionCreate | Array<IPermissionCreate>,
    checked: boolean | undefined
  ) => {
    if (Array.isArray(permission)) {
      _onSelectMultiplePermissions(permission, checked)
    } else {
      _onSelectSinglePermission(permission)
    }
  }

  const onChangeGroupStatus = (group: { group: number; access: boolean }) => {
    const index = groups.findIndex((el) => el.group === group.group)
    const updated = [...groups]
    if (index !== -1) {
      updated[index] = group
      setGroups(updated)
    } else {
      setGroups((prev) => [...prev, group])
    }
  }

  const onPressCancelChanges = () => {
    navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT_ROLES_AND_PERMISSIONS}/view/${id}`)
  }
  const onPressSaveChanges = () => {
    if (isViewMode) {
      return onPressEditPermissions()
    }
    const reqBody: IRequestBody = {
      permissions,
      groups,
    }
    updateRole({
      id,
      body: reqBody,
    })
  }

  const content = (
    <PermissionsTable
      selectedGroups={groups}
      selectedPermissions={permissions}
      onCheckPermission={onCheckPermission}
      onChangeGroupStatus={onChangeGroupStatus}
    />
  )

  return (
    <div className={styles.innerContainer}>
      {isLoading || roleLoading || updateRoleResp.isLoading ? <Spin>{content}</Spin> : content}
      {canEditPermissions && (
        <FooterActions
          submitButtonText={isViewMode ? 'Edit Permissions' : 'Save Changes'}
          submitButtonDisabled={!canEditPermissions}
          onPressCancelChanges={onPressCancelChanges}
          onPressSaveChanges={onPressSaveChanges}
          isLoading={isLoading || updateRoleResp.isLoading}
          isViewMode={isViewMode}
        />
      )}
    </div>
  )
}

export default RolePermissionsView
