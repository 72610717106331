import { memo, useCallback, useContext, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Space, Tabs } from 'antd'

import { UsersManagement } from './Users'
import { DepartmentsManagement } from './Departments'
import { AddIcon, CloudDownloadIcon } from 'src/assets/svg'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { Button } from 'src/shared/components/button/Button'
import { NotFoundPage } from '../../../../../NotFoundPage'
import WithPermissionDeniedContainer from '../../../../../../shared/components/withPermissionDenied'

import { USER_MANAGEMENT_TABS_ENUM } from './index.d'
import { CENTER_MODALS } from '../../../../../../helpers/contants'
import { CenterModalContext } from '../../../../../Modals'
import { Emitter, EmitterEvents } from '../../../../../../helpers/eventEmitter'
import { IPermissionsActions, IPermissionsNames } from '../../../../models/IPermissions'
import usePermissions from '../../../../../../shared/hooks/usePermissions'
import { TableExportFormatEnum } from '../../../../../../shared/hooks/table/useTableExport'
import { Positions } from './Positions'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'

interface IPropsOperations {
  canAddNew: boolean
  canExport: boolean
  onAddButtonClick: () => void
}

const Operations = memo(({ onAddButtonClick, canAddNew, canExport }: IPropsOperations) => {
  const { onOpen, onClose } = useContext(CenterModalContext)

  const handlerExportClick = () => {
    onOpen(CENTER_MODALS.EXPORT_TABLE, {
      title: 'Export Table',
      onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
      onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      footer: <Button onClick={onClose}>Cancel</Button>,
    })
  }

  return (
    <Space size={10}>
      {canExport && (
        <IconButton
          icon={<CloudDownloadIcon />}
          type='primary'
          color='blue'
          disabled={!canExport}
          onClick={handlerExportClick}
        />
      )}
      {canAddNew && (
        <IconButton
          disabled={!canAddNew}
          icon={<AddIcon />}
          type='primary'
          color='orange'
          onClick={onAddButtonClick}
        />
      )}
    </Space>
  )
})

export const UserManagementTabs = () => {
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: USER_MANAGEMENT_TABS_ENUM.USER_DEPARTMENTS,
  })

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()

  const [usersId, departmentsId, rolesId] = getModulesIdsByNames([
    IPermissionsNames.Users,
    IPermissionsNames.Departments,
    IPermissionsNames.Roles,
  ])

  const demandPermissions = [
    IPermissionsActions.VIEW,
    IPermissionsActions.ADD,
    IPermissionsActions.EXPORT,
    IPermissionsActions.EDIT,
    IPermissionsActions.DELETE,
  ]

  const [canShowUsers, canAddUser, canExportUsers, canEditUser, canDeleteUser] = canPerformAction(
    usersId,
    demandPermissions
  )

  const [
    canShowDepartments,
    canAddDepartment,
    canExportDepartments,
    canEditDepartment,
    canDeleteDepartment,
  ] = canPerformAction(departmentsId, demandPermissions)

  const [canShowRoles, canAddRole, canExportRoles, canEditRole, canDeleteRole] = canPerformAction(
    rolesId,
    demandPermissions
  )

  /** Table */
  const currentTabData = useMemo(() => {
    if (activeTab === USER_MANAGEMENT_TABS_ENUM.USER_MANAGEMENT) {
      return {
        navigateTo: 'new',
        canAdd: canAddUser,
        canExport: canExportUsers,
      }
    }
    if (activeTab === USER_MANAGEMENT_TABS_ENUM.USER_DEPARTMENTS) {
      return {
        navigateTo: 'departments/new',
        canAdd: canAddDepartment,
        canExport: canExportDepartments,
      }
    }
    // if (activeTab === USER_MANAGEMENT_TABS_ENUM.USER_ROLES_AND_PERMISSIONS) {
    //   return {
    //     navigateTo: 'roles-and-permissions/new',
    //     canAdd: canAddRole,
    //     canExport: canExportRoles,
    //   }
    // }
    if (activeTab === USER_MANAGEMENT_TABS_ENUM.USER_POSITIONS) {
      return {
        navigateTo: 'positions/new',
        canAdd: true,
        canExport: false,
      }
    }
    if (activeTab === USER_MANAGEMENT_TABS_ENUM.USER_TIMESHEET) {
      return {
        navigateTo: 'timesheet/new',
        canAdd: false,
        canExport: false,
      }
    }
    return {
      canAdd: false,
      canExport: false,
    }
  }, [
    activeTab,
    canAddDepartment,
    canAddRole,
    canAddUser,
    canExportDepartments,
    canExportRoles,
    canExportUsers,
  ])

  const items = useMemo(
    () => [
      {
        label: USER_MANAGEMENT_TABS_ENUM.USER_DEPARTMENTS,
        key: USER_MANAGEMENT_TABS_ENUM.USER_DEPARTMENTS,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canShowDepartments}>
            <DepartmentsManagement
              canEditDepartment={canEditDepartment}
              canDeleteDepartment={canDeleteDepartment}
            />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: USER_MANAGEMENT_TABS_ENUM.USER_POSITIONS,
        key: USER_MANAGEMENT_TABS_ENUM.USER_POSITIONS,
        children: <Positions />,
      },
      {
        label: USER_MANAGEMENT_TABS_ENUM.USER_MANAGEMENT,
        key: USER_MANAGEMENT_TABS_ENUM.USER_MANAGEMENT,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canShowUsers}>
            <UsersManagement canEditUser={canEditUser} canDeleteUser={canDeleteUser} />
          </WithPermissionDeniedContainer>
        ),
      },
      // {
      //   label: USER_MANAGEMENT_TABS_ENUM.USER_ROLES_AND_PERMISSIONS,
      //   key: USER_MANAGEMENT_TABS_ENUM.USER_ROLES_AND_PERMISSIONS,
      //   children: (
      //     <WithPermissionDeniedContainer isAllowedView={canShowRoles}>
      //       <RolesManagement canEditRole={canEditRole} canDeleteRole={canDeleteRole} />
      //     </WithPermissionDeniedContainer>
      //   ),
      // },
      {
        label: USER_MANAGEMENT_TABS_ENUM.USER_TIMESHEET,
        key: USER_MANAGEMENT_TABS_ENUM.USER_TIMESHEET,
        children: <NotFoundPage />,
      },
    ],
    [
      canDeleteDepartment,
      canDeleteRole,
      canDeleteUser,
      canEditDepartment,
      canEditRole,
      canEditUser,
      canShowDepartments,
      canShowRoles,
      canShowUsers,
    ]
  )

  const onAddButtonClick = useCallback(() => {
    if (currentTabData?.navigateTo) {
      return navigate(currentTabData.navigateTo)
    }
  }, [currentTabData])

  return (
    <Tabs
      destroyInactiveTabPane
      activeKey={activeTab}
      tabBarExtraContent={
        <Operations
          onAddButtonClick={onAddButtonClick}
          canAddNew={currentTabData.canAdd}
          canExport={currentTabData.canExport}
        />
      }
      onChange={onTabChange}
      items={items}
    />
  )
}
