import React from 'react'
import classNames from 'classnames'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from 'src/assets/svg'
import styles from './styles.module.scss'
import { Select } from 'antd'
import FormItem from 'antd/es/form/FormItem'

const conditionOptions = [
  {
    label: 'Less than',
    value: 1,
  },
  {
    label: 'Between',
    value: 2,
  },
  {
    label: 'More than',
    value: 3,
  },
]

const conditionValueOptions = [
  {
    label: '3 month',
    value: 1,
  },
  {
    label: '3 - 6 month',
    value: 2,
  },
  {
    label: '6 - 12 month',
    value: 3,
  },
  {
    label: '12 month',
    value: 4,
  },
]

const valueOptions = [
  {
    label: '+5',
    value: 1,
  },
  {
    label: '-5',
    value: 2,
  },
]

const CreditScoreDetailParameters = () => {
  return (
    <Wrapper title='Parameters'>
      <div className={styles.parametersItemContainer}>
        <div className={styles.dblRow}>
          <div className={classNames(styles.dblRow, styles.inputsContainer)}>
            <FormItem name='condition' label='Сondition' style={{ marginBottom: 0 }}>
              <Select allowClear options={conditionOptions} defaultValue={1} />
            </FormItem>
            <FormItem name='on' label='' style={{ marginBottom: 0 }}>
              <Select allowClear options={conditionValueOptions} defaultValue={1} />
            </FormItem>
          </div>
          <div className={styles.dblRow} style={{ maxWidth: 218 }}>
            <FormItem name='on' label='Value' style={{ marginBottom: 8, maxWidth: 150 }}>
              <Select allowClear options={valueOptions} defaultValue={1} />
            </FormItem>
            <IconButton
              color='orange'
              icon={<BucketIcon />}
              style={{ marginBottom: 8, height: 40 }}
            />
          </div>
        </div>
        <div className={styles.addItemButton}>
          <AddIcon color='#F57C00' />
        </div>
      </div>
      <div className={styles.parametersItemContainer}>
        <div className={styles.dblRow}>
          <div className={classNames(styles.dblRow, styles.inputsContainer)}>
            <FormItem name='condition' label='Сondition' style={{ marginBottom: 0 }}>
              <Select allowClear options={conditionOptions} defaultValue={2} />
            </FormItem>
            <FormItem name='on' label='' style={{ marginBottom: 0 }}>
              <Select allowClear options={conditionValueOptions} defaultValue={2} />
            </FormItem>
          </div>
          <div className={styles.dblRow} style={{ maxWidth: 218 }}>
            <FormItem name='on' label='Value' style={{ marginBottom: 8, maxWidth: 150 }}>
              <Select allowClear options={valueOptions} defaultValue={1} />
            </FormItem>
            <IconButton
              color='orange'
              icon={<BucketIcon />}
              style={{ marginBottom: 8, height: 40 }}
            />
          </div>
        </div>
        <div className={styles.addItemButton}>
          <AddIcon color='#F57C00' />
        </div>
      </div>
      <div className={styles.parametersItemContainer}>
        <div className={styles.dblRow}>
          <div className={classNames(styles.dblRow, styles.inputsContainer)}>
            <FormItem name='condition' label='Сondition' style={{ marginBottom: 0 }}>
              <Select allowClear options={conditionOptions} defaultValue={2} />
            </FormItem>
            <FormItem name='on' label='' style={{ marginBottom: 0 }}>
              <Select allowClear options={conditionValueOptions} defaultValue={3} />
            </FormItem>
          </div>
          <div className={styles.dblRow} style={{ maxWidth: 218 }}>
            <FormItem name='on' label='Value' style={{ marginBottom: 8, maxWidth: 150 }}>
              <Select allowClear options={valueOptions} defaultValue={1} />
            </FormItem>
            <IconButton
              color='orange'
              icon={<BucketIcon />}
              style={{ marginBottom: 8, height: 40 }}
            />
          </div>
        </div>
        <div className={styles.addItemButton}>
          <AddIcon color='#F57C00' />
        </div>
      </div>
      <div className={styles.parametersItemContainer}>
        <div className={styles.dblRow}>
          <div className={classNames(styles.dblRow, styles.inputsContainer)}>
            <FormItem name='condition' label='Сondition' style={{ marginBottom: 0 }}>
              <Select allowClear options={conditionOptions} defaultValue={3} />
            </FormItem>
            <FormItem name='on' label='' style={{ marginBottom: 0 }}>
              <Select allowClear options={conditionValueOptions} defaultValue={4} />
            </FormItem>
          </div>
          <div className={styles.dblRow} style={{ maxWidth: 218 }}>
            <FormItem name='on' label='Value' style={{ marginBottom: 8, maxWidth: 150 }}>
              <Select allowClear options={valueOptions} defaultValue={1} />
            </FormItem>
            <IconButton
              color='orange'
              icon={<BucketIcon />}
              style={{ marginBottom: 8, height: 40 }}
            />
          </div>
        </div>
        <div className={styles.addItemButton}>
          <AddIcon color='#F57C00' />
        </div>
      </div>
      <div className={styles.addConditionBtn}>
        <IconButton color='orange' icon={<AddIcon />}>
          Add Condition
        </IconButton>
      </div>
    </Wrapper>
  )
}

export default CreditScoreDetailParameters
