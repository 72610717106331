import { ICustomerReport, IExternalReport, KeyOfReportType } from '../models/IExternalReport'
import { Link } from 'react-router-dom'
import { SALES_ENUM } from '../../../routes/sales'
import styles from '../pages/Transactions/style.module.scss'
import { DEBIT_CREDIT_ENUM } from '../../Finance/models/ITransaction'
import { startCase } from 'lodash'
import React, { useContext } from 'react'
import { RecursivelyReplaceCharacters } from '../../../shared/components/privateMode'
import { RightModalContext, RightModals } from '../../Modals'
import { RIGHT_MODALS } from '../../../helpers/contants'
import classNames from 'classnames'

export const RequesterColumn = ({ row }: { row: IExternalReport }) => {
  const { onOpen } = useContext(RightModalContext)
  const getNameBySenderOrReceiver = (type: KeyOfReportType) => {
    if (type === 'customer') {
      return (row[type] as unknown as ICustomerReport)?.fullName
    }
    return row[type as unknown as Exclude<KeyOfReportType, 'customer'>]?.name
  }

  const renderMainTrans = () => {
    if (row.requesterType === 'AMIN_PAY') {
      return (
        <>
          <div>System</div>
          {row.escrow && <div className={styles.escrowMark}>Escrow</div>}
          {row.vendorWalletType && <div className={styles.escrowMark}>{row.vendorWalletType}</div>}
        </>
      )
    }

    if (row.requesterType === 'AGENT') {
      return (
        <>
          <div>{row.agent?.name}</div>
          <Link
            to={`${SALES_ENUM.SALES_NETWORK}/view/${row.agent?.id}`}
            className={styles.escrowMark}
          >
            {row.agent?.id}
          </Link>
          {row.escrow && <div className={styles.escrowMark}>Escrow</div>}
          {row.vendorWalletType && <div className={styles.escrowMark}>{row.vendorWalletType}</div>}
        </>
      )
    }

    return (
      <>
        <div>{row.customer?.fullName}</div>
        <div
          className={classNames(styles.escrowMark)}
          onClick={() => {
            onOpen(RIGHT_MODALS.SALES.CUSTOMER, {
              title: 'Customer',
              id: row.customer?.id,
            })
          }}
        >
          {row.customer?.id}
        </div>
        {row.escrow && <div className={styles.escrowMark}>Escrow</div>}
        {row.vendorWalletType && <div className={styles.escrowMark}>{row.vendorWalletType}</div>}
      </>
    )
  }

  return (
    <RecursivelyReplaceCharacters>
      <div>
        {!Object.hasOwn(row, 'mainTransactionId') ? (
          renderMainTrans()
        ) : (
          <>
            {row.drCr === DEBIT_CREDIT_ENUM.CREDIT ? (
              <span>{startCase(getNameBySenderOrReceiver(row.receiver))}</span>
            ) : (
              <span>{startCase(getNameBySenderOrReceiver(row.sender))}</span>
            )}
            {row.escrow && <div className={styles.escrowMark}>Escrow</div>}
            {row.vendorWalletType && (
              <div className={styles.escrowMark}>{row.vendorWalletType}</div>
            )}
          </>
        )}
      </div>
    </RecursivelyReplaceCharacters>
  )
}
