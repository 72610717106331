import { memo } from 'react'
import { AccessDenied } from '../../../assets/svg'
import styles from './styles.module.scss'

const PermissionDenied = () => {
  return (
    <div className={styles.container}>
      <AccessDenied />
      <div className={styles.message}>
        <span>Access denied.</span> Please contact system admin for more information!
      </div>
    </div>
  )
}

export default memo(PermissionDenied)
