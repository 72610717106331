import styles from '../styles.module.scss'
import { formatNumberToLocale } from '../../../../../salesNetwork/helpers'
import React from 'react'
import { ICurrency } from '../../../../models/ICurency'

interface IProps {
  balance?: number
  currency: ICurrency
}

export const CurrentBalance = ({ balance = 0, currency }: IProps) => (
  <div className={styles.bankAccountBalance}>
    <p className={styles.balanceHeader}>current balance</p>
    <div className={styles.balanceWrapper}>
      <span className={styles.balanceNumber}>{formatNumberToLocale(balance)}</span>
      <span className={styles.balanceCurrency}>{currency?.textCode}</span>
    </div>
  </div>
)
