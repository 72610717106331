import React, { memo } from 'react'

import styles from './styles.module.scss'

const TotalScore = () => {
  return (
    <div className={styles.totalScore}>
      <div className={styles.totalScoreTitle}>Total available Credit Score </div>
      <div className={styles.totalScoreValue}>20</div>
    </div>
  )
}

export default memo(TotalScore)
