import { Wrapper } from '../../../../../../../shared/components/wrapper/Wrapper'
import 'react-datepicker/dist/react-datepicker.css'
import { useGetInventoryCalendarByMonthQuery } from '../../../../../core/http/VendorInventoryApi'
import { useState } from 'react'
import moment from 'moment'
import {
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  formatOnlyDate,
} from '../../../../../../../helpers/utils'
import { Space } from 'antd/es'
import { Badge, Popover, Row } from 'antd'
import * as React from 'react'
import { formatNumberToLocale } from '../../../../../../salesNetwork/helpers'
import { Dayjs } from 'dayjs'
import { CalendarShamsi, convertToAccureDayjs } from 'src/shared/components/calendarShamsi'

import styles from '../../styles.module.scss'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { CALENDAR_TYPE_ENUM } from 'src/features/Settings/models/IUser'

export const CalendarBlock = ({ vendorId }: { vendorId?: string }) => {
  const [selectedDate] = useState<null | Date>(() => new Date())
  const { data } = useGetInventoryCalendarByMonthQuery(
    {
      vendorId: vendorId as string,
      monthRange: moment(selectedDate)?.set({ date: 15 })?.toISOString() as string,
    },
    { skip: !vendorId || !selectedDate }
  )

  const { data: profile } = useGetProfileQuery()

  const shamsi = profile?.calendar === CALENDAR_TYPE_ENUM.SHAMSI

  const renderDayContents = (day: Dayjs) => {
    const date = convertToAccureDayjs(shamsi, day).set('hour', 15)
    const event = data?.find((e) => {
      if (convertToAccureDayjs(shamsi, date) > convertToAccureDayjs(shamsi).set('hour', 1)) {
        return
      }
      const eventStart = formatMomentDateToDayStart(moment(e?.start))
      const eventEnd = formatMomentDateToDayEnd(moment(e?.end))
      return (
        convertToAccureDayjs(shamsi, eventStart.toISOString()) <= date &&
        convertToAccureDayjs(shamsi, eventEnd.toISOString()) >= date
      )
    })

    const popoverContent = (
      <Row>
        <Space>
          <div>Target:</div>
          <span>{formatNumberToLocale(event?.targetAmount || 0)}</span>
        </Space>
        <Space>
          <div>Purchased:</div>
          <span>{formatNumberToLocale(event?.purchasedAmount || 0)}</span>
        </Space>
        <Space>
          <div>Remain:</div>
          <span>{formatNumberToLocale(event?.remainAmount || 0)}</span>
        </Space>
      </Row>
    )

    const title = formatOnlyDate(date.toISOString(), profile?.calendar)

    return (
      <Popover content={popoverContent} title={title}>
        <ul className={styles.events}>
          {event && (
            <li>
              <Badge status='error' />
            </li>
          )}
        </ul>
      </Popover>
    )
  }

  return (
    <Wrapper title='Calendar'>
      <div className='inventory-calendar'>
        <CalendarShamsi renderCellContents={renderDayContents} jalali={shamsi} />
      </div>
    </Wrapper>
  )
}
