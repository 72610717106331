import { useNavigate } from 'react-router'
import { OpenInIcon, TransactionIcon } from 'src/assets/svg'
import emptyAvatarImage from 'src/assets/img/Avatar.png'
import { GeneralSearchType, IGeneralSearchItem } from 'src/features/app/models/IGeneralSearch'
import { RIGHT_MODALS } from 'src/helpers/contants'

// styles
import styles from './Sidebar.module.scss'
import { InfiniteScrollComponent } from '../infiniteScoll/InfiniteScroll'

import React, { useContext } from 'react'
import { Spin } from 'antd'
import { GlobalSearchTransactionsNameType } from './Sidebar.d'
import { RightModalContext } from 'src/features/Modals'
import { FINANCE_ENUM } from '../../../routes/finance'

interface ISearchItems {
  loading: boolean
  dataLength: number
  totalCount: number
  data: IGeneralSearchItem[]
  onResetSearch: () => void
  loadMoreData: () => void
  onClick: (id: string, entityType: GeneralSearchType, name: string) => void
}

type THandleRedirect = (
  type: GeneralSearchType,
  id: string,
  name: string
) => {
  redirectTo: (() => void) | null
}

export const SearchItems = ({
  data,
  loading,
  dataLength,
  totalCount,
  onResetSearch = () => {},
  loadMoreData,
  onClick,
}: ISearchItems) => {
  const navigate = useNavigate()
  const { onOpen } = useContext(RightModalContext)

  const navigateWithReset = (
    to: string,
    type: GeneralSearchType,
    id: string,
    name: string,
    modal?: string,
    title?: string,
    mode?: string
  ) => ({
    redirectTo: () => {
      onResetSearch()
      navigate(to)
      onClick(id, type, name)
      modal && onOpen(modal, { title, id, mode })
    },
  })

  const handleRedirect: THandleRedirect = (type, id, name) => {
    if (type === 'agent')
      return navigateWithReset(`/sales-network/agent-management/view/${id}`, type, id, name)
    if (type === 'ticket') return navigateWithReset(`/support/tickets/view/${id}`, type, id, name)
    if (type === 'transactions') {
      switch (name) {
        case GlobalSearchTransactionsNameType.PURCHASE_ORDER:
          return navigateWithReset(
            `${FINANCE_ENUM.TRUST_WALLET}/purchase-order/view/${id}`,
            type,
            id,
            name
          )
        case GlobalSearchTransactionsNameType.REFUND_EMONEY:
          return navigateWithReset(
            `${FINANCE_ENUM.BANK_WALLET}/refund-request/view/${id}`,
            type,
            id,
            name
          )
        case GlobalSearchTransactionsNameType.PICKUP_REQUEST:
          return navigateWithReset(
            `${FINANCE_ENUM.BANK_WALLET}/pickup-request/view/${id}`,
            type,
            id,
            name
          )
        case GlobalSearchTransactionsNameType.TRANSFER_FUND:
          return navigateWithReset(
            `reports/internal-transaction-report?limit=10&page=1&orderType=DESC&orderField=createdAt`,
            type,
            id,
            name,
            RIGHT_MODALS.FINANCE.TRANSFER_FUND_DETAIL,
            'Transfer Fund Transaction'
          )
        case GlobalSearchTransactionsNameType.REVERSE_TOPUP:
          return navigateWithReset(
            `reports/external-transaction-report?limit=10&page=1&orderType=DESC&orderField=createdAt`,
            type,
            id,
            name,
            RIGHT_MODALS.FINANCE.REVERSE_TRANSACTION,
            'Reverse Transaction'
          )

        case GlobalSearchTransactionsNameType.ADJUSTMENT:
          return navigateWithReset(
            `reports/internal-transaction-report?limit=10&page=1&orderType=DESC&orderField=createdAt`,
            type,
            id,
            name,
            RIGHT_MODALS.FINANCE.ADJUSTMENT,
            'Adjustment',
            'view'
          )
        case GlobalSearchTransactionsNameType.TOPUP:
          return navigateWithReset(
            `reports/external-transaction-report?limit=10&page=1&orderType=DESC&orderField=createdAt`,
            type,
            id,
            name,
            RIGHT_MODALS.FINANCE.TOPUP,
            'Topup Transaction'
          )
        case GlobalSearchTransactionsNameType.PROMOTION:
          return navigateWithReset(
            `/settings/network-configuration/promotion-policy/view/${id}`,
            type,
            id,
            name
          )
        case GlobalSearchTransactionsNameType.PURCHASE_ORDER_SUBTRANSACTION ||
          GlobalSearchTransactionsNameType.PICKUP_REQUEST_SUBTRANSACTION ||
          GlobalSearchTransactionsNameType.BANK_ACCOUNT_TRANSACTION ||
          GlobalSearchTransactionsNameType.VENDOR_TRANSACTION ||
          GlobalSearchTransactionsNameType.BANK_ACCOUNT ||
          GlobalSearchTransactionsNameType.BANK_WALLET ||
          GlobalSearchTransactionsNameType.COLLECTION_WALLET ||
          GlobalSearchTransactionsNameType.DISCOUNT_WALLET ||
          GlobalSearchTransactionsNameType.PROMOTION_WALLET ||
          GlobalSearchTransactionsNameType.REWARD_WALLET ||
          GlobalSearchTransactionsNameType.SYSTEM_WALLET ||
          GlobalSearchTransactionsNameType.TRUST_WALLET:
          return {
            redirectTo: null,
          }
      }
    }
    return {
      redirectTo: null,
    }
  }

  //  : (
  //   <div className={styles.searchListContainer}>
  //     <SearchItemList data={data} handleRedirect={handleRedirect} />
  //   </div>
  // )

  return (
    <div className={styles.searchItemsContainer}>
      <Spin spinning={loading}>
        {dataLength && totalCount ? (
          <InfiniteScrollComponent
            dataLength={dataLength}
            loadMoreData={loadMoreData}
            totalCount={totalCount}
          >
            <SearchItemList data={data} handleRedirect={handleRedirect} />
          </InfiniteScrollComponent>
        ) : (
          <div className={styles.searchListContainer}>
            <SearchItemList data={data} handleRedirect={handleRedirect} />
          </div>
        )}
      </Spin>
    </div>
  )
}

const SearchItemList = ({
  data,
  handleRedirect,
}: {
  data: IGeneralSearchItem[]
  handleRedirect: THandleRedirect
}) => {
  return (
    <React.Fragment>
      {data.map(({ id, type, name }) => (
        <div className={styles.searchItemContainer} key={id}>
          <div className={styles.searchItemLeftColumn}>
            <div className={styles.searchItemAvatar}>
              {type === 'agent' && <img src={emptyAvatarImage} alt='agent-avatar' />}
              {type === 'transactions' && <TransactionIcon />}
              {type === 'ticket' && <div />}
            </div>
            <div className={styles.searchItemMainInfo}>
              <div className={styles.searchItemName}>{name}</div>
              <div className={styles.searchItemId}>{`ID ${id}`}</div>
            </div>
          </div>

          {handleRedirect(type!, id, name)?.redirectTo && (
            <div
              className={styles.redirectContainer}
              onClick={handleRedirect(type!, id, name)!.redirectTo!}
            >
              <OpenInIcon />
            </div>
          )}
        </div>
      ))}
    </React.Fragment>
  )
}
