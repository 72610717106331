import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { IProfileNotification, IUnreadMessagesCount } from '../models'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['IProfileNotification', 'UnreadMessagesCount'],
  endpoints: (builder) => ({
    getNotificationsList: builder.query<ITableResponse<IProfileNotification>, ITableConf>({
      query: (params) => ({
        url: `internal-notifications`,
        params,
      }),
      providesTags: ['IProfileNotification'],
    }),
    getUnreadNotificationsCounter: builder.query<number, void>({
      query: () => ({
        url: `internal-notifications/unreaded-notifications-counter`,
      }),
      providesTags: ['IProfileNotification'],
    }),
    markNotificationAsRead: builder.mutation<
      Array<number>,
      { createdAtBefore: string; createdAtAfter: string }
    >({
      query: (body) => ({
        url: `internal-notifications/mark-as-read`,
        method: 'PATCH',
        body,
      }),
    }),

    getUnreadMessagesCount: builder.query<IUnreadMessagesCount, void>({
      query: () => ({
        url: 'chats/unread-messages/count',
      }),
      providesTags: ['UnreadMessagesCount'],
    }),

    getNotificationsFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'internal-notifications/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `internal-notifications/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),
  }),
})

export const {
  useGetNotificationsListQuery,
  useGetNotificationsFiltersListQuery,
  useGetUnreadNotificationsCounterQuery,
  useLazyGetUnreadNotificationsCounterQuery,
  useMarkNotificationAsReadMutation,
  useGetUnreadMessagesCountQuery,
} = notificationApi
