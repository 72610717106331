import { ColumnsType } from 'antd/es/table'
import { ITopLocation } from '../../../../models/ITopLocation'
import { Row, Space, Table } from 'antd'
import {
  LocationIcon,
  MonetizationRoundedIcon,
  UserGroupCircleIcon,
} from '../../../../../../assets/svg'
import { formatNumberToLocale } from '../../../../../salesNetwork/helpers'
import React, { useMemo, useState } from 'react'
import useTable from '../../../../../../shared/hooks/table/useTable'
import { useGetQueryParams } from '../../../../../../shared/hooks/table/useGetQueryParams'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import styles from './styles.module.scss'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'

interface IProps {
  items?: Omit<ITopLocation, 'id'>[]
  shouldDisplayProvince?: boolean
}

export const TopLocationsTable = ({ items, shouldDisplayProvince = false }: IProps) => {
  const { queryParams } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })
  const [, setTableConf] = useState<ITableConf>(queryParams)

  const columns: ColumnsType<ITopLocation> = [
    {
      title: '',
      dataIndex: 'id',
      render: (id) => <span className={styles.tableCounter}>{id}</span>,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      render: (_, row) => (
        <Space size={7} align='center'>
          <Row>
            <LocationIcon />
          </Row>
          <Space size={3}>
            <RecursivelyReplaceCharacters>{row?.province?.name}</RecursivelyReplaceCharacters>
            {!shouldDisplayProvince && (
              <RecursivelyReplaceCharacters>{row?.district?.name}</RecursivelyReplaceCharacters>
            )}
          </Space>
        </Space>
      ),
    },
    {
      title: 'Active Agents',
      dataIndex: 'activeAgentsCount',
      render: (activeAgents: ITopLocation['activeAgentsCount']) => (
        <Space size={6} align='center'>
          <Row>
            <UserGroupCircleIcon />
          </Row>
          <RecursivelyReplaceCharacters>
            {formatNumberToLocale(activeAgents)}
          </RecursivelyReplaceCharacters>
        </Space>
      ),
    },
    {
      title: 'Sales Amount',
      dataIndex: 'salesAmount',
      render: (salesAmount: ITopLocation['salesAmount']) => (
        <Space size={6} align='center'>
          <Row>
            <MonetizationRoundedIcon />
          </Row>
          <RecursivelyReplaceCharacters>
            {formatNumberToLocale(salesAmount)}
          </RecursivelyReplaceCharacters>
        </Space>
      ),
    },
  ]

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange } = useTable<ITopLocation>({
    total: 0,
    setTableConf,
  })

  const locationsList = useMemo(
    () =>
      items
        ?.sort((a, b) => b?.salesAmount - a?.salesAmount)
        ?.map((location, i) => ({
          ...location,
          id: i + 1,
        })),
    [items]
  )

  return (
    <Table
      columns={columns}
      locale={emptyTableConf}
      onChange={handleTableChange}
      pagination={pagination}
      dataSource={locationsList}
      rowKey={(record) => record?.district?.id}
      className={styles.table}
    />
  )
}
