import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { DashboardTableWrapper } from '../DashboardTableWrapper'
import styles from '../../styles.module.scss'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { Select } from 'antd'
import { WIDGET_LIST_ENUM } from '../../../../../../shared/models/WidgetListEnum'
import {
  ISalesSummaryByVendors,
  ISalesSummaryByVendorsData,
} from '../../../../../Reports/models/IExternalReport'
import { moment, replaceCharactersWithAsterisks } from '../../../../../../helpers/utils'
import {
  PIE_CHART_COLORS,
  PIE_CHART_COLORS_WITH_TWO_ITEMS,
} from '../../../../../../helpers/contants'
import { NotFoundPage } from '../../../../../NotFoundPage'
import {
  ISalesSummaryByMasterProd,
  ISalesSummaryByMasterProdData,
} from '../../../../../Settings/models/IMasterProduct'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IProps {
  salesSummaryType:
    | WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS
    | WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT
  setSalesSummaryType: Dispatch<
    SetStateAction<
      WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS | WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT
    >
  >
  frequency?: 'day' | 'hour'
  byVendors: ISalesSummaryByVendors[]
  byMasters: ISalesSummaryByMasterProd[]
}

export const SalesSummaryBarChart = ({
  salesSummaryType,
  setSalesSummaryType,
  frequency,
  byMasters = [],
  byVendors = [],
}: IProps) => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const mappedData = useMemo(() => {
    const result: Array<{ [key: string]: number | string }> = []
    const hours = Array(25)
      .fill(undefined)
      .map((_, index) => index)

    const arrayForMap =
      salesSummaryType === WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS ? byVendors : byMasters

    arrayForMap?.forEach((entry, index) => {
      const period = moment(entry?.period)
      let name: string

      if (frequency === 'hour') {
        name = period?.hour(hours[index])?.format('HH')
      } else {
        name = period?.format('DD MMM')
      }

      const transformedEntry: { [key: string]: number | string } = {
        name,
      }

      entry?.data?.forEach((item) => {
        if (salesSummaryType === WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS) {
          const vendorData = item as ISalesSummaryByVendorsData
          transformedEntry[vendorData.vendor.name] = item.amount
          transformedEntry[vendorData.vendor.name + '_color'] = vendorData?.vendor?.color
        } else {
          const masterProductData = item as ISalesSummaryByMasterProdData
          transformedEntry[masterProductData.masterProduct.name] = item.amount
          transformedEntry[masterProductData.masterProduct.name + '_color'] =
            masterProductData?.masterProduct?.color
        }
      })

      result.push(transformedEntry)
    })
    return result
  }, [byMasters, byVendors, frequency, salesSummaryType])

  const barNamesList = useMemo(() => {
    if (!mappedData.length) return []

    const uniqueVendorNames = new Set<string>()
    const vendorNamesMap: { [key: string]: string } = {}

    mappedData.forEach((entry) => {
      Object.keys(entry).forEach((key) => {
        if (key !== 'name' && typeof entry[key] === 'number') {
          uniqueVendorNames.add(key)
          vendorNamesMap[key] = entry[key + '_color'] as string
        }
      })
    })

    return Array.from(uniqueVendorNames).map((vendorName) => ({
      vendorName,
      color: vendorNamesMap[vendorName],
    }))
  }, [mappedData])

  const colors = useMemo(
    () => (barNamesList?.length <= 2 ? PIE_CHART_COLORS_WITH_TWO_ITEMS : PIE_CHART_COLORS),
    [barNamesList?.length]
  )

  const filters = (
    <div className={styles.dashboardSalesSummaryFilter}>
      <Select
        value={salesSummaryType}
        placeholder='Select Sales Summary Type'
        onChange={setSalesSummaryType}
      >
        <Select.Option value={WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS}>By Vendors</Select.Option>
        <Select.Option value={WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT}>
          By Master Product
        </Select.Option>
      </Select>
    </div>
  )

  return (
    <div className={styles.topMargin}>
      <DashboardTableWrapper
        title='Sales Summary'
        infoText='Sales Summary Bar Chart'
        filters={filters}
      >
        {mappedData?.length ? (
          <ResponsiveContainer width='100%' height={300}>
            <BarChart
              width={900}
              height={300}
              data={mappedData}
              margin={{
                top: 30,
                right: 10,
                left: -10,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis
                tickFormatter={(value) =>
                  isPrivateMode ? replaceCharactersWithAsterisks(value) : value
                }
              />
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const time = payload[0]?.payload?.name

                    return (
                      <RecursivelyReplaceCharacters>
                        <div className={styles.tooltipSummary}>
                          <div className={styles.tooltipRow}>
                            <div className={styles.tooltipRowValue}>{time}</div>
                          </div>
                          {payload?.map((value, i) => (
                            <div
                              key={i}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gridGap: 10,
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{
                                  color: payload[i]?.color,
                                }}
                              >
                                {payload[i]?.name}:
                              </div>
                              <div className={styles.tooltipRowValue}>{payload[i]?.value}</div>
                            </div>
                          ))}
                        </div>
                      </RecursivelyReplaceCharacters>
                    )
                  }

                  return null
                }}
              />
              {!isPrivateMode && (
                <Legend
                  verticalAlign='top'
                  align='left'
                  wrapperStyle={{ left: 40, top: 10 }}
                  iconType='circle'
                />
              )}
              {barNamesList?.map((entity, i) => (
                <Bar
                  key={entity?.vendorName}
                  dataKey={entity?.vendorName}
                  stackId='a'
                  fill={entity?.color ?? colors[i]}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <NotFoundPage />
        )}
      </DashboardTableWrapper>
    </div>
  )
}
