import { Col, Row } from 'antd'
import { Filter, ISelectedFilters } from 'src/shared/components/filter/Filter'
import { IFilterFields } from 'src/shared/models/IFilterFields'
import { useFilter } from 'src/shared/hooks/filter/useFilter'
import { FilterPopup } from 'src/shared/components/filter/FilterPopup'
import ShowMessage from 'src/shared/api/errorHandler'
import { useLazyCheckIfAgentHasChildQuery } from 'src/features/salesNetwork/core/http/AgentManagement'
import { useEffect, useState } from 'react'

interface IProps {
  setActiveFilters: (values: ISelectedFilters) => void
  filterValues: ISelectedFilters
  data?: IFilterFields[]
  agentId: string | number
}

export const TableHeader = ({
  setActiveFilters,
  data,
  filterValues,
  agentId,
}: IProps): JSX.Element => {
  const { isOpen, onCancel, form, onReset, onFinish } = useFilter({ setActiveFilters })
  const [checkIfAgentHasChild, { data: isChild, isFetching: isCheckigChild }] =
    useLazyCheckIfAgentHasChildQuery()
  const [formValues, setFormValues] = useState<ISelectedFilters>()

  const handleOnFinish = async (values: ISelectedFilters) => {
    if (!values.childId_like) {
      onFinish(values)
      return
    }

    try {
      await checkIfAgentHasChild({
        agentId: agentId as string,
        childId: values.childId_like as string,
      }).unwrap()
      setFormValues(values)
    } catch (error) {
      ShowMessage('error', 'Error checking if agent has child!')
    }
  }

  useEffect(() => {
    if (!isCheckigChild && formValues) {
      isChild ? onFinish(formValues) : ShowMessage('error', 'Child ID is not valid!')
    }
    // eslint-disable-next-line
  }, [isChild, isCheckigChild, formValues])

  return (
    <>
      <Row align='middle' justify='space-between' style={{ marginBottom: '20px' }}>
        <Col>
          <Filter
            data={data}
            form={form}
            setIsOpen={onCancel}
            selectedFilters={filterValues}
            setSelectedFilters={setActiveFilters}
          />
        </Col>
      </Row>
      {isOpen && (
        <FilterPopup
          data={data}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          form={form}
          onCancel={onCancel}
          onReset={onReset}
          onFinish={handleOnFinish}
        />
      )}
    </>
  )
}
