import React, { useCallback, useState } from 'react'
import { Table } from 'antd'

import { ICreditScoreAgent } from '../../../Finance/models/ICreditScore'
import { ColumnsType } from 'antd/es/table'
import { IPopupListItems, Popup } from '../../../../shared/components/popup/Popup'
import { TableActionIcon } from '../../../../assets/svg'
import { IDiscountPolicy } from '../../../Settings/models/IDiscountPolicy'
import { Toggle } from '../../../../shared/components/toggle/Toggle'
import useTable from '../../../../shared/hooks/table/useTable'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { useGetQueryParams } from '../../../../shared/hooks/table/useGetQueryParams'
import TableHeader from './Components/TableHeader'
import { ISelectedFilters } from '../../../../shared/components/filter/Filter'
import styles from './styles.module.scss'
import { useEmptyTable } from '../../../../shared/hooks/table/useEmptyTable'

const KnowledgeBase = () => {
  const { queryParams, queryFields } = useGetQueryParams()

  const [, setTableConf] = useState<ITableConf>(queryParams)
  const [filterValues] = useState<ISelectedFilters>(queryFields)

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<ICreditScoreAgent>({
    total: 0,
    setTableConf,
  })

  const tableActionsPopup = useCallback((id: number): IPopupListItems[] => {
    return [
      {
        text: 'View',
        shouldDisplay: true,
      },
    ]
  }, [])

  const columns: ColumnsType<ICreditScoreAgent> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '30%',
      sorter: true,
    },
    {
      title: 'Section',
      dataIndex: 'section',
      width: '15%',
      sorter: true,
    },
    {
      title: 'Version',
      dataIndex: 'level',
      width: '15%',
      sorter: true,
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      width: '15%',
      sorter: true,
    },
    {
      title: 'Added By',
      dataIndex: 'addedBy',
      width: '15%',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '15%',
      sorter: true,
      render: (status: IDiscountPolicy['status'], { id }) => {
        return <Toggle checked={status} defaultChecked={status} />
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => {
        if (!Object.hasOwn(row, 'children')) return
        return (
          <Popup data={tableActionsPopup(row.id)}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        )
      },
    },
  ]

  return (
    <div className={styles.layout}>
      <TableHeader filterValues={filterValues} setActiveFilters={handleFiltersChange} data={[]} />
      <Table
        locale={emptyTableConf}
        columns={columns}
        pagination={pagination}
        className={styles.table}
        onChange={handleTableChange}
        loading={false}
        rowKey={(row) => row?.id}
        dataSource={[]}
        rowSelection={{ type: 'checkbox' }}
        scroll={{ x: 1100 }}
      />
    </div>
  )
}

export default KnowledgeBase
