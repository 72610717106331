import { FormInstance, Row, Select as AntSelect } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import classNames from 'classnames'
import { Select } from 'src/shared/components/select/select'
import { TextField } from 'src/shared/components/textfield/TextField'
import styles from './styles.module.scss'
import {
  AddIcon,
  BucketIcon,
  CopyIcon,
  EmailFilledIcon,
  IOSShareIcon,
  PhoneDarkBlueIcon,
  SkypeIcon,
  WhatsappIcon,
} from 'src/assets/svg'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { Wrapper } from '../Wrapper'
import * as V from 'src/helpers/validation'
import * as I from 'src/features/salesNetwork/models/IAgent'
import { DefaultOptionType } from 'antd/lib/select'
import { ErrorNode, handleError, handleInfo } from 'src/shared/api/errorHandler'

import { ConfirmButton } from './ConfirmButton'
import { customAlphabet } from 'nanoid'
import { IVendorContactContactInfoPut } from '../..'
import WrapperReactFlagsSelectWithPhoneNumber from 'src/features/salesNetwork/components/WrapperReactFlagsSelectWithPhoneNumber/CountrySelect'
import { COUNTRIES_DATA } from 'src/features/salesNetwork/components/WrapperReactFlagsSelectWithPhoneNumber/countryData'

const { Option } = AntSelect

const nanoid = customAlphabet('1234567890', 18)

interface IContactInformation {
  view?: boolean
  form: FormInstance
  contactInfo: I.IContactInfo[]
  setContactInfo: I.SetStateType<I.IContactInfo[]>
  setDeleteContacts: I.SetStateType<number[]>
}

const ContactInformation = ({
  view = false,
  form,
  contactInfo,
  setContactInfo,
  setDeleteContacts,
}: IContactInformation) => {
  const options = [
    { value: null, type: I.CONTACT_TYPE_ENUM.EMAIL, icon: <EmailFilledIcon />, active: true },
    { value: null, type: I.CONTACT_TYPE_ENUM.MOBILE, icon: <PhoneDarkBlueIcon />, active: true },
    { value: null, type: I.CONTACT_TYPE_ENUM.WHATSAPP, icon: <WhatsappIcon />, active: true },
    {
      value: null,
      type: I.CONTACT_TYPE_ENUM.SKYPE,
      icon: <SkypeIcon />,
      active: true,
    },
  ]

  const REQUIRED_OBJ_FIELD = { required: true, message: 'Field required' }

  const rulesObj = {
    [I.CONTACT_TYPE_ENUM.EMAIL]: [REQUIRED_OBJ_FIELD, { validator: V.emailValidator }],
    [I.CONTACT_TYPE_ENUM.WHATSAPP]: [
      REQUIRED_OBJ_FIELD,
      { validator: V.mobileNumberValidator },
      { validator: V.mobileNumberLengthFrom8To14 },
    ],
    [I.CONTACT_TYPE_ENUM.SKYPE]: [
      REQUIRED_OBJ_FIELD,
      { validator: V.commonValidator },
      { validator: V.maxLengthValidator25 },
    ],
    [I.CONTACT_TYPE_ENUM.MOBILE]: [
      REQUIRED_OBJ_FIELD,
      { validator: V.mobileNumberValidator },
      { validator: V.mobileNumberLength11 },
    ],
  }

  type rulesType = keyof typeof rulesObj

  const onChange = (contact: I.IContactInfo) => (obj: DefaultOptionType) => {
    const objSelectedOption = options.find((it) => it.type === obj.value)
    setContactInfo((pre) =>
      pre.map((currIt) =>
        currIt.createId === contact.createId
          ? { ...objSelectedOption!, createId: contact.createId }
          : { ...currIt }
      )
    )
  }

  const onCopyText = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        handleInfo('Text copied')
      })
      .catch((error) => handleError(error as ErrorNode))
  }
  const getDefaultSelectedCountryCodeByPhoneNumber = (phoneNumber: string) => {
    const defaultCountry = COUNTRIES_DATA.find((country) => phoneNumber.includes(country.dial_code))
    return defaultCountry?.code
  }

  const onChangeReactFlagSelectWithNumber = (contact: I.IContactInfo) => (value: string) => {
    setContactInfo((pre) =>
      pre.map((currContact) =>
        currContact.createId === contact.createId
          ? { ...currContact, value, status: 'edit' }
          : currContact
      )
    )
  }

  const onChangeTextFieldContactInfo =
    (contact: I.IContactInfo) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setContactInfo((pre) =>
        pre.map((currContact) =>
          currContact.createId === contact.createId
            ? { ...currContact, value, status: 'edit' }
            : currContact
        )
      )
    }

  const onDeleteContact = (contact: IVendorContactContactInfoPut) => () => {
    contact.type && form.setFieldValue([contact.type, String(contact.createId)], null)
    setContactInfo((pre) => pre.filter((currIt) => currIt.createId != contact.createId))

    const deleteIdentifierId = Number((contact as IVendorContactContactInfoPut)?.id)
    return (
      (contact as IVendorContactContactInfoPut)?.id &&
      setDeleteContacts((pre) => [...pre, deleteIdentifierId])
    )
  }

  const onCopyTextContactField = (contact: I.IContactInfo) => () => {
    contact?.value ? onCopyText(contact.value as string) : () => {}
  }

  const onSelectCountry = (contact: I.IContactInfo) => (prefixNumber: string) => {
    contact?.type && form.setFieldValue([contact?.type, String(contact.createId)], '')
  }

  const onAddNewEmptyContact = () =>
    setContactInfo((pre) => [
      ...pre,
      { createId: Number(nanoid()), value: null, type: null, active: true },
    ])

  return (
    <Wrapper title='Contact information' className={styles['wrapper-user-identifiers']}>
      {contactInfo.map((contact) => {
        const isMobileNumber = contact?.type === I.CONTACT_TYPE_ENUM.MOBILE
        const mobileNumberValue = contact?.value
        const mobileNumberHaveValue = mobileNumberValue ? mobileNumberValue : false
        const isSkype = contact.type === I.CONTACT_TYPE_ENUM.SKYPE

        return (
          <Row align='top' wrap={false} key={contact.createId} justify='space-between'>
            <FormItem className={classNames(styles['form-item-inp'], styles['select'])}>
              <Select
                placeholder='Select contact info'
                view={view}
                onChange={onChange(contact)}
                defaultValue={contact.type}
                allowClear
              >
                {options.map(({ type, icon }) => {
                  const isSkype = type === I.CONTACT_TYPE_ENUM.SKYPE
                  return (
                    <Option key={type} value={type}>
                      <div className={styles['wrapper-content-option']}>
                        <div
                          className={classNames(styles['wrapper-icon'], {
                            [styles['size24']]: isSkype,
                          })}
                        >
                          {icon}
                        </div>
                        <div>{I.CONTACT_VALUE_FOR_DISPLAYED_BY_TYPE_ENUM[type]}</div>
                      </div>
                    </Option>
                  )
                })}
              </Select>
            </FormItem>
            {isMobileNumber && (
              <WrapperReactFlagsSelectWithPhoneNumber
                view={view}
                selectedCountry={
                  isMobileNumber && mobileNumberHaveValue
                    ? getDefaultSelectedCountryCodeByPhoneNumber(mobileNumberValue!)
                    : ''
                }
                form={form}
                name={contact?.type ? [contact?.type, String(contact.createId)] : undefined}
                rules={rulesObj[contact?.type as rulesType]}
                onSelect={onSelectCountry(contact)}
                onChange={onChangeReactFlagSelectWithNumber(contact)}
                defaultValue={contact?.value ? contact.value : undefined}
              />
            )}
            {!isMobileNumber && (
              <FormItem
                rules={rulesObj[contact?.type as rulesType]}
                name={contact?.type ? [contact.type, String(contact.createId)] : undefined}
                className={classNames(styles['form-item-inp'], styles['big-inp'])}
                initialValue={contact.value}
              >
                <TextField disabled={view} onChange={onChangeTextFieldContactInfo(contact)} />
              </FormItem>
            )}

            {view && (
              <div className={styles['wrapper-icons']}>
                <div className={styles['wrapper-icon']}>
                  <IconButton
                    onClick={onCopyTextContactField(contact)}
                    slim
                    size='large'
                    color='orange'
                    icon={<CopyIcon />}
                    type='default'
                  />
                </div>
                {!isSkype && <ConfirmButton onChange={(isConfirm) => isConfirm} />}

                {isSkype && (
                  <div className={styles['wrapper-icon']}>
                    <IconButton
                      slim
                      size='large'
                      color='blue'
                      icon={<IOSShareIcon />}
                      type='default'
                    />
                  </div>
                )}
              </div>
            )}

            {!view && (
              <div className={classNames(styles['wrapper-icon'], styles['wrapper-bucket-icon'])}>
                <IconButton
                  slim
                  size='large'
                  color='orange'
                  icon={<BucketIcon />}
                  onClick={onDeleteContact(contact)}
                  type='default'
                />
              </div>
            )}
          </Row>
        )
      })}
      {!view && (
        <div className={styles['wrapper-add-btn']}>
          <IconButton
            size='middle'
            block
            color='orange'
            icon={
              <div className={styles['wrapper-add-icon']}>
                <AddIcon />
              </div>
            }
            onClick={onAddNewEmptyContact}
            type='default'
          ></IconButton>
        </div>
      )}
    </Wrapper>
  )
}
export default ContactInformation
