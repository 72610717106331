import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import { InboxOutlined } from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger'
import { RcFile, UploadProps } from 'antd/es/upload'
import Papa from 'papaparse'
import { UploadFile } from 'antd'
import { read, utils } from 'xlsx'
import FormList from 'antd/es/form/FormList'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { IViewMode } from '../../../../../models/IViewMode'
import { positiveNumber, requiredValidator } from '../../../../../../../helpers/validation'
import styles from '../styles.module.scss'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from '../../../../../../../assets/svg'
import classNames from 'classnames'
import { Dispatch, SetStateAction } from 'react'
import { IScratchCard } from '../../../../../models/IPurchaseOrder'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

interface IProps extends IViewMode {
  setPinList: Dispatch<SetStateAction<IScratchCard[]>>
  pinList: Array<IScratchCard>
  files: UploadFile[]
  setFiles: Dispatch<SetStateAction<UploadFile[]>>
}

export const EPinBlock = ({ setPinList, view, setFiles, files, pinList }: IProps) => {
  const form = useFormInstance()
  const readAndParseFile = (file: UploadFile) => {
    if (file.type === 'text/csv') {
      return new Promise((resolve) => {
        Papa.parse(file.originFileObj as RcFile, {
          skipEmptyLines: true,
          complete: (results: { data: Array<[number, number, number]> }) => {
            const parsedData = results?.data
              ?.map((innerArray) => {
                return {
                  serialNumber: String(innerArray[0]),
                  code: String(innerArray[1]),
                  denomination: Number(innerArray[2]),
                }
              })
              ?.filter((code) => !!code.denomination)

            resolve(parsedData)
          },
        })
      })
    }
    if (
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          /* Get first worksheet */
          const data = read(e?.target?.result, { type: 'binary' })

          const wsname = data.SheetNames[0]
          const ws = data.Sheets[wsname]
          /* Convert array of arrays */
          const parsed = utils.sheet_to_json(ws, { header: 1, blankrows: false }) as Array<
            [number, number, number]
          >

          const parsedData = parsed
            .map((innerArray) => {
              return {
                serialNumber: String(innerArray[0]),
                code: String(innerArray[1]),
                denomination: Number(innerArray[2]),
              }
            })
            ?.filter((code) => !!code.denomination)

          resolve(parsedData)
        }

        reader.readAsBinaryString(file.originFileObj as Blob)
      })
    }
  }

  const isNotAcceptedType = (type: string) =>
    type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
    type !== 'application/vnd.ms-excel' &&
    type !== 'text/csv'

  const propsDragger: UploadProps = {
    name: 'file',
    multiple: true,
    customRequest: (info) => {
      if (typeof info.file !== 'string' && info.onError && info.onSuccess) {
        if (isNotAcceptedType(info.file.type)) {
          const err = new Error('Unacceptable file type!')
          return info.onError(err)
        }

        return info.onSuccess('Ok')
      }
    },
  }

  const recalculateMoneyInfo = (pinList: IScratchCard[]) => {
    const scratchCardInfo = (form.getFieldValue('scratchCardInfo') as IScratchCard[]) || []
    const amount = [...scratchCardInfo, ...pinList].reduce(
      (acc, card) => acc + Number(card?.denomination),
      0
    )
    form.setFieldsValue({
      currencyAmount: amount,
      amount,
    })
  }

  const normFile = async (e: { fileList: UploadFile[] }) => {
    if (Array.isArray(e)) {
      return e
    }
    const promises = e.fileList?.map((file) => readAndParseFile(file))
    const parsedFiles = await Promise.all(promises)
    setPinList(() => parsedFiles.flat() as IScratchCard[])
    recalculateMoneyInfo(parsedFiles.flat() as IScratchCard[])

    const mappedFiles =
      e?.fileList?.map((file) => ({
        ...file,
        status: 'done',
      })) || []

    setFiles(mappedFiles as unknown as UploadFile[])

    return e && e.fileList
  }

  return (
    <Wrapper title='ePins'>
      <FormList name='scratchCardInfo'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name, key }) => (
              <div key={key} style={{ display: 'flex', gridGap: 12, alignItems: 'center' }}>
                <FormItem
                  label='Serial NO.'
                  name={[name, 'serialNumber']}
                  rules={[
                    { required: true, validator: requiredValidator('Serial NO.') },
                    {
                      validator: positiveNumber('Serial No.'),
                    },
                  ]}
                >
                  <TextField disabled={view} placeholder='Enter Serial NO.' />
                </FormItem>

                <FormItem
                  label='Denom.'
                  name={[name, 'denomination']}
                  rules={[
                    { required: true, validator: requiredValidator('Denom.') },
                    {
                      validator: positiveNumber('Denom.'),
                    },
                  ]}
                >
                  <TextField
                    disabled={view}
                    type='number'
                    placeholder='Enter Denom.'
                    onChange={() => recalculateMoneyInfo(pinList)}
                  />
                </FormItem>

                <FormItem
                  label='Code'
                  name={[name, 'code']}
                  rules={[
                    { required: true, validator: requiredValidator('Code') },
                    {
                      validator: positiveNumber('Code'),
                    },
                  ]}
                >
                  <TextField disabled={view} placeholder='Enter Code' />
                </FormItem>

                <FormItem
                  className={classNames(styles.bucketIcon, styles.withoutLeftMg, 'noBottomMargin')}
                >
                  <IconButton
                    block
                    size='large'
                    color='orange'
                    icon={<BucketIcon />}
                    slim
                    type='default'
                    onClick={() => {
                      remove(name)
                      recalculateMoneyInfo(pinList)
                    }}
                  />
                </FormItem>
              </div>
            ))}
            <div className={classNames(styles.addBtn, styles.fullWidth)}>
              <IconButton
                size='large'
                block
                color='orange'
                icon={<AddIcon />}
                type='default'
                disabled={view}
                onClick={() => add()}
              >
                add more
              </IconButton>
            </div>
          </>
        )}
      </FormList>

      <FormItem valuePropName='list' name='pinList' getValueFromEvent={normFile}>
        <Dragger
          {...propsDragger}
          fileList={files}
          style={{ marginTop: 20 }}
          beforeUpload={(info) => {
            if (typeof info.type !== 'string') {
              if (isNotAcceptedType(info.type)) {
                return false
              }
            }
          }}
        >
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p>Click or drag file to this area to upload</p>
          <p className='small'>xlsx, csv</p>
        </Dragger>
      </FormItem>
    </Wrapper>
  )
}
