import styles from '../styles.module.scss'
import { Form, Row, Space, Spin, UploadFile } from 'antd'
import { DetailInfo } from '../Components/DetailInfo'
import { Address } from '../Components/Address'
import { ControlPanel } from '../Components/ControlPanel'
import { UploaderPhotos2 } from 'src/shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import { RcFile } from 'antd/lib/upload'
import { Button } from 'src/shared/components/button/Button'
import React, { useContext, useEffect, useState } from 'react'
import { StepRegistration } from 'src/shared/components/stepRegistration'
import { formatOnlyDate } from 'src/helpers/utils'
import { IconButton } from '../../../../../shared/components/iconButton/IconButton'
import { AddIcon } from '../../../../../assets/svg'
import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  IVendorContact,
  IVendorManagement,
  VENDOR_TYPE_ENUM,
} from '../../../models/IVendorManagement'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { useCreateVendorMutation } from '../../../core/http/VendorManagementApi'
import { RIGHT_MODALS } from 'src/helpers/contants'
import { RightModalContext } from 'src/features/Modals'
import { useAppDispatch, useAppSelector } from '../../../../../redux'
import {
  addProduct,
  clearVendorSliceState,
  deleteContactById,
  deleteProductById,
  selectVendorManagementState,
} from '../../../core/store/VendorManagementSlice'
import { Contact } from '../VendorManagementContactListModal'
import { ProductsList } from './Components/VendorManagementProductsList/ProductsList'
import { IProduct } from '../../../models/IProduct'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import { SETTINGS_ENUM } from '../../../../../routes/settings'
import { omit } from 'lodash'
import { VendorMasterColorBlock } from '../Components/VendorMasterColorBlock'
import FormItem from 'antd/es/form/FormItem'

export const VendorManagementNew = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isImageError, setIsImageError] = useState(false)
  const [picture, setPicture] = useState<RcFile>()
  const [form] = Form.useForm()
  const isViewMode = false
  const location = useLocation()

  const { contactList, productsList } = useAppSelector(selectVendorManagementState)

  const contactListId = contactList.map((contact: IVendorContact) => contact.id)

  const { data: userProfile, isFetching } = useGetProfileQuery()
  const [createVendor, crVenResp] = useCreateVendorMutation()

  useNotification(NOTIFICATION_TYPES.success, crVenResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, crVenResp.isError, crVenResp.error as ErrorNode)

  const onFinish = (values: IVendorManagement) => {
    let reqBody = {
      ...values,
      name: form.getFieldValue('name'),
      createdAt: new Date().toISOString(),
      status: !!values?.status,
    }
    if (!reqBody.addressLine2) {
      reqBody = omit(reqBody, 'addressLine2')
    }
    if (!reqBody.controlPanelPassword) {
      reqBody = omit(reqBody, 'controlPanelPassword')
    }
    if (!reqBody.controlPanelURL) {
      reqBody = omit(reqBody, 'controlPanelURL')
    }
    if (!reqBody.controlPanelUsername) {
      reqBody = omit(reqBody, 'controlPanelUsername')
    }
    const fData = new FormData()

    Object.entries(reqBody).forEach((item) => fData.append(item[0], item[1]))

    contactListId.forEach((item, idx) => fData.append(`contacts[${idx}]`, String(item)))
    productsList.forEach((product, idx) => fData.append(`products[${idx}]`, String(product.id)))

    fData.append('logo', picture!)
    createVendor(fData)
  }

  const { onOpen } = useContext(RightModalContext)

  const showModalContactList = () => {
    onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_CONTACT_LIST, {
      title: 'Add Contacts',
    })
    location.state.isOpenModal = false
  }

  location?.state?.isOpenModal && showModalContactList()

  const onDeleteContact = (contact: IVendorContact) => () => {
    dispatch(deleteContactById(contact.id))
  }
  const onDeleteProduct = (id: number) => {
    dispatch(deleteProductById(id))
  }
  const onAddProduct = (product: IProduct) => {
    dispatch(addProduct(product))
  }

  useEffect(() => {
    return () => {
      dispatch(clearVendorSliceState())
    }
  }, [dispatch])

  useEffect(() => {
    if (crVenResp.isSuccess) {
      return navigate(`/settings/vendor-management/view/${crVenResp.data.id}`)
    }
    // eslint-disable-next-line
  }, [crVenResp.isSuccess])

  useEffect(() => {
    if (userProfile) {
      form.setFieldValue('createdBy', `${userProfile?.firstName} ${userProfile?.lastName}`)
      form.setFieldValue('createdAt', formatOnlyDate(new Date(), userProfile?.calendar))
    }
  }, [form, userProfile])

  const content = (
    <div>
      <Form layout='vertical' form={form} onFinish={onFinish} autoComplete='off'>
        <div className={classNames(styles.columnWrapper, styles.layout)}>
          <div className={styles.leftColumn}>
            <StepRegistration stepNumber='1' stepText='General Information' mB='32px' mT='11.5px' />
            <DetailInfo view={isViewMode} />

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const isGroupSimCard = getFieldValue('vendorType') === VENDOR_TYPE_ENUM.PROCESSOR
                return (
                  <>
                    {!isGroupSimCard && (
                      <>
                        <StepRegistration stepNumber='2' stepText='Address' mB='32px' mT='32px' />
                        <Address view={isViewMode} />

                        <StepRegistration
                          stepNumber='3'
                          stepText='Control Panel'
                          mB='32px'
                          mT='32px'
                        />
                        <ControlPanel view={isViewMode} />
                      </>
                    )}
                    <div className={styles.productsBlock}>
                      <StepRegistration
                        stepNumber={isGroupSimCard ? '2' : '4'}
                        stepText='Add Products'
                        mB='32px'
                        mT='32px'
                      />
                      <ProductsList
                        products={productsList}
                        onDeleteProduct={onDeleteProduct}
                        onAddProduct={onAddProduct}
                        onVendorCreate
                        defaultChecked
                      />
                      <IconButton
                        icon={<AddIcon />}
                        color='orange'
                        iconPos='right'
                        size='large'
                        block
                        onClick={() => {
                          onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_PRODUCT_LIST, {
                            title: 'Add Products',
                          })
                        }}
                      >
                        Add Products
                      </IconButton>
                    </div>
                    {!isGroupSimCard && (
                      <>
                        <StepRegistration
                          stepNumber='5'
                          stepText='Add Contacts'
                          mB='32px'
                          mT='32px'
                        />
                        {contactList &&
                          contactList.map((contact: IVendorContact) => {
                            return (
                              <Contact
                                key={contact.id}
                                contact={contact}
                                onClickConfirm={onDeleteContact}
                                isConfirm={true}
                                onVendorCreate
                              />
                            )
                          })}
                        <IconButton
                          icon={<AddIcon />}
                          color='orange'
                          iconPos='right'
                          size='large'
                          block
                          onClick={() => {
                            onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_CONTACT_LIST, {
                              title: 'Add Contacts',
                            })
                          }}
                        >
                          Add Contacts
                        </IconButton>
                      </>
                    )}
                  </>
                )
              }}
            </FormItem>
          </div>
          <div className={styles.rightBlock}>
            <UploaderPhotos2
              form={form}
              title='Vendor Logo'
              view={isViewMode}
              onChange={(photo: UploadFile[]) => {
                setPicture(photo[0].originFileObj as RcFile)
                setIsImageError(
                  form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                )
              }}
            />
            <VendorMasterColorBlock title='Vendor Color' />
          </div>
        </div>
      </Form>
      <div>
        <Row className={styles.footerStyle} justify='end'>
          <Space size={10}>
            <div className='cancelButtonWrapper'>
              <Button
                color='blue'
                size='middle'
                disabled={crVenResp.isLoading}
                onClick={() => navigate(SETTINGS_ENUM.VENDOR_MANAGEMENT)}
                block
              >
                Cancel
              </Button>
            </div>
            <Button
              color='blue'
              htmlType='submit'
              size='middle'
              type='primary'
              disabled={isImageError || crVenResp.isLoading}
              onClick={form.submit}
            >
              Create Vendor
            </Button>
          </Space>
        </Row>
      </div>
    </div>
  )

  return isFetching || crVenResp.isLoading ? <Spin>{content}</Spin> : content
}
