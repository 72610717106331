import { Col } from 'antd'
import classNames from 'classnames'
import { ReactNode } from 'react'
import styles from './styles.module.scss'
import { RecursivelyReplaceCharacters } from '../../../../shared/components/privateMode'

interface IHeaderDoubleCard {
  color?: 'blue' | 'orange'
  cardName: string
  cardName2?: string
  cardValue: string
  cardValue2?: string
  fontSize?: number
  right?: ReactNode
  letterSpacing?: number | string
  isBalance?: boolean
}

const HeaderDoubleCard = ({
  cardName,
  cardName2,
  cardValue,
  cardValue2,
  right,
  fontSize = 20,
  color = 'orange',
  letterSpacing,
}: IHeaderDoubleCard) => {
  return (
    <RecursivelyReplaceCharacters>
      <Col className={styles['header-card']}>
        <div className={styles['header-inner-container']}>
          <div className={styles['left']}>{cardName}</div>
          <div
            className={classNames(styles['center'], styles[`center-${color}`])}
            style={{ fontSize, letterSpacing }}
          >
            {cardValue}
          </div>
          {right && <div className={styles['right']}>{right}</div>}
        </div>

        <div
          className={classNames(
            styles['header-inner-container'],
            styles['header-inner-container-2']
          )}
        >
          <div className={styles['left']}>{cardName2}</div>
          <div
            className={classNames(styles['center'], styles[`center-grey`])}
            style={{ fontSize, letterSpacing }}
          >
            {cardValue2}
          </div>
          {right && (
            <div className={classNames(styles['right'], styles[`center-grey`])}>{right}</div>
          )}
        </div>
      </Col>
    </RecursivelyReplaceCharacters>
  )
}
export default HeaderDoubleCard
