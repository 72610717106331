import React from 'react'
import { Button } from 'src/shared/components/button/Button'

// styles
import styles from '../styles.module.scss'
import { TRANSACTION_STATUS_ENUM } from '../../../../../../../Finance/models/ITransaction'

interface IFooter {
  status: TRANSACTION_STATUS_ENUM
  onEdit: () => void
  onCheck: () => void
  onReject: () => void
  onApprove: () => void
}

export const Footer: React.FC<IFooter> = ({ status, onEdit, onCheck, onReject, onApprove }) => {
  return (
    <div className={styles.footerContainer}>
      {status === 'Created' && (
        <div className={styles.madeContainer}>
          <Button color='blue' size='middle' onClick={onEdit}>
            edit
          </Button>
          <Button color='orange' size='middle' type='primary' onClick={onCheck}>
            mark as checked
          </Button>
        </div>
      )}
      {status === 'Checked' && (
        <div className={styles.checkedContainer}>
          <Button color='blue' size='middle' onClick={onEdit}>
            edit
          </Button>
          <Button color='orange' size='middle' onClick={onReject}>
            reject
          </Button>
          <Button color='orange' size='middle' type='primary' onClick={onApprove}>
            approve
          </Button>
        </div>
      )}
    </div>
  )
}
