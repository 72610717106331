import { WALLET_TYPE_ENUM } from './IWallet'
import { ICurrency } from './ICurency'
import { IByUser, IUserAvatar } from '../../../shared/models/IByUser'
import { DEBIT_CREDIT_ENUM, TRANSACTION_STATUS_ENUM } from './ITransaction'
import { ISelectedFilters } from '../../../shared/components/filter/Filter'
import { IAttachment } from '../../Support/models/ITicket'
import { ITableResponse } from '../../../shared/models/ITableResponse'
import { VENDOR_WALLET_TYPE_ENUM } from './IPurchaseOrder'

export interface IWalletStatement {
  walletId?: number
  wallet: WALLET_TYPE_ENUM
  balance: null
  debit: number
  credit: number
  subWallets?: ITableResponse<IWalletStatement>
}

export interface IWalletStatementTrans extends IWalletStatement {
  children: Array<IWalletStatement>
  walletId: number
  subWallets?: ITableResponse<IWalletStatement>
}

export interface IWalletStatementTransactionDetail {
  id: number
  createdAt: Date
  drCr: DEBIT_CREDIT_ENUM
  amount: number
  beforeBalance: number
  afterBalance: number
  status: TRANSACTION_STATUS_ENUM
  sender: string
  receiver: string
  escrowSender: boolean
  escrowReceiver: boolean
  mainTransactionId: string
  vendor: IByUser
  agent: IUserAvatar
  currency: ICurrency
}

export enum WALLET_STATEMENT_CAT_ROUTE_ENUM {
  TREASURY = 'treasury-wallet',
  AGENT = 'agent-wallet',
  OPERATION = 'operation-wallet',
  DISCOUNT = 'discount-wallet',
  SYSTEM = 'system-wallet',
  REWARD = 'reward-wallet',
  BANK_ACCOUNT = 'bank-account',
  COLLECTION = 'collection-wallet',
  CREDIT = 'credit-wallet',
  HAWALA = 'hawala-wallet',
  PROMOTION = 'promotion-wallet',
  VENDOR = 'vendor-wallet',
}

export interface IWalletStatementPDFReport {
  link: string
}

export interface IWalletStatementExportParams {
  route: WALLET_STATEMENT_CAT_ROUTE_ENUM
  params: ISelectedFilters
}

export enum ADJUSTMENT_TYPE_ENUM {
  BANK_ACCOUNT_ADJUSTMENT = 'BANK_ACCOUNT_ADJUSTMENT',
  VENDOR_WALLET_ADJUSTMENT = 'VENDOR_WALLET_ADJUSTMENT',
  AGENT_WALLET_ADJUSTMENT = 'AGENT_WALLET_ADJUSTMENT',
  AGENT_ESCROW_WALLET_ADJUSTMENT = 'AGENT_ESCROW_WALLET_ADJUSTMENT',
  HOLD_AGENT_WALLET_ADJUSTMENT = 'HOLD_AGENT_WALLET_ADJUSTMENT',
}

export interface IAdjustmentDetail {
  id: number
  type: ADJUSTMENT_TYPE_ENUM
  amount: number
  description: string
  status: TRANSACTION_STATUS_ENUM
  vendorWalletType: VENDOR_WALLET_TYPE_ENUM
  bankAccount: IByUser
  vendor: IByUser
  agent: IUserAvatar
  createdByUser: IByUser
  updatedByUser: IByUser
  attachments: Array<IAttachment>
  createdAt: Date
  updatedAt: Date
  drCr: DEBIT_CREDIT_ENUM
}

export interface IAdjustmentCreate {
  type: ADJUSTMENT_TYPE_ENUM
  bankAccountId?: number
  vendorId?: number
  agentId?: number
  amount: number
  description: string
  drCr: DEBIT_CREDIT_ENUM
}
