import { Wrapper } from 'src/features/salesNetwork/components/Wrapper'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { Form, Row, Select, Spin } from 'antd'
import classNames from 'classnames'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from '../../../../../../../assets/svg'
import FormList from 'antd/es/form/FormList'
import React from 'react'
import styles from '../../CustomerModal/styles.module.scss'
import { ITargetGroupCustomer } from '../../../../../../Settings/models/IRewardPolicy'
import { CustomerListItem } from '../CustomerListItem'

interface IProps extends IViewMode {
  customers?: Array<ITargetGroupCustomer>
}

export const DetailView = ({ view, customers = [] }: IProps) => {
  return (
    <div>
      <Wrapper title='Detail Info'>
        <FormItem name='id' label='Group ID' className='noBottomMargin'>
          <TextField disabled />
        </FormItem>
      </Wrapper>
      <Wrapper title='Customers'>
        <FormList name='customers'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name, key }) => (
                <CustomerListItem
                  key={key}
                  name={name}
                  remove={remove}
                  customers={customers}
                  view={view}
                />
              ))}
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <>
                    {getFieldValue('customers')?.length < 10 && !view && (
                      <Row>
                        <Form.Item className={classNames('noBottomMargin', styles.addButton)}>
                          <IconButton
                            icon={<AddIcon />}
                            color='orange'
                            block
                            onClick={() => add()}
                          />
                        </Form.Item>
                      </Row>
                    )}
                  </>
                )}
              </FormItem>
            </>
          )}
        </FormList>
      </Wrapper>
    </div>
  )
}
