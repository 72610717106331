import ReactPlayer from 'react-player'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { FullScreenIcon, PauseIcon, PlayArrowIcon } from 'src/assets/svg'
import styles from '../styles.module.scss'
import '../style.scss'
import { convertSecToMin } from '../../../../../../helpers'
import screenfull from 'screenfull'
import { notification } from 'antd'

export const VideoViewer = ({ src }: { src: string }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [totalDuration, setTotalDuration] = useState(0)
  const [isSeeking, setIsSeeking] = useState(false)
  const [playedSeconds, setPlayedSeconds] = useState(0)

  const playerRef = useRef<ReactPlayer>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const handlePlay = () => {
    if (totalDuration === 0) {
      setTotalDuration(Math.floor(playerRef.current!.getDuration()))
    }
    setIsPlaying((prevState) => !prevState)
  }

  const handleClickFullscreen = () => {
    if (playerRef.current) {
      const videoElement = playerRef.current! as unknown as {
        wrapper: { childNodes: Array<Element> }
      }
      screenfull.request(videoElement.wrapper.childNodes[0])
    }
  }

  const handleSeekMouseDown = () => {
    setIsSeeking(true)
  }

  const handleSeekChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPlayedSeconds(parseFloat(e.target.value))
    e.target.style.setProperty('--value', e.target.value)
  }

  const handleSeekMouseUp = () => {
    setIsSeeking(false)
    playerRef.current!.seekTo(playedSeconds)
  }

  const handleProgress = (value: number) => {
    if (!isSeeking) {
      setPlayedSeconds(value)
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.setProperty('--min', '0')
      inputRef.current.style.setProperty('--max', totalDuration.toString())
      inputRef.current!.style.setProperty('--value', playedSeconds.toString())
    }
  }, [playedSeconds, totalDuration])

  return (
    <div className={styles.playerWrapper}>
      <ReactPlayer
        className='videoPlayer'
        playing={isPlaying}
        url={src}
        width='100%'
        height='100%'
        ref={playerRef}
        onProgress={(progress) => {
          handleProgress(Math.floor(progress.playedSeconds))
        }}
        onError={() => {
          notification.open({ type: 'error', message: 'Failed to load content' })
        }}
      />
      <div className={styles.videoFooter}>
        <button className={styles.videoButton} onClick={handlePlay}>
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </button>
        {convertSecToMin(playedSeconds)}
        <input
          type='range'
          className='styled-slider slider-progress'
          ref={inputRef}
          min={0}
          max={totalDuration}
          value={playedSeconds}
          onMouseDown={handleSeekMouseDown}
          onChange={handleSeekChange}
          onMouseUp={handleSeekMouseUp}
        />
        {convertSecToMin(totalDuration)}
        <button className={styles.videoButton} onClick={handleClickFullscreen}>
          <FullScreenIcon />
        </button>
      </div>
    </div>
  )
}
