import { useCallback, useState } from 'react'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import { useGetQueryParams } from '../../../../../../shared/hooks/table/useGetQueryParams'
import { Table } from 'antd'
import styles from '../VendorManagementTable/style.module.scss'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import useTable from '../../../../../../shared/hooks/table/useTable'
import { ColumnsType } from 'antd/es/table'
import { IResponseCodeTable } from '../../../../models/IResponseCode'
import { Tags } from '../../../../../../shared/components/tags'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../shared/components/popup/Popup'
import { TableActionIcon } from '../../../../../../assets/svg'
import { useNavigate } from 'react-router'
import { TableHeader } from '../../VendorManagementView/Components/TableHeader'
import {
  useDeleteResponseCodeByIdMutation,
  useGetResponseCodeFilterListQuery,
  useGetResponseCodeListQuery,
  useLazyExportResponseCodesTableQuery,
} from '../../../../core/http/ResponseCodeApi'
import { useTableExport } from '../../../../../../shared/hooks/table/useTableExport'
import { ErrorNode, showConfirmMessage } from '../../../../../../shared/api/errorHandler'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'

export const ResponseCodesTable = () => {
  const navigate = useNavigate()
  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'code',
  })

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data, isFetching } = useGetResponseCodeListQuery(
    { ...tableConf, ...filterValues },
    { skip: Object.keys(tableConf).length < 4 }
  )
  const { data: filterFields } = useGetResponseCodeFilterListQuery()
  const [delRespCode, delRCResp] = useDeleteResponseCodeByIdMutation()
  const [exportTable] = useLazyExportResponseCodesTableQuery()

  useTableExport({ tableConf, filterValues, promise: exportTable })
  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IResponseCodeTable>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  useNotification(NOTIFICATION_TYPES.success, delRCResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, delRCResp.isError, delRCResp.error as ErrorNode)

  const tableActionsPopup = useCallback(
    (id: IResponseCodeTable['code']): IPopupListItems[] => [
      {
        text: 'View',
        shouldDisplay: true,
        onClick: () => navigate(`response-code/view/${id}`),
      },
      {
        text: 'Edit',
        shouldDisplay: true,
        onClick: () => navigate(`response-code/edit/${id}`),
      },
      {
        text: 'Delete',
        shouldDisplay: true,
        withDivider: true,
        color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        onClick: () =>
          showConfirmMessage('This Response code will be deleted', () => delRespCode(id)),
      },
    ],
    [delRespCode, navigate]
  )

  const columns: ColumnsType<IResponseCodeTable> = [
    {
      title: 'Response Code',
      dataIndex: 'code',
      width: '30%',
      sorter: true,
      render: (code) => <RecursivelyReplaceCharacters>{code}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Type',
      dataIndex: 'responseType',
      width: '25%',
      sorter: true,
      render: (code) => <RecursivelyReplaceCharacters>{code}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Channel',
      dataIndex: 'channels',
      width: '20%',
      render: (operators: IResponseCodeTable['channels']) => <Tags tags={operators} isBlue />,
    },
    {
      title: 'Vendor Response Codes',
      dataIndex: 'vendorCodes',
      width: '20%',
      render: (codes: IResponseCodeTable['vendorCodes']) => <Tags tags={codes} isBlue />,
    },
    {
      title: '',
      dataIndex: 'edit',
      width: '1%',
      key: 'edit',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.code)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return (
    <div>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filterFields}
      />
      <Table
        columns={columns}
        locale={emptyTableConf}
        onChange={handleTableChange}
        className={styles.table}
        loading={isFetching}
        dataSource={data?.items}
        scroll={{ x: 1000 }}
        pagination={pagination}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.code}
      />
    </div>
  )
}
