import {
  DatePicker as AntDatePicker,
  DatePickerProps,
  TimePicker as AntdTimePicker,
  TimePickerProps,
} from 'antd'
import { Moment } from 'moment'

import { RangePickerProps as BaseRangePickerProps } from 'antd/lib/date-picker/generatePicker'
import { DatePicker as DatePickerJalali } from 'antd-jalali'
import jalaliday from 'jalaliday'
import utc from 'dayjs/plugin/utc'
import updateLocale from 'dayjs/plugin/updateLocale'
import weekOfYear from 'dayjs/plugin/weekOfYear'

import dayjs, { Dayjs } from 'dayjs'

dayjs.extend(jalaliday)
dayjs.extend(weekOfYear)
dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.updateLocale('en', {
  weekStart: 6,
  months: ['Hml', 'Swr', 'Jwz', 'Srt', 'Asa', 'Snb', 'Miz', 'Aqr', 'Qws', 'Jad', 'Dlw', 'Hot'],
})

import { DatePickerIcon, TimePickerIcon } from '../../../assets/svg'

import styles from '../filter/styles.module.scss'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { CALENDAR_TYPE_ENUM } from 'src/features/Settings/models/IUser'
import { useState } from 'react'
import classNames from 'classnames'

const JalaliPicker = (props: DatePickerProps) => {
  const jalaliDate = props.value || dayjs().calendar('jalali')

  return <DatePickerJalali {...props} defaultPickerValue={jalaliDate} />
}

export const DatePicker = (props: DatePickerProps) => {
  const { data = { calendar: false } } = useGetProfileQuery()

  return data.calendar === CALENDAR_TYPE_ENUM.GREGORIAN ? (
    <AntDatePicker
      {...props}
      className={styles.dataPicker}
      // @ts-ignore
      showToday={false}
      showNow={false}
      suffixIcon={
        <div className={styles.wrapperDataPickerIcon}>
          <DatePickerIcon />
        </div>
      }
    />
  ) : (
    <JalaliPicker
      {...props}
      className={styles.dataPicker}
      // @ts-ignore
      showNow={false}
      // @ts-ignore
      showToday={false}
      suffixIcon={
        <div className={styles.wrapperDataPickerIcon}>
          <DatePickerIcon />
        </div>
      }
    />
  )
}

const JalaliRangePicker = (props: BaseRangePickerProps<Dayjs>) => {
  const [open, setOpen] = useState(false)
  const jalaliDateStart = props.value?.[0] || dayjs().calendar('jalali')

  return (
    <DatePickerJalali.RangePicker
      {...props}
      onOpenChange={setOpen}
      defaultPickerValue={[jalaliDateStart, jalaliDateStart]}
      className={classNames(styles.dataPicker, {
        [styles.hideClear]: open,
      })}
    />
  )
}

export const RangePicker = (props: BaseRangePickerProps<Moment | Dayjs>) => {
  const { data = { calendar: false } } = useGetProfileQuery()

  return data.calendar === CALENDAR_TYPE_ENUM.GREGORIAN ? (
    //@ts-ignore
    <AntDatePicker.RangePicker
      {...props}
      suffixIcon={
        <div className={styles.wrapperDataPickerIcon}>
          <DatePickerIcon />
        </div>
      }
      className={styles.dataPicker}
    />
  ) : (
    //@ts-ignore
    <JalaliRangePicker
      {...props}
      suffixIcon={
        <div className={styles.wrapperDataPickerIcon}>
          <DatePickerIcon />
        </div>
      }
      className={styles.dataPicker}
    />
  )
}

export const TimePicker = (props: TimePickerProps) => {
  return (
    <AntdTimePicker
      {...props}
      className={styles.dataPicker}
      suffixIcon={
        <div className={styles.wrapperDataPickerIcon}>
          <TimePickerIcon />
        </div>
      }
    />
  )
}
