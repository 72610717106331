import {
  useDeleteWhatsAppButtonMutation,
  useGetWhatsAppActionsListQuery,
  useGetWhatsAppButtonsListQuery,
} from '../../../core/http/WhatsAppManagerApi'
import React, { useEffect, useState } from 'react'
import { ITableConf } from '../../../../../shared/models/ITableConf'
import { isEmpty, unionBy } from 'lodash'
import {
  IWhatsappManagerAction,
  IWhatsappManagerButton,
  WHATSAPP_BUTTON_MODAL_STEPS,
  WHATSAPP_BUTTON_MODAL_TABS,
} from '../../../models/IWhatsappManager'
import styles from './styles.module.scss'
import { ButtonList } from './Components/ButtonList'
import { ViewButtonStep } from './Components/ViewButton'
import { Tabs } from 'antd'
import { AddIcon } from '../../../../../assets/svg'
import { IconButton } from '../../../../../shared/components/iconButton/IconButton'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import { ErrorNode, showConfirmMessage } from '../../../../../shared/api/errorHandler'
import { ActionsList } from './Components/ActionsList'
import { ViewAction } from './Components/ViewAction'

interface IOperationProps {
  onAddButtonClick: () => void
  activeTab: WHATSAPP_BUTTON_MODAL_TABS
}

const TabsOperations = ({ onAddButtonClick, activeTab }: IOperationProps) => {
  return (
    <>
      {activeTab === WHATSAPP_BUTTON_MODAL_TABS.BUTTONS && (
        <IconButton icon={<AddIcon />} type='primary' color='orange' onClick={onAddButtonClick} />
      )}
    </>
  )
}

const initialTableConf = {
  page: 1,
  limit: 7,
}

export const WhatsAppManagerButtonsModal = () => {
  const [id, setId] = useState<string | null>(null)
  const [step, setStep] = useState(WHATSAPP_BUTTON_MODAL_STEPS.LIST)
  const [activeTab, setActiveTab] = useState(WHATSAPP_BUTTON_MODAL_TABS.BUTTONS)
  const [buttons, setButtons] = useState<Array<IWhatsappManagerButton>>([])
  const [actions, setActions] = useState<Array<IWhatsappManagerAction>>([])
  const [activeAction, setActiveAction] = useState<IWhatsappManagerAction | null>(null)
  const [tableConf, setTableConf] = useState<ITableConf>(initialTableConf)

  const { data: buttonsListData } = useGetWhatsAppButtonsListQuery(
    {},
    {
      skip: activeTab === WHATSAPP_BUTTON_MODAL_TABS.ACTIONS,
    }
  )
  const { data: actionsListData } = useGetWhatsAppActionsListQuery(
    {},
    {
      skip: activeTab === WHATSAPP_BUTTON_MODAL_TABS.BUTTONS,
    }
  )
  const [delBtn, delBtnResp] = useDeleteWhatsAppButtonMutation()

  useNotification(NOTIFICATION_TYPES.success, delBtnResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, delBtnResp.isError, delBtnResp.error as ErrorNode)

  const loadMoreData = () => {
    setTableConf((pre) => ({ ...pre, page: pre.page! + 1 }))
  }

  const changeStep = (step: WHATSAPP_BUTTON_MODAL_STEPS, id: string | null) => {
    setStep(step)
    setId(id)
  }

  const onAddButtonClick = () => {
    changeStep(WHATSAPP_BUTTON_MODAL_STEPS.VIEW_BUTTON, null)
  }

  const onDeleteButtonClick = (id: string) => {
    showConfirmMessage('This button will be deleted', () => delBtn(id))
  }

  useEffect(() => {
    if (isEmpty(buttonsListData) || activeTab === WHATSAPP_BUTTON_MODAL_TABS.ACTIONS) return

    if (tableConf.page === 1) {
      setButtons(buttonsListData?.items)
    } else {
      return setButtons(unionBy([...buttons, ...buttonsListData!.items], 'id'))
    }
    // eslint-disable-next-line
  }, [buttonsListData, activeTab])

  useEffect(() => {
    if (isEmpty(actionsListData) || activeTab === WHATSAPP_BUTTON_MODAL_TABS.BUTTONS) return

    if (tableConf.page === 1) {
      setActions(actionsListData?.items)
    } else {
      return setActions(unionBy([...actions, ...actionsListData!.items], 'id'))
    }
    // eslint-disable-next-line
  }, [actionsListData, activeTab])

  return (
    <div className={styles.layout}>
      {step === WHATSAPP_BUTTON_MODAL_STEPS.LIST && (
        <Tabs
          destroyInactiveTabPane
          className={styles.tabs}
          activeKey={activeTab}
          onChange={(val) => {
            setActiveTab(val as WHATSAPP_BUTTON_MODAL_TABS)
            setActions([])
            setButtons([])
            setTableConf(initialTableConf)
          }}
          items={[
            {
              label: WHATSAPP_BUTTON_MODAL_TABS.BUTTONS,
              key: WHATSAPP_BUTTON_MODAL_TABS.BUTTONS,
              children: (
                <ButtonList
                  loadMoreData={loadMoreData}
                  buttons={buttons}
                  data={buttonsListData}
                  setViewStep={changeStep}
                  onDeleteButtonClick={onDeleteButtonClick}
                />
              ),
            },
            {
              label: WHATSAPP_BUTTON_MODAL_TABS.ACTIONS,
              key: WHATSAPP_BUTTON_MODAL_TABS.ACTIONS,
              children: (
                <ActionsList
                  setAction={setActiveAction}
                  actions={actions}
                  loadMoreData={loadMoreData}
                  setViewStep={changeStep}
                  data={actionsListData}
                />
              ),
            },
          ]}
          tabBarExtraContent={
            <TabsOperations
              onAddButtonClick={onAddButtonClick}
              activeTab={activeTab as WHATSAPP_BUTTON_MODAL_TABS}
            />
          }
        />
      )}
      {step === WHATSAPP_BUTTON_MODAL_STEPS.VIEW_BUTTON && (
        <ViewButtonStep onChangeStep={changeStep} id={id} />
      )}
      {step === WHATSAPP_BUTTON_MODAL_STEPS.VIEW_ACTION && (
        <ViewAction onChangeStep={changeStep} setAction={setActiveAction} action={activeAction} />
      )}
    </div>
  )
}
