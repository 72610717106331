import styles from './Breadcrumb.module.scss'
import { Breadcrumb as AntBreadcrumb } from 'antd'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ArrowLeftIcon, ChevronRightIcon } from 'src/assets/svg'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { appRoutesList } from '../../../routes/routes'
import { split } from 'lodash'
import { EXCLUDE_BREADCRUMB } from '../../../helpers/contants'
import { useEffect } from 'react'
import { useAppDispatch } from '../../../redux'
import { resetBreadcrumb } from '../../../features/app/core/BreadcrumbsSlice'
import { Path } from '@remix-run/router/history'

const { Item } = AntBreadcrumb

export const Breadcrumb = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { key: keyLocation } = useLocation()

  const isViewOrEditLink = (link: string) => {
    const splitLink = split(link, '/')
    return EXCLUDE_BREADCRUMB.includes(splitLink[splitLink.length - 1])
  }

  const handleGoBack: VoidFunction = () => {
    const isInitialLocation: boolean = keyLocation === 'default'
    const to: string | Partial<Path> = isInitialLocation
      ? breadcrumbs[1].match.pathname
      : (-1 as Partial<Path>)
    navigate(to)
  }

  const breadcrumbs = useBreadcrumbs(appRoutesList)
  breadcrumbs.shift()
  const isThreeOrMoreElements = breadcrumbs?.length >= 3

  useEffect(() => {
    if (!isThreeOrMoreElements) {
      dispatch(resetBreadcrumb())
    }
  }, [dispatch, isThreeOrMoreElements])

  return (
    <>
      <AntBreadcrumb separator={<ChevronRightIcon />} className={styles.breadcrumbWrapper}>
        {breadcrumbs.map(({ breadcrumb, match }, index, arr) => {
          const linkPath = index === 0 ? arr[index + 1]?.match?.pathname : match.pathname
          if (isViewOrEditLink(match.pathname)) return null

          return (
            <Item className={styles.item} key={match.pathname}>
              <Link to={linkPath}>{breadcrumb}</Link>
            </Item>
          )
        })}
      </AntBreadcrumb>
      <div className={styles['wrapper-title-page']}>
        {isThreeOrMoreElements && (
          <div className={styles['wrapper-arrow-left-icon']} onClick={handleGoBack}>
            <ArrowLeftIcon />
          </div>
        )}

        <div className={styles['title-page']}>
          {/* {agentId && (
            <span className={styles['title-page-detail-view']}>
              {agentNameById[agentId as string] ? agentNameById[agentId as string] : 'Agent Name'}
            </span>
          )} */}

          {breadcrumbs?.at(-1)?.breadcrumb}
        </div>
      </div>
    </>
  )
}
