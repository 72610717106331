import { InfoMessage } from '../../../VendorManagementContactListModal/Components/InfoMessage'
import { useContext, useEffect, useState } from 'react'
import { ISelectedFilters } from 'src/shared/components/filter/Filter'
import {
  useAssignProductsToVendorMutation,
  useGetProductsFiltersListQuery,
  useGetProductsListQuery,
} from '../../../../../core/http/VendorManagementApi'
import { ProductsList } from './ProductsList'
import { InfiniteScrollComponent } from '../../../VendorManagementContactListModal'
import { IProduct } from '../../../../../models/IProduct'
import { Col, Spin } from 'antd'
import useSearch from '../../../../../helpers/useSearch'
import { ContactListModalHeader } from '../../../VendorManagementContactListModal/Components/ContactListModalHeader'
import styles from '../../../VendorManagementContactListModal/styles.module.scss'
import { TextFieldSearch } from '../../../../../../../shared/components/textFieldSearch/TextFieldSearch'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon } from '../../../../../../../assets/svg'
import { addProduct, deleteProductById } from '../../../../../core/store/VendorManagementSlice'
import { useAppDispatch } from '../../../../../../../redux'
import { RIGHT_MODALS } from '../../../../../../../helpers/contants'
import { RightModalContext } from '../../../../../../Modals'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../shared/api/errorHandler'
import { ITableConf } from '../../../../../../../shared/models/ITableConf'
import { isEmpty, unionBy } from 'lodash'

export const VendorManagementProductsList = () => {
  const dispatch = useAppDispatch()
  const {
    onOpen,
    props: { vendorId },
    onClose,
  } = useContext(RightModalContext)
  const [productsList, setProductsList] = useState<IProduct[]>([])
  const [filterValues, setFilterValues] = useState<ISelectedFilters>({})
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 10,
    search: '',
  })

  const [assignProduct, assignProdResp] = useAssignProductsToVendorMutation()
  const { data: filterFields } = useGetProductsFiltersListQuery()
  const { data, isFetching } = useGetProductsListQuery({
    ...tableConf,
    ...filterValues,
  })
  const [isChangeSearch, setIsChangeSearch] = useState(false)
  const { handleSearchChange } = useSearch(setTableConf)

  const loadMoreData = () => {
    setTableConf((pre) => ({ ...pre, page: pre.page! + 1 }))
  }

  useEffect(() => {
    if (isEmpty(data)) return

    if (isChangeSearch && (tableConf.search || Object.keys(filterValues).length !== 0)) {
      setProductsList(data!.items)
      setIsChangeSearch(false)
    } else {
      return setProductsList(unionBy([...productsList, ...data!.items], 'id'))
    }
    // eslint-disable-next-line
  }, [data])

  const onDeleteProduct = (id: number) => {
    dispatch(deleteProductById(id))
  }
  const onAddProduct = (product: IProduct) => {
    if (vendorId) {
      return assignProduct({ id: vendorId, body: { items: [product.id] } })
    }
    dispatch(addProduct(product))
  }

  const onCloseRedirect = () => {
    onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_PRODUCT_LIST, {
      title: 'Add Products',
    })
  }

  useNotification(NOTIFICATION_TYPES.success, assignProdResp.isSuccess, null, onClose)
  useNotification(
    NOTIFICATION_TYPES.error,
    assignProdResp.isError,
    assignProdResp.error as ErrorNode
  )

  const searchComponent = (
    <TextFieldSearch
      className={styles.search}
      onChange={(e) => {
        setIsChangeSearch(true)
        handleSearchChange(e)
      }}
    />
  )

  const addButtonComponent = (
    <Col>
      <IconButton
        className={styles.addBtn}
        icon={<AddIcon />}
        type='primary'
        color='orange'
        size='large'
        onClick={() =>
          onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_PRODUCT, {
            title: 'Add Products',
            onCloseRedirect,
          })
        }
      />
    </Col>
  )

  const content = (
    <InfiniteScrollComponent
      loadMoreData={loadMoreData}
      data={productsList as IProduct[]}
      totalCount={data?.totalCount as number}
    >
      <InfoMessage text='Products' />
      <ContactListModalHeader
        classNameFilterPopup={styles.filterPopup}
        setActiveFilters={(res) => {
          setIsChangeSearch(true)
          setFilterValues(res)
        }}
        filterValues={filterValues}
        data={filterFields}
        searchComponent={searchComponent}
        addButtonComponent={addButtonComponent}
        classNamesFilterWrapperIcon={styles.filterWrraperIcon}
      />
      <ProductsList
        products={productsList}
        onDeleteProduct={onDeleteProduct}
        onAddProduct={onAddProduct}
      />
    </InfiniteScrollComponent>
  )

  return !data ? <Spin>{content}</Spin> : content
}
