import React, { memo, useMemo } from 'react'
import { Checkbox } from '../../../../../../../../shared/components/checkbox/Checkbox'
import styles from '../../styles.module.scss'
import { IPermissionAllowed, IPermissionCreate } from '../../../../../../models/IRoles'

interface IProps {
  disabled?: boolean
  action: string
  moduleId: number
  selectedPermissions: Array<IPermissionCreate>
  onCheckPermission: (permission: IPermissionCreate) => void
  permissionModuleAllowedPermissions: Array<IPermissionAllowed>
}

const PermissionsModuleCheckbox = ({
  disabled,
  action,
  moduleId,
  selectedPermissions,
  onCheckPermission,
  permissionModuleAllowedPermissions,
}: IProps) => {
  const isDisableCheckBox = useMemo(() => {
    return !permissionModuleAllowedPermissions.map((el) => el.permission).includes(action)
  }, [action, permissionModuleAllowedPermissions])

  const isCheckedPermission = useMemo(() => {
    return !!selectedPermissions.find(
      (p) => action === p.permission && moduleId === p.permissionModuleId
    )
  }, [action, moduleId, selectedPermissions])

  return (
    <div
      key={`simple-module-${module.id}-${action}`}
      className={styles.actionContainer}
      style={{ paddingLeft: 16, height: 56, alignItems: 'center' }}
    >
      <Checkbox
        disabled={isDisableCheckBox || disabled}
        checked={isCheckedPermission}
        onChange={() =>
          onCheckPermission({
            permissionModuleId: moduleId,
            permission: action,
          })
        }
      />
    </div>
  )
}

export default memo(PermissionsModuleCheckbox)
