import { VendorManagementViewTabs } from './Components/Tabs/index'
import styles from '../styles.module.scss'

export const VendorManagementView = () => {
  return (
    <div className={styles.layout}>
      <VendorManagementViewTabs />
    </div>
  )
}
