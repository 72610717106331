import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/shared/api'
import { ITableConf } from 'src/shared/models/ITableConf'
import { ITableResponse } from 'src/shared/models/ITableResponse'
import { IGeneralSearchItem } from '../models/IGeneralSearch'

export const generalApi = createApi({
  reducerPath: 'general',
  baseQuery: baseQueryWithReauth,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getGeneralSearch: builder.query<ITableResponse<IGeneralSearchItem>, ITableConf>({
      query: (params) => ({
        url: 'search',
        params,
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res: ITableResponse<IGeneralSearchItem>) => ({
        ...res,
        items: res?.items.map((i) => (i.entityType === 'ticket' ? { ...i, name: 'Ticket' } : i)),
      }),
    }),
  }),
})

export const { useGetGeneralSearchQuery, useLazyGetGeneralSearchQuery } = generalApi
