import React, { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import styles from './Sidebar.module.scss'
import classNames from 'classnames'
import Sider from 'antd/lib/layout/Sider'
import { Divider, Input, Menu, Popover, Tabs } from 'antd'
import * as SVG from 'src/assets/svg'
import { SidebarIcons } from './SidebarIcons'
import { GENERAL_SEARCH_TABS_ENUM, modulePagesArrString, namePagesArrString, pages } from './data'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { IconButton } from '../iconButton/IconButton'
import { clearAuthData } from 'src/shared/api'
import { useGetProfileQuery } from '../../../features/Profile/core/http/Profile'
import {
  addProfileDetails,
  clearProfileSliceState,
  selectProfileState,
} from '../../../features/Profile/core/store/ProfileInfoSlice'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { IUserDetail } from '../../../features/Settings/models/IUser'

import { debounce, isEmpty, unionBy } from 'lodash'

import { useGetGeneralSearchQuery } from 'src/features/app/core/GeneralApi'
import { SearchItems } from './SearchItems'
import { GeneralSearchType, IGeneralSearchItem } from 'src/features/app/models/IGeneralSearch'
import { Button } from '../button/Button'
import { useLocalStorage } from 'src/shared/hooks/useLocalStorage'
import { LOCAL_STORAGE_VARIABLES } from 'src/helpers/contants'
import { useOnClickOutside } from 'src/shared/hooks/useClickOutside'
import { useDispatch } from 'react-redux'
import { resetStateAction } from 'src/redux/actions/resetState'
import { CONTACT_TYPE_ENUM } from 'src/shared/models/Contact'
import { ITableConf } from 'src/shared/models/ITableConf'
import { SUPPORT_ENUM } from '../../../routes/support'
import { clearExternalChatState } from '../../../features/Support/core/store/ExternalChatMessagingSlice'
import { clearExternalChatChatsState } from '../../../features/Support/core/store/ExternalChatChatsListSlice'

// const statuses = [
//   { id: 0, title: 'Available', icon: <SVG.AvailableStatusIcon /> },
//   { id: 1, title: 'Away', icon: <SVG.AwayStatusIcon /> },
//   { id: 2, title: 'Do Not Disturb', icon: <SVG.DoNotDisturbIcon /> },
//   { id: 3, title: 'Offline', icon: <SVG.OfflineIcon /> },
// ]

interface IHeaderSidebar {
  searchVisible: boolean
  searchFocus: boolean
  onSearchVisible: React.Dispatch<React.SetStateAction<boolean>>
  onSearchFocus: React.Dispatch<React.SetStateAction<boolean>>
  onSearch: (search: string) => void
}

const HeaderSideBar = memo(
  ({ searchVisible, searchFocus, onSearchVisible, onSearchFocus, onSearch }: IHeaderSidebar) => {
    const [value, setValue] = useState('')

    useEffect(() => {
      if (!searchVisible) setValue('')
    }, [searchVisible])

    const debounceGetAgent = useCallback(
      debounce((value) => {
        onSearch(value)
      }, 300),
      []
    )

    const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
      debounceGetAgent(event.target.value)
      setValue(event.target.value)
    }

    // const tableActionsPopup = (): IPopupListItems[] => [
    //   {
    //     text: 'AgentsTable',
    //     shouldDisplay: true,
    //     onClick: onSelectItem('agent'),
    //     ...(selectedItems.agent && { suffix: <SVG.TickIcon /> }),
    //   },
    //   {
    //     text: 'Transactions',
    //     shouldDisplay: true,
    //     onClick: onSelectItem('transactions'),
    //     ...(selectedItems.transactions && { suffix: <SVG.TickIcon /> }),
    //   },
    //   {
    //     text: 'Tickets',
    //     shouldDisplay: true,
    //     onClick: onSelectItem('ticket'),
    //     ...(selectedItems.ticket && { suffix: <SVG.TickIcon /> }),
    //   },
    // ]

    return (
      <div className={styles['header-sidebar']}>
        <div className={classNames(styles['header-sidebar-content'])}>
          <Input
            className={classNames(styles['header-sidebar-search'], {
              [styles['header-sidebar-search-active']]: searchFocus,
            })}
            value={value}
            bordered={false}
            placeholder='Search'
            prefix={<SVG.SearchIcon />}
            onClick={() => onSearchVisible(true)}
            onFocus={() => onSearchFocus(true)}
            onBlur={() => onSearchFocus(false)}
            onChange={handleSearchChange}
          />
          {/* {searchVisible && (
            <Popup
              data={tableActionsPopup()} overlayClassName={styles['header-sidebar-popup']} </div> 
            >
              <IconButton
                className={styles['header-setting-button']}
                icon={<SVG.SettingsFilledIcon />}
              />
            </Popup>
          )} */}
        </div>
      </div>
    )
  }
)

interface ISidebarPopover {
  onChangeVisible: () => void
}

const SidebarPopover: React.FC<ISidebarPopover> = ({ onChangeVisible }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleRedirect = (path: string) => () => {
    navigate(path)
    onChangeVisible()
  }

  const handleSignOut = () => {
    clearAuthData()
    navigate('sign-in')
    dispatch(resetStateAction())
    dispatch(clearProfileSliceState())
  }

  return (
    <div className={styles.popupContainer}>
      <div className={styles.headerBtnContainer}>
        <IconButton icon={<SVG.SignOutIcon2 />} onClick={handleSignOut}>
          Sign out
        </IconButton>
      </div>
      <Divider />
      {/* <div className={styles.statusContainer}>
        {statuses.map(({ id, icon, title }) => (
          <div key={id}>
            {icon}
            {title}
          </div>
        ))}
      </div>
      <Divider /> */}
      <div className={styles.actionContainer}>
        <div onClick={handleRedirect('info/profile-info/view')}>
          <div className={styles.svgContainer}>
            <SVG.PersonRoundedIcon />
          </div>
          Profile
        </div>
        <div onClick={handleRedirect('info/profile-settings')}>
          <SVG.SettingsIcon />
          Profile Settings
        </div>
        <div>
          <SVG.HelpRoundedIcon />
          Help
        </div>
      </div>
    </div>
  )
}

interface ISideBarFooterProps {
  userName: string
  userEmail: string
}

const FooterSideBar = memo(({ userName, userEmail }: ISideBarFooterProps) => {
  const [isVisible, setVisible] = useState(false)

  const handleOpenChange = () => setVisible((p) => !p)
  return (
    <div className={styles['footer-sidebar']}>
      <Popover
        destroyTooltipOnHide={true}
        placement='rightBottom'
        content={<SidebarPopover onChangeVisible={handleOpenChange} />}
        trigger='click'
        open={isVisible}
        onOpenChange={handleOpenChange}
      >
        <div className={classNames(styles['footer-sidebar-content'])}>
          <div className={styles.profile}>
            <div className={styles['profile-name']}>{userName}</div>
            <div className={styles['profile-email']}>{userEmail}</div>
          </div>
          <div>
            <SVG.SidebarArrowIcon />
          </div>
        </div>
      </Popover>
    </div>
  )
})

interface ISidebar {
  collapsed: boolean
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  isSearchVis: boolean
  setSearchVis: React.Dispatch<React.SetStateAction<boolean>>
}

const initialSearchConf = {
  page: 1,
  limit: 20,
  search: '',
}

const initialSelectItems = {
  agent: false,
  transactions: false,
  ticket: false,
}

export const Sidebar = ({ collapsed, setCollapsed, isSearchVis, setSearchVis }: ISidebar) => {
  const sidebarRef = useRef(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const isTabletL = useMediaQuery({ maxWidth: 1024 })
  const searchRef = useRef<string>()

  const defaultUrlPage = 'sales-network/agent-management'

  const [isChangeSearch, setIsChangeSearch] = useState(false)

  const [openKeys, setOpenKeys] = useState('sales')
  const [selectedmenu, setSelectedmenu] = useState('')
  const [isSearchFocus, setSearchFocus] = useState(false)
  const [activeTab, setActiveTab] = useState<string>(GENERAL_SEARCH_TABS_ENUM.ALL)
  // const [selectedItems, setSelectedItems] = useState(initialSelectItems)

  const [searchConf, setSearchConf] = useState<ITableConf>(initialSearchConf)
  const [searchItemsAll, setSearchItemsAll] = useState<IGeneralSearchItem[]>([])
  const [searchItemsAgents, setSearchItemsAgents] = useState<IGeneralSearchItem[]>([])
  const [searchItemsTransactions, setSearchItemsTransactions] = useState<IGeneralSearchItem[]>([])
  const [searchItemsTickets, setSearchItemsTickets] = useState<IGeneralSearchItem[]>([])

  const [recentData, setRecentData] = useLocalStorage<IGeneralSearchItem[]>(
    LOCAL_STORAGE_VARIABLES.recentCachedData,
    []
  )

  const { data: searchAll, isFetching } = useGetGeneralSearchQuery(searchConf, {
    skip: !searchConf.search || activeTab !== GENERAL_SEARCH_TABS_ENUM.ALL,
    // || searchRef.current === searchConf.search,
  })

  const { data: searchAg, isFetching: isFAg } = useGetGeneralSearchQuery(
    {
      ...searchConf,
      entityType: 'agent',
    },
    {
      skip: !searchConf.search || activeTab !== GENERAL_SEARCH_TABS_ENUM.AGENTS,
      //  || (activeTab === GENERAL_SEARCH_TABS_ENUM.AGENTS && !isEmpty(searchItemsAgents)),
      // || (!isEmpty(searchItemsAgents) && searchConf.search === searchRef.current),
    }
  )

  const { data: searchTr, isFetching: isFTr } = useGetGeneralSearchQuery(
    {
      ...searchConf,
      entityType: 'transactions',
    },
    {
      skip: !searchConf.search || activeTab !== GENERAL_SEARCH_TABS_ENUM.TRANSACTIONS,
      //  || searchRef.current !== searchConf.search,
    }
  )

  const { data: searchTi, isFetching: isFTi } = useGetGeneralSearchQuery(
    {
      ...searchConf,
      entityType: 'ticket',
    },
    {
      skip: !searchConf.search || activeTab !== GENERAL_SEARCH_TABS_ENUM.TICKETS,
      // || searchRef.current !== searchConf.search,
    }
  )

  useEffect(() => {
    if (isEmpty(searchAll)) return

    if (isChangeSearch && searchConf.search) {
      setSearchItemsAll(searchAll?.items)
    } else {
      if (isChangeSearch && !searchConf.search) {
        setSearchItemsAll(searchAll?.items)
        setIsChangeSearch(false)
      } else {
        setSearchItemsAll((p) => unionBy([...p, ...searchAll?.items], 'id'))
      }
    }
  }, [searchAll])

  useEffect(() => {
    if (isEmpty(searchAg)) return

    if (isChangeSearch && searchConf.search) {
      setSearchItemsAgents(searchAg?.items)
    } else {
      if (isChangeSearch && !searchConf.search) {
        setSearchItemsAgents(searchAg?.items)
        setIsChangeSearch(false)
      } else {
        setSearchItemsAgents((p) => unionBy([...p, ...searchAg?.items], 'id'))
      }
    }
  }, [searchAg])

  useEffect(() => {
    if (isEmpty(searchTr)) return

    if (isChangeSearch && searchConf.search) {
      setSearchItemsTransactions(searchTr?.items)
    } else {
      if (isChangeSearch && !searchConf.search) {
        setSearchItemsTransactions(searchTr?.items)
        setIsChangeSearch(false)
      } else {
        setSearchItemsTransactions((p) => unionBy([...p, ...searchTr?.items], 'id'))
      }
    }
  }, [searchTr])

  useEffect(() => {
    if (isEmpty(searchTi)) return

    if (isChangeSearch && searchConf.search) {
      setSearchItemsTickets(searchTi?.items)
    } else {
      if (isChangeSearch && !searchConf.search) {
        setSearchItemsTickets(searchTi?.items)
        setIsChangeSearch(false)
      } else {
        setSearchItemsTickets((p) => unionBy([...p, ...searchTi?.items], 'id'))
      }
    }
  }, [searchTi])

  const { data: profile } = useGetProfileQuery()

  const { user } = useAppSelector(selectProfileState)

  useEffect(() => {
    dispatch(addProfileDetails(profile as IUserDetail))
  }, [dispatch, profile])

  const resetSearch = () => {
    setSearchVis(false)
    setSearchFocus(false)
    setSearchConf(initialSearchConf)
    // setSelectedItems(initialSelectItems)
    setSearchItemsAll([])
    setSearchItemsAgents([])
    setSearchItemsTransactions([])
    setSearchItemsTickets([])
    setActiveTab(GENERAL_SEARCH_TABS_ENUM.ALL)
  }

  useOnClickOutside(sidebarRef, () => {
    resetSearch()
  })

  useEffect(() => {
    modulePagesArrString.forEach((page) => {
      if (pathname.includes(page)) {
        setOpenKeys(page)
      }
    })
    if (pathname === '/') {
      setSelectedmenu(defaultUrlPage)
      navigate(defaultUrlPage)
    } else {
      namePagesArrString.forEach((page) => {
        if (pathname.includes(page)) {
          setSelectedmenu(page)
        }
      })
      navigate(`${pathname}${search}`)
    }
  }, [navigate, pathname])

  useLayoutEffect(() => {
    setCollapsed(isTabletL)
  }, [pathname, isTabletL, setCollapsed])

  useEffect(() => {
    if (pathname !== SUPPORT_ENUM.SUPPORT_CHAT_ROOM) {
      dispatch(clearExternalChatState())
      dispatch(clearExternalChatChatsState())
    }
  }, [dispatch, pathname])

  const handleSearch = (value: string) => {
    const accureValue = value.trim()

    if (!accureValue) {
      setSearchItemsAll([])
      setSearchItemsAgents([])
      setSearchItemsTransactions([])
      setSearchItemsTickets([])
      // setSelectedItems(initialSelectItems)
    }

    setIsChangeSearch(true)
    setSearchConf((prev) => ({ ...prev, page: 1, search: accureValue }))
    searchRef.current = accureValue
  }

  // const handleClickAction = (name: keyof typeof selectedItems) => () => {
  //   setSelectedItems((p) => ({ ...p, [name]: !p[name] }))
  // }

  const handleResetClick = () => {
    setRecentData([])
  }

  const loadMoreData = () => {
    setIsChangeSearch(false)
    setSearchConf((pre: ITableConf) => ({ ...pre, page: pre.page! + 1 }))
  }

  const handleInstanceClick = (id: string, type: GeneralSearchType, name: string) => {
    const isExist = (recentData as IGeneralSearchItem[]).find((i) => i?.id === id)
    setSearchVis(false)
    setSearchConf(initialSearchConf)
    setSearchItemsAll([])
    setSearchItemsAgents([])
    setSearchItemsTransactions([])
    setSearchItemsTickets([])

    if (isExist) return
    setRecentData([...recentData, { id, type, name }])
  }

  const items = [
    {
      key: GENERAL_SEARCH_TABS_ENUM.ALL,
      label: `${GENERAL_SEARCH_TABS_ENUM.ALL} (${
        searchConf.search && searchAll?.totalCount ? searchAll?.totalCount : 0
      })`,
      children: (
        <SearchItems
          loading={isFetching}
          dataLength={searchItemsAll.length}
          totalCount={searchAll?.totalCount as number}
          data={!isEmpty(recentData) && isEmpty(searchItemsAll) ? recentData : searchItemsAll}
          onResetSearch={resetSearch}
          loadMoreData={loadMoreData}
          onClick={handleInstanceClick}
        />
      ),
    },
    {
      key: GENERAL_SEARCH_TABS_ENUM.AGENTS,
      label: `${GENERAL_SEARCH_TABS_ENUM.AGENTS} (${
        searchConf.search && searchAg?.totalCount ? searchAg?.totalCount : 0
      })`,
      children: (
        <SearchItems
          loading={isFAg}
          dataLength={searchItemsAgents.length}
          totalCount={searchAg?.totalCount as number}
          data={searchItemsAgents}
          onResetSearch={resetSearch}
          loadMoreData={loadMoreData}
          onClick={handleInstanceClick}
        />
      ),
    },
    {
      key: GENERAL_SEARCH_TABS_ENUM.TRANSACTIONS,
      label: `${GENERAL_SEARCH_TABS_ENUM.TRANSACTIONS} (${
        searchConf.search && searchTr?.totalCount ? searchTr?.totalCount : 0
      })`,
      children: (
        <SearchItems
          loading={isFTr}
          dataLength={searchItemsTransactions.length}
          totalCount={searchTr?.totalCount as number}
          data={searchItemsTransactions}
          onResetSearch={resetSearch}
          loadMoreData={loadMoreData}
          onClick={handleInstanceClick}
        />
      ),
    },
    {
      key: GENERAL_SEARCH_TABS_ENUM.TICKETS,
      label: `${GENERAL_SEARCH_TABS_ENUM.TICKETS} (${
        searchConf.search && searchTi?.totalCount ? searchTi?.totalCount : 0
      })`,
      children: (
        <SearchItems
          loading={isFTi}
          dataLength={searchItemsTickets.length}
          totalCount={searchTi?.totalCount as number}
          data={searchItemsTickets}
          onResetSearch={resetSearch}
          loadMoreData={loadMoreData}
          onClick={handleInstanceClick}
        />
      ),
    },
  ]

  const onTabChange = (activeKey: string) => {
    setActiveTab(activeKey)
    setSearchConf((p) => ({ ...p, page: 1 }))
  }

  const userName = `${profile?.firstName || ''} ${profile?.lastName || ''}`
  const userEmail =
    profile?.contactChannels?.find((i) => i.type === CONTACT_TYPE_ENUM.EMAIL_IDENTITY)?.value || ''

  return (
    <>
      <div className={styles['wrapper-sidebar']}>
        <SidebarIcons
          userAvatar={user?.logo}
          openKeys={openKeys}
          setOpenKeys={setOpenKeys}
          setCollapsed={setCollapsed}
        />

        <div className={styles['menu-selected-by-icon']} ref={sidebarRef}>
          <Sider
            className={styles.sidebar}
            trigger={null}
            collapsible={!isTabletL}
            collapsed={collapsed}
            collapsedWidth={0}
            width={isSearchVis ? 480 : 216}
            // width={480}
          >
            <div className={styles['wrraper-menu']}>
              <div className={styles['wrraper-menu']}>
                <HeaderSideBar
                  searchVisible={isSearchVis}
                  searchFocus={isSearchFocus}
                  onSearchVisible={setSearchVis}
                  onSearchFocus={setSearchFocus}
                  onSearch={handleSearch}
                  // selectedItems={selectedItems}
                  // onSelectItem={handleClickAction}
                />
                {isSearchVis ? (
                  <div
                    className={classNames(styles['wrapper-search-container'], {
                      [styles['wrapper-search-container-recent']]:
                        !isEmpty(recentData) && isEmpty(searchItemsAll),
                    })}
                  >
                    <div className={styles['wrapper-tabs-header']}>
                      {!isEmpty(recentData) &&
                        isEmpty(searchItemsAll) &&
                        String(activeTab) === GENERAL_SEARCH_TABS_ENUM.ALL && (
                          <React.Fragment>
                            <div className={styles['wrapper-tabs-recent']}>Recent</div>
                            <Button
                              type='default'
                              size='middle'
                              color='blue'
                              onClick={handleResetClick}
                            >
                              Reset
                            </Button>
                          </React.Fragment>
                        )}
                    </div>
                    <Tabs items={items} onChange={onTabChange} destroyInactiveTabPane />
                  </div>
                ) : (
                  <React.Fragment>
                    <Menu
                      className={styles.menu}
                      mode='vertical'
                      selectedKeys={[selectedmenu]}
                      items={pages[openKeys as keyof typeof pages]}
                      onClick={({ key }) => {
                        if (key !== selectedmenu) {
                          localStorage.removeItem('activeTabs')
                        }

                        if (location?.pathname !== `/${key}`) {
                          setSelectedmenu(key)
                          return navigate(key)
                        }
                      }}
                    />
                    <FooterSideBar userName={userName} userEmail={userEmail} />
                  </React.Fragment>
                )}
              </div>
            </div>
          </Sider>
        </div>
      </div>
    </>
  )
}
