import React from 'react'
import { Select } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { TimePicker } from 'src/shared/components/datePicker'
import { Button } from 'src/shared/components/button/Button'
import styles from './styles.module.scss'

const frequencyOptions = [
  {
    label: 'Monthly Schedule',
    value: 1,
  },
]

const onOptions = [
  {
    label: 'Last day',
    value: 1,
  },
]

const expiryOptions = [
  {
    label: 'Never',
    value: 1,
  },
]

const typeTypeOptions = [
  {
    label: 'AM',
    value: 1,
  },
  {
    label: 'PM',
    value: 2,
  },
]

const RestrictionProfilingScheduling = () => {
  return (
    <Wrapper title='Profiling Scheduling'>
      <FormItem name='scheduling' label='Frequency'>
        <Select
          allowClear
          options={frequencyOptions}
          placeholder='Profiling Scheduling'
          defaultValue={1}
        />
      </FormItem>
      <FormItem name='on' label='On'>
        <Select allowClear options={onOptions} placeholder='On' defaultValue={1} />
      </FormItem>
      <div className={styles.dblRow}>
        <FormItem name='endAt' label='Time'>
          <TimePicker placeholder='' />
        </FormItem>
        <FormItem name='timeType' label=''>
          <Select allowClear options={typeTypeOptions} defaultValue={1} />
        </FormItem>
      </div>
      <FormItem name='Expiry' label='Expiry'>
        <Select allowClear options={expiryOptions} placeholder='Expiry' />
      </FormItem>
      <Button color='blue' size='middle' type='primary' className={styles.saveButton}>
        Save
      </Button>
    </Wrapper>
  )
}

export default RestrictionProfilingScheduling
