import React, { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Spin } from 'antd'

import FooterActions from '../../../../FooterActions'
import DetailInfo from '../../../../DetailInfo'

import { SETTINGS_ENUM } from '../../../../../../../../../routes/settings'
import {
  useGetDepartmentByIdQuery,
  usePatchDepartmentMutation,
} from '../../../../../../../core/http/DepartmentsManagementApi'
import { IDepartment } from '../../../../../../../models/IDepartmentsManagement'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../../../shared/api/errorHandler'

import styles from './styles.module.scss'
import { formatDateWithTime } from '../../../../../../../../../helpers/utils'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

const DepartmentInfo = ({ canEdit }: { canEdit: boolean }) => {
  const navigate = useNavigate()

  const { mode, id } = useParams<{ mode: 'view' | 'edit'; id: string }>()

  const isEditMode = useMemo(() => mode === 'edit', [mode])
  const isViewMode = useMemo(() => mode === 'view', [mode])

  const [form] = Form.useForm()

  const [updateDepartment, upDepartmentResp] = usePatchDepartmentMutation()
  const { data, isLoading, isError, error } = useGetDepartmentByIdQuery(id as string, { skip: !id })
  const { data: profile } = useGetProfileQuery()

  const afterUpdate = () => {
    navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT_DEPARTMENTS}/view/${id}`)
  }

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, upDepartmentResp.isSuccess, null, afterUpdate)
  useNotification(
    NOTIFICATION_TYPES.error,
    upDepartmentResp.isError,
    upDepartmentResp.error as ErrorNode
  )
  useNotification(NOTIFICATION_TYPES.error, isError, error as ErrorNode)

  const onFinish = () => {
    const formValues = form.getFieldsValue() as IDepartment
    const body = {
      ...formValues,
      hasAccessToExternalChat: !!formValues?.hasAccessToExternalChat,
      isGeneralExternalChatSupport: !!formValues?.isGeneralExternalChatSupport,
    }

    updateDepartment({ id, body })
  }

  const setDefaultFields = useCallback(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        createdAt: formatDateWithTime(data?.createdAt, profile?.calendar),
      })
    }
  }, [data, form, profile?.calendar])

  const onPressSaveChanges = useCallback(() => {
    if (isViewMode) {
      return navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT_DEPARTMENTS}/edit/${id}`)
    }

    form.submit()
  }, [form, id, isViewMode, navigate])

  const onPressCancelChanges = useCallback(() => {
    if (isEditMode) {
      form.resetFields()
      setDefaultFields()
      return navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT_DEPARTMENTS}/view/${id}`)
    }

    return navigate(SETTINGS_ENUM.USERS_MANAGEMENT)
    // eslint-disable-next-line
  }, [form, id, navigate, setDefaultFields])

  useEffect(() => {
    setDefaultFields()
  }, [setDefaultFields])

  const content = (
    <>
      <div className={styles.layout}>
        <Form form={form} onFinish={onFinish} layout='vertical'>
          <DetailInfo canEdit={canEdit} isEditMode={isEditMode} />
          <FooterActions
            onPressCancelChanges={onPressCancelChanges}
            onPressSaveChanges={onPressSaveChanges}
            isLoading={isLoading || upDepartmentResp.isLoading}
            submitButtonText={isViewMode ? 'Edit' : 'Save Changes'}
          />
        </Form>
      </div>
    </>
  )

  return isLoading || upDepartmentResp.isLoading ? <Spin>{content}</Spin> : content
}

export default DepartmentInfo
