import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { IEpinTable, IScratchCardInventory } from '../../models/IEPin'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { IPurchaseOrderScratchCard } from '../../models/IPurchaseOrder'

export const epinApi = createApi({
  reducerPath: 'epinApi',
  baseQuery: baseQueryWithReauth,
  refetchOnFocus: true,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getScratchCardList: builder.query<ITableResponse<IScratchCardInventory>, ITableConf>({
      query: (params) => ({
        url: `scratch-card`,
        params,
      }),
    }),

    getPOScratchCardList: builder.query<Array<IPurchaseOrderScratchCard>, string>({
      query: (id) => ({
        url: `purchase-order/${id}/scratch-cards`,
      }),
    }),

    getEPinList: builder.query<ITableResponse<IEpinTable>, ITableConf>({
      query: (params) => ({
        url: `vendors/inventories-e-pin`,
        params,
      }),
      transformResponse: (res: ITableResponse<IEpinTable>) => ({
        ...res,
        items: res.items.map((trans) =>
          trans.scratchCardSummary?.length
            ? {
                ...trans,
                children: trans?.scratchCardSummary?.map((sub) => ({
                  ...sub,
                  mainTransactionId: trans.id,
                  id: `${trans.id}-${sub.available + sub.used + sub.denomination}-sub`,
                })),
              }
            : trans
        ),
      }),
    }),
  }),
})

export const { useGetEPinListQuery, useGetScratchCardListQuery, useGetPOScratchCardListQuery } =
  epinApi
