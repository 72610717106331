import { memo, useCallback } from 'react'
import { FormInstance } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'

import FormItem from 'antd/es/form/FormItem'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { SimpleSelect } from '../../../../../../shared/components/simpleSelect/select'

import { formatTimezones, formatLanguages, formatCalendars } from '../../../../../../shared/data'

import styles from '../styles.module.scss'

export const GeneralBlock = memo(({ form }: { form: FormInstance }) => {
  const onSelectCalendar = useCallback(
    (data: DefaultOptionType | undefined) => {
      form.setFieldValue('calendar', data?.value || null)
    },
    [form]
  )

  const onSelectLang = useCallback(
    (data: DefaultOptionType | undefined) => {
      form.setFieldValue('systemLanguage', data?.value || null)
    },
    [form]
  )

  const onSelectTimeZone = useCallback(
    (data: DefaultOptionType | undefined) => {
      form.setFieldValue('timeZone', data?.value || null)
    },
    [form]
  )

  return (
    <Wrapper title='General' className={styles.layout}>
      <div className={styles.dblRow}>
        <FormItem name='calendar' label='Calendar'>
          <SimpleSelect
            items={formatCalendars}
            onChange={onSelectCalendar}
            allowClear
            valueBy='name'
          />
        </FormItem>
        <FormItem name='systemLanguage' label='System Language'>
          <SimpleSelect
            disabled
            items={formatLanguages}
            onChange={onSelectLang}
            allowClear
            valueBy='name'
          />
        </FormItem>
      </div>

      <div className={styles.row}>
        <FormItem name='timeZone' label='Time Zone'>
          <SimpleSelect
            items={formatTimezones}
            onChange={onSelectTimeZone}
            allowClear
            valueBy='value'
          />
        </FormItem>
      </div>
    </Wrapper>
  )
})
