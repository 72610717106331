import { Reducer } from 'redux'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { authApi } from 'src/features/authorization/core/http/AuthorizationApi'
import { profileApi } from 'src/features/Profile/core/http/Profile'
import { agentManagementApi } from 'src/features/salesNetwork/core/http/AgentManagement'
import { purchaseOrderApi } from 'src/features/Finance/core/http/PurchaseOrderApi'
import { exchangeRatesApi } from 'src/features/Finance/core/http/ExchangeRatesApi'
import { departmentsManagementApi } from 'src/features/Settings/core/http/DepartmentsManagementApi'
import { usersApi } from 'src/features/Settings/core/http/UsersManagementApi'
import { rolesManagementApi } from 'src/features/Settings/core/http/RolesAndPermissionsManagement'
import { fileManagementApi } from 'src/features/Settings/core/http/FileManagementApi'
import { bankAccountApi } from 'src/features/Finance/core/http/BankAccountApi'
import { vendorManagementApi } from 'src/features/Settings/core/http/VendorManagementApi'
import { walletApi } from 'src/features/Finance/core/http/WalletApi'
import { twoFactorAuthApi } from 'src/features/authorization/core/http/TwoFactorAuthApi'
import { ticketSystemApi } from 'src/features/Support/core/http/TicketSystemApi'
import { customerRestrictionManagementApi } from 'src/features/Support/core/http/CutomerRestrictionApi'
import { agentsRestrictionManagementApi } from 'src/features/Support/core/http/AgentsRestriction'
import { generalApi } from 'src/features/app/core/GeneralApi'
import { bankWalletTransactionsApi } from '../features/Finance/core/http/BankWalletTransactionsApi'
import { statementOfWalletsApi } from '../features/Finance/core/http/StatementOfWalletsApi'
import { reverseTransactionApi } from '../features/Finance/core/http/ReverseTransactionApi'
import { positionsApi } from '../features/Settings/core/http/PositionsApi'
import { externalTransactionApi } from '../features/Reports/core/ExternalTransactionApi'
import { systemConfigurationApi } from '../features/Settings/core/http/SystemConfigurationApi'
import { promotionPolicyApi } from '../features/Settings/core/http/PromotionPolicyApi'
import { discountPolicyApi } from '../features/Settings/core/http/DiscountPolicyApi'
import { internalChatsApi } from 'src/features/InternalChat/core/http/InternalChatsApi'
import { internalMessagingApi } from 'src/features/InternalChat/core/http/InternalMessagingApi'

import { RESET_STATE_ACTION_TYPE } from './actions/resetState'

import breadcrumbsSlice from 'src/features/app/core/BreadcrumbsSlice'
import profileSlice from 'src/features/Profile/core/store/ProfileInfoSlice'
import notificationsSlice from 'src/features/Profile/core/store/Notifications'
import usersManagementSlice from 'src/features/Settings/core/store/UsersManagerSlice'
import vendorManagementReducer from 'src/features/Settings/core/store/VendorManagementSlice'
import roleAndPermissionsManagementReducer from 'src/features/Settings/core/store/RoleAndPermissionsManagementSlice'
import internalChatChatsSlice from 'src/features/InternalChat/core/store/Chats'
import internalChatMessagesSlice from 'src/features/InternalChat/core/store/Messages'
import { scheduleNotificationApi } from 'src/features/salesNetwork/core/http/SceduleNotifications'
import { targetGroupsApi } from '../features/Settings/core/http/TargetGroupsApi'
import { internalTransactionApi } from '../features/Reports/core/InternalTransactionApi'
import { subTransactionsApi } from '../features/Reports/core/Subtransactions'
import { agentPolicyApi } from '../features/salesNetwork/core/http/AgentPolicyApi'
import { customersApi } from '../features/salesNetwork/core/http/CustomersApi'
import { documentVerificationApi } from 'src/features/salesNetwork/core/http/DocumentVerificationApi'
import { vendorInventoryApi } from '../features/Finance/core/http/VendorInventoryApi'
import { notificationApi } from '../features/Profile/core/http/Notification'
import { responseCodeApi } from '../features/Settings/core/http/ResponseCodeApi'
import { dashboardApi } from '../features/Dashboard/core/DashboardApi'
import { eventsHolidaysApi } from 'src/features/Settings/core/http/EventsHolidaysApi'
import { externalChatApi } from '../features/Support/core/http/ExternalChatApi'
import { externalChatMessagingSlice } from '../features/Support/core/store/ExternalChatMessagingSlice'
import { integrationsApi } from '../features/Settings/core/http/IntegrationsApi'
import { requestToVendorApi } from 'src/features/Support/core/http/RequestToVendor'
import { externalChatChatsListSlice } from '../features/Support/core/store/ExternalChatChatsListSlice'
import { notificationMessageApi } from '../features/Settings/core/http/NotificationMessageApi'
import { rewardPolicyApi } from '../features/Settings/core/http/RewardPolicyApi'
import { segmentsApi } from '../features/salesNetwork/core/http/SegmentsApi'
import { epinApi } from '../features/Finance/core/http/EPinApi'
import { bankApi } from '../features/Finance/core/http/BankApi'
import { groupSimCardInventoriesApi } from '../features/Finance/core/http/GroupSimCardInventories'
import { whatsAppManagerApi } from '../features/Settings/core/http/WhatsAppManagerApi'
// import { refundRequestApi } from 'src/features/Finance/core/RefundRequestApi'

const reducers = {
  [profileApi.reducerPath]: profileApi.reducer,
  [agentManagementApi.reducerPath]: agentManagementApi.reducer,
  [purchaseOrderApi.reducerPath]: purchaseOrderApi.reducer,
  [bankAccountApi.reducerPath]: bankAccountApi.reducer,
  [exchangeRatesApi.reducerPath]: exchangeRatesApi.reducer,
  [departmentsManagementApi.reducerPath]: departmentsManagementApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [rolesManagementApi.reducerPath]: rolesManagementApi.reducer,
  [fileManagementApi.reducerPath]: fileManagementApi.reducer,
  [vendorManagementApi.reducerPath]: vendorManagementApi.reducer,
  [bankAccountApi.reducerPath]: bankAccountApi.reducer,
  [walletApi.reducerPath]: walletApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [twoFactorAuthApi.reducerPath]: twoFactorAuthApi.reducer,
  [ticketSystemApi.reducerPath]: ticketSystemApi.reducer,
  [customerRestrictionManagementApi.reducerPath]: customerRestrictionManagementApi.reducer,
  [agentsRestrictionManagementApi.reducerPath]: agentsRestrictionManagementApi.reducer,
  // [refundRequestApi.reducerPath]: refundRequestApi.reducer,
  [generalApi.reducerPath]: generalApi.reducer,
  [bankWalletTransactionsApi.reducerPath]: bankWalletTransactionsApi.reducer,
  [statementOfWalletsApi.reducerPath]: statementOfWalletsApi.reducer,
  [reverseTransactionApi.reducerPath]: reverseTransactionApi.reducer,
  [positionsApi.reducerPath]: positionsApi.reducer,
  [externalTransactionApi.reducerPath]: externalTransactionApi.reducer,
  [systemConfigurationApi.reducerPath]: systemConfigurationApi.reducer,
  [promotionPolicyApi.reducerPath]: promotionPolicyApi.reducer,
  [scheduleNotificationApi.reducerPath]: scheduleNotificationApi.reducer,
  [discountPolicyApi.reducerPath]: discountPolicyApi.reducer,
  [internalChatsApi.reducerPath]: internalChatsApi.reducer,
  [internalMessagingApi.reducerPath]: internalMessagingApi.reducer,
  [targetGroupsApi.reducerPath]: targetGroupsApi.reducer,
  [internalTransactionApi.reducerPath]: internalTransactionApi.reducer,
  [subTransactionsApi.reducerPath]: subTransactionsApi.reducer,
  [agentPolicyApi.reducerPath]: agentPolicyApi.reducer,
  [customersApi.reducerPath]: customersApi.reducer,
  [documentVerificationApi.reducerPath]: documentVerificationApi.reducer,
  [vendorInventoryApi.reducerPath]: vendorInventoryApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [responseCodeApi.reducerPath]: responseCodeApi.reducer,
  [eventsHolidaysApi.reducerPath]: eventsHolidaysApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [externalChatApi.reducerPath]: externalChatApi.reducer,
  [integrationsApi.reducerPath]: integrationsApi.reducer,
  [requestToVendorApi.reducerPath]: requestToVendorApi.reducer,
  [notificationMessageApi.reducerPath]: notificationMessageApi.reducer,
  [rewardPolicyApi.reducerPath]: rewardPolicyApi.reducer,
  [segmentsApi.reducerPath]: segmentsApi.reducer,
  [epinApi.reducerPath]: epinApi.reducer,
  [bankApi.reducerPath]: bankApi.reducer,
  [groupSimCardInventoriesApi.reducerPath]: groupSimCardInventoriesApi.reducer,
  [whatsAppManagerApi.reducerPath]: whatsAppManagerApi.reducer,
  vendorManagementSlice: vendorManagementReducer,
  rolesAndPermissionsManagementSlice: roleAndPermissionsManagementReducer,
  internalChatMessagesSlice: internalChatMessagesSlice,
  internalChatChatsSlice: internalChatChatsSlice,
  usersManagementSlice: usersManagementSlice,
  profileSlice: profileSlice,
  notificationsSlice: notificationsSlice,
  breadcrumbsSlice: breadcrumbsSlice,
  externalChatSlice: externalChatMessagingSlice.reducer,
  externalChatChatsListSlice: externalChatChatsListSlice.reducer,
}

const combinedReducer = combineReducers<typeof reducers>(reducers)

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {} as RootState
  }

  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      profileApi.middleware,
      agentManagementApi.middleware,
      purchaseOrderApi.middleware,
      bankAccountApi.middleware,
      exchangeRatesApi.middleware,
      departmentsManagementApi.middleware,
      usersApi.middleware,
      rolesManagementApi.middleware,
      fileManagementApi.middleware,
      vendorManagementApi.middleware,
      bankAccountApi.middleware,
      walletApi.middleware,
      authApi.middleware,
      twoFactorAuthApi.middleware,
      ticketSystemApi.middleware,
      customerRestrictionManagementApi.middleware,
      agentsRestrictionManagementApi.middleware,
      // refundRequestApi.middleware,
      generalApi.middleware,
      bankWalletTransactionsApi.middleware,
      statementOfWalletsApi.middleware,
      reverseTransactionApi.middleware,
      positionsApi.middleware,
      externalTransactionApi.middleware,
      systemConfigurationApi.middleware,
      promotionPolicyApi.middleware,
      scheduleNotificationApi.middleware,
      discountPolicyApi.middleware,
      internalChatsApi.middleware,
      internalMessagingApi.middleware,
      targetGroupsApi.middleware,
      internalTransactionApi.middleware,
      subTransactionsApi.middleware,
      agentPolicyApi.middleware,
      customersApi.middleware,
      documentVerificationApi.middleware,
      vendorInventoryApi.middleware,
      notificationApi.middleware,
      responseCodeApi.middleware,
      eventsHolidaysApi.middleware,
      dashboardApi.middleware,
      externalChatApi.middleware,
      integrationsApi.middleware,
      requestToVendorApi.middleware,
      notificationMessageApi.middleware,
      rewardPolicyApi.middleware,
      segmentsApi.middleware,
      epinApi.middleware,
      bankApi.middleware,
      groupSimCardInventoriesApi.middleware,
      whatsAppManagerApi.middleware
    ),
})

export type RootState = ReturnType<typeof combinedReducer>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
