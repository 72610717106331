import { ILocation } from '../../../shared/models/ILocation'

export enum IDENTIFIER_TYPE_ENUM {
  MOBILE = 'MOBILE',
}

export enum GENDER_TYPE_ENUM {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  GENDERLESS = 'GENDERLESS',
}

interface IReceiverPhone {
  prefix: string
  description: string
}

export interface IIdentifier {
  id: string
  type: IDENTIFIER_TYPE_ENUM
  value: string
  receiverPhoneNumberPrefix: IReceiverPhone
  topupSum: number
  topupCount: number
  topupAvg: number
}

export enum NATIONAL_ID_ENUM {
  PASSPORT = 'Passport',
  NID = 'NID',
  TAZKIRA = 'Tazkira',
  DRIVING_LICENSE = 'Driving License',
}

export enum PROFESSION_ENUM {
  JOBLESS = 'Jobless',
  HOUSEWIFE = 'Housewife',
  RETIRED = 'Retired',
  STUDENT = 'Student',
  ENGINEERING = 'Engineering',
  MEDICAL = 'Medical',
  TEACHER = 'Teacher',
  RELIGIOUS = 'Religious',
  TRANSPORTATION = 'Transportation',
  SHOPKEEPER = 'Shopkeeper',
  CONSTRUCTION_WORKER = 'Construction Worker',
  FARMER = 'Farmer',
  SERVICE = 'Service',
  SARAF_BANKING = 'Saraf & Banking',
  STREET_SELLER = 'Street Seller',
  GOVERNMENT = 'Government',
  MILITARY = 'Military',
  TECHNICAL_FIELDS = 'Technical Fields',
  HUMANITARIAN_SERVICES = 'Humanitarian Services',
  TELECOM = 'Telecom',
  TRAVEL_TOURISM = 'Travel and Tourism',
  OTHERS = 'Others',
}

export enum ETHNIC_GROUP_ENUM {
  HAZARA = 'Hazara',
  PASHTUN = 'Pashtun',
  TAJIK = 'Tajik',
  UZBEK = 'Uzbek',
  QEZILBASH = 'Qezilbash',
  AIMAG = 'Aimaq',
  TURKMEN = 'Turkmen',
  BALOCH = 'Baloch',
  SADAT = 'Sadat',
  PASHAYI = 'Pashayi',
  NURISTANI = 'Nuristani',
  PAMIRI = 'Pamiri',
  KURDS = 'Kurds',
  GUJAR = 'Gujar',
  KYRGYZ = 'Kyrgyz',
  OTHER = 'Other',
}

export enum REGISTRATION_CHANNEL_ENUM {
  BY_PURCHASE = 'By Purchase',
  SELF_USSD = 'Self Registration USSD',
  SELF_WHATSAPP = 'Self Registration WhatsApp',
  SELF_APP = 'Self Registration App',
}

export enum MOBILE_WALLET_PROVIDER_ENUM {
  MNT_MOMO = 'MTN MOMO',
  M_HAWALA = 'mHawala',
  M_PAISA = 'mPaisa',
  MY_MONEY = 'MyMoney',
}

interface IBank {
  bankAccounts: ICustomerBankAccountInfo[]
}

interface IWalletAccount {
  walletAccounts: ICustomerWalletInfo[]
}

export interface ICustomer extends IBank, IWalletAccount {
  id: number
  name: string
  district: ILocation
  province: ILocation
  country: ILocation
  identifiers: IIdentifier[]
  topupSum: number
  topupCount: number
  topupAvg: number
  status: boolean
  closedGroupId: number
  isGroupOwner: boolean
  createdAt: string
  gender: GENDER_TYPE_ENUM
  updatedAt: string
  avatar: string
  birthDate: string
  nationalId: NATIONAL_ID_ENUM
  nationalIdNumber: string
  profession: PROFESSION_ENUM
  ethnicGroup: ETHNIC_GROUP_ENUM
  registrationChannel: REGISTRATION_CHANNEL_ENUM
}

export interface ICustomerBankAccountInfo {
  id: number
  bankId: number
  bankBranchId: number
  agentId: string
  customerId: number
  name: string
  number: string
  currency: string
  active?: boolean
  bankBranch: {
    id: number
    name: string
  }
  bank: {
    id: number
    name: string
    logo: string
  }
}

export interface IClosedGroup {
  id: string
  customers: Array<IClosedGroupCustomer>
}

export interface IClosedGroupCustomer {
  id: number
  fullName: string
  avatar: string
  isGroupOwner: boolean
}

export enum NEW_CLOSED_GROUP {
  NEW = 'NEW',
}

export interface IBankAccountID {
  bankAccountId: number
}

export interface IUpdateCustomerBank {
  toDelete: Array<IBankAccountID>
  toUpdate: Array<ICustomerBankAccountInfo & IBankAccountID>
  toCreate: Array<ICustomerBankAccountInfo>
}

export interface ICustomerWalletInfo {
  id: number
  agentId: number
  customerId: number
  active: boolean
  mobileWalletProvider: string
  mobileWalletNumber: string
}

export interface IWalletID {
  walletAccountId: number
}

export interface IUpdateCustomerWalletAccount {
  toDelete: Array<IWalletID>
  toCreate: Array<Pick<ICustomerWalletInfo, 'mobileWalletNumber' | 'mobileWalletProvider'>>
  toUpdate: Array<
    Pick<ICustomerWalletInfo, 'mobileWalletNumber' | 'mobileWalletProvider'> & IWalletID
  >
}
