import { Input } from 'antd'
import classNames from 'classnames'
import { SearchIcon } from '../../../assets/svg'
import '../toggle/Toggle.module.scss'

interface ITextFieldSearch {
  className?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  value?: string
}

export const TextFieldSearch = ({
  className,
  onChange,
  placeholder = 'Search',
  value,
}: ITextFieldSearch) => {
  return (
    <Input
      onChange={onChange}
      className={classNames('search', className)}
      prefix={<SearchIcon />}
      placeholder={placeholder}
      value={value}
    />
  )
}
