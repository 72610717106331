import styles from './styles.module.scss'
import React, { useEffect } from 'react'
import { Widgets } from './Components/Widgets'
import { InventoryTransactionsTable } from './Components/InventoryTransactionsTable'
import { PurchaseChart } from './Components/PurchaseChart'
import { VendorBlock } from './Components/VendorBlock'
import { TargetPlanBlock } from './Components/TargetPlanBlock'
import { CalendarBlock } from './Components/CalendarBlock'
import { useParams } from 'react-router-dom'
import { useGetInventoryByIdQuery } from '../../../core/http/VendorInventoryApi'
import { Form, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { setBreadcrumb } from '../../../../app/core/BreadcrumbsSlice'
import { VendorTresholdBlock } from './Components/VendorTresholdBlock'
import { useLocalStorageGetByKey } from '../../../../../shared/hooks/useLocalStorageGetByKey'
import { replaceCharactersWithAsterisks } from '../../../../../helpers/utils'

export const InventoryView = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const { vendorId, inventoryId } = useParams()

  const { data, isFetching } = useGetInventoryByIdQuery(inventoryId as string, {
    skip: !inventoryId,
  })

  useEffect(() => {
    if (!data) return
    const name = isPrivateMode
      ? { name: replaceCharactersWithAsterisks(data?.vendor?.name) }
      : data?.vendor
    dispatch(setBreadcrumb(name))
    form.setFieldsValue(data)
  }, [data, dispatch, form, isPrivateMode])

  const content = (
    <div className={styles.layout}>
      <div className={styles.widgetsWrapper}>
        <div className={styles.leftColumn}>
          <Widgets />
          <PurchaseChart vendorId={vendorId as string} />
          <InventoryTransactionsTable />
        </div>
        <Form initialValues={data} form={form} layout='vertical'>
          <div className={styles.rightColumn}>
            <VendorBlock vendor={data?.vendor} />
            <VendorTresholdBlock view={true} isPrivateMode={isPrivateMode} />
            <TargetPlanBlock logs={data?.logs} />
            <CalendarBlock vendorId={vendorId as string} />
          </div>
        </Form>
      </div>
    </div>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
