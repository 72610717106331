import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { moment, replaceCharactersWithAsterisks } from '../../../../../../../helpers/utils'
import { RecursivelyReplaceCharacters } from '../../../../../../../shared/components/privateMode'
import styles from '../../../../../../Dashboard/pages/Overview/styles.module.scss'
import { NotFoundPage } from '../../../../../../NotFoundPage'
import { DashboardTableWrapper } from '../../../../../../Dashboard/pages/Overview/Components/DashboardTableWrapper'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { useLocalStorageGetByKey } from '../../../../../../../shared/hooks/useLocalStorageGetByKey'
import { INVENTORY_CHART_TYPE_ENUM } from '../../../../../models/IInventory'
import { Radio } from 'antd'
import { startCase } from 'lodash'
import { FREQUENCY_ENUM } from '../../../../../../../shared/models/WidgetListEnum'

interface ISimCardChart {
  period: string
  cashOut: number
  po: number
  cashIn: number
  sales: number
}

interface IProps {
  chartType: INVENTORY_CHART_TYPE_ENUM
  setChartType: Dispatch<SetStateAction<INVENTORY_CHART_TYPE_ENUM>>
  data: Array<ISimCardChart>
  frequency: FREQUENCY_ENUM
}

export const InventoryChart = ({ chartType, setChartType, data, frequency }: IProps) => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const mappedData = useMemo(() => {
    const result: Array<{
      name: string
      cashIn: number
      cashOut: number
      po: number
      sales: number
    }> = []
    const hours = Array(25)
      .fill(undefined)
      .map((_, index) => index)

    data?.forEach((entry, index) => {
      const period = moment(entry?.period)
      let name: string

      if (frequency === FREQUENCY_ENUM.HOUR) {
        name = period.hour(hours[index]).format('HH')
      } else if (frequency === FREQUENCY_ENUM.DAY) {
        name = period.format('DD MMM')
      } else {
        name = period.format('MMM')
      }

      const transformedEntry = {
        name,
        cashIn: entry.cashIn,
        cashOut: entry.cashOut,
        po: entry.po,
        sales: entry.sales,
      }

      result.push(transformedEntry)
    })

    return result
  }, [frequency, data])

  const dateOptions = useMemo(
    () =>
      (Object.keys(INVENTORY_CHART_TYPE_ENUM) as Array<keyof typeof INVENTORY_CHART_TYPE_ENUM>).map(
        (method) => ({
          label: startCase(INVENTORY_CHART_TYPE_ENUM[method]),
          value: INVENTORY_CHART_TYPE_ENUM[method],
        })
      ),
    []
  )

  const filters = (
    <Radio.Group value={chartType} onChange={(e) => setChartType(e.target.value)}>
      {dateOptions.map((option) => (
        <Radio.Button key={option.value} value={option.value}>
          {option.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  )

  return (
    <DashboardTableWrapper
      title='Sales Summary'
      infoText='Sales Summary Bar Chart'
      filters={filters}
    >
      {mappedData?.length ? (
        <ResponsiveContainer width='100%' height={300}>
          <BarChart
            width={900}
            height={300}
            data={mappedData}
            margin={{
              top: 30,
              right: 10,
              left: -10,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis
              tickFormatter={(value) =>
                isPrivateMode ? replaceCharactersWithAsterisks(value) : value
              }
            />
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const time = payload[0]?.payload?.name

                  return (
                    <RecursivelyReplaceCharacters>
                      <div className={styles.tooltipSummary}>
                        <div className={styles.tooltipRow}>
                          <div className={styles.tooltipRowValue}>{time}</div>
                        </div>
                        {payload?.map((value, i) => (
                          <div
                            key={i}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gridGap: 10,
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                color: payload[i]?.color,
                              }}
                            >
                              {payload[i]?.name}:
                            </div>
                            <div className={styles.tooltipRowValue}>{payload[i]?.value}</div>
                          </div>
                        ))}
                      </div>
                    </RecursivelyReplaceCharacters>
                  )
                }

                return null
              }}
            />
            {!isPrivateMode && (
              <Legend
                verticalAlign='top'
                align='left'
                wrapperStyle={{ left: 40, top: 10 }}
                iconType='circle'
              />
            )}
            <Bar dataKey='po' fill='#4FC3F7' name='Purchase' />
            <Bar dataKey='sales' fill='#F3722C' name='Sales' />
            <Bar dataKey='cashIn' fill='#81C784' name='Cash In' />
            <Bar dataKey='cashOut' fill='#81b4c7' name='Cash Out' />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <NotFoundPage />
      )}
    </DashboardTableWrapper>
  )
}
