import { ColumnsType } from 'antd/es/table'
import {
  AVAILABLE_HISTORY_CATEGORIES,
  ITrustWalletTransaction,
  ITrustWalletTransactionSub,
} from '../../../../models/ITransaction'
import { Table } from 'antd'
import { DateTableIcon, TableActionIcon } from '../../../../../../assets/svg'
import {
  formatDateWithTime,
  renderDebitCreditAmount,
  tableExpandedIcon,
} from '../../../../../../helpers/utils'
import { formatNumberToLocale } from '../../../../../salesNetwork/helpers'
import styles from '../PurchaseOrderTable/styles.module.scss'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import { TableHeader } from '../TableHeader'
import { IPopupListItems, Popup } from '../../../../../../shared/components/popup/Popup'
import {
  useGetTrustWalletTransactionsFiltersListQuery,
  useGetTrustWalletTransactionsQuery,
  useLazyExportTrustWalletQuery,
} from '../../../../core/http/PurchaseOrderApi'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { renderTableVendor } from './renderTableVendor'
import { useNavigate } from 'react-router-dom'
import { useLazyGetSubTransactionsQuery } from '../../../../../Reports/core/Subtransactions'
import { useOnTransactionViewClick } from '../../../../../../shared/hooks/table/useOnTransactionViewClick'
import { camelCase } from 'lodash'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { useOnTransactionHistoryClick } from '../../../../../../shared/hooks/table/useOnTransactionHistoryClick'
import { useTableExport } from '../../../../../../shared/hooks/table/useTableExport'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IProps {
  canAddNew: boolean
}

export const TransactionsTable = ({ canAddNew }: IProps) => {
  const { data: profile } = useGetProfileQuery()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)
  const [mappedTransactions, setMappedTransactions] = useState<Array<ITrustWalletTransaction>>([])
  const [expandedRowKeys, setExpandedRowKeys] = useState<Array<React.Key>>([])
  const navigate = useNavigate()
  const onTransactionViewClick = useOnTransactionViewClick()
  const onTransactionHistoryClick = useOnTransactionHistoryClick()

  const tableActionsPopup = (row: ITrustWalletTransaction): IPopupListItems[] => [
    {
      text: 'View',
      shouldDisplay: true,
      onClick: () => onTransactionViewClick(row.id, row.transactionCategory, row),
    },
    ...(AVAILABLE_HISTORY_CATEGORIES.includes(row.transactionCategory)
      ? [
          {
            text: 'History',
            shouldDisplay: true,
            onClick: () => onTransactionHistoryClick(row?.id, row?.transactionCategory),
          },
        ]
      : []),
  ]

  const [getSubTrans, getSubTransResp] = useLazyGetSubTransactionsQuery()
  const [exportTable] = useLazyExportTrustWalletQuery()
  const { data, isFetching } = useGetTrustWalletTransactionsQuery(
    {
      ...filterValues,
      ...tableConf,
    },
    { skip: Object.keys(tableConf).length < 4 }
  )
  const { data: filtersList } = useGetTrustWalletTransactionsFiltersListQuery()

  useTableExport({ tableConf, filterValues, promise: exportTable })

  const columns: ColumnsType<ITrustWalletTransaction> = [
    {
      title: '',
      dataIndex: '',
      key: 'expand',
      width: '1%',
      onCell: (record) => {
        return {
          onClick: async () => {
            const mappedRec = { ...record }
            const data = await getSubTrans({
              transactionCategory: record.transactionCategory,
              id: record.id,
            })
            mappedRec.children = data?.data?.items

            setMappedTransactions((prevState) =>
              prevState.map((trans) => {
                if (trans.id === mappedRec.id) {
                  return mappedRec
                }
                return trans
              })
            )
          },
        }
      },
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      width: '20%',
      sorter: true,
      render: (createdAt: ITrustWalletTransaction['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(createdAt, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Transaction ID',
      dataIndex: 'id',
      sorter: true,
      width: '15%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      width: '20%',
      sorter: true,
      render: (vendor: ITrustWalletTransaction['vendor'], row) =>
        renderTableVendor(vendor, row, isPrivateMode),
    },
    {
      title: 'Transaction Category',
      dataIndex: 'transactionCategory',
      width: '15%',
      sorter: true,
      render: (transactionCategory) => (
        <RecursivelyReplaceCharacters>{transactionCategory}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Balance Before',
      dataIndex: 'balanceBefore',
      width: '10%',
      sorter: true,
      render: (balance: ITrustWalletTransaction['balanceBefore']) => (
        <RecursivelyReplaceCharacters>{formatNumberToLocale(balance)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'currencyAmount',
      width: '10%',
      sorter: true,
      render: (
        amount: ITrustWalletTransaction['currencyAmount'],
        row: ITrustWalletTransaction | ITrustWalletTransactionSub
      ) => renderDebitCreditAmount(amount, row?.drCr),
    },
    {
      title: 'Emoney',
      dataIndex: 'amount',
      width: '10%',
      sorter: true,
      render: (
        amount: ITrustWalletTransaction['amount'],
        row: ITrustWalletTransaction | ITrustWalletTransactionSub
      ) => renderDebitCreditAmount(amount, row?.drCr),
    },
    {
      title: 'Balance After',
      dataIndex: 'balanceAfter',
      width: '10%',
      sorter: true,
      render: (balance: ITrustWalletTransaction['balanceAfter']) => (
        <RecursivelyReplaceCharacters>{formatNumberToLocale(balance)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: '10%',
      render: (status: ITrustWalletTransaction['status']) => (
        <RecursivelyReplaceCharacters>
          <div className={classNames('statusContainer', `${status.toLowerCase()}-container`)}>
            <div className={classNames(camelCase(status.toLowerCase()), 'statusPoint')} />
            <div>{status}</div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '10%',
      render: (_, row) => {
        return (
          <Popup data={tableActionsPopup(row)}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        )
      },
    },
  ]

  const emptyTableConf = useEmptyTable(canAddNew ? () => navigate('purchase-order/new') : undefined)
  const { pagination, handleTableChange, handleFiltersChange } = useTable<ITrustWalletTransaction>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
    setExpandedRowKeys,
  })

  useEffect(() => {
    if (data) {
      setMappedTransactions(data?.items)
    }
  }, [data])

  const isLoading = isFetching || getSubTransResp.isFetching

  return (
    <>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filtersList}
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        onChange={handleTableChange}
        pagination={pagination}
        dataSource={mappedTransactions}
        loading={isLoading}
        rowKey={(record) => record.id}
        className={styles.tableContainer}
        rowSelection={{ type: 'checkbox' }}
        scroll={{ x: 1250 }}
        expandable={{
          expandedRowKeys,
          expandIcon: (props) => {
            if (
              Object.hasOwn(props.record, 'mainTransactionId') ||
              !props.record?.countSubTransaction
            )
              return null
            return tableExpandedIcon<ITrustWalletTransaction>(props)
          },
          onExpandedRowsChange: (expandedRows) => {
            setExpandedRowKeys([...expandedRows])
          },
        }}
      />
    </>
  )
}
