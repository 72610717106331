import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import {
  EXTERNAL_CHAT_LIST_TABS,
  IExternalChat,
  IExternalChatMessageFile,
  IExternalChatTable,
  IExternalUnreadMessage,
  ILastExternalChatMessage,
  IUpdateExternalChatMember,
  IUpdateExternalChatMemberResp,
} from '../../models/IExternalChat'
import { ITableConf } from '../../../../shared/models/ITableConf'

export const externalChatApi = createApi({
  reducerPath: 'externalChatApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'IExternalChatList',
    'IExternalMessage',
    'IExternalChatById',
    'MessagesList',
    'UnreadMessages',
  ],
  endpoints: (builder) => ({
    getExternalChatsList: builder.query<
      ITableResponse<IExternalChatTable>,
      { type: EXTERNAL_CHAT_LIST_TABS; params: ITableConf }
    >({
      query: ({ type, params }) => ({
        url: `external-chats/types/${type}`,
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: () => ['IExternalChatList'],
    }),

    getExternalChatById: builder.query<IExternalChat, number>({
      query: (id) => ({
        url: `external-chats/${id}`,
      }),
      providesTags: (data) => [{ type: 'IExternalChatById', id: data?.id }],
      keepUnusedDataFor: 0,
    }),

    getExternalChatFileList: builder.query<ITableResponse<IExternalChatMessageFile>, ITableConf>({
      query: ({ id, ...params }) => ({
        url: `external-chat-message-files/get-files/${id}`,
        params,
      }),
    }),

    getExternalChatLinkToDownloadFile: builder.query<{ link: string }, number>({
      query: (id: number) => ({
        url: `external-chat-message-files/get-link/${id}`,
      }),
    }),

    getExternalChatMessagesById: builder.query<
      ITableResponse<ILastExternalChatMessage>,
      ITableConf
    >({
      query: ({ id, ...params }) => ({
        url: `external-chat-messages/${id}`,
        params,
      }),
      providesTags: () => ['MessagesList'],
      keepUnusedDataFor: 0,
    }),

    updateChatMember: builder.mutation<
      IUpdateExternalChatMemberResp,
      Partial<IUpdateExternalChatMember>
    >({
      query: (body) => ({
        url: 'external-chat-members',
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result) => [{ type: 'IExternalChatById', id: result?.chatId }],
    }),

    archiveExternalChat: builder.mutation<
      IExternalChat,
      { id: number; body: { isArchived: boolean } }
    >({
      query: ({ id, body }) => ({
        url: `external-chats/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (data) => [{ type: 'IExternalChatById', id: data?.id }],
    }),

    createExternalChatMessage: builder.mutation<ILastExternalChatMessage, FormData>({
      query: (body) => ({
        url: 'external-chat-messages',
        method: 'POST',
        body,
      }),
    }),

    removeChatMemberById: builder.mutation<void, { chatId: number; userId: number }>({
      query: ({ chatId, userId }) => ({
        url: `external-chat-members/${chatId}/${userId}`,
        method: 'DELETE',
      }),
    }),

    getUnreadExternalMessagesList: builder.query<
      ITableResponse<IExternalUnreadMessage>,
      ITableConf
    >({
      query: (params) => ({
        url: 'external-chat-messages/unread',
        params,
      }),
      providesTags: ['UnreadMessages'],
      keepUnusedDataFor: 0,
    }),
  }),
})

export const {
  useGetExternalChatsListQuery,
  useUpdateChatMemberMutation,
  useGetExternalChatByIdQuery,
  useLazyGetExternalChatByIdQuery,
  useCreateExternalChatMessageMutation,
  useArchiveExternalChatMutation,
  useLazyGetExternalChatMessagesByIdQuery,
  useGetExternalChatFileListQuery,
  useRemoveChatMemberByIdMutation,
  useGetUnreadExternalMessagesListQuery,
  useLazyGetUnreadExternalMessagesListQuery,
} = externalChatApi
