import React, { useEffect } from 'react'
import { Row, Spin, Form } from 'antd'
import classNames from 'classnames'

import { Button } from 'src/shared/components/button/Button'
import { BackupBlock } from './Components/BackupBlock'
import { DelegationBlock } from './Components/DelegationBlock'
import { GeneralBlock } from './Components/GeneralBlock'
import { TwoFactorBlock } from './Components/TwoFactorBlock'

import { ErrorNode } from 'src/shared/api/errorHandler'

import usePermissions from '../../../../shared/hooks/usePermissions'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from 'src/features/Profile/core/http/Profile'
import { useGetBackupCodesQuery } from 'src/features/authorization/core/http/TwoFactorAuthApi'
import { IProfileSettingsPutRequest } from '../../models/IProfile'
import { IPermissionsActions, IPermissionsNames } from '../../../Settings/models/IPermissions'

import styles from './styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { IUserDetail } from 'src/features/Settings/models/IUser'

export type TwoFAType = 'sms' | 'Google Authenticator' | 'disabled'

export const ProfileSettings = () => {
  const [form] = Form.useForm()

  const { data: profile, isFetching } = useGetProfileQuery()
  const { data: backupCodes, isFetching: isBF } = useGetBackupCodesQuery(null, {
    skip: !profile?.twoFactorAuthenticator || profile?.twoFactorAuthenticator === 'disabled',
  })
  const [updateProfile, updateResp] = useUpdateProfileMutation()

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const demandPermissions = [
    IPermissionsActions.VIEW,
    IPermissionsActions.EDIT,
    IPermissionsActions.ADD,
  ]
  const [auth2FAId] = getModulesIdsByNames([IPermissionsNames['2AF Authentication']])
  const [canView2FA, canEdit2FA, canAdd2FA] = canPerformAction(auth2FAId, demandPermissions)

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, updateResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, updateResp.isError, updateResp.error as ErrorNode)

  const handleSetInitialForm = (profile: IUserDetail) => {
    form.setFieldsValue({
      ...profile,
      ...(profile.twoFactorAuthenticator === 'disabled' && { twoFactorAuthenticator: null }),
      systemLanguage: profile?.systemLanguage || profile.nativeLanguage?.name,
    })
  }

  useEffect(() => {
    if (!profile) return
    handleSetInitialForm(profile)
    // eslint-disable-next-line
  }, [profile])

  const handleEditProfile = (fd: FormData) => {
    updateProfile(fd)
  }

  const onFinish = (values: IProfileSettingsPutRequest) => {
    const { twoFactorAuthenticator, ...rest } = values

    const fd = new FormData()

    fd.append('user', JSON.stringify(rest))
    handleEditProfile(fd)
  }

  const content = (
    <main className={styles.layout}>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          calendar: 0,
          systemLanguage: 'English',
          timeZone: 0,
        }}
      >
        <GeneralBlock form={form} />
        <DelegationBlock />
        {/* {canView2FA && canAdd2FA && ( */}
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const twoFA = getFieldValue('twoFactorAuthenticator')

            return (
              <div
                className={classNames(styles.twoFactorRow, {
                  [styles.thoFactorDblRow]: twoFA === 'Google Authenticator',
                })}
              >
                <TwoFactorBlock onResetForm={handleSetInitialForm} />
                {twoFA === 'Google Authenticator' && (
                  <BackupBlock
                    isFetching={isBF}
                    twoFAStatus={profile?.twoFactorAuthenticator as TwoFAType}
                    codes={backupCodes!}
                  />
                )}
              </div>
            )
          }}
        </FormItem>

        {/* )} */}
      </Form>
    </main>
  )

  return (
    <React.Fragment>
      {isFetching ? <Spin>{content}</Spin> : content}
      <Row className={styles.footer} justify='end'>
        <Button
          htmlType='submit'
          size='middle'
          type='primary'
          onClick={form.submit}
          // disabled={isLoading}
        >
          Save changes
        </Button>
      </Row>
    </React.Fragment>
  )
}
