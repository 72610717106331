import { DashboardTableWrapper } from '../DashboardTableWrapper'
import React, {
  Dispatch,
  lazy,
  MouseEventHandler,
  SetStateAction,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Select, Spin } from 'antd'
import { RangePicker } from '../../../../../../shared/components/datePicker'
import styles from '../../styles.module.scss'
import { TopLocationsTable } from './TopLocationsTable'
import { Moment } from 'moment'
import { useGetVendorListQuery } from '../../../../../Settings/core/http/VendorManagementApi'
import { provinceWithDistricts } from '../../../../../../shared/data'
import { SVG_PATH_COLOR_BY_AMOUNT_ENUM } from './index.d'
import { ITopLocation } from '../../../../models/ITopLocation'
import { Tooltip } from './Tooltip'
import classNames from 'classnames'
import { omit, startCase } from 'lodash'

const AfghanistanMap = lazy(() => import('../../../../../../assets/svg/AfghanistanMap'))

interface IProps {
  widgetData: Omit<ITopLocation, 'id'>[]
  onWidgetDateChange?: (value: Moment[] | null) => void
  setTopLocationsVendor: Dispatch<SetStateAction<number | null>>
  isLoadingTopLocations: boolean
  shouldDisplayProvince?: boolean
}

export const TopLocationsBlock = ({
  widgetData,
  onWidgetDateChange,
  setTopLocationsVendor,
  isLoadingTopLocations,
  shouldDisplayProvince = false,
}: IProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipContent, setTooltipContent] = useState<Partial<ITopLocation>>(
    {} as Partial<ITopLocation>
  )
  const { data: vendorsList } = useGetVendorListQuery({})

  const getSvgColorBySalesAmount = useCallback((amount: number): string => {
    if (!amount) {
      return SVG_PATH_COLOR_BY_AMOUNT_ENUM.BASE_COLOR
    }
    if (amount >= 1 && amount <= 10000) {
      return SVG_PATH_COLOR_BY_AMOUNT_ENUM['1-10k']
    }
    if (amount >= 10001 && amount <= 20000) {
      return SVG_PATH_COLOR_BY_AMOUNT_ENUM['10k-20k']
    }
    if (amount >= 20001 && amount <= 50000) {
      return SVG_PATH_COLOR_BY_AMOUNT_ENUM['20k-50k']
    }
    if (amount >= 50001 && amount <= 75000) {
      return SVG_PATH_COLOR_BY_AMOUNT_ENUM['50k-75k']
    }
    if (amount >= 75001 && amount <= 100000) {
      return SVG_PATH_COLOR_BY_AMOUNT_ENUM['75k-100k']
    }
    if (amount >= 100001 && amount <= 200000) {
      return SVG_PATH_COLOR_BY_AMOUNT_ENUM['100k-200k']
    }
    if (amount >= 200001 && amount <= 500000) {
      return SVG_PATH_COLOR_BY_AMOUNT_ENUM['200k-500k']
    }
    if (amount >= 500001 && amount <= 750000) {
      return SVG_PATH_COLOR_BY_AMOUNT_ENUM['500k-750k']
    }
    if (amount >= 750001 && amount <= 1000000) {
      return SVG_PATH_COLOR_BY_AMOUNT_ENUM['750k-1000k']
    }
    return SVG_PATH_COLOR_BY_AMOUNT_ENUM['1000k']
  }, [])

  const handleSvgPathClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      const { target } = e as unknown as {
        target: { id: string; clientX: number; clientY: number }
      }

      if (!target.id) {
        handleMouseLeave()
      }

      if (!shouldDisplayProvince) {
        const district = provinceWithDistricts
          .flatMap((province) =>
            province.districts.map((district) => ({
              province: province.province,
              district: district.district,
              code: district.code,
            }))
          )
          .find((item) => item.code === target.id)

        if (!district) return

        const sidebar = document.querySelector('.ant-layout-sider-children') as HTMLElement
        const tooltip = document.querySelector('.dashboard-tooltip') as HTMLElement
        const tooltipX = e.pageX - sidebar.offsetWidth - (tooltip?.offsetWidth || 140)
        const tooltipY = e.pageY - (tooltip?.offsetHeight || 93) - 10

        setTooltipPosition({ x: tooltipX, y: tooltipY })

        const districtMatch = widgetData?.find(({ district: d }) => d.name === district?.district)

        if (!districtMatch) {
          setTooltipContent({
            district: {
              id: 1,
              name: district?.district,
            },
            province: {
              id: 1,
              name: district?.province,
            },
            salesAmount: 0,
            activeAgentsCount: 0,
          })

          return setShowTooltip(true)
        }

        setTooltipContent(districtMatch as Partial<ITopLocation>)
        return setShowTooltip(true)
      }

      const province = provinceWithDistricts.find(
        (prov) => startCase(prov.province) === startCase(target.id)
      )

      if (!province) return

      const sidebar = document.querySelector('.ant-layout-sider-children') as HTMLElement
      const tooltip = document.querySelector('.dashboard-tooltip') as HTMLElement
      const tooltipX = e.pageX - sidebar.offsetWidth - (tooltip?.offsetWidth || 140)
      const tooltipY = e.pageY - (tooltip?.offsetHeight || 93) - 10

      setTooltipPosition({ x: tooltipX, y: tooltipY })

      const provinceMatch = widgetData?.find(({ province: p }) => p.name === province?.province)

      if (!provinceMatch) {
        setTooltipContent({
          province: {
            id: 1,
            name: province?.province,
          },
          salesAmount: 0,
          activeAgentsCount: 0,
        })

        return setShowTooltip(true)
      }

      setTooltipContent(omit(provinceMatch, 'district') as Partial<ITopLocation>)
      return setShowTooltip(true)
    },
    [shouldDisplayProvince, widgetData]
  )

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  const findSvg = useCallback(() => {
    widgetData?.forEach((resp) => {
      if (!shouldDisplayProvince) {
        const code = provinceWithDistricts
          .find((province) => province.province === resp?.province?.name)
          ?.districts.find((district) => district.district === resp?.district?.name)

        if (code?.district !== resp?.district?.name) return

        const svg = ref?.current?.querySelector(`#${code?.code}`) as SVGElement
        if (svg) {
          svg.style.fill = getSvgColorBySalesAmount(resp?.salesAmount)
        }
      }

      if (shouldDisplayProvince) {
        const code = provinceWithDistricts.find(
          (province) => province.province === resp?.province?.name
        )

        if (code?.province !== resp?.province?.name) return
        let svgPath = code?.province
        if (code?.province === 'Sar-e Pul') {
          svgPath = 'Sar-e_Pul'
        }
        if (code?.province === 'Maidan Wardak') {
          svgPath = 'Maidan_Wardak'
        }
        const svg = ref?.current?.querySelector(`path#${svgPath}`) as SVGElement
        if (svg) {
          svg.style.fill = getSvgColorBySalesAmount(resp?.salesAmount)
        }
      }
    })

    return
  }, [shouldDisplayProvince, widgetData, getSvgColorBySalesAmount])

  useEffect(() => {
    if (widgetData) {
      findSvg()
    }
  }, [findSvg, widgetData])

  useEffect(() => {
    if (shouldDisplayProvince && !widgetData.length) {
      const svg3 = ref?.current?.querySelectorAll(`.st3`) as NodeListOf<SVGElement>
      const svg2 = ref?.current?.querySelectorAll(`.st2`) as NodeListOf<SVGElement>

      svg2?.forEach((svg) => {
        svg.style.fill = SVG_PATH_COLOR_BY_AMOUNT_ENUM.BASE_COLOR
      })

      svg3?.forEach((svg) => {
        svg.style.fill = SVG_PATH_COLOR_BY_AMOUNT_ENUM.BASE_COLOR
      })
      return
    }
  }, [shouldDisplayProvince, widgetData.length])

  const filters = useMemo(
    () => (
      <>
        <div className={styles.dashboardVendorFilter}>
          <Select
            placeholder='Select Vendor'
            onChange={(val) => setTopLocationsVendor(val)}
            dropdownMatchSelectWidth={false}
            placement='bottomRight'
            allowClear
          >
            {vendorsList?.items?.map((vendor) => (
              <Select.Option key={vendor.id} value={vendor.id}>
                {vendor?.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <RangePicker
          onChange={(val) => onWidgetDateChange && onWidgetDateChange(val as Moment[])}
        />
      </>
    ),
    [onWidgetDateChange, setTopLocationsVendor, vendorsList?.items]
  )

  const content = (
    <div className={styles.topLocationsWrapper}>
      <div
        className={classNames(styles.topLocationsMap, {
          [styles.topLocationsWithProvince]: shouldDisplayProvince,
        })}
        onMouseMove={handleSvgPathClick}
        onMouseLeave={handleMouseLeave}
        ref={ref}
      >
        <Suspense fallback={<Spin />}>
          <AfghanistanMap shouldDisplayProvince={shouldDisplayProvince} />
        </Suspense>
        {showTooltip && (
          <Tooltip tooltipPosition={tooltipPosition} tooltipContent={tooltipContent} />
        )}
      </div>
      <TopLocationsTable items={widgetData} shouldDisplayProvince={shouldDisplayProvince} />
    </div>
  )

  return (
    <div className={styles.topMargin}>
      <DashboardTableWrapper title='Top Locations' filters={filters}>
        {isLoadingTopLocations ? <Spin>{content}</Spin> : content}
      </DashboardTableWrapper>
    </div>
  )
}
