import { Form } from 'antd'
import styles from './styles.module.scss'
import { TextFieldAuth } from '../../../../shared/components/textfieldAuth/TextFieldAuth'
import { Button } from 'src/shared/components/button/Button'
import { useLoginMutation } from '../../core/http/AuthorizationApi'
import React, { useContext, useEffect, useState } from 'react'
import { IAuthBody, IAuthResponse } from 'src/shared/models/IAuth'
import { saveAuthData } from 'src/shared/api'
import { useNavigate } from 'react-router-dom'
import { emailValidator } from 'src/helpers/validation'
import { ErrorNode } from '../../../../shared/api/errorHandler'
import { Logo } from '../../../../shared/components/logo/Logo'
import { CenterModalContext } from 'src/features/Modals'
import { CENTER_MODALS } from 'src/helpers/contants'
import {
  useGoogleAuthCheckMutation,
  useGoogleAuthForgetMutation,
} from '../../core/http/TwoFactorAuthApi'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ArrowLeftOutlined } from '@ant-design/icons'

export const Login = () => {
  const [form] = Form.useForm()
  const [formCode] = Form.useForm()
  const [formBackup] = Form.useForm()

  const navigate = useNavigate()
  const { onOpen, onClose } = useContext(CenterModalContext)

  const [step, setStep] = useState(0)
  const [login, loginData] = useLoginMutation()
  const [check, checkData] = useGoogleAuthCheckMutation()
  const [forget, forgetData] = useGoogleAuthForgetMutation()

  const redirectTo = (value: string) => () => {
    navigate(value)
  }

  const afterCreate = (data: IAuthResponse) => () => {
    saveAuthData(data)
    navigate('/dashboard/overview')
  }

  useNotification(
    NOTIFICATION_TYPES.success,
    checkData.isSuccess,
    null,
    afterCreate(checkData.data!)
  )
  useNotification(
    NOTIFICATION_TYPES.success,
    forgetData.isSuccess,
    null,
    afterCreate(forgetData.data!)
  )
  useNotification(NOTIFICATION_TYPES.error, loginData.isError, loginData.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, checkData.isError, checkData.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, forgetData.isError, forgetData.error as ErrorNode)

  const onFinish = (values: IAuthBody) => {
    login(values)
  }

  const onFinishCode = (values: { code: string }) => {
    check(values)
  }

  const onFinishBackup = (values: { code: string }) => {
    forget(values)
  }

  useEffect(() => {
    const { isSuccess, data } = loginData
    if (isSuccess && data) {
      saveAuthData(data)
    }
    // eslint-disable-next-line
  }, [loginData.data, loginData.isSuccess])

  useEffect(() => {
    const { data } = loginData

    if (data?.twoFactorAuthenticator === 'Google Authenticator') setStep(1)
    if (data?.twoFactorAuthenticator === 'disabled') {
      onOpen(CENTER_MODALS.CONFIRM_MODAL, {
        title: 'Two-Factor Authentification',
        content: <div>Do you want to connect your account to Google Autenticator now?</div>,
        onCloseAction: handleCancelTwoFA,
        footer: (
          <>
            <Button color='blue' size='middle' onClick={handleCancelTwoFA}>
              Cancel
            </Button>

            <Button
              color='orange'
              size='middle'
              type='primary'
              htmlType='submit'
              onClick={handleConfirmTwoFA}
            >
              Confirm
            </Button>
          </>
        ),
      })
    }
    // eslint-disable-next-line
  }, [loginData.isSuccess])

  const handleCancelTwoFA = () => {
    redirectTo('/dashboard/overview')()
    onClose()
  }

  const handleConfirmTwoFA = () => {
    redirectTo('/sign-up/google-authenticator')()
    onClose()
  }

  return (
    <main className={styles.login}>
      <div className={styles.loginSidebar}>
        <div className={styles.loginSidebarInner}>
          <div className={styles.loginSidebarTitle}>Welcome!</div>
          {step === 2 && (
            <div className={styles.backContainer} onClick={() => setStep(1)}>
              <ArrowLeftOutlined />
              Back
            </div>
          )}
          {step !== 2 && <div className={styles.loginSidebarSteps}>{`${step + 1}/2`}</div>}

          {step === 0 && (
            <React.Fragment>
              <div className={styles.loginSidebarSubtitle}>Please log in to continue</div>
              <Form form={form} onFinish={onFinish} autoComplete='off'>
                <Form.Item name='email' rules={[{ validator: emailValidator }]} required>
                  <TextFieldAuth
                    placeholder='Email'
                    prefix='email'
                    disabled={loginData.isLoading}
                    type='email'
                  />
                </Form.Item>

                <Form.Item name='password' required>
                  <TextFieldAuth
                    placeholder='Password'
                    prefix='password'
                    disabled={loginData.isLoading}
                    type='password'
                  />
                </Form.Item>

                <Button
                  block
                  color='orange'
                  type='primary'
                  htmlType='submit'
                  disabled={loginData.isLoading}
                  style={{ marginTop: '32px' }}
                >
                  Next: Two-factor Authentication
                </Button>
              </Form>
            </React.Fragment>
          )}

          {step === 1 && (
            <React.Fragment>
              <div className={styles.loginSidebarSubtitle}>
                Please enter Two-factor Authentication Verification code and click to verify
              </div>
              <Form form={formCode} onFinish={onFinishCode} autoComplete='off'>
                <Form.Item name='code' required>
                  <TextFieldAuth
                    placeholder='Verification Code'
                    prefix='password'
                    disabled={checkData.isLoading}
                    type='password'
                  />
                </Form.Item>

                {checkData.isError && (
                  <div className={styles.forgetLink} onClick={() => setStep(2)}>
                    Do you want to reset 2FA? Use Backup Codes for it.
                  </div>
                )}

                <Button
                  block
                  color='orange'
                  type='primary'
                  disabled={checkData.isLoading}
                  htmlType='submit'
                  style={{ marginTop: '32px' }}
                >
                  Log In
                </Button>
              </Form>
            </React.Fragment>
          )}
          {step === 2 && (
            <React.Fragment>
              <div className={styles.loginSidebarSubtitle}>
                Enter Two-factor Backup code and click to verify
              </div>
              <Form form={formBackup} onFinish={onFinishBackup} autoComplete='off'>
                <Form.Item name='code' required>
                  <TextFieldAuth
                    placeholder='Backup Code'
                    prefix='password'
                    disabled={forgetData.isLoading}
                    type='password'
                  />
                </Form.Item>

                <Button
                  block
                  color='orange'
                  type='primary'
                  disabled={forgetData.isLoading}
                  htmlType='submit'
                  style={{ marginTop: '32px' }}
                >
                  Reset
                </Button>
              </Form>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className={styles.loginContent}>
        <Logo withText />
      </div>
    </main>
  )
}
