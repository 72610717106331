import { FormInstance, Row, Select as AntSelect, Form } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { isEmpty } from 'lodash'

import { Wrapper } from '../Wrapper'

// queries
import {
  useGetAgentLevelListQuery,
  useGetAgentTypeListQuery,
} from 'src/features/salesNetwork/core/http/AgentManagement'

import { Toggle } from '../../../../shared/components/toggle/Toggle'
import { requiredValidator } from 'src/helpers/validation'
import { useGetAccountTypeOptionsQuery } from 'src/features/Settings/core/http/SystemConfigurationApi'
import { IDENTIFIERS_TYPE_NEW } from '../../models/IAgent'
import { AGENT_TYPE_ID_ENUM } from 'src/features/Settings/models/IAgentType'

// styles
import styles from './styles.module.scss'

interface IAccountType {
  form: FormInstance
  view?: boolean
  create?: boolean
  setAgentStatus?: React.Dispatch<React.SetStateAction<boolean>>
  agentStatus?: boolean
}

const AccountType = ({ form, view, create = false, setAgentStatus, agentStatus }: IAccountType) => {
  const { data: accountTypeOptions } = useGetAccountTypeOptionsQuery()
  const { data: agentTypeOptions } = useGetAgentTypeListQuery()
  const { data: agentLevelOptions } = useGetAgentLevelListQuery()

  const onChangeAgentType = (numberAgentType: string | number) => {
    onClearAgentType()

    form.setFields([
      {
        name: 'parentId',
        value: '',
        errors: [],
      },
      {
        name: 'parentName',
        value: '',
      },
      ...(numberAgentType === AGENT_TYPE_ID_ENUM.RESELLER
        ? [
            { name: 'firstName', value: null, errors: [] },
            { name: 'lastName', value: null, errors: [] },
            { name: 'firstNameInLocalLanguage', value: null, errors: [] },
            { name: 'lastNameInLocalLanguage', value: null, errors: [] },
            { name: 'gender', value: null, errors: [] },
            { name: 'merchant', value: null, errors: [] },
          ]
        : []),
    ])
  }

  const statusButton = (
    <Toggle
      defaultChecked={agentStatus}
      onChange={(isChecked) => setAgentStatus!(isChecked)}
      inWrapper
      text='Status'
    />
  )

  const onClearAgentType = () => {
    form.setFields([
      {
        name: ['accountType', 'id'],
        value: null,
        errors: [],
      },
      {
        name: 'agentLevelId',
        value: null,
        errors: [],
      },
      {
        name: 'companyTitle',
        value: null,
        errors: [],
      },
      {
        name: 'agentIdentifiers',
        value: [
          {
            type: IDENTIFIERS_TYPE_NEW.MOBILE.type,
            value: null,
          },
        ],
      },
      {
        name: ['resellerCredentials', 'ips', 0],
        value: [''],
        errors: [],
      },
      {
        name: 'resellerCredentials',
        value: {
          ips: [''],
          email: IDENTIFIERS_TYPE_NEW.EMAIL.type,
          value: '',
        },
        errors: [],
      },
    ])
  }

  return (
    <Wrapper
      title='Account Type'
      statusButton={create ? statusButton : null}
      className={styles['wrapper-detail-info']}
    >
      <Row justify='space-between'>
        <FormItem shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const isParent = getFieldValue('parentName')
            return (
              <FormItem
                name='agentTypeId'
                label='Agent Type'
                rules={[{ required: true, validator: requiredValidator('Agent Type') }]}
                className={styles['form-item-inp']}
              >
                <AntSelect
                  placeholder='Select Agent Type'
                  disabled={view || isParent || isEmpty(agentTypeOptions?.items) || !create}
                  onChange={onChangeAgentType}
                  onClear={onClearAgentType}
                  allowClear
                >
                  {agentTypeOptions?.items?.map(({ id, name }) => (
                    <AntSelect.Option key={id} value={id}>
                      {name}
                    </AntSelect.Option>
                  ))}
                </AntSelect>
              </FormItem>
            )
          }}
        </FormItem>

        <FormItem shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const isParent = getFieldValue('parentName')
            const agentType = getFieldValue('agentTypeId')

            const options = agentLevelOptions?.items?.filter(({ level }) =>
              agentType === AGENT_TYPE_ID_ENUM.INDIRECT ? level : level === 1
            )

            return (
              <FormItem
                name='agentLevelId'
                label='Agent Level'
                className={styles['form-item-inp']}
                rules={[{ required: true, validator: requiredValidator('Agent Level') }]}
              >
                <AntSelect
                  placeholder='Select Agent Level'
                  disabled={
                    view ||
                    isParent ||
                    !agentType ||
                    isEmpty(agentLevelOptions?.items) ||
                    (!create && agentType === AGENT_TYPE_ID_ENUM.RESELLER)
                  }
                  allowClear
                >
                  {options?.map(({ id, level }) => (
                    <AntSelect.Option key={id} value={id}>
                      {level}
                    </AntSelect.Option>
                  ))}
                </AntSelect>
              </FormItem>
            )
          }}
        </FormItem>
      </Row>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const agentLevelId = getFieldValue('agentLevelId')

          return (
            <Form.Item
              name={['accountType', 'id']}
              label='Account Type'
              className={styles['form-item-inp form-item-row']}
              rules={[
                ...(view || agentLevelId !== AGENT_TYPE_ID_ENUM.RESELLER
                  ? []
                  : [{ required: true, validator: requiredValidator('AccountType') }]),
              ]}
            >
              <AntSelect
                allowClear
                placeholder='Select Account Type'
                disabled={view || agentLevelId !== AGENT_TYPE_ID_ENUM.RESELLER || !create}
              >
                {accountTypeOptions?.items?.map(({ id, accountType }) => (
                  <AntSelect.Option key={id} value={id}>
                    {accountType}
                  </AntSelect.Option>
                ))}
              </AntSelect>
            </Form.Item>
          )
        }}
      </Form.Item>
    </Wrapper>
  )
}
export default AccountType
