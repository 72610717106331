import React, { memo } from 'react'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import emptyAvatar from '../../../../../assets/img/Avatar.png'
import styles from './styles.module.scss'

interface IProps {
  isOnline?: boolean
  userName: string
  logo?: string | null
}

const ChatTooltipAvatar = ({ isOnline, userName, logo }: IProps) => {
  return (
    <div
      className={classNames(styles.userAvatar, {
        [styles.isOnline]: isOnline,
      })}
    >
      <Tooltip title={userName} placement='topRight'>
        <img src={logo ?? emptyAvatar} alt={userName} />
      </Tooltip>
    </div>
  )
}

export default memo(ChatTooltipAvatar)
