import React, { Dispatch, memo, SetStateAction, useMemo } from 'react'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { DashboardTableWrapper } from '../../../../../../Dashboard/pages/Overview/Components/DashboardTableWrapper'
import { ITableResponse } from '../../../../../../../shared/models/ITableResponse'
import { ITableConf } from '../../../../../../../shared/models/ITableConf'
import { IBankAccountBalancesChart } from '../../../../../models/IBankAccount'
import styles from '../../../styles.module.scss'
import { Space } from 'antd'
import { PaginationNextIcon, PaginationPrevIcon } from '../../../../../../../assets/svg'
import { NotFoundPage } from '../../../../../../NotFoundPage'
import { RecursivelyReplaceCharacters } from '../../../../../../../shared/components/privateMode'
import { replaceCharactersWithAsterisks } from '../../../../../../../helpers/utils'
import { useLocalStorageGetByKey } from '../../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IProps {
  page: number
  widgetData: ITableResponse<IBankAccountBalancesChart>
  setPage: Dispatch<SetStateAction<ITableConf>>
}

const BankAccountBalance = ({ widgetData, page, setPage }: IProps) => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const mappedData = useMemo(
    () =>
      widgetData?.items?.map((ven) => ({
        name: ven?.name,
        balance: ven?.currentBalance,
      })),
    [widgetData?.items]
  )

  const changePage = (offset: number) => {
    setPage((prev) => ({ ...prev, page: prev.page! + offset }))
  }

  const previousPage = () => {
    changePage(-1)
  }

  const nextPage = () => {
    changePage(1)
  }

  const totalPages = useMemo(() => Math.ceil(widgetData?.totalCount / 5), [widgetData.totalCount])
  const startingIndex = useMemo(() => (page - 1) * 5 + 1, [page])
  const endingIndex = useMemo(
    () => Math.min(page * 5, widgetData?.totalCount),
    [page, widgetData?.totalCount]
  )

  return (
    <DashboardTableWrapper title='Bank Account Balances'>
      {mappedData?.length ? (
        <>
          <ResponsiveContainer width='100%' height={320}>
            <ComposedChart
              layout='vertical'
              width={500}
              height={320}
              data={mappedData}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 30,
              }}
            >
              <CartesianGrid stroke='#f5f5f5' />
              <XAxis
                type='number'
                tickFormatter={(value) =>
                  isPrivateMode ? replaceCharactersWithAsterisks(value) : value
                }
              />
              <YAxis
                dataKey='name'
                type='category'
                tickFormatter={(value) =>
                  isPrivateMode ? replaceCharactersWithAsterisks(value) : value
                }
              />
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const time = payload[0]?.payload?.name

                    return (
                      <RecursivelyReplaceCharacters>
                        <div className={styles.tooltipSummary}>
                          <div className={styles.tooltipRow}>
                            <div className={styles.tooltipRowValue}>{time}</div>
                          </div>
                          {payload?.map((value, i) => (
                            <div
                              key={i}
                              style={{ display: 'flex', alignItems: 'center', gridGap: 10 }}
                            >
                              <div
                                style={{
                                  color: payload[i]?.color,
                                }}
                              >
                                {payload[i]?.name}:
                              </div>
                              <div className={styles.tooltipValue}>{payload[i]?.value}</div>
                            </div>
                          ))}
                        </div>
                      </RecursivelyReplaceCharacters>
                    )
                  }

                  return null
                }}
              />
              <Bar
                dataKey='balance'
                barSize={18}
                stackId='a'
                fill='#ff9800'
                name='Bank Account Balance'
              />
            </ComposedChart>
          </ResponsiveContainer>
          <div className={styles.chartWithPagination}>
            <Space size={5}>
              <div>
                <span>{startingIndex}</span>
                <span>-</span>
                <span>{endingIndex}</span>
              </div>
              <span>of</span>
              <span>{widgetData?.totalCount || 0}</span>
            </Space>
            <Space size={20}>
              <button
                type='button'
                disabled={page <= 1}
                onClick={previousPage}
                className={styles.paginationButton}
              >
                <PaginationPrevIcon />
              </button>
              <button
                type='button'
                disabled={page >= totalPages}
                onClick={nextPage}
                className={styles.paginationButton}
              >
                <PaginationNextIcon />
              </button>
            </Space>
          </div>
        </>
      ) : (
        <NotFoundPage />
      )}
    </DashboardTableWrapper>
  )
}

export default memo(BankAccountBalance)
