import { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { Col, Row, Tabs } from 'antd'

import { Users } from '../Users'
import { DepartmentInfo } from '../../../Components'
import { AddIcon, CloudDownloadIcon } from '../../../../../../../../../../assets/svg'
import { Toggle } from '../../../../../../../../../../shared/components/toggle/Toggle'
import { IconButton } from '../../../../../../../../../../shared/components/iconButton/IconButton'
import { Button } from 'src/shared/components/button/Button'
import WithPermissionDeniedContainer from '../../../../../../../../../../shared/components/withPermissionDenied'

import {
  useGetDepartmentByIdQuery,
  usePatchDepartmentMutation,
} from '../../../../../../../../core/http/DepartmentsManagementApi'

import { DEPARTMENT_MANAGEMENT_VIEW_TABS_ENUM } from './index.d'
import { CenterModalContext, RightModalContext } from '../../../../../../../../../Modals'
import { CENTER_MODALS, RIGHT_MODALS } from '../../../../../../../../../../helpers/contants'
import { Emitter, EmitterEvents } from '../../../../../../../../../../helpers/eventEmitter'
import { IPermissionsActions, IPermissionsNames } from '../../../../../../../../models/IPermissions'
import usePermissions from '../../../../../../../../../../shared/hooks/usePermissions'
import { TableExportFormatEnum } from '../../../../../../../../../../shared/hooks/table/useTableExport'
import { Positions } from '../../../../Positions'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'
import { useDispatch } from 'react-redux'
import { setBreadcrumb } from 'src/features/app/core/BreadcrumbsSlice'
import { useAppDispatch } from '../../../../../../../../../../redux'
import { SETTINGS_ENUM } from '../../../../../../../../../../routes/settings'

interface IPropsOperations {
  canAdd: boolean
  canEdit: boolean
  canExport: boolean
  onAddButtonClick: () => void
}

const Operations = memo(({ canEdit, canAdd, canExport, onAddButtonClick }: IPropsOperations) => {
  const { onOpen, onClose } = useContext(CenterModalContext)
  const dispatch = useDispatch()

  const { id } = useParams<{ mode: 'view' | 'edit'; id: string }>()

  const { data, isFetching } = useGetDepartmentByIdQuery(id as string, { skip: !id })
  const [patchDep, patchDepResp] = usePatchDepartmentMutation()

  useEffect(() => {
    if (data) {
      dispatch(setBreadcrumb(data))
    }
  }, [data, dispatch])

  const onStatusChange = (checked: boolean) => {
    patchDep({ id: id as string, body: { status: checked } })
  }

  const handlerExportClick = () => {
    onOpen(CENTER_MODALS.EXPORT_TABLE, {
      title: 'Export Table',
      onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
      onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      footer: <Button onClick={onClose}>Cancel</Button>,
    })
  }

  return (
    <div style={{ display: 'flex', gridGap: 10 }}>
      <Row>
        {canExport && (
          <Col style={{ marginRight: 10 }}>
            <IconButton
              icon={<CloudDownloadIcon />}
              color='blue'
              type='primary'
              onClick={handlerExportClick}
            />
          </Col>
        )}
        {canAdd && (
          <Col>
            <IconButton
              icon={<AddIcon />}
              color='orange'
              type='primary'
              onClick={onAddButtonClick}
            />
          </Col>
        )}
      </Row>
      {canEdit && (
        <Toggle
          disabled={!canEdit}
          defaultChecked={data?.status}
          checked={data?.status}
          text='Status'
          loading={patchDepResp.isLoading || isFetching}
          onChange={onStatusChange}
          inWrapper
        />
      )}
    </div>
  )
})

export const DepartmentsManagementViewTabs = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const rightModalContext = useContext(RightModalContext)
  const { mode, id } = useParams<{ mode: 'view' | 'edit'; id: string }>()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: DEPARTMENT_MANAGEMENT_VIEW_TABS_ENUM.DEPARTMENT_INFORMATION,
  })

  const { data } = useGetDepartmentByIdQuery(id as string, { skip: !id })

  const isEditMode = useMemo(() => mode === 'edit', [mode])
  const isViewMode = useMemo(() => mode === 'view', [mode])

  useEffect(() => {
    if (data) {
      dispatch(setBreadcrumb({ ...data, key: SETTINGS_ENUM.USERS_MANAGEMENT_DEPARTMENT_VIEW }))
    }
  }, [data, dispatch])

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const [departmentsId] = getModulesIdsByNames([IPermissionsNames.Departments])
  const [usersId] = getModulesIdsByNames([IPermissionsNames.Users])

  const [canViewDepartment, canEditDepartment] = canPerformAction(departmentsId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.EDIT,
  ])
  const [canViewUsers, canExportUsers, canAddUsers] = canPerformAction(usersId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.EXPORT,
    IPermissionsActions.ADD,
  ])

  /** Table */
  const items = useMemo(
    () => [
      {
        key: DEPARTMENT_MANAGEMENT_VIEW_TABS_ENUM.DEPARTMENT_INFORMATION,
        label: DEPARTMENT_MANAGEMENT_VIEW_TABS_ENUM.DEPARTMENT_INFORMATION,
        children: (
          <WithPermissionDeniedContainer
            isAllowedView={(isViewMode && canViewDepartment) || (isEditMode && canEditDepartment)}
          >
            <DepartmentInfo canEdit={canEditDepartment} />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        key: DEPARTMENT_MANAGEMENT_VIEW_TABS_ENUM.POSITIONS,
        label: DEPARTMENT_MANAGEMENT_VIEW_TABS_ENUM.POSITIONS,
        children: <Positions />,
      },
      {
        key: DEPARTMENT_MANAGEMENT_VIEW_TABS_ENUM.USERS,
        label: DEPARTMENT_MANAGEMENT_VIEW_TABS_ENUM.USERS,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canViewUsers}>
            <Users />
          </WithPermissionDeniedContainer>
        ),
      },
    ],
    [canEditDepartment, canViewDepartment, canViewUsers, isEditMode, isViewMode]
  )

  const currentTabData = useMemo(() => {
    if (activeTab === DEPARTMENT_MANAGEMENT_VIEW_TABS_ENUM.USERS) {
      return {
        openModal: {
          name: RIGHT_MODALS.SETTINGS.DEPARTMENT_MANAGEMENT_USERS_LIST,
          props: { title: 'Add Users', departmentId: id },
        },
        canAdd: canAddUsers,
        canExport: canExportUsers,
      }
    }
    return {
      canAdd: false,
      canExport: false,
    }
    // eslint-disable-next-line
  }, [activeTab, canAddUsers, canExportUsers])

  const onAddButtonClick = useCallback(() => {
    if (currentTabData?.openModal) {
      rightModalContext.onOpen(currentTabData.openModal?.name, currentTabData.openModal?.props)
    }
  }, [currentTabData.openModal, rightModalContext])

  return (
    <Tabs
      destroyInactiveTabPane
      activeKey={activeTab}
      items={items}
      tabBarExtraContent={
        <Operations
          canAdd={currentTabData.canAdd}
          canExport={currentTabData.canExport}
          canEdit={canEditDepartment}
          onAddButtonClick={onAddButtonClick}
        />
      }
      onChange={onTabChange}
    />
  )
}
