import React from 'react'

import FormItem from 'antd/es/form/FormItem'
import { Wrapper } from '../../../../../../../../../shared/components/wrapper/Wrapper'
import { TextField } from '../../../../../../../../../shared/components/textfield/TextField'
import { Checkbox } from '../../../../../../../../../shared/components/checkbox/Checkbox'
import classNames from 'classnames'
import { Button } from 'antd'
import { BucketIcon, CrossIcon, EditIcon } from '../../../../../../../../../assets/svg'
import { Toggle } from '../../../../../../../../../shared/components/toggle/Toggle'
import { IconButton } from '../../../../../../../../../shared/components/iconButton/IconButton'
import styles from './styles.module.scss'
import FormList from 'antd/es/form/FormList'

const ConditionActions = () => {
  return (
    <div className={styles.conditionActions}>
      <Toggle inWrapper className={styles.conditionAction} />
      <IconButton
        color='orange'
        iconPos='right'
        size='middle'
        block
        icon={<EditIcon />}
        className={classNames(styles.conditionAction, styles.conditionActionBtn)}
      />
      <IconButton
        color='orange'
        iconPos='right'
        size='middle'
        block
        icon={<BucketIcon />}
        className={classNames(styles.conditionAction, styles.conditionActionBtn)}
      />
    </div>
  )
}

const ParametersDetailInfo = () => {
  const onChangeCheckbox = () => {}
  return (
    <FormList name='parameterConditions'>
      {(fields) => {
        return (
          <>
            <Wrapper title='Condition for Loan Eligibility' actions={<ConditionActions />}>
              {fields.map(({ key }) => (
                <div key={key} className={styles.conditionItem}>
                  <div className={styles.dblRow}>
                    <FormItem name='condition' label='Eligibility Group Name'>
                      <TextField />
                    </FormItem>
                  </div>
                  <div className={styles.dblRow}>
                    <FormItem name='minimumScore' label='Minimum Score for Loan Eligibility'>
                      <TextField />
                    </FormItem>
                  </div>
                  <div className={styles.dblRow}>
                    <FormItem name='amountPerScore' label='Amount Per Score'>
                      <TextField />
                    </FormItem>
                  </div>
                  <div className={styles.dblRow}>
                    <FormItem name='penaltyRate' label='Penalty Rate for Loan Late Clearance'>
                      <TextField />
                    </FormItem>

                    <FormItem name='loanClearance' label='Loan Clearance Deadline'>
                      <TextField />
                    </FormItem>
                  </div>
                  <div className={classNames(styles.dblRow, styles.checkboxContainer)}>
                    <Checkbox onChange={onChangeCheckbox} text='Applicable to Sub-Agent' />
                  </div>
                  <div className={styles.dblRow}>
                    <FormItem name='agentLevel' label='Agent Level Selection'>
                      <TextField />
                    </FormItem>
                  </div>
                  <div>
                    {['Chip', 'Chip', 'Chip', 'Chip'].map((el, i) => (
                      <div key={i} className={styles.tagItem}>
                        <div>{el}</div>
                        <div className={styles.removeTagIcon}>
                          <CrossIcon color='#FFF' size={6} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <div className={styles.buttonsContainer}>
                <Button type='primary' className={styles.saveButton}>
                  Save
                </Button>
              </div>
            </Wrapper>
          </>
        )
      }}
    </FormList>
  )
}

export default ParametersDetailInfo
