import { Col, Row } from 'antd'
import { ISelectedFilters } from 'src/shared/components/filter/Filter'
import { IFilterFields } from 'src/shared/models/IFilterFields'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { useLazySendTransactionToChatQuery } from 'src/features/salesNetwork/core/http/AgentManagement'
import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { downloadFileThroughATag } from 'src/helpers/utils'
import ShowMessage from 'src/shared/api/errorHandler'
import { DownloadIcon } from 'src/assets/svg'

interface IProps {
  agentId: string
  filterValues: ISelectedFilters
  data?: IFilterFields[]
  chatId: number | null
}

export const SendTransactionReportToChat = ({
  agentId,
  filterValues,
  chatId,
}: IProps): JSX.Element => {
  const [sendToChatTrigger, result] = useLazySendTransactionToChatQuery()

  const sendToChat = () => {
    sendToChatTrigger({
      id: agentId,
      chatId,
      params: { ...filterValues },
    })
  }
  useEffect(() => {
    if (isEmpty(result.data)) return
    downloadFileThroughATag(result.data?.link, `agent-transaction-report-${agentId}.pdf`)
    ShowMessage('success', 'Success!')
  }, [result?.data])
  return (
    <>
      <Row align='middle' justify='space-between' style={{ marginBottom: '20px' }}>
        <Col>
          <IconButton
            onClick={sendToChat}
            icon={<DownloadIcon color='#34a9ef' />}
            size='middle'
            borderColor='#34a9ef'
            htmlType='submit'
            block
            disabled={!Object.values(filterValues)?.length || result.isFetching}
          ></IconButton>
        </Col>
      </Row>
    </>
  )
}
