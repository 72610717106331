import { Switch, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { IProduct } from '../../../../../../models/IProduct'
import { useCallback, useContext, useState } from 'react'
import { ISelectedFilters } from '../../../../../../../../shared/components/filter/Filter'
import { ITableConf } from '../../../../../../../../shared/models/ITableConf'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from 'src/shared/components/popup/Popup'
import {
  DateTableIcon,
  DenominationMaxIcon,
  DenominationMinIcon,
  TableActionIcon,
} from 'src/assets/svg'
import styles from './styles.module.scss'
import { formatNumberToLocale } from '../../../../../../../salesNetwork/helpers'
import { formatDateWithTime } from '../../../../../../../../helpers/utils'
import { useParams } from 'react-router-dom'
import emptyAvatarImage from 'src/assets/img/ProductTablePlaceholder.png'
import {
  useDeleteProductByIdMutation,
  useGetProductsFiltersListQuery,
  useGetProductsListQuery,
  useLazyExportProductsTableQuery,
  usePatchProductMutation,
} from '../../../../../../core/http/VendorManagementApi'
import { TableHeader } from '../../TableHeader'
import { RightModalContext } from '../../../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../../../helpers/contants'
import { ErrorNode, showConfirmMessage } from 'src/shared/api/errorHandler'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { isEmpty, startCase } from 'lodash'
import { useTableExport } from '../../../../../../../../shared/hooks/table/useTableExport'
import { useEmptyTable } from '../../../../../../../../shared/hooks/table/useEmptyTable'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { useLocalStorageGetByKey } from '../../../../../../../../shared/hooks/useLocalStorageGetByKey'
import { RecursivelyReplaceCharacters } from '../../../../../../../../shared/components/privateMode'

const ProductsTable = () => {
  const { id: vendorId } = useParams<{ id: string }>()
  const { onOpen } = useContext(RightModalContext)
  const [patchProd, patchProdResp] = usePatchProductMutation()
  const [deleteProd, deleteProdResp] = useDeleteProductByIdMutation()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const { data: profile } = useGetProfileQuery()

  useNotification(NOTIFICATION_TYPES.success, deleteProdResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    deleteProdResp.isError,
    deleteProdResp.error as ErrorNode
  )
  useNotification(NOTIFICATION_TYPES.error, patchProdResp.isError, patchProdResp.error as ErrorNode)

  const tableActionsPopup = useCallback(
    (productId: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () =>
            onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_PRODUCT, {
              title: 'View Products',
              mode: 'view',
              id: productId,
              vendorId,
            }),
        },
        {
          text: 'Edit',
          shouldDisplay: true,
          onClick: () =>
            onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_PRODUCT, {
              title: 'Edit Products',
              mode: 'edit',
              id: productId,
              vendorId,
            }),
        },
        {
          text: 'Delete',
          withDivider: true,
          shouldDisplay: true,
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          onClick: () =>
            showConfirmMessage('This product will be deleted', () => deleteProd(productId)),
        },
      ]
    },
    [deleteProd, onOpen, vendorId]
  )

  const columns: ColumnsType<IProduct> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      width: '20%',
      render: (name: IProduct['name'], row) => (
        <RecursivelyReplaceCharacters>
          <a
            onClick={() =>
              onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_PRODUCT, {
                title: 'View Products',
                mode: 'view',
                id: row.id,
                vendorId,
              })
            }
            className='table-avatar'
          >
            <img
              alt='product logo'
              src={!isPrivateMode && row.logo ? row.logo : emptyAvatarImage}
              loading='lazy'
            />
            <div>{name}</div>
          </a>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Product Type',
      dataIndex: 'type',
      sorter: true,
      width: '15%',
      render: (type: IProduct['type']) => (
        <RecursivelyReplaceCharacters>{startCase(type.toLowerCase())}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Unit Cost',
      dataIndex: 'unitCost',
      sorter: true,
      width: '10%',
      render: (unitCost: IProduct['unitCost']) => (
        <RecursivelyReplaceCharacters>
          <span className='table-cost-blue'>{unitCost}</span>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Refund Penalty Rate',
      dataIndex: 'refundPenaltyRate',
      sorter: true,
      width: '10%',
      render: (refundPenaltyRate: IProduct['refundPenaltyRate']) =>
        refundPenaltyRate && (
          <RecursivelyReplaceCharacters>{refundPenaltyRate}%</RecursivelyReplaceCharacters>
        ),
    },
    {
      title: 'Denomination (min-max)',
      dataIndex: 'denominationMin',
      width: '10%',
      render: (denominationMin: IProduct['denominationMin'], { denominationMax }) => (
        <div>
          <div className='table-denomination'>
            <DenominationMaxIcon />
            <RecursivelyReplaceCharacters>
              {formatNumberToLocale(denominationMax, { isCurrency: true })}
            </RecursivelyReplaceCharacters>
          </div>
          <div className='table-denomination'>
            <DenominationMinIcon />
            <RecursivelyReplaceCharacters>
              {formatNumberToLocale(denominationMin, { isCurrency: true })}
            </RecursivelyReplaceCharacters>
          </div>
        </div>
      ),
    },
    {
      title: 'Master Product',
      dataIndex: 'masterProduct',
      sorter: true,
      width: '15%',
      render: (masterProduct: IProduct['masterProduct']) => (
        <RecursivelyReplaceCharacters>{masterProduct?.name}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      sorter: true,
      width: '15%',
      render: (createdAt: IProduct['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(createdAt, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: '10%',
      render: (status: IProduct['status'], { id }) => {
        return (
          <Switch
            defaultChecked={status}
            checked={status}
            onChange={(status) => {
              patchProd({ vendorId: id, status })
            }}
            loading={patchProdResp.isLoading}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const { queryParams, queryFields } = useGetQueryParams()
  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data: filtersList } = useGetProductsFiltersListQuery()
  const { data, isFetching } = useGetProductsListQuery(
    {
      vendorId: vendorId as string,
      ...tableConf,
      ...filterValues,
    },
    { skip: isEmpty(tableConf) }
  )

  const [exportTable] = useLazyExportProductsTableQuery()
  useTableExport({
    tableConf,
    filterValues: { ...filterValues, vendorId: vendorId as string },
    promise: exportTable,
  })

  const emptyTableConf = useEmptyTable(() =>
    onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_PRODUCT_LIST, {
      title: 'Add Products',
      assignToVendor: true,
      vendorId,
    })
  )
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IProduct>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  return (
    <>
      <TableHeader
        data={filtersList}
        setActiveFilters={handleFiltersChange}
        numberOfItems={data?.totalCount}
        filterValues={filterValues}
        nameNumberOfItems='Products'
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        onChange={handleTableChange}
        dataSource={data?.items}
        loading={isFetching}
        scroll={{ x: 1100 }}
        pagination={pagination}
        className={styles.table}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.id}
      />
    </>
  )
}

export default ProductsTable
