import { Select, Form, Spin } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { useContext, useEffect, useState } from 'react'
import PinInput from 'react-pin-input'
import { DoubleArrowRightIcon, SendPlaneIcon, TickIcon } from 'src/assets/svg'
import { RightModalContext } from 'src/features/Modals'
import { minLengthValidator, requiredValidator } from 'src/helpers/validation'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { PhoneInputWithCountry } from 'src/shared/components/PhoneInput'
import { TextField } from 'src/shared/components/textfield/TextField'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import {
  useGetVerificationTimerQuery,
  useSendVerificationMutation,
  useVerifyPhoneMutation,
} from '../../core/http/AgentManagement'
import { getSecondsFromDateInFuture } from '../../helpers'
import { CONTACT_TYPE_NEW, IDENTIFIERS_TYPE_NEW } from '../../models/IAgent'
import { CONTACT_TYPE_OPTIONS } from '../ContactInformation'

// styles
import styles from './styles.module.scss'

export const IdentifierVerificationModal = () => {
  const {
    onClose,
    props: { selectedType, selectedValue, agentId },
  } = useContext(RightModalContext)

  const [form] = Form.useForm()
  const [OTP, setOTP] = useState('')
  const [timer, setTimer] = useState(0)
  const [timeout, setTimeout] = useState(0)
  const [isResendAvailable, setResendAvailable] = useState(false)

  const { data, isFetching, refetch } = useGetVerificationTimerQuery({
    type: selectedType,
    value: selectedValue,
  })

  const [sendVerification, dSend] = useSendVerificationMutation()
  const [verifyPhone, dVerify] = useVerifyPhoneMutation()

  useNotification(NOTIFICATION_TYPES.success, dSend.isSuccess, null, () => {
    refetch()
  })
  useNotification(NOTIFICATION_TYPES.error, dSend.isError, dSend.error as ErrorNode)

  useNotification(NOTIFICATION_TYPES.success, dVerify.isSuccess, null, onClose)
  useNotification(NOTIFICATION_TYPES.error, dVerify.isError, dVerify.error as ErrorNode)

  useEffect(() => {
    form.setFieldsValue({
      type: selectedType,
      value: selectedValue,
    })
  }, [])

  useEffect(() => {
    if (!data) return
    const { resettableAt } = data

    const timerLocal = getSecondsFromDateInFuture(resettableAt)
    setTimer(Math.abs(timerLocal))

    const countdown = setInterval(() => {
      setTimer(Math.abs(timerLocal) - 1)
    }, 1000)

    if (timerLocal >= 0) {
      clearInterval(countdown)
      setTimer(0)
    }

    return () => clearInterval(countdown)
  }, [data, timer])

  useEffect(() => {
    if (!data) return
    const { timeoutAt } = data

    const timeoutLocal = getSecondsFromDateInFuture(timeoutAt)

    const countdownTimeout = setInterval(() => {
      setTimeout(Math.abs(timeoutLocal) - 1)
    }, 1000)

    if (timeoutLocal >= 0) {
      setResendAvailable(false)
      clearTimeout(countdownTimeout)
      onClose()
    }

    setResendAvailable(true)

    return () => clearInterval(countdownTimeout)
  }, [data, timeout])

  const onSendCode = async () => {
    await sendVerification({
      type: selectedType,
      value: selectedValue,
    })
  }

  const onFinish = (values: { otp: string }) => {
    verifyPhone({
      id: agentId,
      body: {
        type: selectedType,
        value: selectedValue,
        code: values.otp,
      },
    })
  }

  return (
    <div className={styles.modalContainer}>
      <Spin spinning={dSend.isLoading || isFetching}>
        <div className={styles.btnClose} onClick={onClose}>
          <DoubleArrowRightIcon />
        </div>

        <Form form={form} layout='vertical' onFinish={onFinish} className={styles.formContainer}>
          <div className={styles.title}>Please enter OTP Code sent to:</div>
          <FormItem name='type' className={styles.mb24}>
            <Select allowClear disabled>
              {CONTACT_TYPE_OPTIONS.map(({ agentIdentifier, icon }) => (
                <Select.Option key={agentIdentifier.type} value={agentIdentifier.type}>
                  <div className={styles.wrapperContentOption}>
                    <div className={styles.wrapperIcon}>{icon}</div>
                    <div>
                      {
                        CONTACT_TYPE_NEW[agentIdentifier.type as keyof typeof IDENTIFIERS_TYPE_NEW]
                          .name
                      }
                    </div>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem name='value' className={styles.mb24}>
            {selectedType === CONTACT_TYPE_NEW.MOBILE.type ? (
              <PhoneInputWithCountry view={true} fieldName='value' form={form} />
            ) : (
              <TextField disabled />
            )}
          </FormItem>
          <FormItem
            className={styles.mb24}
            name='otp'
            label='OTP'
            rules={[
              {
                validator: requiredValidator('OTP'),
              },
              {
                validator: minLengthValidator(4, 'OTP'),
              },
            ]}
          >
            <PinInput
              length={4}
              type='numeric'
              autoSelect={true}
              onChange={setOTP}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </FormItem>

          {isResendAvailable && (
            <div className={styles.timer}>{`00:${
              String(timer).length < 2 ? `0${timer}` : timer
            }`}</div>
          )}

          <div className={styles.footerContainer}>
            <IconButton size='large' color='blue' icon={<SendPlaneIcon />} onClick={onSendCode}>
              {isResendAvailable ? 'Resend Code' : 'Send Code'}
            </IconButton>
            <IconButton
              size='large'
              color='orange'
              type='primary'
              onClick={() => form.submit()}
              icon={<TickIcon />}
            >
              Confirm
            </IconButton>
          </div>
        </Form>
      </Spin>
    </div>
  )
}
