import { useCallback, useState } from 'react'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../shared/components/popup/Popup'
import { ColumnsType } from 'antd/es/table'
import { DateTableIcon, TableActionIcon } from '../../../../../../assets/svg'
import { formatDateWithTime } from '../../../../../../helpers/utils'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { TableHeader } from '../TableHeader'
import { Table } from 'antd'
import styles from '../../styles.module.scss'
import { IPromotionPolicy } from '../../../../models/IPromotionPolicy'
import {
  useDeletePromotionPolicyMutation,
  useGetPromotionPolicyFilterListQuery,
  useGetPromotionPolicyListQuery,
  useLazyExportPromotionPolicyTableQuery,
  useUpdatePromotionPolicyMutation,
} from '../../../../core/http/PromotionPolicyApi'
import { useTableExport } from '../../../../../../shared/hooks/table/useTableExport'
import { useNavigate } from 'react-router'
import { SETTINGS_ENUM } from '../../../../../../routes/settings'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ErrorNode, showConfirmMessage } from '../../../../../../shared/api/errorHandler'
import { renderTableTargetGroups } from '../../helpers/renderTableTargetGroups'
import { renderAgentGroups } from '../../helpers/renderAgentGroups'
import { Link } from 'react-router-dom'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

export const PromotionPolicyTable = () => {
  const navigate = useNavigate()
  const [patchProm, patchPromResp] = useUpdatePromotionPolicyMutation()
  const [delProm, delPromResp] = useDeletePromotionPolicyMutation()
  const { data: profile } = useGetProfileQuery()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  useNotification(NOTIFICATION_TYPES.success, patchPromResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, delPromResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, patchPromResp.isError, patchPromResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, delPromResp.isError, delPromResp.error as ErrorNode)

  const openNotification = useCallback(
    (id: number) => {
      showConfirmMessage('This promotion policy will be deleted', () => delProm(id))
    },
    [delProm]
  )

  const tableActionsPopup = useCallback(
    (id: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () =>
            navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/promotion-policy/view/${id}`),
        },
        {
          text: 'Edit',
          shouldDisplay: true,
          onClick: () =>
            navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/promotion-policy/edit/${id}`),
        },
        {
          text: 'Delete',
          shouldDisplay: true,
          withDivider: true,
          onClick: () => openNotification(id),
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        },
      ]
    },
    [navigate, openNotification]
  )

  const columns: ColumnsType<IPromotionPolicy> = [
    {
      title: 'Policy Id',
      dataIndex: 'id',
      width: '10%',
      sorter: true,
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Policy Name',
      dataIndex: 'name',
      width: '15%',
      sorter: true,
      render: (name, row) => (
        <RecursivelyReplaceCharacters>
          <Link
            to={`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/promotion-policy/view/${row.id}`}
            className='table-avatar'
          >
            {name}
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Policy Type',
      dataIndex: 'event',
      width: '15%',
      sorter: true,
      render: (ev) => <RecursivelyReplaceCharacters>{ev}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Master Product',
      dataIndex: 'promotionPolicyProducts',
      width: '15%',
      render: (_, row) =>
        renderTableTargetGroups({
          count: row?.promotionPolicyProductsTotalCount,
          products: row?.promotionPolicyProducts,
          isProduct: true,
          isPrivateMode,
        }),
    },
    {
      title: 'Starting Date',
      dataIndex: 'start',
      width: '20%',
      sorter: true,
      render: (date: IPromotionPolicy['start']) => (
        <RecursivelyReplaceCharacters>
          <div className='table-date'>
            <DateTableIcon />
            <span>{formatDateWithTime(date as string, profile?.calendar)}</span>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Expiry Date',
      dataIndex: 'end',
      width: '20%',
      sorter: true,
      render: (date: IPromotionPolicy['end']) => (
        <RecursivelyReplaceCharacters>
          <div className='table-date'>
            <DateTableIcon />
            <span>{formatDateWithTime(date as string, profile?.calendar)}</span>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Target Group',
      width: '15%',
      render: (_, row) =>
        renderAgentGroups({
          agents: row?.targetGroupAgents,
          count: +row?.targetGroupAgentsCount,
          isPrivateMode,
        }),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      sorter: true,
      render: (status: IPromotionPolicy['status'], { id }) => {
        return (
          <Toggle
            defaultChecked={status}
            checked={status}
            loading={patchPromResp.isLoading}
            onChange={(status) => {
              patchProm({ id, body: { status } })
            }}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'id',
  })
  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const [exportTable] = useLazyExportPromotionPolicyTableQuery()
  const { data, isFetching } = useGetPromotionPolicyListQuery(
    { ...filterValues, ...tableConf },
    { skip: Object.keys(tableConf).length < 4 }
  )
  const { data: filterList } = useGetPromotionPolicyFilterListQuery()

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IPromotionPolicy>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  useTableExport({
    tableConf,
    filterValues,
    promise: exportTable,
  })

  return (
    <>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        data={filterList}
        filterValues={filterValues}
      />
      <Table
        locale={emptyTableConf}
        onChange={handleTableChange}
        pagination={pagination}
        columns={columns}
        className={styles.table}
        scroll={{ x: 1000 }}
        rowKey={(record) => record.id}
        rowSelection={{ type: 'checkbox' }}
        dataSource={data?.items}
        loading={isFetching}
      />
    </>
  )
}
