import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from 'src/shared/api'
import { IAuthResponse, IBackupCode } from 'src/shared/models/IAuth'

export const twoFactorAuthApi = createApi({
  reducerPath: 'twoFactorAuth',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['BackupCodes'],
  endpoints: (builder) => ({
    googleAuthGenerate: builder.mutation<Blob, null>({
      query: () => ({
        url: 'auth/2fa/google-authenticator/generate',
        method: 'POST',
        responseHandler: (response) => response.blob(),
      }),
    }),
    googleAuthEnable: builder.mutation<IAuthResponse, { code: string }>({
      query: (body) => ({
        url: 'auth/2fa/google-authenticator/enable',
        method: 'PATCH',
        body,
      }),
    }),
    googleAuthCheck: builder.mutation<IAuthResponse, { code: string }>({
      query: (body) => ({
        url: 'auth/2fa/google-authenticator/check',
        method: 'POST',
        body,
      }),
    }),
    googleAuthForget: builder.mutation<IAuthResponse, { code: string }>({
      query: (body) => ({
        url: 'auth/2fa/google-authenticator/forget',
        method: 'PATCH',
        body,
      }),
    }),
    googleAuthDisable: builder.mutation<IAuthResponse, { code: string }>({
      query: (body) => ({
        url: 'auth/2fa/google-authenticator/disable',
        method: 'PATCH',
        body,
      }),
    }),
    getBackupCodes: builder.query<IBackupCode[], null>({
      query: () => ({
        url: 'auth/2fa/google-authenticator/backup-codes',
      }),
      providesTags: ['BackupCodes'],
    }),
    generateBackupCodes: builder.mutation<IBackupCode[], void>({
      query: () => ({
        url: 'auth/2fa/google-authenticator/backup-codes',
        method: 'POST',
      }),
      invalidatesTags: ['BackupCodes'],
    }),
  }),
})

export const {
  useGoogleAuthGenerateMutation,
  useGoogleAuthEnableMutation,
  useGetBackupCodesQuery,
  useGoogleAuthCheckMutation,
  useGoogleAuthDisableMutation,
  useGoogleAuthForgetMutation,
  useGenerateBackupCodesMutation,
  useLazyGetBackupCodesQuery,
} = twoFactorAuthApi
