import classNames from 'classnames'
import styles from '../Messaging/styles.module.scss'
import moment from 'moment/moment'
import React, {
  ChangeEvent,
  Dispatch,
  memo,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  ILastExternalChatMessage,
  IReplyToMessage,
  IUpdateExternalChatMember,
  MESSAGE_TYPE_ENUM,
} from '../../../../../../models/IExternalChat'
import { DateDelimiter } from '../DateDelimiter'
import { Dropdown, MenuProps } from 'antd'
import { ReplyTo } from '../ReplyTo'
import { CheckMarkIcon, DoubleCheckMarkIcon } from '../../../../../../../../assets/svg'
import { ImagesImMessage } from '../../../../../../../InternalChat/Components/Messaging/Components/ImagesInMessage'
import { VideosInMessage } from '../../../../../../../InternalChat/Components/Messaging/Components/VideosInMessage'
import FilesInMessage from '../../../../../../../InternalChat/Components/Messaging/Components/FilesInMessage'
import Linkify from 'linkify-react'
import { setLastReadingAtByCurrentUser } from '../../../../../../core/store/ExternalChatMessagingSlice'
import { useAppDispatch } from '../../../../../../../../redux'
import emptyUserImage from 'src/assets/img/Avatar.png'
import { externalChatApi } from '../../../../../../core/http/ExternalChatApi'
import { downloadFileThroughATag, isArabic } from '../../../../../../../../helpers/utils'
import { EmojiSelect } from '../../../../../../../../shared/components/chat/Components'
import { EmojiClickData } from 'emoji-picker-react'

interface IProps {
  currentUserId: number | null
  toScrollId: number | null
  message: ILastExternalChatMessage
  isFirstMessageInADay?: boolean
  lastReadingAt?: string
  selectToReply: (message: Partial<ILastExternalChatMessage>) => void
  scrollToReply: (reply: IReplyToMessage) => void
  updateLastReadingAt: (body: Pick<IUpdateExternalChatMember, 'lastReadingAt'>) => void
  shouldUpdateLastRead: (messDate: string) => boolean
  counter: number
  lastReadMessage?: ILastExternalChatMessage | null
  isNextMessageByTheSameUser?: boolean
  addReactionToMessage: (body: EmojiClickData, messageId: number) => void
  deleteReactionFromMessage: (messageId: number) => void
  playAudio: (event: ChangeEvent<HTMLAudioElement>) => void
  setIsEmojiPickerOpen: Dispatch<SetStateAction<boolean>>
  isOnlyOneMessageBefore?: boolean
}

const TextMessage = ({
  currentUserId,
  message,
  isFirstMessageInADay,
  selectToReply,
  scrollToReply,
  lastReadingAt,
  updateLastReadingAt,
  toScrollId,
  shouldUpdateLastRead,
  counter,
  lastReadMessage,
  isNextMessageByTheSameUser,
  addReactionToMessage,
  deleteReactionFromMessage,
  playAudio,
  setIsEmojiPickerOpen,
  isOnlyOneMessageBefore,
}: IProps) => {
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false)
  const messageRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useAppDispatch()

  const imagesFiles = useMemo(
    () =>
      message?.externalChatMessageFiles
        ?.filter((item) => item.fileType.toLowerCase() === 'photo')
        .map((el) => ({ ...el, source: el.file.link })),
    [message]
  )

  const videoFiles = useMemo(
    () =>
      message?.externalChatMessageFiles?.filter((item) => item.fileType.toLowerCase() === 'video'),
    [message]
  )
  const voiceMessages = useMemo(
    () => message.externalChatMessageFiles?.filter((item) => item?.file?.fileFormat === '.ogg'),
    [message]
  )
  const otherFiles = useMemo(
    () =>
      message?.externalChatMessageFiles?.filter(
        (item) =>
          !['photo', 'video'].includes(item.fileType.toLowerCase()) &&
          item?.file?.fileFormat !== '.ogg'
      ),
    [message]
  )

  const isMyMessage = useMemo(
    () => message?.createdByUserId === currentUserId,
    [currentUserId, message?.createdByUserId]
  )

  const isReadByExternal = useMemo(() => {
    return lastReadingAt
      ? moment(lastReadingAt).isAfter(moment(message?.createdAt)) ||
          moment(lastReadingAt).isSame(moment(message?.createdAt))
      : false
  }, [lastReadingAt, message?.createdAt])

  const isReassignMessage = useMemo(() => {
    return message?.messageType === MESSAGE_TYPE_ENUM.REASSIGNED
  }, [message?.messageType])

  const linkProps = {
    onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault()
      const target = e.target as HTMLAnchorElement
      window.open(target.href, '_blank')?.focus()
    },
  }

  const getMessageLink = useCallback(
    async (id: number) => {
      const data = await dispatch(
        externalChatApi.endpoints.getExternalChatLinkToDownloadFile.initiate(id)
      )

      return data?.data?.link || ''
    },
    [dispatch]
  )

  const [voiceMessageLinks, setVoiceMessageLinks] = useState<Array<string>>([])

  const messageActions: MenuProps['items'] = useMemo(
    () => [
      {
        key: message?.id,
        label: (
          <div
            className={styles.messageActionItem}
            onClick={() => {
              selectToReply({
                id: message?.id,
                message: message?.message,
                externalChatMessageFiles: message.externalChatMessageFiles,
              })
              setIsActionMenuOpen(false)
              setIsEmojiPickerOpen(false)
            }}
          >
            Reply
          </div>
        ),
      },
      ...(message.messageType !== MESSAGE_TYPE_ENUM.PRIVATE_NOTE && !isReassignMessage
        ? [
            {
              key: `${message.id}-reaction`,
              label: (
                <div onClick={(e) => e.stopPropagation()}>
                  <EmojiSelect
                    onSelectEmoji={(e) => {
                      addReactionToMessage(e, message.id)
                      setIsEmojiPickerOpen((prevState) => !prevState)
                      setIsActionMenuOpen((prevState) => !prevState)
                    }}
                    emojiOpenText='Add Reaction'
                    handleClick={setIsEmojiPickerOpen}
                  />
                </div>
              ),
            },
          ]
        : []),
      ...(message?.externalChatMessageFiles?.length
        ? [
            {
              key: `${message?.id}-file`,
              label: (
                <div
                  className={styles.messageActionItem}
                  onClick={() =>
                    dispatch(
                      externalChatApi.endpoints.getExternalChatLinkToDownloadFile.initiate(
                        message?.externalChatMessageFiles[0]?.file?.id
                      )
                    ).then(({ data }) => {
                      if (data?.link) {
                        downloadFileThroughATag(
                          data.link,
                          message?.externalChatMessageFiles[0]?.file?.name
                        )
                      }
                    })
                  }
                >
                  Download
                </div>
              ),
            },
          ]
        : []),
    ],
    [dispatch, message, selectToReply]
  )

  useEffect(() => {
    if (Number(toScrollId) === message.id) {
      setTimeout(() => {
        if (messageRef.current) {
          messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
      }, 500)
    }
  }, [message.id, toScrollId])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            !isMyMessage &&
            messageRef.current &&
            counter > 0 &&
            (!shouldUpdateLastRead(message.createdAt) || isOnlyOneMessageBefore)
          ) {
            if (entry.isIntersecting) {
              dispatch(setLastReadingAtByCurrentUser(message.createdAt))
              updateLastReadingAt({ lastReadingAt: message.createdAt })
            }
          }
        })
      },
      {
        root: null,
        threshold: 1,
      }
    )

    if (messageRef.current) {
      observer.observe(messageRef.current)
    }

    return () => {
      if (messageRef.current) {
        observer.unobserve(messageRef.current)
      }
    }
  }, [isMyMessage, counter])

  useEffect(() => {
    const fetchVoiceMessageLinks = async () => {
      if (!voiceMessages?.length) return
      const links = await Promise.all(
        voiceMessages?.map(async (voice) => {
          return await getMessageLink(voice?.fileId)
        })
      )
      setVoiceMessageLinks(links)
    }

    fetchVoiceMessageLinks()
  }, [voiceMessages, getMessageLink])

  return (
    <>
      {lastReadMessage?.id === message?.id && (
        <div className={classNames(styles.chatroomMessage, styles.joinMessage)}>
          Unread Messages
        </div>
      )}
      <Dropdown
        menu={{ items: messageActions }}
        trigger={['contextMenu']}
        placement='bottomRight'
        overlayClassName={styles.contextMenuContainer}
        open={isActionMenuOpen}
        onOpenChange={(vis) => {
          setIsActionMenuOpen(vis)
          setIsEmojiPickerOpen(vis)
        }}
      >
        <div
          id={`external-chat-message-${message.id}`}
          onClick={(e) => {
            e.preventDefault()
          }}
          className={classNames(styles.chatroomMessage, {
            [styles.myMessage]:
              !message?.isMessageFromClinet && message.messageType !== MESSAGE_TYPE_ENUM.REASSIGNED,
            [styles.joinMessage]: isReassignMessage,
            [styles.privateMessage]: message.messageType === MESSAGE_TYPE_ENUM.PRIVATE_NOTE,
            [styles.extUserMessage]: !isMyMessage && !isNextMessageByTheSameUser,
            [styles.withoutBackground]: voiceMessageLinks.length,
          })}
          ref={messageRef}
        >
          <div className={styles.chatroomMessageInner}>
            <div>
              {message?.createdByUser && !isMyMessage && (
                <div className={styles.messageUserName}>{message?.createdByUser?.name}</div>
              )}
              {message?.replyToMessage ? (
                <ReplyTo
                  replyToMessage={message?.replyToMessage as IReplyToMessage}
                  scrollToMessage={scrollToReply}
                />
              ) : null}
              {!!voiceMessageLinks?.length &&
                voiceMessageLinks?.map((link: string) => (
                  <audio key={message.id} controls onPlay={playAudio}>
                    <source src={link} />
                  </audio>
                ))}
              {!!videoFiles?.length && <VideosInMessage videos={videoFiles} />}
              {!!otherFiles?.length && <FilesInMessage files={otherFiles} />}
              {!!imagesFiles?.length && <ImagesImMessage images={imagesFiles} />}
              <Linkify options={{ attributes: linkProps }}>
                <div
                  className={classNames(styles.messageText, {
                    [styles.arabic]: isArabic(message?.message),
                  })}
                >
                  {message?.message}
                </div>
              </Linkify>
            </div>
            {!isReassignMessage && (
              <div className={styles.messageTime}>
                <span>{moment(message?.createdAt).format('HH:mm')}</span>
                <span>
                  {isMyMessage &&
                    message.messageType !== MESSAGE_TYPE_ENUM.PRIVATE_NOTE &&
                    (isReadByExternal ? (
                      <DoubleCheckMarkIcon color='#fff' height={9} width={17} />
                    ) : (
                      <CheckMarkIcon color='#fff' height={9} width={17} />
                    ))}
                </span>
              </div>
            )}
          </div>
          {!isNextMessageByTheSameUser && message?.createdByUser && !isMyMessage && (
            <img
              alt='user-logo'
              loading='lazy'
              src={message?.createdByUser?.logo ?? emptyUserImage}
              className={styles.userLogoImage}
            />
          )}
          {!!message?.reactions?.length && (
            <div
              className={classNames(styles.reactions, {
                [styles.oneReaction]: message.reactions.length === 1,
              })}
            >
              {message?.reactions?.map((react) => (
                <span
                  key={`${message?.id}-${react?.emoji}-${react?.isFromMessenger}`}
                  className={classNames({ [styles.aminPayEmoji]: !react.isFromMessenger })}
                  onClick={() => !react.isFromMessenger && deleteReactionFromMessage(message.id)}
                >
                  {react?.emoji}
                </span>
              ))}
            </div>
          )}
        </div>
      </Dropdown>
      {isFirstMessageInADay && <DateDelimiter createdAt={message?.createdAt} />}
    </>
  )
}

export default memo(TextMessage)
