import { useContext } from 'react'

//components
import { CenterModalContext } from 'src/features/Modals'

// styles
import styles from './styles.module.scss'

export const ConfirmModal = () => {
  const {
    props: { content },
  } = useContext(CenterModalContext)
  return (
    <div className={styles.container}>
      <div>{content}</div>
    </div>
  )
}
