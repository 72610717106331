import styles from '../../AgentManagementDetailView.module.scss'
import { Widget } from '../../../../../../shared/components/widget'
import {
  BalanceIcon,
  DatePickerIcon,
  MobileScreenShareIcon,
  TrendingArrowUpIcon,
} from '../../../../../../assets/svg'
import { WIDGET_LIST_ENUM } from '../../../../../../shared/models/WidgetListEnum'
import React from 'react'
import { AgentAnalyticsTypeEnum } from '../index'
import { Moment } from 'moment/moment'
import { RangePicker } from '../../../../../../shared/components/datePicker'

interface IProps {
  widgetData: AgentAnalyticsTypeEnum
}

export const Widgets = ({ widgetData }: IProps) => {
  return (
    <div className={styles.widgetWrapper}>
      <Widget
        icon={<TrendingArrowUpIcon />}
        title='Generated Profit'
        width={33}
        value={widgetData[WIDGET_LIST_ENUM.GENERATED_PROFIT].value}
        statistic={widgetData[WIDGET_LIST_ENUM.GENERATED_PROFIT].value}
      />
      <Widget
        icon={<BalanceIcon />}
        title='Average Pickup Per Day'
        width={33}
        value={widgetData[WIDGET_LIST_ENUM.AVERAGE_PICKUP_PER_DAY].value}
        statistic={widgetData[WIDGET_LIST_ENUM.AVERAGE_PICKUP_PER_DAY].value}
      />
      <Widget
        icon={<MobileScreenShareIcon />}
        title='Average Daily Topup'
        width={33}
        value={widgetData[WIDGET_LIST_ENUM.AVERAGE_DAILY_TOPUP].value}
        statistic={widgetData[WIDGET_LIST_ENUM.AVERAGE_DAILY_TOPUP].statistic}
      />
    </div>
  )
}
