import styles from '../../styles.module.scss'
import { Widget } from '../../../../../../shared/components/widget'
import {
  BalanceIcon,
  LocationIcon,
  MonetizationWidgetIcon,
  UserGroupRounded,
} from '../../../../../../assets/svg'
import { WIDGET_LIST_ENUM } from '../../../../../../shared/models/WidgetListEnum'
import React from 'react'
import { DashboardWidgetStateType } from '../../index'

interface IProps {
  widgetData: DashboardWidgetStateType
}

export const DashboardWidgets = ({ widgetData }: IProps) => {
  return (
    <div className={styles.widgetsWrapper}>
      <Widget
        icon={<MonetizationWidgetIcon />}
        title='Total Sales'
        color='darkBlue'
        width={35}
        value={widgetData[WIDGET_LIST_ENUM.TOTAL_SALES]?.value}
        statistic={widgetData[WIDGET_LIST_ENUM.TOTAL_SALES]?.statistic}
      />
      <Widget
        icon={<LocationIcon />}
        title='Active Location'
        value={widgetData[WIDGET_LIST_ENUM.ACTIVE_LOCATIONS]?.value}
        valueTotal={widgetData[WIDGET_LIST_ENUM.ACTIVE_LOCATIONS]?.totalValue}
        statistic={widgetData[WIDGET_LIST_ENUM.ACTIVE_LOCATIONS]?.statistic}
        width={25}
      />
      <Widget
        icon={<BalanceIcon />}
        title='Inventory'
        value={widgetData[WIDGET_LIST_ENUM.INVENTORY]?.value}
        statistic={widgetData[WIDGET_LIST_ENUM.INVENTORY]?.statistic}
        width={25}
      />
      <Widget
        icon={<UserGroupRounded />}
        title='Active Sales Point'
        value={widgetData[WIDGET_LIST_ENUM.ACTIVE_AGENTS]?.value}
        valueTotal={widgetData[WIDGET_LIST_ENUM.ACTIVE_AGENTS]?.totalValue}
        width={25}
        statistic={widgetData[WIDGET_LIST_ENUM.ACTIVE_AGENTS]?.statistic}
      />
    </div>
  )
}
