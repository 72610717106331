import styles from '../../styles.module.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { ColumnsType } from 'antd/es/table'
import { IVendorBalanceTable } from '../../../../../Settings/models/IVendorManagement'
import { Table } from 'antd'
import emptyAvatarImage from '../../../../../../assets/img/ProductTablePlaceholder.png'
import { ITableConf } from '../../../../../../shared/models/ITableConf'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { useTablePagination } from '../../../../../../shared/hooks/table/useTablePagination'
import { useOnTableChange } from '../../../../../../shared/hooks/table/useOnTableChange'
import { formatNumberToLocale } from '../../../../../salesNetwork/helpers'
import { formatDateWithTime, tableExpandedIcon } from '../../../../../../helpers/utils'
import { DashboardTableWrapper } from '../DashboardTableWrapper'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { ITableResponse } from '../../../../../../shared/models/ITableResponse'
import classNames from 'classnames'
import { FINANCE_ENUM } from '../../../../../../routes/finance'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'

interface IProps {
  data?: ITableResponse<IVendorBalanceTable>
}

export const VendorBalance = ({ data }: IProps) => {
  const navigate = useNavigate()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const { data: profile } = useGetProfileQuery()
  const [, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 5,
  })

  const columns: ColumnsType<IVendorBalanceTable> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '35%',
      render: (companyName: IVendorBalanceTable['name'], row) => (
        <RecursivelyReplaceCharacters>
          <Link
            to={
              row?.inventoryId
                ? `${FINANCE_ENUM.TRUST_WALLET}/vendor/${row?.id}/inventory/view/${row?.inventoryId}`
                : 'javascript:void(0)'
            }
            className='table-avatar'
          >
            <img
              alt='vendor logo'
              src={!isPrivateMode && row?.logo ? row?.logo : emptyAvatarImage}
              loading='lazy'
            />
            <div>{companyName}</div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Current Balance',
      dataIndex: 'currentBalance',
      width: '30%',
      render: (currentBalance, row) => (
        <RecursivelyReplaceCharacters>
          <span
            className={classNames({
              [styles.redColor]: Number(currentBalance) <= Number(row?.thresholdAmount),
            })}
          >
            {formatNumberToLocale(currentBalance)}
          </span>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Last Purchase',
      dataIndex: 'lastPO',
      align: 'center',
      width: '35%',
      render: (lastPurchase) => (
        <RecursivelyReplaceCharacters>
          {formatDateWithTime(lastPurchase, profile?.calendar)}
        </RecursivelyReplaceCharacters>
      ),
    },
  ]

  const emptyTableConf = useEmptyTable(() => navigate('new'))
  const paginationConfig = useTablePagination(data?.totalCount, false, 5)
  const onTableParamsChange = useOnTableChange<IVendorBalanceTable>(setTableConf)

  return (
    <div className={styles.fixedTableHeight}>
      <DashboardTableWrapper title='Vendor Balance' isBlue>
        <div className={styles.tableAutoScroll}>
          <Table
            columns={[
              {
                title: '',
                dataIndex: '',
                key: 'expand',
                align: 'center',
                width: '1%',
              },
              ...columns,
            ]}
            locale={emptyTableConf}
            onChange={onTableParamsChange}
            className={styles.vendorBalanceTable}
            dataSource={data?.items}
            pagination={paginationConfig}
            rowKey={(record) => record.id}
            expandable={{
              expandIcon: (props) => {
                if (!props.record?.children?.length) return null
                return tableExpandedIcon<IVendorBalanceTable>(props)
              },
            }}
          />
        </div>
      </DashboardTableWrapper>
    </div>
  )
}
