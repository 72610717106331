/* eslint-disable */
import React, { useState } from 'react'
import { Col, Form, Row, UploadFile } from 'antd'
import FormItem from 'antd/es/form/FormItem'

import styles from './AgentManagementNewAgent.module.scss'
import * as C from '../../components'
import { useCreateAgentMutation } from 'src/features/salesNetwork/core/http/AgentManagement'
import { Button } from 'src/shared/components/button/Button'
import { useNavigate } from 'react-router-dom'
import { UploaderPhotos2 } from 'src/shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import { RcFile } from 'antd/lib/upload'

import { CONTACT_TYPE_NEW, IDENTIFIERS_TYPE_NEW } from 'src/features/salesNetwork/models/IAgent'
import { isEmpty, omit } from 'lodash'
import { ErrorNode } from 'src/shared/api/errorHandler'

import { StepRegistration } from 'src/shared/components/stepRegistration'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { AGENT_TYPE_ID_ENUM } from 'src/features/Settings/models/IAgentType'

export const AgentManagementNewAgent = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const [isImageError, setIsImageError] = useState(false)
  const [agentStatus, setAgentStatus] = useState(true)
  const [avatarFile, serAvatarFile] = useState<RcFile>()

  const [createAgent, { isSuccess, isError, error }] = useCreateAgentMutation()

  useNotification(NOTIFICATION_TYPES.error, isError, error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.success, isSuccess, null, () =>
    navigate('/sales-network/agent-management')
  )

  const onFinish = (values: typeof initialValues) => {
    const res = {
      ...omit(
        values,
        'parentId',
        'accountType',
        'agentIdentifiers',
        'resellerCredentials',
        'districtId',
        'provinceId'
      ),
      status: agentStatus,
      contactInfo: values.contactInfo.map((i) => ({ ...(i as Object), active: true })),
      ...(values.parentId ? { parentId: +values.parentId } : {}),
      ...(values?.accountType?.id ? { accountTypeId: values.accountType?.id } : {}),
      ...(values.districtId ? { districtId: values.districtId } : {}),
      ...(values.provinceId ? { provinceId: values.provinceId } : {}),
      ...(values?.resellerCredentials?.password && {
        password: values.resellerCredentials.password,
      }),
      ...(values?.resellerCredentials?.value && {
        resellerCredentials: {
          ...omit(values.resellerCredentials, 'value'),
          email: values.resellerCredentials.value,
          isWhitelist: !!values.resellerCredentials.isWhitelist,
          username: values.resellerCredentials.username,
        },
      }),
      ...(!isEmpty(values.agentIdentifiers) && {
        agentIdentifiers: values.agentIdentifiers.map((i) => ({
          ...(i as Object),
          active: true,
        })),
      }),
    }

    const fd = new FormData()

    fd.append('agent', JSON.stringify(res))
    fd.append('avatar', avatarFile!)

    createAgent(fd)
  }

  const initialValues = {
    parent: null,
    parentId: null,
    parentName: '',
    profileID: '',
    registrationDate: '',
    agentTypeId: null,
    agentLevelId: null,
    districtId: null,
    provinceId: null,
    countryId: null,
    calendar: null,
    accountType: {
      id: null,
    },
    contactInfo: [
      {
        type: CONTACT_TYPE_NEW.MOBILE.type,
      },
    ],
    agentIdentifiers: [
      {
        type: IDENTIFIERS_TYPE_NEW.MOBILE.type,
        value: null,
      },
    ],
    resellerCredentials: {
      ips: [''],
      email: IDENTIFIERS_TYPE_NEW.EMAIL.type,
      value: '',
      isWhitelist: true,
      username: '',
      password: '',
    },
  }

  return (
    <>
      <main className={styles['agent-management-new-agent']}>
        <Form form={form} layout='vertical' initialValues={initialValues} onFinish={onFinish}>
          <StepRegistration stepNumber='1' stepText='Select Parent Account' mB='32px' />
          <Row wrap={false} justify='start'>
            <Col className={styles['wrapper-column']}>
              <C.ParentAccount form={form} />

              <StepRegistration stepNumber='2' stepText='Account information' mB='32px' />
              <C.AccountType
                setAgentStatus={setAgentStatus}
                agentStatus={agentStatus}
                create
                form={form}
              />

              <StepRegistration stepNumber='3' stepText='General information' mB='32px' />
              <C.DetailInfo />

              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const agentTypeId = getFieldValue('agentTypeId')
                  const isReseller = agentTypeId === AGENT_TYPE_ID_ENUM.RESELLER

                  const title = isReseller ? 'Add Reseller Credentials' : 'Add User Identifiers'
                  return (
                    <>
                      <StepRegistration stepNumber='4' stepText={title} mB='32px' />
                      {isReseller ? (
                        <C.ResellerCredentials form={form} />
                      ) : (
                        <C.UserIdentifiers form={form} />
                      )}
                    </>
                  )
                }}
              </FormItem>

              <StepRegistration stepNumber='5' stepText='Add Contact information' mB='32px' />
              <C.ContactInformation form={form} />
              <C.Address form={form} />
            </Col>
            <div>
              <UploaderPhotos2
                form={form}
                title='Profile Picture'
                view={false}
                onChange={(photo: UploadFile[]) => {
                  serAvatarFile(photo[0].originFileObj)
                  setIsImageError(
                    form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                  )
                }}
              />
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const agentType = getFieldValue('agentTypeId')
                  const isWhitelist = getFieldValue(['resellerCredentials', 'isWhitelist'])

                  if (agentType === AGENT_TYPE_ID_ENUM.RESELLER) {
                    return <C.IPBlock isWhitelist={isWhitelist} form={form} />
                  }
                }}
              </FormItem>
            </div>
          </Row>
        </Form>
      </main>
      <Row className={styles['wrapper-footer-buttons']} justify='end'>
        <Button
          color='blue'
          onClick={() => navigate('/sales-network/agent-management')}
          size='middle'
        >
          Cancel
        </Button>

        <Button
          color='blue'
          htmlType='submit'
          onClick={() => form.submit()}
          size='middle'
          type='primary'
          disabled={isImageError}
        >
          Create Agent
        </Button>
      </Row>
    </>
  )
}
