import { Filter, ISelectedFilters } from '../../../../../../../shared/components/filter/Filter'
import { IFilterFields } from '../../../../../../../shared/models/IFilterFields'
import { useFilter } from '../../../../../../../shared/hooks/filter/useFilter'
import { Col, Row } from 'antd'
import { formatNumberToLocale } from '../../../../../../salesNetwork/helpers'
import { FilterPopup } from '../../../../../../../shared/components/filter/FilterPopup'

interface IProps {
  numberOfItems?: number
  setActiveFilters: (values: ISelectedFilters) => void
  filterValues: ISelectedFilters
  data?: IFilterFields[]
  isMasterProducts?: boolean
  numberText?: string
}

export const TableHeader = ({
  setActiveFilters,
  filterValues,
  data,
  numberOfItems = 0,
  numberText = 'Target Groups',
}: IProps) => {
  const { isOpen, onCancel, form, onReset, onFinish } = useFilter({ setActiveFilters })

  return (
    <>
      <Row align='middle' justify='space-between' style={{ marginBottom: 20 }}>
        <Col>
          <Filter
            data={data}
            form={form}
            setIsOpen={onCancel}
            selectedFilters={filterValues}
            setSelectedFilters={setActiveFilters}
          />
        </Col>
        <Col className='totalQuantity'>
          <span>{formatNumberToLocale(numberOfItems)}</span> {numberText}
        </Col>
      </Row>
      {isOpen && (
        <FilterPopup
          data={data}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          form={form}
          onCancel={onCancel}
          onReset={onReset}
          onFinish={onFinish}
        />
      )}
    </>
  )
}
