import styles from '../styles.module.scss'
import { Col, Form, Row, Select, Space } from 'antd'
import { CloseIcon } from '../../../../../../assets/svg'
import FormItem from 'antd/es/form/FormItem'
import { requiredValidator } from '../../../../../../helpers/validation'
import { TextField } from '../../../../../../shared/components/textfield/TextField'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import {
  useGetBankByIdQuery,
  useLazyGetBankByIdQuery,
} from '../../../../../Finance/core/http/BankApi'
import { DefaultOptionType } from 'rc-select/lib/Select'
import { ITableResponse } from '../../../../../../shared/models/ITableResponse'
import { IBankTable } from '../../../../../Finance/models/IBank'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'
import React from 'react'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

interface IProps extends IViewMode {
  name: number
  remove: (index: number | number[]) => void
  currenciesMap?: DefaultOptionType[]
  banks?: ITableResponse<IBankTable>
}

export const BankAccountListItem = ({ view, name, remove, banks, currenciesMap }: IProps) => {
  const fieldName = ['bankAccounts', name, 'bank', 'id']
  const form = useFormInstance()

  const [getBr, getBrResp] = useLazyGetBankByIdQuery()
  const { data } = useGetBankByIdQuery(form.getFieldValue(fieldName), {
    skip: !form.getFieldValue(fieldName),
  })

  return (
    <div className={styles.paymentRow}>
      <Row justify='space-between' className={styles.paymentHeader}>
        <Col>Bank Account #{name + 1}</Col>
        <Col>
          <Space size={10}>
            <Form.Item
              name={[name, 'active']}
              style={{ flexBasis: '10%' }}
              className='noBottomMargin'
              valuePropName='checked'
            >
              <Toggle disabled={view} />
            </Form.Item>
            {!view && (
              <Col
                onClick={() => {
                  remove(name)
                }}
              >
                <CloseIcon />
              </Col>
            )}
          </Space>
        </Col>
      </Row>

      <div className={styles.dblRow}>
        <FormItem noStyle shouldUpdate>
          {({ setFieldValue, getFieldValue }) => {
            const isBankSelected = getFieldValue(['bankAccounts', name, 'bank', 'id'])

            return (
              <>
                <FormItem
                  label='Bank'
                  name={[name, 'bank', 'id']}
                  rules={[{ required: true, validator: requiredValidator('Bank') }]}
                >
                  <Select
                    allowClear
                    placeholder='Select Bank'
                    disabled={view}
                    onChange={(id) => {
                      setFieldValue(['bankAccounts', name, 'bankBranch', 'id'], null)
                      getBr(id)
                    }}
                  >
                    {banks?.items.map((bank) => (
                      <Select key={bank.id} value={bank.id}>
                        {bank.name}
                      </Select>
                    ))}
                  </Select>
                </FormItem>

                <FormItem
                  label='Branch Name'
                  name={[name, 'bankBranch', 'id']}
                  rules={[{ required: true, validator: requiredValidator('Branch') }]}
                >
                  <Select allowClear placeholder='Select Branch' disabled={view || !isBankSelected}>
                    {(getBrResp.data || data)?.bankBranches.map((branch) => (
                      <Select key={branch.id} value={branch.id}>
                        {branch.name}
                      </Select>
                    ))}
                  </Select>
                </FormItem>
              </>
            )
          }}
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem
          label='Account Numbers'
          name={[name, 'number']}
          rules={[{ required: true, validator: requiredValidator('Account Numbers') }]}
        >
          <TextField disabled={view} placeholder='Enter Account Numbers' />
        </FormItem>

        <FormItem
          label='Account Name'
          name={[name, 'name']}
          rules={[{ required: true, validator: requiredValidator('Bank Name') }]}
        >
          <TextField disabled={view} placeholder='Enter Account Name' />
        </FormItem>
      </div>

      <FormItem
        label='Currency'
        name={[name, 'currencyId']}
        rules={[{ required: true, validator: requiredValidator('Currency') }]}
      >
        <Select allowClear placeholder='Select Currency' disabled={view} options={currenciesMap} />
      </FormItem>
    </div>
  )
}
