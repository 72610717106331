import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import FormItem from 'antd/es/form/FormItem'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import { Select, Spin } from 'antd'
import { useGetMasterProductsListQuery } from '../../../../core/http/VendorManagementApi'
import { requiredValidator } from '../../../../../../helpers/validation'
import { useGetIntegrationListQuery } from '../../../../core/http/IntegrationsApi'
import { MASTER_PRODUCT_TYPE_ENUM } from '../../../../models/IProduct'

export const ProductConfiguration = ({ view }: IViewMode) => {
  const { data: masterProducts, isFetching: isFetchingMasters } = useGetMasterProductsListQuery({})
  const { data: integrations, isFetching: isFetchingIntegrations } = useGetIntegrationListQuery({})

  const content = (
    <Wrapper title='Product Configuration'>
      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const productType = getFieldValue('type') as MASTER_PRODUCT_TYPE_ENUM

          return (
            <FormItem
              name={['masterProduct', 'id']}
              label='Assign Product to Master Product'
              rules={[
                {
                  required: true,
                  validator: requiredValidator('Master Product'),
                },
              ]}
            >
              <Select disabled={view || !productType} placeholder='Master Product Name' allowClear>
                {masterProducts?.items
                  ?.filter((master) => {
                    if (master.type === MASTER_PRODUCT_TYPE_ENUM.MASTER_BUNDLE_TOPUP) {
                      return master
                    }
                    if (
                      productType === MASTER_PRODUCT_TYPE_ENUM.TOPUP ||
                      productType === MASTER_PRODUCT_TYPE_ENUM.E_PIN
                    ) {
                      return master.type === MASTER_PRODUCT_TYPE_ENUM.TOPUP
                    }
                    if (productType === MASTER_PRODUCT_TYPE_ENUM.BUNDLE_TOPUP) {
                      return master.type === MASTER_PRODUCT_TYPE_ENUM.BUNDLE_TOPUP
                    }
                  })
                  .map((masterProduct) => (
                    <Select.Option key={masterProduct.id} value={masterProduct.id}>
                      {masterProduct?.name}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
          )
        }}
      </FormItem>

      <FormItem
        name={['productIntegration', 'id']}
        label='Assign Integration'
        className='noBottomMargin'
      >
        <Select disabled={view} placeholder='Integration Name' allowClear>
          {integrations?.items.map((integration) => (
            <Select.Option key={integration.id} value={integration.id}>
              {integration?.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    </Wrapper>
  )

  return isFetchingIntegrations || isFetchingMasters ? <Spin>{content}</Spin> : content
}
