import styles from '../../styles.module.scss'
import { AlertInfoIcon } from '../../../../../../../assets/svg'

export const InfoMessage = ({ text = 'Contacts', isAccount = true }) => {
  return (
    <div className={styles.wrapperInfoMessage}>
      <div className={styles.infoMessage}>
        <div className={styles.wrapperAlertInfoIcon}>
          <AlertInfoIcon />
        </div>
        <div className={styles.infoText}>Select {isAccount ? `${text} Account` : text}</div>
      </div>
    </div>
  )
}
