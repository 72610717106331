import { Col, Form, Row, Spin, UploadFile } from 'antd'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styles from '../AgentManagementDetailView.module.scss'
import * as C from '../../../components'
import {
  agentManagementApi,
  useGenerateApiKeyMutation,
  useGetAgentByIdAQuery,
  useGetAgentByIdQuery,
  useLazyGetAgentByIdQuery,
  useResetAgentMpinMutation,
  useUpdateAgentContactInfoMutation,
  useUpdateAgentIdentifiersMutation,
  useUpdateAgentMutation,
} from 'src/features/salesNetwork/core/http/AgentManagement'
import { UploaderPhotos2 } from 'src/shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import HeaderCard from 'src/features/salesNetwork/components/HeaderCard'
import { RcFile } from 'antd/lib/upload'
import { MessageIcon, ReplayCircleRounded, SwapArrowsIcon } from 'src/assets/svg'
import {
  IAgentAll,
  IAgentCreate,
  IContactInfoResponse,
  IDENTIFIERS_TYPE_NEW,
} from 'src/features/salesNetwork/models/IAgent'
import { Button } from 'src/shared/components/button/Button'

import { customAlphabet } from 'nanoid'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import ShowMessage, { ErrorNode, showConfirmMessage } from 'src/shared/api/errorHandler'
import moment from 'moment'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import { formatNumberToLocale } from '../../../helpers'
import { isEmpty, omit } from 'lodash'
import { IContactChanel, IMutateUserContacts } from 'src/features/Settings/models/IUser'
import { CenterModalContext, RightModalContext } from 'src/features/Modals'
import { RIGHT_MODALS } from 'src/helpers/contants'
import { SALES_ENUM } from '../../../../../routes/sales'
import { AGENT_TYPE_ID_ENUM } from 'src/features/Settings/models/IAgentType'
import {
  generateAvailableOptions,
  SendMessageBlock,
} from 'src/features/salesNetwork/components/SendMessageBlock'
import HeaderDoubleCard from 'src/features/salesNetwork/components/HeaderDoubleCard'
import { useDispatch } from 'react-redux'
import { AgentManagementDetailViewTabsList } from '../index.d'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'
import { formatDateWithTime } from 'src/helpers/utils'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

const nanoid = customAlphabet('1234567890', 18)
const customMpin = customAlphabet('1234567890', 4)

interface IProps {
  canViewAgentDetail: boolean
  canViewCurrentBalance: boolean
  canViewParentAccount: boolean
  canEditAgentDetail: boolean
  canViewContactInfo: boolean
  canEditContactInfo: boolean
  canViewAddress: boolean
  canEditAddress: boolean
  canViewIdentifier: boolean
  canEditIdentifier: boolean
  canViewCreditScore: boolean
  canViewMPIN: boolean
  canEditMPIN: boolean
  canEditContent: boolean
  isSalesNetworkTab?: boolean
}

export const GeneralInformationTab = ({
  canViewAgentDetail,
  canViewCurrentBalance,
  canViewParentAccount,
  canEditAgentDetail,
  canViewContactInfo,
  canEditContactInfo,
  canViewAddress,
  canEditAddress,
  canViewIdentifier,
  canEditIdentifier,
  canViewCreditScore,
  canViewMPIN,
  canEditMPIN,
  canEditContent,
  isSalesNetworkTab = false,
}: IProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { agentId } = useParams()
  const { mode } = useParams()
  const { onOpen } = useContext(RightModalContext)
  const { onClose } = useContext(CenterModalContext)
  const { data: profile } = useGetProfileQuery()

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const [updateAgent, resUpdAgent] = useUpdateAgentMutation()
  const [updAgentContacts, resUpdAgentContacts] = useUpdateAgentContactInfoMutation()
  const [updAgentIdentifiers, resUpdAgentIdentifiers] = useUpdateAgentIdentifiersMutation()

  const [updMpin, updMpinResp] = useResetAgentMpinMutation()
  const [generateApiKey, resGenerateApiKey] = useGenerateApiKeyMutation()

  const { data: dataAgent, isFetching } = useGetAgentByIdQuery(agentId, { skip: !agentId })
  // Used To Fix Bug with Address block
  const { data: dataAgen } = useGetAgentByIdAQuery(agentId, {
    skip: !agentId,
  })
  const [identifierToDelete, setIdentifierToDelete] = useState<Array<number>>([])
  const [isLogoDelete, setIsLogoDelete] = useState(false)
  const [keyUploader, setKeyUploader] = useState(nanoid())
  const [avatarFile, serAvatarFile] = useState<RcFile | string | undefined>(dataAgent?.avatar)
  const [mpin, setMpin] = useState('')

  const [getAgentById] = useLazyGetAgentByIdQuery()
  const [agentParentFirstName, setagentParentFirstName] = useState('')
  const [agentParentLastName, setagentParentLastName] = useState('')
  useEffect(() => {
    if (!dataAgent?.parentId || agentParentFirstName) return
    getAgentById(dataAgent?.parentId).then((response) => {
      const data = response.data
      setagentParentFirstName((prev) => prev + data?.firstName)
      setagentParentLastName((prev) => prev + data?.lastName)
    })
  }, [dataAgent])

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: AgentManagementDetailViewTabsList.GENERAL_INFORMATION,
  })
  const handleParentClick = (agentId: number) => () => {
    navigate(`/sales-network/agent-management/view/${agentId}`)
    setActiveTab(AgentManagementDetailViewTabsList.GENERAL_INFORMATION)
  }

  useNotification(NOTIFICATION_TYPES.error, resUpdAgent.isError, resUpdAgent.error as ErrorNode)
  useNotification(
    NOTIFICATION_TYPES.error,
    resUpdAgentContacts.isError,
    resUpdAgentContacts.error as ErrorNode
  )
  useNotification(
    NOTIFICATION_TYPES.error,
    resUpdAgentIdentifiers.isError,
    resUpdAgentIdentifiers.error as ErrorNode
  )

  // RESELLER TYPE
  useNotification(
    NOTIFICATION_TYPES.success,
    dataAgent?.agentType.id === AGENT_TYPE_ID_ENUM.RESELLER &&
      resUpdAgent.isSuccess &&
      resUpdAgentContacts.isSuccess,
    null,
    () => {
      dispatch(
        agentManagementApi.util.invalidateTags([
          'IAgentAllGetResponse',
          'IFilterFields',
          'IAllTableResponse',
        ])
      )
      navigate(`/sales-network/agent-management/view/${agentId}`)
    }
  )

  useNotification(
    NOTIFICATION_TYPES.success,
    resUpdAgent.isSuccess && resUpdAgentContacts.isSuccess && resUpdAgentIdentifiers.isSuccess,
    null,
    () => {
      dispatch(
        agentManagementApi.util.invalidateTags([
          'IAgentAllGetResponse',
          'IFilterFields',
          'IAllTableResponse',
        ])
      )
      navigate(`/sales-network/agent-management/view/${agentId}`)
    }
  )

  useNotification(NOTIFICATION_TYPES.success, updMpinResp.isSuccess, null, () => {
    setMpin(updMpinResp.data!.agentAccessOption.mPin)
  })
  useNotification(NOTIFICATION_TYPES.error, updMpinResp.isError, updMpinResp.error as ErrorNode)

  useNotification(NOTIFICATION_TYPES.success, resGenerateApiKey.isSuccess, null, () => {
    form.setFieldValue(['apiKey', 'secret'], resGenerateApiKey.data!.secret)
  })
  useNotification(
    NOTIFICATION_TYPES.error,
    resGenerateApiKey.isError,
    resGenerateApiKey.error as ErrorNode
  )

  useEffect(() => {
    if (!dataAgent) return
    handleSetInitialForm(dataAgent)
  }, [dataAgent, mode])

  const handleSetInitialForm = (data: IAgentAll) => {
    form.resetFields()
    form.setFieldsValue(data)
    form.setFieldValue('countryId', data.country?.id)
    form.setFieldValue('provinceId', data.province?.id)
    form.setFieldValue('districtId', +data.district?.id)
    form.setFieldValue('agentLevelId', data.agentLevel?.level)
    form.setFieldValue('agentTypeId', data.agentType?.id)
    form.setFieldValue('languageId', data.language?.id)
    form.setFieldValue('calendar', data.calendar)

    if (data.selfRegistration) {
      form.setFieldValue(['createdByUser', 'name'], 'Self-registration')
    } else if (data.createdByUser?.name) {
      form.setFieldValue('createdByUser', data.createdByUser)
    } else if (data.createdByUser === null) {
      form.setFieldValue(['createdByUser', 'name'], 'Created By System')
    }

    const validDate = formatDateWithTime(data.createdAt, profile?.calendar)
    data.createdAt ? form.setFieldValue('createdAt', validDate) : null

    const validDateUpdatedAt = formatDateWithTime(data.updatedAt, profile?.calendar)
    data.updatedAt ? form.setFieldValue('updatedAt', validDateUpdatedAt) : null

    !isEmpty(data.resellerCredentials) &&
      form.setFieldValue('resellerCredentials', {
        ...data.resellerCredentials,
        email: IDENTIFIERS_TYPE_NEW.EMAIL.type,
        value: data.resellerCredentials.email,
        ips: data.resellerCredentials.ips,
        isWhitelist: data.resellerCredentials.isWhitelist,
        ...(data.resellerCredentials.username && { username: data.resellerCredentials.username }),
        ...(data.agentAccessOption.hasPassword && { password: isViewMode ? '********' : '' }),
      })

    serAvatarFile(data.avatar)
    setIdentifierToDelete([])
    setMpin(data.agentAccessOption?.mPin)
  }

  const initialValues = {
    parent: null,
    parentName: '',
    profileID: '',
    registrationDate: '',
    agentTypeId: '',
    agentLevelId: '',
    districtId: '',
    provinceId: '',
    countryId: '',
    firstName: '',
    lastName: '',
    firstNameInLocalLanguage: '',
    lastNameInLocalLanguage: '',
    merchant: '',
    gender: '',
    companyTitle: '',
    resellerCredentials: {
      ips: [''],
      value: '',
      isWhitelist: true,
      username: '',
      password: '',
    },
    addressLine1: '',
    addressLine2: '',
    password: '',
  }

  const onFinish = (values: typeof initialValues) => {
    const obj = omit(values, [
      'agentIdentifiers',
      'contactInfo',
      'logo',
      'firstName',
      'lastName',
      'firstNameInLocalLanguage',
      'lastNameInLocalLanguage',
      'merchant',
      'gender',
      'resellerCredentials',
      'companyTitle',
      'districtId',
      'provinceId',
      'password',
      'addressLine1',
      'addressLine2',
    ]) as IAgentCreate

    if (isLogoDelete) {
      obj.isDeleteLogo = isLogoDelete
    }

    const contactInfo = form.getFieldValue('contactInfo') as IContactInfoResponse[]
    const reqContactInfo = contactInfo.map((i) => ({ ...i, active: true }))

    const identifierToCreate: Array<Omit<IContactChanel, 'id'>> = []
    const identifierToUpdate: Array<IContactChanel> = []

    form.getFieldValue('agentIdentifiers').forEach((bundle: IContactChanel) => {
      if (Object.hasOwn(bundle, 'id')) {
        identifierToUpdate.push({ ...bundle, id: Number(bundle.id) })
        return
      }
      identifierToCreate.push({ ...bundle, active: true })
    })
    const userIdentifiersMutation: IMutateUserContacts = {
      toCreate: identifierToCreate,
      toUpdate: identifierToUpdate,
      toDelete: identifierToDelete,
    }

    const req = {
      ...obj,
      accountTypeId: obj.accountType.id,
      ...(values.districtId && { districtId: values.districtId }),
      ...(values.provinceId && { provinceId: values.provinceId }),
      ...(values.addressLine1 && { addressLine1: values.addressLine1 }),
      ...(values.addressLine2 && { addressLine2: values.addressLine2 }),
      ...(values.companyTitle && { companyTitle: values.companyTitle }),
      ...(values.firstName && { firstName: values.firstName }),
      ...(values.lastName && { lastName: values.lastName }),
      ...(values.gender && { gender: values.gender }),
      ...(values.merchant && { merchant: values.merchant }),
      ...(values.resellerCredentials?.password && {
        password: values.resellerCredentials.password,
      }),
      ...(values.firstNameInLocalLanguage && {
        firstNameInLocalLanguage: values.firstNameInLocalLanguage,
      }),
      ...(values.lastNameInLocalLanguage && {
        lastNameInLocalLanguage: values.lastNameInLocalLanguage,
      }),
      ...(values.resellerCredentials?.value && {
        resellerCredentials: {
          ips: values.resellerCredentials.ips,
          email: values.resellerCredentials.value,
          isWhitelist: !!values.resellerCredentials.isWhitelist,
          username: values.resellerCredentials.username,
        },
      }),
    }

    const fd = new FormData()
    fd.append('agent', JSON.stringify(req))
    if (avatarFile) {
      fd.append('avatar', avatarFile)
    }

    updAgentContacts({
      id: agentId as string,
      body: { items: reqContactInfo },
    })

    isEmpty(values.resellerCredentials?.value) &&
      updAgentIdentifiers({
        id: agentId as string,
        body: userIdentifiersMutation,
      })

    updateAgent({ id: agentId as string, body: fd })
  }

  const openNotification = useCallback(() => {
    if (!canEditMPIN) return
    const updateCallback = () =>
      updMpin({
        id: Number(agentId),
        oldmPin: dataAgent?.agentAccessOption?.mPin as string,
        newmPin: customMpin(),
      })
    showConfirmMessage('Mpin will be changed', updateCallback)
  }, [agentId, canEditMPIN, dataAgent?.agentAccessOption?.mPin, updMpin])

  const onIdentifierDelete = (id: string) => {
    setIdentifierToDelete((prev) => [...prev, Number(id)])
  }

  const onParentClick = () => {
    onOpen(RIGHT_MODALS.SUPPORT.CHANGE_MPIN, {
      title: 'Change parent account',
      initialStep: 'searchParent',
      agentId,
    })
  }

  const onCancel = () => {
    setKeyUploader(nanoid())

    if (isViewMode) navigate('/sales-network/agent-management')
    if (isEditMode) {
      handleSetInitialForm(dataAgent!)
      navigate(`/sales-network/agent-management/view/${agentId}`)
    }
  }

  const onGenerate = async () => {
    await generateApiKey(agentId as string)
    onClose()
  }

  const onChangedMPinStatus = async (status: boolean) => {
    const fd = new FormData()
    fd.append('agent', JSON.stringify({ mPinActivation: status }))

    const res = await updateAgent({ id: agentId!, body: fd })

    // @ts-ignore
    if (res?.data) {
      dispatch(agentManagementApi.util.invalidateTags(['IAgentAllGetResponse']))
      ShowMessage('success', 'Success!')
    }
  }

  const isUpdating =
    resUpdAgentContacts.isLoading || resUpdAgentIdentifiers.isLoading || resUpdAgent.isLoading

  const renderContent = () => (
    <>
      <main className={styles['agent-detail-view']}>
        <Form form={form} layout='vertical' onFinish={onFinish}>
          <Row className={styles['wrapper-header-cards']} wrap={false}>
            {canViewCurrentBalance && (
              <HeaderDoubleCard
                color='blue'
                cardName='Current Balance'
                cardName2='Hold Balance'
                cardValue={formatNumberToLocale(dataAgent?.wallet?.amount ?? 0)}
                cardValue2={formatNumberToLocale(dataAgent?.wallet?.escrowAmount ?? 0)}
                fontSize={32}
                right={<>AFN</>}
              />
            )}
            {canViewCreditScore && <HeaderCard cardName='Credit Score' cardValue='95' />}
            {canViewMPIN && (
              <HeaderCard
                cardName='Mpin'
                cardValue={isViewMode || !canEditMPIN ? '****' : mpin}
                letterSpacing={7}
                isMpin
                onChangedMPinStatus={onChangedMPinStatus}
                loading={resUpdAgent.isLoading}
                isDeleted={dataAgent?.isDeleted}
                right={
                  <div
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      pointerEvents: isViewMode ? 'none' : 'auto',
                    }}
                    onClick={() => openNotification()}
                  >
                    <ReplayCircleRounded />
                  </div>
                }
              />
            )}
            {canViewParentAccount &&
              dataAgent?.agentLevel?.level &&
              dataAgent?.agentLevel?.level !== 1 && (
                <HeaderCard
                  cardName='Parent Account'
                  cardValue={`${dataAgent?.parentId || ''}`}
                  parentName={
                    <div
                      className={styles.parentName}
                      onClick={handleParentClick(dataAgent.parentId)}
                      style={{ cursor: 'pointer' }}
                    >
                      {agentParentFirstName} {agentParentLastName}
                    </div>
                  }
                  right={
                    <span onClick={onParentClick} style={{ cursor: 'pointer' }}>
                      <SwapArrowsIcon />
                    </span>
                  }
                />
              )}
          </Row>

          <Row wrap={false} justify='start'>
            <Col className={styles['wrapper-column']}>
              {canViewAgentDetail && (
                <C.AccountType form={form} view={isViewMode || !canEditAgentDetail} />
              )}
              {canViewAgentDetail && <C.DetailInfo view={isViewMode || !canEditAgentDetail} />}
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const agentType = getFieldValue('agentTypeId')
                  return canViewIdentifier && agentType === AGENT_TYPE_ID_ENUM.RESELLER ? (
                    <C.ResellerCredentials form={form} view={isViewMode || !canEditIdentifier} />
                  ) : (
                    <C.UserIdentifiers
                      form={form}
                      view={isViewMode || !canEditIdentifier}
                      onIdentifierDelete={onIdentifierDelete}
                    />
                  )
                }}
              </Form.Item>
              {canViewContactInfo && (
                <C.ContactInformation form={form} view={isViewMode || !canEditContactInfo} />
              )}
              {canViewAddress && (
                <C.Address
                  form={form}
                  view={isViewMode || !canEditAddress}
                  dataAgent={dataAgen ?? dataAgent}
                />
              )}
            </Col>
            {canViewAgentDetail && (
              <div>
                <UploaderPhotos2
                  key={keyUploader}
                  form={form}
                  isPersonPreview
                  title='PROFILE PICTURE'
                  view={isViewMode || !canViewAgentDetail}
                  defaultPreviewImage={dataAgent?.avatar}
                  setToDeleteLogo={setIsLogoDelete}
                  onChange={(photo: UploadFile[]) => {
                    serAvatarFile(photo[0].originFileObj as RcFile)
                    setIsLogoDelete(false)
                  }}
                />

                <C.AccountDetail />

                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const agentType = getFieldValue('agentTypeId')
                    const isWhitelist = getFieldValue(['resellerCredentials', 'isWhitelist'])

                    if (agentType === AGENT_TYPE_ID_ENUM.RESELLER) {
                      return <C.IPBlock view={isViewMode} form={form} isWhitelist={isWhitelist} />
                    }
                  }}
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const agentType = getFieldValue('agentTypeId')

                    if (agentType === AGENT_TYPE_ID_ENUM.RESELLER && (isViewMode || isEditMode)) {
                      return <C.ApiKeyBlock onGenerate={onGenerate} view={isViewMode} form={form} />
                    }
                  }}
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const contactInfo = getFieldValue('contactInfo')
                    const agentIdentifiers = getFieldValue('agentIdentifiers')
                    const isReseller = getFieldValue('agentTypeId') === AGENT_TYPE_ID_ENUM.RESELLER

                    const contArr = generateAvailableOptions(contactInfo)
                    const identArr = generateAvailableOptions(agentIdentifiers)

                    const availableOptions = [...contArr, ...identArr]

                    if (!isEmpty(availableOptions) && !isReseller) {
                      return <SendMessageBlock />
                    }
                  }}
                </Form.Item>
              </div>
            )}
          </Row>
        </Form>
      </main>
      <div className={styles['footer']}>
        {!isViewMode && (
          <div className={styles['wrapper-message-icon']}>
            <IconButton
              slim
              size='large'
              color='blue'
              shape='circle'
              icon={<MessageIcon />}
              type='primary'
            />
          </div>
        )}

        <Row className={styles['wrapper-footer-buttons']} justify='end'>
          <Button color='blue' onClick={onCancel} size='middle'>
            Cancel
          </Button>

          {canEditContent && (
            <Button
              color='blue'
              htmlType='submit'
              onClick={() => {
                if (!isViewMode) {
                  form.submit()
                }
                navigate(`${SALES_ENUM.SALES_NETWORK}/edit/${agentId}`)
              }}
              disabled={isUpdating || (isViewMode && dataAgent?.isDeleted)}
              size='middle'
              type='primary'
            >
              {isViewMode ? 'Edit' : 'Save Changes'}
            </Button>
          )}
        </Row>
      </div>
    </>
  )
  return (
    <>
      {isFetching ||
      resUpdAgent.isLoading ||
      resUpdAgentContacts.isLoading ||
      resUpdAgentIdentifiers.isLoading ||
      updMpinResp.isLoading ||
      resGenerateApiKey.isLoading ? (
        <Spin>{renderContent()}</Spin>
      ) : (
        renderContent()
      )}
    </>
  )
}
