import { Form, Select, Spin } from 'antd'
import styles from '../../styles.module.scss'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from '../../../../../../../assets/svg'
import { Wrapper } from '../../../../../../../shared/components/wrapper/Wrapper'
import { useGetVendorListQuery } from '../../../../../core/http/VendorManagementApi'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { maxLengthValidator, requiredValidator } from '../../../../../../../helpers/validation'

export const VendorResponse = ({ view }: IViewMode) => {
  const { data: vendorsList, isFetching: isFetchingVendors } = useGetVendorListQuery({})

  const content = (
    <Wrapper title='Vendor Response'>
      <Form.List name='vendorCodes'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <div key={key} className={styles.operatorResponseRow}>
                <Form.Item
                  className={styles.operatorField}
                  name={[name, 'vendor', 'id']}
                  label='Vendor'
                  rules={[{ required: true, validator: requiredValidator('Vendor') }]}
                >
                  <Select disabled={view} placeholder='Select Vendor' allowClear>
                    {vendorsList?.items.map((vendor) => (
                      <Select.Option key={vendor?.id} value={vendor?.id}>
                        {vendor?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  className={styles.operatorField}
                  name={[name, 'vendorCode']}
                  label='Vendor Response Code'
                  rules={[
                    { required: true, validator: requiredValidator('Vendor Response Code') },
                    { validator: maxLengthValidator(4) },
                  ]}
                >
                  <TextField disabled={view} placeholder='Enter Response Code' />
                </Form.Item>

                <div className={styles.bucketIcon}>
                  <IconButton
                    block
                    color='orange'
                    disabled={view}
                    icon={<BucketIcon />}
                    onClick={() => remove(name)}
                  />
                </div>
              </div>
            ))}
            <div className={styles.addButton}>
              <IconButton
                block
                color='orange'
                disabled={view}
                icon={<AddIcon />}
                onClick={() => add()}
              />
            </div>
          </>
        )}
      </Form.List>
    </Wrapper>
  )

  return isFetchingVendors ? <Spin>{content}</Spin> : content
}
