import { IBankAccountList } from '../../../../../../../Finance/models/IBankAccount'
import styles from '../../PickupRequestModal/styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../../../shared/components/textfield/TextField'
import { positiveNumber, requiredValidator } from '../../../../../../../../helpers/validation'
import TextArea from 'antd/es/input/TextArea'
import { Select } from 'antd'
import React, { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react'
import { useLazyGetCurrencyRateToCurrencyQuery } from '../../../../../../../Finance/core/http/ExchangeRatesApi'
import { AFN_CURRENCY_CODE } from '../../../../../../../Finance/models/IPurchaseOrder'
import { ErrorNode, handleError } from '../../../../../../../../shared/api/errorHandler'
import { isEmpty } from 'lodash'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import Input from 'antd/es/input/Input'

interface IDetailInfo {
  bankAccountList: Array<IBankAccountList> | undefined
  onChangeAgent: ChangeEventHandler<HTMLInputElement>
  status?: string
}

export const DetailInfo: React.FC<IDetailInfo> = ({ bankAccountList = [], onChangeAgent }) => {
  const form = useFormInstance()
  const [exchangeRate, setExchangeRate] = useState<number>()
  const [getExRate, dExRate] = useLazyGetCurrencyRateToCurrencyQuery()

  const handleBankAccountChange = (props: number): void => {
    const bankAccount = bankAccountList.find((i) => props === i.id)
    form.setFieldsValue({
      currency: bankAccount?.currency,
      paymentType: bankAccount?.paymentMethod[0],
      exchangeRate: 0,
      currencyAmount: 0,
      amount: 0,
    })

    if (bankAccount?.currency.id === AFN_CURRENCY_CODE) {
      form.setFieldValue('exchangeRate', 1)
      return setExchangeRate(1)
    }

    if (bankAccount && bankAccount?.currency.id !== AFN_CURRENCY_CODE) {
      getExRate({ currencyId: bankAccount?.currency.id })
    }
  }

  const handleChangeAmount = (val: ChangeEvent<HTMLInputElement>) => {
    const amount = val.target.value
    if (exchangeRate) {
      form.setFieldValue('amount', (exchangeRate * +amount).toFixed(2))
    }
  }

  useEffect(() => {
    if (dExRate.isError) {
      handleError(dExRate.error as ErrorNode)
    }
  }, [dExRate.error, dExRate.isError])

  useEffect(() => {
    if (isEmpty(dExRate.data)) return
    const exchangeRateResp = dExRate.data?.sellingRate
    setExchangeRate(exchangeRateResp)
  }, [dExRate.data])

  useEffect(() => {
    const exchangeRate = form.getFieldValue('exchangeRate')
    if (exchangeRate) {
      setExchangeRate(exchangeRate)
    }
  }, [form])

  return (
    <div>
      <div className={styles.dblRow}>
        <FormItem
          name={['agent', 'id']}
          label='Request By'
          rules={[
            { required: true, validator: requiredValidator('Request By') },
            { validator: positiveNumber('Request By') },
          ]}
        >
          <TextField type='number' placeholder='Enter Agent Id' onChange={onChangeAgent} />
        </FormItem>

        <FormItem name={['agent', 'name']} label='Requester Name'>
          <TextField disabled placeholder='Requester Name' />
        </FormItem>
      </div>
      <div className={styles.dblRow}>
        <FormItem name='parentAgentId' label='eMoney will be send to'>
          <TextField disabled placeholder='Parent Agent Id' />
        </FormItem>

        <FormItem name='parentAgentName' label='Parent Agent Name'>
          <TextField disabled placeholder='Parent Agent Name' />
        </FormItem>
      </div>
      <div className={styles.dblRow}>
        <FormItem
          name={['bankAccount', 'id']}
          label='Bank Account'
          rules={[{ required: true, validator: requiredValidator('Bank Account') }]}
        >
          <Select placeholder='Select Bank Account' onChange={handleBankAccountChange} allowClear>
            {bankAccountList.map(({ id, name }) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        <FormItem name={['currency', 'textCode']} label='Currency'>
          <TextField disabled placeholder='Currency' />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem
          name='currencyAmount'
          label='Amount'
          rules={[
            { required: true, validator: requiredValidator('Amount') },
            { validator: positiveNumber('Amount') },
          ]}
        >
          <TextField type='number' placeholder='Enter Amount' onChange={handleChangeAmount} />
        </FormItem>

        <FormItem name='amount' label='eMoney Amount'>
          <TextField disabled placeholder='eMoney Amount' />
        </FormItem>
      </div>

      <div className={styles.row}>
        <FormItem name='note' label='Note'>
          <TextArea placeholder='Enter Description' />
        </FormItem>
      </div>

      <span>
        <FormItem name='paymentType' noStyle shouldUpdate>
          <Input style={{ display: 'none' }} />
        </FormItem>
      </span>
    </div>
  )
}
