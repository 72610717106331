import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import emptyAvatarImage from '../../../../../../assets/img/Avatar.png'
import styles from '../../styles.module.scss'

interface IProps {
  avatar: string
}

const NotificationAvatar = ({ avatar }: IProps) => {
  return (
    <div className={styles.avatarContainer}>
      <Link className='table-avatar table-avatar-hover' to='#'>
        <img
          alt='user-photo'
          src={avatar || emptyAvatarImage}
          width={40}
          height={40}
          className={styles.avatar}
        />
      </Link>
    </div>
  )
}

export default memo(NotificationAvatar)
