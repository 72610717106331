import { IByUser } from '../../../shared/models/IByUser'
import {
  BankAccount,
  DEBIT_CREDIT_ENUM,
  ITrustWalletTransactionSub,
  TRANSACTION_STATUS_ENUM,
} from './ITransaction'
import { ICurrency } from './ICurency'
import { PAYMENT_METHOD_ENUM } from './IBankAccount'

export const AFN_CURRENCY_CODE = 1

export interface IBankAccountForm {
  id: number
  availableBalance: undefined | string
  currencyAmount: undefined | string
  mainCurrencyAmount?: string
  amount: undefined | string
  bankAccount: {
    id: { label: string; value: number; key: string; disabled: undefined; title: undefined }
  }
  unitPrice: undefined
}

export interface IPurchaseOrderUpdate {
  amount: number
  assignDepartment: { id?: number }
  assignOfficer: { id?: number }
  currency: { id: number }
  currencyAmount: number
  exchangeRate: number
  paymentType: PAYMENT_METHOD_ENUM
  vendor: { id: string }
  purchaseOrderBankWallets: IBankAccountForm[]
}

export interface IScratchCard {
  serialNumber: number
  code: string
  denomination: number
}

export interface IPurchaseOrderCreate {
  vendor: {
    id: {
      disabled: undefined | boolean
      key: string
      label: string
      title: undefined | string
      value: number
    }
  }
  date: string
  paymentType:
    | string
    | {
        label: string
        value: string
        key: string
      }
  currency: {
    id: {
      label: string
      value: string
      key: string
    }
  }
  vendorWalletType: VENDOR_WALLET_TYPE_ENUM
  cashAmount: string
  emoneyAmount: string
  createdBy: string
  exchangeRate: number
  assignOfficer?: {
    id: {
      disabled: undefined | boolean
      key: string
      label: string
      title: undefined | string
      value: number | string
    }
  }
  assignDepartment?: {
    id: {
      disabled: undefined | boolean
      key: string
      label: string
      title: undefined | string
      value: number | string
    }
  }
  purchaseOrderBankWallets: IBankAccountForm[]
  availableBalance: string
  cashAmountPayment: string
  unitPrice: string
  emoneyAmountPayment: string
  amount: string
  scratchCardInfo: Array<IScratchCard>
}

export enum VENDOR_WALLET_TYPE_ENUM {
  TOPUP = 'TOPUP',
  E_PIN = 'E_PIN',
  BILL_PAYMENT = 'BILL_PAYMENT',
  CASH_IN = 'CASH IN',
  CASH_OUT = 'CASH OUT',
}

export interface IPurchaseOrderBody {
  orderInfo: {
    vendorId: number
    paymentType: string
    currencyId?: number
    assignOfficerId?: number | null
    exchangeRate?: number
    assignDepartmentId?: number | null
    vendorWalletType: VENDOR_WALLET_TYPE_ENUM
  }
  paymentInfo?: Array<{
    bankAccountId?: number
    currencyAmount: number
    unitPrice: number
    amount: number
    mainCurrencyAmount: number
  }>
  scratchCardInfo?: Array<IScratchCard>
}

export interface IPurchaseOrderStatus {
  createdAt: string
  id: string
  status: string
  user: IByUser
}

type KeyOfReportType = 'bankAccount'

export interface IPurchaseOrderBankWallet {
  id: number
  amount: number
  currencyAmount: number
  mainCurrencyAmount: number
  unitPrice: number
  bankAccount: IByUser
  balanceBefore: number
  balanceAfter: number
}

export interface IPurchaseOrder {
  id: string
  amount: number
  createdAt: string
  currencyAmount: number // emoney
  currency: string | ICurrency
  status: TRANSACTION_STATUS_ENUM
  bankAccount?: BankAccount
  vendor: IByUser
  sender: KeyOfReportType
  receiver: KeyOfReportType
  paymentType: PAYMENT_METHOD_ENUM
  drCr: DEBIT_CREDIT_ENUM
  purchaseOrderSubTransactions: Array<IPurchaseOrderStatus>
  purchaseOrderBankWallets: Array<IPurchaseOrderBankWallet>
  isExpanded?: boolean
  walletType?: string
  escrow?: boolean
  children?: Array<ITrustWalletTransactionSub>
  countSubTransaction: number
  vendorWalletType: VENDOR_WALLET_TYPE_ENUM
}

export interface IPurchaseOrderLogs {
  id: number
  purchaseOrderId: number
  status: TRANSACTION_STATUS_ENUM
  user: IByUser
  createdAt: Date
  amount: number
  currencyAmount: number
  mainCurrencyAmount: number
  discountAmount: number
  discountRate: number
  paymentType: PAYMENT_METHOD_ENUM
  assignOfficer: IByUser
  currency: ICurrency
  exchangeRate: number
}

interface IPurchaseOrderScratchCardWithId extends IScratchCard {
  id: number
}

export interface IPurchaseOrderScratchCard {
  id: number
  scratchCard: IPurchaseOrderScratchCardWithId
}
