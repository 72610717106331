import React, { ChangeEvent } from 'react'
import { FormInstance, Select as AntSelect } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { DatePickerIcon, SwapArrowsIcon } from 'src/assets/svg'
import { Select } from 'src/shared/components/select/select'
import { DefaultOptionType } from 'antd/lib/select'

// components
import { TextField } from 'src/shared/components/textfield/TextField'

// styles
import styles from '../styles.module.scss'
import '../styles.scss'
import { ITableResponse } from 'src/shared/models/ITableResponse'
import { ICurrency } from 'src/features/Finance/models/ICurency'
import { ICurrencyRatePost, ICurrencyRatePut } from 'src/features/Finance/models/ICurencyRate'
import { SetState } from 'src/shared/models/ITableConf'
import { numberWithPointValidator } from '../../../../../../../../helpers/validation'
import { DatePicker } from 'src/shared/components/datePicker'

const { Option } = AntSelect

interface IProps {
  currencies?: ITableResponse<ICurrency>
  form: FormInstance<ICurrencyRatePost | ICurrencyRatePut>
  isEdite: boolean
  checkedCurrenciesFrom: number | null
  checkedCurrenciesTo: number | null
  setCheckedCurrenciesTo: SetState<number | null>
  setCheckedCurrenciesFrom: SetState<number | null>
}

export const NewRate = ({
  currencies,
  form,
  checkedCurrenciesFrom,
  checkedCurrenciesTo,
  setCheckedCurrenciesTo,
  setCheckedCurrenciesFrom,
}: IProps) => {
  const onSelect = (name: string) => (obj: DefaultOptionType) => {
    form.setFieldValue(name, obj?.value)
    name === 'currencyId' && setCheckedCurrenciesTo(obj?.value as number)
    name === 'toCurrencyId' && setCheckedCurrenciesFrom(obj?.value as number)
  }

  const fSFV = (name: string, value: string) => form.setFieldValue(name, value)

  const onChangeTextField = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    isNaN(Number(value)) ? fSFV(name, value.slice(0, -1)) : fSFV(name, value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerDetailInfo}>
        <div className={styles.header}>New Rate</div>
        <div className={styles.statusConatiner}></div>
      </div>
      <div>
        <div className={styles.dblRow}>
          <FormItem
            name='currencyId'
            label='From'
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <Select placeholder='Select Currency A' onChange={onSelect('currencyId')} allowClear>
              {currencies?.items.map(({ id, name }) => {
                return (
                  <>
                    {checkedCurrenciesFrom !== id && (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    )}
                  </>
                )
              })}
            </Select>
          </FormItem>

          <div className={styles.wrapperSwapArrowsIcon}>
            <SwapArrowsIcon />
          </div>

          <FormItem
            name='toCurrencyId'
            label='To'
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <Select placeholder='Select Currency B' onChange={onSelect('toCurrencyId')} allowClear>
              {currencies?.items.map(({ id, name }) => {
                return (
                  <>
                    {checkedCurrenciesTo !== id && (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    )}
                  </>
                )
              })}
            </Select>
          </FormItem>
        </div>
        <div className='wrapperDataPicker'>
          <FormItem
            name='date'
            label='Date'
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <DatePicker
              showTime
              placeholder='Date'
              className={styles.dataPicker}
              suffixIcon={
                <div className={styles.wrapperDataPickerIcon}>
                  <DatePickerIcon />
                </div>
              }
            />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem
            name='buyingRate'
            label='Buying Rate'
            rules={[
              { required: true, message: 'Field is required' },
              { validator: numberWithPointValidator },
            ]}
          >
            <TextField
              placeholder='Buying Rate'
              min={0}
              onChange={onChangeTextField('buyingRate')}
            />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem
            name='sellingRate'
            label='Selling Rate'
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <TextField
              placeholder='Selling Rate'
              min={0}
              onChange={onChangeTextField('sellingRate')}
            />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem
            name='coefficient'
            label='Сoefficient'
            rules={[{ required: true, message: 'Field is required' }]}
          >
            <TextField
              placeholder='Сoefficient'
              min={0}
              onChange={onChangeTextField('coefficient')}
            />
          </FormItem>
        </div>
      </div>
    </div>
  )
}
