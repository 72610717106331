import { useParams } from 'react-router'
import { useCallback, useContext, useMemo, useState } from 'react'
import { ITableConf } from '../../../../../../../shared/models/ITableConf'
import { useEmptyTable } from '../../../../../../../shared/hooks/table/useEmptyTable'
import useTable from '../../../../../../../shared/hooks/table/useTable'
import {
  useGetRewardPolicyByIdQuery,
  useUpdateRewardPolicyMutation,
} from '../../../../../core/http/RewardPolicyApi'
import { Table } from 'antd'
import styles from '../../../styles.module.scss'
import { ColumnsType } from 'antd/es/table'
import { IRewardPolicy, IRewardTargetGroup } from '../../../../../models/IRewardPolicy'
import { ErrorNode, showConfirmMessage } from '../../../../../../../shared/api/errorHandler'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../../shared/components/popup/Popup'
import { RIGHT_MODALS } from '../../../../../../../helpers/contants'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { RightModalContext } from '../../../../../../Modals'
import { TableActionIcon } from '../../../../../../../assets/svg'

export const TargetGroupTab = () => {
  const { onOpen } = useContext(RightModalContext)
  const { id } = useParams()
  const [, setTableConf] = useState<ITableConf>({})

  const [updRew, updRewResp] = useUpdateRewardPolicyMutation()
  const { data, isFetching } = useGetRewardPolicyByIdQuery(id as string, {
    skip: !id,
  })

  useNotification(NOTIFICATION_TYPES.success, updRewResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, updRewResp.isError, updRewResp.error as ErrorNode)

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange } = useTable<IRewardTargetGroup>({
    total: 1,
    setTableConf,
  })

  const selectedTargetGroups = useMemo(() => {
    return data?.targetGroups?.map((target) => target.id) || []
  }, [data?.targetGroups])

  const openNotification = useCallback(
    (groupId: string) => {
      const body = {
        targetGroups: selectedTargetGroups?.includes(groupId)
          ? selectedTargetGroups.filter((group) => group !== groupId)
          : [...selectedTargetGroups, groupId],
      }
      showConfirmMessage('This target group will be removed from the reward policy', () =>
        updRew({ id: id as string, body })
      )
    },
    [id, selectedTargetGroups, updRew]
  )

  const tableActionsPopup = useCallback(
    (id: string): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () =>
            onOpen(RIGHT_MODALS.SALES.SEGMENT_TARGET_GROUP, {
              title: 'View Target Group',
              mode: 'view',
              id,
            }),
        },
        {
          text: 'Delete',
          withDivider: true,
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          onClick: () => openNotification(id),
          shouldDisplay: true,
        },
      ]
    },
    [onOpen, openNotification]
  )

  const columns: ColumnsType<IRewardTargetGroup> = [
    {
      title: 'Target Group',
      dataIndex: 'title',
      width: '70%',
    },
    {
      title: 'Group Size',
      dataIndex: 'customersCount',
      align: 'center',
      width: '30%',
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return (
    <>
      <Table
        locale={emptyTableConf}
        onChange={handleTableChange}
        pagination={pagination}
        columns={columns}
        className={styles.table}
        scroll={{ x: 1000 }}
        rowKey={(record) => record.id}
        rowSelection={{ type: 'checkbox' }}
        dataSource={data?.targetGroups}
        loading={isFetching}
      />
    </>
  )
}
