import styles from './styles.module.scss'
import { DetailInfo } from './DetailInfo'
import { Form, Spin } from 'antd'
import { AddressBlock } from './Address'
import React, { useCallback, useContext, useEffect } from 'react'
import { RightModalContext } from '../../../../../Modals'
import { Button } from '../../../../../../shared/components/button/Button'
import FormItem from 'antd/es/form/FormItem'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import {
  useCreateSegmentMutation,
  useGetSegmentByIdQuery,
  useUpdateSegmentByIdMutation,
} from '../../../../core/http/SegmentsApi'
import { ISegment } from '../../../../models/ISegment'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../shared/api/errorHandler'

export const SegmentTargetGroupModal = () => {
  const [form] = Form.useForm()
  const {
    onOpen,
    props: { id, mode },
  } = useContext(RightModalContext)

  const { data, isFetching } = useGetSegmentByIdQuery(id as string, { skip: !id })
  const [updSegm, updSegmResp] = useUpdateSegmentByIdMutation()
  const [crSegm, crSegmResp] = useCreateSegmentMutation()

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const afterMutation = () => {
    return onOpen(RIGHT_MODALS.SALES.SEGMENT_TARGET_GROUP, {
      mode: 'view',
      title: 'View Target Group',
      id: id ?? crSegmResp?.data?.id,
    })
  }

  useNotification(NOTIFICATION_TYPES.success, updSegmResp.isSuccess, null, afterMutation)
  useNotification(NOTIFICATION_TYPES.success, crSegmResp.isSuccess, null, afterMutation)
  useNotification(NOTIFICATION_TYPES.success, updSegmResp.isError, updSegmResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.success, crSegmResp.isError, crSegmResp.error as ErrorNode)

  const onFinish = (values: ISegment) => {
    const body = {
      ...values,
      status: !!values?.status,
    } as Partial<ISegment>

    isEditMode ? updSegm({ id, body }) : crSegm(body)
  }

  const onEdit = useCallback(() => {
    if (isViewMode) {
      return onOpen(RIGHT_MODALS.SALES.SEGMENT_TARGET_GROUP, {
        mode: 'edit',
        title: 'Edit Target Group',
        id,
      })
    }

    form.submit()
  }, [form, id, isViewMode, onOpen])

  useEffect(() => {
    if (!data) return
    form.setFieldsValue({
      ...data,
      countryId: data?.country?.id,
      provinceId: data?.province?.id,
      districtId: data?.district?.id,
    })
  }, [data, form])

  const isLoading = isFetching || crSegmResp.isLoading || updSegmResp.isLoading

  const content = (
    <div className={styles.layout}>
      <Form form={form} layout='vertical' className={styles.form} onFinish={onFinish}>
        <DetailInfo view={isViewMode} />
        <AddressBlock view={isViewMode} id={id} />
        <FormItem>
          <Button color='orange' type='primary' block onClick={onEdit}>
            {!id && 'Create Target Group'}
            {isViewMode && 'Edit Target Group'}
            {isEditMode && 'Save Changes'}
          </Button>
        </FormItem>
      </Form>
    </div>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
