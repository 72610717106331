import { Filter, ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { IFilterFields } from '../../../../../../shared/models/IFilterFields'
import { useFilter } from '../../../../../../shared/hooks/filter/useFilter'
import { Col, Row } from 'antd'
import { FilterPopup } from '../../../../../../shared/components/filter/FilterPopup'
import { Widget } from '../../../../../../shared/components/widget'
import { UserGroupRounded } from '../../../../../../assets/svg'
import styles from '../../styles.module.scss'
import { useEffect, useState } from 'react'
import { widgetsSocketConnection } from '../../../../../../shared/sockets'
import { WIDGET_LIST_ENUM } from '../../../../../../shared/models/WidgetListEnum'
import {
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  moment,
} from '../../../../../../helpers/utils'
import { IWidget } from '../../../../../../shared/models/IWidget'
import { useGetProfileQuery } from '../../../../../Profile/core/http/Profile'

interface IProps {
  setActiveFilters: (values: ISelectedFilters) => void
  filterValues: ISelectedFilters
  data?: IFilterFields[]
}

export const TableHeader = ({ setActiveFilters, data, filterValues }: IProps): JSX.Element => {
  const { data: profile } = useGetProfileQuery()
  const { isOpen, onCancel, form, onReset, onFinish } = useFilter({ setActiveFilters })
  const [widgetData, setWidgetData] = useState(() => ({
    [WIDGET_LIST_ENUM.TOTAL_UNIQUE_CUSTOMERS]: {
      value: 0,
    },
    [WIDGET_LIST_ENUM.UNIQUE_CUSTOMERS]: {
      value: 0,
    },
    [WIDGET_LIST_ENUM.RETURN_CUSTOMER]: {
      value: 0,
      statistic: 0,
    },
  }))

  useEffect(() => {
    if (!profile) return
    moment.tz?.setDefault(profile?.timeZone as string)

    const socket = widgetsSocketConnection.getSocket()
    const messagePayload = [
      {
        widget: WIDGET_LIST_ENUM.TOTAL_UNIQUE_CUSTOMERS,
      },
      {
        widget: WIDGET_LIST_ENUM.TOTAL_UNIQUE_CUSTOMERS,
        filters: {
          from: formatMomentDateToDayStart(moment().startOf('month')).toISOString(true),
          to: formatMomentDateToDayEnd(moment().endOf('month')).toISOString(true),
        },
      },
      {
        widget: WIDGET_LIST_ENUM.RETURN_CUSTOMER,
        filters: {
          from: formatMomentDateToDayStart(moment().startOf('month')).toISOString(true),
          to: formatMomentDateToDayEnd(moment().endOf('month')).toISOString(true),
        },
      },
    ]

    socket?.emit('connect-widget', { data: messagePayload })

    return () => {
      socket?.emit('disconnect-widget', {
        data: messagePayload,
      })
    }
  }, [profile])

  useEffect(() => {
    if (!profile) return

    const socket = widgetsSocketConnection.getSocket()
    socket?.on('widget-data', (data: IWidget) => {
      const balance = JSON.parse(data?.data?.jsonData)

      if (data?.filters && data.widget === WIDGET_LIST_ENUM.TOTAL_UNIQUE_CUSTOMERS) {
        return setWidgetData((prevState) => ({
          ...prevState,
          [WIDGET_LIST_ENUM.UNIQUE_CUSTOMERS]: Object.keys(balance).length ? balance : {},
        }))
      }

      return setWidgetData((prevState) => ({
        ...prevState,
        [data.widget]: Object.keys(balance).length ? balance : {},
      }))
    })
  }, [profile])

  return (
    <>
      <div className={styles.widgetsWrapper}>
        <Widget
          icon={<UserGroupRounded />}
          title='Registered Customers'
          value={widgetData[WIDGET_LIST_ENUM.TOTAL_UNIQUE_CUSTOMERS].value}
        />
        <Widget
          icon={<UserGroupRounded />}
          title='Unique Customers / Month'
          value={widgetData[WIDGET_LIST_ENUM.UNIQUE_CUSTOMERS].value}
        />
        <Widget
          icon={<UserGroupRounded />}
          title='Return Customer'
          value={widgetData[WIDGET_LIST_ENUM.RETURN_CUSTOMER].value}
          statistic={widgetData[WIDGET_LIST_ENUM.RETURN_CUSTOMER].statistic}
        />
      </div>
      <Row align='middle' justify='space-between' style={{ marginBottom: 6, height: 'unset' }}>
        <Col style={{ display: 'flex', gridGap: 20 }}>
          <Filter
            data={data}
            form={form}
            setIsOpen={onCancel}
            selectedFilters={filterValues}
            setSelectedFilters={setActiveFilters}
          />
        </Col>
      </Row>
      {isOpen && (
        <FilterPopup
          data={data}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          form={form}
          onCancel={onCancel}
          onReset={onReset}
          onFinish={onFinish}
        />
      )}
    </>
  )
}
