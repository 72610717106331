import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import FormItem from 'antd/es/form/FormItem'
import { Select as AntSelect, Select } from 'antd'
import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import React, { useMemo } from 'react'
import {
  PROMOTION_POLICY_EVENT_ENUM,
  PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM,
} from '../../../../../models/IPromotionPolicy'
import { startCase } from 'lodash'
import {
  positiveNumber,
  requiredMulSelectValidator,
  requiredValidator,
} from '../../../../../../../helpers/validation'
import styles from '../../../styles.module.scss'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { useGetAccessChannelsListQuery } from '../../../../../core/http/DiscountPolicyApi'
import { selectTagRender } from '../../../../../../../helpers/selectTagRender'

interface IProps extends IViewMode {
  isEditable?: boolean
}

export const ConditionsBlock = ({ view, isEditable }: IProps) => {
  const { data: channelsList } = useGetAccessChannelsListQuery()
  const eventType = useMemo(
    () =>
      (
        Object.keys(PROMOTION_POLICY_EVENT_ENUM) as Array<keyof typeof PROMOTION_POLICY_EVENT_ENUM>
      ).map((method) => ({
        label: startCase(PROMOTION_POLICY_EVENT_ENUM[method].toLowerCase()),
        value: PROMOTION_POLICY_EVENT_ENUM[method],
      })),
    []
  )

  const percentageEvents = [
    PROMOTION_POLICY_EVENT_ENUM.PICKUP,
    PROMOTION_POLICY_EVENT_ENUM.TOPUP,
    PROMOTION_POLICY_EVENT_ENUM.WALLET_BALANCE,
    PROMOTION_POLICY_EVENT_ENUM.ACCESS_CHANNEL,
  ]

  return (
    <Wrapper title='conditions'>
      <FormItem noStyle shouldUpdate>
        {({ getFieldValue, setFieldValue, setFields }) => (
          <div className={styles.dblRow}>
            <div>
              <FormItem
                label='Event'
                name='event'
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('Event'),
                  },
                ]}
              >
                <Select
                  disabled={view || !isEditable || !getFieldValue('measurementType')}
                  allowClear
                  placeholder='Select Event'
                  onChange={() => {
                    setFields([{ name: 'amount', value: null, errors: [] }])
                    setFieldValue('promotionPolicyProducts', null)
                    setFieldValue('pickUpConditions', null)
                  }}
                >
                  {eventType
                    .filter((e) => {
                      if (
                        getFieldValue('measurementType') ===
                        PROMOTION_POLICY_MEASUREMENT_TYPE_ENUM.PERCENTAGE
                      ) {
                        return percentageEvents.includes(e.value)
                      }

                      return e.value !== PROMOTION_POLICY_EVENT_ENUM.WALLET_BALANCE
                    })
                    .map((event) => (
                      <Select.Option key={event.value} value={event.value}>
                        {event.label}
                      </Select.Option>
                    ))}
                </Select>
              </FormItem>
            </div>

            {getFieldValue('event') === PROMOTION_POLICY_EVENT_ENUM.WALLET_BALANCE && (
              <div>
                <FormItem
                  label='Threshold (Above)'
                  name='walletAboveAmount'
                  rules={
                    getFieldValue('event') === PROMOTION_POLICY_EVENT_ENUM.WALLET_BALANCE
                      ? [
                          {
                            required: true,
                            validator: requiredValidator('Threshold (Above)'),
                          },
                          {
                            validator: positiveNumber('Threshold (Above)'),
                          },
                        ]
                      : [{}]
                  }
                >
                  <TextField
                    disabled={
                      view ||
                      !isEditable ||
                      getFieldValue('event') !== PROMOTION_POLICY_EVENT_ENUM.WALLET_BALANCE
                    }
                    placeholder='Enter Threshold (Above)'
                  />
                </FormItem>
              </div>
            )}

            <div>
              {getFieldValue('event') === PROMOTION_POLICY_EVENT_ENUM.ACCESS_CHANNEL ? (
                <FormItem
                  name='accessChannels'
                  label='Access Channels'
                  rules={[
                    { required: true, validator: requiredMulSelectValidator('Access Channels') },
                  ]}
                >
                  <AntSelect
                    disabled={view || !isEditable}
                    mode='multiple'
                    placeholder='Select Access Channels'
                    tagRender={selectTagRender}
                    maxTagCount='responsive'
                    showArrow
                    allowClear
                  >
                    {channelsList?.accessChannels?.map((channel) => (
                      <Select.Option key={channel} value={channel}>
                        {channel}
                      </Select.Option>
                    ))}
                  </AntSelect>
                </FormItem>
              ) : (
                <FormItem
                  label='Promotion amount'
                  name='amount'
                  rules={
                    getFieldValue('event') === PROMOTION_POLICY_EVENT_ENUM.WALLET_BALANCE ||
                    !percentageEvents.includes(getFieldValue('event'))
                      ? [
                          {
                            required: true,
                            validator: requiredValidator('Promotion amount'),
                          },
                          {
                            validator: positiveNumber('Promotion amount'),
                          },
                        ]
                      : [{}]
                  }
                >
                  <TextField
                    type='number'
                    disabled={
                      view ||
                      (getFieldValue('event') !== PROMOTION_POLICY_EVENT_ENUM.WALLET_BALANCE &&
                        percentageEvents.includes(getFieldValue('event'))) ||
                      !isEditable ||
                      !getFieldValue('measurementType')
                    }
                    placeholder='Enter Promotion amount'
                  />
                </FormItem>
              )}
            </div>
          </div>
        )}
      </FormItem>

      <div>
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            if (getFieldValue('event') === PROMOTION_POLICY_EVENT_ENUM.REACTIVATION) {
              return (
                <div>
                  <FormItem
                    name='reactivationInterval'
                    label='Reactivation Interval Day'
                    rules={[
                      {
                        validator: positiveNumber('Reactivation Interval Day'),
                      },
                    ]}
                  >
                    <TextField
                      type='number'
                      disabled={view || !isEditable}
                      placeholder='Enter Reactivation Interval Day'
                    />
                  </FormItem>
                </div>
              )
            }
          }}
        </FormItem>
      </div>
    </Wrapper>
  )
}
