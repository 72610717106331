import React, { useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

import TableHeader from './Components/TableHeader'
import { IPopupListItems, Popup } from 'src/shared/components/popup/Popup'
import { ISelectedFilters } from 'src/shared/components/filter/Filter'
import { TableActionIcon } from 'src/assets/svg'
import emptyAvatarImage from 'src/assets/img/Avatar.png'
import { FINANCE_ENUM } from 'src/routes/finance'
import { ITableConf } from 'src/shared/models/ITableConf'
import { ICreditScoreAgent } from '../../../../../models/ICreditScore'
import { tableExpandedIcon } from 'src/helpers/utils'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'

import styles from './styles.module.scss'

const agents: Array<ICreditScoreAgent> = [
  {
    id: 30123456,
    firstName: 'Brooklyn',
    lastName: 'Simmons',
    level: 1,
    lastWeekScore: 5,
    thisWeekScore: 96,
    children: [
      {
        id: 30123454,
        firstName: 'Age in ',
        lastName: 'the System',
        level: 1,
        lastWeekScore: 5,
        thisWeekScore: 96,
      },
    ],
  },
  {
    id: 30123452,
    firstName: 'Jenny',
    lastName: 'Wilson',
    level: 1,
    lastWeekScore: 95,
    thisWeekScore: 96,
  },
  {
    id: 30123453,
    firstName: 'Cameron',
    lastName: 'Williamson',
    level: 1,
    lastWeekScore: 95,
    thisWeekScore: 96,
  },
  {
    id: 30123451,
    firstName: 'Guy',
    lastName: 'Hawkins',
    level: 1,
    lastWeekScore: 95,
    thisWeekScore: 96,
  },
  {
    id: 30123455,
    firstName: 'Ralph',
    lastName: 'Edwards',
    level: 1,
    lastWeekScore: 95,
    thisWeekScore: 96,
    children: [
      {
        id: 30123458,
        firstName: 'Age in ',
        lastName: 'the System',
        level: 1,
        lastWeekScore: 20,
        thisWeekScore: 20,
      },
      {
        id: 30123459,
        firstName: 'Amount of',
        lastName: 'pickups',
        level: 1,
        lastWeekScore: 15,
        thisWeekScore: 15,
      },
    ],
  },
]

const CreditScoreAgents = () => {
  const navigate = useNavigate()
  const { queryParams, queryFields } = useGetQueryParams()

  const [, setTableConf] = useState<ITableConf>(queryParams)
  const [mappedMasters] = useState<ICreditScoreAgent[]>(agents)
  const [, setExpandedRows] = useState<number | undefined>()
  const [filterValues] = useState<ISelectedFilters>(queryFields)

  const { pagination, handleTableChange, handleFiltersChange } = useTable<ICreditScoreAgent>({
    total: agents.length,
    setTableConf,
  })

  const tableActionsPopup = useCallback(
    (id: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () => navigate(`${FINANCE_ENUM.CREDIT_SCORE_AGENTS}/view/${id}`),
        },
      ]
    },
    [navigate]
  )

  const columns: ColumnsType<ICreditScoreAgent> = [
    {
      title: '',
      dataIndex: '',
      key: 'expand',
      width: '1%',
    },
    {
      title: 'Agent ID',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: 'Agent Name',
      dataIndex: 'name',
      width: '47%',
      render: (value, record) => {
        if (!Object.hasOwn(record, 'children')) {
          return <div>{`${record.firstName} ${record.lastName}`}</div>
        }
        return (
          <Link
            to={`${FINANCE_ENUM.CREDIT_SCORE_AGENTS}/view/${record.id}`}
            className='table-avatar'
          >
            <img className={styles.avatar} alt='user logo' src={record.logo ?? emptyAvatarImage} />
            <div>{`${record.firstName} ${record.lastName}`}</div>
          </Link>
        )
      },
    },
    {
      title: 'Agent level',
      dataIndex: 'level',
      width: '15%',
    },
    {
      title: 'Last Week Score',
      dataIndex: 'lastWeekScore',
      width: '15%',
      render: (_, row) => {
        return (
          <div className={row.lastWeekScore >= 20 ? styles.positiveValue : styles.negativeValue}>
            {row.lastWeekScore}
          </div>
        )
      },
    },
    {
      title: 'This week score',
      dataIndex: 'thisWeekScore',
      width: '15%',
      render: (_, row) => {
        return (
          <div className={row.thisWeekScore >= 20 ? styles.positiveValue : styles.negativeValue}>
            {row.thisWeekScore}
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => {
        if (!Object.hasOwn(row, 'children')) return
        return (
          <Popup data={tableActionsPopup(row.id)}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        )
      },
    },
  ]

  return (
    <div>
      <TableHeader filterValues={filterValues} setActiveFilters={handleFiltersChange} data={[]} />
      <Table
        columns={columns}
        pagination={pagination}
        className={styles.table}
        onChange={handleTableChange}
        loading={false}
        rowKey={(row) => row?.id}
        dataSource={mappedMasters}
        rowSelection={{ type: 'checkbox' }}
        scroll={{ x: 1100 }}
        expandable={{
          expandIcon: (props) => {
            if (!Object.hasOwn(props.record, 'children')) return null
            return tableExpandedIcon<ICreditScoreAgent>(props)
          },
          onExpand: (expanded, record) => {
            setExpandedRows(record.id)
          },
        }}
      />
    </div>
  )
}

export default CreditScoreAgents
