import { Form, Select } from 'antd'
import classNames from 'classnames'
import { ErrorRoundedIcon, ResolvedRoundedIcon } from 'src/assets/svg'
import { TICKET_STATUS_ENUM } from 'src/features/Support/models/ITicket'

// styles
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import styles from '../../style.module.scss'

const options = [
  {
    value: TICKET_STATUS_ENUM.OPEN,
    icon: <ErrorRoundedIcon />,
  },
  {
    value: TICKET_STATUS_ENUM.RESOLVED,
    icon: <ResolvedRoundedIcon />,
  },
  {
    value: TICKET_STATUS_ENUM.REPLIED,
    icon: <div className={styles.repliedStatus} />,
  },
]

export const TicketStatus = ({ viewMode, reply }: { viewMode: boolean; reply: string }) => {
  return (
    <Wrapper
      title='ticket status'
      className={classNames(styles.mb24, styles.ticketStatusContainer)}
    >
      <Form.Item name='status'>
        <Select disabled={viewMode} className={styles.ticketStatusSelect} allowClear>
          {(reply ? options : options.slice(0, -1)).map(({ value, icon }) => (
            <Select.Option key={value} value={value}>
              <div className={styles.ticketStatusItemContainer}>
                {icon}
                <div
                  className={classNames(styles.ticketStatusValue, {
                    [styles.green]: value === TICKET_STATUS_ENUM.RESOLVED,
                    [styles.red]: value === TICKET_STATUS_ENUM.OPEN,
                    [styles.orange]: value === TICKET_STATUS_ENUM.REPLIED,
                  })}
                >
                  {value}
                </div>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Wrapper>
  )
}
