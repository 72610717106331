import React, { useEffect, useState } from 'react'
import { IInternalChatMessageFile } from '../../../../../../models'

import styles from './styles.module.scss'
import { FileIcon } from '../../../../../../../../assets/svg'
import { useGetInternalChatLinkToDownloadFileQuery } from '../../../../../../core/http/InternalChatsApi'
import { IExternalChatMessageFile } from '../../../../../../../Support/models/IExternalChat'
import { truncateFileName } from '../../../../../../../../helpers/utils'

interface IProps {
  file: IInternalChatMessageFile | IExternalChatMessageFile
}

export const FileInMessage = ({ file }: IProps) => {
  const [download, setDownload] = useState(false)

  const { data, isSuccess, isError } = useGetInternalChatLinkToDownloadFileQuery(file.id, {
    skip: !file.id || !download,
  })

  useEffect(() => {
    if (data?.link && download) {
      const link = document.createElement('a')
      link.setAttribute('href', data?.link)
      link.setAttribute('download', file.file.name)
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    // eslint-disable-next-line
  }, [data, download])

  useEffect(() => {
    if (isSuccess || isError) {
      setDownload(false)
    }
  }, [isSuccess, isError])

  const onDownLoad = () => {
    setDownload(true)
  }

  return (
    <div>
      <div className={styles.fileInMessageContainer} onClick={onDownLoad}>
        <div>
          <FileIcon />
        </div>
        <div className={styles.fileInMessageTitle}>{truncateFileName(file.file.name)}</div>
      </div>
    </div>
  )
}
