import { useEffect, useState } from 'react'
import { Skeleton, Form, Spin } from 'antd'
import PinInput from 'react-pin-input'
import FormItem from 'antd/es/form/FormItem'

// queries
import {
  useGetAgentByIdQuery,
  usePatchAgentMutation,
  useResetMpinMutation,
} from 'src/features/salesNetwork/core/http/AgentManagement'

// components
import { AgentMainInfo } from '../../Components/AgentMainInfo'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { TickIcon } from 'src/assets/svg'

// helpers
import {
  confirmFieldValidator,
  minLengthValidator,
  requiredValidator,
} from 'src/helpers/validation'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { IMPin, Step } from 'src/features/Support/models/IMPin'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'

// styles
import styles from './styles.module.scss'

interface IChangeMpinStep {
  agentId: string | number | undefined
  onChangeStep: (step: Step, agentId: string | number | null) => void
  onChangedMPin: (value: boolean) => void
}

export const ChangeMpinStep: React.FC<IChangeMpinStep> = ({
  agentId,
  onChangeStep,
  onChangedMPin,
}) => {
  const [form] = Form.useForm<IMPin>()
  const [mpin, setMpin] = useState<string>('')

  const [resetMPin, { isLoading, isSuccess, isError, error }] = useResetMpinMutation()
  const [patch] = usePatchAgentMutation()
  const { data: agent, isFetching } = useGetAgentByIdQuery(agentId as string, {
    skip: !agentId,
  })

  useNotification(NOTIFICATION_TYPES.error, isError, error as ErrorNode)

  useEffect(() => {
    if (!isSuccess) return
    onChangeStep('agentProfile', agentId!)
    onChangedMPin(isSuccess)
    // eslint-disable-next-line
  }, [isSuccess])

  const handlerBackClick = () => {
    onChangeStep('agentProfile', agentId!)
  }

  const handleFinish = (values: IMPin) => {
    const { newmPin } = values
    const req = {
      newmPin,
      // eslint-disable-next-line
      id: +agent?.id!,
      oldmPin: agent?.agentAccessOption.mPin,
    }
    resetMPin(req)
  }

  const onStatusMpinChange = (checked: boolean) => {
    const fd = new FormData()
    fd.append('agent', JSON.stringify({ mPinActivation: checked }))
    patch({ id: agentId, body: fd })
  }

  return (
    <Spin spinning={isLoading}>
      <div className={styles.changeMpinContainer}>
        <Skeleton loading={isFetching}>
          <AgentMainInfo
            agent={agent!}
            onChangeStep={handlerBackClick}
            onStatusMpinChange={onStatusMpinChange}
          />
        </Skeleton>
        <Form form={form} layout='vertical' onFinish={handleFinish}>
          <div className={styles.row}>
            <FormItem
              name='newmPin'
              label='New mPIN'
              rules={[
                {
                  validator: requiredValidator('New mPIN'),
                },
                {
                  validator: minLengthValidator(4, 'mPIN'),
                },
              ]}
            >
              <PinInput
                length={4}
                type='numeric'
                autoSelect={true}
                onChange={setMpin}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </FormItem>
          </div>

          <div className={styles.row}>
            <FormItem
              name='confirmmPin'
              label='Confirm new mPIN'
              dependencies={['newmPin']}
              hasFeedback
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newmPin') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('mPIN does not match!'))
                  },
                }),
              ]}
            >
              <PinInput
                length={4}
                type='numeric'
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </FormItem>
          </div>

          <IconButton
            icon={<TickIcon />}
            htmlType='submit'
            size='large'
            type='primary'
            color='orange'
            className={styles.btnSubmit}
          >
            Confirm
          </IconButton>
        </Form>
      </div>
    </Spin>
  )
}
