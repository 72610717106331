import { Form, Spin } from 'antd'
import {
  useCreateTransferFundMutation,
  useGetWalletsQuery,
} from 'src/features/Finance/core/http/WalletApi'
import { IWalletBody } from 'src/features/Finance/models/IWallet'
import { RightModalContext } from 'src/features/Modals'
import { useContext } from 'react'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { Footer } from './Footer'

// styles
import styles from './styles.module.scss'

import { WalletForm } from './WalletForm'
import { useAppDispatch } from '../../../../../../../redux'
import { statementOfWalletsApi } from '../../../../../../Finance/core/http/StatementOfWalletsApi'

export const TrunsferFundModal = () => {
  const dispatch = useAppDispatch()
  const [form] = Form.useForm<IWalletBody>()
  const rightModalContext = useContext(RightModalContext)

  const { data: walletList, isFetching } = useGetWalletsQuery({})
  const [createTransfer, dTransfer] = useCreateTransferFundMutation()

  const afterSuccess = () => {
    rightModalContext.onClose()
    dispatch(statementOfWalletsApi.util.invalidateTags(['WalletsList', 'StatementList']))
  }

  useNotification(NOTIFICATION_TYPES.success, dTransfer.isSuccess, null, afterSuccess)
  useNotification(NOTIFICATION_TYPES.error, dTransfer.isError, dTransfer.error as ErrorNode)

  const handleFinish = (values: IWalletBody) => {
    const { amount, description, balance, receiverWalletId, senderWalletId } = values

    const req = {
      amount: +amount,
      balance,
      receiverWalletId: +receiverWalletId.value,
      senderWalletId: +senderWalletId.value,
      description: description || ' ',
    }
    createTransfer(req)
  }

  return (
    <Spin spinning={isFetching}>
      <Form form={form} className={styles.formContainer} layout='vertical' onFinish={handleFinish}>
        <WalletForm form={form} walletList={walletList?.items} />
        <Footer form={form} />
      </Form>
    </Spin>
  )
}
