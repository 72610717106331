import React, { memo, useEffect, useMemo, useState } from 'react'
import { Checkbox } from '../../../../../../../../shared/components/checkbox/Checkbox'
import { IPermissionCreate, IPermissionModulesGroup } from '../../../../../../models/IRoles'

interface IProps {
  disabled?: boolean
  group: IPermissionModulesGroup
  action: string
  selectedPermissions: Array<IPermissionCreate>
  onSelectAllPermissionsByTypeInGroup: (
    group: IPermissionModulesGroup,
    action: string,
    status: boolean
  ) => void
}

const PermissionsAllByTypeCheckbox = ({
  disabled,
  group,
  action,
  selectedPermissions,
  onSelectAllPermissionsByTypeInGroup,
}: IProps) => {
  const [isChecked, setIsChecked] = useState(false)
  const [isDisabled, setIsDisabled] = useState(disabled)
  const [isIndeterminate, setIsIndeterminate] = useState(false)

  const permissionsCountInGroup = useMemo(() => {
    return group.modules
      .map((module) => [
        ...module.permissionModuleAllowedPermissions,
        ...module.subModules
          .map((subModule) => subModule.permissionModuleAllowedPermissions)
          .flat(),
      ])
      .flat()
      .filter((el) => el.permission === action).length
  }, [action, group.modules])

  const selectedPermissionsCountInGroup = useMemo(() => {
    const selectedModulesCount = group.modules
      .map((module) =>
        selectedPermissions.filter(
          (per) => per.permission === action && per.permissionModuleId === module.id
        )
      )
      .flat().length

    const selectedSubmodulesCount = group.modules
      .map((module) =>
        module.subModules
          .map((sub) =>
            selectedPermissions.filter(
              (per) => per.permission === action && per.permissionModuleId === sub.id
            )
          )
          .flat()
      )
      .flat().length

    return selectedSubmodulesCount + selectedModulesCount
  }, [action, group.modules, selectedPermissions])

  useEffect(() => {
    const checked =
      selectedPermissionsCountInGroup > 0 &&
      permissionsCountInGroup === selectedPermissionsCountInGroup
    setIsChecked(checked)
  }, [permissionsCountInGroup, selectedPermissionsCountInGroup])

  useEffect(() => {
    const selectedDiff = permissionsCountInGroup - selectedPermissionsCountInGroup
    const indeterminate = selectedDiff > 0 && selectedDiff !== permissionsCountInGroup
    setIsIndeterminate(indeterminate)
  }, [permissionsCountInGroup, selectedPermissionsCountInGroup])

  useEffect(() => {
    const disabled = !permissionsCountInGroup
    setIsDisabled(disabled)
  }, [permissionsCountInGroup])

  return (
    <Checkbox
      disabled={isDisabled || disabled}
      indeterminate={isIndeterminate}
      checked={isChecked}
      onChange={(status) =>
        onSelectAllPermissionsByTypeInGroup(group, action, status.target.checked)
      }
    />
  )
}

export default memo(PermissionsAllByTypeCheckbox)
