import React, { memo } from 'react'
import FormItem from 'antd/es/form/FormItem'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { AddIcon, CrossIcon } from 'src/assets/svg'

import styles from './styles.module.scss'

interface IProps {
  tags: Array<string>
  onAddNewTag: (tag: string) => void
}

const ProfileDocumentationTags = ({ onAddNewTag, tags }: IProps) => {
  return (
    <Wrapper
      title='Tags'
      actions={
        <IconButton
          onClick={() => onAddNewTag('Chip')}
          icon={<AddIcon />}
          type='default'
          color='orange'
        />
      }
    >
      <FormItem name='tags'>
        <div>
          {tags?.length
            ? tags.map((tag: string, i: number) => (
                <div key={i} className={styles.tag}>
                  <div>{tag}</div>
                  <div className={styles.removeTagButton}>
                    <CrossIcon color='#FFF' size={6} />
                  </div>
                </div>
              ))
            : null}
        </div>
      </FormItem>
    </Wrapper>
  )
}

export default memo(ProfileDocumentationTags)
