import React from 'react'
import ReconciliationTabs from './Components/Tabs/index'
import styles from './styles.module.scss'

const Reconciliation = () => {
  return (
    <div className={styles.layout}>
      <ReconciliationTabs />
    </div>
  )
}

export default Reconciliation
