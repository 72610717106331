/* eslint-disable */

import React, { ReactElement, ReactNode, useRef, useState } from 'react'
import {
  PieChart as RechartsPieChart,
  Pie,
  Tooltip,
  Cell,
  Sector,
  ResponsiveContainer,
} from 'recharts'
import styles from './PieChart.module.scss'
import { Props as SectorProps } from 'recharts/types/shape/Sector'
import { startCase } from 'lodash'
import classNames from 'classnames'
import { RecursivelyReplaceCharacters } from '../privateMode'
import { useLocalStorageGetByKey } from '../../hooks/useLocalStorageGetByKey'

interface IPieChart {
  data: {
    name?: string
    value: number | string
    money?: number | string
    info?: Record<string, string | number>
    color?: string
  }[]
  textCenterChart?: string | number
  showPercentOnlyByIndexSector?: number
  colors: string[]
  onSectorClick?: (val: string) => void
}

export const PieChart = ({
  data,
  textCenterChart = '',
  showPercentOnlyByIndexSector,
  colors,
  onSectorClick,
}: IPieChart) => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const ref = useRef<HTMLDivElement>(null)

  const showComponentCenter = textCenterChart && (
    <div className={styles['text-center']} title={String(textCenterChart)}>
      {new Intl.NumberFormat('en-US', {
        notation: 'compact',
        compactDisplay: 'short',
      }).format(Number(textCenterChart))}
    </div>
  )

  const getTotalValue = () => {
    return data?.reduce((total, item) => total + Number(item?.value), 0)
  }

  const renderActiveShape:
    | ReactElement<SVGElement>
    | ((props: any) => ReactElement<SVGElement>)
    | SectorProps = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props

    // Calculate the maximum allowed text width to prevent overlap
    const maxTextWidth = outerRadius + 5 // Adjust as needed

    // Truncate the label text if it exceeds the maximum width
    const labelText =
      payload.name.length > maxTextWidth / 8
        ? payload.name.substring(0, maxTextWidth / 8 - 3) + '...'
        : payload.name

    const percent = `${Math.round((Number(payload?.value) / getTotalValue()) * 100)}%`

    return (
      <RecursivelyReplaceCharacters>
        <g>
          <text
            x={cx}
            y={cy}
            dy={-20}
            textAnchor='middle'
            fill={fill}
            fontWeight={'bold'}
            fontSize={16}
          >
            {labelText}
          </text>
          {payload?.info &&
            Object?.entries(payload?.info)?.map(([label, value], index) => {
              const yOffset = -20 + 20 * (index + 1)

              return (
                <text
                  x={cx}
                  y={cy}
                  dy={yOffset}
                  textAnchor='middle'
                  fill={fill}
                  fontSize={12}
                  key={label}
                >
                  {`${startCase(label)}: ${value}`}
                </text>
              )
            })}
          {payload?.info && (
            <text
              x={cx}
              y={cy}
              dy={-20 + 20 * (Object?.keys(payload?.info)?.length + 1)}
              textAnchor='middle'
              fill={fill}
              fontSize={12}
            >
              {`Percent: ${percent}`}
            </text>
          )}
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
        </g>
      </RecursivelyReplaceCharacters>
    )
  }

  const [activeShape, setActiveShape] = useState<number>()

  const onPieEnter = (_: any, index: number) => {
    setActiveShape(index)
  }

  return (
    <div
      ref={ref}
      className={classNames(styles['wrapper-pie-chart'], 'pie-chart-container-wrapper')}
    >
      {activeShape === undefined && (
        <RecursivelyReplaceCharacters>
          <div className={styles['wrapper-text-center']}>{showComponentCenter}</div>
        </RecursivelyReplaceCharacters>
      )}
      <RechartsPieChart width={240} height={240} onMouseLeave={() => setActiveShape(undefined)}>
        <Pie
          dataKey='value'
          labelLine={false}
          activeIndex={activeShape}
          activeShape={renderActiveShape}
          onClick={(e) => onSectorClick && onSectorClick(e?.payload?.name)}
          data={data}
          cx={112}
          cy={112}
          innerRadius={70}
          outerRadius={112}
          fill='#82ca9d'
          onMouseEnter={onPieEnter}
          onMouseLeave={() => setActiveShape(undefined)}
        >
          {data.map((item: any, index: any) => (
            <Cell key={`cell-${index}`} fill={item?.color ?? colors[index % colors.length]} />
          ))}
        </Pie>
      </RechartsPieChart>
    </div>
  )
}
