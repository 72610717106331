import React, { memo, useContext } from 'react'
import { MessageIcon, NotificationBellIcon } from '../../../../../../assets/svg'

import { InternalChatContext } from '../../../InternalChatModal'
import { clearMessages } from '../../../../core/store/Messages'
import { useAppDispatch } from '../../../../../../redux'
import { ChatInfoAvatar } from 'src/shared/components/chat/Components'
import { modalViewTypeEnum } from '../../../../core/models'
import styles from './styles.module.scss'

interface IProps {
  logo?: string
  fullName: string
  openedChatId: number
}

export const InternalChatDetailsMainInfo = memo(({ logo, fullName, openedChatId }: IProps) => {
  const dispatch = useAppDispatch()

  const { onSelectChat } = useContext(InternalChatContext)

  const onPressMessages = () => {
    dispatch(clearMessages())
    onSelectChat(modalViewTypeEnum.messaging, openedChatId)
  }

  return (
    <div className={styles.internalChatMainInfo}>
      <ChatInfoAvatar logo={logo} />
      <div className={styles.userName}>{fullName}</div>
      {/*<div className={styles.onlineStatus}>Online</div>*/}
      <div className={styles.actionsContainer}>
        <div className={styles.actionContainer} onClick={onPressMessages}>
          <MessageIcon color='#0193EB' size={13} />
        </div>
        {/*<div className={styles.actionContainer}>*/}
        {/*  <ChatCallIcon color='#0193EB' size={13} />*/}
        {/*</div>*/}
        {/*<div className={styles.actionContainer}>*/}
        {/*  <VideoCallIcon />*/}
        {/*</div>*/}
        <div className={styles.actionContainer}>
          <NotificationBellIcon height={13} width={11} />
        </div>
      </div>
    </div>
  )
})
