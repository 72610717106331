import { Form, Row, Spin, UploadFile } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate } from 'react-router'

// styles
import styles from '../style.module.scss'

import { StepRegistration } from '../../../../../shared/components/stepRegistration'
import { DetailInfo } from './DetailInfo'
import { TicketDescription } from './TicketDescription'
import { UploaderFiles } from 'src/shared/components/uploaderPhotos/UploaderFiles'
import { compatibleUplaodTypes } from 'src/shared/components/uploaderPhotos/constants'
import { Button } from 'src/shared/components/button/Button'
import {
  IAttachementRes,
  ILog,
  ITicketExtended,
  ITicketForm,
  TICKET_STATUS_ENUM,
  TICKET_TYPE_ENUM,
} from 'src/features/Support/models/ITicket'

import { TicketModeEnum, verifyUserPermissions } from '../index.d'
import {
  useCreateTicketMutation,
  useDeleteTicketMutation,
  useGetTicketByIdQuery,
  useUpdateTicketMutation,
  useUpdateTicketReplyMutation,
  useUpdateTicketStatusMutation,
} from 'src/features/Support/core/http/TicketSystemApi'
import { isEmpty, omit } from 'lodash'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { formatDateWithTime } from 'src/helpers/utils'
import { TicketStatus } from './TicketStatus'
import { TagsBlock } from './TagsBlock'
import { LogsOfTicket } from './LogsOfTicket'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { EditIcon } from 'src/assets/svg'
import { ReplyTicket } from './ReplyTicket'
import { Tags } from './Tags'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import usePermissions from 'src/shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from 'src/features/Settings/models/IPermissions'

export const SupportTicket = () => {
  const { mode, id } = useParams()
  const navigate = useNavigate()

  const [form] = Form.useForm()
  const [viewFiles, setViewFiles] = useState<IAttachementRes[]>([])
  const [files, setFiles] = useState<UploadFile[]>([])
  const [tags, setTags] = useState<string[]>([])
  const [logs, setLogs] = useState<ILog[]>([])
  const [status, setStatus] = useState<TICKET_STATUS_ENUM>()
  const [reply, setReply] = useState('')
  const [attchmntsToRemove, setAttchmntsToRemove] = useState<string[]>([])

  const [createTicket, dCreate] = useCreateTicketMutation()
  const [updateTicket, dUpdate] = useUpdateTicketMutation()
  const [updateStatus, dUpdStatus] = useUpdateTicketStatusMutation()
  const [deleteTicket, dDelete] = useDeleteTicketMutation()
  const [updateReply, dUpdReply] = useUpdateTicketReplyMutation()

  const { data: ticket, isFetching } = useGetTicketByIdQuery(id!, { skip: id === 'new' })
  const { data: userProfile } = useGetProfileQuery()

  const afterSuccess = () => {
    navigate(`/support/tickets/view/${id}`)
  }

  useNotification(
    NOTIFICATION_TYPES.success,
    dUpdate.isSuccess && dUpdStatus.isSuccess,
    null,
    afterSuccess
  )

  useNotification(
    NOTIFICATION_TYPES.success,
    dUpdate.isSuccess && !dUpdStatus.isSuccess,
    null,
    afterSuccess
  )

  useNotification(NOTIFICATION_TYPES.success, dUpdReply.isSuccess, null, afterSuccess)
  useNotification(NOTIFICATION_TYPES.success, dCreate.isSuccess || dDelete.isSuccess, null, () =>
    navigate('/support/tickets')
  )

  useNotification(NOTIFICATION_TYPES.error, dUpdate.isError, dUpdate.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, dCreate.isError, dCreate.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, dDelete.isError, dDelete.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, dUpdStatus.isError, dUpdStatus.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, dUpdReply.isError, dUpdReply.error as ErrorNode)

  const isViewMode = mode === TicketModeEnum.view
  const isEditMode = mode === TicketModeEnum.edit
  const isCreateMode = useMemo(() => !isViewMode && !isEditMode, [])

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()

  const [ticketDetailId] = getModulesIdsByNames([IPermissionsNames['Ticket Detail View']])
  const demandPermissions = [IPermissionsActions.EDIT, IPermissionsActions.DELETE]
  const [canEditTicket, canDeleteTicket] = canPerformAction(ticketDetailId, demandPermissions)

  useEffect(() => {
    if (!isCreateMode) return
    const creatorName = `${userProfile?.firstName || ''} ${userProfile?.lastName || ''}`

    form.setFieldValue('creator', creatorName)
  }, [form, isCreateMode, userProfile])

  useEffect(() => {
    if (isEmpty(ticket)) return
    handleSetInitialForm(ticket)
  }, [form, ticket, userProfile])

  const handleSetInitialForm = (data: ITicketExtended) => {
    form.setFieldsValue({
      ...data,
      createdAt: formatDateWithTime(data.createdAt, userProfile?.calendar),
      creator: data.createdByUser.name,
    })

    setTags(data.tags!)
    setLogs(data.logs.slice(-3!))
    setStatus(data.status)
    setViewFiles(data.ticketAttachments)
    setReply(data.reply)
    setFiles([])
  }

  const dispatchFiles = (arr: UploadFile[]) => setFiles(arr)

  const handleDelete = () => {
    deleteTicket(id!)
  }

  const handleCancel = () => {
    if (isViewMode || isEditMode) handleSetInitialForm(ticket!)
    if (isEditMode) navigate(`/support/tickets/view/${id}`)
    if (isViewMode || (!isEditMode && !isViewMode)) navigate('/support/tickets')
  }

  const handleStatusChange = (status: string) => () => {
    updateStatus({ id: id!, body: { status } })
  }

  const handleRemoveAttachements = (id: string) => {
    setAttchmntsToRemove((p) => [...p, id])
  }

  const onReply = () => {
    const { reply } = form.getFieldsValue()
    const formattedReply = reply.replace(/(<([^>]+)>)/gi, '')

    const fd = new FormData()

    fd.append('reply', formattedReply ? reply : '')
    updateReply({ id: id!, body: fd })
  }

  const onFinish = (values: ITicketForm) => {
    const req = {
      ...omit(
        values,
        'department',
        'responsiblePersonUser',
        'creator',
        'files',
        'tags',
        'status',
        'reply'
      ),
      ...(values.tags && { tags: values.tags.join() }),
      ...(values.department.id && { departmentId: values.department.id }),
      ...(values.responsiblePersonUser.id && {
        responsiblePersonUserId: values.responsiblePersonUser.id,
      }),
    }

    const fd = new FormData()

    Object.entries(req).forEach(([key, value]) => fd.append(key, String(value)))
    files.forEach((value) => value.originFileObj && fd.append('files', value.originFileObj!))
    !isEmpty(attchmntsToRemove) && fd.append('deleteAttachments', attchmntsToRemove.toString())

    if (ticket?.status !== values.status) {
      updateStatus({ id: id!, body: { status: values.status } })
    }

    isEditMode ? updateTicket({ id: id!, body: fd }) : createTicket(fd)
  }

  const loading =
    isFetching ||
    dCreate.isLoading ||
    dUpdate.isLoading ||
    dUpdStatus.isLoading ||
    dDelete.isLoading

  const permissionReply = verifyUserPermissions(userProfile, ticket)
  const permissionDelete = verifyUserPermissions(userProfile, ticket, canDeleteTicket)
  const permissionEdit = verifyUserPermissions(userProfile, ticket, canEditTicket)
  const permissionStatus = verifyUserPermissions(userProfile, ticket)

  const restrictReply =
    !permissionReply ||
    (typeof permissionReply === 'object' && !permissionReply.reply) ||
    ticket?.type === TICKET_TYPE_ENUM['main-ticket'] ||
    ticket?.status === TICKET_STATUS_ENUM.RESOLVED

  const restrictDelete =
    !permissionDelete || (typeof permissionDelete === 'object' && !permissionDelete.delete)

  const restrictEdit =
    !permissionEdit ||
    (typeof permissionEdit === 'object' && !permissionEdit.edit) ||
    ticket?.type === TICKET_TYPE_ENUM['sub-ticket']

  const restrictStatus =
    !permissionStatus || (typeof permissionStatus === 'object' && !permissionStatus.edit)

  const replyView = (!restrictReply && isViewMode) || (isViewMode && reply)

  const logo = isViewMode || isEditMode ? ticket?.createdByUser.logo : userProfile?.logo

  const content = (
    <main className={styles.layout}>
      {ticket?.status !== TICKET_STATUS_ENUM.RESOLVED && (isEditMode || isViewMode) && (
        <div className={styles.headerTopRow}>
          <IconButton
            color='orange'
            type='default'
            icon={<EditIcon />}
            disabled={restrictEdit || isEditMode}
            onClick={() => navigate(`/support/tickets/edit/${id}`)}
          />
        </div>
      )}

      <Form form={form} layout='vertical' onFinish={onFinish}>
        <div className={styles.leftColumn}>
          {!isViewMode && !isEditMode && (
            <StepRegistration
              stepNumber={1}
              stepText='Add General Information'
              mT='11.5px'
              mB='32px'
            />
          )}
          <DetailInfo
            form={form}
            view={isViewMode || (restrictEdit && isEditMode)}
            edit={isEditMode}
            ticketType={ticket?.type}
            logo={logo}
          />
          {!isViewMode && !isEditMode && (
            <StepRegistration
              stepNumber={2}
              stepText='Add Ticket Description'
              mT='32px'
              mB='32px'
            />
          )}
          <TicketDescription viewMode={isViewMode || (restrictEdit && isEditMode)} />
          {replyView && (
            <ReplyTicket form={form} viewMode={restrictReply || isEditMode} reply={reply} />
          )}
          {!isViewMode && !isEditMode && (
            <React.Fragment>
              <StepRegistration stepNumber={3} stepText='Add Attachment' mT='32px' mB='32px' />
              <div className={styles.mb24}>
                <UploaderFiles
                  formEl
                  required
                  name='files'
                  viewFiles={viewFiles}
                  viewMode={isViewMode}
                  allowTypes={compatibleUplaodTypes}
                  dispatchData={dispatchFiles}
                />
              </div>
            </React.Fragment>
          )}
          {!isViewMode && !isEditMode && (
            <React.Fragment>
              <StepRegistration stepNumber={4} stepText='Add Tags' mT='32px' mB='32px' />
              <Tags />
            </React.Fragment>
          )}
        </div>
        {(isEditMode || isViewMode) && (
          <div className={styles.rightColumn}>
            <TicketStatus viewMode={isViewMode || (restrictStatus && isEditMode)} reply={reply} />
            <TagsBlock tags={tags} />
            <LogsOfTicket logs={logs} />
            <UploaderFiles
              btnTitle=''
              viewFiles={viewFiles}
              viewMode={isViewMode /* || isEditMode */}
              allowTypes={compatibleUplaodTypes}
              dispatchData={dispatchFiles}
              attachmentPropName='attachment'
              onRemove={handleRemoveAttachements}
            />
          </div>
        )}
      </Form>
    </main>
  )

  return (
    <div className={styles.innerContainer}>
      {loading ? <Spin>{content}</Spin> : content}
      <Row className={styles.footer} justify='space-between'>
        {(isViewMode || isEditMode) && !restrictDelete ? (
          <Button color='error' size='middle' onClick={handleDelete}>
            Delete
          </Button>
        ) : (
          <div />
        )}
        <div>
          <Button color='blue' size='middle' onClick={handleCancel}>
            Cancel
          </Button>
          {isViewMode && !restrictReply && (
            <Button
              style={{ marginLeft: '10px' }}
              size='middle'
              type='primary'
              color='orange'
              onClick={onReply}
            >
              Reply
            </Button>
          )}
          {isViewMode &&
            (status === TICKET_STATUS_ENUM.OPEN || status === TICKET_STATUS_ENUM.REPLIED) &&
            !restrictStatus && (
              <Button
                style={{ marginLeft: '10px', width: '160px' }}
                size='middle'
                type='primary'
                color='success'
                onClick={handleStatusChange(TICKET_STATUS_ENUM.RESOLVED)}
              >
                Mark as resolved
              </Button>
            )}
          {isViewMode && status === TICKET_STATUS_ENUM.RESOLVED && !restrictStatus && (
            <Button
              style={{ marginLeft: '10px', width: '160px' }}
              size='middle'
              type='primary'
              color='error'
              onClick={handleStatusChange(TICKET_STATUS_ENUM.OPEN)}
            >
              Reopen
            </Button>
          )}
          {!isViewMode && ticket?.status !== TICKET_STATUS_ENUM.RESOLVED && (
            <Button
              style={{ marginLeft: '10px' }}
              htmlType='submit'
              size='middle'
              type='primary'
              color='blue'
              onClick={() => form.submit()}
            >
              {isEditMode ? 'Update Ticket' : 'Create Ticket'}
            </Button>
          )}
        </div>
      </Row>
    </div>
  )
}
