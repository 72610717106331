import { debounce } from 'lodash'
import { Dispatch, SetStateAction, useCallback } from 'react'
import { ITableConf } from 'src/shared/models/ITableConf'

const useSearch = (
  setTableConfig: Dispatch<SetStateAction<ITableConf>>,
  nameSearchQuery = 'search'
) => {
  // eslint-disable-next-line
  const debounceSearch = useCallback(
    debounce((value) => {
      setTableConfig((prev) => ({
        ...prev,
        page: 1,
        [nameSearchQuery]: value?.trim(),
      }))
    }, 300),
    [nameSearchQuery, setTableConfig]
  )

  const handleSearchChange: (event?: React.ChangeEvent<HTMLInputElement> | string) => void = (
    event
  ) => {
    if (typeof event === 'string') {
      return debounceSearch(event)
    }
    debounceSearch(event?.target?.value)
  }

  return { handleSearchChange }
}

export default useSearch
