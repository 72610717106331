import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../../shared/models/ITableConf'
import {
  IMutateUserContacts,
  IUser,
  IUserDetail,
  IUserResetPassword,
  IUserTransformed,
} from '../../models/IUser'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

export const usersApi = createApi({
  reducerPath: 'users',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['IUser', 'IUserTransformed', 'IUserDetail', 'IProfile', 'FilterFields'],
  endpoints: (builder) => ({
    getUsersList: builder.query<ITableResponse<IUser>, ITableConf>({
      query: (params) => ({
        url: 'users',
        params,
      }),
      providesTags: () => ['IUser'],
    }),

    getUserById: builder.query<IUserTransformed, string | number | undefined>({
      query: (id: number) => ({
        url: `users/${id}`,
      }),
      providesTags: (user) => [{ type: 'IUserTransformed', id: user?.id }],
    }),

    createUser: builder.mutation<IUserDetail, FormData>({
      query: (body) => ({
        url: 'users',
        method: 'POST',
        body,
      }),
    }),

    updateUserById: builder.mutation<IUserDetail, { id: string | number; body: FormData }>({
      query: ({ id, body }) => ({
        url: `users/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (user, error) =>
        error ? [] : [{ type: 'IUserTransformed', id: user?.id }, 'IUser', 'FilterFields'],
    }),

    updateUserPassword: builder.mutation<
      IUserResetPassword,
      { id: string; body: IUserResetPassword }
    >({
      query: ({ id, body }) => ({
        url: `users/${id}/reset-password`,
        method: 'PATCH',
        body,
      }),
    }),

    updateUserContactsInfo: builder.mutation<
      IMutateUserContacts,
      { id: string; body: IMutateUserContacts }
    >({
      query: ({ id, body }) => ({
        url: `users/${id}/contacts`,
        method: 'PATCH',
        body,
      }),
    }),

    deleteUserById: builder.mutation<void, string | number | undefined>({
      query: (id) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['IUser', 'FilterFields'],
    }),

    getUsersFiltersList: builder.query<Array<IFilterFields>, void | null>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'users/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }
        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `users/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: ['FilterFields'],
    }),

    exportUsersTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx'; id: string }
    >({
      query: ({ conf, formatFile }) => ({
        url: `/users/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
})

export const {
  useGetUsersListQuery,
  useGetUsersFiltersListQuery,
  useGetUserByIdQuery,
  useCreateUserMutation,
  useUpdateUserByIdMutation,
  useUpdateUserPasswordMutation,
  useUpdateUserContactsInfoMutation,
  useDeleteUserByIdMutation,
  useLazyExportUsersTableQuery,
  useLazyGetUsersListQuery,
} = usersApi
