import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { IBankWalletTransaction } from '../../models/IBankWalletTransactions'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../../shared/models/ITableConf'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { TRANSACTION_STATUS_ENUM } from '../../models/ITransaction'
import {
  IPickupRequest,
  IPickupRequestBody,
  IPickupRequestLog,
  IPickupRequestSub,
} from '../../models/IPickupRequest'
import { TableExportFormatEnum } from '../../../../shared/hooks/table/useTableExport'
import { IRefundRequest, IRefundRequestBody } from '../../models/IRefundRequest'

export const bankWalletTransactionsApi = createApi({
  reducerPath: 'bankWalletTransactions',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'PRList',
    'PR',
    'TransList',
    'RefReq',
    'RefReqList',
    'RefLogs',
    'PRLogs',
    'IFilterFields',
  ],
  endpoints: (builder) => ({
    getBankWalletTransactions: builder.query<ITableResponse<IBankWalletTransaction>, ITableConf>({
      query: (params) => ({
        url: `bank-wallet-transaction`,
        params,
      }),
      transformResponse: (res: ITableResponse<IBankWalletTransaction>) => ({
        ...res,
        items: res.items.map((trans) =>
          trans.status === TRANSACTION_STATUS_ENUM.APPROVED
            ? {
                ...trans,
                children: [],
              }
            : trans
        ),
      }),
      providesTags: () => ['TransList'],
    }),

    getBWTransTableFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'bank-wallet-transaction/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `bank-wallet-transaction/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: ['IFilterFields'],
    }),

    // BANK WALLET PICKUP REQUEST
    createPickupRequest: builder.mutation<IPickupRequest, IPickupRequestBody | FormData>({
      query: (body) => ({
        url: 'pickup-order',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['PRList', 'PRLogs', 'IFilterFields'],
    }),
    updatePickupRequest: builder.mutation<
      IPickupRequest,
      { id: string; body: IPickupRequestBody | FormData }
    >({
      query: ({ id, body }) => ({
        url: `pickup-order/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['PRList', 'PR', 'PRLogs', 'IFilterFields'],
    }),
    getPickupRequestList: builder.query<ITableResponse<IPickupRequest>, ITableConf>({
      query: (params) => ({
        url: 'pickup-order',
        params,
      }),
      transformResponse: (res: ITableResponse<IPickupRequest>) => ({
        ...res,
        items: res.items.map((pickup) => ({ ...pickup, children: [] })),
      }),
      providesTags: () => ['PRList'],
    }),

    getPickupRequestLogsById: builder.query<Array<IPickupRequestLog>, number>({
      query: (id) => ({
        url: `pickup-order/${id}/logs`,
      }),
      providesTags: () => ['PRLogs'],
    }),

    getPickupRequest: builder.query<IPickupRequest, string | undefined>({
      query: (id) => ({
        url: `pickup-order/${id}`,
      }),
      providesTags: () => ['PR'],
    }),
    getPickupRequestSub: builder.query<ITableResponse<IPickupRequestSub>, number>({
      query: (id) => ({
        url: `pickup-order/${id}/sub-transaction`,
      }),
    }),
    rejectPickupRequest: builder.mutation<void, string>({
      query: (id) => ({
        url: `pickup-order/${id}/reject`,
        method: 'PUT',
      }),
      invalidatesTags: () => ['PRList', 'PR', 'TransList', 'PRLogs', 'IFilterFields'],
    }),
    approvePickupRequest: builder.mutation<void, string>({
      query: (id) => ({
        url: `pickup-order/${id}/approve`,
        method: 'PUT',
      }),
      invalidatesTags: () => ['PRList', 'PR', 'TransList', 'PRLogs', 'IFilterFields'],
    }),
    cancelPickupRequest: builder.mutation<null, string>({
      query: (id) => ({
        url: `pickup-order/${id}/cancel`,
        method: 'PUT',
      }),
      invalidatesTags: () => ['PRList', 'PR', 'TransList', 'PRLogs', 'IFilterFields'],
    }),
    addAttachmentsPR: builder.mutation<null, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: `pickup-order/${id}/attachments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['PR'],
    }),

    exportBankWallet: builder.query<
      BlobPart,
      {
        conf: IFileFormatCsvOrXlsx
        formatFile: keyof typeof TableExportFormatEnum
      }
    >({
      query: ({ conf, formatFile }) => ({
        url: '/bank-wallet-transaction/export',
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    exportPickupRequest: builder.query<
      BlobPart,
      {
        conf: IFileFormatCsvOrXlsx
        formatFile: keyof typeof TableExportFormatEnum
      }
    >({
      query: ({ conf, formatFile }) => ({
        url: 'pickup-order/export',
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),
    exportRefundRequest: builder.query<
      BlobPart,
      {
        conf: IFileFormatCsvOrXlsx
        formatFile: keyof typeof TableExportFormatEnum
      }
    >({
      query: ({ conf, formatFile }) => ({
        url: 'refund-request/export',
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    // TODO: need to check in future
    getPRTableFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'pickup-order/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `pickup-order/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: ['IFilterFields'],
    }),

    // BANK WALLET REFUND LIST
    getRefundList: builder.query<ITableResponse<IRefundRequest>, ITableConf>({
      query: (params) => ({
        url: 'refund-request',
        params,
      }),
      transformResponse: (res: ITableResponse<IRefundRequest>) => ({
        ...res,
        items: res.items.map((refund) => ({ ...refund, children: [] })),
      }),
      providesTags: () => ['RefReqList'],
    }),

    getRefundRequestLogsById: builder.query<Array<IPickupRequestLog>, number>({
      query: (id) => ({
        url: `refund-request/${id}/logs`,
      }),
      providesTags: () => ['RefLogs'],
    }),

    createRefundReq: builder.mutation<IPickupRequest, IRefundRequestBody>({
      query: (body) => ({
        url: `refund-request`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['RefReq', 'RefReqList', 'RefLogs', 'IFilterFields'],
    }),

    updateRefundReq: builder.mutation<IPickupRequest, { id: string; body: IRefundRequestBody }>({
      query: ({ id, body }) => ({
        url: `refund-request/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['RefLogs', 'RefReq', 'RefReqList', 'IFilterFields'],
    }),

    getRufundReqById: builder.query<IPickupRequest, string>({
      query: (id) => `refund-request/${id}`,
      providesTags: () => ['RefReq'],
    }),

    getRefundSubList: builder.query<ITableResponse<IPickupRequestSub>, number>({
      query: (id) => ({
        url: `refund-request/${id}/sub-transaction`,
      }),
    }),

    rejectRefundRequest: builder.mutation<null, string>({
      query: (id) => ({
        url: `refund-request/${id}/reject`,
        method: 'PUT',
      }),
      invalidatesTags: () => ['RefLogs', 'RefReq', 'RefReqList', 'TransList', 'IFilterFields'],
    }),
    approveRefundRequest: builder.mutation<null, string>({
      query: (id) => ({
        url: `refund-request/${id}/approve`,
        method: 'PUT',
      }),
      invalidatesTags: () => ['RefLogs', 'RefReq', 'RefReqList', 'TransList', 'IFilterFields'],
    }),
    cancelRefundRequest: builder.mutation<null, string>({
      query: (id) => ({
        url: `refund-request/${id}/cancel`,
        method: 'PUT',
      }),
      invalidatesTags: () => ['RefReq', 'RefReqList', 'TransList', 'RefLogs', 'IFilterFields'],
    }),

    getRefReqTableFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'refund-request/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `refund-request/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: ['IFilterFields'],
    }),
  }),
})

export const {
  useGetBankWalletTransactionsQuery,
  useGetBWTransTableFiltersListQuery,
  useGetPickupRequestListQuery,
  useGetPickupRequestQuery,
  useGetPickupRequestSubQuery,
  useGetPRTableFiltersListQuery,
  useCreatePickupRequestMutation,
  useUpdatePickupRequestMutation,
  useRejectPickupRequestMutation,
  useApprovePickupRequestMutation,
  useCancelPickupRequestMutation,
  useLazyExportPickupRequestQuery,
  useGetRefundListQuery,
  useGetRefReqTableFiltersListQuery,
  useGetRefundSubListQuery,
  useGetRufundReqByIdQuery,
  useRejectRefundRequestMutation,
  useApproveRefundRequestMutation,
  useCancelRefundRequestMutation,
  useUpdateRefundReqMutation,
  useCreateRefundReqMutation,
  useLazyExportRefundRequestQuery,
  useAddAttachmentsPRMutation,
  useGetPickupRequestLogsByIdQuery,
  useGetRefundRequestLogsByIdQuery,
  useLazyExportBankWalletQuery,
} = bankWalletTransactionsApi
