import React, { useCallback, useState } from 'react'
import { Form } from 'antd'

import DetailInfo from './Components/DetailInfo'
import Editor from './Components/Editor'
import Tags from './Components/Tags'
import styles from './styles.module.scss'

const KnowledgeBaseView = () => {
  const [form] = Form.useForm()

  const [tags, setTags] = useState(['Chip', 'Chip 2', 'Chip 3'])

  const onFinish = () => {}

  const onAddNewTag = useCallback(
    (tag: string) => {
      setTags((prev) => [...prev, tag])
      form.setFieldValue('tags', [...form.getFieldValue('tags'), tag])
    },
    [form]
  )

  return (
    <div className={styles.layout}>
      <Form
        form={form}
        layout='vertical'
        className={styles.columnWrapper}
        onFinish={onFinish}
        autoComplete='off'
        initialValues={{
          message: 'Message',
          tags,
        }}
      >
        <DetailInfo />
        <Editor form={form} />
        <Tags onAddNewTag={onAddNewTag} tags={tags} />
      </Form>
    </div>
  )
}

export default KnowledgeBaseView
