import React, { useCallback, useEffect, useState } from 'react'
import { UploadFile } from 'antd/es/upload'
import { Card, Row, Form } from 'antd'
import classNames from 'classnames'
import { startCase } from 'lodash'
import Upload, { UploadProps } from 'antd/lib/upload'

import { requiredFileValidator } from 'src/helpers/validation'

import { IconButton } from '../iconButton/IconButton'
import { AddIcon } from '../../../assets/svg'

// styles
import './style.scss'
import { showConfirmMessage } from '../../api/errorHandler'
import { convertHeicToJpeg } from '../../../helpers/utils'
import { setFiles } from '@testing-library/user-event/utils/edit/setFiles'
import heic2any from 'heic2any'

const isNotAcceptedType = (type: string, types: string[]) => types.find((i) => i === type)

interface IUploadFiles {
  formEl?: boolean
  name?: string
  // eslint-disable-next-line
  viewFiles?: Array<any>
  isDrawer?: boolean
  viewMode?: boolean
  allowTypes: string[]
  attachmentPropName?: string
  btnTitle?: string
  required?: boolean
  isLoading?: boolean
  isStyledTitle?: boolean
  dispatchData: (arr: UploadFile[]) => void
  onRemove?: (id: string) => void
  onSave?: () => void
  isVisibleAttachBtn?: boolean
}

export const UploaderFiles: React.FC<IUploadFiles> = ({
  formEl = false,
  name,
  viewFiles,
  isDrawer,
  viewMode = false,
  allowTypes,
  attachmentPropName = 'receipt',
  btnTitle = 'Add Attachment',
  required,
  isLoading,
  isStyledTitle,
  dispatchData,
  onRemove,
  onSave,
  isVisibleAttachBtn,
}) => {
  // eslint-disable-next-line
  const [uploadedFile, setUploadedFile] = useState<UploadFile[] | any>([])
  const [isDeletingFile, setIsDeletingFile] = useState(false)

  const convertImages = useCallback(async (upload: UploadFile[]) => {
    return await Promise.all(
      upload?.map(async (file) => {
        if (file.type === 'image/heic') {
          const fileOrigin = await heic2any({
            blob: file.originFileObj as unknown as Blob,
            toType: 'image/jpeg',
            quality: 0.5,
          })

          return {
            ...file,
            originFileObj: new File([fileOrigin as Blob], file.name.replace('.heic', '.jpg'), {
              type: 'image/jpeg',
            }),
          }
        }

        return file
      }) || []
    )
  }, [])

  useEffect(() => {
    const files =
      viewFiles?.map(({ id, [attachmentPropName]: { fileName, link } }) => ({
        uid: +id,
        name: fileName || link,
        url: fileName || link,
        status: 'done',
      })) || []
    setUploadedFile(files)
  }, [viewFiles, attachmentPropName])

  const uploadProps: UploadProps = {
    customRequest: (info) => {
      if (typeof info.file !== 'string' && info.onError && info.onSuccess) {
        if (!isNotAcceptedType(info.file.type, allowTypes)) {
          const err = new Error('File type is not compatible!')
          return info.onError(err, 'Unacceptable file type')
        }
        return info.onSuccess('Ok')
      }
    },
    onChange: async (info) => {
      info.file.status = 'done'
      if (!isDeletingFile) {
        const converted = await convertImages(info.fileList)
        setUploadedFile(converted)
        dispatchData(converted as UploadFile[])
      }
    },
    onRemove: (info) => {
      if (typeof info.uid === 'string') return

      setIsDeletingFile(true)
      showConfirmMessage('This File Will Be Deleted!', () => {
        onRemove?.(info.uid)
        setIsDeletingFile(false)
      })
    },
  }

  const isVisibleSave = !!uploadedFile.filter(({ originFileObj }: UploadFile) => originFileObj)
    .length

  return (
    <Card className='uploader uploader-files'>
      <Row className='uploaderRow'>
        {formEl ? (
          <Form.Item
            name={name}
            {...(required
              ? [{ required: true, validator: requiredFileValidator(startCase(name)) }]
              : [])}
          >
            <Upload
              {...uploadProps}
              listType='picture'
              fileList={uploadedFile}
              className={classNames(formEl && 'formElContainer')}
            >
              {!isVisibleAttachBtn && (
                <IconButton
                  style={{ width: '100%' }}
                  icon={<AddIcon />}
                  color='orange'
                  size='middle'
                >
                  {btnTitle}
                </IconButton>
              )}
            </Upload>
          </Form.Item>
        ) : (
          <React.Fragment>
            <p
              className={classNames('upload-title', {
                'title-drawer': isDrawer,
                'title-modal': isStyledTitle,
              })}
            >
              Attachment
            </p>
            {onSave && isVisibleSave && (
              <IconButton
                type='primary'
                color='orange'
                onClick={onSave}
                className='attachment-save'
                disabled={isLoading}
              >
                Save
              </IconButton>
            )}
            <Upload {...uploadProps} listType='picture' fileList={uploadedFile} disabled={viewMode}>
              {!isVisibleAttachBtn && (
                <IconButton
                  disabled={viewMode}
                  color='orange'
                  icon={<AddIcon />}
                  className='attachment-button'
                >
                  {btnTitle}
                </IconButton>
              )}
            </Upload>
          </React.Fragment>
        )}
      </Row>
    </Card>
  )
}
