import { Col, Row, Table } from 'antd'
import { AddIcon, CloudDownloadIcon, SwapArrowsIcon } from '../../../../assets/svg'
import { useContext, useEffect, useState } from 'react'
import { BankAccountsHeader } from './Components/BankAccountsHeader'
import { ISelectedFilters } from '../../../../shared/components/filter/Filter'
import { Button as CustomButton } from '../../../../shared/components/button/Button'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { ColumnsType } from 'antd/es/table'
import {
  useGetCurrencyRateListQuery,
  useGetExchangeRatesTableFiltersListQuery,
  useLazyGetCurrencyRateTableFileByFormatCsvOrXlsxQuery,
} from 'src/features/Finance/core/http/ExchangeRatesApi'
import { CENTER_MODALS, RIGHT_MODALS } from 'src/helpers/contants'

import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { Emitter, EmitterEvents } from 'src/helpers/eventEmitter'
import { TableExportFormatEnum, useTableExport } from 'src/shared/hooks/table/useTableExport'
import { CenterModalContext, RightModalContext } from 'src/features/Modals'
import { useEmptyTable } from '../../../../shared/hooks/table/useEmptyTable'
import { formatDateWithTime } from '../../../../helpers/utils'
import usePermissions from '../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../Settings/models/IPermissions'
import PermissionDenied from '../../../../shared/components/permissionDenied'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import styles from './styles.module.scss'
import ExchangeChart from './Components/ExchangeChart'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { isEmpty, omit } from 'lodash'
import { RecursivelyReplaceCharacters } from '../../../../shared/components/privateMode'
import { ICurrencyRate } from '../../models/ICurencyRate'

export const EnchangeRates = () => {
  const [mappedCurrencies, setMappedCurrencies] = useState<Array<ICurrencyRate>>([])
  const { onOpen } = useContext(RightModalContext)
  const { onOpen: onOpenCenter, onClose: onCloseCenter } = useContext(CenterModalContext)

  const { queryParams, queryFields } = useGetQueryParams()

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data: profile } = useGetProfileQuery()
  const { data: filtersList } = useGetExchangeRatesTableFiltersListQuery()
  const { data: currencyRateList, isFetching } = useGetCurrencyRateListQuery({
    ...omit(tableConf, ['page', 'limit']),
    ...filterValues,
  })

  const [getAgentTableFileByFormatCsvOrXlsxQuery] =
    useLazyGetCurrencyRateTableFileByFormatCsvOrXlsxQuery()

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()

  const [exchangeRatesId] = getModulesIdsByNames([
    IPermissionsNames['Exchange Rate Rate Management'],
  ])

  const demandPermissions = [
    IPermissionsActions.VIEW,
    IPermissionsActions.ADD,
    IPermissionsActions.EXPORT,
    IPermissionsActions.EDIT,
    IPermissionsActions.DELETE,
  ]

  const [canViewRates, canAddRates, canExportRates, canEditRates, canDeleteRates] =
    canPerformAction(exchangeRatesId, demandPermissions)

  /** Table */
  useTableExport({ tableConf, filterValues, promise: getAgentTableFileByFormatCsvOrXlsxQuery })

  const swapCurrencies = (currencyFromId: number, currencyToId: number) => () => {
    const rateToSwap = currencyRateList?.items?.find(
      (curr) => curr?.toCurrency?.id === currencyFromId && curr?.currency?.id === currencyToId
    )

    if (!rateToSwap) return

    const indexToSwap = mappedCurrencies.findIndex(
      (curr) => curr?.toCurrency?.id === currencyToId && curr?.currency?.id === currencyFromId
    )

    const prevArr = [...mappedCurrencies]

    prevArr.splice(indexToSwap, 1, rateToSwap)

    setMappedCurrencies(prevArr)
  }

  const columns: ColumnsType<ICurrencyRate> = [
    {
      title: 'Currency From',
      dataIndex: 'currency',
      width: '20%',
      render: ({ name, id }, row) => (
        <RecursivelyReplaceCharacters>
          <div className={styles['table-column-currency-with-icon']}>
            <div>{name}</div>
            <div
              className={styles['wrapper-swap-arrows-icon']}
              onClick={swapCurrencies(id, row?.toCurrency?.id)}
            >
              <SwapArrowsIcon />
            </div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
      sorter: true,
    },
    {
      title: 'Currency To',
      dataIndex: 'toCurrency',
      width: '20%',
      render: ({ name }) => (
        <RecursivelyReplaceCharacters>
          <div>{name}</div>
        </RecursivelyReplaceCharacters>
      ),
      sorter: true,
    },
    {
      title: 'Buy Rate',
      dataIndex: 'buyingRate',
      width: '20%',
      ellipsis: true,
      render: (buyingRate) => (
        <RecursivelyReplaceCharacters>
          <div className={styles['table-column-currency-with-icon']}>
            <div>{buyingRate}</div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
      sorter: true,
    },
    {
      title: 'Sell Rate',
      dataIndex: 'sellingRate',
      width: '20%',
      sorter: true,
      render: (selling) => <RecursivelyReplaceCharacters>{selling}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Coefficient',
      dataIndex: 'coefficient',
      width: '20%',
      render: () => <RecursivelyReplaceCharacters>1</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Latest Update',
      dataIndex: 'date',
      width: '20%',
      render: (date) => (
        <RecursivelyReplaceCharacters>
          {formatDateWithTime(date, profile?.calendar)}
        </RecursivelyReplaceCharacters>
      ),
    },
  ]

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<ICurrencyRate>({
    total: mappedCurrencies?.length,
    setTableConf,
    setFilterValues,
  })

  const handlerExportClick = () => {
    onOpenCenter(CENTER_MODALS.EXPORT_TABLE, {
      title: 'Export Table',
      onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
      onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      footer: <CustomButton onClick={onCloseCenter}>Cancel</CustomButton>,
    })
  }

  useEffect(() => {
    if (isEmpty(currencyRateList)) return
    // Create a map to track unique currency pairs
    const uniqueCurrencyPairs = new Map()

    // Filter and keep only the first occurrence of each unique currency pair
    const filteredItems = currencyRateList.items.filter((item) => {
      const key1 = `${item.currency.id}-${item.toCurrency.id}`
      const key2 = `${item.toCurrency.id}-${item.currency.id}`

      if (!uniqueCurrencyPairs.has(key1) && !uniqueCurrencyPairs.has(key2)) {
        uniqueCurrencyPairs.set(key1, true)
        uniqueCurrencyPairs.set(key2, true)
        return true
      }

      return false
    })

    setMappedCurrencies(filteredItems)
  }, [currencyRateList])

  return canViewRates ? (
    <div className={styles.layout}>
      <BankAccountsHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filtersList}
      >
        <Row>
          {canExportRates && (
            <Col style={{ marginRight: 10 }}>
              <IconButton
                onClick={handlerExportClick}
                icon={<CloudDownloadIcon />}
                type='primary'
                color='blue'
              />
            </Col>
          )}
          {canAddRates && (
            <Col>
              <IconButton
                icon={<AddIcon />}
                type='primary'
                color='orange'
                onClick={() =>
                  onOpen(RIGHT_MODALS.FINANCE.ENCHANGE_RATES, { title: 'Update Exchange Rate' })
                }
              />
            </Col>
          )}
        </Row>
      </BankAccountsHeader>
      <ExchangeChart />
      <Table
        locale={emptyTableConf}
        className={styles.bankAccountsTable}
        dataSource={mappedCurrencies}
        loading={isFetching}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{ x: 1300 }}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </div>
  ) : (
    <PermissionDenied />
  )
}
