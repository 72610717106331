import { useCallback, useContext, useEffect, useMemo } from 'react'
import { Form, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import FormItem from 'antd/es/form/FormItem'

//components
import { CenterModalContext } from 'src/features/Modals'
import { TextField } from 'src/shared/components/textfield/TextField'

// styles
import styles from './styles.module.scss'

import { requiredValidator } from 'src/helpers/validation'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode } from 'src/shared/api/errorHandler'
import moment from 'moment'
import { DatePickerIcon } from 'src/assets/svg'
import {
  useCreateEventMutation,
  useCreateHolidayMutation,
  useEditEventMutation,
  useEditHolidayMutation,
  useLazyGetEventByIdQuery,
  useLazyGetHolidayByIdQuery,
} from 'src/features/Settings/core/http/EventsHolidaysApi'
import {
  EEventHoliday,
  IEventHoliday,
  IEventHolidayBody,
  IEventHolidayForm,
} from 'src/features/Settings/models/IEventsHolidays'
import { Emitter, EmitterEvents } from 'src/helpers/eventEmitter'
import { RangePicker } from 'src/shared/components/datePicker'
import { getFormCalendarTime } from 'src/helpers/utils'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

export const EventsModal = () => {
  const {
    onClose,
    props: { type, id },
  } = useContext(CenterModalContext)
  const [form] = useForm()
  const { data: profile } = useGetProfileQuery()

  const [createEvent, cEvent] = useCreateEventMutation()
  const [editEvent, eEvent] = useEditEventMutation()
  const [createHoliday, cHoliday] = useCreateHolidayMutation()
  const [editHoliday, eHoliday] = useEditHolidayMutation()
  const [getEventById, dEvent] = useLazyGetEventByIdQuery()
  const [getHolidayById, dHoliday] = useLazyGetHolidayByIdQuery()

  const handleSetInitialForm = useCallback(
    (data: IEventHoliday) => {
      form.setFieldsValue({
        ...data,
        dateRange: getFormCalendarTime(profile?.calendar, [data.start, data.end]),
      })
    },
    [form, profile?.calendar]
  )

  const afterMutation = () => {
    form.resetFields()
    onClose()
  }

  useNotification(NOTIFICATION_TYPES.error, cEvent.isError, cEvent.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, cHoliday.isError, cHoliday.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.success, cEvent.isSuccess, null, afterMutation)
  useNotification(NOTIFICATION_TYPES.success, cHoliday.isSuccess, null, afterMutation)
  useNotification(NOTIFICATION_TYPES.success, eEvent.isSuccess, null, afterMutation)
  useNotification(NOTIFICATION_TYPES.success, eHoliday.isSuccess, null, afterMutation)

  useEffect(() => {
    if (!id) return
    if (type === EEventHoliday.events) getEventById(id)
    if (type === EEventHoliday.holidays) getHolidayById(id)
  }, [getEventById, getHolidayById, id, type])

  useEffect(() => {
    if (dEvent.data && id && type === EEventHoliday.events) {
      handleSetInitialForm(dEvent.data as IEventHoliday)
    }
  }, [dEvent.data, form, handleSetInitialForm, id, type])

  useEffect(() => {
    if (dHoliday.data && id && type === EEventHoliday.holidays) {
      handleSetInitialForm(dHoliday.data as IEventHoliday)
    }
  }, [dHoliday.data, form, handleSetInitialForm, id, type])

  useEffect(() => {
    form.resetFields()
    Emitter.on(EmitterEvents.EVENT_CREATE, form.submit)
    Emitter.on(EmitterEvents.EVENT_RESET, form.resetFields)

    return () => {
      Emitter.off(EmitterEvents.EVENT_CREATE, form.submit)
      Emitter.off(EmitterEvents.EVENT_RESET, form.resetFields)
    }
  }, [form, form.resetFields, form.submit])

  const onFinish = (values: IEventHolidayForm) => {
    const req = {
      title: values.title,
      start: moment(values.dateRange[0].toISOString()).utcOffset(0).startOf('day').toISOString(),
      end: moment(values.dateRange[1].toISOString()).utcOffset(0).endOf('day').toISOString(),
    } as IEventHolidayBody

    if (type === EEventHoliday.events) {
      return values?.id ? editEvent({ body: req, id: values.id }) : createEvent(req)
    }
    if (type === EEventHoliday.holidays) {
      return values?.id ? editHoliday({ body: req, id: values.id }) : createHoliday(req)
    }
  }

  const fieldPlaceholder = useMemo(
    () => (type === EEventHoliday.events ? 'Event Title' : 'Holiday Title'),
    [type]
  )

  return (
    <Spin
      spinning={dEvent.isLoading || dHoliday.isLoading || dEvent.isFetching || dHoliday.isLoading}
    >
      <Form form={form} layout='vertical' onFinish={onFinish} className={styles.formContainer}>
        <FormItem name='id' style={{ display: 'none' }}>
          <TextField />
        </FormItem>

        <FormItem name='title' rules={[{ validator: requiredValidator('Event Title') }]}>
          <TextField placeholder={fieldPlaceholder} />
        </FormItem>

        <FormItem
          name='dateRange'
          className={styles.delegationDatePickerInp}
          rules={[{ required: true, validator: requiredValidator('Date Range') }]}
        >
          <RangePicker
            allowClear
            // @ts-ignore
            popupClassName={styles.popupContainer}
            className={styles.delegationDatePicker}
            placeholder={['Starting Date', 'End Date']}
            disabledDate={(current) => moment().add(-1, 'days') >= current}
            suffixIcon={
              <div>
                <DatePickerIcon />
              </div>
            }
          />
        </FormItem>
      </Form>
    </Spin>
  )
}
