import styles from '../../../InventoryView/styles.module.scss'
import { Widget } from '../../../../../../../shared/components/widget'
import {
  BalanceIcon,
  MonetizationWidgetIcon,
  WidgetClockIcon,
} from '../../../../../../../assets/svg'
import React from 'react'
import { EPinWidgetType } from '../../index'
import { WIDGET_LIST_ENUM } from '../../../../../../../shared/models/WidgetListEnum'

interface IProps {
  widgetData: EPinWidgetType
}

export const Widgets = ({ widgetData }: IProps) => {
  return (
    <div className={styles.widgetsWrapper}>
      <Widget
        icon={<BalanceIcon />}
        title='Current Balance'
        value={widgetData[WIDGET_LIST_ENUM.INVENTORY_VENDOR_CURRENT_BALANCE].value}
        color='orange'
      />
      <Widget
        icon={<MonetizationWidgetIcon />}
        title='Total Active ePINs'
        value={widgetData[WIDGET_LIST_ENUM.TOTAL_ACTIVE_E_PIN].value.count}
      />
      <Widget
        icon={<WidgetClockIcon />}
        title='ePIN sales'
        value={widgetData[WIDGET_LIST_ENUM.TOTAL_SALES_E_PIN].value.usedAmount}
        valueTotal={widgetData[WIDGET_LIST_ENUM.TOTAL_SALES_E_PIN].value.invalidAmount}
      />
    </div>
  )
}
