import styles from './styles.module.scss'
import {
  ArrowLeftIcon,
  ChatFilesIcon,
  ChatPicturesIcon,
  ChatVideosIcon,
} from '../../../../../../../../assets/svg'
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux'
import {
  selectExternalChatMessagingSlice,
  setExternalChatInfoType,
} from '../../../../../../core/store/ExternalChatMessagingSlice'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  EXTERNAL_CHAT_FILE_TYPE_ENUM,
  IExternalChatMessageFile,
} from '../../../../../../models/IExternalChat'
import { Space, Spin } from 'antd'
import { useGetExternalChatFileListQuery } from '../../../../../../core/http/ExternalChatApi'
import { ITableConf } from '../../../../../../../../shared/models/ITableConf'
import { FilesList } from './FIlesList'
import { ImagesList } from './ImagesList'
import { VideosList } from './VideosList'
import { selectExternalChatChatsSlice } from '../../../../../../core/store/ExternalChatChatsListSlice'

export const AttachmentDetail = () => {
  const dispatch = useAppDispatch()
  const { userInfoModalType } = useAppSelector(selectExternalChatMessagingSlice)
  const { externalChat } = useAppSelector(selectExternalChatChatsSlice)
  const [attachments, setAttachments] = useState<Array<IExternalChatMessageFile>>([])
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 100,
  })

  const { data = { items: [], totalCount: 0 }, isFetching } = useGetExternalChatFileListQuery(
    { id: externalChat?.id as number, fileType: userInfoModalType, ...tableConf },
    {
      skip: !externalChat?.id,
    }
  )

  const type = useMemo(() => {
    if (userInfoModalType === EXTERNAL_CHAT_FILE_TYPE_ENUM.PHOTO) return 'Pictures'
    if (userInfoModalType === EXTERNAL_CHAT_FILE_TYPE_ENUM.VIDEO) return 'Videos'
    if (userInfoModalType === EXTERNAL_CHAT_FILE_TYPE_ENUM.FILE) return 'Files'
    return null
  }, [userInfoModalType])

  const handleBackClick = useCallback(() => {
    dispatch(setExternalChatInfoType('Info'))
  }, [dispatch])

  const loadMoreData = () => {
    setTableConf((pre: ITableConf) => ({ ...pre, page: pre.page! + 1 }))
  }

  useEffect(() => {
    if (isFetching) return

    if (tableConf.page === 1) {
      setAttachments(data.items)
    } else {
      setAttachments((p) => [...p, ...data.items])
    }
  }, [isFetching])

  const content = (
    <div>
      <Space className={styles.attachmentHeader} size={30} align='center'>
        <div onClick={handleBackClick} className={styles.attachmentHeaderArrow}>
          <ArrowLeftIcon />
        </div>
        <div className={styles.attachmentHeaderInfo}>
          <span className={styles.attachmentHeaderIcon}>
            {userInfoModalType === EXTERNAL_CHAT_FILE_TYPE_ENUM.FILE && <ChatFilesIcon />}
            {userInfoModalType === EXTERNAL_CHAT_FILE_TYPE_ENUM.PHOTO && <ChatPicturesIcon />}
            {userInfoModalType === EXTERNAL_CHAT_FILE_TYPE_ENUM.VIDEO && <ChatVideosIcon />}
          </span>
          <span className={styles.attachmentHeaderType}>{type}</span>
        </div>
      </Space>
      <div>
        {userInfoModalType === EXTERNAL_CHAT_FILE_TYPE_ENUM.FILE && (
          <FilesList files={attachments} totalCount={data.totalCount} loadMoreData={loadMoreData} />
        )}
        {userInfoModalType === EXTERNAL_CHAT_FILE_TYPE_ENUM.PHOTO && (
          <ImagesList
            files={attachments}
            totalCount={data.totalCount}
            loadMoreData={loadMoreData}
          />
        )}
        {userInfoModalType === EXTERNAL_CHAT_FILE_TYPE_ENUM.VIDEO && (
          <VideosList
            files={attachments}
            totalCount={data.totalCount}
            loadMoreData={loadMoreData}
          />
        )}
      </div>
    </div>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
