import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../redux'

interface IBreadcrumb {
  [key: string]: string | number
}

const initialState: IBreadcrumb = {
  entityName: '',
  entityId: '',
}

interface INameConstraintWithId {
  firstName?: string
  lastName?: string
  name?: string
  id?: number | string
  companyTitle?: string
  key?: keyof IBreadcrumb
}

export const BreadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setBreadcrumb: <T extends INameConstraintWithId>(
      state: IBreadcrumb,
      { payload }: PayloadAction<T>
    ) => {
      if (payload?.id) {
        state.entityId = payload.id
      }
      if (payload?.companyTitle) {
        state.entityName = payload.companyTitle
      }
      if (payload?.name && payload?.key) {
        state[payload.key] = payload.name
      }
      if (payload?.name && !payload?.key) {
        state.entityName = payload.name
      }
      if (payload?.firstName && payload?.lastName) {
        state.entityName = `${payload.firstName} ${payload.lastName}`
      }
    },
    resetBreadcrumb: (state: IBreadcrumb) => {
      state.entityName = ''
      state.entityId = ''
    },
  },
})

export const selectLastBreadCrumb = (state: RootState) => state.breadcrumbsSlice

export const { setBreadcrumb, resetBreadcrumb } = BreadcrumbsSlice.actions
export default BreadcrumbsSlice.reducer
