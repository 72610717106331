import FormItem from 'antd/es/form/FormItem'
import { useParams } from 'react-router-dom'
import { Form, Select, Tabs } from 'antd'
import { RefObject, useCallback, useRef, useState } from 'react'
import styles from '../styles.module.scss'
import { TextField } from '../../../../../../shared/components/textfield/TextField'
import { TextEditor } from '../TextEditor'
import { LANGUAGE_ENUM } from './index.d'
import { requiredValidator, richTextEditorReqValidator } from '../../../../../../helpers/validation'
import { IMessage, INotificationMessageVariable } from '../../../../models/INotificationMessage'
import { omit, startCase } from 'lodash'
import ReactQuill from 'react-quill'
import { deleteHtmlTagsFromString } from '../../../../../../helpers/utils'

interface IProps {
  variables: INotificationMessageVariable[]
}

export const ChannelBlock = ({ variables }: IProps) => {
  const [activeTab, setActiveTab] = useState(LANGUAGE_ENUM.ENGLISH)
  const { mode } = useParams()
  const form = Form.useFormInstance()
  const isViewMode = mode === 'view'

  const engRef: RefObject<ReactQuill> = useRef<ReactQuill>(null)
  const dariRef: RefObject<ReactQuill> = useRef<ReactQuill>(null)
  const pashRef: RefObject<ReactQuill> = useRef<ReactQuill>(null)

  const languageItems = useCallback(
    (id: number) => [
      {
        label: LANGUAGE_ENUM.ENGLISH,
        key: LANGUAGE_ENUM.ENGLISH,
        children: (
          <FormItem name={[id, 'en']}>
            <TextEditor
              form={form}
              name={['messages', id, 'en']}
              viewMode={isViewMode}
              initialContent={form.getFieldValue(['messages', id, 'en'])}
              ref={engRef}
              fieldToValidate='messages'
            />
          </FormItem>
        ),
      },
      {
        label: LANGUAGE_ENUM.DARI,
        key: LANGUAGE_ENUM.DARI,
        children: (
          <FormItem name={[id, 'prs']}>
            <TextEditor
              form={form}
              name={['messages', id, 'prs']}
              viewMode={isViewMode}
              initialContent={form.getFieldValue(['messages', id, 'prs'])}
              ref={dariRef}
              fieldToValidate='messages'
            />
          </FormItem>
        ),
      },
      {
        label: LANGUAGE_ENUM.PASHTU,
        key: LANGUAGE_ENUM.PASHTU,
        children: (
          <FormItem name={[id, 'ps']}>
            <TextEditor
              form={form}
              name={['messages', id, 'ps']}
              viewMode={isViewMode}
              initialContent={form.getFieldValue(['messages', id, 'ps'])}
              ref={pashRef}
              fieldToValidate='messages'
            />
          </FormItem>
        ),
      },
    ],
    [form, isViewMode]
  )

  const Operations = ({ shouldBeDisabled }: { shouldBeDisabled: boolean }) => {
    const splitValue = (val: string) => val.split('.')

    const options = variables?.map((variable) => {
      if (variable.isTable) {
        return {
          label: startCase(variable.name),
          value: `{{#each ${variable.name}}} ${variable.variables.map(
            (nested) => `{{this.${splitValue(nested.name)[1]}}}`
          )} {{/each}}`,
        }
      }

      return {
        label: startCase(variable.name),
        value: `{{${variable.name}}}`,
      }
    })

    const onChange = (val: string) => {
      const quillRef =
        activeTab === LANGUAGE_ENUM.ENGLISH
          ? engRef
          : activeTab === LANGUAGE_ENUM.DARI
          ? dariRef
          : pashRef

      const quill = quillRef?.current?.getEditor()

      if (quill) {
        quill.focus()
        const range = quill.getSelection()
        const cursorPosition = range ? range.index + range.length : quill.getLength()

        quill.insertText(cursorPosition, val)
        quill.setSelection(cursorPosition + val.length, 0)
      }
    }
    return (
      <div className={styles.variableSelectionFilter}>
        <Select
          onSelect={onChange}
          dropdownMatchSelectWidth={false}
          placeholder='Select variable'
          options={options}
          disabled={isViewMode || shouldBeDisabled}
        />
      </div>
    )
  }

  return (
    <FormItem noStyle shouldUpdate>
      {({ getFieldValue }) => (
        <Form.List
          name='messages'
          rules={[
            {
              validator: () => {
                if (getFieldValue('messages') && getFieldValue('messages').length) {
                  const isMessageFilled = getFieldValue('messages').some((message: IMessage) =>
                    Object.entries(omit(message, 'channel', 'id')).some(
                      ([, val]) => deleteHtmlTagsFromString(val)?.length
                    )
                  )

                  if (isMessageFilled) {
                    return Promise.resolve()
                  }
                }
                return Promise.reject('At least one message is required!')
              },
            },
          ]}
        >
          {(fields, {}, { errors }) => (
            <>
              <div className={styles.channelBlock}>
                <FormItem noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const shouldBeDisabled = !getFieldValue('action')
                    const items = fields.map(({ name }) => ({
                      label: form.getFieldValue(['messages', name, 'channel']),
                      key: form.getFieldValue(['messages', name, 'channel']),
                      children: (
                        <>
                          <FormItem name={[name, 'channel']} style={{ display: 'none' }}>
                            <TextField />
                          </FormItem>
                          <Tabs
                            items={languageItems(name)}
                            tabBarExtraContent={<Operations shouldBeDisabled={shouldBeDisabled} />}
                            activeKey={activeTab}
                            onChange={(tab) => setActiveTab(tab as LANGUAGE_ENUM)}
                          />
                        </>
                      ),
                    }))

                    return (
                      <>
                        <Tabs items={items} destroyInactiveTabPane />
                        <div style={{ color: 'red' }}>
                          {errors?.map((error, i) => (
                            <div key={i}>{error}</div>
                          ))}
                        </div>
                      </>
                    )
                  }}
                </FormItem>
              </div>
            </>
          )}
        </Form.List>
      )}
    </FormItem>
  )
}
