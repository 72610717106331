import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { IHydraTable } from '../../models/IHydra'
import { IInventoryTransaction } from '../../models/IInventory'

export const groupSimCardInventoriesApi = createApi({
  reducerPath: 'groupSimCardInventoriesApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getGroupSimInventoryList: builder.query<ITableResponse<IHydraTable>, ITableConf>({
      query: (params) => ({
        url: 'vendors/group-sim-card-inventories',
        params,
      }),
    }),

    getGroupSimTransactionsList: builder.query<
      ITableResponse<IInventoryTransaction>,
      { vendorId: string; params: ITableConf }
    >({
      query: ({ vendorId, params }) => ({
        url: `vendors/${vendorId}/sim-card-inventories/transactions`,
        params,
      }),
    }),

    getGroupSimTransactionsFiltersList: builder.query<Array<IFilterFields>, string>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'vendors/sim-card-inventories/transactions/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `vendors/${arg}/sim-card-inventories/transactions/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),

    getGroupSimInventoryFiltersList: builder.query<Array<IFilterFields>, { date?: string }>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'vendors/group-sim-card-inventories/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `vendors/group-sim-card-inventories/filter-values?filterField=${filter.field}`,
            params: { date: arg.date },
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),
  }),
})

export const {
  useGetGroupSimInventoryListQuery,
  useGetGroupSimInventoryFiltersListQuery,
  useGetGroupSimTransactionsListQuery,
  useGetGroupSimTransactionsFiltersListQuery,
} = groupSimCardInventoriesApi
