import styles from '../../styles.module.scss'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { ArrowLeftIcon } from '../../../../../../../assets/svg'
import {
  IWhatsappManagerButton,
  WHATSAPP_BUTTON_MODAL_STEPS,
} from '../../../../../models/IWhatsappManager'
import React, { useEffect, useState } from 'react'
import { Form, Select, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import { maxLengthValidator, requiredValidator } from '../../../../../../../helpers/validation'
import {
  useCreateWhatsAppButtonMutation,
  useGetWhatsAppActionsListQuery,
  useGetWhatsAppButtonByIdQuery,
  useUpdateWhatsAppButtonMutation,
} from '../../../../../core/http/WhatsAppManagerApi'
import { Button } from '../../../../../../../shared/components/button/Button'
import { isEmpty } from 'lodash'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../shared/api/errorHandler'
import classNames from 'classnames'

interface IProps {
  onChangeStep: (step: WHATSAPP_BUTTON_MODAL_STEPS, id: string | null) => void
  id: string | null
}

export const ViewButtonStep = ({ onChangeStep, id }: IProps) => {
  const [mode, setMode] = useState('view')
  const [form] = useForm()

  const { data: actions, isFetching: isFetchingActions } = useGetWhatsAppActionsListQuery({})
  const { data, isFetching } = useGetWhatsAppButtonByIdQuery(id as string, { skip: !id })
  const [updBtn, updBtnResp] = useUpdateWhatsAppButtonMutation()
  const [crBtn, crBtnResp] = useCreateWhatsAppButtonMutation()

  const afterUpdate = () => {
    onChangeStep(WHATSAPP_BUTTON_MODAL_STEPS.VIEW_BUTTON, crBtnResp.data?.id ?? id)
    setMode('view')
  }

  useNotification(NOTIFICATION_TYPES.success, updBtnResp.isSuccess, null, afterUpdate)
  useNotification(NOTIFICATION_TYPES.success, crBtnResp.isSuccess, null, afterUpdate)
  useNotification(NOTIFICATION_TYPES.error, updBtnResp.isError, updBtnResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, crBtnResp.isError, crBtnResp.error as ErrorNode)

  const isLoading = isFetching || isFetchingActions || crBtnResp.isLoading || updBtnResp.isLoading
  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const onFinish = (values: IWhatsappManagerButton) => {
    id ? updBtn({ id: id as string, body: values }) : crBtn(values)
  }

  const onEdit = () => {
    if (!id) {
      return form.submit()
    }

    if (isViewMode) {
      return setMode('edit')
    }

    return form.submit()
  }

  useEffect(() => {
    if (isEmpty(data)) return

    form.setFieldsValue(data)
  }, [data, form])

  const content = (
    <div>
      <IconButton
        color='blue'
        icon={<ArrowLeftIcon />}
        onClick={() => onChangeStep(WHATSAPP_BUTTON_MODAL_STEPS.LIST, null)}
      />
      <Form layout='vertical' form={form} className={styles.viewButtonForm} onFinish={onFinish}>
        <Wrapper title='Detail Info'>
          {id && (
            <FormItem name='id' label='ID'>
              <TextField disabled />
            </FormItem>
          )}

          <FormItem
            name='title'
            label='Title'
            rules={[
              {
                validator: requiredValidator('Title'),
                required: true,
              },
              {
                validator: maxLengthValidator(20),
              },
            ]}
          >
            <TextField disabled={Boolean(id) && isViewMode} placeholder='Enter Title' />
          </FormItem>

          <FormItem
            name='actionId'
            label='Action'
            className='noBottomMargin'
            rules={[
              {
                validator: requiredValidator('Action'),
                required: true,
              },
            ]}
          >
            <Select placeholder='Select Action' disabled={Boolean(id) && isViewMode} allowClear>
              {actions?.items.map((action) => (
                <Select.Option key={action.id} value={action.id}>
                  {action.description}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Wrapper>

        <FormItem className={classNames(styles.actionButton, 'noBottomMargin')}>
          <Button color='orange' type='primary' block onClick={onEdit} disabled={isLoading}>
            {id && isViewMode && 'Edit'}
            {id && isEditMode && 'Save Changes'}
            {!id && 'Create New'}
          </Button>
        </FormItem>
      </Form>
    </div>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
