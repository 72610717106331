import { useGetSendEmoneyTransactionByIdQuery } from '../../../../../../Finance/core/http/PurchaseOrderApi'
import React, { useContext, useEffect } from 'react'
import { RightModalContext } from '../../../../../../Modals'
import { Form, Spin } from 'antd'
import styles from '../styles.module.scss'
import { DetailInfo } from './DetailInfo'
import { useGetSubTransactionsQuery } from '../../../../../core/Subtransactions'
import { TRANSACTION_CATEGORY_ENUM } from '../../../../../../Finance/models/ITransaction'
import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import { SubTransactionDetail } from '../Components/SubTransactionDetail'
import { startCase } from 'lodash'
import { formatDateWithTime } from '../../../../../../../helpers/utils'
import { useGetProfileQuery } from '../../../../../../Profile/core/http/Profile'

export const SendEmoneyModal = () => {
  const [form] = Form.useForm()
  const {
    props: { id },
  } = useContext(RightModalContext)

  const { data, isFetching } = useGetSendEmoneyTransactionByIdQuery(id)
  const { data: profile, isFetching: isFetchingProfile } = useGetProfileQuery()
  const { data: subTransactions, isFetching: isFetchingSub } = useGetSubTransactionsQuery(
    { id: id as string, transactionCategory: TRANSACTION_CATEGORY_ENUM.SEND_EMONEY },
    {
      skip: !id,
    }
  )

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data)
    }
  }, [data, form])

  useEffect(() => {
    if (subTransactions) {
      form.setFieldsValue({
        subTransactions: subTransactions?.items.map((sub) => ({
          ...sub,
          sender: startCase(sub[sub?.sender]?.name.toLowerCase()),
          receiver: startCase(sub[sub?.receiver]?.name.toLowerCase()),
          createdAt: formatDateWithTime(sub?.createdAt, profile?.calendar),
        })),
      })
    }
  }, [form, profile?.calendar, subTransactions])

  const isLoading = isFetching || isFetchingSub || isFetchingProfile

  const content = (
    <Form form={form} className={styles.formContainer} layout='vertical'>
      <DetailInfo status={data?.status} />
      {subTransactions && subTransactions?.items.length > 0 ? (
        <Wrapper title='Sub Transaction Detail'>
          <Form.List name='subTransactions'>
            {(fields) => (
              <>
                {fields?.length > 0
                  ? fields.map(({ key, ...rest }) => {
                      return <SubTransactionDetail {...rest} key={key} />
                    })
                  : null}
              </>
            )}
          </Form.List>
        </Wrapper>
      ) : null}
    </Form>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
