import './App.scss'
import { ConfigProvider } from 'antd'
import ErrorBoundary from '../../shared/components/errorBoundary/ErrorBoundary'
import { ReactNode, useEffect } from 'react'
import { container } from '../../index'
import { useGetProfileQuery } from '../Profile/core/http/Profile'
import moment from 'moment-timezone'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'

dayjs.extend(timezone)
dayjs.extend(utc)

export function App({ children }: { children: ReactNode }) {
  const { data: profile } = useGetProfileQuery()

  useEffect(() => {
    if (profile?.timeZone) {
      moment.tz.setDefault(profile.timeZone as string)
      dayjs.tz.setDefault(profile.timeZone as string)
    }
  }, [profile?.timeZone])

  return (
    <ConfigProvider getPopupContainer={(trigger) => trigger?.parentElement || container}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </ConfigProvider>
  )
}
