import moment from 'moment'

export const formatPhoneNumber = (phoneNumber = '') => {
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2$3')
}

interface IProps {
  num: string | number
  currencyConf?: {
    isCurrency?: boolean
    currencyCode?: string
    locale?: string
  }
}

export const formatNumberToLocale: (num: IProps['num'], curr?: IProps['currencyConf']) => string = (
  num = 0,
  currencyConf = {}
): string => {
  const { locale = 'en-US' } = currencyConf
  // const currencyStyle = isCurrency ? { style: 'currency', currency: currencyCode } : {}

  return new Intl.NumberFormat(locale).format(num as number)
}

export const getSecondsFromDateInFuture = (timeISO: string) => {
  const now = moment.utc(new Date())
  const end = moment(timeISO)
  const duration = moment.duration(now.diff(end))
  const seconds = duration.asSeconds()
  return Math.round(seconds)
}
