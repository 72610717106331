import styles from './styles.module.scss'
import { SalesNetworkConfigurationTabs } from './Components/Tabs/index'

export const SalesNetworkConfiguration = () => {
  return (
    <div className={styles.layout}>
      <SalesNetworkConfigurationTabs />
    </div>
  )
}
