import { DashboardTableWrapper } from '../../../../../Dashboard/pages/Overview/Components/DashboardTableWrapper'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { IProcurement } from '../../../../models/ITransaction'
import React, { useMemo } from 'react'
import { moment, replaceCharactersWithAsterisks } from '../../../../../../helpers/utils'
import {
  PIE_CHART_COLORS,
  PIE_CHART_COLORS_WITH_TWO_ITEMS,
} from '../../../../../../helpers/contants'
import styles from '../../../../../Dashboard/pages/Overview/styles.module.scss'
import { NotFoundPage } from '../../../../../NotFoundPage'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IProps {
  widgetData: IProcurement[]
  frequency: string
}

export const ProcurementBarChart = ({ widgetData, frequency }: IProps) => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const mappedData = useMemo(() => {
    const result: Array<{ [key: string]: number | string }> = []
    const hours = Array(25)
      .fill(undefined)
      .map((_, index) => index)

    widgetData?.forEach((entry, index) => {
      const period = moment(entry?.period)
      let name: string

      if (frequency === 'hour') {
        name = period?.hour(hours[index])?.format('HH')
      } else {
        name = period?.format('DD MMM')
      }

      const transformedEntry: { [key: string]: number | string } = {
        name,
      }

      entry?.data?.forEach((item) => {
        transformedEntry[item?.vendor?.name] = item?.amount
        transformedEntry[item?.vendor?.name + '_color'] = item?.vendor?.color
      })

      result.push(transformedEntry)
    })
    return result
  }, [frequency, widgetData])

  const barNamesList = useMemo(() => {
    if (!mappedData.length) return []

    const uniqueVendorNames = new Set<string>()
    const vendorNamesMap: { [key: string]: string } = {}

    mappedData.forEach((entry) => {
      Object.keys(entry).forEach((key) => {
        if (key !== 'name' && typeof entry[key] === 'number') {
          uniqueVendorNames.add(key)
          vendorNamesMap[key] = entry[key + '_color'] as string
        }
      })
    })

    return Array.from(uniqueVendorNames).map((vendorName) => ({
      vendorName,
      color: vendorNamesMap[vendorName],
    }))
  }, [mappedData])

  const colors = useMemo(
    () => (barNamesList?.length <= 2 ? PIE_CHART_COLORS_WITH_TWO_ITEMS : PIE_CHART_COLORS),
    [barNamesList?.length]
  )

  const shouldDisplay = useMemo(
    () => mappedData?.some((pickup) => Object.keys(pickup).length > 1),
    [mappedData]
  )

  return (
    <DashboardTableWrapper title='Procurement' infoText='Procurement Bar Chart' withPadding={false}>
      {shouldDisplay ? (
        <ResponsiveContainer width='100%' height={300}>
          <BarChart
            width={900}
            height={300}
            data={mappedData}
            margin={{
              top: 30,
              right: 10,
              left: 10,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis
              tickFormatter={(value) =>
                isPrivateMode
                  ? replaceCharactersWithAsterisks(
                      new Intl.NumberFormat('en-US', {
                        notation: 'compact',
                        compactDisplay: 'short',
                      }).format(value)
                    )
                  : new Intl.NumberFormat('en-US', {
                      notation: 'compact',
                      compactDisplay: 'short',
                    }).format(value)
              }
            />
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const time = payload[0]?.payload?.name

                  return (
                    <RecursivelyReplaceCharacters>
                      <div className={styles.tooltipSummary}>
                        <div className={styles.tooltipRow}>
                          <div className={styles.tooltipRowValue}>{time}</div>
                        </div>
                        {payload?.map((value, i) => (
                          <div
                            key={i}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gridGap: 10,
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                color: payload[i]?.color,
                              }}
                            >
                              {payload[i]?.name}:
                            </div>
                            <div className={styles.value}>{payload[i]?.value}</div>
                          </div>
                        ))}
                      </div>
                    </RecursivelyReplaceCharacters>
                  )
                }

                return null
              }}
            />
            {!isPrivateMode && (
              <Legend
                verticalAlign='top'
                align='left'
                wrapperStyle={{ left: 40, top: 10 }}
                iconType='circle'
              />
            )}

            {barNamesList?.map((entity, i) => (
              <Bar
                key={entity?.vendorName}
                dataKey={entity?.vendorName}
                stackId='a'
                fill={entity?.color ?? colors[i]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <NotFoundPage />
      )}
    </DashboardTableWrapper>
  )
}
