import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetUserByIdQuery,
  useUpdateUserByIdMutation,
  useUpdateUserContactsInfoMutation,
  useUpdateUserPasswordMutation,
} from '../../../../../../core/http/UsersManagementApi'
import { Col, Form, Row, Space, Spin } from 'antd'
import styles from './styles.module.scss'
import { DetailInfo } from '../Components/DetailInfo'
import { useCallback, useEffect, useState } from 'react'
import { UploaderPhotos2 } from '../../../../../../../../shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import { RcFile } from 'antd/es/upload'
import { Button } from '../../../../../../../../shared/components/button/Button'
import { Address } from '../Components/Address'
import { formatDateWithTime } from '../../../../../../../../helpers/utils'
import { PasswordBlock } from '../Components/Password'
import { ContactInfo } from '../Components/ContactInfo'
import { SETTINGS_ENUM } from '../../../../../../../../routes/settings'
import {
  IContactChanel,
  IMutateUserContacts,
  IUserCreate,
  IUserDetail,
  IUserTransformed,
} from '../../../../../../models/IUser'
import { omit } from 'lodash'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../../shared/api/errorHandler'
import { useAppDispatch } from '../../../../../../../../redux'
import { setBreadcrumb } from '../../../../../../../app/core/BreadcrumbsSlice'
import usePermissions from '../../../../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../../../models/IPermissions'
import PermissionDenied from '../../../../../../../../shared/components/permissionDenied'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { IByUser } from '../../../../../../../../shared/models/IByUser'
import { externalChatSocketConnection } from '../../../../../../../../shared/sockets'
import { getSavedAuthData } from '../../../../../../../../shared/api'

export const UserDetailView = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const token = getSavedAuthData()?.tokens?.accessToken
  const { id, mode } = useParams<{ id: string; mode: 'view' | 'edit' }>()

  const [isPasswordEdit, setIsPasswordEdit] = useState(true)
  const [contactToDelete, setContactToDelete] = useState<Array<number>>([])
  const [picture, setPicture] = useState<RcFile>()
  const [isLogoDelete, setIsLogoDelete] = useState(false)

  const { data, isLoading } = useGetUserByIdQuery(id, { skip: !id })
  const { data: profile } = useGetProfileQuery()

  const [updUser, updUserResp] = useUpdateUserByIdMutation()
  const [updPass, updPassResp] = useUpdateUserPasswordMutation()
  const [updCont, updContResp] = useUpdateUserContactsInfoMutation()

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const [usersId] = getModulesIdsByNames([IPermissionsNames.Users])
  const demandPermissions = [IPermissionsActions.EDIT, IPermissionsActions.VIEW]
  const [canEditUser, canViewUser] = canPerformAction(usersId, demandPermissions)
  const notAllowedContent = (isViewMode && !canViewUser) || (isEditMode && !canEditUser)

  const afterUpdate = () => {
    setIsPasswordEdit(true)
    externalChatSocketConnection.connect(token)
    navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT}/view/${id}`)
  }

  /** Notifications */
  useNotification(
    NOTIFICATION_TYPES.success,
    updUserResp.isSuccess && updContResp.isSuccess,
    null,
    afterUpdate
  )
  useNotification(NOTIFICATION_TYPES.error, updUserResp.isError, updUserResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, updPassResp.isError, updPassResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, updContResp.isError, updContResp.error as ErrorNode)

  const isUpdating = updUserResp.isLoading || updContResp.isLoading || updPassResp.isLoading

  useEffect(() => {
    if (data) {
      dispatch(setBreadcrumb(data))
    }
  }, [data, dispatch])

  const setDefaultValues = useCallback(() => {
    if (data as IUserTransformed) {
      form.setFieldsValue({
        ...data,
        createdAt: formatDateWithTime(data?.createdAt, profile?.calendar),
        countryId: data?.country?.id,
        provinceId: data?.province?.id,
        districtId: data?.district?.id,
        skillIds: data?.skills?.map((skill: Omit<IByUser, 'logo'>) => skill?.id),
      })
    }
  }, [data, form, profile])

  const onCancelButtonClick = () => {
    setIsPasswordEdit(true)
    if (isViewMode) {
      return navigate(SETTINGS_ENUM.USERS_MANAGEMENT)
    }
    if (isEditMode) {
      form.resetFields()
      setDefaultValues()
      return navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT}/view/${id}`)
    }
  }

  const onSubmitButtonClick = () => {
    if (isViewMode) {
      return navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT}/edit/${id}`)
    }
    form.submit()
  }

  const onContactDelete = (id: number) => {
    setContactToDelete((prev) => [...prev, id])
  }

  const togglePasswordEdit = () => {
    form.resetFields(['oldPassword', 'newPassword'])
    setIsPasswordEdit((prevState) => !prevState)
  }

  const onFinish = (values: IUserDetail) => {
    const oldPassword = form.getFieldValue('oldPassword')
    const newPassword = form.getFieldValue('newPassword')

    const obj = omit(values, [
      'department',
      'userPosition',
      'userRole',
      'nativeLanguage',
      'confirmPassword',
      'newPassword',
      'oldPassword',
      'logo',
      'contactChannels',
      'addressLine2',
    ]) as IUserCreate

    obj.departmentId = values.department?.id
    obj.nativeLanguageId = values.nativeLanguage?.id
    obj.userRoleId = values.userRole.id
    obj.userPositionId = +values.userPosition.id
    obj.addressLine2 = !values.addressLine2 ? null : values.addressLine2

    if (isLogoDelete) {
      obj.isDeleteLogo = isLogoDelete
    }

    const contactToCreate: Array<Omit<IContactChanel, 'id'>> = []
    const contactToUpdate: Array<IContactChanel> = []

    form.getFieldValue('contactChannels').forEach((bundle: IContactChanel) => {
      if (Object.hasOwn(bundle, 'id')) {
        contactToUpdate.push(bundle)
        return
      }
      contactToCreate.push(bundle)
    })

    const userContactsMutation: IMutateUserContacts = {
      toCreate: contactToCreate,
      toUpdate: contactToUpdate,
      toDelete: contactToDelete,
    }

    const userPasswordMutation = {
      oldPassword,
      newPassword,
    }

    const fd = new FormData()
    fd.append('user', JSON.stringify(obj))
    if (picture) {
      fd.append('logo', picture)
    }

    updCont({ id: id as string, body: userContactsMutation })
    updUser({ id: id as string, body: fd })
    if (oldPassword && newPassword) {
      updPass({ id: id as string, body: userPasswordMutation })
    }
  }

  useEffect(() => {
    setContactToDelete([])
  }, [updUserResp.requestId])

  useEffect(() => {
    setDefaultValues()
  }, [setDefaultValues])

  const content = (
    <>
      {notAllowedContent && <PermissionDenied />}
      {((isViewMode && canViewUser) || (isEditMode && canEditUser)) && (
        <div className='fullVH'>
          <div className={styles.layout}>
            <Form
              form={form}
              layout='vertical'
              className={styles.columnWrapper}
              onFinish={onFinish}
              autoComplete='new-password'
            >
              <div className={styles.leftColumn}>
                <DetailInfo view={isViewMode} />
                <ContactInfo view={isViewMode} form={form} onContactDelete={onContactDelete} />
                <Address form={form} view={isViewMode} />
                {isEditMode && (
                  <PasswordBlock
                    view={isViewMode}
                    isEditMode={isEditMode}
                    isPasswordEdit={isPasswordEdit}
                    togglePasswordEdit={togglePasswordEdit}
                  />
                )}
              </div>
              <div>
                <UploaderPhotos2
                  form={form}
                  key={data?.logo || 'emptyUserLogo'}
                  defaultPreviewImage={data?.logo as string}
                  setToDeleteLogo={setIsLogoDelete}
                  onChange={(photo) => {
                    setPicture(photo[0].originFileObj as RcFile)
                    setIsLogoDelete(false)
                  }}
                  view={isViewMode}
                  isPersonPreview
                  title='Profile Picture'
                />
              </div>
            </Form>
          </div>
          <div className={styles.footerWrapper}>
            <Row className={styles.footerStyle} justify='end'>
              <Col>
                <Space size={10}>
                  <div className='cancelButtonWrapper'>
                    <Button
                      color='blue'
                      size='middle'
                      onClick={onCancelButtonClick}
                      disabled={isUpdating}
                      block
                    >
                      Cancel
                    </Button>
                  </div>
                  {canEditUser && (
                    <div style={{ width: isViewMode ? 121 : 130 }}>
                      <Button
                        htmlType='submit'
                        size='middle'
                        type='primary'
                        onClick={onSubmitButtonClick}
                        disabled={isUpdating}
                        block
                      >
                        {isViewMode ? 'Edit User' : 'Save Changes'}
                      </Button>
                    </div>
                  )}
                </Space>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  )

  return isLoading || isUpdating ? <Spin>{content}</Spin> : content
}
