import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import FormItem from 'antd/es/form/FormItem'
import { CirclePicker } from 'react-color'
import classNames from 'classnames'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import styles from './styles.module.scss'

interface IProps extends IViewMode {
  title: string
}

export const VendorMasterColorBlock = ({ title, view }: IProps) => {
  return (
    <Wrapper title={title}>
      <FormItem noStyle shouldUpdate>
        {({ getFieldValue, setFieldValue }) => (
          <FormItem name='color' className='noBottomMargin'>
            <CirclePicker
              onChangeComplete={(color) => setFieldValue('color', color?.hex)}
              color={getFieldValue('color') || ''}
              className={classNames(styles.circlePicker, { [styles.disabledPicker]: view })}
              colors={[
                '#f44336',
                '#e91e63',
                '#9c27b0',
                '#673ab7',
                '#3f51b5',
                '#2196f3',
                '#03a9f4',
                '#00bcd4',
                '#009688',
                '#4caf50',
                '#8bc34a',
                '#cddc39',
                '#ffeb3b',
                '#ffc107',
                '#ff9800',
                '#ff5722',
                '#795548',
                '#607d8b',
                '#9e9e9e',
                '#607d34',
              ]}
            />
          </FormItem>
        )}
      </FormItem>
    </Wrapper>
  )
}
