import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../shared/api'
import { ITopLocation } from '../models/ITopLocation'
import { ITableConf } from '../../../shared/models/ITableConf'

export const dashboardApi = createApi({
  reducerPath: 'dashboard',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getDashboardSalesHistory: builder.query<Array<ITopLocation>, ITableConf>({
      query: (params) => ({
        url: 'sales-history/statistic-for-map',
        params,
      }),
      transformResponse: (resp: ITopLocation[]) =>
        resp
          ?.sort((a, b) => b?.salesAmount - a?.salesAmount)
          ?.map((location, i) => ({
            ...location,
            id: i + 1,
          })),
    }),
  }),
})

export const { useGetDashboardSalesHistoryQuery } = dashboardApi
