import styles from './styles.module.scss'
import { Widgets } from './Components/Widgets'
import { InventoryChart } from './Components/InventoryChart'
import { TransactionsTable } from './Components/TransactionsTable'
import { LogoBlock } from './Components/LogoBlock'
import { SimCardList } from './Components/SimCardList'
import { useEffect, useMemo, useState } from 'react'
import { FREQUENCY_ENUM, WIDGET_LIST_ENUM } from '../../../../../shared/models/WidgetListEnum'
import { widgetsSocketConnection } from '../../../../../shared/sockets'
import { IWidget } from '../../../../../shared/models/IWidget'
import { useParams } from 'react-router-dom'
import { useLocalStorageGetByKey } from '../../../../../shared/hooks/useLocalStorageGetByKey'
import { useGetVendorByIdQuery } from '../../../../Settings/core/http/VendorManagementApi'
import { Spin } from 'antd'
import { isEmpty } from 'lodash'
import { useAppDispatch } from '../../../../../redux'
import { setBreadcrumb } from '../../../../app/core/BreadcrumbsSlice'
import { INVENTORY_CHART_TYPE_ENUM } from '../../../models/IInventory'
import { getFrequency, moment } from '../../../../../helpers/utils'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import { ITableConf } from '../../../../../shared/models/ITableConf'
import { ITableResponse } from '../../../../../shared/models/ITableResponse'

export interface ISimCard {
  id: string
  simNumber: string
  balance: number
  status: boolean
}

export interface ISimCardTable extends ISimCard {
  children?: Array<ISimCard>
}

const initialState = {
  [WIDGET_LIST_ENUM.SIM_CARD_TOTAL_BALANCE]: {
    value: 0,
  },
  [WIDGET_LIST_ENUM.SIM_CARD_COUNT_BY_STATUS]: {
    value: [{ status: false, count: 0 }],
  },
  [WIDGET_LIST_ENUM.SIM_CARD_LIST]: {
    value: {
      totalCount: 0,
      items: [],
    },
  },
  [WIDGET_LIST_ENUM.SIM_CARD_SALES_AND_PO]: {
    value: [
      {
        po: 0,
        sales: 0,
      },
    ],
  },
  [WIDGET_LIST_ENUM.SIM_CARD_CHART]: {
    value: [{ period: '', cashOut: 0, po: 0, cashIn: 0, sales: 0 }],
    frequency: FREQUENCY_ENUM.MONTH,
  },
}

export type HYDRA_INVENTORY_WIDGETS_TYPE = typeof initialState

export const HydraInventoryView = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const [simTableConf, setSimTableConf] = useState<ITableConf>({
    page: 1,
    limit: 10,
  })
  const [chartType, setChartType] = useState(INVENTORY_CHART_TYPE_ENUM.YEAR)
  const [widgetData, setWidgetData] = useState(initialState)

  const { data: profile } = useGetProfileQuery()
  const { data: vendor, isFetching } = useGetVendorByIdQuery(id as string, { skip: !id })

  const unitOfTime = useMemo(() => {
    if (chartType === INVENTORY_CHART_TYPE_ENUM.MONTH) {
      return 'month'
    }

    if (chartType === INVENTORY_CHART_TYPE_ENUM.WEEK) {
      return 'week'
    }

    return 'year'
  }, [chartType])

  useEffect(() => {
    if (!id || isEmpty(profile)) return
    const from =
      unitOfTime === 'year'
        ? moment().subtract(1, 'year').add(1, 'month').toISOString(true)
        : moment().startOf(unitOfTime).toISOString(true)
    const to =
      unitOfTime === 'year'
        ? moment().endOf('month').toISOString(true)
        : moment().endOf(unitOfTime).toISOString(true)

    const socket = widgetsSocketConnection.getSocket()
    const messagePayload = [
      {
        widget: WIDGET_LIST_ENUM.SIM_CARD_TOTAL_BALANCE,
        filters: { groupVendorId: Number(id) },
      },
      {
        widget: WIDGET_LIST_ENUM.SIM_CARD_COUNT_BY_STATUS,
        filters: { groupVendorId: Number(id) },
      },
      {
        widget: WIDGET_LIST_ENUM.SIM_CARD_SALES_AND_PO,
        filters: { groupVendorId: Number(id) },
      },
      {
        widget: WIDGET_LIST_ENUM.SIM_CARD_LIST,
        filters: { groupVendorId: Number(id), ...simTableConf },
      },
      {
        widget: WIDGET_LIST_ENUM.SIM_CARD_CHART,
        filters: { groupVendorId: Number(id), from, to, frequency: getFrequency(from, to) },
      },
    ]

    socket?.emit('connect-widget', {
      data: messagePayload,
    })

    return () => {
      socket?.emit('disconnect-widget', {
        data: messagePayload,
      })
    }
  }, [id, profile, simTableConf, unitOfTime])

  useEffect(() => {
    if (isEmpty(profile)) return
    const socket = widgetsSocketConnection.getSocket()
    socket?.on('widget-data', (data: IWidget) => {
      const balance = JSON.parse(data?.data?.jsonData)
      setWidgetData((prevState) => ({
        ...prevState,
        [data.widget]: Object.keys(balance).length
          ? {
              value: balance?.value,
              ...(data['filters']['frequency'] && { frequency: data['filters']['frequency'] }),
            }
          : {},
      }))
    })
  }, [profile])

  useEffect(() => {
    if (isEmpty(vendor)) return
    dispatch(setBreadcrumb(vendor))
  }, [dispatch, vendor])

  const content = (
    <div className={styles.layout}>
      <div className={styles.columnsWrapper}>
        <div className={styles.leftColumn}>
          <Widgets data={widgetData} />
          <InventoryChart
            setChartType={setChartType}
            chartType={chartType}
            data={widgetData[WIDGET_LIST_ENUM.SIM_CARD_CHART].value}
            frequency={widgetData[WIDGET_LIST_ENUM.SIM_CARD_CHART].frequency}
          />
          <TransactionsTable />
        </div>
        <div className={styles.rightColumn}>
          <LogoBlock isPrivateMode={isPrivateMode} logo={vendor?.logo} />
          <SimCardList
            data={
              widgetData[WIDGET_LIST_ENUM.SIM_CARD_LIST]
                .value as unknown as ITableResponse<ISimCardTable>
            }
            tableConf={simTableConf}
            setTableConf={setSimTableConf}
          />
        </div>
      </div>
    </div>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
