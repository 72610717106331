import React, { memo } from 'react'
import FormItem from 'antd/es/form/FormItem'
import TextArea from 'antd/es/input/TextArea'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { TextField } from 'src/shared/components/textfield/TextField'
import styles from '../../styles.module.scss'

const VendorInformation = () => {
  return (
    <Wrapper title='Vendor Information'>
      <div className={styles.dblRow}>
        <FormItem name={['vendor', 'name']} label='Vendor Name'>
          <TextField disabled />
        </FormItem>

        <FormItem name={['product', 'name']} label='Product Name'>
          <TextField disabled />
        </FormItem>
      </div>
      <FormItem name='vendorTransactionId' label='Vendor Transaction ID'>
        <TextField disabled />
      </FormItem>
      <div className={styles.dblRow}>
        <FormItem name='vendorBalanceBefore' label='Vendor Balance Before'>
          <TextField disabled />
        </FormItem>
        <FormItem name='vendorBalanceAfter' label='Vendor Balance After'>
          <TextField disabled />
        </FormItem>
      </div>
      <FormItem name='vendorResponseCode' label='Response Code'>
        <TextField disabled />
      </FormItem>
      <FormItem name='vendorResponseMessage' label='Response Message'>
        <TextArea disabled />
      </FormItem>
    </Wrapper>
  )
}

export default memo(VendorInformation)
