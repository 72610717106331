import { Space, Tabs } from 'antd'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useGetDefaultTab } from '../../../../../../../../salesNetwork/helpers/useGetDefaultTab'
import { SIM_CARD_TABS_ENUM } from './index.d'
import { useContext, useEffect } from 'react'
import { useAppDispatch } from '../../../../../../../../../redux'
import { setBreadcrumb } from '../../../../../../../../app/core/BreadcrumbsSlice'
import { SETTINGS_ENUM } from '../../../../../../../../../routes/settings'
import {
  useGetVendorByIdQuery,
  useUpdateVendorMutation,
} from '../../../../../../../core/http/VendorManagementApi'
import { isEmpty } from 'lodash'
import { InformationTab } from '../InformationTab'
import VariablesTable from '../../../Tabs/Variables'
import { WalletTypeTab } from '../WalletTypeTab'
import { IconButton } from '../../../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, EditIcon } from '../../../../../../../../../assets/svg'
import { Toggle } from '../../../../../../../../../shared/components/toggle/Toggle'
import { useNavigate } from 'react-router'
import { RIGHT_MODALS } from '../../../../../../../../../helpers/contants'
import { RightModalContext } from '../../../../../../../../Modals'

const Operations = ({ activeTab }: { activeTab: string }) => {
  const navigate = useNavigate()
  const { onOpen } = useContext(RightModalContext)
  const { venId, id, mode } = useParams<{ venId: string; id: string; mode: string }>()

  const [updateVendor, upVenResp] = useUpdateVendorMutation()
  const { data, isFetching } = useGetVendorByIdQuery(id as string, { skip: !id })

  const isVariablesTabActive = activeTab === SIM_CARD_TABS_ENUM.VARIABLES
  const shouldDisplayEdit = mode === 'view' && !isVariablesTabActive

  const onStatusChange = (checked: boolean) => {
    const fd = new FormData()
    fd.append('vendor', JSON.stringify({ status: checked }))
    updateVendor({ id: id, body: fd })
  }

  const handleAddBtnClick = () => {
    return onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_VARIABLES, {
      title: 'Add Variable',
      vendorId: id,
    })
  }

  return (
    <Space size={10}>
      {shouldDisplayEdit && (
        <IconButton
          icon={<EditIcon />}
          color='orange'
          onClick={() =>
            navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${venId}/sim-card/edit/${id}`)
          }
        />
      )}
      {/*{!isVendorInfoActive && (*/}
      {/*  <IconButton icon={<DownloadIcon />} color='blue' onClick={handlerExportClick} />*/}
      {/*)}*/}
      <Toggle
        defaultChecked={data?.status}
        checked={data?.status}
        text='Status'
        inWrapper
        loading={upVenResp.isLoading || isFetching}
        onChange={onStatusChange}
      />
      {isVariablesTabActive && (
        <IconButton icon={<AddIcon />} color='orange' type='primary' onClick={handleAddBtnClick} />
      )}
    </Space>
  )
}

export const SimCardTabs = () => {
  const { venId, id } = useParams<{ venId: string; id: string }>()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: SIM_CARD_TABS_ENUM.SIM_CARD_INFORMATION,
  })
  const { data: vendorData } = useGetVendorByIdQuery(venId as string, { skip: !venId })
  const { data: simData } = useGetVendorByIdQuery(id as string, { skip: !id })

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  useEffect(() => {
    if (isEmpty(vendorData)) return
    dispatch(setBreadcrumb({ ...vendorData, key: SETTINGS_ENUM.VENDOR_MANAGEMENT_VIEW }))
  }, [dispatch, vendorData])

  useEffect(() => {
    if (!simData?.simNumber) return
    dispatch(setBreadcrumb({ name: simData.simNumber }))
  }, [simData, dispatch])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  const items = [
    {
      key: SIM_CARD_TABS_ENUM.SIM_CARD_INFORMATION,
      label: SIM_CARD_TABS_ENUM.SIM_CARD_INFORMATION,
      children: <InformationTab />,
    },
    // {
    //   key: SIM_CARD_TABS_ENUM.CONTACTS,
    //   label: SIM_CARD_TABS_ENUM.CONTACTS,
    //   children: <ContactsTable />,
    // },
    // {
    //   key: SIM_CARD_TABS_ENUM.INTEGRATIONS,
    //   label: SIM_CARD_TABS_ENUM.INTEGRATIONS,
    //   children: <IntegrationsTable />,
    // },
    {
      key: SIM_CARD_TABS_ENUM.VARIABLES,
      label: SIM_CARD_TABS_ENUM.VARIABLES,
      children: <VariablesTable />,
    },
    {
      key: SIM_CARD_TABS_ENUM.WALLET_TYPE,
      label: SIM_CARD_TABS_ENUM.WALLET_TYPE,
      children: <WalletTypeTab />,
    },
  ]

  return (
    <Tabs
      activeKey={activeTab}
      onChange={onTabChange}
      items={items}
      tabBarExtraContent={<Operations activeTab={activeTab} />}
    />
  )
}
