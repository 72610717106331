import React, { memo } from 'react'

import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { PieChart } from 'src/shared/components/pieChart/pieChart'

import styles from './styles.module.scss'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'

enum hours {
  DAY = 24,
  WEEK = 168,
  MONTH = 720,
}

const COLORS = ['#4CAF50', '#F9C74F']

const ActiveHours = () => {
  // TODO: remove when use real data
  const dayHoursRandom = Math.floor(Math.random() * (hours.DAY - 1 + 1) + 1)
  const weekHoursRandom = Math.floor(Math.random() * (hours.WEEK - 1 + 1) + 1)
  const monthHoursRandom = Math.floor(Math.random() * (hours.MONTH - 1 + 1) + 1)

  const dayActivities = [
    { name: 'Active hours', value: dayHoursRandom, id: 1 },
    { name: 'Inactive hours', value: hours.DAY - dayHoursRandom, id: 2 },
  ]

  const weekActivities = [
    { name: 'Active hours', value: weekHoursRandom, id: 1 },
    { name: 'Inactive hours', value: hours.WEEK - weekHoursRandom, money: 25, id: 2 },
  ]

  const monthActivities = [
    { name: 'Active hours', value: monthHoursRandom, id: 1 },
    { name: 'Inactive hours', value: hours.MONTH - monthHoursRandom, id: 2 },
  ]

  // TODO: ADD CENTER COMPONENT TO THE PIE CHARTS WHEN BACKEND WILL BE READY

  return (
    <Wrapper title='Active Hours' className={styles.wrapperContainer}>
      <div className={styles.wrapperContent}>
        <div className={styles.hoursData}>
          <div>
            <div className={styles.chartDataTitle}>
              <div className={styles.chartDataDot} style={{ backgroundColor: COLORS[0] }} />
              <div className={styles.chartDataName}>Active hours</div>
            </div>
            <div className={styles.chartDataTitle}>
              <div className={styles.chartDataDot} style={{ backgroundColor: COLORS[1] }} />
              <div className={styles.chartDataName}>Inactive hours</div>
            </div>
          </div>
          <div className={styles.inactiveContainer}>
            <div className={styles.inactiveToggleTitle}>Inactive hours</div>
            <div className={styles.inactiveToggleContainer}>
              <div className={styles.inactiveToggleTitle}>All</div>
              <div className={styles.inactiveToggle}>
                <Toggle />
              </div>
              <div className={styles.inactiveToggleTitle}>Scheduled working hours</div>
            </div>
          </div>
        </div>
        <div className={styles.hoursCharts}>
          <PieChart
            data={dayActivities}
            colors={COLORS}
            // componentCenter={
            //   <div className={styles.chartCenterData}>
            //     <span className={styles.chartCenterDataTitle}>TODAY</span>
            //     <span className={styles.chartCenterDataTime}>
            //       {formatTime(dayHoursRandom * 60 * 60)}
            //     </span>
            //     <span className={styles.chartCenterDataTitle}>/{`${hours.DAY}:00:00`}</span>
            //   </div>
            // }
          />
          <PieChart
            data={weekActivities}
            colors={COLORS}
            // componentCenter={
            //   <div className={styles.chartCenterData}>
            //     <span className={styles.chartCenterDataTitle}>WEEK</span>
            //     <span className={styles.chartCenterDataTime}>
            //       {formatTime(weekHoursRandom * 60 * 60)}
            //     </span>
            //     <span className={styles.chartCenterDataTitle}>/{`${hours.WEEK}:00:00`}</span>
            //   </div>
            // }
          />
          <PieChart
            data={monthActivities}
            colors={COLORS}
            // componentCenter={
            //   <div className={styles.chartCenterData}>
            //     <span className={styles.chartCenterDataTitle}>MONTH</span>
            //     <span className={styles.chartCenterDataTime}>
            //       {formatTime(monthHoursRandom * 60 * 60)}
            //     </span>
            //     <span className={styles.chartCenterDataTitle}>/{`${hours.MONTH}:00:00`}</span>
            //   </div>
            // }
          />
        </div>
      </div>
    </Wrapper>
  )
}

export default memo(ActiveHours)
