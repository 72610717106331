import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import {
  IDepartment,
  IDepartmentTable,
  IDepartmentManagementCreate,
} from '../../models/IDepartmentsManagement'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../../shared/models/ITableConf'
import { IUser } from '../../models/IUser'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { IRoles } from '../../models/IRoles'
import { ILanguageList } from '../../models/ILanguage'

export const departmentsManagementApi = createApi({
  reducerPath: 'departmentsManagement',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'IDepartmentTable',
    'IDepartment',
    'IUser',
    'IUserDetail',
    'IFilterFields',
    'IDepartmentManagementCreate',
  ],
  endpoints: (builder) => ({
    getDepartmentsList: builder.query<ITableResponse<IDepartmentTable>, ITableConf>({
      query: (params) => ({
        url: 'departments',
        params,
      }),
      providesTags: () => ['IDepartmentTable'],
    }),

    getDepartmentById: builder.query<IDepartment, string>({
      query: (id) => ({
        url: `departments/${id}`,
      }),
      transformResponse: (res: IDepartment) => ({
        ...res,
        createdBy: `${res.createdByUser.firstName} ${res.createdByUser.lastName}`,
      }),
      providesTags: (department) => [{ type: 'IDepartment', id: department?.id }],
    }),

    createDepartment: builder.mutation<IDepartment, IDepartmentManagementCreate>({
      query: (body) => ({
        url: 'departments',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IDepartmentTable', 'IFilterFields'],
    }),

    patchDepartment: builder.mutation<
      IDepartment,
      { id: string | undefined; body: Partial<IDepartment> }
    >({
      query: ({ id, body }) => ({
        url: `departments/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['IDepartmentTable', 'IFilterFields', 'IDepartment'],
    }),

    deleteDepartmentById: builder.mutation<void, string>({
      query: (id) => ({
        url: `departments/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['IDepartmentTable', 'IDepartment', 'IFilterFields'],
    }),

    exportDepartmentUsersTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx'; id: string }
    >({
      query: ({ conf, formatFile }) => ({
        url: `/departments/${conf.id}/users/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getUsersListByDepartmentId: builder.query<ITableResponse<IUser>, ITableConf>({
      query: ({ id, ...params }) => ({
        url: `departments/${String(id)}/users`,
        params,
      }),
      providesTags: () => ['IUser'],
    }),

    getRolesList: builder.query<ITableResponse<IRoles>, void>({
      query: () => ({
        url: 'roles',
      }),
    }),

    getLanguagesList: builder.query<ILanguageList, void>({
      query: () => ({
        url: 'languages',
      }),
    }),

    getDepartmentUsersTableFiltersList: builder.query<Array<IFilterFields>, string>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'departments/users/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `departments/${arg}/users/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      keepUnusedDataFor: 0,
    }),

    exportDepartmentsListTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx' }
    >({
      query: ({ conf, formatFile }) => ({
        url: `departments/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getDepartmentsManagementFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'departments/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `departments/filter-values?filterField=${filter.field}&id=${arg}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      keepUnusedDataFor: 0,
      providesTags: ['IFilterFields'],
    }),

    addUserToDepartment: builder.mutation<void, { userId: number; departmentId: number }>({
      query: (body) => ({
        url: 'departments/users',
        method: 'PATCH',
        body,
      }),
    }),
  }),
})

export const {
  useGetDepartmentsListQuery,
  useGetDepartmentByIdQuery,
  useCreateDepartmentMutation,
  useDeleteDepartmentByIdMutation,
  useGetUsersListByDepartmentIdQuery,
  useGetDepartmentUsersTableFiltersListQuery,
  useLazyExportDepartmentsListTableQuery,
  useGetDepartmentsManagementFiltersListQuery,
  useGetLanguagesListQuery,
  usePatchDepartmentMutation,
  useLazyExportDepartmentUsersTableQuery,
  useAddUserToDepartmentMutation,
} = departmentsManagementApi
