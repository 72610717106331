import {
  archiveTypes,
  documentTypes,
  imageTypes,
  videoTypes,
} from '../shared/components/uploaderPhotos/constants'

export const PAYMENT_METHOD = {
  CASH: 'Cash',
  BANK_DEPOSIT: 'Bank deposit',
  BANK_TRANSFER: 'Bank Transfer',
  BONUS: 'Bonus',
}

export const CURRENCY = {
  usd: 'USD',
  afn: 'AFN',
  iranToman: 'Iran Toman',
  turkishLira: 'Turkish Lira',
  pakistanRupee: 'Pakistan Rupee',
  uAEDirham: 'UAE Dirham',
  euro: 'Euro',
}

export const RIGHT_MODALS = {
  FINANCE: {
    PURCHASE_ORDER: 'FINANCE_PURCHASE_ORDER',
    PICKUP_REQUEST: 'FINANCE_PICKUP_REQUEST',
    PICKUP_REQUEST_CHANGES: 'FINANCE_PICKUP_REQUEST_CHANGES',
    CREATE_PICKUP_REQUEST: 'FINANCE_CREATE_PICKUP_REQUEST',
    ENCHANGE_RATES: 'FINANCE_ENCHANGE_RATES',
    TRANSFER_FUND: 'FINANCE_TRANSFER_FUND',
    TRANSFER_FUND_DETAIL: 'TRANSFER_FUND_DETAIL',
    REFUND_REQUEST: 'REFUND_REQUEST',
    REFUND_REQUEST_CHANGES: 'REFUND_REQUEST_CHANGES',
    ADJUSTMENT: 'ADJUSTMENT',
    REVERSE_TRANSACTION: 'REVERSE_TRANSACTION',
    TOPUP_OR_REVERSE_SUB_TRANSACTION: 'TOPUP_OR_REVERSE_SUB_TRANSACTION',
    REVERSE_TRANSACTION_REQUEST: 'REVERSE_TRANSACTION_REQUEST',
    TOPUP: 'TOPUP',
    PURCHASE_PLAN: 'PURCHASE_PLAN',
    PURCHASE_ORDER_CHANGES: 'PURCHASE_ORDER_CHANGES',
    RECONCILIATION: 'RECONCILIATION',
    SEND_EMONEY: 'SEND_EMONEY',
  },
  SUPPORT: {
    CHANGE_MPIN: 'SUPPORT_CHANGE_MPIN',
  },
  SALES: {
    AUDIENCE_BASE: 'AUDIENCE_BASE',
    CUSTOMER: 'CUSTOMER',
    IDENTIFIER_VERIFICATION: 'IDENTIFIER_VERIFICATION',
    AGENT_TRANSACTIONS: 'AGENT_TRANSACTIONS',
    SEGMENT_TARGET_GROUP: 'SEGMENT_TARGET_GROUP',
    SEGMENT_TARGET_GROUP_LIST: 'SEGMENT_TARGET_GROUP_LIST',
    CUSTOMER_CLOSED_GROUP: 'CUSTOMER_CLOSED_GROUP',
  },
  SETTINGS: {
    USERS_MANAGEMENT_USERS_LIST: 'USERS_MANAGEMENT_USERS_LIST',
    USERS_MANAGEMENT_USER: 'USERS_MANAGEMENT_USER',
    DEPARTMENT_MANAGEMENT_USERS_LIST: 'DEPARTMENT_MANAGEMENT_USERS_LIST',
    DEPARTMENT_MANAGEMENT_USER: 'DEPARTMENT_MANAGEMENT_USER',
    ROLES_AND_PERMISSIONS_MANAGEMENT_USERS_LIST: 'ROLES_AND_PERMISSIONS_MANAGEMENT_USERS_LIST',
    POSITIONS_USERS_LIST: 'POSITIONS_USERS_LIST',
    ROLES_AND_PERMISSIONS_MANAGEMENT_USERS_TO_ROLE_LIST:
      'ROLES_AND_PERMISSIONS_MANAGEMENT_USERS_TO_ROLE_LIST',
    ROLES_AND_PERMISSIONS_MANAGEMENT_USER: 'ROLES_AND_PERMISSIONS_MANAGEMENT_USER',
    VENDOR_MANAGEMENT_CONTACT_LIST: 'VENDOR_MANAGEMENT_CONTACT_LIST',
    VENDOR_MANAGEMENT_VARIABLES: 'VENDOR_MANAGEMENT_VARIABLES',
    VENDOR_MANAGEMENT_PRODUCT_LIST: 'VENDOR_MANAGEMENT_PRODUCT_LIST',
    VENDOR_MANAGEMENT_PRODUCT: 'VENDOR_MANAGEMENT_PRODUCT',
    NETWORK_CONFIGURATION_TARGET_GROUP: 'NETWORK_CONFIGURATION_TARGET_GROUP',
    NETWORK_CONFIGURATION_TARGET_GROUP_LIST: 'NETWORK_CONFIGURATION_TARGET_GROUP_LIST',
    WHATSAPP_MANAGER_BUTTONS: 'WHATSAPP_MANAGER_WHATSAPP_BUTTONS',
    WHATSAPP_NOTIFICATION_BUTTON_DETAIL: 'WHATSAPP_NOTIFICATION_BUTTON_DETAIL',
    PROMOTION: 'PROMOTION',
    INTERNAL_CHAT: 'INTERNAL_CHAT',
    VENDOR_MANAGEMENT_GATEWAY: 'VENDOR_MANAGEMENT_GATEWAY',
  },
  DASHBOARD: {
    DASHBOARD_OVERVIEW_CUSTOMIZATION: 'DASHBOARD_OVERVIEW_CUSTOMIZATION',
  },
}

export const CENTER_MODALS = {
  EXPORT_TABLE: 'EXPORT_TABLE',
  CONFIRM_MODAL: 'CONFIRM_MODAL',
  GOOGLE_AUTHENTICATOR: 'GOOGLE_AUTHENTICATOR',
  DISABLE_GOOGLE_AUTH: 'DISABLE_GOOGLE_AUTH',
  EVENTS_MODAL: 'EVENTS_MODAL',
}

export const EXCLUDE_BREADCRUMB = [
  'view',
  'edit',
  'contact',
  'master-product',
  'departments',
  'purchase-order',
  'pickup-request',
  'refund-request',
  'ticket',
  'positions',
  'discount-policy',
  'promotion-policy',
  'roles-and-permissions',
  'inventory',
  'customer',
  'agent',
  'vendor',
  'response-code',
  'notification-manager',
  'reward-policy',
  'pin',
  'structure',
  'e-pin',
  'bank-accounts',
  'banks',
  'sim-card',
  'hydra',
]

export const LOCAL_STORAGE_VARIABLES = {
  recentCachedData: 'recentCachedData',
}

export const AMIN_PAY_VENDOR_PROD_ID = '90430119'

export const compatibleUplaodTypes = [
  ...videoTypes,
  ...imageTypes,
  ...archiveTypes,
  ...documentTypes,
]

export const PIE_CHART_COLORS_WITH_TWO_ITEMS = ['#ff9800', '#3b81c5']

export const PIE_CHART_COLORS = [
  '#0393EB',
  '#189EED',
  '#4DB3F2',
  '#66BEF3',
  '#9AD4F7',
  '#B3DFF9',
  '#CDE9FB',
  '#E6F4FD',
]
