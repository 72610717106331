import { Form, Spin } from 'antd'
import { useNavigate, useParams } from 'react-router'
import {
  useDeleteDiscountPolicyByIdMutation,
  useGetDiscountPolicyByIdQuery,
  useUpdateDiscountPolicyMutation,
} from '../../../../../core/http/DiscountPolicyApi'
import React, { useCallback, useEffect } from 'react'
import { Moment } from 'moment'
import {
  formatDateMomentToISO,
  formatDateWithTime,
  getFormCalendarTime,
} from '../../../../../../../helpers/utils'
import { Footer } from '../Footer'
import { DetailInfoBlock } from '../DetailInfoBlock'
import { SETTINGS_ENUM } from '../../../../../../../routes/settings'
import { ErrorNode, showConfirmMessage } from '../../../../../../../shared/api/errorHandler'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { IDiscountPolicy, IDiscountPolicyCreate } from '../../../../../models/IDiscountPolicy'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { ACCOUNT_TYPE_TO_ID } from '../../../../../models/IAccountType'
import { ProductAccessBlock } from '../ProductAccessBlock'
import { Wrapper } from '../../../../../../../shared/components/wrapper/Wrapper'
import styles from '../styles.module.scss'
import { useGetMasterProductsListQuery } from '../../../../../core/http/VendorManagementApi'
import { MasterProducts } from '../MasterProducts/MasterProducts'
import { AccessChannelsBlock } from '../AccessChannelsBlock'

export const InformationTab = () => {
  const navigate = useNavigate()
  const { id, mode } = useParams<{ id: string; mode: 'view' | 'edit' }>()
  const [form] = Form.useForm()
  const { data: profile } = useGetProfileQuery()
  const { data: masterProducts, isFetching: isFetchingMaster } = useGetMasterProductsListQuery({})

  const { data, isFetching } = useGetDiscountPolicyByIdQuery(id as string)
  const [delDisc, delDiscResp] = useDeleteDiscountPolicyByIdMutation()
  const [updDisc, updDiscResp] = useUpdateDiscountPolicyMutation()

  useNotification(NOTIFICATION_TYPES.success, delDiscResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, updDiscResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, delDiscResp.isError, delDiscResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, updDiscResp.isError, updDiscResp.error as ErrorNode)

  const isViewMode = mode === 'view'

  const openNotification = useCallback(() => {
    showConfirmMessage('This discount policy will be deleted', () => delDisc(id as string))
  }, [delDisc, id])

  const setInitialValues = useCallback(() => {
    if (data) {
      const {
        start,
        end,
        createdAt,
        discountPolicyProducts,
        accountType,
        productsAccess,
        ...rest
      } = data

      const res = {
        start: getFormCalendarTime(profile?.calendar, start),
        end: getFormCalendarTime(profile?.calendar, end),
        createdAt: formatDateWithTime(createdAt, profile?.calendar),
        accountType: accountType,
        discountPolicyProducts,
        productsAccess:
          accountType.id === ACCOUNT_TYPE_TO_ID.POSTPAID
            ? masterProducts?.items
                .filter(
                  (master) =>
                    !discountPolicyProducts.some(
                      (product) => product.masterProduct.id === master.id
                    ) && data.masterProductType?.toString() === master.type
                )
                ?.map((filtered) => ({ masterProduct: filtered }))
            : productsAccess,
        ...rest,
      }
      form.setFieldsValue(res)
    }
  }, [data, form, masterProducts?.items, profile?.calendar])

  const onFinish = (values: IDiscountPolicy) => {
    const dataObj = {
      name: values.name,
      start: formatDateMomentToISO(profile?.calendar, values.start as unknown as Moment),
      end: formatDateMomentToISO(profile?.calendar, values.end as unknown as Moment),
      isOpenFloating: !!values.isOpenFloating,
      ...(data?.isEditable && {
        accountTypeId: +values?.accountType?.id,
        agentTypeId: +values?.agentType?.id,
        masterProductType: values.masterProductType,
      }),
      minRate: +values?.minRate,
      discountPolicyProducts: values?.discountPolicyProducts?.map((product) => ({
        masterProductId: product?.masterProduct?.id,
        levelRates: product.levelRates.map((lev) => ({
          levelId: +lev.level.id,
          rate: +lev.rate,
        })),
      })),
      isOpen: values.isOpen,
      ...(values.accountType?.id === ACCOUNT_TYPE_TO_ID.PREPAID && {
        productsAccess: values?.productsAccess?.map((prod) => prod?.masterProduct.id),
      }),
      forbiddenAccessChannels: values?.forbiddenAccessChannels,
    } as IDiscountPolicyCreate

    updDisc({ id: id as string, body: dataObj })
  }

  const onSubmitClick = () => {
    if (isViewMode) {
      return navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/discount-policy/edit/${id}`)
    }

    return form.submit()
  }

  const onCancelClick = () => {
    if (isViewMode) {
      return navigate(SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION)
    }

    form.resetFields()
    setInitialValues()
    return navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/discount-policy/view/${id}`)
  }

  useEffect(() => {
    setInitialValues()
  }, [setInitialValues])

  useEffect(() => {
    if (delDiscResp.isSuccess) {
      navigate(SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION)
    }
    // eslint-disable-next-line
  }, [delDiscResp.isSuccess])

  useEffect(() => {
    if (updDiscResp.isSuccess) {
      navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/discount-policy/view/${id}`)
    }
    // eslint-disable-next-line
  }, [updDiscResp.isSuccess])

  const content = (
    <Form form={form} layout='vertical' onFinish={onFinish} className={styles.targetGroupForm}>
      <DetailInfoBlock view={isViewMode} isEditable={data?.isEditable} onViewPage />
      <Wrapper title='Master Products'>
        <MasterProducts view={isViewMode} />
      </Wrapper>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const isPostpaid = getFieldValue(['accountType', 'id']) === ACCOUNT_TYPE_TO_ID.POSTPAID
          return (
            <Wrapper title='Forbidden Products'>
              <ProductAccessBlock view={isViewMode} isPostpaid={isPostpaid} onViewPage />
            </Wrapper>
          )
        }}
      </Form.Item>
      <Wrapper title='Forbidden Access Channels' className={styles.productAccessView}>
        <AccessChannelsBlock view={isViewMode} onViewPage />
      </Wrapper>
    </Form>
  )

  const isLoading = isFetching || delDiscResp.isLoading || updDiscResp.isLoading || isFetchingMaster

  return (
    <div className='fullVH'>
      {isLoading ? <Spin>{content}</Spin> : content}
      <Footer
        onCancelClick={onCancelClick}
        onSubmitClick={onSubmitClick}
        mode={mode}
        onDeleteClick={openNotification}
      />
    </div>
  )
}
