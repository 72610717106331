import { Modal as AntModal } from 'antd'
import { ReactNode } from 'react'
import { CenterModalCrossIcon } from 'src/assets/svg'

interface IModal {
  title: string | undefined
  footer: ReactNode | null
  children: ReactNode
  zIndex: number
  handleOk: () => void
  handleCancel: () => void
  isModalOpen: boolean
  className: string
  width?: number
}

export const Modal = ({
  title,
  footer,
  zIndex,
  children,
  handleOk,
  handleCancel,
  isModalOpen,
  className,
  width,
}: IModal) => {
  return (
    <AntModal
      width={width}
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footer}
      zIndex={zIndex}
      wrapClassName={className}
      closeIcon={<CenterModalCrossIcon />}
    >
      {children}
    </AntModal>
  )
}

export default Modal
