import { Form, Select, Spin } from 'antd'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'
import styles from '../styles.module.scss'
import classNames from 'classnames'
import { TextField } from '../../../../../../shared/components/textfield/TextField'
import { numberRange, requiredValidator } from '../../../../../../helpers/validation'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import { IAgentDiscountPolicyRateLimit } from '../../../../models/IAgentPolicy'
import React from 'react'
import { IMasterProduct } from '../../../../../Settings/models/IMasterProduct'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IProps extends IViewMode {
  rateLimits?: IAgentDiscountPolicyRateLimit
  isPostpaid?: boolean
  masterProductsList?: Array<IMasterProduct>
  isFirstLevel: boolean
}

export const DiscountPolicyBlock = ({
  view,
  rateLimits,
  isPostpaid,
  masterProductsList,
  isFirstLevel,
}: IProps) => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const getMaxNumberLimit = (
    productId: number,
    rateLimit?: IAgentDiscountPolicyRateLimit['max']
  ): number => {
    if (typeof rateLimit === 'number') return rateLimit
    return rateLimit?.find((limit) => +limit?.productId === productId)?.max || 100
  }

  return (
    <Form.List name='discountPolicyProducts'>
      {(fields) => (
        <>
          {fields.map(({ key, name }) => (
            <div key={key} className={styles.discountPolicyRow}>
              <Form.Item name={[name, 'id']} noStyle shouldUpdate>
                <TextField className={styles.hiddenField} />
              </Form.Item>
              <Form.Item
                name={[name, 'masterProduct', 'name']}
                className={classNames(styles.masterProductRowItem, 'noBottomMargin')}
              >
                <Select disabled>
                  {masterProductsList?.map((product) => (
                    <Select.Option key={product.id} value={product.id}>
                      {product.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const currentProd = getFieldValue([
                    'discountPolicyProducts',
                    name,
                    'masterProduct',
                    'id',
                  ])
                  return (
                    <Form.Item
                      name={[name, 'rate']}
                      className={classNames(styles.rateRowItem, 'noBottomMargin')}
                      rules={[
                        {
                          validator: numberRange(
                            rateLimits?.min as number,
                            getMaxNumberLimit(currentProd, rateLimits?.max),
                            'Rate'
                          ),
                        },
                        {
                          required: true,
                          validator: requiredValidator('Rate'),
                        },
                      ]}
                    >
                      <TextField type={isPrivateMode ? 'password' : 'number'} disabled={view} />
                    </Form.Item>
                  )
                }}
              </Form.Item>

              <div className={styles.productAccessMasterProductStatus}>
                <Form.Item
                  name={[name, 'isLocked']}
                  className='noBottomMargin'
                  valuePropName='checked'
                >
                  <Toggle disabled={view || !isFirstLevel} text='Is Locked' />
                </Form.Item>
              </div>

              {isPostpaid && (
                <Form.Item
                  name={[name, 'status']}
                  className='noBottomMargin'
                  valuePropName='checked'
                >
                  <Toggle disabled={view} />
                </Form.Item>
              )}
            </div>
          ))}
        </>
      )}
    </Form.List>
  )
}
