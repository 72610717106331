import { AgentManagementDetailViewTabsList } from '../index.d'
import styles from './styles.module.scss'
import './styles.scss'
import { Tabs } from 'antd'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { AdjustmentIcon, EditIcon } from 'src/assets/svg'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { GeneralInformationTab } from '../GeneralInformationTab'
import { Toggle } from 'src/shared/components/toggle/Toggle'
import { AgentSalesNetworkTable } from '../AgentSalesNetworkTable'
import { useContext, useEffect, useMemo } from 'react'
import {
  useGetAgentByIdQuery,
  useGetSalesNetworkTableQuery,
  usePatchAgentMutation,
} from '../../../core/http/AgentManagement'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'
import { Policy } from '../PolicyTab'
import { isEmpty } from 'lodash'
import usePermissions from '../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../Settings/models/IPermissions'
import WithPermissionDeniedContainer from '../../../../../shared/components/withPermissionDenied'
import { TransactionHistoryTable } from '../TransactionHistoryTable'
import { setBreadcrumb } from '../../../../app/core/BreadcrumbsSlice'
import { useAppDispatch } from '../../../../../redux'
import { AnalyticsTab } from '../AnalyticsTab'
import { VerificationAgent } from '../../AgentManagment/componentns/Verification'
import { RightModalContext } from '../../../../Modals'
import { RIGHT_MODALS } from '../../../../../helpers/contants'
import { FinancialInformationTab } from '../FinancialInformationTab'

type IPropsOperations = {
  activeTab: AgentManagementDetailViewTabsList
  canEditStatus: boolean
  canViewStatus: boolean
  canEditContent: boolean
}

const Operations = ({
  activeTab = AgentManagementDetailViewTabsList.GENERAL_INFORMATION,
  canEditStatus,
  canViewStatus,
  canEditContent,
}: IPropsOperations) => {
  const navigate = useNavigate()
  const { agentId } = useParams()
  const rightModalContext = useContext(RightModalContext)

  const { data: dataAgent, isFetching: isFetchingDataAgent } = useGetAgentByIdQuery(agentId, {
    skip: !agentId,
  })
  const [patch, { isLoading }] = usePatchAgentMutation()

  const shouldDisplayEdit = useMemo(
    () =>
      activeTab === AgentManagementDetailViewTabsList.GENERAL_INFORMATION ||
      activeTab === AgentManagementDetailViewTabsList.POLICY ||
      activeTab === AgentManagementDetailViewTabsList.VERIFICATION,
    [activeTab]
  )

  const onStatusChange = (checked: boolean) => {
    const fd = new FormData()
    fd.append('agent', JSON.stringify({ status: checked }))

    patch({ id: agentId, body: fd })
  }

  const handlerAdjustmentClick = () => {
    rightModalContext.onOpen(RIGHT_MODALS.FINANCE.ADJUSTMENT, {
      title: 'Adjustment',
      agentId: dataAgent?.id,
    })
  }

  const onPressEdit = () => navigate(`/sales-network/agent-management/edit/${agentId}`)

  return (
    <div style={{ display: 'flex', gridGap: 10 }}>
      {shouldDisplayEdit && canEditContent && (
        <div className={styles['wrapper-icon']}>
          <IconButton
            color='orange'
            icon={<EditIcon />}
            onClick={onPressEdit}
            type='default'
            block
            disabled={dataAgent?.isDeleted}
          />
        </div>
      )}
      <IconButton
        color='blue'
        type='default'
        icon={<AdjustmentIcon />}
        onClick={handlerAdjustmentClick}
      >
        Adjustment
      </IconButton>
      {canViewStatus && (
        <Toggle
          disabled={!canEditStatus || dataAgent?.isDeleted}
          defaultChecked={dataAgent?.status}
          checked={dataAgent?.status}
          text={dataAgent?.isDeleted ? 'Deleted' : 'Status'}
          loading={isLoading || isFetchingDataAgent}
          onChange={onStatusChange}
          inWrapper
        />
      )}
    </div>
  )
}

export const AgentManagementDetailViewTabs = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [, setSearchParams] = useSearchParams()
  const { agentId } = useParams<{ agentId: string }>()

  const { data: dataAgent } = useGetAgentByIdQuery(agentId)
  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: AgentManagementDetailViewTabsList.GENERAL_INFORMATION,
  })

  const { data } = useGetSalesNetworkTableQuery({
    page: 1,
    limit: 10,
    id: agentId,
  })

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()

  const [agentsId] = getModulesIdsByNames([IPermissionsNames['Sales Agent Profile']])
  const [currentBalanceId] = getModulesIdsByNames([IPermissionsNames['Agent Current Balance']])
  const [parentAccountId] = getModulesIdsByNames([IPermissionsNames['Agent Parent Account']])
  const [agentDetailId] = getModulesIdsByNames([IPermissionsNames['Agent Detail']])
  const [agentContactInfoId] = getModulesIdsByNames([
    IPermissionsNames['Agent Contact Information'],
  ])
  const [agentAddressId] = getModulesIdsByNames([IPermissionsNames['Agent Address']])
  const [agentIdentifierId] = getModulesIdsByNames([IPermissionsNames['Agent User Identifier']])
  const [agentMPINId] = getModulesIdsByNames([IPermissionsNames['Agent mPIN Management']])
  const [agentCreditScoreId] = getModulesIdsByNames([IPermissionsNames['Agent Credit Score']])
  const [agentPolicyManagementId] = getModulesIdsByNames([
    IPermissionsNames['Agent Commission Policy Management'],
  ])
  const [statusAgentId] = getModulesIdsByNames([IPermissionsNames['Status (Active/Inactive)']])
  const [agentTransactionHistory] = getModulesIdsByNames([
    IPermissionsNames['Agent Transaction History'],
  ])

  const [canViewAgent] = canPerformAction(agentsId, [IPermissionsActions.VIEW])
  const [canViewCurrentBalance] = canPerformAction(currentBalanceId, [IPermissionsActions.VIEW])
  const [canViewParentAccount] = canPerformAction(parentAccountId, [IPermissionsActions.VIEW])
  const [canViewCreditScore] = canPerformAction(agentCreditScoreId, [IPermissionsActions.VIEW])
  const [canViewAgentTransactions] = canPerformAction(agentTransactionHistory, [
    IPermissionsActions.VIEW,
  ])

  const [canViewAgentDetail, canEditAgentDetail] = canPerformAction(agentDetailId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.EDIT,
  ])

  const [canViewContactInfo, canEditContactInfo] = canPerformAction(agentContactInfoId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.EDIT,
  ])

  const [canViewAddress, canEditAddress] = canPerformAction(agentAddressId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.EDIT,
  ])

  const [canViewIdentifier, canEditIdentifier] = canPerformAction(agentIdentifierId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.EDIT,
  ])

  const [canViewMPIN, canEditMPIN] = canPerformAction(agentMPINId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.EDIT,
  ])

  const [canViewPolicyManagement, canEditPolicyManagement] = canPerformAction(
    agentPolicyManagementId,
    [IPermissionsActions.VIEW, IPermissionsActions.EDIT]
  )

  const [canViewAgentStatus, canEditAgentStatus] = canPerformAction(statusAgentId, [
    IPermissionsActions.VIEW,
    IPermissionsActions.EDIT,
  ])

  const canViewContent = useMemo(() => {
    return [
      canViewAgentDetail,
      canViewCurrentBalance,
      canViewParentAccount,
      canViewContactInfo,
      canViewAddress,
      canViewIdentifier,
      canViewCreditScore,
      canViewMPIN,
    ].some((el) => el)
  }, [
    canViewAddress,
    canViewAgentDetail,
    canViewContactInfo,
    canViewCreditScore,
    canViewCurrentBalance,
    canViewIdentifier,
    canViewMPIN,
    canViewParentAccount,
  ])

  const canEditContent = useMemo(() => {
    return (
      canViewContent &&
      [canEditAgentDetail, canEditContactInfo, canEditAddress, canEditIdentifier, canEditMPIN].some(
        (el) => el
      )
    )
  }, [
    canEditAddress,
    canEditAgentDetail,
    canEditContactInfo,
    canEditIdentifier,
    canEditMPIN,
    canViewContent,
  ])

  const currentTabData = useMemo(() => {
    if (activeTab === AgentManagementDetailViewTabsList.GENERAL_INFORMATION) {
      return {
        canEdit: canEditContent,
      }
    }

    if (activeTab === AgentManagementDetailViewTabsList.FINANCIAL_INFORMATION) {
      return {
        canEdit: true,
      }
    }

    if (activeTab === AgentManagementDetailViewTabsList.POLICY) {
      return {
        canEdit: canEditPolicyManagement,
      }
    }
    if (activeTab === AgentManagementDetailViewTabsList.TRANSACTION_HISTORY) {
      return {
        canEdit: true,
      }
    }
    if (activeTab === AgentManagementDetailViewTabsList.VERIFICATION) {
      return {
        canEdit: true,
      }
    }
    return {
      canEdit: false,
    }
  }, [activeTab, canEditContent, canEditPolicyManagement])

  /** Table */
  const items = useMemo(() => {
    const isSalesNetworkTab = !isEmpty(data?.items)
    return [
      {
        label: AgentManagementDetailViewTabsList.GENERAL_INFORMATION,
        key: AgentManagementDetailViewTabsList.GENERAL_INFORMATION,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canViewContent}>
            <GeneralInformationTab
              canViewAgentDetail={canViewAgentDetail}
              canViewParentAccount={canViewParentAccount}
              canViewCurrentBalance={canViewCurrentBalance}
              canEditAgentDetail={canEditAgentDetail}
              canViewContactInfo={canViewContactInfo}
              canEditContactInfo={canEditContactInfo}
              canViewAddress={canViewAddress}
              canEditAddress={canEditAddress}
              canViewIdentifier={canViewIdentifier}
              canEditIdentifier={canEditIdentifier}
              canViewCreditScore={canViewCreditScore}
              canViewMPIN={canViewMPIN}
              canEditMPIN={canEditMPIN}
              canEditContent={canEditContent}
              isSalesNetworkTab={isSalesNetworkTab}
            />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: AgentManagementDetailViewTabsList.FINANCIAL_INFORMATION,
        key: AgentManagementDetailViewTabsList.FINANCIAL_INFORMATION,
        children: <FinancialInformationTab />,
      },
      {
        label: AgentManagementDetailViewTabsList.VERIFICATION,
        key: AgentManagementDetailViewTabsList.VERIFICATION,
        children: <VerificationAgent />,
      },
      // eslint-disable-next-line
      // @ts-ignore
      ...(isSalesNetworkTab
        ? [
            {
              label: AgentManagementDetailViewTabsList.AGENT_SALES_NETWORK,
              key: AgentManagementDetailViewTabsList.AGENT_SALES_NETWORK,
              children: <AgentSalesNetworkTable onChangeTab={setActiveTab} />,
            },
          ]
        : []),
      {
        label: AgentManagementDetailViewTabsList.POLICY,
        key: AgentManagementDetailViewTabsList.POLICY,
        children: (
          // isAllowedView={canViewPolicy}
          <WithPermissionDeniedContainer isAllowedView={canViewPolicyManagement}>
            <Policy
              canViewPolicyManagement={canViewPolicyManagement}
              canEditPolicyManagement={canEditPolicyManagement}
            />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: AgentManagementDetailViewTabsList.TRANSACTION_HISTORY,
        key: AgentManagementDetailViewTabsList.TRANSACTION_HISTORY,
        children: <TransactionHistoryTable />,
      },
      {
        label: AgentManagementDetailViewTabsList.ANALYTICS,
        key: AgentManagementDetailViewTabsList.ANALYTICS,
        children: <AnalyticsTab />,
      },
    ]
  }, [
    data?.items,
    canViewContent,
    canViewAgentDetail,
    canViewParentAccount,
    canViewCurrentBalance,
    canEditAgentDetail,
    canViewContactInfo,
    canEditContactInfo,
    canViewAddress,
    canEditAddress,
    canViewIdentifier,
    canEditIdentifier,
    canViewCreditScore,
    canViewMPIN,
    canEditMPIN,
    canEditContent,
    setActiveTab,
    canViewPolicyManagement,
    canEditPolicyManagement,
  ])

  useEffect(() => {
    if (dataAgent) {
      dispatch(setBreadcrumb(dataAgent))
    }
  }, [dataAgent, dispatch])

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setSearchParams('')
    setActiveTab(tab)
  }

  return (
    <Tabs
      destroyInactiveTabPane
      activeKey={activeTab}
      className='tabs'
      tabBarExtraContent={
        <Operations
          activeTab={activeTab as AgentManagementDetailViewTabsList}
          canEditStatus={canEditAgentStatus}
          canViewStatus={canViewAgentStatus}
          canEditContent={currentTabData.canEdit}
        />
      }
      items={items}
      onChange={onTabChange}
    />
  )
}
