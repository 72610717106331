import { useCallback, useState, useContext } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import { Table } from 'antd'

import {
  useDeleteAudienceMutation,
  useGetAudienceFiltersListQuery,
  useGetAudienceQuery,
  useGetScheduleNotificationByIdQuery,
} from 'src/features/salesNetwork/core/http/SceduleNotifications'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { ISelectedFilters } from 'src/shared/components/filter/Filter'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from 'src/shared/components/popup/Popup'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ITableConf } from 'src/shared/models/ITableConf'
import { RightModalContext } from 'src/features/Modals'
import { RIGHT_MODALS } from 'src/helpers/contants'

import {
  IAudience,
  IAudienceBody,
  AudienceType,
  IScheduleNotification,
} from 'src/features/salesNetwork/models/SceduleNotification'

import styles from '../styles.module.scss'

import { DateTableIcon, TableActionIcon } from 'src/assets/svg'
import { formatDateWithTime } from 'src/helpers/utils'
import { useEmptyTable } from 'src/shared/hooks/table/useEmptyTable'
import { TableHeader } from '../Components/TableHeader'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { isEmpty } from 'lodash'
import { RecursivelyReplaceCharacters } from '../../../../../shared/components/privateMode'

interface IProps {
  canDelete: boolean
}

export const AudienceList = ({ canDelete }: IProps) => {
  const { onOpen } = useContext(RightModalContext)
  const { id } = useParams()
  const navigate = useNavigate()

  const { queryParams, queryFields } = useGetQueryParams()
  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data: profile } = useGetProfileQuery()

  const { data, isFetching } = useGetAudienceQuery(
    {
      tableConf: {
        ...tableConf,
        ...filterValues,
      },
      id: id as string,
    },
    { skip: isEmpty(tableConf) }
  )

  const notification = useGetScheduleNotificationByIdQuery(id!)
  const [deleteAudience, dDelete] = useDeleteAudienceMutation()
  const { data: filtersList } = useGetAudienceFiltersListQuery(id!)

  useNotification(NOTIFICATION_TYPES.success, dDelete.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, dDelete.isError, dDelete.error as ErrorNode)

  const tableActionsPopup = useCallback(
    (id: string, audienceType: AudienceType, itemId: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () => {
            const {
              audience: { audienceType },
            } = notification.data as IScheduleNotification

            if (audienceType === 'agents')
              navigate(`/sales-network/agent-management/view/${itemId}`)
            if (audienceType === 'users') navigate(`/settings/users-management/view/${itemId}`)
          },
        },
        {
          text: 'Delete',
          withDivider: true,
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          shouldDisplay: canDelete,
          onClick: () => {
            const req = {
              id,
              audienceType,
              body: {
                selectedIds: [+itemId],
              } as IAudienceBody,
            }
            deleteAudience(req)
          },
        },
      ]
    },
    [deleteAudience, canDelete, notification.data]
  )

  const columns: ColumnsType<IAudience> = [
    {
      title: 'User',
      dataIndex: 'entity',
      sorter: true,
      width: '20%',
      render: (value: IAudience['entity'], row) => {
        const type = notification.data?.audience.audienceType
        let link: string
        if (type === 'agents') link = `/sales-network/agent-management/view/${row.entity.id}`
        if (type === 'users') link = `/settings/users-management/view/${row.entity.id}`

        return (
          <RecursivelyReplaceCharacters>
            <Link to={link!} className='table-avatar'>
              {value.name}
            </Link>
          </RecursivelyReplaceCharacters>
        )
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '20%',
      render: () => <RecursivelyReplaceCharacters>Email</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Group Type',
      dataIndex: 'audienceType',
      sorter: true,
      width: '20%',
      render: (value: IAudience['audienceType']) => (
        <RecursivelyReplaceCharacters>
          {value === 'agents' ? 'External' : 'Internal'}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Date/Time created',
      dataIndex: 'createdAt',
      sorter: true,
      width: '20%',
      render: (value: IAudience['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(value, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Added By',
      dataIndex: 'addedBy',
      sorter: true,
      width: '20%',
      render: (value: IAudience['addedBy']) => (
        <RecursivelyReplaceCharacters>{value.name}</RecursivelyReplaceCharacters>
      ),
    },

    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '20%',
      render: (_, row) => (
        <Popup
          // eslint-disable-next-line
          data={tableActionsPopup(id!, notification.data?.audience.audienceType!, row.entity.id)}
        >
          <div className='table-kebab-actions' style={{ justifyContent: 'flex-end' }}>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const emptyTableConf = useEmptyTable(() => {
    onOpen(RIGHT_MODALS.SUPPORT.CHANGE_MPIN, {
      audienceType: notification?.data!.audience.audienceType,
      title: 'Add Audience Base',
      id,
      initialStep: 'searchAudience',
    })
  })
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IAudience>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  return (
    <>
      <TableHeader
        filterValues={filterValues}
        setActiveFilters={handleFiltersChange}
        data={filtersList}
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        pagination={pagination}
        className={styles.table}
        onChange={handleTableChange}
        loading={isFetching}
        rowKey={(row) => row?.entity.id}
        dataSource={data?.items}
        rowSelection={{ type: 'checkbox' }}
        scroll={{ x: 1100 }}
      />
    </>
  )
}
