import React, { memo, useCallback, useMemo } from 'react'

import {
  IInternalChatMessage,
  IInternalChatMessageFile,
  MessageTypeEnum,
} from '../../../../../features/InternalChat/models'
import { Microphone, MissedCall } from '../../../../../assets/svg'
import styles from './styles.module.scss'
import { isObject } from 'lodash'
import classNames from 'classnames'
import { isArabic } from '../../../../../helpers/utils'

interface IProps {
  message: string | IInternalChatMessage
  type: MessageTypeEnum
}

const LastMessage = ({ message, type }: IProps) => {
  const messageIcon = useMemo(() => {
    if (type === MessageTypeEnum.VOICE_MESSAGE) return <Microphone />
    if (type === MessageTypeEnum.MESSED_CALL) return <MissedCall />
    return null
  }, [type])

  const getLastMessageFileType = useCallback((files: Array<IInternalChatMessageFile>) => {
    const getFileType = (item: IInternalChatMessageFile) => {
      if (item.fileType.toLowerCase() === 'photo') {
        return 'Photo'
      }
      if (item.fileType.toLowerCase() === 'video') {
        return 'Video'
      }
      if (item?.file?.fileFormat === '.ogg') {
        return 'Voice Message'
      }
      return 'Other'
    }

    const fileTypes = files.map((item) => getFileType(item))

    return fileTypes.length > 0 ? fileTypes[fileTypes.length - 1] : ''
  }, [])

  const messageText = useMemo(() => {
    if (isObject(message) && message?.message) {
      return message.message
    }

    if (isObject(message) && message?.internalChatMessageFiles?.length) {
      return getLastMessageFileType(message?.internalChatMessageFiles)
    }

    if (typeof message === 'string') {
      return message
    }

    return ''
  }, [getLastMessageFileType, message])

  const normalizedMessage = useMemo(() => {
    const regex = /\{"id": "([0-9]+)", "name": "([a-zA-Z0-9\s-]+)"}/gi
    const regexTransaction = /\{"id": "([0-9]+)", "transaction": "([a-zA-Z\s-]+)"}/gi
    if (regex.test(messageText) || regexTransaction.test(messageText)) {
      return messageText?.split('::').map((el) => {
        if (regex.test(el)) {
          const data = JSON.parse(el)
          return (
            <span key={data.id} className={styles.isUserTag}>
              @{data.name}
            </span>
          )
        }
        if (regexTransaction.test(el)) {
          const data = JSON.parse(el)
          return (
            <span key={data.id} className={styles.isUserTag}>
              #{data.id}
            </span>
          )
        }
        return el
      })
    }
    return messageText
  }, [messageText])

  return (
    <div className={styles.internalUserLastMessage}>
      <div className={styles.messageTextContainer}>
        {messageIcon && <div className={styles.messageIcon}>{messageIcon}</div>}
        <div
          className={classNames(styles.messageText, {
            [styles.arabic]: messageText && isArabic(messageText),
          })}
        >
          {normalizedMessage}
        </div>
      </div>
    </div>
  )
}

export default memo(LastMessage)
