export enum FileCategoryEnum {
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
  PHOTO = 'PHOTO',
  FOLDER = 'FOLDER',
  AUDIO = 'AUDIO',
  UNKNOWN = 'UNKNOWN',
}

export interface ICreatedBy {
  firstName?: string
  lastName?: string
  id: number
  name: string
}

export interface IFile {
  id: number
  name: string
  link: string
  fileFormat: string
  category: FileCategoryEnum
  isPrivate: boolean
  createdByUser: ICreatedBy
  createdAt: Date
}

export interface IFileTable {
  id: string
  preview: string
  fileName: string
  category: FileCategoryEnum
  isPrivate: boolean
  fileType: string
  createdAt: Date
  creatorName: string
  creatorId: number
  createdByUser: ICreatedBy
}
