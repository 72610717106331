import { ITableConf } from '../../../shared/models/ITableConf'
import { IByUser } from '../../../shared/models/IByUser'

export enum PRODUCT_TYPE_ENUM {
  TOPUP = 'TOPUP',
  E_PIN = 'E_PIN',
  BILL_PAYMENT = 'BILL_PAYMENT',
  BUNDLE_TOPUP = 'BUNDLE_TOPUP',
  CASH_IN = 'CASH IN',
  CASH_OUT = 'CASH OUT',
}

export enum MASTER_PRODUCT_TYPE_ENUM {
  TOPUP = 'TOPUP',
  BUNDLE_TOPUP = 'BUNDLE_TOPUP',
  MASTER_BUNDLE_TOPUP = 'MASTER_BUNDLE_TOPUP',
  E_PIN = 'E_PIN',
}

export enum PRODUCT_BUNDLE_REVERSE_TYPE_ENUM {
  MANUAL = 'MANUAL',
  API = 'API',
  NA = 'N/A',
}

export enum BUNDLE_TYPE_ENUM {
  DATA = 'Data',
  VOICE = 'Voice',
  SMS = 'SMS',
  MIX = 'Mix',
  CUSTOM = 'Custom',
}

export enum AVAILABILITY {
  FULL = 'Full',
  DAY = 'Day',
  NIGHT = 'Night',
  FRIDAY = 'Friday',
  HOUR = 'Hour',
}

export enum BUNDLE_VALIDITY_ENUM {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  BI_MONTHLY = 'Bi-Monthly',
  BI_WEEKLY = 'Bi-Weekly',
  QUARTERLY = 'Quarterly',
  YEARLY = 'Yearly',
}

export enum DATA_UNITS {
  GB = 'GB',
  MB = 'MB',
}

export enum VOICE_UNITS {
  ONNET = 'Onnet Minutes',
  OFFNET = 'Offnet Minutes',
}

export enum SMS_UNITS {
  ONNET = 'Onnet SMS',
  OFFNET = 'Offnet SMS',
}

export enum MIX_UNITS {
  MB_OnnetMinutes_OnnetSMS = 'MB+Onnet Minutes+Onnet SMS',
  MB_OnnetMinutes_OffnetSMS = 'MB+Onnet Minutes+Offnet SMS',
  MB_OffnetMinutes_OnnetSMS = 'MB+Offnet Minutes+Onnet SMS',
  MB_OffnetMinutes_OffnetSMS = 'MB+Offnet Minutes+Offnet SMS',
  GB_OnnetMinutes_OnnetSMS = 'GB+Onnet Minutes+Onnet SMS',
  GB_OnnetMinutes_OffnetSMS = 'GB+Onnet Minutes+Offnet SMS',
  GB_OffnetMinutes_OnnetSMS = 'GB+Offnet Minutes+Onnet SMS',
  GB_OffnetMinutes_OffnetSMS = 'GB+Offnet Minutes+Offnet SMS',
}

export interface IBundle {
  id: number
  productId: number
  name: string
  description: string
  internalProductCode: string
  bundleExternalProductCode: string
  price: number
  costRefundPenaltyRate: number
  refundType: string
  shortcode: string
}

export interface IMaster {
  id: number
  name: string
}

export interface ITopupBundle {
  size: string
  unit: DATA_UNITS | VOICE_UNITS | SMS_UNITS | MIX_UNITS
}

export interface IProduct {
  id: number
  name: string
  logo: string
  status: boolean
  type: PRODUCT_TYPE_ENUM
  internalProductCode: string
  externalProductCode: string
  createdAt: Date
  updatedAt: Date
  vendor?: IByUser
  masterProduct: IMaster
  createdByUser: IByUser
  updatedByUser: IByUser
  productIntegration: IMaster
  denomination?: number
  description: string
  denominationMin: string
  denominationMax: string
  unitCost: number
  refundPenaltyRate: number
  reverseTimeEligibility: number
  discountRate: number
  reverseType: PRODUCT_BUNDLE_REVERSE_TYPE_ENUM
  bundles: Array<IBundle>
  taxRecoveryRate: number
  maximumReverseProcessingTime: number
  reverseDenominationMin: number
  reverseDenominationMax: number
  bundleType: BUNDLE_TYPE_ENUM
  availability: AVAILABILITY
  bundleValidity: number
  bundleDescription: string
  bundlePrice: number
  bundleTax: number
  agentBundlePrice: number
  apiBundlePrice: number
  bundleSize: string
  bundleSizeUnit: DATA_UNITS | VOICE_UNITS | SMS_UNITS | MIX_UNITS
  agentBundleServiceFee: number
  apiBundleServiceFee: number
  dataBundle?: ITopupBundle
  voiceBundle?: ITopupBundle
  SMSBundle?: ITopupBundle
  processorTopupAmount: number
}

export interface IProductCreate {
  vendorId: number
  masterProductId: number
  productIntegrationId: number
  name: string
  internalProductCode: string
  externalProductCode: string
  status: true
  type: PRODUCT_TYPE_ENUM
  description: string
  denominationMin: number
  denominationMax: number
  unitCost: number
  refundPenaltyRate: number
  reverseType: PRODUCT_BUNDLE_REVERSE_TYPE_ENUM
  bundles: Array<IBundle>
}

export interface IVendorBundle {
  id: number
  name: string
  description: string
  internalProductCode: string
  bundleExternalProductCode: string
  price: number
  costRefundPenaltyRate: number
  refundType: PRODUCT_BUNDLE_REVERSE_TYPE_ENUM
  shortcode: string
}

export interface IGetProducts extends ITableConf {
  vendorId?: string
  masterProductId?: number
}

export interface IUpdateVendorBundle {
  toCreate: Array<Omit<IVendorBundle, 'id'>>
  toUpdate: Array<IVendorBundle>
  toDelete: Array<number>
}
