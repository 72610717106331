import React, { useContext, useEffect, useState } from 'react'
import { RightModalContext } from '../../../../../../../../Modals'
import { useNavigate } from 'react-router-dom'
import { IUser } from '../../../../../../../models/IUser'
import { ISelectedFilters } from '../../../../../../../../../shared/components/filter/Filter'
import { InfiniteScrollComponent } from '../../../../../../../../../shared/components/infiniteScoll/InfiniteScroll'
import {
  useGetUsersFiltersListQuery,
  useGetUsersListQuery,
} from '../../../../../../../core/http/UsersManagementApi'
import useSearch from '../../../../../../../helpers/useSearch'
import { SETTINGS_ENUM } from '../../../../../../../../../routes/settings'
import { TextFieldSearch } from '../../../../../../../../../shared/components/textFieldSearch/TextFieldSearch'
import styles from '../../../Departments/DepartmentsManagementNew/Components/styles.module.scss'
import { Col, Spin } from 'antd'
import { IconButton } from '../../../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon } from '../../../../../../../../../assets/svg'
import { InfoMessage } from '../../../../../../VendorManagement/VendorManagementContactListModal/Components/InfoMessage'
import { ContactListModalHeader } from '../../../../../../VendorManagement/VendorManagementContactListModal/Components/ContactListModalHeader'
import { UserList } from './UserList'
import { usePatchUserPositionMutation } from '../../../../../../../core/http/PositionsApi'
import { isEmpty, isNumber } from 'lodash'
import { ITableConf } from '../../../../../../../../../shared/models/ITableConf'

export const PositionUserListModal = () => {
  const {
    onClose,
    props: { id, onDeleteUser, onAddUser },
  } = useContext(RightModalContext)
  const navigate = useNavigate()

  const [usersList, setUsersList] = useState<IUser[]>([])
  const [filterValues, setFilterValues] = useState<ISelectedFilters>({})
  const [isChangeSearch, setIsChangeSearch] = useState(false)
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 10,
    search: '',
  })

  const [patchPos] = usePatchUserPositionMutation()
  const { data: filterFields } = useGetUsersFiltersListQuery()
  const { data } = useGetUsersListQuery({
    ...tableConf,
    ...filterValues,
  })

  const { handleSearchChange } = useSearch(setTableConf)

  const loadMoreData = () => {
    setTableConf((pre: ITableConf) => ({ ...pre, page: pre.page! + 1 }))
  }

  useEffect(() => {
    if (isEmpty(data)) return

    if (isChangeSearch && (tableConf.search || Object.keys(filterValues).length !== 0)) {
      setUsersList(data.items)
      setIsChangeSearch(false)
    } else {
      return setUsersList([...usersList, ...data.items])
    }
    // eslint-disable-next-line
  }, [data])

  const handleDeleteUser = (id: number) => {
    if (onDeleteUser) {
      return onDeleteUser(id)
    }
  }

  const handleAddUser = (user: IUser) => {
    if (onAddUser) {
      return onAddUser(user)
    }
    patchPos({ id, body: { userIds: [user.id] } })
  }

  const onPressCreateNewUser = () => {
    navigate(SETTINGS_ENUM.USERS_MANAGEMENT_USER_NEW)
    onClose()
  }

  const searchComponent = (
    <TextFieldSearch
      className={styles.search}
      onChange={(e) => {
        setIsChangeSearch(true)
        handleSearchChange(e)
      }}
    />
  )

  const addButtonComponent = (
    <Col>
      <IconButton
        className={styles.addBtn}
        icon={<AddIcon />}
        type='primary'
        color='orange'
        size='large'
        onClick={onPressCreateNewUser}
      />
    </Col>
  )

  const content = (
    <InfiniteScrollComponent
      loadMoreData={loadMoreData}
      dataLength={usersList.length}
      totalCount={data?.totalCount as number}
      loaderStyles={{ width: 'calc(100% - 24px * 2)', marginLeft: '24px' }}
    >
      <div className={styles.layout} style={{ marginBottom: 0 }}>
        <InfoMessage text='Users' />
        <ContactListModalHeader
          classNameFilterPopup={styles.filterPopup}
          setActiveFilters={(res) => {
            setIsChangeSearch(true)
            setFilterValues(res)
          }}
          filterValues={filterValues}
          data={filterFields}
          searchComponent={searchComponent}
          addButtonComponent={addButtonComponent}
          classNamesFilterWrapperIcon={styles.filterWrraperIcon}
        />
        <UserList
          users={usersList}
          onPressSelect={(user) => handleAddUser(user)}
          positionId={id}
          onDeleteUser={(id) => handleDeleteUser(id)}
        />
      </div>
    </InfiniteScrollComponent>
  )

  return !isNumber(data?.totalCount) ? <Spin>{content}</Spin> : content
}
