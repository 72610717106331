import styles from '../InventoryTable/styles.module.scss'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { FINANCE_ENUM } from '../../../../../../routes/finance'
import { formatNumberToLocale } from '../../../../../salesNetwork/helpers'
import { TableActionIcon } from '../../../../../../assets/svg'
import { IPopupListItems, Popup } from '../../../../../../shared/components/popup/Popup'
import { IHydraTable } from '../../../../models/IHydra'
import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import { useGetQueryParams } from '../../../../../../shared/hooks/table/useGetQueryParams'
import { useGetProfileQuery } from '../../../../../Profile/core/http/Profile'
import useTable from '../../../../../../shared/hooks/table/useTable'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import {
  useGetGroupSimInventoryFiltersListQuery,
  useGetGroupSimInventoryListQuery,
} from '../../../../core/http/GroupSimCardInventories'
import { isEmpty } from 'lodash'
import moment from 'moment/moment'
import { TableHeader } from '../PurchaseOrderTable/TableHeader'
import { Link } from 'react-router-dom'
import emptyUserImage from '../../../../../../assets/img/Avatar.png'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

export const HydraTable = () => {
  const navigate = useNavigate()
  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.ASC,
    orderField: 'name',
  })

  const [dateFilter, setDateFilter] = useState<string | undefined>()
  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const { data: profile } = useGetProfileQuery()
  const { data, isFetching } = useGetGroupSimInventoryListQuery(
    { ...tableConf, ...filterValues, date: dateFilter },
    { skip: Object.keys(tableConf).length < 4 || !dateFilter }
  )
  const { data: filterFields } = useGetGroupSimInventoryFiltersListQuery(
    { date: dateFilter },
    { skip: !dateFilter }
  )

  const { pagination, handleTableChange, handleFiltersChange } = useTable<IHydraTable>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })
  const emptyTableConf = useEmptyTable()

  const tableActionsPopup = (row: IHydraTable): IPopupListItems[] => [
    {
      text: 'View',
      shouldDisplay: true,
      onClick: () =>
        navigate(`${FINANCE_ENUM.TRUST_WALLET}/hydra/inventory/view/${row?.vendor?.id}`),
    },
  ]

  const columns: ColumnsType<IHydraTable> = [
    {
      title: 'Name Of Hydra',
      dataIndex: 'name',
      sorter: true,
      width: '35%',
      render: (name, { vendor }) => (
        <RecursivelyReplaceCharacters>
          <Link
            to={`${FINANCE_ENUM.TRUST_WALLET}/hydra/inventory/view/${vendor?.id}`}
            className='table-avatar'
          >
            <img
              src={!isPrivateMode && vendor?.logo ? vendor?.logo : emptyUserImage}
              alt='vendor-logo'
            />
            <div>{vendor?.name}</div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Total Balance',
      dataIndex: 'balance',
      width: '20%',
      render: (balance) => (
        <RecursivelyReplaceCharacters>{formatNumberToLocale(balance)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Today Sales',
      dataIndex: 'salesToday',
      width: '20%',
      render: (todaySales) => (
        <RecursivelyReplaceCharacters>
          {formatNumberToLocale(todaySales)}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Average Sales',
      dataIndex: 'salesAverage',
      width: '20%',
      render: (averageSales) => (
        <RecursivelyReplaceCharacters>
          {formatNumberToLocale(averageSales)}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '10%',
      render: (_, row) => {
        return (
          <Popup data={tableActionsPopup(row)}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        )
      },
    },
  ]

  useEffect(() => {
    if (isEmpty(profile)) return
    setDateFilter(moment().toISOString(true))
  }, [profile])

  return (
    <>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filterFields}
      />
      <Table
        rowSelection={{ type: 'checkbox' }}
        columns={columns}
        className={styles.table}
        locale={emptyTableConf}
        pagination={pagination}
        dataSource={data?.items}
        loading={isFetching}
        onChange={handleTableChange}
        rowKey={(row) => row?.vendor?.id}
        scroll={{ x: 1000 }}
      />
    </>
  )
}
