import React, { memo } from 'react'
import styles from '../../styles.module.scss'
import { Widget } from '../../../../../../shared/components/widget'
import {
  BalanceIcon,
  MonetizationWidgetIcon,
  NewRegistrationIcon,
  UserGroupRounded,
} from '../../../../../../assets/svg'
import { WIDGET_LIST_ENUM } from '../../../../../../shared/models/WidgetListEnum'
import { SalesDashboardWidgetStateType } from '../../index'

interface IProps {
  widgetData: SalesDashboardWidgetStateType
}

const SalesNetworkWidgets = ({ widgetData }: IProps) => {
  return (
    <div className={styles.widgetsWrapper}>
      <Widget
        icon={<UserGroupRounded />}
        title='Active Agents'
        value={widgetData[WIDGET_LIST_ENUM.ACTIVE_AGENTS]?.value}
        valueTotal={widgetData[WIDGET_LIST_ENUM.ACTIVE_AGENTS]?.totalValue}
        statistic={widgetData[WIDGET_LIST_ENUM.ACTIVE_AGENTS]?.statistic}
      />
      <Widget
        icon={<NewRegistrationIcon />}
        title='New Registration'
        value={widgetData[WIDGET_LIST_ENUM.NEW_REGISTRATION]?.value}
        statistic={widgetData[WIDGET_LIST_ENUM.NEW_REGISTRATION]?.statistic}
      />
      <Widget
        icon={<BalanceIcon />}
        title='Total Pickup'
        value={widgetData[WIDGET_LIST_ENUM.TOTAL_PICKUP]?.value}
        valueTotal={widgetData[WIDGET_LIST_ENUM.TOTAL_PICKUP]?.valueTotal}
        statistic={widgetData[WIDGET_LIST_ENUM.TOTAL_PICKUP]?.statistic}
      />
      <Widget
        icon={<MonetizationWidgetIcon />}
        title='Total Sales'
        value={widgetData[WIDGET_LIST_ENUM.TOTAL_SALES]?.value}
        statistic={widgetData[WIDGET_LIST_ENUM.TOTAL_SALES]?.statistic}
      />
    </div>
  )
}

export default memo(SalesNetworkWidgets)
