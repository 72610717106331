import styles from './styles.module.scss'
import { StatementOfWalletsTabs } from './components/Tabs'

export const StatementOfWallets = () => {
  return (
    <div className={styles.layout}>
      <StatementOfWalletsTabs />
    </div>
  )
}
