import styles from '../../styles.module.scss'
import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { Space } from 'antd'
import { InfoIcon } from '../../../../../../assets/svg'
import { Tooltip } from '../../../../../../shared/components/tooltip/Tooltip'

interface IProps {
  title: string
  children: ReactNode
  filters?: ReactNode
  isBlue?: boolean
  infoText?: string
  withPadding?: boolean
}

export const DashboardTableWrapper = ({
  title,
  filters,
  children,
  isBlue,
  infoText,
  withPadding = true,
}: IProps) => {
  return (
    <div className={classNames(styles.tableWrapper, { [styles.blue]: isBlue })}>
      <div className={styles.header}>
        <Space size={10}>
          <span className={styles.headerTitle}>{title}</span>
          {infoText && (
            <Tooltip title={infoText}>
              <span className={styles.headerInfoIcon}>
                <InfoIcon />
              </span>
            </Tooltip>
          )}
        </Space>
        <Space size={10}>{filters}</Space>
      </div>
      <div
        className={classNames({
          [styles.content]: withPadding,
          [styles.withoutPadding]: !withPadding,
        })}
      >
        {children}
      </div>
    </div>
  )
}
