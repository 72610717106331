import { useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Switch, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

import TableHeader from '../../TableHeader'
import { DateTableIcon, TableActionIcon } from '../../../../../../../assets/svg'
import TableUsersOrder from '../../../../../../../shared/components/tableUsersOrder'
import { ISelectedFilters } from '../../../../../../../shared/components/filter/Filter'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../../shared/components/popup/Popup'

import {
  useDeleteDepartmentByIdMutation,
  useGetDepartmentsListQuery,
  useGetDepartmentsManagementFiltersListQuery,
  useLazyExportDepartmentsListTableQuery,
  usePatchDepartmentMutation,
} from '../../../../../core/http/DepartmentsManagementApi'

import { formatDateWithTime } from '../../../../../../../helpers/utils'
import { useTableExport } from '../../../../../../../shared/hooks/table/useTableExport'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'

import { ErrorNode, showConfirmMessage } from '../../../../../../../shared/api/errorHandler'
import { ITableConf, SortingOrderEnum } from '../../../../../../../shared/models/ITableConf'
import { IDepartmentTable } from '../../../../../models/IDepartmentsManagement'

import styles from './styles.module.scss'
import { useEmptyTable } from '../../../../../../../shared/hooks/table/useEmptyTable'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { RecursivelyReplaceCharacters } from '../../../../../../../shared/components/privateMode'
import { externalChatSocketConnection } from '../../../../../../../shared/sockets'
import { getSavedAuthData } from '../../../../../../../shared/api'

interface IProps {
  canEditDepartment: boolean
  canDeleteDepartment: boolean
}

export const DepartmentsManagement = ({ canEditDepartment, canDeleteDepartment }: IProps) => {
  const navigate = useNavigate()
  const token = getSavedAuthData()?.tokens?.accessToken

  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })
  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const [updateDepartment, upDepartmentResp] = usePatchDepartmentMutation()
  const [deleteDepartment, delDepartmentResp] = useDeleteDepartmentByIdMutation()
  const [exportTable] = useLazyExportDepartmentsListTableQuery()
  const { data: filtersList } = useGetDepartmentsManagementFiltersListQuery()
  const { data = { items: [], totalCount: 0 }, isFetching } = useGetDepartmentsListQuery(
    {
      ...filterValues,
      ...tableConf,
    },
    { skip: Object.keys(tableConf).length < 4 }
  )
  const { data: profile } = useGetProfileQuery()

  const afterSuccess = () => {
    externalChatSocketConnection.connect(token)
  }

  useNotification(NOTIFICATION_TYPES.success, upDepartmentResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    upDepartmentResp.isError,
    upDepartmentResp.error as ErrorNode
  )
  useNotification(NOTIFICATION_TYPES.success, delDepartmentResp.isSuccess, null, afterSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    delDepartmentResp.isError,
    delDepartmentResp.error as ErrorNode
  )

  useTableExport({ tableConf, filterValues, promise: exportTable })

  const emptyTableConf = useEmptyTable(() => navigate('departments/new'))
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IDepartmentTable>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  const openNotification = useCallback(
    (id: string) => {
      showConfirmMessage('This department will be deleted', () => deleteDepartment(id))
    },
    [deleteDepartment]
  )

  const tableActionsPopup = useCallback(
    (id: string): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () => navigate(`departments/view/${id}`),
        },
        {
          text: 'Edit',
          shouldDisplay: canEditDepartment,
          onClick: () => navigate(`departments/edit/${id}`),
        },
        {
          text: 'Delete',
          withDivider: true,
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          shouldDisplay: canDeleteDepartment,
          onClick: () => {
            openNotification(id)
          },
        },
      ]
    },
    [navigate]
  )

  const columns: ColumnsType<IDepartmentTable> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '25%',
      sorter: true,
      render: (name: IDepartmentTable['name'], { id }) => (
        <RecursivelyReplaceCharacters>
          <Link to={`departments/view/${id}`} className={styles.itemText}>
            <span>{name}</span>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      width: '20%',
      sorter: true,
      render: (createdAt: IDepartmentTable['createdAt']) => (
        <RecursivelyReplaceCharacters>
          <div className='table-date'>
            <DateTableIcon />
            <span>{formatDateWithTime(createdAt, profile?.calendar)}</span>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'List of users',
      dataIndex: 'users',
      width: '20%',
      render: (users: IDepartmentTable['users'], row) => (
        <RecursivelyReplaceCharacters>
          <div className='table-date'>
            <TableUsersOrder users={users} totalCount={row.usersCount} />
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Added by',
      dataIndex: 'createdByUser',
      width: '25%',
      sorter: true,
      render: (createdByUser: IDepartmentTable['createdByUser']) => (
        <RecursivelyReplaceCharacters>{`${createdByUser.firstName ?? ''} ${
          createdByUser.lastName ?? ''
        }`}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      sorter: true,
      render: (status: IDepartmentTable['status'], { id }) => {
        return (
          <Switch
            disabled={!canEditDepartment}
            defaultChecked={status}
            onChange={(status) => {
              updateDepartment({ id, body: { status } })
            }}
            loading={false}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => {
        return (
          <Popup data={tableActionsPopup(row.id)}>
            <div className={`table-kebab-actions ${_ === 'Delete' && styles.deleteButton}`}>
              <TableActionIcon />
            </div>
          </Popup>
        )
      },
    },
  ]

  return (
    <>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filtersList}
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        onChange={handleTableChange}
        className={styles.table}
        loading={isFetching || upDepartmentResp.isLoading}
        dataSource={data.items}
        scroll={{ x: 1000 }}
        pagination={pagination}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.id}
      />
    </>
  )
}
