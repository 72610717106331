import React, { memo } from 'react'
import styles from './styles.module.scss'
import { ChatInfoAvatar } from 'src/shared/components/chat/Components'
import emptyLogo from 'src/assets/img/Avatar.png'

interface IProps {
  logo?: string | null
  fullName: string
}

const ChatRoomDetailsMainInfo = ({ logo, fullName }: IProps) => {
  const onPressMessages = () => {}

  return (
    <div className={styles.internalChatMainInfo}>
      <ChatInfoAvatar logo={logo ?? emptyLogo} />
      <div className={styles.userName}>{fullName}</div>
      {/*<div className={styles.onlineStatus}>Online</div>*/}
    </div>
  )
}

export default memo(ChatRoomDetailsMainInfo)
