import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { ITicket, ITicketExtended, ITicketStatusBody } from '../../models/ITicket'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { DefaultOptionType } from 'rc-cascader'

export const ticketSystemApi = createApi({
  reducerPath: 'ticketSystem',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['TicketList', 'Ticket', 'Tags', 'AssignedTicketList', 'FilterFields'],
  endpoints: (builder) => ({
    getAllTickets: builder.query<ITableResponse<ITicket>, ITableConf>({
      query: (params) => ({
        url: 'tickets',
        params,
      }),
      transformResponse: (res: ITableResponse<ITicket>) => ({
        ...res,
        items: res.items.map((item) => ({ ...item, children: [] })),
      }),
      providesTags: () => ['TicketList'],
    }),
    getAssignedTickets: builder.query<ITableResponse<ITicket>, ITableConf>({
      query: (params) => ({
        url: 'tickets/assigned',
        params,
      }),
      transformResponse: (res: ITableResponse<ITicket>) => ({
        ...res,
        items: res.items.map((item) => ({ ...item, children: [] })),
      }),
      providesTags: () => ['AssignedTicketList'],
    }),

    getTicketById: builder.query<ITicketExtended, string>({
      query: (id) => ({
        url: `tickets/${id}`,
      }),
      providesTags: () => ['Ticket'],
    }),

    createTicket: builder.mutation<ITicket, FormData>({
      query: (body) => ({
        url: 'tickets',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['TicketList', 'AssignedTicketList', 'FilterFields'],
    }),

    updateTicket: builder.mutation<ITicket, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: `tickets/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['TicketList', 'Ticket', 'AssignedTicketList', 'FilterFields'],
    }),

    deleteTicket: builder.mutation<void, string>({
      query: (id) => ({
        url: `tickets/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['TicketList', 'AssignedTicketList', 'FilterFields'],
    }),

    updateTicketStatus: builder.mutation<ITicket, { id: string; body: ITicketStatusBody }>({
      query: ({ id, body }) => ({
        url: `tickets/${id}/status`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['TicketList', 'Ticket', 'AssignedTicketList', 'FilterFields'],
    }),

    updateTicketReply: builder.mutation<ITicket, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: `tickets/${id}/reply`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['TicketList', 'Ticket', 'AssignedTicketList', 'FilterFields'],
    }),

    getTags: builder.query<ITableResponse<DefaultOptionType>, void>({
      query: () => ({
        url: `tickets/filter-values?filterField=tags`,
      }),
      transformResponse: (res: ITableResponse<string>) => ({
        ...res,
        items: res.items.map((tag) => ({ label: tag, value: tag })),
      }),
      providesTags: () => ['Tags'],
    }),

    getAllTicketsTableFiltersList: builder.query<Array<IFilterFields>, null>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'tickets/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `tickets/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: ['FilterFields'],
    }),

    getAssignedTicketsTableFiltersList: builder.query<Array<IFilterFields>, null>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'tickets/assigned/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `tickets/assigned/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: ['FilterFields'],
    }),
  }),
})

export const {
  useGetAllTicketsQuery,
  useGetAllTicketsTableFiltersListQuery,
  useGetTicketByIdQuery,
  useCreateTicketMutation,
  useUpdateTicketMutation,
  useDeleteTicketMutation,
  useUpdateTicketStatusMutation,
  useUpdateTicketReplyMutation,
  useGetTagsQuery,
  useGetAssignedTicketsTableFiltersListQuery,
  useGetAssignedTicketsQuery,
} = ticketSystemApi
