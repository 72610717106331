import { Form } from 'antd'
import styles from './styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { isInteger } from 'lodash'
import { TextField } from '../../../../../shared/components/textfield/TextField'
import { Wrapper } from '../../../../salesNetwork/components/Wrapper'
import { ITrustWalletTransactionSub } from '../../../../Finance/models/ITransaction'
import { ITableResponse } from '../../../../../shared/models/ITableResponse'

export const SubTransactions = ({ data }: { data: ITableResponse<ITrustWalletTransactionSub> }) => {
  return (
    <Wrapper title='Sub Transaction Detail'>
      <Form.List name='subTransactions' initialValue={data?.items}>
        {(fields) => (
          <>
            {fields.map(({ key, name }) => (
              <div className={styles.subTransactionItem} key={key}>
                <div className={styles.dblRow}>
                  <FormItem
                    name={isInteger(name) ? [name as number, 'id'] : 'id'}
                    label='Sub-Transaction ID'
                  >
                    <TextField disabled />
                  </FormItem>

                  <FormItem
                    name={isInteger(name) ? [name as number, 'createdAt'] : 'createdAt'}
                    label='Date & Time'
                  >
                    <TextField disabled />
                  </FormItem>
                </div>

                <div className={styles.dblRow}>
                  <FormItem noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const sender = getFieldValue(['subTransactions', name as number, 'sender'])

                      return (
                        <FormItem
                          name={isInteger(name) ? [name as number, sender, 'name'] : 'sender'}
                          label='Sender'
                        >
                          <TextField disabled />
                        </FormItem>
                      )
                    }}
                  </FormItem>

                  <FormItem noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const receiver = getFieldValue([
                        'subTransactions',
                        name as number,
                        'receiver',
                      ])

                      return (
                        <FormItem
                          name={isInteger(name) ? [name as number, receiver, 'name'] : 'receiver'}
                          label='Receiver'
                        >
                          <TextField disabled />
                        </FormItem>
                      )
                    }}
                  </FormItem>
                </div>

                <div className={styles.dblRow}>
                  <FormItem
                    name={
                      isInteger(name)
                        ? [name as number, 'transactionCategory']
                        : 'transactionCategory'
                    }
                    label='Transaction Type'
                  >
                    <TextField disabled />
                  </FormItem>

                  <FormItem
                    name={isInteger(name) ? [name as number, 'amount'] : 'amount'}
                    label='Amount'
                  >
                    <TextField disabled />
                  </FormItem>

                  <FormItem
                    name={isInteger(name) ? [name as number, 'status'] : 'status'}
                    label='Status'
                  >
                    <TextField disabled />
                  </FormItem>
                </div>
              </div>
            ))}
          </>
        )}
      </Form.List>
    </Wrapper>
  )
}
