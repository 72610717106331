import React, { memo } from 'react'
import { Col, Row } from 'antd'

import { FilterPopup } from 'src/shared/components/filter/FilterPopup'
import { Filter, ISelectedFilters } from 'src/shared/components/filter/Filter'
import { IFilterFields } from 'src/shared/models/IFilterFields'
import { useFilter } from 'src/shared/hooks/filter/useFilter'
import { AddIcon, DownloadIcon } from '../../../../../../assets/svg'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { useNavigate } from 'react-router-dom'

interface IProps {
  setActiveFilters: (values: ISelectedFilters) => void
  filterValues: ISelectedFilters
  data?: IFilterFields[]
}

const TableHeader = ({ setActiveFilters, data, filterValues }: IProps): JSX.Element => {
  const navigate = useNavigate()

  const { isOpen, onCancel, form, onReset, onFinish } = useFilter({ setActiveFilters })

  return (
    <>
      <Row align='middle' justify='space-between' style={{ marginBottom: 20 }}>
        <Col style={{ display: 'flex', gridGap: 20 }}>
          <Filter
            data={data}
            form={form}
            setIsOpen={onCancel}
            selectedFilters={filterValues}
            setSelectedFilters={setActiveFilters}
          />
        </Col>
        <Row>
          <IconButton
            icon={<DownloadIcon />}
            type='primary'
            color='blue'
            style={{ marginRight: 16 }}
          />
          <IconButton
            icon={<AddIcon />}
            type='primary'
            color='orange'
            onClick={() => navigate('new')}
          />
        </Row>
      </Row>
      {isOpen && (
        <FilterPopup
          data={data}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          form={form}
          onCancel={onCancel}
          onReset={onReset}
          onFinish={onFinish}
        />
      )}
    </>
  )
}

export default memo(TableHeader)
