import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import {
  IResponseCode,
  IResponseCodeMutation,
  IResponseCodeTable,
} from '../../models/IResponseCode'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../../shared/models/ITableConf'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

export const responseCodeApi = createApi({
  reducerPath: 'responseCode',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['ResponseCodeList', 'ResponseCode', 'FilterFields'],
  endpoints: (builder) => ({
    getResponseCodeList: builder.query<ITableResponse<IResponseCodeTable>, ITableConf>({
      query: (params) => ({
        url: 'response-code',
        params,
      }),
      providesTags: ['ResponseCodeList'],
    }),

    getResponseCodeById: builder.query<IResponseCode, string>({
      query: (id) => ({
        url: `response-code/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'ResponseCode', id: arg }],
    }),

    deleteResponseCodeById: builder.mutation<void, string>({
      query: (id) => ({
        url: `response-code/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'ResponseCode', id: arg },
        'ResponseCodeList',
        'FilterFields',
      ],
    }),

    updateResponseCode: builder.mutation<
      IResponseCode,
      { id: string; body: IResponseCodeMutation }
    >({
      query: ({ id, body }) => ({
        url: `response-code/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result) => [
        { type: 'ResponseCode', id: result?.code },
        'ResponseCodeList',
        'FilterFields',
      ],
    }),

    createResponseCode: builder.mutation<IResponseCode, IResponseCodeMutation>({
      query: (body) => ({
        url: `response-code`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['ResponseCodeList', 'FilterFields'],
    }),

    exportResponseCodesTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx' }
    >({
      query: ({ conf, formatFile }) => ({
        url: `response-code/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getResponseCodeFilterList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'response-code/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `response-code/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: ['FilterFields'],
    }),
  }),
})

export const {
  useGetResponseCodeListQuery,
  useGetResponseCodeFilterListQuery,
  useGetResponseCodeByIdQuery,
  useDeleteResponseCodeByIdMutation,
  useLazyExportResponseCodesTableQuery,
  useUpdateResponseCodeMutation,
  useCreateResponseCodeMutation,
} = responseCodeApi
