import FormItem from 'antd/es/form/FormItem'
import { requiredValidator } from '../../../../../../../../helpers/validation'
import { DatePicker } from '../../../../../../../../shared/components/datePicker'
import { Wrapper } from '../../../../../../../../shared/components/wrapper/Wrapper'
import { Moment } from 'moment'

interface IProps {
  onMonthChange: (month: Moment) => void
}

export const PeriodBlock = ({ onMonthChange }: IProps) => {
  return (
    <Wrapper title='Period'>
      <FormItem
        name='monthRange'
        label='Month'
        className='noBottomMargin'
        rules={[{ required: true, validator: requiredValidator('Month') }]}
      >
        <DatePicker picker='month' onChange={(date) => onMonthChange(date as Moment)} allowClear />
      </FormItem>
    </Wrapper>
  )
}
