import styles from './style.module.scss'
import modalStyles from '../../../../../Support/pages/Chatroom/Components/ChangeMPinModal/styles.module.scss'
import { Checkbox } from '../../../../../../shared/components/checkbox/Checkbox'
import emptyAvatarImage from '../../../../../../assets/img/Avatar.png'
import { Button } from '../../../../../../shared/components/button/Button'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { ArrowRightIcon } from '../../../../../../assets/svg'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import React, { useContext } from 'react'
import { IAgent } from '../../../../../salesNetwork/models/IAllTable'
import { RightModalContext } from '../../../../../Modals'

interface IProps {
  data?: IAgent[]
  onCheckboxChange: () => void
  onAddButtonClick: (id: number) => () => void
  onCloseRedirect: () => void
  selectedItemList?: Array<string>
  totalCount?: number
  selectAll?: boolean
}

export const AgentsList = ({
  data,
  onCheckboxChange,
  onAddButtonClick,
  onCloseRedirect,
  selectAll = false,
  selectedItemList = [],
  totalCount = 0,
}: IProps) => {
  const { onOpen } = useContext(RightModalContext)
  return (
    <>
      <div className={styles.amountContainer}>
        <div className={modalStyles.amountContacts}>{`${totalCount} agents`}</div>
        <Checkbox
          className={modalStyles.amountContacts}
          color='blue'
          text='Select all'
          checked={selectAll}
          onChange={onCheckboxChange}
        />
      </div>
      {data?.map((item) => {
        const isSelected = selectedItemList.includes(String(item?.id))
        return (
          <div className={modalStyles.agentContainer} key={item?.id}>
            <div className={modalStyles.leftContainer}>
              <img src={item?.avatar ?? emptyAvatarImage} alt='image' />
              <div className={modalStyles.agentInfo}>
                <div className={modalStyles.agentName}>
                  {item?.companyTitle ?? `${item?.firstName} ${item?.lastName}`}
                </div>
                <div className={modalStyles.agentTypeContainer}>
                  <div className={modalStyles.agentType}>{item?.agentType?.name}</div>
                  <div>{`Level ${item?.agentLevel?.level}`}</div>
                </div>
                <div className={modalStyles.idContainer}>
                  <div className={modalStyles.agentId}>
                    {`ID `}
                    <div>{item.id}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.rightContainer}>
              <Button
                size='small'
                color={isSelected ? 'error' : 'blue'}
                onClick={onAddButtonClick(item.id)}
              >
                {isSelected ? 'Remove' : 'Add'}
              </Button>
              <IconButton
                color='blue'
                size='small'
                icon={<ArrowRightIcon />}
                onClick={() => {
                  onOpen(RIGHT_MODALS.SUPPORT.CHANGE_MPIN, {
                    title: 'Agent Profile',
                    initialStep: 'agentProfile',
                    agentId: item?.id,
                    onCloseRedirect,
                  })
                }}
              />
            </div>
          </div>
        )
      })}
    </>
  )
}
