import React, { forwardRef, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import styles from '../../../../../../shared/components/textEditor/styles.module.scss'
import { FormInstance } from 'antd/es/form/Form'
import { INotificationMessageVariable } from '../../../../models/INotificationMessage'
import classNames from 'classnames'
import { isArabic } from '../../../../../../helpers/utils'

interface ITextEditor {
  form: FormInstance
  name: string | [string, number, string]
  viewMode: boolean
  initialContent?: string
  variables?: INotificationMessageVariable[]
  fieldToValidate: string
}

export const TextEditor = forwardRef<ReactQuill, ITextEditor>(
  ({ form, name, viewMode, initialContent, fieldToValidate }, ref) => {
    const [value, setValue] = useState('')

    useEffect(() => {
      if (!initialContent) return
      setValue(initialContent)
    }, [initialContent])

    const onChange = (value: string) => {
      form.validateFields([name, fieldToValidate])
      setValue(value)

      form.setFields([
        {
          name,
          value,
        },
      ])
    }

    const description = form.getFieldValue(name)

    return (
      <React.Fragment>
        {viewMode ? (
          <div
            className={classNames(styles.content, { [styles.arabic]: isArabic(description) })}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        ) : (
          <ReactQuill
            className={styles.reactQuillContainer}
            theme='snow'
            value={value}
            onChange={onChange}
            ref={ref}
            modules={{
              toolbar: {
                container: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ color: [] }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ align: [] }],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  ['link', 'image', 'video'],
                  [{ direction: 'rtl' }],
                  ['clean'],
                ],
              },
            }}
          />
        )}
      </React.Fragment>
    )
  }
)
