import React, { useEffect, useState } from 'react'
import Table, { ColumnsType } from 'antd/es/table'
import classNames from 'classnames'
import { camelCase, startCase, upperCase } from 'lodash'
import { DateTableIcon, TableActionIcon } from '../../../../../assets/svg'
import {
  formatDateWithTime,
  formatMoneyByCurrencyType,
  tableExpandedIcon,
} from '../../../../../helpers/utils'
import emptyProductImage from '../../../../../assets/img/ProductTablePlaceholder.png'
import { useEmptyTable } from '../../../../../shared/hooks/table/useEmptyTable'
import { TableHeader } from '../Components/TableHeader'
import { IInternalReport } from '../../../models/IInternalReport'
import { ISelectedFilters } from '../../../../../shared/components/filter/Filter'
import { ITableConf, SortingOrderEnum } from '../../../../../shared/models/ITableConf'
import { useTableExport } from '../../../../../shared/hooks/table/useTableExport'
import {
  useGetInternalTransactionsQuery,
  useGetITFiltersListQuery,
  useLazyExportITReportQuery,
} from '../../../core/InternalTransactionApi'
import { useLazyGetSubTransactionsQuery } from '../../../core/Subtransactions'
import { IPopupListItems, Popup } from '../../../../../shared/components/popup/Popup'
import {
  AVAILABLE_HISTORY_CATEGORIES,
  DEBIT_CREDIT_ENUM,
  ITrustWalletTransactionSub,
  TRANSACTION_CATEGORY_ENUM,
} from '../../../../Finance/models/ITransaction'
import { useOnTransactionViewClick } from '../../../../../shared/hooks/table/useOnTransactionViewClick'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import styles from '../style.module.scss'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { useOnTransactionHistoryClick } from '../../../../../shared/hooks/table/useOnTransactionHistoryClick'
import { Link } from 'react-router-dom'
import { SALES_ENUM } from '../../../../../routes/sales'
import { RecursivelyReplaceCharacters } from '../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../shared/hooks/useLocalStorageGetByKey'

export const InternalTransactionReport = () => {
  const onTransactionViewClick = useOnTransactionViewClick()
  const onTransactionHistoryClick = useOnTransactionHistoryClick()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const [expandedRowKeys, setExpandedRowKeys] = useState<Array<React.Key>>([])
  const [mappedTrans, setMappedTrans] = useState<Array<IInternalReport>>([])
  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data: profile } = useGetProfileQuery()

  const [getSubTrans, getSubTransResp] = useLazyGetSubTransactionsQuery()
  const { data: filtersList } = useGetITFiltersListQuery()
  const { data, isFetching } = useGetInternalTransactionsQuery(
    { ...tableConf, ...filterValues },
    { skip: Object.keys(tableConf).length < 4 }
  )
  const [exportTable] = useLazyExportITReportQuery()

  useTableExport({ tableConf, filterValues, promise: exportTable })

  const tableActionsPopup = (report: IInternalReport): IPopupListItems[] => [
    {
      text: 'View',
      shouldDisplay: true,
      onClick: () => onTransactionViewClick(report.id, report.transactionCategory, report, true),
    },
    ...(AVAILABLE_HISTORY_CATEGORIES.includes(report.transactionCategory)
      ? [
          {
            text: 'History',
            shouldDisplay: true,
            onClick: () => onTransactionHistoryClick(report?.id, report?.transactionCategory),
          },
        ]
      : []),
  ]

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IInternalReport>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
    setExpandedRowKeys,
  })

  const columns: ColumnsType<IInternalReport> = [
    {
      title: '',
      dataIndex: '',
      key: 'expand',
      width: '1%',
      onCell: (record) => {
        return {
          onClick: async () => {
            const mappedRec = { ...record }
            const data = await getSubTrans({
              transactionCategory: record.transactionCategory,
              id: record.id,
            })
            mappedRec.children = data?.data?.items

            setMappedTrans((prevState) =>
              prevState.map((trans) => {
                if (trans.id === mappedRec.id) {
                  return mappedRec
                }
                return trans
              })
            )
          },
        }
      },
    },
    {
      title: 'Transaction ID',
      dataIndex: 'id',
      sorter: true,
      width: '15%',
      render: (id: number) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      sorter: true,
      width: '15%',
      render: (name: IInternalReport['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(name, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Sender',
      dataIndex: 'sender',
      width: '15%',
      render: (sender: IInternalReport['sender'], row) => (
        <RecursivelyReplaceCharacters>
          {!Object.hasOwn(row, 'mainTransactionId') ? (
            <>
              <div>{row[sender] && startCase(row[sender]?.name)}</div>
              {row?.sender === 'agent' && (
                <Link
                  to={`${SALES_ENUM.SALES_NETWORK}/view/${row?.agent?.id}`}
                  className={styles.escrowMark}
                >
                  {row?.agent?.id}
                </Link>
              )}
              {row?.sender === 'vendor' && row?.vendorWalletType && (
                <div className={styles.escrowMark}>
                  {upperCase(camelCase(row.vendorWalletType))}
                </div>
              )}
            </>
          ) : (
            <>
              {row.drCr === DEBIT_CREDIT_ENUM.CREDIT ? (
                <>
                  <div>{startCase(row[row?.receiver]?.name)}</div>
                  {row?.receiver === 'agent' && (
                    <Link
                      to={`${SALES_ENUM.SALES_NETWORK}/view/${row?.agent?.id}`}
                      className={styles.escrowMark}
                    >
                      {row?.agent?.id}
                    </Link>
                  )}
                  {row?.receiver === 'vendor' && row?.vendorWalletType && (
                    <div className={styles.escrowMark}>
                      {upperCase(camelCase(row.vendorWalletType))}
                    </div>
                  )}
                  {row?.escrow && <div className={styles.escrowMark}>Escrow</div>}
                </>
              ) : (
                <>
                  <div>{startCase(row[row?.sender]?.name)}</div>
                  {row?.sender === 'agent' && (
                    <Link
                      to={`${SALES_ENUM.SALES_NETWORK}/view/${row?.agent?.id}`}
                      className={styles.escrowMark}
                    >
                      {row?.agent?.id}
                    </Link>
                  )}
                  {row?.sender === 'vendor' && row?.vendorWalletType && (
                    <div className={styles.escrowMark}>
                      {upperCase(camelCase(row.vendorWalletType))}
                    </div>
                  )}
                  {row?.escrow && <div className={styles.escrowMark}>Escrow</div>}
                </>
              )}
            </>
          )}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'transactionCategory',
      sorter: true,
      width: '10%',
      render: (cat: string) => <RecursivelyReplaceCharacters>{cat}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Amount',
      dataIndex: 'currencyAmount',
      sorter: true,
      width: '5%',
      render: (amount, row) =>
        amount && (
          <RecursivelyReplaceCharacters>
            <span
              className={classNames('amountContainer', {
                dr:
                  (amount < 0 && row?.drCr === DEBIT_CREDIT_ENUM.CREDIT && row.mainTransactionId) ||
                  (amount > 0 && row?.drCr === DEBIT_CREDIT_ENUM.DEBIT && row.mainTransactionId),
                cr:
                  (amount < 0 && row?.drCr === DEBIT_CREDIT_ENUM.DEBIT && row.mainTransactionId) ||
                  (amount > 0 && row?.drCr === DEBIT_CREDIT_ENUM.CREDIT && row.mainTransactionId),
                zero: !amount && row.mainTransactionId,
              })}
            >
              {formatMoneyByCurrencyType(amount, row?.drCr)}
            </span>
          </RecursivelyReplaceCharacters>
        ),
    },
    {
      title: 'Emoney',
      dataIndex: 'amount',
      sorter: true,
      width: '5%',
      render: (amount, row) =>
        amount && (
          <RecursivelyReplaceCharacters>
            <span
              className={classNames(styles.amountContainer, {
                [styles.dr]:
                  amount && row.drCr === DEBIT_CREDIT_ENUM.DEBIT && row.mainTransactionId,
                [styles.cr]:
                  amount && row.drCr === DEBIT_CREDIT_ENUM.CREDIT && row.mainTransactionId,
                [styles.zero]: !amount && row.mainTransactionId,
              })}
            >
              {formatMoneyByCurrencyType(amount, row?.drCr)}
            </span>
          </RecursivelyReplaceCharacters>
        ),
    },
    {
      title: 'Receiver',
      dataIndex: 'receiver',
      width: '15%',
      render: (
        receiver: IInternalReport['receiver'],
        row: IInternalReport | ITrustWalletTransactionSub
      ) => {
        const isSub = Object.hasOwn(row, 'mainTransactionId')
        return !isSub ? (
          <RecursivelyReplaceCharacters>
            <span>{startCase(row[receiver]?.name)}</span>
          </RecursivelyReplaceCharacters>
        ) : null
      },
    },
    {
      title: 'Product',
      dataIndex: 'product',
      sorter: true,
      width: '15%',
      render: (masterProduct: IInternalReport['product']) => (
        <RecursivelyReplaceCharacters>
          <div className='table-avatar'>
            <img
              src={!isPrivateMode && masterProduct?.logo ? masterProduct?.logo : emptyProductImage}
              alt='icon'
              loading='lazy'
            />
            <span>{masterProduct?.name || 'eMoney'}</span>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: '10%',
      render: (status: IInternalReport['status']) => (
        <RecursivelyReplaceCharacters>
          <div className={classNames('statusContainer', `${status.toLowerCase()}-container`)}>
            <div className={classNames(camelCase(status.toLowerCase()), 'statusPoint')} />
            <div>{status}</div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '10%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  useEffect(() => {
    if (data) {
      setMappedTrans(data.items)
    }
  }, [data])

  return (
    <div className={styles.layout}>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filtersList}
      />
      <Table
        locale={emptyTableConf}
        className={styles.table}
        onChange={handleTableChange}
        pagination={pagination}
        scroll={{ x: 1000 }}
        rowKey={(record) => record.id}
        rowSelection={{ type: 'checkbox' }}
        expandable={{
          expandedRowKeys,
          expandIcon: (props) => {
            if (
              Object.hasOwn(props.record, 'mainTransactionId') ||
              !props.record?.countSubTransaction ||
              props.record.transactionCategory === TRANSACTION_CATEGORY_ENUM.TRANSFER_FUND
            )
              return null
            return tableExpandedIcon<IInternalReport>(props)
          },
          onExpandedRowsChange: (expandedKeys) => setExpandedRowKeys([...expandedKeys]),
        }}
        columns={columns}
        dataSource={mappedTrans}
        loading={isFetching || getSubTransResp.isFetching}
      />
    </div>
  )
}
