import FormList from 'antd/es/form/FormList'
import styles from '../../../styles.module.scss'
import classNames from 'classnames'
import { useGetAccessChannelsListQuery } from '../../../../../core/http/DiscountPolicyApi'
import FormItem from 'antd/es/form/FormItem'
import { requiredValidator } from '../../../../../../../helpers/validation'
import { Select } from 'antd'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from '../../../../../../../assets/svg'
import React from 'react'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'

interface IProps extends IViewMode {
  onViewPage?: boolean
}

export const AccessChannelsBlock = ({ view, onViewPage }: IProps) => {
  const { data } = useGetAccessChannelsListQuery()

  return (
    <FormList name='forbiddenAccessChannels'>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ name, key }) => (
            <div
              key={key}
              className={classNames(styles.productAccessListItem, {
                [styles.productAccessListItemView]: onViewPage,
              })}
            >
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const selectedForbiddenChannels = getFieldValue(
                    'forbiddenAccessChannels'
                  ) as Array<string>

                  return (
                    <FormItem
                      name={name}
                      rules={[{ required: true, validator: requiredValidator('Access Channel') }]}
                      className={classNames(styles.masterProductAccessSelector, 'noBottomMargin')}
                    >
                      <Select placeholder='Select Access Channel' disabled={view}>
                        {data?.accessChannels?.map((channel) => (
                          <Select.Option
                            key={channel}
                            value={channel}
                            disabled={selectedForbiddenChannels?.includes(channel)}
                          >
                            {channel}
                          </Select.Option>
                        ))}
                      </Select>
                    </FormItem>
                  )
                }}
              </FormItem>

              <div>
                <IconButton
                  block
                  size='large'
                  color='orange'
                  slim
                  icon={<BucketIcon />}
                  type='default'
                  onClick={() => {
                    remove(name)
                  }}
                  className='noBottomMargin'
                  disabled={view}
                />
              </div>
            </div>
          ))}
          <div className={styles.addButton}>
            <IconButton
              icon={<AddIcon />}
              disabled={view}
              color='orange'
              block
              onClick={() => add()}
            />
          </div>
        </>
      )}
    </FormList>
  )
}
