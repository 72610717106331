export enum SVG_PATH_COLOR_BY_AMOUNT_ENUM {
  BASE_COLOR = '#E6E7E8',
  '1-10k' = '#cce8ff',
  '10k-20k' = '#B1D6FF',
  '20k-50k' = '#96C3FF',
  '50k-75k' = '#7AAFFF',
  '75k-100k' = '#5F9CFF',
  '100k-200k' = '#2e71da',
  '200k-500k' = '#0f43b6',
  '500k-750k' = '#0432a9',
  '750k-1000k' = '#022a98',
  '1000k' = '#094669',
}
