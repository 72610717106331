import { omit, pick } from 'lodash'
import { ParsedQuery } from 'query-string'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { getQueryParams, updatePageUrl } from 'src/helpers/queryString'
import { ITableConf } from 'src/shared/models/ITableConf'
import { paginationParams, paginationSettings } from './useTable'

/**
 *
 * @param {ITableConf} params
 * @param {Boolean} resetParams - when you need to reset params on first tab after navigation(-1)
 * @param {Array<String>} extraFilters - when you need to use extra filters in url, but without dispay in filters block
 * @returns { queryParams, queryFields }
 */

export const useGetQueryParams = (
  params: ITableConf = {},
  resetParams = false,
  extraFilters: string[] = []
) => {
  const { search } = useLocation()
  const [, setSearchParams] = useSearchParams()

  let queryParams: ITableConf = useMemo(
    () => (resetParams ? {} : (getQueryParams(search) as ITableConf)),
    [resetParams, search]
  )
  let queryFields = useMemo(
    () => (resetParams ? {} : omit(queryParams, [...paginationParams, ...extraFilters])),
    [extraFilters, queryParams, resetParams]
  )

  useEffect(() => {
    if (!queryParams.page || !queryParams.limit || resetParams) {
      const { current: page, pageSize: limit } = paginationSettings
      const newQueryParams = { limit, page, ...params }

      if (resetParams) {
        queryParams = pick(queryParams, [...paginationParams, ...extraFilters])
        queryFields = pick(queryFields, [...paginationParams, ...extraFilters])
        updatePageUrl({}, newQueryParams, setSearchParams)
        return
      } else {
        updatePageUrl(queryParams as ParsedQuery<string>, newQueryParams, setSearchParams)
      }
    }
  }, [])

  return {
    queryParams,
    queryFields,
  }
}
