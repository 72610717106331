import { Form, Spin, UploadFile } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'

// helpers
import { IPurchaseOrderStatus } from 'src/features/Finance/models/IPurchaseOrder'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { RightModalContext } from 'src/features/Modals'
import { PRUploadTypes } from 'src/shared/components/uploaderPhotos/constants'

// api
import {
  useAddAttachmentsPRMutation,
  useApprovePickupRequestMutation,
  useCancelPickupRequestMutation,
  useGetPickupRequestQuery,
  useRejectPickupRequestMutation,
} from 'src/features/Finance/core/http/BankWalletTransactionsApi'
import { useGetBankAccountsQuery } from 'src/features/Finance/core/http/BankAccountApi'

// components
import { DetailInfo } from './DetailInfo'
import { Logs } from './Logs'
import { Footer } from './Footer'
import { UploaderFiles } from 'src/shared/components/uploaderPhotos/UploaderFiles'

import { ErrorNode } from 'src/shared/api/errorHandler'
import {
  agentManagementApi,
  useLazyGetRootAgentByIdQuery,
} from '../../../../../../salesNetwork/core/http/AgentManagement'
import { FINANCE_ENUM } from '../../../../../../../routes/finance'
import { TRANSACTION_STATUS_ENUM } from 'src/features/Finance/models/ITransaction'
// styles
import styles from './styles.module.scss'
import AgentWalletSummary from './AgentWalletSummary'
import BankWalletSummary from './BankWalletSummary'
import { useAppDispatch } from '../../../../../../../redux'
import { useGetProfileQuery } from '../../../../../../Profile/core/http/Profile'

export const PickupRequestModal = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const {
    onClose,
    props: { id },
  } = useContext(RightModalContext)

  const dispatch = useAppDispatch()

  const [files, setFiles] = useState<UploadFile[]>([])

  const { data, isFetching } = useGetPickupRequestQuery(id, { skip: !id })
  const { data: bankAccountList } = useGetBankAccountsQuery({})
  const { data: profile } = useGetProfileQuery()

  const [getRootAgent, dRootAgent] = useLazyGetRootAgentByIdQuery()
  const [onCancelPO, dCancel] = useCancelPickupRequestMutation()
  const [onRejectPO, dReject] = useRejectPickupRequestMutation()
  const [onApprovePO, dApprove] = useApprovePickupRequestMutation()
  const [addAttachmentPO, dAttahcment] = useAddAttachmentsPRMutation()

  const afterCreate = () => {
    dispatch(agentManagementApi.util.resetApiState())
    navigate(FINANCE_ENUM.BANK_WALLET)
    onClose()
  }

  useNotification(NOTIFICATION_TYPES.success, dCancel.isSuccess, null)
  useNotification(NOTIFICATION_TYPES.error, dCancel.isError, dCancel.error as ErrorNode)

  useNotification(NOTIFICATION_TYPES.success, dReject.isSuccess, null, afterCreate)
  useNotification(NOTIFICATION_TYPES.error, dReject.isError, dReject.error as ErrorNode)

  useNotification(NOTIFICATION_TYPES.success, dApprove.isSuccess, null, () => {
    navigate(FINANCE_ENUM.BANK_WALLET)
    dispatch(agentManagementApi.util.invalidateTags(['IAllTableResponse']))
    afterCreate()
  })
  useNotification(NOTIFICATION_TYPES.error, dApprove.isError, dApprove.error as ErrorNode)

  useNotification(NOTIFICATION_TYPES.success, dAttahcment.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, dAttahcment.isError, dAttahcment.error as ErrorNode)

  useEffect(() => {
    if (isEmpty(data)) return
    getRootAgent(data.agent?.id)
  }, [data, form, getRootAgent])

  useEffect(() => {
    if (isEmpty(dRootAgent)) return
    const rootAgent = dRootAgent.data
    form.setFieldsValue({
      parentAgentId: `${rootAgent?.id} ${
        rootAgent?.companyTitle ?? `${rootAgent?.firstName} ${rootAgent?.lastName}`
      }`,
    })
  }, [dRootAgent, form])

  const handlerEdit = () => {
    navigate(`${FINANCE_ENUM.BANK_WALLET}/pickup-request/edit/${id}`)
    onClose()
  }

  const dispatchFiles = (arr: UploadFile[]) => setFiles(arr)

  const onSaveAttachments = () => {
    const fd = new FormData()

    files.forEach((value) => value.originFileObj && fd.append('files', value.originFileObj!))

    addAttachmentPO({
      id,
      body: fd,
    })
  }

  const isMutating = dApprove.isLoading || dCancel.isLoading || dReject.isLoading

  const content = (
    <Form form={form} className={styles.formContainer} layout='vertical' initialValues={data}>
      <DetailInfo bankAccountList={bankAccountList?.items} status={data?.status} />
      <AgentWalletSummary />
      <BankWalletSummary />

      {(data?.status === TRANSACTION_STATUS_ENUM.CREATED ||
        data?.status === TRANSACTION_STATUS_ENUM.APPROVED) && (
        <div style={{ marginBottom: '24px' }}>
          <UploaderFiles
            allowTypes={PRUploadTypes}
            attachmentPropName='receipt'
            btnTitle='Attach File'
            viewFiles={data.agentRequestReceipts}
            dispatchData={dispatchFiles}
            onSave={onSaveAttachments}
            isLoading={dAttahcment.isLoading}
            isStyledTitle
            viewMode={data?.status === TRANSACTION_STATUS_ENUM.APPROVED}
            isVisibleAttachBtn={data?.status === TRANSACTION_STATUS_ENUM.APPROVED}
          />
        </div>
      )}

      <Logs logs={data?.AgentRequestSubTransaction as IPurchaseOrderStatus[]} />
      <Footer
        status={data?.status}
        onEdit={handlerEdit}
        canCancel={data?.createdByUser?.id === profile?.id}
        onCancel={() => onCancelPO(id)}
        onReject={() => onRejectPO(id)}
        onApprove={() => onApprovePO(id)}
        paymentType={data?.paymentType}
      />
    </Form>
  )

  return isFetching || dRootAgent.isFetching || dAttahcment.isLoading || isMutating ? (
    <Spin>{content}</Spin>
  ) : (
    content
  )
}
