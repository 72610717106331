import { useContext, useEffect, useState } from 'react'
import { IAgent } from 'src/features/salesNetwork/models/IAllTable'
import useSearch from 'src/features/Settings/helpers/useSearch'
import { ISelectedFilters } from 'src/shared/components/filter/Filter'
import {
  useGetAgentListQuery,
  useGetAllTableFiltersListQuery,
} from '../../../../../salesNetwork/core/http/AgentManagement'
import { AgentProfileStep } from './steps/AgentProfileStep'
import { SearchListStep } from './steps/SearchListStep'
import { ChangeMpinStep } from './steps/ChangeMpinStep'
import { DoubleArrowRightIcon } from 'src/assets/svg'
import { RightModalContext } from 'src/features/Modals'
import { Step } from 'src/features/Support/models/IMPin'

// styles
import styles from './styles.module.scss'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { isEmpty, unionBy } from 'lodash'
import { ITableConf } from '../../../../../../shared/models/ITableConf'
import { SelectAudienceStep } from './steps/SelectAudienceStep'
import { UserProfileStep } from './steps/UserProfileStep'
import { paginationSettings } from 'src/shared/hooks/table/useTable'

export const ChangeMPinModal = () => {
  const {
    onClose,
    props: { initialStep, agentId, onSelect, audienceType, title, id, selectedAgent },
  } = useContext(RightModalContext)

  const [step, setStep] = useState<Step>(initialStep || 'searchAgent')
  const [isChangedMPin, setChangedMPin] = useState(false)
  const [isChangedParentId, setChangedParentId] = useState<{
    status: boolean
    parentId: string | number | null
    agentLevel: number | null
  }>({
    status: false,
    parentId: null,
    agentLevel: null,
  })

  const [selectedAgentId, setSelectedAgentId] = useState<string | number | null>(null)
  const [dataAgentList, setDataAgentList] = useState<IAgent[]>([])
  const [filterValues, setFilterValues] = useState<ISelectedFilters>({})
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 10,
    search: '',
  })

  const { data: filterFields } = useGetAllTableFiltersListQuery()

  const {
    data: agentList,
    isError,
    error,
  } = useGetAgentListQuery({
    ...tableConf,
    ...filterValues,
  })
  const [isChangeSearch, setIsChangeSearch] = useState(false)

  const { handleSearchChange } = useSearch(setTableConf)

  useNotification(NOTIFICATION_TYPES.error, isError, error as ErrorNode)

  useEffect(() => {
    if (!agentId) return
    setSelectedAgentId(agentId)
  }, [agentId])

  useEffect(() => {
    if (isEmpty(agentList)) return

    if (isChangeSearch && (tableConf.search || Object.keys(filterValues).length !== 0)) {
      setDataAgentList(agentList.items)
      setIsChangeSearch(false)
    } else {
      setDataAgentList((p) => unionBy([...p, ...agentList.items], 'id'))
    }
    // eslint-disable-next-line
  }, [agentList])

  const handleSetChangedMPin = (value: boolean) => {
    setChangedMPin(value)
  }

  const handleChangeParentId = (values: {
    status: boolean
    parentId: string | number | null
    agentLevel: number | null
  }) => {
    setChangedParentId(values)
  }

  const loadMoreData = () => {
    setTableConf((pre) => ({ ...pre, page: pre.page! + 1 }))
  }

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChangeSearch(true)
    handleSearchChange(e)
  }

  const handlerSetActiveFilters = (values: ISelectedFilters) => {
    setTableConf((p) => ({ ...p, page: paginationSettings.current }))
    setFilterValues(values)
    setIsChangeSearch(true)
  }

  const handleChangeStep = (step: Step, agentId: string | number | null) => {
    setStep(step)
    setSelectedAgentId(agentId)
  }

  return (
    <div className={styles.changeMpinContainer}>
      <div className={styles.btnClose} onClick={onClose}>
        <DoubleArrowRightIcon />
      </div>
      {step === 'searchAgent' && (
        <SearchListStep
          step={step}
          dataAgentList={dataAgentList}
          totalCount={agentList?.totalCount as number}
          filterValues={filterValues}
          filterFields={filterFields}
          loadMoreData={loadMoreData}
          handlerSetActiveFilters={handlerSetActiveFilters}
          onSearchChange={onSearchChange}
          onChangeStep={handleChangeStep}
          onSelect={onSelect}
          selectedAgent={selectedAgent}
        />
      )}
      {step === 'agentProfile' && (
        <AgentProfileStep
          agentId={selectedAgentId as string}
          parentId={isChangedParentId.parentId}
          isChangedMPin={isChangedMPin}
          isChangedParentId={isChangedParentId.status}
          onChangeStep={handleChangeStep}
          onChangedMPin={handleSetChangedMPin}
          onChangeParentId={handleChangeParentId}
          isAudience={audienceType}
        />
      )}
      {step === 'changeMPin' && (
        <ChangeMpinStep
          agentId={selectedAgentId as string}
          onChangeStep={handleChangeStep}
          onChangedMPin={handleSetChangedMPin}
        />
      )}
      {step === 'searchParent' && (
        <SearchListStep
          step={step}
          agentId={selectedAgentId as string}
          agentLevel={isChangedParentId.agentLevel}
          dataAgentList={dataAgentList}
          totalCount={agentList?.totalCount as number}
          filterValues={filterValues}
          filterFields={filterFields}
          loadMoreData={loadMoreData}
          handlerSetActiveFilters={handlerSetActiveFilters}
          onSearchChange={onSearchChange}
          onChangeStep={handleChangeStep}
          onChangedParentId={handleChangeParentId}
        />
      )}
      {step === 'searchAudience' && (
        <SelectAudienceStep
          step={step}
          onChangeStep={handleChangeStep}
          audienceType={audienceType}
          id={id}
        />
      )}
      {step === 'userProfile' && (
        <UserProfileStep
          id={selectedAgentId as string}
          step={step}
          onChangeStep={handleChangeStep}
        />
      )}
    </div>
  )
}
