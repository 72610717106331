import { Col, List, Row, Popover } from 'antd'
import { ReactNode } from 'react'
import classNames from 'classnames'

// styles
import styles from './popup.module.scss'

export enum POPOVER_LIST_ITEM_COLORS_ENUM {
  GREEN = 'green',
  RED = 'red',
}

export interface IPopupListItems {
  text: string
  withDivider?: boolean
  onClick?: () => void
  icon?: ReactNode
  shouldDisplay?: boolean
  color?: POPOVER_LIST_ITEM_COLORS_ENUM
  suffix?: ReactNode
  topAlign?: boolean
}

interface IProps {
  data: Array<IPopupListItems>
  children: ReactNode
  inModal?: boolean
}

export const Popup = ({ children, inModal = false, data = [] }: IProps) => {
  const content = () => (
    <List
      dataSource={data}
      renderItem={({
        onClick,
        withDivider,
        shouldDisplay,
        icon,
        text,
        color,
        suffix,
        topAlign,
      }) => (
        <List.Item
          onClick={onClick}
          className={classNames({
            [styles.topBorder]: withDivider,
            [styles.shouldHide]: !shouldDisplay,
            [styles[color!]]: color,
          })}
        >
          <Row align='middle'>
            {icon && (
              <Col className={classNames(styles.iconContainer, { [styles.topAlign]: topAlign })}>
                {icon}
              </Col>
            )}
            <Col>{text}</Col>
            {suffix && <Col className={styles.suffixContainer}>{suffix}</Col>}
          </Row>
        </List.Item>
      )}
    />
  )

  return (
    <Popover
      overlayClassName={classNames(styles.topbarPopover, { [styles.inModal]: inModal })}
      placement='bottomRight'
      content={content}
      trigger='click'
    >
      {children}
    </Popover>
  )
}
