// styles
import classNames from 'classnames'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import styles from '../styles.module.scss'
import React from 'react'
import { camelCase } from 'lodash'

interface IStatusInfo {
  status: string
}

export const StatusInfo: React.FC<IStatusInfo> = ({ status }) => {
  return (
    <Wrapper title='Status' className={styles.layout}>
      <div className={classNames('statusContainer', `${status?.toLowerCase()}-container`)}>
        <div className={classNames(camelCase(status?.toLowerCase()), 'statusPoint')} />
        <div>{status}</div>
      </div>
    </Wrapper>
  )
}
