import { Wrapper } from '../../../../../../../shared/components/wrapper/Wrapper'
import emptyProductImage from 'src/assets/img/ProductTablePlaceholder.png'
import styles from '../../styles.module.scss'
import { IPurchasePlanVendor } from '../../../../../models/IInventory'
import { RecursivelyReplaceCharacters } from '../../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IProps {
  vendor?: IPurchasePlanVendor
}

export const VendorBlock = ({ vendor }: IProps) => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  return (
    <Wrapper title='Vendor'>
      <RecursivelyReplaceCharacters>
        <div className={styles.vendorLogo}>
          <img
            src={!isPrivateMode && vendor?.logo ? vendor?.logo : emptyProductImage}
            alt='Vendor Logo'
          />
          <div>
            <div className={styles.vendorName}>{vendor?.name}</div>
            <div className={styles.vendorType}>{vendor?.type}</div>
          </div>
        </div>
      </RecursivelyReplaceCharacters>
    </Wrapper>
  )
}
