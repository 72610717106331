import React from 'react'

import styles from './styles.module.scss'

import { RolesManagementViewTabs } from './Components/Tabs/index'

export const RolesManagementView = () => {
  return (
    <div className={styles.layout}>
      <RolesManagementViewTabs />
    </div>
  )
}
