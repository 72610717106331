import { Row, Space } from 'antd'
import styles from './styles.module.scss'
import { Button } from '../../../../../../../shared/components/button/Button'
import React from 'react'
import classNames from 'classnames'

interface IProps {
  mode?: 'view' | 'edit'
  onCancelClick: () => void
  onSubmitClick: () => void
  onDeleteClick?: () => void
}

export const Footer = ({ mode, onSubmitClick, onCancelClick, onDeleteClick }: IProps) => {
  return (
    <div className={styles.footerWrapper}>
      <Row
        className={classNames(styles.footer, { [styles.isCreatePage]: !mode })}
        justify={mode && onDeleteClick ? 'space-between' : 'end'}
      >
        {onDeleteClick && (
          <Button color='error' size='middle' onClick={onDeleteClick}>
            Delete
          </Button>
        )}
        <Space size={10}>
          <Button color='blue' size='middle' onClick={onCancelClick}>
            Cancel
          </Button>
          <Button htmlType='submit' size='middle' type='primary' onClick={onSubmitClick}>
            {mode === 'view' && 'Edit'}
            {mode === 'edit' && 'Save Changes'}
            {!mode && 'Create'}
          </Button>
        </Space>
      </Row>
    </div>
  )
}
