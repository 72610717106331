import { ColumnsType } from 'antd/es/table'
import { IWalletStatementTrans } from '../../../models/IWalletStatement'
import styles from '../styles.module.scss'
import { formatNumberToLocale } from '../../../../salesNetwork/helpers'
import { useNavigate } from 'react-router-dom'
import { IPopupListItems, Popup } from '../../../../../shared/components/popup/Popup'
import { TableActionIcon } from '../../../../../assets/svg'
import React from 'react'
import { startCase } from 'lodash'
import { FINANCE_ENUM } from '../../../../../routes/finance'
import { RecursivelyReplaceCharacters } from '../../../../../shared/components/privateMode'
import { VENDOR_WALLET_TYPE_ENUM } from '../../../models/IPurchaseOrder'

const getTransactionDetailLink = (
  row: IWalletStatementTrans,
  name?: string,
  withSubWallet?: boolean
) => {
  if (name)
    return `${FINANCE_ENUM.STATEMENT_OF_WALLETS}/${name}/${row.walletId}${
      withSubWallet &&
      Object.values(VENDOR_WALLET_TYPE_ENUM).includes(
        row?.wallet?.toString() as VENDOR_WALLET_TYPE_ENUM
      )
        ? `/${row?.wallet}`
        : ''
    }`
  return `${FINANCE_ENUM.STATEMENT_OF_WALLETS}/${row.wallet.toLowerCase().split(' ').join('-')}`
}
export const useTableColumns = (
  name?: string,
  isAgent?: boolean,
  withSubWallet?: boolean
): ColumnsType<IWalletStatementTrans> => {
  const navigate = useNavigate()
  const tableActionsPopup = (row: IWalletStatementTrans): IPopupListItems[] => [
    {
      text: 'View',
      shouldDisplay: true,
      onClick: () =>
        navigate(getTransactionDetailLink(row, name, withSubWallet), {
          state: { name: row?.wallet },
        }),
    },
  ]
  return [
    {
      title: isAgent ? 'Agent Name' : 'Wallet',
      dataIndex: 'wallet',
      width: '35%',
      render: (wallet: IWalletStatementTrans['wallet']) => (
        <RecursivelyReplaceCharacters>{startCase(wallet)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Total Debit',
      dataIndex: 'debit',
      width: '20%',
      render: (debit) => (
        <RecursivelyReplaceCharacters>
          <span className={styles.dr}>{formatNumberToLocale(debit)}</span>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Total Credit',
      dataIndex: 'credit',
      width: '20%',
      render: (credit) => (
        <RecursivelyReplaceCharacters>
          <span className={styles.cr}>{formatNumberToLocale(credit)}</span>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      width: '20%',
      render: (balance) => (
        <RecursivelyReplaceCharacters>
          <strong>{formatNumberToLocale(balance)}</strong>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '5%',
      render: (_, row) => {
        return (
          !Object.hasOwn(row, 'shouldDisplayExpand') && (
            <Popup data={tableActionsPopup(row)}>
              <div className='table-kebab-actions'>
                <TableActionIcon />
              </div>
            </Popup>
          )
        )
      },
    },
  ]
}
