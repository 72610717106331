import { FormInstance, Select as AntSelect, Spin } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from 'src/shared/components/textfield/TextField'
import { maxLengthValidator50, requiredValidator } from 'src/helpers/validation'
import styles from './styles.module.scss'
import { Wrapper } from '../Wrapper'
import {
  useGetCountryListQuery,
  useLazyGetDistrictListQuery,
  useLazyGetProvinceListQuery,
} from '../../../Settings/core/http/VendorManagementApi'
import { useEffect } from 'react'
import { IAgentAll } from 'src/features/salesNetwork/models/IAgent'
import { IUserDetail } from 'src/features/Settings/models/IUser'
import { isEmpty } from 'lodash'

interface IAddress {
  form: FormInstance
  view?: boolean
  dataAgent?: IAgentAll | IUserDetail
}

const Address = ({ form, view = false, dataAgent }: IAddress) => {
  const { data: dataCountry = { items: [] }, isFetching: isFC } = useGetCountryListQuery()
  const isEmptyDataCountryList = dataCountry.items.length === 0

  const [getProvinceByCountryId, { data: dataProvince = { items: [] }, isFetching: isFP }] =
    useLazyGetProvinceListQuery()

  const isEmptyDataProvinceList = dataProvince.items.length === 0

  const [getDistrictByProvinceId, { data: dataDistrict = { items: [] }, isFetching: isFD }] =
    useLazyGetDistrictListQuery()

  const isEmptyDataDistrictList = dataDistrict.items.length === 0

  useEffect(() => {
    if (isEmpty(dataAgent)) return
    getProvinceByCountryId(dataAgent.country?.id)
    getDistrictByProvinceId(dataAgent.province?.id)
  }, [view, dataAgent, getDistrictByProvinceId, getProvinceByCountryId])

  const handleResetFields = (fields: string[] = []) => {
    form.setFields(fields.map((i) => ({ name: i, value: null, errors: [] })))
  }

  const onChangeCountry = (val: number) => {
    getProvinceByCountryId(val)
    handleResetFields(['districtId', 'provinceId'])
  }

  const onChangeProvince = (val: number) => {
    getDistrictByProvinceId(val)
    handleResetFields(['districtId'])
  }

  return (
    <div className={styles['spin-container']}>
      <Spin spinning={isFC || isFD || isFP}>
        <Wrapper title='Address' className={styles['wrapper-address']}>
          <div className={styles.dblRow}>
            <FormItem
              name='countryId'
              label='Country'
              rules={[{ required: true, validator: requiredValidator('Country') }]}
            >
              <AntSelect
                disabled={view || isEmptyDataCountryList}
                onChange={onChangeCountry}
                className={styles['form-item-inp']}
                onClear={() => handleResetFields(['districtId', 'provinceId'])}
                options={dataCountry.items}
                allowClear
                placeholder='Select Country'
              />
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const isDisabled = view || isEmptyDataProvinceList || !getFieldValue('countryId')
                return (
                  <FormItem
                    name='provinceId'
                    label='Province'
                    style={{ margin: '0 12px' }}
                    rules={[
                      ...(isEmptyDataProvinceList || !getFieldValue('countryId')
                        ? [{}]
                        : [{ required: true, validator: requiredValidator('Province') }]),
                    ]}
                  >
                    <AntSelect
                      disabled={isDisabled}
                      onChange={(val) => onChangeProvince(val)}
                      className={styles['form-item-inp']}
                      onClear={() => handleResetFields(['districtId'])}
                      options={dataProvince.items}
                      allowClear
                      placeholder='Select Province'
                    />
                  </FormItem>
                )
              }}
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const isDisabled = view || isEmptyDataDistrictList || !getFieldValue('provinceId')
                return (
                  <FormItem
                    name='districtId'
                    label='City/District'
                    rules={[
                      ...(isEmptyDataDistrictList || !getFieldValue('provinceId')
                        ? [{}]
                        : [{ required: true, validator: requiredValidator('City/District') }]),
                    ]}
                  >
                    <AntSelect
                      disabled={isDisabled}
                      options={dataDistrict.items}
                      className={styles['form-item-inp']}
                      allowClear
                      placeholder='Select City/District'
                    />
                  </FormItem>
                )
              }}
            </FormItem>
          </div>

          <FormItem
            className={styles['form-item']}
            rules={[
              { validator: maxLengthValidator50 },
              { required: true, validator: requiredValidator('Address line 1') },
            ]}
            name='addressLine1'
            label='Address line 1'
          >
            <TextField placeholder='Address line 1' disabled={view} />
          </FormItem>
          <FormItem
            className={styles['form-item']}
            rules={[{ validator: maxLengthValidator50 }]}
            name='addressLine2'
            label='Address line 2'
          >
            <TextField placeholder='Address line 2' disabled={view} />
          </FormItem>
        </Wrapper>
      </Spin>
    </div>
  )
}
export default Address
