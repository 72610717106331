/* eslint-disable */
import React, { useCallback, useState } from 'react'
import { Modal, ModalGateway } from 'react-images'

import { IInternalChatMessageFile } from '../../../../models'
import ReactPlayer from 'react-player/lazy'
import { PlayVideoIcon } from '../../../../../../assets/svg'
import styles from './styles.module.scss'
import { IExternalChatMessageFile } from '../../../../../Support/models/IExternalChat'

interface IProps {
  videos: Array<IInternalChatMessageFile | IExternalChatMessageFile>
}

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const VideosInMessage = ({ videos }: IProps) => {
  const [currentVideoLink, setCurrentVideoLink] = useState('')
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((link: string) => {
    setCurrentVideoLink(link)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentVideoLink('')
    setViewerIsOpen(false)
  }

  return (
    <div className={styles.filesImagesContainer}>
      {videos.map((video) => (
        <div
          onClick={() => openLightbox(video.file.link)}
          className={styles.videoContainer}
          key={video.id}
        >
          <div className={styles.playVideoIcon}>
            <PlayVideoIcon size={48} />
          </div>
          <ReactPlayer url={video.file?.link} width={240} height={160} />
        </div>
      ))}
      {/* @ts-ignore */}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <ReactPlayer url={currentVideoLink} playing controls />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}
