import { Col, Form, Row, Space, Spin, UploadFile } from 'antd'
import { DetailInfo } from '../../../../Components/DetailInfo'
import { ControlPanel } from '../../../../Components/ControlPanel'
import { Address } from '../../../../Components/Address'
import { useNavigate, useParams } from 'react-router-dom'
import { RcFile } from 'antd/lib/upload'
import { UploaderPhotos2 } from 'src/shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import React, { useCallback, useEffect, useState } from 'react'
import styles from '../../../../styles.module.scss'
import { Button } from 'src/shared/components/button/Button'
import {
  useGetVendorByIdQuery,
  useUpdateVendorMutation,
} from '../../../../../../core/http/VendorManagementApi'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { formatDateWithTime } from 'src/helpers/utils'
import { IVendor, VENDOR_TYPE_ENUM } from '../../../../../../models/IVendorManagement'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { isEmpty } from 'lodash'
import { VendorMasterColorBlock } from '../../../../Components/VendorMasterColorBlock'
import classNames from 'classnames'
import FormItem from 'antd/es/form/FormItem'

const VendorInfoTab = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [isImageError, setIsImageError] = useState(false)
  const { id, mode } = useParams<{ id: string; mode: 'view' | 'edit' }>()

  const [keyUploader, setKeyUploader] = useState(() => new Date().toString())
  const [isLogoDelete, setIsLogoDelete] = useState(false)

  const { data, isError, error, isLoading } = useGetVendorByIdQuery(id as string, { skip: !id })
  const [updateVendor, upVenResp] = useUpdateVendorMutation()
  const { data: profile } = useGetProfileQuery()

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const setDefaultValues = useCallback(() => {
    if (isEmpty(data)) return
    form.setFieldValue('createdBy', data?.createdByUser?.name)
    form.setFieldValue('createdAt', formatDateWithTime(data?.createdAt, profile?.calendar))
    form.setFieldValue('countryId', data?.country?.value)
    form.setFieldValue('provinceId', data?.province?.value)
    form.setFieldValue('districtId', data?.district?.value)
  }, [data, form, profile])

  const onCancelButtonClick = () => {
    if (isViewMode) {
      return navigate('/settings/vendor-management')
    }
    if (isEditMode) {
      form.resetFields()
      setDefaultValues()
      setKeyUploader(new Date().toString())
      return navigate(`/settings/vendor-management/view/${id}`)
    }
  }

  const onSubmitButtonClick = () => {
    if (isViewMode) {
      return navigate(`/settings/vendor-management/edit/${id}`)
    }
    form.submit()
  }

  const onFinish = () => {
    const formValues = form.getFieldsValue() as IVendor
    const fData = new FormData()

    if (form.getFieldValue('logo')) {
      fData.append('logo', form.getFieldValue('logo') as RcFile)
    }

    formValues.addressLine2 = !formValues.addressLine2 ? null : (formValues?.addressLine2 as string)
    formValues.controlPanelPassword = !formValues.controlPanelPassword
      ? null
      : (formValues?.controlPanelPassword as string)
    formValues.controlPanelURL = !formValues.controlPanelURL
      ? null
      : (formValues?.controlPanelURL as string)
    formValues.controlPanelUsername = !formValues.controlPanelUsername
      ? null
      : (formValues?.controlPanelUsername as string)

    fData.append('vendor', JSON.stringify(formValues))

    updateVendor({ id, body: fData })
  }

  useNotification(NOTIFICATION_TYPES.success, upVenResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, upVenResp.isError, upVenResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, isError, error as ErrorNode)

  useEffect(() => {
    setDefaultValues()
  }, [setDefaultValues])

  useEffect(() => {
    if (upVenResp.isSuccess) {
      return navigate(`/settings/vendor-management/view/${id}`)
    }
    // eslint-disable-next-line
  }, [id, upVenResp.isSuccess])

  const content = (
    <>
      <div>
        <Form
          layout='vertical'
          form={form}
          initialValues={data}
          className={styles.columnWrapper}
          onFinish={onFinish}
        >
          <div className={styles.leftColumn}>
            <DetailInfo view={isViewMode} />
            {/* TODO: move <ConnectionType /> to INTEGRATIONS tab */}
            {/*<ConnectionType view={isViewMode} />*/}
            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const isGroupSimCard = getFieldValue('vendorType') === VENDOR_TYPE_ENUM.PROCESSOR
                return (
                  !isGroupSimCard && (
                    <>
                      <Address view={isViewMode} />
                      <ControlPanel view={isViewMode} />
                    </>
                  )
                )
              }}
            </FormItem>
          </div>
          <div className={classNames(styles.rightBlock, styles.withoutMargin)}>
            <UploaderPhotos2
              form={form}
              title='Vendor Logo'
              key={keyUploader}
              view={isViewMode}
              isProductPreview
              defaultPreviewImage={data?.logo}
              setToDeleteLogo={setIsLogoDelete}
              onChange={(photo: UploadFile[]) => {
                form.setFieldValue('logo', photo[0].originFileObj)
                setIsImageError(
                  form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                )
                setIsLogoDelete(false)
              }}
            />
            <VendorMasterColorBlock title='Vendor Color' view={isViewMode} />
          </div>
        </Form>
      </div>
      <div className={styles.footerWrapper}>
        <Row className={styles.footerStyle} justify='end'>
          <Col>
            <Space size={10}>
              <div className='cancelButtonWrapper'>
                <Button
                  color='blue'
                  size='middle'
                  onClick={onCancelButtonClick}
                  disabled={upVenResp.isLoading}
                  block
                >
                  Cancel
                </Button>
              </div>
              <div style={{ width: isViewMode ? 121 : 130 }}>
                <Button
                  htmlType='submit'
                  size='middle'
                  type='primary'
                  onClick={onSubmitButtonClick}
                  disabled={isImageError || upVenResp.isLoading}
                  block
                >
                  {isViewMode ? 'Edit Vendor' : 'Save Changes'}
                </Button>
              </div>
            </Space>
          </Col>
        </Row>
      </div>
    </>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}

export default VendorInfoTab
