import React from 'react'
import { Button } from 'src/shared/components/button/Button'

// styles
import styles from '../styles.module.scss'
import { TRANSACTION_STATUS_ENUM } from '../../../../../../../Finance/models/ITransaction'
import { PAYMENT_METHOD_ENUM } from '../../../../../../../Finance/models/IBankAccount'
import classNames from 'classnames'

interface IFooter {
  status?: string
  paymentType?: PAYMENT_METHOD_ENUM
  onEdit: () => void
  onCancel: () => void
  onReject: () => void
  onApprove: () => void
  canCancel: boolean
}

export const Footer: React.FC<IFooter> = ({
  status,
  onEdit,
  onCancel,
  onReject,
  onApprove,
  paymentType,
  canCancel = true,
}) => {
  const isStripePayment = paymentType === PAYMENT_METHOD_ENUM.STRIPE

  return (
    <div className={styles.footerContainer}>
      {status === TRANSACTION_STATUS_ENUM.CREATED && (
        <div
          className={classNames(styles.checkedContainer, {
            [styles.withStripePayment]: isStripePayment,
          })}
        >
          {!isStripePayment && (
            <>
              <Button color='blue' size='middle' onClick={onEdit}>
                edit
              </Button>
              <Button color='orange' size='middle' onClick={onCancel} disabled={!canCancel}>
                cancel
              </Button>
              <Button color='orange' size='middle' type='primary' onClick={onApprove}>
                approve
              </Button>
            </>
          )}
          <Button color='orange' size='middle' type='primary' onClick={onReject}>
            reject
          </Button>
        </div>
      )}
    </div>
  )
}
