import { DateTableIcon, PersonIcon, TicksIcon } from 'src/assets/svg'
import { IPurchaseOrder, IPurchaseOrderStatus } from 'src/features/Finance/models/IPurchaseOrder'
import { formatDateWithTime } from 'src/helpers/utils'
import React from 'react'
// styles
import styles from '../styles.module.scss'
import { nanoid } from 'nanoid'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

export interface ILogs {
  logs: IPurchaseOrder['purchaseOrderSubTransactions']
}

const LogRow: React.FC<IPurchaseOrderStatus> = ({ user, createdAt, status }) => {
  const { data: profile } = useGetProfileQuery()

  return (
    <div className={styles.logRowContainer}>
      <div>
        <TicksIcon />
        {status}
      </div>
      <div>
        <PersonIcon />
        <div title={user?.name}>{user?.name}</div>
      </div>
      <div>
        <DateTableIcon />
        {formatDateWithTime(createdAt, profile?.calendar)}
      </div>
    </div>
  )
}

export const Logs: React.FC<ILogs> = ({ logs = [] }) => {
  return (
    <div className={styles.container}>
      <div className={styles.headerDetailInfo}>
        <div className={styles.header}>Log</div>
      </div>
      {logs?.map((log) => (
        <LogRow {...log} key={nanoid()} />
      ))}
    </div>
  )
}
