import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './styles.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux'
import {
  addExternalMessagesAfter,
  addExternalMessagesBefore,
  clearExternalChatMessages,
  selectExternalChatMessagingSlice,
  setCreatedAtAfter,
  setCreatedAtBefore,
} from '../../../../../../core/store/ExternalChatMessagingSlice'
import moment from 'moment'
import TextMessage from '../TextMessage'
import {
  EXTERNAL_CHAT_WS_EVENTS_ENUM,
  ILastExternalChatMessage,
  IUpdateExternalChatMember,
  ScrollToMessage,
} from '../../../../../../models/IExternalChat'
import { useLazyGetExternalChatMessagesByIdQuery } from '../../../../../../core/http/ExternalChatApi'
import MessagingFooter from '../Footer'
import { Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import { first } from 'lodash'
import {
  selectExternalChatChatsSlice,
  selectExternalChat,
} from '../../../../../../core/store/ExternalChatChatsListSlice'
import { externalChatSocketConnection } from '../../../../../../../../shared/sockets'
import { Socket } from 'socket.io-client'
import { DefaultEventsMap } from '@socket.io/component-emitter'
import classNames from 'classnames'
import { EmojiClickData } from 'emoji-picker-react'

interface IProps {
  scrollToSearchMessageId: number | null
  searchMessageDate: string | null
}

const MessagingList = ({ scrollToSearchMessageId, searchMessageDate }: IProps) => {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false)
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null)
  const [toScrollId, setToScrollId] = useState<number | null>(null)
  const chatContainer = useRef<HTMLDivElement | null>(null)
  const websocketRef = useRef<Socket<DefaultEventsMap, DefaultEventsMap> | null>(null)
  const dispatch = useAppDispatch()
  const [limit] = useState(25)
  const [messageToReply, setMessageToReply] = useState<Partial<ILastExternalChatMessage> | null>(
    null
  )
  const { currentUserId, messages, lastReadingAtByCurrentUser, createdAtAfter, createdAtBefore } =
    useAppSelector(selectExternalChatMessagingSlice)
  const { externalChat, selectedChatId, activeTab } = useAppSelector(selectExternalChatChatsSlice)

  const counter =
    useAppSelector((state) => selectExternalChat(state, selectedChatId as number))
      ?.unreadedMessagesCount || 0
  const lastReadingAt = useMemo(() => externalChat?.lastReadingAt, [externalChat?.lastReadingAt])

  const [
    getBeforeMessages,
    { data: messagesBefore = { items: [], totalCount: 0 }, isFetching: isFetchingBefore },
  ] = useLazyGetExternalChatMessagesByIdQuery()
  const [
    getAfterMessages,
    { data: messagesAfter = { items: [], totalCount: 0 }, isFetching: isFetchingAfter },
  ] = useLazyGetExternalChatMessagesByIdQuery()

  const updateChatLastMessage = useCallback(
    (body: Partial<IUpdateExternalChatMember>) => {
      websocketRef.current?.emit(EXTERNAL_CHAT_WS_EVENTS_ENUM.READ_MESSAGE, {
        chatId: externalChat?.id,
        ...body,
      })
    },
    [externalChat?.id]
  )

  const addReactionToMessage = useCallback((body: EmojiClickData, messageId: number) => {
    websocketRef.current?.emit(EXTERNAL_CHAT_WS_EVENTS_ENUM.ADD_REACTION, {
      externalChatMessageId: messageId,
      emoji: body?.emoji,
    })
  }, [])

  const deleteReactionFromMessage = useCallback((messageId: number) => {
    websocketRef.current?.emit(EXTERNAL_CHAT_WS_EVENTS_ENUM.DELETE_REACTION, {
      externalChatMessageId: messageId,
    })
  }, [])

  const playAudio = useCallback(
    (event: ChangeEvent<HTMLAudioElement>) => {
      const audioElement = event.target
      if (currentAudio && currentAudio !== audioElement) {
        currentAudio?.pause()
      }

      audioElement?.play()
      setCurrentAudio(audioElement)
    },
    [currentAudio]
  )

  /** This condition is necessary for the design of messages delimiter by date in the chat */
  const isFirstMessageInADay = useCallback(
    (createdAt: string, i: number) => {
      return (
        i === messages.length - 1 ||
        (messages[i + 1] && !moment(messages[i + 1].createdAt).isSame(moment(createdAt), 'days'))
      )
    },
    [messages]
  )

  const isCurrentUserConnected = useMemo(() => {
    return externalChat?.members.find((member) => member?.userId === currentUserId)?.isConnected
  }, [currentUserId, externalChat?.members])

  const isChatArchived = useMemo(() => {
    return externalChat?.isArchived
  }, [externalChat?.isArchived])

  const selectToReply = useCallback((message: Partial<ILastExternalChatMessage> | null) => {
    setMessageToReply(message)
  }, [])

  const clearReply = useCallback(() => setMessageToReply(null), [])

  const isOnlyOneMessageBefore = useMemo(
    () => messagesBefore.totalCount === 1,
    [messagesBefore.totalCount]
  )

  const resetToRepliedMessage = useCallback(
    (date: string, id: number) => {
      dispatch(setCreatedAtAfter(date))
      dispatch(setCreatedAtBefore(date))
      setToScrollId(id)
    },
    [dispatch]
  )

  const scrollToReply = useCallback(
    (message: ScrollToMessage<{ id: number | null; createdAt: string | null }>) => {
      if (!chatContainer?.current) return

      const currentMessageElement = chatContainer.current?.querySelector(
        `#external-chat-message-${message.id}`
      ) as Element

      if (currentMessageElement) {
        currentMessageElement.scrollIntoView({ behavior: 'smooth' })
        currentMessageElement.classList.add('highlight')
        setTimeout(() => {
          currentMessageElement?.classList.remove('highlight')
        }, 1000)
      } else {
        dispatch(clearExternalChatMessages())
        if (message?.createdAt && message.id) {
          resetToRepliedMessage(message.createdAt, message.id)
        }
      }
    },
    [dispatch, resetToRepliedMessage]
  )

  /** WHEN USER SELECTS MESSAGE IN SEARCH, THE PAGE SHOULD SCROLL TO THIS
   * MESSAGE WHEN IT IS IN THE DOM, OTHERWISE - CLEAR ALL MESSAGES AND SET
   * DATA OF THE SEEKING MESS TO FETCH IT
   *  */
  useEffect(() => {
    if (!scrollToSearchMessageId && !searchMessageDate) return
    scrollToReply({ id: scrollToSearchMessageId, createdAt: searchMessageDate })
  }, [scrollToReply, scrollToSearchMessageId, searchMessageDate])

  /** LOAD MESSAGES ON SCROLL DOWN
   * IT GETS THE LAST MESSAGE IN THE LIST AND SETS CREATED AFTER
   * DATE OF THIS MESSAGE TO FETCH NEXT
   * */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadMessagesAfter = () => {
    const newCreatedAfter = first(messages)?.createdAt
    if (newCreatedAfter) {
      dispatch(setCreatedAtAfter(newCreatedAfter))
    }
  }

  /* LOAD MESSAGES ON SCROLL UP
   * IT GETS THE FIRST MESSAGE IN THE LIST AND SETS CREATED BEFORE
   * DATE OF THIS MESSAGE TO FETCH PREVIOUS
   * */
  const loadMessagesBefore = () => {
    const lastMessageDate = messagesBefore.items.at(-1)
    if (!lastMessageDate) return
    if (lastMessageDate?.createdAt) {
      return dispatch(setCreatedAtBefore(lastMessageDate.createdAt))
    }
  }

  const isMessageDateAfterLastReading = useCallback(
    (messDate: string) =>
      moment(lastReadingAtByCurrentUser).isAfter(moment(messDate)) ||
      moment(lastReadingAtByCurrentUser).isSame(moment(messDate)),
    [lastReadingAtByCurrentUser]
  )

  useEffect(() => {
    if (!externalChat?.id || (!createdAtBefore && !lastReadingAtByCurrentUser)) return

    getBeforeMessages({
      id: externalChat?.id,
      limit,
      createdAtBefore: (createdAtBefore as string) || (lastReadingAtByCurrentUser as string),
    })
  }, [createdAtBefore, externalChat?.id, getBeforeMessages, limit])

  useEffect(() => {
    if (!externalChat?.id || !createdAtAfter) return

    getAfterMessages({
      id: externalChat?.id,
      limit: limit,
      createdAtAfter: (createdAtAfter as string) || (lastReadingAtByCurrentUser as string),
    })
  }, [createdAtAfter, externalChat?.id, getAfterMessages, limit])

  const [lastReadMessage, setLastReadMessage] = useState<ILastExternalChatMessage | null>(null)

  useEffect(() => {
    if (isFetchingBefore) return
    if (messagesBefore.items?.length) {
      dispatch(addExternalMessagesBefore(messagesBefore.items))
      const firstMessage = first(messagesBefore.items)

      if (counter && !lastReadMessage && firstMessage && !createdAtBefore) {
        setLastReadMessage(firstMessage as ILastExternalChatMessage)
      }
    }
  }, [dispatch, isFetchingBefore, messagesBefore.items])

  useEffect(() => {
    if (isFetchingAfter) return
    if (messagesAfter.items?.length) {
      const sorted = [...messagesAfter.items].sort((a, b) =>
        moment(b?.createdAt).diff(moment(a?.createdAt))
      )
      dispatch(addExternalMessagesAfter(sorted))
    }
  }, [dispatch, isFetchingAfter, messagesAfter.items])

  useEffect(() => {
    setLastReadMessage(null)
  }, [selectedChatId])

  useEffect(() => {
    websocketRef.current = externalChatSocketConnection.getSocket()

    return () => {
      websocketRef.current = null
    }
  }, [])

  useEffect(() => {
    if (selectedChatId) {
      setToScrollId(null)
    }
  }, [selectedChatId])

  return (
    <>
      <div
        className={classNames(styles.chatroomMessagingContainer, 'externalChatMessages', {
          loading: isFetchingBefore,
        })}
        ref={chatContainer}
      >
        <div
          id='scrollable-external-div'
          className={classNames(styles.messagesListScrollContainer, {
            [styles.isOpen]: isEmojiPickerOpen,
          })}
          ref={chatContainer}
        >
          <InfiniteScroll
            inverse={true}
            onScroll={loadMessagesAfter}
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
              overflowY: isFetchingBefore ? 'hidden' : 'inherit',
            }}
            dataLength={messages?.length}
            next={loadMessagesBefore}
            hasMore={!!messagesBefore?.totalCount && messagesBefore?.totalCount > limit}
            loader={<Skeleton paragraph={{ rows: 5 }} active />}
            scrollableTarget='scrollable-external-div'
            endMessage={
              <div className={styles.scrollMessage}>{isFetchingBefore && 'Loading...'}</div>
            }
          >
            {messages?.map((message, i) => {
              const isNextMessageByTheSameUser =
                messages[i - 1]?.createdByUserId === message?.createdByUserId

              // PREVENT MESSAGE APPEARING IN OTHER CHAT
              return selectedChatId === message?.chatId ? (
                <TextMessage
                  key={message.id}
                  message={message}
                  currentUserId={currentUserId}
                  isFirstMessageInADay={isFirstMessageInADay(message?.createdAt, i)}
                  selectToReply={selectToReply}
                  scrollToReply={scrollToReply}
                  lastReadingAt={lastReadingAt}
                  updateLastReadingAt={updateChatLastMessage}
                  toScrollId={toScrollId}
                  shouldUpdateLastRead={isMessageDateAfterLastReading}
                  counter={counter}
                  lastReadMessage={lastReadMessage}
                  isNextMessageByTheSameUser={isNextMessageByTheSameUser}
                  addReactionToMessage={addReactionToMessage}
                  deleteReactionFromMessage={deleteReactionFromMessage}
                  playAudio={playAudio}
                  setIsEmojiPickerOpen={setIsEmojiPickerOpen}
                  isOnlyOneMessageBefore={isOnlyOneMessageBefore}
                />
              ) : null
            })}
          </InfiniteScroll>
        </div>
      </div>

      <MessagingFooter
        selectedChatId={externalChat?.id}
        currentUserId={currentUserId}
        messageToReply={messageToReply}
        clearReply={clearReply}
        setToScrollId={setToScrollId}
        isCurrentUserConnected={isCurrentUserConnected}
        isChatArchived={isChatArchived}
        activeTab={activeTab}
      />
    </>
  )
}

export default MessagingList
