import React, { useState } from 'react'
import { noop } from 'lodash'
import { IModalContext, IModalProps } from './models/IModal'

const context = {
  selectedModal: null,
  props: {},
  onOpen: noop,
  onClose: noop,
  setProps: noop,
}

export const RightModalContext = React.createContext<IModalContext>(context)

export const RightModalContextProvider: React.FC<IModalProps> = ({ children }) => {
  const [selectedModal, setModal] = useState<null | string>(null)
  const [props, setProps] = useState({})

  const onOpen = (modal: string, newModalProps?: Record<string, unknown>) => {
    setModal(modal)

    if (newModalProps !== undefined) {
      setProps({ ...props, ...newModalProps })
    }
  }

  const onClose = () => {
    setModal(null)
    setProps({})
  }

  const contextValue: IModalContext = {
    selectedModal,
    props,
    onOpen,
    onClose,
    setProps,
  }

  return <RightModalContext.Provider value={contextValue}>{children}</RightModalContext.Provider>
}
