import styles from '../styles.module.scss'
import { ScheduleNotificationTabs } from '../Components/ScheduleNotificationTabs'

export const ScheduleNotification = () => {
  return (
    <div className={styles.layout}>
      <ScheduleNotificationTabs />
    </div>
  )
}
