export enum TrustWalletTabsEnum {
  OVERVIEW = 'OVERVIEW',
  INVENTORY = 'INVENTORY',
  PURCHASE_ORDERS = 'PURCHASE ORDERS',
  E_PIN = 'ePIN INVENTORY',
  HYDRA = 'HYDRA INVENTORY',
}

export enum PurchaseOrderStatus {
  made = 'Made',
  checked = 'Checked',
  approved = 'Approved',
  rejected = 'Rejected',
  paid = 'Paid',
  processed = 'Processed',
}

export enum PurchaseOrderMode {
  view = 'view',
  edit = 'edit',
}
