import { Calendar } from 'antd-jalali'
import { Badge, Popover, Space } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { Row, Select } from 'antd'

import { formatOnlyDate } from 'src/helpers/utils'
import { SHAMSI_MONTHS } from 'src/shared/components/calendar/index.d'

import styles from './styles.module.scss'

import localeData from 'dayjs/plugin/localeData'
import { EEventHoliday, IEventOrHoliday } from 'src/features/Settings/models/IEventsHolidays'
import { ReactNode } from 'react'
import { startCase } from 'lodash'
import { CALENDAR_TYPE_ENUM } from 'src/features/Settings/models/IUser'

export const convertToAccureDayjs = (
  jalali: boolean | undefined,
  date: string | Date | Dayjs = new Date()
) => {
  return jalali ? dayjs(date).calendar('jalali') : dayjs(date)
}

dayjs.extend(localeData)

interface IProps {
  value?: Date
  events?: IEventOrHoliday[]
  jalali?: boolean | undefined
  renderCellContents?: (value: Dayjs) => ReactNode
  onPanelChange?: (date: Dayjs) => void
}

export const CalendarShamsi = ({
  value,
  jalali,
  events,
  renderCellContents,
  onPanelChange,
}: IProps) => {
  const defaultPickerValue = convertToAccureDayjs(jalali, value)

  const generateYearOptions = (date = new Date()) => {
    const currentYear = convertToAccureDayjs(jalali, date).get('year')
    const endYear = currentYear + 10
    const startYear = currentYear - 50
    return Array.from({ length: endYear - startYear + 1 }, (_, index) => {
      const year = startYear + index
      return { value: year, label: year.toString() }
    })
  }

  const generateMonthOptions = () => {
    return jalali
      ? Object.entries(SHAMSI_MONTHS).map(([_, value], i) => {
          const monthName = value.name
          return { value: i, label: monthName }
        })
      : Array.from({ length: 12 }, (_, i) => {
          const date = new Date(2000, i, 1)
          const monthName = date.toLocaleString('en-US', { month: 'long' })
          return { value: i, label: monthName }
        })
  }

  const getEvents = (events: IEventOrHoliday[] = [], date: Dayjs) => {
    return events.filter(
      (i) =>
        convertToAccureDayjs(jalali, i.start) <= date && convertToAccureDayjs(jalali, i.end) >= date
    )
  }

  const dateCellRender = (value: Dayjs) => {
    if (renderCellContents) {
      return renderCellContents(value)
    }

    const listData = getEvents(events as IEventOrHoliday[], value)

    const popoverContent = listData.map((i, index) => (
      <div key={index}>
        <Space>
          <div>Type:</div>
          <div>{startCase(i.type)}</div>
        </Space>
        <Space key={index}>
          <div>Name:</div>
          <div>{i.title}</div>
        </Space>
      </div>
    ))

    const title = formatOnlyDate(
      value.toISOString(),
      jalali ? CALENDAR_TYPE_ENUM.SHAMSI : CALENDAR_TYPE_ENUM.GREGORIAN
    )

    return (
      <Popover content={popoverContent} title={title}>
        <ul className={styles.events}>
          {listData?.map((item, index) => (
            <li key={index}>
              <Badge status={item.type === EEventHoliday.events ? 'success' : 'warning'} />
            </li>
          ))}
        </ul>
      </Popover>
    )
  }

  return (
    <div className={styles.container}>
      {/* <ConfigProvider locale={en_US}> */}
      <Calendar
        {...(value && { value: convertToAccureDayjs(jalali, value) })}
        onSelect={onPanelChange}
        onPanelChange={onPanelChange}
        defaultValue={defaultPickerValue}
        dateCellRender={dateCellRender}
        headerRender={({ value, onChange }) => {
          const d = convertToAccureDayjs(jalali, value)

          return (
            <div style={{ padding: 8 }}>
              <Row justify='space-between' className={styles.calendarHeader}>
                <div className={styles.yearSelect}>
                  <Select
                    dropdownMatchSelectWidth={false}
                    value={d.get('month')}
                    onChange={(newMonth) => {
                      const now = value.clone().month(newMonth)
                      onChange(now)
                    }}
                    options={generateMonthOptions()}
                    className='calendar-select'
                  />
                  <Select
                    value={d.get('year')}
                    onChange={(newYear) => {
                      const now = value.clone().year(newYear)
                      onChange(now)
                    }}
                    options={generateYearOptions()}
                    className='calendar-select'
                  />
                </div>
              </Row>
            </div>
          )
        }}
      />
      {/* </ConfigProvider> */}
    </div>
  )
}
