import React from 'react'
import NotificationAvatar from '../NotificationAvatar'
import NotificationDelimiter from '../NotificationDelimiter'
import NotificationData from '../NotificationData'
import NotificationStatus from '../NotificationStatus'
import { IProfileNotification } from '../../../../core/models'
import styles from '../../styles.module.scss'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { CALENDAR_TYPE_ENUM } from 'src/features/Settings/models/IUser'
import dayjs from 'dayjs'
import moment from 'moment'

interface IProps {
  isFirstNotificationInADay: boolean
  notification: IProfileNotification
  onPressDecline: () => void
  onPressAccept: () => void
}

const ProfileNotification = ({ isFirstNotificationInADay, notification }: IProps) => {
  const { data: profile } = useGetProfileQuery()

  const createdAt =
    profile?.calendar === CALENDAR_TYPE_ENUM.SHAMSI
      ? dayjs(notification.createdAt).calendar('jalali').format('YYYY-MM-DD')
      : moment(notification.createdAt).format('YYYY-MM-DD')

  const getMessageDateToShamsi = (message: string) => {
    const isDataExist = message.match(/\b\d{2}-\d{2}-\d{4}\b/)

    if (isDataExist && profile?.calendar === CALENDAR_TYPE_ENUM.SHAMSI) {
      const [dd, mm, yy] = isDataExist[0].split('-')

      const date = dayjs(new Date(`${yy}-${mm}-${dd}`))
        .calendar('jalali')
        .format('DD-MM-YYYY')

      return message.replace(/\b\d{2}-\d{2}-\d{4}\b/, date)
    }

    return message
  }

  return (
    <div key={notification.id} className='notificationItem' id={`notification-${notification.id}`}>
      {isFirstNotificationInADay && <NotificationDelimiter createdAt={createdAt} />}
      <div className={styles.notificationContainer}>
        <NotificationAvatar avatar={notification.user?.avatar} />
        <NotificationData
          text={getMessageDateToShamsi(notification.message)}
          createdAt={createdAt}
        />
        <div className={styles.actionsContainer}>
          {/*{!notification.isDelivered && (*/}
          {/*  <NotificationActions onPressAccept={onPressAccept} onPressDecline={onPressDecline} />*/}
          {/*)}*/}
          <NotificationStatus status={notification.isRead} />
        </div>
      </div>
    </div>
  )
}

export default ProfileNotification
