import { DashboardTableWrapper } from '../../../../../Dashboard/pages/Overview/Components/DashboardTableWrapper'
import styles from '../../../../../salesNetwork/pages/Overview/styles.module.scss'
import { PieChart } from '../../../../../../shared/components/pieChart/pieChart'
import React, { useMemo } from 'react'
import { NotFoundPage } from '../../../../../NotFoundPage'
import { ISalesByMasterProducts } from '../../../../models/IExternalReport'
import {
  PIE_CHART_COLORS,
  PIE_CHART_COLORS_WITH_TWO_ITEMS,
} from '../../../../../../helpers/contants'
import { Moment } from 'moment/moment'
import { RangePicker } from '../../../../../../shared/components/datePicker'
import { DatePickerIcon } from '../../../../../../assets/svg'
import { omit } from 'lodash'
import { RecursivelyReplaceCharacters } from 'src/shared/components/privateMode'

interface IProps {
  data: ISalesByMasterProducts[]
  onWidgetDateChange?: (value: Moment[] | null) => void
}

export const TotalSalesPieChart = ({ data = [], onWidgetDateChange }: IProps) => {
  const mappedData = useMemo(
    () =>
      data?.map((ven) => ({
        name: ven?.masterProduct?.name,
        value: Math.abs(ven?.salesAmount),
        label: ven?.salesAmount,
        info: omit(ven, 'masterProduct'),
        color: ven?.masterProduct?.color,
      })),
    [data]
  )

  const colors = useMemo(
    () => (mappedData?.length <= 2 ? PIE_CHART_COLORS_WITH_TWO_ITEMS : PIE_CHART_COLORS),
    [mappedData?.length]
  )

  const filters = onWidgetDateChange && (
    <RangePicker
      className={styles.delegationDatePicker}
      placeholder={['Date range', '']}
      onChange={(val) => onWidgetDateChange(val as Moment[])}
      suffixIcon={
        <div>
          <DatePickerIcon color='#0193EB' />
        </div>
      }
    />
  )

  return (
    <DashboardTableWrapper title='Total Sales' filters={filters}>
      {mappedData?.length ? (
        <div className={styles.pieChartTypeDataContainer}>
          <div>
            {mappedData?.map((cData, i) => (
              <RecursivelyReplaceCharacters key={cData?.name}>
                <div className={styles.chartDataTitle}>
                  <div
                    className={styles.chartDataDot}
                    style={{ backgroundColor: cData?.color ?? colors[i] }}
                  />
                  <div className={styles.chartDataName}>{cData?.name}</div>
                </div>
              </RecursivelyReplaceCharacters>
            ))}
          </div>
          <div>
            <PieChart
              data={mappedData}
              colors={colors}
              textCenterChart={mappedData.reduce((a, v) => a + v.label, 0)}
            />
          </div>
        </div>
      ) : (
        <NotFoundPage />
      )}
    </DashboardTableWrapper>
  )
}
