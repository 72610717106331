import { IByUser, IByUserWithFullName } from '../../../shared/models/IByUser'

interface ICustomer {
  id: string
  name: string
  phoneNumber: string
  status: boolean
  createdAt: string
}

interface IAgent {
  id: number
  firstName: string
  lastName: string
  companyTitle: string
  avatar: string
  createdAt: string
}

export interface IExternalChatMember {
  chatId: string | number
  userId: number
  isPinned: boolean
  isMuted: boolean
  isConnected: boolean
  lastReadingAt: string
  createdAt: string
  updatedAt: string
  user?: IByUserWithFullName
}

interface IFile {
  id: number
  name: string
  link: string
  fileFormat: string
  category: string
  isPrivate: boolean
  createdByUser: IByUser
  createdAt: string
}

export interface IExternalChatMessageFile {
  id: number
  externalChatMessageId: number
  fileId: number
  fileType: EXTERNAL_CHAT_FILE_TYPE_ENUM
  file: IFile
  createdAt: string
  updatedAt: string
}

export type ScrollToMessage<T> = T

export interface IReplyToMessage {
  id: number
  isMessageFromClinet: boolean
  messengerMessageId: number
  createdByUserId: number
  message: string
  messageType: MESSAGE_TYPE_ENUM
  createdAt: string
  updatedAt: string
  deletedAt: string
  externalChatMessageFiles: Array<IExternalChatMessageFile>
}

export interface IReaction {
  emoji: string
  isFromMessenger: boolean
}

export interface ILastExternalChatMessage {
  chatId?: number
  id: number
  isMessageFromClinet: boolean
  messengerMessageId: string
  createdByUserId: number
  message: string
  messageType: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  replyToMessage?: IReplyToMessage
  externalChatMessageFiles: Array<IExternalChatMessageFile>
  createdByUser: IByUser
  reactions: Array<IReaction>
}

export enum EXTERNAL_CHAT_LIST_TABS {
  MY = 'MY',
  ALL = 'ALL',
  ARCHIVED = 'ARCHIVED',
}

export enum EXTERNAL_CHAT_FILE_TYPE_ENUM {
  PHOTO = 'Photo',
  VIDEO = 'Video',
  FILE = 'File',
}

export enum MESSENGER_TYPE_ENUM {
  WHATSAPP = 'WHATSAPP',
}

export enum MESSAGE_TYPE_ENUM {
  MESSAGE = 'MESSAGE',
  PRIVATE_NOTE = 'PRIVATE_NOTE',
  REASSIGNED = 'REASSIGNED',
}

export enum EXTERNAL_CHAT_WS_EVENTS_ENUM {
  MESSAGE = 'message',
  TYPING = 'typing',
  READING = 'reading',
  END_SESSION = 'session-end',
  START_SESSION = 'session-start',
  READ_MESSAGE = 'read-message',
  ADD_MEMBER = 'add-member',
  REMOVE_MEMBER = 'remove-member',
  ARCHIVE_CHAT = 'archive-chat',
  UN_ARCHIVE_CHAT = 'un-archive-chat',
  ADD_REACTION = 'add-reaction',
  DELETE_REACTION = 'delete-reaction',
  UPDATE_CHAT_COUNTER = 'update-chat-counter',
}

export interface IExternalChat {
  id: number
  messengerId: number
  lastReadingAt: string
  lastReadingByUserAt: string
  messengerType: MESSENGER_TYPE_ENUM
  messengerProfileName: string
  customerPhoneNumber: string
  agentId: string
  isArchived: boolean
  hasSession: boolean
  updatedAt: string
  createdAt: string
  customer: ICustomer
  agent: IAgent
  members: IExternalChatMember[]
  lastExternalChatMessage: ILastExternalChatMessage
  firstMessageDate: string
  typingUser?: {
    userId: number | null
    typing: boolean
  }
}

export interface IExternalChatTable extends Omit<IExternalChat, 'member'> {
  member: IExternalChatMember
  unreadedMessagesCount: number
  typing?: boolean
}

export interface IUpdateExternalChatMember {
  chatId: number
  userId?: number
  isMuted: boolean
  isPinned: boolean
  isConnected: boolean
  lastReadingAt: string
}

export interface IUpdateExternalChatMemberResp extends IUpdateExternalChatMember {
  userId: number
  createdAt: string
  updatedAt: string
}

export interface ICreateExternalChatMessage {
  chatId: number
  message?: string
  replyToMessageId?: number
  messageType?: MESSAGE_TYPE_ENUM
}

export interface IExtChatWSReadingEvent {
  chatId: number
  userId: number
  lastReadingAt: string
}

interface IChat {
  id: number
  messengerProfileName: string
  messengerType: string
}

export interface IExternalUnreadMessage {
  id: number
  message?: string
  isMessageFromClinet: string
  createdByUser: IByUser
  messageType: string
  createdAt: string
  chat: IChat
  externalChatMessageFiles: IExternalChatMessageFile[]
}

export interface IAddReactionResp {
  chatId: string
  emoji: string
  isFromMessenger: boolean
  messageId: string
}
