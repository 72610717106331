import styles from '../../styles.module.scss'
import { Collapse, Space } from 'antd'
import {
  PermissionModule,
  PermissionsAllByTypeCheckbox,
  PermissionsAllByTypeSubmoduleCheckbox,
  PermissionsAllInGroupCheckbox,
  PermissionsAllInSubModuleCheckbox,
  PermissionsGroupStatusSwitcher,
} from '../index'
import React, { useCallback } from 'react'
import {
  IGroupCreate,
  IPermissionCreate,
  IPermissionModulesGroup,
  IRolePermissionModule,
} from '../../../../../../models/IRoles'
import {
  useGetPermissionModulesGroupListQuery,
  useGetPermissionModulesListQuery,
} from '../../../../../../core/http/RolesAndPermissionsManagement'
import { KeyboardArrowDown } from '../../../../../../../../assets/svg'

const { Panel } = Collapse

interface IProps {
  isViewMode?: boolean
  selectedPermissions: Array<IPermissionCreate>
  selectedGroups: Array<IGroupCreate>
  actions: Array<string>
  onChangeGroupStatus: (data: { group: number; access: boolean }) => void
  onCheckPermission: (
    permissions: IPermissionCreate | Array<IPermissionCreate>,
    checked?: boolean
  ) => void
}

const PermissionsTableBody = ({
  isViewMode,
  selectedPermissions,
  selectedGroups,
  actions,
  onChangeGroupStatus,
  onCheckPermission,
}: IProps) => {
  const { data: permissionModuleGroupsData, ...permissionModuleGroupsDataResp } =
    useGetPermissionModulesGroupListQuery()

  const { data: permissionModuleGroupsData2, ...permissionModuleGroupsDataResp2 } =
    useGetPermissionModulesListQuery()

  const onSwitchPermissionsGroupStatus = useCallback(
    (status: boolean, groupId: number) => {
      onChangeGroupStatus({ group: groupId, access: status })
    },
    [onChangeGroupStatus]
  )

  const onSelectedAllGroupPermissions = useCallback(
    (group: IPermissionModulesGroup, status: boolean) => {
      const selected: Array<IPermissionCreate> = []

      group.modules.forEach((module) => {
        /** Add permissions from module */
        module.permissionModuleAllowedPermissions.forEach((item) => {
          selected.push({
            permissionModuleId: module.id,
            permission: item.permission,
          })
        })

        /** Add permissions from submodule */
        module.subModules.forEach((subModule) => {
          subModule.permissionModuleAllowedPermissions.forEach((i) => {
            selected.push({
              permissionModuleId: subModule.id,
              permission: i.permission,
            })
          })
        })
      })

      onCheckPermission(selected, status)
    },
    [onCheckPermission]
  )

  const onSelectAllModulePermissions = useCallback(
    (module: IRolePermissionModule, status: boolean) => {
      const selected: Array<IPermissionCreate> = module.permissionModuleAllowedPermissions.map(
        (item) => ({
          permissionModuleId: module.id,
          permission: item.permission,
        })
      )

      onCheckPermission(selected, status)
    },
    [onCheckPermission]
  )

  const onSelectAllPermissionsByTypeInGroup = useCallback(
    (group: IPermissionModulesGroup, actionType: string, status: boolean) => {
      const selected: Array<IPermissionCreate> = []
      group.modules.forEach((module) => {
        const permission = module.permissionModuleAllowedPermissions.find(
          (item) => item.permission === actionType
        )
        if (permission) {
          selected.push({
            permissionModuleId: module.id,
            permission: permission?.permission,
          })
        }
        module.subModules.forEach((sub) => {
          const permission = sub.permissionModuleAllowedPermissions.find(
            (item) => item.permission === actionType
          )
          if (permission) {
            selected.push({
              permissionModuleId: sub.id,
              permission: permission?.permission,
            })
          }
        })
      })

      onCheckPermission(selected, status)
    },
    [onCheckPermission]
  )

  const onSelectAllSubmodulePermissions = useCallback(
    (module: IRolePermissionModule, status: boolean) => {
      const selected: Array<IPermissionCreate> = []
      /** Add permissions from submodule */
      module.subModules.forEach((subModule) => {
        subModule.permissionModuleAllowedPermissions.forEach((i) => {
          selected.push({
            permissionModuleId: subModule.id,
            permission: i.permission,
          })
        })
      })

      onCheckPermission(selected, status)
    },
    [onCheckPermission]
  )

  const onSelectAllSubmoduleByTypePermissions = useCallback(
    (module: IRolePermissionModule, actionType: string, status: boolean) => {
      const selected: Array<IPermissionCreate> = []
      module.subModules.forEach((subModule) => {
        const permission = subModule.permissionModuleAllowedPermissions.find(
          (item) => item.permission === actionType
        )

        if (permission) {
          selected.push({
            permissionModuleId: subModule.id,
            permission: permission?.permission,
          })
        }
      })

      onCheckPermission(selected, status)
    },
    [onCheckPermission]
  )

  const isActiveGroup = (id: number) => {
    return selectedGroups.find((group) => group.group === id)?.access
  }

  return (
    <Space direction='vertical' style={{ width: '100%' }} className={styles.collapseSpaceContainer}>
      {permissionModuleGroupsData?.length
        ? permissionModuleGroupsData.map((group) => (
            <Collapse
              style={{ width: '100%' }}
              bordered={false}
              key={`collapse-module-header-${group.id}`}
              collapsible='header'
              className={styles.collapseItem}
              expandIcon={(panelProps) => (
                <div
                  className={`${styles.expandGroupIcon} ${
                    panelProps.isActive && styles.expandGroupIconRotate
                  }`}
                >
                  <KeyboardArrowDown />
                </div>
              )}
            >
              <Panel
                style={{ marginBottom: 8, width: '100%' }}
                header={
                  <div className={styles.actionsPanelHeader}>
                    <div className={styles.groupTitle}>{group.group}</div>
                    <div className={styles.actionsContainer}>
                      <div className={styles.actionContainer}>
                        <PermissionsAllInGroupCheckbox
                          disabled={isViewMode}
                          selectedPermissions={selectedPermissions}
                          group={group}
                          onSelectedAllGroupPermissions={onSelectedAllGroupPermissions}
                        />
                      </div>
                      {actions.map((action) => (
                        <div key={`group-header-${action}`} className={styles.actionContainer}>
                          <PermissionsAllByTypeCheckbox
                            disabled={isViewMode}
                            group={group}
                            action={action}
                            selectedPermissions={selectedPermissions}
                            onSelectAllPermissionsByTypeInGroup={
                              onSelectAllPermissionsByTypeInGroup
                            }
                          />
                        </div>
                      ))}
                      <PermissionsGroupStatusSwitcher
                        selected={isActiveGroup(group.id)}
                        groupId={group.id}
                        onSwitchPermissionsGroupStatus={onSwitchPermissionsGroupStatus}
                        isViewMode={isViewMode}
                      />
                    </div>
                  </div>
                }
                key={group.id}
              >
                <div className='moduleContainer'>
                  {group.modules.length
                    ? group.modules.map((module, i) =>
                        module.subModules.length ? (
                          // SUBMODULES COLLAPSE
                          <Collapse
                            bordered={false}
                            key={`collapse-module-header-${module.id}`}
                            collapsible='header'
                            className={styles.actionsPanelHeader}
                            expandIcon={(panelProps) => (
                              <div
                                className={`${styles.expandGroupIcon} ${
                                  panelProps.isActive && styles.expandGroupIconRotate
                                }`}
                              >
                                <KeyboardArrowDown />
                              </div>
                            )}
                          >
                            <Panel
                              style={{ marginBottom: 0 }}
                              header={
                                <div className={styles.actionsPanelHeader}>
                                  <div className={styles.moduleName}>{module.module}</div>
                                  <div className={styles.actionsContainer}>
                                    <div className={styles.actionContainer}>
                                      <PermissionsAllInSubModuleCheckbox
                                        disabled={isViewMode}
                                        selectedPermissions={selectedPermissions}
                                        module={module}
                                        onSelectAllSubmodulePermissions={
                                          onSelectAllSubmodulePermissions
                                        }
                                      />
                                    </div>
                                    {actions.map((action) => (
                                      <div
                                        key={`sub-module-header-${action}`}
                                        className={styles.actionContainer}
                                      >
                                        <PermissionsAllByTypeSubmoduleCheckbox
                                          disabled={isViewMode}
                                          selectedPermissions={selectedPermissions}
                                          action={action}
                                          module={module}
                                          onSelectAllSubmoduleByTypePermissions={
                                            onSelectAllSubmoduleByTypePermissions
                                          }
                                        />
                                      </div>
                                    ))}
                                    <div className={styles.actionContainer}></div>
                                  </div>
                                </div>
                              }
                              key={i}
                            >
                              <div>
                                {module.subModules.length
                                  ? module.subModules.map((subModule) => (
                                      <PermissionModule
                                        key={`collapse-module-header-${subModule.id}`}
                                        isViewMode={isViewMode}
                                        isSubModule
                                        module={subModule}
                                        actions={actions}
                                        selectedPermissions={selectedPermissions}
                                        onCheckPermission={onCheckPermission}
                                        onSelectAllModulePermissions={onSelectAllModulePermissions}
                                      />
                                    ))
                                  : null}
                              </div>
                            </Panel>
                          </Collapse>
                        ) : (
                          <PermissionModule
                            key={`collapse-module-header-${module.id}`}
                            isViewMode={isViewMode}
                            module={module}
                            actions={actions}
                            selectedPermissions={selectedPermissions}
                            onCheckPermission={onCheckPermission}
                            onSelectAllModulePermissions={onSelectAllModulePermissions}
                          />
                        )
                      )
                    : null}
                </div>
              </Panel>
            </Collapse>
          ))
        : null}
    </Space>
  )
}

export default PermissionsTableBody
