import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import {
  ICreatePurchasePlan,
  IInventoryCalendar,
  IInventoryChart,
  IInventoryTable,
  IInventoryTransaction,
  INVENTORY_CHART_TYPE_ENUM,
  IPurchasePlan,
} from '../../models/IInventory'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { VENDOR_WALLET_TYPE_ENUM } from '../../models/IPurchaseOrder'
import { IEpinInventory } from '../../models/IEPin'

export const vendorInventoryApi = createApi({
  reducerPath: 'vendorInventory',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'IInventory',
    'InventoryList',
    'ICalendar',
    'IInventoryTransactions',
    'IInventoryChart',
  ],
  endpoints: (builder) => ({
    createInventoryPurchasePlan: builder.mutation<
      void,
      { vendorId: string; body: ICreatePurchasePlan }
    >({
      query: ({ body, vendorId }) => ({
        url: `vendors/${vendorId}/inventories`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InventoryList'],
    }),

    updateInventoryPurchasePlan: builder.mutation<
      void,
      { inventoryId: string; body: ICreatePurchasePlan }
    >({
      query: ({ body, inventoryId }) => ({
        url: `vendors/inventories/${inventoryId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'IInventory', id: arg.inventoryId },
        'InventoryList',
        'ICalendar',
        'IInventoryChart',
      ],
    }),

    getInventoryList: builder.query<ITableResponse<IInventoryTable>, ITableConf>({
      query: (params) => ({
        url: `vendors/inventories`,
        params,
      }),
      providesTags: () => ['InventoryList'],
    }),

    getInventoryById: builder.query<IPurchasePlan, string>({
      query: (inventoryId) => ({
        url: `vendors/inventories/${inventoryId}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'IInventory', id: arg }],
    }),

    getInventoryCalendarByMonth: builder.query<
      Array<IInventoryCalendar>,
      { vendorId: string; monthRange: string }
    >({
      query: ({ vendorId, monthRange }) => ({
        url: `vendors/${vendorId}/inventories/calendar/${monthRange}`,
      }),
      providesTags: ['ICalendar'],
    }),

    getInventoryChart: builder.query<
      Array<IInventoryChart>,
      { vendorId: string; chartType: INVENTORY_CHART_TYPE_ENUM }
    >({
      query: ({ vendorId, chartType }) => ({
        url: `vendors/${vendorId}/inventories/charts/${chartType}`,
      }),
      providesTags: ['IInventoryChart'],
    }),

    getInventoryTransactionsList: builder.query<
      ITableResponse<IInventoryTransaction>,
      { vendorId: string; params: ITableConf }
    >({
      query: ({ vendorId, params }) => ({
        url: `vendors/${vendorId}/inventories/transactions`,
        params,
      }),
      providesTags: ['IInventoryTransactions'],
    }),

    getEPinInventoryList: builder.query<
      ITableResponse<IEpinInventory>,
      { params: ITableConf; vendorId: string }
    >({
      query: ({ params, vendorId }) => ({
        url: `vendors/${vendorId}/inventories/e-pin-summary`,
        params,
      }),
    }),

    getInventoryTransactionsFiltersList: builder.query<
      Array<IFilterFields>,
      { id: string; vendorWalletType: VENDOR_WALLET_TYPE_ENUM }
    >({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'vendors/inventories/transactions/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `vendors/${arg.id}/inventories/transactions/filter-values?filterField=${filter.field}`,
            params: { vendorWalletType: arg.vendorWalletType },
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),
  }),
})

export const {
  useCreateInventoryPurchasePlanMutation,
  useUpdateInventoryPurchasePlanMutation,
  useGetInventoryListQuery,
  useGetInventoryTransactionsListQuery,
  useGetInventoryTransactionsFiltersListQuery,
  useGetInventoryCalendarByMonthQuery,
  useGetInventoryChartQuery,
  useGetInventoryByIdQuery,
  useGetEPinInventoryListQuery,
} = vendorInventoryApi
