import styles from './styles.module.scss'
import React from 'react'
import classNames from 'classnames'
import { RecursivelyReplaceCharacters } from '../privateMode'

interface IProps {
  tag: string
  isBlue?: boolean
  onTagClick?: (tag: string) => () => void
}

interface ITags {
  tags: Array<string>
  isBlue?: boolean
  onTagClick?: (tag: string) => () => void
}
export const Tag = ({ tag, isBlue, onTagClick }: IProps) => {
  return (
    <RecursivelyReplaceCharacters>
      <div
        onClick={onTagClick?.(tag)}
        className={classNames(styles.tag, { [styles.isBlue]: isBlue, [styles.cursor]: onTagClick })}
      >
        {tag}
      </div>
    </RecursivelyReplaceCharacters>
  )
}

export const Tags = ({ tags, isBlue, onTagClick }: ITags) => {
  return (
    <div className={classNames(styles.tagsWrapper)}>
      {tags?.map((tag) => (
        <Tag key={tag} tag={tag} isBlue={isBlue} onTagClick={onTagClick} />
      ))}
    </div>
  )
}
