import styles from '../../styles.module.scss'
import { Form, Spin } from 'antd'
import { Button } from '../../../../../../../shared/components/button/Button'
import {
  ICreatePurchasePlan,
  IPurchasePlan,
  PURCHASE_PLAN_TIME_OPTIONS,
  PURCHASE_PLAN_TYPE_ENUM,
} from '../../../../../models/IInventory'
import {
  useCreateInventoryPurchasePlanMutation,
  useGetInventoryByIdQuery,
  useUpdateInventoryPurchasePlanMutation,
} from '../../../../../core/http/VendorInventoryApi'
import { useCallback, useContext, useEffect } from 'react'
import { RightModalContext } from '../../../../../../Modals'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../shared/api/errorHandler'
import { PeriodBlock } from './PeriodBlock'
import { PurchaseTargetBlock } from './PurchaseTargetBlock'
import { VendorBlock } from './VendorBlock'
import {
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  getFormCalendarTime,
} from '../../../../../../../helpers/utils'
import moment from 'moment-timezone'
import { Moment } from 'moment/moment'
import { VendorTresholdBlock } from '../VendorTresholdBlock'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

export const PurchasePlanModal = () => {
  const {
    onClose,
    props: { vendorId, inventoryId },
  } = useContext(RightModalContext)
  const [form] = Form.useForm()
  const { data: profile } = useGetProfileQuery()

  const [crPurchasePlan, crPurchasePlanResp] = useCreateInventoryPurchasePlanMutation()
  const [updPurchasePlan, updPurchasePlanResp] = useUpdateInventoryPurchasePlanMutation()
  const { data, isFetching } = useGetInventoryByIdQuery(inventoryId as string, {
    skip: !inventoryId,
  })

  const isLoading = isFetching || crPurchasePlanResp.isLoading || updPurchasePlanResp.isLoading

  useNotification(NOTIFICATION_TYPES.success, crPurchasePlanResp.isSuccess, null, onClose)
  useNotification(NOTIFICATION_TYPES.success, updPurchasePlanResp.isSuccess, null, onClose)
  useNotification(
    NOTIFICATION_TYPES.error,
    crPurchasePlanResp.isError,
    crPurchasePlanResp.error as ErrorNode
  )
  useNotification(
    NOTIFICATION_TYPES.error,
    updPurchasePlanResp.isError,
    updPurchasePlanResp.error as ErrorNode
  )

  const onFinish = (values: IPurchasePlan) => {
    const isEachDayType = values.type === PURCHASE_PLAN_TIME_OPTIONS.EACH_DAY
    const id = vendorId ?? values?.vendorId

    const body = {
      monthRange: values.monthRange,
      cashAmount: +values.cashAmount,
      eMoneyAmount: +values.eMoneyAmount,
      thresholdAmount: +values.thresholdAmount,
      thresholdDenomination: +values.thresholdDenomination,
      type: isEachDayType ? values.type : values.timeOption,
      ...(!isEachDayType && {
        distributionRanges: values?.distributionRanges.map((range) => ({
          ...(range?.date[0] && {
            start: formatMomentDateToDayStart(range?.date[0])?.utc(true).toISOString(),
          }),
          ...(range?.date[1] && {
            end: formatMomentDateToDayEnd(range?.date[1])?.utc(true).toISOString(),
          }),
          amount: +range?.amount,
          percent: +range?.percent,
        })),
      }),
    } as ICreatePurchasePlan

    inventoryId ? updPurchasePlan({ inventoryId, body }) : crPurchasePlan({ vendorId: id, body })
  }

  const renderDays = useCallback((month: Moment) => {
    const daysInMonth = moment(month).daysInMonth()
    const days = []
    for (let i = 1; i <= daysInMonth; i++) {
      days.push({
        date: [formatMomentDateToDayStart(month.date(i)), formatMomentDateToDayEnd(month.date(i))],
        percent: 0,
        amount: 0,
      })
    }
    return days
  }, [])

  const renderWeeks = useCallback((month: Moment) => {
    const startOfMonth = moment(month).tz('UTC').startOf('month')
    const endOfMonth = moment(month).tz('UTC').endOf('month')
    const weeks = []
    let weekStart = startOfMonth

    while (weekStart.isBefore(endOfMonth)) {
      const weekEnd = moment.min(moment(weekStart).endOf('week'), endOfMonth)
      weeks.push({
        date: [formatMomentDateToDayStart(weekStart), formatMomentDateToDayEnd(weekEnd)],
        percent: 0,
        amount: 0,
      })
      weekStart = moment(weekEnd).add(1, 'day')
    }

    if (weekStart.isSameOrBefore(endOfMonth)) {
      weeks.push({
        date: [formatMomentDateToDayStart(weekStart), formatMomentDateToDayEnd(endOfMonth)],
        percent: 0,
        amount: 0,
      })
    }

    return weeks
  }, [])

  const onTimeOptionChange = (e: string) => {
    const selectedMonth = form.getFieldValue('monthRange')

    if (e === PURCHASE_PLAN_TIME_OPTIONS.MANUALLY_PER_WEEK) {
      const weeks = renderWeeks(selectedMonth)
      return form.setFieldsValue({ distributionRanges: weeks })
    }

    if (e === PURCHASE_PLAN_TIME_OPTIONS.MANUALLY_PER_DAY) {
      const days = renderDays(selectedMonth)
      return form.setFieldValue('distributionRanges', days)
    }

    return form.setFieldValue('distributionRanges', [])
  }

  const onMonthChange = (selectedMonth: Moment) => {
    const timeOption = form.getFieldValue('timeOption')

    if (timeOption === PURCHASE_PLAN_TIME_OPTIONS.MANUALLY_PER_WEEK) {
      const weeks = renderWeeks(selectedMonth)
      return form.setFieldsValue({ distributionRanges: weeks })
    }

    if (timeOption === PURCHASE_PLAN_TIME_OPTIONS.MANUALLY_PER_DAY) {
      const days = renderDays(selectedMonth)
      return form.setFieldValue('distributionRanges', days)
    }

    return form.setFieldValue('distributionRanges', [])
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        monthRange: getFormCalendarTime(
          profile?.calendar,
          data?.monthRange
        ) /* moment(data?.monthRange), */,
        type:
          data?.type === PURCHASE_PLAN_TIME_OPTIONS.EACH_DAY
            ? PURCHASE_PLAN_TYPE_ENUM.EACH_DAY
            : PURCHASE_PLAN_TYPE_ENUM.MANUALLY,
        timeOption: data?.type !== PURCHASE_PLAN_TIME_OPTIONS.EACH_DAY ? data?.type : null,
        distributionRanges: data?.distributionRanges?.map((range) => ({
          ...range,
          date: [
            getFormCalendarTime(profile?.calendar, range?.start),
            getFormCalendarTime(profile?.calendar, range?.end),
          ],
        })),
      })
    }
  }, [data, form, profile])

  const content = (
    <div className={styles.modalWrapper}>
      <Form form={form} layout='vertical' onFinish={onFinish} className={styles.purchasePlanModal}>
        {!vendorId && <VendorBlock />}
        <VendorTresholdBlock />
        <PeriodBlock onMonthChange={onMonthChange} />
        <PurchaseTargetBlock onTimeOptionChange={onTimeOptionChange} />
      </Form>
      <div className={styles.footerContainer}>
        <Button color='blue' onClick={onClose}>
          Cancel
        </Button>
        <Button color='orange' onClick={() => form.resetFields()}>
          Clear All
        </Button>
        <Button color='orange' type='primary' onClick={() => form.submit()}>
          Setup Purchase Plan
        </Button>
      </div>
    </div>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
