import React from 'react'
import { Select } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import FormList from 'antd/es/form/FormList'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { TextField } from 'src/shared/components/textfield/TextField'
import { Checkbox } from 'src/shared/components/checkbox/Checkbox'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from 'src/assets/svg'
import styles from './styles.module.scss'
import classNames from 'classnames'

const variableTypes = [
  { label: 'CONSTANT', value: 'constant' },
  { label: 'COMPUTED', value: 'computed' },
]

const systemVariables = [
  { label: 'AMOUNT WITH FORMAT', value: 'AMOUNT_WITH_FORMAT' },
  { label: 'AMOUNT MULTIPLYING BY 100', value: 'AMOUNT_MULTIPLYING_BY_100' },
  { label: 'PRODUCT CODE', value: 'PRODUCT_CODE' },
  { label: 'TRANSACTION ID', value: 'TRANSACTION_ID' },
  { label: 'VENDOR TRANSACTION ID', value: 'VENDOR_TRANSACTION_ID' },
  { label: 'CUSTOMER PHONE NUMBER', value: 'CUSTOMER_PHONE_NUMBER' },
  { label: 'CUSTOMER NATIONAL PHONE NUMBER', value: 'CUSTOMER_NATIONAL_PHONE_NUMBER' },
  { label: 'CUSTOMER INTERNATIONAL PHONE NUMBER', value: 'CUSTOMER_INTERNATIONAL_PHONE_NUMBER' },
  { label: 'RESPONSE CODE', value: 'RESPONSE_CODE' },
  { label: 'RESPONSE DESCRIPTION', value: 'RESPONSE_DESCRIPTION' },
  { label: 'BALANCE', value: 'BALANCE' },
  { label: 'CUSTOMER BALANCE BEFORE', value: 'CUSTOMER_BALANCE_BEFORE' },
  { label: 'CUSTOMER BALANCE AFTER', value: 'CUSTOMER_BALANCE_AFTER' },
  { label: 'REQUEST DATE TIME', value: 'REQUEST_DATE_TIME' },
  { label: 'ENCRYPTION KEY', value: 'ENCRYPTION_KEY' },
  { label: 'SIGN KEY', value: 'SIGN_KEY' },
  { label: 'API USER NAME', value: 'API_USER_NAME' },
  { label: 'API PASSWORD', value: 'API_PASSWORD' },
  { label: 'HOST', value: 'HOST' },
  { label: 'PORT', value: 'PORT' },
]

const functionTypes = [
  { label: 'CALCULATE_CHECK_SUM', value: 'CALCULATE_CHECK_SUM' },
  { label: 'CALCULATE_MESSAGE_LENGTH', value: 'CALCULATE_MESSAGE_LENGTH' },
  { label: 'CONVERT_TO_HEX', value: 'CONVERT_TO_HEX' },
  { label: 'PAD_START', value: 'PAD_START' },
]

interface IProps {
  onAddNew: (header: IIntegrationFormVariable, key: string) => void
}

export interface IIntegrationFormVariable {
  key: string
  type: string
  value: string
  encrypted: boolean
  system_variables: string
}

const ResponseVariables = ({ onAddNew }: IProps) => {
  const addNewVariable = () => {
    onAddNew(
      {
        key: '',
        type: 'constant',
        value: '',
        encrypted: false,
        system_variables: 'AMOUNT_WITH_FORMAT',
      },
      'responseVariables'
    )
  }

  const onChangeCheckbox = () => {}
  return (
    <Wrapper
      title='Response Variables'
      className={styles.generalInfoWrapper}
      actions={
        <IconButton
          type='default'
          color='orange'
          block
          icon={<AddIcon />}
          onClick={addNewVariable}
        />
      }
    >
      <FormList name='responseVariables'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name, key }) => (
              <div key={key} className={classNames(styles.dblRow, styles.formBlockItem)}>
                <div>
                  <div className={styles.dblRow}>
                    <FormItem name={[name, 'key']} label='Key' rules={[{ required: true }]}>
                      <TextField placeholder='Enter variable key' />
                    </FormItem>
                    <FormItem name={[name, 'value']} label='Value' rules={[{ required: true }]}>
                      <TextField placeholder='Enter variable value' />
                    </FormItem>
                    <FormItem name={[name, 'encrypted']}>
                      <Checkbox onChange={onChangeCheckbox} text='Encrypted' />
                    </FormItem>
                  </div>
                  <div className={styles.dblRow}>
                    <FormItem name={[name, 'type']} label='Type'>
                      <Select allowClear options={variableTypes} defaultValue='constant' />
                    </FormItem>
                    <FormItem name={[name, 'system_variables']} label='System variables'>
                      <Select
                        allowClear
                        options={systemVariables}
                        defaultValue='AMOUNT_WITH_FORMAT'
                      />
                    </FormItem>
                    <FormItem name={[name, 'function']} label='Function'>
                      <Select
                        allowClear
                        options={functionTypes}
                        defaultValue='CALCULATE_CHECK_SUM'
                      />
                    </FormItem>
                  </div>
                </div>
                <IconButton
                  color='orange'
                  size='middle'
                  icon={<BucketIcon />}
                  onClick={() => remove(name)}
                  className={styles.removeButton}
                />
              </div>
            ))}
          </>
        )}
      </FormList>
    </Wrapper>
  )
}

export default ResponseVariables
