import { useNavigate, useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useCreateNotificationMessageMutation,
  useGetNotificationMessageByIdQuery,
  useGetNotificationVariablesListQuery,
  useUpdateNotificationMessageMutation,
} from '../../../core/http/NotificationMessageApi'
import { Form, Row, Space, Spin } from 'antd'
import styles from './styles.module.scss'
import { DetailInfo } from './DetailInfo'
import { useAppDispatch } from '../../../../../redux'
import { setBreadcrumb } from '../../../../app/core/BreadcrumbsSlice'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import { deleteHtmlTagsFromString, formatDateWithTime } from '../../../../../helpers/utils'
import { ChannelBlock } from './ChannelBlock/index'
import {
  IMessage,
  INotificationManagerCreate,
  INotificationMessage,
  MESSAGE_AVAILABLE_CHANNEL_ENUM,
  NOTIFICATION_MESSAGE_ACTION_ENUM,
} from '../../../models/INotificationMessage'
import { Button } from '../../../../../shared/components/button/Button'
import { SETTINGS_ENUM } from '../../../../../routes/settings'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../shared/api/errorHandler'
import { omit } from 'lodash'
import { whatsAppManagerApi } from '../../../core/http/WhatsAppManagerApi'

export const NotificationManagerView = () => {
  const [action, setAction] = useState<NOTIFICATION_MESSAGE_ACTION_ENUM | null>(null)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const { mode, id } = useParams()

  const isViewMode = useMemo(() => mode === 'view', [mode])
  const isEditMode = useMemo(() => mode === 'edit', [mode])

  const [updNotif, updNotifResp] = useUpdateNotificationMessageMutation()
  const [crNotif, crNotifResp] = useCreateNotificationMessageMutation()
  const { data, isFetching } = useGetNotificationMessageByIdQuery(id as string, { skip: !id })
  const { data: variables = [] } = useGetNotificationVariablesListQuery(
    action as NOTIFICATION_MESSAGE_ACTION_ENUM,
    { skip: !action }
  )
  const { data: profile, isFetching: isFetchingProfile } = useGetProfileQuery()

  const afterUpdate = () => {
    if (updNotifResp.data?.id) {
      dispatch(whatsAppManagerApi.util.invalidateTags(['NotButtons']))

      return navigate(
        `${SETTINGS_ENUM.VENDOR_MANAGEMENT}/notification-manager/view/${updNotifResp.data.id}`
      )
    }
  }

  const afterCreate = () => {
    if (crNotifResp.data?.id) {
      dispatch(whatsAppManagerApi.util.invalidateTags(['NotButtons']))

      return navigate(
        `${SETTINGS_ENUM.VENDOR_MANAGEMENT}/notification-manager/view/${crNotifResp.data.id}`
      )
    }
  }

  useNotification(NOTIFICATION_TYPES.success, updNotifResp.isSuccess, null, afterUpdate)
  useNotification(NOTIFICATION_TYPES.success, crNotifResp.isSuccess, null, afterCreate)
  useNotification(NOTIFICATION_TYPES.error, crNotifResp.isError, crNotifResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, updNotifResp.isError, updNotifResp.error as ErrorNode)

  const channelBlockTabs = useMemo(
    () =>
      (
        Object.keys(MESSAGE_AVAILABLE_CHANNEL_ENUM) as Array<
          keyof typeof MESSAGE_AVAILABLE_CHANNEL_ENUM
        >
      ).map((code) => MESSAGE_AVAILABLE_CHANNEL_ENUM[code]),
    []
  )

  const setDefaultFields = useCallback(() => {
    form.setFieldsValue({
      ...data,
      createdByUser: {
        name: data?.createdByUser?.name,
      },
      createdAt: formatDateWithTime(data?.createdAt, profile?.calendar),
      messages: [
        ...(data?.messages as IMessage[]),
        ...channelBlockTabs
          ?.filter((message) => !data?.messages?.find((m) => m.channel === message))
          .map((mm) => ({ channel: mm })),
      ],
    })
    setAction(data?.action as NOTIFICATION_MESSAGE_ACTION_ENUM)
  }, [channelBlockTabs, data, form, profile?.calendar])

  const onFinish = (values: INotificationMessage) => {
    const body = {
      name: values.name,
      transactionStatus: values.transactionStatus,
      receiver: values.receiver,
      action: values.action,
      messages: values?.messages
        ?.filter((message) => Object.keys(message).length >= 2)
        .map((filtMessage) => ({
          ...filtMessage,
          en: deleteHtmlTagsFromString(filtMessage?.en) || null,
          ps: deleteHtmlTagsFromString(filtMessage?.ps) || null,
          prs: deleteHtmlTagsFromString(filtMessage?.prs) || null,
        }))
        ?.filter((message) => {
          const parsedMessage = omit(message, 'channel')
          return !Object.values(parsedMessage)?.every((lang) => lang === null)
        }),
    } as INotificationManagerCreate

    return isEditMode ? updNotif({ id: id as string, body }) : crNotif(body)
  }

  const onActionChange = (val: NOTIFICATION_MESSAGE_ACTION_ENUM | null) => {
    setAction(val)
    form.setFieldsValue({
      messages: channelBlockTabs.map((block) => ({ channel: block })),
    })
  }

  const onCancelButtonClick = () => {
    if (isEditMode) {
      form.resetFields()
      setDefaultFields()
      return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/notification-manager/view/${id}`)
    }

    return navigate(SETTINGS_ENUM.VENDOR_MANAGEMENT)
  }

  const onSubmitButtonClick = () => {
    if (isViewMode) {
      return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/notification-manager/edit/${id}`)
    }

    form.submit()
  }

  useEffect(() => {
    if (data) {
      setDefaultFields()
      dispatch(setBreadcrumb(data))
    }
  }, [data, dispatch, setDefaultFields])

  useEffect(() => {
    if (!id && profile) {
      form.setFieldsValue({
        createdByUser: {
          name: `${profile?.firstName} ${profile?.lastName}`,
        },
        createdAt: formatDateWithTime(new Date().toISOString(), profile?.calendar),
        messages: channelBlockTabs.map((block) => ({ channel: block })),
      })
    }
  }, [channelBlockTabs, form, id, profile])

  const loading = isFetching || updNotifResp.isLoading || crNotifResp.isLoading || isFetchingProfile

  const content = (
    <div className={styles.layout}>
      <Form form={form} layout='vertical' className={styles.form} onFinish={onFinish}>
        <DetailInfo view={isViewMode} onChangeAction={onActionChange} />
        <ChannelBlock variables={variables} />
      </Form>
      <div className={styles.wrapper}>
        <Row className={styles.footer} justify='end'>
          <Space size={10}>
            <Button color='blue' size='middle' onClick={onCancelButtonClick}>
              Cancel
            </Button>
            <Button htmlType='submit' size='middle' type='primary' onClick={onSubmitButtonClick}>
              {isViewMode && 'Edit'}
              {isEditMode && 'Save Changes'}
              {!isViewMode && !isEditMode && 'Create Notification'}
            </Button>
          </Space>
        </Row>
      </div>
    </div>
  )

  return loading ? <Spin>{content}</Spin> : content
}
