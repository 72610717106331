import styles from './styles.module.scss'
import React, { useEffect, useMemo, useState } from 'react'
import { DashboardWidgets } from './Components/Widgets'
import { TopSalesPointTable } from './Components/TopSalesPointTable'
import { VendorBalance } from './Components/VendorBalance'
import { SalesSummaryBarChart } from './Components/SalesSummaryBarChart'
import { DashboardHeader } from './Components/DashboardHeader'
import { TopLocationsBlock } from './Components/TopLocationsBlock'
import { useGetProfileQuery } from '../../../Profile/core/http/Profile'
import { DASHBOARD_DATE_OPTIONS, WIDGET_LIST_ENUM } from '../../../../shared/models/WidgetListEnum'
import {
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  formatShamsiMomentToISO,
  getFrequency,
  moment,
} from '../../../../helpers/utils'
import { widgetsSocketConnection } from '../../../../shared/sockets'
import { IWidget } from '../../../../shared/models/IWidget'
import { IVendorBalanceTable } from '../../../Settings/models/IVendorManagement'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { ITopAgent } from '../../../salesNetwork/models/IAgent'
import { ISelectedFilters } from '../../../../shared/components/filter/Filter'
import { Moment } from 'moment'
import {
  ISalesByChannels,
  ISalesByMasterProducts,
  ISalesSummaryByVendors,
} from '../../../Reports/models/IExternalReport'
import { FailedTransactionsPieChart } from './Components/FailedTransactionsPieChart'
import { SalesChannelsPieChart } from './Components/SalesChannelsPieChart'
import { isEmpty } from 'lodash'
import {
  IFailedTransactionsByMasterProduct,
  ISalesSummaryByMasterProd,
} from '../../../Settings/models/IMasterProduct'
import ErrorBoundary from '../../../../shared/components/errorBoundary/ErrorBoundary'
import { ITopLocation } from '../../models/ITopLocation'
import { TotalSalesPieChart } from '../../../Reports/pages/Transactions/Components/TotalSalesPieChart'

export type DashboardWidgetStateType = Record<
  Exclude<
    WIDGET_LIST_ENUM,
    | WIDGET_LIST_ENUM.VENDOR_CURRENT_BALANCE
    | WIDGET_LIST_ENUM.TOP_AGENTS
    | WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS
    | WIDGET_LIST_ENUM.SALES_CHANNELS
    | WIDGET_LIST_ENUM.FAILED_TRANSACTIONS_BY_MASTER_PRODUCTS
    | WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS
    | WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT
    | WIDGET_LIST_ENUM.TOP_LOCATIONS
  >,
  {
    value: number
    statistic: number
    totalValue?: number
    valueTotal?: number
  }
> &
  Record<
    WIDGET_LIST_ENUM.VENDOR_CURRENT_BALANCE,
    {
      value: ITableResponse<IVendorBalanceTable>
    }
  > &
  Record<
    WIDGET_LIST_ENUM.TOP_AGENTS,
    {
      value: ITableResponse<ITopAgent>
    }
  > &
  Record<
    WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS,
    {
      value: ISalesSummaryByVendors[]
      frequency: 'day' | 'hour'
    }
  > &
  Record<
    WIDGET_LIST_ENUM.SALES_CHANNELS,
    {
      value: ISalesByChannels[]
    }
  > &
  Record<
    WIDGET_LIST_ENUM.FAILED_TRANSACTIONS_BY_MASTER_PRODUCTS,
    {
      value: IFailedTransactionsByMasterProduct[]
    }
  > &
  Record<
    WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT,
    {
      value: ISalesSummaryByMasterProd[]
      frequency: 'day' | 'hour'
    }
  > &
  Record<
    WIDGET_LIST_ENUM.TOP_LOCATIONS,
    {
      value: ITopLocation[]
    }
  > &
  Record<
    WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS,
    {
      value: ISalesByMasterProducts[]
    }
  >

export const DashboardOverview = () => {
  const [option, setOption] = useState(DASHBOARD_DATE_OPTIONS.TODAY)
  const [dateFilter, setDateFilter] = useState<string | null>(null)
  const [salesSummaryType, setSalesSummaryType] = useState<
    WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS | WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT
  >(WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT)
  const [topLocationsVendor, setTopLocationsVendor] = useState<number | null>(null)
  const [isLoadingTopLocations, setIsLoadingTopLocations] = useState(false)
  const [widgetDate, setWidgetDate] = useState<Record<WIDGET_LIST_ENUM, ISelectedFilters>>(
    {} as Record<WIDGET_LIST_ENUM, ISelectedFilters>
  )
  const { data: profile } = useGetProfileQuery()

  const [topAgentsFilterValues, setTopAgentsFilterValues] = useState<ISelectedFilters>({})

  const [widgetData, setWidgetData] = useState<DashboardWidgetStateType>(
    () =>
      ({
        [WIDGET_LIST_ENUM.TOTAL_SALES]: {
          value: 0,
          statistic: 0,
        },
        [WIDGET_LIST_ENUM.OPERATOR_BALANCES]: {
          value: 0,
          statistic: 0,
        },
        [WIDGET_LIST_ENUM.INVENTORY]: {
          value: 0,
          statistic: 0,
        },
        [WIDGET_LIST_ENUM.ACTIVE_LOCATIONS]: {
          value: 0,
          statistic: 0,
          totalValue: 0,
        },
        [WIDGET_LIST_ENUM.ACTIVE_AGENTS]: {
          value: 0,
          totalValue: 0,
          statistic: 0,
        },
        [WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS]: {
          value: [] as ISalesSummaryByVendors[],
          frequency: 'hour',
        },
        [WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT]: {
          value: [] as ISalesSummaryByMasterProd[],
          frequency: 'hour',
        },
        [WIDGET_LIST_ENUM.SALES_CHANNELS]: {
          value: [] as ISalesByChannels[],
        },
        [WIDGET_LIST_ENUM.FAILED_TRANSACTIONS_BY_MASTER_PRODUCTS]: {
          value: [] as IFailedTransactionsByMasterProduct[],
        },
        [WIDGET_LIST_ENUM.TOP_LOCATIONS]: {
          value: [] as ITopLocation[],
        },
        [WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS]: {
          value: [] as ISalesByMasterProducts[],
        },
      } as DashboardWidgetStateType)
  )

  const filters = useMemo(() => {
    const selectedDate = dateFilter
    if (option === DASHBOARD_DATE_OPTIONS.WEEK) {
      return {
        from: formatMomentDateToDayStart(moment(selectedDate).startOf('week'))?.toISOString(true),
        to: formatMomentDateToDayEnd(moment(selectedDate).endOf('week'))?.toISOString(true),
      }
    }
    if (option === DASHBOARD_DATE_OPTIONS.MONTH) {
      return {
        from: formatMomentDateToDayStart(moment(selectedDate).startOf('month'))?.toISOString(true),
        to: formatMomentDateToDayEnd(moment(selectedDate).endOf('month'))?.toISOString(true),
      }
    }
    if (option === DASHBOARD_DATE_OPTIONS.TODAY) {
      return {
        from: formatMomentDateToDayStart(moment(selectedDate))?.toISOString(true),
        to: formatMomentDateToDayEnd(moment(selectedDate))?.toISOString(true),
      }
    }
  }, [dateFilter, option])

  const onDateChange = (date: Moment | null) => {
    if (date) {
      setDateFilter(typeof date === 'string' ? date : date.toISOString(true))
    }
  }

  const onWidgetDateChange = (widgetName: WIDGET_LIST_ENUM) => (value: Moment[] | null) => {
    if (!Array.isArray(value)) {
      return setWidgetDate((prevState) => ({
        ...prevState,
        [widgetName]: null,
      }))
    }

    const from = !isEmpty(value[0])
      ? formatMomentDateToDayStart(
          formatShamsiMomentToISO(profile?.calendar, value[0])
        ).toISOString(true)
      : ''
    const to = !isEmpty(value[1])
      ? formatMomentDateToDayEnd(formatShamsiMomentToISO(profile?.calendar, value[1])).toISOString(
          true
        )
      : ''

    return setWidgetDate((prevState) => ({
      ...prevState,
      [widgetName]: { from, to },
    }))
  }

  useEffect(() => {
    if (!profile) return
    const socket = widgetsSocketConnection.getSocket()
    setIsLoadingTopLocations(true)
    const messagePayload = [
      {
        widget: WIDGET_LIST_ENUM.TOTAL_SALES,
        filters,
      },
      {
        widget: WIDGET_LIST_ENUM.OPERATOR_BALANCES,
        filters,
      },
      {
        widget: WIDGET_LIST_ENUM.ACTIVE_LOCATIONS,
        filters,
      },
      {
        widget: WIDGET_LIST_ENUM.ACTIVE_AGENTS,
        filters,
      },
      {
        widget: WIDGET_LIST_ENUM.VENDOR_CURRENT_BALANCE,
      },
      {
        widget: WIDGET_LIST_ENUM.INVENTORY,
        filters,
      },
      {
        widget: salesSummaryType,
        filters: {
          ...filters,
          ...(filters?.from &&
            filters?.to && { frequency: getFrequency(filters.from, filters?.to) }),
        },
      },
      {
        widget: WIDGET_LIST_ENUM.TOP_AGENTS,
        filters: {
          ...(widgetDate[WIDGET_LIST_ENUM.TOP_AGENTS] ?? filters),
          ...topAgentsFilterValues,
        },
      },
      {
        widget: WIDGET_LIST_ENUM.SALES_CHANNELS,
        filters: widgetDate[WIDGET_LIST_ENUM.SALES_CHANNELS] ?? filters,
      },
      {
        widget: WIDGET_LIST_ENUM.FAILED_TRANSACTIONS_BY_MASTER_PRODUCTS,
        filters: widgetDate[WIDGET_LIST_ENUM.FAILED_TRANSACTIONS_BY_MASTER_PRODUCTS] ?? filters,
      },
      {
        widget: WIDGET_LIST_ENUM.TOP_LOCATIONS,
        filters: {
          ...(widgetDate[WIDGET_LIST_ENUM.TOP_LOCATIONS] ?? filters),
          ...(topLocationsVendor && { vendorId: topLocationsVendor }),
        },
      },
      {
        widget: WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS,
        filters: widgetDate[WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS] ?? filters,
      },
    ]

    socket?.emit('connect-widget', { data: messagePayload })

    return () => {
      socket?.emit('disconnect-widget', { data: messagePayload })
    }
  }, [
    filters,
    option,
    profile,
    salesSummaryType,
    topAgentsFilterValues,
    topLocationsVendor,
    widgetDate,
  ])

  useEffect(() => {
    if (!profile) return
    const socket = widgetsSocketConnection.getSocket()
    socket?.on('widget-data', (data: IWidget) => {
      const balance = JSON.parse(data?.data?.jsonData)

      setWidgetData((prevState) => ({
        ...prevState,
        [data.widget]:
          Object.keys(balance).length === 1
            ? {
                value: balance.value,
                ...(data['filters']?.frequency && { frequency: data['filters']?.frequency }),
              }
            : balance,
      }))
      setIsLoadingTopLocations(false)
    })
  }, [profile])

  useEffect(() => {
    if (profile?.timeZone) {
      moment.tz?.setDefault(profile?.timeZone as string)

      const date = formatShamsiMomentToISO(profile?.calendar, moment())
      setDateFilter(typeof date === 'string' ? date : date.toISOString(true))
    }
  }, [profile])

  const frequency = useMemo(
    () =>
      salesSummaryType === WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT
        ? widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT]?.frequency
        : widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS]?.frequency,
    [salesSummaryType, widgetData]
  )

  const topLocationsMap: Omit<ITopLocation, 'id'>[] = useMemo(() => {
    const transformedArray = []
    const locationMap: Record<string, Omit<ITopLocation, 'id'>> = {}

    widgetData[WIDGET_LIST_ENUM.TOP_LOCATIONS].value.forEach((location) => {
      const provinceId = location.province.id

      if (!locationMap[provinceId]) {
        locationMap[provinceId] = {
          province: location.province,
          district: location.district,
          activeAgentsCount: 0,
          salesAmount: 0,
        }
      }

      locationMap[provinceId].activeAgentsCount += location.activeAgentsCount
      locationMap[provinceId].salesAmount += location.salesAmount
    })

    for (const provinceId in locationMap) {
      transformedArray.push(locationMap[provinceId])
    }

    return transformedArray
  }, [widgetData])

  return (
    <div className={styles.layout}>
      <DashboardHeader
        option={option}
        setOption={setOption}
        onDateChange={onDateChange}
        dateFilter={dateFilter}
      />
      <DashboardWidgets widgetData={widgetData} />
      <div className={styles.pieChartsWrapper}>
        <SalesChannelsPieChart
          widgetData={widgetData[WIDGET_LIST_ENUM.SALES_CHANNELS].value}
          onWidgetDateChange={onWidgetDateChange(WIDGET_LIST_ENUM.SALES_CHANNELS)}
        />
        <FailedTransactionsPieChart
          onWidgetDateChange={onWidgetDateChange(
            WIDGET_LIST_ENUM.FAILED_TRANSACTIONS_BY_MASTER_PRODUCTS
          )}
          widgetData={widgetData[WIDGET_LIST_ENUM.FAILED_TRANSACTIONS_BY_MASTER_PRODUCTS].value}
        />
        <TotalSalesPieChart
          onWidgetDateChange={onWidgetDateChange(WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS)}
          data={widgetData[WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS].value}
        />
      </div>
      <div className={styles.tableList}>
        <TopSalesPointTable
          data={widgetData[WIDGET_LIST_ENUM.TOP_AGENTS]?.value}
          setFilterValues={setTopAgentsFilterValues}
          onWidgetDateChange={onWidgetDateChange(WIDGET_LIST_ENUM.TOP_AGENTS)}
        />
        <VendorBalance data={widgetData[WIDGET_LIST_ENUM.VENDOR_CURRENT_BALANCE]?.value} />
      </div>
      <ErrorBoundary>
        <SalesSummaryBarChart
          salesSummaryType={salesSummaryType}
          setSalesSummaryType={setSalesSummaryType}
          frequency={frequency}
          byVendors={widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY_BY_VENDORS]?.value}
          byMasters={widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY_BY_MASTER_PRODUCT]?.value}
        />
      </ErrorBoundary>
      <TopLocationsBlock
        widgetData={topLocationsMap}
        onWidgetDateChange={onWidgetDateChange(WIDGET_LIST_ENUM.TOP_LOCATIONS)}
        setTopLocationsVendor={setTopLocationsVendor}
        isLoadingTopLocations={isLoadingTopLocations}
        shouldDisplayProvince
      />
    </div>
  )
}
