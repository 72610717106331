import React, { useCallback, useEffect } from 'react'
import ChatroomMessaging from './Components/ChatroomMessaging'
import ChatroomListOfChats from './Components/ChatroomListOfChats'
import ChatroomInfo from './Components/ChatroomDetails'
import styles from './styles.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import {
  addExternalMessagesAfter,
  addReactionToExternalMessage,
  deleteReactionToExternalMessage,
  selectExternalChatMessagingSlice,
  setCurrentExternalUserId,
} from '../../core/store/ExternalChatMessagingSlice'
import { useGetProfileQuery } from '../../../Profile/core/http/Profile'
import {
  archiveExternalChat,
  clearExternalChatList,
  selectExternalChatChatsSlice,
  setActiveTab,
  setCurrentExternalChatId,
  setExternalChat,
  updateExternalChatCounter,
  updateExternalChatLastMessage,
  updateExternalChatLastReadingAt,
  updateExternalChatTypingStatus,
} from '../../core/store/ExternalChatChatsListSlice'
import { externalChatSocketConnection } from '../../../../shared/sockets'
import {
  EXTERNAL_CHAT_LIST_TABS,
  EXTERNAL_CHAT_WS_EVENTS_ENUM,
  IAddReactionResp,
  IExtChatWSReadingEvent,
  ILastExternalChatMessage,
} from '../../models/IExternalChat'
import { externalChatApi } from '../../core/http/ExternalChatApi'

export const SupportChatroom = () => {
  const dispatch = useAppDispatch()
  const { selectedChatId, activeTab } = useAppSelector(selectExternalChatChatsSlice)
  const { currentUserId } = useAppSelector(selectExternalChatMessagingSlice)
  const { data: userProfile } = useGetProfileQuery()

  const handleWSMessageEvent = useCallback(
    (data: ILastExternalChatMessage) => {
      if (data?.chatId === selectedChatId && data?.createdByUserId !== currentUserId) {
        dispatch(addExternalMessagesAfter([data]))
      }

      if (data?.createdByUserId !== currentUserId) {
        dispatch(updateExternalChatLastMessage({ selectedChatId: data?.chatId as number, data }))
      }
    },
    [currentUserId, dispatch, selectedChatId]
  )

  const handleConnectUserToTheChat = useCallback(
    (data: Omit<IExtChatWSReadingEvent, 'lastReadingAt'>) => {
      if (
        currentUserId === Number(data.userId) &&
        Number(selectedChatId) === Number(data.chatId) &&
        activeTab !== EXTERNAL_CHAT_LIST_TABS.MY
      ) {
        dispatch(clearExternalChatList())
        dispatch(setActiveTab(EXTERNAL_CHAT_LIST_TABS.MY))
      }
      dispatch(
        externalChatApi.util.invalidateTags([{ type: 'IExternalChatById', id: data.chatId }])
      )
    },
    [activeTab, currentUserId, dispatch, selectedChatId]
  )

  const handleRemoveUserFromTheChat = useCallback(
    (data: Omit<IExtChatWSReadingEvent, 'lastReadingAt'>) => {
      if (
        currentUserId === Number(data.userId) &&
        Number(selectedChatId) === Number(data.chatId) &&
        activeTab !== EXTERNAL_CHAT_LIST_TABS.ARCHIVED
      ) {
        dispatch(clearExternalChatList())
        dispatch(setActiveTab(EXTERNAL_CHAT_LIST_TABS.ALL))
      }
      dispatch(
        externalChatApi.util.invalidateTags([{ type: 'IExternalChatById', id: data.chatId }])
      )
    },
    [activeTab, currentUserId, dispatch, selectedChatId]
  )

  const handleWSReadingEvent = useCallback(
    (data: IExtChatWSReadingEvent) => {
      if (data.userId) return
      dispatch(updateExternalChatLastReadingAt(data))
    },
    [dispatch]
  )

  const handleTypingEvent = useCallback(
    (data: { chatId: number; userId: number }) => {
      if (currentUserId !== Number(data.userId)) {
        dispatch(updateExternalChatTypingStatus({ ...data, status: true }))
      }
    },
    [currentUserId, dispatch]
  )

  const handleSessionEnd = useCallback(
    (data: { chatId: number }) => {
      if (activeTab !== EXTERNAL_CHAT_LIST_TABS.ARCHIVED) {
        dispatch(archiveExternalChat(data.chatId))
      }

      dispatch(
        externalChatApi.util.invalidateTags([
          { type: 'IExternalChatById', id: data.chatId },
          'IExternalChatList',
        ])
      )

      if (selectedChatId === data.chatId && activeTab !== EXTERNAL_CHAT_LIST_TABS.ARCHIVED) {
        dispatch(setActiveTab(EXTERNAL_CHAT_LIST_TABS.ARCHIVED))
      }
    },
    [activeTab, dispatch, selectedChatId]
  )

  const handleArchiveExtChat = useCallback(
    (data: { chatIds: Array<number> }) => {
      if (!data?.chatIds?.length) return

      const chatId = data?.chatIds[0]
      if (activeTab !== EXTERNAL_CHAT_LIST_TABS.ARCHIVED) {
        dispatch(archiveExternalChat(Number(chatId)))
      }

      dispatch(
        externalChatApi.util.invalidateTags([
          { type: 'IExternalChatById', id: chatId },
          'IExternalChatList',
        ])
      )

      if (
        Number(selectedChatId) === Number(chatId) &&
        activeTab !== EXTERNAL_CHAT_LIST_TABS.ARCHIVED
      ) {
        dispatch(setActiveTab(EXTERNAL_CHAT_LIST_TABS.ARCHIVED))
      }
    },
    [activeTab, dispatch, selectedChatId]
  )

  const handleSessionStart = useCallback(
    (data: { chatId: number }) => {
      if (activeTab !== EXTERNAL_CHAT_LIST_TABS.MY) {
        dispatch(archiveExternalChat(data.chatId))
      }
      dispatch(
        externalChatApi.util.invalidateTags([
          { type: 'IExternalChatById', id: data.chatId },
          'IExternalChatList',
        ])
      )
      if (selectedChatId === data.chatId && activeTab !== EXTERNAL_CHAT_LIST_TABS.MY) {
        dispatch(setCurrentExternalChatId(null))
        dispatch(setExternalChat(null))
      }
    },
    [activeTab, dispatch, selectedChatId]
  )

  const handleAddReaction = useCallback(
    (data: IAddReactionResp) => {
      if (selectedChatId !== +data?.chatId) return
      dispatch(addReactionToExternalMessage(data))
    },
    [dispatch, selectedChatId]
  )

  const handleDeleteReaction = useCallback(
    (data: IAddReactionResp) => {
      if (selectedChatId !== +data?.chatId) return
      dispatch(deleteReactionToExternalMessage(data))
    },
    [dispatch, selectedChatId]
  )

  const handleUpdateChatCounter = useCallback(
    (data: { chatId: number; count: number }) => {
      dispatch(updateExternalChatCounter(data))
    },
    [dispatch]
  )

  useEffect(() => {
    const connection = externalChatSocketConnection.getSocket()

    connection?.on(EXTERNAL_CHAT_WS_EVENTS_ENUM.MESSAGE, handleWSMessageEvent)
    connection?.on(EXTERNAL_CHAT_WS_EVENTS_ENUM.READING, handleWSReadingEvent)
    connection?.on(EXTERNAL_CHAT_WS_EVENTS_ENUM.TYPING, handleTypingEvent)
    connection?.on(EXTERNAL_CHAT_WS_EVENTS_ENUM.END_SESSION, handleSessionEnd)
    connection?.on(EXTERNAL_CHAT_WS_EVENTS_ENUM.START_SESSION, handleSessionStart)
    connection?.on(EXTERNAL_CHAT_WS_EVENTS_ENUM.ADD_MEMBER, handleConnectUserToTheChat)
    connection?.on(EXTERNAL_CHAT_WS_EVENTS_ENUM.REMOVE_MEMBER, handleRemoveUserFromTheChat)
    connection?.on(EXTERNAL_CHAT_WS_EVENTS_ENUM.ARCHIVE_CHAT, handleArchiveExtChat)
    connection?.on(EXTERNAL_CHAT_WS_EVENTS_ENUM.UN_ARCHIVE_CHAT, handleSessionStart)
    connection?.on(EXTERNAL_CHAT_WS_EVENTS_ENUM.ADD_REACTION, handleAddReaction)
    connection?.on(EXTERNAL_CHAT_WS_EVENTS_ENUM.DELETE_REACTION, handleDeleteReaction)
    connection?.on(EXTERNAL_CHAT_WS_EVENTS_ENUM.UPDATE_CHAT_COUNTER, handleUpdateChatCounter)

    return () => {
      connection?.removeListener(EXTERNAL_CHAT_WS_EVENTS_ENUM.MESSAGE, handleWSMessageEvent)
      connection?.removeListener(EXTERNAL_CHAT_WS_EVENTS_ENUM.READING, handleWSReadingEvent)
      connection?.removeListener(EXTERNAL_CHAT_WS_EVENTS_ENUM.TYPING, handleTypingEvent)
      connection?.removeListener(EXTERNAL_CHAT_WS_EVENTS_ENUM.END_SESSION, handleSessionEnd)
      connection?.removeListener(EXTERNAL_CHAT_WS_EVENTS_ENUM.START_SESSION, handleSessionStart)
      connection?.removeListener(
        EXTERNAL_CHAT_WS_EVENTS_ENUM.ADD_MEMBER,
        handleConnectUserToTheChat
      )
      connection?.removeListener(EXTERNAL_CHAT_WS_EVENTS_ENUM.ARCHIVE_CHAT, handleArchiveExtChat)
      connection?.removeListener(
        EXTERNAL_CHAT_WS_EVENTS_ENUM.REMOVE_MEMBER,
        handleRemoveUserFromTheChat
      )
      connection?.removeListener(EXTERNAL_CHAT_WS_EVENTS_ENUM.UN_ARCHIVE_CHAT, handleSessionStart)
      connection?.removeListener(EXTERNAL_CHAT_WS_EVENTS_ENUM.ADD_REACTION, handleAddReaction)
      connection?.removeListener(EXTERNAL_CHAT_WS_EVENTS_ENUM.DELETE_REACTION, handleDeleteReaction)
      connection?.removeListener(
        EXTERNAL_CHAT_WS_EVENTS_ENUM.UPDATE_CHAT_COUNTER,
        handleUpdateChatCounter
      )
    }
  }, [
    handleAddReaction,
    handleArchiveExtChat,
    handleConnectUserToTheChat,
    handleDeleteReaction,
    handleRemoveUserFromTheChat,
    handleSessionEnd,
    handleSessionStart,
    handleTypingEvent,
    handleUpdateChatCounter,
    handleWSMessageEvent,
    handleWSReadingEvent,
  ])

  useEffect(() => {
    if (userProfile) {
      dispatch(setCurrentExternalUserId(userProfile?.id))
    }
  }, [dispatch, userProfile])

  return (
    <div className={styles.chatRoomLayout}>
      <div className={styles.externalChatContainer}>
        <ChatroomListOfChats />
        {selectedChatId ? (
          <>
            <ChatroomMessaging />
            <ChatroomInfo />
          </>
        ) : (
          <div className={styles.emptyChatContainer}>Select chat to start conversation.</div>
        )}
      </div>
    </div>
  )
}
