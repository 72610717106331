import FormList from 'antd/es/form/FormList'
import React, { useMemo } from 'react'
import FormItem from 'antd/es/form/FormItem'
import { Form, FormInstance, Row, Select } from 'antd'
import { Wrapper } from '../../../../../../../../salesNetwork/components/Wrapper'
import { IViewMode } from '../../../../../../../../Finance/models/IViewMode'
import {
  AddIcon,
  BucketIcon,
  CopyIcon,
  EmailFilledIcon,
  EmailRedIcon,
  FacebookMessengerIcon2,
  PhoneLightBlueIcon,
  PhoneRedIcon,
  SkypeIcon,
  TelegramIcon,
  ViberIcon,
  WhatsappIcon,
} from '../../../../../../../../../assets/svg'
import { CONTACT_TYPE_ENUM } from '../../../../../../../../../shared/models/Contact'
import { TextField } from '../../../../../../../../../shared/components/textfield/TextField'
import styles from '../../UsersManagementView/styles.module.scss'
import { IconButton } from '../../../../../../../../../shared/components/iconButton/IconButton'
import classNames from 'classnames'
import { startCase } from 'lodash'
import * as V from '../../../../../../../../../helpers/validation'
import { IContactChanel, IUserIdentifiers } from '../../../../../../../models/IUser'
import {
  ErrorNode,
  handleError,
  handleInfo,
} from '../../../../../../../../../shared/api/errorHandler'
import { PhoneInputWithCountry } from '../../../../../../../../../shared/components/PhoneInput'

interface IProps extends IViewMode {
  form: FormInstance
  onContactDelete?: (id: number) => void
}

export const ContactInfo = ({ view, form, onContactDelete }: IProps) => {
  const handleDeleteContact = (index: number) => {
    if (onContactDelete) {
      const deletedContact = form.getFieldValue('contactChannels') as Array<IContactChanel>

      if (deletedContact[index] && Object.hasOwn(deletedContact[index], 'id')) {
        const contactId = deletedContact[index].id
        return onContactDelete(contactId!)
      }
    }
    return
  }

  return (
    <Wrapper title='Contact Information' className={styles.wrapperBlock}>
      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const hasEmailIdentifier = (
            (getFieldValue('contactChannels') as IUserIdentifiers[]) || []
          ).some((ident) => ident?.type === CONTACT_TYPE_ENUM.EMAIL_IDENTITY)
          return (
            <FormList
              name='contactChannels'
              rules={[
                {
                  validator: () => {
                    if (hasEmailIdentifier) {
                      return Promise.resolve()
                    }
                    return Promise.reject('Email Identifier is required')
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} style={{ display: 'flex', gridGap: 24 }}>
                      <ContactItem
                        form={form}
                        name={name}
                        view={view as boolean}
                        remove={remove}
                        handleDeleteContact={handleDeleteContact}
                        {...restField}
                      />
                    </div>
                  ))}
                  {!view && (
                    <Row>
                      <Form.Item className={classNames(styles.noBottomMargin, styles.addButton)}>
                        <IconButton icon={<AddIcon />} color='orange' block onClick={() => add()} />
                      </Form.Item>
                    </Row>
                  )}
                  <div className={styles.errorsList}>
                    {errors?.map((error, i) => (
                      <div key={i}>{error}</div>
                    ))}
                  </div>
                </>
              )}
            </FormList>
          )
        }}
      </FormItem>
    </Wrapper>
  )
}

const ContactItem = ({
  name,
  view,
  remove,
  form,
  handleDeleteContact,
  ...restField
}: {
  name: number
  view: boolean
  remove: (index: number | number[]) => void
  handleDeleteContact: (index: number) => void
  form: FormInstance
}) => {
  const contactTypes = useMemo(
    () => [
      { type: CONTACT_TYPE_ENUM.EMAIL, icon: <EmailFilledIcon /> },
      { type: CONTACT_TYPE_ENUM.EMAIL_IDENTITY, icon: <EmailRedIcon /> },
      { type: CONTACT_TYPE_ENUM.MOBILE, icon: <PhoneLightBlueIcon /> },
      { type: CONTACT_TYPE_ENUM.MOBILE_IDENTITY, icon: <PhoneRedIcon /> },
      { type: CONTACT_TYPE_ENUM.WHATSAPP, icon: <WhatsappIcon /> },
      {
        type: CONTACT_TYPE_ENUM.FACEBOOK,
        icon: <FacebookMessengerIcon2 />,
      },
      { type: CONTACT_TYPE_ENUM.TELEGRAM, icon: <TelegramIcon /> },
      { type: CONTACT_TYPE_ENUM.VIBER, icon: <ViberIcon /> },
      { type: CONTACT_TYPE_ENUM.SKYPE, icon: <SkypeIcon /> },
    ],
    []
  )
  const REQUIRED_OBJ_FIELD = useMemo(() => ({ required: true, message: 'Field required' }), [])

  const rulesObj = useMemo(
    () => ({
      [CONTACT_TYPE_ENUM.EMAIL]: [REQUIRED_OBJ_FIELD, { validator: V.emailValidator }],
      [CONTACT_TYPE_ENUM.EMAIL_IDENTITY]: [REQUIRED_OBJ_FIELD, { validator: V.emailValidator }],
      [CONTACT_TYPE_ENUM.FACEBOOK]: [
        REQUIRED_OBJ_FIELD,
        { validator: V.minMaxLengthValidator(2, 50) },
      ],
      [CONTACT_TYPE_ENUM.TELEGRAM]: [
        REQUIRED_OBJ_FIELD,
        { validator: V.minMaxLengthValidator(2, 50) },
      ],
      [CONTACT_TYPE_ENUM.WHATSAPP]: [
        REQUIRED_OBJ_FIELD,
        { validator: V.mobileNumberLengthFrom8To14 },
      ],
      [CONTACT_TYPE_ENUM.SKYPE]: [
        REQUIRED_OBJ_FIELD,
        { validator: V.commonValidator },
        { validator: V.minMaxLengthValidator(2, 50) },
      ],
      [CONTACT_TYPE_ENUM.MOBILE_IDENTITY]: [
        REQUIRED_OBJ_FIELD,
        { validator: V.mobileNumberLengthFrom8To14 },
      ],
      [CONTACT_TYPE_ENUM.MOBILE]: [
        REQUIRED_OBJ_FIELD,
        { validator: V.mobileNumberLengthFrom8To14 },
      ],
      [CONTACT_TYPE_ENUM.VIBER]: [REQUIRED_OBJ_FIELD, { validator: V.mobileNumberLengthFrom8To14 }],
    }),
    [REQUIRED_OBJ_FIELD]
  )

  type rulesType = keyof typeof rulesObj

  const onCopyText = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        handleInfo('Text copied')
      })
      .catch((error) => handleError(error as ErrorNode))
  }

  return (
    <>
      <FormItem {...restField} name={[name, 'type']} style={{ flexBasis: '30%', minWidth: 40 }}>
        <Select
          disabled={view}
          onChange={() => {
            form.resetFields([['contactChannels', name, 'value']])
            form.validateFields(['contactChannels'])
          }}
          allowClear
          placeholder='Select Contact Information'
        >
          {contactTypes.map(({ type, icon }) => {
            return (
              <Select.Option key={type} value={type}>
                <div className={styles.wrapperContentOption}>
                  <div className={classNames(styles.wrapperSelectIcon)}>{icon}</div>
                  <div>{startCase(CONTACT_TYPE_ENUM[type].toLowerCase())}</div>
                </div>
              </Select.Option>
            )
          })}
        </Select>
      </FormItem>

      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const selectedType = getFieldValue(['contactChannels', name, 'type']) as rulesType
          const isMobile =
            selectedType === CONTACT_TYPE_ENUM.MOBILE ||
            selectedType === CONTACT_TYPE_ENUM.MOBILE_IDENTITY ||
            selectedType === CONTACT_TYPE_ENUM.WHATSAPP ||
            selectedType === CONTACT_TYPE_ENUM.VIBER ||
            selectedType === CONTACT_TYPE_ENUM.TELEGRAM

          return isMobile ? (
            <Form.Item
              {...restField}
              name={[name, 'value']}
              style={{ flexBasis: '65%' }}
              rules={rulesObj[selectedType]}
            >
              <PhoneInputWithCountry
                fieldName={['contactChannels', name, 'value']}
                form={form}
                view={view}
              />
            </Form.Item>
          ) : (
            <Form.Item
              {...restField}
              name={[name, 'value']}
              style={{ flexBasis: '65%' }}
              rules={rulesObj[selectedType]}
            >
              <TextField disabled={view} />
            </Form.Item>
          )
        }}
      </FormItem>

      {view && (
        <div className={styles['wrapper-icon']}>
          <IconButton
            onClick={() => onCopyText(form.getFieldValue(['contactChannels', name, 'value']))}
            slim
            size='large'
            color='orange'
            icon={<CopyIcon />}
            type='default'
          />
        </div>
      )}

      {!view && (
        <div className={styles['wrapper-icon']}>
          <IconButton
            slim
            size='large'
            color='orange'
            icon={<BucketIcon />}
            type='default'
            disabled={view}
            onClick={() => {
              handleDeleteContact(name)
              remove(name)
            }}
          />
        </div>
      )}
    </>
  )
}
