import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { SortingOrderEnum } from 'src/shared/models/ITableConf'
import { baseQueryWithReauth } from '../../../shared/api'
import { ITableResponse } from '../../../shared/models/ITableResponse'
import {
  ITrustWalletTransactionSub,
  TRANSACTION_CATEGORY_ENUM,
} from '../../Finance/models/ITransaction'

interface IConstraint {
  transactionCategory: TRANSACTION_CATEGORY_ENUM
  id: number | string
}

type ReqParam<T extends IConstraint> = T

export const subTransactionsApi = createApi({
  reducerPath: 'subTransactionsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['ITrustWalletTransactionSub'],
  endpoints: (builder) => ({
    getSubTransactions: builder.query<
      ITableResponse<ITrustWalletTransactionSub>,
      ReqParam<IConstraint>
    >({
      query: ({ transactionCategory, id }) => {
        const params = { orderType: SortingOrderEnum.DESC, orderField: 'createdAt' }
        switch (transactionCategory) {
          case TRANSACTION_CATEGORY_ENUM.TRANSFER_FUND:
            return { url: `wallet-transfer-fund/${id}/sub-transaction`, params }
          case TRANSACTION_CATEGORY_ENUM.PURCHASE_ORDER:
            return { url: `purchase-order/${id}/sub-transaction`, params }
          case TRANSACTION_CATEGORY_ENUM.PICKUP:
            return { url: `pickup-order/${id}/sub-transaction`, params }
          case TRANSACTION_CATEGORY_ENUM.REFUND:
            return { url: `refund-request/${id}/sub-transaction`, params }
          case TRANSACTION_CATEGORY_ENUM.REVERSE:
            return { url: `reverse-topup/${id}/sub-transaction`, params }
          case TRANSACTION_CATEGORY_ENUM.TOPUP:
            return { url: `topup/${id}/sub-transaction`, params }
          case TRANSACTION_CATEGORY_ENUM.ADJUSTMENT:
            return { url: `adjustment/${id}/sub-transaction`, params }
          case TRANSACTION_CATEGORY_ENUM.PROMOTION:
            return { url: `agent-promotion/${id}/sub-transaction`, params }
          case TRANSACTION_CATEGORY_ENUM.SEND_EMONEY:
            return { url: `send-emoney/${id}/sub-transaction`, params }
          default:
            return { url: '' }
        }
      },
    }),
    getSubTransactionById: builder.query<ITrustWalletTransactionSub, number>({
      query: (id: number) => ({
        url: `sub-transactions/${id}`,
      }),
      providesTags: (transaction) => [{ type: 'ITrustWalletTransactionSub', id: transaction?.id }],
    }),
  }),
})

export const {
  useGetSubTransactionsQuery,
  useLazyGetSubTransactionsQuery,
  useGetSubTransactionByIdQuery,
} = subTransactionsApi
