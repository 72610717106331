import { Image, notification, Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { FullScreenIcon } from 'src/assets/svg'
import styles from '../styles.module.scss'
import { convertHeicToJpeg } from '../../../../../../../../helpers/utils'

interface IProps {
  src: string
  extension: string
}

export const ImageViewer = ({ src, extension }: IProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState<string | undefined>()
  const [isPreviewVisible, setIsPreviewVisible] = useState(false)

  const loadImage = useCallback(async () => {
    setIsLoading(true)
    const link = await convertHeicToJpeg(src, extension)
    setImage(link)
    setIsLoading(false)
  }, [extension, src])

  useEffect(() => {
    loadImage()
  }, [loadImage])

  const content = (
    <Image
      width='100%'
      height={550}
      className={styles.image}
      src={image}
      preview={{
        visible: isPreviewVisible,
        maskClassName: styles.imagePreview,
        mask: (
          <span onClick={() => setIsPreviewVisible(true)}>
            <FullScreenIcon />
          </span>
        ),
        onVisibleChange: (value) => {
          setIsPreviewVisible(value)
        },
      }}
      onError={() => {
        notification.open({ type: 'error', message: 'Failed to load image' })
      }}
    />
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
