import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import { useGetScratchCardListQuery } from '../../../../../core/http/EPinApi'
import { TextFieldSearch } from '../../../../../../../shared/components/textFieldSearch/TextFieldSearch'
import styles from '../../styles.module.scss'
import { useEffect, useState } from 'react'
import useSearch from '../../../../../../Settings/helpers/useSearch'
import { ITableConf } from '../../../../../../../shared/models/ITableConf'
import { IScratchCardInventory } from '../../../../../models/IEPin'
import { isEmpty, startCase } from 'lodash'
import { Spin } from 'antd'
import { maskStringByAsterisk } from '../../../../../../../helpers/utils'

export const ScratchCardsList = () => {
  const [data, setData] = useState<IScratchCardInventory[]>([])
  const [tableConf, setTableConf] = useState<ITableConf>({
    search: '',
  })
  const { data: scratchList, isFetching } = useGetScratchCardListQuery(tableConf, {
    skip: !tableConf.search,
  })

  const { handleSearchChange } = useSearch(setTableConf)

  useEffect(() => {
    if (isEmpty(scratchList)) return
    setData(scratchList.items)
  }, [scratchList])

  useEffect(() => {
    if (tableConf.search) return
    setData([])
  }, [tableConf.search])

  return (
    <Wrapper title='Search Pin'>
      <TextFieldSearch className={styles.search} onChange={handleSearchChange} />
      <Spin spinning={isFetching}>
        <div className={styles.scratchCardsList}>
          {data.map((card) => (
            <div key={card.id} className={styles.scratchCardsListItem}>
              <span>{maskStringByAsterisk(card.serialNumber, 4)}</span>
              <span>{startCase(card.status.toLowerCase())}</span>
            </div>
          ))}
        </div>
      </Spin>
    </Wrapper>
  )
}
