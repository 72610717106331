/* eslint-disable */
import React, { useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { IInternalChatMessageFile } from '../../../../models'
import { InfiniteScrollComponent } from '../../../../../../shared/components/infiniteScoll/InfiniteScroll'
import styles from '../FilesList/styles.module.scss'
import { CloseIcon, DownloadIcon } from '../../../../../../assets/svg'
import { NotFoundPage } from '../../../../../NotFoundPage'
import { IExternalChatMessageFile } from '../../../../../Support/models/IExternalChat'

interface IProps {
  files: Array<IInternalChatMessageFile>
  totalCount: number
  loadMoreData: () => void
}

const ImagesList = ({ files, totalCount, loadMoreData }: IProps) => {
  const [currentImage, setCurrentImage] = useState(0)
  const [currentOpenMonth, setCurrentOpenMonth] = useState('')
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((index: number, month: string) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
    setCurrentOpenMonth(month)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
    setCurrentOpenMonth('')
  }

  const itemsMap = useMemo(() => {
    const a: { [key: string]: Array<IInternalChatMessageFile> } = {}
    files.forEach((el) => {
      const key = moment(el.createdAt).format('YYYY-MM')
      if (a[key] && Array.isArray(a[key])) {
        a[key].push(el)
      } else {
        a[key] = [el]
      }
    })
    return a
  }, [files])

  const onPressDownloadImage = (link: string, fileName: string) => {
    if (link) {
      const element = document.createElement('a')
      element.setAttribute('href', link)
      element.setAttribute('download', fileName)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
  }

  const onViewChange = (id: number) => setCurrentImage(id)

  return (
    <div className={styles.filesContainer}>
      {files.length ? (
        <InfiniteScrollComponent
          loadMoreData={loadMoreData}
          dataLength={files.length}
          totalCount={totalCount}
          endMessage=''
          showDivider={false}
        >
          {Object.keys(itemsMap).map((key: string) => (
            <div key={key} className={styles.imagesMonthContainer}>
              <div className={styles.imageDateDivider}>{moment(key).format('MMMM')}</div>
              <div className={styles.monthImages}>
                {itemsMap[key].map(({ file }, i) => (
                  <div key={file.id}>
                    <div className={styles.imageContainer} onClick={() => openLightbox(i, key)}>
                      <img src={file.link} />
                    </div>
                  </div>
                ))}
              </div>
              {/* @ts-ignore */}
              <ModalGateway>
                {viewerIsOpen && key === currentOpenMonth ? (
                  <Modal onClose={closeLightbox}>
                    <div>
                      <div className={styles.topLineContainer}>
                        <div
                          className={styles.topLineIcon}
                          onClick={() =>
                            onPressDownloadImage(
                              files[currentImage].file.link,
                              files[currentImage].file.name as string
                            )
                          }
                        >
                          <DownloadIcon color='#FFFFFF' />
                        </div>
                        <div className={styles.topLineIcon} onClick={closeLightbox}>
                          <CloseIcon color='#FFFFFF' />
                        </div>
                      </div>
                      <Carousel
                        currentIndex={currentImage}
                        views={itemsMap[key].map((x) => ({
                          ...x.file,
                          source: x.file.link,
                        }))}
                        trackProps={{ onViewChange }}
                      />
                    </div>
                  </Modal>
                ) : null}
              </ModalGateway>
            </div>
          ))}
        </InfiniteScrollComponent>
      ) : (
        <NotFoundPage text='Empty data' />
      )}
    </div>
  )
}

export default ImagesList
