import styles from './styles.module.scss'
import { useNavigate, useParams } from 'react-router'
import { useGetListOfCurrenciesQuery } from '../../../../Finance/core/http/BankAccountApi'
import React, { useEffect, useMemo } from 'react'
import { Form, Row, Space, Spin } from 'antd'
import { Button } from '../../../../../shared/components/button/Button'
import { SALES_ENUM } from '../../../../../routes/sales'
import {
  ICustomerBankAccountInfo,
  ICustomerWalletInfo,
  MOBILE_WALLET_PROVIDER_ENUM,
} from '../../../models/ICustomer'
import { useGetBankListQuery } from '../../../../Finance/core/http/BankApi'
import {
  useGetAgentBankAccountsByIdQuery,
  useGetAgentWalletAccountAccountsByIdQuery,
  useUpdateAgentBankAccountMutation,
  useUpdateAgentWalletAccountMutation,
} from '../../../core/http/AgentManagement'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../shared/api/errorHandler'
import { BankAccountsBlock } from './BankAccountsBlock'
import { WalletAccounts } from './WalletAccounts'

export const FinancialInformationTab = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { mode, agentId } = useParams()

  const { data: currencies, isFetching: isCurrencyFetching } = useGetListOfCurrenciesQuery()
  const { data: banks, isFetching: isFetchingBanks } = useGetBankListQuery({})
  const { data: agentBanks, isFetching: isFetchingAgentBanks } = useGetAgentBankAccountsByIdQuery(
    agentId as string,
    { skip: !agentId }
  )
  const { data: agentWallets, isFetching: isFetchingWallets } =
    useGetAgentWalletAccountAccountsByIdQuery(agentId as string, { skip: !agentId })

  const [updBA, updBAResp] = useUpdateAgentBankAccountMutation()
  const [updWA, updWAResp] = useUpdateAgentWalletAccountMutation()

  const isViewMode = mode === 'view'
  const isLoading =
    isCurrencyFetching ||
    isFetchingBanks ||
    isFetchingAgentBanks ||
    isFetchingWallets ||
    updWAResp.isLoading ||
    updBAResp.isLoading

  const afterMutation = () => {
    return navigate(`${SALES_ENUM.SALES_NETWORK}/view/${agentId}`)
  }

  useNotification(NOTIFICATION_TYPES.success, updBAResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, updWAResp.isSuccess, null, afterMutation)
  useNotification(NOTIFICATION_TYPES.error, updBAResp.isError, updBAResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, updWAResp.isError, updWAResp.error as ErrorNode)

  const currenciesMap = useMemo(
    () =>
      currencies?.items.map((currency) => ({
        label: currency?.name,
        value: currency?.id,
      })),
    [currencies?.items]
  )

  const mobileWalletOptions = useMemo(
    () =>
      (
        Object.keys(MOBILE_WALLET_PROVIDER_ENUM) as Array<keyof typeof MOBILE_WALLET_PROVIDER_ENUM>
      ).map((method) => ({
        label: MOBILE_WALLET_PROVIDER_ENUM[method],
        value: MOBILE_WALLET_PROVIDER_ENUM[method],
      })),
    []
  )

  const onFinish = (values: {
    bankAccounts: Array<ICustomerBankAccountInfo>
    walletAccounts: Array<ICustomerWalletInfo>
  }) => {
    // BANK ACCOUNTS
    const toDeleteBanks = (agentBanks || [])
      .filter(
        (dataBranches) =>
          !values.bankAccounts?.some((valueBranch) => valueBranch.id === dataBranches.id)
      )
      .map(({ id }) => ({ bankAccountId: id }))
    const toCreateBanks = values.bankAccounts
      ?.filter((bank) => !bank.agentId)
      .map((bank) => ({
        ...bank,
        active: !!bank.active,
        bankBranchId: bank.bankBranch.id,
      }))
    const toUpdateBanks = values.bankAccounts
      ?.filter((bank) => bank.agentId)
      .map((bank) => ({
        ...bank,
        bankAccountId: bank.id,
        bankBranchId: bank.bankBranch.id,
        active: !!bank.active,
      }))

    // WALLET ACCOUNTS
    const toDeleteWallets = (agentWallets || [])
      .filter(
        (dataWallets) => !values.walletAccounts?.some((wallet) => wallet.id === dataWallets.id)
      )
      .map(({ id }) => ({ walletAccountId: id }))
    const toCreateWallets = values.walletAccounts
      ?.filter((wallet) => !wallet.agentId)
      .map((wallet) => ({ ...wallet, active: !!wallet.active }))
    const toUpdateWallets = values.walletAccounts
      ?.filter((wallet) => wallet.agentId)
      .map((wallet) => ({
        walletAccountId: wallet.id,
        mobileWalletProvider: wallet.mobileWalletProvider,
        mobileWalletNumber: wallet.mobileWalletNumber,
        active: !!wallet.active,
      }))

    updBA({
      agentId: agentId as string,
      body: { toCreate: toCreateBanks, toUpdate: toUpdateBanks, toDelete: toDeleteBanks },
    })
    updWA({
      agentId: agentId as string,
      body: { toCreate: toCreateWallets, toUpdate: toUpdateWallets, toDelete: toDeleteWallets },
    })
  }

  const onSubmitButtonClick = () => {
    if (isViewMode) {
      return navigate(`${SALES_ENUM.SALES_NETWORK}/edit/${agentId}`)
    }

    form.submit()
  }

  const onCancelButtonClick = () => {
    if (isViewMode) {
      return navigate(SALES_ENUM.SALES_NETWORK)
    }

    form.resetFields()
    form.setFieldValue('bankAccounts', agentBanks)
    return navigate(`${SALES_ENUM.SALES_NETWORK}/view/${agentId}`)
  }

  useEffect(() => {
    if (!agentBanks) return
    form.setFieldValue('bankAccounts', agentBanks)
  }, [agentBanks, form])

  useEffect(() => {
    if (!agentWallets) return
    form.setFieldValue('walletAccounts', agentWallets)
  }, [agentWallets, form])

  const content = (
    <div className={styles.layout}>
      <Form form={form} layout='vertical' onFinish={onFinish} className={styles.paymentForm}>
        <BankAccountsBlock banks={banks} currenciesMap={currenciesMap} view={isViewMode} />
        <WalletAccounts view={isViewMode} mobileWalletOptions={mobileWalletOptions} />
      </Form>
    </div>
  )

  return (
    <>
      {isLoading ? <Spin>{content}</Spin> : content}
      <Row className={styles.footer} justify='end'>
        <Space size={10}>
          <Button color='blue' size='middle' onClick={onCancelButtonClick}>
            Cancel
          </Button>
          <Button
            color='blue'
            htmlType='submit'
            size='middle'
            type='primary'
            onClick={onSubmitButtonClick}
          >
            {isViewMode ? 'Edit' : 'Save Changes'}
          </Button>
        </Space>
      </Row>
    </>
  )
}
