import React, { memo } from 'react'
import { Select } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { TextField } from 'src/shared/components/textfield/TextField'
import styles from '../../styles.module.scss'

const sectionOptions = [
  { label: 'Direct', value: 'direct' },
  { label: 'Sales Network', value: 'salesNetwork' },
  { label: 'Support', value: 'support' },
  { label: 'Finance', value: 'finance' },
]

const DetailInfo = () => {
  return (
    <Wrapper title='Detail Info' className={styles.wrapperDetailInfo}>
      <div className={styles.dblRow}>
        <FormItem name='createdByUser' label='Created By'>
          <TextField disabled />
        </FormItem>
        <FormItem name='createdAt' label='Creation Date'>
          <TextField disabled />
        </FormItem>
      </div>
      <FormItem name='name' label='Name'>
        <TextField />
      </FormItem>
      <div className={styles.dblRow}>
        <FormItem name='section' label='Section' style={{ marginBottom: 0 }}>
          <Select options={sectionOptions} defaultValue='direct' />
        </FormItem>
        <FormItem name='version' label='Version'>
          <TextField />
        </FormItem>
      </div>
    </Wrapper>
  )
}

export default memo(DetailInfo)
