import { IScratchCard, VENDOR_WALLET_TYPE_ENUM } from '../../../../../models/IPurchaseOrder'
import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import FormItem from 'antd/es/form/FormItem'
import React from 'react'
import styles from '../styles.module.scss'

interface IGroupDataItem {
  denomination: number
  numberOfCards: number
  amount: number
}

interface IGroupedData {
  [denomination: number]: IGroupDataItem
}

interface IProps {
  pinList?: Array<IScratchCard>
}

export const EPinsCountBlock = ({ pinList = [] }: IProps) => {
  return (
    <FormItem noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const scratchCardInfo = (getFieldValue('scratchCardInfo') as IScratchCard[]) || []
        const isEPinType = getFieldValue('vendorWalletType') === VENDOR_WALLET_TYPE_ENUM.E_PIN
        const groupedData = [...scratchCardInfo, ...pinList].reduce((acc, card) => {
          const denomination = card?.denomination

          if (!acc[denomination]) {
            acc[denomination] = {
              denomination,
              numberOfCards: 0,
              amount: 0,
            }
          }
          acc[denomination].numberOfCards += 1
          acc[denomination].amount += Number(denomination)
          return acc
        }, {} as IGroupedData)

        const shouldDisplayCount = isEPinType && !!Object.values(groupedData).length

        return (
          shouldDisplayCount && (
            <Wrapper title='EPins Count'>
              {(Object.values(groupedData) as Array<IGroupDataItem>)
                ?.filter((data) => !!data?.denomination)
                ?.map((code) => (
                  <div key={code.denomination} className={styles.countRow}>
                    <div>
                      <span className={styles.countRowTitle}>Denomination:</span>{' '}
                      <span>{code?.denomination}</span>
                    </div>
                    <div>
                      <span className={styles.countRowTitle}>NO Cards:</span>{' '}
                      <span>{code?.numberOfCards}</span>
                    </div>
                    <div>
                      <span className={styles.countRowTitle}>Amount:</span>{' '}
                      <span>{code?.amount}</span>
                    </div>
                  </div>
                ))}
            </Wrapper>
          )
        )
      }}
    </FormItem>
  )
}
