import { Form, Select } from 'antd'
import { TextField } from '../textfield/TextField'
import { Range } from '../range/Range'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { DatePicker, RangePicker } from '../datePicker'
import { toSpaceCase } from '../../../helpers/utils'

export const FilterFieldsMapper = ({
  field,
  element,
  placeholder,
  onChange,
  options,
}: {
  field: string
  element: string
  placeholder: string
  onChange: (name: string, value: unknown) => void
  options: Array<string>
}) => {
  if (element === 'select') {
    return (
      <Form.Item name={field} key={field} className={styles.formItem}>
        <Select onChange={(value) => onChange(field, value)} placeholder={placeholder} allowClear>
          {options.map((option) => {
            let text
            if (typeof option === 'string') {
              text = toSpaceCase(option)
            } else if (option === true) {
              text = 'Active'
            } else if (option === false) {
              text = 'Inactive'
            } else {
              text = option
            }

            return (
              <Select.Option key={option} value={option}>
                {text}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
    )
  }

  if (element === 'number') {
    return (
      <Form.Item name={field} key={field} className={styles.formItem} label={placeholder}>
        <Range
          onChange={(value) => onChange(field, value)}
          min={Number(options[0])}
          max={Number(options[options.length - 1])}
          range
        />
      </Form.Item>
    )
  }

  if (element === 'date-range') {
    return (
      <Form.Item name={field} key={field} className={styles.formItem}>
        <RangePicker placeholder={['From', 'To']} className={styles.dataPicker} />
      </Form.Item>
    )
  }

  if (element === 'date-less') {
    return (
      <Form.Item name={`${field}_less`} key={field} className={styles.formItem}>
        <DatePicker placeholder={placeholder} className={styles.dataPicker} />
      </Form.Item>
    )
  }

  if (element === 'date-more') {
    return (
      <Form.Item name={`${field}_more`} key={field} className={styles.formItem}>
        <DatePicker placeholder={placeholder} className={styles.dataPicker} />
      </Form.Item>
    )
  }

  if (element === 'date-equals') {
    return (
      <Form.Item name={`${field}`} key={field} className={styles.formItem}>
        <DatePicker placeholder={placeholder} className={styles.dataPicker} />
      </Form.Item>
    )
  }

  if (element === 'boolean') {
    const options = [
      {
        label: 'Active',
        value: 'true',
      },
      {
        label: 'Inactive',
        value: 'false',
      },
    ]

    return (
      <Form.Item name={field} key={field} className={classNames(styles.formItem, styles.switch)}>
        <Select options={options} placeholder={placeholder} allowClear />
      </Form.Item>
    )
  }

  if (element === 'text-like') {
    return (
      <Form.Item name={`${field}_like`} key={field} className={styles.formItem}>
        <TextField placeholder={placeholder} onChange={(e) => onChange(field, e.target.value)} />
      </Form.Item>
    )
  }

  return (
    <Form.Item name={field} key={field} className={styles.formItem}>
      <TextField placeholder={placeholder} onChange={(e) => onChange(field, e.target.value)} />
    </Form.Item>
  )
}
