import React, { memo, useMemo } from 'react'

import styles from './styles.module.scss'

interface IProps {
  position?: string
  department?: string
  userContactChannels?: Array<{ id: number; type: string; value: string }>
}

const ChatDetailsUserInfo = ({ position = '', department = '', userContactChannels }: IProps) => {
  const emails = useMemo(() => {
    if (userContactChannels) {
      return userContactChannels
        .filter((el) => ['EMAIL_IDENTITY', 'EMAIL'].includes(el.type))
        .map((el) => el.value)
        .join(', ')
    }
    return ''
  }, [userContactChannels])

  const phoneNumbers = useMemo(() => {
    if (userContactChannels) {
      return userContactChannels
        .filter((el) => ['MOBILE_IDENTITY', 'MOBILE'].includes(el.type))
        .map((el) => el.value)
        .join(', ')
    }
    return ''
  }, [userContactChannels])

  return (
    <div className={styles.internalChatUserInfo}>
      <div className={styles.userInfoItem}>
        <div className={styles.infoTitle}>Position</div>
        <div className={styles.infoData}>{position}</div>
      </div>
      <div className={styles.userInfoItem}>
        <div className={styles.infoTitle}>Department</div>
        <div className={styles.infoData}>{department}</div>
      </div>
      <div className={styles.userInfoItem}>
        <div className={styles.infoTitle}>Phone Number</div>
        <div className={styles.infoData}>{phoneNumbers}</div>
      </div>
      <div className={styles.userInfoItem}>
        <div className={styles.infoTitle}>Email</div>
        <div className={styles.infoData}>{emails}</div>
      </div>
    </div>
  )
}

export default memo(ChatDetailsUserInfo)
