import { Space, Tabs } from 'antd'
import { useContext, useEffect, useMemo, useState } from 'react'
import { STATEMENT_OF_WALLETS_ENUM } from './index.d'
import { SummaryStatementTable } from '../SummaryStatementTable'
import { AgentStatementTable } from '../AgentStatementTable'
import { BankAccountsTable } from '../BankAccountsTable'
import { VendorWalletTable } from '../VendorWalletTable'
import { RewardWalletTable } from '../RewardWalletTable'
import { TrustWalletTable } from '../TrustWalletTable'
import { DiscountWalletTable } from '../DiscountWalletTable'
import { HawalaWalletsTable } from '../HawalaWalletsTable'
import { CreditWalletTable } from '../CreditWalletTable'
import { PromotionWalletTable } from '../PromotionWalletTable'
import { CollectionWalletTable } from '../CollectionWalletTable'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { AdjustmentIcon } from '../../../../../../assets/svg'
import { BankWalletsTable } from '../BankWalletsTable'
import { RightModalContext } from '../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import { Emitter, EmitterEvents } from '../../../../../../helpers/eventEmitter'
import useLocalPermissions from './useLocalPermissions'
import WithPermissionDeniedContainer from '../../../../../../shared/components/withPermissionDenied'
import styles from './styles.module.scss'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'

const Operations = () => {
  const [, setIsLoadingPdf] = useState(false)
  const rightModalContext = useContext(RightModalContext)

  const handlerAdjustmentClick = () => {
    rightModalContext.onOpen(RIGHT_MODALS.FINANCE.ADJUSTMENT, {
      title: 'Adjustment',
    })
  }

  useEffect(() => {
    Emitter.on(EmitterEvents.LOADING_PDF, () => {
      setIsLoadingPdf(true)
    })

    Emitter.on(EmitterEvents.LOADED_PDF, () => {
      setIsLoadingPdf(false)
    })

    return () => {
      Emitter.off(EmitterEvents.LOADED_PDF)
      Emitter.off(EmitterEvents.LOADING_PDF)
    }
  }, [])

  return (
    <Space>
      <IconButton
        color='blue'
        type='primary'
        icon={<AdjustmentIcon />}
        onClick={handlerAdjustmentClick}
      >
        Adjustment
      </IconButton>
      {/* {!isSummaryTabActive && (
        <IconButton
          icon={<CloudShareIcon />}
          color='blue'
          type='primary'
          loading={isLoadingPdf}
          onClick={handleExportClick}
        />
      )} */}
    </Space>
  )
}

export const StatementOfWalletsTabs = () => {
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: STATEMENT_OF_WALLETS_ENUM.SUMMARY_STATEMENT,
  })

  const {
    canViewSummary,
    canViewAgentStatement,
    canViewTrustWallet,
    canViewBankAccounts,
    canViewVendorWallets,
    canViewRewardWallet,
    canViewCollectionWallet,
    canViewHawalaWallet,
  } = useLocalPermissions()

  const items = useMemo(
    () => [
      {
        label: STATEMENT_OF_WALLETS_ENUM.SUMMARY_STATEMENT,
        key: STATEMENT_OF_WALLETS_ENUM.SUMMARY_STATEMENT,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canViewSummary}>
            <SummaryStatementTable />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: STATEMENT_OF_WALLETS_ENUM.AGENT_STATEMENT,
        key: STATEMENT_OF_WALLETS_ENUM.AGENT_STATEMENT,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canViewAgentStatement}>
            <AgentStatementTable />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: STATEMENT_OF_WALLETS_ENUM.BANK_ACCOUNTS,
        key: STATEMENT_OF_WALLETS_ENUM.BANK_ACCOUNTS,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canViewBankAccounts}>
            <BankAccountsTable />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: STATEMENT_OF_WALLETS_ENUM.VENDOR_WALLETS,
        key: STATEMENT_OF_WALLETS_ENUM.VENDOR_WALLETS,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canViewVendorWallets}>
            <VendorWalletTable />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: STATEMENT_OF_WALLETS_ENUM.REWARD_WALLET,
        key: STATEMENT_OF_WALLETS_ENUM.REWARD_WALLET,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canViewRewardWallet}>
            <RewardWalletTable />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: STATEMENT_OF_WALLETS_ENUM.TRUST_WALLET,
        key: STATEMENT_OF_WALLETS_ENUM.TRUST_WALLET,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canViewTrustWallet}>
            <TrustWalletTable />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: STATEMENT_OF_WALLETS_ENUM.BANK_WALLET,
        key: STATEMENT_OF_WALLETS_ENUM.BANK_WALLET,
        children: <BankWalletsTable />,
      },
      {
        label: STATEMENT_OF_WALLETS_ENUM.DISCOUNT_WALLET,
        key: STATEMENT_OF_WALLETS_ENUM.DISCOUNT_WALLET,
        children: <DiscountWalletTable />,
      },
      {
        label: STATEMENT_OF_WALLETS_ENUM.HAWALA_WALLET,
        key: STATEMENT_OF_WALLETS_ENUM.HAWALA_WALLET,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canViewHawalaWallet}>
            <HawalaWalletsTable />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: STATEMENT_OF_WALLETS_ENUM.CREDIT_WALLET,
        key: STATEMENT_OF_WALLETS_ENUM.CREDIT_WALLET,
        children: <CreditWalletTable />,
      },
      {
        label: STATEMENT_OF_WALLETS_ENUM.PROMOTION_WALLET,
        key: STATEMENT_OF_WALLETS_ENUM.PROMOTION_WALLET,
        children: <PromotionWalletTable />,
      },
      {
        label: STATEMENT_OF_WALLETS_ENUM.COLLECTION_WALLET,
        key: STATEMENT_OF_WALLETS_ENUM.COLLECTION_WALLET,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canViewCollectionWallet}>
            <CollectionWalletTable />
          </WithPermissionDeniedContainer>
        ),
      },
    ],
    [
      canViewAgentStatement,
      canViewBankAccounts,
      canViewCollectionWallet,
      canViewHawalaWallet,
      canViewRewardWallet,
      canViewSummary,
      canViewTrustWallet,
      canViewVendorWallets,
    ]
  )

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  return (
    <div className={styles.parentTabsContainer}>
      <Tabs
        destroyInactiveTabPane
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
        tabBarExtraContent={<Operations />}
        className={styles.tabs}
      />
    </div>
  )
}
