export enum FREQUENCY_ENUM {
  MONTH = 'month',
  DAY = 'day',
  HOUR = 'hour',
}

export enum WIDGET_LIST_ENUM {
  ACTIVE_LOCATIONS = 'active-locations',
  ACTIVE_AGENTS = 'active-agents',
  AVERAGE_DAILY_TOPUP = 'average-daily-topup',
  AVERAGE_PICKUP_PER_DAY = 'average-pickup-per-day',
  BANK_ACCOUNTS_BALANCE = 'bank-accounts-balance',
  BANK_ACCOUNT_BALANCE = 'bank-account-balance',
  BANK_WALLET_BALANCE = 'bank-wallet-balance',
  CREDIT_TRANSACTIONS = 'credit-transactions',
  CREDIT_WALLET_BALANCE = 'credit-wallet-balance',
  DEBIT_TRANSACTIONS = 'debit-transactions',
  ELIGABLE_AGENTS = 'eligable-agents',
  GENERATED_PROFIT = 'generated-profit',
  HAWALA = 'hawala',
  NEW_REGISTRATION = 'new-registration',
  OPERATOR_BALANCES = 'operator-balances',
  PROFIT_AND_LOSS = 'profit-and-loss',
  PO_UNDER_PROCESS = 'po-under-process',
  PURCHASE_ORDERS = 'purchase-orders',
  TOTAL_ELIGIBLE_LOAN_AMOUNT = 'total-eligible-loan-amount',
  TOTAL_FLOATING_BALANCE = 'total-floating-balance',
  TOTAL_PICKUP = 'total-pickup',
  TOTAL_PICKUPS = 'total-pickups',
  TOTAL_PICKUP_TODAY = 'total-pickup-today',
  TOTAL_SALES = 'total-sales',
  SALES_BY_VENDORS = 'sales-by-vendors',
  SALES_BY_MASTER_PRODUCTS = 'sales-by-master-products',
  SALES_SUMMARY = 'sales-summary',
  SALES_SUMMARY_BY_VENDORS = 'sales-summary-by-vendors',
  SALES_SUMMARY_BY_MASTER_PRODUCT = 'sales-summary-by-master-product',
  TRUST_WALLET_AVAILABLE_BALANCE = 'trust-wallet-available-balance',
  INVENTORY_VENDOR_CURRENT_BALANCE = 'inventory-vendor-current-balance',
  INVENTORY_PO_AVERAGE_DAILY_USAGE = 'inventory-po-average-daily-usage',
  INVENTORY_PO_REMAINING_MONTH_TARGET = 'inventory-po-remaining-month-target',
  TOTAL_UNIQUE_CUSTOMERS = 'total-uniq-customer',
  UNIQUE_CUSTOMERS = 'uniq-customer',
  VENDOR_CURRENT_BALANCE = 'vendor-current-balance',
  TOP_AGENTS = 'top-agents',
  WALLET_TYPE = 'sales-by-wallet-type',
  AGENT_TYPE = 'sales-by-agent-type',
  SALES_CHANNELS = 'sales-by-channels',
  FAILED_TRANSACTIONS_BY_MASTER_PRODUCTS = 'failed-topup-by-master-products',
  FAILED_TRANSACTIONS_BY_VENDORS = 'failed-topup-by-vendors',
  AGENT_ACTIVITY = 'agent-activity',
  PROCUREMENT = 'procurement',
  PICKUP_SUMMARY = 'pickups',
  PNL_SUMMARY = 'pl-summary',
  VENDOR_BALANCES = 'vendor-current-balance-with-po',
  BANK_ACCOUNTS_BALANCE_CHART = 'bank-accounts-balance',
  RETURN_CUSTOMER = 'return-customer',
  INVENTORY = 'inventory',
  TOP_LOCATIONS = 'top-locations',
  E_PIN_SALES = 'sales-e-pin',
  TOTAL_ACTIVE_E_PIN = 'total-active-e-pin',
  TOTAL_SALES_E_PIN = 'total-sales-e-pin',
  SIM_CARD_TOTAL_BALANCE = 'sim-card-total-balance',
  SIM_CARD_COUNT_BY_STATUS = 'sim-card-count-by-status',
  SIM_CARD_SALES_AND_PO = 'sim-card-sales-and-po',
  SIM_CARD_CHART = 'sim-card-chart',
  SIM_CARD_LIST = 'sim-card-list',
}

export enum DASHBOARD_DATE_OPTIONS {
  TODAY = 'date',
  WEEK = 'week',
  MONTH = 'month',
}
