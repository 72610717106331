import { useGetMasterProductsListQuery } from '../../../../../core/http/VendorManagementApi'
import FormList from 'antd/es/form/FormList'
import FormItem from 'antd/es/form/FormItem'
import styles from '../../../styles.module.scss'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from '../../../../../../../assets/svg'
import React from 'react'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import { IPolicyProduct, IProductAccess } from '../../../../../models/IDiscountPolicy'
import classNames from 'classnames'
import { requiredValidator } from '../../../../../../../helpers/validation'
import { Select } from 'antd'
import { AGENT_TYPE_ID_ENUM } from '../../../../../models/IAgentType'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { ACCOUNT_TYPE_TO_ID } from '../../../../../models/IAccountType'

interface IProps extends IViewMode {
  onViewPage?: boolean
  isPostpaid?: boolean
}

export const ProductAccessBlock = ({ view, onViewPage, isPostpaid }: IProps) => {
  const form = useFormInstance()
  const { data } = useGetMasterProductsListQuery({})

  const onProductAccessRemove = (arrId: number) => {
    if (form.getFieldValue(['accountType', 'id']) === ACCOUNT_TYPE_TO_ID.PREPAID) {
      return
    }

    const isIndirect = form.getFieldValue(['agentType', 'id']) === AGENT_TYPE_ID_ENUM.INDIRECT

    const elementProductAccess = (form.getFieldValue('productsAccess') as IProductAccess[])[arrId]
      ?.masterProduct
    const selectedDiscountProducts = form.getFieldValue(
      'discountPolicyProducts'
    ) as IPolicyProduct[]

    if (!elementProductAccess?.id) {
      return
    }

    if (
      elementProductAccess &&
      !selectedDiscountProducts?.some(
        (access) => access?.masterProduct?.id === elementProductAccess.id
      )
    ) {
      const prodToTransfer = {
        masterProduct: elementProductAccess,
        levelRates: Array.from({ length: isIndirect ? 5 : 1 }, (_, index) => ({
          level: { id: index + 1 },
          rate: null,
        })),
      }
      form.setFieldValue('discountPolicyProducts', [...selectedDiscountProducts, prodToTransfer])
    }
  }

  return (
    <FormList name='productsAccess'>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ name, key }) => (
            <div
              key={key}
              className={classNames(styles.productAccessListItem, {
                [styles.productAccessListItemView]: onViewPage,
              })}
            >
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const selectedProductAccess = (
                    getFieldValue('productsAccess') as IProductAccess[]
                  )?.map((product) => product?.masterProduct?.id)

                  const selectedProductType = getFieldValue('masterProductType')

                  return (
                    <FormItem
                      name={[name, 'masterProduct', 'id']}
                      className={classNames(styles.masterProductAccessSelector, 'noBottomMargin')}
                      style={{ minWidth: 300 }}
                      rules={[
                        {
                          required: true,
                          validator: requiredValidator('Product'),
                        },
                      ]}
                    >
                      <Select disabled={view || isPostpaid} allowClear placeholder='Select Product'>
                        {data?.items
                          ?.filter((masterProduct) =>
                            isPostpaid ? masterProduct?.type === selectedProductType : true
                          )
                          ?.map((product) => (
                            <Select.Option
                              key={product.id}
                              value={product.id}
                              disabled={selectedProductAccess?.includes(product?.id)}
                            >
                              {product.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormItem>
                  )
                }}
              </FormItem>

              <div>
                <IconButton
                  block
                  size='large'
                  color='orange'
                  slim
                  icon={<BucketIcon />}
                  type='default'
                  onClick={() => {
                    onProductAccessRemove(name)
                    remove(name)
                  }}
                  className='noBottomMargin'
                  disabled={view}
                />
              </div>
            </div>
          ))}
          {!isPostpaid && (
            <div className={styles.addButton}>
              <IconButton
                icon={<AddIcon />}
                disabled={view}
                color='orange'
                block
                onClick={() => add()}
              />
            </div>
          )}
        </>
      )}
    </FormList>
  )
}
