import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Form, Skeleton } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { isEmpty } from 'lodash'

import { Button } from 'src/shared/components/button/Button'
import { requiredValidator } from 'src/helpers/validation'
import { ErrorNode } from '../../../../shared/api/errorHandler'
import { Logo } from '../../../../shared/components/logo/Logo'

// styles
import styles from './styles.module.scss'

import googleAuthenticatorImg from 'src/assets/img/GoogleAuthenticator.png'
import classNames from 'classnames'
import {
  useGenerateBackupCodesMutation,
  useGoogleAuthEnableMutation,
  useGoogleAuthGenerateMutation,
} from '../../core/http/TwoFactorAuthApi'
import { IStep2FormInstance } from 'src/features/Modals/Components/GoogleAuthenticatorModal'
import { TextField } from 'src/shared/components/textfield/TextField'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { saveAuthData } from 'src/shared/api'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { ArrowLeftIcon } from '../../../../assets/svg'

export const SignUpGoogleAuth = () => {
  const [form] = Form.useForm<IStep2FormInstance>()

  const navigate = useNavigate()

  const [step, setStep] = useState(0)

  const [generateQR, gData] = useGoogleAuthGenerateMutation()
  const [enableGoogleAuth, eData] = useGoogleAuthEnableMutation()
  const [generateBackupCodes] = useGenerateBackupCodesMutation()

  useNotification(NOTIFICATION_TYPES.success, eData.isSuccess, null, () => {
    saveAuthData(eData.data!)
    generateBackupCodes()
    navigate('/dashboard/overview')
  })
  useNotification(NOTIFICATION_TYPES.error, eData.isError, eData.error as ErrorNode, () =>
    form.resetFields()
  )

  const redirectTo = (value: string) => () => {
    navigate(value)
  }

  const handleStepChange = (value: number) => () => {
    setStep(value)

    if (value === 1) generateQR(null)
  }

  const handleVerify = async (values: IStep2FormInstance) => {
    await enableGoogleAuth(values)
  }

  const onPressBack = () => {
    if (step > 0) {
      return setStep((p) => p - 1)
    }
    navigate('/sign-in')
  }

  useEffect(() => {
    if (isEmpty(eData.isSuccess)) return
    setStep(3)
  }, [eData.isSuccess])

  const qrURL = gData?.data instanceof Blob ? URL.createObjectURL(gData.data) : ''

  return (
    <main className={styles.login}>
      <div className={styles.loginSidebar}>
        <div className={styles.loginSidebarInner}>
          <div
            className={classNames(styles.loginSidebarTitle, {
              [styles.successTitle]: step === 3,
            })}
          >
            {step === 3
              ? 'You have succesfully registred for Two-factor Authentication!'
              : 'Connect your Account and Google Authentificator'}
          </div>
          {step !== 3 && <div className={styles.loginSidebarSteps}>{`${step + 1}/3`}</div>}
          {step === 0 && (
            <>
              <Col>
                <div className={classNames(styles.title, styles.title1)}>
                  <div>Download and install Google Authenticator on your mobile device</div>
                </div>
                <img src={googleAuthenticatorImg} className={styles.googleAuthenticatorImg} />
                <Button size='large' color='orange' type='primary' onClick={handleStepChange(1)}>
                  Next: Add your account
                </Button>
              </Col>
            </>
          )}
          {step === 1 && (
            <React.Fragment>
              <div className={classNames(styles.title, styles.title2)}>
                Add your account to Google Authentificator
              </div>
              <div className={styles.subTitle}>
                After clicking the “+” icon in Google Authenticator, use the camera to scan the QR
                code on the screen.
              </div>

              {gData.isLoading && (
                <div className={styles.skeletonContainer}>
                  <Skeleton.Image active={true} />
                </div>
              )}
              {qrURL && <img src={qrURL} className={styles.googleAuthenticatorImg} />}
              <Button size='large' color='orange' type='primary' onClick={handleStepChange(2)}>
                Next: Verify your device
              </Button>
            </React.Fragment>
          )}
          {step === 2 && (
            <React.Fragment>
              <div className={classNames(styles.title, styles.title3)}>Verify your device</div>
              <Form
                form={form}
                layout='vertical'
                onFinish={handleVerify}
                className={styles.formContainer}
              >
                <FormItem
                  name='code'
                  className={styles.codeInput}
                  rules={[{ validator: requiredValidator('Verification code') }]}
                >
                  <TextField placeholder='Verification code' type='number' />
                </FormItem>
              </Form>

              <Button
                size='large'
                color='orange'
                type='primary'
                onClick={() => form.submit()}
                disabled={eData.isLoading}
              >
                Verify
              </Button>
            </React.Fragment>
          )}
          {step === 3 && (
            <Button size='large' color='orange' type='primary' onClick={redirectTo('/')}>
              Continue with aminpay
            </Button>
          )}
        </div>
        <IconButton
          size='large'
          color='blue'
          type='text'
          textColor='#FFFFFF'
          className={styles.backButton}
          icon={<ArrowLeftIcon color='#FFFFFF' />}
          onClick={onPressBack}
        >
          Back
        </IconButton>
      </div>
      <div className={styles.loginContent}>
        <Logo withText />
      </div>
    </main>
  )
}
