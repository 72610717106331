import styles from '../styles.module.scss'
import { formatDateWithTime } from '../../../../../../../../helpers/utils'
import { Space } from 'antd'
import { IconButton } from '../../../../../../../../shared/components/iconButton/IconButton'
import React, { useContext } from 'react'
import {
  CreateReverseTransStepType,
  ITopup,
  ITopupDetail,
} from '../../../../../../../Finance/models/IReverseTransaction'
import { RightModalContext } from '../../../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../../../helpers/contants'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

interface IProps {
  topup: ITopup | ITopupDetail
  handleChangeStep: (step: CreateReverseTransStepType, topupId: number | null) => void
  shouldDisplayCreate?: boolean
}

export const TopupListItem = ({ topup, handleChangeStep, shouldDisplayCreate = true }: IProps) => {
  const { data: profile } = useGetProfileQuery()

  return (
    <div className={styles.topupListItem}>
      <div className={styles.info}>
        <div className={styles.id}>ID{topup?.id}</div>
        <div className={styles.amount}>{topup?.amount}</div>
        <div className={styles.receiver}>{topup?.receiver}</div>
        <div className={styles.footerTopupItem}>
          <span>{formatDateWithTime(topup?.createdAt, profile?.calendar)}</span>
          <div className={styles.popupDivider} />
          <span>{topup?.status}</span>
        </div>
      </div>
      <Space className={styles.actions} size={13}>
        {shouldDisplayCreate && (
          <IconButton color='orange' onClick={() => handleChangeStep('createTopup', topup.id)}>
            Create Request
          </IconButton>
        )}
      </Space>
    </div>
  )
}
