import { useNavigate, useParams } from 'react-router-dom'
import styles from '../styles.module.scss'
import { Form, Row, Space, Spin, UploadFile } from 'antd'
import {
  ICreateMasterProduct,
  IMasterProduct,
  IMasterProductProduct,
  PRODUCTS_ACTIONS_ENUM,
} from '../../../models/IMasterProduct'
import { DetailInfo } from '../MasterProductNew/DetailInfo'
import { Products } from '../MasterProductNew/Products'
import {
  useGetMasterProductByIdQuery,
  usePatchMasterProductLogoMutation,
  useUpdateMasterProductMutation,
} from '../../../core/http/VendorManagementApi'
import { formatOnlyDate } from '../../../../../helpers/utils'
import { Button } from '../../../../../shared/components/button/Button'
import React, { useCallback, useEffect, useState } from 'react'
import { UploaderPhotos2 } from '../../../../../shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import { RcFile } from 'antd/lib/upload'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../shared/api/errorHandler'
import { useAppDispatch } from '../../../../../redux'
import { setBreadcrumb } from '../../../../app/core/BreadcrumbsSlice'
import usePermissions from '../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../models/IPermissions'
import PermissionDenied from '../../../../../shared/components/permissionDenied'
import { SETTINGS_ENUM } from '../../../../../routes/settings'
import { MASTER_PRODUCT_TYPE_ENUM } from '../../../models/IProduct'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { VendorMasterColorBlock } from '../Components/VendorMasterColorBlock'
import classNames from 'classnames'

export const MasterProductView = () => {
  const [picture, setPicture] = useState<RcFile>()
  const [, setIsImageError] = useState(false)
  const [isDeleteLogo, setIsDeleteLogo] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const { id, mode } = useParams<{ id: string; mode: 'view' | 'edit' }>()

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const { data: profile } = useGetProfileQuery()

  const { data, isFetching } = useGetMasterProductByIdQuery(id as string)
  const [updLogo, updLogoResp] = usePatchMasterProductLogoMutation()
  const [updMastProd, updMastProdResp] = useUpdateMasterProductMutation()

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const [productsId] = getModulesIdsByNames([IPermissionsNames.Product])
  const demandPermissions = [IPermissionsActions.EDIT, IPermissionsActions.VIEW]
  const [canEditProduct, canViewProduct] = canPerformAction(productsId, demandPermissions)
  const notAllowedContent = (isViewMode && !canViewProduct) || (isEditMode && !canEditProduct)

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, updMastProdResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, updLogoResp.isError, updLogoResp.error as ErrorNode)
  useNotification(
    NOTIFICATION_TYPES.error,
    updMastProdResp.isError,
    updMastProdResp.error as ErrorNode
  )

  const onFinish = (values: IMasterProduct) => {
    const isMasterBundle = values?.type === MASTER_PRODUCT_TYPE_ENUM.MASTER_BUNDLE_TOPUP

    const body = {
      name: values?.name,
      type: values?.type,
      internalCode: values?.internalCode,
      status: !!values?.status,
      ...(values?.price ? { price: values.price } : {}),
      ...(values?.externalCode ? { externalCode: values.externalCode } : {}),
      ...(values?.color && { color: values?.color }),
    } as ICreateMasterProduct

    body.receiverPhoneNumberPrefixes = values?.receiverPhoneNumberPrefixes?.map((prefix) => ({
      receiverPhoneNumberPrefixId: typeof prefix === 'string' ? prefix : prefix.id,
    })) as ICreateMasterProduct['receiverPhoneNumberPrefixes']

    if (isMasterBundle) {
      body.masterBundleItems = values?.masterBundleItems?.map((product) => {
        return {
          ...product,
          masterProductId: product?.masterProduct?.id,
          action: PRODUCTS_ACTIONS_ENUM.UPDATE,
        }
      })
    }

    if (!isMasterBundle) {
      // ANOTHER ARRAY IS USED WHEN THE PRODUCT IS UPDATING, TO AVOID CREATING THE NEW ONE
      const productsToDelete = (data?.products || [])
        .filter(
          (dataProduct) =>
            !values.products?.some(
              (valueProduct) => valueProduct.productId === dataProduct.productId
            )
        )
        ?.map((prod) => ({ ...prod, action: PRODUCTS_ACTIONS_ENUM.DELETE }))

      body.products =
        (values?.products
          ?.map((product) => ({
            ...product,
            status: !!product?.status,
            action: PRODUCTS_ACTIONS_ENUM.UPDATE,
          }))
          .concat(productsToDelete) as IMasterProductProduct[]) || []
    }

    updMastProd({ id: id as string, body }).then(() => {
      if (picture) {
        const fd = new FormData()
        fd.append('logo', picture!)

        updLogo({ id: id as string, body: fd })
        setPicture(undefined)
      }
    })
  }

  const setDefaultFields = useCallback(() => {
    const type =
      data?.type === MASTER_PRODUCT_TYPE_ENUM.MASTER_BUNDLE_TOPUP ? 'masterBundleItems' : 'products'
    form.setFieldValue('logo', data?.logo)
    form.setFieldValue('createdByUser', data?.createdByUser?.name)
    form.setFieldValue('createdAt', formatOnlyDate(data?.createdAt, profile?.calendar))
    form.setFieldValue(
      'receiverPhoneNumberPrefixes',
      data?.receiverPhoneNumberPrefixes.map((pref) =>
        typeof pref === 'string' ? pref : pref?.receiverPhoneNumberPrefixId
      )
    )
    if (data) {
      form.setFieldValue(
        type,
        [...data[type]]?.sort((a, b) => a?.priority - b?.priority)
      )
    }
  }, [data, form, profile])

  const onCancelButtonClick = () => {
    if (isViewMode || (!isViewMode && !isEditMode)) {
      return navigate(SETTINGS_ENUM.VENDOR_MANAGEMENT)
    }
    if (isEditMode) {
      form.resetFields()
      setDefaultFields()
      return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/master-product/view/${id}`)
    }
  }

  const onSubmitButtonClick = () => {
    if (isViewMode) {
      return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/master-product/edit/${id}`)
    }

    form.submit()
  }

  useEffect(() => {
    if (data) {
      dispatch(setBreadcrumb(data))
      setDefaultFields()
    }
  }, [data, dispatch, form, setDefaultFields])

  useEffect(() => {
    if ((updLogoResp.isSuccess || updMastProdResp.isSuccess) && updMastProdResp.data) {
      return navigate(
        `${SETTINGS_ENUM.VENDOR_MANAGEMENT}/master-product/view/${updMastProdResp.data.id}`
      )
    }
    // eslint-disable-next-line
  }, [updLogoResp.isSuccess, updMastProdResp.isSuccess])

  const content = (
    <>
      {notAllowedContent && <PermissionDenied />}
      {((isViewMode && canViewProduct) || (isEditMode && canEditProduct)) && (
        <>
          <div className={styles.layout}>
            <Form
              layout='vertical'
              form={form}
              onFinish={onFinish}
              autoComplete='off'
              initialValues={data}
            >
              <div className={styles.columnWrapper}>
                <div className={styles.leftColumn}>
                  <DetailInfo view={isViewMode} isTypeDisabled={true} />
                  <Products view={isViewMode} form={form} masterProductId={id} />
                </div>
                <div className={classNames(styles.rightBlock, styles.withoutMargin)}>
                  <UploaderPhotos2
                    form={form}
                    title='Master Product Logo'
                    isProductPreview
                    view={isViewMode}
                    defaultPreviewImage={data?.logo}
                    onChange={(photo: UploadFile[]) => {
                      setPicture(photo[0].originFileObj as RcFile)
                      setIsImageError(
                        form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                      )
                    }}
                  />
                  <VendorMasterColorBlock title='Master Product Color' view={isViewMode} />
                </div>
              </div>
            </Form>
          </div>
          <div>
            <Row justify='end' className={styles.masterProductFooter}>
              <Space size={10}>
                <Button color='blue' size='middle' onClick={onCancelButtonClick}>
                  Cancel
                </Button>
                {canEditProduct && (
                  <Button
                    color='blue'
                    htmlType='submit'
                    size='middle'
                    type='primary'
                    onClick={onSubmitButtonClick}
                  >
                    {isViewMode ? 'Edit Master Product' : 'Save Changes'}
                  </Button>
                )}
              </Space>
            </Row>
          </div>
        </>
      )}
    </>
  )

  return isFetching || updMastProdResp.isLoading || updLogoResp.isLoading ? (
    <Spin>{content}</Spin>
  ) : (
    content
  )
}
