import React, { memo } from 'react'
import { CrossIcon } from 'src/assets/svg'
import styles from './styles.module.scss'

interface IProps {
  filesNames: Array<{ name: string }>
  onRemoveAttachment: (name: string) => void
}

const ChatRoomAttachedFiles = ({ filesNames, onRemoveAttachment }: IProps) => {
  return (
    <div className={styles.attachmentFileNamesContainer}>
      {filesNames?.length
        ? filesNames.map(({ name }) => (
            <div key={name} className={styles.attachmentFileName}>
              <div className={styles.attachmentFileNameTitle}>{name}</div>
              <div
                className={styles.attachmentFileNameRemove}
                onClick={() => onRemoveAttachment(name)}
              >
                <CrossIcon color='#fff' size={6} />
              </div>
            </div>
          ))
        : null}
    </div>
  )
}

export default memo(ChatRoomAttachedFiles)
