import React from 'react'
import ExternalChatListTabs from './Components/ChatroomListTabs/index'
import styles from '../../styles.module.scss'

const ChatroomListOfChats = () => {
  return (
    <div className={styles.externalChatList}>
      <ExternalChatListTabs />
    </div>
  )
}

export default ChatroomListOfChats
