import InfiniteScroll from 'react-infinite-scroll-component'
import { Divider, Skeleton, Space, Spin } from 'antd'
import { InfoMessage } from '../../../VendorManagementContactListModal/Components/InfoMessage'
import styles from '../../styles.module.scss'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { ArrowRightIcon, CrossIcon } from '../../../../../../../assets/svg'
import React from 'react'
import {
  IWhatsappManagerButton,
  WHATSAPP_BUTTON_MODAL_STEPS,
} from '../../../../../models/IWhatsappManager'
import { ITableResponse } from '../../../../../../../shared/models/ITableResponse'
import { isNumber } from 'lodash'

interface IProps {
  loadMoreData: () => void
  buttons: Array<IWhatsappManagerButton>
  data?: ITableResponse<IWhatsappManagerButton>
  setViewStep: (step: WHATSAPP_BUTTON_MODAL_STEPS, id: string | null) => void
  onDeleteButtonClick: (id: string) => void
}

export const ButtonList = ({
  setViewStep,
  data,
  buttons,
  loadMoreData,
  onDeleteButtonClick,
}: IProps) => {
  const content = (
    <div id='whatsapp-buttons-list'>
      <InfiniteScroll
        dataLength={buttons.length}
        next={loadMoreData}
        hasMore={buttons.length < (data?.totalCount as number)}
        loader={<Skeleton paragraph={{ rows: 1 }} active />}
        endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
        scrollableTarget='whatsapp-buttons-list'
      >
        <InfoMessage text='Buttons' isAccount={false} />
        <div className={styles.buttonsList}>
          {buttons.map((btn) => (
            <div className={styles.buttonListItem} key={btn.id}>
              <div>
                <div className={styles.buttonName}>{btn.title}</div>
                <div className={styles.buttonAction}>{btn.actionId}</div>
              </div>

              <Space size={10}>
                <div className={styles.redirectButton}>
                  <IconButton
                    color='warning'
                    icon={<CrossIcon />}
                    onClick={() => onDeleteButtonClick(btn.id)}
                  />
                </div>
                <div className={styles.redirectButton}>
                  <IconButton
                    color='blue'
                    icon={<ArrowRightIcon />}
                    onClick={() => setViewStep(WHATSAPP_BUTTON_MODAL_STEPS.VIEW_BUTTON, btn.id)}
                  />
                </div>
              </Space>
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  )

  return !isNumber(data?.totalCount) ? <Spin>{content}</Spin> : content
}
