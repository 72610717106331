import Modal from 'antd/lib/modal'
import { useCallback, useEffect, useRef, useState } from 'react'
import Upload from 'antd/lib/upload'
import { UploadFile, UploadProps } from 'antd/es/upload'
import { InboxOutlined } from '@ant-design/icons'
import { Col, Form, Row } from 'antd'
import { Button } from 'src/shared/components/button/Button'
import { useCreateFileMutation } from '../../../../core/http/FileManagementApi'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'
import ShowMessage, { ErrorNode, handleError } from '../../../../../../shared/api/errorHandler'

const { Dragger } = Upload

interface IProps {
  isOpen: boolean
  handleCancel: () => void
}

export const AddFileModal = ({ isOpen, handleCancel }: IProps) => {
  const [files, setFiles] = useState<Array<UploadFile>>([])

  const isNotAcceptedType = (type: string) =>
    type !== 'video/mp4' &&
    type !== 'video/quicktime' &&
    type !== 'image/heic' &&
    type !== 'image/png' &&
    type !== 'image/jpeg' &&
    type !== 'image/gif' &&
    type !== 'application/pdf' &&
    type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
    type !== 'application/vnd.ms-excel' &&
    type !== 'text/csv' &&
    type !== 'text/plain'

  const propsDragger: UploadProps = {
    name: 'file',
    multiple: true,
    customRequest: (info) => {
      if (typeof info.file !== 'string' && info.onError && info.onSuccess) {
        if (isNotAcceptedType(info.file.type)) {
          const err = new Error('Unacceptable file type!')
          return info.onError(err)
        }

        return info.onSuccess('Ok')
      }
    },
    onChange: (info) => {
      setFiles(info.fileList)
      if (info.file.status !== 'uploading') {
        setFiles(info.fileList)
      }
    },
    maxCount: 1,
  }

  const onCancel = useCallback(() => {
    setFiles([])
    handleCancel()
  }, [handleCancel])

  const [form] = Form.useForm()

  const [createFile, { isSuccess, error, isError, isLoading }] = useCreateFileMutation()
  const abortCreateFile = useRef<(() => void) | null>(null)

  const onFinish = ({ isPrivate }: { isPrivate: boolean | undefined }) => {
    if (files[0]?.error) {
      ShowMessage('error', `File type ${files[0].type} is not supported.`)
      return
    }

    const fData = new FormData()
    fData.append('isFolder', JSON.stringify(false))
    fData.append('name', files[0]?.name)
    fData.append('isPrivate', JSON.stringify(!!isPrivate))
    fData.append('file', files[0].originFileObj!)

    const request = createFile(fData)
    abortCreateFile.current = request.abort
  }

  const onFinishFailure = () => {
    if (files[0]?.error) {
      ShowMessage('error', `File type ${files[0].type} is not supported.`)
      return
    }
  }

  const onAbort = () => {
    if (abortCreateFile.current) {
      abortCreateFile.current()

      ShowMessage('error', `Request was aborted!`)
    }
  }

  useEffect(() => {
    if (isError && Object.hasOwn(error!, 'data')) {
      handleError(error as ErrorNode)
    }
  }, [error, isError])

  useEffect(() => {
    if (isSuccess) {
      handleCancel()
    }
  }, [handleCancel, isSuccess])

  return (
    <Modal
      destroyOnClose
      open={isOpen}
      onCancel={onCancel}
      onOk={onCancel}
      footer={null}
      title={null}
      zIndex={1055}
      style={{ borderRadius: 4 }}
    >
      <Form
        name='upload-form'
        className='upload-mo'
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailure}
        initialValues={{ isPrivate: false }}
      >
        <Dragger {...propsDragger} style={{ marginTop: 20 }} disabled={isLoading}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          {files.length > 0 ? (
            <p>Change uploading file</p>
          ) : (
            <>
              <p>Click or drag file to this area to upload</p>
              <p className='small'>pdf, txt, xlsx, csv, jpg, gif, png, heic, mov, mp4</p>
            </>
          )}
        </Dragger>
        {files.length > 0 && (
          <Row justify='space-between' style={{ marginTop: 20 }}>
            <Col>
              <Form.Item>
                <Button onClick={onAbort} disabled={!isLoading}>
                  Cancel Uploading
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name='isPrivate'>
                <Toggle text='Private' />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button loading={isLoading} disabled={isLoading} color='orange' htmlType='submit'>
                  Upload Files
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  )
}
