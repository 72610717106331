import { useEffect } from 'react'

interface IUseInfiniteScrollProps {
  loaderRef: React.MutableRefObject<HTMLDivElement | null>
  callback: () => void
  totalCount: number
  mappedArrLength: number
}

export const useInfiniteScroll = ({
  loaderRef,
  callback,
  totalCount,
  mappedArrLength,
}: IUseInfiniteScrollProps) => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio !== 1) return
        if (mappedArrLength < totalCount) {
          callback()
        }
      })
    }, {})
    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }
    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current)
      }
    }
  }, [callback, loaderRef, mappedArrLength, totalCount])
}
