export enum TimePickerTypes {
  'Daily' = 'Daily',
  'Weekly on selected day' = 'Weekly on selected day',
  'Monthly on selected day' = 'Monthly on selected day',
}

export const TimePickerOptions = [
  { value: 'daily', name: 'Daily' },
  {
    value: 'weekly',
    name: 'Weekly on selected day',
  },
  {
    value: 'monthly',
    name: 'Monthly on selected day',
  },
]

export enum ExpiryTypes {
  'never' = 'never',
  'once' = 'once',
  'on' = 'on',
}

export const ExpiryOptions = [
  {
    value: 'never',
    name: 'Never',
  },
  {
    value: 'once',
    name: 'Once',
  },
  {
    value: 'on',
    name: 'On',
  },
]

export enum OnTypes {
  'first-day' = 'first-day',
  'last-day' = 'last-day',
  'custom' = 'custom',
}

export const OnOptions = [
  {
    value: 'first-day',
    name: 'First day of month',
  },
  {
    value: 'last-day',
    name: 'Last day of month',
  },
  {
    value: 'custom',
    name: 'Custom',
  },
]

export enum FrequencyTypes {
  'daily' = 'daily',
  'weekly' = 'weekly',
  'monthly' = 'monthly',
}

export const FrequencyOptions = [
  {
    value: 'daily',
    name: 'Daily',
  },
  {
    value: 'weekly',
    name: 'Weekly on selected day',
  },
  {
    value: 'monthly',
    name: 'Monthly on selected day',
  },
]

export enum DaysTypes {
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
}

export const DaysOptions = [
  {
    id: 0,
    name: 'Sunday',
  },
  {
    id: 1,
    name: 'Monday',
  },
  {
    id: 2,
    name: 'Tuesday',
  },
  {
    id: 3,
    name: 'Wednesday',
  },
  {
    id: 4,
    name: 'Thursday',
  },
  {
    id: 5,
    name: 'Friday',
  },
  {
    id: 6,
    name: 'Saturday',
  },
]
