import { Filter, ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { IFilterFields } from '../../../../../../shared/models/IFilterFields'
import React, { ChangeEvent } from 'react'
import { useFilter } from '../../../../../../shared/hooks/filter/useFilter'
import { Col } from 'antd'
import { FilterPopup } from '../../../../../../shared/components/filter/FilterPopup'
import styles from './style.module.scss'

interface IProps {
  setActiveFilters: (values: ISelectedFilters) => void
  filterValues: ISelectedFilters
  data?: IFilterFields[]
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void
  onPressSettingsButtonClick: () => void
  classNameFilterPopup?: string
}

const NotificationsHeader = ({
  setActiveFilters,
  data,
  filterValues,
  onSearchChange,
  onPressSettingsButtonClick,
  classNameFilterPopup,
}: IProps): JSX.Element => {
  const { isOpen, onCancel, form, onReset, onFinish } = useFilter({ setActiveFilters })

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ marginBottom: 20, width: '100%', display: 'flex' }}>
          {/*<TextFieldSearch onChange={onSearchChange} className={styles.search} />*/}
          <Col style={{ display: 'flex', gridGap: 20 }}>
            <Filter
              data={data}
              form={form}
              setIsOpen={onCancel}
              selectedFilters={filterValues}
              setSelectedFilters={setActiveFilters}
              classNamesFilterWrapperIcon={styles.filterIcon}
              sizeFilter='large'
            />
          </Col>
        </div>
        {/*<div>*/}
        {/*  <IconButton*/}
        {/*    icon={<SettingsIcon />}*/}
        {/*    type='primary'*/}
        {/*    color='orange'*/}
        {/*    size='large'*/}
        {/*    onClick={onPressSettingsButtonClick}*/}
        {/*    className={styles.addButton}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      {isOpen && (
        <FilterPopup
          className={classNameFilterPopup}
          data={data}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          form={form}
          onCancel={onCancel}
          onReset={onReset}
          onFinish={onFinish}
        />
      )}
    </div>
  )
}
export default NotificationsHeader
