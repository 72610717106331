import styles from '../../UsersManagementView/styles.module.scss'
import { Wrapper } from '../../../../../../../../salesNetwork/components/Wrapper'
import FormItem from 'antd/es/form/FormItem'
import { passwordValidator, requiredValidator } from '../../../../../../../../../helpers/validation'
import { EditIcon } from '../../../../../../../../../assets/svg'
import { IViewMode } from '../../../../../../../../Finance/models/IViewMode'
import React from 'react'
import { IconButton } from '../../../../../../../../../shared/components/iconButton/IconButton'
import { InputPassword } from '../../../../../../../../../shared/components/inputPassword'

interface IProps extends IViewMode {
  isEditMode?: boolean
  isPasswordEdit?: boolean
  togglePasswordEdit?: () => void
}

export const PasswordBlock = ({ isEditMode, isPasswordEdit, togglePasswordEdit }: IProps) => {
  const password = isEditMode
    ? { label: 'Old Password', name: 'oldPassword' }
    : { label: 'Password', name: 'password' }

  const confirmPassword = isEditMode
    ? { label: 'New Password', name: 'newPassword' }
    : { label: 'Confirm Password', name: 'confirmPassword' }

  const validationRules = {
    password: [
      { required: true, validator: requiredValidator('Password') },
      {
        validator: passwordValidator(),
      },
    ],
    oldPassword: [
      { ...(!isPasswordEdit && { required: true, validator: requiredValidator('Old Password') }) },
    ],
    newPassword: [
      { ...(!isPasswordEdit && { required: true, validator: requiredValidator('New Password') }) },
      {
        ...(!isPasswordEdit && { validator: passwordValidator() }),
      },
    ],
    confirmPassword: [{ required: true, validator: requiredValidator('Confirm Password') }],
  }

  const editButton = (
    <IconButton
      icon={<EditIcon />}
      color='orange'
      type={isPasswordEdit ? 'default' : 'primary'}
      onClick={() => togglePasswordEdit && togglePasswordEdit()}
    />
  )

  return (
    <Wrapper
      title='Password'
      className={styles.wrapperBlock}
      statusButton={isEditMode && editButton}
    >
      <div>
        <FormItem
          name={password.name}
          label={password.label}
          rules={validationRules[password.name as keyof typeof validationRules]}
        >
          <InputPassword disabled={isPasswordEdit} placeholder={`Enter ${password.label}`} />
        </FormItem>
      </div>

      <div>
        <FormItem
          name={confirmPassword.name}
          label={confirmPassword.label}
          className={styles.noBottomMargin}
          rules={[
            ...validationRules[confirmPassword.name as keyof typeof validationRules],
            ({ getFieldValue }) =>
              !isEditMode
                ? {
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error('The two passwords that you entered do not match!')
                      )
                    },
                  }
                : {},
          ]}
        >
          <InputPassword disabled={isPasswordEdit} placeholder={`Enter ${confirmPassword.label}`} />
        </FormItem>
      </div>
    </Wrapper>
  )
}
