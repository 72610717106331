import React, { memo } from 'react'
import { Toggle } from '../../../../../../../../shared/components/toggle/Toggle'
import styles from '../../styles.module.scss'

interface IProps {
  selected?: boolean
  groupId: number
  isViewMode?: boolean
  onSwitchPermissionsGroupStatus: (status: boolean, groupId: number) => void
}

const PermissionsGroupStatusSwitcher = ({
  selected,
  isViewMode,
  groupId,
  onSwitchPermissionsGroupStatus,
}: IProps) => {
  return (
    <div className={styles.actionContainer}>
      <Toggle
        checked={selected}
        disabled={isViewMode}
        onChange={(status, event) => {
          event.stopPropagation()
          onSwitchPermissionsGroupStatus(status, groupId)
        }}
      />
    </div>
  )
}

export default memo(PermissionsGroupStatusSwitcher)
