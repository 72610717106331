import React from 'react'
import { MessagingHeader } from './Components/Header'

import { MessagesList } from './Components/Messages'
import { useAppSelector } from '../../../../redux'
import { selectInternalChatChatsState } from '../../core/store/Chats'
import { selectInternalChatMessagesState } from '../../core/store/Messages'
import { modalViewTypeEnum } from '../../core/models'
import styles from './styles.module.scss'
import { checkIsOnlineStatus } from '../InternalChatModal'

interface IProps {
  onSwitchView: (viewType: modalViewTypeEnum) => void
}

export const Messaging = ({ onSwitchView }: IProps) => {
  const { openedChat, openedChatId } = useAppSelector(selectInternalChatChatsState)
  const { messages, createdAtBefore, createdAtAfter, lastReadingAt, limit } = useAppSelector(
    selectInternalChatMessagesState
  )

  return openedChat ? (
    <div className={styles.messagingContainer}>
      <MessagingHeader
        id={openedChatId as number}
        onSwitchView={onSwitchView}
        name={`${openedChat.firstName} ${openedChat.lastName}`}
        icon={openedChat.logo}
        typing={openedChat?.typing as boolean}
        isOnline={checkIsOnlineStatus(openedChat.lastOnlineAt)}
      />
      <MessagesList
        messages={messages}
        createdAtBefore={createdAtBefore}
        createdAtAfter={createdAtAfter}
        lastReadingAt={lastReadingAt}
        limit={limit}
        openedChatId={openedChatId as number}
        openedChat={openedChat}
      />
    </div>
  ) : null
}
