import React, { useEffect, useState } from 'react'
import { IInternalReport } from '../../../../Reports/models/IInternalReport'
import { ISelectedFilters } from '../../../../../shared/components/filter/Filter'
import { ITableConf, SortingOrderEnum } from '../../../../../shared/models/ITableConf'
import { useTableExport } from '../../../../../shared/hooks/table/useTableExport'
import { IPopupListItems, Popup } from '../../../../../shared/components/popup/Popup'
import Table, { ColumnsType } from 'antd/es/table'
import { DateTableIcon, TableActionIcon } from '../../../../../assets/svg'
import {
  formatDateWithSeconds,
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  formatMoneyByCurrencyType,
  formatShamsiMomentToISO,
  getFormCalendarTime,
  moment,
  tableExpandedIcon,
} from '../../../../../helpers/utils'
import emptyUserImage from '../../../../../assets/img/ProductTablePlaceholder.png'
import styles from '../../../../Reports/pages/Transactions/style.module.scss'
import classNames from 'classnames'
import { useEmptyTable } from '../../../../../shared/hooks/table/useEmptyTable'
import { TableHeader } from './TableHeader'
import {
  useGetAgentTransactionsByIdQuery,
  useGetATFiltersListQuery,
  useLazyExportAgentTransactionsTableQuery,
} from '../../../core/http/AgentManagement'
import { ITransactionHistory } from '../../../models/ITransactionHistory'
import { useParams } from 'react-router'
import { useLazyGetSubTransactionsQuery } from '../../../../Reports/core/Subtransactions'
import { useOnTransactionViewClick } from '../../../../../shared/hooks/table/useOnTransactionViewClick'
import { camelCase, isEmpty, startCase } from 'lodash'
import {
  AVAILABLE_HISTORY_CATEGORIES,
  DEBIT_CREDIT_ENUM,
  TRANSACTION_CATEGORY_ENUM,
} from '../../../../Finance/models/ITransaction'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { useOnTransactionHistoryClick } from '../../../../../shared/hooks/table/useOnTransactionHistoryClick'
import { Moment } from 'moment/moment'
import { Dayjs } from 'dayjs'
import { RecursivelyReplaceCharacters } from '../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../shared/hooks/useLocalStorageGetByKey'

export const TransactionHistoryTable = () => {
  const { agentId } = useParams()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })

  const onTransactionViewClick = useOnTransactionViewClick()
  const onTransactionHistoryClick = useOnTransactionHistoryClick()
  const [expandedRowKeys, setExpandedRowKeys] = useState<Array<React.Key>>([])
  const [mappedTrans, setMappedTrans] = useState<Array<ITransactionHistory>>([])
  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)
  const [dateFilter, setDateFilter] = useState<ISelectedFilters>({})

  const { data: profile } = useGetProfileQuery()

  const { data: filtersList } = useGetATFiltersListQuery(agentId as string)
  const { data, isFetching } = useGetAgentTransactionsByIdQuery(
    {
      id: agentId as string,
      params: { ...tableConf, ...filterValues, ...dateFilter },
    },
    { skip: isEmpty(profile) || Object.keys(tableConf).length < 4 }
  )
  const [getSubTrans, getSubTransResp] = useLazyGetSubTransactionsQuery()
  const [exportTable] = useLazyExportAgentTransactionsTableQuery()

  useTableExport({
    tableConf,
    filterValues,
    promise: exportTable,
    promiseParams: { id: agentId as string },
  })

  const tableActionsPopup = (report: ITransactionHistory): IPopupListItems[] => [
    {
      text: 'View',
      shouldDisplay: true,
      onClick: () => onTransactionViewClick(report.id, report.objectType, report),
    },
    ...(AVAILABLE_HISTORY_CATEGORIES.includes(report.transactionCategory)
      ? [
          {
            text: 'History',
            shouldDisplay: true,
            onClick: () => onTransactionHistoryClick(report?.id, report?.transactionCategory),
          },
        ]
      : []),
  ]

  const columns: ColumnsType<ITransactionHistory> = [
    {
      title: '',
      dataIndex: '',
      key: 'expand',
      width: '1%',
      onCell: (record) => {
        return {
          onClick: async () => {
            const mappedRec = { ...record }
            const data = await getSubTrans({
              transactionCategory: record.objectType,
              id: record.id,
            })
            mappedRec.children = data?.data?.items

            setMappedTrans((prevState) =>
              prevState.map((trans) => {
                if (trans.id === mappedRec.id) {
                  return mappedRec
                }
                return trans
              })
            )
          },
        }
      },
    },
    {
      title: 'Transaction ID',
      dataIndex: 'id',
      sorter: true,
      width: '15%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      sorter: true,
      width: '15%',
      render: (name: ITransactionHistory['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithSeconds(name, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'transactionCategory',
      sorter: true,
      width: '10%',
      render: (transactionCategory) => (
        <RecursivelyReplaceCharacters>{transactionCategory}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Sender',
      dataIndex: 'sender',
      width: '15%',
      render: (sender: ITransactionHistory['sender'], row) => {
        if (
          row?.transactionCategory === TRANSACTION_CATEGORY_ENUM.TOPUP ||
          row?.transactionCategory === TRANSACTION_CATEGORY_ENUM.REVERSE
        ) {
          return <RecursivelyReplaceCharacters>{row?.sender}</RecursivelyReplaceCharacters>
        }
        if (Object.hasOwn(row, 'mainTransactionId')) {
          return (
            <RecursivelyReplaceCharacters>
              {startCase(row[sender]?.name?.toLowerCase())}
            </RecursivelyReplaceCharacters>
          )
        }

        return (
          <RecursivelyReplaceCharacters>
            {startCase(row[sender]?.name?.toLowerCase())}
          </RecursivelyReplaceCharacters>
        )
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: true,
      width: '10%',
      render: (amount, row) => {
        const moneyCount = formatMoneyByCurrencyType(amount, row?.drCr)

        return (
          <RecursivelyReplaceCharacters>
            <span
              className={classNames(styles.amountContainer, {
                [styles.dr]:
                  amount && row.drCr === DEBIT_CREDIT_ENUM.DEBIT && row.mainTransactionId,
                [styles.cr]:
                  amount && row.drCr === DEBIT_CREDIT_ENUM.CREDIT && row.mainTransactionId,
                [styles.zero]: !amount && row.mainTransactionId,
              })}
            >
              {(!Object.hasOwn(row, 'mainTransactionId')
                ? moneyCount?.replace(/\+/, '')
                : moneyCount) || 0}
            </span>
          </RecursivelyReplaceCharacters>
        )
      },
    },
    {
      title: 'Receiver',
      dataIndex: 'receiver',
      width: '15%',
      render: (receiver: ITransactionHistory['receiver'], row) => {
        if (
          row?.transactionCategory === TRANSACTION_CATEGORY_ENUM.TOPUP ||
          row?.transactionCategory === TRANSACTION_CATEGORY_ENUM.REVERSE
        ) {
          return <RecursivelyReplaceCharacters>{row?.receiver}</RecursivelyReplaceCharacters>
        }
        if (Object.hasOwn(row, 'mainTransactionId')) {
          return (
            <RecursivelyReplaceCharacters>
              {startCase(row[receiver]?.name?.toLowerCase())}
            </RecursivelyReplaceCharacters>
          )
        }

        return (
          <RecursivelyReplaceCharacters>
            {startCase(row[receiver]?.name?.toLowerCase())}
          </RecursivelyReplaceCharacters>
        )
      },
    },
    {
      title: 'Product',
      dataIndex: 'masterProduct',
      width: '15%',
      render: (masterProduct: ITransactionHistory['masterProduct'], row: ITransactionHistory) => (
        <RecursivelyReplaceCharacters>
          <div className='table-avatar'>
            <img
              src={!isPrivateMode && masterProduct?.logo ? masterProduct?.logo : emptyUserImage}
              alt='icon'
              loading='lazy'
            />
            <div>
              <span>{masterProduct?.name || 'eMoney'}</span>
              {row?.product && <div className={styles.escrowMark}>{row?.product?.name}</div>}
              {row?.vendor && <div className={styles.escrowMark}>{row?.vendor?.name}</div>}
            </div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Access Channel',
      dataIndex: 'transactionRequestAccessChannel',
      width: '20%',
      render: (channel: ITransactionHistory['transactionRequestAccessChannel'], row) => (
        <>
          <RecursivelyReplaceCharacters>{channel}</RecursivelyReplaceCharacters>
          {row?.agentIdentifier?.value && (
            <RecursivelyReplaceCharacters>
              <div className={styles.escrowMark}>{row?.agentIdentifier.value}</div>
            </RecursivelyReplaceCharacters>
          )}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: '10%',
      render: (status: IInternalReport['status']) => (
        <div className={classNames('statusContainer', `${status.toLowerCase()}-container`)}>
          <div className={classNames(camelCase(status.toLowerCase()), 'statusPoint')} />
          <RecursivelyReplaceCharacters>{status}</RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '10%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<ITransactionHistory>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
    setExpandedRowKeys,
  })

  const onDateChange = (value: Moment[] | null) => {
    if (!Array.isArray(value)) {
      return setDateFilter({})
    }

    const createdAt_more = !isEmpty(value[0])
      ? formatMomentDateToDayStart(
          formatShamsiMomentToISO(profile?.calendar, value[0])
        ).toISOString(true)
      : ''
    const createdAt_less = !isEmpty(value[1])
      ? formatMomentDateToDayEnd(formatShamsiMomentToISO(profile?.calendar, value[1])).toISOString(
          true
        )
      : ''

    return createdAt_less && createdAt_more && setDateFilter({ createdAt_less, createdAt_more })
  }

  useEffect(() => {
    if (data) {
      setMappedTrans(data.items)
    }
  }, [data])

  useEffect(() => {
    if (profile?.timeZone) {
      moment.tz?.setDefault(profile?.timeZone as string)

      setDateFilter({
        createdAt_more: formatMomentDateToDayStart(
          formatShamsiMomentToISO(profile?.calendar, moment())
        ).toISOString(true),
        createdAt_less: formatMomentDateToDayEnd(
          formatShamsiMomentToISO(profile?.calendar, moment())
        ).toISOString(true),
      })
    }
  }, [profile])

  return (
    <div className={styles.layout}>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filtersList}
        dateFilter={
          dateFilter?.createdAt_more && dateFilter?.createdAt_less
            ? [
                (getFormCalendarTime(
                  profile?.calendar,
                  dateFilter.createdAt_more as string
                ) as Moment) || Dayjs,
                (getFormCalendarTime(
                  profile?.calendar,
                  dateFilter.createdAt_less as string
                ) as Moment) || Dayjs,
              ]
            : undefined
        }
        onDateChange={onDateChange}
      />
      <Table
        locale={emptyTableConf}
        className={styles.table}
        onChange={handleTableChange}
        pagination={pagination}
        scroll={{ x: 1000 }}
        rowKey={(record) => record.id}
        rowSelection={{ type: 'checkbox' }}
        expandable={{
          expandedRowKeys,
          expandIcon: (props) => {
            if (Object.hasOwn(props.record, 'mainTransactionId')) return null
            if (props.record?.countSubTransaction === 0) return null
            return tableExpandedIcon<ITransactionHistory>(props)
          },
          onExpandedRowsChange: (expandedRows) => {
            setExpandedRowKeys([...expandedRows])
          },
        }}
        columns={columns}
        dataSource={mappedTrans}
        loading={isFetching || getSubTransResp.isFetching}
      />
    </div>
  )
}
