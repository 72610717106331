import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../shared/components/textfield/TextField'
import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import React from 'react'
import classNames from 'classnames'

interface IProps {
  onCreatePage: boolean
}

export const Details = ({ onCreatePage }: IProps) => {
  return (
    <Wrapper title='Details'>
      <div>
        <FormItem name='createdBy' label='Added By'>
          <TextField disabled />
        </FormItem>
      </div>

      <div>
        <FormItem
          name='createdAt'
          label='Creating Date'
          className={classNames({ noBottomMargin: onCreatePage })}
        >
          <TextField disabled />
        </FormItem>
      </div>

      {!onCreatePage && (
        <>
          <div>
            <FormItem name={['updatedByUser', 'name']} label='Updated By'>
              <TextField disabled />
            </FormItem>
          </div>

          <div>
            <FormItem name='updatedAt' label='Updated At' className='noBottomMargin'>
              <TextField disabled />
            </FormItem>
          </div>
        </>
      )}
    </Wrapper>
  )
}
