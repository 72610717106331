import { Table } from 'antd'
import { formatNumberToLocale } from '../../../../../salesNetwork/helpers'
import React from 'react'

interface IConstraint {
  currencyAmount: number
  amount: number
}

interface IProps<T extends IConstraint> {
  data: T[]
}

export const PickupRefundTableFooter = <T extends IConstraint>({ data = [] }: IProps<T>) => {
  const cashAmount = data?.reduce((prev, curr) => prev + curr.currencyAmount, 0)
  const emoneyAmount = data?.reduce((prev, curr) => prev + curr.amount, 0)

  return (
    <Table.Summary.Row className='table-footer-total'>
      <Table.Summary.Cell index={0} colSpan={5}>
        Total
      </Table.Summary.Cell>

      <Table.Summary.Cell index={1} colSpan={2}>
        <div>{formatNumberToLocale(cashAmount)}</div>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1} colSpan={5}>
        <div>{formatNumberToLocale(emoneyAmount)}</div>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  )
}
