import { Row, FormInstance } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import classNames from 'classnames'
import { useCallback } from 'react'
import { debounce, isEmpty } from 'lodash'

import { useLazyGetAgentByIdQuery } from 'src/features/salesNetwork/core/http/AgentManagement'
import { agentTypeValidator } from 'src/helpers/validation'
import { AGENT_TYPE_ID_ENUM } from 'src/features/Settings/models/IAgentType'
import { TextField } from 'src/shared/components/textfield/TextField'

import { Wrapper } from '../Wrapper'

// styles
import styles from './styles.module.scss'

import { IAgentAll } from '../../models/IAgent'

interface IParentAccount {
  form: FormInstance
}

const ParentAccount = ({ form }: IParentAccount) => {
  const [getAgentById, { data: parentAgent }] = useLazyGetAgentByIdQuery({})

  const debounceGetParent = useCallback(
    debounce(async (value) => {
      let agent
      let parent: IAgentAll | undefined
      let isParent: boolean | undefined
      let isIndirect: boolean | undefined
      if (value) {
        agent = await getAgentById(value)

        parent = agent.data as IAgentAll

        isParent = !isEmpty(parent)
        isIndirect = parent?.agentType?.id === AGENT_TYPE_ID_ENUM.INDIRECT
      }

      form.setFields([
        {
          name: 'parentId',
          errors: [
            ...(!isParent && !value ? [] : []),
            ...(!isParent && value ? [`Agent with id ${value} is not exist!`] : []),
            ...(isParent && !isIndirect ? ['Parent should has the Indirect agent type!'] : []),
            ...(isParent && parent?.agentLevel?.level === 5
              ? ["You can't select an agent with level 5!"]
              : []),
          ],
        },
        {
          name: 'parentName',
          value: isIndirect ? `${parent?.firstName} ${parent?.lastName}` : '',
        },
        {
          name: 'agentTypeId',
          value: isIndirect ? AGENT_TYPE_ID_ENUM.INDIRECT : null,
          errors: [],
        },
        {
          name: 'accountType',
          value: isIndirect ? { id: parent?.accountType?.id } : null,
        },
        {
          name: 'agentLevelId',
          value: isIndirect ? (parent?.agentLevel?.level || 0) + 1 : null,
        },
      ])
    }, 300),
    []
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    form.setFieldValue('parentId', value)
    debounceGetParent(value)
  }

  return (
    <Wrapper title='parent account' className={styles.wrapperParentAccount}>
      <Row justify='space-between'>
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const agentTypeId = getFieldValue('agentTypeId')
            const parentId = getFieldValue('parentId')

            const disable = !parentId && agentTypeId

            return (
              <div className={styles.formItemPrefix}>
                <FormItem
                  name='parentId'
                  label='Parent Account ID'
                  className={classNames(styles.formItemInp)}
                  rules={[
                    ...(parentAgent
                      ? [
                          {
                            validator: agentTypeValidator(parentAgent.agentType?.id),
                          },
                        ]
                      : []),
                  ]}
                >
                  <TextField
                    type='number'
                    prefix='ID'
                    placeholder='Parent Account ID'
                    disabled={disable}
                    onChange={onChange}
                  />
                </FormItem>
              </div>
            )
          }}
        </FormItem>

        <FormItem name='parentName' label='Parent Name' className={styles.formItemInp}>
          <TextField disabled placeholder='Parent Name' />
        </FormItem>
      </Row>
    </Wrapper>
  )
}
export default ParentAccount
