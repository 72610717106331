// icons
import { DateTableIcon, PersonIcon, TicksIcon } from 'src/assets/svg'

// components
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'

// helpers
import { IPurchaseOrderStatus } from 'src/features/Finance/models/IPurchaseOrder'
import { formatDateWithTime } from 'src/helpers/utils'

// styles
import styles from '../styles.module.scss'
import { nanoid } from 'nanoid'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

interface IProps {
  logs: Array<IPurchaseOrderStatus>
}

export const LogOfOrder = ({ logs }: IProps) => {
  const { data: profile } = useGetProfileQuery()

  return (
    <Wrapper title='Log of Order'>
      {logs?.map((log) => (
        <div className={styles.logOfOrderContainer} key={nanoid()}>
          <div className={styles.logStatusContainer}>
            <TicksIcon />
            {log.status}
          </div>
          <div className={styles.logNameContainer}>
            <PersonIcon />
            {log?.user?.name}
          </div>
          <div className={styles.logDateContainer}>
            <DateTableIcon />
            {formatDateWithTime(log.createdAt, profile?.calendar)}
          </div>
        </div>
      ))}
    </Wrapper>
  )
}
