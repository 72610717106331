import { FormInstance, Select as AntSelect, Select } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { ChangeEvent, ChangeEventHandler, useEffect, useMemo, useState } from 'react'
import TextArea from 'antd/es/input/TextArea'

// components
import { TextField } from 'src/shared/components/textfield/TextField'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'

// styles
import styles from '../styles.module.scss'

// helpers
import { positiveNumber, requiredValidator } from 'src/helpers/validation'

// interfaces
import { IBankAccountList, PAYMENT_METHOD_ENUM } from 'src/features/Finance/models/IBankAccount'
import Input from 'antd/es/input/Input'
import { useLazyGetCurrencyRateToCurrencyQuery } from '../../../../../../core/http/ExchangeRatesApi'
import { isEmpty } from 'lodash'
import { ErrorNode, handleError } from '../../../../../../../../shared/api/errorHandler'
import { AFN_CURRENCY_CODE } from '../../../../../../models/IPurchaseOrder'

const { Option } = AntSelect

interface IDetailInfo {
  form: FormInstance
  viewMode: boolean
  editMode: boolean
  bankAccountList: Array<IBankAccountList> | undefined
  onChangeAgent: ChangeEventHandler<HTMLInputElement>
  isPickupReq?: boolean
  bankAccountId?: number
  setDiscountAmount?: (value: number) => void
}

export const DetailInfo: React.FC<IDetailInfo> = ({
  form,
  viewMode,
  editMode,
  onChangeAgent,
  bankAccountList = [],
  isPickupReq = false,
  bankAccountId,
  setDiscountAmount,
}) => {
  const [exchangeRate, setExchangeRate] = useState<number>()
  const [getExRate, dExRate] = useLazyGetCurrencyRateToCurrencyQuery()

  const handleBankAccountChange = (props: number): void => {
    const bankAccount = bankAccountList.find((i) => props === i.id)
    form.setFieldsValue({
      currency: bankAccount?.currency,
      paymentType: bankAccount?.paymentMethod[0],
      exchangeRate: 0,
      currencyAmount: 0,
      amount: 0,
    })

    if (bankAccount?.currency.id === AFN_CURRENCY_CODE) {
      form.setFieldValue('exchangeRate', 1)
      return setExchangeRate(1)
    }

    if (bankAccount && bankAccount?.currency.id !== AFN_CURRENCY_CODE) {
      getExRate({ currencyId: bankAccount?.currency.id })
    }
  }

  const handleChangeAmount = (val: ChangeEvent<HTMLInputElement>) => {
    const amount = val.target.value
    if (exchangeRate) {
      form.setFieldValue('amount', (exchangeRate * +amount).toFixed(2))
      setDiscountAmount && setDiscountAmount(form.getFieldValue('amount'))
    }
  }

  const handleChangeEmoney = (val: ChangeEvent<HTMLInputElement>) => {
    const emoney = val.target.value
    if (exchangeRate) {
      form.setFieldValue('currencyAmount', (+emoney / exchangeRate).toFixed(2))
    }
  }

  useEffect(() => {
    if (isPickupReq && dExRate.isError) {
      handleError(dExRate.error as ErrorNode)
    }
  }, [dExRate.error, dExRate.isError, isPickupReq])

  useEffect(() => {
    if (isEmpty(dExRate.data)) return
    const exchangeRateResp = dExRate.data?.sellingRate
    setExchangeRate(exchangeRateResp)
  }, [dExRate.data])

  useEffect(() => {
    if (!bankAccountId) return
    const bankAccount = bankAccountList.find((i) => bankAccountId === i?.id)

    if (bankAccount?.currency?.id === AFN_CURRENCY_CODE) {
      return setExchangeRate(1)
    }

    if (bankAccount?.currency) {
      getExRate({ currencyId: bankAccount?.currency?.id as number })
    }
  }, [bankAccountList, form, bankAccountId, getExRate])

  useEffect(() => {
    const exchangeRate = form.getFieldValue('exchangeRate')
    if (exchangeRate) {
      setExchangeRate(exchangeRate)
    }
  }, [form])

  const paymentMethods = useMemo(
    () =>
      (Object.keys(PAYMENT_METHOD_ENUM) as Array<keyof typeof PAYMENT_METHOD_ENUM>).map(
        (method) => ({
          label: PAYMENT_METHOD_ENUM[method],
          value: PAYMENT_METHOD_ENUM[method],
        })
      ),
    []
  )

  const [filteredBankAccountList, setFilteredBankAccountList] = useState<
    { label: string; value: number }[]
  >([])

  const handlePaymentMethodChange = (props: PAYMENT_METHOD_ENUM): void => {
    form.setFieldValue(['bankAccount', 'id'], null)
    const list = bankAccountList
      .filter((bankAccount) => bankAccount.paymentMethod.includes(props))
      .map(({ id, name }) => ({ label: name, value: id }))

    setFilteredBankAccountList(list)
  }

  return (
    <Wrapper title='Detail info' className={styles.layout}>
      {(viewMode || editMode) && (
        <div className={styles.row}>
          <FormItem
            name='id'
            label='Transaction ID'
            rules={[{ required: true, validator: requiredValidator('Transaction ID') }]}
          >
            <TextField disabled />
          </FormItem>
        </div>
      )}

      <div className={styles.dblRow}>
        <FormItem
          name={['agent', 'id']}
          label='Request By'
          rules={[
            { required: true, validator: requiredValidator('Request By') },
            { validator: positiveNumber('Request By') },
          ]}
        >
          <TextField type='number' onChange={onChangeAgent} disabled={viewMode} />
        </FormItem>

        <FormItem name={['agent', 'name']} label='Requester Name'>
          <TextField disabled />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem name='parentAgentId' label='eMoney will be send to'>
          <TextField disabled />
        </FormItem>

        <FormItem name='parentAgentName' label='Parent Agent Name'>
          <TextField disabled />
        </FormItem>
      </div>

      <div>
        <FormItem
          name='paymentType'
          label='Payment Method'
          rules={[{ required: true, validator: requiredValidator('Payment Method') }]}
        >
          <AntSelect
            onChange={handlePaymentMethodChange}
            disabled={viewMode}
            allowClear
            placeholder='Select Payment Method'
            options={paymentMethods}
          ></AntSelect>
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem
          name={['bankAccount', 'id']}
          label='Bank Account'
          rules={[{ required: true, validator: requiredValidator('Bank Account') }]}
          shouldUpdate
        >
          <AntSelect
            onChange={handleBankAccountChange}
            disabled={viewMode}
            allowClear
            placeholder='Select Payment Method'
            options={filteredBankAccountList}
          ></AntSelect>
        </FormItem>

        <FormItem
          name='currencyAmount'
          label='Amount'
          rules={[
            { required: true, validator: requiredValidator('Amount') },
            { validator: positiveNumber('Amount') },
          ]}
        >
          <TextField type='number' disabled={viewMode} onChange={handleChangeAmount} />
        </FormItem>
      </div>

      <span>
        <FormItem name='paymentType' noStyle shouldUpdate>
          <Input style={{ display: 'none' }} />
        </FormItem>
      </span>

      {!isPickupReq && (
        <div className={styles.row}>
          <FormItem
            name='amount'
            label='eMoney Amount'
            rules={[{ validator: positiveNumber('eMoney Amount') }]}
          >
            <TextField type='number' disabled={viewMode} onChange={handleChangeEmoney} />
          </FormItem>
        </div>
      )}

      {viewMode && (
        <div className={styles.row}>
          <FormItem name='discountRate' label='Commission rate'>
            <TextField disabled />
          </FormItem>
        </div>
      )}

      <div className={styles.row}>
        <FormItem name='note' label='Note'>
          <TextArea disabled={viewMode} />
        </FormItem>
      </div>
    </Wrapper>
  )
}
