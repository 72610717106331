import { Col, Form, Row, Space, Spin, UploadFile } from 'antd'

import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import * as C from './components'
import { Button } from 'src/shared/components/button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { UploaderPhotos2 } from 'src/shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import { RcFile } from 'antd/lib/upload'

import { ErrorNode } from 'src/shared/api/errorHandler'

import { StepRegistration } from 'src/shared/components/stepRegistration'
import { useCreateVendorContactMutation } from 'src/features/Settings/core/http/VendorManagementApi'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { formatOnlyDate } from '../../../../../helpers/utils'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import { ContactInformation } from 'src/features/salesNetwork/components'
import { isEmpty } from 'lodash'
import { EmailFilledIcon, PhoneDarkBlueIcon, SkypeIcon, WhatsappIcon } from 'src/assets/svg'
import { CONTACT_TYPE_NEW } from 'src/features/salesNetwork/models/IAgent'
import { SETTINGS_ENUM } from '../../../../../routes/settings'

const initialValues = {
  name: null,
  department: null,
  position: null,
  contactType: null,
  contactInfo: [],
}

export const CONTACTS_OPTIONS = [
  {
    icon: <EmailFilledIcon />,
    active: true,
    agentIdentifier: CONTACT_TYPE_NEW.EMAIL,
  },
  {
    icon: <PhoneDarkBlueIcon />,
    active: true,
    agentIdentifier: CONTACT_TYPE_NEW.MOBILE,
  },
  {
    icon: <WhatsappIcon />,
    active: true,
    agentIdentifier: CONTACT_TYPE_NEW.WHATSAPP,
  },
  {
    icon: <SkypeIcon />,
    active: true,
    agentIdentifier: CONTACT_TYPE_NEW.SKYPE,
  },
]

export const VendorManagementCreateNewContact = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { mode } = useParams()

  const [agentStatus, setAgentStatus] = useState(true)
  const [avatarFile, serAvatarFile] = useState<RcFile>()

  const { data, isFetching } = useGetProfileQuery()

  const [createContact, dCreate] = useCreateVendorContactMutation()

  const view = mode === 'view'

  const afterCreate = () => {
    navigate('/settings/vendor-management/new', { state: { isOpenModal: true } })
  }

  useNotification(NOTIFICATION_TYPES.success, dCreate.isSuccess, null, afterCreate)
  useNotification(NOTIFICATION_TYPES.error, dCreate.isError, dCreate.error as ErrorNode)

  useEffect(() => {
    if (isEmpty(data)) return

    form.setFieldValue('createdBy', `${data?.firstName} ${data?.lastName}`)
    form.setFieldValue('createdAt', formatOnlyDate(new Date(), data?.calendar))
  }, [form, data])

  const handleCancel = () => {
    navigate(`/settings/vendor-management`)
  }

  const onFinish = (values: typeof initialValues) => {
    const requestObj = {
      ...values,
      status: agentStatus,
      contacts: values.contactInfo,
    }

    const fData = new FormData()

    fData.append('contact', JSON.stringify(requestObj))
    fData.append('avatar', avatarFile!)

    createContact(fData)
  }

  const content = (
    <main className={styles.main}>
      <div className={styles['agent-management-new-agent']}>
        <Form form={form} layout='vertical' initialValues={initialValues} onFinish={onFinish}>
          <StepRegistration stepNumber='1' stepText='General information' mB='32px' />

          <Row wrap={false} justify='start'>
            <Col className={styles['wrapper-column']}>
              <C.DetailInfo
                setAgentStatus={setAgentStatus}
                agentStatus={agentStatus}
                create
                form={form}
                view={view}
              />
              <StepRegistration stepNumber='2' stepText='Contacts' mB='32px' />

              {/* Imported component from salesNetwork */}
              <ContactInformation form={form} view={view} options={CONTACTS_OPTIONS} />
            </Col>
            <div>
              <UploaderPhotos2
                form={form}
                title='Profile Picture'
                view={false}
                onChange={(photo: UploadFile[]) => {
                  serAvatarFile(photo[0].originFileObj)
                }}
              />
            </div>
          </Row>
        </Form>
      </div>
      <div className={styles.wrapper}>
        <Row className={styles.footer} justify='end'>
          <Space size={10}>
            <Button color='blue' onClick={handleCancel} size='middle'>
              Cancel
            </Button>

            <Button
              color='blue'
              htmlType='submit'
              onClick={() => form.submit()}
              size='middle'
              type='primary'
            >
              Create Contact
            </Button>
          </Space>
        </Row>
      </div>
    </main>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
