import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { saveAs } from 'file-saver'
import { useCallback, useEffect } from 'react'
import { Emitter, EmitterEvents } from '../../../helpers/eventEmitter'
import {
  IWalletStatementPDFReport,
  WALLET_STATEMENT_CAT_ROUTE_ENUM,
} from '../../../features/Finance/models/IWalletStatement'
import { ISelectedFilters } from '../../components/filter/Filter'
import ShowMessage from '../../api/errorHandler'

interface IUseTableExport {
  route: WALLET_STATEMENT_CAT_ROUTE_ENUM
  // eslint-disable-next-line
  promise: LazyQueryTrigger<any>
  filterValues?: ISelectedFilters
}

export const usePdfExport = ({ route, promise, filterValues }: IUseTableExport) => {
  const handler = useCallback(
    async (
      conf: { route: WALLET_STATEMENT_CAT_ROUTE_ENUM; params: ISelectedFilters },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      promise: LazyQueryTrigger<unknown>
    ) => {
      if (!Object.values(conf.params!)?.length) {
        return ShowMessage('error', 'Select date range to generate report!')
      }

      Emitter.emit(EmitterEvents.LOADING_PDF)
      const exportData = (await promise(conf)) as {
        data: IWalletStatementPDFReport
      }

      Emitter.emit(EmitterEvents.LOADED_PDF)
      saveAs(exportData.data?.link)
    },
    []
  )

  useEffect(() => {
    Emitter.on(EmitterEvents.GENERATE_PDF, () => {
      handler({ route, params: filterValues || {} }, promise)
    })

    return () => {
      Emitter.off(EmitterEvents.GENERATE_PDF)
    }
  }, [route, promise, handler, filterValues])
}
