import React, { Dispatch, SetStateAction } from 'react'
import { ITableConf } from '../../../../../../../shared/models/ITableConf'
import styles from './styles.module.scss'
import { ISimCardTable } from '../../index'
import { ITableResponse } from '../../../../../../../shared/models/ITableResponse'
import { formatNumberToLocale } from '../../../../../../salesNetwork/helpers'
import classNames from 'classnames'
import { maskPhoneNumberByAsterisk, tableExpandedIcon } from '../../../../../../../helpers/utils'
import { TableWrapper } from 'src/features/salesNetwork/pages/AgentManagementDetailView/PolicyTab/TableWrapper'
import { useEmptyTable } from 'src/shared/hooks/table/useEmptyTable'
import { useTablePagination } from 'src/shared/hooks/table/useTablePagination'
import { useOnTableChange } from 'src/shared/hooks/table/useOnTableChange'
import { ColumnsType } from 'antd/es/table'
import { Table } from 'antd'

interface IProps {
  data: ITableResponse<ISimCardTable>
  tableConf: ITableConf
  setTableConf: Dispatch<SetStateAction<ITableConf>>
}

export const SimCardList = ({ data, tableConf, setTableConf }: IProps) => {
  const columns: ColumnsType<ISimCardTable> = [
    Table.EXPAND_COLUMN,
    {
      title: 'Vendor',
      dataIndex: 'simNumber',
      width: '35%',
      className: styles.vendorName,
      render: (simNumber: ISimCardTable['simNumber']) => (
        <span>{maskPhoneNumberByAsterisk(simNumber)}</span>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      width: '30%',
      render: (balance) => <span>{formatNumberToLocale(balance)}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      width: '35%',
      render: (status, row) => (
        <div>
          <span
            className={classNames(styles.statusCircle, {
              [styles.activeStatus]: status,
              [styles.inactiveStatus]: !status,
              [styles.negativeBalance]: row.balance < 0,
            })}
          />
        </div>
      ),
    },
  ]

  const emptyTableConf = useEmptyTable()
  const paginationConfig = useTablePagination(data?.totalCount, false, 10)
  const onTableParamsChange = useOnTableChange<ISimCardTable>(setTableConf)

  data = {
    totalCount: 2,
    currentPage: 1,
    items: [
      {
        id: '90582432',
        simNumber: '93784357245',
        status: true,
        balance: 1060,
        children: [
          {
            id: '90582432',
            simNumber: '93784357245',
            status: true,
            balance: 1060,
          },
        ],
      },
      {
        id: '90833520',
        simNumber: '93785280280',
        status: true,
        balance: 14840,
      },
    ],
  }

  return (
    <TableWrapper title='Vendors'>
      <Table
        columns={columns}
        locale={emptyTableConf}
        onChange={onTableParamsChange}
        dataSource={data?.items}
        pagination={paginationConfig}
        rowKey={(record) => record.id}
        className={styles.table}
        expandable={{
          expandIcon: (props) => {
            if (!props.record?.children?.length) return null
            return tableExpandedIcon<ISimCardTable>(props)
          },
        }}
      />
    </TableWrapper>
  )
}
