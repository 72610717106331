import { memo, useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tabs } from 'antd'

import { AddIcon, CloudDownloadIcon } from 'src/assets/svg'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { Button } from 'src/shared/components/button/Button'
import WithPermissionDeniedContainer from '../../../../../../shared/components/withPermissionDenied'

import { CAMPAIGNS_TABS_ENUM } from './index.d'
import { CENTER_MODALS } from '../../../../../../helpers/contants'
import { CenterModalContext } from '../../../../../Modals'
import { Emitter, EmitterEvents } from '../../../../../../helpers/eventEmitter'
import usePermissions from '../../../../../../shared/hooks/usePermissions'
import { TableExportFormatEnum } from '../../../../../../shared/hooks/table/useTableExport'
import { IPermissionsActions, IPermissionsNames } from 'src/features/Settings/models/IPermissions'
import { ScheduleNotificationList } from '../../ScheduleNotificationList'

interface IPropsOperations {
  canAddNew: boolean
  canExport: boolean
  onAddButtonClick: () => void
}

const Operations = memo(({ onAddButtonClick, canAddNew, canExport }: IPropsOperations) => {
  const { onOpen, onClose } = useContext(CenterModalContext)

  const handlerExportClick = () => {
    onOpen(CENTER_MODALS.EXPORT_TABLE, {
      title: 'Export Table',
      onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
      onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      footer: <Button onClick={onClose}>Cancel</Button>,
    })
  }

  return (
    <div style={{ display: 'flex', gridGap: 10 }}>
      {canExport && (
        <IconButton
          icon={<CloudDownloadIcon />}
          type='primary'
          color='blue'
          disabled={!canExport}
          onClick={handlerExportClick}
        />
      )}
      {canAddNew && (
        <IconButton
          disabled={!canAddNew}
          icon={<AddIcon />}
          type='primary'
          color='orange'
          onClick={onAddButtonClick}
        />
      )}
    </div>
  )
})

export const CampaignsTabs = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<string>(CAMPAIGNS_TABS_ENUM.CAMPAIGNS)

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()

  const [notificationsId] = getModulesIdsByNames([IPermissionsNames['Schedule Notification']])

  const demandPermissions = [
    IPermissionsActions.VIEW,
    IPermissionsActions.ADD,
    IPermissionsActions.EXPORT,
    IPermissionsActions.EDIT,
    IPermissionsActions.DELETE,
  ]

  const [
    canShowNotifications,
    canAddNotifications,
    canExportNotifications,
    canEditNotifications,
    canDeleteNotifications,
  ] = canPerformAction(notificationsId, demandPermissions)

  /** Table */
  const currentTabData = useMemo(() => {
    if (activeTab === CAMPAIGNS_TABS_ENUM.CAMPAIGNS) {
      return {
        navigateTo: '/sales-network/campaign/new',
        canAdd: canAddNotifications,
        canExport: false,
      }
    }
    return {
      canAdd: false,
      canExport: false,
    }
  }, [activeTab, canAddNotifications, canExportNotifications])

  const items = useMemo(
    () => [
      {
        label: CAMPAIGNS_TABS_ENUM.CAMPAIGNS,
        key: CAMPAIGNS_TABS_ENUM.CAMPAIGNS,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canShowNotifications}>
            <ScheduleNotificationList
              canEdit={canEditNotifications}
              canDelete={canDeleteNotifications}
            />
          </WithPermissionDeniedContainer>
        ),
      },
    ],
    [canShowNotifications]
  )

  const onAddButtonClick = useCallback(() => {
    if (!currentTabData?.navigateTo) return

    navigate(currentTabData.navigateTo)
  }, [currentTabData])

  return (
    <Tabs
      destroyInactiveTabPane
      tabBarExtraContent={
        <Operations
          onAddButtonClick={onAddButtonClick}
          canAddNew={currentTabData.canAdd}
          canExport={currentTabData.canExport}
        />
      }
      onChange={setActiveTab}
      items={items}
    />
  )
}
