import FormItem from 'antd/es/form/FormItem'
import { Wrapper } from 'src/features/salesNetwork/components/Wrapper'
import { Toggle } from '../../../../../../../shared/components/toggle/Toggle'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import React, { useMemo } from 'react'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import { GENDER_TYPE_ENUM } from '../../../../../models/ICustomer'
import { startCase } from 'lodash'
import { requiredValidator } from '../../../../../../../helpers/validation'
import { Form, Select, Select as AntSelect } from 'antd'

export const DetailInfo = ({ view }: IViewMode) => {
  const genderType = useMemo(
    () =>
      (Object.keys(GENDER_TYPE_ENUM) as Array<keyof typeof GENDER_TYPE_ENUM>).map((method) => ({
        label: startCase(GENDER_TYPE_ENUM[method].toLowerCase()),
        value: GENDER_TYPE_ENUM[method],
      })),
    []
  )

  const statusButton = (
    <FormItem name='status' valuePropName='checked'>
      <Toggle text='Status' inWrapper disabled={view} />
    </FormItem>
  )
  return (
    <Wrapper title='Detail Info' statusButton={statusButton}>
      <FormItem
        name='title'
        label='Title'
        rules={[{ required: true, validator: requiredValidator('Title') }]}
      >
        <TextField disabled={view} placeholder='Enter Title' />
      </FormItem>

      <FormItem
        name='gender'
        label='Gender'
        rules={[{ required: true, validator: requiredValidator('Gender') }]}
      >
        <AntSelect allowClear placeholder='Select Gender' disabled={view} options={genderType} />
      </FormItem>

      <Form.Item
        name='hasFirstName'
        label='Has First Name'
        rules={[{ required: true, validator: requiredValidator('Has First Name') }]}
      >
        <Select disabled={view} allowClear placeholder='Has First Name'>
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name='hasLastName'
        label='Has Last Name'
        rules={[{ required: true, validator: requiredValidator('Has Last Name') }]}
      >
        <Select disabled={view} allowClear placeholder='Has Last Name'>
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name='hasPhoto'
        label='Has Photo'
        rules={[{ required: true, validator: requiredValidator('Has Photo') }]}
      >
        <Select disabled={view} allowClear placeholder='Has Photo'>
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name='isGroupOwner'
        label='Is Group Owner'
        rules={[{ required: true, validator: requiredValidator('Is Group Owner') }]}
        className='noBottomMargin'
      >
        <Select disabled={view} allowClear placeholder='Is Group Owner'>
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      </Form.Item>
    </Wrapper>
  )
}
