import React from 'react'
import { Widget } from 'src/shared/components/widget'
import {
  BalanceIcon,
  BankIcon,
  BarChartOutlinedIcon,
  PurchaseOrdersIcon,
  SwapCircleIcon,
  UserGroupRounded,
} from '../../../../../../assets/svg'
import { WIDGET_LIST_ENUM } from 'src/shared/models/WidgetListEnum'
import styles from '../../styles.module.scss'
import { FinanceDashboardWidgetStateType } from '../../index'

interface IProps {
  widgetData: FinanceDashboardWidgetStateType
}

export const FinanceOverviewWidgets = ({ widgetData }: IProps) => {
  return (
    <div className={styles.widgetsContainer}>
      <div className={styles.widgetsWrapper}>
        <Widget
          icon={<BankIcon />}
          title='Bank Account Balance'
          value={widgetData[WIDGET_LIST_ENUM.BANK_ACCOUNT_BALANCE].value}
          statistic={widgetData[WIDGET_LIST_ENUM.BANK_ACCOUNT_BALANCE].statistic}
        />
        <Widget
          icon={<BalanceIcon />}
          title='Total Vendor Stocks'
          value={widgetData[WIDGET_LIST_ENUM.INVENTORY]?.value}
          statistic={widgetData[WIDGET_LIST_ENUM.INVENTORY]?.statistic}
        />
        <Widget
          icon={<UserGroupRounded />}
          title='Floating eMoney'
          value={widgetData[WIDGET_LIST_ENUM.TOTAL_FLOATING_BALANCE]?.value}
          valueTotal={widgetData[WIDGET_LIST_ENUM.TOTAL_FLOATING_BALANCE].valueTotal}
          statistic={widgetData[WIDGET_LIST_ENUM.TOTAL_FLOATING_BALANCE].statistic}
        />
      </div>
      <div className={styles.widgetsWrapper}>
        <Widget
          icon={<PurchaseOrdersIcon />}
          title='Purchase Orders'
          value={widgetData[WIDGET_LIST_ENUM.PURCHASE_ORDERS].value}
          valueTotal={widgetData[WIDGET_LIST_ENUM.PURCHASE_ORDERS].valueTotal}
        />
        <Widget
          icon={<SwapCircleIcon />}
          title='Pickups'
          value={widgetData[WIDGET_LIST_ENUM.TOTAL_PICKUP_TODAY].value}
          statistic={widgetData[WIDGET_LIST_ENUM.TOTAL_PICKUP_TODAY].statistic}
          valueTotal={widgetData[WIDGET_LIST_ENUM.TOTAL_PICKUP_TODAY].valueTotal}
        />
        <Widget
          icon={<BarChartOutlinedIcon />}
          title='P&L'
          value={widgetData[WIDGET_LIST_ENUM.GENERATED_PROFIT].value}
          statistic={widgetData[WIDGET_LIST_ENUM.GENERATED_PROFIT].statistic}
          valueColor={
            Number(widgetData[WIDGET_LIST_ENUM.GENERATED_PROFIT].value) > 0
              ? 'green'
              : Number(widgetData[WIDGET_LIST_ENUM.GENERATED_PROFIT].value) < 0
              ? 'red'
              : 'white'
          }
        />
      </div>
    </div>
  )
}
