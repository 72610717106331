import React, {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  memo,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { isArabic } from '../../../../../../../../../../helpers/utils'
import classNames from 'classnames'
import styles from '../../styles.module.scss'

interface IProps {
  message: string
  onSendMessage: () => void
  onChangeMessage: (e: ChangeEvent<HTMLTextAreaElement>) => void
  setMessage: Dispatch<SetStateAction<string>>
}

const SubmitInput = ({ message, onSendMessage, onChangeMessage, setMessage }: IProps) => {
  const [arabic, setArabic] = useState(false)
  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const textarea = e.currentTarget
    const selectionStart = textarea.selectionStart
    const selectionEnd = textarea.selectionEnd

    if (e.key === 'Enter') {
      if (!e.metaKey && !e.shiftKey) {
        e.preventDefault()
        onSendMessage()
      } else if (e.shiftKey) {
        e.preventDefault()

        // Insert a new line at the cursor position
        const newMessage =
          message.substring(0, selectionStart) + '\n' + message.substring(selectionEnd)
        setMessage(newMessage)

        setTimeout(() => {
          textarea.selectionStart = selectionEnd + 1
          textarea.selectionEnd = selectionEnd + 1
        }, 0)
      }
    }

    setTimeout(() => {
      textarea.style.height = '24px'
      textarea.style.height = `${textarea.scrollHeight}px`
      const wrapper = document.querySelector(
        '#ext-chat-add-message-input-container'
      ) as HTMLDivElement
      wrapper.style.height = `48px`
      wrapper.style.height = `${textarea.scrollHeight + 14}px`
    }, 0)

    setTimeout(() => {
      textarea.scrollTop = textarea.scrollHeight
    }, 0)
  }

  const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (isArabic(e.target.value)) {
      setArabic(true)
      return onChangeMessage(e)
    }
    setArabic(false)
    onChangeMessage(e)
  }

  useEffect(() => {
    const textarea = document.querySelector('#external-chat-input-field') as HTMLTextAreaElement
    const wrapper = document.querySelector(
      '#ext-chat-add-message-input-container'
    ) as HTMLDivElement

    if (!message.length) {
      textarea.style.height = '24px'
      wrapper.style.height = `48px`
    }

    const onInput = () => {
      textarea.style.height = '24px'
      textarea.style.height = `${textarea.scrollHeight}px`
      wrapper.style.height = `48px`
      wrapper.style.height = `${textarea.scrollHeight + 14}px`
    }

    textarea.addEventListener('input', onInput)

    return () => textarea.removeEventListener('input', onInput)
  }, [message.length])

  return (
    <textarea
      placeholder='Write a message...'
      value={message}
      onKeyDown={onKeyDown}
      onChange={handleMessageChange}
      className={classNames({ [styles.arabic]: arabic })}
      id='external-chat-input-field'
    />
  )
}

export default memo(SubmitInput)
