import { Col } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import classNames from 'classnames'
import { ReactNode } from 'react'
import { useParams } from 'react-router'
import { Toggle } from 'src/shared/components/toggle/Toggle'
import { useGetAgentByIdQuery } from '../../core/http/AgentManagement'
import styles from './styles.module.scss'
import { RecursivelyReplaceCharacters } from '../../../../shared/components/privateMode'

interface IHeaderCard {
  color?: 'blue' | 'orange'
  cardName: string
  cardValue: string
  parentName?: ReactNode
  fontSize?: number
  right?: ReactNode
  letterSpacing?: number | string
  isMpin?: boolean
  loading?: boolean
  isDeleted?: boolean
  onChangedMPinStatus?: (status: boolean) => void
}

const HeaderCard = ({
  cardName,
  cardValue,
  parentName,
  right,
  fontSize = 20,
  color = 'orange',
  letterSpacing,
  isMpin,
  loading,
  isDeleted,
  onChangedMPinStatus,
}: IHeaderCard) => {
  const { agentId } = useParams()
  const { data: dataAgent, isFetching: isFetchingDataAgent } = useGetAgentByIdQuery(agentId, {
    skip: !agentId,
  })
  const handleChangeStatus = (status: boolean) => {
    onChangedMPinStatus?.(status)
  }

  return (
    <RecursivelyReplaceCharacters>
      <Col
        className={classNames(styles['header-card'], {
          [styles['header-flex-column-direction']]: isMpin,
        })}
      >
        {isMpin && (
          <div className={styles['header-inner-container']}>
            <div className={classNames(styles['left'], styles['left-mpin'])}>MPIN ACTIVATION</div>
            <div className={classNames(styles['right'], styles['right-mpin'])}>
              <FormItem name='mPinActivation'>
                <Toggle
                  disabled={isDeleted}
                  checked={dataAgent?.mPinActivation}
                  loading={loading || isFetchingDataAgent}
                  onChange={handleChangeStatus}
                  inWrapper
                  text={dataAgent?.mPinActivation ? 'Active' : 'Inactive'}
                />
              </FormItem>
            </div>
          </div>
        )}

        <div className={styles['header-inner-container']}>
          <div className={styles['left']}>{cardName}</div>
          <div className={classNames(styles['left'], styles['header-flex-column-direction'])}>
            <div
              className={classNames(styles[`center-${color}`])}
              style={{ fontSize, letterSpacing, fontWeight: 'bold' }}
            >
              {cardValue}
            </div>
            {parentName && <div className={styles['parentName']}>{parentName}</div>}
          </div>
          {right && <div className={styles['right']}>{right}</div>}
        </div>
      </Col>
    </RecursivelyReplaceCharacters>
  )
}
export default HeaderCard
