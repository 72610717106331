import { FormInstance, Form } from 'antd'
import classNames from 'classnames'
import { maxLengthValidator256 } from 'src/helpers/validation'

import { TextEditor } from 'src/shared/components/textEditor'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'

import styles from '../../style.module.scss'

interface IReplyToTicket {
  form: FormInstance
  viewMode: boolean
  reply: string
}

export const ReplyTicket = ({ form, viewMode, reply }: IReplyToTicket) => {
  return (
    <Wrapper
      title='Reply to ticket'
      className={classNames(styles.mb24, styles.replyTicketContainer)}
    >
      <Form.Item name='reply' rules={[{ validator: maxLengthValidator256 }]}>
        <TextEditor form={form} name='reply' viewMode={viewMode} initialContent={reply} />
      </Form.Item>
    </Wrapper>
  )
}
