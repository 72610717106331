import styles from '../Tabs/RolesAndPermissions/RolesManagementNew/Components/RolesAndPermissionsManagementUsersList/styles.module.scss'
import emptyAvatarImage from '../../../../../../assets/img/Avatar.png'
import { Button } from '../../../../../../shared/components/button/Button'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { ArrowRightIcon, BucketIcon } from '../../../../../../assets/svg'
import React from 'react'
import { IUser } from '../../../../models/IUser'

interface IProps {
  user: IUser
  disabled?: boolean
  isSelected: boolean
  onPressSelect: () => void
  onPressViewUser: () => void
  isCreating?: boolean
}

const UserModalRow = ({
  user,
  disabled,
  isSelected,
  onPressSelect,
  onPressViewUser,
  isCreating,
}: IProps) => {
  return (
    <div className={styles.user}>
      <div className={styles.userContent}>
        <div className={styles.userAvatar}>
          <img alt='product logo' src={user.logo ?? emptyAvatarImage}></img>
        </div>
        <div className={styles.userDescriptions}>
          <div className={styles.userName}>{`${user.firstName ?? ''} ${user.lastName ?? ''}`}</div>
          <div className={`${styles.userDepartmentAndPosition} ${styles.userPositionName}`}>
            {user.userPosition?.name}
          </div>
          <div className={styles.userDepartmentAndPosition}>
            <div
              className={`${styles.circle} ${
                !user.department ? styles.blueCircle : styles.redCircle
              }`}
            />
            <div className={styles.userDepartmentName}>{user.department?.name}</div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', gridGap: 10 }}>
        {isCreating ? (
          <IconButton color='orange' icon={<BucketIcon />} onClick={onPressSelect} />
        ) : (
          <>
            <div>
              <Button
                disabled={disabled || isSelected}
                color='orange'
                type={isSelected ? 'primary' : 'default'}
                onClick={onPressSelect}
              >
                Select
              </Button>
            </div>
            <div>
              <IconButton color='blue' icon={<ArrowRightIcon />} onClick={onPressViewUser} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default UserModalRow
