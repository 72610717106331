import { IByUser } from '../../../shared/models/IByUser'

export enum NOTIFICATION_MESSAGE_CHANNEL_ENUM {
  ADMIN_PORTAL = 'Admin Portal',
  ADMIN_MOBILE_APP = 'Admin Mobile App - Android',
  AGENT_PORTAL = 'Agent Portal',
  AGENT_MOBILE_APP_ANDROID = 'Agent Mobile App - Android',
  AGENT_MOBILE_APP_IOS = 'Agent Mobile App - iOS',
  API = 'API',
  CORPORATE_PORTAL = 'Corporate Portal',
  POS = 'POS',
  SYSTEM = 'System',
  CUSTOMER_PORTAL = 'Customer Portal',
  CUSTOMER_MOBILE_APP_IOS = 'Customer Mobile App - iOS',
  CUSTOMER_MOBILE_APP_ANDROID = 'Customer Mobile App - Android',
  USSD = 'USSD',
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
}

export enum NOTIFICATION_MESSAGE_ACTION_ENUM {
  TOPUP = 'Topup',
  BUNDLE_TOPUP = 'Bundle Topup',
  SEND_EMONEY = 'Send eMoney',
  BALANCE_CHECK = 'Balance Check',
  REVERSE_TOPUP = 'Reverse Topup',
  MY_SALES = 'My Sales',
  MY_BENEFITS = 'My Benefits',
  RESET_MPIN = 'Reset MPIN',
  MPIN_ACTIVATION = 'MPIN Activation',
  MPIN_DEACTIVATION = 'MPIN DeActivation',
  ADD_NEW_PHONE_IDENTIFIER = 'Add new phone identifier',
  REMOVE_PHONE_IDENTIFIER = 'Remove phone identifier',
  PHONE_IDENTIFIER_VERIFICATIONS = 'Phone identifier verification',
  PICKUP_REQUEST = 'Pickup request',
  AGENT_REGISTRATION = 'Agent registration',
}

export enum NOTIFICATION_MESSAGE_TRANSACTION_STATUS_ENUM {
  SUCCESS = 'Success',
  IN_PROCESS = 'In Process',
  FAILED = 'Failed',
}

export enum RECEIVER_ENUM {
  SENDER = 'sender',
  RECEIVER = 'receiver',
}

export enum MESSAGE_AVAILABLE_CHANNEL_ENUM {
  USSD = 'USSD',
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
}

export interface IMessage {
  id: string
  channel: MESSAGE_AVAILABLE_CHANNEL_ENUM
  en: string
  prs: string
  ps: string
}

export interface INotificationMessage {
  id: number
  name: string
  action: NOTIFICATION_MESSAGE_ACTION_ENUM
  transactionStatus: NOTIFICATION_MESSAGE_TRANSACTION_STATUS_ENUM
  receiver: RECEIVER_ENUM
  messages: IMessage[]
  createdByUser: IByUser
  updatedByUser: IByUser
  createdAt: string
  updatedAt: string
}

export interface INotificationMessageTable {
  id: number
  name: string
  createdAt: string
  action: NOTIFICATION_MESSAGE_ACTION_ENUM
  transactionStatus: NOTIFICATION_MESSAGE_TRANSACTION_STATUS_ENUM
  receiver: RECEIVER_ENUM
  channels: Array<{ channel: NOTIFICATION_MESSAGE_CHANNEL_ENUM }>
}

export interface INotificationManagerCreate {
  name: string
  action: NOTIFICATION_MESSAGE_ACTION_ENUM
  transactionStatus: NOTIFICATION_MESSAGE_TRANSACTION_STATUS_ENUM
  receiver: RECEIVER_ENUM
  messages: IMessage[]
}

export interface INotificationMessageVariable {
  name: string
  isTable: boolean
  variables: Array<{
    name: string
    isTable: boolean
  }>
}
