import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment, { Moment } from 'moment'
import { debounce, first, isEmpty, last } from 'lodash'

import ProfileNotification from './Components/Notification'
import NotificationsHeader from './Components/NotificationsHeader'
import { ISelectedFilters } from 'src/shared/components/filter/Filter'
import { InfiniteScrollComponent } from 'src/shared/components/infiniteScoll/InfiniteScroll'
import {
  notificationApi,
  useGetNotificationsFiltersListQuery,
  useGetNotificationsListQuery,
  useLazyGetUnreadNotificationsCounterQuery,
  useMarkNotificationAsReadMutation,
} from '../../core/http/Notification'

import { ITableConf, SortingOrderEnum } from 'src/shared/models/ITableConf'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import {
  addNotifications,
  addNotificationsUnreadCounter,
  clearNotifications,
  selectNotificationsState,
  updateNotificationsReadStatus,
} from '../../core/store/Notifications'
import { NotFoundPage } from '../../../NotFoundPage'
import { IProfileNotification } from '../../core/models'
import styles from './styles.module.scss'

export const ProfileNotifications = () => {
  const dispatch = useAppDispatch()

  const [filterValues, setFilterValues] = useState<ISelectedFilters>({})
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 12,
    orderType: SortingOrderEnum.DESC,
  })

  const { notifications } = useAppSelector(selectNotificationsState)

  const { data = { items: [], totalCount: 0 } } = useGetNotificationsListQuery({
    ...tableConf,
    ...filterValues,
  })
  const { data: filtersList } = useGetNotificationsFiltersListQuery()
  const [getUnreadCounter, unreadCounterResp] = useLazyGetUnreadNotificationsCounterQuery()

  const [markAsRead, markAsReadResp] = useMarkNotificationAsReadMutation()

  const loadMoreData = () => {
    setTableConf((pre: ITableConf) => {
      return { ...pre, page: pre.page! + 1 }
    })
  }

  // TODO:
  const onPressAccept = useCallback(() => {}, [])

  // TODO:
  const onPressDecline = useCallback(() => {}, [])

  // TODO:
  const onSearchChange = useCallback(() => {}, [])

  // TODO:
  const onPressSettingsButtonClick = useCallback(() => {}, [])

  /** This condition is necessary for the design of notifications delimiter by date in the list */
  const isFirstNotificationInADay = useCallback(
    (createdAt: string, i: number) => {
      return (
        !notifications[i - 1] ||
        // i === notifications.length - 1 ||
        (notifications[i - 1] &&
          !moment(notifications[i - 1].createdAt).isSame(moment(createdAt), 'days'))
      )
    },
    [notifications]
  )

  const filteredUnreadNotifications = useMemo(() => {
    return notifications.filter((n) => !n.isRead)
  }, [notifications])

  useEffect(() => {
    if (markAsReadResp.isSuccess) {
      getUnreadCounter()
    }
    // eslint-disable-next-line
  }, [markAsReadResp.data, markAsReadResp.isSuccess])

  useEffect(() => {
    if (markAsReadResp.data?.length) {
      dispatch(updateNotificationsReadStatus(markAsReadResp?.data))
    }
    // eslint-disable-next-line
  }, [markAsReadResp.data])

  useEffect(() => {
    if (unreadCounterResp.isSuccess) {
      dispatch(addNotificationsUnreadCounter(unreadCounterResp.data as number))
    }
    // eslint-disable-next-line
  }, [unreadCounterResp.data, unreadCounterResp.isSuccess])

  useEffect(() => {
    if (isEmpty(data.items)) return

    if (Object.keys(filterValues).length !== 0 || tableConf.page === 1) {
      dispatch(addNotifications(data.items))
      // updateReadOnScroll()
    } else {
      dispatch(addNotifications([...notifications, ...data.items]))
    }
    // eslint-disable-next-line
  }, [data.items])

  useEffect(() => {
    return () => {
      dispatch(notificationApi.util.resetApiState())
      dispatch(clearNotifications())
    }
    // eslint-disable-next-line
  }, [])

  const updateReadOnScroll = debounce(() => {
    if (!filteredUnreadNotifications.length) return
    const container = document.getElementById('scrollableDiv')
    const containerTop = container?.scrollTop
    const containerHeight = container?.clientHeight
    const visibleElements: Array<IProfileNotification> = []

    for (let i = 0; i < filteredUnreadNotifications.length; i++) {
      const element = filteredUnreadNotifications[i]
      const domElement = document.getElementById(`notification-${element.id}`)

      if (domElement) {
        const elementTop = domElement.offsetTop
        const elementHeight = domElement.offsetHeight
        const elementBottom = elementTop + elementHeight

        const isFullyVisible =
          // @ts-ignore
          elementTop >= containerTop && elementBottom <= containerTop + containerHeight

        if (isFullyVisible) {
          visibleElements.push(element)
        }
      }
    }

    const firstDate = first(notifications)?.createdAt
    const secondDate = last(visibleElements)?.createdAt

    if (firstDate && secondDate) {
      markAsRead({ createdAtBefore: firstDate, createdAtAfter: secondDate })
    }
  }, 500)

  useEffect(() => {
    updateReadOnScroll()
    // eslint-disable-next-line
  }, [filteredUnreadNotifications])

  const onDateChange = (values: ISelectedFilters): void => {
    for (const i in values) {
      if (typeof values[i] === 'object') {
        values[i] = (values[i] as unknown as Moment).toISOString()
      }
    }

    setFilterValues?.(values)
  }

  return (
    <div className={styles.layout}>
      <NotificationsHeader
        setActiveFilters={onDateChange}
        filterValues={filterValues}
        onSearchChange={onSearchChange}
        onPressSettingsButtonClick={onPressSettingsButtonClick}
        classNameFilterPopup={styles.customFilterPopup}
        data={filtersList}
      />
      {notifications.length ? (
        <InfiniteScrollComponent
          loadMoreData={loadMoreData}
          dataLength={notifications.length}
          totalCount={data.totalCount}
          endMessage='All'
          showDivider={false}
        >
          {notifications.map((notification, i) => (
            <ProfileNotification
              key={notification.id}
              isFirstNotificationInADay={isFirstNotificationInADay(notification.createdAt, i)}
              notification={notification}
              onPressDecline={onPressDecline}
              onPressAccept={onPressAccept}
            />
          ))}
        </InfiniteScrollComponent>
      ) : (
        <NotFoundPage text='Empty data' />
      )}
    </div>
  )
}
