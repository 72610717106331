import React, { useContext, useEffect } from 'react'
import { RightModalContext } from '../../../../../Modals'
import {
  useGetClosedGroupByIdQuery,
  useUpdateClosedGroupByIdMutation,
  useUpdateCustomerByIdMutation,
} from '../../../../core/http/CustomersApi'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../shared/api/errorHandler'
import styles from '../CustomerModal/styles.module.scss'
import { Form, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { isEmpty } from 'lodash'
import { Button } from '../../../../../../shared/components/button/Button'
import { InfoMessage } from '../../../../../Settings/pages/VendorManagement/VendorManagementContactListModal/Components/InfoMessage'
import { IClosedGroupCustomer, NEW_CLOSED_GROUP } from '../../../../models/ICustomer'
import { CreateStep } from './CreateStep/CreateStep'
import { DetailView } from './DetailView/DetailView'
import classNames from 'classnames'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'

export const CustomerClosedGroupModal = () => {
  const [form] = useForm()
  const {
    onOpen,
    props: { ownerId, id, mode },
  } = useContext(RightModalContext)

  const { data, isFetching } = useGetClosedGroupByIdQuery(id, { skip: !id })
  const [updCust, updCustResp] = useUpdateCustomerByIdMutation()
  const [updCloseGr, updCloseGrResp] = useUpdateClosedGroupByIdMutation()

  const afterCreate = () => {
    if (updCustResp?.data?.closedGroupId) {
      onOpen(RIGHT_MODALS.SALES.CUSTOMER_CLOSED_GROUP, {
        title: 'View Closed Group',
        mode: 'view',
        id: updCustResp?.data?.closedGroupId,
      })
    }
  }

  const afterUpdate = () => {
    if (id) {
      onOpen(RIGHT_MODALS.SALES.CUSTOMER_CLOSED_GROUP, {
        title: 'View Closed Group',
        mode: 'view',
        id,
      })
    }
  }

  useNotification(NOTIFICATION_TYPES.success, updCustResp.isSuccess, null, afterCreate)
  useNotification(NOTIFICATION_TYPES.success, updCloseGrResp.isSuccess, null, afterUpdate)
  useNotification(NOTIFICATION_TYPES.error, updCustResp.isError, updCustResp.error as ErrorNode)
  useNotification(
    NOTIFICATION_TYPES.error,
    updCloseGrResp.isError,
    updCloseGrResp.error as ErrorNode
  )

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const onFinish = (values: {
    closedGroupId?: string
    customers?: Array<IClosedGroupCustomer>
  }) => {
    if (!mode && ownerId) {
      const body = {
        closedGroupId: values?.closedGroupId ?? NEW_CLOSED_GROUP.NEW,
      }
      const fd = new FormData()

      fd.append('customer', JSON.stringify(body))

      return updCust({ id: ownerId, body: fd })
    }

    const body = {
      customerIds: values?.customers?.map((cust) => cust.id) || [],
    }

    updCloseGr({ id, body })
  }

  const onSubmit = () => {
    if (!id || isEditMode) {
      return form.submit()
    }

    onOpen(RIGHT_MODALS.SALES.CUSTOMER_CLOSED_GROUP, {
      title: 'Edit Closed Group',
      mode: 'edit',
      id,
    })
  }

  useEffect(() => {
    if (isEmpty(data)) return
    const withSorting = {
      ...data,
      customers: [...data?.customers]?.sort((a, b) => {
        if (a?.isGroupOwner === true) return -1
        if (b?.isGroupOwner === true) return 1
        return 0
      }),
    }
    form.setFieldsValue(withSorting)
  }, [data, form])

  const isLoading = isFetching || updCustResp.isLoading || updCloseGrResp.isLoading

  const content = (
    <div className={styles.layout}>
      {!id && <InfoMessage text='existing or create the new one' isAccount={false} />}
      <Form
        layout='vertical'
        form={form}
        className={classNames({
          [styles.closedGroupForm]: !id,
          [styles.form]: !!id,
          [styles.viewForm]: !!id,
        })}
        onFinish={onFinish}
      >
        {!id && <CreateStep />}
        {!!id && <DetailView view={isViewMode} customers={data?.customers} />}
        <div className={styles.actionButton}>
          <Button color='orange' type='primary' block onClick={onSubmit}>
            {!id && 'Add Closed Group'}
            {isEditMode && 'Save Changes'}
            {isViewMode && 'Edit Closed Group'}
          </Button>
        </div>
      </Form>
    </div>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
