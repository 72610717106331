import { useContext, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import classNames from 'classnames'

// helpers
import { CenterModalContext } from './index'
import { CENTER_MODALS } from 'src/helpers/contants'

// components
import Modal from 'src/shared/components/modal/Modal'
import { ExportTableModal } from './Components/ExportTableModal'
import { ConfirmModal } from './Components/ConfirmModal'
import { GoogleAuthenticatorModal } from './Components/GoogleAuthenticatorModal'
import { DisableGoogleAuthModal } from './Components/DisableGoogleAuthModal'
import { EventsModal } from './Components/EventsModal'

// styles
import styles from './styles.module.scss'

export const CenterModals = () => {
  const {
    selectedModal,
    props: { title, className, footer, onCloseAction },
    onClose,
  } = useContext(CenterModalContext)

  // to create delay for animation
  const [delayedInfo, setDelayedInfo] = useState<Record<string, string>>({})

  useEffect(() => {
    if (selectedModal) setDelayedInfo({ selectedModal })
    if (!className && isEmpty(delayedInfo)) return
    if (!className) {
      setTimeout(() => setDelayedInfo({}), 100)
      return
    }
    setDelayedInfo({ className, selectedModal: selectedModal! })
    // eslint-disable-next-line
  }, [className, selectedModal])

  const isVisible = selectedModal !== null
  const { selectedModal: delayedModal, className: delayedClassName } = delayedInfo

  return (
    <Modal
      zIndex={1055}
      title={title}
      handleOk={onClose}
      handleCancel={onCloseAction || onClose}
      isModalOpen={isVisible}
      footer={footer}
      className={classNames(delayedClassName, styles.modalCenter)}
      {...(delayedModal === CENTER_MODALS.GOOGLE_AUTHENTICATOR && { width: 500 })}
    >
      {delayedModal === CENTER_MODALS.EXPORT_TABLE && <ExportTableModal />}
      {delayedModal === CENTER_MODALS.CONFIRM_MODAL && <ConfirmModal />}
      {delayedModal === CENTER_MODALS.GOOGLE_AUTHENTICATOR && <GoogleAuthenticatorModal />}
      {delayedModal === CENTER_MODALS.DISABLE_GOOGLE_AUTH && <DisableGoogleAuthModal />}
      {delayedModal === CENTER_MODALS.EVENTS_MODAL && <EventsModal />}
    </Modal>
  )
}
