import React from 'react'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from 'src/shared/components/textfield/TextField'

const PickupSummary = () => {
  return (
    <Wrapper title='Pickup Summary'>
      <FormItem name='currencyAmount' label='Cash Amount'>
        <TextField type='number' disabled />
      </FormItem>
      <FormItem name={['currency', 'textCode']} label='Currency'>
        <TextField disabled />
      </FormItem>
      <FormItem name='exchangeRate' label='FX Rate'>
        <TextField disabled />
      </FormItem>
      <FormItem name='discountAmount' label='Commission'>
        <TextField disabled />
      </FormItem>
      <FormItem name='accountType' label='Account Type'>
        <TextField disabled />
      </FormItem>
      <FormItem name='promotionAmount' label='Promotion'>
        <TextField disabled />
      </FormItem>
      <FormItem name='amount' label='eMoney Amount'>
        <TextField disabled />
      </FormItem>
    </Wrapper>
  )
}

export default PickupSummary
