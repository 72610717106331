import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { ReactNode } from 'react'
import * as SVG from 'src/assets/svg'
import styles from './Sidebar.module.scss'

function getItem(label: ReactNode, key: string, icon?: ReactNode, children?: ItemType[]) {
  return {
    key,
    icon,
    children,
    label,
  }
}

const dashboard = [getItem('Overview', 'dashboard/overview')]

const settings = [
  getItem('Vendor Management', 'settings/vendor-management'),
  getItem('Sales Network Configuration', 'settings/network-configuration'),
  getItem('Value Add Services', 'settings/value-add-services'),
  getItem('File Management', 'settings/file-management'),
  getItem('User management', 'settings/users-management'),
  getItem('System Configuration', 'settings/system-configuration'),
  // getItem('Departments Management', 'settings/departments-management'),
  // getItem('Agent Configuration', 'settings/agent-configuration'),
  // getItem('Direct Sales', 'settings/direct-sales'),
  // getItem('Access Channel Management', 'settings/access-channel-management'),
  // getItem('Route Management', 'settings/route-management'),
  // getItem('Portals', 'settings/portals'),
  // getItem('External relations', 'settings/external-relations'),
]

const finance = [
  getItem('Overview', 'finance/overview'),
  getItem('Treasury Wallet', 'finance/treasury-wallet'),
  getItem('Operation Wallet', 'finance/operation-wallet'),
  getItem('Bank Settings', 'finance/bank-settings'),
  getItem('Exchange Rates', 'finance/exchange-rates'),
  getItem('Statement of Wallets', 'finance/statement-of-wallets'),
  getItem('Credit Score', 'finance/credit-score'),
  getItem('Projections', 'finance/projections'),
  getItem('Reconciliation', 'finance/reconciliation'),
]

const support = [
  getItem('Tickets', 'support/tickets'),
  getItem('ChatRoom', 'support/chat-room'),
  getItem('Knowledge Base', 'support/knowledge-base'),
  getItem('Transaction Log', 'support/transaction-log'),
  getItem('System Monitoring', 'support/system-monitoring'),
  getItem('Restriction Management', 'support/restriction-management'),
]

const reports = [
  // getItem('Scheduled reports', 'reports/scheduled-reports'),
  // getItem('Templates', 'reports/templates'),
  getItem('External Transaction Report', 'reports/external-transaction-report'),
  getItem('Internal Transaction Report', 'reports/internal-transaction-report'),
]

const sales = [
  getItem('Overview', 'sales-network/overview'),
  getItem('Sales Network', 'sales-network/agent-management'),
  getItem('Customer', 'sales-network/customer'),
  getItem('Projection', 'sales-network/projection'),
  getItem('Market Intelligence', 'sales-network/market-intelligence'),
  getItem('Campaign', 'sales-network/campaign'),
]

const info = [
  getItem('Profile Info', 'info/profile-info/view'),
  getItem('Notification', 'info/notification'),
  getItem('Profile Settings', 'info/profile-settings'),
  getItem('User Activity Log', 'info/activity-log'),
  getItem('Documentation', 'info/documentation'),
  getItem('Help', 'info/help'),
]

export const pages = {
  dashboard,
  settings,
  finance,
  support,
  reports,
  info,
  sales,
}
export const modulePagesArrString = [
  'dashboard',
  'settings',
  'finance',
  'support',
  'reports',
  'info',
  'sales',
]
export const namePagesArrString = [
  'settings/network-configuration',
  'settings/vendor-management',
  'settings/agent-configuration',
  'settings/users-management',
  'settings/file-management',
  'settings/system-configuration',
  'finance/overview',
  'finance/treasury-wallet',
  'finance/operation-wallet',
  'finance/bank-settings',
  'finance/exchange-rates',
  'finance/statement-of-wallets',
  'finance/credit-score',
  'finance/projections',
  'finance/reconciliation',
  'support/tickets',
  'support/knowledge-base',
  'support/chat-room',
  'support/restriction-management',
  'support/transaction-log',
  'support/system-monitoring',
  'support/transaction-log',
  // 'reports/scheduled-reports',
  // 'reports/templates',
  'reports/external-transaction-report',
  'reports/internal-transaction-report',
  'info/profile-info/view',
  'info/notification',
  'info/profile-settings',
  'info/profile-activity-log',
  'info/documentation',
  'info/activity-log',
  'info/help',
  'sales-network/agent-management',
  'sales-network/overview',
  'sales-network/customer',
  'sales-network/projection',
  'sales-network/market-intelligence',
  'sales-network/campaign',
  'dashboard/overview',
]
export const arrIconMenu = [
  {
    icon: (
      <div className={styles['aminpay-logo']}>
        <SVG.AminPayLogoIcon />
      </div>
    ),
    page: '',
  },
  {
    icon: <SVG.MenuDashboardIcon />,
    page: 'dashboard',
  },
  {
    icon: <SVG.MenuUsersIcon />,
    page: 'sales',
  },
  {
    icon: <SVG.MenuFinanceIcon />,
    page: 'finance',
  },
  {
    icon: <SVG.MenuSupportIcon />,
    page: 'support',
  },

  {
    icon: <SVG.MenuReportsIcon />,
    page: 'reports',
  },
  {
    icon: <SVG.MenuSettingsIcon />,
    page: 'settings',
  },
]

export enum GENERAL_SEARCH_TABS_ENUM {
  ALL = 'ALL',
  AGENTS = 'AGENTS',
  TRANSACTIONS = 'TRANSACTIONS',
  TICKETS = 'TICKETS',
}
