import { Moment } from 'moment'
import { IInventoryCalendar } from 'src/features/Finance/models/IInventory'
import { ITableConf } from 'src/shared/models/ITableConf'

export interface IEventHoliday {
  id: string
  title: string
  start: string
  end: string
}

export type IEventHolidayBody = Omit<IEventHoliday, 'id'>
export interface IEventHolidayForm extends Omit<IEventHolidayBody, 'start' | 'end'> {
  id: string
  dateRange: [Moment, Moment]
}

export type TEventHoliday = keyof typeof EEventHoliday

export enum EEventHoliday {
  'events' = 'events',
  'holidays' = 'holidays',
}

export type TActionType = keyof typeof EActionType
export enum EActionType {
  'edit' = 'edit',
  'create' = 'create',
}

export interface ITableConfEvent extends ITableConf {
  monthRange: string
}

export interface IEventOrHoliday extends IInventoryCalendar {
  type?: TEventHoliday
  title: string
}
