import { Filter, ISelectedFilters } from '../../../../../../../../shared/components/filter/Filter'
import { IFilterFields } from '../../../../../../../../shared/models/IFilterFields'
import { useFilter } from '../../../../../../../../shared/hooks/filter/useFilter'
import { Row } from 'antd'
import { TextFieldSearch } from '../../../../../../../../shared/components/textFieldSearch/TextFieldSearch'
import styles from '../styles.module.scss'
import { FilterPopup } from '../../../../../../../../shared/components/filter/FilterPopup'

interface IProps {
  filterValues: ISelectedFilters
  data?: IFilterFields[]
  setActiveFilters: (values: ISelectedFilters) => void
  onSearchChange: React.ChangeEventHandler<HTMLInputElement>
  searchQuery?: string
}

export const ModalHeader = ({
  data,
  filterValues,
  setActiveFilters,
  onSearchChange,
  searchQuery,
}: IProps): JSX.Element => {
  const { form, isOpen, onCancel, onReset, onFinish } = useFilter({ setActiveFilters })

  return (
    <>
      <Row align='middle' justify='space-between' style={{ marginBottom: 18, width: '100%' }}>
        <TextFieldSearch
          value={searchQuery}
          className={styles.search}
          onChange={onSearchChange}
          placeholder='Enter Agent ID or Transaction ID'
        />
        <Filter
          form={form}
          data={data}
          setIsOpen={onCancel}
          selectedFilters={filterValues}
          setSelectedFilters={setActiveFilters}
          disabled={!data}
          sizeFilter='large'
          classNamesFilterWrapperIcon={styles.filterWrraperIcon}
        />
      </Row>
      {isOpen && (
        <FilterPopup
          className={styles.filterPopup}
          data={data}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          form={form}
          onCancel={onCancel}
          onReset={onReset}
          onFinish={onFinish}
        />
      )}
    </>
  )
}
