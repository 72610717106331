import React, { memo } from 'react'
import { CheckMarkIcon, DoubleCheckMarkIcon } from '../../../../../../../../assets/svg'

import styles from '../../styles.module.scss'

interface IProps {
  messageTime: string
  isMy: boolean
  isRead: boolean
}

const MessageInfo = ({ messageTime, isMy, isRead }: IProps) => {
  return (
    <div className={styles.messageInfoContainer}>
      <div className={styles.messageDate}>{messageTime}</div>
      <div>
        {isMy &&
          (isRead ? (
            <DoubleCheckMarkIcon color='#fff' height={9} width={17} />
          ) : (
            <CheckMarkIcon color='#fff' height={9} width={17} />
          ))}
      </div>
    </div>
  )
}

export default memo(MessageInfo)
