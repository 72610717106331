import { TextEditor } from '../../../../../../../shared/components/textEditor'
import { LANGUAGE_ENUM } from './index.d'
import FormItem from 'antd/es/form/FormItem'
import { useParams } from 'react-router-dom'
import { Form, Tabs } from 'antd'
import { useCallback } from 'react'
import styles from '../../styles.module.scss'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { deleteHtmlTagsFromString } from '../../../../../../../helpers/utils'
import { IMessage } from '../../../../../models/INotificationMessage'
import { omit } from 'lodash'

export const ChannelBlock = () => {
  const { mode } = useParams()
  const form = Form.useFormInstance()
  const isViewMode = mode === 'view'

  const languageItems = useCallback(
    (id: number) => [
      {
        label: LANGUAGE_ENUM.ENGLISH,
        key: LANGUAGE_ENUM.ENGLISH,
        children: (
          <FormItem name={[id, 'en']}>
            <TextEditor
              form={form}
              name={['messages', id, 'en']}
              viewMode={isViewMode}
              initialContent={form.getFieldValue(['messages', id, 'en'])}
              fieldToValidate='messages'
            />
          </FormItem>
        ),
      },
      {
        label: LANGUAGE_ENUM.DARI,
        key: LANGUAGE_ENUM.DARI,
        children: (
          <FormItem name={[id, 'prs']}>
            <TextEditor
              form={form}
              name={['messages', id, 'prs']}
              viewMode={isViewMode}
              initialContent={form.getFieldValue(['messages', id, 'prs'])}
              fieldToValidate='messages'
            />
          </FormItem>
        ),
      },
      {
        label: LANGUAGE_ENUM.PASHTU,
        key: LANGUAGE_ENUM.PASHTU,
        children: (
          <FormItem name={[id, 'ps']}>
            <TextEditor
              form={form}
              name={['messages', id, 'ps']}
              viewMode={isViewMode}
              initialContent={form.getFieldValue(['messages', id, 'ps'])}
              fieldToValidate='messages'
            />
          </FormItem>
        ),
      },
    ],
    [form, isViewMode]
  )

  return (
    <FormItem noStyle shouldUpdate>
      {({ getFieldValue }) => (
        <Form.List
          name='messages'
          rules={[
            {
              validator: () => {
                if (getFieldValue('messages') && getFieldValue('messages').length) {
                  const isMessageFilled = getFieldValue('messages').some((message: IMessage) =>
                    Object.entries(omit(message, 'channel', 'id')).some(
                      ([, val]) => deleteHtmlTagsFromString(val)?.length
                    )
                  )

                  if (isMessageFilled) {
                    return Promise.resolve()
                  }
                }
                return Promise.reject('At least one message is required!')
              },
            },
          ]}
        >
          {(fields, {}, { errors }) => (
            <>
              <div className={styles.channelBlock}>
                <FormItem noStyle shouldUpdate>
                  {() => {
                    const items = fields.map(({ name }) => ({
                      label: form.getFieldValue(['messages', name, 'channel']),
                      key: form.getFieldValue(['messages', name, 'channel']),
                      children: (
                        <>
                          <FormItem name={[name, 'channel']} style={{ display: 'none' }}>
                            <TextField />
                          </FormItem>
                          <Tabs items={languageItems(name)} />
                        </>
                      ),
                    }))

                    return (
                      <>
                        <Tabs items={items} />
                        <div style={{ color: 'red' }}>
                          {errors?.map((error, i) => (
                            <div key={i}>{error}</div>
                          ))}
                        </div>
                      </>
                    )
                  }}
                </FormItem>
              </div>
            </>
          )}
        </Form.List>
      )}
    </FormItem>
  )
}
