import React, { useEffect } from 'react'
import { Form } from 'antd'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import RestrictionProfilingScheduling from './Components/Scheduling'
import ParametersDetailInfo from './Components/DetailInfo'
import { AddIcon } from 'src/assets/svg'
import { formatDateWithTime } from 'src/helpers/utils'
import styles from './styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

const CreditScoreParameters = () => {
  const [form] = Form.useForm()
  const { data: profile } = useGetProfileQuery()

  const onFinish = () => {}

  useEffect(() => {
    form.setFieldsValue({
      createdAt: formatDateWithTime(new Date(), profile?.calendar),
      parameterConditions: [{ condition: '' }],
    })
  }, [form, profile])

  const content = (
    <div>
      <Form form={form} layout='vertical' onFinish={onFinish}>
        <div className={styles.columnWrapper}>
          <div className={styles.leftColumn}>
            <FormItem shouldUpdate noStyle>
              <ParametersDetailInfo />
            </FormItem>
          </div>
          <div className={styles.rightColumn}>
            <RestrictionProfilingScheduling />
          </div>
        </div>
        <div className={styles.addConditionBtn}>
          <IconButton color='orange' icon={<AddIcon />} onClick={() => {}}>
            Add New Condition
          </IconButton>
        </div>
      </Form>
    </div>
  )

  return content
}

export default CreditScoreParameters
