import FormList from 'antd/es/form/FormList'
import React, { useEffect } from 'react'
import { Form, Select, Spin } from 'antd'
import { useGetUsersListQuery } from 'src/features/Settings/core/http/UsersManagementApi'
import { useGetProfileQuery, useUpdateUserDelegationMutation } from '../../../../core/http/Profile'

// styles
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import styles from '../styles.module.scss'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from 'src/assets/svg'
import { Toggle } from 'src/shared/components/toggle/Toggle'
import { Button } from 'src/shared/components/button/Button'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { requiredValidator } from 'src/helpers/validation'
import { IUserDelegationForm, IUserDetail } from 'src/features/Settings/models/IUser'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode } from 'src/shared/api/errorHandler'
import FormItem from 'antd/es/form/FormItem'
import { RangePicker } from 'src/shared/components/datePicker'
import { getFormCalendarTime } from 'src/helpers/utils'

export const DelegationBlock = () => {
  const [form] = Form.useForm()

  const { data: userList, isFetching: isUF } = useGetUsersListQuery({})
  const { data: profile, isFetching: isPF } = useGetProfileQuery()

  const handleSetInitialForm = (data: IUserDetail) => {
    const delegations = data.userDelegations.map((i) => ({
      ...i,
      toUserId: i.toUser!.id,
      dateRange: getFormCalendarTime(profile!.calendar, [i.startAt, i.endAt]),
      // dateRange: [moment(i.startAt), moment(i.endAt)],
    }))
    form.setFieldValue('userDelegations', delegations)
  }

  useEffect(() => {
    if (isEmpty(profile)) return
    handleSetInitialForm(profile)
    // eslint-disable-next-line
  }, [profile])

  const [updateDelegation, dDelegation] = useUpdateUserDelegationMutation()

  useNotification(NOTIFICATION_TYPES.success, dDelegation.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, dDelegation.isError, dDelegation.error as ErrorNode)

  const handleCancelClick = () => {
    handleSetInitialForm(profile as IUserDetail)
  }
  const onFinish = (values: { userDelegations: IUserDelegationForm[] }) => {
    const { userDelegations } = values

    const req = userDelegations.map((i) => ({
      id: i.id,
      toUserId: i.toUserId,
      startAt: i.dateRange[0].toISOString(),
      endAt: i.dateRange[1].toISOString(),
      // startAt: rangeDate![0].toISOString() /* moment(i.dateRange[0]).toISOString() */,
      // endAt: rangeDate![1].toISOString() /* moment(i.dateRange[1]).toISOString() */,
      isActive: !!i.isActive,
    }))
    updateDelegation({ delegations: req })
  }

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    form.submit()
  }

  return (
    <Spin spinning={isUF || isPF || dDelegation.isLoading}>
      <Wrapper title='delegation' className={styles.layout}>
        <Form form={form} layout='vertical' onFinish={onFinish}>
          <FormList name='userDelegations'>
            {(fields, { add, remove }) => (
              <React.Fragment>
                <div>
                  {fields.map((field) => {
                    return (
                      <div className={styles.delegationItemContainer} key={field.key}>
                        <div className={styles.delegationItemLeftContainer}>
                          <Form.Item
                            name={[field.name, 'toUserId']}
                            label='User'
                            rules={[{ required: true, validator: requiredValidator('User') }]}
                          >
                            <Select placeholder='Select User' allowClear>
                              {userList?.items?.map(({ id, firstName, lastName }) => (
                                <Select.Option key={id} value={id}>
                                  {`${firstName} ${lastName}`}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className={styles.delegationItemRightContainer}>
                          <div className={styles.delegationDatePickerInp}>
                            <Form.Item
                              name={[field.name, 'dateRange']}
                              label='Date Range'
                              rules={[
                                { required: true, validator: requiredValidator('Date Range') },
                              ]}
                            >
                              <RangePicker
                                allowClear={false}
                                className={styles.delegationDatePicker}
                                placeholder={['Starting Date', 'End Date']}
                                disabledDate={(current) => moment().add(-1, 'days') >= current}
                              />
                            </Form.Item>
                          </div>
                          <div className={styles.delegationToggleContainer}>
                            <Form.Item shouldUpdate>
                              {({ getFieldValue }) => {
                                const isActive = getFieldValue([
                                  'userDelegations',
                                  field.name,
                                  'isActive',
                                ])
                                return (
                                  <Form.Item name={[field.name, 'isActive']}>
                                    <Toggle loading={false} inWrapper checked={isActive} />
                                  </Form.Item>
                                )
                              }}
                            </Form.Item>
                          </div>
                          <div className={styles.bucketIcon}>
                            <IconButton
                              block
                              size='large'
                              color='orange'
                              icon={<BucketIcon />}
                              type='default'
                              // disabled={view}
                              onClick={() => remove(field.name)}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className={styles.delegationFooterBtns}>
                  <div className={styles.delegationFooterLeftColumn}>
                    <IconButton icon={<AddIcon />} color='orange' block onClick={() => add()} />
                  </div>
                  <div className={styles.delegationFooterRightColumn}>
                    <FormItem shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const formDelegation = getFieldValue('userDelegations')

                        if (profile?.userDelegations?.length || !!formDelegation?.length) {
                          return (
                            <Button
                              color='blue'
                              type='default'
                              size='middle'
                              onClick={handleCancelClick}
                            >
                              cancel
                            </Button>
                          )
                        }
                      }}
                    </FormItem>
                    <FormItem noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        const formDelegation = getFieldValue('userDelegations')

                        if (profile?.userDelegations?.length || !!formDelegation?.length) {
                          return (
                            <Button
                              color='blue'
                              type='primary'
                              size='middle'
                              htmlType='submit'
                              onClick={handleSubmit}
                            >
                              save
                            </Button>
                          )
                        }
                      }}
                    </FormItem>
                  </div>
                  {/* {(!isEmpty(profile?.userDelegations) || !!fields.length) && (
                    <div className={styles.delegationFooterRightColumn}>
                      <Button color='blue' type='default' size='middle' onClick={handleCancelClick}>
                        cancel
                      </Button>
                    </div>
                  )} */}
                </div>
              </React.Fragment>
            )}
          </FormList>
        </Form>
      </Wrapper>
    </Spin>
  )
}
