import { IPurchaseOrderStatus, VENDOR_WALLET_TYPE_ENUM } from './IPurchaseOrder'
import { IWalletList } from './IWallet'
import { ICurrency } from './ICurency'
import { IByUser } from '../../../shared/models/IByUser'
import { IWidgetMasterProduct } from '../../Settings/models/IMasterProduct'

export enum TRANSACTION_CATEGORY_ENUM {
  PURCHASE_ORDER = 'Purchase Order',
  CREATE_EMONEY = 'Create eMoney',
  KILL_EMONEY = 'Kill eMoney',
  PICKUP = 'Pickup',
  PICKUP_REQUEST = 'Pickup Request',
  SEND_EMONEY = 'Send eMoney',
  TRANSFER_FUND = 'Transfer Fund',
  TOPUP = 'Topup',
  REFUND = 'Refund',
  ADJUSTMENT = 'Adjustment',
  DISCOUNT = 'Discount',
  FEE = 'FEE',
  PROMOTION = 'Promotion',
  REWARD = 'Reward',
  PENALTY = 'Penalty',
  HAWALA = 'Hawala',
  BILL_PAYMENT = 'Bill Payment',
  ETICKETING = 'eTicketing',
  EXCHANGE = 'Exchange',
  REVERSE = 'Reverse',
  REVERSE_TOPUP = 'Reverse Topup',
  Discount = 'Discount',
  'PICKUP_REQUEST_SUBTRANSACTION' = 'Pickup request subtransaction',
  'PURCHASE_ORDER_SUBTRANSACTION' = 'Purchase order subtransaction',
  'TRUST_WALLET_TRANSACTION' = 'Trust Wallet transaction',
  'BANK_ACCOUNT_TRANSACTION' = 'Bank account transaction',
  'DISCOUNT_WALLET_TRANSACTION' = 'Discount Wallet transaction',
}

export const AVAILABLE_HISTORY_CATEGORIES = [
  TRANSACTION_CATEGORY_ENUM.PURCHASE_ORDER,
  TRANSACTION_CATEGORY_ENUM.PICKUP,
  TRANSACTION_CATEGORY_ENUM.PICKUP_REQUEST,
  TRANSACTION_CATEGORY_ENUM.REFUND,
]

export enum TRANSACTION_STATUS_ENUM {
  CREATED = 'Created',
  CHECKED = 'Checked',
  CANCELED = 'Canceled',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  IN_PROCESS = 'In Process',
  SUCCESS = 'Success',
  COMPLETED = 'Completed',
  FAIL = 'Fail',
  FAILED = 'Failed',
  REVERSED = 'Reversed',
}

export enum DEBIT_CREDIT_ENUM {
  DEBIT = 'DR',
  CREDIT = 'CR',
}

export interface ITrustWalletTransaction {
  id: number
  createdAt: Date
  vendor: IByUser
  countSubTransaction: number
  wallet?: IWalletList
  bankAccount?: BankAccount
  walletType?: string
  drCr?: DEBIT_CREDIT_ENUM
  currency: ICurrency
  escrow?: boolean
  transactionCategory: TRANSACTION_CATEGORY_ENUM
  balanceBefore: number
  currencyAmount: number
  amount: number
  balanceAfter: number
  status: TRANSACTION_STATUS_ENUM
  transactionStatuses: Array<IPurchaseOrderStatus>
  isExpanded?: boolean
  sender: 'bankAccount'
  receiver: 'bankAccount'
  children?: Array<ITrustWalletTransactionSub>
  vendorWalletType: VENDOR_WALLET_TYPE_ENUM
}

type SENDER_RECEIVER_KEY = 'vendor' | 'agentWallet' | 'bankAccount' | 'secondWallet'

export interface ITrustWalletTransactionSub {
  id: number
  mainTransactionId: number
  createdAt: string
  vendor: IByUser
  amount: number
  currencyAmount: number
  status: string
  balanceBefore: number
  balanceAfter: number
  drCr?: DEBIT_CREDIT_ENUM
  walletType: string
  transactionCategory: string
  serviceTypes: string
  transactionRequestAccessChannel: string
  duration: number
  escrow: boolean
  agentWallet: Wallet
  bankAccount: BankAccount
  discountAmount: number
  discountRate: number
  secondAgentWallet: Wallet
  secondWallet: Wallet
  product: IByUser
  agent: Agent
  wallet: Wallet
  exchangeRate: number
  mainCurrency: ICurrency
  currency: ICurrency
  createdByUser: IByUser
  receiver: SENDER_RECEIVER_KEY
  sender: SENDER_RECEIVER_KEY
}

export interface Agent {
  id: number
  name: string
  avatar: string
}

export interface Wallet {
  id: number
  name: string
  walletType: string
  currency?: ICurrency
}

export interface BankAccount {
  id: number
  name: string
  description: string
}

export interface IProcurementData {
  amount: number
  vendor: IWidgetMasterProduct
}

export interface IProcurement {
  period: string
  data: IProcurementData[]
}
