import styles from './styles.module.scss'
import { ProductAccess } from './ProductAccessTable'
import { Form, Row, Select, Space, Spin, Tabs } from 'antd'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { SALES_ENUM } from '../../../../../routes/sales'
import classNames from 'classnames'
import {
  useCreateAgentDiscountPolicyMutation,
  useDeleteDiscountPolicyByAgentIdMutation,
  useGetDPConfigurationByAgentIdQuery,
  useGetDPTemplatesByAgentIdQuery,
  useGetMasterProductsListPostpaidQuery,
  useLazyGetDPRateLimitationsByAgentIDQuery,
  useUpdateAgentDiscountPolicyMutation,
} from '../../../core/http/AgentPolicyApi'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  IAgentDiscountPolicyProduct,
  IAgentDiscountPolicyTemplateType,
  IAgentDiscountPolicyUpdate,
  IAgentMasterProductPostpaid,
  IAgentProduct,
  IDiscountPolicyAccessChannel,
} from '../../../models/IAgentPolicy'
import { ITableConf } from '../../../../../shared/models/ITableConf'
import { agentManagementApi, useGetAgentByIdQuery } from '../../../core/http/AgentManagement'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import { ErrorNode, showConfirmMessage } from '../../../../../shared/api/errorHandler'
import { DiscountPolicyBlock } from './DiscountPolicyBlock'
import { useAppDispatch } from '../../../../../redux'
import { Wrapper } from './DiscountPolicyBlock/Wrapper'
import { Button } from '../../../../../shared/components/button/Button'
import FormItem from 'antd/es/form/FormItem'
import { startCase } from 'lodash'
import {
  DISCOUNT_POLICY_PRODUCT_TYPE_ENUM,
  IProductAccess,
} from '../../../../Settings/models/IDiscountPolicy'
import { ACCOUNT_TYPE_TO_ID } from '../../../../Settings/models/IAccountType'
import { AGENT_TYPE_ID_ENUM } from '../../../../Settings/models/IAgentType'
import { PrepaidProductAccessBlock } from './PrepaidProductAccess'
import { useGetMasterProductsListQuery } from '../../../../Settings/core/http/VendorManagementApi'
import { AccessChannelBlock } from './AccessChannelBlock'
import { useGetAccessChannelsListQuery } from '../../../../Settings/core/http/DiscountPolicyApi'

interface IProps {
  canViewPolicyManagement: boolean
  canEditPolicyManagement: boolean
}

export const Policy = ({ canViewPolicyManagement }: IProps) => {
  const [productType, setProductType] = useState<DISCOUNT_POLICY_PRODUCT_TYPE_ENUM | null>(null)
  const [isPrepaid, setIsPrepaid] = useState<boolean | null>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { mode, agentId } = useParams()

  const [form] = Form.useForm()
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 5,
  })

  const [mappedProducts, setMappedProducts] = useState<Array<IAgentMasterProductPostpaid>>([])
  const [template, setTemplate] = useState<IAgentDiscountPolicyTemplateType>(
    {} as IAgentDiscountPolicyTemplateType
  )

  // AGENT DATA
  const { data: agentData, isFetching: isLoadingAgent } = useGetAgentByIdQuery(agentId as string)

  // DISCOUNT POLICY CONFIGURATION
  const [delDisc, delDiscResp] = useDeleteDiscountPolicyByAgentIdMutation()
  const [getRateLim, getRateLimResp] = useLazyGetDPRateLimitationsByAgentIDQuery()
  const [crDiscPol, crDiscPolResp] = useCreateAgentDiscountPolicyMutation()
  const [upDiscPol, upDiscPolResp] = useUpdateAgentDiscountPolicyMutation()

  const { data: channels } = useGetAccessChannelsListQuery()
  const { data: masterProductsList } = useGetMasterProductsListQuery({})
  const { data: masterProductsListPostpaid, isFetching: isMasterProductsPostpaidFetching } =
    useGetMasterProductsListPostpaidQuery(
      {
        id: agentId as string,
        type: productType as DISCOUNT_POLICY_PRODUCT_TYPE_ENUM,
        params: { ...tableConf },
      },
      { skip: !agentId || !agentData || !productType || !!isPrepaid }
    )
  const { data: discountPolicyTemplates, isFetching: isLoadingTemplates } =
    useGetDPTemplatesByAgentIdQuery(
      { id: agentId as string, type: productType as DISCOUNT_POLICY_PRODUCT_TYPE_ENUM },
      {
        skip: !agentId || !productType,
      }
    )
  const {
    data,
    isFetching,
    isError: isNotFound,
  } = useGetDPConfigurationByAgentIdQuery(
    { id: agentId as string, type: productType as DISCOUNT_POLICY_PRODUCT_TYPE_ENUM },
    {
      skip: !agentId || !productType || !agentData?.discountPolicies.length,
    }
  )

  const isViewMode = mode === 'view'

  const afterMutation = () => {
    dispatch(agentManagementApi.util.invalidateTags(['IAgentAllGetResponse']))

    navigate(`${SALES_ENUM.SALES_NETWORK}/view/${agentId}`)
  }

  // NOTIFICATIONS
  useNotification(NOTIFICATION_TYPES.success, crDiscPolResp.isSuccess, null, afterMutation)
  useNotification(NOTIFICATION_TYPES.success, upDiscPolResp.isSuccess, null, afterMutation)
  useNotification(NOTIFICATION_TYPES.success, delDiscResp.isSuccess, null, afterMutation)
  useNotification(NOTIFICATION_TYPES.error, delDiscResp.isError, delDiscResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, crDiscPolResp.isError, crDiscPolResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, upDiscPolResp.isError, upDiscPolResp.error as ErrorNode)

  const onProductSelect = (product: IAgentMasterProductPostpaid) => {
    const productsList = form.getFieldValue('discountPolicyProducts') || []
    const toggleSwitch = (status: boolean) => {
      return mappedProducts.map((prod) => {
        if (+prod.masterProductId === +product.masterProductId) {
          return { ...prod, status }
        }
        return prod
      })
    }

    const discountProduct = {
      masterProduct: {
        name: product?.name,
        logo: product?.logo,
        id: product?.masterProductId,
      },
      status: true,
    } as IAgentDiscountPolicyProduct

    if (!product?.status) {
      const filtered = productsList?.filter(
        (p: IAgentDiscountPolicyProduct) => p.masterProduct.id !== product?.masterProductId
      )

      setMappedProducts(toggleSwitch(false))
      return form.setFieldsValue({
        discountPolicyProducts: filtered,
        forbiddenProducts: getForbiddenProducts(filtered),
      })
    }

    setMappedProducts(toggleSwitch(true))
    return form.setFieldsValue({
      discountPolicyProducts: [...productsList, discountProduct],
      forbiddenProducts: getForbiddenProducts([...productsList, discountProduct]),
    })
  }

  const onFinish = (values: {
    discountPolicyProducts: IAgentDiscountPolicyProduct[]
    agent: IProductAccess[]
    tree: IProductAccess[]
    templateId: string
    forbiddenAccessChannels: IDiscountPolicyAccessChannel[]
  }) => {
    // CHECK IF THE POLICY IS EXISTS THEN WE MAKE A POST REQUEST OTHERWISE - PATCH
    const hasDiscountPolicy =
      !!agentData?.discountPolicies.length &&
      agentData?.discountPolicies?.some((policy) => policy?.masterProductType === productType)

    if (hasDiscountPolicy) {
      const toUpdate = [] as IAgentDiscountPolicyUpdate['products']['toUpdate']
      const toCreate = [] as IAgentDiscountPolicyUpdate['products']['toCreate']
      const toDelete = [] as IAgentDiscountPolicyUpdate['products']['toDelete']

      values?.discountPolicyProducts?.forEach((product) => {
        if (data?.agentProducts?.some((agentP) => agentP?.id === product?.id)) {
          toUpdate.push({
            id: +product?.id,
            rate: +product?.rate,
            status: !!product.status,
            isLocked: !!product.isLocked,
          })
        }

        if (!data?.agentProducts.some((agentP) => agentP?.id === product?.id)) {
          toCreate.push({
            status: !!product.status,
            masterProductId: product?.masterProduct?.id,
            rate: +product.rate,
            isLocked: !!product.isLocked,
          })
        }
      })

      data?.agentProducts?.forEach((item) => {
        if (!values?.discountPolicyProducts?.some((element) => element?.id === item?.id)) {
          toDelete.push(+item?.id)
        }
      })

      const body = {
        // in sub-agent need to remove tamplateId
        ...(template && agentData.agentLevel.level === 1
          ? { templateId: +template?.id || +values?.templateId }
          : {}),
        products: {
          toUpdate,
          toCreate,
          toDelete,
        },
        forbiddenAccessChannels: values?.forbiddenAccessChannels
          ?.filter((channel) => !channel?.status)
          .map((filteredChannel) => filteredChannel?.name),
        lockedAccessChannels: values?.forbiddenAccessChannels
          ?.filter((channel) => channel?.isLocked)
          .map((filteredChannel) => filteredChannel?.name),
        ...(isPrepaid && {
          productsAccess: {
            agent: values?.agent.map((ag) => ({
              masterProductId: ag?.masterProduct?.id,
              isLocked: !!ag.isLocked,
            })),
            ...(values?.tree?.length && {
              tree: values?.tree.map((tr) => ({
                masterProductId: tr?.masterProduct?.id,
                isLocked: !!tr.isLocked,
              })),
            }),
          },
        }),
      } as IAgentDiscountPolicyUpdate

      return upDiscPol({
        id: agentId as string,
        type: productType as DISCOUNT_POLICY_PRODUCT_TYPE_ENUM,
        body,
      })
    }

    const body = {
      ...(template ? { templateId: +template?.id || +values?.templateId } : {}),
      products: values?.discountPolicyProducts?.map((product) => ({
        masterProductId: product?.masterProduct?.id,
        rate: +product?.rate,
        status: !!product.status,
        isLocked: !!product.isLocked,
      })),
      forbiddenAccessChannels: values?.forbiddenAccessChannels
        ?.filter((channel) => !channel?.status)
        .map((filteredChannel) => filteredChannel?.name),
      lockedAccessChannels: values?.forbiddenAccessChannels
        ?.filter((channel) => channel?.isLocked)
        .map((filteredChannel) => filteredChannel?.name),
      ...(isPrepaid && {
        productsAccess: {
          agent: values?.agent.map((ag) => ({
            masterProductId: ag?.masterProduct?.id,
            isLocked: !!ag.isLocked,
          })),
          ...(values?.tree?.length && {
            tree: values?.tree.map((tr) => ({
              masterProductId: tr?.masterProduct?.id,
              isLocked: !!tr.isLocked,
            })),
          }),
        },
      }),
    } as unknown as IAgentDiscountPolicyUpdate

    crDiscPol({ id: agentId as string, body })
  }

  const selectProductsOnFirstLoad = useCallback(
    (postpaidProducts: IAgentMasterProductPostpaid[]) => {
      if (isPrepaid) return
      const selectedProducts =
        (form.getFieldValue('discountPolicyProducts') as IAgentDiscountPolicyProduct[]) || []

      if (selectedProducts?.length) {
        // SELECT PRODUCTS AFTER PAGINATION CHANGE
        const products = postpaidProducts?.map((product) => ({
          ...product,
          status: selectedProducts?.some(
            (selectedProd) => +selectedProd?.masterProduct?.id === +product?.masterProductId
          ),
        }))

        return setMappedProducts(products)
      }
    },
    [form, isPrepaid]
  )

  const getForbiddenProducts = useCallback(
    (discountProducts: Array<IAgentProduct> = [], type?: DISCOUNT_POLICY_PRODUCT_TYPE_ENUM) => {
      return masterProductsList?.items
        ?.filter(
          (master) =>
            !discountProducts?.some((product) => product?.masterProduct?.id === master.id) &&
            master.type === (type || master.type)
        )
        .map((filtered) => filtered)
    },
    [masterProductsList?.items]
  )

  const getForbiddenAccessChannels = useCallback(
    (forbiddenChannels: Array<string> = [], lockedChannels: Array<string> = []) =>
      channels?.accessChannels.map((channel: string) => ({
        name: channel,
        isLocked: lockedChannels?.includes(channel),
        status: !forbiddenChannels?.includes(channel),
      })),
    [channels]
  )

  const resetForm = useCallback(() => {
    setMappedProducts([])
    setTemplate({} as IAgentDiscountPolicyTemplateType)
    form.setFieldsValue({
      templateId: null,
      discountPolicyProducts: [],
      forbiddenProducts: [],
      agent: [],
      tree: [],
      parentDiscountPolicy: { status: false },
      forbiddenAccessChannels: [],
    })
  }, [form])

  const onCancelButtonClick = () => {
    if (isViewMode) {
      return navigate(SALES_ENUM.SALES_NETWORK)
    }

    form.resetFields()
    // SET DEFAULT PRODUCT TYPE FROM AGENT DISCOUNT POLICY
    const selectedProductType = agentData?.discountPolicies[0]?.masterProductType || null

    if (!selectedProductType) {
      resetForm()
      return navigate(`${SALES_ENUM.SALES_NETWORK}/view/${agentId}`)
    }

    setProductType(selectedProductType)
    form.setFieldValue('productType', selectedProductType)

    const agentTemplate = discountPolicyTemplates?.items.find(
      (temp) => Number(temp?.id) === Number(data?.parentDiscountPolicy?.id)
    )

    if (agentTemplate && data?.agentProducts) {
      form.setFieldsValue({
        templateId: agentTemplate?.id,
        discountPolicyProducts: data?.agentProducts,
        forbiddenProducts: getForbiddenProducts(data?.agentProducts),
        parentDiscountPolicy: { status: agentTemplate.status },
        forbiddenAccessChannels: getForbiddenAccessChannels(
          data?.forbiddenAccessChannels,
          data?.lockedAccessChannels
        ),
      })
    }
    selectProductsOnFirstLoad(masterProductsListPostpaid?.items || [])

    return navigate(`${SALES_ENUM.SALES_NETWORK}/view/${agentId}`)
  }

  const onSubmitButtonClick = () => {
    if (isViewMode) {
      return navigate(`${SALES_ENUM.SALES_NETWORK}/edit/${agentId}`)
    }

    form.submit()
  }

  const onTemplateChange = async (value: string) => {
    if (!value) {
      return setTemplate({} as IAgentDiscountPolicyTemplateType)
    }
    const selectedTemplate = discountPolicyTemplates?.items.find(
      (template) => +template?.id === +value
    )

    setTemplate(selectedTemplate as IAgentDiscountPolicyTemplateType)

    form.setFieldValue(
      'discountPolicyProducts',
      selectedTemplate?.discountPolicyProducts.map((i) => ({ ...i, status: true }))
    )
    form.setFieldValue('forbiddenProducts', [])
    form.setFieldValue(['parentDiscountPolicy', 'status'], selectedTemplate?.status)

    form.setFieldValue(
      'forbiddenAccessChannels',
      getForbiddenAccessChannels(selectedTemplate?.forbiddenAccessChannels, [])
    )

    if (discountPolicyTemplates?.items && isPrepaid) {
      const [template] = discountPolicyTemplates.items
      return form.setFieldsValue({
        agent: template?.productsAccess,
        tree: template?.productsAccess,
      })
    }

    setTableConf((prevState) => ({ ...prevState }))
  }

  const onClearTemplate = () => {
    form.setFieldValue('discountPolicyProducts', [])
    form.setFieldValue('forbiddenProducts', [])
    form.setFieldValue('agent', [])
    form.setFieldValue('tree', [])
    form.setFieldValue('forbiddenAccessChannels', [])
    form.setFieldValue(['parentDiscountPolicy', 'status'], false)

    setMappedProducts((prevState) =>
      prevState.map((prod) => ({
        ...prod,
        status: false,
      }))
    )
  }

  const onChangeProductType = (val: DISCOUNT_POLICY_PRODUCT_TYPE_ENUM) => {
    if (val) {
      setTableConf((prevState) => ({ ...prevState, page: 1 }))
    }
    setProductType(val)

    resetForm()
  }

  const onClearProductType = () => {
    const hasDiscount = agentData?.discountPolicies?.some(
      (policy) => policy?.masterProductType === productType
    )
    hasDiscount &&
      showConfirmMessage('This Discount Policy Will Be Deleted', () =>
        delDisc({ id: agentId as string, type: productType as string })
      )

    setMappedProducts([])
    setTemplate({} as IAgentDiscountPolicyTemplateType)
    form.setFieldsValue({
      templateId: null,
      discountPolicyProducts: [],
      forbiddenProducts: [],
      agent: [],
      tree: [],
      parentDiscountPolicy: { status: false },
    })
  }

  const productTypes = useMemo(
    () =>
      (
        Object.keys(DISCOUNT_POLICY_PRODUCT_TYPE_ENUM) as Array<
          keyof typeof DISCOUNT_POLICY_PRODUCT_TYPE_ENUM
        >
      )
        .map((method) => ({
          label: startCase(DISCOUNT_POLICY_PRODUCT_TYPE_ENUM[method].toLowerCase()),
          value: DISCOUNT_POLICY_PRODUCT_TYPE_ENUM[method],
        }))
        .filter((masterProductType) => {
          if (agentData?.accountType.id === ACCOUNT_TYPE_TO_ID.PREPAID) {
            return masterProductType.value === DISCOUNT_POLICY_PRODUCT_TYPE_ENUM.E_MONEY
          }
          return masterProductType.value !== DISCOUNT_POLICY_PRODUCT_TYPE_ENUM.E_MONEY
        }),
    [agentData?.accountType.id]
  )

  useEffect(() => {
    if (masterProductsListPostpaid) {
      setMappedProducts(masterProductsListPostpaid.items)
      selectProductsOnFirstLoad(masterProductsListPostpaid?.items)
    }
  }, [data, masterProductsListPostpaid, selectProductsOnFirstLoad, tableConf])

  useEffect(() => {
    if (!agentId || !template.id || !agentData) return
    const isAssign = !agentData?.discountPolicies?.some(
      (policy) => policy?.masterProductType === productType
    )
    getRateLim({ id: agentId as string, discountPolicyId: template?.id, isAssign })
  }, [agentData, agentId, getRateLim, productType, template])

  useEffect(() => {
    // SET DEFAULT TEMPLATE AND PRODUCTS IN THIS TEMPLATE FROM AGENT DISCOUNT POLICY
    if (!isNotFound && !isFetching && data && discountPolicyTemplates && productType) {
      const agentTemplate = discountPolicyTemplates?.items.find(
        (temp) => Number(temp?.id) === Number(data?.parentDiscountPolicy?.id)
      )

      if (agentTemplate && data?.agentProducts && agentData) {
        form.setFieldsValue({
          templateId: agentTemplate?.id,
          discountPolicyProducts: data?.agentProducts,
          parentDiscountPolicy: { status: data?.parentDiscountPolicy?.status },
          forbiddenProducts: getForbiddenProducts(
            data?.agentProducts,
            agentTemplate.masterProductType
          ),
          forbiddenAccessChannels: getForbiddenAccessChannels(
            data?.forbiddenAccessChannels,
            data?.lockedAccessChannels
          ),
        })
        const isAssign = !agentData?.discountPolicies?.some(
          (policy) => policy?.masterProductType === productType
        )
        getRateLim({ id: agentId as string, discountPolicyId: agentTemplate?.id, isAssign })

        setTableConf((prevState) => ({ ...prevState, page: 1 }))
      }
    }
  }, [
    isNotFound,
    isFetching,
    agentData,
    agentId,
    data,
    discountPolicyTemplates,
    form,
    getForbiddenProducts,
    getRateLim,
    productType,
    channels,
    getForbiddenAccessChannels,
  ])

  useEffect(() => {
    // SET DEFAULT PRODUCT TYPE FROM AGENT DISCOUNT POLICY
    if (agentData) {
      const agTypeId = agentData?.accountType?.id
      setIsPrepaid(agTypeId === ACCOUNT_TYPE_TO_ID.PREPAID)

      if (agentData?.discountPolicies?.length) {
        const selectedProductType = agentData?.discountPolicies[0]?.masterProductType
        setProductType(selectedProductType)
        form.setFieldValue('productType', selectedProductType)
      }
    }
  }, [agentData, form])

  const isLoading =
    isFetching ||
    isLoadingTemplates ||
    isLoadingAgent ||
    crDiscPolResp.isLoading ||
    upDiscPolResp.isLoading ||
    delDiscResp.isLoading

  const canEditTree = useMemo(
    () =>
      agentData?.agentLevel?.level === 1 && agentData.agentType.id === AGENT_TYPE_ID_ENUM.INDIRECT,
    [agentData]
  )

  const isFirstLevel = useMemo(
    () => agentData?.agentLevel?.level === 1,
    [agentData?.agentLevel?.level]
  )

  const discountTemplateButton = (
    <Space size={10}>
      <FormItem name='productType' className={classNames('noBottomMargin', styles.templateButton)}>
        <Select
          disabled={isViewMode}
          options={productTypes}
          value={productType}
          onChange={(val) => onChangeProductType(val)}
          onClear={onClearProductType}
          allowClear
          placeholder='Select Product Type'
        />
      </FormItem>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item
            name='templateId'
            className={classNames('noBottomMargin', styles.templateButton)}
          >
            <Select
              placeholder='Discount Policy'
              disabled={isViewMode || !getFieldValue('productType')}
              onChange={onTemplateChange}
              onClear={onClearTemplate}
              dropdownMatchSelectWidth={false}
              allowClear
            >
              {discountPolicyTemplates?.items.map((template) => (
                <Select.Option value={template.id} key={template.id}>
                  {template?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form.Item>
    </Space>
  )

  const discountPolicyStatus = (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => (
        <Form.Item
          name={['parentDiscountPolicy', 'status']}
          valuePropName='checked'
          style={{ alignSelf: 'flex-start' }}
          className='noBottomMargin'
        >
          <div className={styles.policyStatus}>
            {getFieldValue(['parentDiscountPolicy', 'status']) ? 'Active' : 'Inactive'}
          </div>
        </Form.Item>
      )}
    </Form.Item>
  )

  const content = (
    <div className={styles.policy}>
      {canViewPolicyManagement && (
        <>
          <Form
            form={form}
            onFinish={onFinish}
            layout='horizontal'
            initialValues={{ discountPolicyProducts: data?.agentProducts }}
          >
            <div className={styles.wrapperProducAccessAndAccessChanel}>
              {!isPrepaid && (
                <>
                  <ProductAccess
                    onProductSelect={onProductSelect}
                    mappedProducts={mappedProducts}
                    totalCount={!productType ? 0 : masterProductsListPostpaid?.totalCount}
                    setTableConf={setTableConf}
                    isFetching={isMasterProductsPostpaidFetching}
                    view={isViewMode}
                    isFirstLevel={isFirstLevel}
                  />
                  <AccessChannelBlock view={isViewMode} />
                </>
              )}

              {isPrepaid && (
                <>
                  <PrepaidProductAccessBlock
                    title='Agent Forbidden Products'
                    view={isViewMode}
                    agentId={agentId}
                    productType={productType}
                    isFirstLevel={isFirstLevel}
                  />
                  {agentData?.agentType?.id !== AGENT_TYPE_ID_ENUM.RESELLER ? (
                    <PrepaidProductAccessBlock
                      title='Tree Forbidden Products'
                      isTree
                      view={isViewMode}
                      canEditTree={!canEditTree}
                      agentId={agentId}
                      productType={productType}
                      isFirstLevel={isFirstLevel}
                    />
                  ) : (
                    <AccessChannelBlock view={isViewMode} />
                  )}
                  {agentData?.agentType?.id !== AGENT_TYPE_ID_ENUM.RESELLER && (
                    <AccessChannelBlock view={isViewMode} />
                  )}
                </>
              )}
            </div>

            <Wrapper
              title='Discount Policy'
              statusButton={discountPolicyStatus}
              rightActions={discountTemplateButton}
            >
              <DiscountPolicyBlock
                view={isViewMode}
                rateLimits={getRateLimResp?.data}
                isPostpaid={!isPrepaid}
                masterProductsList={masterProductsList?.items}
                isFirstLevel={isFirstLevel}
              />
            </Wrapper>
          </Form>

          <Row className={styles.footer} justify='end'>
            <Space size={10}>
              <Button color='blue' size='middle' onClick={onCancelButtonClick}>
                Cancel
              </Button>
              <Button
                color='blue'
                htmlType='submit'
                size='middle'
                type='primary'
                onClick={onSubmitButtonClick}
                disabled={agentData?.isDeleted}
              >
                {isViewMode ? 'Edit' : 'Save Changes'}
              </Button>
            </Space>
          </Row>
        </>
      )}
    </div>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
