import React, { ReactNode } from 'react'
import { ReactComponent as ReactLogo } from './error.svg'
import styles from './ErrorBoundary.module.scss'

interface IProps {
  children?: ReactNode
}

interface IState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<IProps, IState> {
  state: IState = { hasError: false }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles['error-boundary']}>
          <ReactLogo className={styles['logo-error']} />
          <h1>Something went wrong.</h1>
        </div>
      )
    }
    return this.props.children
  }
}
export default ErrorBoundary
