import { IViewMode } from '../../../../../../../../Finance/models/IViewMode'
import { useParams } from 'react-router-dom'
import {
  useGetCountryListQuery,
  useLazyGetDistrictListQuery,
  useLazyGetProvinceListQuery,
} from '../../../../../../../core/http/VendorManagementApi'
import { useEffect } from 'react'
import styles from '../../UsersManagementView/styles.module.scss'
import { Wrapper } from '../../../../../../../../salesNetwork/components/Wrapper'
import FormItem from 'antd/es/form/FormItem'
import {
  maxLengthValidator,
  requiredValidator,
} from '../../../../../../../../../helpers/validation'
import { Select as AntSelect, Spin } from 'antd'
import { TextField } from '../../../../../../../../../shared/components/textfield/TextField'
import { useGetUserByIdQuery } from '../../../../../../../core/http/UsersManagementApi'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

export const Address = ({ view }: IViewMode) => {
  const form = useFormInstance()
  const { id } = useParams<{ id: string }>()

  const { data: dataVendor } = useGetUserByIdQuery(id as string, { skip: !id })
  const { data: dataCountry = { items: [] }, isFetching: isFC } = useGetCountryListQuery()
  const [getProvinceByCountryId, { data: dataProvince = { items: [] }, isFetching: isFP }] =
    useLazyGetProvinceListQuery()
  const [getDistrictByProvinceId, { data: dataDistrict = { items: [] }, isFetching: isFD }] =
    useLazyGetDistrictListQuery()

  const onProvinceChange = (value: number) => {
    getDistrictByProvinceId(value)
    form.setFieldValue('districtId', null)
  }

  const clearProvinceAndDistrict = () => {
    form.setFields([
      { name: 'provinceId', value: null, errors: [] },
      { name: 'districtId', value: null, errors: [] },
    ])
  }

  useEffect(() => {
    if (dataCountry && form.getFieldValue('countryId')) {
      getProvinceByCountryId(form.getFieldValue('countryId'))
    }
  }, [dataCountry, form, getProvinceByCountryId])

  useEffect(() => {
    if (dataProvince && form.getFieldValue('provinceId')) {
      getDistrictByProvinceId(form.getFieldValue('provinceId'))
    }
  }, [dataCountry, dataProvince, form, getDistrictByProvinceId, getProvinceByCountryId])

  useEffect(() => {
    if (dataVendor) {
      getProvinceByCountryId(dataVendor?.country?.id)
      getDistrictByProvinceId(dataVendor?.province?.id)
    }
  }, [view, dataVendor, form, getDistrictByProvinceId, getProvinceByCountryId])

  return (
    <div className={styles.wrapperBlock}>
      <Spin spinning={isFC || isFD || isFP}>
        <Wrapper title='Address'>
          <div className={styles.dblRow}>
            <FormItem
              name='countryId'
              label='Country'
              rules={[{ required: true, validator: requiredValidator('Country') }]}
            >
              <AntSelect
                disabled={view || !dataCountry.items.length}
                onChange={(val) => {
                  getProvinceByCountryId(val)
                  clearProvinceAndDistrict()
                }}
                onClear={clearProvinceAndDistrict}
                options={dataCountry.items}
                placeholder='Select Country'
                allowClear
              />
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue, setFields }) => {
                const isDisabled = view || !dataProvince.items.length || !getFieldValue('countryId')
                return (
                  <FormItem
                    name='provinceId'
                    label='Province'
                    style={{ margin: '0 12px' }}
                    rules={[
                      !isDisabled
                        ? { required: true, validator: requiredValidator('Province') }
                        : {},
                    ]}
                  >
                    <AntSelect
                      disabled={isDisabled}
                      onChange={(val) => onProvinceChange(val)}
                      onClear={() => {
                        setFields([{ name: 'districtId', value: null }])
                      }}
                      options={dataProvince.items}
                      allowClear
                      placeholder='Select Province'
                    />
                  </FormItem>
                )
              }}
            </FormItem>

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const isDisabled =
                  view || !dataDistrict.items.length || !getFieldValue('provinceId')
                return (
                  <FormItem
                    name='districtId'
                    label='City/District'
                    rules={[
                      !isDisabled
                        ? { required: true, validator: requiredValidator('City/District') }
                        : {},
                    ]}
                  >
                    <AntSelect
                      disabled={isDisabled}
                      options={dataDistrict.items}
                      allowClear
                      placeholder='Select City/District'
                    />
                  </FormItem>
                )
              }}
            </FormItem>
          </div>

          <div>
            <FormItem
              name='addressLine1'
              label='Address line 1'
              rules={[
                {
                  required: true,
                  validator: requiredValidator('Address line 1'),
                },
                { validator: maxLengthValidator(50) },
              ]}
            >
              <TextField placeholder='Address line 1' disabled={view} />
            </FormItem>
          </div>

          <div>
            <FormItem
              name='addressLine2'
              label='Address line 2'
              className='noBottomMargin'
              rules={[{ validator: maxLengthValidator(50) }]}
            >
              <TextField placeholder='Address line 2' disabled={view} />
            </FormItem>
          </div>
        </Wrapper>
      </Spin>
    </div>
  )
}
