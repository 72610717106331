function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0')
}

export const convertSecToMin = (totalSeconds: number): string => {
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60

  return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
}
