import React, { useContext, useMemo } from 'react'
import { Spin } from 'antd'

import { IconButton } from '../../../../shared/components/iconButton/IconButton'
import { InternalChatDetailsMainInfo } from './Components/MainInfo'
import { ChatDetailsUserInfo } from 'src/shared/components/chat/Components'
import { InternalChatAttachmentsInfo } from './Components/AttachmentsInfo'
import { ArrowLeftIcon } from '../../../../assets/svg'
import { InternalChatContext } from '../InternalChatModal'
import { useGetInternalChatInfoQuery } from '../../core/http/InternalChatsApi'

import { useAppSelector } from '../../../../redux'
import { selectInternalChatChatsState } from '../../core/store/Chats'
import { RightModalContext } from '../../../Modals'
import { modalViewTypeEnum } from '../../core/models'
import styles from './styles.module.scss'

export const ChatDetails = () => {
  const { onSelectChat } = useContext(InternalChatContext)
  const {
    props: { id: fromChatId },
    setProps,
  } = useContext(RightModalContext)

  const { openedChatId } = useAppSelector(selectInternalChatChatsState)

  const {
    data: currentChatInfo,
    isLoading,
    isFetching,
  } = useGetInternalChatInfoQuery(openedChatId as number, {
    skip: !openedChatId,
  })

  const fullName = useMemo(() => {
    return `${currentChatInfo?.firstName || ''} ${currentChatInfo?.lastName || ''}`
  }, [currentChatInfo])

  const content = (
    <div className={styles.internalChatDetailsContainer}>
      <IconButton
        color='blue'
        type='default'
        icon={<ArrowLeftIcon />}
        className={styles.backButton}
        onClick={() => {
          onSelectChat(modalViewTypeEnum.messaging, fromChatId || (openedChatId as number))
          setProps((prev) => ({ ...prev, id: null }))
        }}
      />
      <InternalChatDetailsMainInfo
        fullName={fullName}
        logo={currentChatInfo?.logo}
        openedChatId={openedChatId as number}
      />
      <ChatDetailsUserInfo
        position={currentChatInfo?.userPosition?.name}
        department={currentChatInfo?.department?.name}
        userContactChannels={currentChatInfo?.userContactChannels}
      />
      <InternalChatAttachmentsInfo />
    </div>
  )

  return isFetching || isLoading ? <Spin>{content}</Spin> : content
}
