import { FormInstance, Form, Select } from 'antd'
import FormItem from 'antd/lib/form/FormItem'

import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { TextField } from 'src/shared/components/textfield/TextField'
import { Wrapper } from '../Wrapper'

// stylees
import styles from './styles.module.scss'

import {
  CopyIcon,
  DoubleCheckMarkIcon,
  EmailFilledIcon,
  InfoIcon,
  ReplayCircleRounded,
  SendPlaneIcon,
  TickIcon,
} from 'src/assets/svg'

import { useContext, useEffect, useState } from 'react'
import { RightModalContext } from 'src/features/Modals'
import { useParams } from 'react-router'
import {
  useGetAgentByIdQuery,
  useLazyGetAgentByIdQuery,
  useLazyGetVerificationTimerQuery,
} from '../../core/http/AgentManagement'
import { getSecondsFromDateInFuture } from '../../helpers'
import { RIGHT_MODALS } from 'src/helpers/contants'
import { generatePassword, onCopyText } from 'src/helpers/utils'
import { emailValidator, passwordValidator, requiredValidator } from 'src/helpers/validation'
import { IDENTIFIERS_TYPE_NEW } from 'src/features/salesNetwork/models/IAgent'
import { Tooltip } from 'src/shared/components/tooltip/Tooltip'

export const options = [
  {
    value: IDENTIFIERS_TYPE_NEW.EMAIL.type,
    name: IDENTIFIERS_TYPE_NEW.EMAIL.name,
    icon: <EmailFilledIcon />,
  },
]

interface IContactInformation {
  view?: boolean
  form: FormInstance
}

const ResellerCredentials = ({ view = false, form }: IContactInformation) => {
  const { onOpen } = useContext(RightModalContext)
  const { agentId, mode } = useParams()

  const { data: dataAgent } = useGetAgentByIdQuery(agentId, { skip: !agentId })

  const isEditMode = mode === 'edit'
  const isViewMode = mode === 'view'

  const [getVerificationTimer, dVerification] = useLazyGetVerificationTimerQuery()
  const [getAgentById] = useLazyGetAgentByIdQuery()

  const [timeout, setTimeout] = useState(0)

  useEffect(() => {
    if (!view) return
    const email = form.getFieldValue(['resellerCredentials', 'email'])
    const isOtpSent = form.getFieldValue(['resellerCredentials', 'isOtpSent'])

    isOtpSent &&
      getVerificationTimer({
        type: IDENTIFIERS_TYPE_NEW.EMAIL.type,
        value: email,
      })
  }, [form, view])

  useEffect(() => {
    if (!dVerification.data || !view) return
    const { timeoutAt } = dVerification.data

    const timeoutLocal = getSecondsFromDateInFuture(timeoutAt)

    const countdown = setInterval(() => {
      setTimeout(Math.abs(timeoutLocal) - 1)
    }, 1000)

    if (timeoutLocal >= 0) {
      clearTimeout(countdown)
      getAgentById(agentId)
    }

    return () => clearInterval(countdown)
  }, [dVerification.data, timeout])

  const onVerifyIdentifiers = (selectedType: string, selectedValue: string) => () => {
    onOpen(RIGHT_MODALS.SALES.IDENTIFIER_VERIFICATION, {
      title: 'User Identifiers Verification',
      selectedType,
      selectedValue,
      agentId,
    })
  }

  const onResetPassword = () => {
    form.setFields([
      {
        name: ['resellerCredentials', 'password'],
        value: generatePassword(),
        errors: [],
      },
    ])
  }

  const infoText =
    "The password is encrypted, that's why you can’t see it on UI. To change the password, generate a new one or create one to your requirements consider validation rules!"

  return (
    <Wrapper title='Reseller Credentials' className={styles['wrapper-user-identifiers']}>
      <div className={styles.identifierItemContainer}>
        <FormItem
          label='Credential Type'
          name={['resellerCredentials', 'email']}
          style={{ flexBasis: '30%', marginRight: '24px', minWidth: 256 }}
          className={styles['form-item-inp']}
          rules={[{ required: true, validator: requiredValidator('Credentials Email') }]}
        >
          <Select disabled={view} placeholder='Select Email'>
            {options.map(({ value, name, icon }) => (
              <Select.Option key={value} value={value}>
                <div className={styles.wrapperContentOption}>
                  <div className={styles.wrapperIcon}>{icon}</div>
                  <div>{name}</div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <Form.Item
          label='Credential Value'
          name={['resellerCredentials', 'value']}
          style={{
            flexBasis: '70%',
            ...(isViewMode && { marginRight: '24px', flexBasis: '65%' }),
          }}
          rules={[
            {
              required: true,
              validator: requiredValidator('Credentials Value'),
            },
            { validator: emailValidator },
          ]}
        >
          <TextField disabled={view} placeholder='Type Email' />
        </Form.Item>

        {view && (
          <div className={styles['wrapper-icons']}>
            <div className={styles['wrapper-icon']}>
              <IconButton
                onClick={() => onCopyText(form.getFieldValue(['resellerCredentials', 'value']))}
                slim
                size='large'
                color='orange'
                icon={<CopyIcon />}
                type='default'
              />
            </div>

            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const isOtpSent = getFieldValue(['resellerCredentials', 'isOtpSent'])
                const isVerified = getFieldValue(['resellerCredentials', 'isVerified'])
                const value = getFieldValue(['resellerCredentials', 'value'])

                return (
                  <div className={styles['wrapper-icon']} style={{ marginLeft: '16px' }}>
                    {!isOtpSent && !isVerified && (
                      <IconButton
                        slim
                        size='large'
                        color='blue'
                        icon={<TickIcon />}
                        type='default'
                        onClick={onVerifyIdentifiers(IDENTIFIERS_TYPE_NEW.EMAIL.type, value)}
                        disabled={dataAgent?.isDeleted}
                      />
                    )}

                    {isOtpSent && !isVerified && (
                      <IconButton
                        slim
                        size='large'
                        color='blue'
                        icon={<SendPlaneIcon />}
                        type='primary'
                        onClick={onVerifyIdentifiers(IDENTIFIERS_TYPE_NEW.EMAIL.type, value)}
                        disabled={dataAgent?.isDeleted}
                      />
                    )}

                    {isVerified && (
                      <IconButton
                        slim
                        size='large'
                        color='success'
                        icon={<DoubleCheckMarkIcon />}
                        type='default'
                      />
                    )}
                  </div>
                )
              }}
            </FormItem>
          </div>
        )}
      </div>

      <div className={styles.identifierItemContainer}>
        <Form.Item
          label='Username'
          name={['resellerCredentials', 'username']}
          style={{ flexBasis: '30%', marginRight: '24px', minWidth: 256 }}
          rules={[
            {
              required: true,
              validator: requiredValidator('Username'),
            },
          ]}
        >
          <TextField disabled={view} placeholder='Type Username' />
        </Form.Item>

        <FormItem shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const hasPassword = getFieldValue(['agentAccessOption', 'hasPassword'])

            return (
              <Form.Item
                label='Password'
                name={['resellerCredentials', 'password']}
                style={{ flexBasis: '70%' }}
                rules={[
                  {
                    validator: passwordValidator(),
                  },
                ]}
              >
                <TextField
                  disabled={view}
                  placeholder='Type Password'
                  prefix={
                    isEditMode || !view ? (
                      <div className={styles.passContainer} onClick={onResetPassword}>
                        <ReplayCircleRounded />
                      </div>
                    ) : isViewMode && hasPassword ? (
                      <div className={styles.tooltipContainer}>
                        <Tooltip title={infoText}>
                          <span>
                            <InfoIcon />
                          </span>
                        </Tooltip>
                      </div>
                    ) : null
                  }
                />
              </Form.Item>
            )
          }}
        </FormItem>
      </div>
    </Wrapper>
  )
}
export default ResellerCredentials
