import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { Tags } from 'src/shared/components/tags'

// styles
import styles from '../../style.module.scss'

interface ITagsBlock {
  tags: string[]
}

export const TagsBlock = ({ tags = [] }: ITagsBlock) => {
  return (
    <Wrapper title='Tags' className={styles.mb24}>
      <Tags tags={tags} />
    </Wrapper>
  )
}
