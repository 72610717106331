import { createApi } from '@reduxjs/toolkit/query/react'
import {
  IAgentAll,
  IAgentAllGetResponse,
  IAgentBankAccountInfo,
  IAgentLanguagesListResponse,
  IAgentLevelListResponse,
  IAgentTypeListResponse,
  IContactInfoUpdateResponse,
  ICountryListResponse,
  IDistrictListResponse,
  IIdentifiersListResponse,
  IProvinceListResponse,
  ISendVerification,
  ISendVerificationBody,
  IUpdateAgentMpin,
  IVerifyPhoneBody,
} from 'src/features/salesNetwork/models/IAgent'
import { IAgent, IRootAgent } from '../../models/IAllTable'
import { IAgentSalesCounters, IAgentSalesNetwork } from '../../models/IAgentSalesNetworkTable'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../../shared/models/ITableConf'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from 'src/shared/models/ITableResponse'
import { IMPinBody } from 'src/features/Support/models/IMPin'
import { IMutateUserContacts } from 'src/features/Settings/models/IUser'
import { ITransactionHistory } from '../../models/ITransactionHistory'
import {
  ICustomerBankAccountInfo,
  ICustomerWalletInfo,
  IUpdateCustomerBank,
  IUpdateCustomerWalletAccount,
} from '../../models/ICustomer'
import { string } from 'prop-types'

export const agentManagementApi = createApi({
  reducerPath: 'agentManagement',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'IAgentSalesNetworkResponse',
    'IAllTableResponse',
    'IFilterFields',
    'IAgentAllGetResponse',
    'IAgentSalesCounters',
    'TransactionHistory',
    'AgentBanks',
    'IAgentData',
    'AgentWallets',
  ],
  endpoints: (builder) => ({
    getAgentList: builder.query<ITableResponse<IAgent>, ITableConf>({
      query: (tableConf) => ({
        url: `agents`,
        params: tableConf,
      }),
      providesTags: () => ['IAllTableResponse'],
    }),
    getAllTableFiltersList: builder.query<Array<IFilterFields>, void | null>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'agents/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({ url: `agents/filter-values?filterField=${filter.field}` })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: () => ['IFilterFields'],
    }),
    getSalesNetworkTable: builder.query<ITableResponse<IAgentSalesNetwork>, ITableConf>({
      query: (tableConf) => {
        return {
          url: `sales-network`,
          params: tableConf,
        }
      },
      keepUnusedDataFor: 0,
    }),
    getSalesNetworkFiltersList: builder.query<Array<IFilterFields>, number | string | undefined>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'sales-network/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `sales-network/filter-values?filterField=${filter.field}&id=${arg}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      keepUnusedDataFor: 0,
    }),

    getAgentCounters: builder.query<IAgentSalesCounters, string | undefined>({
      query: (id) => ({
        url: `/sales-network/${id}/counters`,
      }),
      providesTags: () => ['IAgentSalesCounters'],
    }),

    getSubAgents: builder.query<ITableResponse<IAgentSalesNetwork>, ITableConf>({
      query: (tableConf) => ({
        url: `sales-network`,
        params: tableConf,
      }),
      keepUnusedDataFor: 0,
      providesTags: () => ['IAgentSalesNetworkResponse'],
    }),
    getAgentById: builder.query<IAgentAllGetResponse, number | string | undefined>({
      query: (id) => ({
        url: `agents/${id}`,
      }),
      providesTags: (agent) => [{ type: 'IAgentAllGetResponse', id: agent?.id }],
    }),
    getAgentByIdA: builder.query<IAgentAllGetResponse, number | string | undefined>({
      query: (id) => ({
        url: `agents/${id}`,
      }),
      providesTags: (agent) => [{ type: 'IAgentData', id: agent?.id }],
    }),
    getAgentTypeList: builder.query<IAgentTypeListResponse, void>({
      query: () => `agent-types`,
    }),
    getAgentLevelList: builder.query<IAgentLevelListResponse, void>({
      query: () => `agent-levels`,
    }),
    getAgentLanguageslList: builder.query<IAgentLanguagesListResponse, void>({
      query: () => `languages`,
    }),
    getCountryList: builder.query<ICountryListResponse, string>({
      query: () => `locations/countries`,
    }),
    getProvinceList: builder.query<IProvinceListResponse, string | number>({
      query: (id) => `locations/${id}/provinces`,
    }),
    getDistrictList: builder.query<IDistrictListResponse, string | number>({
      query: (id) => `locations/${id}/districts`,
    }),
    getIdentifiersList: builder.query<IIdentifiersListResponse, string>({
      query: () => `agent-identifier-types`,
    }),

    deleteAgent: builder.mutation<void, number>({
      query: (id) => ({
        url: `agents/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['IAllTableResponse', 'IFilterFields'],
    }),
    patchAgent: builder.mutation<IAgentAll, { id: string | number | undefined; body: FormData }>({
      query: (data) => ({
        url: `agents/${data.id}`,
        method: 'PATCH',
        body: data.body,
      }),
      invalidatesTags: () => ['IAllTableResponse', 'IFilterFields', 'IAgentAllGetResponse'],
    }),
    createAgent: builder.mutation<IAgentAll, FormData>({
      query: (body) => ({
        url: `agents`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IAllTableResponse', 'IFilterFields'],
    }),
    updateAgent: builder.mutation<IAgentAll, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: `agents/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        'IFilterFields',
        { type: 'IAgentData', id: arg?.id },
      ],
    }),
    updateAgentContactInfo: builder.mutation<
      IAgentAll,
      { id: string; body: IContactInfoUpdateResponse }
    >({
      query: ({ id, body }) => ({
        url: `agents/${id}/agent-contacts`,
        method: 'PUT',
        body,
      }),
    }),

    updateAgentIdentifiers: builder.mutation<IAgentAll, { id: string; body: IMutateUserContacts }>({
      query: ({ id, body }) => ({
        url: `agents/${id}/agent-identifiers`,
        method: 'PUT',
        body,
      }),
    }),

    getAgentTableFileByFormatCsvOrXlsx: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx' }
    >({
      query: ({ conf, formatFile }) => ({
        url: `agents/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),
    getRootAgentById: builder.query<IRootAgent, number | string>({
      query: (id) => ({
        url: `agents/${id}/root-agent`,
      }),
    }),
    resetMpin: builder.mutation<IAgentAll, IMPinBody>({
      query: (body) => ({
        url: 'agents/reset/mpin',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['IAgentAllGetResponse'],
    }),
    transferAgent: builder.mutation<IAgentAll, { id: number; body: Record<string, unknown> }>({
      query: ({ id, body }) => ({
        url: `agents/${id}/transfer`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['IAllTableResponse', 'IAgentAllGetResponse', 'IFilterFields'],
    }),
    resetAgentMpin: builder.mutation<IAgentAllGetResponse, IUpdateAgentMpin>({
      query: (body) => ({
        url: `agents/reset/mpin`,
        method: 'PATCH',
        body,
      }),
      // invalidatesTags: (res) => [
      //   { type: 'IAgentAllGetResponse', id: res?.id },
      //   'IAllTableResponse',
      // ],
    }),
    generateApiKey: builder.mutation<{ id: string; secret: string }, string>({
      query: (id) => ({
        url: `/agents/${id}/api-keys`,
        method: 'POST',
      }),
      // invalidatesTags: () => ['IAgentAllGetResponse'],
    }),

    sendVerification: builder.mutation<ISendVerification, ISendVerificationBody>({
      query: (body) => ({
        url: '/agents/verifications/send',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IAgentAllGetResponse', 'IAllTableResponse'],
    }),

    verifyPhone: builder.mutation<null, { id: number; body: IVerifyPhoneBody }>({
      query: ({ id, body }) => ({
        url: `/agents/${id}/verifications/verify`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IAgentAllGetResponse', 'IAllTableResponse'],
    }),

    sendMessage: builder.mutation<
      null,
      { id: string; type: string; value: string; body: { message: string } }
    >({
      query: ({ id, type, value, body }) => ({
        url: `/agents/${id}/messages/${type}/${value}`,
        method: 'POST',
        body,
      }),
    }),

    getVerificationTimer: builder.query<ISendVerification, { type: string; value: string }>({
      query: ({ type, value }) => ({
        url: `/agents/verifications/${type}/${value}`,
      }),
      keepUnusedDataFor: 0,
      // providesTags: (agent) => [{ type: 'IAgentAllGetResponse', id: agent?.id }],
    }),

    getAgentTransactionsById: builder.query<
      ITableResponse<ITransactionHistory>,
      { id: number | string; params: ITableConf }
    >({
      query: ({ id, params }) => ({
        url: `agents/${id}/transactions`,
        params,
      }),
      transformResponse: (res: ITableResponse<ITransactionHistory>) => ({
        ...res,
        items: res.items.map((report) => {
          if (report?.countSubTransaction !== 0) return { ...report, children: [] }
          return report
        }),
      }),
      providesTags: () => ['TransactionHistory'],
    }),
    getATFiltersList: builder.query<Array<IFilterFields>, string>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'agents/transactions/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `agents/${arg}/transactions/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),
    getATCFiltersList: builder.query<Array<IFilterFields>, string>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'agents/transactions/chat/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `agents/${arg}/transactions/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),
    exportAgentTransactionsTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx' }
    >({
      query: ({ conf, formatFile }) => ({
        url: `agents/${conf.id}/transactions/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getAgentBankAccountsById: builder.query<Array<IAgentBankAccountInfo>, string>({
      query: (id) => ({
        url: `agents/${id}/bank-account`,
      }),
      providesTags: ['AgentBanks'],
    }),

    updateAgentBankAccount: builder.mutation<
      ICustomerBankAccountInfo,
      { agentId: string; body: IUpdateCustomerBank }
    >({
      query: ({ agentId, body }) => ({
        url: `agents/${agentId}/bank-account`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['AgentBanks'],
    }),

    getAgentWalletAccountAccountsById: builder.query<Array<ICustomerWalletInfo>, string>({
      query: (id) => ({
        url: `/agents/${id}/wallet-account`,
      }),
      providesTags: ['AgentWallets'],
    }),

    updateAgentWalletAccount: builder.mutation<
      void,
      { agentId: string; body: IUpdateCustomerWalletAccount }
    >({
      query: ({ agentId, body }) => ({
        url: `agents/${agentId}/wallet-account`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['AgentWallets'],
    }),
    sendTransactionToChat: builder.query<
      { link: string },
      { id: string; chatId: number | null; params: ITableConf }
    >({
      query: ({ id, chatId, params }) => ({
        url: `/agents/${id}/transactions/reports/chat/${chatId}/send`,
        params,
      }),
    }),
    checkIfAgentHasChild: builder.query<void, { agentId: string; childId: string }>({
      query: ({ agentId, childId }) => ({
        url: `/agents/${agentId}/has-child/${childId}`,
      }),
    }),
  }),
})

export const {
  useGetAgentListQuery,
  useLazyGetAgentListQuery,
  useLazyGetAgentTableFileByFormatCsvOrXlsxQuery,
  useGetAllTableFiltersListQuery,
  useGetSalesNetworkFiltersListQuery,
  useLazyGetAgentByIdQuery,
  useGetAgentByIdQuery,
  useGetAgentByIdAQuery,
  useCreateAgentMutation,
  useGetSalesNetworkTableQuery,
  useGetSubAgentsQuery,
  useGetAgentTypeListQuery,
  useGetAgentLevelListQuery,
  useGetAgentLanguageslListQuery,
  useGetCountryListQuery,
  useLazyGetProvinceListQuery,
  useLazyGetDistrictListQuery,
  useGetIdentifiersListQuery,
  useUpdateAgentMutation,
  useUpdateAgentContactInfoMutation,
  useUpdateAgentIdentifiersMutation,
  usePatchAgentMutation,
  useDeleteAgentMutation,
  useLazyGetRootAgentByIdQuery,
  useResetAgentMpinMutation,
  useGetRootAgentByIdQuery,
  useResetMpinMutation,
  useTransferAgentMutation,
  useGetAgentTransactionsByIdQuery,
  useGetATFiltersListQuery,
  useGetATCFiltersListQuery,
  useLazyExportAgentTransactionsTableQuery,
  useGenerateApiKeyMutation,
  useSendVerificationMutation,
  useVerifyPhoneMutation,
  useGetVerificationTimerQuery,
  useLazyGetVerificationTimerQuery,
  useGetAgentCountersQuery,
  useSendMessageMutation,
  useGetAgentBankAccountsByIdQuery,
  useUpdateAgentBankAccountMutation,
  useGetAgentWalletAccountAccountsByIdQuery,
  useUpdateAgentWalletAccountMutation,
  useLazySendTransactionToChatQuery,
  useLazyCheckIfAgentHasChildQuery,
} = agentManagementApi
