import { Link } from 'react-router-dom'
import styles from '../styles.module.scss'
import { useTablePagination } from '../../../../../../shared/hooks/table/useTablePagination'
import { useOnTableChange } from '../../../../../../shared/hooks/table/useOnTableChange'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import Table, { ColumnsType } from 'antd/es/table'
import emptyProductImage from '../../../../../../assets/img/ProductTablePlaceholder.png'
import { Switch } from 'antd'
import { IMasterProduct } from '../../../../../Settings/models/IMasterProduct'
import { SETTINGS_ENUM } from '../../../../../../routes/settings'
import { ITableConf } from '../../../../../../shared/models/ITableConf'
import { IAgentMasterProductPostpaid } from '../../../../models/IAgentPolicy'
import { TableWrapper } from '../TableWrapper'
import { IViewMode } from '../../../../../Finance/models/IViewMode'

interface IProps extends IViewMode {
  onProductSelect: (product: IAgentMasterProductPostpaid) => void
  mappedProducts: IAgentMasterProductPostpaid[]
  totalCount?: number
  setTableConf: React.Dispatch<React.SetStateAction<ITableConf>>
  isFetching: boolean
  isFirstLevel: boolean
}

export const ProductAccess = ({
  onProductSelect,
  mappedProducts,
  totalCount,
  setTableConf,
  view,
  isFetching,
  isFirstLevel,
}: IProps) => {
  const paginationConf = useTablePagination(totalCount, true, 5)
  const onTableParamsChange = useOnTableChange<IAgentMasterProductPostpaid>(setTableConf)
  const emptyTableConf = useEmptyTable()

  const columns: ColumnsType<IAgentMasterProductPostpaid> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '80%',
      render: (_, row) => {
        return (
          <Link
            to={`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/master-product/view/${row.masterProductId}`}
            className='table-avatar'
          >
            <img alt='master product' src={row?.logo ?? emptyProductImage} />
            <div>{row?.name}</div>
          </Link>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      width: '10%',
      render: (status: IMasterProduct['status'], row: IAgentMasterProductPostpaid) => {
        return (
          <Switch
            disabled={view || !isFirstLevel}
            defaultChecked={status}
            checked={status}
            onChange={(status) => onProductSelect({ ...row, status })}
          />
        )
      },
    },
  ]

  return (
    <TableWrapper title='Product Access'>
      <Table
        locale={emptyTableConf}
        columns={columns}
        pagination={paginationConf}
        onChange={onTableParamsChange}
        className={styles.table}
        dataSource={mappedProducts}
        loading={isFetching}
        rowKey={(record) => record.masterProductId}
      />
    </TableWrapper>
  )
}
