import React from 'react'
import FormItem from 'antd/es/form/FormItem'
import { Wrapper } from '../../../../../../../../../../../shared/components/wrapper/Wrapper'
import { TextField } from '../../../../../../../../../../../shared/components/textfield/TextField'

import styles from '../GeneralInfo/styles.module.scss'

const VendorInfo = () => {
  return (
    <Wrapper title='Vendor info'>
      <div className={styles.dblRow}>
        <FormItem name='vendorId' label='Vendor ID' rules={[{ required: true }]}>
          <TextField disabled />
        </FormItem>
        <FormItem name='vendorName' label='Vendor Name' rules={[{ required: true }]}>
          <TextField placeholder='Enter name' />
        </FormItem>
      </div>
    </Wrapper>
  )
}

export default VendorInfo
