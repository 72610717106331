import { Wrapper } from '../../../../../../../../../salesNetwork/components/Wrapper'
import { IViewMode } from '../../../../../../../../../Finance/models/IViewMode'
import FormList from 'antd/lib/form/FormList'
import styles from '../styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { numberRange, requiredValidator } from '../../../../../../../../../../helpers/validation'
import { Form, Select, Spin } from 'antd'
import { TextField } from '../../../../../../../../../../shared/components/textfield/TextField'
import { IconButton } from '../../../../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from '../../../../../../../../../../assets/svg'
import React from 'react'
import { useParams } from 'react-router-dom'
import {
  useGetProductsListQuery,
  useGetVendorWalletTypesQuery,
} from '../../../../../../../../core/http/VendorManagementApi'
import {
  IVendorPaymentPolicyProduct,
  IVendorPaymentPolicyType,
} from '../../../../../../../../models/IVendorManagement'

export const ProductsBlock = ({ view }: IViewMode) => {
  const { venId } = useParams<{ venId: string }>()
  const { data: walletTypes, isFetching: isFetchingWalletTypes } = useGetVendorWalletTypesQuery()
  const { data: products, isFetching } = useGetProductsListQuery(
    { vendorId: venId as string },
    { skip: !venId }
  )

  const isLoading = isFetching || isFetchingWalletTypes

  const content = (
    <div className={styles.productsBlock}>
      <Wrapper title='Products'>
        <FormList name='policyProducts'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name, key }) => (
                <div key={key} className={styles.walletTypeRow}>
                  <FormItem noStyle shouldUpdate>
                    {({ getFieldValue, setFieldValue }) => {
                      const selectedWalletTypes = (getFieldValue('contractTypes') || [])
                        .map((contract: IVendorPaymentPolicyType) => contract?.vendorWalletType)
                        .filter((type: string) => !!type)

                      return (
                        <FormItem
                          name={[name, 'vendorWalletType']}
                          label='Wallet Type'
                          rules={[
                            {
                              required: true,
                              validator: requiredValidator('Wallet Type'),
                            },
                          ]}
                          className={styles.select}
                        >
                          <Select
                            placeholder='Select Wallet Type'
                            allowClear
                            disabled={view}
                            onChange={() => {
                              setFieldValue(['policyProducts', name, 'product', 'id'], null)
                            }}
                          >
                            {walletTypes
                              ?.filter((walletType) => selectedWalletTypes.includes(walletType))
                              .map((type) => (
                                <Select.Option key={type} value={type}>
                                  {type}
                                </Select.Option>
                              ))}
                          </Select>
                        </FormItem>
                      )
                    }}
                  </FormItem>

                  <FormItem noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const selectedProducts = new Set(
                        (
                          (getFieldValue('policyProducts') as Array<IVendorPaymentPolicyProduct>) ||
                          []
                        )
                          .filter((product) => product?.product?.id && product?.vendorWalletType)
                          .map((product) => `${product.product?.id}-${product.vendorWalletType}`)
                      )

                      const currentWalletType = getFieldValue([
                        'policyProducts',
                        name,
                        'vendorWalletType',
                      ])

                      return (
                        <Form.Item
                          name={[name, 'product', 'id']}
                          label='Product'
                          rules={[
                            {
                              required: true,
                              validator: requiredValidator('Product'),
                            },
                          ]}
                          className={styles.select}
                        >
                          <Select
                            placeholder='Select Product'
                            allowClear
                            disabled={view || !currentWalletType}
                          >
                            {products?.items.map((prod) => (
                              <Select.Option
                                key={prod.id}
                                value={prod.id}
                                disabled={selectedProducts.has(`${prod.id}-${currentWalletType}`)}
                              >
                                {prod.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )
                    }}
                  </FormItem>

                  <Form.Item
                    name={[name, 'discountRate']}
                    label='Rate'
                    rules={[
                      {
                        validator: numberRange(-100, 100, 'Rate'),
                      },
                    ]}
                  >
                    <TextField disabled={view} placeholder='Enter Rate' type='number' />
                  </Form.Item>

                  <div className={styles.bucketIcon}>
                    <IconButton
                      block
                      size='large'
                      color='orange'
                      icon={<BucketIcon />}
                      type='default'
                      disabled={view}
                      onClick={() => {
                        remove(name)
                      }}
                    />
                  </div>
                </div>
              ))}

              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const contractTypes = (getFieldValue('contractTypes') || [])
                    .map((contract: IVendorPaymentPolicyType) => contract?.vendorWalletType)
                    .filter((type: string) => !!type)

                  return (
                    <div className={styles.addButton}>
                      <IconButton
                        icon={<AddIcon />}
                        color='orange'
                        block
                        onClick={() => add()}
                        disabled={view || !contractTypes.length}
                      />
                    </div>
                  )
                }}
              </FormItem>
            </>
          )}
        </FormList>
      </Wrapper>
    </div>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
