import styles from './styles.module.scss'
import moment from 'moment'

interface IProps {
  createdAt: string
}

export const DateDelimiter = ({ createdAt }: IProps) => {
  return (
    <div className={styles.daysMessageSeparator}>
      {moment().isSame(createdAt, 'days') ? 'Today' : moment(createdAt).format('MMMM DD')}
    </div>
  )
}
