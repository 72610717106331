import styles from '../styles.module.scss'
import { DiscountPolicyDetailViewTabs } from './Components/Tabs/index'

export const DiscountPolicyView = () => {
  return (
    <div className={styles.layout}>
      <DiscountPolicyDetailViewTabs />
    </div>
  )
}
