import { Form, FormInstance, Select, Tabs, TabsProps } from 'antd'
import TextArea from 'antd/es/input/TextArea'

import { maxLengthValidator, requiredValidator } from 'src/helpers/validation'
import { TextField } from 'src/shared/components/textfield/TextField'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'

// styles
import styles from '../../NotificationPage/styles.module.scss'
import classNames from 'classnames'
import { isArabic } from '../../../../../../helpers/utils'

const TYPES = [{ id: 0, name: 'Email' }]
const AUDIENCE_TYPE = [
  { id: 0, name: 'Agents' },
  { id: 1, name: 'Users' },
]
interface IDetailInfo {
  form: FormInstance
  view?: boolean
  edit?: boolean
}

export const DetailInfo = ({ view, edit }: IDetailInfo) => {
  const items: TabsProps['items'] = [
    {
      key: 'en',
      label: `English`,
      children: (
        <Form.Item
          name='content-1'
          label='Content'
          rules={[{ required: true, validator: requiredValidator('Content') }]}
          style={{ marginBottom: 0 }}
        >
          <TextArea disabled={view} placeholder='Add content' style={{ height: 80 }} />
        </Form.Item>
      ),
    },
    {
      key: 'da',
      label: `Dari`,
      children: (
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item name='content-2' label='Content' style={{ marginBottom: 0 }}>
              <TextArea
                disabled={view}
                placeholder='Add content'
                style={{ height: 80 }}
                className={classNames({ [styles.arabic]: isArabic(getFieldValue('content-2')) })}
              />
            </Form.Item>
          )}
        </Form.Item>
      ),
    },
    {
      key: 'pa',
      label: 'Pashto',
      children: (
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item name='content-3' label='Content' style={{ marginBottom: 0 }}>
              <TextArea
                disabled={view}
                placeholder='Add content'
                style={{ height: 80 }}
                className={classNames({ [styles.arabic]: isArabic(getFieldValue('content-3')) })}
              />
            </Form.Item>
          )}
        </Form.Item>
      ),
    },
  ]

  return (
    <Wrapper title='detail info' className={styles.datailInfoContainer}>
      <div className={styles.dblRow}>
        <Form.Item name='createdBy' label='Created By'>
          <TextField disabled placeholder='Created By' />
        </Form.Item>

        <Form.Item name='createdAt' label='Created Date'>
          <TextField disabled placeholder='Created Date' />
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item
          name='name'
          label='Name'
          rules={[
            { required: true, validator: requiredValidator('Name') },
            { validator: maxLengthValidator(45) },
          ]}
        >
          <TextField disabled={view} placeholder='Name' />
        </Form.Item>

        <Form.Item
          name='type'
          label='Type'
          rules={[{ required: true, validator: requiredValidator('Type') }]}
        >
          <Select disabled={view} placeholder='Type' allowClear>
            {TYPES.map(({ id, name }) => (
              <Select.Option key={id} value={name.toLowerCase()}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <div>
        <Form.Item
          name='audienceType'
          label='Audience Type'
          rules={[{ required: true, validator: requiredValidator('Audience Type') }]}
        >
          <Select disabled={view || edit} placeholder='Audience Type' allowClear>
            {AUDIENCE_TYPE.map(({ id, name }) => (
              <Select.Option key={id} value={name.toLowerCase()}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <div>
        <Tabs items={items} />
      </div>
    </Wrapper>
  )
}
