import React, { memo } from 'react'
import styles from '../../styles.module.scss'

interface IProps {
  actions: Array<string>
}

const PermissionsTableHeader = ({ actions }: IProps) => {
  return (
    <div>
      <div className={styles.tableHeaderTopLine}>
        <div></div>
        <div className={styles.tableHeaderTopLineTitles}>
          <div className={styles.allPermission}>
            <div className={styles.divider}></div>
          </div>
          <div className={`${styles.tableHeaderTopLineTitle} ${styles.accessPermission}`}>
            <div className={styles.divider}></div>
            <div className={styles.title}>ACCESS PERMISSION</div>
          </div>
          <div className={`${styles.tableHeaderTopLineTitle} ${styles.dataPermission}`}>
            <div className={styles.divider}></div>
            <div className={styles.title}>DATA PERMISSION</div>
          </div>
          <div className={`${styles.tableHeaderTopLineTitle} ${styles.processPermission}`}>
            <div className={styles.divider}></div>
            <div className={styles.title}>PROCESS PERMISSION</div>
          </div>
          <div className={`${styles.tableHeaderTopLineTitle} ${styles.tableHeaderTopLineActions}`}>
            <div className={styles.divider}></div>
          </div>
        </div>
      </div>
      <div className={styles.tableHeader}>
        <div className={styles.moduleTitle}>
          <span>MODULE</span>
        </div>
        <div className={styles.actionsNames}>
          <div className={`${styles.actionsName} ${styles.actionsCommonName}`}>ALL</div>
          {actions.map((action) => (
            <div key={action} className={styles.actionsName}>
              {action}
            </div>
          ))}
          <div className={`${styles.actionsName} ${styles.actionsCommonName}`}>ACCESS</div>
        </div>
      </div>
    </div>
  )
}

export default memo(PermissionsTableHeader)
