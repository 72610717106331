import styles from '../../CustomerModal/styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { IClosedGroupCustomer } from '../../../../../models/ICustomer'
import { Form, Select, Spin } from 'antd'
import classNames from 'classnames'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { BucketIcon } from '../../../../../../../assets/svg'
import React, { useCallback, useMemo } from 'react'
import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import { useLazyGetCustomersListQuery } from '../../../../../core/http/CustomersApi'
import { debounce } from 'lodash'
import { ITargetGroupCustomer } from '../../../../../../Settings/models/IRewardPolicy'

interface IProps extends IViewMode {
  name: number
  remove: (index: number | number[]) => void
  customers?: Array<ITargetGroupCustomer>
}

export const CustomerListItem = ({ name, view, remove, customers }: IProps) => {
  const [getList, getListResp] = useLazyGetCustomersListQuery()

  const handleSearch = useCallback(
    debounce((val: string) => {
      if (!val) return
      getList({ search: val })
    }, 300),
    []
  )

  const defaultCustomersList = useMemo(
    () => customers?.map((cust) => ({ ...cust, name: cust.fullName })),
    [customers]
  )

  return (
    <div className={styles.customerNameListItem}>
      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const selectedCustomers = (
            (getFieldValue('customers') as IClosedGroupCustomer[]) || ([] as IClosedGroupCustomer[])
          )?.map((cust) => cust?.id)

          const isGroupOwner = getFieldValue(['customers', name, 'isGroupOwner'])
          const label = isGroupOwner ? 'Group Owner' : 'Group Member'
          const currentCustomerId = getFieldValue(['customers', name, 'id'])

          return (
            <Form.Item
              name={[name, 'id']}
              className={classNames(styles.customerField, {
                [styles.customerFieldView]: view,
              })}
              label={label}
              rules={[{ required: true, message: 'Required' }]}
            >
              <Select
                showSearch
                disabled={view || isGroupOwner}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={getListResp.isLoading ? <Spin size='small' /> : null}
                placeholder='Enter customer name or phone number'
                className={styles.searchSelect}
                onSearch={handleSearch}
                allowClear
              >
                {(defaultCustomersList?.some((cust) => cust?.id === currentCustomerId)
                  ? defaultCustomersList
                  : getListResp?.data?.items
                )?.map((cust) => (
                  <Select.Option
                    key={cust?.id}
                    value={cust?.id}
                    disabled={selectedCustomers?.includes(cust?.id)}
                  >
                    {cust?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )
        }}
      </FormItem>

      <FormItem noStyle name={[name, 'isGroupOwner']}>
        <TextField className={styles.hidden} />
      </FormItem>

      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const isGroupOwner = getFieldValue(['customers', name, 'isGroupOwner'])
          return (
            <>
              {!view && (
                <FormItem className='noBottomMargin'>
                  <IconButton
                    slim
                    size='large'
                    color='orange'
                    icon={<BucketIcon />}
                    className={styles.deleteIcon}
                    type={isGroupOwner ? 'primary' : 'default'}
                    disabled={view || isGroupOwner}
                    onClick={() => {
                      remove(name)
                    }}
                  />
                </FormItem>
              )}
            </>
          )
        }}
      </FormItem>
    </div>
  )
}
