import FormItem from 'antd/es/form/FormItem'
import styles from '../../../styles.module.scss'
import { TextField } from '../../../../../../../../../shared/components/textfield/TextField'
import { requiredValidator } from '../../../../../../../../../helpers/validation'
import { Select as AntSelect, Spin } from 'antd'
import { Wrapper } from '../../../../../../../../salesNetwork/components/Wrapper'
import React from 'react'
import { IViewMode } from '../../../../../../../../Finance/models/IViewMode'
import { VENDOR_TYPE_ENUM } from '../../../../../../../models/IVendorManagement'
import { useGetVendorListQuery } from '../../../../../../../core/http/VendorManagementApi'

export const DetailInfo = ({ view }: IViewMode) => {
  const { data, isFetching } = useGetVendorListQuery({ vendorType: VENDOR_TYPE_ENUM.VENDOR })

  const content = (
    <Wrapper title='Detail Info'>
      <div className={styles.dblRow}>
        <FormItem name='createdAt' label='Creation Date'>
          <TextField disabled />
        </FormItem>

        <FormItem name='createdBy' label='Created By'>
          <TextField disabled />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem
          name='simNumber'
          label='Sim Card Number'
          rules={[
            {
              required: true,
              validator: requiredValidator('Sim Card Number'),
            },
          ]}
        >
          <TextField disabled={view} placeholder='Enter Sim Card Number' />
        </FormItem>

        <FormItem
          name='vendorType'
          label='Vendor Type'
          rules={[
            {
              required: true,
              validator: requiredValidator('Vendor Type'),
            },
          ]}
        >
          <AntSelect
            allowClear
            disabled={view}
            placeholder='Select Vendor Type'
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <AntSelect.Option value={VENDOR_TYPE_ENUM.SIM_CARD}>
              {VENDOR_TYPE_ENUM.SIM_CARD}
            </AntSelect.Option>
          </AntSelect>
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem
          name='ownerFirstName'
          label='Owner First Name'
          rules={[
            {
              required: true,
              validator: requiredValidator('Owner First Name'),
            },
          ]}
        >
          <TextField disabled={view} placeholder='Enter Owner First Name' />
        </FormItem>

        <FormItem
          name='ownerSecondName'
          label='Owner Last Name'
          rules={[
            {
              required: true,
              validator: requiredValidator('Owner Last Name'),
            },
          ]}
        >
          <TextField disabled={view} placeholder='Enter Owner Last Name' />
        </FormItem>
      </div>

      <div>
        <FormItem
          name={['operator', 'id']}
          label='Operator'
          rules={[
            {
              required: true,
              validator: requiredValidator('Operator'),
            },
          ]}
          className='noBottomMargin'
        >
          <AntSelect disabled={view} placeholder='Select Operator' allowClear>
            {data?.items.map((ven) => (
              <AntSelect.Option key={ven.id} value={ven.id}>
                {ven.name}
              </AntSelect.Option>
            ))}
          </AntSelect>
        </FormItem>
      </div>
    </Wrapper>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
