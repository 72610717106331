import styles from '../../styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../../../shared/components/textfield/TextField'
import { isInteger } from 'lodash'

interface IProps {
  name?: number
}

export const SubTransactionDetail = ({ name }: IProps) => {
  return (
    <div className={styles.subTransactionItem}>
      <div className={styles.dblRow}>
        <FormItem name={isInteger(name) ? [name as number, 'id'] : 'id'} label='Sub-Transaction ID'>
          <TextField disabled />
        </FormItem>

        <FormItem
          name={isInteger(name) ? [name as number, 'createdAt'] : 'createdAt'}
          label='Date & Time'
        >
          <TextField disabled />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem name={isInteger(name) ? [name as number, 'sender'] : 'sender'} label='Sender'>
          <TextField disabled />
        </FormItem>

        <FormItem
          name={isInteger(name) ? [name as number, 'receiver'] : 'receiver'}
          label='Receiver'
        >
          <TextField disabled />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem
          name={isInteger(name) ? [name as number, 'transactionCategory'] : 'transactionCategory'}
          label='Transaction Type'
          className='noBottomMargin'
        >
          <TextField disabled />
        </FormItem>

        <FormItem
          name={isInteger(name) ? [name as number, 'amount'] : 'amount'}
          label='Amount'
          className='noBottomMargin'
        >
          <TextField disabled />
        </FormItem>

        <FormItem
          name={isInteger(name) ? [name as number, 'status'] : 'status'}
          label='Status'
          className='noBottomMargin'
        >
          <TextField disabled />
        </FormItem>
      </div>
    </div>
  )
}
