import { Route } from 'use-react-router-breadcrumbs'
import { ProfileInfo } from '../../features/Profile/pages/ProfileInfo'
import { ProfileSettings } from '../../features/Profile/pages/ProfileSettings'
import { ProfileNotifications } from '../../features/Profile/pages/ProfileNotifications'
import ProfileActivityLog from '../../features/Profile/pages/ProfileActivityLog'

export enum PROFILE_ENUM {
  PROFILE_INFO = 'info/profile-info/:mode',
  PROFILE_SETTINGS = 'info/profile-settings',
  PROFILE_NOTIFICATIONS = 'info/notification',
  PROFILE_ACTIVITY_LOG = 'info/activity-log',
  PROFILE_DOCUMENTATION = 'info/documentation',
  PROFILE_DOCUMENTATION_NEW = 'info/documentation/new',
}

export default () => {
  return (
    <>
      <Route path={PROFILE_ENUM.PROFILE_INFO} element={<ProfileInfo />} breadcrumb='Profile Info' />
      <Route path={PROFILE_ENUM.PROFILE_SETTINGS} element={<ProfileSettings />} />
      <Route path={PROFILE_ENUM.PROFILE_NOTIFICATIONS} element={<ProfileNotifications />} />
      <Route path={PROFILE_ENUM.PROFILE_ACTIVITY_LOG} element={<ProfileActivityLog />} />
    </>
  )
}
