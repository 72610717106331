import styles from './styles.module.scss'
import { CustomersTabs } from './Components/Tabs/index'

export const Customers = () => {
  return (
    <div className={styles.layout}>
      <CustomersTabs />
    </div>
  )
}
