import { UserManagementTabs } from './Components/Tabs/index'
import styles from './styles.module.scss'

export const UserManagement = () => {
  return (
    <div className={styles.layout}>
      <UserManagementTabs />
    </div>
  )
}
