import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import {
  IClosedGroup,
  ICustomer,
  ICustomerBankAccountInfo,
  ICustomerWalletInfo,
  IUpdateCustomerBank,
  IUpdateCustomerWalletAccount,
} from '../../models/ICustomer'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../../shared/models/ITableConf'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

export const customersApi = createApi({
  reducerPath: 'customers',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'CustomersList',
    'ICustomer',
    'FilterFields',
    'ClosedGroupList',
    'IClosedGroup',
    'CustomerBanks',
    'CustomerWallets',
  ],
  endpoints: (builder) => ({
    getCustomersList: builder.query<ITableResponse<ICustomer>, ITableConf>({
      query: (params) => ({
        url: 'customers',
        params,
      }),
      providesTags: () => ['CustomersList'],
    }),

    getCustomerById: builder.query<ICustomer, string | number>({
      query: (id) => ({
        url: `customers/${id}`,
      }),
      providesTags: (result) => [{ type: 'ICustomer', id: result?.id }],
    }),

    createCustomer: builder.mutation<ICustomer, FormData>({
      query: (body) => ({
        url: `customers`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['FilterFields', 'CustomersList'],
    }),

    updateCustomerById: builder.mutation<ICustomer, { id: number; body: FormData }>({
      query: ({ id, body }) => ({
        url: `customers/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error) =>
        !error
          ? [
              'FilterFields',
              'CustomersList',
              'ClosedGroupList',
              'IClosedGroup',
              'ClosedGroupList',
              { type: 'ICustomer', id: result?.id },
            ]
          : [],
    }),

    getClosedGroupsList: builder.query<ITableResponse<IClosedGroup>, ITableConf>({
      query: (params) => ({
        url: `closed-customer-groups`,
        params,
      }),
      providesTags: ['ClosedGroupList'],
    }),

    getClosedGroupById: builder.query<IClosedGroup, string>({
      query: (id) => ({
        url: `closed-customer-groups/${id}`,
      }),
      providesTags: ['IClosedGroup'],
    }),

    getCustomerBankAccountsById: builder.query<Array<ICustomerBankAccountInfo>, string>({
      query: (id) => ({
        url: `customers/${id}/bank-account`,
      }),
      providesTags: ['CustomerBanks'],
    }),

    updateCustomerBankAccount: builder.mutation<
      Array<ICustomerBankAccountInfo>,
      { customerId: string; body: IUpdateCustomerBank }
    >({
      query: ({ customerId, body }) => ({
        url: `customers/${customerId}/bank-account`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CustomerBanks'],
    }),

    getCustomerWalletAccountById: builder.query<Array<ICustomerWalletInfo>, string>({
      query: (id) => ({
        url: `/customers/${id}/wallet-account`,
      }),
      providesTags: ['CustomerWallets'],
    }),

    updateCustomerWalletAccount: builder.mutation<
      void,
      { customerId: string; body: IUpdateCustomerWalletAccount }
    >({
      query: ({ customerId, body }) => ({
        url: `customers/${customerId}/wallet-account`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CustomerWallets'],
    }),

    deleteCustomerBankAccount: builder.mutation<void, { customerId: string; bankId: number }>({
      query: ({ customerId, bankId }) => ({
        url: `customers/${customerId}/bank-account/${bankId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CustomerBanks'],
    }),

    updateClosedGroupById: builder.mutation<
      IClosedGroup,
      { id: string; body: { customerIds: Array<number> } }
    >({
      query: ({ id, body }) => ({
        url: `closed-customer-groups/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ClosedGroupList', 'IClosedGroup', 'CustomersList'],
    }),

    deleteClosedGroupById: builder.mutation<void, string>({
      query: (id) => ({
        url: `closed-customer-groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['ClosedGroupList', 'CustomersList'],
    }),

    deleteCustomerById: builder.mutation<void, number>({
      query: (id) => ({
        url: `customers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'ICustomer', id: arg },
        'FilterFields',
        'CustomersList',
      ],
    }),

    exportCustomersTable: builder.query<
      BlobPart,
      {
        conf: IFileFormatCsvOrXlsx
        formatFile: 'csv' | 'xlsx'
      }
    >({
      query: ({ conf, formatFile }) => ({
        url: 'customers/export',
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getCustomersTableFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'customers/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `customers/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: () => ['FilterFields'],
    }),
  }),
})

export const {
  useGetCustomersListQuery,
  useGetCustomersTableFiltersListQuery,
  useGetCustomerByIdQuery,
  useDeleteCustomerByIdMutation,
  useLazyExportCustomersTableQuery,
  useUpdateCustomerByIdMutation,
  useCreateCustomerMutation,
  useGetClosedGroupByIdQuery,
  useLazyGetClosedGroupsListQuery,
  useUpdateClosedGroupByIdMutation,
  useDeleteClosedGroupByIdMutation,
  useGetClosedGroupsListQuery,
  useLazyGetCustomersListQuery,
  useGetCustomerBankAccountsByIdQuery,
  useUpdateCustomerBankAccountMutation,
  useGetCustomerWalletAccountByIdQuery,
  useUpdateCustomerWalletAccountMutation,
} = customersApi
