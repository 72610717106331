import { useContext, useEffect, useMemo } from 'react'
import { Form, Spin } from 'antd'

import { RightModalContext } from '../../../../../../Modals'

import styles from '../styles.module.scss'
import { SubTransactionDetail } from '../Components/SubTransactionDetail'
import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import { formatDateWithTime } from '../../../../../../../helpers/utils'
import { startCase } from 'lodash'
import { useGetSubTransactionByIdQuery } from '../../../../../core/Subtransactions'
import { IByUser } from '../../../../../../../shared/models/IByUser'
import { BankAccount, Wallet } from '../../../../../../Finance/models/ITransaction'
import TransactionStatus from '../../../../../../../shared/components/transactionStatus'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

export const SubTransactionDetailModal = () => {
  const [form] = Form.useForm()
  const {
    props: { id },
  } = useContext(RightModalContext)

  const { data, isFetching } = useGetSubTransactionByIdQuery(id, { skip: !id })
  const { data: profile } = useGetProfileQuery()

  const receiver = useMemo(() => {
    if (data) {
      const rec = data[data.receiver] as IByUser | Wallet | BankAccount
      return rec?.name
    }
    return ''
  }, [data])

  const sender = useMemo(() => {
    if (data) {
      const sen = data[data.sender] as IByUser | Wallet | BankAccount
      return sen?.name
    }
    return ''
  }, [data])

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        createdAt: formatDateWithTime(data?.createdAt, profile?.calendar),
        receiver: startCase(receiver),
        sender: startCase(sender),
      })
    }
    // eslint-disable-next-line
  }, [data, form, profile])

  const content = (
    <Form form={form} className={styles.formContainer} layout='vertical'>
      <Wrapper
        title='Sub Transaction Detail'
        statusButton={data?.status && <TransactionStatus status={data?.status} />}
      >
        <SubTransactionDetail />
      </Wrapper>
    </Form>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
