import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import TransactionStatus from '../../../../../../../shared/components/transactionStatus'
import styles from '../PurchaseOrderChangesModal/styles.module.scss'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { Form } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

export const OldItem = ({ name, isRefund }: { name: number; isRefund?: boolean }) => {
  const form = useFormInstance()
  return (
    <Wrapper
      title={`${isRefund ? 'Refund' : 'Pickup'} Request History ${name + 1}`}
      statusButton={
        <TransactionStatus status={form.getFieldValue(['historyChanges', name, 'status'])} />
      }
      className={styles.wrapper}
    >
      <div className={styles.dblRow}>
        <Form.Item name={[name, 'id']} label='ID'>
          <TextField disabled />
        </Form.Item>

        <Form.Item name={[name, 'createdAt']} label='Created At'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item name={[name, 'paymentType']} label='Payment Method'>
          <TextField disabled />
        </Form.Item>

        <Form.Item name={[name, 'currency', 'textCode']} label='Currency'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item name={[name, 'amount']} label='eMoneyAmount'>
          <TextField disabled />
        </Form.Item>

        <Form.Item name={[name, 'exchangeRate']} label='Exchange Rate'>
          <TextField disabled />
        </Form.Item>

        <Form.Item name={[name, 'currencyAmount']} label='Cash Amount'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item name={[name, 'discountRate']} label='Discount Rate'>
          <TextField disabled />
        </Form.Item>

        <Form.Item name={[name, 'discountAmount']} label='Discount Amount'>
          <TextField disabled />
        </Form.Item>
      </div>

      <Form.Item name={[name, 'user', 'name']} label='User' className='noBottomMargin'>
        <TextField disabled />
      </Form.Item>
    </Wrapper>
  )
}
