import { useCallback, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

import styles from './../styles.module.scss'
import { ISelectedFilters } from 'src/shared/components/filter/Filter'
import { ITableConf } from 'src/shared/models/ITableConf'
import {
  useGetUsersFiltersListQuery,
  useLazyExportUsersTableQuery,
} from 'src/features/Settings/core/http/UsersManagementApi'
import { useTableExport } from 'src/shared/hooks/table/useTableExport'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from 'src/shared/components/popup/Popup'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { ClockIcon, DateTableIcon, EmailWhiteIcon, TableActionIcon } from 'src/assets/svg'
import { formatDateWithTime, moment } from 'src/helpers/utils'
import { Toggle } from 'src/shared/components/toggle/Toggle'
import { useEmptyTable } from 'src/shared/hooks/table/useEmptyTable'
import { TableHeader } from '../Components/TableHeader'
import {
  useDeleteScheduleNotificationMutation,
  useGetScheduleNotificationQuery,
  useUpdateScheduleNotificationMutation,
} from 'src/features/salesNetwork/core/http/SceduleNotifications'
import { IScheduleNotification } from 'src/features/salesNetwork/models/SceduleNotification'
import { startCase } from 'lodash'
import classNames from 'classnames'
import { DaysOptions } from 'src/shared/components/sheduler/index.d'
import { SALES_ENUM } from 'src/routes/sales'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { RecursivelyReplaceCharacters } from '../../../../../shared/components/privateMode'

interface IProps {
  canEdit: boolean
  canDelete: boolean
}

export const ScheduleNotificationList = ({ canEdit, canDelete }: IProps) => {
  const navigate = useNavigate()

  const { queryParams, queryFields } = useGetQueryParams()

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const [exportTable] = useLazyExportUsersTableQuery()

  const { data, isFetching } = useGetScheduleNotificationQuery({
    ...tableConf,
    ...filterValues,
  })
  const { data: profile } = useGetProfileQuery()

  // CHECK!!!
  const [updateNotification, dUpdate] = useUpdateScheduleNotificationMutation()
  const [deleteNotification, dDelete] = useDeleteScheduleNotificationMutation()
  const { data: filtersList } = useGetUsersFiltersListQuery()

  useTableExport({
    tableConf,
    filterValues,
    promise: exportTable,
  })

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, dUpdate.isSuccess || dDelete.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, dUpdate.isError, dUpdate.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, dDelete.isError, dDelete.error as ErrorNode)

  const tableActionsPopup = useCallback(
    (id: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () => navigate(`${SALES_ENUM.SCEDULE_NOTIFICATIONS}/view/${id}`),
        },
        {
          text: 'Edit',
          shouldDisplay: canEdit,
          onClick: () => navigate(`${SALES_ENUM.SCEDULE_NOTIFICATIONS}/edit/${id}`),
        },
        {
          text: 'Delete',
          withDivider: true,
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          shouldDisplay: canDelete,
          onClick: () => {
            deleteNotification(id)
          },
        },
      ]
    },
    [deleteNotification, navigate, canDelete, canEdit]
  )

  const columns: ColumnsType<IScheduleNotification> = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      width: '20%',
      render: (value: IScheduleNotification['name'], record) => (
        <RecursivelyReplaceCharacters>
          <Link to={`view/${record.id}`} className={styles.itemText}>
            {value}
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: true,
      width: '20%',
      render: (value: IScheduleNotification['type']) => (
        <div className={classNames(styles.typeContainer, styles[value])}>
          <EmailWhiteIcon />
          <RecursivelyReplaceCharacters>{startCase(value)}</RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Next notification',
      dataIndex: 'nextNotification',
      sorter: true,
      width: '20%',
      render: (value: IScheduleNotification['nextNotification']) => {
        const [h, m] = value.split(' ').pop()?.split(':') || []

        const date = moment(value).set('hour', +h).set('minute', +m).toISOString()
        return (
          <div className='table-date'>
            <DateTableIcon />
            <RecursivelyReplaceCharacters>
              {formatDateWithTime(date, profile?.calendar)}
            </RecursivelyReplaceCharacters>
          </div>
        )
      },
    },
    {
      title: 'Scheduled For',
      dataIndex: 'sheduledFor',
      width: '20%',
      render: (_, record: IScheduleNotification) => {
        const week = DaysOptions.find(({ id }) => id === record.scheduler.weekDay)?.name
        const timeType = startCase(record.scheduler.monthlyType || week)

        const [h, m, s] = record.scheduler.time.split(' ').pop()?.split(':') || []
        const time = moment(
          moment().set('hour', +h).set('minute', +m).set('second', +s).toISOString()
        ).format('HH:mm:ss')

        return (
          <RecursivelyReplaceCharacters>
            <div className={styles.scheduledContainer}>
              <div>
                <DateTableIcon />
                {`${startCase(record.scheduler.frequency)} Schedule:`}
              </div>
              <div>
                <ClockIcon />
                {`${timeType}${
                  record.scheduler.frequency !== 'daily' && record.scheduler.frequency ? ',' : ''
                } ${time}`}
              </div>
            </div>
          </RecursivelyReplaceCharacters>
        )
      },
    },
    {
      title: 'Added By',
      dataIndex: 'createdBy',
      sorter: true,
      width: '20%',
      render: (value: IScheduleNotification['createdBy']) => (
        <RecursivelyReplaceCharacters>{value.name}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      render: (status: IScheduleNotification['status'], { id }) => {
        return (
          <Toggle
            defaultChecked={status}
            disabled={!canEdit}
            onChange={(status) => {
              const req = { status }
              updateNotification({ id: String(id), body: req })
            }}
            loading={dUpdate.isLoading}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const emptyTableConf = useEmptyTable(() => navigate('new'))

  const { pagination, handleTableChange, handleFiltersChange } = useTable<IScheduleNotification>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  return (
    <>
      <TableHeader
        filterValues={filterValues}
        setActiveFilters={handleFiltersChange}
        data={filtersList}
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        pagination={pagination}
        className={styles.table}
        onChange={handleTableChange}
        loading={isFetching}
        rowKey={(row) => row?.id}
        dataSource={data?.items}
        rowSelection={{ type: 'checkbox' }}
        scroll={{ x: 1100 }}
      />
    </>
  )
}
