import FormItem from 'antd/es/form/FormItem'
import { positiveNumber, requiredValidator } from '../../../../../../../../helpers/validation'
import { TextField } from '../../../../../../../../shared/components/textfield/TextField'
import {
  PURCHASE_PLAN_TIME_OPTIONS,
  PURCHASE_PLAN_TYPE_ENUM,
} from '../../../../../../models/IInventory'
import classNames from 'classnames'
import styles from '../../../styles.module.scss'
import { Select } from 'antd'
import { Wrapper } from '../../../../../../../../shared/components/wrapper/Wrapper'
import { DATE_RANGE_LABEL_NAME } from '../index.d'
import { DateRangeFormList } from './DateRangeFormList'
import { useCallback } from 'react'
import { debounce } from 'lodash'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

interface IProps {
  onTimeOptionChange: (e: string) => void
}

export const PurchaseTargetBlock = ({ onTimeOptionChange }: IProps) => {
  const form = useFormInstance()

  const handleCashAmountChange = useCallback(
    debounce(() => {
      form.validateFields(['distributionRanges'])
    }, 300),
    []
  )

  return (
    <Wrapper title='Purchase Target'>
      <FormItem
        name='cashAmount'
        label='Cash'
        rules={[
          { required: true, validator: requiredValidator('Cash') },
          { validator: positiveNumber('Cash') },
        ]}
      >
        <TextField type='number' placeholder='Enter Amount' onChange={handleCashAmountChange} />
      </FormItem>

      <FormItem
        name='eMoneyAmount'
        label='EMoney'
        rules={[
          { required: true, validator: requiredValidator('EMoney') },
          { validator: positiveNumber('EMoney') },
        ]}
      >
        <TextField type='number' placeholder='Enter Amount' />
      </FormItem>

      <FormItem noStyle shouldUpdate>
        {({ setFieldValue, getFieldValue }) => {
          const shouldDisplayTime =
            getFieldValue('type') && getFieldValue('type') !== PURCHASE_PLAN_TYPE_ENUM.EACH_DAY
          const shouldDisableType =
            !getFieldValue('monthRange') ||
            !getFieldValue('cashAmount') ||
            !getFieldValue('eMoneyAmount')

          return (
            <div className={classNames({ [styles.dblRow]: shouldDisplayTime })}>
              <FormItem
                name='type'
                label='Distribute'
                className='noBottomMargin'
                rules={[{ required: true, validator: requiredValidator('Distribute') }]}
              >
                <Select
                  placeholder='Select'
                  disabled={shouldDisableType}
                  onChange={() => setFieldValue('timeOption', null)}
                  allowClear
                >
                  <Select.Option value={PURCHASE_PLAN_TYPE_ENUM.EACH_DAY}>
                    Equally to each day of the selected period
                  </Select.Option>
                  <Select.Option value={PURCHASE_PLAN_TYPE_ENUM.MANUALLY}>Manually</Select.Option>
                </Select>
              </FormItem>

              {shouldDisplayTime && (
                <FormItem label='Range Type' className='noBottomMargin' name='timeOption'>
                  <Select placeholder='Select' onChange={onTimeOptionChange} allowClear>
                    <Select.Option value={PURCHASE_PLAN_TIME_OPTIONS.MANUALLY_PER_DAY}>
                      {DATE_RANGE_LABEL_NAME.DAY}
                    </Select.Option>
                    <Select.Option value={PURCHASE_PLAN_TIME_OPTIONS.MANUALLY_PER_WEEK}>
                      {DATE_RANGE_LABEL_NAME.WEEK}
                    </Select.Option>
                    <Select.Option value={PURCHASE_PLAN_TIME_OPTIONS.MANUALLY_CUSTOM}>
                      {DATE_RANGE_LABEL_NAME.CUSTOM}
                    </Select.Option>
                  </Select>
                </FormItem>
              )}
            </div>
          )
        }}
      </FormItem>
      <FormItem shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const isTimeOptionSelected = getFieldValue('timeOption')
          return isTimeOptionSelected && <DateRangeFormList />
        }}
      </FormItem>
    </Wrapper>
  )
}
