import React, { useCallback, useState } from 'react'
import { Switch, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router'
import { IPosition } from '../../../../../models/IPosition'
import { ISelectedFilters } from '../../../../../../../shared/components/filter/Filter'
import { ITableConf } from '../../../../../../../shared/models/ITableConf'
import {
  useDeletePositionMutation,
  useGetPositionListQuery,
  usePatchUserPositionMutation,
} from '../../../../../core/http/PositionsApi'
import { useEmptyTable } from '../../../../../../../shared/hooks/table/useEmptyTable'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../../shared/components/popup/Popup'
import { TableActionIcon } from '../../../../../../../assets/svg'
import emptyAvatarImage from '../../../../../../../assets/img/Avatar.png'
import { formatNumberToLocale } from '../../../../../../salesNetwork/helpers'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { ErrorNode, showConfirmMessage } from '../../../../../../../shared/api/errorHandler'
import { SETTINGS_ENUM } from '../../../../../../../routes/settings'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import styles from './style.module.scss'
import { isEmpty } from 'lodash'
import { RecursivelyReplaceCharacters } from '../../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../../shared/hooks/useLocalStorageGetByKey'

export const Positions = () => {
  const params = useParams<{ id?: string }>()
  const navigate = useNavigate()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const { queryParams, queryFields } = useGetQueryParams()

  const [filterValues] = useState<ISelectedFilters>({
    ...queryFields,
    ...(params?.id ? { departmentId: params.id } : {}),
  })
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const [delPos, delPosResp] = useDeletePositionMutation()
  const [patchPos, patchPosResp] = usePatchUserPositionMutation()
  const { data, isFetching } = useGetPositionListQuery(
    { ...filterValues, ...tableConf },
    { skip: isEmpty(tableConf) }
  )

  useNotification(NOTIFICATION_TYPES.success, patchPosResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, delPosResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, patchPosResp.isError, patchPosResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, delPosResp.isError, delPosResp.error as ErrorNode)

  const tableActionsPopup = useCallback(
    (posId: number): IPopupListItems[] => {
      const posLink = params?.id ? `/departments/view/${params.id}` : ''
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () =>
            navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT}${posLink}/positions/view/${posId}`),
        },
        {
          text: 'Edit',
          shouldDisplay: true,
          onClick: () =>
            navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT}${posLink}/positions/edit/${posId}`),
        },
        {
          text: 'Delete',
          withDivider: true,
          shouldDisplay: true,
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          onClick: () => showConfirmMessage('This position will be deleted', () => delPos(posId)),
        },
      ]
    },
    [delPos, navigate, params.id]
  )

  const columns: ColumnsType<IPosition> = [
    {
      title: 'Position ID',
      dataIndex: 'id',
      sorter: true,
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Position Name',
      dataIndex: 'name',
      sorter: true,
      render: (_, row) => {
        const department = params?.id ? `/departments/view/${params.id}` : ''
        return (
          <RecursivelyReplaceCharacters>
            <Link
              to={`${SETTINGS_ENUM.USERS_MANAGEMENT}${department}/positions/view/${row.id}`}
              className='table-avatar'
            >
              {row.name}
            </Link>
          </RecursivelyReplaceCharacters>
        )
      },
    },
    {
      title: 'Hourly Rate',
      dataIndex: 'hourlyRate',
      sorter: true,
      render: (rate: IPosition['hourlyRate']) => (
        <RecursivelyReplaceCharacters>{formatNumberToLocale(rate)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Overtime Rate',
      dataIndex: 'overtimeRate',
      sorter: true,
      render: (rate: IPosition['overtimeRate']) => (
        <RecursivelyReplaceCharacters>{formatNumberToLocale(rate)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Holiday Rate',
      dataIndex: 'holidayRate',
      sorter: true,
      render: (rate: IPosition['holidayRate']) => (
        <RecursivelyReplaceCharacters>{formatNumberToLocale(rate)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Supervisor',
      dataIndex: 'supervisor',
      sorter: true,
      render: (supervisor: IPosition['supervisor']) => (
        <RecursivelyReplaceCharacters>
          <div className='table-avatar'>
            <img
              className={styles.avatar}
              alt='user logo'
              src={!isPrivateMode && supervisor?.logo ? supervisor?.logo : emptyAvatarImage}
              loading='lazy'
            />
            <div>{supervisor?.name}</div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      render: (status: IPosition['status'], row) => {
        return (
          <Switch
            defaultChecked={status}
            onChange={(status) => {
              patchPos({ id: row.id, body: { status } })
            }}
            loading={patchPosResp.isLoading}
          />
        )
      },
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => {
        return (
          <Popup data={tableActionsPopup(row.id)}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        )
      },
    },
  ]

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange } = useTable<IPosition>({
    total: data?.totalCount,
    setTableConf,
  })
  return (
    <Table
      locale={emptyTableConf}
      onChange={handleTableChange}
      pagination={pagination}
      rowKey={(record) => record.id}
      scroll={{ x: 1000 }}
      rowSelection={{ type: 'checkbox' }}
      className={styles.table}
      columns={columns}
      dataSource={data?.items}
      loading={isFetching}
    />
  )
}
