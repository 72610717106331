import { ReactNode } from 'react'
import styles from '../../styles.module.scss'

interface IProps {
  title: string
  statusButton: ReactNode
  rightActions: ReactNode
  children: ReactNode
}

export const Wrapper = ({ title, rightActions, statusButton, children }: IProps) => {
  return (
    <div className={styles.discountPolicyBlock}>
      <div className={styles.discountPolicyBlockHeader}>
        <div className={styles.discountPolicyTitle}>
          <div>{title}</div>
          <div>{statusButton}</div>
        </div>
        <div>{rightActions}</div>
      </div>
      <div>{children}</div>
    </div>
  )
}
