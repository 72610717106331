import { Form, Row, Space, Spin } from 'antd'
import styles from '../style.module.scss'
import { useNavigate, useParams } from 'react-router'
import {
  useDeletePositionMutation,
  useGetPositionByIdQuery,
  usePatchUserPositionMutation,
} from '../../../../../../../core/http/PositionsApi'
import { formatDateWithTime } from '../../../../../../../../../helpers/utils'
import { DetailInfo } from '../DetailInfo'
import { useGetListOfCurrenciesQuery } from '../../../../../../../../Finance/core/http/BankAccountApi'
import React, { useCallback, useEffect, useMemo } from 'react'
import { ISelectOption } from '../../../../../../../../../shared/models/ISelectOption'
import { Rates } from '../Rates'
import { Button } from '../../../../../../../../../shared/components/button/Button'
import { IPosition, IPositionCreate } from '../../../../../../../models/IPosition'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../../shared/hooks/useNotification'
import { ErrorNode, showConfirmMessage } from '../../../../../../../../../shared/api/errorHandler'
import { SETTINGS_ENUM } from '../../../../../../../../../routes/settings'
import { useGetProfileQuery } from '../../../../../../../../Profile/core/http/Profile'

export const InformationTab = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { posId, depId, mode } = useParams<{
    depId?: string
    posId: string
    mode: 'view' | 'edit'
  }>()

  const { data: profile } = useGetProfileQuery()
  const { data, isFetching } = useGetPositionByIdQuery(posId as string, { skip: !posId })
  const { data: currencies, isFetching: isCurrencyFetching } = useGetListOfCurrenciesQuery()
  const [patchPos, patchPosResp] = usePatchUserPositionMutation()
  const [delPos, delPosResp] = useDeletePositionMutation()

  useNotification(NOTIFICATION_TYPES.success, patchPosResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, delPosResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, delPosResp.isError, delPosResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, patchPosResp.isError, patchPosResp.error as ErrorNode)

  const currencyList = useMemo(
    () =>
      currencies?.items.map((currency) => ({
        label: currency.name,
        value: currency.id,
      })),
    [currencies?.items]
  )

  const isViewMode = mode === 'view'
  const isDepartmentDetail = !!depId

  const onCancelClick = () => {
    if (isViewMode) {
      return navigate(
        isDepartmentDetail
          ? `${SETTINGS_ENUM.USERS_MANAGEMENT}/departments/view/${depId}`
          : SETTINGS_ENUM.USERS_MANAGEMENT
      )
    }

    form.resetFields()
    setInitialValues()
    return navigate(
      isDepartmentDetail
        ? `${SETTINGS_ENUM.USERS_MANAGEMENT}/departments/view/${depId}/positions/view/${posId}`
        : `${SETTINGS_ENUM.USERS_MANAGEMENT}/positions/view/${posId}`
    )
  }

  const onSubmitButtonClick = () => {
    if (isViewMode) {
      return navigate(
        isDepartmentDetail
          ? `${SETTINGS_ENUM.USERS_MANAGEMENT}/departments/view/${depId}/positions/edit/${posId}`
          : `${SETTINGS_ENUM.USERS_MANAGEMENT}/positions/edit/${posId}`
      )
    }
    return form.submit()
  }

  const openNotification = useCallback(() => {
    showConfirmMessage('This position will be deleted', () => delPos(posId as string))
  }, [delPos, posId])

  const setInitialValues = useCallback(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        createdAt: formatDateWithTime(data?.createdAt, profile?.calendar),
      })
    }
  }, [data, form, profile])

  const onFinish = (values: IPosition) => {
    const data = {
      name: values?.name,
      rateCurrencyId: values?.rateCurrency.id,
      supervisorId: values?.supervisor?.id,
      holidayRate: +values?.holidayRate,
      overtimeRate: +values?.overtimeRate,
      hourlyRate: +values?.hourlyRate,
    } as IPositionCreate

    patchPos({ id: posId as string, body: data })
  }

  useEffect(() => {
    setInitialValues()
  }, [setInitialValues])

  useEffect(() => {
    if (patchPosResp.isSuccess) {
      return navigate(
        isDepartmentDetail
          ? `${SETTINGS_ENUM.USERS_MANAGEMENT}/departments/view/${depId}/positions/view/${posId}`
          : `${SETTINGS_ENUM.USERS_MANAGEMENT}/positions/view/${posId}`
      )
    }
    // eslint-disable-next-line
  }, [posId, patchPosResp.isSuccess])

  useEffect(() => {
    if (delPosResp.isSuccess) {
      return navigate(
        isDepartmentDetail
          ? `${SETTINGS_ENUM.USERS_MANAGEMENT}/departments/view/${depId}`
          : SETTINGS_ENUM.USERS_MANAGEMENT
      )
    }
    // eslint-disable-next-line
  }, [delPosResp.isSuccess])

  const isLoading =
    patchPosResp.isLoading || delPosResp.isLoading || isFetching || isCurrencyFetching

  const content = (
    <Form form={form} layout='vertical' className={styles.form} onFinish={onFinish}>
      <DetailInfo view={isViewMode} />
      <Rates view={isViewMode} currencyList={currencyList as Array<ISelectOption>} />
    </Form>
  )

  return (
    <div className='fullVH'>
      {isLoading ? <Spin>{content}</Spin> : content}
      <div className={styles.footerWrapper}>
        <Row className={styles.footer} justify='space-between'>
          <Button color='error' size='middle' onClick={openNotification}>
            Delete
          </Button>
          <Space size={10}>
            <Button color='blue' size='middle' onClick={onCancelClick}>
              Cancel
            </Button>
            <Button htmlType='submit' size='middle' type='primary' onClick={onSubmitButtonClick}>
              {isViewMode ? 'Edit' : 'Save Changes'}
            </Button>
          </Space>
        </Row>
      </div>
    </div>
  )
}
