import React, { memo, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { notification } from 'antd'
import { NotificationBellIcon } from '../../../assets/svg'
import { useLazyGetUnreadNotificationsCounterQuery } from '../../../features/Profile/core/http/Notification'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { notificationSocketConnection } from '../../sockets'
import { IProfileNotification } from '../../../features/Profile/core/models'
import {
  addNewNotification,
  addNotificationsUnreadCounter,
  selectNotificationsState,
} from '../../../features/Profile/core/store/Notifications'
import { PROFILE_ENUM } from '../../../routes/profile'

import styles from '../header/Header.module.scss'
import { Badge } from '../badge/Badge'

const NotificationsCounter = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [getUnreadCounter, { data, isSuccess }] = useLazyGetUnreadNotificationsCounterQuery()

  const { unreadCounter } = useAppSelector(selectNotificationsState)

  const [, contextHolder] = notification.useNotification()

  const moveToNotificationsPage = () => {
    navigate(PROFILE_ENUM.PROFILE_NOTIFICATIONS)
  }

  useEffect(() => {
    getUnreadCounter()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isSuccess) {
      dispatch(addNotificationsUnreadCounter(data as number))
    }
    // eslint-disable-next-line
  }, [data, isSuccess])

  /** Sockets effects */
  /** Allows to notify the user about new notification */
  useEffect(() => {
    const socket = notificationSocketConnection.getSocket()
    const onNotification = (data: { internalNotification: IProfileNotification; type: string }) => {
      dispatch(addNewNotification(data.internalNotification))
      getUnreadCounter()
      // openNotification(data.internalNotification.message)
    }

    socket?.on('internal-notification', onNotification)
    return () => {
      // @ts-ignore
      socket?.removeListener('internal-notification', onNotification)
    }
  })

  return (
    <div className={styles['wrapper-notification-icon']} onClick={moveToNotificationsPage}>
      {contextHolder}
      <NotificationBellIcon />
      {unreadCounter ? (
        <div className={styles.counter}>
          <Badge color='orange' count={unreadCounter} />
        </div>
      ) : null}
    </div>
  )
}

export default memo(NotificationsCounter)
