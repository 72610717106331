import { Form } from 'antd'
import TextArea from 'antd/es/input/TextArea'

import {
  maxLengthValidator25,
  maxLengthValidator256,
  requiredValidator,
} from 'src/helpers/validation'
import { TextField } from 'src/shared/components/textfield/TextField'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'

// styles
import styles from '../../style.module.scss'
import classNames from 'classnames'

interface ITicketDescription {
  viewMode: boolean
}

export const TicketDescription = ({ viewMode }: ITicketDescription) => {
  return (
    <Wrapper
      title='ticket description'
      className={classNames(styles.ticketDescriptionContainer, styles.mb24)}
    >
      <Form.Item
        name='subject'
        label='Subject'
        rules={[
          { required: true, validator: requiredValidator('Subject') },
          {
            validator: maxLengthValidator25,
          },
        ]}
      >
        <TextField disabled={viewMode} placeholder='Enter Subject' />
      </Form.Item>

      <Form.Item
        className={styles.textAreaContainer}
        name='description'
        label='Description'
        rules={[
          { required: true, validator: requiredValidator('Description') },
          {
            validator: maxLengthValidator256,
          },
        ]}
      >
        <TextArea autoSize={{ minRows: 3 }} disabled={viewMode} placeholder='Enter Description' />
      </Form.Item>
    </Wrapper>
  )
}
