import { createApi } from '@reduxjs/toolkit/query/react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

import { baseQueryWithReauth } from 'src/shared/api'
import { IFilterFields } from 'src/shared/models/IFilterFields'
import { IPurchaseOrder, IPurchaseOrderBody, IPurchaseOrderLogs } from '../../models/IPurchaseOrder'
import { IFileFormatCsvOrXlsx, ITableConf } from 'src/shared/models/ITableConf'
import { ITableResponse } from 'src/shared/models/ITableResponse'
import { IDepartment } from '../../models/IDepartment'
import { ITrustWalletTransaction, ITrustWalletTransactionSub } from '../../models/ITransaction'
import { ISendEmoneyTransaction } from '../../models/ISendEmoneyTransaction'

export const purchaseOrderApi = createApi({
  reducerPath: 'purchaseOrder',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['POList', 'PO', 'ITrustWalletTransaction', 'IFilterFields', 'POLogs'],
  endpoints: (builder) => ({
    createPurchaseOrder: builder.mutation<IPurchaseOrder, IPurchaseOrderBody>({
      query: (body) => ({
        url: 'purchase-order',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['POList', 'ITrustWalletTransaction', 'IFilterFields'],
    }),
    updatePurchaseOrder: builder.mutation<IPurchaseOrder, { id: string; body: IPurchaseOrderBody }>(
      {
        query: ({ id, body }) => ({
          url: `purchase-order/${id}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: () => ['POList', 'PO', 'IFilterFields'],
      }
    ),
    getDepartmentList: builder.query<ITableResponse<IDepartment>, void>({
      query: () => ({
        url: `departments`,
      }),
    }),
    getPurchaseOrderList: builder.query<ITableResponse<IPurchaseOrder>, ITableConf>({
      query: (params) => ({
        url: 'purchase-order',
        params,
      }),
      transformResponse: (res: ITableResponse<IPurchaseOrder>) => ({
        ...res,
        items: res.items.map((purchase) => ({ ...purchase, children: [] })),
      }),
      providesTags: () => ['POList'],
    }),
    getPurchaseOrder: builder.query<IPurchaseOrder, string | undefined>({
      query: (id) => ({
        url: `purchase-order/${id}`,
      }),
      providesTags: () => ['PO'],
    }),
    getPurchaseOrderLogs: builder.query<Array<IPurchaseOrderLogs>, string>({
      query: (id) => ({
        url: `purchase-order/${id}/logs`,
      }),
      providesTags: () => ['POLogs'],
    }),

    addAttachmentsPO: builder.mutation<null, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: `purchase-order/${id}/attachments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['PO'],
    }),
    rejectPurchaseOrder: builder.mutation<null, string>({
      query: (id) => ({
        url: `purchase-order/${id}/reject`,
        method: 'PUT',
      }),
      invalidatesTags: () => ['POList', 'PO', 'IFilterFields', 'ITrustWalletTransaction'],
    }),
    approvePurchaseOrder: builder.mutation<null, string>({
      query: (id) => ({
        url: `purchase-order/${id}/approve`,
        method: 'PUT',
      }),
      invalidatesTags: () => ['POList', 'PO', 'IFilterFields', 'ITrustWalletTransaction'],
    }),
    checkPurchaseOrder: builder.mutation<null, string>({
      query: (id) => ({
        url: `purchase-order/${id}/check`,
        method: 'PUT',
      }),
      invalidatesTags: () => ['POList', 'PO', 'IFilterFields', 'ITrustWalletTransaction'],
    }),
    exportPurchaseOrder: builder.query<
      BlobPart,
      {
        conf: IFileFormatCsvOrXlsx
        formatFile: 'csv' | 'xlsx'
      }
    >({
      query: ({ conf, formatFile }) => ({
        url: 'purchase-order/export',
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    exportTrustWallet: builder.query<
      BlobPart,
      {
        conf: IFileFormatCsvOrXlsx
        formatFile: 'csv' | 'xlsx'
      }
    >({
      query: ({ conf, formatFile }) => ({
        url: 'trust-wallet-transaction/export',
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    // TODO: need to check in future
    getPOTableFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'purchase-order/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `purchase-order/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: ['IFilterFields'],
    }),

    getTrustWalletTransactions: builder.query<ITableResponse<ITrustWalletTransaction>, ITableConf>({
      query: (params) => ({
        url: `trust-wallet-transaction`,
        params,
      }),
      transformResponse: (res: ITableResponse<ITrustWalletTransaction>) => ({
        ...res,
        items: res.items.map((trans) => ({ ...trans, children: [] })),
      }),
      providesTags: () => ['ITrustWalletTransaction'],
    }),

    getTrustWalletTransactionsSub: builder.query<
      ITableResponse<ITrustWalletTransactionSub>,
      number
    >({
      query: (id) => ({
        url: `/wallet-transfer-fund/${id}/sub-transaction`,
      }),
    }),

    getPOSubTransactions: builder.query<
      ITableResponse<ITrustWalletTransactionSub>,
      number | string
    >({
      query: (id) => ({
        url: `/purchase-order/${id}/sub-transaction`,
      }),
    }),

    getSendEmoneyTransactionById: builder.query<ISendEmoneyTransaction, string>({
      query: (id) => ({
        url: `send-emoney/${id}`,
      }),
    }),

    getTrustWalletTransactionsFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'trust-wallet-transaction/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `trust-wallet-transaction/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: () => ['IFilterFields'],
    }),
  }),
})

export const {
  useCreatePurchaseOrderMutation,
  useGetPOTableFiltersListQuery,
  useGetPurchaseOrderListQuery,
  useGetPurchaseOrderQuery,
  useRejectPurchaseOrderMutation,
  useApprovePurchaseOrderMutation,
  useCheckPurchaseOrderMutation,
  useUpdatePurchaseOrderMutation,
  useLazyExportPurchaseOrderQuery,
  useGetDepartmentListQuery,
  useGetTrustWalletTransactionsQuery,
  useGetTrustWalletTransactionsFiltersListQuery,
  useAddAttachmentsPOMutation,
  useGetPurchaseOrderLogsQuery,
  useGetSendEmoneyTransactionByIdQuery,
  useLazyExportTrustWalletQuery,
} = purchaseOrderApi
