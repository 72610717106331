import { CUSTOMERS_TABS_ENUM } from './index.d'
import { Tabs } from 'antd/es'
import { CustomersTable } from '../CustomersTable'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, CloudDownloadIcon } from '../../../../../../assets/svg'
import { useContext, useEffect } from 'react'
import { CenterModalContext, RightModalContext } from '../../../../../Modals'
import { CENTER_MODALS, RIGHT_MODALS } from '../../../../../../helpers/contants'
import { Emitter, EmitterEvents } from '../../../../../../helpers/eventEmitter'
import { TableExportFormatEnum } from '../../../../../../shared/hooks/table/useTableExport'
import { Button } from '../../../../../../shared/components/button/Button'
import { Space } from 'antd'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useGetDefaultTab } from '../../../../helpers/useGetDefaultTab'
import { SegmentsTable } from '../SegmentsTable'
import { CCGTable } from '../CCGTable'
import styles from './styles.module.scss'

const Operations = ({ activeTab }: { activeTab: string }) => {
  const { onOpen, onClose } = useContext(CenterModalContext)
  const { onOpen: onRightModalOpen } = useContext(RightModalContext)

  const handlerExportClick = () => {
    onOpen(CENTER_MODALS.EXPORT_TABLE, {
      title: 'Export Table',
      onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
      onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      footer: <Button onClick={onClose}>Cancel</Button>,
    })
  }

  const onAddButtonClick = () => {
    if (activeTab === CUSTOMERS_TABS_ENUM.CUSTOMERS) {
      return onRightModalOpen(RIGHT_MODALS.SALES.CUSTOMER, { title: 'New Customer' })
    }
    if (activeTab === CUSTOMERS_TABS_ENUM.SEGMENTS) {
      return onRightModalOpen(RIGHT_MODALS.SALES.SEGMENT_TARGET_GROUP, {
        title: 'New Target Group',
      })
    }
  }

  return (
    <Space size={10}>
      {activeTab !== CUSTOMERS_TABS_ENUM.CCG && (
        <>
          <IconButton
            icon={<CloudDownloadIcon />}
            type='primary'
            color='blue'
            onClick={handlerExportClick}
          />
          <IconButton color='orange' icon={<AddIcon />} type='primary' onClick={onAddButtonClick} />
        </>
      )}
    </Space>
  )
}

export const CustomersTabs = () => {
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: CUSTOMERS_TABS_ENUM.CUSTOMERS,
  })

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  const items = [
    {
      label: CUSTOMERS_TABS_ENUM.CUSTOMERS,
      key: CUSTOMERS_TABS_ENUM.CUSTOMERS,
      children: <CustomersTable />,
    },
    {
      label: CUSTOMERS_TABS_ENUM.CCG,
      key: CUSTOMERS_TABS_ENUM.CCG,
      children: <CCGTable />,
    },
    {
      label: CUSTOMERS_TABS_ENUM.SEGMENTS,
      key: CUSTOMERS_TABS_ENUM.SEGMENTS,
      children: <SegmentsTable />,
    },
  ]

  return (
    <Tabs
      className={styles.tabs}
      destroyInactiveTabPane
      items={items}
      activeKey={activeTab}
      onChange={onTabChange}
      tabBarExtraContent={<Operations activeTab={activeTab} />}
    />
  )
}
