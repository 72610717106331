import React, { memo, useEffect, useState } from 'react'
import styles from './Sidebar.module.scss'
import classNames from 'classnames'
import * as SVG from 'src/assets/svg'
import { arrIconMenu } from './data'
import avatar from 'src/assets/img/Avatar.png'

export interface ISidebarIcons {
  setOpenKeys: React.Dispatch<React.SetStateAction<string>>
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  openKeys: string | boolean
  userAvatar?: string | null | undefined
}

export const SidebarIcons = memo(
  ({ setOpenKeys, setCollapsed, openKeys, userAvatar }: ISidebarIcons) => {
    const [activeIcon, setActiveIcon] = useState<string>(openKeys as string)
    const onSetCurrentPage = (page: string) => () => {
      activeIcon === page && setCollapsed((pre) => !pre)
      setActiveIcon(page)
      setOpenKeys(page)
    }

    useEffect(() => {
      setActiveIcon(openKeys as string)
    }, [openKeys])

    return (
      <div className={styles['sidebar-icon']}>
        <div className={styles['wrapper-icons']}>
          <div className={styles.icons}>
            {arrIconMenu.map(({ icon, page }, idx) => {
              const isAminPayLogo: boolean = idx === 0
              return (
                <div
                  key={page}
                  className={classNames(styles.icon, {
                    [styles.active]: activeIcon === page,
                    [styles.header]: isAminPayLogo,
                  })}
                  onClick={!isAminPayLogo ? onSetCurrentPage(page) : () => {}}
                >
                  {icon}
                </div>
              )
            })}
          </div>

          <div className={classNames(styles['wrapper-icon-and-footer'])}>
            <div
              className={classNames(styles.icon, { [styles.active]: activeIcon === 'info' })}
              onClick={onSetCurrentPage('info')}
            >
              <SVG.MenuInfoIcon />
            </div>

            <div className={styles.footer}>
              <img
                src={userAvatar || avatar}
                style={{ borderRadius: '50%' }}
                height={32}
                width={32}
                alt='user-profile-photo'
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
)
