import React, { memo } from 'react'
import { PieChart } from '../../../../../../../shared/components/pieChart/pieChart'
import { DatePickerIcon } from '../../../../../../../assets/svg'
import styles from '../../../styles.module.scss'
import { RangePicker } from 'src/shared/components/datePicker'
import { DashboardTableWrapper } from '../../../../../../Dashboard/pages/Overview/Components/DashboardTableWrapper'
import { RecursivelyReplaceCharacters } from '../../../../../../../shared/components/privateMode'

const agentTypesChartData = [
  { name: 'Intiated', value: 19, money: 19, id: 1 },
  { name: 'In Process', value: 25, money: 25, id: 2 },
  { name: 'Completed', value: 12, money: 12, id: 3 },
  { name: 'No Need', value: 4, money: 4, id: 4 },
]

interface IProps {
  colors: Array<string>
}

const HawalaChart = ({ colors }: IProps) => {
  const onSelectDate = () => {}

  return (
    <DashboardTableWrapper
      title='Hawala'
      filters={
        <RangePicker
          className={styles.delegationDatePicker}
          placeholder={['Date range', '']}
          onChange={onSelectDate}
          suffixIcon={
            <div>
              <DatePickerIcon color='#0193EB' />
            </div>
          }
        />
      }
    >
      <div className={styles.pieChartTypeDataContainer}>
        <div>
          {agentTypesChartData.map((cData, i) => (
            <RecursivelyReplaceCharacters key={cData.name}>
              <div className={styles.chartDataTitle}>
                <div className={styles.chartDataDot} style={{ backgroundColor: colors[i] }} />
                <div className={styles.chartDataName}>{cData.name}</div>
              </div>
            </RecursivelyReplaceCharacters>
          ))}
        </div>
        <div>
          <PieChart
            data={agentTypesChartData}
            colors={colors}
            textCenterChart={agentTypesChartData.reduce((a, v) => a + v.value, 0)}
          />
        </div>
      </div>
    </DashboardTableWrapper>
  )
}

export default memo(HawalaChart)
