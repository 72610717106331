import styles from './styles.module.scss'
import { SystemConfigurationTabs } from './Components/Tabs/index'

export const SystemConfiguration = () => {
  return (
    <div className={styles.layout}>
      <SystemConfigurationTabs />
    </div>
  )
}
