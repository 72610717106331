import { Filter, ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { IFilterFields } from '../../../../../../shared/models/IFilterFields'
import { useFilter } from '../../../../../../shared/hooks/filter/useFilter'
import { Row, Space } from 'antd'
import { FilterPopup } from '../../../../../../shared/components/filter/FilterPopup'
import React, { useEffect, useState } from 'react'
import { BalanceIcon, MonetizationWidgetIcon } from '../../../../../../assets/svg'
import { Widget } from '../../../../../../shared/components/widget'
import { WIDGET_LIST_ENUM } from '../../../../../../shared/models/WidgetListEnum'
import { IWidget } from '../../../../../../shared/models/IWidget'
import { widgetsSocketConnection } from '../../../../../../shared/sockets'
import styles from '../../../BankWallet/Tables/PickupRequestTable/styles.module.scss'
import { ProcurementBarChart } from '../TransactionsTable/ProcurementBarChart'
import { useGetProfileQuery } from '../../../../../Profile/core/http/Profile'
import {
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  formatShamsiMomentToISO,
} from '../../../../../../helpers/utils'
import moment from 'moment'
import { IProcurement } from '../../../../models/ITransaction'

interface IProps {
  setActiveFilters: (values: ISelectedFilters) => void
  filterValues: ISelectedFilters
  data?: IFilterFields[]
}

export const TableHeader = ({ setActiveFilters, data, filterValues }: IProps) => {
  const { data: profile } = useGetProfileQuery()
  const [widgetData, setWidgetData] = useState(() => ({
    [WIDGET_LIST_ENUM.TRUST_WALLET_AVAILABLE_BALANCE]: {
      value: 0,
      statistic: 0,
    },
    [WIDGET_LIST_ENUM.PO_UNDER_PROCESS]: {
      value: 0,
    },
    [WIDGET_LIST_ENUM.PROCUREMENT]: {
      value: [] as IProcurement[],
      frequency: 'hour',
    },
  }))

  const { isOpen, onCancel, form, onReset, onFinish } = useFilter({ setActiveFilters })

  useEffect(() => {
    if (!profile?.timeZone) return
    moment.tz?.setDefault(profile?.timeZone as string)

    const from = formatMomentDateToDayStart(
      formatShamsiMomentToISO(profile?.calendar, moment())
    ).toISOString(true)
    const to = formatMomentDateToDayEnd(
      formatShamsiMomentToISO(profile?.calendar, moment())
    ).toISOString(true)

    const socket = widgetsSocketConnection.getSocket()
    const messagePayload = [
      {
        widget: WIDGET_LIST_ENUM.TRUST_WALLET_AVAILABLE_BALANCE,
      },
      {
        widget: WIDGET_LIST_ENUM.PO_UNDER_PROCESS,
      },
      {
        widget: WIDGET_LIST_ENUM.PROCUREMENT,
        filters: {
          frequency: 'hour',
          from,
          to,
        },
      },
    ]

    socket?.emit('connect-widget', { data: messagePayload })

    return () => {
      socket?.emit('disconnect-widget', { data: messagePayload })
    }
  }, [profile])

  useEffect(() => {
    if (!profile) return
    const socket = widgetsSocketConnection.getSocket()
    socket?.on('widget-data', (data: IWidget) => {
      const balance = JSON.parse(data.data.jsonData)
      setWidgetData((prevState) => ({
        ...prevState,
        [data.widget]:
          Object.keys(balance).length === 1
            ? {
                value: balance?.value,
                ...(data['filters']?.frequency && { frequency: data['filters']?.frequency }),
              }
            : balance,
      }))
    })
  }, [profile])

  return (
    <>
      <div className={styles.tableWidgetsChart}>
        <div className={styles.widgetWrapper}>
          <Widget
            icon={<BalanceIcon />}
            title='Treasury Wallet Available Balance'
            value={widgetData[WIDGET_LIST_ENUM.TRUST_WALLET_AVAILABLE_BALANCE]?.value}
            statistic={widgetData[WIDGET_LIST_ENUM.TRUST_WALLET_AVAILABLE_BALANCE]?.statistic}
          />
          <Widget
            icon={<MonetizationWidgetIcon />}
            title='PO Under Process'
            value={widgetData[WIDGET_LIST_ENUM.PO_UNDER_PROCESS]?.value}
          />
        </div>
        <ProcurementBarChart
          widgetData={widgetData[WIDGET_LIST_ENUM.PROCUREMENT].value}
          frequency={widgetData[WIDGET_LIST_ENUM.PROCUREMENT].frequency}
        />
      </div>

      <Row align='middle' style={{ marginBottom: 20, marginTop: 32 }}>
        <Space size={20}>
          <Filter
            data={data}
            form={form}
            setIsOpen={onCancel}
            selectedFilters={filterValues}
            setSelectedFilters={setActiveFilters}
          />
        </Space>
      </Row>
      {isOpen && (
        <FilterPopup
          data={data}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          form={form}
          onCancel={onCancel}
          onReset={onReset}
          onFinish={onFinish}
        />
      )}
    </>
  )
}
