import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { ITableConf } from '../../../../shared/models/ITableConf'
import {
  IAgentDiscountPolicy,
  IAgentDiscountPolicyRateLimit,
  IAgentDiscountPolicyUpdate,
  IAgentDiscountPolicyTemplateType,
  IAgentMasterProductPostpaid,
} from '../../models/IAgentPolicy'
import { IProductAccess } from '../../../Settings/models/IDiscountPolicy'

export const agentPolicyApi = createApi({
  reducerPath: 'agentManagementPolicyApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'IAgentDiscountPolicy',
    'IProductAccessList',
    'IDiscountTemplate',
    'IProductAccessList',
    'MasterProductsPostpaid',
  ],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getProductAccessListPrepaid: builder.query<
      ITableResponse<IProductAccess>,
      { id: string; type: string; params: ITableConf }
    >({
      query: ({ id, type, params }) => ({
        url: `agents/${id}/discount-policy/products-access/${type}`,
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: () => ['IProductAccessList'],
    }),

    getMasterProductsListPostpaid: builder.query<
      ITableResponse<IAgentMasterProductPostpaid>,
      { id: string; type: string; params: ITableConf }
    >({
      query: ({ id, type, params }) => ({
        url: `agents/${id}/discount-policy/master-products/${type}`,
        params,
      }),
      keepUnusedDataFor: 0,
    }),

    getDPConfigurationByAgentId: builder.query<IAgentDiscountPolicy, { id: string; type: string }>({
      query: ({ id, type }) => ({
        url: `agents/${id}/discount-policy/${type}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: (result, error, arg) => [{ type: 'IAgentDiscountPolicy', id: arg?.id }],
    }),

    getDPTemplatesByAgentId: builder.query<
      ITableResponse<IAgentDiscountPolicyTemplateType>,
      { id: string; type: string }
    >({
      query: ({ id, type }) => ({
        url: `agents/${id}/discount-policy/templates/${type}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: ['IDiscountTemplate'],
    }),

    getDPRateLimitationsByAgentID: builder.query<
      IAgentDiscountPolicyRateLimit,
      { id: string; discountPolicyId: string | number; isAssign: boolean }
    >({
      query: ({ id, discountPolicyId, ...params }) => ({
        url: `agents/${id}/discount-policy/limitations/${discountPolicyId}`,
        params,
      }),
      keepUnusedDataFor: 0,
    }),

    createAgentDiscountPolicy: builder.mutation<
      IAgentDiscountPolicy,
      { id: string; body: IAgentDiscountPolicyUpdate }
    >({
      query: ({ id, body }) => ({
        url: `agents/${id}/discount-policy`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'IAgentDiscountPolicy', id: arg?.id },
        'IProductAccessList',
      ],
    }),

    updateAgentDiscountPolicy: builder.mutation<
      IAgentDiscountPolicy,
      { id: string; body: IAgentDiscountPolicyUpdate; type: string }
    >({
      query: ({ id, type, body }) => ({
        url: `agents/${id}/discount-policy/${type}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'IAgentDiscountPolicy', id: arg?.id },
        'IProductAccessList',
      ],
    }),

    deleteDiscountPolicyByAgentId: builder.mutation<void, { id: string; type: string }>({
      query: ({ id, type }) => ({
        url: `agents/${id}/discount-policy/${type}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'IAgentDiscountPolicy', id: arg?.id },
        'IProductAccessList',
      ],
    }),
  }),
})

export const {
  useGetProductAccessListPrepaidQuery,
  useGetDPConfigurationByAgentIdQuery,
  useGetDPTemplatesByAgentIdQuery,
  useLazyGetDPRateLimitationsByAgentIDQuery,
  useCreateAgentDiscountPolicyMutation,
  useUpdateAgentDiscountPolicyMutation,
  useGetMasterProductsListPostpaidQuery,
  useDeleteDiscountPolicyByAgentIdMutation,
} = agentPolicyApi
