import React, { memo, useCallback } from 'react'
import classNames from 'classnames'
import {
  VendorAverageTimeIcon,
  VendorConnectedIcon,
  VendorDisconnectedIcon,
  VendorIdCaseIcon,
  VendorServerIcon,
  VendorSMSIcon,
  VendorTransactionHealthIcon,
  VendorUSSDIcon,
  VendorVPNIdentityIcon,
} from 'src/assets/svg'
import VendorInfoItem from '../VendorInfoItem'
import { CONNECTION_DATA_TYPE_ENUM, ISystemMonitoring } from '../../../../models/SystemMonitoring'
import placeholder from 'src/assets/img/ProductPlaceholderPicture.png'

import styles from './styles.module.scss'
import { startCase } from 'lodash'
import { Link } from 'react-router-dom'
import { SETTINGS_ENUM } from '../../../../../../routes/settings'

interface IProps {
  monitoring: ISystemMonitoring
}

const Vendor = ({ monitoring }: IProps) => {
  const findConnectionData = useCallback(
    (type: CONNECTION_DATA_TYPE_ENUM) =>
      monitoring?.data?.connectionsData?.find((con) => con?.type === type),
    [monitoring?.data?.connectionsData]
  )

  return (
    <div
      className={classNames(styles.vendorContainer, {
        [styles.isConnectedVendor]: monitoring?.data?.status,
        [styles.isNotConnectedVendor]: !monitoring?.data?.status,
      })}
    >
      <div className={styles.vendorInfoSection}>
        <div className={styles.vendorLogo}>
          <img src={monitoring?.vendor?.logo ?? placeholder} alt='logo' />
        </div>
        <div>
          <Link
            className={styles.vendorName}
            to={`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${monitoring?.vendor?.id}`}
          >
            {monitoring?.vendor?.name}
          </Link>
          <div className={styles.vendorId}>
            <VendorIdCaseIcon />
            <div className={styles.vendorIdTitle}>{`ID${monitoring?.vendor?.id}`}</div>
          </div>
          <div className={styles.vendorStatus}>
            {monitoring?.data?.status ? <VendorConnectedIcon /> : <VendorDisconnectedIcon />}
            <div className={styles.vendorStatusTitle}>
              {monitoring?.data?.status ? 'Connected' : 'Disconnected'}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.vendorInfoSection}>
        <div className={styles.vendorInfoSectionContent}>
          <VendorInfoItem
            value={
              monitoring?.data?.transactionsData?.avgTime
                ? `${monitoring?.data?.transactionsData?.avgTime?.toFixed(2)} sec`
                : null
            }
            title='Average response time'
            icon={<VendorAverageTimeIcon />}
          />
          <VendorInfoItem
            value={startCase(findConnectionData(CONNECTION_DATA_TYPE_ENUM.VPN)?.status)}
            title='VPN Connectivity'
            icon={<VendorVPNIdentityIcon />}
          />
        </div>
      </div>
      <div className={styles.vendorInfoSection}>
        <div className={styles.vendorInfoSectionContent}>
          <VendorInfoItem
            value={startCase(findConnectionData(CONNECTION_DATA_TYPE_ENUM.USSD)?.status)}
            title='USSD Gateway Reachability'
            icon={<VendorUSSDIcon />}
          />
          <VendorInfoItem
            value={startCase(findConnectionData(CONNECTION_DATA_TYPE_ENUM.SMSC)?.status)}
            title='SMSC Reachability'
            icon={<VendorSMSIcon />}
          />
        </div>
      </div>
      <div className={styles.vendorInfoSection}>
        <div className={styles.vendorInfoSectionContent}>
          <VendorInfoItem
            value={startCase(findConnectionData(CONNECTION_DATA_TYPE_ENUM.RECHARGE)?.status)}
            title='Recharge Server Availability'
            icon={<VendorServerIcon />}
          />
          <VendorInfoItem
            title='Transaction Processing Health'
            icon={<VendorTransactionHealthIcon />}
            transactions={monitoring?.data?.transactionsData?.transactions}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(Vendor)
