import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { RightModalContext } from '../../../../../Modals'
import useSearch from '../../../../helpers/useSearch'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import { InfoMessage } from '../../../VendorManagement/VendorManagementContactListModal/Components/InfoMessage'
import { TargetListModalHeader } from './TargetListModalHeader'
import { InfiniteScrollComponent } from '../../../../../../shared/components/infiniteScoll/InfiniteScroll'
import {
  useGetDiscountPolicyByIdQuery,
  useUpdateDiscountPolicyMutation,
} from '../../../../core/http/DiscountPolicyApi'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ITableConf } from '../../../../../../shared/models/ITableConf'
import { isEmpty, unionBy } from 'lodash'
import { useGetAgentListQuery } from '../../../../../salesNetwork/core/http/AgentManagement'
import { ErrorNode } from '../../../../../../shared/api/errorHandler'
import { IDiscountPolicyCreate } from '../../../../models/IDiscountPolicy'
import { Spin } from 'antd'
import { useAppDispatch } from '../../../../../../redux'
import { ITargetGroup } from '../../../../models/ITargetGroup'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { useAssignTargetToPromotionPolicyMutation } from '../../../../core/http/PromotionPolicyApi'
import {
  targetGroupsApi,
  useGetTargetGroupListQuery,
  useGetTargetGroupsFilterListQuery,
} from '../../../../core/http/TargetGroupsApi'
import { TargetGroupList } from './TagretGroupList'
import { AgentsList } from './AgentsList'
import { IAgent } from '../../../../../salesNetwork/models/IAllTable'

export const TargetGroupListModal = () => {
  const dispatch = useAppDispatch()
  const {
    onOpen,
    onClose,
    props: { discountId, promotionId },
  } = useContext(RightModalContext)

  const [targetGroups, setTargetGroups] = useState<ITargetGroup[]>([])
  const [mappedAgentsList, setMappedAgentsList] = useState<IAgent[]>([])
  const [isChangeSearch, setIsChangeSearch] = useState(false)
  const [filterValues, setFilterValues] = useState<ISelectedFilters>({})
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 10,
    search: '',
  })

  const { data: discountPolicy, isFetching: isFetchingPolicy } = useGetDiscountPolicyByIdQuery(
    discountId,
    { skip: !discountId }
  )
  const { data: agentList } = useGetAgentListQuery(
    {
      agentLevel: 1,
      walletType: discountPolicy?.accountType?.accountType,
      agentType: discountPolicy?.agentType?.name,
      withDeleted: false,
      ...tableConf,
    },
    { skip: isEmpty(discountPolicy) }
  )
  const [updDiscPol, updDiscPolResp] = useUpdateDiscountPolicyMutation()
  const [assignTargetToProm, assignTargetToPromResp] = useAssignTargetToPromotionPolicyMutation()
  const { data: filterFields } = useGetTargetGroupsFilterListQuery(null, { skip: !promotionId })
  const { data = { items: [], totalCount: 0 } } = useGetTargetGroupListQuery(
    {
      ...tableConf,
      ...filterValues,
    },
    { skip: discountId && !promotionId }
  )

  useNotification(NOTIFICATION_TYPES.success, updDiscPolResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.success,
    updDiscPolResp.isError,
    updDiscPolResp.error as ErrorNode
  )
  useNotification(NOTIFICATION_TYPES.success, assignTargetToPromResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    assignTargetToPromResp.isError,
    assignTargetToPromResp.error as ErrorNode
  )
  const { handleSearchChange } = useSearch(setTableConf)

  const loadMoreData = () => {
    setTableConf((pre) => ({ ...pre, page: pre.page! + 1 }))
  }

  const onCloseRedirect = () => {
    onOpen(RIGHT_MODALS.SETTINGS.NETWORK_CONFIGURATION_TARGET_GROUP_LIST, {
      title: 'Select Target Group',
      discountId,
    })
  }

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleSearchChange(e)
    setIsChangeSearch(true)
  }

  const onAddTargetGroupClick = (id: number) => () => {
    const currentAllowedAgents = discountPolicy?.allowedAgents || []
    const isIncludes = discountPolicy?.allowedAgents.includes(String(id))

    if (isIncludes) {
      const body = {
        allowedAgents: currentAllowedAgents
          ?.filter((allowed) => String(allowed) !== String(id))
          .map((filtered) => +filtered),
      } as IDiscountPolicyCreate

      body.isAllowAllAgents = body?.allowedAgents.length === agentList?.totalCount

      updDiscPol({ id: discountId, body })
    } else {
      const body = {
        allowedAgents: currentAllowedAgents?.map((filtered) => +filtered),
      } as IDiscountPolicyCreate

      body?.allowedAgents?.push(Number(id))
      body.isAllowAllAgents = body?.allowedAgents.length === agentList?.totalCount

      updDiscPol({ id: discountId, body })
    }
  }

  const onAddButtonClick = () => {
    onOpen(RIGHT_MODALS.SETTINGS.NETWORK_CONFIGURATION_TARGET_GROUP, {
      title: 'Add Target Group',
      onCloseRedirect,
    })
  }

  const onSelectClick = (groupId: number) => {
    if (promotionId) {
      return assignTargetToProm({ promotionId, groupId })
    }
  }

  const onCheckboxChange = () => {
    const body = {
      isAllowAllAgents: !discountPolicy?.isAllowAllAgents,
      allowedAgents: [],
    }

    updDiscPol({ id: discountId, body })
  }

  useEffect(() => {
    if (assignTargetToPromResp.isSuccess) {
      dispatch(targetGroupsApi.util.invalidateTags(['TargetGroups']))
      onClose()
    }
  }, [assignTargetToPromResp.isSuccess, dispatch, onClose])

  useEffect(() => {
    if (!promotionId || isEmpty(data)) return

    if (
      tableConf.page === 1 ||
      (isChangeSearch && (tableConf.search || Object.keys(filterValues).length !== 0))
    ) {
      setTargetGroups(data?.items)
      setIsChangeSearch(false)
    } else {
      setTargetGroups((p) => unionBy([...p, ...data?.items], 'id'))
    }
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    if (!discountId || isEmpty(agentList)) return

    if (
      tableConf.page === 1 ||
      (isChangeSearch && (tableConf.search || Object.keys(filterValues).length !== 0))
    ) {
      setMappedAgentsList(agentList?.items)
      setIsChangeSearch(false)
    } else {
      setMappedAgentsList((p) => unionBy([...p, ...agentList?.items], 'id'))
    }
    // eslint-disable-next-line
  }, [agentList])

  const loading = updDiscPolResp.isLoading || isFetchingPolicy

  const content = (
    <>
      {discountId && (
        <InfiniteScrollComponent
          loadMoreData={loadMoreData}
          dataLength={mappedAgentsList.length}
          totalCount={agentList?.totalCount as number}
          containerStyles={{ width: '100%', padding: '24px 24px 24px 32px' }}
          loaderStyles={{ paddingTop: 24 }}
        >
          <InfoMessage text='Target Groups' isAccount={false} />
          <TargetListModalHeader
            onSearchChange={onSearchChange}
            isDiscountPolicy={true}
            setActiveFilters={(res) => {
              setFilterValues(res)
            }}
            filterValues={filterValues}
            data={filterFields}
            onAddButtonClick={onAddButtonClick}
          />
          <AgentsList
            onAddButtonClick={onAddTargetGroupClick}
            onCloseRedirect={onCloseRedirect}
            onCheckboxChange={onCheckboxChange}
            selectAll={discountPolicy?.isAllowAllAgents}
            selectedItemList={discountPolicy?.allowedAgents}
            data={mappedAgentsList}
            totalCount={agentList?.totalCount}
          />
        </InfiniteScrollComponent>
      )}

      {promotionId && (
        <InfiniteScrollComponent
          loadMoreData={loadMoreData}
          dataLength={targetGroups.length}
          totalCount={data?.totalCount as number}
          containerStyles={{ width: '100%', padding: '24px 24px 8px 32px' }}
          loaderStyles={{ paddingTop: 24 }}
        >
          <InfoMessage text='Target Groups' isAccount={false} />
          <TargetListModalHeader
            setActiveFilters={(res) => {
              setFilterValues(res)
            }}
            filterValues={filterValues}
            data={filterFields}
            onSearchChange={onSearchChange}
            onAddButtonClick={onAddButtonClick}
            isDiscountPolicy={false}
          />
          <TargetGroupList
            targetGroups={targetGroups}
            onCloseRedirect={onCloseRedirect}
            onSelectClick={onSelectClick}
            promotionId={promotionId}
          />
        </InfiniteScrollComponent>
      )}
    </>
  )

  return loading ? <Spin>{content}</Spin> : content
}
