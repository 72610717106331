import { useFilter } from '../../../../../../../shared/hooks/filter/useFilter'
import { Col, Row } from 'antd'
import { Filter, ISelectedFilters } from '../../../../../../../shared/components/filter/Filter'
import { FilterPopup } from '../../../../../../../shared/components/filter/FilterPopup'
import { IFilterFields } from '../../../../../../../shared/models/IFilterFields'
import React, { ReactNode } from 'react'
import usePermissions from '../../../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../../models/IPermissions'

interface IProps {
  setActiveFilters: (values: ISelectedFilters) => void
  filterValues: ISelectedFilters
  data?: IFilterFields[]
  searchComponent?: ReactNode
  classNamesFilterWrapperIcon?: string
  addButtonComponent?: ReactNode
  classNameFilterPopup?: string
}

export const ContactListModalHeader = ({
  setActiveFilters,
  data,
  filterValues,
  searchComponent,
  classNamesFilterWrapperIcon,
  addButtonComponent,
  classNameFilterPopup,
}: IProps): JSX.Element => {
  const { isOpen, onCancel, form, onReset, onFinish } = useFilter({ setActiveFilters })
  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const [usersId] = getModulesIdsByNames([IPermissionsNames.Users])

  const [canAddUser] = canPerformAction(usersId, [IPermissionsActions.ADD])

  return (
    <>
      <Row align='middle' justify='space-between' style={{ marginBottom: 20, width: '100%' }}>
        {searchComponent}
        {canAddUser ? addButtonComponent : null}
      </Row>
      <Row align='middle' justify='space-between' style={{ marginBottom: 20, width: '100%' }}>
        <Col style={{ display: 'flex', gridGap: 20 }}>
          <Filter
            data={data}
            form={form}
            setIsOpen={onCancel}
            selectedFilters={filterValues}
            setSelectedFilters={setActiveFilters}
            sizeFilter='large'
            classNamesFilterWrapperIcon={classNamesFilterWrapperIcon}
          />
        </Col>
      </Row>
      {isOpen && (
        <FilterPopup
          className={classNameFilterPopup}
          data={data}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          form={form}
          onCancel={onCancel}
          onReset={onReset}
          onFinish={onFinish}
        />
      )}
    </>
  )
}
