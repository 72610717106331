import { Table } from 'antd'
import styles from './styles.module.scss'
import {
  useDeleteBankAccountMutation,
  useGetBAFiltersListQuery,
  useGetBankAccountsQuery,
} from '../../../../core/http/BankAccountApi'
import { IBankAccount, IBankAccountList } from '../../../../models/IBankAccount'
import { TableActionIcon } from '../../../../../../assets/svg'
import React, { useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { TableHeader } from '../TableHeader'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { ITableConf } from '../../../../../../shared/models/ITableConf'
import { ColumnsType } from 'antd/es/table'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../shared/components/popup/Popup'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ErrorNode, showConfirmMessage } from '../../../../../../shared/api/errorHandler'
import emptyAvatarImage from '../../../../../../assets/img/BankLogoPlaceholder.png'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { Tags } from '../../../../../../shared/components/tags'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { isEmpty } from 'lodash'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'
import { FINANCE_ENUM } from '../../../../../../routes/finance'

export const BankAccounts = () => {
  const navigate = useNavigate()
  const { queryParams, queryFields } = useGetQueryParams()
  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const [deleteBankAccount, delBAResp] = useDeleteBankAccountMutation()
  const { data: filtersList } = useGetBAFiltersListQuery()
  const { data: bankAccounts, isLoading } = useGetBankAccountsQuery(
    {
      ...tableConf,
      ...filterValues,
    },
    { skip: isEmpty(tableConf) }
  )

  useNotification(NOTIFICATION_TYPES.success, delBAResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, delBAResp.isError, delBAResp.error as ErrorNode)

  const tableActionsPopup = useCallback(
    (id: IBankAccount['id']): IPopupListItems[] => [
      {
        text: 'View',
        shouldDisplay: true,
        onClick: () => navigate(`${FINANCE_ENUM.BANK_SETTINGS}/bank-accounts/view/${id}`),
      },
      {
        text: 'Edit',
        shouldDisplay: true,
        onClick: () => navigate(`${FINANCE_ENUM.BANK_SETTINGS}/bank-accounts/edit/${id}`),
      },
      {
        text: 'Delete',
        shouldDisplay: true,
        onClick: () =>
          showConfirmMessage('This bank account will be deleted', () => deleteBankAccount(id)),
        color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        withDivider: true,
      },
    ],
    [deleteBankAccount, navigate]
  )

  const columns: ColumnsType<IBankAccountList> = [
    {
      title: 'Bank Name',
      dataIndex: 'name',
      width: '10%',
      render: (companyName: IBankAccountList['name'], row) => (
        <RecursivelyReplaceCharacters>
          <Link
            to={`${FINANCE_ENUM.BANK_SETTINGS}/bank-accounts/view/${row.id}`}
            className='table-avatar'
          >
            <img alt='vendor logo' src={!isPrivateMode && row.logo ? row.logo : emptyAvatarImage} />
            <div>{companyName}</div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
      sorter: true,
    },
    {
      title: 'Account Number',
      dataIndex: 'description',
      width: '10%',
      sorter: true,
      render: (descr) => <RecursivelyReplaceCharacters>{descr}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Payment Options',
      dataIndex: 'paymentMethod',
      width: '17%',
      render: (value: IBankAccountList['paymentMethod']) => <Tags tags={value} isBlue />,
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      width: '10%',
      sorter: true,
      render: (value: IBankAccountList['currency']) => (
        <RecursivelyReplaceCharacters>{value.textCode}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Currency Balance',
      dataIndex: 'walletBalance',
      width: '10%',
      sorter: true,
      render: (value: IBankAccountList['walletBalance']) => (
        <RecursivelyReplaceCharacters>{value}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Type Of Account',
      dataIndex: 'typeOfAccount',
      width: '10%',
      sorter: true,
      ellipsis: { showTitle: true },
      render: (type) => <RecursivelyReplaceCharacters>{type}</RecursivelyReplaceCharacters>,
    },
    {
      title: '',
      dataIndex: 'edit',
      width: '1%',
      key: 'edit',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const emptyTableConf = useEmptyTable(() => navigate('new'))
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IBankAccountList>({
    total: bankAccounts?.totalCount,
    setTableConf,
    setFilterValues,
  })

  return (
    <>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filtersList}
      />
      <Table
        locale={emptyTableConf}
        className={styles.bankAccountsTable}
        dataSource={bankAccounts?.items}
        loading={isLoading}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{ x: 1300 }}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </>
  )
}
