import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import emptyLogo from 'src/assets/img/ProductTablePlaceholder.png'
import styles from '../../styles.module.scss'

interface IProps {
  logo?: string
  isPrivateMode?: boolean
}

export const LogoBlock = ({ logo, isPrivateMode }: IProps) => {
  return (
    <Wrapper title='Logo'>
      <div className={styles.logoWrapper}>
        <img alt='logo' src={!isPrivateMode && logo ? logo : emptyLogo} width={200} height={200} />
      </div>
    </Wrapper>
  )
}
