import { createApi } from '@reduxjs/toolkit/query/react'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from 'src/shared/models/ITableResponse'
import {
  AudienceType,
  IAudience,
  IAudienceBody,
  IScheduleNotification,
  IScheduleNotificationBody,
} from '../../models/SceduleNotification'

export const scheduleNotificationApi = createApi({
  reducerPath: 'scheduleNotification',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['NotificationList', 'Notification', 'FilterFields', 'AudienceList', 'AFilterFields'],
  endpoints: (builder) => ({
    getScheduleNotification: builder.query<ITableResponse<IScheduleNotification>, ITableConf>({
      query: (tableConf) => ({
        url: 'schedule-notifications',
        params: tableConf,
      }),
      providesTags: () => ['NotificationList'],
    }),

    getScheduleNotificationById: builder.query<IScheduleNotification, string>({
      query: (id: string) => ({
        url: `schedule-notifications/${id}`,
      }),
      providesTags: () => ['Notification'],
    }),

    createScheduleNotification: builder.mutation<IScheduleNotification, IScheduleNotificationBody>({
      query: (body: IScheduleNotificationBody) => ({
        url: 'schedule-notifications',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['NotificationList', 'FilterFields'],
    }),

    updateScheduleNotification: builder.mutation<
      IScheduleNotification,
      { id: string; body: IScheduleNotificationBody }
    >({
      query: ({ id, body }) => ({
        url: `schedule-notifications/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Notification', 'NotificationList', 'FilterFields'],
    }),

    deleteScheduleNotification: builder.mutation<void, number>({
      query: (id) => ({
        url: `schedule-notifications/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['NotificationList'],
    }),

    getAudience: builder.query<ITableResponse<IAudience>, { tableConf: ITableConf; id: string }>({
      query: ({ tableConf, id }) => ({
        url: `schedule-notifications/${id}/audience`,
        params: tableConf,
      }),
      providesTags: () => ['AudienceList'],
    }),

    deleteAudience: builder.mutation<
      void,
      { id: string; audienceType: string; body: IAudienceBody }
    >({
      query: ({ id, audienceType, body }) => ({
        url: `/schedule-notifications/${id}/audience/connect/${audienceType}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () => ['AudienceList', 'FilterFields'],
    }),

    createAudience: builder.mutation<
      IAudience,
      { id: string; audienceType: AudienceType; body: IAudienceBody }
    >({
      query: ({ id, audienceType, body }) => ({
        url: `/schedule-notifications/${id}/audience/connect/${audienceType}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['AudienceList', 'FilterFields'],
    }),

    getAllTableFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({ url: 'schedule-notifications/filter-fields' })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `schedule-notifications/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: () => ['FilterFields'],
    }),

    getAudienceFiltersList: builder.query<Array<IFilterFields>, string>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: `/schedule-notifications/${arg}/audience/filter-fields`,
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `schedule-notifications/${arg}/audience/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: () => ['AFilterFields'],
    }),
  }),
})

export const {
  useCreateScheduleNotificationMutation,
  useGetAllTableFiltersListQuery,
  useGetScheduleNotificationByIdQuery,
  useGetScheduleNotificationQuery,
  useUpdateScheduleNotificationMutation,
  useDeleteScheduleNotificationMutation,
  useGetAudienceQuery,
  useDeleteAudienceMutation,
  useGetAudienceFiltersListQuery,
  useCreateAudienceMutation,
} = scheduleNotificationApi
