import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { IDocumentListResponse, IGeneralDocument } from '../../models/IDocumentVerification'
import { AgentDocumentStatusEnum } from '../../pages/AgentManagment/AgentManagement.d'

export const documentVerificationApi = createApi({
  reducerPath: 'documentVerificationApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['IDocumentList'],

  endpoints: (builder) => ({
    getDocumentList: builder.query<IDocumentListResponse, string | undefined>({
      query: (id) => ({
        url: `agents/${id}/document-verifications`,
      }),
      providesTags: () => ['IDocumentList'],
    }),

    createDocument: builder.mutation<
      IGeneralDocument,
      { id: string; type: string; body: FormData }
    >({
      query: ({ id, type, body }) => ({
        url: `agents/${id}/document-verifications/${type}`,
        method: 'POST',
        body,
      }),
      // invalidatesTags: () => ['IDocumentList'],
    }),

    updateDocument: builder.mutation<
      IGeneralDocument,
      { id: string; type: string; documentId: string; body: FormData }
    >({
      query: ({ id, type, documentId, body }) => ({
        url: `agents/${id}/document-verifications/${type}/${documentId}`,
        method: 'PATCH',
        body,
      }),
      // invalidatesTags: () => ['IDocumentList'],
    }),

    verifyDocument: builder.mutation<
      IGeneralDocument,
      {
        id: string
        documentId: string
        body: { verificationStatus: AgentDocumentStatusEnum }
      }
    >({
      query: ({ id, documentId, body }) => ({
        url: `agents/${id}/document-verifications/${documentId}/verify`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['IDocumentList'],
    }),

    deleteDocument: builder.mutation<IGeneralDocument, { id: string; documentId: string }>({
      query: ({ id, documentId }) => ({
        url: `agents/${id}/document-verifications/${documentId}`,
        method: 'DELETE',
      }),
      // invalidatesTags: () => ['IDocumentList'],
    }),
  }),
})

export const {
  useGetDocumentListQuery,
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useUpdateDocumentMutation,
  useVerifyDocumentMutation,
} = documentVerificationApi
