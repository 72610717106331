import React, { useEffect, useMemo } from 'react'
import { Form, Select, Spin } from 'antd'
import { TableWrapper } from '../TableWrapper'
import styles from '../styles.module.scss'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import {
  useGetDPTemplatesByAgentIdQuery,
  useGetProductAccessListPrepaidQuery,
} from '../../../../core/http/AgentPolicyApi'
import {
  DISCOUNT_POLICY_PRODUCT_TYPE_ENUM,
  IProductAccess,
} from '../../../../../Settings/models/IDiscountPolicy'
import classNames from 'classnames'
import { requiredValidator } from '../../../../../../helpers/validation'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'
import { useGetMasterProductsListQuery } from '../../../../../Settings/core/http/VendorManagementApi'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from '../../../../../../assets/svg'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { ACCOUNT_TYPE_TO_ID } from 'src/features/Settings/models/IAccountType'
import { isEmpty } from 'lodash'

interface IProps extends IViewMode {
  title: string
  isTree?: boolean
  agentId?: string
  productType?: DISCOUNT_POLICY_PRODUCT_TYPE_ENUM | null
  canEditTree?: boolean
  isFirstLevel: boolean
}

export const PrepaidProductAccessBlock = ({
  title,
  agentId,
  productType,
  isTree = false,
  view,
  canEditTree,
  isFirstLevel,
}: IProps) => {
  const form = useFormInstance()
  const { data: masterProductsList, isFetching } = useGetMasterProductsListQuery({})
  // PRODUCT ACCESS TABLE
  const { data: productAccess, isFetching: isAccessFetching } = useGetProductAccessListPrepaidQuery(
    {
      id: agentId as string,
      type: productType as string,
      params: { isTree: isTree },
    },
    { skip: !agentId || !productType }
  )

  const formListName = useMemo(() => (isTree ? 'tree' : 'agent'), [isTree])

  useEffect(() => {
    if (isEmpty(productAccess)) return

    form.setFieldValue(formListName, productAccess?.items)
  }, [form, formListName, productAccess])

  const content = (
    <TableWrapper title={title}>
      <div className={styles.productAccessInner}>
        <Form.List name={formListName} initialValue={productAccess?.items}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <div key={key} className={styles.discountPolicyRow}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const selectedProducts = getFieldValue(formListName)?.map(
                        (product: IProductAccess) => product?.masterProduct?.id
                      )
                      return (
                        <Form.Item
                          name={[name, 'masterProduct', 'id']}
                          className={classNames(
                            styles.productAccessMasterProduct,
                            'noBottomMargin'
                          )}
                          rules={[
                            { required: true, validator: requiredValidator('Master Product') },
                          ]}
                        >
                          <Select
                            disabled={view || canEditTree}
                            allowClear
                            placeholder='Select Product'
                          >
                            {masterProductsList?.items?.map((product) => (
                              <Select.Option
                                key={product.id}
                                value={product.id}
                                disabled={selectedProducts?.includes(product?.id)}
                              >
                                {product.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )
                    }}
                  </Form.Item>

                  <Form.Item
                    name={[name, 'isLocked']}
                    className={classNames(
                      styles.productAccessMasterProductStatus,
                      'noBottomMargin'
                    )}
                    valuePropName='checked'
                  >
                    <Toggle disabled={view || canEditTree || !isFirstLevel} text='Is Locked' />
                  </Form.Item>

                  <div>
                    <IconButton
                      block
                      size='large'
                      color='orange'
                      slim
                      icon={<BucketIcon />}
                      type='default'
                      onClick={() => remove(name)}
                      className='noBottomMargin'
                      disabled={view || canEditTree}
                    />
                  </div>
                </div>
              ))}
              <div className={styles.productAccessAddButton}>
                <IconButton
                  icon={<AddIcon />}
                  disabled={view || !agentId || !productType || canEditTree}
                  color='orange'
                  block
                  onClick={() => {
                    add()
                  }}
                />
              </div>
            </>
          )}
        </Form.List>
      </div>
    </TableWrapper>
  )

  return isAccessFetching || isFetching ? <Spin>{content}</Spin> : content
}
