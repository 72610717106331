import React, { useEffect, useMemo } from 'react'
import { ArrowRightIcon } from 'src/assets/svg'
import emptyAvatarImage from 'src/assets/img/Avatar.png'
import { InfoMessage } from 'src/features/Settings/pages/VendorManagement/VendorManagementContactListModal/Components/InfoMessage'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { InfiniteScrollComponent } from 'src/shared/components/infiniteScoll/InfiniteScroll'
import { ModalHeader } from '../../Components/ModalHeader'

// interfaces
import { ISelectedFilters } from 'src/shared/components/filter/Filter'
import { IAgent } from 'src/features/salesNetwork/models/IAllTable'
import { IFilterFields } from 'src/shared/models/IFilterFields'
import { Step } from 'src/features/Support/models/IMPin'

// styles
import styles from '../../styles.module.scss'
import { Button } from 'src/shared/components/button/Button'
import {
  useGetAgentByIdQuery,
  useTransferAgentMutation,
} from 'src/features/salesNetwork/core/http/AgentManagement'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode } from 'src/shared/api/errorHandler'
import classNames from 'classnames'
import { AGENT_TYPE_ID_ENUM } from 'src/features/Settings/models/IAgentType'
import { Space, Spin } from 'antd'
import { isNumber } from 'lodash'

interface ISearchListStep {
  step: Step
  agentId?: string | number | null
  agentLevel?: number | null
  dataAgentList: IAgent[]
  totalCount: number
  filterValues: ISelectedFilters
  filterFields: IFilterFields[] | undefined
  loadMoreData: () => void
  handlerSetActiveFilters: (values: ISelectedFilters) => void
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeStep: (step: Step, agentId: string | number) => void
  onSelect?: (id: string | number) => void
  selectedAgent?: number | string
  onChangedParentId?: (values: {
    status: boolean
    parentId: string | number | null
    agentLevel: number | null
  }) => void
}

export const SearchListStep: React.FC<ISearchListStep> = ({
  step,
  agentId,
  agentLevel,
  totalCount,
  dataAgentList,
  filterValues,
  filterFields,
  loadMoreData,
  handlerSetActiveFilters,
  onSearchChange,
  onChangeStep,
  onChangedParentId,
  onSelect,
  selectedAgent,
}) => {
  const [transferAgent, { data, isError, error, isSuccess }] = useTransferAgentMutation()

  const { data: agentData } = useGetAgentByIdQuery(agentId as string, { skip: !agentId })

  useNotification(NOTIFICATION_TYPES.error, isError, error as ErrorNode)

  useEffect(() => {
    if (!isSuccess) return
    onChangeStep('agentProfile', agentId!)
    // eslint-disable-next-line
    onChangedParentId?.({
      status: isSuccess,
      // eslint-disable-next-line
      parentId: data?.parentId!,
      // eslint-disable-next-line
      agentLevel: data?.agentLevel.level!,
    })
    // eslint-disable-next-line
  }, [isSuccess])

  const handleChangeParentId = (id: string | number) => () => {
    transferAgent({ id: +agentId!, body: { parentId: +id } })
  }

  const infoMessageTitle = step === 'searchAgent' ? 'Agent' : 'Parent'

  const content = (
    <InfiniteScrollComponent
      loadMoreData={loadMoreData}
      dataLength={dataAgentList.length}
      totalCount={totalCount}
      containerStyles={{ width: '100%', padding: '24px 24px 8px 32px' }}
    >
      <InfoMessage text={infoMessageTitle} />
      <ModalHeader
        filterValues={filterValues}
        data={filterFields}
        setActiveFilters={handlerSetActiveFilters}
        onSearchChange={onSearchChange}
      />
      <div className={styles.amountContacts}>{totalCount} agents</div>
      {dataAgentList.map((agent) => (
        <div className={styles.agentContainer} key={agent.id}>
          <div className={styles.leftContainer}>
            <img src={agent.avatar ?? emptyAvatarImage} alt='image' />
            <div className={styles.agentInfo}>
              <div className={styles.agentName}>
                {agent?.firstName
                  ? `${agent.firstName} ${agent.lastName}`
                  : `${agent.companyTitle}`}
              </div>
              <div className={styles.agentTypeContainer}>
                <div className={styles.agentType}>{agent.agentType.name}</div>
                <div>{`Level ${agent.agentLevel.level}`}</div>
              </div>
              <div className={styles.idContainer}>
                <div className={styles.agentId}>
                  {`ID `}
                  <div
                    className={classNames({
                      [styles.colorGreen]: agentLevel && agent.agentLevel.level > agentLevel!,
                      [styles.colorRed]: agentLevel && agent.agentLevel.level <= agentLevel!,
                    })}
                  >
                    {agent.id}
                  </div>
                </div>
                {agent.parentId && (
                  <div className={styles.parentId}>{`Parent ID ${agent.parentId}`}</div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.rightContainer}>
            {step === 'searchAgent' && (
              <Space size={10}>
                {onSelect && (
                  <Button
                    size='small'
                    color='orange'
                    onClick={() => onSelect(agent?.id)}
                    disabled={Number(agent?.id) === Number(selectedAgent)}
                    type={Number(agent?.id) === Number(selectedAgent) ? 'primary' : 'default'}
                  >
                    select
                  </Button>
                )}
                <IconButton
                  onClick={() => onChangeStep('agentProfile', agent.id)}
                  color='blue'
                  size='small'
                  icon={<ArrowRightIcon />}
                />
              </Space>
            )}

            {step === 'searchParent' &&
              agent.agentType.id === AGENT_TYPE_ID_ENUM.INDIRECT &&
              agent.agentLevel.level < agentData!.agentLevel?.level && (
                <Button size='small' color='blue' onClick={handleChangeParentId(agent.id!)}>
                  select
                </Button>
              )}
          </div>
        </div>
      ))}
    </InfiniteScrollComponent>
  )

  return !isNumber(totalCount) ? <Spin>{content}</Spin> : content
}
