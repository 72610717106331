import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../redux'
import { IUser } from '../../models/IUser'

interface UsersState {
  usersList: IUser[]
}

const initialState: UsersState = {
  usersList: [],
}

export const usersManagementSlice = createSlice({
  name: 'usersManagementSlice',
  initialState,
  reducers: {
    addUser: (state, { payload }: PayloadAction<IUser>) => {
      state.usersList.push(payload)
    },
    deleteUserById: (state, { payload }: PayloadAction<number>) => {
      if (state.usersList) {
        state.usersList = state.usersList.filter((user) => user.id !== payload)
      }
    },
    clearUsersSliceState(state) {
      state.usersList = []
    },
  },
})

export const selectUsersManagementState = (state: RootState) => state.usersManagementSlice

export const { addUser, deleteUserById, clearUsersSliceState } = usersManagementSlice.actions

export default usersManagementSlice.reducer
