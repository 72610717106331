import { Select, Space, Spin } from 'antd'
import styles from '../../CustomerModal/styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import React, { useCallback } from 'react'
import { debounce } from 'lodash'
import { useLazyGetClosedGroupsListQuery } from '../../../../../core/http/CustomersApi'
import classNames from 'classnames'

export const CreateStep = () => {
  const [getList, getListResp] = useLazyGetClosedGroupsListQuery()
  const handleSearch = useCallback(
    debounce((val: string) => {
      if (!val) return
      getList({ search: val })
    }, 300),
    []
  )

  return (
    <FormItem name='closedGroupId' label='Select Closed Group'>
      <Select
        showSearch
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        notFoundContent={getListResp.isLoading ? <Spin size='small' /> : null}
        className={styles.searchSelect}
        onSearch={handleSearch}
        allowClear
      >
        {getListResp?.data?.items?.map((group) => (
          <Select.Option key={group?.id} value={group?.id}>
            <Space>
              <div>{group?.id}</div>
              <span>-</span>
              <div className={styles.customerSearchItem}>
                {group?.customers?.map((cust, i) => (
                  <div
                    key={cust.id}
                    className={classNames({ [styles.groupOwner]: cust?.isGroupOwner })}
                  >
                    {cust?.fullName}
                    {i !== group?.customers?.length - 1 && ','}
                  </div>
                ))}
              </div>
            </Space>
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  )
}
