import styles from './styles.module.scss'
import { RestrictionManagementTabs } from './Components/Tabs/index'

export const RestrictionManagement = () => {
  return (
    <div className={styles.layout}>
      <RestrictionManagementTabs />
    </div>
  )
}
