import { memo, useEffect, useState } from 'react'
import { isEmpty, startCase } from 'lodash'
import { Form, FormInstance, Select as AntSelect, UploadFile, UploadProps } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import { InboxOutlined } from '@ant-design/icons'

import { TextField } from '../../../../../../../../shared/components/textfield/TextField'
import { DatePickerIcon } from '../../../../../../../../assets/svg'
import { AgentDocumentsEnum, AgentDocumentStatusEnum } from '../../../../AgentManagement.d'
import { letterValidator, requiredValidator } from 'src/helpers/validation'
import {
  IDocumentAttachmentRes,
  IDocumentForm,
  IUploadFileBody,
} from 'src/features/salesNetwork/models/IDocumentVerification'

import styles from '../../styles.module.scss'
import { Button } from 'src/shared/components/button/Button'
import { documentVerificationTypes } from 'src/shared/components/uploaderPhotos/constants'
import { DatePicker } from 'src/shared/components/datePicker'
import { PDFViewer } from 'src/features/Settings/pages/FileManagement/FileManagementView/Components/Content/PDFViewer'
import classNames from 'classnames'
import moment from 'moment'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { CALENDAR_TYPE_ENUM } from 'src/features/Settings/models/IUser'
import dayjs from 'dayjs'

const isNotAcceptedType = (type: string, types: string[]) => types.find((i) => i === type)

const documentOptionsName = {
  [AgentDocumentsEnum.NIC]: 'National ID Card',
  [AgentDocumentsEnum.ID]: 'ID Card',
}

const documentOptions = Object.keys(AgentDocumentsEnum).map((i) => ({
  id: i,
  value: i,
  name:
    i === AgentDocumentsEnum.NIC
      ? documentOptionsName[AgentDocumentsEnum.NIC]
      : i === AgentDocumentsEnum.ID
      ? documentOptionsName[AgentDocumentsEnum.ID]
      : startCase(i.toLowerCase()).split('_').join(' '),
}))

const reqVal = (title: string) => ({
  required: true,
  validator: requiredValidator(title),
})

const requiredFields: Record<
  AgentDocumentsEnum,
  (
    | 'type'
    | 'idNumber'
    | 'firstName'
    | 'lastName'
    | 'fatherName'
    | 'issuedAt'
    | 'expireAt'
    | 'companyTitle'
  )[]
> = {
  [AgentDocumentsEnum.NIC]: ['type', 'idNumber', 'firstName', 'fatherName', 'issuedAt'],
  [AgentDocumentsEnum.ID]: ['type', 'idNumber', 'firstName', 'lastName', 'fatherName', 'issuedAt'],
  [AgentDocumentsEnum.COMPANY_LICENCE]: [
    'type',
    'companyTitle',
    'firstName',
    'lastName',
    'issuedAt',
    'expireAt',
  ],
  [AgentDocumentsEnum.DRIVING_LICENCE]: [
    'type',
    'idNumber',
    'firstName',
    'fatherName',
    'issuedAt',
    'expireAt',
  ],
  [AgentDocumentsEnum.PASSPORT]: [
    'type',
    'idNumber',
    'firstName',
    'lastName',
    'fatherName',
    'issuedAt',
    'expireAt',
  ],
  [AgentDocumentsEnum.CONTRACT]: ['type', 'idNumber', 'issuedAt', 'expireAt'],
}

const getRequiredValidator = (
  documentType: AgentDocumentsEnum,
  field:
    | 'type'
    | 'idNumber'
    | 'firstName'
    | 'lastName'
    | 'fatherName'
    | 'issuedAt'
    | 'expireAt'
    | 'companyTitle',
  fieldTitle: string
) => {
  return requiredFields[documentType]?.includes(field) ? [reqVal(fieldTitle)] : []
}
interface IProps {
  form: FormInstance
  view: boolean
  edit: boolean
  name: number
  // eslint-disable-next-line
  viewFiles?: IDocumentAttachmentRes[]
  dispatchData: (obj: IUploadFileBody) => void
  onPressVerify: (documentId: string) => void
  onRemoveAttachments: (id: string, type: string) => void
}

const IdVerificationForm = ({
  form,
  view,
  edit,
  name,
  viewFiles = [],
  dispatchData,
  onPressVerify,
  onRemoveAttachments,
  ...restField
}: IProps) => {
  // eslint-disable-next-line
  const [uploadedFile, setUploadedFile] = useState<UploadFile[] | any>([])
  const { data: profile } = useGetProfileQuery()

  useEffect(() => {
    if (isEmpty(viewFiles)) return

    const files = viewFiles?.map(
      ({
        id,
        attachment: {
          bucketOptions: { file },
          key,
        },
      }) => ({
        uid: +id,
        name: file,
        url: key,
        status: 'done',
      })
    )

    setUploadedFile(files)
  }, [viewFiles])

  const resetDocumentType = () => {
    form.setFields([
      {
        name: ['documents', name, 'idNumber'],
        value: '',
        errors: [],
      },
      {
        name: ['documents', name, 'companyTitle'],
        value: '',
        errors: [],
      },
      {
        name: ['documents', name, 'firstName'],
        value: '',
        errors: [],
      },
      {
        name: ['documents', name, 'lastName'],
        value: '',
        errors: [],
      },
      {
        name: ['documents', name, 'fatherName'],
        value: '',
        errors: [],
      },
      {
        name: ['documents', name, 'issuedAt'],
        value: '',
        errors: [],
      },
      {
        name: ['documents', name, 'expireAt'],
        value: '',
        errors: [],
      },
      {
        name: ['documents', name, 'attachments'],
        value: '',
        errors: [],
      },
    ])
    setUploadedFile([])
  }

  const onChangeDocumentType = () => {
    resetDocumentType()
  }

  const onChangeIssueDate = () => {
    form.setFields([
      {
        name: ['documents', name, 'expireAt'],
        value: '',
        errors: [],
      },
    ])
  }

  const uploadProps: UploadProps = {
    customRequest: (info) => {
      if (typeof info.file !== 'string' && info.onError && info.onSuccess) {
        if (!isNotAcceptedType(info.file.type, documentVerificationTypes)) {
          const err = new Error('File type is not compatible!')
          return info.onError(err, 'Unacceptable file type')
        }
        return info.onSuccess('Ok')
      }
    },
    onChange: (info) => {
      info.file.status = 'done'
      if (!isNotAcceptedType(info.file.type!, documentVerificationTypes)) {
        info.file.status = 'error'
      }

      setUploadedFile(info.fileList)
      const type = form.getFieldValue(['documents', name, 'type'])

      dispatchData({ [type]: info.fileList })
    },
    onRemove: (info) => {
      if (typeof info.uid === 'string') return
      const type = form.getFieldValue(['documents', name, 'type'])

      onRemoveAttachments?.(info.uid, type)
    },
  }

  return (
    <div className={styles.formContent}>
      <div
        className={classNames(styles.formInnerContainer, {
          [styles.hideMargin]: view && isEmpty(viewFiles),
        })}
      >
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const documentList = getFieldValue(['documents'])
            const documentType = getFieldValue(['documents', name, 'type'])
            const documentId = getFieldValue(['documents', name, 'id'])

            const shouldDisableTypes = documentList
              .map((i: IDocumentForm) => i?.type)
              .filter(Boolean)

            return (
              <Form.Item
                {...restField}
                name={[name, 'type']}
                label='Document Type'
                rules={[reqVal('Document Type')]}
                style={{ width: 'calc(50% - 8px)' }}
              >
                <AntSelect
                  allowClear
                  disabled={view || (edit && documentId)}
                  placeholder='Select Document Type'
                  onChange={onChangeDocumentType}
                  onClear={resetDocumentType}
                >
                  {documentOptions
                    .filter(
                      (i) => i.value === documentType || !shouldDisableTypes.includes(i.value)
                    )
                    .map(({ id, value, name }) => (
                      <AntSelect.Option key={id} value={value}>
                        {name}
                      </AntSelect.Option>
                    ))}
                </AntSelect>
              </Form.Item>
            )
          }}
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const documentType = getFieldValue(['documents', name, 'type'])

            const labelType =
              documentType === AgentDocumentsEnum.CONTRACT ? 'Contract Number' : 'ID Number'

            const isRequired =
              documentType &&
              requiredFields[documentType as AgentDocumentsEnum].includes('idNumber')

            if (!isRequired) {
              return null
            }

            return (
              <Form.Item
                {...restField}
                name={[name, 'idNumber']}
                label={labelType}
                rules={[...getRequiredValidator(documentType, 'idNumber', labelType)]}
              >
                <TextField
                  type='number'
                  placeholder={`Type ${labelType}`}
                  disabled={view || !isRequired}
                />
              </Form.Item>
            )
          }}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const documentType = getFieldValue(['documents', name, 'type'])

            const isRequired =
              documentType &&
              requiredFields[documentType as AgentDocumentsEnum].includes('firstName')

            if (!isRequired) {
              return null
            }

            return (
              <Form.Item
                {...restField}
                name={[name, 'firstName']}
                label='First Name'
                rules={[
                  ...getRequiredValidator(documentType, 'firstName', 'First Name'),
                  {
                    validator: letterValidator,
                  },
                ]}
              >
                <TextField placeholder='Type First Name' disabled={view || !isRequired} />
              </Form.Item>
            )
          }}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const documentType = getFieldValue(['documents', name, 'type'])

            const isRequired =
              documentType &&
              requiredFields[documentType as AgentDocumentsEnum].includes('lastName')

            if (!isRequired) {
              return null
            }

            return (
              <Form.Item
                {...restField}
                name={[name, 'lastName']}
                label='Last Name'
                rules={[
                  ...getRequiredValidator(documentType, 'lastName', 'Last Name'),
                  {
                    validator: letterValidator,
                  },
                ]}
              >
                <TextField placeholder='Type Last Name' disabled={view || !isRequired} />
              </Form.Item>
            )
          }}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const documentType = getFieldValue(['documents', name, 'type'])

            const isRequired =
              documentType &&
              requiredFields[documentType as AgentDocumentsEnum].includes('fatherName')

            if (!isRequired) {
              return null
            }

            return (
              <Form.Item
                {...restField}
                name={[name, 'fatherName']}
                label='Father Name'
                rules={[
                  ...getRequiredValidator(documentType, 'fatherName', 'Father Name'),
                  {
                    validator: letterValidator,
                  },
                ]}
              >
                <TextField placeholder='Type Father Name' disabled={view || !isRequired} />
              </Form.Item>
            )
          }}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const documentType = getFieldValue(['documents', name, 'type'])

            const isRequired =
              documentType &&
              requiredFields[documentType as AgentDocumentsEnum].includes('companyTitle')

            if (!isRequired) {
              return null
            }

            return (
              <Form.Item
                {...restField}
                name={[name, 'companyTitle']}
                label='Company Title'
                rules={[...getRequiredValidator(documentType, 'companyTitle', 'Company Title')]}
              >
                <TextField placeholder='Type Company Title' disabled={view || !isRequired} />
              </Form.Item>
            )
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const documentType = getFieldValue(['documents', name, 'type'])

            const isRequired =
              documentType &&
              requiredFields[documentType as AgentDocumentsEnum].includes('issuedAt')

            if (!isRequired) {
              return null
            }

            return (
              <Form.Item
                {...restField}
                name={[name, 'issuedAt']}
                label='Issue Date'
                rules={[...getRequiredValidator(documentType, 'issuedAt', 'Issue Date')]}
              >
                <DatePicker
                  onChange={onChangeIssueDate}
                  // format='MMMM DD, YYYY'
                  disabled={view || !isRequired}
                  placeholder='Issue Date'
                  className={styles.dataPicker}
                  suffixIcon={
                    <div className={styles.wrapperDataPickerIcon}>
                      <DatePickerIcon />
                    </div>
                  }
                />
              </Form.Item>
            )
          }}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const documentType = getFieldValue(['documents', name, 'type'])

            const isRequired =
              documentType &&
              requiredFields[documentType as AgentDocumentsEnum].includes('expireAt')

            const issuedAt = getFieldValue(['documents', name, 'issuedAt'])

            if (!isRequired) {
              return null
            }

            return (
              <Form.Item
                {...restField}
                name={[name, 'expireAt']}
                label='Expiry Date'
                rules={[...getRequiredValidator(documentType, 'expireAt', 'Expiry Date')]}
              >
                <DatePicker
                  {...(issuedAt && {
                    disabledDate: (current) =>
                      profile?.calendar === CALENDAR_TYPE_ENUM.SHAMSI
                        ? dayjs(current.toISOString()).calendar('jalali').add(-1, 'day') <= issuedAt
                        : moment(current).add(-1, 'days') <= issuedAt,
                  })}
                  // format='MMMM DD, YYYY'
                  disabled={view || !isRequired || !issuedAt}
                  placeholder='Expiry Date'
                  className={styles.dataPicker}
                  suffixIcon={
                    <div className={styles.wrapperDataPickerIcon}>
                      <DatePickerIcon />
                    </div>
                  }
                />
              </Form.Item>
            )
          }}
        </Form.Item>
      </div>

      {view ? (
        <div className={styles.fileContainer}>
          {viewFiles?.map(({ attachment }) => {
            return (
              <div key={attachment.id} className={styles.itemContainer}>
                {attachment.category === 'PHOTO' && (
                  <a href={attachment.key}>
                    <img src={attachment.key} alt={attachment.bucketOptions.file} />
                  </a>
                )}
                {attachment.category === 'TEXT' && <PDFViewer src={attachment.key} />}
              </div>
            )
          })}
        </div>
      ) : (
        <Form.Item
          {...restField}
          name={[name, 'attachments']}
          label='Files'
          className={styles.dragContainerItem}
        >
          <Dragger
            {...uploadProps}
            listType='picture'
            fileList={uploadedFile}
            disabled={view}
            className={styles.dragContainer}
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>Click or drag file to this area to upload</p>
            <p className='ant-upload-hint'>png, jpg, jpeg, pdf</p>
          </Dragger>
        </Form.Item>
      )}

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const id = getFieldValue(['documents', name, 'id'])
          const verificationStatus = getFieldValue(['documents', name, 'verificationStatus'])

          if (view && verificationStatus === AgentDocumentStatusEnum.IN_PROGRESS) {
            return (
              <div className={styles.verifyBtnContainer}>
                <Button type='primary' size='middle' onClick={() => onPressVerify(id)}>
                  Verify
                </Button>
              </div>
            )
          }
        }}
      </Form.Item>
    </div>
  )
}

export default memo(IdVerificationForm)
