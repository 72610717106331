import React, { useCallback, useContext } from 'react'
import { Spin } from 'antd'
import { InternalChatUser } from './Components/InternalChatUser'
import { InfiniteScrollComponent } from '../../../../shared/components/infiniteScoll/InfiniteScroll'
import { checkIsOnlineStatus, InternalChatContext } from '../InternalChatModal'
import { RightModalContext } from '../../../Modals'
import { IInternalChat } from '../../models'
import { modalViewTypeEnum } from '../../core/models'

interface IProps {
  totalCount?: number
  loadMoreData: () => void
  chatsList: Array<IInternalChat>
}

export const InternalChatUsersList = ({ totalCount = 0, loadMoreData, chatsList }: IProps) => {
  const { setProps } = useContext(RightModalContext)
  const { onSelectChat } = useContext(InternalChatContext)

  const onPressChat = useCallback((id: number) => {
    /** Set the id so that when you click back in the details window, the navigation will work on the chat you need. */
    setProps((prev) => ({ ...prev, id }))
    onSelectChat(modalViewTypeEnum.messaging, id)
    // eslint-disable-next-line
  }, [])

  return (
    <InfiniteScrollComponent
      loadMoreData={loadMoreData}
      dataLength={chatsList.length}
      totalCount={totalCount}
      containerStyles={{ paddingTop: 24, paddingLeft: 16, width: '100%' }}
    >
      <Spin spinning={false}>
        {chatsList.length
          ? chatsList.map((user) => (
              <InternalChatUser
                key={user.id}
                user={user}
                onPressChat={onPressChat}
                isOnline={checkIsOnlineStatus(user.lastOnlineAt)}
              />
            ))
          : null}
      </Spin>
    </InfiniteScrollComponent>
  )
}
