import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { IUser } from '../../../models/IUser'
import { ISelectedFilters } from '../../../../../shared/components/filter/Filter'
import { IPageConf } from '../../../../../shared/components/infiniteScoll/InfiniteScroll'
import {
  useGetUsersFiltersListQuery,
  useGetUsersListQuery,
} from '../../../core/http/UsersManagementApi'
import { addUser, deleteUserById } from '../../../core/store/UsersManagerSlice'
import { useAppDispatch, useAppSelector } from '../../../../../redux'
import { RightModalContext } from '../../../../Modals'
import { SETTINGS_ENUM } from '../../../../../routes/settings'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { ITableConf } from '../../../../../shared/models/ITableConf'

export default () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { onClose } = useContext(RightModalContext)

  const [usersList, setUsersList] = useState<IUser[]>([])
  const [filterValues, setFilterValues] = useState<ISelectedFilters>({})
  const [isChangeSearch, setIsChangeSearch] = useState(false)
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 10,
    search: '',
  })

  const selectedUsers: IUser[] = useAppSelector((state) => state.usersManagementSlice.usersList)

  const { data: filterFields } = useGetUsersFiltersListQuery()
  const { data: users } = useGetUsersListQuery({
    ...tableConf,
    ...filterValues,
  })

  const selectedUsersIds = useMemo(() => {
    return selectedUsers.map((user: IUser) => user.id)
  }, [selectedUsers])

  const loadMoreData = useCallback(() => {
    setTableConf((pre: ITableConf) => ({ ...pre, page: pre.page! + 1 }))
  }, [])

  const onDeleteUser = useCallback((id: number) => {
    dispatch(deleteUserById(id))
  }, [])

  const onAddUser = useCallback((user: IUser) => {
    dispatch(addUser(user))
  }, [])

  const onPressCreateNewUser = useCallback(() => {
    onClose()
    navigate(SETTINGS_ENUM.USERS_MANAGEMENT_USER_NEW)
  }, [])

  const setActiveFilters = useCallback((res: ISelectedFilters) => {
    setIsChangeSearch(true)
    setFilterValues(res)
  }, [])

  useEffect(() => {
    if (isEmpty(users)) return

    if (isChangeSearch && (tableConf.search || Object.keys(filterValues).length !== 0)) {
      setUsersList(users!.items)
      setIsChangeSearch(false)
    } else {
      return setUsersList([...usersList, ...users!.items])
    }
  }, [users])

  useEffect(() => {
    setTableConf((prev) => ({ ...prev, page: 1 }))
  }, [filterValues])

  return {
    usersList,
    selectedUsersIds,
    filterValues,
    filterFields,
    isChangeSearch,
    loadMoreData,
    onDeleteUser,
    onAddUser,
    onPressCreateNewUser,
    setTableConf,
    setIsChangeSearch,
    setActiveFilters,
    totalCount: users?.totalCount,
  }
}
