import { DashboardTableWrapper } from '../DashboardTableWrapper'
import styles from '../../../../../Finance/pages/Overview/styles.module.scss'
import { PieChart } from '../../../../../../shared/components/pieChart/pieChart'
import React, { useMemo } from 'react'
import { ISalesByChannels } from '../../../../../Reports/models/IExternalReport'
import { NotFoundPage } from '../../../../../NotFoundPage'
import {
  PIE_CHART_COLORS,
  PIE_CHART_COLORS_WITH_TWO_ITEMS,
} from '../../../../../../helpers/contants'
import { RangePicker } from '../../../../../../shared/components/datePicker'
import { DatePickerIcon } from '../../../../../../assets/svg'
import { Moment } from 'moment'
import { omit } from 'lodash'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'

interface IProps {
  widgetData: ISalesByChannels[]
  onWidgetDateChange?: (value: Moment[] | null) => void
}

export const SalesChannelsPieChart = ({ widgetData, onWidgetDateChange }: IProps) => {
  const mappedData = useMemo(
    () =>
      widgetData?.map((channel) => ({
        name: channel?.channel,
        value: Math.abs(channel?.count),
        label: channel?.count,
        info: omit(channel, 'channel'),
      })),
    [widgetData]
  )

  const colors = useMemo(
    () => (mappedData?.length <= 2 ? PIE_CHART_COLORS_WITH_TWO_ITEMS : PIE_CHART_COLORS),
    [mappedData?.length]
  )

  return (
    <DashboardTableWrapper
      title='Sales Channels'
      filters={
        <RangePicker
          className={styles.delegationDatePicker}
          placeholder={['Date range', '']}
          onChange={(val) => onWidgetDateChange && onWidgetDateChange(val as Moment[])}
          suffixIcon={
            <div>
              <DatePickerIcon color='#0193EB' />
            </div>
          }
        />
      }
    >
      {widgetData?.length ? (
        <div className={styles.pieChartTypeDataContainer}>
          <div>
            {mappedData?.map((cData, i) => (
              <RecursivelyReplaceCharacters key={cData.name}>
                <div className={styles.chartDataTitle}>
                  <div
                    className={styles.chartDataDot}
                    style={{
                      backgroundColor: colors[i],
                    }}
                  />
                  <div className={styles.chartDataName}>{cData.name}</div>
                </div>
              </RecursivelyReplaceCharacters>
            ))}
          </div>
          <div>
            <PieChart
              data={mappedData}
              colors={colors}
              textCenterChart={mappedData.reduce((a, v) => a + v.value, 0)}
            />
          </div>
        </div>
      ) : (
        <NotFoundPage />
      )}
    </DashboardTableWrapper>
  )
}
