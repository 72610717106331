import styles from '../BankAccountView/styles.module.scss'
import { Form, Row, Space, Spin, UploadFile } from 'antd'
import { useParams } from 'react-router-dom'
import { DetailInfo } from './DetailInfo'
import { UploaderPhotos2 } from '../../../../../shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import {
  useCreateBankMutation,
  useGetBankByIdQuery,
  useUpdateBankBranchMutation,
  useUpdateBankByIdMutation,
} from '../../../core/http/BankApi'
import { useCallback, useEffect, useState } from 'react'
import { RcFile } from 'antd/lib/upload'
import { useAppDispatch } from '../../../../../redux'
import { setBreadcrumb } from '../../../../app/core/BreadcrumbsSlice'
import { isEmpty, omit } from 'lodash'
import { BankBranches } from './BankBranches'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import { CALENDAR_TYPE_ENUM } from '../../../../Settings/models/IUser'
import dayjs from 'dayjs'
import moment from 'moment/moment'
import classNames from 'classnames'
import { Button } from '../../../../../shared/components/button/Button'
import { FINANCE_ENUM } from '../../../../../routes/finance'
import { useNavigate } from 'react-router'
import { IBank, IBankBranch } from '../../../models/IBank'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../shared/api/errorHandler'

export const BankView = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const [picture, setPicture] = useState<RcFile>()
  const { id, mode } = useParams<{ id: string; mode: 'view' | 'edit' }>()

  const { data, isFetching } = useGetBankByIdQuery(id as string, { skip: !id })
  const { data: profile, isFetching: isFetchingProfile } = useGetProfileQuery()

  const [updBank, updBankResp] = useUpdateBankByIdMutation()
  const [crBank, crBankResp] = useCreateBankMutation()
  const [updBankBr, updBankBrResp] = useUpdateBankBranchMutation()

  const afterSuccess = () => {
    navigate(`${FINANCE_ENUM.BANK_SETTINGS}/banks/view/${crBankResp.data?.id ?? id}`)
  }

  useNotification(NOTIFICATION_TYPES.success, crBankResp.isSuccess, null, afterSuccess)
  useNotification(NOTIFICATION_TYPES.success, updBankBrResp.isSuccess, null, afterSuccess)

  useNotification(NOTIFICATION_TYPES.error, crBankResp.isError, crBankResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, updBankResp.isError, updBankResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, updBankBrResp.isError, updBankBrResp.error as ErrorNode)

  const getMomentTime = useCallback((val: string[], calendar?: CALENDAR_TYPE_ENUM) => {
    if (calendar === CALENDAR_TYPE_ENUM.SHAMSI) {
      return dayjs().calendar('jalali').set('hour', +val[0]).set('minute', +val[1])
    }

    return moment().set({
      hour: +val[0],
      minute: +val[1],
    })
  }, [])

  const setDefaultValues = useCallback(() => {
    form.setFieldsValue({
      ...data,
      bankBranches: data?.bankBranches
        ?.map((branch) => {
          const openH = branch.openingHours.split(':')
          const closeH = branch.closingHours.split(':')

          return {
            ...branch,
            openingHours: getMomentTime(openH, profile!.calendar),
            closingHours: getMomentTime(closeH, profile!.calendar),
          }
        })
        .sort((a, b) => a.id - b.id),
    })
  }, [data, form, getMomentTime, profile])

  const onFinish = (values: IBank) => {
    const body = new FormData()

    body.append('bank', JSON.stringify(omit(values, 'bankBranches')))

    const toDelete = (data?.bankBranches || [])
      .filter(
        (dataBranches) =>
          !values.bankBranches?.some((valueBranch) => valueBranch.id === dataBranches.id)
      )
      .map((br) => ({ branchId: br.id }))

    const toCreate = values.bankBranches
      ?.filter((branch) => !branch.id)
      .map((branch) => ({
        ...branch,
        branchId: branch.id,
        closingHours: moment(branch.closingHours).format('HH:mm'),
        openingHours: moment(branch.openingHours).format('HH:mm'),
      })) as Array<IBankBranch>

    const toUpdate = values.bankBranches
      ?.filter((branch) => branch.id)
      .map((branch) => ({
        ...branch,
        branchId: branch.id,
        closingHours: moment(branch.closingHours).format('HH:mm'),
        openingHours: moment(branch.openingHours).format('HH:mm'),
      })) as Array<IBankBranch>

    if (picture) {
      body.append('logo', picture)
    }

    if (isEditMode) {
      updBank({ id: id as string, body })
      updBankBr({ bankId: id as string, body: { toCreate, toUpdate, toDelete } })

      return
    }

    crBank(body)
  }

  const onCancelButtonClick = () => {
    if (isViewMode || (!isViewMode && !isEditMode)) {
      return navigate(FINANCE_ENUM.BANK_SETTINGS)
    }

    if (isEditMode) {
      form.resetFields()
      setDefaultValues()
      return navigate(`${FINANCE_ENUM.BANK_SETTINGS}/banks/view/${id}`)
    }
  }

  const onSubmit = () => {
    if (isViewMode) {
      return navigate(`${FINANCE_ENUM.BANK_SETTINGS}/banks/edit/${id}`)
    }

    form.submit()
  }

  useEffect(() => {
    if (isEmpty(data) || isEmpty(profile)) return

    dispatch(setBreadcrumb(data))
    setDefaultValues()
  }, [data, dispatch, profile, setDefaultValues])

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'

  const isLoading = isFetching || isFetchingProfile || updBankResp.isLoading || crBankResp.isLoading

  const submitBtnText = isEditMode ? 'Save Changes' : isViewMode ? 'Edit Bank' : 'Add Bank'

  const content = (
    <div className={styles.content}>
      <Form form={form} className={styles.columnWrapper} layout='vertical' onFinish={onFinish}>
        <div className={classNames(styles.leftColumn, styles.nestedDivs)}>
          <DetailInfo view={isViewMode} />
          {mode && <BankBranches view={isViewMode} />}
        </div>
        <UploaderPhotos2
          form={form}
          title='BANK LOGO'
          view={isViewMode}
          isBankPreview
          defaultPreviewImage={data?.logo}
          onChange={(photo: UploadFile[]) => {
            setPicture(photo[0].originFileObj as RcFile)
          }}
        />
      </Form>
    </div>
  )

  return (
    <>
      {isLoading ? <Spin>{content}</Spin> : content}
      <Row className={styles.footer} justify='end'>
        <Space size={10}>
          <Button
            color='blue'
            size='middle'
            disabled={isLoading}
            onClick={onCancelButtonClick}
            block
          >
            Cancel
          </Button>

          <Button
            htmlType='submit'
            size='middle'
            type='primary'
            block
            disabled={isLoading}
            onClick={onSubmit}
          >
            {submitBtnText}
          </Button>
        </Space>
      </Row>
    </>
  )
}
