import styles from '../../styles.module.scss'
import { Widget } from '../../../../../../../shared/components/widget'
import {
  BalanceIcon,
  MonetizationWidgetIcon,
  WidgetClockIcon,
} from '../../../../../../../assets/svg'
import React, { useEffect, useState } from 'react'
import { WIDGET_LIST_ENUM } from '../../../../../../../shared/models/WidgetListEnum'
import { useParams } from 'react-router-dom'
import { IWidget } from '../../../../../../../shared/models/IWidget'
import { widgetsSocketConnection } from '../../../../../../../shared/sockets'
import { VENDOR_WALLET_TYPE_ENUM } from '../../../../../models/IPurchaseOrder'

export const Widgets = () => {
  const { vendorId } = useParams()
  const [widgetData, setWidgetData] = useState(() => ({
    [WIDGET_LIST_ENUM.INVENTORY_PO_AVERAGE_DAILY_USAGE]: {
      value: 0,
    },
    [WIDGET_LIST_ENUM.INVENTORY_VENDOR_CURRENT_BALANCE]: {
      value: 0,
    },
    [WIDGET_LIST_ENUM.INVENTORY_PO_REMAINING_MONTH_TARGET]: {
      value: 0,
      targetValue: 0,
    },
  }))

  useEffect(() => {
    const socket = widgetsSocketConnection.getSocket()
    const messagePayload = [
      {
        widget: WIDGET_LIST_ENUM.INVENTORY_PO_AVERAGE_DAILY_USAGE,
        filters: { vendorId: Number(vendorId) },
      },
      {
        widget: WIDGET_LIST_ENUM.INVENTORY_VENDOR_CURRENT_BALANCE,
        filters: { vendorId: Number(vendorId), vendorWalletType: VENDOR_WALLET_TYPE_ENUM.TOPUP },
      },
      {
        widget: WIDGET_LIST_ENUM.INVENTORY_PO_REMAINING_MONTH_TARGET,
        filters: { vendorId: Number(vendorId) },
      },
    ]

    socket?.emit('connect-widget', {
      data: messagePayload,
    })

    return () => {
      socket?.emit('disconnect-widget', {
        data: messagePayload,
      })
    }
  }, [vendorId])

  useEffect(() => {
    const socket = widgetsSocketConnection.getSocket()
    socket?.on('widget-data', (data: IWidget) => {
      const balance = JSON.parse(data?.data?.jsonData)
      setWidgetData((prevState) => ({ ...prevState, [data.widget]: balance }))
    })
  }, [])

  return (
    <div className={styles.widgetsWrapper}>
      <Widget
        icon={<BalanceIcon />}
        title='Current Balance'
        value={widgetData[WIDGET_LIST_ENUM.INVENTORY_VENDOR_CURRENT_BALANCE].value}
        color='orange'
      />
      <Widget
        icon={<MonetizationWidgetIcon />}
        title='Average Sales'
        value={widgetData[WIDGET_LIST_ENUM.INVENTORY_PO_AVERAGE_DAILY_USAGE].value}
      />
      <Widget
        icon={<WidgetClockIcon />}
        title='Remaining Month Target'
        value={widgetData[WIDGET_LIST_ENUM.INVENTORY_PO_REMAINING_MONTH_TARGET].value}
        valueTotal={widgetData[WIDGET_LIST_ENUM.INVENTORY_PO_REMAINING_MONTH_TARGET].targetValue}
      />
    </div>
  )
}
