import React, { memo } from 'react'
import { ChatFilesIcon, ChatPicturesIcon, ChatVideosIcon } from '../../../../../../../../assets/svg'
import styles from './styles.module.scss'
import { ChatInfoAction } from '../../../../../../../../shared/components/chat/Components'
import { EXTERNAL_CHAT_FILE_TYPE_ENUM } from '../../../../../../models/IExternalChat'
import { useAppDispatch } from '../../../../../../../../redux'
import { setExternalChatInfoType } from '../../../../../../core/store/ExternalChatMessagingSlice'

const ChatroomAttachmentsInfo = () => {
  const dispatch = useAppDispatch()

  const onSelectByAttachmentType = (type: EXTERNAL_CHAT_FILE_TYPE_ENUM) => {
    dispatch(setExternalChatInfoType(type))
  }

  return (
    <div className={styles.chatRoomAttachmentsActionsContainer}>
      <ChatInfoAction
        icon={<ChatFilesIcon />}
        actionType='Files'
        onClickByType={() => onSelectByAttachmentType(EXTERNAL_CHAT_FILE_TYPE_ENUM.FILE)}
      />
      <ChatInfoAction
        icon={<ChatPicturesIcon />}
        actionType='Pictures'
        onClickByType={() => onSelectByAttachmentType(EXTERNAL_CHAT_FILE_TYPE_ENUM.PHOTO)}
      />
      <ChatInfoAction
        icon={<ChatVideosIcon />}
        actionType='Videos'
        onClickByType={() => onSelectByAttachmentType(EXTERNAL_CHAT_FILE_TYPE_ENUM.VIDEO)}
      />
    </div>
  )
}
export default memo(ChatroomAttachmentsInfo)
