import { UploadFile } from 'antd'
import { IByUser } from '../../../shared/models/IByUser'

export enum TICKET_PRIORITY_ENUM {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum TICKET_STATUS_ENUM {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
  REPLIED = 'REPLIED',
}

export enum TICKET_TYPE_ENUM {
  'sub-ticket' = 'sub-ticket',
  'main-ticket' = 'main-ticket',
  'ticket' = 'ticket',
}
export interface ITicketSubTicket {
  assigned: {
    id: number
    logo: string | null
    name: string
  }
  createdAt: string
  id: number
  priority: TICKET_PRIORITY_ENUM
  status: TICKET_PRIORITY_ENUM
  subject: string
}

export interface ITicket {
  id: number
  status: TICKET_STATUS_ENUM
  priority: TICKET_PRIORITY_ENUM
  creator: IByUser
  department: Omit<IByUser, 'logo'>
  assigned: IByUser
  subject: string
  createdAt: Date
  tags: Array<string>
  tickets: ITicketSubTicket[]
  mainTicketId?: number
  type?: TICKET_TYPE_ENUM
  children?: ITicketSubTicket[]
}

export interface ITicketForm {
  creator: IByUser
  department: Omit<IByUser, 'logo'>
  description: string | undefined
  reply: string | undefined
  priority: TICKET_PRIORITY_ENUM
  responsiblePersonUser: {
    id: number
  }
  subject: string
  tags: Array<string>
  status: TICKET_STATUS_ENUM
}

export interface ITicketBody {
  files?: UploadFile[]
  priority: string
  departmentId: number
  responsiblePersonUserId: number
  subject: string
  description: string | undefined
  tags: string[]
}

export interface ISubTicket {
  id: number
  status: string
  priority: string
  creator: IByUser
  department: Omit<IByUser, 'logo'>
  assigned: IByUser
  subject: string
  createdAt: string
  tags: Array<string>
}

export interface ILog {
  id: number
  createdByUser: IByUser
  event: string
  createdAt: Date
}

export interface IAttachment {
  id: number
  fileName: string
  category: string
}

export interface IAttachementRes {
  id: number
  attachment: Array<IAttachment>
}

export interface ITicketExtended {
  id: number
  parentTicketId: number
  createdAt: Date
  status: TICKET_STATUS_ENUM
  priority: TICKET_PRIORITY_ENUM
  type: string
  createdByUser: IByUser
  responsiblePersonUser: IByUser
  department: Omit<IByUser, 'logo'>
  subject: string
  description: string
  reply: string
  logs: Array<ILog>
  ticketAttachments: Array<IAttachementRes>
  tags: Array<string>
}

export interface ITicketDetail {
  ticket: ITicketExtended
  subTickets: ISubTicket[]
}

export interface ITicketStatusBody {
  status: string
}
