export enum GlobalSearchTransactionsNameType {
  PURCHASE_ORDER = 'Purchase order',
  PURCHASE_ORDER_SUBTRANSACTION = 'Purchase order subtransaction',
  REFUND_EMONEY = 'Refund Emoney',
  PICKUP_REQUEST = 'Pickup Request',
  PICKUP_REQUEST_SUBTRANSACTION = 'Pickup request subtransaction',
  BANK_ACCOUNT_TRANSACTION = 'Bank account transaction',
  VENDOR_TRANSACTION = 'Vendor transaction',
  TRANSFER_FUND = 'Transfer Fund',
  REVERSE_TOPUP = 'Reverse Topup',
  ADJUSTMENT = 'Adjustment',
  TOPUP = 'Topup',
  PROMOTION = 'Promotion',
  BANK_ACCOUNT = 'Bank account wallet transaction',
  BANK_WALLET = 'Bank wallet transaction',
  COLLECTION_WALLET = 'Collection wallet transaction',
  DISCOUNT_WALLET = 'Discount wallet transaction',
  PROMOTION_WALLET = 'Promotion wallet transaction',
  REWARD_WALLET = 'Reward wallet transaction',
  SYSTEM_WALLET = 'System wallet transaction',
  TRUST_WALLET = 'Trust wallet transaction',
}

export enum ESearchType {
  all = 'all',
  agent = 'agent',
  transaction = 'transaction',
  ticket = 'ticket',
}
