import { DashboardTableWrapper } from '../../../../../Dashboard/pages/Overview/Components/DashboardTableWrapper'
import { NotFoundPage } from '../../../../../NotFoundPage'
import styles from '../../AgentManagementDetailView.module.scss'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import React, { useMemo } from 'react'
import { IAgentActivity } from '../../../../models/IAgent'
import { moment, replaceCharactersWithAsterisks } from '../../../../../../helpers/utils'
import {
  PIE_CHART_COLORS,
  PIE_CHART_COLORS_WITH_TWO_ITEMS,
} from '../../../../../../helpers/contants'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

interface IProps {
  widgetData: IAgentActivity[]
  frequency: string
}

export const ActivityPerDayPieChart = ({ widgetData, frequency }: IProps) => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const mappedData = useMemo(() => {
    const result: Array<{ [key: string]: number | string }> = []
    const hours = Array(25)
      .fill(undefined)
      .map((_, index) => index)

    widgetData?.forEach((entry, index) => {
      const period = moment(entry?.period)
      let name: string

      if (frequency === 'hour') {
        name = period?.hour(hours[index])?.format('HH')
      } else {
        name = period?.format('DD MMM')
      }

      const transformedEntry: { [key: string]: number | string } = {
        name,
      }

      entry?.data?.forEach((item) => {
        transformedEntry[item.transactionCategory] = item.count
      })

      result.push(transformedEntry)
    })
    return result
  }, [widgetData, frequency])

  const barNamesList = useMemo(() => {
    if (!mappedData.length) return []
    const uniqueVendorNames = new Set<string>()

    mappedData?.forEach((entry) => {
      Object.keys(entry).forEach((key) => {
        if (key !== 'name' && typeof entry[key] === 'number') {
          uniqueVendorNames.add(key)
        }
      })
    })

    return Array.from(uniqueVendorNames)
  }, [mappedData])

  const colors = useMemo(
    () => (barNamesList?.length <= 2 ? PIE_CHART_COLORS_WITH_TWO_ITEMS : PIE_CHART_COLORS),
    [barNamesList?.length]
  )

  const shouldDisplay = useMemo(
    () => mappedData.some((activity) => Object.keys(activity).length > 1),
    [mappedData]
  )

  return (
    <div className={styles.topMargin}>
      <DashboardTableWrapper title='Activity Per Day' infoText='Activity Per Day Pie Chart'>
        {shouldDisplay ? (
          <ResponsiveContainer width='100%' height={300}>
            <BarChart
              width={900}
              height={300}
              data={mappedData}
              margin={{
                top: 30,
                right: 10,
                left: -10,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis
                tickFormatter={(value) =>
                  isPrivateMode ? replaceCharactersWithAsterisks(value) : value
                }
              />
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const time = payload[0]?.payload?.name

                    return (
                      <RecursivelyReplaceCharacters>
                        <div className={styles.tooltipSummary}>
                          <div className={styles.tooltipRow}>
                            <div className={styles.tooltipRowValue}>{time}</div>
                          </div>
                          {payload?.map((value, i) => (
                            <div
                              key={i}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gridGap: 10,
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{
                                  color: payload[i]?.color,
                                }}
                              >
                                {payload[i]?.name}:
                              </div>
                              <div>{payload[i]?.value}</div>
                            </div>
                          ))}
                        </div>
                      </RecursivelyReplaceCharacters>
                    )
                  }

                  return null
                }}
              />
              <Legend
                verticalAlign='top'
                align='left'
                wrapperStyle={{ left: 40, top: 10 }}
                iconType='circle'
              />
              {barNamesList?.map((entity, i) => (
                <Bar key={entity} dataKey={entity} stackId='a' fill={colors[i]} />
              ))}
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <NotFoundPage />
        )}
      </DashboardTableWrapper>
    </div>
  )
}
