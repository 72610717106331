import styles from './styles.module.scss'
import { useContext, useEffect, useState } from 'react'
import { RightModalContext } from '../../../../../../Modals'
import Table, { ColumnsType } from 'antd/es/table'
import { ITransactionHistory } from '../../../../../../salesNetwork/models/ITransactionHistory'
import { DateTableIcon } from '../../../../../../../assets/svg'
import {
  formatDateWithTime,
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  formatShamsiMomentToISO,
  moment,
} from '../../../../../../../helpers/utils'
import { TRANSACTION_CATEGORY_ENUM } from '../../../../../../Finance/models/ITransaction'
import { camelCase, startCase } from 'lodash'
import { IInternalReport } from '../../../../../models/IInternalReport'
import classNames from 'classnames'

import { useOnTransactionViewClick } from '../../../../../../../shared/hooks/table/useOnTransactionViewClick'
import { useGetProfileQuery } from '../../../../../../Profile/core/http/Profile'
import {
  useGetATCFiltersListQuery,
  useGetAgentTransactionsByIdQuery,
} from '../../../../../../salesNetwork/core/http/AgentManagement'
import { ITableConf, SortingOrderEnum } from '../../../../../../../shared/models/ITableConf'
import { useEmptyTable } from '../../../../../../../shared/hooks/table/useEmptyTable'
import useTable from '../../../../../../../shared/hooks/table/useTable'
import { ISelectedFilters } from '../../../../../../../shared/components/filter/Filter'
import { RIGHT_MODALS } from '../../../../../../../helpers/contants'
import { TableHeader } from './TableHeader'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import { SendTransactionReportToChat } from './SendTransactionsReportToChat'

export const AgentTransactionsModal = () => {
  const {
    onOpen,
    props: { agentId, chatId, isArchived },
  } = useContext(RightModalContext)

  const onCloseRedirect = () => {
    onOpen(RIGHT_MODALS.SALES.AGENT_TRANSACTIONS, {
      title: 'Transactions History',
      agentId,
      chatId,
      isArchived,
    })
  }
  const { data: filtersList } = useGetATCFiltersListQuery(agentId as string)

  const onTransactionViewClick = useOnTransactionViewClick(onCloseRedirect)
  const [mappedTrans, setMappedTrans] = useState<Array<ITransactionHistory>>([])
  const { queryParams, queryFields } = useGetQueryParams(
    {
      orderType: SortingOrderEnum.DESC,
      orderField: 'createdAt',
    },
    true
  )
  const { data: profile } = useGetProfileQuery()

  const createdAt_more = formatMomentDateToDayStart(
    formatShamsiMomentToISO(profile?.calendar, moment())
  ).toISOString(true)

  const createdAt_less = formatMomentDateToDayEnd(
    formatShamsiMomentToISO(profile?.calendar, moment())
  ).toISOString(true)

  const [filterValues, setFilterValues] = useState<ISelectedFilters>({
    ...queryFields,
    createdAt_more,
    createdAt_less,
  })

  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data, isFetching } = useGetAgentTransactionsByIdQuery({
    id: (filterValues.childId_like as string) || (agentId as string),
    params: { ...tableConf, ...filterValues },
  })

  const columns: ColumnsType<ITransactionHistory> = [
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      sorter: true,
      width: '25%',
      render: (name: ITransactionHistory['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          {formatDateWithTime(name, profile?.calendar)}
        </div>
      ),
    },
    {
      title: 'Transaction ID',
      dataIndex: 'id',
      sorter: true,
      width: '25%',
      render: (_, row) => (
        <div
          onClick={() => onTransactionViewClick(row.id, row.transactionCategory, row)}
          className={styles.idAction}
        >
          {row.id}
        </div>
      ),
    },
    {
      title: 'Receiver',
      dataIndex: 'receiver',
      width: '25%',
      render: (receiver: ITransactionHistory['receiver'], row) => {
        if (
          row?.transactionCategory === TRANSACTION_CATEGORY_ENUM.TOPUP ||
          row?.transactionCategory === TRANSACTION_CATEGORY_ENUM.REVERSE
        ) {
          return <span>{row?.receiver}</span>
        }
        if (Object.hasOwn(row, 'mainTransactionId')) {
          return <span>{startCase(row[receiver]?.name?.toLowerCase())}</span>
        }

        return <span>{startCase(row[receiver]?.name?.toLowerCase())}</span>
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: '10%',
      render: (status: IInternalReport['status']) => (
        <div className={classNames('statusContainer', `${status.toLowerCase()}-container`)}>
          <div className={classNames(camelCase(status.toLowerCase()), 'statusPoint')} />
          <div>{status}</div>
        </div>
      ),
    },
  ]

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<ITransactionHistory>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  useEffect(() => {
    if (data) {
      setMappedTrans(data.items)
    }
  }, [data])
  return (
    <div className={styles.layout}>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filtersList}
        agentId={agentId}
      ></TableHeader>
      {!!data?.totalCount && (
        <SendTransactionReportToChat
          agentId={agentId}
          filterValues={filterValues}
          chatId={chatId}
        />
      )}
      {profile && (
        <Table
          locale={emptyTableConf}
          className={styles.table}
          onChange={handleTableChange}
          pagination={pagination}
          scroll={{ x: 300 }}
          rowKey={(record) => record.id}
          expandable={{
            expandIcon: () => null,
          }}
          columns={columns}
          dataSource={mappedTrans}
          loading={isFetching}
        />
      )}
    </div>
  )
}
