import { useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

import { UsersTableHeader } from './Components/UsersTableHeader'
import { Toggle } from '../../../../../../../shared/components/toggle/Toggle'
import { ISelectedFilters } from '../../../../../../../shared/components/filter/Filter'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../../shared/components/popup/Popup'
import emptyAvatarImage from '../../../../../../../assets/img/Avatar.png'
import {
  DateTableIcon,
  EmailFilledIcon,
  PhoneLightBlueIcon,
  TableActionIcon,
} from '../../../../../../../assets/svg'

import { SETTINGS_ENUM } from '../../../../../../../routes/settings'
import { IUser } from '../../../../../models/IUser'
import { ITableConf, SortingOrderEnum } from '../../../../../../../shared/models/ITableConf'
import {
  useGetUsersFiltersListQuery,
  useGetUsersListQuery,
  useUpdateUserByIdMutation,
  useDeleteUserByIdMutation,
  useLazyExportUsersTableQuery,
} from '../../../../../core/http/UsersManagementApi'
import { ErrorNode, showConfirmMessage } from '../../../../../../../shared/api/errorHandler'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { useTableExport } from '../../../../../../../shared/hooks/table/useTableExport'
import { formatDateWithTime } from '../../../../../../../helpers/utils'
import { formatPhoneNumber } from '../../../../../../salesNetwork/helpers'

import styles from './styles.module.scss'
import { useEmptyTable } from '../../../../../../../shared/hooks/table/useEmptyTable'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { useLocalStorageGetByKey } from '../../../../../../../shared/hooks/useLocalStorageGetByKey'
import { RecursivelyReplaceCharacters } from '../../../../../../../shared/components/privateMode'
import { getSavedAuthData } from '../../../../../../../shared/api'
import { externalChatSocketConnection } from '../../../../../../../shared/sockets'

interface IProps {
  canEditUser: boolean
  canDeleteUser: boolean
}

export const UsersManagement = ({ canEditUser, canDeleteUser }: IProps) => {
  const navigate = useNavigate()
  const token = getSavedAuthData()?.tokens?.accessToken
  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const [exportTable] = useLazyExportUsersTableQuery()
  const { data, isFetching } = useGetUsersListQuery(
    {
      ...tableConf,
      ...filterValues,
    },
    { skip: Object.keys(tableConf).length < 4 }
  )
  const [patchUser, patchUserResp] = useUpdateUserByIdMutation()
  const [delUser, delUserResp] = useDeleteUserByIdMutation()
  const { data: filtersList } = useGetUsersFiltersListQuery()
  const { data: profile } = useGetProfileQuery()

  useTableExport({
    tableConf,
    filterValues,
    promise: exportTable,
  })

  const afterSuccess = () => {
    externalChatSocketConnection.connect(token)
  }

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, patchUserResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, delUserResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, patchUserResp.isError, patchUserResp.error as ErrorNode)
  useNotification(
    NOTIFICATION_TYPES.error,
    delUserResp.isError,
    delUserResp.error as ErrorNode,
    afterSuccess
  )

  const tableActionsPopup = useCallback(
    (id: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () => navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT}/view/${id}`),
        },
        {
          text: 'Edit',
          shouldDisplay: canEditUser,
          onClick: () => navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT}/edit/${id}`),
        },
        {
          text: 'Delete',
          withDivider: true,
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          shouldDisplay: canDeleteUser,
          onClick: () => showConfirmMessage('This user will be deleted', () => delUser(id)),
        },
      ]
    },
    [canDeleteUser, canEditUser, delUser, navigate]
  )

  const columns: ColumnsType<IUser> = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      width: '20%',
      render: (value, record) => (
        <RecursivelyReplaceCharacters>
          <Link to={`${SETTINGS_ENUM.USERS_MANAGEMENT}/view/${record.id}`} className='table-avatar'>
            <img
              className={styles.avatar}
              alt='user logo'
              src={!isPrivateMode && record?.logo ? record.logo : emptyAvatarImage}
              loading='lazy'
            />
            <div>{`${record.firstName} ${record.lastName}`}</div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Position',
      dataIndex: 'position',
      sorter: true,
      width: '10%',
      render: (position: IUser['userPosition'], record) =>
        record.userPosition && (
          <RecursivelyReplaceCharacters>{record.userPosition?.name}</RecursivelyReplaceCharacters>
        ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: true,
      width: '10%',
      render: (role: IUser['userRole'], record) =>
        record.userRole && (
          <RecursivelyReplaceCharacters>{record.userRole.name}</RecursivelyReplaceCharacters>
        ),
    },
    {
      title: 'Account Id',
      dataIndex: 'id',
      sorter: true,
      width: '10%',
      render: (id: number) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Contact information',
      dataIndex: 'mobile',
      sorter: true,
      width: '20%',
      render: (mobile: IUser['mobileNumber'], record) => (
        <>
          {record.contactChannels &&
            record.contactChannels.map((el) => (
              <RecursivelyReplaceCharacters key={el.id}>
                <div className='table-contact-info__row mobile'>
                  {['EMAIL_IDENTITY', 'EMAIL'].includes(el.type) ? (
                    <EmailFilledIcon />
                  ) : (
                    <PhoneLightBlueIcon />
                  )}
                  <span>{formatPhoneNumber(el.value)}</span>
                </div>
              </RecursivelyReplaceCharacters>
            ))}
        </>
      ),
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      width: '20%',
      sorter: true,
      render: (createdAt: IUser['createdAt']) => (
        <RecursivelyReplaceCharacters>
          <div className='table-date'>
            <DateTableIcon />
            <span>{formatDateWithTime(createdAt, profile?.calendar)}</span>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Added by',
      dataIndex: 'createdBy',
      width: '20%',
      sorter: true,
      render: (createdBy: IUser['createdBy']) =>
        createdBy && (
          <RecursivelyReplaceCharacters>{`${createdBy.firstName} ${createdBy.lastName}`}</RecursivelyReplaceCharacters>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      sorter: true,
      render: (status: IUser['status'], row) => {
        const fd = new FormData()
        return (
          <Toggle
            defaultChecked={status}
            disabled={!canEditUser}
            onChange={(status) => {
              fd.append('user', JSON.stringify({ status }))
              patchUser({ id: row.id, body: fd })
            }}
            loading={patchUserResp.isLoading}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const emptyTableConf = useEmptyTable(() => navigate('new'))
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IUser>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  return (
    <>
      <UsersTableHeader
        filterValues={filterValues}
        setActiveFilters={handleFiltersChange}
        data={filtersList}
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        pagination={pagination}
        className={styles.table}
        onChange={handleTableChange}
        loading={isFetching}
        rowKey={(row) => row?.id}
        dataSource={data?.items}
        rowSelection={{ type: 'checkbox' }}
        scroll={{ x: 1100 }}
      />
    </>
  )
}
