export const languages: Record<string, string> = {
  english: 'English',
  dari: 'Dari',
  pashto: 'Pashto',
}

export const formatLanguages = Object.values(languages).map((value, index) => ({
  id: index,
  name: value,
}))
