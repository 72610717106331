import styles from './styles.module.scss'
import { Spin } from 'antd'
import { useParams } from 'react-router'
import { useEffect, useMemo, useState } from 'react'
import { getFrequency, moment, replaceCharactersWithAsterisks } from '../../../../../helpers/utils'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import { Widgets } from './Components/Widgets'
import { VendorBlock } from '../InventoryView/Components/VendorBlock'
import { SalesBarChart } from './Components/SalesBarChart'
import { PoTable } from './Components/PoTable'
import { EPinList } from './Components/EPinList'
import { INVENTORY_CHART_TYPE_ENUM, IPurchasePlanVendor } from '../../../models/IInventory'
import { WIDGET_LIST_ENUM } from '../../../../../shared/models/WidgetListEnum'
import { setBreadcrumb } from '../../../../app/core/BreadcrumbsSlice'
import { useLocalStorageGetByKey } from '../../../../../shared/hooks/useLocalStorageGetByKey'
import { useGetVendorByIdQuery } from '../../../../Settings/core/http/VendorManagementApi'
import { useAppDispatch } from '../../../../../redux'
import { isEmpty } from 'lodash'
import { widgetsSocketConnection } from '../../../../../shared/sockets'
import { IWidget } from '../../../../../shared/models/IWidget'
import { IEpinSalesChart, IEpinSalesChartWidget } from '../../../models/IEPin'
import { ScratchCardsList } from './Components/ScratchCardsList'
import { VENDOR_WALLET_TYPE_ENUM } from '../../../models/IPurchaseOrder'

const initialState = {
  [WIDGET_LIST_ENUM.E_PIN_SALES]: {
    value: [] as IEpinSalesChart[],
    frequency: 'month',
  } as IEpinSalesChartWidget,
  [WIDGET_LIST_ENUM.INVENTORY_VENDOR_CURRENT_BALANCE]: {
    value: 0,
  },
  [WIDGET_LIST_ENUM.TOTAL_ACTIVE_E_PIN]: {
    value: {
      amount: 0,
      count: 0,
    },
  },
  [WIDGET_LIST_ENUM.TOTAL_SALES_E_PIN]: {
    value: {
      invalidAmount: 0,
      invalidCount: 0,
      usedAmount: 0,
      usedCount: 0,
    },
  },
}

export type EPinWidgetType = typeof initialState

export const PinView = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const [chartType, setChartType] = useState(INVENTORY_CHART_TYPE_ENUM.YEAR)
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const { data: vendor, isFetching: isFetchingVendor } = useGetVendorByIdQuery(id as string)
  const { data: userProfile, isFetching: isFetchingProfile } = useGetProfileQuery()

  const [widgetData, setWidgetData] = useState(initialState)

  const isLoading = isFetchingVendor || isFetchingVendor || isFetchingProfile

  const vendorProps = useMemo(
    () => ({
      type: vendor?.vendorType,
      id: vendor?.id,
      logo: vendor?.logo,
      name: vendor?.name,
    }),
    [vendor]
  )

  const unitOfTime = useMemo(() => {
    if (chartType === INVENTORY_CHART_TYPE_ENUM.MONTH) {
      return 'month'
    }

    if (chartType === INVENTORY_CHART_TYPE_ENUM.WEEK) {
      return 'week'
    }

    return 'year'
  }, [chartType])

  useEffect(() => {
    if (isEmpty(userProfile) || !id) return
    const socket = widgetsSocketConnection.getSocket()

    const from =
      unitOfTime === 'year'
        ? moment().subtract(1, 'year').add(1, 'month').toISOString(true)
        : moment().startOf(unitOfTime).toISOString(true)
    const to =
      unitOfTime === 'year'
        ? moment().endOf('month').toISOString(true)
        : moment().endOf(unitOfTime).toISOString(true)

    const messagePayload = [
      {
        widget: WIDGET_LIST_ENUM.E_PIN_SALES,
        filters: {
          vendorId: id,
          frequency: getFrequency(from, to),
          from,
          to,
        },
      },
      {
        widget: WIDGET_LIST_ENUM.INVENTORY_VENDOR_CURRENT_BALANCE,
        filters: {
          vendorId: id,
          vendorWalletType: VENDOR_WALLET_TYPE_ENUM.E_PIN,
        },
      },
      {
        widget: WIDGET_LIST_ENUM.TOTAL_ACTIVE_E_PIN,
        filters: {
          vendorId: id,
        },
      },
      {
        widget: WIDGET_LIST_ENUM.TOTAL_SALES_E_PIN,
        filters: {
          vendorId: id,
        },
      },
    ]

    socket?.emit('connect-widget', { data: messagePayload })

    return () => {
      socket?.emit('disconnect-widget', {
        data: messagePayload,
      })
    }
  }, [id, unitOfTime, userProfile])

  useEffect(() => {
    if (!userProfile) return
    const socket = widgetsSocketConnection.getSocket()
    socket?.on('widget-data', (data: IWidget) => {
      if (isEmpty(data?.data)) {
        return setWidgetData(initialState)
      }

      const salesData = JSON.parse(data?.data?.jsonData)

      setWidgetData((prevState) => ({
        ...prevState,
        [data.widget]: Object.keys(salesData).length
          ? {
              value: salesData?.value,
              ...(data['filters']['frequency'] && { frequency: data['filters']['frequency'] }),
            }
          : {},
      }))
    })
  }, [userProfile])

  useEffect(() => {
    if (isEmpty(vendor)) return
    const name = isPrivateMode ? { name: replaceCharactersWithAsterisks(vendor.name) } : vendor
    dispatch(setBreadcrumb(name))
  }, [vendor, dispatch, isPrivateMode])

  const content = (
    <div className={styles.layout}>
      <div className={styles.columnsWrapper}>
        <div className={styles.leftColumn}>
          <Widgets widgetData={widgetData} />
          <SalesBarChart
            chartType={chartType}
            setChartType={setChartType}
            data={widgetData[WIDGET_LIST_ENUM.E_PIN_SALES]}
          />
          <PoTable />
        </div>
        <div className={styles.rightColumn}>
          <VendorBlock vendor={vendorProps as IPurchasePlanVendor} />
          <ScratchCardsList />
          <EPinList />
        </div>
      </div>
    </div>
  )

  return <Spin spinning={isLoading}>{content}</Spin>
}
