export enum CALENDAR_MONTHS {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

export const SHAMSI_MONTHS = {
  '1': {
    name: 'Hamal',
    short: 'Hml',
    gregorianMonthIndex: '3',
  },
  '2': {
    name: 'Sawr',
    short: 'Swr',
    gregorianMonthIndex: '4',
  },
  '3': {
    name: 'Jawza',
    short: 'Jwz',
    gregorianMonthIndex: '5',
  },
  '4': {
    name: 'Saratan',
    short: 'Srt',
    gregorianMonthIndex: '6',
  },
  '5': {
    name: 'Asad',
    short: 'Asa',
    gregorianMonthIndex: '7',
  },
  '6': {
    name: 'Sunbola',
    short: 'Snb',
    gregorianMonthIndex: '8',
  },
  '7': {
    name: 'Mizan',
    short: 'Miz',
    gregorianMonthIndex: '9',
  },
  '8': {
    name: 'Aqrab',
    short: 'Aqr',
    gregorianMonthIndex: '10',
  },
  '9': {
    name: 'Qawz',
    short: 'Qws',
    gregorianMonthIndex: '11',
  },
  '10': {
    name: 'Jadi',
    short: 'Jad',
    gregorianMonthIndex: '0',
  },
  '11': {
    name: 'Dalw',
    short: 'Dlw',
    gregorianMonthIndex: '1',
  },
  '12': {
    name: 'Hoot',
    short: 'Hot',
    gregorianMonthIndex: '2',
  },
}
