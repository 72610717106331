import React, { useContext, useEffect } from 'react'
import styles from '../../../VendorManagement/VendorManagementProductModal/style.module.scss'
import { Form, Spin } from 'antd'
import { DetailInfo } from '../Tabs/Users/Components/DetailInfo'
import { Address } from '../Tabs/Users/Components/Address'
import { UploaderPhotos2 } from '../../../../../../shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import emptyAvatarImage from '../../../../../../assets/img/Avatar.png'
import { RightModalContext } from '../../../../../Modals'
import { useGetUserByIdQuery } from '../../../../core/http/UsersManagementApi'

const UserViewModal = () => {
  const {
    props: { id },
  } = useContext(RightModalContext)
  const [form] = Form.useForm()

  const { data, isLoading, isFetching } = useGetUserByIdQuery(id)

  useEffect(() => {
    if (data) {
      form.setFieldValue('countryId', data.country?.id)
      form.setFieldValue('provinceId', data.province?.name)
      form.setFieldValue('districtId', data.district?.name)
    }
  }, [data, form])

  const content = (
    <Form
      layout='vertical'
      form={form}
      className={styles.addProductModal}
      initialValues={{
        ...data,
      }}
    >
      <UploaderPhotos2
        form={form}
        defaultPreviewImage={data?.logo ?? emptyAvatarImage}
        view
        title=''
        isBig
      />
      <DetailInfo view />
      <Address form={form} view />
    </Form>
  )

  return <div>{isFetching || isLoading ? <Spin>{content}</Spin> : content}</div>
}

export default UserViewModal
