import styles from './styles.module.scss'

interface IRoundNumber {
  stepNumber: string | number
  stepText: string
  mB?: string
  mT?: string
  backgroundColor?: string
}

export const StepRegistration = ({
  stepNumber = '',
  stepText = '',
  mT = '0px',
  mB = '0px',
  backgroundColor = '#1976D2',
}: IRoundNumber) => {
  const margin = `${mT} 0px ${mB} 0px`
  return (
    <div style={{ margin }} className={styles.stepRegistration}>
      <div style={{ backgroundColor }} className={styles.stepRegistrationNumber}>
        {stepNumber}
      </div>
      <div className={styles.stepRegistrationText}>{stepText}</div>
    </div>
  )
}
