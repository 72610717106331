import styles from './styles.module.scss'
import { CampaignsTabs } from './Components/Tabs/index'

export const Campaigns = () => {
  return (
    <div className={styles.layout}>
      <CampaignsTabs />
    </div>
  )
}
