import { Form, Row, Space, Spin, UploadFile } from 'antd'
import { StepRegistration } from '../../../../../shared/components/stepRegistration'
import { formatOnlyDate } from '../../../../../helpers/utils'
import styles from '../styles.module.scss'
import { DetailInfo } from './DetailInfo'
import { Products } from './Products'
import classNames from 'classnames'
import { UploaderPhotos2 } from '../../../../../shared/components/uploaderPhotos/UploaderPhotos2/UploaderPhotos2'
import { RcFile } from 'antd/lib/upload'
import React, { useEffect, useState } from 'react'
import {
  useCreateMasterProductMutation,
  usePatchMasterProductLogoMutation,
} from '../../../core/http/VendorManagementApi'
import {
  ICreateMasterProduct,
  IMasterProduct,
  IMasterProductMaster,
  IMasterProductProduct,
  PRODUCTS_ACTIONS_ENUM,
} from '../../../models/IMasterProduct'
import { Button } from '../../../../../shared/components/button/Button'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../shared/api/errorHandler'
import { useNavigate } from 'react-router-dom'
import usePermissions from '../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../models/IPermissions'
import PermissionDenied from '../../../../../shared/components/permissionDenied'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import { MASTER_PRODUCT_TYPE_ENUM } from '../../../models/IProduct'
import { SETTINGS_ENUM } from '../../../../../routes/settings'
import FormItem from 'antd/es/form/FormItem'
import { VendorMasterColorBlock } from '../Components/VendorMasterColorBlock'

export const MasterProductNew = () => {
  const navigate = useNavigate()
  const [picture, setPicture] = useState<RcFile>()
  const [isImageError, setIsImageError] = useState(false)
  const [form] = Form.useForm()

  const { data: userProfile, isFetching } = useGetProfileQuery()
  const [crMastProd, crMastProdResp] = useCreateMasterProductMutation()
  const [updLogo, updLogoResp] = usePatchMasterProductLogoMutation()

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const [productsId] = getModulesIdsByNames([IPermissionsNames.Product])
  const demandPermissions = [IPermissionsActions.EDIT, IPermissionsActions.ADD]
  const [canAddProduct] = canPerformAction(productsId, demandPermissions)

  /** Notifications */
  useNotification(NOTIFICATION_TYPES.success, crMastProdResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, updLogoResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    crMastProdResp.isError,
    crMastProdResp.error as ErrorNode
  )
  useNotification(NOTIFICATION_TYPES.success, crMastProdResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    crMastProdResp.isError,
    crMastProdResp.error as ErrorNode
  )

  const onFinish = (values: IMasterProduct) => {
    const isMasterBundle = values?.type === MASTER_PRODUCT_TYPE_ENUM.MASTER_BUNDLE_TOPUP

    const body = {
      name: values?.name,
      type: values?.type,
      internalCode: values?.internalCode,
      status: !!values?.status,
      ...(values?.price ? { price: values.price } : {}),
      ...(values?.externalCode ? { externalCode: values.externalCode } : {}),
      ...(values?.color && { color: values?.color }),
    } as ICreateMasterProduct

    body.receiverPhoneNumberPrefixes = values.receiverPhoneNumberPrefixes?.map((prefix) => ({
      receiverPhoneNumberPrefixId: typeof prefix === 'string' ? prefix : prefix.id,
    })) as ICreateMasterProduct['receiverPhoneNumberPrefixes']

    if (isMasterBundle) {
      body.masterBundleItems = values?.masterBundleItems?.map((product) => {
        return {
          ...product,
          masterProductId: product?.masterProduct?.id,
          action: PRODUCTS_ACTIONS_ENUM.CREATE,
        }
      }) as IMasterProductMaster[]
    }
    if (!isMasterBundle) {
      body.products = values?.products?.map((product) => {
        if (!product.status) {
          return { ...product, status: false }
        }
        return {
          ...product,
          action: PRODUCTS_ACTIONS_ENUM.CREATE,
        }
      }) as IMasterProductProduct[]
    }

    crMastProd(body)
  }

  useEffect(() => {
    if (crMastProdResp.data && picture) {
      const fd = new FormData()
      fd.append('logo', picture)

      updLogo({ id: crMastProdResp.data.id, body: fd })
    }
  }, [crMastProdResp.data, picture, updLogo])

  useEffect(() => {
    if ((updLogoResp.isSuccess || crMastProdResp.isSuccess) && crMastProdResp.data) {
      return navigate(
        `${SETTINGS_ENUM.VENDOR_MANAGEMENT}/master-product/view/${crMastProdResp.data.id}`
      )
    }
    // eslint-disable-next-line
  }, [updLogoResp.isSuccess, crMastProdResp.isSuccess])

  useEffect(() => {
    if (userProfile) {
      form.setFieldValue('createdByUser', `${userProfile?.firstName} ${userProfile?.lastName}`)
      form.setFieldValue('createdAt', formatOnlyDate(new Date(), userProfile?.calendar))
    }
  }, [form, userProfile])

  const content = canAddProduct ? (
    <div>
      <div className={styles.layout}>
        <Form layout='vertical' form={form} onFinish={onFinish} autoComplete='off'>
          <div className={classNames(styles.columnWrapper)}>
            <div className={styles.leftColumn}>
              <StepRegistration
                stepNumber={1}
                stepText='General Information'
                mB='33px'
                mT='11.5px'
              />
              <DetailInfo view={false} form={form} />
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const type = getFieldValue('type') as MASTER_PRODUCT_TYPE_ENUM
                  const stepText =
                    type === MASTER_PRODUCT_TYPE_ENUM.MASTER_BUNDLE_TOPUP
                      ? 'Assign Bundle Topups'
                      : 'Assign Products'
                  return (
                    <>
                      <StepRegistration stepNumber={2} stepText={stepText} mB='33px' />
                      <Products view={false} form={form} />
                    </>
                  )
                }}
              </FormItem>
            </div>
            <div className={styles.pictureBlock}>
              <UploaderPhotos2
                form={form}
                title='Master Product Logo'
                view={false}
                onChange={(photo: UploadFile[]) => {
                  setPicture(photo[0].originFileObj as RcFile)
                  setIsImageError(
                    form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                  )
                }}
              />
              <VendorMasterColorBlock title='Master Product Color' />
            </div>
          </div>
        </Form>
      </div>
      <div>
        <Row justify='end' className={styles.masterProductFooter}>
          <Space size={10}>
            <Button
              color='blue'
              size='middle'
              disabled={crMastProdResp.isLoading}
              onClick={() => navigate(SETTINGS_ENUM.VENDOR_MANAGEMENT)}
            >
              Cancel
            </Button>
            <Button
              color='blue'
              htmlType='submit'
              size='middle'
              type='primary'
              disabled={isImageError || crMastProdResp.isLoading}
              onClick={form.submit}
            >
              Create Master product
            </Button>
          </Space>
        </Row>
      </div>
    </div>
  ) : (
    <PermissionDenied />
  )

  return isFetching || crMastProdResp.isLoading ? <Spin>{content}</Spin> : content
}
