import styles from './styles.module.scss'
import { SimCardTabs } from './Components/Tabs/index'

export const SimCardView = () => {
  return (
    <div className={styles.layout}>
      <SimCardTabs />
    </div>
  )
}
