import styles from '../UserListItem/styles.module.scss'
import {
  IExternalChatMessageFile,
  ILastExternalChatMessage,
} from '../../../../../../models/IExternalChat'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from '../../../../../../../../redux'
import { updateExternalChatTypingStatus } from '../../../../../../core/store/ExternalChatChatsListSlice'
import classNames from 'classnames'
import { isArabic } from '../../../../../../../../helpers/utils'

interface IProps {
  lastMessage?: Partial<ILastExternalChatMessage>
  typing?: boolean
  chatId?: number
  userId?: number
  className?: string
}

export const UserLastMessage = ({ lastMessage, typing, chatId, userId, className }: IProps) => {
  const dispatch = useAppDispatch()
  const [numDots, setNumDots] = useState(1)

  const getLastMessageFileType = useCallback((files: Array<IExternalChatMessageFile>) => {
    const getFileType = (item: IExternalChatMessageFile) => {
      if (item.fileType.toLowerCase() === 'photo') {
        return 'Photo'
      }
      if (item.fileType.toLowerCase() === 'video') {
        return 'Video'
      }
      if (item?.file?.fileFormat === '.ogg') {
        return 'Voice Message'
      }
      return item.fileType
    }

    const fileTypes = files.map((item) => getFileType(item))

    return fileTypes.length > 0 ? fileTypes[fileTypes.length - 1] : null
  }, [])

  useEffect(() => {
    if (typing) {
      const timeout = setTimeout(() => {
        dispatch(
          updateExternalChatTypingStatus({
            status: false,
            chatId: chatId as number,
            userId: userId as number,
          })
        )
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [chatId, dispatch, typing, userId])

  useEffect(() => {
    if (typing) {
      const interval = setInterval(() => {
        setNumDots((prev) => (prev < 3 ? prev + 1 : 1))
      }, 500)

      return () => clearInterval(interval)
    }
  }, [typing])

  const messageText = useMemo(() => {
    if (!lastMessage) return

    if (lastMessage?.message) {
      return lastMessage.message
    }

    if (lastMessage?.externalChatMessageFiles?.length) {
      return getLastMessageFileType(lastMessage?.externalChatMessageFiles)
    }

    return ''
  }, [getLastMessageFileType, lastMessage])

  return (
    <div
      className={classNames(styles.userLastMessage, className, {
        [styles.arabic]: messageText && isArabic(messageText),
      })}
    >
      {typing ? `Typing${'.'.repeat(numDots)}` : messageText}
    </div>
  )
}
