import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetFileInfoQuery } from '../../../../../core/http/FileManagementApi'
import SingleFile from './SingleFile'
import Folder from './Folder'
import styles from './styles.module.scss'

export const Content = () => {
  const { id } = useParams<{ id: string }>()
  const { data } = useGetFileInfoQuery(id, { skip: !id })

  if (data && data?.category !== 'FOLDER') {
    return <SingleFile data={data} />
  }

  if (data && data?.category === 'FOLDER') {
    return <Folder folderId={data.id} />
  }

  return <div className={styles.unknownPreview} />
}
