import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../redux'
import { IProfileNotification } from '../models'
import { cloneDeep } from 'lodash'

interface NotificationsState {
  notifications: Array<IProfileNotification>
  unreadCounter: number
  unreadMessagesCounter: number
}

const initialState: NotificationsState = {
  notifications: [],
  unreadCounter: 0,
  unreadMessagesCounter: 0,
}

export const notificationsSlice = createSlice({
  name: 'notificationsSlice',
  initialState,
  reducers: {
    addNotifications: (state, { payload }: PayloadAction<Array<IProfileNotification>>) => {
      state.notifications = payload
    },
    addNotificationsUnreadCounter: (state, { payload }: PayloadAction<number>) => {
      state.unreadCounter = payload
    },
    addMessagesUnreadCounter: (state, { payload }: PayloadAction<number>) => {
      state.unreadMessagesCounter = payload
    },
    addNewNotification: (state, { payload }: PayloadAction<IProfileNotification>) => {
      state.notifications.unshift(payload)
    },
    updateNotificationsReadStatus: (state, { payload }: PayloadAction<Array<number>>) => {
      state.notifications = state.notifications.map((notification) => {
        if (payload.includes(notification.id)) {
          return cloneDeep({ ...notification, isRead: true })
        }
        return notification
      })
    },
    clearNotifications(state) {
      state.notifications = []
    },
  },
})

export const selectNotificationsState = (state: RootState) => state.notificationsSlice

export const {
  addNotifications,
  addNewNotification,
  addNotificationsUnreadCounter,
  clearNotifications,
  updateNotificationsReadStatus,
  addMessagesUnreadCounter,
} = notificationsSlice.actions

export default notificationsSlice.reducer
