import FormList from 'antd/es/form/FormList'
import styles from '../styles.module.scss'
import { Form, Row } from 'antd'
import { AddIcon } from '../../../../../../assets/svg'
import classNames from 'classnames'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { Wrapper } from '../../../../components/Wrapper'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import { DefaultOptionType } from 'rc-select/lib/Select'
import { ITableResponse } from '../../../../../../shared/models/ITableResponse'
import { IBankTable } from '../../../../../Finance/models/IBank'
import React from 'react'
import { BankAccountListItem } from './BankAccountListItem'

interface IProps extends IViewMode {
  currenciesMap?: DefaultOptionType[]
  banks?: ITableResponse<IBankTable>
}

export const BankAccountsBlock = ({ view, currenciesMap, banks }: IProps) => {
  return (
    <Wrapper title='Bank Accounts'>
      <FormList name='bankAccounts'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name, key }) => (
              <BankAccountListItem
                key={key}
                name={name}
                remove={remove}
                currenciesMap={currenciesMap}
                banks={banks}
                view={view}
              />
            ))}

            {!view && (
              <Row>
                <Form.Item className={classNames('noBottomMargin', styles.addButton)}>
                  <IconButton icon={<AddIcon />} color='orange' block onClick={() => add()} />
                </Form.Item>
              </Row>
            )}
          </>
        )}
      </FormList>
    </Wrapper>
  )
}
