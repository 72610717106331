import React, { memo } from 'react'
import { Row, Space } from 'antd'
import { Button } from '../../../../../../shared/components/button/Button'

import styles from './styles.module.scss'

interface IProps {
  onPressCancelChanges: () => void
  onPressSaveChanges?: () => void
  onPressDelete?: () => void
  isLoading: boolean
  submitButtonText?: string | boolean
  cancelButtonText?: string
  deleteButtonText?: string
  isViewMode?: boolean
  submitButtonDisabled?: boolean
}

const FooterActions = ({
  onPressCancelChanges,
  onPressSaveChanges,
  isLoading,
  submitButtonText = 'Save Changes',
  cancelButtonText = 'Cancel',
  deleteButtonText = 'Delete',
  isViewMode,
  submitButtonDisabled,
  onPressDelete,
}: IProps) => {
  return (
    <div className={styles.wrapper}>
      <Row className={styles.footer} justify='space-between'>
        <div>
          {onPressDelete && (
            <Button color='error' size='middle' onClick={onPressDelete}>
              {deleteButtonText}
            </Button>
          )}
        </div>
        <Row justify='end'>
          <Space size={10}>
            {!isViewMode ? (
              <Button color='blue' size='middle' onClick={onPressCancelChanges}>
                {cancelButtonText}
              </Button>
            ) : null}
            {onPressSaveChanges && (
              <Button
                size='middle'
                type='primary'
                onClick={onPressSaveChanges}
                disabled={isLoading || submitButtonDisabled}
              >
                {submitButtonText}
              </Button>
            )}
          </Space>
        </Row>
      </Row>
    </div>
  )
}

export default memo(FooterActions)
