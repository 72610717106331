import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { IUser } from '../../../../../../../../models/IUser'
import { Link, useNavigate, useParams } from 'react-router-dom'
import emptyAvatarImage from '../../../../../../../../../../assets/img/Avatar.png'
import {
  DateTableIcon,
  PhoneLightBlueIcon,
  TableActionIcon,
} from '../../../../../../../../../../assets/svg'
import { formatDateWithTime } from '../../../../../../../../../../helpers/utils'
import { Toggle } from '../../../../../../../../../../shared/components/toggle/Toggle'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from '../../../../../../../../../../shared/components/popup/Popup'
import { useCallback, useState } from 'react'
import { TableHeader } from './TableHeader'
import { ISelectedFilters } from '../../../../../../../../../../shared/components/filter/Filter'
import {
  ITableConf,
  SortingOrderEnum,
} from '../../../../../../../../../../shared/models/ITableConf'
import styles from './styles.module.scss'
import {
  departmentsManagementApi,
  useGetDepartmentUsersTableFiltersListQuery,
  useGetUsersListByDepartmentIdQuery,
  useLazyExportDepartmentUsersTableQuery,
} from 'src/features/Settings/core/http/DepartmentsManagementApi'
import { useUpdateUserByIdMutation } from '../../../../../../../../core/http/UsersManagementApi'
import { formatPhoneNumber } from '../../../../../../../../../salesNetwork/helpers'
import { useTableExport } from '../../../../../../../../../../shared/hooks/table/useTableExport'
import { SETTINGS_ENUM } from '../../../../../../../../../../routes/settings'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import {
  ErrorNode,
  showConfirmMessage,
} from '../../../../../../../../../../shared/api/errorHandler'
import { useEmptyTable } from '../../../../../../../../../../shared/hooks/table/useEmptyTable'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../../../shared/hooks/useNotification'
import { useAppDispatch } from '../../../../../../../../../../redux'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { useLocalStorageGetByKey } from '../../../../../../../../../../shared/hooks/useLocalStorageGetByKey'
import { RecursivelyReplaceCharacters } from '../../../../../../../../../../shared/components/privateMode'
import { externalChatSocketConnection } from '../../../../../../../../../../shared/sockets'
import { getSavedAuthData } from '../../../../../../../../../../shared/api'

export const Users = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const token = getSavedAuthData()?.tokens?.accessToken

  const [patchUser, patchUserResp] = useUpdateUserByIdMutation()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const { data: profile } = useGetProfileQuery()

  const afterUserDelete = () => {
    dispatch(departmentsManagementApi.util.invalidateTags(['IUser']))
    externalChatSocketConnection.connect(token)
  }

  useNotification(NOTIFICATION_TYPES.success, patchUserResp.isSuccess, null, afterUserDelete)
  useNotification(NOTIFICATION_TYPES.error, patchUserResp.isError, patchUserResp.error as ErrorNode)

  const deleteUserFromDepartment = useCallback(
    (id: number) => {
      const reqObject = { departmentId: null }
      const body = new FormData()

      body.append('user', JSON.stringify(reqObject))

      showConfirmMessage('This user will be unassigned from the department', () =>
        patchUser({ id, body })
      )
    },
    [patchUser]
  )

  const tableActionsPopup = useCallback(
    (id: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () => navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT}/view/${id}`),
        },
        {
          text: 'Edit',
          shouldDisplay: true,
          onClick: () => navigate(`${SETTINGS_ENUM.USERS_MANAGEMENT}/edit/${id}`),
        },
        {
          text: 'Unassign',
          shouldDisplay: true,
          withDivider: true,
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          onClick: () => deleteUserFromDepartment(id),
        },
      ]
    },
    [deleteUserFromDepartment, navigate]
  )

  const columns: ColumnsType<IUser> = [
    {
      title: 'Account Id',
      dataIndex: 'id',
      sorter: true,
      width: '10%',
      render: (id: number) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Position',
      dataIndex: 'position',
      sorter: true,
      width: '10%',
      render: (position: IUser['userPosition'], record) =>
        record.userPosition && (
          <RecursivelyReplaceCharacters>{record.userPosition.name}</RecursivelyReplaceCharacters>
        ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      width: '20%',
      render: (value, record) => (
        <RecursivelyReplaceCharacters>
          <Link to={`${SETTINGS_ENUM.USERS_MANAGEMENT}/view/${record.id}`} className='table-avatar'>
            <img
              alt='user logo'
              src={!isPrivateMode && record?.logo ? record?.logo : emptyAvatarImage}
            />
            <div>{`${record.firstName} ${record.lastName}`}</div>
          </Link>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: true,
      width: '10%',
      render: (role: IUser['userRole'], row) =>
        row?.userRole && (
          <RecursivelyReplaceCharacters>{row.userRole.name}</RecursivelyReplaceCharacters>
        ),
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      sorter: true,
      width: '20%',
      render: (mobile: IUser['mobileNumber'], record) =>
        record.phoneNumber && (
          <RecursivelyReplaceCharacters>
            <div className='table-contact-info__row mobile'>
              <PhoneLightBlueIcon />
              <span>{formatPhoneNumber(record.phoneNumber)}</span>
            </div>
          </RecursivelyReplaceCharacters>
        ),
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      width: '20%',
      sorter: true,
      render: (createdAt: IUser['createdAt']) => (
        <RecursivelyReplaceCharacters>
          <div className='table-date'>
            <DateTableIcon />
            <span>{formatDateWithTime(createdAt, profile?.calendar)}</span>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Added by',
      dataIndex: 'createdBy',
      width: '15%',
      sorter: true,
      render: (createdBy: IUser['addedBy'], row) =>
        row?.addedBy && (
          <RecursivelyReplaceCharacters>{`${row?.addedBy?.firstName} ${row?.addedBy?.lastName}`}</RecursivelyReplaceCharacters>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      sorter: true,
      render: (status: IUser['status'], row) => {
        const fd = new FormData()
        return (
          <Toggle
            defaultChecked={status}
            onChange={(status) => {
              fd.append('user', JSON.stringify({ status }))
              patchUser({ id: row.id, body: fd })
            }}
            loading={patchUserResp.isLoading}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })
  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)
  const emptyTableConf = useEmptyTable()

  const [exportTable] = useLazyExportDepartmentUsersTableQuery()
  const { data, isFetching } = useGetUsersListByDepartmentIdQuery({
    ...{ ...tableConf, id: id as string },
    ...filterValues,
  })
  const { data: filtersList } = useGetDepartmentUsersTableFiltersListQuery(id as string)

  useTableExport({
    tableConf,
    filterValues,
    promise: exportTable,
    promiseParams: { id: id as string },
  })
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IUser>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  return (
    <>
      <TableHeader
        filterValues={filterValues}
        setActiveFilters={handleFiltersChange}
        data={filtersList}
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        pagination={pagination}
        className={styles.table}
        onChange={handleTableChange}
        loading={isFetching || patchUserResp.isLoading}
        rowKey={(row) => row?.id}
        dataSource={data?.items}
        rowSelection={{ type: 'checkbox' }}
        scroll={{ x: 1100 }}
      />
    </>
  )
}
