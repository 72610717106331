import { IViewMode } from '../../../../../../Finance/models/IViewMode'
import styles from '../../styles.module.scss'
import { Form, Select } from 'antd'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import { Wrapper } from '../../../../../../../shared/components/wrapper/Wrapper'
import { Toggle } from '../../../../../../../shared/components/toggle/Toggle'
import { useMemo } from 'react'
import { startCase } from 'lodash'
import { CUSTOMER_RESTRICTION_REASON_ENUM } from '../../../../../../Support/models/Restrictions'
import { requiredValidator } from '../../../../../../../helpers/validation'

export const DetailInfo = ({ view }: IViewMode) => {
  const responseTypes = useMemo(
    () =>
      (
        Object.keys(CUSTOMER_RESTRICTION_REASON_ENUM) as Array<
          keyof typeof CUSTOMER_RESTRICTION_REASON_ENUM
        >
      ).map((method) => ({
        label: `${startCase(CUSTOMER_RESTRICTION_REASON_ENUM[method].toLowerCase())} Response`,
        value: CUSTOMER_RESTRICTION_REASON_ENUM[method],
      })),
    []
  )

  const statusButton = (
    <Form.Item name='addToRestriction' valuePropName='checked'>
      <Toggle text='Add To Restriction' disabled={view} />
    </Form.Item>
  )

  return (
    <Wrapper title='Detail Info' actions={statusButton}>
      <div className={styles.dblRow}>
        <Form.Item name='createdBy' label='Created By'>
          <TextField disabled />
        </Form.Item>

        <Form.Item name='createdAt' label='Created At'>
          <TextField disabled />
        </Form.Item>
      </div>

      <div className={styles.dblRow}>
        <Form.Item
          name='description'
          label='Description'
          className='noBottomMargin'
          rules={[{ required: true, validator: requiredValidator('Description') }]}
        >
          <TextField disabled={view} placeholder='Enter Description' />
        </Form.Item>

        <Form.Item name='restrictionReason' label='Reason' className='noBottomMargin'>
          <Select disabled={view} placeholder='Select Reason' options={responseTypes} allowClear />
        </Form.Item>
      </div>
    </Wrapper>
  )
}
