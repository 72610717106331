import FormItem from 'antd/es/form/FormItem'
import { requiredValidator, positiveNumber } from 'src/helpers/validation'
import { TextField } from '../../../../../../../../shared/components/textfield/TextField'
import { Wrapper } from '../../../../../../../salesNetwork/components/Wrapper'

import styles from '../../styles.module.scss'
import React from 'react'

export const ReverseAgentAccountSummary = ({ isCreatedStatus }: { isCreatedStatus?: boolean }) => {
  return (
    <Wrapper title='Agent Account Summary'>
      <div className={styles.dblRow}>
        <FormItem name='balanceBefore' label='Agent Balance Before'>
          <TextField disabled />
        </FormItem>
        <FormItem name='balanceAfter' label='Agent Balance After'>
          <TextField disabled />
        </FormItem>
      </div>

      <div>
        <FormItem name='id' label='Reverse Sub-Transaction ID'>
          <TextField disabled />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem name='originalTopupAmount' label='Original Topup Amount'>
          <TextField disabled />
        </FormItem>

        <FormItem
          name='vendorAmount'
          label='Amount Reversed from Receiver'
          rules={[
            { required: true, validator: requiredValidator('Amount') },
            {
              validator: positiveNumber('Amount'),
            },
          ]}
        >
          <TextField disabled={!isCreatedStatus} type='number' />
        </FormItem>
      </div>

      <div>
        <FormItem name='totalDiscount' label='Total Deduction from Commission Paid'>
          <TextField disabled />
        </FormItem>
      </div>

      <div>
        <FormItem name='rewardAmount' label='Deduction from Loyalty Paid'>
          <TextField disabled />
        </FormItem>
      </div>

      <div>
        <FormItem name='promotionAmount' label='Deduction from Promotion Paid'>
          <TextField disabled />
        </FormItem>
      </div>

      <div>
        <FormItem name='penaltyAmount' label='Deduct Reverse Penalty'>
          <TextField disabled />
        </FormItem>
      </div>

      <div>
        <FormItem
          name='amount'
          label='eMoney Refund to Agent Wallet'
          className={styles.noBottomMargin}
        >
          <TextField disabled />
        </FormItem>
      </div>
    </Wrapper>
  )
}
