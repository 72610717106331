import FormItem from 'antd/es/form/FormItem'

import { Wrapper } from '../Wrapper'

// styles
import styles from './styles.module.scss'
import { TextField } from 'src/shared/components/textfield/TextField'

const AccountDetail = () => {
  return (
    <Wrapper title='Account Detail' className={styles.wrapperContainer}>
      <FormItem name='id' label='Account ID'>
        <TextField disabled placeholder='Account Id' />
      </FormItem>
      <FormItem name={['createdByUser', 'name']} label='Registered By' style={{ marginBottom: 0 }}>
        <TextField disabled placeholder='Registered By' />
      </FormItem>
      <FormItem name='createdAt'>
        <TextField disabled placeholder='Registaration Date' />
      </FormItem>
      <FormItem name={['updatedByUser', 'name']} label='Last Update By' style={{ marginBottom: 0 }}>
        <TextField disabled placeholder='Last Update By' />
      </FormItem>
      <FormItem name='updatedAt'>
        <TextField disabled placeholder='Last Updated At' />
      </FormItem>
    </Wrapper>
  )
}
export default AccountDetail
