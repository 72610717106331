import React, { useContext, useEffect, useMemo, useState } from 'react'
import { CenterModalContext, RightModalContext } from '../../../../../../../../../Modals'

import { useAppSelector } from '../../../../../../../../../../redux'
import { IUser } from '../../../../../../../../models/IUser'
import { ITableResponse } from 'src/shared/models/ITableResponse'
import { RIGHT_MODALS } from '../../../../../../../../../../helpers/contants'
import { selectUsersManagementState } from '../../../../../../../../core/store/UsersManagerSlice'
import UserModalRow from '../../../../../UserModalRow'

interface IProps {
  users?: ITableResponse<IUser>['items']
  onDeleteUser: (id: number) => void
  onAddUser: (product: IUser) => void
  defaultChecked?: boolean
  onDepartmentCreate?: boolean
}

interface IUserRow extends Omit<IProps, 'users'> {
  user: IUser
}

const UserRow = ({ user, defaultChecked, onDeleteUser, onAddUser }: IUserRow) => {
  const { onOpen } = useContext(RightModalContext)
  const centerModalContext = useContext(CenterModalContext)

  const [isSelected, setIsSelected] = useState(!!defaultChecked)

  const isDisabled = useMemo(() => {
    return !!user.userRole
  }, [user])

  const onSelectConfirm = () => {
    if (isSelected) {
      onDeleteUser(user.id)
      setIsSelected(false)
      return
    }

    onAddUser(user)
    setIsSelected(true)
    return
  }

  const onCloseRedirect = () => {
    onOpen(RIGHT_MODALS.SETTINGS.ROLES_AND_PERMISSIONS_MANAGEMENT_USERS_LIST, {
      title: 'Add Users',
    })
  }

  const onPressViewUser = () => {
    onOpen(RIGHT_MODALS.SETTINGS.USERS_MANAGEMENT_USER, {
      title: 'View Users',
      mode: 'view',
      id: user.id,
      onCloseRedirect,
    })
  }

  useEffect(() => {
    centerModalContext.onClose()
  }, [isSelected])

  return (
    <UserModalRow
      user={user}
      disabled={isDisabled}
      isSelected={isSelected}
      onPressSelect={onSelectConfirm}
      onPressViewUser={onPressViewUser}
    />
  )
}

export const RolesAndPermissionsManagementUsersList = ({
  users,
  onDeleteUser,
  onAddUser,
  defaultChecked,
  onDepartmentCreate,
}: IProps) => {
  const { usersList } = useAppSelector(selectUsersManagementState)
  return (
    <>
      {users?.map((product) => {
        const shouldBeChecked = usersList.some((savedUser) => savedUser.id === product.id)

        return (
          <UserRow
            key={product.id}
            user={product}
            defaultChecked={defaultChecked || shouldBeChecked}
            onDeleteUser={onDeleteUser}
            onAddUser={onAddUser}
            onDepartmentCreate={onDepartmentCreate}
          />
        )
      })}
    </>
  )
}
