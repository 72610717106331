import { IViewMode } from '../../../../models/IViewMode'
import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import FormList from 'antd/es/form/FormList'
import FormItem from 'antd/es/form/FormItem'
import {
  emailValidator,
  latitudeValidator,
  longitudeValidator,
  maxLengthValidator,
  requiredValidator,
} from '../../../../../../helpers/validation'
import { TextField } from '../../../../../../shared/components/textfield/TextField'
import { Col, Row, TimePicker } from 'antd'
import pickerStyles from '../../../../../../shared/components/sheduler/styles.module.scss'
import styles from '../../BankAccountView/styles.module.scss'
import { AddIcon, CloseIcon, TimePickerIcon } from '../../../../../../assets/svg'
import React from 'react'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import moment from 'moment/moment'

export const BankBranches = ({ view }: IViewMode) => {
  return (
    <Wrapper title='Bank Branches'>
      <FormList name='bankBranches'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <div key={key}>
                <Row justify='space-between' className={styles.bankBranchCounter}>
                  <Col>Bank Branch #{name + 1}</Col>
                  {!view && (
                    <Col
                      onClick={() => {
                        remove(name)
                      }}
                    >
                      <CloseIcon />
                    </Col>
                  )}
                </Row>

                <div className={styles.dblRow}>
                  <FormItem
                    name={[name, 'name']}
                    label='Branch Name'
                    rules={[
                      { required: true, validator: requiredValidator('Branch Name') },
                      { validator: maxLengthValidator(45) },
                    ]}
                  >
                    <TextField disabled={view} placeholder='Enter Branch Name' />
                  </FormItem>

                  <FormItem
                    name={[name, 'code']}
                    label='Branch Code'
                    rules={[{ required: true, validator: requiredValidator('Branch Code') }]}
                  >
                    <TextField disabled={view} placeholder='Enter Branch Code' />
                  </FormItem>
                </div>

                <div className={styles.dblRow}>
                  <FormItem
                    name={[name, 'address']}
                    label='Branch Address'
                    rules={[{ required: true, validator: requiredValidator('Branch Address') }]}
                  >
                    <TextField disabled={view} placeholder='Enter Branch Address' />
                  </FormItem>

                  <FormItem
                    name={[name, 'phoneNumber']}
                    label='Branch Phone Number'
                    rules={[
                      { required: true, validator: requiredValidator('Branch Phone Number') },
                    ]}
                  >
                    <TextField disabled={view} type='number' placeholder='Enter Phone Number' />
                  </FormItem>
                </div>

                <FormItem
                  name={[name, 'email']}
                  label='Branch Email'
                  rules={[
                    { required: true, validator: requiredValidator('Branch Email') },
                    { validator: emailValidator },
                  ]}
                >
                  <TextField disabled={view} placeholder='Enter Email' />
                </FormItem>

                <div className={styles.dblRow}>
                  <FormItem noStyle shouldUpdate>
                    {({ setFieldValue }) => {
                      return (
                        <FormItem
                          name={[name, 'openingHours']}
                          label='Opening Hours'
                          rules={[
                            {
                              required: true,
                              validator: requiredValidator('Branch Opening Hours'),
                            },
                          ]}
                        >
                          <TimePicker
                            disabled={view}
                            placeholder='Select Opening Hours'
                            className={pickerStyles.timePickerContainer}
                            showNow={false}
                            format='HH:mm'
                            suffixIcon={<TimePickerIcon />}
                            onChange={() => {
                              setFieldValue(['bankBranches', name, 'closingHours'], null)
                            }}
                          />
                        </FormItem>
                      )
                    }}
                  </FormItem>

                  <FormItem noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const openingHours = getFieldValue(['bankBranches', name, 'openingHours'])
                      return (
                        <FormItem
                          name={[name, 'closingHours']}
                          label='Closing Hours'
                          rules={[
                            {
                              required: true,
                              validator: requiredValidator('Branch Closing Hours'),
                            },
                          ]}
                        >
                          <TimePicker
                            disabled={view || !openingHours}
                            placeholder='Select Closing Hours'
                            className={pickerStyles.timePickerContainer}
                            showNow={false}
                            format='HH:mm'
                            suffixIcon={<TimePickerIcon />}
                            disabledTime={() => {
                              return {
                                disabledHours: () => {
                                  if (!openingHours) return []

                                  const openingHour = moment(openingHours).hour()
                                  const disabledHours = []
                                  for (let hour = 0; hour < openingHour; hour++) {
                                    disabledHours.push(hour)
                                  }
                                  return disabledHours
                                },
                                disabledMinutes: (selectedHour) => {
                                  if (!openingHours) return []

                                  const openingHour = moment(openingHours).hour()
                                  const openingMinute = moment(openingHours).minute()
                                  if (selectedHour === openingHour) {
                                    const disabledMinutes = []
                                    for (let minute = 0; minute <= openingMinute; minute++) {
                                      disabledMinutes.push(minute)
                                    }
                                    return disabledMinutes
                                  }

                                  return []
                                },
                              }
                            }}
                          />
                        </FormItem>
                      )
                    }}
                  </FormItem>
                </div>

                <div className={styles.dblRow}>
                  <FormItem
                    name={[name, 'lat']}
                    label='Branch Latitude'
                    rules={[
                      { required: true, validator: requiredValidator('Branch Latitude') },
                      { validator: latitudeValidator },
                    ]}
                  >
                    <TextField disabled={view} placeholder='Enter Branch Latitude' />
                  </FormItem>

                  <FormItem
                    name={[name, 'long']}
                    label='Branch Longitude'
                    rules={[
                      { required: true, validator: requiredValidator('Branch Longitude') },
                      { validator: longitudeValidator },
                    ]}
                  >
                    <TextField disabled={view} placeholder='Enter Longitude' />
                  </FormItem>
                </div>
              </div>
            ))}

            <div className={styles.addBtn}>
              <IconButton
                size='large'
                block
                color='orange'
                icon={<AddIcon />}
                type='default'
                disabled={view}
                onClick={() => add()}
              >
                add more
              </IconButton>
            </div>
          </>
        )}
      </FormList>
    </Wrapper>
  )
}
