import styles from '../../../styles.module.scss'
import classNames from 'classnames'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, TableActionIcon } from '../../../../../../../assets/svg'
import { formatOnlyDate, formatToShamsiDate } from '../../../../../../../helpers/utils'
import { useContext } from 'react'
import { ITableConf } from 'src/shared/models/ITableConf'
import { useRemoveHolidayMutation } from 'src/features/Settings/core/http/EventsHolidaysApi'
import { Emitter, EmitterEvents } from 'src/helpers/eventEmitter'
import { CenterModalContext } from 'src/features/Modals'
import { CENTER_MODALS } from 'src/helpers/contants'
import { EEventHoliday, IEventHoliday } from 'src/features/Settings/models/IEventsHolidays'
import { notification, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useEmptyTable } from 'src/shared/hooks/table/useEmptyTable'
import { useTablePagination } from 'src/shared/hooks/table/useTablePagination'
import { useOnTableChange } from 'src/shared/hooks/table/useOnTableChange'
import { Button } from 'src/shared/components/button/Button'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { ErrorNode, MESSAGE_KEY } from 'src/shared/api/errorHandler'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from 'src/shared/components/popup/Popup'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { CALENDAR_TYPE_ENUM } from '../../../../../models/IUser'
import dayjs from 'dayjs'
import moment from 'moment'

interface IEvent {
  totalCount: number | undefined
  items: IEventHoliday[] | undefined
  isFetching: boolean | undefined
  setTableConf: React.Dispatch<React.SetStateAction<ITableConf>>
}

export const PublicHolidaysBlock = ({
  totalCount = 0,
  items = [],
  isFetching,
  setTableConf,
}: IEvent) => {
  const { onOpen, onClose } = useContext(CenterModalContext)
  const [deleteHoliday, dHoliday] = useRemoveHolidayMutation()
  const { data: profile } = useGetProfileQuery()

  useNotification(NOTIFICATION_TYPES.success, dHoliday.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, dHoliday.isError, dHoliday.error as ErrorNode)

  const openNotification = (id: string) => {
    const btn = (
      <Button
        type='primary'
        color='blue'
        onClick={() => {
          deleteHoliday(id)
          notification.close(MESSAGE_KEY)
        }}
      >
        Confirm
      </Button>
    )
    notification.open({
      type: 'warning',
      message: 'This Holiday will be deleted',
      key: MESSAGE_KEY,
      btn,
    })
  }

  const tableActionsPopup = (id: string): IPopupListItems[] => {
    return [
      {
        text: 'Edit',
        shouldDisplay: true,
        onClick: () =>
          onOpen(CENTER_MODALS.EVENTS_MODAL, {
            title: 'Edit Holiday',
            type: EEventHoliday.holidays,
            onCloseAction: onResetForm,
            id,
            footer: (
              <Button
                color='orange'
                size='middle'
                type='primary'
                htmlType='submit'
                onClick={onSubmit}
              >
                Confirm
              </Button>
            ),
          }),
      },
      {
        text: 'Delete',
        withDivider: true,
        shouldDisplay: true,
        color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        onClick: () => openNotification(id),
      },
    ]
  }

  const onSubmit = () => {
    Emitter.emit(EmitterEvents.EVENT_CREATE)
  }

  const onResetForm = () => {
    onClose()
    Emitter.emit(EmitterEvents.EVENT_RESET)
  }

  const onEventClick = () => {
    onOpen(CENTER_MODALS.EVENTS_MODAL, {
      title: 'Create Holiday',
      type: EEventHoliday.holidays,
      onCloseAction: onResetForm,
      footer: (
        <Button color='orange' size='middle' type='primary' htmlType='submit' onClick={onSubmit}>
          Confirm
        </Button>
      ),
    })
  }

  const columns: ColumnsType<IEventHoliday> = [
    {
      title: 'Name',
      dataIndex: 'title',
      width: '33%',
      render: (title: IEventHoliday['title']) => (
        // <Link to={`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${row.id}`} className='table-avatar'>
        <div>{title}</div>
        // </Link>
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'start',
      width: '33%',
      render: (start) => <span>{formatOnlyDate(start, profile?.calendar)}</span>,
    },
    {
      title: 'End Date',
      dataIndex: 'end',
      width: '33%',
      render: (date) => {
        if (profile?.calendar === CALENDAR_TYPE_ENUM.SHAMSI && date) {
          const end = dayjs(date).utc().calendar('jalali').format('DD, YYYY')
          const { shamsiMonth } = formatToShamsiDate(end)
          return `${shamsiMonth} ${end}`
        }

        return moment(date).utc().format('MMMM DD, YYYY')
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const emptyTableConf = useEmptyTable()
  const paginationConfig = useTablePagination(totalCount, false, 5)
  const onTableParamsChange = useOnTableChange<IEventHoliday>(setTableConf)

  return (
    <div>
      <div className={styles.eventsBlock}>
        <div className={styles.eventsBlockHeader}>
          <div className={styles.eventsBlockTitle}>
            <span className={classNames(styles.titleDot, styles.orangeDot)} />
            <div>Public Holidays</div>
          </div>
          <div onClick={onEventClick}>
            <IconButton icon={<AddIcon />} color='orange' />
          </div>
        </div>
        <div className={classNames(styles.logsTable, styles.logsHolidays)}>
          <Table
            columns={columns}
            locale={emptyTableConf}
            onChange={onTableParamsChange}
            // className={styles.vendorBalanceTable}
            loading={isFetching}
            dataSource={items}
            pagination={paginationConfig}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    </div>
  )
}
