import { cloneDeep } from 'lodash'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../redux'
import { IInternalChat, IInternalChatMessage, IInternalChatMessageReadings } from '../../models'
import { TRANSACTION_CATEGORY_ENUM } from '../../../Finance/models/ITransaction'
import { modalViewTypeEnum } from '../models'

const findChatIndex = (list: Array<IInternalChat>, fromUserId: number) => {
  return list.findIndex((c) => {
    return c.id === fromUserId
  })
}

interface ChatsState {
  openedChatId: number | null
  openedTransaction: { id: number; transaction: TRANSACTION_CATEGORY_ENUM } | null
  openedChat: IInternalChat | null
  chats: Array<IInternalChat>
  currentModalView: modalViewTypeEnum
}

const initialState: ChatsState = {
  currentModalView: modalViewTypeEnum.chats,
  openedTransaction: null,
  openedChatId: null,
  openedChat: null,
  chats: [],
}

export const internalChatChatsSlice = createSlice({
  name: 'internalChatChatsSlice',
  initialState,
  reducers: {
    setCurrentModalView: (state, { payload }: PayloadAction<modalViewTypeEnum>) => {
      state.currentModalView = payload
    },
    addChats: (state, { payload }: PayloadAction<Array<IInternalChat>>) => {
      state.chats = payload
    },

    updateChatTypingStatus: (
      state,
      { payload }: PayloadAction<{ status: boolean; fromUserId: number }>
    ) => {
      const currentChatIndex = findChatIndex(state.chats, payload.fromUserId)
      if (currentChatIndex > -1) {
        state.chats[currentChatIndex] = { ...state.chats[currentChatIndex], typing: payload.status }
      }
    },

    updateChatLastMessage: (
      state,
      { payload }: PayloadAction<{ message: IInternalChatMessage }>
    ) => {
      const isMy = state.openedChatId && state.openedChatId !== payload.message.fromUserId
      const currentChatIndex = findChatIndex(
        state.chats,
        isMy ? payload.message.toUserId : payload.message.fromUserId
      )
      if (currentChatIndex > -1) {
        state.chats[currentChatIndex] = {
          ...state.chats[currentChatIndex],
          lastInternalChatMessage: cloneDeep(payload.message),
        }
      }
    },

    updateChatUnreadCounter: (
      state,
      { payload }: PayloadAction<{ fromUserId: number; counter?: number }>
    ) => {
      const currentChatIndex = findChatIndex(state.chats, payload.fromUserId)
      if (currentChatIndex > -1) {
        state.chats[currentChatIndex] = {
          ...state.chats[currentChatIndex],
          unreadedMessagesCount:
            payload.counter || payload.counter === 0
              ? payload.counter
              : ++state.chats[currentChatIndex].unreadedMessagesCount,
        }
      }
    },

    updateChatMessageLastReadingDate: (
      state,
      { payload }: PayloadAction<{ data: IInternalChatMessageReadings }>
    ) => {
      const currentChatIndex = findChatIndex(state.chats, payload.data.createdByUserId as number)
      state.chats[currentChatIndex].recievedInternalChatMessageReading = { ...payload.data }
    },

    updateOnlineStatus: (
      state,
      { payload }: PayloadAction<{ id: number; lastOnlineAt: string }>
    ) => {
      const currentChatIndex = findChatIndex(state.chats, payload.id as number)

      if (state.chats[currentChatIndex]) {
        state.chats[currentChatIndex].lastOnlineAt = payload.lastOnlineAt
      }
    },

    clearChats(state) {
      state.chats = []
    },

    setOpenedChatId: (state, { payload }: PayloadAction<number | null>) => {
      state.openedChatId = payload
    },

    addChatOnOpen: (state, { payload }: PayloadAction<Partial<IInternalChat> | null>) => {
      state.openedChat = payload as IInternalChat
    },

    setOpenedTransaction: (
      state,
      { payload }: PayloadAction<{ id: number; transaction: TRANSACTION_CATEGORY_ENUM } | null>
    ) => {
      state.openedTransaction = payload
    },
  },
})

export const selectInternalChatChatsState = (state: RootState) => state.internalChatChatsSlice

export const {
  addChats,
  updateChatTypingStatus,
  updateChatLastMessage,
  updateChatUnreadCounter,
  updateChatMessageLastReadingDate,
  updateOnlineStatus,
  clearChats,
  addChatOnOpen,
  setCurrentModalView,
  setOpenedChatId,
  setOpenedTransaction,
} = internalChatChatsSlice.actions

export default internalChatChatsSlice.reducer
