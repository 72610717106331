import { Wrapper } from '../../../../../../shared/components/wrapper/Wrapper'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { EditIcon, SearchIcon } from '../../../../../../assets/svg'
import { useContext } from 'react'
import { RightModalContext } from '../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import { IAgentAllGetResponse } from '../../../../../salesNetwork/models/IAgent'
import styles from '../../styles.module.scss'
import emptyAvatar from 'src/assets/img/Avatar.png'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import classNames from 'classnames'
import FormItem from 'antd/es/form/FormItem'
import { requiredValidator } from '../../../../../../helpers/validation'

interface IProps extends IViewMode {
  onAgentSelect: (id: string) => void
  agent: IAgentAllGetResponse
  agentName?: string
}

export const AgentBlock = ({ onAgentSelect, agent, view, agentName }: IProps) => {
  const { onOpen, onClose } = useContext(RightModalContext)
  const onSelectAgentClick = () => {
    return onOpen(RIGHT_MODALS.SUPPORT.CHANGE_MPIN, {
      title: 'Select Agent',
      onSelect: (id: string) => {
        onAgentSelect(id)
        onClose()
      },
      selectedAgent: agent.id,
    })
  }

  return (
    <Wrapper title='Agent'>
      {!!Object.keys(agent).length ? (
        <div>
          <img src={agent?.avatar ?? emptyAvatar} alt='agent' className={styles.agentImage} />
          <div className={styles.agentName}>{agentName}</div>
          <div className={classNames(styles.agentId, { [styles.mb]: view })}>ID{agent?.id}</div>
          {!view && (
            <IconButton
              color='orange'
              icon={<EditIcon />}
              onClick={onSelectAgentClick}
              disabled={view}
              block
            >
              Change
            </IconButton>
          )}
        </div>
      ) : (
        <FormItem
          name='agentId'
          rules={[{ required: true, validator: requiredValidator('Agent') }]}
          className='noBottomMargin'
        >
          <IconButton
            color='orange'
            icon={<SearchIcon />}
            onClick={onSelectAgentClick}
            disabled={view}
            block
          >
            Select Agent account
          </IconButton>
        </FormItem>
      )}
    </Wrapper>
  )
}
