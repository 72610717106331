import { DashboardTableWrapper } from '../DashboardTableWrapper'
import styles from '../../../../../Finance/pages/Overview/styles.module.scss'
import { PieChart } from '../../../../../../shared/components/pieChart/pieChart'
import React, { useMemo } from 'react'
import {
  PIE_CHART_COLORS,
  PIE_CHART_COLORS_WITH_TWO_ITEMS,
} from '../../../../../../helpers/contants'
import { RangePicker } from '../../../../../../shared/components/datePicker'
import { Moment } from 'moment/moment'
import { IFailedTransactionsByMasterProduct } from '../../../../../Settings/models/IMasterProduct'
import { NotFoundPage } from '../../../../../NotFoundPage'
import { omit } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { REPORT_ENUM } from '../../../../../../routes/reports'
import { TRANSACTION_STATUS_ENUM } from '../../../../../Finance/models/ITransaction'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'

interface IProps {
  onWidgetDateChange?: (value: Moment[] | null) => void
  widgetData: IFailedTransactionsByMasterProduct[]
}

export const FailedTransactionsPieChart = ({ onWidgetDateChange, widgetData }: IProps) => {
  const navigate = useNavigate()
  const mappedData = useMemo(
    () =>
      widgetData?.map((trans) => ({
        name: trans?.masterProduct?.name,
        value: trans?.count,
        label: trans?.count,
        color: trans?.masterProduct?.color,
        info: omit(trans, 'masterProduct'),
      })),
    [widgetData]
  )

  const colors = useMemo(
    () => (mappedData?.length <= 2 ? PIE_CHART_COLORS_WITH_TWO_ITEMS : PIE_CHART_COLORS),
    [mappedData?.length]
  )

  const onSectorClick = (masterProduct: string) => {
    if (masterProduct) {
      navigate(
        `/${REPORT_ENUM.EXTERNAL_TRANSACTION_REPORT}?limit=10&orderType=DESC&orderField=createdAt&page=1&masterProduct=${masterProduct}&status=${TRANSACTION_STATUS_ENUM.FAILED}`
      )
    }
  }

  return (
    <DashboardTableWrapper
      title='Failed Transactions'
      filters={
        <RangePicker
          className={styles.delegationDatePicker}
          placeholder={['Date range', '']}
          onChange={(val) => onWidgetDateChange && onWidgetDateChange(val as Moment[])}
        />
      }
    >
      {widgetData?.length ? (
        <div className={styles.pieChartTypeDataContainer}>
          <div>
            {mappedData.map((cData, i) => (
              <RecursivelyReplaceCharacters key={cData.name}>
                <div className={styles.chartDataTitle}>
                  <div
                    className={styles.chartDataDot}
                    style={{ backgroundColor: cData.color ?? colors[i] }}
                  />
                  <div className={styles.chartDataName}>{cData.name}</div>
                </div>
              </RecursivelyReplaceCharacters>
            ))}
          </div>
          <div>
            <PieChart
              data={mappedData}
              colors={colors}
              textCenterChart={mappedData.reduce((a, v) => a + v.value, 0)}
              onSectorClick={onSectorClick}
            />
          </div>
        </div>
      ) : (
        <NotFoundPage />
      )}
    </DashboardTableWrapper>
  )
}
