import React, { memo } from 'react'
import emptyAvatarImage from '../../../assets/img/Avatar.png'
import styles from './styles.module.scss'
import { IDepartmentUser } from '../../../features/Settings/models/IDepartmentsManagement'
import { IByUserWithFullName } from '../../models/IByUser'
import { useLocalStorageGetByKey } from '../../hooks/useLocalStorageGetByKey'

interface IProps {
  users: Array<IDepartmentUser | IByUserWithFullName>
  totalCount: number
}

const TableUsersOrder = ({ users, totalCount }: IProps) => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  return (
    <div className={styles.content}>
      {users?.length > 0
        ? users.slice(0, 2).map((user, index) => (
            <div
              key={user.id}
              className={styles.userAvatarContainer}
              style={{
                left: index * 32,
                zIndex: users?.length - index,
              }}
            >
              <img
                style={{ width: 37, height: 37 }}
                className={styles.userAvatarImage}
                alt='vendor logo'
                src={!isPrivateMode && user?.logo ? user?.logo : emptyAvatarImage}
              />
            </div>
          ))
        : null}
      {totalCount > 2 ? (
        <div className={styles.restUsersCounter}>
          <span className={styles.restUsersCounterText}>{`+${totalCount - 2}`}</span>
        </div>
      ) : null}
    </div>
  )
}

export default memo(TableUsersOrder)
