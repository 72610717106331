import FormItem from 'antd/lib/form/FormItem'
import { TextField } from 'src/shared/components/textfield/TextField'
import styles from './styles.module.scss'
import { commonValidator, maxLengthValidator25 } from 'src/helpers/validation'
import classNames from 'classnames'

export interface IFormItemField {
  name: string
  label: string
  view?: boolean
  className?: string
}

export const FormItemField = ({ name, label, view = false, className }: IFormItemField) => {
  return (
    <FormItem
      rules={[
        { required: true, message: 'Field required' },
        { validator: commonValidator },
        { validator: maxLengthValidator25 },
      ]}
      name={name}
      label={label}
      className={classNames(styles['form-item-inp'], className)}
    >
      <TextField disabled={view} />
    </FormItem>
  )
}
