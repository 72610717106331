import React, { memo, useEffect, useMemo, useState } from 'react'
import { Checkbox } from '../../../../../../../../shared/components/checkbox/Checkbox'
import { IPermissionCreate, IPermissionModulesGroup } from '../../../../../../models/IRoles'

interface IProps {
  disabled?: boolean
  selectedPermissions: Array<IPermissionCreate>
  group: IPermissionModulesGroup
  onSelectedAllGroupPermissions: (group: IPermissionModulesGroup, status: boolean) => void
}

const PermissionsAllInGroupCheckbox = ({
  disabled,
  selectedPermissions,
  group,
  onSelectedAllGroupPermissions,
}: IProps) => {
  const [isChecked, setIsChecked] = useState(false)
  const [isIndeterminate, setIsIndeterminate] = useState(false)

  const permissionsCountInGroup = useMemo(() => {
    const modulesPermissionCount = group.modules.reduce(
      (acc, currentValue) => acc + currentValue.permissionModuleAllowedPermissions.length,
      0
    )

    return group.modules.reduce(
      (acc, currentValue) =>
        acc +
        currentValue.subModules.reduce(
          (acc2, currentValue2) => acc2 + currentValue2.permissionModuleAllowedPermissions.length,
          0
        ),
      modulesPermissionCount
    )
  }, [group.modules])

  const selectedPermissionsCountInGroup = useMemo(() => {
    const selectedSubmodulesCount = group.modules
      .map((module) =>
        module.subModules
          .map((sub) => selectedPermissions.filter((per) => per.permissionModuleId === sub.id))
          .flat()
      )
      .flat().length

    const selectedModulesCount = group.modules
      .map((module) => selectedPermissions.filter((per) => per.permissionModuleId === module.id))
      .flat().length

    return selectedSubmodulesCount + selectedModulesCount
  }, [group.modules, selectedPermissions])

  useEffect(() => {
    const checked =
      selectedPermissionsCountInGroup > 0 &&
      permissionsCountInGroup === selectedPermissionsCountInGroup
    setIsChecked(checked)
  }, [permissionsCountInGroup, selectedPermissionsCountInGroup])

  useEffect(() => {
    const selectedDiff = permissionsCountInGroup - selectedPermissionsCountInGroup
    const indeterminate = selectedDiff > 0 && selectedDiff !== permissionsCountInGroup
    setIsIndeterminate(indeterminate)
  }, [permissionsCountInGroup, selectedPermissionsCountInGroup])

  return (
    <Checkbox
      disabled={disabled}
      checked={isChecked}
      indeterminate={isIndeterminate}
      onChange={(status) => onSelectedAllGroupPermissions(group, status.target.checked)}
    />
  )
}

export default memo(PermissionsAllInGroupCheckbox)
