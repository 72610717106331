export enum STATEMENT_OF_WALLETS_ENUM {
  SUMMARY_STATEMENT = 'Summary statement',
  AGENT_STATEMENT = 'Agent Statement',
  BANK_ACCOUNTS = 'Bank Accounts',
  VENDOR_WALLETS = 'Vendor Wallets',
  TRUST_WALLET = 'Treasury Wallet',
  REWARD_WALLET = 'Reward Wallet',
  BANK_WALLET = 'Operation Wallet',
  DISCOUNT_WALLET = 'Discount Wallet',
  HAWALA_WALLET = 'Hawala Wallet',
  CREDIT_WALLET = 'Credit Wallet',
  PROMOTION_WALLET = 'Promotion Wallet',
  COLLECTION_WALLET = 'Collection Wallet',
}
