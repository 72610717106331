import { ColumnsType } from 'antd/es/table'
import { useCallback, useState } from 'react'
import { ITableConf } from '../../../../../../shared/models/ITableConf'
import {
  useGetAccountTypeListQuery,
  useLazyExportAccountTypeTableQuery,
} from '../../../../core/http/SystemConfigurationApi'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { Table } from 'antd'
import styles from '../../styles.module.scss'
import { IAccountType } from '../../../../models/IAccountType'
import { useTableExport } from '../../../../../../shared/hooks/table/useTableExport'
import { renderAgentGroups } from '../../helpers/renderAgentGroups'
import { useNavigate } from 'react-router'
import { IPopupListItems, Popup } from '../../../../../../shared/components/popup/Popup'
import { SALES_ENUM } from '../../../../../../routes/sales'
import { TableActionIcon } from '../../../../../../assets/svg'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { isEmpty } from 'lodash'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'

export const AccountTypeTable = () => {
  const navigate = useNavigate()
  const tableActionsPopup = useCallback(
    (id: number, accountType: string): IPopupListItems[] => {
      return [
        {
          text: 'View',
          onClick: () =>
            navigate(`${SALES_ENUM.SALES_NETWORK}?limit=10&page=1&walletType=${accountType}`),
          shouldDisplay: true,
        },
      ]
    },
    [navigate]
  )
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  const columns: ColumnsType<IAccountType> = [
    {
      title: 'Account Type',
      dataIndex: 'accountType',
      width: '90%',
      render: (type) => <RecursivelyReplaceCharacters>{type}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Agents',
      dataIndex: 'agents',
      width: '10%',
      render: (agents: IAccountType['agents']) => renderAgentGroups({ agents, isPrivateMode }),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '20%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id, row.accountType)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const { queryParams } = useGetQueryParams()
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const [exportTable] = useLazyExportAccountTypeTableQuery()
  const { data, isFetching } = useGetAccountTypeListQuery(
    { ...tableConf },
    { skip: isEmpty(tableConf) }
  )

  useTableExport({
    tableConf,
    promise: exportTable,
  })

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange } = useTable<IAccountType>({
    total: data?.totalCount,
    setTableConf,
  })

  return (
    <>
      <Table
        locale={emptyTableConf}
        onChange={handleTableChange}
        pagination={pagination}
        columns={columns}
        className={styles.table}
        scroll={{ x: 1000 }}
        rowKey={(record) => record.id}
        rowSelection={{ type: 'checkbox' }}
        dataSource={data?.items}
        loading={isFetching}
      />
    </>
  )
}
