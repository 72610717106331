import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment/moment'
import styles from '../styles.module.scss'
import { InfiniteScrollComponent } from '../../../../../../../../../shared/components/infiniteScoll/InfiniteScroll'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { CloseIcon, DownloadIcon } from '../../../../../../../../../assets/svg'
import { NotFoundPage } from '../../../../../../../../NotFoundPage'
import {
  convertHeicToJpeg,
  downloadFileThroughATag,
} from '../../../../../../../../../helpers/utils'
import { IExternalChatMessageFile } from '../../../../../../../models/IExternalChat'
import classNames from 'classnames'
import { Row, Spin } from 'antd'

interface IProps {
  files: Array<IExternalChatMessageFile>
  totalCount: number
  loadMoreData: () => void
}

export const ImagesList = ({ files, totalCount, loadMoreData }: IProps) => {
  const [isFetching, setIsFetching] = useState(false)
  const [convertedFiles, setConvertedFiles] = useState<Array<IExternalChatMessageFile>>([])
  const [currentImage, setCurrentImage] = useState(0)
  const [currentOpenMonth, setCurrentOpenMonth] = useState('')
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((index: number, month: string) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
    setCurrentOpenMonth(month)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
    setCurrentOpenMonth('')
  }

  const convertImagesToJpeg = useCallback(async () => {
    setIsFetching(true)
    const imagesData = await Promise.all(
      files.map(async (el) => {
        if (el.file?.name.split('.').at(-1) !== 'heic') return el

        return {
          ...el,
          file: {
            ...el.file,
            link: await convertHeicToJpeg(el.file?.link, el.file?.fileFormat),
          },
        }
      })
    )
    setIsFetching(false)

    setConvertedFiles(imagesData)
  }, [files])

  const itemsMap = useMemo(() => {
    const a: { [key: string]: Array<IExternalChatMessageFile> } = {}
    convertedFiles.forEach((el) => {
      const key = moment(el.createdAt).format('YYYY-MM')
      if (a[key] && Array.isArray(a[key])) {
        a[key].push(el)
      } else {
        a[key] = [el]
      }
    })
    return a
  }, [convertedFiles])

  const onPressDownloadImage = (link: string, fileName: string) => {
    if (link) {
      downloadFileThroughATag(link, fileName)
    }
  }

  const onViewChange = (id: number) => setCurrentImage(id)

  useEffect(() => {
    convertImagesToJpeg()
  }, [convertImagesToJpeg])

  return (
    <div
      className={classNames(styles.filesContainer, { [styles.emptyFileContainer]: !files.length })}
    >
      <Row justify='center'>
        <Spin spinning={isFetching} />
      </Row>
      {files.length ? (
        <InfiniteScrollComponent
          loadMoreData={loadMoreData}
          dataLength={files.length}
          totalCount={totalCount}
          endMessage=''
          showDivider={false}
        >
          {Object.keys(itemsMap).map((key: string) => (
            <div key={key} className={styles.imagesMonthContainer}>
              <div className={styles.dateDivider}>{moment(key).format('MMMM')}</div>
              <div className={styles.monthImages}>
                {itemsMap[key].map(({ file }, i) => (
                  <div key={file.id} className={styles.imageItem}>
                    <div className={styles.imageContainer} onClick={() => openLightbox(i, key)}>
                      <img src={file.link} alt='external-chat-image' />
                    </div>
                  </div>
                ))}
              </div>
              {/* @ts-ignore */}
              <ModalGateway>
                {viewerIsOpen && key === currentOpenMonth ? (
                  <Modal onClose={closeLightbox}>
                    <div>
                      <div className={styles.topLineContainer}>
                        <div
                          className={styles.topLineIcon}
                          onClick={() =>
                            onPressDownloadImage(
                              files[currentImage].file.link,
                              files[currentImage].file.name as string
                            )
                          }
                        >
                          <DownloadIcon color='#FFFFFF' />
                        </div>
                        <div className={styles.topLineIcon} onClick={closeLightbox}>
                          <CloseIcon color='#FFFFFF' />
                        </div>
                      </div>
                      <Carousel
                        currentIndex={currentImage}
                        views={itemsMap[key].map((x) => ({
                          ...x.file,
                          source: x.file.link,
                        }))}
                        trackProps={{ onViewChange }}
                      />
                    </div>
                  </Modal>
                ) : null}
              </ModalGateway>
            </div>
          ))}
        </InfiniteScrollComponent>
      ) : (
        <NotFoundPage text='Empty data' />
      )}
    </div>
  )
}
