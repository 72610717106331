import { DatePickerIcon } from '../../../../../assets/svg'
import React, { useEffect, useState } from 'react'
import styles from '../AgentManagementDetailView.module.scss'
import { WIDGET_LIST_ENUM } from '../../../../../shared/models/WidgetListEnum'
import { IWidget } from '../../../../../shared/models/IWidget'
import { useParams } from 'react-router-dom'
import { widgetsSocketConnection } from '../../../../../shared/sockets'
import {
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  formatShamsiMomentToISO,
  getFormCalendarTime,
  getFrequency,
  moment,
} from '../../../../../helpers/utils'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import { Widgets } from './Widgets'
import { SalesChannelsPieChart } from '../../../../Dashboard/pages/Overview/Components/SalesChannelsPieChart'
import { TotalSalesPieChart } from '../../../../Reports/pages/Transactions/Components/TotalSalesPieChart'
import { WeekProductivityPieChart } from './WeekProductivityPieChart'
import { ActivityPerDayPieChart } from './ActivityPerDayPieChart'
import { ISelectedFilters } from '../../../../../shared/components/filter/Filter'
import { Moment } from 'moment/moment'
import { isEmpty } from 'lodash'
import { RangePicker } from '../../../../../shared/components/datePicker'
import { Dayjs } from 'dayjs'
import { SalesSummaryBarChart } from '../../../../Reports/pages/Transactions/Components/SalesSummaryBarChart'
import {
  ISalesByChannels,
  ISalesByMasterProducts,
  ISalesSummary,
} from '../../../../Reports/models/IExternalReport'
import { IAgentActivity } from '../../../models/IAgent'

export type AgentAnalyticsTypeEnum = Record<
  Exclude<
    WIDGET_LIST_ENUM,
    | WIDGET_LIST_ENUM.SALES_SUMMARY
    | WIDGET_LIST_ENUM.SALES_CHANNELS
    | WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS
    | WIDGET_LIST_ENUM.AGENT_ACTIVITY
  >,
  {
    value: number
    statistic: number
  }
> &
  Record<
    WIDGET_LIST_ENUM.SALES_SUMMARY,
    {
      value: {
        pickupSummary: ISalesSummary[]
        salesSummary: ISalesSummary[]
      }
      frequency: string
    }
  > &
  Record<
    WIDGET_LIST_ENUM.SALES_CHANNELS,
    {
      value: ISalesByChannels[]
    }
  > &
  Record<
    WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS,
    {
      value: ISalesByMasterProducts[]
    }
  > &
  Record<
    WIDGET_LIST_ENUM.AGENT_ACTIVITY,
    {
      value: IAgentActivity[]
      frequency: string
    }
  >

export const AnalyticsTab = () => {
  const { agentId } = useParams()
  const { data: profile } = useGetProfileQuery()
  const [widgetsDateFilter, setWidgetsDateFilter] = useState<ISelectedFilters>({})
  const [specificWidgetDate, setSpecificWidgetDate] = useState<
    Record<WIDGET_LIST_ENUM, ISelectedFilters>
  >({} as Record<WIDGET_LIST_ENUM, ISelectedFilters>)
  const [widgetData, setWidgetData] = useState<AgentAnalyticsTypeEnum>(
    () =>
      ({
        [WIDGET_LIST_ENUM.AVERAGE_PICKUP_PER_DAY]: {
          value: 0,
          statistic: 0,
        },
        [WIDGET_LIST_ENUM.AVERAGE_DAILY_TOPUP]: {
          value: 0,
          statistic: 0,
        },
        [WIDGET_LIST_ENUM.GENERATED_PROFIT]: {
          value: 0,
          statistic: 0,
        },
        [WIDGET_LIST_ENUM.SALES_SUMMARY]: {
          value: {
            pickupSummary: [] as ISalesSummary[],
            salesSummary: [] as ISalesSummary[],
          },
          frequency: 'hour',
        },
        [WIDGET_LIST_ENUM.SALES_CHANNELS]: {
          value: [] as ISalesByChannels[],
        },
        [WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS]: {
          value: [] as ISalesByMasterProducts[],
        },
        [WIDGET_LIST_ENUM.AGENT_ACTIVITY]: {
          value: [] as IAgentActivity[],
          frequency: 'hour',
        },
      } as AgentAnalyticsTypeEnum)
  )

  const onDateChange = (value: Moment[] | null) => {
    if (!Array.isArray(value)) {
      return setWidgetsDateFilter({})
    }

    const from = !isEmpty(value[0])
      ? formatMomentDateToDayStart(
          formatShamsiMomentToISO(profile?.calendar, value[0])
        ).toISOString(true)
      : ''
    const to = !isEmpty(value[1])
      ? formatMomentDateToDayEnd(formatShamsiMomentToISO(profile?.calendar, value[1])).toISOString(
          true
        )
      : ''

    return setWidgetsDateFilter((prevState) => ({
      ...prevState,
      from,
      to,
    }))
  }

  const onWidgetDateChange = (widgetName: WIDGET_LIST_ENUM) => (value: Moment[] | null) => {
    if (!Array.isArray(value)) {
      return setSpecificWidgetDate((prevState) => ({
        ...prevState,
        [widgetName]: null,
      }))
    }

    const from = !isEmpty(value[0])
      ? formatMomentDateToDayStart(
          formatShamsiMomentToISO(profile?.calendar, value[0])
        ).toISOString(true)
      : ''
    const to = !isEmpty(value[1])
      ? formatMomentDateToDayEnd(formatShamsiMomentToISO(profile?.calendar, value[1])).toISOString(
          true
        )
      : ''

    return setSpecificWidgetDate((prevState) => ({
      ...prevState,
      [widgetName]: { from, to },
    }))
  }

  const getFrequencyName = (widgetDate: ISelectedFilters) => {
    return getFrequency(widgetDate.from as string, widgetDate.to as string)
  }

  useEffect(() => {
    if (!profile) return

    const socket = widgetsSocketConnection.getSocket()
    const messagePayload = [
      {
        widget: WIDGET_LIST_ENUM.AVERAGE_PICKUP_PER_DAY,
        filters: { id: agentId, ...widgetsDateFilter },
      },
      {
        widget: WIDGET_LIST_ENUM.AVERAGE_DAILY_TOPUP,
        filters: { id: agentId, ...widgetsDateFilter },
      },
      {
        widget: WIDGET_LIST_ENUM.SALES_SUMMARY,
        filters: {
          agentId,
          ...(specificWidgetDate[WIDGET_LIST_ENUM.SALES_SUMMARY] ?? widgetsDateFilter),
          frequency: getFrequencyName(
            specificWidgetDate[WIDGET_LIST_ENUM.SALES_SUMMARY] ?? widgetsDateFilter
          ),
        },
      },
      {
        widget: WIDGET_LIST_ENUM.SALES_CHANNELS,
        filters: {
          agentId,
          ...(specificWidgetDate[WIDGET_LIST_ENUM.SALES_CHANNELS] ?? widgetsDateFilter),
        },
      },
      {
        widget: WIDGET_LIST_ENUM.GENERATED_PROFIT,
        filters: {
          agentId,
          ...widgetsDateFilter,
        },
      },
      {
        widget: WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS,
        filters: {
          agentId,
          ...(specificWidgetDate[WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS] ?? widgetsDateFilter),
        },
      },
      {
        widget: WIDGET_LIST_ENUM.AGENT_ACTIVITY,
        filters: {
          agentId,
          ...(specificWidgetDate[WIDGET_LIST_ENUM.AGENT_ACTIVITY] ?? widgetsDateFilter),
          frequency: getFrequencyName(
            specificWidgetDate[WIDGET_LIST_ENUM.AGENT_ACTIVITY] ?? widgetsDateFilter
          ),
        },
      },
    ]

    socket?.emit('connect-widget', { data: messagePayload })

    return () => {
      socket?.emit('disconnect-widget', {
        data: messagePayload,
      })
    }
  }, [agentId, profile, specificWidgetDate, widgetsDateFilter])

  useEffect(() => {
    if (!profile) return
    const socket = widgetsSocketConnection.getSocket()
    socket?.on('widget-data', (data: IWidget) => {
      const balance = JSON.parse(data?.data?.jsonData)
      setWidgetData((prevState) => ({
        ...prevState,
        [data.widget]:
          Object.keys(balance).length === 1
            ? {
                value: balance.value,
                ...(data['filters']?.frequency && { frequency: data['filters']?.frequency }),
              }
            : balance,
      }))
    })
  }, [profile])

  useEffect(() => {
    if (profile?.timeZone) {
      moment.tz?.setDefault(profile?.timeZone as string)

      const from = formatMomentDateToDayStart(
        formatShamsiMomentToISO(profile?.calendar, moment())
      ).toISOString(true)
      const to = formatMomentDateToDayEnd(
        formatShamsiMomentToISO(profile?.calendar, moment())
      ).toISOString(true)

      setWidgetsDateFilter(() => ({
        from,
        to,
      }))
    }
  }, [profile?.calendar, profile?.timeZone])

  return (
    <div className={styles['agent-detail-view']}>
      <div className={styles.headerDatePicker}>
        <RangePicker
          placeholder={['Date range', '']}
          onChange={(val) => onDateChange(val as Moment[])}
          value={
            widgetsDateFilter?.from && widgetsDateFilter?.to
              ? [
                  (getFormCalendarTime(
                    profile?.calendar,
                    widgetsDateFilter.from as string
                  ) as Moment) || Dayjs,
                  (getFormCalendarTime(
                    profile?.calendar,
                    widgetsDateFilter.to as string
                  ) as Moment) || Dayjs,
                ]
              : undefined
          }
          suffixIcon={
            <div>
              <DatePickerIcon color='#0193EB' />
            </div>
          }
        />
      </div>
      <Widgets widgetData={widgetData} />

      <div className={styles.topMargin}>
        <SalesSummaryBarChart
          frequency={widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY].frequency}
          data={widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY].value}
          onWidgetDateChange={onWidgetDateChange(WIDGET_LIST_ENUM.SALES_SUMMARY)}
        />
      </div>

      <div className={styles.pieChartListWrapper}>
        <SalesChannelsPieChart
          widgetData={widgetData[WIDGET_LIST_ENUM.SALES_CHANNELS].value}
          onWidgetDateChange={onWidgetDateChange(WIDGET_LIST_ENUM.SALES_CHANNELS)}
        />
        <TotalSalesPieChart
          data={widgetData[WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS].value}
          onWidgetDateChange={onWidgetDateChange(WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS)}
        />
        <WeekProductivityPieChart widgetData={[]} />
      </div>

      <ActivityPerDayPieChart
        widgetData={widgetData[WIDGET_LIST_ENUM.AGENT_ACTIVITY].value}
        frequency={widgetData[WIDGET_LIST_ENUM.AGENT_ACTIVITY].frequency}
      />
    </div>
  )
}
