import React, { memo, useRef, useState } from 'react'
import moment from 'moment'
import { FileIcon, TableActionIcon } from '../../../../../../assets/svg'
import { IPopupListItems } from '../../../../../../shared/components/popup/Popup'
import { IAttachmentFile } from '../../../../models'
import InternalChatPopup from '../InternalChatPopup'
import { useOnClickOutside } from '../../../../../../shared/hooks/useClickOutside'
import styles from '../FilesList/styles.module.scss'

interface IProps {
  tableActionsPopup: (
    id: string,
    file: IAttachmentFile,
    // eslint-disable-next-line @typescript-eslint/ban-types
    callback?: Function
  ) => IPopupListItems[]
  file: IAttachmentFile
  isFirstNotificationInAMonth: boolean
  createdAt: string
  fileId: string
}

const AttachmentFile = ({
  tableActionsPopup,
  file,
  isFirstNotificationInAMonth,
  createdAt,
  fileId,
}: IProps) => {
  const actionsRef = useRef(null)
  const [isShownActions, setIsShownActions] = useState(false)

  useOnClickOutside(actionsRef, () => {
    setIsShownActions(false)
  })

  const content = tableActionsPopup(fileId, file, setIsShownActions)

  return (
    <div className='chatFileActions'>
      {isFirstNotificationInAMonth && (
        <div className={styles.fileDateDivider}>{moment(createdAt).format('MMMM')}</div>
      )}
      <div key={file.id} className={styles.fileContainer}>
        <div className={styles.fileInfo}>
          <FileIcon />
          <div className={styles.fileName}>{file.name}</div>
        </div>
        <div className='table-kebab-actions' onClick={() => setIsShownActions(true)}>
          <TableActionIcon />
        </div>
        {isShownActions && (
          <div className={styles.isShawnActions} ref={actionsRef}>
            <InternalChatPopup data={content} />
          </div>
        )}
      </div>
    </div>
  )
}
export default memo(AttachmentFile)
