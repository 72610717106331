import { IByUser } from '../../../shared/models/IByUser'
import { ICurrency } from '../../Finance/models/ICurency'

export enum POSITION_RATE_TYPE_ENUM {
  HOURLY = 'HOURLY',
  OVERTIME = 'OVERTIME',
  HOLIDAY = 'HOLIDAY',
}

export interface IPositionRate {
  id: string
  amount: number
  percent: string
  type: POSITION_RATE_TYPE_ENUM
}

export interface IPosition {
  id: 0
  name: string
  createdBy: IByUser
  supervisor: IByUser
  rateCurrency: ICurrency
  createdAt: Date
  updatedAt: Date
  hourlyRate: number
  hourlyPercent: string
  overtimeRate: number
  overtimePercent: string
  holidayRate: number
  holidayPercent: string
  status: boolean
}

export interface IPositionCreate {
  name: string
  rateCurrencyId: number
  status?: boolean
  supervisorId: number
  userIds?: Array<number>
  hourlyRate: number
  overtimeRate: number
  holidayRate: number
}
