import styles from '../../styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../../../../shared/components/textfield/TextField'
import TextArea from 'antd/es/input/TextArea'

interface IProps {
  name: number
}

export const RequestHistory = ({ name }: IProps) => {
  return (
    <div className={styles.subTransactionItem}>
      <div className={styles.dblRow}>
        <FormItem name={[name, 'id']} label='ID'>
          <TextField disabled />
        </FormItem>

        <FormItem name={[name, 'createdAt']} label='Date & Time'>
          <TextField disabled />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem name={[name, 'responseCode']} label='Response Code'>
          <TextField disabled />
        </FormItem>
        <FormItem name={[name, 'responseDescription']} label='Response Message'>
          <TextField disabled />
        </FormItem>
      </div>

      <div>
        <FormItem name={[name, 'body']} label='Body'>
          <TextArea disabled rows={5} />
        </FormItem>
      </div>

      <div>
        <FormItem name={[name, 'responseAt']} label='Response At'>
          <TextField disabled />
        </FormItem>
      </div>

      <div>
        <FormItem name={[name, 'response']} label='Response'>
          <TextArea disabled rows={8} />
        </FormItem>
      </div>

      <div>
        <FormItem name={[name, 'processedResponse']} label='Processed Response'>
          <TextArea disabled rows={10} />
        </FormItem>
      </div>
    </div>
  )
}
