import { Route } from 'use-react-router-breadcrumbs'
import { SupportOverview } from '../../features/Support/pages/Overview/index'
import { SupportChatroom } from '../../features/Support/pages/Chatroom'
import SupportSystemMonitoring from '../../features/Support/pages/SystemMonitoring'
import SupportTransactionLogs from '../../features/Support/pages/TransactionLogs'
import KnowledgeBase from '../../features/Support/pages/KnowledgeBase'
import KnowledgeBaseView from '../../features/Support/pages/KnowledgeBaseView'
import { SupportTicket } from '../../features/Support/pages/Overview/SupportTicket'
import { RestrictionManagement } from '../../features/Support/pages/RestrictionManagement'
import { CustomerRestrictionView } from '../../features/Support/pages/RestrictionManagement/CustomerRestrictionView'
import { AgentRestrictionView } from '../../features/Support/pages/RestrictionManagement/AgentRestrictionView'
import { getBreadcrumb } from '../utils'

export enum SUPPORT_ENUM {
  SUPPORT_OVERVIEW = 'support/tickets',
  SUPPORT_TICKET_VIEW = 'support/tickets/:mode/:id',
  SUPPORT_TICKET_NEW = 'support/tickets/new',
  SUPPORT_RESTRICTION_MANAGEMENT = 'support/restriction-management',
  SUPPORT_RESTRICTION_MANAGEMENT_CUSTOMER_VIEW = 'support/restriction-management/customer/:mode/:id',
  SUPPORT_RESTRICTION_MANAGEMENT_CUSTOMER_NEW = 'support/restriction-management/customer/new',
  SUPPORT_RESTRICTION_MANAGEMENT_AGENT_VIEW = 'support/restriction-management/agent/:mode/:id',
  SUPPORT_RESTRICTION_MANAGEMENT_AGENT_NEW = 'support/restriction-management/agent/new',
  SUPPORT_RESTRICTION_MANAGEMENT_NEW = 'support/restriction-management/new',
  SUPPORT_RESTRICTION_MANAGEMENT_VIEW = 'support/restriction-management/:mode/:id',
  SUPPORT_CHAT_ROOM = 'support/chat-room',
  SUPPORT_SYSTEM_MONITORING = 'support/system-monitoring',
  SUPPORT_TRANSACTION_LOG = 'support/transaction-log',
  KNOWLEDGE_BASE = 'support/knowledge-base',
  KNOWLEDGE_BASE_NEW = 'support/knowledge-base/new',
}

export default () => {
  return (
    <>
      <Route path={SUPPORT_ENUM.SUPPORT_OVERVIEW} element={<SupportOverview />} />
      <Route path={SUPPORT_ENUM.SUPPORT_CHAT_ROOM} element={<SupportChatroom />} />
      <Route path={SUPPORT_ENUM.SUPPORT_SYSTEM_MONITORING} element={<SupportSystemMonitoring />} />
      <Route path={SUPPORT_ENUM.SUPPORT_TRANSACTION_LOG} element={<SupportTransactionLogs />} />
      <Route path={SUPPORT_ENUM.KNOWLEDGE_BASE} element={<KnowledgeBase />} />
      <Route path={SUPPORT_ENUM.KNOWLEDGE_BASE_NEW} element={<KnowledgeBaseView />} />
      <Route
        path={SUPPORT_ENUM.SUPPORT_TICKET_VIEW}
        element={<SupportTicket />}
        breadcrumb='Ticket Detail View'
      />
      <Route
        path={SUPPORT_ENUM.SUPPORT_TICKET_NEW}
        element={<SupportTicket />}
        breadcrumb='New Ticket'
      />
      <Route
        path={SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT}
        element={<RestrictionManagement />}
      />
      <Route
        path={SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT_CUSTOMER_VIEW}
        element={<CustomerRestrictionView />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT_CUSTOMER_NEW}
        element={<CustomerRestrictionView />}
        breadcrumb='New Customer Restriction'
      />
      <Route
        path={SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT_AGENT_VIEW}
        element={<AgentRestrictionView />}
        breadcrumb={getBreadcrumb()}
      />
      <Route
        path={SUPPORT_ENUM.SUPPORT_RESTRICTION_MANAGEMENT_AGENT_NEW}
        element={<AgentRestrictionView />}
        breadcrumb='New Agent Restriction'
      />
    </>
  )
}
