import React, { memo, useEffect, useMemo, useState } from 'react'
import { Select, Spin } from 'antd'

import styles from '../../styles.module.scss'
import { ITransactionLogAll } from 'src/features/Support/models/TransactionLog'
import { SearchIcon } from 'src/assets/svg'
import classNames from 'classnames'

interface Props {
  loading: boolean
  isNeedClear: boolean
  tableData: ITransactionLogAll[]
  onSearchSelect: React.ChangeEventHandler<HTMLSelectElement>
  onSearchChange: (value: string) => void
  setNeedClear: React.Dispatch<React.SetStateAction<boolean>>
  clearLogs: () => void
}

const SearchTransaction = ({
  isNeedClear,
  loading,
  tableData,
  setNeedClear,
  onSearchChange,
  onSearchSelect,
  clearLogs,
}: Props) => {
  const [value, setValue] = useState<React.ChangeEvent<HTMLSelectElement> | null>()

  useEffect(() => {
    if (!isNeedClear) return
    setValue(null)
    setNeedClear(false)
  }, [isNeedClear, setNeedClear])

  const onSearch = (value: string) => {
    onSearchChange(value)
  }

  const handleSearchSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    onSearchSelect(e)
    setValue(e)
  }

  const handleClear = () => {
    setValue(null)
    clearLogs()
  }

  const options = useMemo(
    () =>
      tableData.map((d, index) => ({
        value: d.id,
        label: `${index + 1}. ${d.topupId} ${d.responseDescription}`,
      })),
    [tableData]
  )

  return (
    <Select
      showSearch
      value={value}
      placeholder='Search by transaction ID'
      style={{ width: 200 }}
      className={classNames(styles.searchInput)}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={onSearch}
      onChange={handleSearchSelect}
      onClear={handleClear}
      options={options}
      suffixIcon={<SearchIcon />}
      allowClear
      notFoundContent={loading ? <Spin size='small' /> : null}
    />
  )
}

export default memo(SearchTransaction)
