import React, { useCallback, useContext, useState } from 'react'
import { Button, notification, Table } from 'antd'
import { useNavigate, useParams } from 'react-router'
import useTable from '../../../../../../../../shared/hooks/table/useTable'
import {
  useDeleteGatewayMutation,
  useGetGatewayFiltersListQuery,
  useGetGatewayListQuery,
  useUpdateGatewayByIdMutation,
} from '../../../../../../core/http/VendorManagementApi'
import { useGetQueryParams } from '../../../../../../../../shared/hooks/table/useGetQueryParams'
import { ISelectedFilters } from '../../../../../../../../shared/components/filter/Filter'
import { ITableConf } from '../../../../../../../../shared/models/ITableConf'
import { useEmptyTable } from '../../../../../../../../shared/hooks/table/useEmptyTable'
import { ColumnsType } from 'antd/es/table'
import { IGatewayTable } from '../../../../../../models/IGateway'
import styles from '../ContactsTable/styles.module.scss'

import {
  DateTableIcon,
  PhoneLightBlueIcon,
  TableActionIcon,
} from '../../../../../../../../assets/svg'
import { ErrorNode, MESSAGE_KEY } from '../../../../../../../../shared/api/errorHandler'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../shared/hooks/useNotification'
import { TableHeader } from '../../TableHeader'
import { RIGHT_MODALS } from '../../../../../../../../helpers/contants'
import { RightModalContext } from '../../../../../../../Modals'
import { Toggle } from 'src/shared/components/toggle/Toggle'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from 'src/shared/components/popup/Popup'
import { formatDateWithTime } from 'src/helpers/utils'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import gStyles from './styles.module.scss'
import { CalendarFilled } from '@ant-design/icons'

const Gateway = () => {
  const navigate = useNavigate()
  const { id: vendorIdParam } = useParams()
  const { queryParams, queryFields } = useGetQueryParams()

  const {
    onOpen,
    props: { vendorId },
  } = useContext(RightModalContext)

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const [updateGatewayStatus, updateGatewayStatusResponse] = useUpdateGatewayByIdMutation()

  const { data: profile } = useGetProfileQuery()

  const { data: filtersList } = useGetGatewayFiltersListQuery({ vendorId: vendorIdParam as string })
  const { data, isFetching } = useGetGatewayListQuery({
    params: {
      ...tableConf,
      ...filterValues,
    },
    vendorId: vendorIdParam as string,
  })

  const [deleteGateway, dgResp] = useDeleteGatewayMutation()

  useNotification(NOTIFICATION_TYPES.success, dgResp.isSuccess, null)
  useNotification(NOTIFICATION_TYPES.error, dgResp.isError, dgResp.error as ErrorNode)

  useNotification(NOTIFICATION_TYPES.success, updateGatewayStatusResponse.isSuccess, null)
  useNotification(
    NOTIFICATION_TYPES.error,
    updateGatewayStatusResponse.isError,
    updateGatewayStatusResponse.error as ErrorNode
  )

  const { pagination, handleTableChange, handleFiltersChange } = useTable<IGatewayTable>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  const emptyTableConf = useEmptyTable()

  const openNotification = useCallback(
    (gatewayId: number) => {
      const btn = (
        <Button
          type='primary'
          onClick={() => deleteGateway({ vendorId: vendorIdParam as string, gatewayId })}
        >
          Confirm
        </Button>
      )
      const notificationRef = notification
      notificationRef.open({
        type: 'warning',
        message: 'This gateway will be deleted',
        key: MESSAGE_KEY,
        btn,
      })
    },
    [deleteGateway]
  )

  const tableActionsPopup = useCallback(
    (gatewayId: IGatewayTable['id']): IPopupListItems[] => [
      {
        text: 'View',
        shouldDisplay: true,
        onClick: () => {
          onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_GATEWAY, {
            title: 'Gateway',
            vendorId: vendorIdParam,
            gatewayId,
          })
        },
      },
      {
        text: 'Edit',
        shouldDisplay: true,
        onClick: () => {
          onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_GATEWAY, {
            title: 'Update Gateway',
            vendorId: vendorIdParam,
            gatewayId,
          })
        },
      },
      {
        text: 'Delete',
        shouldDisplay: true,
        onClick: () => openNotification(gatewayId),
        color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
        withDivider: true,
      },
    ],
    [navigate, openNotification]
  )

  const columns: ColumnsType<IGatewayTable> = [
    {
      title: 'Sim Number',
      dataIndex: 'simNumber',
      sorter: true,
      width: '25%',
      render: (simNumber) => (
        <div className={gStyles.hasIcon}>
          <PhoneLightBlueIcon />
          {simNumber}
        </div>
      ),
    },
    {
      title: 'Owner Name',
      dataIndex: 'ownerName',
      sorter: true,
      width: '25%',
      render: (ownerName) => <div>{ownerName}</div>,
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      sorter: true,
      width: '25%',
      render: (createdAt) => (
        <div className={gStyles.hasIcon}>
          <DateTableIcon />
          {formatDateWithTime(createdAt, profile?.calendar)}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '20%',
      render: (status: IGatewayTable['status'], { id }) => {
        return (
          <Toggle
            checked={status}
            defaultChecked={status}
            loading={updateGatewayStatusResponse.isLoading}
            onChange={(status) => {
              if (!vendorIdParam) return

              updateGatewayStatus({ vendorId: vendorIdParam, gatewayId: id, body: { status } })
            }}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '5%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  return (
    <>
      <TableHeader
        data={filtersList}
        setActiveFilters={handleFiltersChange}
        numberOfItems={data?.totalCount}
        filterValues={filterValues}
        nameNumberOfItems='Gateways'
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        onChange={handleTableChange}
        dataSource={data?.items}
        loading={isFetching}
        scroll={{ x: 1100 }}
        pagination={pagination}
        className={styles.table}
        rowKey={(record) => record.id}
      />
    </>
  )
}

export default Gateway
