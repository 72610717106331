import { Form, FormInstance, Select, TimePicker } from 'antd'
import moment from 'moment'
import { TimePickerIcon } from 'src/assets/svg'
import { requiredValidator } from 'src/helpers/validation'
import {
  FrequencyOptions,
  DaysOptions,
  OnOptions,
  ExpiryOptions,
  TimePickerOptions,
  FrequencyTypes,
  ExpiryTypes,
  OnTypes,
} from './index.d'
import { Wrapper } from '../wrapper/Wrapper'

// styles
import styles from './styles.module.scss'
import { DatePicker } from '../datePicker'
interface ISheduler {
  view: boolean
  form: FormInstance
}

export const DROP_SCHEDULE_FIELDS = [
  'sh-frequency',
  'sh-expire',
  'sh-on',
  'sh-day',
  'sh-date',
  'sh-expire-time',
]

export const Sheduler = ({ view, form }: ISheduler) => {
  const handleChangeFrequency = () => {
    form.setFieldsValue({
      'sh-date': null,
      'sh-day': null,
      'set-on': null,
      'sh-expire': null,
      'sh-expire-time': null,
    })
  }

  const handleChangeExpire = () => {
    form.setFieldValue('sh-expire-time', null)
  }

  const getFrequencyField = (name?: keyof typeof FrequencyTypes) => {
    if (name === FrequencyTypes.daily) {
      return
    }
    if (name === FrequencyTypes['weekly']) {
      return (
        <Form.Item
          name='sh-day'
          label='Select Day'
          rules={[{ validator: requiredValidator('Day') }]}
        >
          <Select disabled={view} placeholder='Select Day' allowClear>
            {DaysOptions.map(({ id, name }) => (
              <Select.Option key={id} value={String(id)}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )
    }
    if (name === FrequencyTypes['monthly']) {
      return (
        <Form.Item name='sh-on' label='On' rules={[{ validator: requiredValidator('On') }]}>
          <Select disabled={view} placeholder='Select type' allowClear>
            {OnOptions.map(({ value, name }) => (
              <Select.Option key={value} value={value}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )
    }
  }

  return (
    <Wrapper title='scheduler' className={styles.schedulerContainer}>
      <Form.Item
        name='sh-frequency'
        label='Frequency'
        rules={[{ validator: requiredValidator('Frequency') }]}
      >
        <Select
          disabled={view}
          placeholder='Select Frequency'
          onChange={handleChangeFrequency}
          allowClear
        >
          {FrequencyOptions.map(({ value, name }) => (
            <Select.Option key={value} value={value}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const frequency = getFieldValue('sh-frequency')

          return getFrequencyField(frequency)
        }}
      </Form.Item>

      {/* <div className={styles.dblRow}> */}
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const selectedType = getFieldValue('sh-frequency')
          const selectedOn = getFieldValue('sh-on')
          const isTimePicker = TimePickerOptions.map(({ value }) => value).includes(selectedType)
          const isCustom = selectedOn === OnTypes.custom.toLowerCase()

          return (
            <Form.Item
              name='sh-date'
              label='Time'
              rules={[{ validator: requiredValidator('Time') }]}
            >
              {isTimePicker && !isCustom ? (
                <TimePicker
                  disabled={view || !selectedType}
                  placeholder='Select Time'
                  className={styles.timePickerContainer}
                  showNow={false}
                  format='HH:mm'
                  suffixIcon={<TimePickerIcon />}
                  disabledDate={(current) => current.isBefore(moment().subtract(1, 'day'))}
                />
              ) : (
                <DatePicker
                  disabled={view || !selectedType}
                  placeholder='Select Time'
                  className={styles.timePickerContainer}
                  showNow={false}
                  format='DD-MM-YYYY HH:mm'
                  showTime={{ format: 'HH:mm' }}
                  suffixIcon={<TimePickerIcon />}
                  disabledDate={(current) => current.isBefore(moment().subtract(1, 'day'))}
                />
              )}
            </Form.Item>
          )
        }}
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const frequency = getFieldValue('sh-frequency')

          return (
            frequency && (
              <Form.Item
                name='sh-expire'
                label='Expiry'
                rules={[{ validator: requiredValidator('Expiry') }]}
              >
                <Select
                  disabled={view}
                  placeholder='Expiry'
                  allowClear
                  onChange={handleChangeExpire}
                >
                  {ExpiryOptions.map(({ name, value }) => (
                    <Select.Option key={value} value={value}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )
          )
        }}
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const expire = getFieldValue('sh-expire')
          const date = getFieldValue('sh-date')

          return (
            expire &&
            expire === ExpiryTypes['on'] && (
              <Form.Item
                name='sh-expire-time'
                label='Expiry Time'
                rules={[{ validator: requiredValidator('Expiry Time') }]}
              >
                <DatePicker
                  disabled={!date || view}
                  placeholder='Expiry Time'
                  className={styles.timePickerContainer}
                  format='DD-MM-YYYY HH:mm'
                  showTime={{ format: 'HH:mm' }}
                  showNow={false}
                  suffixIcon={<TimePickerIcon />}
                  disabledDate={(current) => current.isBefore(moment(date))}
                />
              </Form.Item>
            )
          )
        }}
      </Form.Item>
    </Wrapper>
  )
}
