import FormList from 'antd/lib/form/FormList'
import styles from '../styles.module.scss'
import FormItem from 'antd/es/form/FormItem'
import { numberRange, requiredValidator } from '../../../../../../../../../../helpers/validation'
import { Form, Select, Spin } from 'antd'
import { PAYMENT_POLICY_TYPE_ENUM } from '../../../../../../../../models/ITargetGroup'
import { TextField } from '../../../../../../../../../../shared/components/textfield/TextField'
import { IconButton } from '../../../../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, BucketIcon } from '../../../../../../../../../../assets/svg'
import { Wrapper } from '../../../../../../../../../salesNetwork/components/Wrapper'
import React, { useMemo } from 'react'
import { IViewMode } from '../../../../../../../../../Finance/models/IViewMode'
import { useGetVendorWalletTypesQuery } from '../../../../../../../../core/http/VendorManagementApi'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import {
  IVendorPaymentPolicyProduct,
  IVendorPaymentPolicyType,
} from '../../../../../../../../models/IVendorManagement'

export const TypeOfContractsBlock = ({ view }: IViewMode) => {
  const form = useFormInstance()
  const { data: walletTypes, isFetching } = useGetVendorWalletTypesQuery()
  const contractTypes = useMemo(
    () =>
      (Object.keys(PAYMENT_POLICY_TYPE_ENUM) as Array<keyof typeof PAYMENT_POLICY_TYPE_ENUM>).map(
        (vendor) => ({
          label: PAYMENT_POLICY_TYPE_ENUM[vendor],
          value: PAYMENT_POLICY_TYPE_ENUM[vendor],
        })
      ),
    []
  )

  const handleWalletTypeChange = () => {
    const previousProducts =
      (form.getFieldValue('policyProducts') as Array<IVendorPaymentPolicyProduct>) || []
    const contractTypes =
      (form.getFieldValue('contractTypes') as Array<IVendorPaymentPolicyType>) || []

    const selectedWalletTypes = contractTypes.map((contract) => contract?.vendorWalletType)
    const filteredProducts = previousProducts.filter((product) =>
      selectedWalletTypes.includes(product?.vendorWalletType)
    )

    form.setFieldValue('policyProducts', filteredProducts)
  }

  const isLoading = isFetching

  const content = (
    <Wrapper title='Type of contract'>
      <FormList name='contractTypes'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name, key }) => (
              <div key={key} className={styles.walletTypeRow}>
                <FormItem
                  name={[name, 'vendorWalletType']}
                  label='Wallet Type'
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator('Wallet Type'),
                    },
                  ]}
                  className={styles.select}
                >
                  <Select
                    placeholder='Select Wallet Type'
                    allowClear
                    disabled={view}
                    onChange={handleWalletTypeChange}
                  >
                    {walletTypes?.map((type) => (
                      <Select.Option key={type} value={type}>
                        {type}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>

                <Form.Item
                  name={[name, 'paymentPolicyType']}
                  label='Payment Policy Type'
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator('Payment Policy Type'),
                    },
                  ]}
                  className={styles.select}
                >
                  <Select
                    disabled={view}
                    placeholder='Select Payment Policy Type'
                    options={contractTypes}
                    allowClear
                  />
                </Form.Item>

                <Form.Item
                  name={[name, 'discountRate']}
                  label='Rate'
                  rules={[
                    {
                      validator: numberRange(-100, 100, 'Rate'),
                    },
                  ]}
                >
                  <TextField disabled={view} placeholder='Enter Rate' type='number' />
                </Form.Item>

                <div className={styles.bucketIcon}>
                  <IconButton
                    block
                    size='large'
                    color='orange'
                    icon={<BucketIcon />}
                    type='default'
                    disabled={view}
                    onClick={() => {
                      remove(name)
                      handleWalletTypeChange()
                    }}
                  />
                </div>
              </div>
            ))}

            <div className={styles.addButton}>
              <IconButton
                icon={<AddIcon />}
                color='orange'
                block
                onClick={() => add()}
                disabled={view}
              />
            </div>
          </>
        )}
      </FormList>
    </Wrapper>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
