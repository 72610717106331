import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import {
  INotificationManagerCreate,
  INotificationMessage,
  INotificationMessageTable,
  INotificationMessageVariable,
  NOTIFICATION_MESSAGE_ACTION_ENUM,
} from '../../models/INotificationMessage'
import { ITableConf } from '../../../../shared/models/ITableConf'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { ITableResponse } from '../../../../shared/models/ITableResponse'

export const notificationMessageApi = createApi({
  reducerPath: 'notificationMessageApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['NotificationList', 'INotification', 'FilterFields'],
  endpoints: (builder) => ({
    getNotificationMessagesList: builder.query<
      ITableResponse<INotificationMessageTable>,
      ITableConf
    >({
      query: (params) => ({
        url: 'notification-message-by-action',
        params,
      }),
      providesTags: ['NotificationList'],
    }),

    getNotificationMessageById: builder.query<INotificationMessage, string>({
      query: (id) => ({
        url: `notification-message-by-action/${id}`,
      }),
      providesTags: (res) => [{ type: 'INotification', id: res?.id }],
    }),

    getNotificationMessageActionList: builder.query<Array<string>, void>({
      query: () => ({
        url: `notification-message-by-action/actions`,
      }),
    }),

    deleteNotificationMessageById: builder.mutation<void, number>({
      query: (id) => ({
        url: `notification-message-by-action/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['NotificationList', 'FilterFields'],
    }),

    createNotificationMessage: builder.mutation<INotificationMessage, INotificationManagerCreate>({
      query: (body) => ({
        url: `notification-message-by-action`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['NotificationList', 'FilterFields'],
    }),

    updateNotificationMessage: builder.mutation<
      INotificationMessage,
      { id: string; body: INotificationManagerCreate }
    >({
      query: ({ id, body }) => ({
        url: `notification-message-by-action/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (res) => [
        { type: 'INotification', id: res?.id },
        'NotificationList',
        'FilterFields',
      ],
    }),

    getNotificationVariablesList: builder.query<
      INotificationMessageVariable[],
      NOTIFICATION_MESSAGE_ACTION_ENUM
    >({
      query: (action) => ({
        url: `notification-message-by-action/variables/${action}`,
      }),
    }),

    getNotificationMessagesFiltersList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'notification-message-by-action/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `notification-message-by-action/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: ['FilterFields'],
    }),
  }),
})

export const {
  useGetNotificationMessagesListQuery,
  useGetNotificationMessagesFiltersListQuery,
  useGetNotificationMessageByIdQuery,
  useCreateNotificationMessageMutation,
  useUpdateNotificationMessageMutation,
  useDeleteNotificationMessageByIdMutation,
  useGetNotificationVariablesListQuery,
  useGetNotificationMessageActionListQuery,
} = notificationMessageApi
