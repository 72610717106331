import { IAuthResponse } from '../models/IAuth'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from 'src/config'
import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { LOCAL_STORAGE_VARIABLES } from 'src/helpers/contants'
import { resetStateAction } from 'src/redux/actions/resetState'

enum Headers {
  AUTHORIZATION = 'authorization',
  ACCEPT = 'Accept',
}

export const saveAuthData = (data: IAuthResponse) => {
  localStorage.setItem('auth', JSON.stringify(data))
  localStorage.removeItem('activeTabs')
}

export const removeDataFromLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_VARIABLES.recentCachedData)
}

export const clearAuthData = () => {
  saveAuthData({} as IAuthResponse)
  removeDataFromLocalStorage()
}

export const getSavedAuthData = (): IAuthResponse =>
  JSON.parse(localStorage.getItem('auth') || '{}')

export const isAuthorized = () => !!getSavedAuthData()?.tokens

export const isNeedTwoFA = () =>
  getSavedAuthData().twoFactorAuthenticator === 'Google Authenticator'

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    const token = getSavedAuthData().tokens.accessToken

    if (token) {
      headers.set(Headers.AUTHORIZATION, `Bearer ${token}`)
    }
    headers.set(Headers.ACCEPT, 'application/json')
    headers.set('ngrok-skip-browser-warning', String(true))
    return headers
  },
  mode: 'cors',
})

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const token = await getSavedAuthData()?.tokens?.refreshToken

  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // 2FA endpoints in case of error return 401
    if ((args as FetchArgs).url.match(/enable|disable|check|forget/)) return result
    const refreshResult = await baseQuery(
      {
        url: 'auth/refresh',
        method: 'PATCH',
        body: {
          refreshToken: token,
        },
      },
      api,
      extraOptions
    )
    if (refreshResult.data) {
      saveAuthData(refreshResult.data as IAuthResponse)
      result = await baseQuery(args, api, extraOptions)
    } else {
      clearAuthData()
      api.dispatch(resetStateAction())
      location.replace('/sign-in')
    }
  }
  return result
}
