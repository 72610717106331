import { IViewMode } from '../../../../models/IViewMode'
import FormItem from 'antd/es/form/FormItem'
import {
  emailValidator,
  maxLengthValidator,
  requiredValidator,
} from '../../../../../../helpers/validation'
import { TextField } from '../../../../../../shared/components/textfield/TextField'
import styles from '../../BankAccountView/styles.module.scss'
import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'

export const DetailInfo = ({ view }: IViewMode) => {
  return (
    <Wrapper title='Detail Info'>
      <FormItem
        name='name'
        label='Bank Name'
        rules={[
          { required: true, validator: requiredValidator('Bank Name') },
          { validator: maxLengthValidator(45) },
        ]}
      >
        <TextField disabled={view} placeholder='Enter Bank name' />
      </FormItem>

      <div className={styles.dblRow}>
        <FormItem
          name='phoneNumber'
          label='Bank Phone Number'
          rules={[{ required: true, validator: requiredValidator('Bank Phone Number') }]}
        >
          <TextField disabled={view} placeholder='Enter Bank Phone Number' />
        </FormItem>

        <FormItem
          name='email'
          label='Bank Email'
          rules={[
            { required: true, validator: requiredValidator('Email') },
            { validator: emailValidator },
          ]}
        >
          <TextField disabled={view} placeholder='Enter Bank Email' />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem
          name='website'
          label='Bank Website'
          rules={[{ required: true, validator: requiredValidator('Bank Bank Website') }]}
        >
          <TextField disabled={view} placeholder='Enter Bank Bank Website' />
        </FormItem>

        <FormItem
          name='hqAddress'
          label='Bank HQ Address'
          rules={[{ required: true, validator: requiredValidator('Bank HQ Address') }]}
        >
          <TextField disabled={view} placeholder='Enter Bank HQ Address' />
        </FormItem>
      </div>

      <FormItem noStyle shouldUpdate>
        {({ setFieldValue }) => (
          <FormItem
            name='swift'
            label='SWIFT'
            rules={[
              { required: true, validator: requiredValidator('Swift') },
              { validator: maxLengthValidator(9) },
            ]}
            wrapperCol={{ span: 12 }}
            style={{ marginRight: 24 }}
            className='noBottomMargin'
          >
            <TextField
              disabled={view}
              placeholder='Enter SWIFT'
              onChange={(e) => {
                setFieldValue('swift', e.target.value?.toUpperCase())
              }}
            />
          </FormItem>
        )}
      </FormItem>
    </Wrapper>
  )
}
