import React, { memo, ReactElement } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import camelcase from 'camelcase'

interface IProps {
  actionType: string
  onClickByType: (type: string) => void
  icon: ReactElement
}

const ChatInfoAction = ({ actionType, onClickByType, icon }: IProps) => {
  return (
    <div className={styles.actionContainer} onClick={() => onClickByType(actionType)}>
      <div className={classNames(styles.actionIcon, styles[`${camelcase(actionType)}`])}>
        {icon}
      </div>
      <div className={styles.actionTitle}>{actionType}</div>
    </div>
  )
}

export default memo(ChatInfoAction)
