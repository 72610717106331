import React, { ChangeEvent, Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Col, Row, Space } from 'antd'
import { FilterPopup } from 'src/shared/components/filter/FilterPopup'
import { Filter, ISelectedFilters } from 'src/shared/components/filter/Filter'
import { useFilter } from 'src/shared/hooks/filter/useFilter'
import { IFilterFields } from 'src/shared/models/IFilterFields'
import { RangePicker } from '../../../../../../shared/components/datePicker'
import { Moment } from 'moment/moment'
import { Dayjs } from 'dayjs'
import { nanoid } from 'nanoid'
import { CloudDownloadIcon, PauseIcon, PlayArrowIcon } from '../../../../../../assets/svg'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { ExportReportModal } from '../ExportReportModal'
import { moment } from '../../../../../../helpers/utils'
import { TotalSalesPieChart } from '../TotalSalesPieChart'
import styles from '../../style.module.scss'
import { SalesSummaryBarChart } from '../SalesSummaryBarChart'
import { WIDGET_LIST_ENUM } from '../../../../../../shared/models/WidgetListEnum'
import { TextField } from '../../../../../../shared/components/textfield/TextField'
import { ExtTransRepWidgetType, initialState } from '../../ExternalTransactionReport'

interface IProps {
  onDateChange?: (value: Moment[] | null) => void
  setActiveFilters: (values: ISelectedFilters) => void
  filterValues: ISelectedFilters
  data?: IFilterFields[]
  value?: [Moment, Moment] | [Dayjs, Dayjs]
  setIsPlaying?: Dispatch<SetStateAction<boolean>>
  counter?: number
  seconds?: number
  isPlaying?: boolean
  onTimeChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onStartTimerClick?: () => void
  widgetData?: ExtTransRepWidgetType
}

export const TableHeader = ({
  setActiveFilters,
  data,
  filterValues,
  value,
  onDateChange,
  onTimeChange,
  counter,
  seconds,
  onStartTimerClick,
  isPlaying = false,
  widgetData = initialState,
}: IProps): JSX.Element => {
  const [modalKey, setModalKey] = useState(() => nanoid())
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { isOpen, onCancel, form, onReset, onFinish } = useFilter({ setActiveFilters })

  const handleModalCancel = () => {
    setIsModalOpen(false)
    setTimeout(() => {
      setModalKey(nanoid())
    }, 300)
  }

  const exportTableParams = useMemo(
    () => ({
      ...filterValues,
      ...(value?.length === 2 && {
        createdAt_more: moment.isMoment(value[0])
          ? value[0]?.toISOString()
          : value[0]?.toISOString(),
        createdAt_less: moment.isMoment(value[1])
          ? value[1]?.toISOString()
          : value[1]?.toISOString(),
      }),
    }),
    [filterValues, value]
  )

  return (
    <>
      <Row align='middle' justify='space-between' style={{ marginBottom: '20px' }}>
        <Col>
          <Filter
            data={data}
            form={form}
            setIsOpen={onCancel}
            selectedFilters={filterValues}
            setSelectedFilters={setActiveFilters}
          />
        </Col>
        <ExportReportModal
          isOpen={isModalOpen}
          key={modalKey}
          handleCancel={handleModalCancel}
          exportTableParams={exportTableParams}
        />
        {onDateChange && (
          <Space size={30}>
            <div className={styles.timer}>
              {isPlaying ? (
                <p>{seconds}</p>
              ) : (
                <TextField
                  className={styles.timerInput}
                  disabled={isPlaying}
                  type='number'
                  value={counter}
                  onChange={onTimeChange}
                />
              )}
              <div className={styles.timerIcon} onClick={onStartTimerClick}>
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              </div>
            </div>

            <Space size={10}>
              <IconButton
                onClick={() => setIsModalOpen(true)}
                icon={<CloudDownloadIcon />}
                type='primary'
                color='blue'
                size='middle'
              />
              <RangePicker value={value} onChange={(value) => onDateChange(value as Moment[])} />
            </Space>
          </Space>
        )}
      </Row>
      {isOpen && (
        <FilterPopup
          data={data}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          form={form}
          onCancel={onCancel}
          onReset={onReset}
          onFinish={onFinish}
        />
      )}
      {onDateChange && (
        <div className={styles.tableCharts}>
          <TotalSalesPieChart data={widgetData[WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS].value} />
          <SalesSummaryBarChart
            data={widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY].value}
            frequency={widgetData[WIDGET_LIST_ENUM.SALES_SUMMARY].frequency}
          />
        </div>
      )}
    </>
  )
}
