import { Form, Select, Spin } from 'antd'
import { useGetTagsQuery } from 'src/features/Support/core/http/TicketSystemApi'
import { requiredMulSelectValidator } from 'src/helpers/validation'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'

import styles from '../../style.module.scss'

export const Tags = () => {
  const { data, isFetching } = useGetTagsQuery()

  return (
    <Wrapper title='Tags' className={styles.tagsContainer}>
      <Form.Item
        name='tags'
        label='Tags'
        rules={[{ required: true, validator: requiredMulSelectValidator('Tags') }]}
      >
        <Select
          placement='topLeft'
          showSearch
          allowClear
          mode='tags'
          placeholder='Select Tags'
          options={data?.items}
          style={{ width: '100%' }}
          notFoundContent={isFetching ? <Spin size='small' /> : null}
        />
      </Form.Item>
    </Wrapper>
  )
}
