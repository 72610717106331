import { ColumnsType } from 'antd/es/table'
import { IMasterProduct } from '../../../../models/IMasterProduct'
import { Link, useNavigate } from 'react-router-dom'
import emptyAvatarImage from 'src/assets/img/ProductTablePlaceholder.png'
import { DateTableIcon, TableActionIcon } from '../../../../../../assets/svg'
import { formatDateWithTime, tableExpandedIcon } from '../../../../../../helpers/utils'
import { Switch, Table } from 'antd'
import { useCallback, useContext, useEffect, useState } from 'react'
import { ISelectedFilters } from '../../../../../../shared/components/filter/Filter'
import { ITableConf, SortingOrderEnum } from '../../../../../../shared/models/ITableConf'
import {
  useGetMasterProductsFiltersListQuery,
  useGetMasterProductsListQuery,
  useLazyExportMasterProductsTableQuery,
  useLazyGetProductsListQuery,
  usePatchProductMutation,
  useUpdateMasterProductMutation,
} from '../../../../core/http/VendorManagementApi'
import { VendorManagementTableHeader } from '../TableHeader'
import styles from './style.module.scss'
import { IPopupListItems, Popup } from '../../../../../../shared/components/popup/Popup'
import { startCase } from 'lodash'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../shared/api/errorHandler'
import { IProduct } from '../../../../models/IProduct'
import { RightModalContext } from '../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../helpers/contants'
import { useEmptyTable } from '../../../../../../shared/hooks/table/useEmptyTable'
import { useTableExport } from '../../../../../../shared/hooks/table/useTableExport'
import { SETTINGS_ENUM } from '../../../../../../routes/settings'
import { ITableResponse } from '../../../../../../shared/models/ITableResponse'
import { IByUser } from '../../../../../../shared/models/IByUser'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../shared/hooks/useLocalStorageGetByKey'
import { Emitter, EmitterEvents } from '../../../../../../helpers/eventEmitter'

interface IMasterProductWithChildren extends Omit<IMasterProduct, 'products'> {
  children?: Array<IProduct>
  products: Omit<ITableResponse<IProduct>, 'currentPage'>
  isMasterProduct?: boolean
  vendor?: IByUser
}

interface IProps {
  canAdd: boolean
  canEdit: boolean
  canDelete?: boolean
}

export const MasterProductsTable = ({ canAdd, canEdit }: IProps) => {
  const navigate = useNavigate()
  const { onOpen } = useContext(RightModalContext)
  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const [expandedRowKeys, setExpandedRowKeys] = useState<Array<React.Key>>([])
  const [mappedMasters, setMappedMasters] = useState<IMasterProductWithChildren[]>([])
  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data: profile } = useGetProfileQuery()

  const { data: filtersList } = useGetMasterProductsFiltersListQuery()
  const { data = { totalCount: 0, current: 1, items: [] }, isFetching } =
    useGetMasterProductsListQuery(
      { ...filterValues, ...tableConf },
      { skip: Object.keys(tableConf).length < 4 }
    )

  const [getProd, getProdResp] = useLazyGetProductsListQuery()
  const [exportTable] = useLazyExportMasterProductsTableQuery()

  useTableExport({ tableConf, filterValues, promise: exportTable })

  const emptyTableConf = useEmptyTable(
    canAdd ? () => SETTINGS_ENUM.VENDOR_MANAGEMENT_MASTER_PRODUCT_NEW : undefined
  )
  const { pagination, handleTableChange, handleFiltersChange } =
    useTable<IMasterProductWithChildren>({
      total: data?.totalCount,
      setTableConf,
      setFilterValues,
      setExpandedRowKeys,
    })

  const mutateInputData = useCallback((masterProducts: IMasterProductWithChildren[]) => {
    return masterProducts.map((master) => {
      if (master.products.totalCount > 0) {
        return { ...master, children: [], isMasterProduct: true }
      }
      return { ...master, isMasterProduct: true }
    })
  }, [])

  const tableActionsPopup = useCallback(
    (row: IMasterProductWithChildren): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () => {
            row.isMasterProduct
              ? navigate(`master-product/view/${row.id}`)
              : onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_PRODUCT, {
                  title: 'View Products',
                  mode: 'view',
                  id: row.id,
                  vendorId: row?.vendor?.id,
                })
          },
        },
        {
          text: 'Edit',
          shouldDisplay: canEdit,
          onClick: () => {
            row.isMasterProduct
              ? navigate(`master-product/edit/${row.id}`)
              : onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_PRODUCT, {
                  title: 'Edit Products',
                  mode: 'edit',
                  id: row.id,
                  vendorId: row?.vendor?.id,
                })
          },
        },
      ]
    },
    [canEdit, navigate, onOpen]
  )

  const [upMastProd, upMastProdResp] = useUpdateMasterProductMutation()
  const [patchProd, patchProdResp] = usePatchProductMutation()
  useNotification(
    NOTIFICATION_TYPES.error,
    upMastProdResp.isError,
    upMastProdResp.error as ErrorNode
  )

  const columns: ColumnsType<IMasterProductWithChildren> = [
    {
      title: '',
      dataIndex: '',
      key: 'expand',
      width: '1%',
      onCell: (record) => {
        return {
          onClick: async () => {
            const mappedRec = { ...record }
            const data = await getProd({ masterProductId: record.id })
            mappedRec.children = data?.data?.items

            setMappedMasters((prevState) =>
              prevState.map((trans) => {
                if (trans.id === mappedRec.id) {
                  return mappedRec
                }
                return trans
              })
            )
          },
        }
      },
    },
    {
      title: 'Master Product Id',
      dataIndex: 'id',
      sorter: true,
      width: '10%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      width: '30%',
      render: (masterProductName: IMasterProductWithChildren['name'], row) => {
        if (row.isMasterProduct) {
          return (
            <RecursivelyReplaceCharacters>
              <Link to={`master-product/view/${row.id}`} className='table-avatar'>
                <img
                  alt='masterProd logo'
                  src={!isPrivateMode && row.logo ? row.logo : emptyAvatarImage}
                />
                <div>{masterProductName}</div>
              </Link>
            </RecursivelyReplaceCharacters>
          )
        }
        return (
          <RecursivelyReplaceCharacters>
            <a
              onClick={() =>
                onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_PRODUCT, {
                  title: 'View Products',
                  mode: 'view',
                  id: row.id,
                })
              }
              className='table-avatar'
            >
              <img
                alt='product logo'
                src={!isPrivateMode && row.logo ? row.logo : emptyAvatarImage}
              />
              <div>{masterProductName}</div>
            </a>
          </RecursivelyReplaceCharacters>
        )
      },
    },
    {
      title: 'Product Type',
      dataIndex: 'type',
      sorter: true,
      width: '10%',
      render: (type: IMasterProductWithChildren['type']) => (
        <RecursivelyReplaceCharacters>{startCase(type.toLowerCase())}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Products',
      dataIndex: 'products',
      width: '10%',
      render: (products: IMasterProductWithChildren['products'], row) =>
        row?.products &&
        products.totalCount > 0 && (
          <div className={styles.productsImages}>
            {products.items?.map((product: IProduct) => (
              <RecursivelyReplaceCharacters key={product.id}>
                <img
                  alt='vendor logo'
                  src={!isPrivateMode && product.logo ? product.logo : emptyAvatarImage}
                  width={38}
                  height={38}
                />
              </RecursivelyReplaceCharacters>
            ))}
            {products.totalCount > 2 && (
              <div className={styles.productsCount}>+{products.totalCount - 2}</div>
            )}
          </div>
        ),
    },
    {
      title: 'Added by',
      dataIndex: 'createdByUser',
      width: '20%',
      sorter: true,
      render: (createdBy: IMasterProductWithChildren['createdByUser']) =>
        createdBy && <RecursivelyReplaceCharacters>{createdBy.name}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      width: '20%',
      sorter: true,
      render: (createdAt: IMasterProductWithChildren['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(createdAt, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: '10%',
      render: (status: IMasterProductWithChildren['status'], row) => (
        <Switch
          disabled={!canEdit}
          defaultChecked={status}
          onChange={(status) => {
            row.isMasterProduct
              ? upMastProd({ id: row.id, body: { status } })
              : patchProd({ vendorId: row.id, status })
          }}
          loading={upMastProdResp.isLoading || patchProdResp.isLoading}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  useEffect(() => {
    setMappedMasters(mutateInputData(data?.items as IMasterProductWithChildren[]))
  }, [data, mutateInputData])

  useEffect(() => {
    const resetKeys = () => setExpandedRowKeys([])
    Emitter.on(EmitterEvents.UPDATE_PRODUCT, resetKeys)

    return () => {
      Emitter.off(EmitterEvents.UPDATE_PRODUCT, resetKeys)
    }
  }, [])

  return (
    <>
      <VendorManagementTableHeader
        data={filtersList}
        setActiveFilters={handleFiltersChange}
        numberOfItems={data?.totalCount}
        filterValues={filterValues}
        numberText='Master Products'
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        pagination={pagination}
        onChange={handleTableChange}
        className={styles.table}
        dataSource={mappedMasters}
        scroll={{ x: 1000 }}
        loading={isFetching || getProdResp.isFetching}
        expandable={{
          expandedRowKeys,
          expandIcon: (props) => {
            if (!Object.hasOwn(props.record, 'products')) {
              return null
            } else if (props.record.products.totalCount === 0) {
              return null
            }
            return tableExpandedIcon(props)
          },
          onExpandedRowsChange: (expandedRows) => {
            setExpandedRowKeys([...expandedRows])
          },
        }}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.createdAt}
      />
    </>
  )
}
