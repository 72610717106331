import styles from './styles.module.scss'
import { VendorManagementTabs } from './Components/Tabs/index'

export const VendorManagement = () => {
  return (
    <div className={styles.layout}>
      <VendorManagementTabs />
    </div>
  )
}
