import { IByUser } from '../../../shared/models/IByUser'
import { TRANSACTION_STATUS_ENUM } from './ITransaction'
import { Moment } from 'moment'

export enum PURCHASE_PLAN_TYPE_ENUM {
  EACH_DAY = 'EACH_DAY',
  MANUALLY = 'MANUALLY',
}

export enum PURCHASE_PLAN_TIME_OPTIONS {
  EACH_DAY = 'EACH_DAY',
  MANUALLY_PER_WEEK = 'MANUALLY_PER_WEEK',
  MANUALLY_PER_DAY = 'MANUALLY_PER_DAY',
  MANUALLY_CUSTOM = 'MANUALLY_CUSTOM',
}

export interface IPurchasePlanLog {
  id: string
  user: IByUser
  createdAt: string
}

export interface IPurchasePlanDistributionRange {
  id: string
  start: string
  end: string
  date: [Moment, Moment]
  percent: number
  amount: number
}

export interface IPurchasePlanVendor extends IByUser {
  type: string
}

export interface IPurchasePlan {
  id: string
  monthRange: string
  vendor: IPurchasePlanVendor
  cashAmount: number
  eMoneyAmount: number
  thresholdAmount: number
  thresholdDenomination: number
  type: PURCHASE_PLAN_TIME_OPTIONS
  logs: Array<IPurchasePlanLog>
  distributionRanges: Array<IPurchasePlanDistributionRange>
  timeOption?: PURCHASE_PLAN_TIME_OPTIONS
  vendorId?: string
}

interface IDistributionRange {
  start: string
  end: string
  percent: number
  amount: number
}

export interface ICreatePurchasePlan {
  monthRange: string
  cashAmount: number
  eMoneyAmount: number
  type: PURCHASE_PLAN_TIME_OPTIONS
  distributionRanges: Array<IDistributionRange>
}

export interface IInventoryTable {
  inventoryId: string
  vendorId: string
  logo: string
  name: string
  balance: number
  targetAmount: number
  purchasedAmount: number
  averageSalesAmount: number
  lastPurchasedAt: Date
}

interface IPurchaseOrderStatus {
  id: number
  status: TRANSACTION_STATUS_ENUM
  user: IByUser
  createdAt: Date
}

export interface IInventoryTransaction {
  countSubTransaction: number
  id: number
  createdAt: Date
  vendor: IByUser
  amount: number
  currencyAmount: number
  currency: string
  status: TRANSACTION_STATUS_ENUM
  purchaseOrderStatuses: Array<IPurchaseOrderStatus>
}

export enum INVENTORY_CALENDAR_STATUS_ENUM {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  FULL = 'FULL',
}

export interface IInventoryCalendar {
  start: Date | string
  end: Date | string
  purchasedAmount: number
  targetAmount: number
  remainAmount: number
  status: INVENTORY_CALENDAR_STATUS_ENUM
}

export enum INVENTORY_CHART_TYPE_ENUM {
  YEAR = 'year',
  MONTH = 'month',
  WEEK = 'week',
}

export interface IInventoryChart {
  date?: Date
  start?: Date
  end?: Date
  purchasedAmount: number
  targetAmount: number
}
