import { useNavigate, useParams } from 'react-router'
import { Form, Row, Spin } from 'antd'
import { useGetBankAccountsQuery } from '../../../../core/http/BankAccountApi'
import {
  useLazyGetAgentByIdQuery,
  useLazyGetRootAgentByIdQuery,
} from '../../../../../salesNetwork/core/http/AgentManagement'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../../shared/hooks/useNotification'
import ShowMessage, { ErrorNode } from '../../../../../../shared/api/errorHandler'
import React, { useCallback, useEffect } from 'react'
import { debounce, isEmpty } from 'lodash'
import { formatOnlyDate } from '../../../../../../helpers/utils'
import styles from '../PickupRequest/styles.module.scss'
import { DetailInfo } from '../PickupRequest/Components/DetailInfo'
import { StatusInfo } from '../PickupRequest/Components/StatusInfo'
import { LogOfOrder } from '../PickupRequest/Components/LogOfOrder'
import { IPurchaseOrderStatus } from '../../../../models/IPurchaseOrder'
import { Button } from '../../../../../../shared/components/button/Button'
import {
  useCreateRefundReqMutation,
  useGetRufundReqByIdQuery,
  useUpdateRefundReqMutation,
} from '../../../../core/http/BankWalletTransactionsApi'
import { IRefundRequestForm } from '../../../../models/IRefundRequest'
import usePermissions from '../../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../../Settings/models/IPermissions'
import PermissionDenied from '../../../../../../shared/components/permissionDenied'
import { PickupRequestModeEnum } from '../../index'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

export const RefundRequest = () => {
  const { mode, id } = useParams()
  const navigate = useNavigate()
  const { data: profile } = useGetProfileQuery()

  const [form] = Form.useForm()
  const { data, isFetching } = useGetRufundReqByIdQuery(id as string, { skip: !id })
  const { data: bankAccountList } = useGetBankAccountsQuery({})

  const [createRR, dCreate] = useCreateRefundReqMutation()
  const [updateRR, dUpdate] = useUpdateRefundReqMutation()

  const [getAgent, dAgent] = useLazyGetAgentByIdQuery()
  const [getRootAgent, dRootAgent] = useLazyGetRootAgentByIdQuery()

  const isViewMode = mode === PickupRequestModeEnum.view
  const isEditMode = mode === PickupRequestModeEnum.edit
  const isNewMode = !isViewMode && !isEditMode

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()
  const [pickupsId] = getModulesIdsByNames([IPermissionsNames['FinanceRefund eMoney']])
  const demandPermissions = [
    IPermissionsActions.VIEW,
    IPermissionsActions.EDIT,
    IPermissionsActions.ADD,
  ]
  const [canViewFinanceRefundEMoney, canEditFinanceRefundEMoney, canAddFinanceRefundEMoney] =
    canPerformAction(pickupsId, demandPermissions)
  const notAllowedContent =
    (isViewMode && !canViewFinanceRefundEMoney) ||
    (isEditMode && !canEditFinanceRefundEMoney) ||
    (isNewMode && !canAddFinanceRefundEMoney)

  const afterCreate = () => {
    navigate('/finance/operation-wallet/')
  }

  const handleNotExistingAgent = () => {
    form.setFieldValue(['agent', 'name'], null)
    form.setFieldValue('parentAgentId', null)
    form.setFieldValue('parentAgentName', null)
    form.setFieldValue('accountType', null)
  }

  useNotification(NOTIFICATION_TYPES.success, dUpdate.isSuccess, null, afterCreate)
  useNotification(NOTIFICATION_TYPES.success, dCreate.isSuccess, null, afterCreate)
  useNotification(NOTIFICATION_TYPES.error, dUpdate.isError, dUpdate.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, dCreate.isError, dCreate.error as ErrorNode)
  useNotification(
    NOTIFICATION_TYPES.error,
    dAgent.isError,
    dAgent.error as ErrorNode,
    handleNotExistingAgent
  )

  useEffect(() => {
    if (isEmpty(data)) return
    const { agent } = data
    form.setFieldsValue({ ...data, createdAt: formatOnlyDate(data.createdAt, profile?.calendar) })
    if (agent?.id) {
      getRootAgent(agent.id)
    }
  }, [data, form, getRootAgent])

  useEffect(() => {
    if (isEmpty(dAgent.data)) return
    const agent = dAgent.data

    form.setFieldValue(
      ['agent', 'name'],
      agent.companyTitle ? `${agent.companyTitle}` : `${agent.firstName} ${agent.lastName}`
    )
  }, [dAgent.data, dAgent.requestId, form, data])

  useEffect(() => {
    if (isEmpty(dRootAgent.data)) return
    const rootAgent = dRootAgent.data
    form.setFieldValue('parentAgentId', rootAgent.id)
  }, [dRootAgent.data, dRootAgent.requestId, data, form])

  // eslint-disable-next-line
  const debounceGetAgent = useCallback(
    debounce((value) => {
      Promise.all([getAgent(value), getRootAgent(value)])
    }, 300),
    []
  )

  const handlerAgenIdChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.value) return
    debounceGetAgent(event.target.value)
  }

  const handleFinish = (values: IRefundRequestForm) => {
    const { agent, amount, note, bankAccount, paymentType } = values

    const body = {
      agentId: +agent.id,
      amount: +amount,
      bankAccountId: Number(bankAccount?.id),
      paymentType,
      note,
    }

    if (dAgent.data && Number(body.amount) > Number(dAgent.data?.wallet?.amount as number)) {
      return ShowMessage(
        'error',
        `Refund amount cannot be higher than ${dAgent.data?.wallet?.amount}`
      )
    }

    isEditMode ? updateRR({ id: id!, body }) : createRR(body)
  }

  const content = (
    <>
      {notAllowedContent && <PermissionDenied />}
      {((isViewMode && canViewFinanceRefundEMoney) ||
        (isEditMode && canEditFinanceRefundEMoney) ||
        (isNewMode && canAddFinanceRefundEMoney)) && (
        <main className={styles.layout}>
          <Form
            form={form}
            layout='vertical'
            initialValues={data}
            className={styles.pickupForm}
            onFinish={handleFinish}
          >
            <Row wrap={false} justify='start'>
              <div className={styles.leftColumn}>
                <DetailInfo
                  form={form}
                  viewMode={isViewMode}
                  editMode={isEditMode}
                  bankAccountList={bankAccountList?.items}
                  onChangeAgent={handlerAgenIdChange}
                />
              </div>
              {(isViewMode || isEditMode) && (
                <div className={styles.rightColumn}>
                  <StatusInfo status={data?.status as string} />
                  {/* <UploaderFiles /> */}
                  <LogOfOrder logs={data?.AgentRequestSubTransaction as IPurchaseOrderStatus[]} />
                </div>
              )}
            </Row>
          </Form>
        </main>
      )}
    </>
  )

  const isLoading = dCreate.isLoading || dUpdate.isLoading // only for mutation
  const submitBtnText = isEditMode ? 'Edit Refund Request' : 'Create Refund Request'

  return (
    <React.Fragment>
      {isLoading || isFetching || dAgent.isFetching ? <Spin>{content}</Spin> : content}
      {!isViewMode && (
        <Row className={styles.footer} justify='end'>
          <Button color='blue' size='middle' onClick={afterCreate}>
            Cancel
          </Button>
          {((isNewMode && canAddFinanceRefundEMoney) ||
            (isEditMode && canEditFinanceRefundEMoney)) && (
            <Button
              style={{ marginLeft: '10px' }}
              htmlType='submit'
              size='middle'
              type='primary'
              color='orange'
              onClick={() => form.submit()}
              disabled={isLoading}
            >
              {submitBtnText}
            </Button>
          )}
        </Row>
      )}
    </React.Fragment>
  )
}
