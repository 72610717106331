import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../../../../shared/api'
import { ITableResponse } from '../../../../shared/models/ITableResponse'
import { IFileFormatCsvOrXlsx, ITableConf } from '../../../../shared/models/ITableConf'
import {
  IAgentPromotion,
  IPromotionPolicy,
  PromotionPolicyCreateType,
} from '../../models/IPromotionPolicy'
import { IFilterFields } from '../../../../shared/models/IFilterFields'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { ITargetGroup } from '../../models/ITargetGroup'

export const promotionPolicyApi = createApi({
  reducerPath: 'promotionPolicy',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['TargetGroups', 'PromotionList', 'Promotion', 'FilterFields'],
  endpoints: (builder) => ({
    getPromotionPolicyList: builder.query<ITableResponse<IPromotionPolicy>, ITableConf>({
      query: (params) => ({
        url: 'promotion-policy',
        params,
      }),
      providesTags: ['PromotionList'],
    }),

    getPromotionPolicyById: builder.query<IPromotionPolicy, string>({
      query: (id) => ({
        url: `promotion-policy/${id}`,
      }),
      providesTags: (result) => [{ type: 'Promotion', id: result?.id }],
    }),

    createPromotionPolicy: builder.mutation<IPromotionPolicy, PromotionPolicyCreateType>({
      query: (body) => ({
        url: `promotion-policy`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PromotionList', 'FilterFields'],
    }),

    deletePromotionPolicy: builder.mutation<void, string | number>({
      query: (id) => ({
        url: `promotion-policy/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PromotionList', 'FilterFields'],
    }),

    updatePromotionPolicy: builder.mutation<
      IPromotionPolicy,
      { id: string | number; body: Partial<PromotionPolicyCreateType> }
    >({
      query: ({ id, body }) => ({
        url: `promotion-policy/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result) => [
        'PromotionList',
        'FilterFields',
        { type: 'Promotion', id: result?.id },
      ],
    }),

    exportPromotionPolicyTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx'; id: string }
    >({
      query: ({ conf, formatFile }) => ({
        url: `promotion-policy/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getPromotionPolicyFilterList: builder.query<Array<IFilterFields>, void>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: 'promotion-policy/filter-fields',
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `promotion-policy/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
      providesTags: ['FilterFields'],
    }),

    assignTargetToPromotionPolicy: builder.mutation<void, { promotionId: string; groupId: number }>(
      {
        query: ({ promotionId, groupId }) => ({
          url: `promotion-policy/${promotionId}/target-groups/${groupId}`,
          method: 'POST',
        }),
        invalidatesTags: ['TargetGroups'],
      }
    ),

    unassignTargetFromPromotionPolicy: builder.mutation<
      void,
      { promotionId: string | number; groupId: number | string }
    >({
      query: ({ promotionId, groupId }) => ({
        url: `promotion-policy/${promotionId}/target-groups/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TargetGroups'],
    }),

    getPPTargetGroupsList: builder.query<ITableResponse<ITargetGroup>, ITableConf>({
      query: ({ id, ...params }) => ({
        url: `promotion-policy/${id}/target-groups?withGroupSize=true`,
        params,
      }),
      providesTags: ['TargetGroups'],
    }),

    exportPPTargetGroupsTable: builder.query<
      BlobPart,
      { conf: IFileFormatCsvOrXlsx; formatFile: 'csv' | 'xlsx'; id: string }
    >({
      query: ({ conf, formatFile }) => ({
        url: `promotion-policy/${conf.id}/target-groups/export`,
        params: conf,
        headers: {
          'Content-Type': `text/${formatFile}; charset=utf-8`,
          'Content-Disposition': 'attachment;',
        },
        responseHandler: (response) => response.blob(),
      }),
    }),

    getPPTargetGroupsFilterList: builder.query<Array<IFilterFields>, string>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        const filterFields = (await baseQuery({
          url: `promotion-policy/${arg}/target-groups/filter-fields`,
        })) as {
          data: IFilterFields[]
          error?: FetchBaseQueryError
        }

        if (filterFields.error) return { error: filterFields.error as FetchBaseQueryError }

        const promiseQueue = filterFields.data.map(async (filter) => {
          const res = await baseQuery({
            url: `promotion-policy/${arg}/target-groups/filter-values?filterField=${filter.field}`,
          })
          if (res.error) return { error: filterFields.error as FetchBaseQueryError }
          return { field: filter.field, data: res.data }
        })

        const filterValues = await Promise.all(promiseQueue)
        const response = filterFields.data.map((field, index) => ({
          ...field,
          ...filterValues[index],
        }))

        return filterFields.data ? { data: response } : { error: filterFields.error }
      },
    }),

    getAgentPromotionById: builder.query<IAgentPromotion, string>({
      query: (id) => ({
        url: `agent-promotion/${id}`,
      }),
    }),
  }),
})

export const {
  useGetPromotionPolicyListQuery,
  useGetPromotionPolicyFilterListQuery,
  useLazyExportPromotionPolicyTableQuery,
  useGetPromotionPolicyByIdQuery,
  useGetPPTargetGroupsListQuery,
  useGetPPTargetGroupsFilterListQuery,
  useAssignTargetToPromotionPolicyMutation,
  useUnassignTargetFromPromotionPolicyMutation,
  useUpdatePromotionPolicyMutation,
  useDeletePromotionPolicyMutation,
  useCreatePromotionPolicyMutation,
  useLazyExportPPTargetGroupsTableQuery,
  useGetAgentPromotionByIdQuery,
} = promotionPolicyApi
