import { TableHeader } from '../../../Components/InventoryTable/TableHeader'
import { Table } from 'antd'
import styles from '../../../InventoryView/styles.module.scss'
import React, { useState } from 'react'
import { IInventoryTransaction } from '../../../../../models/IInventory'
import { IPopupListItems, Popup } from '../../../../../../../shared/components/popup/Popup'
import {
  ITrustWalletTransaction,
  TRANSACTION_CATEGORY_ENUM,
} from '../../../../../models/ITransaction'
import { ColumnsType } from 'antd/es/table'
import { DateTableIcon, TableActionIcon } from '../../../../../../../assets/svg'
import { formatDateWithTime } from '../../../../../../../helpers/utils'
import emptyProductImage from '../../../../../../../assets/img/ProductTablePlaceholder.png'
import { formatNumberToLocale } from '../../../../../../salesNetwork/helpers'
import classNames from 'classnames'
import { camelCase } from 'lodash'
import { useEmptyTable } from '../../../../../../../shared/hooks/table/useEmptyTable'
import useTable from '../../../../../../../shared/hooks/table/useTable'
import { ISelectedFilters } from '../../../../../../../shared/components/filter/Filter'
import { ITableConf, SortingOrderEnum } from '../../../../../../../shared/models/ITableConf'
import { useGetQueryParams } from '../../../../../../../shared/hooks/table/useGetQueryParams'
import { useOnTransactionViewClick } from '../../../../../../../shared/hooks/table/useOnTransactionViewClick'
import { useGetProfileQuery } from '../../../../../../Profile/core/http/Profile'
import { useLocalStorageGetByKey } from '../../../../../../../shared/hooks/useLocalStorageGetByKey'
import { useParams } from 'react-router-dom'
import {
  useGetInventoryTransactionsFiltersListQuery,
  useGetInventoryTransactionsListQuery,
} from '../../../../../core/http/VendorInventoryApi'
import { VENDOR_WALLET_TYPE_ENUM } from '../../../../../models/IPurchaseOrder'
import { RecursivelyReplaceCharacters } from '../../../../../../../shared/components/privateMode'

export const PoTable = () => {
  const onTransactionClick = useOnTransactionViewClick()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const { data: profile } = useGetProfileQuery()

  const { id } = useParams()
  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const { data: filterFields } = useGetInventoryTransactionsFiltersListQuery({
    id: id as string,
    vendorWalletType: VENDOR_WALLET_TYPE_ENUM.E_PIN,
  })
  const { data, isFetching } = useGetInventoryTransactionsListQuery({
    vendorId: id as string,
    params: { ...tableConf, ...filterValues, vendorWalletType: VENDOR_WALLET_TYPE_ENUM.E_PIN },
  })

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IInventoryTransaction>({
    total: data?.totalCount,
    setTableConf,
    setFilterValues,
  })

  const tableActionsPopup = (row: IInventoryTransaction): IPopupListItems[] => [
    {
      text: 'View',
      shouldDisplay: true,
      onClick: () => onTransactionClick(row?.id, TRANSACTION_CATEGORY_ENUM.PURCHASE_ORDER, null),
    },
  ]

  const columns: ColumnsType<IInventoryTransaction> = [
    {
      title: 'Transaction ID',
      dataIndex: 'id',
      sorter: true,
      width: '15%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      width: '15%',
      sorter: true,
      render: (date: IInventoryTransaction['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            <span>{formatDateWithTime(date, profile?.calendar)}</span>
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      width: '25%',
      sorter: true,
      render: (vendor: IInventoryTransaction['vendor']) => (
        <div className='table-avatar'>
          <img
            alt='user-photo'
            src={!isPrivateMode && vendor?.logo ? vendor?.logo : emptyProductImage}
            loading='lazy'
          />
          <RecursivelyReplaceCharacters>
            <div>{vendor?.name}</div>
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'currencyAmount',
      width: '20%',
      sorter: true,
      render: (amount) => (
        <RecursivelyReplaceCharacters>{formatNumberToLocale(amount)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Emoney Amount',
      dataIndex: 'amount',
      width: '20%',
      sorter: true,
      render: (amount) => (
        <RecursivelyReplaceCharacters>{formatNumberToLocale(amount)}</RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '25%',
      sorter: true,
      render: (status: ITrustWalletTransaction['status']) => (
        <RecursivelyReplaceCharacters>
          <div className={classNames('statusContainer', `${status.toLowerCase()}-container`)}>
            <div className={classNames(camelCase(status.toLowerCase()), 'statusPoint')} />
            <div>{status}</div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '10%',
      render: (_, row) => {
        return (
          <Popup data={tableActionsPopup(row)}>
            <div className='table-kebab-actions'>
              <TableActionIcon />
            </div>
          </Popup>
        )
      },
    },
  ]

  return (
    <>
      <TableHeader
        setActiveFilters={handleFiltersChange}
        filterValues={filterValues}
        data={filterFields}
      />
      <Table
        rowSelection={{ type: 'checkbox' }}
        columns={columns}
        className={styles.table}
        locale={emptyTableConf}
        pagination={pagination}
        dataSource={data?.items}
        loading={isFetching}
        onChange={handleTableChange}
        rowKey={(row) => row.id}
        scroll={{ x: 1000 }}
      />
    </>
  )
}
