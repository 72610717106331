import { useState } from 'react'
import { Select, Spin } from 'antd'
import { Document, Page } from 'react-pdf'
import { PaginationNextIcon, PaginationPrevIcon } from 'src/assets/svg'
import styles from '../styles.module.scss'

export const PDFViewer = ({ src }: { src: string }) => {
  const [numPages, setNumPages] = useState<number | null>(null)
  const [pageNumber, setPageNumber] = useState(1)

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  return (
    <div className={styles.pdfViewer}>
      <Document
        file={src}
        onLoadSuccess={onDocumentLoadSuccess}
        className={styles.document}
        loading={
          <div className={styles.loadingBlock}>
            <Spin />
          </div>
        }
      >
        <Page
          pageNumber={pageNumber}
          loading={
            <div className={styles.loadingBlock}>
              <Spin />
            </div>
          }
        />
      </Document>
      <div className={styles.pagination}>
        <div className={styles.paginationNav}>
          <div className='ant-pagination pdf-pagination'>
            <Select defaultValue={pageNumber} onChange={(val) => setPageNumber(val)}>
              {Array.from(Array(numPages! + 1).keys())
                .filter((el) => el !== 0)
                .map((el) => (
                  <Select.Option key={el} value={+el}>
                    {el}
                  </Select.Option>
                ))}
            </Select>
          </div>
          <div>
            {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </div>
        </div>
        <div className={styles.paginationActions}>
          <button
            type='button'
            disabled={pageNumber <= 1}
            onClick={previousPage}
            className={styles.paginationButton}
          >
            <PaginationPrevIcon />
          </button>
          <button
            type='button'
            disabled={pageNumber >= numPages!}
            onClick={nextPage}
            className={styles.paginationButton}
          >
            <PaginationNextIcon />
          </button>
        </div>
      </div>
    </div>
  )
}
