import { Form, Spin, UploadFile } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router'

// helpers
import { RightModalContext } from 'src/features/Modals'
import { formatDateWithTime } from 'src/helpers/utils'
import { IPurchaseOrder, IPurchaseOrderStatus } from 'src/features/Finance/models/IPurchaseOrder'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'

// api
import {
  useAddAttachmentsPOMutation,
  useApprovePurchaseOrderMutation,
  useCheckPurchaseOrderMutation,
  useGetPurchaseOrderQuery,
  useRejectPurchaseOrderMutation,
} from 'src/features/Finance/core/http/PurchaseOrderApi'

// components
import { DetailInfo } from './DetailInfo'
import { Logs } from './Logs'
import { Footer } from './Footer'
import { ErrorNode } from 'src/shared/api/errorHandler'

// styles
import styles from './styles.module.scss'
import { TRANSACTION_STATUS_ENUM } from '../../../../../../Finance/models/ITransaction'
import { FINANCE_ENUM } from '../../../../../../../routes/finance'
import { useAppDispatch } from '../../../../../../../redux'
import { bankAccountApi } from '../../../../../../Finance/core/http/BankAccountApi'
import { UploaderFiles } from 'src/shared/components/uploaderPhotos/UploaderFiles'
import { PRUploadTypes } from 'src/shared/components/uploaderPhotos/constants'
import TrustWalletSummary from './TrustWalletSummary'
import BankAccountSummary from './BankAccountSummary'
import { vendorInventoryApi } from '../../../../../../Finance/core/http/VendorInventoryApi'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

export const PurchaseOrderModal = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [form] = Form.useForm<IPurchaseOrder>()
  const {
    onClose,
    props: { id, isReport },
  } = useContext(RightModalContext)

  const [files, setFiles] = useState<UploadFile[]>([])

  const { data, isFetching } = useGetPurchaseOrderQuery(id, { skip: !id })
  const [onCheckPO, dCheck] = useCheckPurchaseOrderMutation()
  const [onRejectPO, dReject] = useRejectPurchaseOrderMutation()
  const [onApprovePO, dApprove] = useApprovePurchaseOrderMutation()
  const [addAttachmentPO, dAttahcment] = useAddAttachmentsPOMutation()

  const { data: profile } = useGetProfileQuery()

  const invalidateTags = () => {
    dispatch(bankAccountApi.util.invalidateTags(['IBankAccountList']))
    dispatch(
      vendorInventoryApi.util.invalidateTags([
        'IInventoryTransactions',
        'ICalendar',
        'IInventoryChart',
      ])
    )
  }
  const afterCreate = () => {
    invalidateTags()
    onClose()
  }

  useNotification(NOTIFICATION_TYPES.success, dCheck.isSuccess, null, invalidateTags)
  useNotification(NOTIFICATION_TYPES.error, dCheck.isError, dCheck.error as ErrorNode)

  useNotification(NOTIFICATION_TYPES.success, dReject.isSuccess, null, afterCreate)
  useNotification(NOTIFICATION_TYPES.error, dReject.isError, dReject.error as ErrorNode)

  useNotification(NOTIFICATION_TYPES.success, dApprove.isSuccess, null, afterCreate)
  useNotification(NOTIFICATION_TYPES.error, dApprove.isError, dApprove.error as ErrorNode)

  useNotification(NOTIFICATION_TYPES.success, dAttahcment.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, dAttahcment.isError, dAttahcment.error as ErrorNode)

  useEffect(() => {
    if (isEmpty(data)) return
    form.setFieldsValue({
      ...data,
      createdAt: formatDateWithTime(data.createdAt, profile?.calendar),
      currency: typeof data.currency === 'string' ? data.currency : data.currency?.textCode,
    })
  }, [data, form, profile])

  const handlerEdit = () => {
    navigate(`${FINANCE_ENUM.TRUST_WALLET}/purchase-order/edit/${id}`)
    onClose()
  }

  const dispatchFiles = (arr: UploadFile[]) => setFiles(arr)

  const onSaveAttachments = () => {
    const fd = new FormData()

    files.forEach((value) => value.originFileObj && fd.append('files', value.originFileObj!))

    addAttachmentPO({
      id,
      body: fd,
    }).then(() => {
      setFiles([])
    })
  }

  const status = data?.status || ''
  const isMutating = dApprove.isLoading || dCheck.isLoading || dReject.isLoading

  const content = (
    <Form form={form} className={styles.formContainer} layout='vertical'>
      <DetailInfo status={status} />
      <TrustWalletSummary />
      <BankAccountSummary />

      {data?.status === TRANSACTION_STATUS_ENUM.CHECKED ||
        (data?.status === TRANSACTION_STATUS_ENUM.CREATED && (
          <div style={{ marginBottom: '24px' }}>
            <UploaderFiles
              allowTypes={PRUploadTypes}
              attachmentPropName='attachment'
              btnTitle='Attach File'
              // viewFiles={[]}
              dispatchData={dispatchFiles}
              onSave={onSaveAttachments}
              isLoading={dAttahcment.isLoading}
              isVisibleAttachBtn={isReport}
              isStyledTitle
            />
          </div>
        ))}

      {/* <PaymentInfo /> */}
      <Logs logs={data?.purchaseOrderSubTransactions as Array<IPurchaseOrderStatus>} />
      {!isReport && (
        <Footer
          status={data?.status as TRANSACTION_STATUS_ENUM}
          onEdit={handlerEdit}
          onCheck={() => onCheckPO(id)}
          onReject={() => onRejectPO(id)}
          onApprove={() => onApprovePO(id)}
        />
      )}
    </Form>
  )

  return <Spin spinning={isFetching || dAttahcment.isLoading || isMutating}>{content}</Spin>
}
