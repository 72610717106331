import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { camelCase, isEmpty, startCase } from 'lodash'
import Table, { ColumnsType } from 'antd/es/table'
import { TableHeader } from '../Components/TableHeader'
import { ISelectedFilters } from '../../../../../shared/components/filter/Filter'
import { ITableConf, SortingOrderEnum } from '../../../../../shared/models/ITableConf'
import {
  useGetETFiltersListQuery,
  useGetExternalTransactionsQuery,
} from '../../../core/ExternalTransactionApi'
import { useLazyGetSubTransactionsQuery } from '../../../core/Subtransactions'
import {
  IExternalReport,
  ISalesByMasterProducts,
  ISalesSummary,
} from '../../../models/IExternalReport'
import { useEmptyTable } from '../../../../../shared/hooks/table/useEmptyTable'
import { DateTableIcon, TableActionIcon } from '../../../../../assets/svg'
import {
  formatDateWithSeconds,
  formatMomentDateToDayEnd,
  formatMomentDateToDayStart,
  formatMoneyByCurrencyType,
  formatShamsiMomentToISO,
  getFormCalendarTime,
  getFrequency,
  moment,
  tableExpandedIcon,
} from '../../../../../helpers/utils'
import { formatNumberToLocale } from '../../../../salesNetwork/helpers'
import emptyProduct from 'src/assets/img/ProductTablePlaceholder.png'
import { IPopupListItems, Popup } from '../../../../../shared/components/popup/Popup'
import {
  AVAILABLE_HISTORY_CATEGORIES,
  DEBIT_CREDIT_ENUM,
  ITrustWalletTransactionSub,
} from '../../../../Finance/models/ITransaction'
import { useOnTransactionViewClick } from '../../../../../shared/hooks/table/useOnTransactionViewClick'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import styles from '../style.module.scss'

import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { useOnTransactionHistoryClick } from '../../../../../shared/hooks/table/useOnTransactionHistoryClick'
import { Moment } from 'moment'
import { Spin } from 'antd'
import { Dayjs } from 'dayjs'
import ShowMessage from '../../../../../shared/api/errorHandler'
import {
  externalTransactionReportConnection,
  widgetsSocketConnection,
} from '../../../../../shared/sockets'
import { ITableResponse } from '../../../../../shared/models/ITableResponse'
import { WIDGET_LIST_ENUM } from '../../../../../shared/models/WidgetListEnum'
import { IWidget } from '../../../../../shared/models/IWidget'
import { RecursivelyReplaceCharacters } from 'src/shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../shared/hooks/useLocalStorageGetByKey'
import { RequesterColumn } from '../../../helpers/renderRequesterColumn'

export const initialState = {
  [WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS]: {
    value: [] as ISalesByMasterProducts[],
  },
  [WIDGET_LIST_ENUM.SALES_SUMMARY]: {
    value: {
      pickupSummary: [] as ISalesSummary[],
      salesSummary: [] as ISalesSummary[],
    },
    frequency: '',
  },
}

export type ExtTransRepWidgetType = typeof initialState

const negativeStatusList = ['failed', 'canceled', 'rejected']

export const ExternalTransactionReport = () => {
  const { data: profile, isFetching: isFetchingProfile } = useGetProfileQuery()
  const onTransactionViewClick = useOnTransactionViewClick()
  const onTransactionHistoryClick = useOnTransactionHistoryClick()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const [expandedRowKeys, setExpandedRowKeys] = useState<Array<React.Key>>([])
  const [mappedTrans, setMappedTrans] = useState<IExternalReport[]>([])
  const { queryParams, queryFields } = useGetQueryParams({
    orderType: SortingOrderEnum.DESC,
    orderField: 'createdAt',
  })

  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [dateFilter, setDateFilter] = useState<ISelectedFilters>({})

  /** WEBSOCKET STATE */
  const [isPlaying, setIsPlaying] = useState(false)
  const [seconds, setSeconds] = useState(5)
  const [counter, setCounter] = useState(5)
  const [totalCount, setTotalCount] = useState(0)
  const [isLoadingWS, setIsLoadingWS] = useState(false)
  const [widgetData, setWidgetData] = useState(() => initialState)

  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const [getSubTrans, getSubTransResp] = useLazyGetSubTransactionsQuery()
  const { data: filtersList } = useGetETFiltersListQuery(dateFilter, {
    skip: !Object.keys(dateFilter).length || !Object.keys(tableConf).length,
  })

  const { data = { totalCount: 0, items: [] }, isFetching } = useGetExternalTransactionsQuery(
    {
      ...tableConf,
      ...filterValues,
      ...dateFilter,
    },
    { skip: !Object.keys(dateFilter).length || Object.keys(tableConf).length < 4 }
  )

  const tableActionsPopup = (report: IExternalReport): IPopupListItems[] => [
    {
      text: 'View',
      shouldDisplay: true,
      onClick: () => onTransactionViewClick(report.id, report.transactionCategory, report, true),
    },
    ...(AVAILABLE_HISTORY_CATEGORIES.includes(report.transactionCategory)
      ? [
          {
            text: 'History',
            shouldDisplay: true,
            onClick: () => onTransactionHistoryClick(report?.id, report?.transactionCategory),
          },
        ]
      : []),
  ]

  const columns: ColumnsType<IExternalReport> = [
    {
      title: '',
      dataIndex: '',
      key: 'expand',
      width: '1%',
      onCell: (record) => {
        return {
          onClick: async () => {
            const mappedRec = { ...record }
            const data = await getSubTrans({
              transactionCategory: record.transactionCategory,
              id: record.id,
            })
            mappedRec.children = data?.data?.items

            setMappedTrans((prevState) =>
              prevState.map((trans) => {
                if (trans.id === mappedRec.id) {
                  return mappedRec
                }
                return trans
              })
            )
          },
        }
      },
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      sorter: true,
      width: '15%',
      render: (name: IExternalReport['createdAt']) => (
        <RecursivelyReplaceCharacters>
          <div className='table-date'>
            <DateTableIcon />
            {formatDateWithSeconds(name, profile?.calendar)}
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Transaction ID',
      dataIndex: 'id',
      sorter: true,
      width: '15%',
      render: (id) => <RecursivelyReplaceCharacters>{id}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Requester',
      dataIndex: 'agent',
      width: '15%',
      render: (_, row) => <RequesterColumn row={row} />,
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType',
      width: '10%',
      render: (type) => <RecursivelyReplaceCharacters>{type}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Type',
      dataIndex: 'transactionCategory',
      sorter: true,
      width: '10%',
      render: (cat) => <RecursivelyReplaceCharacters>{cat}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: true,
      width: '5%',
      render: (amount, row) => (
        <RecursivelyReplaceCharacters>
          <span
            className={classNames(styles.amountContainer, {
              [styles.dr]: amount && row.drCr === DEBIT_CREDIT_ENUM.DEBIT && row.mainTransactionId,
              [styles.cr]: amount && row.drCr === DEBIT_CREDIT_ENUM.CREDIT && row.mainTransactionId,
              [styles.zero]: !amount && row.mainTransactionId,
            })}
          >
            {formatMoneyByCurrencyType(amount, row?.drCr)}
          </span>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Receiver',
      dataIndex: 'receiver',
      width: '10%',
      render: (
        receiver: IExternalReport['receiver'],
        row: IExternalReport | ITrustWalletTransactionSub
      ) => {
        const isSub = Object.hasOwn(row, 'mainTransactionId')
        return !isSub ? (
          <RecursivelyReplaceCharacters>
            {startCase((row as IExternalReport).receiver)}
          </RecursivelyReplaceCharacters>
        ) : null
      },
    },
    {
      title: 'Product',
      dataIndex: 'masterProduct',
      sorter: true,
      width: '20%',
      render: (masterProduct: IExternalReport['masterProduct'], row) =>
        !row?.mainTransactionId ? (
          <RecursivelyReplaceCharacters>
            <div className='table-avatar'>
              <img
                src={!isPrivateMode && masterProduct?.logo ? masterProduct?.logo : emptyProduct}
                alt='icon'
                loading='lazy'
              />
              <div>
                <span>{masterProduct?.name}</span>
                {row?.product && <div className={styles.escrowMark}>{row?.product?.name}</div>}
              </div>
            </div>
          </RecursivelyReplaceCharacters>
        ) : null,
    },
    {
      title: 'Access Channel',
      dataIndex: 'transactionRequestAccessChannel',
      width: '15%',
      render: (channel: string, row) => (
        <>
          <RecursivelyReplaceCharacters>{channel.toUpperCase()}</RecursivelyReplaceCharacters>
          {row?.agentIdentifier?.value && (
            <RecursivelyReplaceCharacters>
              <div className={styles.escrowMark}>{row?.agentIdentifier.value}</div>
            </RecursivelyReplaceCharacters>
          )}
        </>
      ),
    },
    {
      title: 'P&L',
      dataIndex: 'aminPayPLAmount',
      sorter: true,
      width: '5%',
      render: (amount: IExternalReport['aminPayPLAmount'], row) => (
        <RecursivelyReplaceCharacters>
          <span
            className={classNames(styles.amountContainer, {
              [styles.dr]: amount < 0,
              [styles.cr]: amount > 0,
              [styles.zero]: !amount && row?.mainTransactionId,
            })}
          >
            {amount && <span>{`${amount > 0 ? '+' : ''}${formatNumberToLocale(amount)}`}</span>}
          </span>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: '10%',
      render: (status: IExternalReport['status']) => (
        <RecursivelyReplaceCharacters>
          <div className={classNames('statusContainer', `${status.toLowerCase()}-container`)}>
            <div className={classNames(camelCase(status.toLowerCase()), 'statusPoint')} />
            <div>{status}</div>
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '10%',
      render: (_, row) => (
        <Popup data={tableActionsPopup(row)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const emptyTableConf = useEmptyTable()
  const { pagination, handleTableChange, handleFiltersChange } = useTable<IExternalReport>({
    total: totalCount > data?.totalCount ? totalCount : data?.totalCount,
    setTableConf,
    setFilterValues,
    setExpandedRowKeys,
    onChange: () => {
      setIsPlaying(false)
      setIsLoadingWS(false)
    },
  })

  const onDateChange = (value: Moment[] | null) => {
    setIsPlaying(false)
    if (!Array.isArray(value)) {
      return setDateFilter({})
    }

    const createdAt_more = !isEmpty(value[0])
      ? formatMomentDateToDayStart(
          formatShamsiMomentToISO(profile?.calendar, value[0])
        ).toISOString(true)
      : ''
    const createdAt_less = !isEmpty(value[1])
      ? formatMomentDateToDayEnd(formatShamsiMomentToISO(profile?.calendar, value[1])).toISOString(
          true
        )
      : ''
    setExpandedRowKeys([])
    return createdAt_less && createdAt_more && setDateFilter({ createdAt_less, createdAt_more })
  }

  useEffect(() => {
    if (data) {
      setMappedTrans(data.items)
    }
  }, [data])

  useEffect(() => {
    if (profile?.timeZone) {
      moment.tz?.setDefault(profile?.timeZone as string)

      setDateFilter({
        createdAt_more: formatMomentDateToDayStart(
          formatShamsiMomentToISO(profile?.calendar, moment())
        ).toISOString(true),
        createdAt_less: formatMomentDateToDayEnd(
          formatShamsiMomentToISO(profile?.calendar, moment())
        ).toISOString(true),
      })
    }
  }, [profile])

  useEffect(() => {
    if (!Object.keys(dateFilter).length || !profile) return
    const socket = widgetsSocketConnection.getSocket()
    const messagePayload = [
      {
        widget: WIDGET_LIST_ENUM.SALES_BY_MASTER_PRODUCTS,
        filters: {
          from: moment(dateFilter?.createdAt_more as string)?.toISOString(true),
          to: moment(dateFilter?.createdAt_less as string)?.toISOString(true),
        },
      },
      {
        widget: WIDGET_LIST_ENUM.SALES_SUMMARY,
        filters: {
          from: moment(dateFilter?.createdAt_more as string)?.toISOString(true),
          to: moment(dateFilter?.createdAt_less as string)?.toISOString(true),
          frequency: getFrequency(
            moment(dateFilter?.createdAt_more as string)?.toISOString(true),
            moment(dateFilter?.createdAt_less as string)?.toISOString(true)
          ),
        },
      },
    ]

    socket?.emit('connect-widget', { data: messagePayload })

    return () => {
      socket?.emit('disconnect-widget', {
        data: messagePayload,
      })
    }
  }, [profile, dateFilter])

  useEffect(() => {
    if (!dateFilter || !profile) return
    const socket = widgetsSocketConnection.getSocket()
    socket?.on('widget-data', (data: IWidget) => {
      if (isEmpty(data?.data)) {
        return setWidgetData(initialState)
      }

      const balance = JSON.parse(data?.data?.jsonData)

      setWidgetData((prevState) => ({
        ...prevState,
        [data.widget]: Object.keys(balance).length
          ? {
              value: balance?.value,
              ...(data['filters']['frequency'] && { frequency: data['filters']['frequency'] }),
            }
          : {},
      }))
    })
  }, [profile, dateFilter])

  useEffect(() => {
    if (isEmpty(dateFilter)) {
      setWidgetData(initialState)
    }
  }, [dateFilter])

  // eslint-disable-next-line
  const setMappedTransactions = (resp: ITableResponse<IExternalReport>) => {
    setTotalCount(resp?.totalCount)
    const mappTrans = resp?.items?.map((report) => ({ ...report, children: [] }))

    setMappedTrans(mappTrans)
    setIsLoadingWS(false)
    setExpandedRowKeys([])
  }

  const onStartTimerClick = () => {
    if (!counter || counter < 1) {
      return ShowMessage('error', 'Minimum timer value is 1!')
    }

    if (
      Object.keys(dateFilter)?.length &&
      !moment(dateFilter?.createdAt_less as string).isSame(moment(), 'day')
    ) {
      return ShowMessage('error', 'Date less should be today!')
    }

    setTableConf((prevState) => ({ ...prevState, page: 1 }))
    setIsPlaying((prevState) => !prevState)
  }

  const makeRequest = useCallback(() => {
    const socket = externalTransactionReportConnection.getSocket()
    setIsLoadingWS(true)
    socket.emit('data', {
      ...tableConf,
      filter: {
        ...filterValues,
        ...dateFilter,
      },
    })
  }, [dateFilter, filterValues, tableConf])

  const onTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputTimer = Number(e.target.value)
    setCounter(inputTimer)
    setSeconds(inputTimer)
  }

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (isPlaying && seconds > 0) {
      timer = setTimeout(() => setSeconds(seconds - 1), 1000)
    } else if (isPlaying && seconds === 0) {
      makeRequest()
      setSeconds(counter)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [seconds, isPlaying, counter, makeRequest])

  useEffect(() => {
    const socket = externalTransactionReportConnection.getSocket()

    socket?.on('data', setMappedTransactions)
    return () => {
      socket?.removeListener('data')
    }
  }, [setMappedTransactions])

  return (
    <Spin spinning={isFetchingProfile}>
      <div className={styles.layout}>
        <TableHeader
          setActiveFilters={handleFiltersChange}
          filterValues={filterValues}
          data={filtersList}
          value={
            dateFilter?.createdAt_more && dateFilter?.createdAt_less
              ? [
                  (getFormCalendarTime(
                    profile?.calendar,
                    dateFilter.createdAt_more as string
                  ) as Moment) || Dayjs,
                  (getFormCalendarTime(
                    profile?.calendar,
                    dateFilter.createdAt_less as string
                  ) as Moment) || Dayjs,
                ]
              : undefined
          }
          onTimeChange={onTimeChange}
          onDateChange={onDateChange}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          onStartTimerClick={onStartTimerClick}
          counter={counter}
          seconds={seconds}
          widgetData={widgetData}
        />
        <Table
          locale={emptyTableConf}
          className={styles.table}
          onChange={handleTableChange}
          pagination={pagination}
          scroll={{ x: 1000 }}
          rowSelection={{ type: 'checkbox' }}
          rowKey={(record) => record.id}
          expandable={{
            expandedRowKeys,
            expandIcon: (props) => {
              if (
                Object.hasOwn(props.record, 'mainTransactionId') ||
                !props.record?.countSubTransaction
              )
                return null
              return tableExpandedIcon<IExternalReport>(props)
            },
            onExpandedRowsChange: (expandedKeys) => setExpandedRowKeys([...expandedKeys]),
          }}
          rowClassName={(row) =>
            negativeStatusList.includes(row?.status?.toLowerCase()) ? 'failedRow' : ''
          }
          columns={columns}
          dataSource={mappedTrans}
          loading={isFetching || getSubTransResp.isFetching || isLoadingWS}
        />
      </div>
    </Spin>
  )
}
