import { Form, Spin } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { omit } from 'lodash'

// helpers
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
// api
import {
  useCreateCurrencyRateMutation,
  useGetCurrencyRateByIdQuery,
  useUpdateCurrencyRateByIdMutation,
} from 'src/features/Finance/core/http/ExchangeRatesApi'

import styles from '../../../../../../Finance/pages/ExchangeRates/styles.module.scss'

// components
import { NewRate } from './DetailInfo'

import { ErrorNode } from 'src/shared/api/errorHandler'
import { Button } from 'src/shared/components/button/Button'

import { useGetListOfCurrenciesQuery } from 'src/features/Finance/core/http/BankAccountApi'
import { Moment } from 'moment'
import { ICurrencyRatePost, ICurrencyRatePut } from 'src/features/Finance/models/ICurencyRate'
import { RightModalContext } from 'src/features/Modals'
import { formatDateMomentToISO, formatDateWithTime } from '../../../../../../../helpers/utils'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'

export const EnChangeRatesModal = () => {
  const [form] = Form.useForm<ICurrencyRatePost | ICurrencyRatePut>()
  const {
    onClose,
    props: { id },
  } = useContext(RightModalContext)

  const initialValues = {}
  const { data: profile } = useGetProfileQuery()

  const [checkedCurrenciesFrom, setCheckedCurrenciesFrom] = useState<number | null>(null)
  const [checkedCurrenciesTo, setCheckedCurrenciesTo] = useState<number | null>(null)

  const [createCurrencyRate, createCurrencyResp] = useCreateCurrencyRateMutation()
  const [updateCurrencyRateById, updateCurrencyRateResp] = useUpdateCurrencyRateByIdMutation()

  const {
    data: currencyByIdData,
    isFetching: isFetchingCurrencyById,
    isSuccess: isSuccessCurrencyById,
  } = useGetCurrencyRateByIdQuery(id, { skip: !id })

  useEffect(() => {
    if (isFetchingCurrencyById) return
    if (!isSuccessCurrencyById) return

    setCheckedCurrenciesFrom(currencyByIdData.toCurrency.id)
    setCheckedCurrenciesTo(currencyByIdData.currency.id)

    const initialValues = {
      ...omit(currencyByIdData, ['']),
      currencyId: currencyByIdData!.currency.id,
      toCurrencyId: currencyByIdData!.toCurrency.id,
      date: formatDateWithTime(currencyByIdData!.date, profile?.calendar),
    }
    form.setFieldsValue(initialValues)
  }, [id, isFetchingCurrencyById, isSuccessCurrencyById, profile])

  // updateCurrencyRateResp
  useNotification(NOTIFICATION_TYPES.success, updateCurrencyRateResp.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    updateCurrencyRateResp.isError,
    updateCurrencyRateResp.error as ErrorNode
  )
  // updateCurrencyRateResp

  // createCurrencyResp
  useNotification(NOTIFICATION_TYPES.success, createCurrencyResp.isSuccess, null)
  useNotification(
    NOTIFICATION_TYPES.error,
    createCurrencyResp.isError,
    createCurrencyResp.error as ErrorNode
  )
  // createCurrencyResp

  const onFinish = (values: ICurrencyRatePost | ICurrencyRatePut) => {
    const date = formatDateMomentToISO(profile?.calendar, values.date as Moment)
    const toRequestObj = {
      ...omit(values, ['date']),
      buyingRate: Number(values.buyingRate),
      sellingRate: Number(values.sellingRate),
      coefficient: Number(values.coefficient),
      date,
    }

    id
      ? updateCurrencyRateById({ body: { ...toRequestObj, id } })
      : createCurrencyRate(toRequestObj)
  }

  useEffect(() => {
    if (!createCurrencyResp.isSuccess) return
    onClose()
    // eslint-disable-next-line
  }, [createCurrencyResp.isSuccess])

  useEffect(() => {
    if (!updateCurrencyRateResp.isSuccess) return
    onClose()
    // eslint-disable-next-line
  }, [updateCurrencyRateResp.isSuccess])

  const { data: currencies, isFetching } = useGetListOfCurrenciesQuery()

  const content = (
    <Form
      form={form}
      layout='vertical'
      initialValues={initialValues}
      onFinish={onFinish}
      className={styles.form}
    >
      <NewRate
        form={form}
        currencies={currencies}
        isEdite={!!id}
        checkedCurrenciesFrom={checkedCurrenciesFrom}
        checkedCurrenciesTo={checkedCurrenciesTo}
        setCheckedCurrenciesFrom={setCheckedCurrenciesFrom}
        setCheckedCurrenciesTo={setCheckedCurrenciesTo}
      />

      <Button
        htmlType='submit'
        color='orange'
        block
        type='primary'
        style={{ height: 38 }}
        className={styles.btn}
      >
        {id ? 'Update Rate' : 'Add New Rate'}
      </Button>
    </Form>
  )

  return <div>{isFetching || isFetchingCurrencyById ? <Spin>{content}</Spin> : content}</div>
}
