import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Tabs } from 'antd'
import { useGetDefaultTab } from '../../../../../../../../salesNetwork/helpers/useGetDefaultTab'
import { VENDOR_INTEGRATIONS_TABS_ENUM } from './index.d'
import { NotFoundPage } from '../../../../../../../../NotFoundPage'
import { IconButton } from '../../../../../../../../../shared/components/iconButton/IconButton'
import { EditIcon } from '../../../../../../../../../assets/svg'
import VendorIntegrationAuthorization from './Authorization'
import { useLocation } from 'react-router'

const Operations = ({ activeTab }: { activeTab: string }) => {
  const { mode } = useParams<{ mode: 'view' | 'edit'; id: string }>()

  const shouldDisplayEdit = mode === 'view'

  return (
    <div style={{ display: 'flex', gridGap: 10 }}>
      {shouldDisplayEdit && <IconButton icon={<EditIcon />} color='orange' />}
    </div>
  )
}

const VendorIntegrationsTabs = () => {
  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: VENDOR_INTEGRATIONS_TABS_ENUM.AUTHORISATION,
  })

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  const items = useMemo(
    () => [
      {
        key: VENDOR_INTEGRATIONS_TABS_ENUM.AUTHORISATION,
        label: VENDOR_INTEGRATIONS_TABS_ENUM.AUTHORISATION,
        children: <VendorIntegrationAuthorization />,
      },
      {
        key: VENDOR_INTEGRATIONS_TABS_ENUM.TOPUP,
        label: VENDOR_INTEGRATIONS_TABS_ENUM.TOPUP,
        children: <VendorIntegrationAuthorization />,
      },
      {
        key: VENDOR_INTEGRATIONS_TABS_ENUM.TOPUP_STATUS,
        label: VENDOR_INTEGRATIONS_TABS_ENUM.TOPUP_STATUS,
        children: <VendorIntegrationAuthorization />,
      },
      {
        key: VENDOR_INTEGRATIONS_TABS_ENUM.REVERSE,
        label: VENDOR_INTEGRATIONS_TABS_ENUM.REVERSE,
        children: <VendorIntegrationAuthorization />,
      },
      {
        key: VENDOR_INTEGRATIONS_TABS_ENUM.LOGOUT,
        label: VENDOR_INTEGRATIONS_TABS_ENUM.LOGOUT,
        children: <VendorIntegrationAuthorization />,
      },
    ],
    []
  )

  return (
    <Tabs
      destroyInactiveTabPane
      activeKey={activeTab}
      items={items}
      tabBarExtraContent={<Operations activeTab={activeTab} />}
      onChange={onTabChange}
    />
  )
}

export default VendorIntegrationsTabs
