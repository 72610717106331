import React, { memo, useCallback, useContext } from 'react'
import {
  ChatFilesIcon,
  ChatLinksIcon,
  ChatPicturesIcon,
  ChatVideosIcon,
} from '../../../../../../assets/svg'
import { InternalChatContext } from '../../../InternalChatModal'
import { useAppSelector } from '../../../../../../redux'
import { selectInternalChatChatsState } from '../../../../core/store/Chats'
import { ChatInfoAction } from 'src/shared/components/chat/Components'
import { modalViewTypeEnum } from '../../../../core/models'
import styles from './styles.module.scss'

export const InternalChatAttachmentsInfo = memo(() => {
  const { onSelectChat, setProps } = useContext(InternalChatContext)
  const { openedChatId } = useAppSelector(selectInternalChatChatsState)

  const onSelectByAttachmentType = useCallback(
    (type: string) => {
      setProps((prev) => ({ ...prev, attachmentType: type }))
      onSelectChat(modalViewTypeEnum.attachments, openedChatId as number)
    },
    // eslint-disable-next-line
    [openedChatId]
  )

  const onSelectByLinksType = useCallback(
    (type: string) => {
      setProps((prev) => ({ ...prev, attachmentType: type }))
      onSelectChat(modalViewTypeEnum.links, openedChatId as number)
    },
    // eslint-disable-next-line
    [openedChatId]
  )

  return (
    <div className={styles.internalChatAttachments}>
      <ChatInfoAction
        icon={<ChatFilesIcon />}
        actionType='Files'
        onClickByType={onSelectByAttachmentType}
      />
      <ChatInfoAction
        icon={<ChatPicturesIcon />}
        actionType='Pictures'
        onClickByType={onSelectByAttachmentType}
      />
      <ChatInfoAction
        icon={<ChatVideosIcon />}
        actionType='Videos'
        onClickByType={onSelectByAttachmentType}
      />
      <ChatInfoAction
        icon={<ChatLinksIcon />}
        actionType='Links'
        onClickByType={onSelectByLinksType}
      />
    </div>
  )
})
