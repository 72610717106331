import FormItem from 'antd/es/form/FormItem'
import {
  maxLengthValidator,
  maxNumber,
  minNumber,
  positiveNumber,
  requiredValidator,
} from '../../../../../../helpers/validation'
import { TextField } from '../../../../../../shared/components/textfield/TextField'
import styles from '../style.module.scss'
import { Form, Select } from 'antd'
import {
  VOICE_UNITS,
  SMS_UNITS,
  DATA_UNITS,
  MIX_UNITS,
  BUNDLE_TYPE_ENUM,
  PRODUCT_BUNDLE_REVERSE_TYPE_ENUM,
  PRODUCT_TYPE_ENUM,
  AVAILABILITY,
} from '../../../../models/IProduct'
import { Wrapper } from '../../../../../salesNetwork/components/Wrapper'
import React, { useCallback, useContext, useMemo } from 'react'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import { isNumber, startCase } from 'lodash'
import classNames from 'classnames'
import TextArea from 'antd/es/input/TextArea'
import { AddIcon, BucketIcon } from 'src/assets/svg'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { useLazyGetVendorBundlesQuery } from 'src/features/Settings/core/http/VendorManagementApi'
import { RightModalContext } from '../../../../../Modals'

export const ProductInfo = ({ view: isDisabled, form }: IViewMode) => {
  const [getVendorBundles, vendorBundles] = useLazyGetVendorBundlesQuery()
  const {
    props: { vendorId },
  } = useContext(RightModalContext)

  const productTypes = useMemo(
    () =>
      (Object.keys(PRODUCT_TYPE_ENUM) as Array<keyof typeof PRODUCT_TYPE_ENUM>).map((method) => ({
        label: startCase(PRODUCT_TYPE_ENUM[method].toLowerCase()),
        value: PRODUCT_TYPE_ENUM[method],
      })),
    []
  )

  const bundleTypes = useMemo(
    () =>
      (Object.keys(BUNDLE_TYPE_ENUM) as Array<keyof typeof BUNDLE_TYPE_ENUM>).map((method) => ({
        label: BUNDLE_TYPE_ENUM[method],
        value: BUNDLE_TYPE_ENUM[method],
      })),
    []
  )

  const bundleTimeOptions = useMemo(
    () =>
      (Object.keys(AVAILABILITY) as Array<keyof typeof AVAILABILITY>).map((method) => ({
        label: AVAILABILITY[method],
        value: AVAILABILITY[method],
      })),
    []
  )

  const bundleUnits = useCallback((category: string) => {
    const unitMap = {
      Data: DATA_UNITS,
      Voice: VOICE_UNITS,
      SMS: SMS_UNITS,
      Mix: MIX_UNITS,
    }

    const units = unitMap[category as keyof typeof unitMap]

    if (units) {
      return Object.entries(units).map(([key, value]) => ({
        label: value,
        value: value,
      }))
    }

    return []
  }, [])

  const handleBundleTypeChange = (value: string) => {
    form?.setFieldValue('bundleUnit', null)
    if (value == 'Custom') {
      getVendorBundles(vendorId)
    }
  }

  const vendorBundlesList = useMemo(() => {
    if (!vendorBundles) return []

    return Object.entries(vendorBundles).map(([key, value]) => ({
      label: value,
      value: key,
    }))
  }, [vendorBundles])

  const calculatePrice = useCallback(() => {
    if (!form) return

    const bundlePrice = Number(form.getFieldValue('bundlePrice'))
    const bundleTax = Number(form.getFieldValue('bundleTax'))
    const agentBundleServiceFee = Number(form.getFieldValue('agentBundleServiceFee'))
    const apiBundleServiceFee = Number(form.getFieldValue('apiBundleServiceFee'))

    const priceTaxIncluded = bundlePrice + bundleTax

    form.setFieldValue('agentBundlePrice', (agentBundleServiceFee + priceTaxIncluded).toFixed(2))
    form.setFieldValue('apiBundlePrice', (apiBundleServiceFee + priceTaxIncluded).toFixed(2))
  }, [form])

  const calculateTax = useCallback(() => {
    if (!form) return

    const bundlePrice = Number(form.getFieldValue('bundlePrice'))
    const tax = ((bundlePrice / 100) * 11.1).toFixed(2)
    form.setFieldValue('bundleTax', tax)

    calculatePrice()
  }, [form, calculatePrice])

  return (
    <Wrapper title='Product Information'>
      <div>
        <FormItem
          name='name'
          label='Name'
          rules={[
            {
              required: true,
              validator: requiredValidator('Name'),
            },
            {
              validator: maxLengthValidator(45),
            },
          ]}
        >
          <TextField disabled={isDisabled} placeholder='Enter product name' />
        </FormItem>
      </div>

      <div className={styles.dblRow}>
        <FormItem
          name='internalProductCode'
          label='Internal Product Code'
          rules={[
            {
              required: true,
              validator: requiredValidator('Internal Product Code'),
            },
          ]}
        >
          <TextField disabled={isDisabled} placeholder='Internal Product Code' />
        </FormItem>

        <FormItem
          name='externalProductCode'
          label='External Product Code'
          rules={[
            {
              required: true,
              validator: requiredValidator('External Product Code'),
            },
          ]}
        >
          <TextField disabled={isDisabled} placeholder='External Product Code' />
        </FormItem>
      </div>

      <div>
        <FormItem noStyle shouldUpdate>
          {({ setFieldValue, getFieldValue }) => {
            const productType = getFieldValue('type') as PRODUCT_TYPE_ENUM

            return (
              <FormItem
                name='type'
                label='Product Type'
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('Product Type'),
                  },
                ]}
                className={classNames({
                  noBottomMargin:
                    productType !== PRODUCT_TYPE_ENUM.TOPUP &&
                    productType !== PRODUCT_TYPE_ENUM.E_PIN &&
                    productType !== PRODUCT_TYPE_ENUM.BUNDLE_TOPUP,
                })}
              >
                <Select
                  disabled={isDisabled}
                  options={productTypes}
                  onChange={(value) => {
                    setFieldValue(['masterProduct', 'id'], null)
                    if (
                      ![PRODUCT_TYPE_ENUM.E_PIN, PRODUCT_TYPE_ENUM.BUNDLE_TOPUP].includes(value)
                    ) {
                      return setFieldValue('reverseType', null)
                    }

                    setFieldValue('reverseType', PRODUCT_BUNDLE_REVERSE_TYPE_ENUM.NA)
                  }}
                  placeholder='Product Type'
                  allowClear
                />
              </FormItem>
            )
          }}
        </FormItem>
      </div>

      <FormItem noStyle shouldUpdate>
        {({ getFieldValue, validateFields }) => {
          const productType = getFieldValue('type') as PRODUCT_TYPE_ENUM

          if (productType === PRODUCT_TYPE_ENUM.TOPUP) {
            return (
              <div className={styles.dblRow}>
                <FormItem
                  name='denominationMin'
                  label='Denomination Min'
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator('Denomination Min'),
                    },
                    {
                      validator: positiveNumber('Denomination Min'),
                    },
                    ...(isNumber(+getFieldValue('denominationMax'))
                      ? [
                          {
                            validator: maxNumber(
                              +getFieldValue('denominationMax'),
                              'Denomination Min'
                            ),
                          },
                        ]
                      : [{}]),
                  ]}
                  className='noBottomMargin'
                >
                  <TextField
                    type='number'
                    disabled={isDisabled}
                    placeholder='Min'
                    onChange={() => {
                      if (getFieldValue('denominationMax')) {
                        validateFields(['denominationMax'])
                      }
                    }}
                  />
                </FormItem>

                <FormItem
                  name='denominationMax'
                  label='Denomination Max'
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator('Denomination Max'),
                    },
                    ...(isNumber(+getFieldValue('denominationMin'))
                      ? [
                          {
                            validator: minNumber(
                              +getFieldValue('denominationMin'),
                              'Denomination Max'
                            ),
                          },
                        ]
                      : [{}]),
                    {
                      validator: positiveNumber('Denomination Max'),
                    },
                  ]}
                  className='noBottomMargin'
                >
                  <TextField
                    type='number'
                    disabled={isDisabled}
                    placeholder='Max'
                    onChange={() => {
                      validateFields(['denominationMin'])
                    }}
                  />
                </FormItem>
              </div>
            )
          }

          if (productType === PRODUCT_TYPE_ENUM.E_PIN) {
            return (
              <FormItem
                name='denomination'
                label='Denomination'
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('Denomination'),
                  },
                  {
                    validator: positiveNumber('Denomination Max'),
                  },
                ]}
                className='noBottomMargin'
              >
                <TextField type='number' disabled={isDisabled} placeholder='Denomination' />
              </FormItem>
            )
          }

          if (productType === PRODUCT_TYPE_ENUM.BUNDLE_TOPUP) {
            return (
              <>
                <div className={styles.dblRow}>
                  <FormItem
                    name='bundleType'
                    label='Bundle Type'
                    rules={[
                      {
                        required: true,
                        validator: requiredValidator('Bundle Type'),
                      },
                    ]}
                  >
                    <Select
                      options={bundleTypes}
                      placeholder='Select Bundle Type'
                      disabled={isDisabled}
                      allowClear
                      onChange={handleBundleTypeChange}
                    />
                  </FormItem>

                  <FormItem
                    name='availability'
                    label='Availability'
                    rules={[
                      {
                        required: true,
                        validator: requiredValidator('Availability'),
                      },
                    ]}
                  >
                    <Select
                      options={bundleTimeOptions}
                      placeholder='Select Availability'
                      disabled={isDisabled}
                      allowClear
                    />
                  </FormItem>
                </div>

                <FormItem
                  name='bundleValidity'
                  label='Bundle Validity'
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator('Bundle Validity'),
                    },
                  ]}
                >
                  <TextField
                    type='number'
                    disabled={isDisabled}
                    placeholder='Enter Bundle Validity'
                  />
                </FormItem>

                {form?.getFieldValue('bundleType') !== 'Custom' ? (
                  <>
                    {['Data', 'Mix'].includes(form?.getFieldValue('bundleType')) && (
                      <FormItem noStyle shouldUpdate>
                        <div className={styles.dblRow}>
                          <FormItem
                            name={['dataBundle', 'size']}
                            label='Data Bundle Size'
                            rules={[
                              {
                                required: true,
                                validator: requiredValidator('Data Bundle Size'),
                              },
                            ]}
                          >
                            <TextField
                              type='number'
                              disabled={isDisabled}
                              placeholder='Data Bundle Size'
                            />
                          </FormItem>
                          <FormItem
                            name={['dataBundle', 'unit']}
                            label='Data Bundle Unit'
                            rules={[
                              {
                                required: true,
                                validator: requiredValidator('Data Bundle Unit'),
                              },
                            ]}
                          >
                            <Select
                              options={bundleUnits(
                                form?.getFieldValue('bundleType') === 'Mix'
                                  ? 'Data'
                                  : form?.getFieldValue('bundleType')
                              )}
                              placeholder='Select Data Bundle Unit'
                              disabled={isDisabled}
                              allowClear
                            />
                          </FormItem>
                        </div>
                      </FormItem>
                    )}

                    <>
                      {['Voice', 'Mix'].includes(form?.getFieldValue('bundleType')) && (
                        <FormItem noStyle shouldUpdate>
                          <div className={styles.dblRow}>
                            <FormItem
                              name={['voiceBundle', 'size']}
                              label='Voice Bundle Size'
                              rules={[
                                {
                                  required: true,
                                  validator: requiredValidator('Voice Bundle Size'),
                                },
                              ]}
                            >
                              <TextField
                                type='number'
                                disabled={isDisabled}
                                placeholder='Voice Bundle Size'
                              />
                            </FormItem>
                            <FormItem
                              name={['voiceBundle', 'unit']}
                              label='Voice Bundle Unit'
                              rules={[
                                {
                                  required: true,
                                  validator: requiredValidator('Voice Bundle Unit'),
                                },
                              ]}
                            >
                              <Select
                                options={bundleUnits('Voice')}
                                placeholder='Select Voice Bundle Unit'
                                disabled={isDisabled}
                                allowClear
                              />
                            </FormItem>
                          </div>
                        </FormItem>
                      )}

                      {['SMS', 'Mix'].includes(form?.getFieldValue('bundleType')) && (
                        <FormItem noStyle shouldUpdate>
                          <div className={styles.dblRow}>
                            <FormItem
                              name={['SMSBundle', 'size']}
                              label='SMS Bundle Size'
                              rules={[
                                {
                                  required: true,
                                  validator: requiredValidator('SMS Bundle Size'),
                                },
                              ]}
                            >
                              <TextField
                                type='number'
                                disabled={isDisabled}
                                placeholder='SMS Bundle Size'
                              />
                            </FormItem>
                            <FormItem
                              name={['SMSBundle', 'unit']}
                              label='SMS Bundle Unit'
                              rules={[
                                {
                                  required: true,
                                  validator: requiredValidator('SMS Bundle Unit'),
                                },
                              ]}
                            >
                              <Select
                                options={bundleUnits('SMS')}
                                placeholder='Select SMS Bundle Unit'
                                disabled={isDisabled}
                                allowClear
                              />
                            </FormItem>
                          </div>
                        </FormItem>
                      )}
                    </>
                  </>
                ) : (
                  <Form.List name='bundles'>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <div className={styles.dblRow} key={field.key}>
                            <Form.Item noStyle shouldUpdate>
                              {() => (
                                <FormItem
                                  {...field}
                                  name={[field.name, 'bundleName']}
                                  label='Bundle'
                                  rules={[
                                    {
                                      required: true,
                                      validator: requiredValidator('Bundle Name'),
                                    },
                                  ]}
                                >
                                  <Select
                                    options={undefined}
                                    placeholder='Select Bundle Name'
                                    disabled={isDisabled}
                                    allowClear
                                  />
                                </FormItem>
                              )}
                            </Form.Item>

                            <FormItem
                              name={[field.name, 'topupAmount']}
                              label='Topup Amount'
                              rules={[
                                {
                                  required: true,
                                  validator: requiredValidator('Topup Amount'),
                                },
                              ]}
                            >
                              <TextField
                                type='number'
                                disabled={isDisabled}
                                placeholder='Topup Amount'
                              />
                            </FormItem>

                            <FormItem label='' style={{ flexBasis: '3%', alignSelf: 'end' }}>
                              <IconButton
                                block
                                size='large'
                                color='orange'
                                icon={<BucketIcon />}
                                type='default'
                                disabled={isDisabled}
                                onClick={() => remove(field.name)}
                                style={{ width: '34px' }}
                              ></IconButton>
                            </FormItem>
                          </div>
                        ))}

                        <Form.Item>
                          <IconButton
                            icon={<AddIcon />}
                            color='orange'
                            block
                            onClick={() => add()}
                          />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                )}

                <div className={styles.dblRow}>
                  <FormItem
                    name='bundlePrice'
                    label='Bundle Price (Exclude Tax)'
                    rules={[
                      {
                        required: true,
                        validator: requiredValidator('Bundle Price'),
                      },
                      {
                        validator: positiveNumber('Bundle Price'),
                      },
                    ]}
                  >
                    <TextField
                      disabled={isDisabled}
                      type='number'
                      placeholder='Enter Bundle Price'
                      onChange={calculateTax}
                    />
                  </FormItem>

                  <FormItem
                    name='bundleTax'
                    label='Bundle Tax'
                    rules={[
                      {
                        required: true,
                        validator: requiredValidator('Bundle Tax'),
                      },
                      {
                        validator: positiveNumber('Bundle Tax'),
                      },
                    ]}
                  >
                    <TextField
                      disabled={isDisabled}
                      type='number'
                      placeholder='Enter Bundle Tax'
                      onChange={calculatePrice}
                    />
                  </FormItem>
                </div>

                <div className={styles.dblRow}>
                  <FormItem
                    name='agentBundleServiceFee'
                    label='Agent Bundle Service Fee'
                    rules={[
                      {
                        required: true,
                        validator: requiredValidator('Agent Bundle Service Fee'),
                      },
                      {
                        validator: positiveNumber('Agent Bundle Service Fee'),
                      },
                    ]}
                  >
                    <TextField
                      disabled={isDisabled}
                      type='number'
                      placeholder='Enter Agent Bundle Service Fee'
                      onChange={calculatePrice}
                    />
                  </FormItem>

                  <FormItem
                    name='apiBundleServiceFee'
                    label='API Bundle Service Fee'
                    rules={[
                      {
                        required: true,
                        validator: requiredValidator('API Bundle Service Fee'),
                      },
                      {
                        validator: positiveNumber('API Bundle Service Fee'),
                      },
                    ]}
                  >
                    <TextField
                      disabled={isDisabled}
                      type='number'
                      placeholder='Enter API Bundle Service Fee'
                      onChange={calculatePrice}
                    />
                  </FormItem>
                </div>

                <div className={styles.dblRow}>
                  <FormItem
                    name='agentBundlePrice'
                    label='Agent Bundle Price'
                    rules={[
                      {
                        required: true,
                        validator: requiredValidator('Agent Bundle Price'),
                      },
                      {
                        validator: positiveNumber('Agent Bundle Price'),
                      },
                    ]}
                  >
                    <TextField disabled type='number' placeholder='Agent Bundle Price' />
                  </FormItem>
                  <FormItem
                    name='apiBundlePrice'
                    label='API Bundle Price'
                    rules={[
                      {
                        required: true,
                        validator: requiredValidator('API Bundle Price'),
                      },
                      {
                        validator: positiveNumber('API Bundle Price'),
                      },
                    ]}
                  >
                    <TextField disabled type='number' placeholder='API Bundle Price' />
                  </FormItem>
                </div>
                <FormItem
                  name='processorTopupAmount'
                  label='Processor Topup Amount'
                  rules={[
                    {
                      required: true,
                      validator: requiredValidator('Processor Topup Amount'),
                    },
                    {
                      validator: positiveNumber('Processor Topup Amount'),
                    },
                  ]}
                >
                  <TextField
                    type='number'
                    disabled={isDisabled}
                    placeholder='Enter Processor Topup Amount'
                  />
                </FormItem>

                <FormItem
                  name='bundleDescription'
                  label='Bundle Description'
                  className='noBottomMargin'
                >
                  <TextArea
                    disabled={isDisabled}
                    placeholder='Enter Bundle Description'
                    aria-rowcount={5}
                  />
                </FormItem>
              </>
            )
          }
        }}
      </FormItem>
    </Wrapper>
  )
}
