import { Switch, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { IProduct } from '../../../../../../models/IProduct'
import { useCallback, useContext, useState } from 'react'
import { ISelectedFilters } from '../../../../../../../../shared/components/filter/Filter'
import { ITableConf } from '../../../../../../../../shared/models/ITableConf'
import {
  IPopupListItems,
  POPOVER_LIST_ITEM_COLORS_ENUM,
  Popup,
} from 'src/shared/components/popup/Popup'
import {
  DateTableIcon,
  EmailFilledIcon,
  PhoneDarkBlueIcon,
  SkypeIcon,
  TableActionIcon,
  WhatsappIcon,
} from 'src/assets/svg'
import styles from './styles.module.scss'
import { formatPhoneNumber } from '../../../../../../../salesNetwork/helpers'
import { formatDateWithTime } from '../../../../../../../../helpers/utils'
import { useNavigate, useParams } from 'react-router-dom'
import emptyAvatarImage from '../../../../../../../../assets/img/Avatar.png'

import { TableHeader } from '../../TableHeader'
import { RightModalContext } from '../../../../../../../Modals'
import {
  useDeleteContactMutation,
  useGetContactListByVendorIdQuery,
  useGetVendorContactFiltersListQuery,
  useLazyExportContactListByVendorIdListTableQuery,
  usePatchContactByIdMutation,
} from 'src/features/Settings/core/http/VendorManagementApi'
import {
  IContactObj,
  IVendorContactResponseView,
} from 'src/features/Settings/models/IVendorManagement'
import { useTableExport } from 'src/shared/hooks/table/useTableExport'
import { useEmptyTable } from '../../../../../../../../shared/hooks/table/useEmptyTable'
import { RIGHT_MODALS } from '../../../../../../../../helpers/contants'
import { useGetQueryParams } from 'src/shared/hooks/table/useGetQueryParams'
import useTable from 'src/shared/hooks/table/useTable'
import { SETTINGS_ENUM } from '../../../../../../../../routes/settings'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../../shared/hooks/useNotification'
import { ErrorNode, showConfirmMessage } from '../../../../../../../../shared/api/errorHandler'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import { isEmpty } from 'lodash'
import { RecursivelyReplaceCharacters } from '../../../../../../../../shared/components/privateMode'
import { useLocalStorageGetByKey } from '../../../../../../../../shared/hooks/useLocalStorageGetByKey'

const ContactsTable = () => {
  const navigate = useNavigate()
  const { id: vendorId } = useParams<{ id: string }>()

  const { onOpen } = useContext(RightModalContext)
  const [patchContact, patchContResp] = usePatchContactByIdMutation()
  const [deleteContact, delContactResp] = useDeleteContactMutation()

  const { data: profile } = useGetProfileQuery()
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')

  useNotification(NOTIFICATION_TYPES.success, delContactResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.success, patchContResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, patchContResp.isError, patchContResp.error as ErrorNode)

  const tableActionsPopup = useCallback(
    (id: number): IPopupListItems[] => {
      return [
        {
          text: 'View',
          shouldDisplay: true,
          onClick: () => navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/contact/view/${id}`),
        },
        {
          text: 'Edit',
          shouldDisplay: true,
          onClick: () => navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/contact/edit/${id}`),
        },
        {
          text: 'Delete',
          withDivider: true,
          shouldDisplay: true,
          color: POPOVER_LIST_ITEM_COLORS_ENUM.RED,
          onClick: () =>
            showConfirmMessage('This contact will be deleted', () => deleteContact(id)),
        },
      ]
    },
    [deleteContact, navigate]
  )

  const columns: ColumnsType<IVendorContactResponseView> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      width: '20%',
      render: (name: IProduct['name'], row) => (
        <RecursivelyReplaceCharacters>
          <a
            onClick={() => {
              navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/contact/view/${row?.id}`)
            }}
            className='table-avatar'
          >
            <img
              alt='product logo'
              src={!isPrivateMode && row?.avatar ? row.avatar : emptyAvatarImage}
            />
            <div>{name}</div>
          </a>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Contact Type',
      dataIndex: 'contactType',
      sorter: true,
      width: '15%',
      render: (contactType: string) => (
        <RecursivelyReplaceCharacters>
          <div style={{ textTransform: 'capitalize' }}>{contactType.toLowerCase()}</div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Details',
      dataIndex: 'department',
      sorter: true,
      width: '10%',
      render: (_, contactObj) => (
        <RecursivelyReplaceCharacters>
          <div>
            {contactObj?.department && (
              <div className={styles.detailItem}>{contactObj?.department}</div>
            )}
            {contactObj?.position && (
              <div className={styles.detailItem}>{contactObj?.position}</div>
            )}
          </div>
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Contact info',
      width: '15%',
      render: (_, contactObj) => {
        const isEmptyContact = (contact: IContactObj[]) => contact.length === 0
        const email = !isEmptyContact(contactObj?.email) ? contactObj?.email[0]?.value : ''
        const whatsapp = !isEmptyContact(contactObj?.whatsapp) ? contactObj?.whatsapp[0]?.value : ''
        const skype = !isEmptyContact(contactObj?.skype) ? contactObj?.skype[0]?.value : ''

        return (
          <RecursivelyReplaceCharacters>
            <div className='table-contact-info'>
              {contactObj?.mobileNumber.map((mobile) => (
                <div key={mobile.id} className='table-contact-info__row mobile'>
                  <PhoneDarkBlueIcon />
                  <span>{formatPhoneNumber(mobile.value)}</span>
                </div>
              ))}
              <div className={styles['wrapper-icon']}>
                {email && (
                  <>
                    <EmailFilledIcon />
                    <span>{email}</span>
                  </>
                )}
              </div>
              <div className={styles['wrapper-icon']}>
                {whatsapp && (
                  <>
                    <WhatsappIcon />
                    <span>{whatsapp}</span>
                  </>
                )}
              </div>
              <div className={styles['wrapper-icon']}>
                {skype && (
                  <>
                    <SkypeIcon />
                    <span>{skype}</span>
                  </>
                )}
              </div>
            </div>
          </RecursivelyReplaceCharacters>
        )
      },
    },
    {
      title: 'Added by',
      dataIndex: 'createdByUser',
      sorter: true,
      width: '10%',
      render: (createdByUser) => (
        <RecursivelyReplaceCharacters>
          {createdByUser?.name ? createdByUser.name : ''}
        </RecursivelyReplaceCharacters>
      ),
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      sorter: true,
      width: '15%',
      render: (createdAt: IProduct['createdAt']) => (
        <div className='table-date'>
          <DateTableIcon />
          <RecursivelyReplaceCharacters>
            {formatDateWithTime(createdAt, profile?.calendar)}
          </RecursivelyReplaceCharacters>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: '10%',
      render: (status: IProduct['status'], { id }) => {
        return (
          <Switch
            checked={status}
            defaultChecked={status}
            onChange={(status) => {
              patchContact({ id, body: { status } })
            }}
            loading={patchContResp.isLoading}
          />
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, contactObj) => (
        <Popup data={tableActionsPopup(contactObj.id)}>
          <div className='table-kebab-actions'>
            <TableActionIcon />
          </div>
        </Popup>
      ),
    },
  ]

  const { queryParams, queryFields } = useGetQueryParams()
  const [filterValues, setFilterValues] = useState<ISelectedFilters>(queryFields)
  const [tableConf, setTableConf] = useState<ITableConf>(queryParams)

  const [exportContactListByVendorTableRequest] = useLazyExportContactListByVendorIdListTableQuery()
  useTableExport({
    tableConf,
    filterValues: { ...filterValues, vendorId },
    promise: exportContactListByVendorTableRequest,
  })

  const { data: filtersList } = useGetVendorContactFiltersListQuery()
  const { data, isFetching } = useGetContactListByVendorIdQuery(
    {
      ...tableConf,
      ...filterValues,
      vendorId: vendorId as string,
    },
    { skip: isEmpty(tableConf) }
  )

  const emptyTableConf = useEmptyTable(() =>
    onOpen(RIGHT_MODALS.SETTINGS.VENDOR_MANAGEMENT_CONTACT_LIST, {
      title: 'Add Contacts',
      assignToVendor: true,
      vendorId,
    })
  )
  const { pagination, handleTableChange, handleFiltersChange } =
    useTable<IVendorContactResponseView>({
      total: data?.totalCount,
      setTableConf,
      setFilterValues,
    })

  return (
    <>
      <TableHeader
        data={filtersList}
        setActiveFilters={handleFiltersChange}
        numberOfItems={data?.totalCount}
        filterValues={filterValues}
        nameNumberOfItems='Contacts'
      />
      <Table
        locale={emptyTableConf}
        columns={columns}
        onChange={handleTableChange}
        dataSource={data?.items}
        loading={isFetching}
        scroll={{ x: 1100 }}
        pagination={pagination}
        className={styles.table}
        rowSelection={{ type: 'checkbox' }}
        rowKey={(record) => record.id}
      />
    </>
  )
}

export default ContactsTable
