import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import styles from '../styles.module.scss'

export const TextViewer = ({
  uri,
  fileName,
  fileFormat,
}: {
  uri: string
  fileName: string
  fileFormat: string
}) => {
  const fileTypes: { [key: string]: string } = {
    csv: 'text/csv',
    odt: 'application/vnd.oasis.opendocument.text',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pdf: 'application/pdf',
    txt: 'txt',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  }

  const formatKey = fileFormat.replace(/\./, '')

  const docs = [
    {
      uri,
      fileName,
      fileType: fileTypes[formatKey],
    },
  ]

  return (
    <DocViewer
      documents={docs}
      pluginRenderers={DocViewerRenderers}
      key={fileName}
      className={styles.docViewer}
      language='en'
      prefetchMethod='GET'
    />
  )
}
