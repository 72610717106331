import { BankAccount, DEBIT_CREDIT_ENUM } from '../../../../models/ITransaction'
import { Link } from 'react-router-dom'
import emptyAvatarImage from '../../../../../../assets/img/Avatar.png'
import React from 'react'
import styles from '../../../BankWallet/Tables/PickupRequestTable/styles.module.scss'
import { SETTINGS_ENUM } from '../../../../../../routes/settings'
import { IByUser } from '../../../../../../shared/models/IByUser'
import { IWalletList } from '../../../../models/IWallet'
import { camelCase, startCase, upperCase } from 'lodash'
import { RecursivelyReplaceCharacters } from '../../../../../../shared/components/privateMode'
import { VENDOR_WALLET_TYPE_ENUM } from '../../../../models/IPurchaseOrder'

interface IConstraint {
  walletType?: string
  wallet?: IWalletList
  escrow?: boolean
  bankAccount?: BankAccount
  vendor?: IByUser
  drCr?: DEBIT_CREDIT_ENUM
  receiver: 'bankAccount' | 'vendor'
  sender: 'bankAccount' | 'vendor'
  isPrivateMode?: boolean
  vendorWalletType?: VENDOR_WALLET_TYPE_ENUM
}

type TableRow<T> = T

export const renderTableVendor = (
  vendor: IByUser,
  row: TableRow<IConstraint>,
  isPrivateMode = false
) =>
  vendor && !Object.hasOwn(row, 'mainTransactionId') ? (
    <RecursivelyReplaceCharacters>
      <Link to={`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/view/${vendor?.id}`} className='table-avatar'>
        <img
          alt='vendor logo'
          src={!isPrivateMode && vendor?.logo ? vendor?.logo : emptyAvatarImage}
        />
        <div>
          <div className={styles.entityName}>Vendor Wallet</div>
          <div>{vendor?.name}</div>
          <div className={styles.bankAccountId}>{upperCase(camelCase(row.vendorWalletType))}</div>
          {row?.escrow && <div className={styles.bankAccountId}>Escrow</div>}
        </div>
      </Link>
    </RecursivelyReplaceCharacters>
  ) : (
    <div>
      <RecursivelyReplaceCharacters>
        {row?.wallet && <div className={styles.entityName}>{row.wallet.name}</div>}
      </RecursivelyReplaceCharacters>
      <RecursivelyReplaceCharacters>
        {row?.drCr === DEBIT_CREDIT_ENUM.CREDIT ? (
          <>
            <div>{startCase(row[row?.receiver]?.name)}</div>
            {row?.receiver === 'vendor' && (
              <div className={styles.bankAccountId}>
                {upperCase(camelCase(row.vendorWalletType))}
              </div>
            )}
          </>
        ) : (
          <>
            <div>{startCase(row[row?.sender]?.name)}</div>
            {row?.sender === 'vendor' && (
              <div className={styles.bankAccountId}>
                {upperCase(camelCase(row.vendorWalletType))}
              </div>
            )}
          </>
        )}
      </RecursivelyReplaceCharacters>
      <RecursivelyReplaceCharacters>
        {row?.escrow && <div className={styles.bankAccountId}>Escrow</div>}
      </RecursivelyReplaceCharacters>
    </div>
  )
