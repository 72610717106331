import { useState } from 'react'
import { ITableConf } from '../../../../../../../shared/models/ITableConf'
import { useEmptyTable } from '../../../../../../../shared/hooks/table/useEmptyTable'
import { useOnTableChange } from '../../../../../../../shared/hooks/table/useOnTableChange'
import { useTablePagination } from '../../../../../../../shared/hooks/table/useTablePagination'
import Table, { ColumnsType } from 'antd/es/table'
import { IEpinInventory } from '../../../../../models/IEPin'
import styles from '../../styles.module.scss'
import { useGetEPinInventoryListQuery } from '../../../../../core/http/VendorInventoryApi'
import { useParams } from 'react-router'
import { RecursivelyReplaceCharacters } from '../../../../../../../shared/components/privateMode'
import { nanoid } from 'nanoid'

export const EPinList = () => {
  const { id } = useParams()
  const [tableConf, setTableConf] = useState<ITableConf>({
    page: 1,
    limit: 5,
  })

  const emptyTableConf = useEmptyTable()
  const onTableParamsChange = useOnTableChange<IEpinInventory>(setTableConf)
  const pagination = useTablePagination(0, false, 5)

  const { data, isFetching } = useGetEPinInventoryListQuery(
    { vendorId: id as string, params: tableConf },
    { skip: !id }
  )

  const columns: ColumnsType<IEpinInventory> = [
    {
      title: 'PIN Denomination',
      dataIndex: 'denomination',
      sorter: true,
      width: '15%',
      render: (denom) => <RecursivelyReplaceCharacters>{denom}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Number Of Active',
      dataIndex: 'count',
      sorter: true,
      width: '15%',
      render: (count) => <RecursivelyReplaceCharacters>{count}</RecursivelyReplaceCharacters>,
    },
    {
      title: 'Value Amount',
      dataIndex: 'amount',
      sorter: true,
      width: '15%',
      render: (amount) => <RecursivelyReplaceCharacters>{amount}</RecursivelyReplaceCharacters>,
    },
  ]

  return (
    <Table
      pagination={pagination}
      locale={emptyTableConf}
      onChange={onTableParamsChange}
      dataSource={data?.items}
      className={styles.table}
      rowKey={(row) => `${id}-${row.amount}-${row.count}-${row.denomination}-${nanoid()}`}
      columns={columns}
      loading={isFetching}
    />
  )
}
