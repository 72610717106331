import React, { ReactNode } from 'react'
import { recursivelyReplaceTextWithAsterisks } from '../../../helpers/utils'
import { useLocalStorageGetByKey } from '../../hooks/useLocalStorageGetByKey'

interface RecursivelyReplaceCharactersProps {
  children: ReactNode
}

export const RecursivelyReplaceCharacters = ({ children }: RecursivelyReplaceCharactersProps) => {
  const isPrivateMode = useLocalStorageGetByKey<boolean | undefined>('isPrivateMode')
  const processedChildren = recursivelyReplaceTextWithAsterisks(children, isPrivateMode)

  return <>{processedChildren}</>
}
