import { Tabs } from 'antd/es'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useGetDefaultTab } from '../../../salesNetwork/helpers/useGetDefaultTab'
import React, { useEffect } from 'react'
import { BANK_SETTINGS_TABS_ENUM } from './index.d'
import { BankAccounts } from './Components/BankAccountsTable'
import { IconButton } from '../../../../shared/components/iconButton/IconButton'
import { AddIcon } from '../../../../assets/svg'
import styles from './styles.module.scss'
import { FINANCE_ENUM } from '../../../../routes/finance'
import { BanksTable } from './Components/BanksTable'

const Operations = ({ activeTab }: { activeTab: string }) => {
  const navigate = useNavigate()

  const onAddButtonClick = () => {
    if (activeTab === BANK_SETTINGS_TABS_ENUM.BANK_ACCOUNTS) {
      return navigate(`${FINANCE_ENUM.BANK_SETTINGS}/bank-accounts/new`)
    }
    if (activeTab === BANK_SETTINGS_TABS_ENUM.BANKS) {
      return navigate(`${FINANCE_ENUM.BANK_SETTINGS}/banks/new`)
    }
  }

  return <IconButton icon={<AddIcon />} type='primary' color='orange' onClick={onAddButtonClick} />
}

export const BankSettings = () => {
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: BANK_SETTINGS_TABS_ENUM.BANK_ACCOUNTS,
  })

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  const items = [
    {
      label: BANK_SETTINGS_TABS_ENUM.BANK_ACCOUNTS,
      key: BANK_SETTINGS_TABS_ENUM.BANK_ACCOUNTS,
      children: <BankAccounts />,
    },
    {
      label: BANK_SETTINGS_TABS_ENUM.BANKS,
      key: BANK_SETTINGS_TABS_ENUM.BANKS,
      children: <BanksTable />,
    },
  ]

  return (
    <div className={styles.layout}>
      <Tabs
        destroyInactiveTabPane
        items={items}
        activeKey={activeTab}
        onChange={onTabChange}
        tabBarExtraContent={<Operations activeTab={activeTab} />}
      />
    </div>
  )
}
