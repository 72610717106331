import styles from './TextFieldAuth.module.scss'
import { ReactNode, useState } from 'react'
import { EmailIcon, PasswordIcon } from 'src/assets/svg'
import classNames from 'classnames'
import { Input } from 'antd'

interface IProps {
  disabled?: boolean
  type?: string
  placeholder: string
  prefix: 'email' | 'password'
  onChange?: () => void
}

const getPrefixByIcon = (
  icon: ReactNode,
  onChange?: React.Dispatch<React.SetStateAction<string>>
) => (
  <div
    className={classNames(styles['prefix-auth-inp'], {
      [styles['prefix-hover']]: !!onChange,
    })}
    onClick={() => onChange?.((p) => (p === 'text' ? 'password' : 'text'))}
  >
    <div className={styles['wrapper-auth-icon']}>{icon}</div>
  </div>
)

export const TextFieldAuth = ({
  onChange,
  disabled = false,
  type = 'text',
  placeholder = 'text',
  prefix,
}: IProps): JSX.Element => {
  const [passwordType, setPasswordType] = useState(type)

  const prefixObj = {
    email: getPrefixByIcon(<EmailIcon />),
    password: getPrefixByIcon(<PasswordIcon />, setPasswordType),
  }

  return (
    <Input
      prefix={prefixObj[prefix]}
      className={classNames(styles.textField, styles.inp, {
        [styles.disabled]: disabled,
      })}
      placeholder={placeholder}
      disabled={disabled}
      type={passwordType}
      onChange={onChange}
    />
  )
}
