import { Row, Select as AntSelect, Form } from 'antd'
import classNames from 'classnames'
import FormItem from 'antd/es/form/FormItem'
import { isEmpty } from 'lodash'

import { TextField } from 'src/shared/components/textfield/TextField'
import { Wrapper } from '../Wrapper'
import { AfghanistanFlag, AmericanFlag2, BritishFlag } from 'src/assets/svg'

// queries
import { useGetAgentLanguageslListQuery } from 'src/features/salesNetwork/core/http/AgentManagement'

import { commonValidator, maxLengthValidator25, requiredValidator } from 'src/helpers/validation'
import { IAgentLanguagesListResponse } from '../../models/IAgent'
import { AGENT_TYPE_ID_ENUM } from 'src/features/Settings/models/IAgentType'

// styles
import styles from './styles.module.scss'

const genderOptions = [
  { id: 0, name: 'Male', value: 'MALE' },
  { id: 1, name: 'Female', value: 'FEMALE' },
  { id: 2, name: 'Genderless', value: 'GENDERLESS' },
]

const merchantOptions = [
  { id: 1, name: 'Yes', value: true },
  { id: 2, name: 'No', value: false },
]

const calendarOptions = [
  { id: 1, name: 'GREGORIAN', icon: <BritishFlag /> },
  { id: 2, name: 'SHAMSI', icon: <AfghanistanFlag /> },
]

const getLanguageOptions = (options: IAgentLanguagesListResponse['items'] = []) => {
  return options.map((option) => ({
    ...option,
    ...(option.name === 'English'
      ? { name: 'American English', icon: <AmericanFlag2 /> }
      : {
          name: option.name,
          icon: <AfghanistanFlag />,
        }),
  }))
}

interface IDetailInfo {
  view?: boolean
}

const DetailInfo = ({ view }: IDetailInfo) => {
  const { data: agentLanguagesOptions } = useGetAgentLanguageslListQuery()

  return (
    <Wrapper title='Detail Info' className={styles['wrapper-detail-info']}>
      <Row justify='space-between'>
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const agentTypeId = getFieldValue('agentTypeId')

            if (agentTypeId !== AGENT_TYPE_ID_ENUM.RESELLER) {
              return (
                <FormItem
                  name='firstName'
                  label='First Name'
                  className={styles['form-item-inp']}
                  rules={[
                    { required: true, validator: requiredValidator('First Name') },
                    { validator: commonValidator },
                    { validator: maxLengthValidator25 },
                  ]}
                >
                  <TextField disabled={view} placeholder='First Name' />
                </FormItem>
              )
            }
          }}
        </FormItem>

        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const agentTypeId = getFieldValue('agentTypeId')

            if (agentTypeId !== AGENT_TYPE_ID_ENUM.RESELLER) {
              return (
                <FormItem
                  className={styles['form-item-inp']}
                  name='lastName'
                  label='Last Name'
                  rules={[
                    { required: true, validator: requiredValidator('Last Name') },
                    { validator: commonValidator },
                    { validator: maxLengthValidator25 },
                  ]}
                >
                  <TextField disabled={view} placeholder='Last Name' />
                </FormItem>
              )
            }
          }}
        </FormItem>
      </Row>

      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const agentTypeId = getFieldValue('agentTypeId')
          const directOrIndirect =
            agentTypeId === AGENT_TYPE_ID_ENUM.DIRECT || agentTypeId === AGENT_TYPE_ID_ENUM.INDIRECT

          return agentTypeId === AGENT_TYPE_ID_ENUM.RESELLER ? (
            <FormItem
              className={classNames(styles['form-item-inp'], styles['form-item-inp-row'])}
              name='companyTitle'
              label='Company Title'
              rules={[
                { required: true, validator: requiredValidator('Company Title') },
                { validator: commonValidator },
                { validator: maxLengthValidator25 },
              ]}
            >
              <TextField placeholder='Company Title' disabled={view} />
            </FormItem>
          ) : (
            <Row justify='space-between'>
              <Form.Item
                name='gender'
                label='Gender'
                className={classNames(styles['form-item-inp'], {
                  [styles['form-item-inp-row']]: !directOrIndirect,
                })}
                rules={[{ required: true, validator: requiredValidator('Gender') }]}
              >
                <AntSelect allowClear placeholder='Select Gender' disabled={view}>
                  {genderOptions.map(({ id, value, name }) => (
                    <AntSelect.Option key={id} value={value}>
                      {name}
                    </AntSelect.Option>
                  ))}
                </AntSelect>
              </Form.Item>

              {directOrIndirect && (
                <Form.Item
                  name='isMerchant'
                  label='Merchant'
                  className={classNames(styles['form-item-inp'])}
                  rules={[{ required: true, validator: requiredValidator('Merchant') }]}
                >
                  <AntSelect allowClear placeholder='Select Merchant' disabled={view}>
                    {merchantOptions.map(({ id, value, name }) => (
                      <AntSelect.Option key={id} value={value}>
                        {name}
                      </AntSelect.Option>
                    ))}
                  </AntSelect>
                </Form.Item>
              )}
            </Row>
          )
        }}
      </FormItem>

      <Row justify='space-between'>
        <Form.Item
          name='calendar'
          label='Preferred Calendar'
          className={styles['form-item-inp']}
          rules={[{ required: true, validator: requiredValidator('Preferred Calendar') }]}
        >
          <AntSelect allowClear placeholder='Select Preferred Calendar' disabled={view}>
            {calendarOptions.map(({ id, name, icon }) => (
              <AntSelect.Option key={id} value={name}>
                <div className={styles['wrapper-content-option']}>
                  <div className={styles['wrapper-icon']}>{icon}</div>
                  <div>{name}</div>
                </div>
              </AntSelect.Option>
            ))}
          </AntSelect>
        </Form.Item>

        <Form.Item
          name='languageId'
          label='Preferred Language'
          className={styles['form-item-inp']}
          rules={[{ required: true, validator: requiredValidator('Preferred Language') }]}
        >
          <AntSelect
            allowClear
            placeholder='Select Preferred Language'
            disabled={view || isEmpty(agentLanguagesOptions?.items)}
          >
            {getLanguageOptions(agentLanguagesOptions?.items).map(({ id, name, icon }) => (
              <AntSelect.Option key={id} value={id}>
                <div className={styles['wrapper-content-option']}>
                  <div className={styles['wrapper-icon']}>{icon}</div>
                  <div>{name}</div>
                </div>
              </AntSelect.Option>
            ))}
          </AntSelect>
        </Form.Item>
      </Row>
      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const agentTypeId = getFieldValue('agentTypeId')

          if (agentTypeId !== AGENT_TYPE_ID_ENUM.RESELLER) {
            return (
              <Row justify='space-between' style={{ marginBottom: 16 }}>
                <FormItem
                  name='firstNameInLocalLanguage'
                  label='First Name in Local language'
                  className={classNames(styles['form-item-inp'], styles['witout-bottom-margin'])}
                  rules={[{ validator: maxLengthValidator25 }]}
                >
                  <TextField disabled={view} placeholder='First Name in Local language' />
                </FormItem>

                <FormItem
                  name='lastNameInLocalLanguage'
                  label='Last Name in Local Language'
                  className={classNames(styles['form-item-inp'], styles['witout-bottom-margin'])}
                  rules={[{ validator: maxLengthValidator25 }]}
                >
                  <TextField disabled={view} placeholder='Last Name in Local Language' />
                </FormItem>
              </Row>
            )
          }
        }}
      </FormItem>
    </Wrapper>
  )
}
export default DetailInfo
