import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import { Form } from 'antd'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'

export const DetailInfo = () => {
  return (
    <Wrapper title='Detail Info'>
      <Form.Item name='name' label='Name'>
        <TextField disabled />
      </Form.Item>

      <Form.Item name='action' label='Action'>
        <TextField disabled />
      </Form.Item>

      <Form.Item name='transactionStatus' label='Transaction Status' className='noBottomMargin'>
        <TextField disabled />
      </Form.Item>
    </Wrapper>
  )
}
