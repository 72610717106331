import { AllowedOperationsEnum, FieldsTypeEnum, IFilterFields } from '../../models/IFilterFields'
import { Row } from 'antd'
import { useEffect, useState } from 'react'
import lodash from 'lodash'
import { FilterFieldsMapper } from './FilterFieldsMapper'
import { ITableResponse } from '../../models/ITableResponse'
import classNames from 'classnames'

class FieldElement {
  private allowedOperations: AllowedOperationsEnum[]
  private readonly fieldType: FieldsTypeEnum

  constructor(field: IFilterFields) {
    this.allowedOperations = field.settings.allowedOperations
    this.fieldType = field.settings.type
  }

  public getFieldElement() {
    if (this.fieldType === FieldsTypeEnum.STRING) {
      if (this.allowedOperations.includes(AllowedOperationsEnum.EQUALS)) {
        return 'select'
      }

      if (this.allowedOperations.includes(AllowedOperationsEnum.LIKE)) {
        return 'text-like'
      }
    }

    if (this.fieldType === FieldsTypeEnum.NUMBER) {
      if (this.allowedOperations.includes(AllowedOperationsEnum.MORE)) {
        return 'number'
      }
    }

    if (this.fieldType === FieldsTypeEnum.DATE) {
      if (
        this.allowedOperations.includes(AllowedOperationsEnum.LESS) &&
        this.allowedOperations.includes(AllowedOperationsEnum.MORE)
      ) {
        return 'date-range'
      }

      if (this.allowedOperations.includes(AllowedOperationsEnum.LESS)) {
        return 'date-less'
      }

      if (this.allowedOperations.includes(AllowedOperationsEnum.MORE)) {
        return 'date-more'
      }

      if (this.allowedOperations.includes(AllowedOperationsEnum.EQUALS)) {
        return 'date-equals'
      }
    }

    if (this.fieldType === FieldsTypeEnum.BOOLEAN) {
      if (this.allowedOperations.includes(AllowedOperationsEnum.EQUALS)) {
        return 'boolean'
      }
    }

    return 'text'
  }
}

interface IFields {
  field: string
  element: string
  placeholder: string
  options: ITableResponse<string>['items']
}

const stringHelper = (field: IFilterFields): IFields => {
  const fieldElement = new FieldElement(field)

  return {
    field: field.field,
    placeholder: lodash.startCase(field.field),
    element: fieldElement.getFieldElement(),
    options: field.data?.items ?? [],
  }
}

export const FilterFields = ({
  data,
  onChange,
}: {
  data?: IFilterFields[]
  onChange: (name: string, value: unknown) => void
}) => {
  const [mappedFields, setMappedFields] = useState<IFields[]>([])

  const mapFilterByAllowedOperations = (data: IFilterFields[]) => {
    const changed = data.map((field) => stringHelper(field))
    setMappedFields(changed)
  }

  useEffect(() => {
    if (data) {
      mapFilterByAllowedOperations(data)
    }
  }, [data])

  return (
    <Row
      className={classNames('filter__popup-fields', { hasTwoItems: mappedFields?.length === 2 })}
    >
      {mappedFields?.map((filter) => {
        return <FilterFieldsMapper onChange={onChange} {...filter} key={filter.placeholder} />
      })}
    </Row>
  )
}
