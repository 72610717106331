import { VALUE_ADD_SERVICES_TABS_ENUM } from './index.d'
import { NotFoundPage } from '../../../../../NotFoundPage'
import { Tabs } from 'antd/es'

export const ValueAddServicesTabs = () => {
  const items = [
    {
      label: VALUE_ADD_SERVICES_TABS_ENUM.CHAT_MANAGEMENT,
      key: VALUE_ADD_SERVICES_TABS_ENUM.CHAT_MANAGEMENT,
      children: <NotFoundPage />,
    },
    {
      label: VALUE_ADD_SERVICES_TABS_ENUM.GAME_MANAGEMENT,
      key: VALUE_ADD_SERVICES_TABS_ENUM.GAME_MANAGEMENT,
      children: <NotFoundPage />,
    },
    {
      label: VALUE_ADD_SERVICES_TABS_ENUM.INFO_VAS,
      key: VALUE_ADD_SERVICES_TABS_ENUM.INFO_VAS,
      children: <NotFoundPage />,
    },
  ]

  return <Tabs items={items} />
}
