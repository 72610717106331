import styles from '../style.module.scss'
import { Form, Select } from 'antd'
import { TextField } from '../../../../../../../../../shared/components/textfield/TextField'
import { Wrapper } from '../../../../../../../../salesNetwork/components/Wrapper'
import { IViewMode } from '../../../../../../../../Finance/models/IViewMode'
import { ISelectOption } from '../../../../../../../../../shared/models/ISelectOption'
import { positiveNumber, requiredValidator } from '../../../../../../../../../helpers/validation'

interface IProps extends IViewMode {
  currencyList: Array<ISelectOption>
}

export const Rates = ({ view, currencyList = [] }: IProps) => {
  const currencyContent = (
    <Form.Item
      name={['rateCurrency', 'id']}
      label='Currency'
      labelAlign='left'
      className={styles.currencyInput}
      rules={[
        {
          required: true,
          validator: requiredValidator('Currency'),
        },
      ]}
    >
      <Select disabled={view} options={currencyList} allowClear placeholder='Select Currency' />
    </Form.Item>
  )

  return (
    <Wrapper title='Rates' statusButton={currencyContent}>
      <div className={styles.rateBlockWrapper}>
        <div className={styles.rateBlock}>
          <div className={styles.rateBlockHeader}>Hourly Rate</div>
          <div className={styles.doubleRow}>
            <Form.Item
              label='Rate'
              name='hourlyRate'
              rules={[
                {
                  required: true,
                  validator: requiredValidator('Hourly Rate'),
                },
                {
                  validator: positiveNumber('Hourly Rate'),
                },
              ]}
              className={styles.noBottomMargin}
            >
              <TextField disabled={view} type='number' placeholder='Enter Hourly Rate' />
            </Form.Item>

            <Form.Item label='%' name='hourlyPercent'>
              <TextField disabled />
            </Form.Item>
          </div>
        </div>

        <div className={styles.rateBlock}>
          <div className={styles.rateBlockHeader}>Overtime Rate</div>
          <div className={styles.doubleRow}>
            <Form.Item
              label='Rate'
              name='overtimeRate'
              rules={[
                {
                  required: true,
                  validator: requiredValidator('Overtime Rate'),
                },
                {
                  validator: positiveNumber('Overtime Rate'),
                },
              ]}
              className={styles.noBottomMargin}
            >
              <TextField disabled={view} type='number' placeholder='Enter Overtime Rate' />
            </Form.Item>

            <Form.Item label='%' name='overtimePercent' className={styles.noBottomMargin}>
              <TextField disabled />
            </Form.Item>
          </div>
        </div>

        <div className={styles.rateBlock}>
          <div className={styles.rateBlockHeader}>Holiday Rate</div>
          <div className={styles.doubleRow}>
            <Form.Item
              label='Rate'
              name='holidayRate'
              rules={[
                {
                  required: true,
                  validator: requiredValidator('Holiday Rate'),
                },
                {
                  validator: positiveNumber('Holiday Rate'),
                },
              ]}
              className={styles.noBottomMargin}
            >
              <TextField disabled={view} type='number' placeholder='Enter Holiday Rate' />
            </Form.Item>

            <Form.Item label='%' name='holidayPercent' className={styles.noBottomMargin}>
              <TextField disabled />
            </Form.Item>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
