import styles from './styles.module.scss'
import { Form, Row, Space, Spin } from 'antd'
import { useParams } from 'react-router-dom'
import { DetailInfo } from './Components/DetailInfo'
import { VendorResponse } from './Components/VendorResponse'
import { SystemResponse } from './Components/SystemResponse'
import {
  IResponseCode,
  IResponseCodeMutation,
  RESPONSE_CODE_CHANNEL,
} from '../../../models/IResponseCode'
import { Button } from '../../../../../shared/components/button/Button'
import { useGetProfileQuery } from '../../../../Profile/core/http/Profile'
import React, { useCallback, useEffect, useMemo } from 'react'
import { deleteHtmlTagsFromString, formatDateWithTime } from '../../../../../helpers/utils'
import {
  useCreateResponseCodeMutation,
  useGetResponseCodeByIdQuery,
  useUpdateResponseCodeMutation,
} from '../../../core/http/ResponseCodeApi'
import { NOTIFICATION_TYPES, useNotification } from '../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../shared/api/errorHandler'
import { useNavigate } from 'react-router'
import { SETTINGS_ENUM } from '../../../../../routes/settings'
import { useDispatch } from 'react-redux'
import { setBreadcrumb } from '../../../../app/core/BreadcrumbsSlice'
import { ChannelBlock } from './Components/ChannelBlock/index'

export const ResponseCodeView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { mode, id } = useParams()
  const [form] = Form.useForm()

  const [updRespCode, updRCResponse] = useUpdateResponseCodeMutation()
  const [crRespCode, crRCResponse] = useCreateResponseCodeMutation()
  const { data, isFetching } = useGetResponseCodeByIdQuery(id as string, { skip: !id })
  const { data: profile, isFetching: isFetchingProfile } = useGetProfileQuery()

  const afterCreate = () => {
    if (crRCResponse?.data?.code) {
      return navigate(
        `${SETTINGS_ENUM.VENDOR_MANAGEMENT}/response-code/view/${crRCResponse?.data?.code}`
      )
    }
  }

  const afterUpdate = () => {
    if (updRCResponse?.data?.code) {
      return navigate(
        `${SETTINGS_ENUM.VENDOR_MANAGEMENT}/response-code/view/${updRCResponse?.data?.code}`
      )
    }
  }

  useNotification(NOTIFICATION_TYPES.success, updRCResponse.isSuccess, null, afterUpdate)
  useNotification(NOTIFICATION_TYPES.success, crRCResponse.isSuccess, null, afterCreate)
  useNotification(NOTIFICATION_TYPES.error, crRCResponse.isError, crRCResponse.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, updRCResponse.isError, updRCResponse.error as ErrorNode)

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'
  const isLoading =
    isFetching || isFetchingProfile || updRCResponse.isLoading || crRCResponse.isLoading

  const channelBlockTabs = useMemo(
    () =>
      (Object.keys(RESPONSE_CODE_CHANNEL) as Array<keyof typeof RESPONSE_CODE_CHANNEL>).map(
        (code) => RESPONSE_CODE_CHANNEL[code]
      ),
    []
  )

  const setInitialValues = useCallback(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        createdBy: data?.createdByUser?.name,
        createdAt: formatDateWithTime(data?.createdAt, profile?.calendar),
        messages: [
          ...data?.messages,
          ...channelBlockTabs
            ?.filter((message) => !data?.messages?.find((m) => m.channel === message))
            .map((mm) => ({ channel: mm })),
        ],
      })
    }
  }, [channelBlockTabs, data, form, profile])

  const onCancelButtonClick = () => {
    if (isEditMode) {
      form.resetFields()
      setInitialValues()
      return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/response-code/view/${id}`)
    }

    return navigate(SETTINGS_ENUM.VENDOR_MANAGEMENT)
  }

  const onSubmitButtonClick = () => {
    if (isViewMode) {
      return navigate(`${SETTINGS_ENUM.VENDOR_MANAGEMENT}/response-code/edit/${id}`)
    }

    form.submit()
  }

  const onFinish = (values: IResponseCode) => {
    const body = {
      code: values?.code,
      description: values?.description,
      addToRestriction: !!values?.addToRestriction,
      restrictionReason: values?.restrictionReason,
      ...(values?.responseType && { responseType: values?.responseType }),
      vendorCodes:
        values.vendorCodes?.map((code) => ({
          vendorId: code.vendor?.id,
          vendorCode: code?.vendorCode,
        })) || [],
      messages: values?.messages
        ?.filter((message) => Object.keys(message).length >= 2)
        .map((filtMessage) => ({
          ...filtMessage,
          en: deleteHtmlTagsFromString(filtMessage?.en) || null,
          ps: deleteHtmlTagsFromString(filtMessage?.ps) || null,
          prs: deleteHtmlTagsFromString(filtMessage?.prs) || null,
        })),
    } as IResponseCodeMutation

    id ? updRespCode({ id: id as string, body }) : crRespCode(body)
  }

  useEffect(() => {
    if (data) {
      setInitialValues()
      dispatch(setBreadcrumb({ name: `Response Code ${data?.code}` }))
    }
  }, [data, dispatch, form, setInitialValues])

  useEffect(() => {
    if (!id && profile) {
      form.setFieldsValue({
        createdBy: `${profile?.firstName} ${profile?.lastName}`,
        createdAt: formatDateWithTime(new Date().toISOString(), profile?.calendar),
        messages: channelBlockTabs.map((block) => ({ channel: block })),
      })
    }
  }, [channelBlockTabs, form, id, profile])

  const content = (
    <div className={styles.layout}>
      <Form form={form} layout='vertical' className={styles.form} onFinish={onFinish}>
        <DetailInfo view={isViewMode} />
        <SystemResponse view={isViewMode} />
        <VendorResponse view={isViewMode} />
        <ChannelBlock />
      </Form>
      <div className={styles.wrapper}>
        <Row className={styles.footer} justify='end'>
          <Space size={10}>
            <Button color='blue' size='middle' onClick={onCancelButtonClick}>
              Cancel
            </Button>
            <Button htmlType='submit' size='middle' type='primary' onClick={onSubmitButtonClick}>
              {isViewMode && 'Edit'}
              {isEditMode && 'Save Changes'}
              {!isViewMode && !isEditMode && 'Create Response Code'}
            </Button>
          </Space>
        </Row>
      </div>
    </div>
  )

  return isLoading ? <Spin>{content}</Spin> : content
}
