import React, { memo } from 'react'
import FormItem from 'antd/es/form/FormItem'
import FormList from 'antd/es/form/FormList'
import { Wrapper } from 'src/shared/components/wrapper/Wrapper'
import { TextField } from 'src/shared/components/textfield/TextField'
import styles from '../styles.module.scss'

const BankAccountSummary = () => {
  return (
    <Wrapper title='Bank Account Summary' className={styles.container}>
      <FormList name='purchaseOrderBankWallets'>
        {(fields) =>
          fields.map(({ name, key }) => {
            return (
              <div key={key} className={styles.purchaseOrderBankWallet}>
                <FormItem name={[name, 'bankAccount', 'name']} label='Bank Name'>
                  <TextField disabled />
                </FormItem>
                <div className={styles.dblRow}>
                  <FormItem name={[name, 'balanceBefore']} label='Balance Before'>
                    <TextField disabled />
                  </FormItem>
                  <FormItem name={[name, 'balanceAfter']} label='Balance After'>
                    <TextField disabled />
                  </FormItem>
                </div>
                <div>
                  <FormItem name={[name, 'unitPrice']} label='Unit Rate' className='noBottomMargin'>
                    <TextField disabled />
                  </FormItem>
                </div>
              </div>
            )
          })
        }
      </FormList>
    </Wrapper>
  )
}

export default memo(BankAccountSummary)
