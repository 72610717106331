import { RuleObject } from 'antd/lib/form'
import { UploadChangeParam } from 'antd/lib/upload'
import { isBoolean, isEmpty, isNumber, isObject } from 'lodash'
import parsePhoneNumber from 'libphonenumber-js'
import { AGENT_TYPE_ID_ENUM } from 'src/features/Settings/models/IAgentType'
import { isIP } from 'class-validator'
import { deleteHtmlTagsFromString } from './utils'

export const commonValidator = (_: RuleObject, value: string) => {
  if (!value) return Promise.resolve()

  const pattern = /^[a-zA-Z0-9 ]+$/

  const isValue = typeof value === 'string' ? value.trim() : value
  return pattern.test(isValue)
    ? Promise.resolve()
    : Promise.reject(new Error('Please enter valid field!'))
}

export const maxLengthValidator = (maxLength: number) => (_: RuleObject, value: string) => {
  if (!value) return Promise.resolve()

  return value.trim()?.length <= maxLength
    ? Promise.resolve()
    : Promise.reject(new Error(`Max length ${maxLength} characters!`))
}

export const minLengthValidator =
  (min: number, field: string) => (_: RuleObject, value: string) => {
    if (!value) return Promise.resolve()
    return value.trim()?.length < min
      ? Promise.reject(new Error(`${field} should contain at least ${min} characters!`))
      : Promise.resolve()
  }

export const mobileNumberLengthByFromByTo =
  (lengthFrom: number, lengthTo: number) => (_: RuleObject, value: string) => {
    if (!value) return Promise.resolve()

    return value.trim().length >= lengthFrom && value.trim().length <= lengthTo
      ? Promise.resolve()
      : Promise.reject(new Error(`Please enter ${lengthFrom - 1} - ${lengthTo - 1} characters!`))
  }

export const mobileNumberLength = (length: number) => (_: RuleObject, value: string) => {
  if (!value) return Promise.resolve()

  return value.trim().length == length
    ? Promise.resolve()
    : Promise.reject(new Error('Please enter 11 characters!'))
}

export const emailValidator = (_: RuleObject, value: string) => {
  if (!value) return Promise.resolve()

  const pattern =
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i // eslint-disable-line no-control-regex, no-useless-escape, max-len
  const isValue = typeof value === 'string' ? value.trim() : value

  return pattern.test(isValue)
    ? Promise.resolve()
    : Promise.reject(new Error('Please enter valid email address!'))
}

export const confirmFieldValidator =
  (field: string, message: string) => (_: RuleObject, value: string) => {
    if (field === value) return Promise.resolve()
    return Promise.reject(new Error(message))
  }

export const mobileNumberValidator = (_: RuleObject, value: string) => {
  if (!value) return Promise.resolve()
  // const pattern = /^\+/

  // const isValue = typeof value === 'string' ? value.trim() : value
  return parsePhoneNumber(value)?.isValid()
    ? Promise.resolve()
    : Promise.reject(new Error('Please enter valid mobile number!'))
}

// custom validator for better error message
export const requiredValidator = (name: string) => (_: RuleObject, value: string | boolean) => {
  if (!isBoolean(value) && (value === null || value === undefined || value === '')) {
    return Promise.reject(new Error(`${name} is required!`))
  }
  return Promise.resolve()
}

export const richTextEditorReqValidator =
  (name: string, value: string) => (_: RuleObject, v: string) => {
    if (!deleteHtmlTagsFromString(value)) {
      return Promise.reject(new Error(`${name} is required!`))
    }
    return Promise.resolve()
  }

export const requiredMulSelectValidator = (name: string) => (_: RuleObject, value: string) => {
  if (!value || isEmpty(value)) {
    return Promise.reject(new Error(`${name} are required!`))
  }
  return Promise.resolve()
}

export const requiredFileValidator =
  (name: string) => (_: RuleObject, value: UploadChangeParam) => {
    if (!value || isEmpty(value.fileList)) {
      return Promise.reject(new Error(`${name} is required!`))
    }
    return Promise.resolve()
  }

export const passwordValidator = () => (_: RuleObject, value: string) => {
  const errorMessage =
    'The password must contain a digit, an uppercase and lowercase letter, and a special character (!@#$%^&)'
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&*])(?=.{8,})/

  if (!value) return Promise.resolve()
  const isValue = typeof value === 'string' ? value.trim() : value

  return pattern.test(isValue) ? Promise.resolve() : Promise.reject(new Error(errorMessage))
}

export const numberValidator = (_: RuleObject, value: string) => {
  if (!value) return Promise.resolve()

  if (!isNumber(value)) {
    return Promise.reject(new Error('Please enter only numeric value!'))
  }
}

export const numberWithPointValidator = (_: RuleObject, value: string) => {
  const errorMessage = 'Please enter valid number!'

  if (!value) return Promise.reject(new Error(errorMessage))

  const pattern = /^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/

  return pattern.test(value) ? Promise.resolve() : Promise.reject(new Error(errorMessage))
}

export const letterValidator = (_: RuleObject, value: string) => {
  if (!value) return Promise.resolve()

  const pattern = /^[\p{L}\s]+$/u

  const isValue = typeof value === 'string' ? value.trim() : value
  return pattern.test(isValue)
    ? Promise.resolve()
    : Promise.reject(new Error('Please enter only numeric letters!'))
}

// const mobileNumber
export const mobileNumberLengthFrom8To14 = mobileNumberLengthByFromByTo(9, 15)
export const mobileNumberLength11 = mobileNumberLengthFrom8To14

// export const mobileNumberLength11 = mobileNumberLength(12)
export const maxLengthValidator25 = maxLengthValidator(25)
export const maxLengthValidator50 = maxLengthValidator(50)
export const maxLengthValidator256 = maxLengthValidator(256)

export const positiveNumber = (name: string) => (_: RuleObject, value: string) => {
  if (+value < 0) return Promise.reject(new Error(`${name} can not be negative!`))
  return Promise.resolve()
}

export const maxNumber = (max: number, name: string) => (_: RuleObject, value: string) => {
  if (+value > max) return Promise.reject(new Error(`${name} can not be greater than ${max}!`))
  return Promise.resolve()
}

export const minNumber = (min: number, name: string) => (_: RuleObject, value: string) => {
  if (+value < min) return Promise.reject(new Error(`${name} can not be lower than ${min}!`))
  return Promise.resolve()
}

export const numberRange =
  (min: number, max: number, name: string) => (_: RuleObject, value: string) => {
    if (+value > max) return Promise.reject(new Error(`${name} can not be greater than ${max}!`))
    if (+value < min) return Promise.reject(new Error(`${name} can not be lower than ${min}!`))
    return Promise.resolve()
  }

export const minMaxLengthValidator =
  (min: number, max: number) => (_: RuleObject, value: string) => {
    const validationMessage = `Field length must be from ${min} to ${max} signs!`

    if (!value) return Promise.reject(new Error(validationMessage))
    if (value.length > max) return Promise.reject(new Error(validationMessage))
    if (value.length < min) return Promise.reject(new Error(validationMessage))

    return Promise.resolve()
  }

export const agentTypeValidator = (type: number) => () => {
  if (type === AGENT_TYPE_ID_ENUM.INDIRECT || !type) {
    return Promise.resolve()
  }

  return Promise.reject(new Error(`Parent should has the Indirect agent type!`))
}

export const IPValidator = (_: RuleObject, value: string) => {
  if (!isIP(value)) {
    return Promise.reject(new Error(`Please enter valid IP Address!`))
  }
  return Promise.resolve()
}

export const totalNumberValidator =
  (maxNumber: string | number, arr: Array<Record<string, string>>, key: string) =>
  (_: RuleObject) => {
    const currentCount = arr?.reduce((acc, next) => (isObject(next) ? acc + +next[key] : 0), 0)

    if (Number(currentCount?.toFixed(2)) !== +maxNumber) {
      return Promise.reject(new Error(`Total sum of ${key} should be ${maxNumber}`))
    }
    return Promise.resolve()
  }

export const negativeNumberInArrValidator =
  (arr: Array<Record<string, string>>, key: string) => (_: RuleObject) => {
    const isNegative = arr.find((element) => +element[key] < 0)
    if (isNegative) {
      return Promise.reject(new Error(`The ${key} field can not be negative!`))
    }

    return Promise.resolve()
  }

export const urlValidator = (_: RuleObject, value: string) => {
  const urlRegex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/

  return urlRegex.test(value) ? Promise.resolve() : Promise.reject('URL is not valid')
}

export const latitudeValidator = (_: RuleObject, value: string) => {
  const latRegex = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/

  return latRegex.test(value) ? Promise.resolve() : Promise.reject('Latitude is not valid')
}

export const longitudeValidator = (_: RuleObject, value: string) => {
  const longRegex =
    /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/

  return longRegex.test(value) ? Promise.resolve() : Promise.reject('Longitude is not valid')
}
