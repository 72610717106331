import { createContext, Dispatch, ReactNode, useContext, useMemo, useState } from 'react'

// helpers
import { TrustWalletTabsEnum } from './index.d'

// components
import { TrustWalletTabs } from './Components/Tabs'

// styles
import styles from './styles.module.scss'

export interface IInitialValues {
  id?: number | null
  activeTab?: string
}

const initialValues: IInitialValues = {
  id: null,
  activeTab: TrustWalletTabsEnum.OVERVIEW,
}

const TabsContext = createContext<{
  state: IInitialValues
  setState: Dispatch<IInitialValues>
}>({
  state: initialValues,
  setState: (): void => {},
})

export const TabsContextProvider = ({ children }: { children: ReactNode }) => {
  const [value, setValue] = useState<IInitialValues>(initialValues)

  const stateObj = useMemo(
    () => ({
      state: value,
      setState: (val: IInitialValues) => setValue((prevState) => ({ ...prevState, ...val })),
    }),
    [value]
  )

  return <TabsContext.Provider value={stateObj}>{children}</TabsContext.Provider>
}

export const useTabsContext = () => useContext(TabsContext)

export const TrustWallet = () => (
  <main className={styles.trustLayout}>
    <TabsContextProvider>
      <TrustWalletTabs />
    </TabsContextProvider>
  </main>
)
