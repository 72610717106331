import styles from './styles.module.scss'
import {
  useGetAgentPromotionByIdQuery,
  useGetPromotionPolicyListQuery,
} from '../../../core/http/PromotionPolicyApi'
import { Form, Select, Spin } from 'antd'
import { useContext } from 'react'
import { RightModalContext } from '../../../../Modals'
import { Wrapper } from '../../../../salesNetwork/components/Wrapper'
import FormItem from 'antd/es/form/FormItem'
import { TextField } from '../../../../../shared/components/textfield/TextField'
import TextArea from 'antd/es/input/TextArea'
import { useGetSubTransactionsQuery } from '../../../../Reports/core/Subtransactions'
import {
  ITrustWalletTransactionSub,
  TRANSACTION_CATEGORY_ENUM,
} from '../../../../Finance/models/ITransaction'
import { SubTransactions } from './SubTransactions'
import { ITableResponse } from '../../../../../shared/models/ITableResponse'
import classNames from 'classnames'

export const PromotionModal = () => {
  const [form] = Form.useForm()
  const {
    props: { id, mode },
  } = useContext(RightModalContext)

  const { data, isLoading } = useGetAgentPromotionByIdQuery(id, { skip: !id })
  const { data: dataSubTrans, isLoading: isSubTransLoading } = useGetSubTransactionsQuery(
    { id, transactionCategory: TRANSACTION_CATEGORY_ENUM.PROMOTION },
    { skip: !id }
  )
  const { data: promotionsList, isLoading: isPromotionListLoading } =
    useGetPromotionPolicyListQuery({})

  const isViewMode = mode === 'view'
  const isFetching = isLoading || isPromotionListLoading || isSubTransLoading

  const content = (
    <Form
      initialValues={data}
      form={form}
      className={classNames(styles.layout, styles.form)}
      layout='vertical'
    >
      <Wrapper title='Detail Info'>
        <FormItem name='id' label='Transaction Id'>
          <TextField disabled={isViewMode} />
        </FormItem>

        <FormItem name={['agent', 'id']} label='Agent'>
          <TextField disabled={isViewMode} />
        </FormItem>

        <FormItem name='amount' label='Amount'>
          <TextField disabled={isViewMode} />
        </FormItem>

        <div className={styles.dblRow}>
          <FormItem name='balanceBefore' label='Balance Before'>
            <TextField disabled={isViewMode} />
          </FormItem>

          <FormItem name='balanceAfter' label='Balance After'>
            <TextField disabled={isViewMode} />
          </FormItem>
        </div>

        <FormItem name={['promotionPolicy', 'id']} label='Promotion Policy'>
          <Select disabled={isViewMode}>
            {promotionsList?.items.map((promotion) => (
              <Select.Option key={promotion.id} value={promotion.id}>
                {promotion?.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        <FormItem name='description' label='Note' className='noBottomMargin'>
          <TextArea disabled={isViewMode} />
        </FormItem>
      </Wrapper>

      <SubTransactions data={dataSubTrans as ITableResponse<ITrustWalletTransactionSub>} />
    </Form>
  )

  return isFetching ? <Spin>{content}</Spin> : content
}
