import React, { useEffect, useState } from 'react'

import Vendor from './Components/Vendor'
import { ISystemMonitoring } from '../../models/SystemMonitoring'
import styles from './styles.module.scss'
import { systemMonitoringSocketConnection } from '../../../../shared/sockets'
import { useGetProfileQuery } from '../../../Profile/core/http/Profile'
import { NotFoundPage } from '../../../NotFoundPage'
import { AMIN_PAY_VENDOR_PROD_ID } from '../../../../helpers/contants'

const SupportSystemMonitoring = () => {
  const { data: profile } = useGetProfileQuery()
  const [data, setData] = useState<ISystemMonitoring[]>([])

  useEffect(() => {
    if (!profile) return
    const socket = systemMonitoringSocketConnection.getSocket()
    socket?.emit('data')

    const interval = setInterval(() => {
      socket?.emit('data')
    }, 60000)

    return () => {
      clearInterval(interval)
      socket.removeListener('data')
    }
  }, [profile])

  useEffect(() => {
    if (!profile) return
    const socket = systemMonitoringSocketConnection.getSocket()

    socket?.on('data', (resp: ISystemMonitoring[]) => {
      setData(resp)
    })
  }, [profile])

  return (
    <div className={styles.layout}>
      {/*<FiltersHeader setActiveFilters={setFilterValues} filterValues={filterValues} data={[]} />*/}
      {data.length ? (
        data
          .filter((ven) => ven.vendor.name && String(ven.vendor?.id) !== AMIN_PAY_VENDOR_PROD_ID)
          .map((monitoring) => <Vendor key={monitoring?.vendor?.id} monitoring={monitoring} />)
      ) : (
        <div className={styles.center}>
          <NotFoundPage />
        </div>
      )}
    </div>
  )
}

export default SupportSystemMonitoring
