import { IByUser } from 'src/shared/models/IByUser'
import { ILocation } from 'src/shared/models/ILocation'
import { PAYMENT_POLICY_TYPE_ENUM } from './ITargetGroup'

export enum VENDOR_TYPE_ENUM {
  VENDOR = 'Vendor',
  AGGREGATOR = 'Aggregator',
  SERVICE_PROVIDER = 'ServiceProvider',
  PROCESSOR = 'Processor',
  SIM_CARD = 'SimCard',
}

export enum CONNECTION_TYPE_ENUM {
  PUBLIC_IP = 'public-ip',
  URL = 'url',
  VPN = 'vpn',
}

export interface IVendor {
  id: number
  logo: string
  status: boolean
  createdByUser: IByUser
  updatedByUser: IByUser
  createdAt: Date
  updatedAt: Date
  name: string
  vendorType: VENDOR_TYPE_ENUM
  website: string
  paymentPolicyType: PAYMENT_POLICY_TYPE_ENUM
  isDeleteLogo?: boolean
  addressLine1: string
  addressLine2: string | null
  country: ILocation
  province: ILocation
  district: ILocation
  connectionType: CONNECTION_TYPE_ENUM
  connectionValue: string
  controlPanelURL: string | null
  controlPanelUsername: string | null
  controlPanelPassword: string | null
  contacts?: Array<number>
  products?: Array<number>
  operatorId?: number
  operator: IByUser
  simNumber: string
}

export interface IVendorTable {
  id: string
  createdAt: Date
  name: string
  vendorType: string
  website: string
  logo: string
  status: boolean
  createdByUser: IByUser
}

export interface ILocationTransformed {
  value: number
  label: string
}

export interface IVendorTransformed extends Omit<IVendor, 'country' | 'province' | 'district'> {
  country: ILocationTransformed
  province: ILocationTransformed
  district: ILocationTransformed
}

export interface IVendorManagement {
  id: number
  companyName: string
  vendorName: string
  link: string
  addedBy: string
  createdAt: Date
  status: boolean
  addressLine2?: string | null
  controlPanelURL?: string | null
  controlPanelUsername?: string | null
  controlPanelPassword?: string | null
}

export interface IVendorContact {
  id: number
  vendor?: IByUser
  name: string
  avatar: string
  type: string
  status: boolean
  department: string
  position: string
  createdAt: string
}
export interface IContactObj {
  id: number
  type: string
  value: string
}

export interface IVendorContactResponseView extends IVendorContact {
  email: IContactObj[]
  mobileNumber: IContactObj[]
  whatsapp: IContactObj[]
  skype: IContactObj[]
}

export interface IDepartments {
  name: string
}

export interface IVendorContactContactInfoCreate {
  value: string | null
  type: string | null
}

export interface IVendorContactContactInfoUpdate {
  id: number
  value: string | null
  type: string | null
}

export interface IVendorContactContactInfoPutRequest {
  toCreate?: IVendorContactContactInfoCreate[]
  toUpdate?: IVendorContactContactInfoUpdate[]
  toDelete?: number[]
}

export interface IVendorBalance {
  currentBalance: number
  id: string
  lastPO: string
  thresholdAmount: number
  logo: string | null
  name: string
  inventoryId?: number
  color: string | null
  children?: Array<IVendorBalance>
}

export interface IVendorBalanceTable extends IVendorBalance {
  inventoryId?: number
  children?: Array<IVendorBalance>
}

export interface IVendorBalanceChart {
  currentBalance: number
  id: string
  logo: string
  name: string
  poAmount: number
  color: string
}

export interface IVendorPaymentPolicyType {
  vendorWalletType: string
  paymentPolicyType: PAYMENT_POLICY_TYPE_ENUM
  discountRate: number
}

export interface IVendorPaymentPolicyProduct {
  vendorWalletType: string
  product: IByUser
  discountRate: number
}

export interface IVendorPaymentPolicyProductUpdate
  extends Omit<IVendorPaymentPolicyProduct, 'product'> {
  productId: number
}
