import { Space, Tabs } from 'antd'
import { DISCOUNT_POLICY_TABS_ENUM } from './index.d'
import { IconButton } from '../../../../../../../shared/components/iconButton/IconButton'
import { AddIcon, EditIcon } from '../../../../../../../assets/svg'
import { Toggle } from '../../../../../../../shared/components/toggle/Toggle'
import { useContext, useEffect } from 'react'
import { InformationTab } from '../InformationTab'
import { useAppDispatch } from '../../../../../../../redux'
import {
  useGetDiscountPolicyByIdQuery,
  useUpdateDiscountPolicyMutation,
} from '../../../../../core/http/DiscountPolicyApi'
import { useLocation, useNavigate, useParams } from 'react-router'
import { setBreadcrumb } from '../../../../../../app/core/BreadcrumbsSlice'
import { TargetGroupTable } from '../TargetGroupTable'
import { SETTINGS_ENUM } from '../../../../../../../routes/settings'
import { RightModalContext } from '../../../../../../Modals'
import { RIGHT_MODALS } from '../../../../../../../helpers/contants'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../shared/api/errorHandler'
import { useSearchParams } from 'react-router-dom'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'
import { agentPolicyApi } from '../../../../../../salesNetwork/core/http/AgentPolicyApi'

const Operations = ({ activeTab }: { activeTab: string }) => {
  const { onOpen } = useContext(RightModalContext)
  const navigate = useNavigate()
  const { id, mode } = useParams()
  const dispatch = useAppDispatch()

  const [updDisc, updDiscResp] = useUpdateDiscountPolicyMutation()
  const { data, isFetching } = useGetDiscountPolicyByIdQuery(id as string)

  useNotification(NOTIFICATION_TYPES.success, updDiscResp.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, updDiscResp.isError, updDiscResp.error as ErrorNode)

  const onEditButtonClick = () => {
    navigate(`${SETTINGS_ENUM.SALES_NETWORK_CONFIGURATION}/discount-policy/edit/${id}`)
  }

  const onAddButtonClick = () => {
    onOpen(RIGHT_MODALS.SETTINGS.NETWORK_CONFIGURATION_TARGET_GROUP_LIST, {
      title: 'Select Target Group',
      discountId: id,
    })
  }

  const onStatusChange = (status: boolean) => {
    updDisc({ id: id as string, body: { status } })
  }

  useEffect(() => {
    if (data) {
      dispatch(setBreadcrumb(data))
    }
  }, [data, dispatch])

  useEffect(() => {
    if (updDiscResp.data) {
      dispatch(agentPolicyApi.util.invalidateTags(['IDiscountTemplate', 'IAgentDiscountPolicy']))
    }
  }, [dispatch, updDiscResp.data])

  const isTargetGroupTabActive = activeTab === DISCOUNT_POLICY_TABS_ENUM.TARGET_GROUP

  return (
    <Space size={10}>
      {!isTargetGroupTabActive && mode === 'view' && (
        <IconButton color='orange' icon={<EditIcon />} onClick={onEditButtonClick} />
      )}
      <Toggle
        defaultChecked={data?.status}
        checked={data?.status}
        text='Status'
        inWrapper
        loading={updDiscResp.isLoading || isFetching}
        onChange={onStatusChange}
      />
      {isTargetGroupTabActive && (
        <IconButton color='orange' icon={<AddIcon />} type='primary' onClick={onAddButtonClick} />
      )}
    </Space>
  )
}

export const DiscountPolicyDetailViewTabs = () => {
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: DISCOUNT_POLICY_TABS_ENUM.INFORMATION,
  })

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  const items = [
    {
      label: DISCOUNT_POLICY_TABS_ENUM.INFORMATION,
      key: DISCOUNT_POLICY_TABS_ENUM.INFORMATION,
      children: <InformationTab />,
    },
    {
      label: DISCOUNT_POLICY_TABS_ENUM.TARGET_GROUP,
      key: DISCOUNT_POLICY_TABS_ENUM.TARGET_GROUP,
      children: <TargetGroupTable />,
    },
  ]

  return (
    <Tabs
      activeKey={activeTab}
      onChange={onTabChange}
      items={items}
      tabBarExtraContent={<Operations activeTab={activeTab} />}
    />
  )
}
