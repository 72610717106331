import { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { Tabs } from 'antd'
import { VENDOR_MANAGEMENT_TABS_ENUM } from './index.d'
import { VendorManagementTable } from '../VendorManagementTable'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { AddIcon, CloudDownloadIcon } from 'src/assets/svg'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { CENTER_MODALS, RIGHT_MODALS } from '../../../../../../helpers/contants'
import { Emitter, EmitterEvents } from '../../../../../../helpers/eventEmitter'
import { TableExportFormatEnum } from '../../../../../../shared/hooks/table/useTableExport'
import { CenterModalContext, RightModalContext } from '../../../../../Modals'
import { MasterProductsTable } from '../MasterProductsTable'
import { Button } from 'src/shared/components/button/Button'
import usePermissions from '../../../../../../shared/hooks/usePermissions'
import { IPermissionsActions, IPermissionsNames } from '../../../../models/IPermissions'
import WithPermissionDeniedContainer from '../../../../../../shared/components/withPermissionDenied'
import { useGetDefaultTab } from 'src/features/salesNetwork/helpers/useGetDefaultTab'
import { ResponseCodesTable } from '../ResponseCodesTable'
import { NotificationManagerTable } from '../NotificationManagerTable'
import { WhatsAppManagerTab } from '../WhatsAppManagerTab'

interface IPropsOperations {
  canAddNew: boolean
  canExport: boolean
  onAddButtonClick: () => void
}

const Operations = memo(({ canAddNew, canExport, onAddButtonClick }: IPropsOperations) => {
  const { onOpen, onClose } = useContext(CenterModalContext)

  const handlerExportClick = () => {
    onOpen(CENTER_MODALS.EXPORT_TABLE, {
      title: 'Export Table',
      onExportCSV: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.csv),
      onExportXLSX: () => Emitter.emit(EmitterEvents.EXPORT_TABLE, TableExportFormatEnum.xlsx),
      footer: <Button onClick={onClose}>Cancel</Button>,
    })
  }

  return (
    <div style={{ display: 'flex', gridGap: 10 }}>
      {canExport && (
        <IconButton
          icon={<CloudDownloadIcon />}
          type='primary'
          color='blue'
          onClick={handlerExportClick}
        />
      )}
      {canAddNew && (
        <IconButton icon={<AddIcon />} type='primary' color='orange' onClick={onAddButtonClick} />
      )}
    </div>
  )
})

export const VendorManagementTabs = () => {
  const { onOpen } = useContext(RightModalContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()

  const { activeTab, setActiveTab } = useGetDefaultTab({
    activeTab: VENDOR_MANAGEMENT_TABS_ENUM.VENDOR_MANAGEMENT,
  })

  /** Permissions */
  const { canPerformAction, getModulesIdsByNames } = usePermissions()

  const [vendorsId, productsId] = getModulesIdsByNames([
    IPermissionsNames.Vendor,
    IPermissionsNames.Product,
  ])

  const demandPermissions = [
    IPermissionsActions.VIEW,
    IPermissionsActions.ADD,
    IPermissionsActions.EXPORT,
    IPermissionsActions.EDIT,
    IPermissionsActions.DELETE,
  ]

  const [canShowVendors, canAddVendor, canExportVendors, canEditVendor, canDeleteVendor] =
    canPerformAction(vendorsId, demandPermissions)

  const [canShowProducts, canAddProduct, canExportProducts, canEditProduct] = canPerformAction(
    productsId,
    demandPermissions
  )

  /** Table */
  const currentTabData = useMemo(() => {
    if (activeTab === VENDOR_MANAGEMENT_TABS_ENUM.VENDOR_MANAGEMENT) {
      return {
        navigateTo: 'new',
        canAdd: canAddVendor,
        canExport: canExportVendors,
      }
    }

    if (activeTab === VENDOR_MANAGEMENT_TABS_ENUM.MASTER_PRODUCTS) {
      return {
        navigateTo: 'master-product/new',
        canAdd: canAddProduct,
        canExport: canExportProducts,
      }
    }

    if (activeTab === VENDOR_MANAGEMENT_TABS_ENUM.SYSTEM_RESPONSE_CODES) {
      return {
        navigateTo: 'response-code/new',
        canAdd: true,
        canExport: true,
      }
    }

    if (activeTab === VENDOR_MANAGEMENT_TABS_ENUM.NOTIFICATION_MANAGER) {
      return {
        navigateTo: 'notification-manager/new',
        canAdd: true,
        canExport: false,
      }
    }

    if (activeTab === VENDOR_MANAGEMENT_TABS_ENUM.WHATSAPP_MANAGER) {
      return {
        onOpen: () =>
          onOpen(RIGHT_MODALS.SETTINGS.WHATSAPP_MANAGER_BUTTONS, {
            title: 'WhatsApp Buttons & Actions',
          }),
        canAdd: true,
        canExport: false,
      }
    }

    return {
      canAdd: false,
      canExport: false,
    }
  }, [activeTab, canAddProduct, canAddVendor, canExportProducts, canExportVendors, onOpen])

  const items = useMemo(
    () => [
      {
        label: VENDOR_MANAGEMENT_TABS_ENUM.VENDOR_MANAGEMENT,
        key: VENDOR_MANAGEMENT_TABS_ENUM.VENDOR_MANAGEMENT,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canShowVendors}>
            <VendorManagementTable
              canAdd={canAddVendor}
              canEdit={canEditVendor}
              canDelete={canDeleteVendor}
            />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: VENDOR_MANAGEMENT_TABS_ENUM.MASTER_PRODUCTS,
        key: VENDOR_MANAGEMENT_TABS_ENUM.MASTER_PRODUCTS,
        children: (
          <WithPermissionDeniedContainer isAllowedView={canShowProducts}>
            <MasterProductsTable canAdd={canAddProduct} canEdit={canEditProduct} />
          </WithPermissionDeniedContainer>
        ),
      },
      {
        label: VENDOR_MANAGEMENT_TABS_ENUM.SYSTEM_RESPONSE_CODES,
        key: VENDOR_MANAGEMENT_TABS_ENUM.SYSTEM_RESPONSE_CODES,
        children: <ResponseCodesTable />,
      },
      {
        label: VENDOR_MANAGEMENT_TABS_ENUM.NOTIFICATION_MANAGER,
        key: VENDOR_MANAGEMENT_TABS_ENUM.NOTIFICATION_MANAGER,
        children: <NotificationManagerTable />,
      },
      {
        label: VENDOR_MANAGEMENT_TABS_ENUM.WHATSAPP_MANAGER,
        key: VENDOR_MANAGEMENT_TABS_ENUM.WHATSAPP_MANAGER,
        children: <WhatsAppManagerTab />,
      },
    ],
    [
      canAddProduct,
      canAddVendor,
      canDeleteVendor,
      canEditProduct,
      canEditVendor,
      canShowProducts,
      canShowVendors,
    ]
  )

  useEffect(() => {
    if (!location.state) return
    setActiveTab(location.state.activeTab)
  }, [location, setActiveTab])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
    setSearchParams('')
  }

  const onAddButtonClick = useCallback(() => {
    if (currentTabData?.navigateTo) {
      return navigate(currentTabData.navigateTo)
    }
    if (currentTabData?.onOpen) {
      return currentTabData?.onOpen()
    }
  }, [currentTabData, navigate])

  return (
    <Tabs
      activeKey={activeTab}
      destroyInactiveTabPane
      tabBarExtraContent={
        <Operations
          canAddNew={currentTabData.canAdd}
          canExport={currentTabData.canExport}
          onAddButtonClick={onAddButtonClick}
        />
      }
      items={items}
      onChange={onTabChange}
    />
  )
}
