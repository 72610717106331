import { Row } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { PasswordIcon2 } from 'src/assets/svg'
import { TextField } from 'src/shared/components/textfield/TextField'

// styles
import styles from '../../../ProfileInfo/styles.module.scss'
import { Wrapper } from '../Wrapper'

interface IPassword {
  view?: boolean
}

export const PasswordBlock = ({}: IPassword) => {
  const [oldPasswordValue, setOldPasswordValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [isErrorsPasswords, setIsErrorsPasswords] = useState(false)

  useEffect(() => {
    passwordValue === confirmPassword ? setIsErrorsPasswords(false) : setIsErrorsPasswords(true)
    if (oldPasswordValue && !passwordValue) setIsErrorsPasswords(true)
  }, [oldPasswordValue, passwordValue, confirmPassword])

  return (
    <Wrapper
      title='Password'
      className={classNames(styles['wrapper-password'], {
        [styles['passwordError']]: isErrorsPasswords,
      })}
    >
      <PasswordComponent
        label='Old Password'
        name='oldPassword'
        onChange={(e) => setOldPasswordValue(e.target.value)}
      />
      <PasswordComponent
        name='newPassword'
        label='Password'
        onChange={(e) => setPasswordValue(e.target.value)}
      />
      <PasswordComponent
        label='Confirm Password'
        onChange={(e) => setConfirmPassword(e.target.value)}
        isError={isErrorsPasswords}
      />
    </Wrapper>
  )
}

interface IPasswordIcon {
  isShowPassword: boolean
  onClick: () => void
}

const PasswordIconComponent = ({ onClick, isShowPassword = false }: IPasswordIcon) => {
  return (
    <div
      className={classNames(styles['wrapper-password-icon'], {
        [styles['showPassword']]: isShowPassword,
      })}
      onClick={onClick}
    >
      <PasswordIcon2 />
    </div>
  )
}

interface IPasswordComponent {
  label: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isError?: boolean
  name?: string
  view?: boolean
}

const PasswordComponent = ({ label, onChange, isError, name, view }: IPasswordComponent) => {
  const [isShowPassword, setIsShowPassword] = useState(false)
  const onClick = () => setIsShowPassword((pre) => !pre)

  return (
    <Row justify='space-between' style={{ position: 'relative' }}>
      <FormItem
        name={name}
        label={label}
        className={classNames(styles['form-item-inp'], styles['password'], {
          'ant-form-item-has-error': isError,
        })}
      >
        <TextField
          onChange={onChange}
          type={!isShowPassword ? 'password' : 'text'}
          disabled={view}
        />
      </FormItem>
      <PasswordIconComponent onClick={onClick} isShowPassword={isShowPassword} />
    </Row>
  )
}
