import styles from '../../styles.module.scss'
import { Form, FormListOperation, Select } from 'antd'
import { requiredValidator } from '../../../../../../helpers/validation'
import { IconButton } from '../../../../../../shared/components/iconButton/IconButton'
import { BucketIcon } from '../../../../../../assets/svg'
import { Toggle } from '../../../../../../shared/components/toggle/Toggle'
import React, { useEffect } from 'react'
import { useLazyGetProductsListQuery } from '../../../../core/http/VendorManagementApi'
import { ITableResponse } from '../../../../../../shared/models/ITableResponse'
import { IMasterProduct, IMasterProductProduct } from '../../../../models/IMasterProduct'
import { IViewMode } from '../../../../../Finance/models/IViewMode'
import { IVendorTable } from '../../../../models/IVendorManagement'
import { MASTER_PRODUCT_TYPE_ENUM, PRODUCT_TYPE_ENUM } from '../../../../models/IProduct'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import FormItem from 'antd/es/form/FormItem'
import { IPolicyProduct } from '../../../../models/IDiscountPolicy'

interface IProps extends IViewMode {
  masterProductsList?: ITableResponse<IMasterProduct>
  vendorsList?: ITableResponse<IVendorTable>
  isMasterBundle: boolean
  name: number
  masterProductId?: string
  type: string
  remove: FormListOperation['remove']
  vendorId?: string
}

export const MasterProductListItem = ({
  masterProductsList,
  isMasterBundle,
  name,
  view,
  vendorsList,
  masterProductId,
  type,
  remove,
  vendorId,
}: IProps) => {
  const [getProducts, getProductsResp] = useLazyGetProductsListQuery()

  useEffect(() => {
    if (vendorId) {
      getProducts({ vendorId })
    }
  }, [getProducts, vendorId])

  return (
    <div className={styles.productRowWrapper}>
      {!isMasterBundle && (
        <Form.Item noStyle shouldUpdate>
          {({ setFieldValue }) => (
            <Form.Item label='Vendor' name={[name, 'vendor', 'id']} style={{ flexBasis: '40%' }}>
              <Select
                allowClear
                disabled={view}
                placeholder='Vendor'
                onChange={() => {
                  setFieldValue(['products', name, 'productId'], null)
                }}
              >
                {vendorsList?.items.map((vendor) => (
                  <Select.Option key={vendor.id} value={vendor.id}>
                    {vendor.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form.Item>
      )}
      {isMasterBundle ? (
        <Form.Item
          name={[name, 'masterProduct', 'id']}
          label='Bundle Topup'
          rules={[
            {
              required: true,
              validator: requiredValidator('Bundle Topup'),
            },
          ]}
          style={{ flexBasis: '50%' }}
        >
          <Select disabled={view} placeholder='Bundle Topup' allowClear>
            {masterProductsList?.items
              .filter((prod) => {
                if (masterProductId && prod.id === Number(masterProductId)) {
                  return
                }
                return prod
              })
              .map((product) => (
                <Select.Option key={product.id} value={product.id}>
                  {product.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      ) : (
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const selectedProducts = (
              getFieldValue('products') as Array<IMasterProductProduct>
            )?.map((product) => product?.productId)

            return (
              <Form.Item
                name={[name, 'productId']}
                label='Product'
                rules={[
                  {
                    required: true,
                    validator: requiredValidator('Product'),
                  },
                ]}
                style={{ flexBasis: '50%' }}
              >
                <Select disabled={view} placeholder='Product' allowClear>
                  {getProductsResp?.data?.items
                    .filter((product) => {
                      if (product.type === PRODUCT_TYPE_ENUM.E_PIN) {
                        return product
                      }

                      return product?.type === type
                    })
                    .map((product) => (
                      <Select.Option
                        key={product?.id}
                        value={product?.id}
                        disabled={selectedProducts?.includes(product?.id)}
                      >
                        {product?.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )
          }}
        </FormItem>
      )}

      {isMasterBundle ? (
        <Form.Item
          name={[name, 'order']}
          label='Order'
          rules={[{ required: true, validator: requiredValidator('Order') }]}
          style={{ flexBasis: '45%', minWidth: 100 }}
        >
          <Select disabled={view} placeholder='Order' allowClear>
            {Array.from({ length: 100 }, (_, i) => i + 1).map((order) => (
              <Select.Option key={order} value={order}>
                {order}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <Form.Item
          name={[name, 'priority']}
          label='Priority'
          rules={[{ required: true, validator: requiredValidator('Priority') }]}
          style={{ flexBasis: '5%', minWidth: 100 }}
        >
          <Select disabled={view} placeholder='Priority' allowClear>
            {Array.from({ length: 10 }, (_, i) => i + 1).map((priority) => (
              <Select.Option key={priority} value={priority}>
                {priority}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item style={{ flexBasis: '5%' }}>
        <div className={styles.bucketIcon}>
          <IconButton
            block
            size='large'
            color='orange'
            icon={<BucketIcon />}
            type='default'
            disabled={view}
            onClick={() => {
              remove(name)
            }}
          />
        </div>
      </Form.Item>
      {!isMasterBundle && (
        <div className={styles.statusButton}>
          <Form.Item name={[name, 'status']} style={{ flexBasis: '10%' }} valuePropName='checked'>
            <Toggle disabled={view} />
          </Form.Item>
        </div>
      )}
    </div>
  )
}
