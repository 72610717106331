import { useFilter } from '../../../../../../../../shared/hooks/filter/useFilter'
import { Row } from 'antd'
import { Filter, ISelectedFilters } from '../../../../../../../../shared/components/filter/Filter'
import { FilterPopup } from '../../../../../../../../shared/components/filter/FilterPopup'
import { IFilterFields } from '../../../../../../../../shared/models/IFilterFields'
import { TextFieldSearch } from 'src/shared/components/textFieldSearch/TextFieldSearch'

// styles
import styles from '../../styles.module.scss'

interface IProps {
  filterValues: ISelectedFilters
  data?: IFilterFields[]
  setActiveFilters: (values: ISelectedFilters) => void
  onSearchChange: React.ChangeEventHandler<HTMLInputElement>
}

export const ModalHeader = ({
  data,
  filterValues,
  setActiveFilters,
  onSearchChange,
}: IProps): JSX.Element => {
  const { form, isOpen, onCancel, onReset, onFinish } = useFilter({ setActiveFilters })

  return (
    <>
      <Row align='middle' justify='space-between' style={{ marginBottom: 18, width: '100%' }}>
        <TextFieldSearch className={styles.search} onChange={onSearchChange} />
        <Filter
          form={form}
          data={data}
          setIsOpen={onCancel}
          selectedFilters={filterValues}
          setSelectedFilters={setActiveFilters}
          sizeFilter='large'
          classNamesFilterWrapperIcon={styles.filterWrraperIcon}
        />
      </Row>
      {isOpen && (
        <FilterPopup
          className={styles.filterPopup}
          data={data}
          setActiveFilters={setActiveFilters}
          filterValues={filterValues}
          form={form}
          onCancel={onCancel}
          onReset={onReset}
          onFinish={onFinish}
        />
      )}
    </>
  )
}
