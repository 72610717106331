import { Form, Select, Spin, UploadFile } from 'antd'
import { useContext, useEffect, useMemo, useState } from 'react'
import { RightModalContext } from '../../../../../../Modals'
import { Wrapper } from '../../../../../../salesNetwork/components/Wrapper'
import styles from './styles.module.scss'
import { TextField } from '../../../../../../../shared/components/textfield/TextField'
import TextArea from 'antd/es/input/TextArea'
import {
  maxNumber,
  positiveNumber,
  requiredValidator,
} from '../../../../../../../helpers/validation'
import { Button } from '../../../../../../../shared/components/button/Button'
import { UploaderFiles } from 'src/shared/components/uploaderPhotos/UploaderFiles'
import { compatibleUplaodTypes, RIGHT_MODALS } from '../../../../../../../helpers/contants'
import { formatDateWithTime } from '../../../../../../../helpers/utils'
import {
  ADJUSTMENT_TYPE_ENUM,
  IAdjustmentCreate,
  IAdjustmentDetail,
} from '../../../../../../Finance/models/IWalletStatement'
import { isNumber, startCase } from 'lodash'
import { DEBIT_CREDIT_ENUM } from '../../../../../../Finance/models/ITransaction'
import { useGetBankAccountsQuery } from '../../../../../../Finance/core/http/BankAccountApi'
import {
  useGetVendorListQuery,
  useGetVendorWalletTypesQuery,
} from '../../../../../../Settings/core/http/VendorManagementApi'
import {
  statementOfWalletsApi,
  useAddAttachmentsToAdjustmentMutation,
  useCreateAdjustmentMutation,
  useGetAdjustmentByIdQuery,
} from '../../../../../../Finance/core/http/StatementOfWalletsApi'
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../shared/hooks/useNotification'
import { ErrorNode } from '../../../../../../../shared/api/errorHandler'
import TransactionStatus from '../../../../../../../shared/components/transactionStatus'
import { useGetProfileQuery } from 'src/features/Profile/core/http/Profile'
import {
  agentManagementApi,
  useGetAgentByIdQuery,
  useGetAgentListQuery,
} from '../../../../../../salesNetwork/core/http/AgentManagement'
import FormItem from 'antd/es/form/FormItem'
import { useAppDispatch } from '../../../../../../../redux'

export const AdjustmentModal = () => {
  const dispatch = useAppDispatch()
  const {
    props: { id, mode, agentId },
    onOpen,
    onClose,
  } = useContext(RightModalContext)

  const [currentAgentId, setCurrentAgentId] = useState<null | number>(null)
  const [adjustmentType, setAdjustmentType] = useState<ADJUSTMENT_TYPE_ENUM | null>(null)
  const [files, setFiles] = useState<UploadFile[]>([])
  const [form] = Form.useForm()

  const { data: bankAccountList, isFetching: isFetchingBank } = useGetBankAccountsQuery(
    {},
    { skip: adjustmentType !== ADJUSTMENT_TYPE_ENUM.BANK_ACCOUNT_ADJUSTMENT }
  )
  const { data: vendorList, isFetching: isFetchingVendor } = useGetVendorListQuery(
    {},
    { skip: adjustmentType !== ADJUSTMENT_TYPE_ENUM.VENDOR_WALLET_ADJUSTMENT }
  )
  const { data: agentList, isFetching: isFetchingAgents } = useGetAgentListQuery(
    {},
    {
      skip:
        adjustmentType === ADJUSTMENT_TYPE_ENUM.VENDOR_WALLET_ADJUSTMENT ||
        adjustmentType === ADJUSTMENT_TYPE_ENUM.BANK_ACCOUNT_ADJUSTMENT,
    }
  )
  const { data: agentData, isFetching: isFetchingAgent } = useGetAgentByIdQuery(
    currentAgentId as number,
    {
      skip: !currentAgentId,
    }
  )

  const { data, isFetching } = useGetAdjustmentByIdQuery(id as string, { skip: !id })
  const [crAdj, crAdjResp] = useCreateAdjustmentMutation()
  const [addAttach, addAttachResp] = useAddAttachmentsToAdjustmentMutation()

  const { data: profile } = useGetProfileQuery()
  const { data: vendorWalletTypes } = useGetVendorWalletTypesQuery()

  const isViewMode = mode === 'view'
  const isEditMode = mode === 'edit'
  const isLoading = crAdjResp.isLoading || addAttachResp.isLoading || isFetching

  const afterFileAdd = () => {
    onOpen(RIGHT_MODALS.FINANCE.ADJUSTMENT, {
      mode: 'view',
      id,
    })
    setFiles([])
  }

  const invalidateAgent = () => {
    dispatch(statementOfWalletsApi.util.invalidateTags(['WalletsList', 'StatementList']))
    currentAgentId &&
      dispatch(
        agentManagementApi.util.invalidateTags([
          { type: 'IAgentAllGetResponse', id: currentAgentId },
        ])
      )
  }

  useNotification(NOTIFICATION_TYPES.success, crAdjResp.isSuccess, null, invalidateAgent)
  useNotification(NOTIFICATION_TYPES.success, addAttachResp.isSuccess, null, afterFileAdd)
  useNotification(NOTIFICATION_TYPES.error, crAdjResp.isError, crAdjResp.error as ErrorNode)
  useNotification(NOTIFICATION_TYPES.error, addAttachResp.isError, addAttachResp.error as ErrorNode)

  const dispatchFiles = (arr: UploadFile[]) => setFiles(arr)

  const onConfirmClick = () => {
    if (isViewMode) {
      return onOpen(RIGHT_MODALS.FINANCE.ADJUSTMENT, {
        mode: 'edit',
        id,
      })
    }

    form.submit()
  }

  const onFinish = (values: IAdjustmentDetail) => {
    const body = {
      type: values.type,
      amount: +values.amount,
      description: values.description,
      drCr: values.drCr,
      ...(values?.vendor && { vendorId: +values?.vendor?.id }),
      ...(values?.vendorWalletType && { vendorWalletType: values?.vendorWalletType }),
      ...(values?.agent && { agentId: +values?.agent?.id }),
      ...(values?.bankAccount && { bankAccountId: values?.bankAccount?.id }),
    } as IAdjustmentCreate

    crAdj(body)
  }

  const attachFiles = () => {
    const attachedFiles = new FormData()
    Object.entries(files).forEach(([, value]) =>
      attachedFiles.append('files', value.originFileObj!)
    )
    addAttach({ id: id as string, body: attachedFiles })
  }

  const targetWallets = useMemo(
    () =>
      (Object.keys(ADJUSTMENT_TYPE_ENUM) as Array<keyof typeof ADJUSTMENT_TYPE_ENUM>)
        .map((method) => ({
          label: startCase(ADJUSTMENT_TYPE_ENUM[method].toLowerCase()),
          value: ADJUSTMENT_TYPE_ENUM[method],
        }))
        .filter((targetWal) => {
          if (agentId) {
            return (
              targetWal.value !== ADJUSTMENT_TYPE_ENUM.BANK_ACCOUNT_ADJUSTMENT &&
              targetWal.value !== ADJUSTMENT_TYPE_ENUM.VENDOR_WALLET_ADJUSTMENT
            )
          }
          return targetWal
        }),
    [agentId]
  )

  const drCrOptions = useMemo(
    () => [
      {
        label: 'Debit',
        value: DEBIT_CREDIT_ENUM.DEBIT,
      },
      {
        label: 'Credit',
        value: DEBIT_CREDIT_ENUM.CREDIT,
      },
    ],
    []
  )

  const getAgentWalletField = (targetWallet: ADJUSTMENT_TYPE_ENUM, dr: DEBIT_CREDIT_ENUM) => {
    if (targetWallet === ADJUSTMENT_TYPE_ENUM.AGENT_WALLET_ADJUSTMENT) {
      return dr === DEBIT_CREDIT_ENUM.DEBIT ? 'amount' : ''
    }
    if (targetWallet === ADJUSTMENT_TYPE_ENUM.AGENT_ESCROW_WALLET_ADJUSTMENT) {
      return dr === DEBIT_CREDIT_ENUM.DEBIT ? 'escrowAmount' : ''
    }
    if (targetWallet === ADJUSTMENT_TYPE_ENUM.HOLD_AGENT_WALLET_ADJUSTMENT) {
      return dr === DEBIT_CREDIT_ENUM.DEBIT ? 'escrowAmount' : 'amount'
    }
  }

  useEffect(() => {
    if (crAdjResp.data) {
      onClose()
    }
  }, [crAdjResp.data, onClose, onOpen])

  useEffect(() => {
    if (data) {
      setAdjustmentType(data?.type)
    }
    form.setFieldsValue({
      ...data,
      createdAt: formatDateWithTime(data?.createdAt, profile?.calendar),
    })
  }, [data, form, profile])

  useEffect(() => {
    if (agentId) {
      setAdjustmentType(ADJUSTMENT_TYPE_ENUM.AGENT_WALLET_ADJUSTMENT)
      setCurrentAgentId(agentId)
      form.setFieldValue(['agent', 'id'], agentId)
      form.setFieldValue('type', ADJUSTMENT_TYPE_ENUM.AGENT_WALLET_ADJUSTMENT)
    }
  }, [agentId, form])

  const loading =
    isLoading || isFetchingAgents || isFetchingAgent || isFetchingVendor || isFetchingBank

  const content = (
    <Form form={form} layout='vertical' className={styles.form} onFinish={onFinish}>
      <div className={styles.formBlock}>
        <Wrapper
          title='Transaction Details'
          statusButton={data?.status && <TransactionStatus status={data?.status} />}
        >
          <div>
            <Form.Item name='createdAt' label='Date and Time'>
              <TextField disabled />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              name='type'
              label='Target Wallet'
              rules={[{ required: true, validator: requiredValidator('Target Wallet') }]}
            >
              <Select
                placeholder='Select Target Wallet'
                disabled={isViewMode || mode}
                options={targetWallets}
                onChange={(val) => {
                  setAdjustmentType(val)
                }}
                allowClear
              />
            </Form.Item>
          </div>

          <div>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, resetFields }) => {
                const targetWallet = getFieldValue('type')

                if (targetWallet === ADJUSTMENT_TYPE_ENUM.BANK_ACCOUNT_ADJUSTMENT) {
                  return (
                    <Form.Item
                      label='Bank Account'
                      name={['bankAccount', 'id']}
                      rules={[{ required: true, validator: requiredValidator('Bank Account') }]}
                    >
                      <Select
                        disabled={isViewMode || mode}
                        placeholder='Select Bank Account'
                        allowClear
                      >
                        {bankAccountList?.items?.map((bank) => (
                          <Select.Option key={bank.id} value={bank.id}>
                            {bank.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )
                }

                if (targetWallet === ADJUSTMENT_TYPE_ENUM.VENDOR_WALLET_ADJUSTMENT) {
                  return (
                    <>
                      <Form.Item
                        label='Vendor Wallet Type'
                        name='vendorWalletType'
                        rules={[
                          { required: true, validator: requiredValidator('Vendor Wallet Type') },
                        ]}
                      >
                        <Select
                          disabled={isViewMode || mode}
                          placeholder='Select Vendor Wallet Type'
                          allowClear
                        >
                          {vendorWalletTypes?.map((type) => (
                            <Select.Option value={type} key={type}>
                              {type}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label='Vendor'
                        name={['vendor', 'id']}
                        rules={[{ required: true, validator: requiredValidator('Vendor') }]}
                      >
                        <Select
                          disabled={isViewMode || mode}
                          placeholder='Select Vendor'
                          allowClear
                        >
                          {vendorList?.items?.map((vendor) => (
                            <Select.Option key={vendor.id} value={vendor.id}>
                              {vendor.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </>
                  )
                }

                if (
                  targetWallet === ADJUSTMENT_TYPE_ENUM.AGENT_WALLET_ADJUSTMENT ||
                  targetWallet === ADJUSTMENT_TYPE_ENUM.HOLD_AGENT_WALLET_ADJUSTMENT ||
                  targetWallet === ADJUSTMENT_TYPE_ENUM.AGENT_ESCROW_WALLET_ADJUSTMENT
                ) {
                  return (
                    <Form.Item
                      label='Agent'
                      name={['agent', 'id']}
                      rules={[{ required: true, validator: requiredValidator('Agent') }]}
                    >
                      <Select
                        disabled={isViewMode || mode}
                        placeholder='Select Agent'
                        onChange={(val) => {
                          setCurrentAgentId(val)
                          resetFields(['amount'])
                        }}
                        allowClear
                      >
                        {agentList?.items?.map((agent) => (
                          <Select.Option key={agent?.id} value={agent?.id}>
                            {agent?.companyTitle ?? `${agent?.firstName} ${agent?.lastName}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )
                }
              }}
            </Form.Item>
          </div>

          <div>
            <FormItem noStyle shouldUpdate>
              {({ resetFields }) => (
                <Form.Item
                  name='drCr'
                  label='Type of Transaction'
                  rules={[{ required: true, validator: requiredValidator('Type of Transaction') }]}
                >
                  <Select
                    placeholder='Select Transaction Type'
                    disabled={isViewMode || mode}
                    options={drCrOptions}
                    onChange={() => {
                      resetFields(['amount'])
                    }}
                    allowClear
                  />
                </Form.Item>
              )}
            </FormItem>
          </div>

          <div>
            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const targetWallet = getFieldValue('type')
                const drCr = getFieldValue('drCr')
                const shouldValidate =
                  getFieldValue('agent') &&
                  (targetWallet === ADJUSTMENT_TYPE_ENUM.AGENT_WALLET_ADJUSTMENT ||
                    targetWallet === ADJUSTMENT_TYPE_ENUM.HOLD_AGENT_WALLET_ADJUSTMENT ||
                    targetWallet === ADJUSTMENT_TYPE_ENUM.AGENT_ESCROW_WALLET_ADJUSTMENT)

                const agentWalletField = getAgentWalletField(targetWallet, drCr)

                return (
                  <Form.Item
                    name='amount'
                    label='Amount'
                    rules={[
                      { required: true, validator: requiredValidator('Amount') },
                      { validator: positiveNumber('Amount') },
                      ...(agentWalletField &&
                      agentData?.wallet &&
                      shouldValidate &&
                      isNumber(agentData?.wallet[agentWalletField])
                        ? [
                            {
                              validator: maxNumber(
                                agentData?.wallet[agentWalletField] as number,
                                'Amount'
                              ),
                            },
                          ]
                        : []),
                    ]}
                  >
                    <TextField
                      placeholder='Enter Amount'
                      type='number'
                      disabled={isViewMode || mode || !getFieldValue('drCr')}
                    />
                  </Form.Item>
                )
              }}
            </FormItem>
          </div>

          <div>
            <Form.Item name='description' label='Note'>
              <TextArea placeholder='Enter Note' disabled={isViewMode || mode} />
            </Form.Item>
          </div>

          {isViewMode && (
            <div>
              <Form.Item
                name={['createdByUser', 'name']}
                label='Created By'
                className='noBottomMargin'
              >
                <TextField placeholder='Created By' disabled />
              </Form.Item>
            </div>
          )}
        </Wrapper>

        {(isEditMode || isViewMode) && (
          <UploaderFiles
            allowTypes={compatibleUplaodTypes}
            attachmentPropName='attachment'
            btnTitle='Attach File'
            viewFiles={data?.attachments}
            dispatchData={dispatchFiles}
            onSave={attachFiles}
            isLoading={addAttachResp.isLoading}
          />
        )}
      </div>
      {!isViewMode && (
        <div className={styles.footerContainer}>
          <div className={styles.footerInnerContainer}>
            <Button color='blue' size='middle' onClick={onClose}>
              cancel
            </Button>

            <Button
              color='orange'
              size='middle'
              type='primary'
              htmlType='submit'
              disabled={isEditMode && !files.length}
              onClick={onConfirmClick}
            >
              {isViewMode ? 'edit' : 'confirm'}
            </Button>
          </div>
        </div>
      )}
    </Form>
  )

  return loading ? <Spin>{content}</Spin> : content
}
