import React, { useContext, useState } from 'react'
import { Spin, Form } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import classNames from 'classnames'

import {
  useGenerateBackupCodesMutation,
  useGoogleAuthEnableMutation,
  useGoogleAuthGenerateMutation,
} from 'src/features/authorization/core/http/TwoFactorAuthApi'

import { requiredValidator } from 'src/helpers/validation'
import { ErrorNode } from 'src/shared/api/errorHandler'
import { Button } from 'src/shared/components/button/Button'
import { TextField } from 'src/shared/components/textfield/TextField'
import { NOTIFICATION_TYPES, useNotification } from 'src/shared/hooks/useNotification'
import { CenterModalContext } from '../../index'

import googleAuthenticatorImg from 'src/assets/img/GoogleAuthenticator.png'

// styles
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { profileApi } from 'src/features/Profile/core/http/Profile'
import { saveAuthData } from 'src/shared/api'

export interface IStep2FormInstance {
  code: string
}

export const GoogleAuthenticatorModal = () => {
  const { onClose } = useContext(CenterModalContext)
  const [form] = Form.useForm<IStep2FormInstance>()
  const dispatch = useDispatch()

  const [step, setStep] = useState(0)

  const [generateQR, gData] = useGoogleAuthGenerateMutation()
  const [enableGoogleAuth, eData] = useGoogleAuthEnableMutation()
  const [generateBackupCodes] = useGenerateBackupCodesMutation()

  useNotification(NOTIFICATION_TYPES.success, eData.isSuccess, null, () => {
    saveAuthData(eData.data!)
    dispatch(profileApi.util.invalidateTags(['IProfile']))
    generateBackupCodes()
    onClose()
  })
  useNotification(NOTIFICATION_TYPES.error, eData.isError, eData.error as ErrorNode, () =>
    form.resetFields()
  )

  const handleStepChange = (value: number) => () => {
    setStep(value)

    if (value === 1) generateQR(null)
  }

  const handleVerify = (values: IStep2FormInstance) => {
    enableGoogleAuth(values)
  }

  const qrURL = gData?.data instanceof Blob ? URL.createObjectURL(gData.data) : ''

  return (
    <div className={styles.container}>
      <div className={styles.stepsContainer}>{`${step + 1}/3`}</div>
      {step === 0 && (
        <React.Fragment>
          <div className={classNames(styles.title, styles.title1)}>
            Download and install Google Authenticator on your mobile device
          </div>
          <img src={googleAuthenticatorImg} className={styles.googleAuthenticatorImg} />
          <Button size='large' color='orange' type='primary' onClick={handleStepChange(1)}>
            Next: Add your account
          </Button>
        </React.Fragment>
      )}
      {step === 1 && (
        <Spin spinning={gData.isLoading}>
          <div className={classNames(styles.title, styles.title2)}>
            Add your account to Google Authenticator
          </div>
          <div className={classNames(styles.subTitle)}>
            After clicking the “+” icon in Google Authenticator, use the camera to scan the QR code
            on the screen.
          </div>
          <img src={qrURL} className={styles.googleAuthenticatorImg} />
          <Button size='large' color='orange' type='primary' onClick={handleStepChange(2)}>
            Next: Verify your device
          </Button>
        </Spin>
      )}
      {step === 2 && (
        <Spin spinning={eData.isLoading}>
          <div className={classNames(styles.title, styles.title3)}>Verify your device</div>
          <Form
            form={form}
            layout='vertical'
            onFinish={handleVerify}
            className={styles.formContainer}
          >
            <FormItem
              name='code'
              className={styles.codeInput}
              rules={[{ validator: requiredValidator('Verification code') }]}
            >
              <TextField placeholder='Verification code' type='number' />
            </FormItem>
          </Form>

          <Button size='large' color='orange' type='primary' onClick={() => form.submit()}>
            Verify
          </Button>
        </Spin>
      )}
    </div>
  )
}
