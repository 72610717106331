export enum BankWalletTabsEnum {
  TRANSACTIONS = 'TRANSACTIONS',
  PICKUP_REQUEST = 'PICKUP REQUEST',
  REFUND_EMONEY = 'REFUND EMONEY',
  REVERSE_TRANSACTION = 'REVERSE TRANSACTION',
}

export enum PickupRequestStatusEnum {
  Made = 'Made',
  Checked = 'Checked',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Paid = 'Paid',
  Processed = 'Processed',
}

export enum PickupRequestModeEnum {
  view = 'view',
  edit = 'edit',
}
