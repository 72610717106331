import React, { useContext } from 'react'
import { useNavigate } from 'react-router'

import { ArrowLeftIcon, OpenProfileIcon } from 'src/assets/svg'
import { RightModalContext } from 'src/features/Modals'

import emptyAvatarImage from 'src/assets/img/Avatar.png'

// interfaces
import { IAgentAll } from 'src/features/salesNetwork/models/IAgent'
import { IconButton } from 'src/shared/components/iconButton/IconButton'
import { Toggle } from '../../../../../../../../shared/components/toggle/Toggle'

// styles
import styles from '../../steps/AgentProfileStep/styles.module.scss'

interface IAgentMainInfo {
  agent: IAgentAll
  onChangeStep: () => void
  onStatusChange?: (checked: boolean) => void
  onStatusMpinChange?: (checked: boolean) => void
}

export const AgentMainInfo: React.FC<IAgentMainInfo> = ({
  agent,
  onChangeStep,
  onStatusChange,
  onStatusMpinChange,
}) => {
  const navigate = useNavigate()
  const { onClose } = useContext(RightModalContext)

  const handleOpenProfile = () => {
    navigate(`sales-network/agent-management/view/${agent.id}`)
    onClose()
  }

  return (
    <React.Fragment>
      <div className={styles.headerContainer}>
        <IconButton color='blue' icon={<ArrowLeftIcon />} onClick={onChangeStep} />
        <IconButton
          color='blue'
          type='primary'
          iconPos='right'
          icon={<OpenProfileIcon />}
          onClick={handleOpenProfile}
        >
          Open full profile page
        </IconButton>
      </div>

      <div className={styles.agentMainInfoContainer}>
        <img src={agent?.avatar || emptyAvatarImage} />
        <div className={styles.middleContainer}>
          <div className={styles.agentName}>
            {agent?.firstName ? `${agent?.firstName} ${agent?.lastName}` : `${agent?.companyTitle}`}
          </div>
          <div className={styles.agentTypeContainer}>
            <div>{agent?.agentType?.name}</div>
            <div>{`Level ${agent?.agentLevel?.level}`}</div>
          </div>
          <div className={styles.agentId}>{`ID ${agent?.id}`}</div>
        </div>
        <div className={styles.statusContainer}>
          {onStatusChange && (
            <Toggle text='Status' inWrapper checked={agent?.status} onChange={onStatusChange} />
          )}

          {onStatusMpinChange && (
            <Toggle
              text='mPin'
              inWrapper
              checked={agent?.mPinActivation}
              onChange={onStatusMpinChange}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}
